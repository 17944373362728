import React, { useState, useEffect } from 'react';

import { LinearProgress, Snackbar, Typography } from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import axios from 'axios';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import * as UserActions from 'store/actions/user';
import { ButtonChip } from 'styles/buttons';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import ScoreCardInfos from './components/ScoreCardInfos';

function SalesNfe({ userInfo }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );

  const { id: paramsID } = useParams();
  const actualDate = new Date();
  const dateLessTwo = new Date().setDate(actualDate.getDate() - 2);
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 100,
    loading: false,
    filters: [],
    numberOfElements: 0,
    totalElements: 0
  });
  const [scoreBoardCallSettings, setScoreBoardCallSettings] = useState({
    scoreBoardLoading: false,
    scoreBoardFilters: []
  });
  const {
    getId,
    page,
    size,
    totalPages,
    numberOfElements,
    filters,
    totalElements
  } = callSettings;
  const { scoreBoardFilters } = scoreBoardCallSettings;
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [scoreBoardFiltersChanged, setScoreBoardFiltersChanged] = useState(
    scoreBoardFilters.values()
  );
  const [salesNfes, setSalesNfes] = useState([]);
  const [salesScoreInfos, setSalesScoreInfos] = useState([]);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [filterFields, setFilterFields] = useState([
    [
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'startDate',
        filterNameSecond: 'endDate',
        initialDate: dateLessTwo,
        endDate: actualDate,
        minDate: true,
        maxDate: true
      },
      {
        label: 'Status',
        filterName: 'status',
        placeHolder: 'Selecione...',
        size: 'medium_plus',
        type: 'autoComplete',
        optionReference: 'name',
        options: [
          { title: 'Todas', value: null },
          { title: 'Enviado', value: 'SENT' },
          { title: 'Com erro', value: 'ERROR' }
        ]
      },
      {
        label: 'Restaurantes',
        filterName: 'restaurantId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'stores',
        optionReference: 'name',
        options: stores[0],
        size: 'medium_plus'
      }
    ]
  ]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: paramsID });
  }, [paramsID]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    (async () => {
      const otherRestaurant =
        filtersChanged.find((item) => item.param === 'restaurantId') &&
        filtersChanged.find((item) => item.param === 'restaurantId').value;

      if (!filtersChanged.find((item) => item.param === 'startDate')) {
        filtersChanged.push(...filtersChanged, {
          param: 'startDate',
          value: format(new Date(dateLessTwo), 'yyyy-MM-dd'),
          urlParam: `startDate=${format(new Date(dateLessTwo), 'yyyy-MM-dd')}`
        });
      }

      if (!filtersChanged.find((item) => item.param === 'endDate')) {
        filtersChanged.push({
          param: 'endDate',
          value: format(new Date(actualDate), 'yyyy-MM-dd'),
          urlParam: `endDate=${format(new Date(actualDate), 'yyyy-MM-dd')}`
        });
      }

      setCallSettings({ ...callSettings, loading: true });
      const params = filtersChanged
        .map((filter) => filter.urlParam.replace('restaurantId=', ''))
        .join('&');
      const response = await fetch(
        `${environments.sales}/notes?page=${page}&size=${size}&restaurantId=${
          otherRestaurant || stores[0].map((r) => r.id)
        }&${params}`
      );
      const items = await response.json();
      setSalesNfes(items.content);
      setCallSettings({
        ...callSettings,
        loading: false,
        numberOfElements: items.numberOfElements,
        totalElements: items.totalElements,
        totalPages: items.totalPages
      });
    })();
  }, [getId, page, size, filtersChanged]);

  useEffect(() => {
    (async () => {
      const otherRestaurant =
        scoreBoardFilters.find((item) => item.param === 'restaurantId') &&
        scoreBoardFilters.find((item) => item.param === 'restaurantId').value;

      if (!scoreBoardFilters.find((item) => item.param === 'startDate')) {
        scoreBoardFilters.push(...scoreBoardFilters, {
          param: 'startDate',
          value: format(new Date(dateLessTwo), 'yyyy-MM-dd'),
          urlParam: `startDate=${format(new Date(dateLessTwo), 'yyyy-MM-dd')}`
        });
      }

      if (!scoreBoardFilters.find((item) => item.param === 'endDate')) {
        scoreBoardFilters.push({
          param: 'endDate',
          value: format(new Date(actualDate), 'yyyy-MM-dd'),
          urlParam: `endDate=${format(new Date(actualDate), 'yyyy-MM-dd')}`
        });
      }

      setScoreBoardCallSettings({
        ...scoreBoardCallSettings,
        scoreBoardLoading: true
      });
      const params = scoreBoardFilters
        .map((filter) => filter.urlParam.replace('restaurantId=', ''))
        .join('&');
      const response = await fetch(
        `${
          environments.sales
        }/notes/scoreboard?page=${page}&size=${size}&restaurantId=${
          otherRestaurant || stores[0].map((r) => r.id)
        }&${params}`
      );
      const items = await response.json();
      setSalesScoreInfos(items);
      setScoreBoardCallSettings({
        ...scoreBoardCallSettings,
        scoreBoardLoading: false
      });
    })();
  }, [scoreBoardFiltersChanged]);

  const reprocessAll = (restaurantId) => {
    setCallSettings({ ...callSettings, loading: true });

    const body = {
      restaurantId,
      startDate: scoreBoardFilters.find((item) => item.param === 'startDate')
        .value,
      endDate: scoreBoardFilters.find((item) => item.param === 'endDate').value,
      user: userInfo?.user
    };

    axios
      .put(`${environments.sales}/notes/reprocessAll`, body)
      .then((res) => {
        setCallSettings({ ...callSettings, loading: false });

        setSalesScoreInfos(
          salesScoreInfos.map((item) => {
            if (item.restaurantId === restaurantId) {
              return {
                ...item,
                countSent:
                  item?.countSent +
                  (item?.countError - res?.data?.errorNotes?.length),
                countError: res?.data?.errorNotes?.length
              };
            }
            return { ...item };
          })
        );

        let descriptionMessage = '';
        let statusMessage = '';

        if (!res.data?.errorNotes?.length) {
          descriptionMessage = 'Notas reprocessadas com sucesso.';
          statusMessage = 'success';
        } else {
          descriptionMessage = 'Notas reprocessadas, mas ainda contém erro';
          statusMessage = 'error';
        }

        setMessage({ description: descriptionMessage, status: statusMessage });
      })
      .catch(() => {
        setCallSettings({ ...callSettings, loading: false });
        const errorMessage = 'Erro ao reprocessar nota';

        setMessage({
          description: errorMessage,
          status: 'error'
        });
      });
  };

  const reprocessNfe = (nfe) => {
    setCallSettings({ ...callSettings, loading: true });

    const body = {
      noteId: nfe._id,
      user: userInfo?.user
    };

    axios
      .put(`${environments.sales}/notes/reprocess`, body)
      .then((res) => {
        setCallSettings({ ...callSettings, loading: false });

        setSalesNfes(
          salesNfes.map((item) => {
            if (item._id === nfe._id && !res.data?.errorNotes?.length) {
              return {
                ...item,
                status: 'SENT'
              };
            }
            return { ...item };
          })
        );

        let descriptionMessage = '';
        let statusMessage = '';

        if (!res.data?.errorNotes?.length) {
          descriptionMessage = 'Nota reprocessada com sucesso.';
          statusMessage = 'success';
        } else {
          descriptionMessage = 'Erro ao reprocessar nota';
          statusMessage = 'error';
        }

        setMessage({ description: descriptionMessage, status: statusMessage });
      })
      .catch(() => {
        setCallSettings({ ...callSettings, loading: false });
        const errorMessage = 'Erro ao reprocessar nota';

        setMessage({
          description: errorMessage,
          status: 'error'
        });
      });
  };

  const handleFilter = (param, value) => {
    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  };

  const handleFilterScoreBoard = (paramsAndValues) => {
    handleMultipleFilters(
      paramsAndValues,
      scoreBoardFilters,
      setScoreBoardFiltersChanged
    );
  };

  const handleOrdinationFilter = (paramsAndValues) => {
    handleMultipleFilters(
      paramsAndValues,
      filtersChanged,
      setFiltersChanged,
      1,
      {
        callSettings,
        setCallSettings
      }
    );
  };

  const getFilterHandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page
    );
  };

  const nfeStatusButton = (nfe) => {
    const description = nfe?.status === 'ERROR' ? 'Com erro' : 'Enviado';
    const color = nfe?.status === 'ERROR' ? 'danger' : 'success';

    return (
      <ButtonChip
        onClick={() => reprocessNfe(nfe)}
        label={description}
        color={color}
        background={color}
      />
    );
  };

  return (
    <Layout>
      <TitleHead title="Nfes de Venda" />

      <GlobalStyle />

      <Container className="salesNfePage">
        <ScoreCardInfos
          salesScoreInfo={salesScoreInfos}
          scoreBoardCallSettings={scoreBoardCallSettings}
          handleFilterScoreBoard={handleFilterScoreBoard}
          initialDate={format(new Date(dateLessTwo), 'yyyy-MM-dd')}
          endDate={format(new Date(actualDate), 'yyyy-MM-dd')}
          reprocessAll={reprocessAll}
        />

        <section className="dFlex flexColumn ml10" style={{ width: '70%' }}>
          <PageTitleAndFilter
            title="Nfes de Venda"
            activeFilterFilds={filterFields}
            handleFilter={handleFilter}
            handleMultipleFilters={handleOrdinationFilter}
            dynamicJustifyOff
            getFilterhandle={getFilterHandle}
          />

          <table
            className="desktop salesNfesListTable"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th className="subtitle" width="17%">
                  DATA DE ENVIO{' '}
                  <OrderBy
                    filterName="sendDate"
                    handleFilter={handleOrdinationFilter}
                  />
                </th>
                <th className="subtitle" width="15%">
                  DATA DE EMISSÃO{' '}
                  <OrderBy
                    filterName="emissionDate"
                    handleFilter={handleOrdinationFilter}
                  />
                </th>
                <th className="subtitle" width="25%">
                  RESTAURANTE
                </th>
                <th className="subtitle" width="8%">
                  Nº NOTA{' '}
                </th>
                <th className="subtitle" width="35%">
                  ERRO
                </th>
                <th className="subtitle" width="15%">
                  STATUS
                </th>
              </tr>

              <tr className="headerBorder">
                <th colSpan="6" className="titleLineBorder" />
              </tr>
            </thead>

            <tbody>
              {salesNfes && salesNfes.length ? (
                salesNfes.map((nfe, index) => (
                  <>
                    <tr>
                      <td className="description pl12">
                        {nfe?.sendDate
                          ? `${format(
                              new Date(nfe.sendDate).valueOf() +
                                new Date(nfe.sendDate).getTimezoneOffset() *
                                  60 *
                                  1000,
                              "dd/MM/yyyy - HH:mm'h'"
                            )}`
                          : '-'}
                      </td>

                      <td className="description">
                        {nfe?.emissionDate
                          ? `${format(new Date(nfe.emissionDate), 'dd/MM/yy')}`
                          : '-'}
                      </td>

                      <td className="description">
                        {ValidationLength(nfe?.unitName || '-', 20)}
                      </td>

                      <td className="description">{nfe?.noteNumber || '-'}</td>

                      <td id={`status${index}`} className="description">
                        {ValidationLength(nfe?.typeError || '-', 40)}
                      </td>

                      <td id={`status${index}`} className="description p12">
                        {nfeStatusButton(nfe)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="6" className="p0">
                        <hr className="m0" />
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="textAlignCenter">
                    <Typography
                      style={{
                        fontSize: '12px',
                        margin: '10px 0',
                        fontFamily: 'Lato',
                        textAlign: 'center'
                      }}
                    >
                      Não há registros para exibir.
                    </Typography>
                  </td>
                </tr>
              )}

              {callSettings.loading ? (
                <tr>
                  <td colSpan="6" className="m10">
                    <LinearProgress variant="query" />
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>

          <div className="pagination">
            <Pagination
              count={totalPages}
              onChange={(event, page) =>
                setCallSettings({ ...callSettings, page })
              }
              variant="outlined"
              shape="rounded"
              size="small"
              color="primary"
              showFirstButton
              showLastButton
            />

            <ItemsPerPage
              numberOfElements={numberOfElements}
              size={totalElements}
            />
          </div>
        </section>

        <Snackbar
          open={!!message.description}
          autoHideDuration={2000}
          onClose={() => setMessage({ description: '', status: '' })}
        >
          <Alert
            onClose={() => setMessage({ description: '', status: '' })}
            severity={message.status}
          >
            {message.description}
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SalesNfe);
