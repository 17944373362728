import React from 'react';

function PreparationView() {
  const encodedUrl = localStorage.getItem('preparationUrl');
  const url = atob(encodedUrl);

  return (
    <embed
      src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${url}`}
      width="100%"
      height="100%"
      type="application/pdf"
    />
  );
}

export default PreparationView;
