const getParamsID = () => {
    const { pathname } = window.location;
    if (!pathname) return;
    const regexResult = pathname.match(/(\/\b)(?!.*\/)(.*$)/);
    if(!regexResult) return;
    if(regexResult.length < 2) return;
    const paramID = regexResult[2]?.includes(',') ? regexResult[2] : parseFloat(regexResult[2]);
    return paramID;
}

export default getParamsID;
