export function setEditCards(data) {
  return {
    type: 'SET_CARDS_DATA',
    data,
  };
}

export function createCards(data) {
  return {
    type: 'CREATE_CARDS_DATA',
    data,
  };
}

