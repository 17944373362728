import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { handleColorType, handleBackgroundType } from '../../styles/colors';

export const Filtros = styled.div`
  font-family: 'Lato';
  font-size: ${(props) => props.fontSize};
  margin: 5px;
  padding: 0 5px;
  text-align: left;
  flex: 2;
  @media (min-width: 768px) and (max-width: 1170px) {
    font-family: 'Lato';
    font-size: 14px;
    flex: 2;
  }
`;
export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#222',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontFamily: 'Lato'
  }
}))(Tooltip);

export const ButtonChip = styled(Chip)`
  width: 80px;
  height: 20px;
  font-family: 'Lato';
  font-size: 12px;
  margin: 0;
  padding: 0;
  line-height: 35px;
  border-radius: 4px;
  cursor: pointer;
  ${({ background }) => handleBackgroundType(background)};
  ${({ color }) => handleColorType(color)};

  :hover {
    ${({ background }) => handleBackgroundType(background)};
    ${({ color }) => handleColorType(color)};
  }

  @media (max-width: 1024px) {
    width: 100%;
    font-size: 12px;
  }

  span {
    padding: 0 8px;
    display: ${(props) => (props.align === 'right' ? 'flex' : '')};
    width: 100%;
    height: ${(props) => (props.align === 'right' ? '100%' : '')};
    align-items: center;
    justify-content: flex-end;
  }
`;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },

  nested: {
    paddingLeft: theme.spacing(4)
  },

  label: {
    fontFamily: 'Lato'
  },

  inputRoot: {
    fontFamily: 'Lato'
  },

  option: {
    fontFamily: 'Lato'
  }
}));
