import styled from 'styled-components'

export const InflationHeader = styled.div`
  background-color: #F2F3F8;
  padding: 4px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  p {
    color: #454A67;
    font-size: 12px;
  }
`

export const LastOrder = styled.div`
  padding: 8px 12px;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      color: #454A67;
      font-size: 12px;
    }

    p:first-child {
      font-size: 9px;
      margin-bottom: 2px;
      font-weight: 700;
    }
  }
  .lastOrderInfo {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      
      p:first-child {
        font-size: 9px;
      }
      
    }
    
  }
`