/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Paper
} from '@material-ui/core';
import Draggable from 'react-draggable';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-packagetype"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ProductsDialog({
  modalSettings,
  setModalSettings,
  products,
}) {
  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'productModal')
          .fullScreen
      }
      open={
        modalSettings.find((modal) => modal.name === 'productModal').open
      }
      onClose={() => {
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'productModal'
              ? { ...modal, open: false }
              : modal
          )
        );
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Produtos</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'productModal'
                  ? { ...modal, open: false }
                  : modal
              )
            );
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <table>
          <thead>
            <tr>
              <th width="500" className="subtitle">
                DESCRIÇÃO
              </th>
            </tr>

            <tr>
              <td colSpan="5">
                <hr
                  className="titleLineBorder"
                  style={{ margin: '0', height: '2px' }}
                />
              </td>
            </tr>
          </thead>

          <tbody>
            {products && products.length ? (
              products.map((product) => (
                <>
                  <tr>
                    <td className="description">
                      <div>
                        {product.description}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="5">
                      <hr
                        className="titleLineBorder"
                        style={{ margin: '0', height: '2px' }}
                      />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="description center">
                  <Typography className="fontSizeDefault bold" style={{ margin: '5px', textAlign: 'center' }}>
                    Não há nenhum produto
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default ProductsDialog;
