import React from "react";

const SelectAllIcon = (props) => {
    return (
        <>
            {
                props.selected &&
                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
                    <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
                    fill="#5062F0"
                    />
                    <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.75 8C2.75 7.58579 3.08579 7.25 3.5 7.25H12.5C12.9142 7.25 13.25 7.58579 13.25 8C13.25 8.41421 12.9142 8.75 12.5 8.75H3.5C3.08579 8.75 2.75 8.41421 2.75 8Z"
                    fill="white"
                    />
                </svg>
            }

            {
                !props.selected && 
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"  {...props}>
                    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#0E1337" fill="white" />
                </svg>
            }

        </>
    );
};

export default SelectAllIcon;