/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import numberToReal from 'utils/numberToReal';

import { MobileDialogDescontos, MobileDialogDescontosDetails } from './style';

function AcrescimosDescontos({
  openModalOrder,
  handleCloseModal,
  PaperComponent,
  order
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openModalOrder}
      onClose={handleCloseModal}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="modalHeader navyBlue"
        style={{
          fontWeight: 'bold',
          textAlign: 'center',
          cursor: 'move',
          fontFamily: 'Lato'
        }}
        id="draggable-dialog-title"
      >
        <Typography>
          <div>
            <p
              style={{
                margin: '0',
                padding: '0',
                textAlign: 'left',
                fontSize: '9px',
                fontWeight: '400'
              }}
            >
              Pedido {order._id}
            </p>
            
            <p
              style={{
                margin: '0',
                padding: '0',
                textAlign: 'left',
                fontSize: '12px',
                fontWeight: '400'
              }}
            >
              Acrescimos e Descontos
            </p>
          </div>
        </Typography>

        <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <div style={{ margin: '0', padding: '0' }}>
        <MobileDialogDescontos>
          <div>
            <h1
              style={{
                fontSize: '16px',
                margin: '0px 10px 10px 10px ',
                paddingTop: '10px'
              }}
            >
              Total Recebimentos
            </h1>

            <div className="totalHeader">
              <div className="firstDetails">
                <div style={{ marginLeft: '15px' }}>
                  <p>FRETE</p>
                  <h1>R${numberToReal(order?.shipping)}</h1>
                </div>

                <div>
                  <p>SEGURO</p>
                  <h1>R${numberToReal(order?.segCosts)}</h1>
                </div>

                <div>
                  <p>OUTRAS DESPESAS</p>
                  <h1>R${numberToReal(order?.otherCosts)}</h1>
                </div>
              </div>

              <div className="secondDetails">
                <div>
                  <p>DESCONTOS</p>
                  <h1>R${numberToReal(order?.discount)}</h1>
                </div>

                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <p>TOTAL</p>
                  <h1 style={{ fontSize: '16px' }}>
                    R${numberToReal(order?.totalOrderAmount)}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <MobileDialogDescontosDetails>
            {order?.orderItems?.map((odr) => (
              <div className="acrescimosDetail">
                <h1 style={{ fontSize: '16px', margin: '0 10px' }}>
                  {odr?.sku?.description}
                </h1>

                <div className="totalHeaderDetail">
                  <div
                    className="firstDetailsOrder"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ marginLeft: '10px' }}>
                      <p>FRETE</p>

                      <h1>R${numberToReal(odr?.sku?.provider?.shipping)}</h1>
                    </div>

                    <div>
                      <p>SEGURO</p>

                      <h1>R${numberToReal(odr?.sku?.provider?.segCosts)}</h1>
                    </div>

                    <div>
                      <p>OUTRAS DESPESAS</p>

                      <h1>
                        R$
                        {numberToReal(
                          odr?.sku?.provider?.otherCosts * odr?.costs?.quantity
                        )}
                      </h1>
                    </div>
                  </div>

                  <div
                    className="secondDetailsOrder"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '10px'
                    }}
                  >
                    <div>
                      <p>DESCONTOS</p>

                      <h1>
                        R$
                        {numberToReal(
                          odr?.sku?.provider?.discount * odr?.costs?.quantity
                        )}
                      </h1>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <p>TOTAL</p>

                      <h1 style={{ fontSize: '16px' }}>
                        R${numberToReal(odr?.costs?.total)}
                      </h1>
                    </div>
                  </div>
                </div>

                <hr style={{ margin: '2px 0' }} />
              </div>
            ))}
          </MobileDialogDescontosDetails>
        </MobileDialogDescontos>
      </div>
    </Dialog>
  );
}

export default AcrescimosDescontos;
