/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import {
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
  Paper,
  Dialog
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import { LocalizationPlainText } from 'components/Localization';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as UserActions from 'store/actions/user';
import { Container } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-brands-form"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function BrandForm({
  userInfo,
  brandInfo,
  redirectPath,
  isModal,
  onClose,
  setUpdateList,
  createMode,
  skuModal,
  dialogBrandsSettings,
  multipleSkuData,
  setMultipleSkuData,
  isMultipleSku,
  selectedItems,
  setSelectedItems,
  jsonBody,
  setJsonBody,
  skuDefaultDescription,
  setSkuDefaultDescription,
  openModal,
  updateOptions,
  setUpdateOptions
}) {
  const filter = createFilterOptions();
  const environments = Environment(process.env.REACT_APP_ENV);
  const [open, setOpen] = useState(false);
  const [getOrigin, setOriginId] = useState(userInfo.companiesActive.id);
  const [loading, setLoading] = useState(false);
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const [messageErr, setMessageErr] = useState('');
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const onDismiss = () => setAlertErr(false);
  const { handleSubmit } = useForm();
  const [editMode, setEditMode] = useState(
    createMode !== true
      ? !!(brandInfo.action && brandInfo.action.toLowerCase() === 'edit')
      : false
  );
  const [brandId, setBrandId] = useState(editMode ? brandInfo.data.id : '');
  const [description, setDescription] = useState(
    editMode ? brandInfo.data.description : ''
  );
  const [itemSelected, setItemSelected] = useState(
    editMode ? brandInfo.data.producers : ''
  );
  const [options, setOptions] = useState([]);
  const [newProducerName, setNewProducerName] = useState('');
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  useEffect(() => {
    if (messageErr === 'Results found according to these parameters')
      setMessageErr('Marca já cadastrada');
    else setMessageErr('Erro ao cadastrar');
  }, [alertErr, messageErr]);

  useEffect(() => {
    setEditMode(
      !!(brandInfo.action && brandInfo.action.toLowerCase() === 'edit')
    );
    setBrandId(editMode ? brandInfo.data.id : '');
    setDescription(editMode ? brandInfo.data.description : '');
    setItemSelected(editMode ? brandInfo.data.producers : '');
  }, [brandInfo, editMode]);

  const onSubmitBrand = () => {
    setLoading(true);
    if (editMode) {
      axios
        .put(
          `${environments.catalog}/brands/${brandId}`,
          {
            description,
            user: userInfo.user,
            producersId: itemSelected?.id || null,
            producerName: newProducerName?.toUpperCase() || ''
          },
          config
        )
        .then(() => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          setMessage({description: error.response.data.message, status: 'error' });
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    } else {
      axios
        .post(
          `${environments.catalog}/brands`,
          {
            description,
            originId: userInfo.companiesActive.id,
            user: userInfo.user,
            producersId: itemSelected?.id || null,
            producerName: newProducerName?.toUpperCase() || ''
          },
          config
        )
        .then((res) => {
          setOpen(true);
          setLoading(false);
          skuModal && updateSkuModalObject(res.data);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          setMessage({description: error.response.data.message, status: 'error' });
          setLoading(false);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    }
  };

  const handleClose = (reason) => {
    if (redirectPath !== null) {
      history.push(redirectPath);
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const updateSkuModalObject = (obj) => {
    if (isMultipleSku) {
      setMultipleSkuData(
        multipleSkuData.map((sku, i) => {
          if (dialogBrandsSettings.currentSku === i) {
            return {
              ...sku,
              brand: {
                description: obj.description,
                hasSku: obj.hasSku,
                id: obj.id,
                status: obj.status,
                producers: itemSelected || {
                  id: obj.producersId,
                  description: newProducerName?.toUpperCase() || ''
                }
              },
              brandsId: obj.id,
              skuDefaultDescription: {
                ...sku.skuDefaultDescription,
                brand: obj.description || ''
              }
            };
          }
          return sku;
        })
      );
    } else {
      setSelectedItems({
        ...selectedItems,
        brand: {
          description: obj.description,
          hasSku: obj.hasSku,
          id: obj.id,
          status: obj.status,
          producers: itemSelected || {
            id: obj.producersId,
            description: newProducerName?.toUpperCase() || ''
          }
        }
      });

      setJsonBody({
        ...jsonBody,
        sku: {
          ...jsonBody.sku,
          brandsId: obj.id
        }
      });

      setSkuDefaultDescription({
        ...skuDefaultDescription,
        brand: obj.description || ''
      });
    }

    setUpdateOptions({ ...updateOptions, brands: true });
  };

  useEffect(() => {
    setOriginId(userInfo.companiesActive.id);
  }, [userInfo.companiesActive.id]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.catalog}/producers?page=1&size=1000&originId=${getOrigin}`
      );
      const producers = await response.json();
      setOptions(producers.content);
    })();
  }, [getOrigin]);

  return (
    <Dialog
      className="defaultModal"
      style={{ width: '50%' }}
      maxWidth="xl"
      open={openModal}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title-brands-form"
      transitionDuration={0}
    >
      <Container className="w100" style={{ marginBottom: '0px' }}>
        <DialogTitle
          className="modalHeader navyBlue"
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'move',
            fontFamily: 'Lato',
            borderRadius: '4px 4px 0 0'
          }}
          id="draggable-dialog-title-brands-form"
        >
          <Typography>CADASTRO DE MARCAS</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

      <CommonAlert
        open={alertErr}
        severity={message.status}
        onClose={() => setAlertErr(false)}
        messagePersonal={message.description}
        autoHideDuration={2000}
        width="60%"
      />

        {loading ? <LinearProgress /> : null}

        <div
          className="cardDefault pt16"
          style={{ borderRadius: '0', width: '614px' }}
        >
          <form onSubmit={handleSubmit(onSubmitBrand)}>
            <Grid className="m0" container xs={12} sm={12} spacing={4}>
              <Grid item xs={6} sm={6} className="pl0">
                <TextField
                  size="small"
                  label={LocalizationPlainText('description', 'translations')}
                  name="description"
                  onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  value={description}
                  fullWidth
                  autoFocus
                  required="required"
                  inputProps={{ maxLength: 50 }}
                  className="textFieldSmall"
                  placeholder={LocalizationPlainText('TypeHere', 'translations')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={6} className="pr0">
                <Autocomplete
                  id="controllable-states-demo"
                  open={openAutoComplete}
                  onOpen={() => {
                    setOpenAutoComplete(true);
                  }}
                  onClose={() => {
                    setOpenAutoComplete(false);
                  }}
                  getOptionSelected={(option, value) =>
                    option.description === value.description
                  }
                  getOptionLabel={(option) => option.description}
                  options={options}
                  size="small"
                  value={itemSelected}
                  onChange={(event, itemSelected) => {
                    if (itemSelected && itemSelected.inputValue) {
                      setItemSelected({
                        id: null,
                        description: itemSelected.inputValue
                      });
                    } else if (itemSelected) {
                      setItemSelected(itemSelected);
                    } else {
                      setNewProducerName('');
                      setItemSelected('');
                    }
                  }}
                  className="autocompleteSmall"
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        description: `Criar "${params.inputValue}"`
                      });

                      setNewProducerName(params.inputValue);
                    }

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="providers"
                      label="Fabricante"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>

            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                Criado com sucesso.
              </Alert>
            </Snackbar>

            <Grid
              item
              xs={12}
              sm={12}
              className="p0 dFlex justifyEnd bgWhite"
              style={{ borderRadius: '0 0 4px 4px' }}
            >
              {!isModal && (
                <Link to="/catalogo/marcas/">
                  <Button
                    className="defaultButton backButton"
                    design="outlined"
                    label="Voltar"
                    style={{ marginRight: '20px' }}
                  />
                </Link>
              )}

              <Button
                className="defaultButton submitButton"
                type="submit"
                label="Salvar"
                design="contained"
                onSubmit={handleSubmit(onSubmitBrand)}
              />
            </Grid>
          </form>
        </div>
      </Container>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  brandInfo: state.brands
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
