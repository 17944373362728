import React from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const ButtonOutlined = withStyles({
  root: {
    boxShadow: 'none',
    fontSize: 14,
    color: '#395FFA',
    padding: '20px 65px',
    border: '1px solid',
    lineHeight: '0',
    letterSpacing: '1.25px',
    backgroundColor: '#FFFFFF',
    borderColor: '#395FFA',
    borderRadius: '32px',
    textTransform: 'uppercase',
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      backgroundColor: 'rgba(57, 95, 250, 0.04)',
      borderColor: '#395FFA',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(57, 95, 250, 0.12)',
      borderColor: '#395FFA'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(57, 95, 250, 0.12)'
    }
  },
  disabled: {
    padding: '22px 65px',
    backgroundColor: '#FFFFFF !important',
    border: '1px solid rgba(0, 0, 0, 0.12)'
  }
})(Button);

const ButtonContained = withStyles({
  root: {
    boxShadow: 'none',
    fontSize: 14,
    color: '#FFFFFF',
    padding: '20px 65px',
    border: '1px solid #5062F0',
    lineHeight: '0',
    letterSpacing: '1.25px',
    backgroundColor: '#5062F0',
    borderRadius: '32px',
    textTransform: 'uppercase',
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      backgroundColor: '#5062F0',
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgba(47, 118, 252, 0.12)'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(47, 118, 252, 0.12)'
    }
  },
  disabled: {
    backgroundColor: '#FFFFFF !important',
    border: 'none'
  }
})(Button);

function Buttons({
  className,
  design,
  type,
  variant,
  startIcon,
  endIcon,
  label,
  disabled,
  fullWidth,
  margin,
  href,
  style,
  onClick,
  id
}) {
  switch (design) {
    case 'contained':
      return (
        <ButtonContained
          id={id}
          className={className}
          design={design}
          type={type}
          disabled={disabled}
          variant={variant}
          startIcon={startIcon}
          endIcon={endIcon}
          fullWidth={fullWidth}
          margin={margin}
          href={href}
          style={style}
          onClick={onClick}
        >
          {label}
        </ButtonContained>
      );

    default:
      return (
        <ButtonOutlined
          id={id}
          className={className}
          design={design}
          type={type}
          disabled={disabled}
          variant={variant}
          startIcon={startIcon}
          endIcon={endIcon}
          fullWidth={fullWidth}
          margin={margin}
          href={href}
          style={style}
          onClick={onClick}
        >
          {label}
        </ButtonOutlined>
      );
  }
}

export default Buttons;