/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

import AddTag from './AddTag';
import TagsList from './TagsList';

function TagsManager({
  jsonBody,
  getId,
  setJsonBody,
  tagsProps,
  sku,
  skuIndex,
  onlyViewMode,
  setSkuDefaultDescription,
  skuDefaultDescription
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [tagsUpdated, setTagsUpdated] = useState(false);
  const [tags, setTags] = useState(tagsProps);
  const [tag, setTag] = useState({});

  useEffect(() => {
    if (!tagsUpdated) return;

    getOptions(`${environments.catalog}/tags`, 'tags', tags, setTags, getId);
  }, [tagsUpdated]);

  const addNewTag = () => {
    if (sku) {
      setJsonBody(
        jsonBody.map((sku, i) => {
          if (i === skuIndex) {
            if (sku.tags?.some((item) => item.id === tag.id)) {
              return {
                ...sku,
                tags: [
                  ...sku.tags.map((element) => {
                    if (tag.id === element.id) {
                      return {
                        ...element,
                        status: 'CREATED'
                      };
                    }
                    return element;
                  })
                ].flat()
              };
            }
            return {
              ...sku,
              tags: [sku.tags, { ...tag, status: 'CREATED', values: [] }].flat()
            };
          }
          return sku;
        })
      );
    } else if (jsonBody.tags?.some((item) => item.id === tag.id)) {
      setJsonBody({
        ...jsonBody,
        tags: [
          ...jsonBody.tags.map((element) => {
            if (tag.id === element.id) {
              return {
                ...element,
                status: 'CREATED'
              };
            }
            return element;
          })
        ].flat()
      });
    } else {
      setJsonBody({
        ...jsonBody,
        tags: [
          ...jsonBody.tags,
          { ...tag, status: 'CREATED', values: [] }
        ].flat()
      });
    }

    setTag({});
  };

  const handleChangeProductTagsList = (
    tagItem,
    updatedValue,
    status,
    checked
  ) => {
    if (sku) {
      setJsonBody(
        jsonBody.map((sku, i) => {
          if (i === skuIndex) {
            return {
              ...sku,
              skuDefaultDescription: {
                ...sku.skuDefaultDescription,
                tags:
                  (status === 'CREATED' && updatedValue) || checked
                    ? !checked
                      ? sku?.skuDefaultDescription?.tags || []
                      : [
                          sku?.skuDefaultDescription?.tags || [],
                          updatedValue
                        ].flat()
                    : status === 'DISABLED' ||
                      (status === 'DELETED' && !updatedValue)
                    ? sku?.skuDefaultDescription?.tags.filter(
                        (item) => item.tagId !== tagItem.id
                      )
                    : sku?.skuDefaultDescription?.tags
                    ? sku?.skuDefaultDescription?.tags.filter(
                        (item) => item.id !== updatedValue.id
                      )
                    : ''
              },
              tags: sku.tags.map((tag) => {
                if (tagItem.id === tag.id) {
                  return {
                    ...tag,
                    showInDescription:
                      status === 'DISABLED'
                        ? checked
                        : status === 'CREATED' &&
                          !tag?.values?.filter(
                            (item) => item.status !== 'DELETED'
                          ).length
                        ? true
                        : tag.showInDescription,
                    status:
                      status === 'DISABLED'
                        ? tag.status
                        : !updatedValue
                        ? status
                        : 'CREATED',
                    values:
                      status === 'CREATED'
                        ? tag.values.find((val) => val.id === updatedValue.id)
                          ? tag.values.map((val) =>
                              val.id === updatedValue.id
                                ? {
                                    ...val,
                                    status: 'CREATED',
                                    showInDescription: !tag?.values?.filter(
                                      (item) => item.status !== 'DELETED'
                                    ).length
                                      ? true
                                      : tag.showInDescription
                                  }
                                : val
                            )
                          : [
                              ...tag.values,
                              {
                                ...updatedValue,
                                status: 'CREATED',
                                showInDescription: !tag?.values?.filter(
                                  (item) => item.status !== 'DELETED'
                                ).length
                                  ? true
                                  : tag.showInDescription
                              }
                            ]
                        : status === 'DISABLED'
                        ? tag.values.map((value) => ({
                            ...value,
                            showInDescription: checked
                          }))
                        : !updatedValue
                        ? tag.values.map((value) => ({
                            ...value,
                            status: 'DELETED',
                            showInDescription: tag.showInDescription
                          }))
                        : tag.values.map((value) =>
                            value.id === updatedValue.id
                              ? {
                                  ...value,
                                  status: 'DELETED',
                                  showInDescription: tag.showInDescription
                                }
                              : value
                          )
                  };
                }
                return tag;
              })
            };
          }
          return sku;
        })
      );
    } else {
      setJsonBody({
        ...jsonBody,
        sku: {
          ...jsonBody.sku
        },
        tags: jsonBody.tags.map((tag) => {
          if (tagItem.id === tag.id) {
            return {
              ...tag,
              showInDescription:
                status === 'DISABLED'
                  ? checked
                  : status === 'CREATED' &&
                    !tag?.values?.filter((item) => item.status !== 'DELETED')
                      .length
                  ? true
                  : tag.showInDescription,
              status:
                status === 'DISABLED'
                  ? tag.status
                  : !updatedValue
                  ? status
                  : 'CREATED',
              values:
                status === 'CREATED'
                  ? tag.values.find((val) => val.id === updatedValue.id)
                    ? tag.values.map((val) =>
                        val.id === updatedValue.id
                          ? {
                              ...val,
                              status: 'CREATED',
                              showInDescription: !tag?.values?.filter(
                                (item) => item.status !== 'DELETED'
                              ).length
                                ? true
                                : tag.showInDescription
                            }
                          : val
                      )
                    : [
                        ...tag.values,
                        {
                          ...updatedValue,
                          status: 'CREATED',
                          showInDescription: !tag?.values?.filter(
                            (item) => item.status !== 'DELETED'
                          ).length
                            ? true
                            : tag.showInDescription
                        }
                      ]
                  : status === 'DISABLED'
                  ? tag.values.map((value) => ({
                      ...value,
                      showInDescription: checked
                    }))
                  : !updatedValue
                  ? tag.values.map((value) => ({
                      ...value,
                      status: 'DELETED',
                      showInDescription: tag.showInDescription
                    }))
                  : tag.values.map((value) =>
                      value.id === updatedValue.id
                        ? {
                            ...value,
                            status: 'DELETED',
                            showInDescription: tag.showInDescription
                          }
                        : value
                    )
            };
          }
          return tag;
        })
      });

      setSkuDefaultDescription({
        ...skuDefaultDescription,
        tags:
          (status === 'CREATED' && updatedValue) || checked
            ? !checked
              ? skuDefaultDescription?.tags || []
              : [skuDefaultDescription?.tags || [], updatedValue].flat()
            : status === 'DISABLED' || (status === 'DELETED' && !updatedValue)
            ? skuDefaultDescription?.tags.filter(
                (item) => item.tagId !== tagItem.id
              )
            : skuDefaultDescription?.tags
            ? skuDefaultDescription?.tags.filter(
                (item) => item.id !== updatedValue.id
              )
            : ''
      });
    }

    if (!updatedValue) {
      setTagsUpdated(!tagsUpdated);
    }
  };

  return (
    <>
      <AddTag
        tags={tags}
        tag={tag}
        setTag={setTag}
        tagsUpdated={tagsUpdated}
        productTags={jsonBody?.tags}
        setTagsUpdated={setTagsUpdated}
        addNewTag={addNewTag}
        onlyViewMode={onlyViewMode}
      />

      <TagsList
        productTags={sku?.tags ? sku.tags || [] : jsonBody?.tags}
        handleChangeProductTagsList={handleChangeProductTagsList}
        getId={getId}
        onlyViewMode={onlyViewMode}
      />
    </>
  );
}

export default TagsManager;
