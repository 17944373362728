import cardsLocalize from './Cards';
import catalogacaoLocalize from './Catalogacao';
import CategoriesEn from './Categories';
import common from './Common';
import companiesLocalize from './Companies';
import copilotLocalize from './Copilot';
import fabricantesLocalize from './Fabricantes';
import inventoriesLocalize from './Inventories';
import brandsLocalize from './Marcas';
import periodicidadeLocalize from './Periodicities';
import processEn from './Process';
import productions from './Productions';
import produtos from './Produtos';
import providersLocalize from './Providers';
import registerLocalize from './Register';
import SalesProjectionEn from './SalesProjection';
import skuLocalize from './Sku';
import squaresLocalize from './Squares';
import storageLocationsLocalize from './StorageLocations';
import StoresEn from './Stores';
import UnitsMeasurement from './UnitsMeasurement';
import userLocalize from './User';

export default {
  translations: {
    ...common,
    ...fabricantesLocalize,
    ...brandsLocalize,
    ...catalogacaoLocalize,
    ...companiesLocalize,
    ...copilotLocalize,
    ...productions,
    ...produtos,
    ...processEn,
    ...providersLocalize,
    ...userLocalize,
    ...SalesProjectionEn,
    ...registerLocalize,
    ...periodicidadeLocalize,
    ...storageLocationsLocalize,
    ...skuLocalize,
    ...inventoriesLocalize,
    ...cardsLocalize,
    ...squaresLocalize,
    ...UnitsMeasurement,
    ...StoresEn,
    ...CategoriesEn
  }
};
