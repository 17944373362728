/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  LinearProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import PaperComponent from 'components/PaperComponent';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import getOptions from 'services/getOptions';
import * as ProductsActions from 'store/actions/products';
import Environment from 'utils/environments';

import {
  IconsContainer,
  InfoBox,
  MainContainer,
  ProductInfosContainer
} from './styles';

import RelatedCards from '../RelatedCards';

const environments = Environment(process.env.REACT_APP_ENV);

function UnifyCards({
  handleClosePopover,
  unifyCardsObj,
  setUnifyCardsObj,
  handleUnifyCards,
  getRedirectUrl,
  validationLength,
  isLoading,
  cardAssociation,
  setCardAssociation,
  handleToCardEditPageClick
}) {
  const { id: getId } = useParams();
  const [selectedCard, setSelectedCard] = useState('');
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'cardsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    { id: 2, name: 'modalTechnicalSheet', open: false, fullScreen: false }
  ]);

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'modalTechnicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  return (
    <>
      <Dialog
        open={unifyCardsObj?.open}
        onClose={() => handleClosePopover()}
        aria-labelledby="responsive-dialog-title"
        className="unifyCardsDialog defaultModal fakePopover"
        PaperComponent={(props) => (
          <PaperComponent {...props} handleId="#unifyCardsObj" />
        )}
      >
        {isLoading ? (
          <LinearProgress
            style={{ height: '7px' }}
            id="loading"
            variant="query"
          />
        ) : null}

        <DialogTitle
          className="modalHeader transparent bold textAlignCenter cursorMove"
          id="unifyCardsObj"
        >
          <Typography className="dFlex black justifyCenter bold initialTransform w100">
            Tem certeza que deseja unificar o cadastro destas{' '}
            {unifyCardsObj?.cards?.length} fichas?
          </Typography>
        </DialogTitle>

        <DialogContent className="modalContent bgWhite pb0">
          <div className="optionsList dFlex flexColumn">
            <span className="bold mb12 textAlignCenter">
              Selecione a ficha que será a referência dos dados desta
              unificação:
            </span>

            <MainContainer>
              <IconsContainer>
                <span style={{ color: '#77798B', fontSize: 9, marginRight: 3 }}>
                  FICHA
                </span>
              </IconsContainer>

              {unifyCardsObj?.cards?.map((card) => (
                <div className="optionBox dFlex alignCenter">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="type"
                      name="type"
                      value={unifyCardsObj?.refCard}
                      onChange={() => {
                        setUnifyCardsObj({
                          ...unifyCardsObj,
                          refCard: card?.id
                        });
                      }}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value={card?.id}
                        control={<Radio required size="small" />}
                        label="Ficha:"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Typography
                    style={{ marginTop: 3 }}
                    onClick={(e) => {
                      if (card.type !== 'MENU') {
                        getOptions(
                          `${environments.catalog}/cards/${card?.id}`,
                          'cards',
                          selectedCard,
                          setSelectedCard,
                          getId,
                          null,
                          null,
                          null,
                          true
                        );
                      } else {
                        getRedirectUrl(
                          card.menu.length ? card.menu[0].id : null
                        )

                        handleToCardEditPageClick(e, card)
                      }
                    }}
                    className="productInfos dFlex mb0 cursorPointer lightBlue p0 fontSizeDefault"
                  >
                    {validationLength(card?.description, 30)}
                  </Typography>

                  <ProductInfosContainer>
                    <InfoBox
                      onClick={() => {
                        setModalSettings(
                          modalSettings.map((modal) =>
                            modal.name === 'cardsAssociation'
                              ? { ...modal, open: true }
                              : modal
                          )
                        );
                        setCardAssociation(
                          card.product.length &&
                            card.product[0].items.filter((el) => el.cardsOrigin)
                            ? card.product[0].items.filter(
                                (el) => el.cardsOrigin
                              )
                            : []
                        );
                      }}
                    >
                      <span
                        className="fontSizeDefault"
                        style={{ color: '#1890ff' }}
                      >
                        {card.product.length &&
                        card.product[0].items.filter((el) => el.cardsOrigin)
                          ? card.product[0].items.filter(
                              (item) => item.cardsOrigin
                            ).length
                          : 0}
                      </span>
                    </InfoBox>
                  </ProductInfosContainer>
                </div>
              ))}
            </MainContainer>
          </div>

          <Typography
            className="dFlex flexColumn uppercase textAlignCenter mt20"
            style={{ color: '#F53D4C' }}
          >
            <span className="bold">Atenção!</span>

            <span>Esta ação alterará também a estrutura de catálogo dos</span>

            <span>restaurantes relacionados.</span>

            <span>Esta ação não poderá ser desfeita.</span>
          </Typography>
        </DialogContent>

        <div className="dFlex pt12 pb12 pl16 pr16">
          <Grid item xs={6} className="dFlex justifyEnd mr20">
            <Button
              design="outlined"
              className="defaultButton backButton"
              onClick={() => {
                handleClosePopover();
              }}
              label="Cancelar"
            />
          </Grid>

          <Grid item xs={6} className="dFlex justifyStart">
            <Button
              className="defaultButton submitButton"
              disabled={
                !unifyCardsObj?.refCard ||
                isLoading
              }
              autoFocus
              design="contained"
              onClick={() => {
                handleUnifyCards();
              }}
              label={isLoading ? 'Processando...' : 'Unificar'}
            />
          </Grid>
        </div>
      </Dialog>

      {modalSettings.find((modal) => modal.name === 'cardsAssociation').open &&
          <RelatedCards
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            setCardAssociation={setCardAssociation}
            cardAssociation={cardAssociation}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            getId={getId}
          />
        }

      {selectedCard && (
        <TechnicalSheetDialog
          maxWidth={null}
          open={
            modalSettings.find((modal) => modal.name === 'modalTechnicalSheet')
              .open
          }
          handleClose={() =>
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'modalTechnicalSheet'
                  ? { ...modal, open: false }
                  : modal
              )
            )
          }
          cardDataTable={cardDataTable}
          cardTransformation={cardTransformation}
          selectedCard={selectedCard}
          ucPrimarySelectedId={ucPrimarySelectedId}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnifyCards);
