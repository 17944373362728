/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import Draggable from 'react-draggable';

import DefaultImage from 'images/defaultAndEmpties/defaultImage.svg';
import NotCheckedIcon from 'images/icons/empties/notChecked.svg';
import SearchHeader from 'images/icons/searches/searchBlueBg.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import CheckedIcon from 'images/icons/signals/radioChecks/checked.svg';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

import { StyledTypography } from './styles';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-google-image-searcher"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function GoogleImageSearcher({
  openedByGeneralSuggestion,
  modalSettings,
  setModalSettings,
  multipleSkuData,
  setMultipleSkuData,
  jsonBody,
  setJsonBody,
  providers,
  defaultValue,
  setImgSugg,
  setSuggestionsSelected,
  suggestionsSelected,
  showImageOptions = true,
  radioOption = { option: 'description', value: null }
}) {
  const currentDialogSettings = openedByGeneralSuggestion
    ? 'generalSuggestions'
    : 'searchImage';
  const currentSkuIndex = modalSettings.find(
    (modal) => modal.name === currentDialogSettings
  )?.currentSkuIndex;
  const environments = Environment(process.env.REACT_APP_ENV);
  const isMultipleSku = currentSkuIndex || currentSkuIndex === 0;
  const providerOptions = isMultipleSku
    ? multipleSkuData
      .find((sku, i) => currentSkuIndex === i)
      ?.providers?.map((provider) => provider.descriptionSku)
    : providers?.map((provider) => provider.descriptionSku);
  const [searchText, setSearchText] = useState(
    radioOption.option === 'ean' && radioOption.value
      ? radioOption.value
      : defaultValue?.description || defaultValue || ''
  );
  const [searchOption, setSearchOption] = useState([]);
  const [imageOptions, setImageOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const addDefaultImage = (event) => {
    event.target.src = DefaultImage;
  };

  const handleClose = () => {
    setMessage({ ...message, description: '', status: '' });
    setLoading(false);
    setSearchText('');
    setImageOptions([]);
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'searchImage' ? { ...modal, open: false } : modal
      )
    );
  };

  const handleSearchImage = (value, isRadio = false) => {
    const valueParam = isRadio ? value : value || searchText;

    setLoading(true);
    setImageOptions([]);

    axios
      .get(`${environments.catalog}/skus/platform/images?search=${valueParam}`)
      .then((response) => {
        setImageOptions(response?.data?.content?.links);
        setLoading(false);
      })
      .catch((error) => {
        setImageOptions([]);
        setLoading(false);
        console.log(error);
      });
  };

  const handleSelectImage = (value) => {
    // Quando recebermos "version v2" indica que caímos em outro layout de imagens do google
    // No layout v2, não conseguimos acessar a URL original da imagem, assim como acontecia na v1. Nessa versão, conseguimos pegar o base64 da imagem direto
    // Apesar disso, o layout v1 ainda é usado em algumas pesquisas, o google alterna aleatoriamente entre v1 e v2
    // Quando recebermos o layout v2 então, não mais chamaremos o endpoint que converte uma URL em imagem base64, pois o conteúdo do base64 já vem direto na primeira requisição

    if (value?.version === "v2") {
      if (isMultipleSku) {
        setMultipleSkuData(
          multipleSkuData.map((sku, i) => {
            if (currentSkuIndex === i) {
              return {
                ...sku,
                images: {
                  ...sku.images,
                  baseImage: value.content,
                  name: value.title
                }
              }
            }

            return sku
          })
        )

        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'imageCropper'
              ? {
                ...modal,
                open: true,
                currentSkuIndex,
                openedByGeneralSuggestion
              }
              : modal
          )
        );
      } else {
        setJsonBody({
          ...jsonBody,
          sku: {
            ...jsonBody.sku,
            images: {
              ...jsonBody.sku?.images,
              baseImage: value.content,
              name: value.title
            }
          }
        });

        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'imageCropper'
              ? { ...modal, open: true, openedByGeneralSuggestion }
              : modal
          )
        );
      }
    } else {
      axios
        .get(`${environments.catalog}/skus/platform/base64?url=${value.url}`)
        .then((response) => {
          if (isMultipleSku) {
            setMultipleSkuData(
              multipleSkuData.map((sku, i) => {
                if (currentSkuIndex === i) {
                  return {
                    ...sku,
                    images: {
                      ...sku.images,
                      baseImage: response?.data?.content,
                      name: value.title
                    }
                  };
                }

                return sku;
              })
            );

            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'imageCropper'
                  ? {
                    ...modal,
                    open: true,
                    currentSkuIndex,
                    openedByGeneralSuggestion
                  }
                  : modal
              )
            );
          } else {
            setJsonBody({
              ...jsonBody,
              sku: {
                ...jsonBody.sku,
                images: {
                  ...jsonBody.sku?.images,
                  baseImage: response?.data?.content,
                  name: value.title
                }
              }
            });

            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'imageCropper'
                  ? { ...modal, open: true, openedByGeneralSuggestion }
                  : modal
              )
            );
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setMessage({
            ...message,
            description: 'Imagem não autorizada.',
            status: 'error'
          });
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (!openedByGeneralSuggestion) {
      handleSearchImage();
    }
  }, []);

  useEffect(() => {
    if (openedByGeneralSuggestion) {
      const text =
        radioOption.option === 'ean' && radioOption.value
          ? radioOption.value
          : defaultValue?.description || defaultValue || '';

      setSearchText(text);
      handleSearchImage(text, true);
    }
  }, [radioOption]);

  const renderMainContent = () => (
    <section
      className="googleSearchImageDialog flexColumn"
      style={{ width: openedByGeneralSuggestion && '40%' }}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter"
        style={{
          cursor: openedByGeneralSuggestion ? 'auto' : 'move'
        }}
        id="draggable-dialog-google-image-searcher"
        fullWidth
      >
        <Typography style={{ fontSize: openedByGeneralSuggestion ? 10 : 12 }}>
          PESQUISA DE IMAGEM
        </Typography>

        <div className="dFlex justifyBetween">
          <a
            style={{ fontSize: openedByGeneralSuggestion ? 10 : 12 }}
            target={searchText ? '_blank' : '_self'}
            href={
              searchText
                ? `https://www.google.com/search?q=${searchText}&tbm=isch`
                : '#'
            }
            rel="noreferrer"
          >
            Abrir pesquisa em nova aba
          </a>

          {!openedByGeneralSuggestion && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'searchImage'
                      ? { ...modal, open: false }
                      : modal
                  )
                );
                setSearchText('');
                setImageOptions([]);
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          )}
        </div>
      </DialogTitle>

      <DialogContent className="modalContent bgWhite dFlex flexColumn">
        <div
          className={`dFlex alignCenter ${providerOptions?.length ? 'justifyBetween' : 'flex-start'
            }`}
        >
          <div className="searchBox dFlex">
            <img
              onClick={() => handleSearchImage()}
              src={SearchHeader}
              alt="SearchHeader"
            />

            <TextField
              name="googleSearcher"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearchImage();
                }
              }}
              fullWidth
              variant="outlined"
              size="small"
              className="textFieldSmall"
              placeholder="Pesquisa"
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>

          {[
            ...new Set(
              [
                defaultValue?.description || defaultValue,
                providerOptions
              ].flat()
            )
          ]?.filter((item) => item)?.length > 1 &&
            showImageOptions && (
              <Autocomplete
                options={
                  [
                    ...new Set(
                      [
                        defaultValue?.description || defaultValue,
                        providerOptions
                      ].flat()
                    )
                  ]?.filter((item) => item) || []
                }
                getOptionLabel={(option) => option}
                getOptionDisabled={(option) => option === searchText}
                className="autocompleteSmall"
                style={{
                  marginBottom: 11,
                  width: openedByGeneralSuggestion ? '45%' : '35%'
                }}
                value={searchOption || ''}
                size="small"
                onChange={(event, value) => {
                  if (value) {
                    setSearchOption(value);
                    setSearchText(value);
                    handleSearchImage(value);
                  } else {
                    setSearchOption('');
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={params.id}
                    type="text"
                    name="providerDescription"
                    label="Descrição do fornecedor"
                    placeholder="Selecione..."
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => {
                  const selected =
                    (defaultValue?.description || defaultValue) === params &&
                    defaultValue.isDefaultDescription;

                  return (
                    <StyledTypography
                      noWrap
                      selected={selected}
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params}
                    </StyledTypography>
                  );
                }}
              />
            )}
        </div>

        <div className="imageOptionsContainer">
          {imageOptions.length ? (
            imageOptions.map((image, index) => (
              <Tooltip
                arrow
                placement="right"
                title={
                  <div
                    className={`cropDataSmallBox skuImgPreview mr0 ${image?.url && 'bgWhite'
                      } largeSize`}
                  >
                    <img
                      onError={(e) => addDefaultImage(e)}
                      src={image?.version === "v1" ? image?.url : image?.content}
                      alt="Tooltip"
                    />
                  </div>
                }
              >
                <div className="dFlex flexColumn imageOptionBox">
                  <div
                    className="imageBox"
                    onClick={() => {
                      handleSelectImage(image);

                      if (openedByGeneralSuggestion) {
                        setSuggestionsSelected({
                          ...suggestionsSelected,
                          img: ''
                        });
                        setImgSugg(index);
                        setTimeout(() => {
                          setImageOptions(
                            imageOptions.map((img, imgIndex) => {
                              if (index === imgIndex) {
                                return {
                                  ...img,
                                  selected: true
                                };
                              }
                              return {
                                ...img,
                                selected: false
                              };
                            })
                          );
                        }, 900);
                      }
                    }}
                  >
                    <img
                      onError={(e) => addDefaultImage(e)}
                      src={image?.version === "v1" ? image?.url : image?.content}
                      alt="Title"
                    />
                  </div>

                  <div className="dFlex alignStart">
                    {openedByGeneralSuggestion ? (
                      <img
                        style={{ margin: 3 }}
                        src={
                          image?.selected || suggestionsSelected?.img === index
                            ? CheckedIcon
                            : NotCheckedIcon
                        }
                        alt="Selected"
                      />
                    ) : null}

                    <div className="dFlex justifyBetween flexColumn refLinkUrl">
                      <span className="bold">
                        {ValidationLength(image.title || 'Sem descrição', 18)}
                      </span>

                      <a
                        className="mb0"
                        target={image.refUrl ? '_blank' : '_self'}
                        href={image.refUrl ? image.refUrl : '#'}
                        rel="noreferrer"
                      >
                        {ValidationLength(
                          image.refUrl || 'Link não disponível',
                          25
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </Tooltip>
            ))
          ) : (
            <div className="dFlex justifyCenter mb20">
              <Typography>Nenhuma imagem encontrada.</Typography>
            </div>
          )}
        </div>
      </DialogContent>

      {loading && <LinearProgress variant="query" />}

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => setMessage({ ...message, description: '', status: '' })}
      >
        <Alert
          onClose={() =>
            setMessage({ ...message, description: '', status: '' })
          }
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </section>
  );

  return openedByGeneralSuggestion ? (
    renderMainContent()
  ) : (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'searchImage').fullScreen
      }
      open={modalSettings.find((modal) => modal.name === 'searchImage').open}
      onClose={() => {
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'searchImage' ? { ...modal, open: false } : modal
          )
        );
        setSearchText('message', message);
        setImageOptions([]);
      }}
      className="defaultModal googleSearchImageDialog"
      PaperComponent={PaperComponent}
    >
      {renderMainContent()}
    </Dialog>
  );
}

export default GoogleImageSearcher;
