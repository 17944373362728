/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import {
  Tooltip
} from '@material-ui/core';
import { connect } from 'react-redux';

function CmvTheoreticalInfos({ cmvTheoreticalInfos }) {
  const realCmv = cmvTheoreticalInfos?.realCmv || 0
  const percentCmv = cmvTheoreticalInfos?.percentCmv || 0
  const goalCmv = cmvTheoreticalInfos?.goalCmv || 0
  const isPositiveTarget = percentCmv >= goalCmv
  
  return (
    <div className="cmvTheoreticalCard">
      <p className="cardTitle">CMV TEÓRICO</p>

      <div className="dFlex alignCenter justifyCenter pRelative w100">
        <span className="bold">
          R$ {" "}
          {realCmv?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </span>

        <Tooltip
          title={
            <div className="dFlex flexColumn" style={{ padding: '4px 2px' }}>
              <p className="mb0 bold">
                {!goalCmv
                ? 'Sem meta cadastrada'
                :
                  <>
                    {isPositiveTarget ? 'Acima' : 'Abaixo'} da meta <br/>
                    META: {" "}
                    {goalCmv?.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}%
                  </>
                }
              </p>
            </div>
          }
          aria-label="add"
        >
          <div className={`percentChip ${!goalCmv ? 'disabledTheoretical' : !isPositiveTarget ? 'positive' : 'negative'}`}>
            {percentCmv?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}%
          </div>
        </Tooltip>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(CmvTheoreticalInfos);
