import styled, { css } from 'styled-components'

import banner from 'images/backgrounds/homeBackground.png'

export const Filter = styled.div`
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
  border-radius: 4px;
  margin-left: 5%;
`

export const Header = styled.div`
  height: 120px;
  background-color: rgba(25, 42, 129);
  background-image: url(${banner});
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
  margin-left: -10px;
  margin-right: -10px;

  .btnGrid {
    button {
      background: #5062F0;
      box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
      border-radius: 8px;
      transition: background 0.2s;

      &:hover {
        background: #242b63;
      }
     
      width: 32px;
      height: 32px;
      border: none;
      cursor: pointer;
    }
  }


  .initial {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
    
    strong {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      margin-right: 10px;
    }

    .card {
      margin-top: 5px;
      background: transparent;
      margin-left: 1%;
      border: none;
      display: flex;
      flex-direction: column;
      padding: 10px;

      span {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #FFFFFF;
      }
     

      div {
        margin-top: 5px;
        background: white;
        display: flex;
        flex-direction: column;
        min-width: 100px;
        width: 100%;
        height: 50px;
        justify-content: center;
        align-items: flex-start;
        box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
        border-radius: 4px;

        
        span {
          color: #77798B;
          font-size: 12px;
          font-weight: normal;
          line-height: 16px;
          margin-left: 5%;
          margin-right: 1%;
          margin-top: 3%;
        }

        small {
          font-family: Lato;
          margin-bottom: 3%;
          margin-left: 5%;
          margin-right: 1%;
          margin-top: 2%;
          font-style: normal;
          font-weight: bold;
          font-size: 9px;
          line-height: 12px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          font-feature-settings: 'tnum' on, 'lnum' on;
          color: #313347;
        }
      }
    }
  }
`

export const ActionsButtonsWrap = styled.div`
    display: flex;
    justify-content: space-around;
    margin-block-start: auto;
    margin-right: 15px;
    margin-bottom: 7px;
`;

export const FilterButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 32px;
  margin-right: 12px;
`;

export const FilterIcon = styled.img`
  cursor: pointer;
`;

export const TableHeaderTitle = styled.span`
  padding: 8px !important;
`;

export const LineTable = styled.span`
  position: relative;
  background-color: ${({backgroundColor}) => backgroundColor ? '#d2d7f1 !important' : 'transparent!important'};

  ${({backgroundColor}) => backgroundColor && 
    css`
      &:before{
        content: ' ';
        background-color: #d2d7f1;
        width: 45px;
        height: 27px;
        top: 0px;
        left: -43px;
        position: absolute;
        z-index: -10;
        }
      }
    `}
`; 
