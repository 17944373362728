import styled, { css } from 'styled-components';

export const CardWrapper = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(1, 3, 17, 0.45);
    padding: 0 12px 12px 12px;
    ${({ padding }) => padding && css`
        padding: ${padding};
    `}
    ${({ marginTop }) => marginTop && css`
        margin-top: ${marginTop};
    `}
`;

export const CardItem = styled.div`
    padding-top: 12px;
    position: relative;

    div {
        display: flex;
        justify-content: space-between;

        img {
            position: absolute;
            right: 0;
            margin-bottom: 7px;
            bottom: -8px;
        }
    }
    
`;

export const CardItemLavel = styled.p`
    text-transform: uppercase;
    color: #454A67;
    font-weight: bold;
    font-size: 12px;
`;

export const CardProductItem = styled.div`
    padding-top: 0;
    position: unset;
    color: #010311;
    font-weight: normal;
    font-size: 12px;

    div {
        display: flex;
        justify-content: space-between;
        img {
            position: unset;
        }
    }
`;
