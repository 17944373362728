import Axios from 'axios';

import { queries } from './queries';

const userLocal = JSON.parse(localStorage.getItem('companies'))

const config = {
  headers: {
    User: `${userLocal?.user}`
  }
};

const makePost = (endpoint, data, handleResponsePost, appendEndpoint) => {
  const query = `${queries[endpoint]}${appendEndpoint || ''}`;

  Axios
    .post(query, data, config)
    .then((response) => {
      handleResponsePost(true, response);
    })
    .catch((error) => {
      handleResponsePost(false, error.response);
    });
};

export default makePost;
