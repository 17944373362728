/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DeleteOutline } from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import ItemsPerPage from 'components/ItemsPerPage';
import Localization from 'components/Localization';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as ProducersActions from 'store/actions/producers';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import ProducerForm from './ProducerForm';

function Fabricantes({ userInfo, setProducers, createProducers }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [callSettings, setCallSettings] = useState({
    getId: userInfo.companiesActive.id,
    page: 1,
    totalPages: 0,
    size: 1000,
    mainContent: [],
    numberOfElements: 0,
    loading: true,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    mainContent,
    numberOfElements,
    totalElements
  } = callSettings;
  const [producerModal, setProducerModal] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [deleteError, handleDeleteError] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  function handleDelete() {
    const baseUrl = `${environments.catalog}/producers`;
    const data = mainContent;
    const index = data.indexOf(deleteData);
    mainContent.splice(index, 1);

    axios
      .delete(`${baseUrl}/${deleteData.id}`, config)
      .then(() => {
        handleDialogClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const toggleProducerModal = () => {
    setProducerModal(!producerModal);

    if (producerModal === false) {
      setUpdateList(false);
    }
  };

  const addProducer = () => (
    <Tooltip
      title={<Localization text="registerProducer_plural" />}
      aria-label="add"
    >
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          toggleProducerModal();
          createProducers();
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  const { t } = useTranslation('translations');

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useEffect(() => {
    (async () => {
      const response = await axiosInstanceCatalog.get(
        `${environments.catalog}/producers?page=${page}&size=${size}&originId=${getId}`
      );
      const producers = await response.data;
      setCallSettings({
        ...callSettings,
        totalPages: producers.totalPages,
        mainContent: producers.content || [],
        numberOfElements: producers.numberOfElements,
        loading: false,
        totalElements: producers.totalElements
      });
    })();
  }, [getId, page, updateList]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: userInfo.companiesActive.id });
  }, [userInfo]);

  return (
    <Layout>
      <TitleHead title={t('producers')} />

      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title={<Localization text={t('producers')} />}
          additionalbutton={addProducer}
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="98%" className="subtitle">
                <Localization text="description" />
              </th>

              <th width="2%" className="subtitle">
                &nbsp;
              </th>
            </tr>
          </thead>

          <tbody>
            {mainContent.map((producer) => (
              <>
                <tr>
                  <td className="description p12">
                    <span
                      className="cursorPointer lightBlue"
                      onClick={() => {
                        setProducers(producer);
                        toggleProducerModal();
                      }}
                    >
                      {producer.description}
                    </span>
                  </td>

                  <td>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        handleDialogClickOpen()
                        setDeleteData(producer)
                      }}
                      style={{ padding: 0, outline: 0, marginRight: '7px' }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </td>
                </tr>

                <tr>
                  <td colSpan="2">
                    <hr />
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(event, page) =>
              setCallSettings({ ...callSettings, page })
            }
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>

        <Modal
          className="defaultModal modalRegisterContainer"
          open={producerModal}
          onClose={toggleProducerModal}
          aria-labelledby="Producer Modal"
        >
          <ProducerForm
            isModal
            openModal={producerModal}
            redirectPath={null}
            onClose={toggleProducerModal}
            setUpdateList={setUpdateList}
          />
        </Modal>

        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            className="modalHeader navyBlue"
            style={{ fontWeight: 'bold', cursor: 'move', fontFamily: 'Lato' }}
            id="draggable-dialog-title"
          >
            <Typography>
              <Localization text="deleteTitle" />
            </Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography>
              <Localization text="deleteMessageQuestion" />
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={handleDialogClose}
              label={<Localization text="cancel" />}
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              autoFocus
              design="contained"
              label={<Localization text="confirmed" />}
              onClick={() => handleDelete()}
              color="primary"
            />
          </DialogActions>
        </Dialog>

        <Snackbar
          open={deleteSuccess}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteSuccess)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteSuccess)}
            severity="success"
          >
            <Localization text="deleteMessageQuestion" />
          </Alert>
        </Snackbar>

        <Snackbar
          open={deleteError}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteError)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteError)}
            severity="error"
          >
            <Localization text="saveInfoError" />
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProducersActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Fabricantes);
