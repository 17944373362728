import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import './style.scss';
import numberToReal from 'utils/numberToReal';

import CustomFilter from './CustomFilter';
import InvoiceTable from './InvoiceTable';

export default function DialogInvoices({
  openState,
  onCloseProductDialog,
  data,
  handleOpenSingleModal,
  countSales,
  salesTotalValues,
  restaurantId,
  rangeDate,
  setRangeDate,
  productFilter,
  setProductFilter
}) {
  return (
    <Dialog
      open={openState}
      onClose={() => onCloseProductDialog()}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal mH80 invoiceDialog"
      maxWidth="lg"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <div className="dFlex justifyBetween alignCenter w100">
          <Typography>NOTAS FISCAIS DE VENDA</Typography>
        </div>

        <IconButton
          className="ml10"
          edge="start"
          color="inherit"
          onClick={() => onCloseProductDialog()}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent
        className="modalContent onlyTable pb0"
        style={{ padding: '0px 15px' }}
      >
        <CustomFilter
          restaurantId={restaurantId}
          rangeDate={rangeDate}
          setRangeDate={setRangeDate}
          productFilter={productFilter}
          setProductFilter={setProductFilter}
        />

        <div className="totalBar">
          <div>
            <span>TOTAL:</span>
          </div>

          <div>
            <span className="notes">{countSales} notas</span>
            <span>R$ {numberToReal(salesTotalValues, 2)}</span>
          </div>
        </div>
        
        <InvoiceTable data={data} openInvoice={handleOpenSingleModal} />
      </DialogContent>
    </Dialog>
  );
}
