import React from 'react';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import { Container, GlobalStyle } from 'styles/general';

function Error() {
  return (
    <Layout>
      <GlobalStyle />

      <TitleHead title="Erro" />

      <Container>
        <PageTitleAndFilter title="Página não encontrada" />

        <h1 className="fontSizeDefault bold">Rota não encontrada</h1>
      </Container>
    </Layout>
  );
}

export default Error;
