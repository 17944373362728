/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import graphW from 'images/icons/arrows/graphW.svg';
import wrenchW from 'images/icons/configsAndSetups/wrenchW.svg';
import ValidationLength from 'utils/utils';

import { Header } from './styles';

function HomeHeader({ userInfo }) {
  const [data, setData] = useState('');

  useEffect(() => {
    if (userInfo.companiesActive.id === 2) {
      setData('5 - PRODUÇÃO');
    } else if (userInfo.companiesActive.id === 1) {
      setData('3 - ESTOQUE');
    } else if (userInfo.companiesActive.id === 4) {
      setData('2 - CARDÁPIO');
    }
  }, [userInfo.companiesActive.id]);

  return (
    <Header>
      <div className="HeaderData">
        <div className="dFlex flexColumn">
          <div className="ColumnGrid">
            <small>Olá, bem vindo de volta</small>
            <strong>{ValidationLength(userInfo.user, 15)}</strong>
          </div>

          <div className="pageTitle">
            <p>
              Acompanhe sempre por aqui as <strong>Missões</strong> sugeridas
              que irão auxiliar a melhorar o <strong>Resultado</strong> do seu
              Restaurante
            </p>
          </div>
        </div>

        <div className="sideGrid">
          <div className="ProgressBarBox">
            <div className="ProgressBar">
              <div className="section1">
                <img src={graphW} alt="graph" />
                <strong>Potencial Diário</strong>
              </div>

              <div className="section2">
                <div
                  className="progressPercentage"
                  style={{
                    width:
                      userInfo.companiesActive.id === 2
                        ? '10%'
                        : userInfo.companiesActive.id === 1
                        ? '20%'
                        : '30%'
                  }}
                >
                  {userInfo.companiesActive.id !== 1 &&
                    userInfo.companiesActive.id !== 2 && (
                      <strong>
                        {userInfo.companiesActive.id === 2
                          ? '10%'
                          : userInfo.companiesActive.id === 1
                          ? '20%'
                          : '30%'}
                      </strong>
                    )}
                </div>

                {(userInfo.companiesActive.id === 2 ||
                  userInfo.companiesActive.id === 1) && (
                  <strong className="percentageColored">
                    {userInfo.companiesActive.id === 2
                      ? '10%'
                      : userInfo.companiesActive.id === 1
                      ? '20%'
                      : '30%'}
                  </strong>
                )}
              </div>
            </div>

            <div className="ProgressBar">
              <div className="section3">
                <img src={wrenchW} alt="wrenchW" />
                <strong>Potencial Bloom</strong>
              </div>

              <div className="section4">
                <div
                  className="progressPercentage"
                  style={{
                    width:
                      userInfo.companiesActive.id === 2
                        ? '100%'
                        : userInfo.companiesActive.id === 1
                        ? '60%'
                        : '20%'
                  }}
                >
                  {(userInfo.companiesActive.id === 2 ||
                    userInfo.companiesActive.id === 1) && (
                    <strong>
                      {userInfo.companiesActive.id === 2
                        ? '100%'
                        : userInfo.companiesActive.id === 1
                        ? '60%'
                        : '20%'}
                    </strong>
                  )}
                </div>

                {userInfo.companiesActive.id !== 2 &&
                  userInfo.companiesActive.id !== 1 && (
                    <strong className="percentageColored">
                      {userInfo.companiesActive.id === 2
                        ? '100%'
                        : userInfo.companiesActive.id === 1
                        ? '60%'
                        : '20%'}
                    </strong>
                  )}
              </div>
            </div>
          </div>

          <div className="btnGrid">
            <span style={{ marginTop: '0px' }}>Veja cases do Bloom</span>
            <span>Ajuda</span>
          </div>
        </div>
      </div>
    </Header>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(HomeHeader);
