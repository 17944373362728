import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { format } from 'date-fns';

import ItemSelect from 'components/BloomTable/components/ItemSelect';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makeGet from 'services/useFetch/makeGet';
import numberToReal from 'utils/numberToReal';
import { shortenString } from 'utils/shortenString';

import { InflationHeader, LastOrder } from './styles';


function InflationMobileDialog({
  openInflationMobileDialog,
  setOpenInflationMobileDialog,
  restaurantsIds, 
  productsIds, 
}) {
  const [description, setDescription] = useState()
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [filters, setFilters] = useState([
    {
      param: 'originId',
      value: restaurantsIds,
      urlParam: `originId=${restaurantsIds}`
    },
    {
      param: 'productId',
      value: productsIds,
      urlParam: `productId=${productsIds}`
    },
  ]);

  const handleItems = (response) =>  {
    if (response?.orders?.length == 0 || response?.orders == undefined) {
      setLoading(false)
      setIsEmpty(true)
      return 
    }
    setLoading(false)
    setDescription(response?.description)
    setOrders(response?.orders)
  }

  useEffect(() => {
    setLoading(true)
    makeGet('modalInflation', filters, handleItems, null);
  }, [])

  const getInflationContent = (inflation) => {
    if (inflation > 0) {
      return {
        content: `+ ${numberToReal(inflation, 2)}%`,
        backgroundColor: '#F7DFDF',
        color: '#E22121'
      }
    }

    if (inflation < 0) {
      const realInflation = numberToReal(inflation, 2)
      return {
        content: `${realInflation === '-0,00' ? '0,00' : realInflation}%`,
        backgroundColor: '#DFF7E8',
        color: '#3DCC6E'
      }
    }

    if (inflation === '-') {
      return {
        content: '-'
      }
    }

    return {
      content: '0,00%',
      backgroundColor: '#DFF7E8',
      color: '#3DCC6E'
    }
  }
  
  
  return (
    <Dialog
      className="defaultModal mobileModal"
      fullWidth
      maxWidth="sm"
      open={openInflationMobileDialog}
      aria-labelledby="draggable-dialog-title"
     >
     <DialogTitle className="modalHeader navyBlue" style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'move', fontFamily: 'Lato' }} id="draggable-dialog-title">
       <Typography>
          INFLAÇÃO % - {shortenString(description, 15)}
       </Typography>

       <IconButton edge="start" color="inherit"
       onClick={() => setOpenInflationMobileDialog(false)}
       >
        <img src={CloseIconNavy} style={{ width: "8px" }} alt="close" />
       </IconButton>
     </DialogTitle>
     {loading && <div sytle={{ margin: "10px" }}><LinearProgress style={{ height: "7px" }} variant="query" /></div>}
     <DialogContent className="modalContent p0" style={{backgroundColor: "white", height: orders?.length >= 3 ? "382px" : "auto"}} >
      {
         isEmpty ? 
         <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
           <Typography >
             Inflação não encontrada
           </Typography>  
         </div>
         :
         orders?.map((order => (
          <>
          <InflationHeader>
            <Typography>
              {order?.historicalDate}
            </Typography>
            <Typography>
              {format(new Date(order?.date).valueOf() + 10800000, 'dd/MM/yyyy')}
            </Typography>
          </InflationHeader>
          <LastOrder>
            <div>
              <Typography>
                FORNECEDOR
              </Typography>
              <Typography>
                {order?.provider}
              </Typography>
            </div>
            <div className="lastOrderInfo"  >
            <div>
              <Typography>
                VALOR R$
              </Typography>
              <Typography>
                {numberToReal(order?.cost, 2)}
              </Typography>
            </div>
            <div style={{margin: "0 40px"}} >
              <Typography>
                INFLAÇÃO
              </Typography>
              <ItemSelect
                itemName={getInflationContent(order?.inflation).content}
                buttonLinkColor={getInflationContent(order?.inflation)}
                type='richNumber'
              />
            </div>
            <div>
              <Typography>
                COT. DÓLAR R$
              </Typography>
              <Typography>
                {numberToReal(order?.currencyValue, 2)}
              </Typography>
            </div>
            </div>
          </LastOrder>
          </>
        )))
      }
      
     </DialogContent>
     </Dialog>
  )
}

export default InflationMobileDialog;
