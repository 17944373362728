import * as React from 'react';

import moment from 'moment';
import ValidationLength from 'utils/utils';

export class ComponentToPrint extends React.PureComponent {
  render() {
    const { item, title } = this.props;
    const hasFinishedDate = item?.actions?.find(
      (el) => el.type === 'FINISH'
    )?.date;
    const manufacturedDate = hasFinishedDate
      ? new Date(hasFinishedDate)
      : new Date();

    return (
      <div className="labelFileToPrint">
        <style type="text/css" media="print">
          {'\
    @page { size: portrait; }\
  '}
        </style>

        <div className="testClass">
          {/* <div className="dFlex justifyCenter labelFileTitle">
            <p>{title}</p>
          </div> */}

          <div className="dFlex item">
            <p className="mr5">Produto:</p>
            <p>{ValidationLength(item?.description, 35) || '-'}</p>
          </div>

          <div className="dFlex item">
            <p className="mr5">Manipulação:</p>
            <p>{moment(manufacturedDate).format('DD/MM/YYYY - HH:mm')}</p>
          </div>

          <div className="dFlex item">
            <p className="mr5">Rendimento:</p>
            <p>
              {item?.manufactured?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })} {" "}
              {item?.un?.abbreviation}
            </p>
          </div>

          <div className="dFlex item">
            <p className="mr5">Validade:</p>
            <p>
              {moment(manufacturedDate)
                .add(item?.quantityValidDays || 0, 'days')
                .format('DD/MM/YYYY')}
            </p>
          </div>

          <div className="dFlex item">
            <p className="mr5">Responsável:</p>
            <p>{item?.user}</p>
          </div>

          <div className="dFlex alignCenter optionsBox">
            <div className="dFlex alignCenter">
              <input
                type="checkbox"
                id="frozen"
                name="color"
                value="frozen"
                checked={false}
              />

              <span className="ml3">Congelado</span>
            </div>

            <div className="dFlex alignCenter ml8">
              <input
                type="checkbox"
                id="cold"
                name="color"
                value="cold"
                checked={false}
              />

              <span className="ml3">Resfriado</span>
            </div>

            <div className="dFlex alignCenter ml8">
              <input
                type="checkbox"
                id="roomTemperature"
                name="color"
                value="roomTemperature"
                checked={false}
              />

              <span className="ml3">Temp. ambiente</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => (
  <ComponentToPrint ref={ref} text={props.text} item={props.item} />
));
