import styled from 'styled-components';

export const InputManufactureWrapper = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InputManufactureInput = styled.input`
  width: ${({ width }) => width || '80px'};
  height: ${({ height }) => height || '20px'};
  text-align: right;
  font-family: Lato;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  font-size: ${({ fontSize }) => fontSize || '20px'};
  line-height: 20px;
  border: none;
  border-bottom: solid 1px #333541;
  margin: auto;
  outline: none;
  background: transparent;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export const InputManufactureInputContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
`;
