const catalogacaoLocalize = {
    "need to send restaurantId to complete request": "need to send restaurantId to complete request",
    "invalid cards type": "invalid cards type",
    "there are mandatory keys missing from request": "there are mandatory keys missing from request",
    "already exists a card with this description in one of the restaurants sent": "already exists a card with this description in one of the restaurants sent",
    "already exists a card with this description": "already exists a card with this description",
    "already exists a product with this description": "already exists a product with this description",
    "need to send a origin ID": "need to send a origin ID",
    "not found results according to these parameters": "not found results according to these parameters",
    "there are mandatory keys missing from request": "there are mandatory keys missing from request",
    "groups in body can't be empty": "groups in body can't be empty",
    "not found results according to these parameters": "not found results according to these parameters",
    "need to send id to update": "need to send id to update",
    "there are mandatory keys missing from request": "there are mandatory keys missing from request",
    'not found cards': 'Cards not found'
};

export default catalogacaoLocalize;