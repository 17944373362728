import React, { useState, useEffect, useRef  } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link, useParams, Redirect } from "react-router-dom";
import PullToRefresh from 'react-simple-pull-to-refresh';
import { bindActionCreators } from 'redux';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import useForceUpdate from 'hooks/useForceUptate';
import useOnClickOutside from 'hooks/useOnClickOutside';
import DrillDownBlueArrow from 'images/icons/arrows/drillDownBlueArrow.svg';
import MobileWhiteArrow from 'images/icons/arrows/mobileWhiteArrow.svg';
import { canSetDiffInCatalog, calculateDifference } from 'pages/inventory/diary-count/calcDivergentParams';
import ConfigPackageDialog from 'pages/inventory/diary-count/dialogs/ConfigPackageCount/index';
import calcPercentDifference, { calcDifference, setDifferentCollor, calcDifferenceByCost } from 'pages/inventory/services/calcDifference';
import calcRealStock from "pages/inventory/services/calcRealStock";
import calcTotalCountValue from 'pages/inventory/services/calcTotalCountValue';
import getPercentageDifference, { putDiffInCatalog } from 'pages/inventory/services/getPercentageDifference';
import saveCountItem from "pages/inventory/services/saveCountItem";
import validateProviders from 'pages/inventory/services/validateProviders';
import makeGet from 'services/useFetch/makeGet';
import * as AssortmentActions from 'store/actions/assortment';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import numberToReal from 'utils/numberToReal';
import { shortenString } from 'utils/shortenString';

import { 
    CardItemContainer, 
    ItemTitle, 
    CardTop,
    ItemType,
    CardItemDetails,
    CardItemValueCollapsed,
    CardItemDifference,
    DifferencePercent,
    CardItemStockAndCount,
    ItemStock,
    ItemCounts,
    CardRealAndTheorical,
    CardRealAndTheoricalItem,
    PackagesList,
    PackageItem,
    PackageWrapper,
    Line,
    ArrowDrillDown,
    CollapsedCard,
    CollapseCardContainer,
    OtherStocks,
    OtherStocksButton,
    CollapsedCardLine,
    DiferenceCard,
    BackAndForwardWrapper,
    NextFowardButton,
    BackTocounts,
    DivergentMessageWrapper,
    BackToLastCount
} from './index.style';

import CountInput from '../CountInput';
import CountOrdenationDialog from '../CountOrdenationDialog/index';
import CountDialog from '../CountSearchDialog';
import DivergentMobileDialog from '../DivergentMobileDialog';
import FinishDialog from '../FinishDialog';
import OtherStocksDialog from '../OtherStocksDialog';



function CountPage({ userInfo }) {
    const { id: paramsID, selectItem } = useParams();
    const environments = Environment(process.env.REACT_APP_ENV);
    const [ drillDownOpen, setDrillDownOpen ] = useState(false);
    const [ currentSelectItem, setCurrentSelectItem ] = useState({});
    const [ currentData, setCurrentData ] = useState({});
    const [ previuosCount, setPreviousCount ] = useState(null);
    const [ nextCount, setNextCount ] = useState(null);
    const [ redirectToCounts, setRedirectToCounts ] = useState();
    const [ openDivergentDialog, setOpenDivergentDialog ] = useState(false);
    const [ openOtherStockDialog, setOpenOtherStockDialog ] = useState(false);
    const [ openMobileOrnationCount, setOpenMobileOrnationCount ] = useState(false);
    const [ currentSetUp, setCurrentSetUp ] = useState();
    const [ configMobilePackageDialog, setConfigMobilePackageDialog ] = useState(false);
    const [ configPackageSkuItens, setConfigPackageSkuItens ] = useState([]);
    const [ finishCountButton, setFinishCountButton ] = useState(false);
    const [ openFinishDialog, setOpenFinishDialog ] = useState(false);
    const [ openCountSearchDialog, setOpenCountSearchDialog ] = useState(false);
    const [ percentageDifference, setPercentageDifference ] = useState();
    const [ assortmentDateState, setAssortmentDateState ] = useState(undefined);
    const [closeModalSettings, setCloseModalSettings] = useState();
    const [isLoading, setisLoading] = useState(true);

    const otherStockDialogRef = useRef();
    const finishDialogReference = useRef();
    const openCountSearchDialogReference = useRef();

    // const countOrdenationref = useRef(null);
    const forceUpdate = useForceUpdate();

    useOnClickOutside(otherStockDialogRef, () => setOpenOtherStockDialog(false));
    useOnClickOutside(finishDialogReference, () => setOpenFinishDialog(false));
    // useOnClickOutside(openCountSearchDialogReference, () => setOpenCountSearchDialog(false));

    // useOnClickOutside(countOrdenationref, () => setOpenMobileOrnationCount(false));

    const query = new URLSearchParams(window.location.search);
    const countID = query.get('countId');
    const countDate = query.get('countDate');
    const categoryDescription = query.get('categoryDescription');
    const assortmentDate = query.get('assortmentDate');
    const backCountButton = query.get('backCountButton');
    const uncountedProducts = query.get('uncountedProducts');
    const assortmentType = query.get('assortmentType');

    const isParamsPropsLoading = countID && countDate && assortmentDate;
    
    const openConfigPackageCount = (product) => {
        axios.get(`${environments.catalog}/inventories/counts/${countID}/packages/${product.id}?restaurantId=${paramsID}`)
            .then((response) => {
                setConfigPackageSkuItens(response.data.content);
                setConfigMobilePackageDialog(true);
            })
            .catch((error) => {
                console.log("error =====>", error)
            });
    }

    const handleSwippeButtons = (products, parsedSelectedItem) => {
        if ((products.length - 1) !== parsedSelectedItem) {
            const nextProduct = products[parsedSelectedItem + 1];

            setNextCount({
                url:{
                    pathname: `/inventario/contagem-diaria-mobile/${paramsID}/${parsedSelectedItem + 1}/?countId=${countID}&countDate=${countDate}&categoryDescription=&assortmentDate=${assortmentDate}&selectItem=${parsedSelectedItem + 1}&assortmentType=${assortmentType}`,
                    key: Math.random(),
                    state: {
                        applied: true
                    }
                },
                description: nextProduct.description
             });
        }

        if (parsedSelectedItem === 0) return; 
        const previousProduct = products[parsedSelectedItem - 1];

        setPreviousCount({
            url: {
                pathname: `/inventario/contagem-diaria-mobile/${paramsID}/${parsedSelectedItem - 1}/?countId=${countID}&countDate=${countDate}&categoryDescription=&assortmentDate=${assortmentDate}&selectItem=${parsedSelectedItem - 1}&assortmentType=${assortmentType}`,
                key: Math.random(),
                state: {
                    applied: true
                }
            },
            description: previousProduct.description
        });
    }

    const handleResponseData = (data) => {
        if (data && data?.products) {
            const { products, setup } = data; 

            const parsedSelectedItem = parseFloat(selectItem);

            const selected = selectItem ? products[parsedSelectedItem] : null;

            const { skus = [] } = selected;

            setCurrentData(data);
            setCurrentSetUp(setup);

            if (!selected) return;
            setCurrentSelectItem(selected);

            handleSwippeButtons(products, parsedSelectedItem);
            setisLoading(false);
        }
    };

    const handleRequests = () => {
        setisLoading(true);
        setCurrentData({});
        setCurrentSelectItem({});

        const params = [
            { urlParam: `originId=${paramsID}` },
            { urlParam: `countDate=${countDate}` },
            { urlParam: `categoryDescription=${categoryDescription}` },
            { urlParam: `uncountedProducts=${uncountedProducts}` }
        ];

        if (assortmentType === "ALPHABETICAL") {
            params.push({ urlParam: `assortmentType=${assortmentType}` });
        }

        if (!assortmentType || assortmentType === "PHYSICAL") {
            // Se eu tiver um assortmentType PHYSICAL e uma data válida, vou passar a data normal
            // Se por qualquer motivo eu não tiver a data, vou forçar o tipo da ordenação como alfabética, pra não deixar em loading infinito
            if (assortmentDateState && moment(assortmentDateState).isValid()) {
                params.push({ urlParam: `assortmentDate=${assortmentDateState}` });
            } else {
                params.push({ urlParam: `assortmentType=ALPHABETICAL` })
            }
        }

        makeGet('inventories', params, handleResponseData, countID);
    }

    const updateContent = () => {
        handleRequests();
    }

    const cleanSwipeButton = () => {
        setNextCount(null);
        setPreviousCount(null);
    }

    const handleDrillDown = () => {
        setDrillDownOpen(!drillDownOpen);
    } 

    const handleBackButtonFilter = () => {
        const { setup: { periodicity: { id = null } = {} } = {} } = currentData;
        if (!id) return;
        setRedirectToCounts(`/inventario/contagens/${paramsID}/?selectedPeriodicity=${id}&periodicyDate=${countDate}`);
    }

    const handleDivergentDialog = () => {
        setOpenDivergentDialog(!openDivergentDialog);
        forceUpdate();
    }

    const handleOtherStocksDialog = () => {
        setOpenOtherStockDialog(!openOtherStockDialog);
    }

    const settingsMobile = [
        {
            description: "Pesquisar na Contagem", 
            action: () => {setOpenCountSearchDialog(true)},
            backgroundBlue: true
        },
        {
            description: "Embalagens do produto",
            action: () => {openConfigPackageCount(currentSelectItem)},
            styleType: "link",
            bold: true
        },
        {
            description: "Alterar a ordenação da contagem",
            action: () => { setOpenMobileOrnationCount(true) },
            styleType: "link"
        },
    ];

    const settingsMobileWithoutPackage = [
        {
            description: "Pesquisar na Contagem", 
            action: () => {setOpenCountSearchDialog(true)},
            backgroundBlue: true
        },
        {
            description: "Alterar a ordenação da contagem",
            action: () => { setOpenMobileOrnationCount(true) },
            styleType: "link"
        },
    ];

    const handleFinishCount = () => {
        setOpenFinishDialog(true);
    }

    const handleOnBlurInputCount = (input, dataCount) => {
        const { 
            sku: { 
                quantity: quantitySku, 
                providers = [],
                unitsMeasurements: { abbreviation: skuUnity = '' } = {},
                id: skuId
            } = {}, 
            currentProduct: { 
                consumptionUnitsPrimary: { abbreviation: productUnity = '' } = {},
                additional,
                skus = []
            } = {},
            type
        } = dataCount;
        const [ firstProvider ] = providers; 
        const {quantity: providerQuantity, stock } = firstProvider;

        if (type === "count") {
            currentSelectItem.realStock = calcRealStock({
                quantitySku, 
                providerQuantity,
                countValue: input,
                additional,
                unity: skuUnity,
                productUnity,
                skus,
                skuId,
            });
        }

        if (type === "additionalSKU") {
            currentSelectItem.realStock = calcRealStock({
                quantitySku, 
                providerQuantity,
                countValue: stock,
                additional: input,
                unity: skuUnity,
                productUnity,
                skus,
                skuId,
            });
        }
        
        if (type === "additional") {
            currentSelectItem.realStock = calcRealStock({
                quantitySku, 
                providerQuantity,
                countValue: stock,
                // additional: input,
                unity: skuUnity,
                productUnity,
                skus,
                skuId,
                unityAdditional: input
            });
        }

        setCurrentSelectItem(currentSelectItem);
        saveCountItem(input, dataCount, type, userInfo?.user);
        forceUpdate();

        const canSetDifference = canSetDiffInCatalog({
                maxPercentage: percentageDifference,
                currentTheoreticalStock: currentSelectItem.theoreticalStock,
                currentRealStock: currentSelectItem.realStock,
                historicalTheoreticalStock: currentSelectItem.historical.theoreticalStock,
                historicalRealStock: currentSelectItem.historical.realStock
        });

        if (canSetDifference) {
            const { difference } = calculateDifference(currentSelectItem.realStock, currentSelectItem.theoreticalStock)
            putDiffInCatalog({
              productId: currentSelectItem.id,
              countId: countID,
              restaurantId: paramsID,
              difference
            }).then().catch(err => {
              console.log(err)
            })
          }
    };

    const differencePercent = calcPercentDifference({
        otherStock: currentSelectItem?.otherStock,
        realStock: currentSelectItem?.realStock,
        theoricalStock: currentSelectItem?.theoricalStock,
        consumed: currentSelectItem?.consumed
    });

    const differenceValue = calcDifference({
        otherStock: currentSelectItem?.otherStock,
        realStock: currentSelectItem?.realStock,
        theoricalStock: currentSelectItem?.theoricalStock,
        consumed: currentSelectItem?.consumed
    });

    const differenceValueByCost = calcDifferenceByCost({
        difference: differenceValue,
        cost: currentSelectItem?.cost
    });

    const differenceColor = setDifferentCollor(differencePercent, currentSelectItem?.lossPercentage, differenceValue);

    useEffect(() => {
        setAssortmentDateState(assortmentDate)
        getPercentageDifference(setPercentageDifference, paramsID);
        setCloseModalSettings(Math.random());
    }, [isParamsPropsLoading, selectItem, assortmentDate]);

    useEffect(() => {
        if (assortmentDateState !== undefined) {
            handleRequests()
        }
    }, [assortmentDateState])
  
    return (
        <Layout>
            <TitleHead title='Contagem'/>

            <GlobalStyle/>

            <Container>
                <PageTitleAndFilter 
                    title={currentData?.setup?.storageLocation?.description || ''}
                    settings
                    filterOff
                    backButton={{
                        action: handleBackButtonFilter,
                        description: `Voltar ${moment(countDate).format('DD/MM')} - ${currentData?.setup?.periodicity?.description || ""}`,
                    }}
                    settingsMobile={currentSelectItem?.showDisplaySettings ? settingsMobile : settingsMobileWithoutPackage}
                    closeModalSettings={closeModalSettings}
                />

                <PullToRefresh className="customPullToRefresh" onRefresh={() => window.location.reload()}>
                    {
                        isLoading &&
                        <>
                            <Skeleton variant="rect" width="100%" height={139} />
                            <Skeleton variant="rect" width="100%" height={71} style={{marginTop: "12px"}} />
                        </>
                    }
                    {
                        !isLoading && 
                        <>
                            {
                                currentSelectItem.hasDiffTheoreticalRealStock &&
                                <DivergentMessageWrapper onClick={handleDivergentDialog}>
                                    Divergência. Toque para resolver
                                </DivergentMessageWrapper>
                            }
                            
                            <CardItemContainer>
                                <CardTop>
                                    <ItemTitle>{currentSelectItem?.description}</ItemTitle>
                                    <ItemType color={currentSelectItem?.type == "INTERNAL"}>
                                        {currentSelectItem?.type == "INTERNAL" ?  "PROD" : "MERC"}
                                    </ItemType>
                                </CardTop>

                                <CardItemDetails onClick={handleDrillDown}>

                                    <CardItemValueCollapsed>
                                        <div>Estoque e valor <ArrowDrillDown src={DrillDownBlueArrow} rotate={drillDownOpen} slt="arrow drill down" /> </div>
                                        {
                                            !drillDownOpen && 

                                            <CardItemDifference>
                                                Diferença
                                                <DifferencePercent color={differenceColor}>
                                                    {calcPercentDifference({
                                                        otherStock: currentSelectItem?.otherStock,
                                                        realStock: currentSelectItem?.realStock,
                                                        theoricalStock: currentSelectItem?.theoricalStock,
                                                        consumed: currentSelectItem?.consumed
                                                    })
                                                    }
                                                </DifferencePercent>
                                            </CardItemDifference>
                                        }
                                    </CardItemValueCollapsed>

                                </CardItemDetails>

                                <CardItemStockAndCount>
                                    <ItemStock>ESTOQUES</ItemStock>
                                    <ItemCounts>CONTADOS {currentSelectItem?.stocks?.countedStocks}/{currentSelectItem?.stocks?.totalStocks}</ItemCounts>
                                </CardItemStockAndCount>

                                <CardRealAndTheorical>
                                    <CardRealAndTheoricalItem rotate={drillDownOpen}>
                                        <span>REAL</span> {numberToReal(currentSelectItem?.realStock)} {currentSelectItem?.consumptionUnitsPrimary?.abbreviation}
                                    </CardRealAndTheoricalItem>
                                    <CardRealAndTheoricalItem rotate={drillDownOpen} grayBackground>
                                        <span>TEÓRICO</span> {numberToReal(currentSelectItem?.theoricalStock)} {currentSelectItem?.consumptionUnitsPrimary?.abbreviation}
                                    </CardRealAndTheoricalItem>
                                </CardRealAndTheorical>

                                <CollapsedCard display={drillDownOpen}>

                                    <CollapseCardContainer>
                                        <OtherStocks>
                                            <span>OUTROS ESTOQUES</span> 
                                            {currentSelectItem?.otherStock} {currentSelectItem?.consumptionUnitsPrimary?.abbreviation}
                                        </OtherStocks>

                                        {
                                            currentSelectItem?.stocks?.totalStocks > 1 &&
                                            <OtherStocksButton onClick={handleOtherStocksDialog}>
                                                Ver outros estoques
                                            </OtherStocksButton>
                                        }

                                    </CollapseCardContainer>

                                    <DiferenceCard color={differenceColor}>
                                        <p>DIFERENÇA</p>
                                        <div>
                                            <span>
                                                {numberToReal(differenceValue)}
                                                {
                                                    ' '
                                                }
                                                {
                                                    currentSelectItem?.consumptionUnitsPrimary?.abbreviation
                                                }
                                            </span>
                                            <DifferencePercent color={differenceColor}>
                                                {differencePercent} 
                                            </DifferencePercent>
                                        </div>
                                    </DiferenceCard>

                                    <CollapsedCardLine>Valor</CollapsedCardLine>

                                    <CardRealAndTheorical>
                                        <CardRealAndTheoricalItem rotate={drillDownOpen}>
                                            <span>PREÇO</span> 
                                            R$ {numberToReal(currentSelectItem?.cost)}
                                        </CardRealAndTheoricalItem>
                                        <CardRealAndTheoricalItem rotate={drillDownOpen}>
                                            <span>TOTAL</span>
                                            {
                                                calcTotalCountValue({
                                                    cost: currentSelectItem?.cost,
                                                    real: currentSelectItem?.realStock
                                                })
                                            }
                                        </CardRealAndTheoricalItem>
                                    </CardRealAndTheorical>

                                    <DiferenceCard color={differenceColor}>
                                        <p>DIFERENÇA</p>
                                        <div>
                                            <span>
                                                R$
                                                {numberToReal(differenceValueByCost)}
                                            </span>
                                            <DifferencePercent color={differenceColor}>
                                                {differencePercent} 
                                            </DifferencePercent>
                                        </div>
                                    </DiferenceCard>


                                </CollapsedCard>

                            </CardItemContainer>

                            <PackagesList>
                                {
                                    currentSelectItem?.skus?.map((sku, skuIndex) => {
                                        const { description = '',} = sku;
                                        const providers = validateProviders(sku);

                                        const [ firstProvider = {} ] = providers;
                                        const { stock } = firstProvider;
                                    
                                        return(
                                            <>
                                            {
                                                providers.map(( provider) => (
                                                    <>
                                                        {
                                                            !provider.isAdditional &&
                                                            <>
                                                            
                                                                <PackageItem>
                                                                    <p>{description}</p>
                                                                    <PackageWrapper>
                                                                        <div>
                                                                            <p>EMBALAGEM</p>
                                                                            <span>{`E${provider.quantity ? provider.quantity : 0}x${sku.quantity ? sku.quantity : 0}${sku.unitsMeasurements ? sku.unitsMeasurements.abbreviation : ""}`}</span>
                                                                        </div>
                                                                        <div>
                                                                            <CountInput 
                                                                                
                                                                                label="CONTAGEM" 
                                                                                content={provider?.stock}
                                                                                handleOnBlur={(input, dataCount) => handleOnBlurInputCount(input, dataCount)}  
                                                                                dataCount={
                                                                                    {
                                                                                        sku,
                                                                                        ...currentData,
                                                                                        currentProduct: currentSelectItem,
                                                                                        restaurantId: paramsID,
                                                                                        type: "count"
                                                                                    }
                                                                                }
                                                                                setCurrentSelectItem={setCurrentSelectItem}
                                                                            />
                                                                        </div>
                                                                    </PackageWrapper>
                                                                </PackageItem>
                                                                <Line />
                                                            </>
                                                        }
                                                        {
                                                            provider.isAdditional &&
                                                            <> 
                                                                <PackageItem>
                                                                    <p />
                                                                    <PackageWrapper>
                                                                        <div>
                                                                            <p>EMBALAGEM</p>
                                                                            <span>{`${sku.quantity ? sku.quantity : 0}${sku.unitsMeasurements ? sku.unitsMeasurements.abbreviation : ""}`}</span>
                                                                        </div>
                                                                        <div>
                                                                            <CountInput 
                                                                                label="adicional" 
                                                                                content={sku?.labelUnityAdditional ? sku?.labelUnityAdditional : sku?.unityAdditional}
                                                                                handleOnBlur={(input, dataCount) => handleOnBlurInputCount(input, dataCount)}  
                                                                                dataCount={
                                                                                    {
                                                                                        sku,
                                                                                        ...currentData,
                                                                                        currentProduct: currentSelectItem,
                                                                                        restaurantId: paramsID,
                                                                                        type: "additional"
                                                                                    }
                                                                                }
                                                                                setCurrentSelectItem={setCurrentSelectItem}
                                                                            />
                                                                        </div>
                                                                    </PackageWrapper>
                                                                </PackageItem>
                                                                <Line />
                                                            </>
                                                        }
                                                    </>
                                                ))
                                            }
                                            {
                                                (currentSelectItem?.skus.some(sku => sku.providers.some(provider => provider.quantity > 1)) || currentSelectItem?.skus.some(sku => sku.quantity !== 1)) 
                                                && (currentSelectItem.consumptionUnitsPrimary.abbreviation || currentSelectItem.consumptionUnitsSecondary)  
                                                && ((skuIndex + 1) === currentSelectItem?.skus?.length ) &&
                                                    <> 
                                                        <PackageItem>
                                                            <p>Adicional em {currentSelectItem.consumptionUnitsSecondary && currentSelectItem.consumptionUnitsSecondary.abbreviation ? currentSelectItem.consumptionUnitsSecondary.abbreviation : currentSelectItem.consumptionUnitsPrimary.abbreviation}</p>
                                                            <PackageWrapper>
                                                                <div>
                                                                    <p>EMBALAGEM</p>
                                                                    <span>E1x1{currentSelectItem.consumptionUnitsSecondary && currentSelectItem.consumptionUnitsSecondary.abbreviation ? currentSelectItem.consumptionUnitsSecondary.abbreviation : currentSelectItem.consumptionUnitsPrimary.abbreviation}</span>
                                                                </div>
                                                                <div>
                                                                    <CountInput 
                                                                        label="adicional" 
                                                                        content={currentSelectItem?.labelAdditional ? currentSelectItem?.labelAdditional : currentSelectItem?.additional}
                                                                        handleOnBlur={(input, dataCount) => handleOnBlurInputCount(input, dataCount)}  
                                                                        dataCount={
                                                                            {
                                                                                sku,
                                                                                ...currentData,
                                                                                currentProduct: currentSelectItem,
                                                                                restaurantId: paramsID,
                                                                                type: "additionalSKU"
                                                                            }
                                                                        }
                                                                        setCurrentSelectItem={setCurrentSelectItem}
                                                                    />
                                                                </div>
                                                            </PackageWrapper>
                                                        </PackageItem>
                                                        <Line />
                                                    </>
                                            }
                                            </>
                                        )
                                    })
                                }
                            </PackagesList>
                            
                            {
                                Boolean(backCountButton) &&
                                
                                <BackAndForwardWrapper width100>
                                    <Link to={`/inventario/contagem-diaria-mobile/${paramsID}/${0}/?countId=${countID}&countDate=${countDate}&categoryDescription=&assortmentDate=${assortmentDateState}&selectItem=${0}&uncountedProducts=true`}>
                                        <BackToLastCount>
                                            Retornar ao último contado
                                        </BackToLastCount>
                                    </Link>
                                    
                                </BackAndForwardWrapper>
                            }
                            
                            {
                                !backCountButton && 
                                <BackAndForwardWrapper>
                                    {
                                        previuosCount?.description && 
                                        <Link to={previuosCount.url.pathname} onClick={cleanSwipeButton}>
                                            <NextFowardButton>
                                                <div>
                                                    <img src={MobileWhiteArrow} alt="left arrow" /> 
                                                </div>
                                                { shortenString(previuosCount.description, 11) }
                                            </NextFowardButton>
                                        </Link>
                                    }

                                    {
                                        !previuosCount?.description && 
                                        <div />
                                    }
                                    
                                    {
                                        nextCount?.description && !finishCountButton &&
                                        <Link to={nextCount.url.pathname} onClick={cleanSwipeButton}>
                                            <NextFowardButton 
                                                rightArrow 
                                            >
                                                <div>
                                                    { shortenString(nextCount.description, 11) }
                                                </div>
                                                <img src={MobileWhiteArrow} alt="right arrow" /> 
                                            </NextFowardButton>
                                        </Link>
                                    }
                                    
                                    {
                                        !nextCount?.description && !finishCountButton &&
                                        <NextFowardButton secondary onClick={handleFinishCount}>
                                            <div>
                                                Finalizar contagem
                                            </div>
                                        </NextFowardButton>
                                    }
                                </BackAndForwardWrapper>
                            }

                            <Link to={`/inventario/contagens/${paramsID}`}>
                                <BackTocounts>
                                    <span>
                                        Voltar para Contagens
                                    </span>
                                </BackTocounts>
                            </Link>

                            {
                                !!redirectToCounts &&
                                <Redirect to={redirectToCounts} />
                            }
                        </>
                    }
                </PullToRefresh>
            </Container>
            {
                openDivergentDialog && 
                <DivergentMobileDialog 
                    open={openDivergentDialog} 
                    handleClose={handleDivergentDialog} 
                    item={currentSelectItem}
                    currentData={currentData}
                    countDate={countDate}
                    userInfo={userInfo.user}
                />
            }   
            
            {
                openOtherStockDialog &&
                <OtherStocksDialog 
                    reference={otherStockDialogRef} 
                    loadOtherStocksProps={
                        {
                            id: currentSelectItem.id,
                            date: countDate
                        }
                    } 
                />
            }
            {
                openMobileOrnationCount && 
                <CountOrdenationDialog 
                    // reference={countOrdenationref}
                    getId={paramsID}
                    setupId={currentSetUp?.id}
                    countDate={countDate}
                    onClose={() => setOpenMobileOrnationCount(false)}
                    currentData={currentData}
                    assortment={assortmentDateState}
                    countID={countID}
                    selectItem={selectItem}
                    userInfo={userInfo}
                />
            }
            {
                configMobilePackageDialog && 
                <ConfigPackageDialog 
                    openModal={{ modalConfigPackage: configMobilePackageDialog}}
                    handleClose={() => setConfigMobilePackageDialog(false)}
                    configPackageSkuItens={configPackageSkuItens}
                    countDate={countDate}
                    getId={paramsID}
                    internalProducts={currentSelectItem}
                    setInternalProduct={() => {}}
                    setToastOpen={() => {}}
                    setToastProps={() => {}}
                    setupId={currentSetUp?.id}
                    mobileBehavior={() => updateContent()}
                />
            }
            {
                openFinishDialog && 
                <FinishDialog 
                    reference={finishDialogReference}
                    currentData={currentData}
                    onClose={() => setOpenFinishDialog(false)}
                    params={
                        [
                            { urlParam: `originId=${paramsID}` },
                            { urlParam: `countDate=${countDate}` },
                            { urlParam: `categoryDescription=${categoryDescription}` },
                            { urlParam: `assortmentDate=${assortmentDateState}` },
                            { urlParam: `uncountedProducts=true` }
                        ]
                    }
                    countID={countID}
                    paramsID={paramsID}
                    setRedirectToCounts={() => handleBackButtonFilter()}
                />
            }
            {
                openCountSearchDialog && 
                <CountDialog 
                    reference={openCountSearchDialogReference}
                    currentData={currentData}
                    onClose={() => {setOpenCountSearchDialog(false)}}
                    paramsID={paramsID}
                    countID={countID}
                    countDate={countDate}
                    assortmentDate={assortmentDateState}
                />
            }
        </Layout>
    );
}

const mapStateToProps = state => ({
    userInfo: state.user,
    countInfo: state.count,
    assortmentInfo: state.assortment
  })
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(AssortmentActions, dispatch);
  
  export default connect(mapStateToProps, mapDispatchToProps)(CountPage);