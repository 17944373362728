export function setBrands(data) {
    return {
        type: 'SET_BRANDS_DATA',
        data,
    };
}

export function createBrands(data) {
    return {
        type: 'CREATE_BRANDS_DATA',
        data,
    };
}