import { Chip, Tooltip, Typography } from '@material-ui/core';
import { withStyles, fade, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { handleColorType, handleBackgroundType } from '../../styles/colors';

export const StyledTypography = styled(Typography)`
  background-color: ${(props) => (!props.selected ? 'transparent' : '#d6d8d9')};
  width: calc(100% + 32px);
  margin: -8px -16px;
  padding: 9px;
`;

export const ButtonChip = styled(Chip)`
  width: 60px;
  height: 20px;
  font-family: Lato;
  font-size: ${(props) => props.fontSize};
  margin: 0;
  padding: 0;
  line-height: 35px;
  cursor: pointer;
  ${({ background }) => handleBackgroundType(background)};
  ${({ color }) => handleColorType(color)};
  :hover {
    ${({ background }) => handleBackgroundType(background)};
    ${({ color }) => handleColorType(color)};
  }
`;
export const ButtonStatus = styled.div`
  width: 100%;
  height: 28px;
  font-family: Lato;
  font-size: 11px;
  margin: 2px 0;
  padding: 2px 0;
  line-height: 10px;
  cursor: pointer;
  border-radius: 16px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${({ background }) => handleBackgroundType(background)};
  ${({ color }) => handleColorType(color)};
  :hover {
    ${({ background }) => handleBackgroundType(background)};
    ${({ color }) => handleColorType(color)};
  }

  @media (max-width: 1024px) {
    width: 90%;
    height: 33px;
    font-size: 12px;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
    }
  }
`;

export const Curva = styled.div`
  font-family: Lato;
  font-size: ${(props) => props.fontSize};
  margin: 5px;
  padding: 0 5px;
  text-align: center;
  flex: 1;

  @media (min-width: 768px) and (max-width: 1170px) {
    font-family: Lato;
    font-size: ${(props) => props.fontSize};
    flex: 1;
  }
`;
export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#222',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontFamily: 'Lato'
  }
}))(Tooltip);

export const headerStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
      minWidth: '30%'
    },

    '&:active': {
      width: '46%'
    }
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  inputRoot: {
    color: 'inherit',
    fontFamily: 'Lato'
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  },

  input: {
    fontFamily: 'Lato'
  },

  option: {
    fontFamily: 'Lato'
  },

  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    outline: 0
  }
}));
