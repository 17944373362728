/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';

import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makePut from 'services/useFetch/makePut';

function Index({ open, close, userInfo, setMessage, callSettings, getUsers }) {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const changeUserPassword = async (id) => {
    setLoading(true);

    const handleResponse = (status) => {
      if (status) {
        close();
      } else {
        setMessage({
          description: 'Erro ao atualizar senha',
          status: 'error'
        });
      }

      setLoading(false);

      setMessage({
        description: 'Senha atualizada com sucesso',
        status: 'success'
      });

      getUsers(callSettings?.page);
    };
    await makePut(
      'changePassword',
      { password: newPassword, passwordConfirm: confirmNewPassword },
      handleResponse,
      id
    );
  };

  return (
    <Dialog
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      transitionDuration={0}
      className="defaultModal"
      open={open}
      onClose={() => close()}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>ATUALIZAR SENHA</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            close();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <div className="cardDefault pt16" style={{ borderRadius: '0' }}>
        {newPassword !== confirmNewPassword &&
        !!newPassword &&
        !!confirmNewPassword ? (
          <Alert className="mb16" severity="warning">
            Elas precisam ser iguais!
          </Alert>
        ) : null}

        <TextField
          className="newPassword textFieldSmall mr20 mt16"
          type="password"
          label="Nova Senha"
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />

        <TextField
          className="newPassword textFieldSmall mt16"
          type="password"
          value={confirmNewPassword}
          label="Confirmar Nova Senha"
          onChange={(e) => {
            setConfirmNewPassword(e.target.value);
          }}
        />

        <div className="dFlex justifyCenter mt20">
          <Button
            variant="contained"
            onClick={close}
            className="defaultButton backButton mr20"
          >
            Cancelar
          </Button>

          {newPassword === confirmNewPassword &&
          !!newPassword &&
          !!confirmNewPassword ? (
            <Button
              type="submit"
              variant="contained"
              className="defaultButton submitButton"
              onClick={() => changeUserPassword(userInfo)}
            >
              {loading ? 'Salvando...' : 'Salvar'}
            </Button>
          ) : (
            <Button variant="contained" className="defaultButton submitButton">
              Salvar
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default Index;
