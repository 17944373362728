import React from 'react';

function RadioButtonIcon(props) {
  return (
    <>
      {props.selected && (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
          <circle
            cx={8}
            cy={8}
            r={6.5}
            stroke={props.customFill || '#5062F0'}
            strokeWidth={3}
          />
          <circle
            cx={8}
            cy={8}
            r={1.5}
            stroke={props.customFill || '#5062F0'}
            strokeWidth={3}
          />
        </svg>
      )}

      {!props.selected && (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
          <circle
            cx={8}
            cy={8}
            r={7.5}
            stroke={props.customFill || '#0E1337'}
          />
        </svg>
      )}
    </>
  );
}

export default RadioButtonIcon;
