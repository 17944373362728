import styled from "styled-components";

export const SkuTagDiv = styled.div`
  table {
    width: 100%;
  }
` 

export const SkuTagThead = styled.thead`
  background-color: #F2F2F4;
  tr {
    th {
      font-size: 9px;
      font-weight: 700;
      color: #666770;
    }
  }
`

export const SkuTagBody = styled.thead`
  tr {
    td {
      padding: 8px 0;
      font-size: 12px;
      font-weight: 400;
      color: #0F1225;

      &:first-child {
        padding-left: 9px;
      }
    }
    &:not(:last-child) {
        border-bottom: 1px solid #D2D3D7;
      }
  }
`