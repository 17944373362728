/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useRef } from 'react';

import { Tooltip, CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import DateInputField from 'components/PageTitleAndFilter/DateInputField/DateInputField';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import downloadIcon from 'images/icons/downloadUpload/download.svg';
import uploadIcon from 'images/icons/downloadUpload/uploadIcon.svg';
import searchInputIconWhite from 'images/icons/searches/searchInputIconWhite.svg';
import xIconSmallBlack from 'images/icons/signals/closesAndCancels/x-icon-small-black.svg';
import makePost from 'services/useFetch/makePost';
import Environment from 'utils/environments';
import handleCSVFile, { checkCSVFile } from 'utils/handleCSVFile';

import {
  QuotesWrap,
  QuotesTitle,
  QuotesParagraph,
  FormSection,
  InputFile,
  UploadButton,
  UploadText,
  FileNameWrapper
} from './style';

function QuotesUpload({ userInfo }) {
  const [dates, setDates] = useState({
    date: new Date(),
    validDate: null
  });
  const [disableUploadButton, setDisableUploadButton] = useState(false);
  const [disableSendDataButton, setDisableSendDataButton] = useState(true);
  const [currentFileName, setCurrentFileName] = useState();
  const [toastOpen, setToastOpen] = useState(false);
  const [toastProps, setToastProps] = useState({});
  const [loadStatus, setLoadStatus] = useState(false);
  const [sendLoadStatus, setSendLoadStatus] = useState(false);
  const [inputResetDate, setInputResetDate] = useState(false);
  const [CSVJson, setCSVJson] = useState();
  const { id: paramsID } = useParams();
  const environments = Environment(process.env.REACT_APP_ENV);

  const inputRef = useRef(null);

  const resetInputFile = () => {
    inputRef.current.value = null;
    setCurrentFileName();
    setCSVJson();
    setDisableSendDataButton(true);
  };

  const handleResponsePost = (success, response) => {
    setSendLoadStatus(false);
    resetInputFile();
    if (success) {
      setToastProps({
        severity: 'success',
        message: 'importação realizada com sucesso!',
        timeOutOff: false
      });
      setToastOpen(true);
      setInputResetDate(true);
      setDates({
        date: new Date(),
        validDate: null
      });
      return;
    }

    if (response) {
      const { data = {} } = response;
      if (data?.message === 'there are some invalid fields in this file') {
        const { invalidCPNJs = [], invalidIDS = [] } = data;
        const reducer = (accumulator, currentValue, index, array) => {
          const separator = index + 1 === array.length ? ' e ' : ', ';
          return accumulator + separator + currentValue;
        };

        if (invalidCPNJs.length) {
          const getCNPJLinha = invalidCPNJs.map((element) => element.Linha);
          const cnpjInvalidLines = getCNPJLinha.reduce(reducer);

          setToastProps({
            severity: 'error',
            message: `As linhas ${cnpjInvalidLines} possuem CNPJ inválidos`,
            timeOutOff: true
          });
          setToastOpen(true);
          return;
        }
        if (invalidIDS.length) {
          const getIDSLine = invalidIDS.map((element) => element.Linha);
          const IDs = getIDSLine.reduce(reducer);
          setToastProps({
            severity: 'error',
            message: `As linhas ${IDs} possuem código do produto inválidos`,
            timeOutOff: true
          });
          setToastOpen(true);
          return;
        }
      }
    }

    setToastProps({
      severity: 'error',
      message: 'Algo deu errado na importação, por favor tente mais tarde.',
      timeOutOff: false
    });
    setToastOpen(true);
  };

  const sendData = () => {
    if (!CSVJson) return;
    setSendLoadStatus(true);
    makePost('quotations', CSVJson, handleResponsePost);
  };

  const handleDate = (name, value) => {
    const newDates = { ...dates, [name]: value };
    setDates(newDates);
    if (newDates.date) {
      if (
        newDates.validDate &&
        new Date(newDates.date) > new Date(newDates.validDate)
      ) {
        setDisableSendDataButton(true);
        return;
      }
      if (currentFileName) {
        setDisableSendDataButton(false);
      }
    }
  };

  const handleJSONConverter = (CSVItensOBJ, errorMessage, fileName) => {
    if (!CSVItensOBJ) {
      setToastProps({
        severity: 'error',
        message: errorMessage,
        timeOutOff: false
      });
      setToastOpen(true);
      setLoadStatus(false);
      resetInputFile();
      return;
    }

    const postData = {
      quoteDate: moment(dates.date, 'YYYY-MM-DD')
        .set({ hour: 5 })
        .format('YYYY-MM-DD HH:mm'),
      expirationDate: dates.validDate
        ? moment(dates.validDate, 'YYYY-MM-DD')
            .set({ hour: 5 })
            .format('YYYY-MM-DD HH:mm')
        : null,
      items: CSVItensOBJ,
      user: userInfo.user,
      originId: paramsID
    };
    setCSVJson(postData);
    setLoadStatus(false);
    setCurrentFileName(fileName);
    if (dates.validDate && new Date(dates.date) > new Date(dates.validDate)) {
      setDisableSendDataButton(true);
      return;
    }
    setDisableSendDataButton(false);
  };

  const handleFile = (event) => {
    const file = event.target.files[0];
    setLoadStatus(true);
    const { name = '' } = file;

    const CSViSValid = checkCSVFile(file);
    if (!CSViSValid) {
      setToastProps({
        severity: 'error',
        message: 'O arquivo não está no formato correto.',
        timeOutOff: false
      });
      setToastOpen(true);
      setLoadStatus(false);
      return;
    }
    handleCSVFile(file, handleJSONConverter, name);
  };

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleQuotationSample = () => {
    const url = `${environments.catalog}/quotations/file/sample`;

    window.open(url);
  };

  return (
    <Layout>
      <TitleHead title="Upload" />
      <QuotesWrap>
        <section>
          <QuotesTitle>Upload de cotações</QuotesTitle>
          <QuotesParagraph>
            Defina a data da cotação e envie um arquivo no formato CSV.
            <span style={{ marginLeft: '20px' }}>
              <UploadButton
                disabled={disableUploadButton}
                aria-label="upload"
                width="140px"
                onClick={handleQuotationSample}
              >
                <UploadText aria-label="upload">
                  <img
                    src={downloadIcon}
                    alt={downloadIcon}
                    id="downloadIcon"
                  />
                  <span>Baixar modelo</span>
                  {loadStatus ? (
                    <CircularProgress color="inherit" size={12} />
                  ) : null}
                </UploadText>
              </UploadButton>
            </span>
          </QuotesParagraph>
        </section>

        <FormSection>
          <DateInputField
            label="Data"
            handleFilter={handleDate}
            filterName="date"
            size="small"
            inputSizes={{ small: 110 }}
            initialDate={new Date()}
            resetDate={inputResetDate}
            setInputResetDate={setInputResetDate}
          />

          <DateInputField
            label="Validade"
            handleFilter={handleDate}
            filterName="validDate"
            size="small"
            inputSizes={{ small: 110 }}
            initialDate={false}
            minDate={new Date(dates.date).setDate(
              new Date(dates.date).getDate() + 1
            )}
            resetDate={inputResetDate}
            setInputResetDate={setInputResetDate}
          />
          <Tooltip
            title={
              disableUploadButton ? 'Escolha a data para enviar o arquivo.' : ''
            }
            aria-label="upload"
          >
            <span>
              <UploadButton
                disabled={disableUploadButton}
                aria-label="upload"
                width="146px"
              >
                <UploadText aria-label="upload">
                  <img
                    src={searchInputIconWhite}
                    alt={searchInputIconWhite}
                    id="searchIcon"
                  />
                  <span>Escolha o arquivo</span>
                  {loadStatus ? (
                    <CircularProgress color="inherit" size={12} />
                  ) : null}
                </UploadText>
                <InputFile
                  type="file"
                  id="csvFile"
                  name="myfile"
                  onChange={handleFile}
                  accept=".csv"
                  ref={inputRef}
                  disabled={disableUploadButton}
                  aria-label="upload"
                />
              </UploadButton>
            </span>
          </Tooltip>

          <FileNameWrapper>
            {currentFileName}
            {currentFileName && (
              <img
                src={xIconSmallBlack}
                alt={xIconSmallBlack}
                onClick={resetInputFile}
                id="xicon"
              />
            )}
          </FileNameWrapper>
        </FormSection>
        <FormSection>
          <Tooltip
            title={disableUploadButton ? 'Enviar arquivo,' : ''}
            aria-label="upload"
          >
            <span>
              <UploadButton
                disabled={disableSendDataButton}
                aria-label="upload"
                onClick={sendData}
              >
                <UploadText aria-label="upload">
                  <img src={uploadIcon} alt={uploadIcon} id="uploadIcon" />
                  <span>Enviar</span>
                  {sendLoadStatus ? (
                    <CircularProgress color="inherit" size={12} />
                  ) : null}
                </UploadText>
              </UploadButton>
            </span>
          </Tooltip>
        </FormSection>
      </QuotesWrap>

      <Snackbar
        open={toastOpen}
        autoHideDuration={toastProps?.timeOutOff ? null : 6000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity={toastProps.severity}>
          {toastProps.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(QuotesUpload);
