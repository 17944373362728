import React from 'react';

import './style.scss';
import numberToDecimalPlace from 'utils/numberToDecimalPlace';
import numberToReal from 'utils/numberToReal';

export default function SingleInvoiceTable({ data }) {
  return (
    <table className="invoiceTable singleInvoiceTable">
      <thead>
        <tr>
          <th>Código</th>
          <th className="alignLeft">Descrição Produto NFe</th>
          <th>QTDE</th>
          <th>Valor Unitário</th>
          <th>Valor Total</th>
        </tr>
      </thead>
      <tbody>
        {data?.data &&
          data?.data.map((item, index) => (
            <tr key={index}>
              <td>{item.code}</td>
              <td className="alignLeft">{item.description}</td>
              <td>{numberToDecimalPlace(item.quantity, 2)}</td>
              <td>R$ {numberToReal(item.unitaryValue, 2)}</td>
              <td>R$ {numberToReal(item.value, 2)}</td>
            </tr>
          ))}

        <tr>
          <td colSpan="4" style={{ fontWeight: 'bold' }}>
            TOTAL
          </td>
          <td>R$ {numberToReal(data.totalValue, 2)}</td>
        </tr>
      </tbody>
    </table>
  );
}
