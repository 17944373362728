function removeSpecialsLetters(text) {
  text = text.replace(/[ÀÁÂÃÄÅ]/,"A");
  text = text.replace(/[àáâãäå]/,"a");
  text = text.replace(/[ÈÉÊË]/,"E");
  text = text.replace(/[èéê]/,"e");
  text = text.replace(/[Ç]/,"C");
  text = text.replace(/[ç]/,"c");
  text = text.replace(/[ÓÔÕ]/,"O");
  text = text.replace(/[óôõ]/,"o");
  text = text.replace(/[ÍÌ]/,"I");
  text = text.replace(/[íì]/,"i");
  text = text.replace(/[ÚÙÜ]/,"U");
  text = text.replace(/[ùúü]/,"u");
  text = text.replace(/[Ñ]/,"N");
  text = text.replace(/[ñ]/,"ñ");
  return text.replace(/[^a-z0-9]/gi,''); 
}
const checkStringSimilarity = (value1, value2) => {
  const text1 = removeSpecialsLetters(value1)
  const text2 = removeSpecialsLetters(value2)

  const result = text1.toLowerCase().indexOf(text2.toLowerCase()) > -1;
  return result;
}

const filterElementInCategory = (data, value, setNewList, list = []) => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const element = data[key];
      if (checkStringSimilarity(element.description, value)) {
        list.push(element)
        setNewList(list);
      }
       filterElementInCategory(element.children, value, setNewList, list);
    }
  }
};

export default filterElementInCategory;
