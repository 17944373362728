/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  Fab,
  CircularProgress
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Add, DeleteOutline } from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Axios from 'axios';
import moment from 'moment';
import Draggable from 'react-draggable';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import DialogCards from 'components/Dialogs/DialogCards';
import DialogQuotes from 'components/Dialogs/DialogQuotes';
import DialogSkuTags from 'components/Dialogs/DialogSkuTags';
import ImageCropper from 'components/Dialogs/ImageCropper';
import ProvidersAssociation from 'components/Dialogs/ProvidersAssociation';
import RestaurantsDialog from 'components/Dialogs/RestaurantsDialog';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import UnifySkusPlatform from 'components/Dialogs/UnifySkusPlatform';
import ImageWrapper from 'components/ImageWrapper';
import ItemsPerPage from 'components/ItemsPerPage';
import { LocalizationContext } from 'components/Localization';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import useAxiosFetch from 'hooks/useAxiosFetch';
import DownloadIcon from 'images/icons/downloadUpload/download.svg';
import BloomIcon from 'images/icons/logo/bloomIconLarge.svg';
import ClipboardBlueIcon from 'images/icons/objects/clipboards/clipboardBlue.svg';
import AutoCataloguing from 'images/icons/configsAndSetups/cataloguingAuto.svg'
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import checkIcon from 'images/icons/signals/checks/correct-icon-blue.svg';
import checkIconGray from 'images/icons/signals/checks/correct-icon-gray.svg';
import getOptions from 'services/getOptions';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import makeGet from 'services/useFetch/makeGet';
import makePost from 'services/useFetch/makePost';
import makePut from 'services/useFetch/makePut';
import * as ProductsActions from 'store/actions/products';
import * as SkusActions from 'store/actions/skus';
import { enable } from 'store/reducers/LoadingSlice';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import handleQuotations from 'utils/quotations/handleQuotations';
import ValidationLength from 'utils/utils';

import TableIcon from './TableIcon';

import RegisterProductModal from '../products/ProductForm';

const environments = Environment(process.env.REACT_APP_ENV);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function Skus({ userInfo, setEditProducts, productInfo }) {
  const history = useHistory();
  const bigScreen = useMediaQuery('(min-width:1281px)');
  const queryParams = useLocation()?.search;
  const { id: paramsID, skuId } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const isAdminMaster = userInfo?.profile?.[0]?.[0]?.name === 'admin_master';
  const isCatalogProfile = userInfo.profile[0]?.[0]?.name === 'cataloguer';
  const cancelTokenSource = Axios.CancelToken.source();

  const [originsId, setOriginsId] = useState([]);
  const [openSuccessDeleteQuotation, setOpenSuccessDeleteQuotation] =
    useState(false);
  const [cropper, setCropper] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingCountSkus, setLoadingCountSkus] = useState(false)
  const [toastOpen, setToastOpen] = useState(false);
  const [statusCode, setStatusCode] = useState();
  const [toastProps, setToastProps] = useState();
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    mainLoading: false,
    page: 1,
    totalPages: 0,
    size: 100,
    numberOfElements: 0,
    totalElements: 0
  });

  const dispatch = useDispatch();

  const { getId, size, page, totalPages, numberOfElements, totalElements } =
    callSettings;
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'providersAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      sku: null
    },
    {
      id: 2,
      name: 'productsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 3,
      name: 'cardsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    { id: 4, name: 'filters', open: false, fullScreen: null, maxWidth: null },
    {
      id: 5,
      name: 'skuModal',
      open: false,
      fullScreen: true,
      maxWidth: null,
      fullWidth: true
    },
    {
      id: 6,
      name: 'imageCropper',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 7,
      name: 'skuTags',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      skuName: ''
    },
    {
      id: 8,
      name: 'technicalSheetModal',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      skuName: ''
    }
  ]);

  const [selectedSku, setSelectedSku] = useState([]);
  const [variationFilter, setVariationFilter] = useState(false);
  const [variationFilterValue, setVariationFilterValue] = useState(15);
  const [skuCurrentIdAssociation, setSkuCurrentIdAssociation] = useState();
  const [productsSku, setProductsSku] = useState()
  const [dialogQuotesSettings, setDialogQuotesSettings] = useState({
    open: false,
    obj: {}
  });

  const [skus, setSkus] = useState([]);
  const [skuAssociation, setSkuAssociation] = useState();
  const [unifySkusPopover, setUnifySkusPopover] = useState({
    open: false,
    skus: [],
    refSku: ''
  });
  const [deleteError, handleDeleteError] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [selectedCard, setSelectedCard] = useState();
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [countVariationSkus, setCountVariationSkus] = useState(0);
  const [providers, setProviders] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [brands, setBrands] = useState('');
  const [storageLocations, setStorageLocations] = useState('');
  const [periodicities, setPeriodicities] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [isEditSku, setIsEditSku] = useState(true);
  const [updateMainList, setUpdateMainList] = useState(false);
  const [updateControl, setUpdateControl] = useState(false)
  const [dialogRestaurantSettings, setDialogRestaurantSettings] =
    useState(false);
  const [dialogRestaurantData, setDialogRestaurantData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [msgErrProdDescription, setMsgErrProdDescription] = useState({ description: '', statusCode: ''})
  const [onlyViewProduct, setOnlyViewProduct] = useState(false);
  const [currentSkuImage, setCurrentSkuImage] = useState({});
  const [filterRestaurantsSkuSelected, setFilterRestaurantsSkuSelected] =
    useState(false);
  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [filterOpened, setFilterOpened] = useState(true);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [receivedWithoutCatalogingQueryParam, setReceivedWithoutCatalogingQueryParam] = useState(
    queryParams?.includes('receivedWithoutCataloging')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('receivedWithoutCataloging'))
          ?.split('=')[1]
      : ''
  );
  const [skusWithoutMovementQueryParam, setSkusWithoutMovementQueryParam] = useState(
    queryParams?.includes('skusWithoutMovement')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('skusWithoutMovement'))
          ?.split('=')[1]
      : ''
  );
  const [withOutProductQueryParam, setWithOutProductQueryParam] = useState(
    queryParams?.includes('withOutProduct')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('withOutProduct'))
          ?.split('=')[1]
      : ''
  );
  const [skusNewsQueryParam, setSkusNewsQueryParam] = useState(
    queryParams?.includes('skusNews')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('skusNews'))
          ?.split('=')[1]
      : ''
  );

  const DEFAULT_FILTERS = useMemo(
    () => [
      receivedWithoutCatalogingQueryParam && {
        param: 'receivedWithoutCataloging',
        value: receivedWithoutCatalogingQueryParam,
        urlParam: `receivedWithoutCataloging=${receivedWithoutCatalogingQueryParam}`
      },
      skusWithoutMovementQueryParam && {
        param: 'skusWithoutMovement',
        value: skusWithoutMovementQueryParam,
        urlParam: `skusWithoutMovement=${skusWithoutMovementQueryParam}`
      },
      withOutProductQueryParam && {
        param: 'withOutProduct',
        value: withOutProductQueryParam,
        urlParam: `withOutProduct=${withOutProductQueryParam}`
      },
      skusNewsQueryParam && {
        param: 'skusNews',
        value: skusNewsQueryParam,
        urlParam: `skusNews=${skusNewsQueryParam}`
      },
    ].filter(value => value),
    [
      receivedWithoutCatalogingQueryParam,
      skusWithoutMovementQueryParam,
      withOutProductQueryParam,
      skusNewsQueryParam,
    ]
  );
  const [filtersChanged, setFiltersChanged] = useState(DEFAULT_FILTERS);

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  useAxiosFetch({
    endpoint: `${environments.catalog}/skus/${skuId}`,
    params: {
      originId: paramsID
    },
    enabled: !!skuId && paramsID === 0 ? userInfo?.companiesActive?.id === 0 : true,
    onSuccess: (sku) => {
      handleSelectedSku(_, sku);
      setSelectedSku(sku);
      setIsEditSku(true);
    }
  });

  const targetSkuControl = (skuInfo, control) => {
    const body = {
      control,
      restaurants: [getId],
      user: userInfo.user
    }

    Axios.put(`${environments.catalog}/skus/${skuInfo.id}/control`,
      body
    ).then(() => {
      setToastOpen(true);
      setToastProps({
        customMessage: 'Controle de sku atualizado',
        message: 'Controle de sku atualizado',
        severity: 'success',
      });
      setUpdateControl(true)
    }).catch(() => {
      setToastOpen(true);
      setToastProps({
        customMessage: 'Erro ao atualizar controle do sku',
        message: 'Erro ao atualizar controle do sku',
        severity: 'error',
      });
    }).finally(() => {
      setUpdateControl(false)
    })

  }

  const tagsFilter = {
    label: 'Tags',
    filterName: 'hasTags',
    placeHolder: 'Selecione...',
    type: 'autoComplete',
    optionReference: 'hasTags',
    defaultValue: { title: 'Ambos', value: '' },
    options: [
      { title: 'Ambos', value: '' },
      { title: 'Com tag', value: 'true' },
      { title: 'Sem tag', value: 'false' }
    ],
    size: 'medium'
  };

  const imageFilter = {
    label: 'Fotos',
    filterName: 'image',
    placeHolder: 'Selecione...',
    type: 'autoComplete',
    optionReference: 'image',
    defaultValue: { title: 'Ambos', value: '' },
    options: [
      { title: 'Ambos', value: '' },
      { title: 'Com imagem', value: 'true' },
      { title: 'Sem imagem', value: 'false' }
    ],
    size: 'medium'
  };

  const restaurantFilter = {
    label: 'Restaurantes',
    filterName: 'originId',
    placeHolder: 'Selecione...',
    type: 'autoComplete',
    urlPath: `${environments.restaurants}/companies?page=0&size=5000`,
    key: 'stores',
    optionReference: 'fantasyName',
    options: [],
    size: 'medium'
  };

  const curveFilter = {
    label: 'Curva',
    type: 'multipleCheckbox',
    checkBoxInputs: [{ name: 'A' }, { name: 'B' }, { name: 'C' }]
  };

  const multipleCheckboxFilter = {
    label: '',
    type: 'multipleCheckbox',
    checkBoxInputs: [
      { name: 'Revenda', referenceName: 'resales' },
      { name: 'Prioritário', referenceName: 'itemPriority' },
      { name: 'Marca Preferida', referenceName: 'brandFavorite' },
      { name: 'Marca Aceita', referenceName: 'brandAccepted' }
    ]
  };

  const platformSkusFilter = {
    label: 'Sku Plataforma',
    filterName: 'restaurants',
    type: 'switchField'
  };

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'SKU',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: isAdminPage || isAdminMaster
          ? `${environments.catalog}/skus/platform`
          : `${environments.catalog}/skus`,
        key: 'skus',
        optionReference: 'description',
        options: [],
        size: 'medium',
        searchByTermMode: true
      },
      {
        label: 'EAN',
        filterName: 'ean',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Categoria',
        filterName: 'categoryDescription',
        placeHolder: 'Selecione...',
        type: 'newTreeSelector',
        key: 'categoryDescription',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_to_plus',
      },
      {
        label: 'Fornecedores',
        filterName: 'providerIds',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/suppliers/groups`,
        key: 'providersIdList',
        optionReference: 'description',
        options: [],
        size: 'medium',
        filterParamsOff: true,
        ignoreFilterContent: true
      },
      {
        label: 'Fabricantes',
        filterName: 'producersId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/producers`,
        key: 'producers',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium'
      },
      {
        label: 'Marcas',
        filterName: 'brandsId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/brands`,
        page: 1,
        key: 'brands',
        optionReference: 'description',
        options: [],
        size: 'medium'
      },
      ...(!isAdminPage && !isAdminMaster ? [curveFilter] : []),
      ...(isAdminPage || isAdminMaster ? [restaurantFilter] : []),
    ],
    [
      ...(isAdminPage || isAdminMaster ? [tagsFilter, imageFilter, platformSkusFilter] : []),
      ...(!isAdminPage && !isAdminMaster ? [multipleCheckboxFilter] : []),
      ... 
        [
          {
            label: 'SKU sem Produto',
            filterName: 'withOutProduct',
            type: 'switchField',
            checkedAsDefault: withOutProductQueryParam,
            customClassName: !isAdminPage && !isAdminMaster ? 'skuWithoutProduct' : ''
          }
        ],
    ]
  ]);

  const getCropData = () => {
    const currentSkuId = currentSkuImage?.sku?.images?.skuId;
    const body = {
      user: userInfo.user,
      image: cropper.getCroppedCanvas().toDataURL()
    };

    Axios.put(
      `${environments.catalog}/skus/platform/${currentSkuId}/images`,
      body,
      config
    )
      .then(() => {
        setLoading(false);
        setSkus(
          skus.map((sku) => {
            if (currentSkuId === sku.id) {
              return {
                ...sku,
                skuBloom: {
                  ...sku?.skuBloom,
                  images: {
                    ...sku?.skuBloom?.images,
                    url: body.image,
                    name: currentSkuImage?.sku?.images?.name
                  }
                }
              };
            }
            return sku;
          })
        );
        setCurrentSkuImage({
          ...currentSkuImage,
          sku: {
            ...currentSkuImage.sku,
            images: {
              ...currentSkuImage.sku?.images,
              skuId: '',
              baseImage: '',
              name: '',
              url: ''
            }
          }
        });
        handleClearFileInput(currentSkuId);
        setToastOpen(true);
        setToastProps({
          message: 'Imagem atualizada com sucesso.',
          severity: 'success'
        });
      })
      .catch((error) => {
        setLoading(false);
        setToastOpen(true);
        setToastProps({
          message: error.response.data.message,
          severity: 'error'
        });
        setStatusCode(error.response.status);
      });
  };

  const handleClearFileInput = (skuId) => {
    document.querySelector(`#fileInput${skuId}`).value = null;
  };

  const handleSelectImage = (e, skuId) => {
    document.querySelector(`#fileInput${skuId}`).click();
  };

  const onChangeImage = (e, skuId) => {
    if (!e?.target?.files?.length) return;

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();

    reader.onload = () => {
      setCurrentSkuImage({
        ...currentSkuImage,
        sku: {
          ...currentSkuImage.sku,
          images: {
            ...currentSkuImage.sku?.images,
            skuId,
            baseImage: reader.result,
            name: files.length && files[0]?.name
          }
        }
      });

      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'imageCropper'
            ? { ...modal, open: true, skuId }
            : modal
        )
      );
    };
    reader.readAsDataURL(files.length && files[0]);
  };

  function handleModal(title, value) {
    if (value) {
      const key = Object.keys(value)[0];
      const objValue = Object.values(value)[0];

      setModalSettings(
        modalSettings.map((x) =>
          x.name === title ? { ...x, [key]: objValue } : x
        )
      );
    } else {
      const { id } = modalSettings.find((x) => x.name === title);
      const { name } = modalSettings.find((x) => x.name === title);
      const { open } = modalSettings.find((x) => x.name === title);
      const { fullWidth } = modalSettings.find((x) => x.name === title);
      const { maxWidth } = modalSettings.find((x) => x.name === title);

      return { id, name, open, fullWidth, maxWidth };
    }

    if (title === 'skuModal' && value.open === false) {
      setSelectedSku();
    }

    setUpdateMainList(false);
  }

  const canOpenSkuModal = useMemo(() => {
    const validateSetupInfos =
      isAdminPage || isAdminMaster ||
      (storageLocations.length && periodicities.length)
    const validateProducts = isEditSku || products.length;
    const validateSkuModalOptions = isAdminPage || isAdminMaster
      ? packageTypes.length && categories.length && skus.length
      : true;

    if (!isEditSku) return handleModal('skuModal').open;

    return (
      handleModal('skuModal').open &&
      typeof brands === 'object' &&
      unitsMeasurements.length &&
      consumptionUnits.length &&
      validateSkuModalOptions &&
      validateProducts &&
      validateSetupInfos
    );
  }, [
    categories.length,
    brands.length,
    consumptionUnits.length,
    handleModal,
    isAdminPage,
    isAdminMaster,
    items.length,
    packageTypes.length,
    periodicities.length,
    products.length,
    skus.length,
    storageLocations.length,
    unitsMeasurements.length
  ]);

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  function handleDelete() {
    if (isAdminPage || isAdminMaster) {
      const baseUrl = `${environments.catalog}/skus/platform`;

      Axios.delete(
        `${baseUrl}/${deleteData.skuBloomId}?user=${userInfo.user}`,
        config
      )
        .then(() => {
          const data = skus;
          const index = data.indexOf(deleteData);
          skus.splice(index, 1);
          setSkus(skus);
          handleDeleteSuccess(true);
          handleDialogClose();
        })
        .catch((error) => {
          setToastOpen(true);
          setToastProps({
            message: error.response.data.message,
            severity: 'error',
            timeOutOff: true
          });
          setStatusCode(error.response.status);
          handleDeleteError(true);
        });
    } else {
      const baseUrl = `${environments.catalog}/skus`;

      Axios.delete(
        `${baseUrl}/${deleteData.id}?originId=${userInfo.companiesActive.id}&user=${userInfo.user}`,
        config
      )
        .then(() => {
          const data = skus;
          const index = data.indexOf(deleteData);
          skus.splice(index, 1);
          setSkus(skus);
          handleDeleteSuccess(true);
          handleDialogClose();
        })
        .catch((error) => {
          setToastOpen(true);
          setToastProps({
            message: error.response.data.message,
            severity: 'error',
            timeOutOff: true
          });
          setStatusCode(error.response.status);
          handleDeleteError(true);
        });
    }
  }

  const getSelectedStores = (sku) => {
    const restaurantsIds = sku?.nRestaurants
      ?.split(',')
      ?.map((id) => parseInt(id, 10));
    const associatedRestaurants = storeOptions?.filter((option) =>
      restaurantsIds?.includes(option.companyId)
    );

    return associatedRestaurants;
  };

  function handleFilter(param, value, _, paramSecond) {
    if (param === 'restaurants') {
      setFilterRestaurantsSkuSelected(!!value);
    }
    const val = param === 'restaurants' ? (!value ? '' : value) : value;

    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!value && (hasFilterWithFirstName || hasFilterWithSecondName)) {
      formattedFilters = filtersChanged?.filter(
        (el) => el?.param !== paramSecond && el?.param !== param
      );
    }

    cancelTokenSource.cancel();

    handleFilterOptions(
      param,
      val,
      formattedFilters,
      setFiltersChanged,
      null,
      false,
      '1'
    );
  }

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getMainContent = useCallback(
    (page, size, samePage, updatedBody) => {
      (async () => {
        setLoading(true);
        setCallSettings({ ...callSettings, mainLoading: true });
        const params = filtersChanged
          .map((filter) => filter.urlParam)
          .join('&');
        const urlParam = !isAdminPage && !isAdminMaster
          ? `${environments.catalog}/skus?page=${
              samePage ? page : 1
            }&size=${size}&originId=${getId}&${params}`
          : `${environments.catalog}/skus/platform?page=${
              samePage ? page : 1
            }&size=${size}&${params}`;

        try {
          const response = await axiosInstanceCatalog.get(urlParam);
          const skus = await response.data;
          const skusContent = skus?.content || [];

          setSkus(
            skusContent?.map((sku) => {
              if (updatedBody?.id === sku.id) {
                return {
                  ...sku,
                  skuBloom: {
                    ...sku?.skuBloom,
                    images: {
                      ...sku?.skuBloom?.images,
                      url: updatedBody?.images?.url
                    }
                  }
                };
              }
              return sku;
            })
          );

          if (unifySkusPopover?.skus?.length) {
            setUnifySkusPopover({
              ...unifySkusPopover,
              skus: skus?.content?.filter((sku) =>
                unifySkusPopover?.skus?.map((item) => item.id).includes(sku.id)
              )
            });
          }

          setCallSettings({
            ...callSettings,
            totalPages: skus.totalPages,
            mainLoading: false,
            page: samePage ? page : 1,
            numberOfElements: skus.numberOfElements,
            totalElements: skus.totalElements
          });
          
          setLoading(false);
        } catch (error) {
          if (Axios.isCancel(error)) {
            console.log('Requisição cancelada:', error.message);
          } else {
            console.error('Erro na requisição:', error);
          }

          setCallSettings({
            ...callSettings,
            totalPages: 0,
            mainLoading: false,
            page: samePage ? page : 1,
            numberOfElements: 0,
            totalElements: 0
          });

          setLoading(false);
        }
      })();
    },
    [getId, filtersChanged]
  );

  const getRedirectUrl = useCallback(
    (selectedSkuId) => {
      const redirectUrl = history.location.pathname
        .split(`/${paramsID}`)[0]
        .concat(`/${paramsID}/${selectedSkuId}`);

      return redirectUrl;
    },
    [history.location, paramsID]
  );

  const openSkuModal = (sku) => {
    setSelectedSku({ ...sku, restaurantsIds: sku.nRestaurants });
    setIsEditSku(true);
    handleModal('skuModal', { open: true });
  };

  const getSkuModalOptions = (sku) => {
    dispatch(enable());
    const skuProductDescription = sku?.products?.length
      ? sku?.products[0].products?.description
      : '';

    getOptions(
      `${environments.catalog}/consumptionUnits`,
      'consumptionUnits',
      consumptionUnits,
      setConsumptionUnits,
      getId,
      null,
      null,
      50
    );
    getOptions(
      `${environments.catalog}/unitsMeasurements`,
      'unitsMeasurements',
      unitsMeasurements,
      setUnitsMeasurements,
      getId,
      null,
      null,
      50
    );
    getOptions(
      `${environments.catalog}/storageLocations`,
      'storageLocations',
      storageLocations,
      setStorageLocations,
      getId,
      null,
      null
    );
    getOptions(
      `${environments.catalog}/periodicities`,
      'periodicities',
      periodicities,
      setPeriodicities,
      getId,
      null,
      null
    );
    getOptions(
      `${environments.catalog}/brands`,
      'brands',
      brands,
      setBrands,
      getId,
      { description: sku?.brands?.description },
      null,
      10
    );

    if (isAdminPage || isAdminMaster) {
      getOptions(
        `${environments.catalog}/products/internal/skus`,
        'products',
        products,
        setProducts,
        getId,
        { description: skuProductDescription },
        null,
        10
      );
      getOptions(
        `${environments.catalog}/skus/providers`,
        'skus',
        items,
        setItems,
        getId,
        null,
        null,
        10
      );
      getOptions(
        `${environments.catalogV2}/categories`,
        'categories',
        categories,
        setCategories,
        getId,
        null,
        null,
        10
      );
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        getId,
        null,
        null,
        10
      );
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        getId,
        null,
        null,
        10
      );
    }
  };

  const handleSelectedSku = (event, sku) => {
    getSkuModalOptions(sku);
    const updatedSkuIdUrl = skuId ? '' : `/${sku.id}`
    // event && event?.preventDefault();
    const redirectUrl = `${window.location.href}${updatedSkuIdUrl}`;

    if (isAdminPage || isAdminMaster) {
      const associatedRestaurants = getSelectedStores(sku);
      setSelectedStores(associatedRestaurants);
    }

    if (event.ctrlKey) {
      window.open(`${redirectUrl}`);
    } else {
      window.history.replaceState(null, '', redirectUrl);
      openSkuModal(sku);
    }
  };

  const handleCloseSkuModal = () => {
    setConsumptionUnits([]);
    setUnitsMeasurements([]);
    setStorageLocations([]);
    setPeriodicities([]);
    setBrands('');
    setProducts([]);
    setItems([]);
    setCategories([]);
    setPackageTypes([]);
    setProviders([]);

    if (updateMainList) {
      getMainContent(page, size, true);
      getOptions(
        `${environments.catalog}/products/internal/skus`,
        'products',
        products,
        setProducts,
        getId
      );
    }

    const redirectUrl = `${window.location.href}`
      .split(`/${paramsID}/`)[0]
      .concat(`/${paramsID}`);

    window.history.replaceState(null, '', redirectUrl);
    handleModal('skuModal', { open: false });
  };

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card?.items?.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card?.items?.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    handleModal('technicalSheetModal', { open: true });
  };

  const addSkuSuggestion = () => (
    <Tooltip title="Enviar sugestão de Sku" aria-label="add">
      <span
        onClick={() => {
          handleModal('skuModal', { open: true });
          setIsEditSku(false);
        }}
      >
        <IconButton
          className="p0"
          edge="start"
          color="inherit"
          style={{ outline: 0 }}
          aria-label="add"
        >
          <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
        </IconButton>
      </span>
    </Tooltip>
  );

  function exportSKUS() {
    const url = `${environments.catalog}/skus/xls?originId=${getId}`;

    window.open(url);
  }

  const handleOnSaveSkuPlatform = ({ body, isEditMode }) => {
    const { id } = body;
    const handleResponse = (status, error) => {
      const verb = isEditMode ? 'atualizado' : 'criado';
      if (status) {
        setToastProps({
          severity: 'success',
          customMessage: `SKU ${verb} com sucesso`,
          timeOutOff: true
        });
        setToastOpen(true);
        handleModal('skuModal', { open: false });
        setFiltersChanged(filtersChanged);
        getOptions(
          `${environments.catalog}/products/internal/skus`,
          'products',
          products,
          setProducts,
          getId,
          null,
          null,
          1500
        );
        getMainContent(page, size, true, body);
        return;
      }
      setMsgErrProdDescription({ description: error.data.message, statusCode: error.status })
      setToastProps({
        severity: 'error',
        message: error.response.data.message,
        timeOutOff: true
      });
      setStatusCode(error.status);
      setToastOpen(true);
      handleModal('skuModal', { open: false });
    };

    if (isEditMode) {
      makePut('skuCards', body, handleResponse, `/platform/${id}`);
    } else {
      makePost('skuCards', body, handleResponse, `/platform`);
    }
  };

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const exportExcelSku = () => (
    <Tooltip title="Exportar planilha com SKUS" aria-label="add">
      <IconButton
        className="p0 mr20"
        color="primary"
        aria-label="add"
        component="span"
        onClick={() => {
          exportSKUS();
        }}
      >
        <img style={{ width: '25px' }} src={DownloadIcon} alt="DownloadIcon" />
      </IconButton>
    </Tooltip>
  );

  const fetchRestaurantsData = async (skuId) => {
    const urlParam = `${environments.catalog}/skus/platform/restaurants/${skuId}`;
    const response = await Axios.get(urlParam);
    const data = await response.data;

    return data;
  };

  const handleResponseOriginId = (data) => {
    if (data) {
      setOriginsId(data);
    }
  };

  const handleSelectSkuToUnify = (checked, sku) => {
    const isAlreadySelected = unifySkusPopover?.skus?.find(
      (item) => item.id === sku.id
    );

    if (isAlreadySelected) {
      setUnifySkusPopover({
        ...unifySkusPopover,
        skus: unifySkusPopover.skus.filter((item) => item.id !== sku.id)
      });
    } else {
      setUnifySkusPopover({
        ...unifySkusPopover,
        skus: [...unifySkusPopover.skus, sku]
      });
    }
  };

  const handleUnifySkus = () => {
    setIsLoading(true);

    const body = {
      skus:
        unifySkusPopover?.skus?.map((sku) => ({
          id: sku?.id,
          reference: unifySkusPopover?.refSku === sku?.id
        })) || [],
      user: userInfo?.user
    };

    Axios.put(`${environments.catalog}/skus/platform/unify`, body, config)
      .then(() => {
        setToastOpen(true);
        setToastProps({
          message: 'Skus unificados com sucesso.',
          severity: 'success',
          timeOutOff: true
        });

        setUnifySkusPopover({
          open: false,
          skus: [],
          refSku: ''
        });

        setFiltersChanged(filtersChanged);
        setIsLoading(false);
      })
      .catch((error) => {
        setToastOpen(true);
        setToastProps({
          message: error.response.data.message,
          severity: 'error',
          timeOutOff: true
        });
        setStatusCode(error.response.status);
        setIsLoading(false);
      });
  };

  const handleClosePopover = () => {
    setUnifySkusPopover({
      ...unifySkusPopover,
      open: false
    });
  };

  const getCountSkus = (variationCostValue) => {
    setLoadingCountSkus(true)

    axiosInstanceCatalog
      .get(`${environments.catalog}/skus/platform/variationCost?variationCost=${variationCostValue}?restaurantId=${paramsID}`)
      .then((response) => {
        setCountVariationSkus(response?.data?.count);
        setLoadingCountSkus(false)
      })
      .catch((err) => {
        console.log(err);
        setLoadingCountSkus(false)
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getMainContent(page, size, true);
  }, [getId, filtersChanged, updateControl]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    setCallSettings((prevState) => ({ ...prevState, getId: paramsID }));
    getCountSkus(15)
  }, [paramsID]);

  useEffect(() => {
    if (selectedCard) {
      handleSelectedCard(selectedCard);
    }
  }, [selectedCard]);

  useEffect(() => {
    if (filterRestaurantsSkuSelected && (isAdminPage || isAdminMaster) && !originsId.length) {
      makeGet(
        'restaurants',
        filtersChanged,
        handleResponseOriginId,
        `?page=0&size=5000`
      );
    }
  }, [filterRestaurantsSkuSelected]);

  useEffect(() => {
    filterFields?.forEach((fields) => {
      fields?.forEach(({ urlPath, label, key, optionReference, page }) => {
        if (!urlPath) return;
        getFilterOptions(
          urlPath,
          label,
          key,
          filterFields,
          setFilterFields,
          optionReference,
          getId,
          filtersChanged,
          1
        );
      });
    });
  }, [getId, updateMainList]);

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page
    );
  };

  useEffect(() => {
    if (!storeOptions.length) {
      getOptions(
        `${environments.restaurants}/companies`,
        'restaurants',
        storeOptions,
        setStoreOptions,
        getId
      );
    }
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      const arraySkus = [];
      selectedProduct?.skus?.forEach((skus) => {
        arraySkus.push({
          id: skus.skus ? skus.skus.id : null,
          description: skus.skus ? skus.skus.description : null,
          quantity: skus.skus
            ? parseFloat(skus.skus.quantity).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          unitsMeasurements: skus.skus
            ? skus.skus.unitsMeasurements.abbreviation
            : null,
          purchaseAverage:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseAverage
              : null,
          purchaseLast:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseLast
              : null,
          conversion: skus.skus
            ? parseFloat(skus.conversion).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          priceUc: skus ? skus.priceUc : null
        });
      });

      selectedProduct.skus = arraySkus;
    }

    setEditProducts(selectedProduct);
  }, [selectedProduct]);

  return (
    <Layout>
      <Snackbar
        open={openSuccessDeleteQuotation}
        autoHideDuration={2000}
        onClose={() => setOpenSuccessDeleteQuotation(false)}
      >
        <Alert
          onClose={() => setOpenSuccessDeleteQuotation(false)}
          severity="success"
        >
          Desabilitado com sucesso!
        </Alert>
      </Snackbar>

      <CommonAlert 
        open={!!msgErrProdDescription.description || !!msgErrProdDescription.statusCode}
        onClose={() => {
          setMsgErrProdDescription({ description: '', statusCode: '' })
        }}
        indexMessage={msgErrProdDescription.description}
        statusCode={msgErrProdDescription.statusCode}
      />

      <LocalizationContext.Provider value={{ nameSpace: 'marcas' }}>
        <LocalizationContext.Provider value={{ nameSpace: 'fabricantes' }}>
          <GlobalStyle />

          <TitleHead title="SKUs" />

          <Container className="skusPage">
            <PageTitleAndFilter
              title="SKUs Cadastrados"
              activeFilterFilds={filterFields}
              handleFilter={handleFilter}
              additionalbutton={addSkuSuggestion}
              dynamicJustifyOff
              getFilterhandle={getFilterhandle}
              extrabutton={!isAdminPage && !isAdminMaster ? exportExcelSku : null}
              setFilterOpenedMainPageControl={setFilterOpened}
            />

            {isAdminPage &&
              <section className={`fakeFilterRight ${!filterOpened && 'filterClosed'}`}>
                <span className="bold fakeLabel">
                  Variação acima de:
                </span>
                
                <CurrencyTextField
                  variant="outlined"
                  size="small"
                  currencySymbol="%"
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  decimalPlaces={2}
                  decimalPlacesShownOnFocus={2}
                  type="text"
                  name="variationPercent"
                  fullWidth
                  className="textFieldSmall childTextAlignRight childPr0 labelTextAlignRight"
                  placeholder="Digite aqui..."
                  onBlur={(e) => {
                    setVariationFilterValue(parseFloat(e.target.value?.replace('.', '')?.replace(',', '.')))
                    getCountSkus(parseFloat(e?.target?.value?.replace('.', '')?.replace(',', '.')))

                    if (variationFilter) {
                      handleFilter("variationCost", parseFloat(e.target.value?.replace('.', '')?.replace(',', '.')))
                    }
                  }}
                  value={variationFilterValue}
                  InputProps={{
                    maxLength: 8,
                    style: { fontSize: '11px' }
                  }}
                />

                <span className="variationAfterSymbol">
                  %
                </span>

                <Tooltip
                  title={
                    <div
                      className="dFlex flexColumn"
                      style={{ padding: '4px 2px' }}
                    >
                      <p className="mb0">
                        <b>Preencha o valor da variação</b>
                      </p>
                    </div>
                  }
                  disableHoverListener={!isNaN(variationFilterValue)}
                  aria-label="add"
                >
                  <div 
                    className={`variationFilterButton ${variationFilter && 'active'} ${(isNaN(variationFilterValue) || loadingCountSkus) && 'disabledButton' }`}
                    onClick={() => {
                      if (isNaN(variationFilterValue) || loadingCountSkus) return ;
                      
                      setVariationFilter(!variationFilter)
                      
                      handleFilter("variationCost", !variationFilter ? variationFilterValue : '')
                    }}
                  >
                    {loadingCountSkus ?
                      <CircularProgress size={20} />
                    :
                      <span>
                        {countVariationSkus || 0} SKUs
                      </span>
                    }
                  </div>
                </Tooltip>
              </section>
            }

            <table
              className="desktop"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{ tableLayout: 'fixed' }}
            >
              <thead>
                <tr className="headerTitle">
                  {(isAdminPage || isAdminMaster) && (
                    <th width="2%" className="subtitle">
                      &nbsp;
                    </th>
                  )}

                  <th width="5%" className="subtitle pl12">
                    CÓD.
                  </th>

                  <th width="1%" className="subtitle" />

                  <th width={isAdminPage || isAdminMaster ? '19%' : '24%'} className="subtitle">
                    SKU{' '}
                    <OrderBy
                      filterName="description"
                      handleFilter={handleOrdernationFilter}
                    />
                  </th>

                  <th width="5%" className="subtitle right">
                    QTDE{' '}
                    <OrderBy
                      filterName="quantity"
                      handleFilter={handleOrdernationFilter}
                    />
                  </th>

                  <th width="2%" className="subtitle center">
                    UN.
                  </th>

                  <th width="3%" className="subtitle left p8">
                    &nbsp;
                  </th>

                  <th width="9%" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }} className="subtitle left pr12 pl12" colSpan="1">
                    CATEGORIA
                  </th>

                  <th width="9%" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }} className="subtitle pl12">
                    EAN{' '}
                    <OrderBy
                      filterName="ean"
                      handleFilter={handleOrdernationFilter}
                    />
                  </th>

                  <th width="10%" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }} className="subtitle pl12">
                    MARCA{' '}
                    <OrderBy
                      filterName="brands"
                      handleFilter={handleOrdernationFilter}
                    />
                  </th>

                  <th
                    width={isAdminPage || isAdminMaster ? "20%" : "14%"}
                    colSpan={isAdminPage || isAdminMaster ? "3" : "2"}
                    className="subtitle left p12"
                    style={{
                      borderLeft: 'solid 2px #73004C',
                      background: '#73004C'
                    }}
                  >
                    COMPRAS R$
                  </th>

                  {!isAdminPage && !isAdminMaster && (
                    <th
                      width="7%"
                      className="subtitle left p12"
                      style={{
                        borderLeft: 'solid 1px #4D0073',
                        background: '#4D0073'
                      }}
                    >
                      ESTOQUE
                    </th>
                  )}

                  <th
                    width="11%"
                    colSpan="5"
                    className="subtitle left p12"
                    style={{ borderLeft: 'solid 2px #313347' }}
                  >
                    CATÁLOGO
                  </th>

                  {(isAdminPage || isAdminMaster) && (
                    <th
                      width="2%"
                      className="subtitle left p12"
                      colSpan="1"
                    >
                      &nbsp;
                    </th>
                  )}
                </tr>

                <tr className="headerSubtitle">
                  <th
                    colSpan={(isAdminPage || isAdminMaster) ? 7 : 6}
                  >
                    &nbsp;
                  </th>
                  
                  <th
                    style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                  >
                    &nbsp;
                  </th>

                  <th
                    style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                  >
                    &nbsp;
                  </th>

                  <th
                    style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)', borderRight: 'solid 1px #73004C' }}
                  >
                    &nbsp;
                  </th>

                  {!isAdminPage && !isAdminMaster ?
                    <>
                      <th
                        width="10%"
                        className="subtitle right"
                        style={{ borderLeft: 'solid 2px #73004C' }}
                      >
                        MÉDIA R${' '}
                        <OrderBy
                          filterName="purchaseAverage"
                          handleFilter={handleOrdernationFilter}
                          tinyArrow
                        />
                      </th>

                      <th
                        style={{ borderRight: 'solid 2px #4D0073' }}
                        width="12%"
                        className="subtitle right p12"
                      >
                        ÚLTIMA R${' '}
                        <OrderBy
                          filterName="purchaseLast"
                          handleFilter={handleOrdernationFilter}
                          tinyArrow
                        />
                      </th>
                    </>
                  : (
                    <>
                      <th
                        width="8%"
                        className="subtitle right"
                        style={{ borderLeft: 'solid 2px #73004C' }}
                      >
                        MÍNIMOR${' '}
                        <OrderBy
                          filterName="minCost"
                          handleFilter={handleOrdernationFilter}
                          tinyArrow
                          customMargin="0 0 0 5px"
                        />
                      </th>

                      <th
                        width="8%"
                        className="subtitle right p12 pr0"
                      >
                        MÁXIMO R${' '}
                        <OrderBy
                          filterName="maxCost"
                          handleFilter={handleOrdernationFilter}
                          tinyArrow
                          customMargin="0 0 0 5px"
                        />
                      </th>

                      <th
                        style={{ borderRight: 'solid 2px #4D0073' }}
                        width="7%"
                        className="subtitle right p12"
                      >
                        VARIAÇÃO{' '}
                        <OrderBy
                          filterName="variationCost"
                          handleFilter={handleOrdernationFilter}
                          tinyArrow
                          customMargin="0 0 0 5px"
                        />
                      </th>
                    </>
                  )}

                  {!isAdminPage && !isAdminMaster && (
                    <th
                      width="5%"
                      className="subtitle right p12"
                      style={{
                        borderLeft: 'solid 2px #4D0073',
                        borderRight: 'solid 2px #313347'
                      }}
                    >
                      REAL{' '}
                      <OrderBy
                        filterName="stock"
                        handleFilter={handleOrdernationFilter}
                        tinyArrow
                      />
                    </th>
                  )}

                  <th
                    width="2%"
                    className="subtitle center"
                    style={{ borderLeft: 'solid 2px #313347' }}
                  >
                    <TableIcon icon="production" name="Produtos" />
                  </th>

                  <th 
                    width="2%" 
                    className="subtitle center"
                  >
                    <TableIcon
                      icon={!isAdminPage && !isAdminMaster ? 'card' : 'tag'}
                      name={!isAdminPage && !isAdminMaster ? 'Fichas' : 'Tags'}
                    />
                  </th>

                  <th
                    width={isAdminPage || isAdminMaster ? '4%' : '2%'}
                    className="subtitle center"
                  >
                    <TableIcon icon="provider" name="Fornecedores" />
                  </th>

                  <th 
                    width="2%" 
                    className="subtitle center"
                  >
                    <TableIcon icon="quote" name="Cotações" />
                  </th>

                  {(isAdminPage || isAdminMaster) && 
                    <th width="2%" className="subtitle center">
                      REST.
                    </th>
                  }

                  <th
                    width="2%"
                    className="subtitle center p8"
                  />
                </tr>
              </thead>

              <tbody>
                {loading && (
                  <tr>
                    <th
                      colSpan="19"
                      style={{ margin: '10px', textAlign: 'center' }}
                    >
                      <LinearProgress
                        style={{ height: '7px' }}
                        id="loading"
                        variant="query"
                      />
                    </th>
                  </tr>
                )}

                {skus.length === 0 ? (
                  <tr>
                    <th
                      colSpan="19"
                      style={{ margin: '10px', textAlign: 'center' }}
                    >
                      Nenhum SKU encontrado
                    </th>
                  </tr>
                ) : null}

                {skus.map((sku) => {
                  const lastReceivedProvidersOrdered = sku?.providers?.sort((a, b) => (a.recivedDate < b.recivedDate ? 1 : -1))
                  const lastReceivedProvider = lastReceivedProvidersOrdered?.length ? lastReceivedProvidersOrdered[0] : ''

                  const autoCataloguing = sku.providers.find((item) => item.autoCataloguing)

                  return (
                    <>
                      <tr>
                        {(isAdminPage || isAdminMaster) && (
                          <td className="dFlex alignCenter pr12">
                            <Checkbox
                              checked={unifySkusPopover?.skus?.some(
                                (el) => el.id === sku.id
                              )}
                              size="small"
                              onChange={(_, checked) =>
                                handleSelectSkuToUnify(checked, sku)
                              }
                            />
                          </td>
                        )}

                        <td
                          width="5%"
                          style={{ maxWidth: '60px' }}
                          className="description pl12"
                        >
                          <div className="dFlex">
                            {sku.id ? sku.id : ' '}

                            <span
                              style={{
                                fontSize: '10px',
                                margin: '2px 6px 0px 5px',
                                marginRight:
                                  sku.restaurants != null &&
                                  sku.restaurants.length > 0 &&
                                  sku.restaurants[0].curve != null
                                    ? '6px'
                                    : '19px'
                              }}
                            >
                              {sku.restaurants != null &&
                              sku.restaurants.length > 0 &&
                              sku.restaurants[0].curve != null
                                ? `(${sku.restaurants[0].curve})`
                                : ''}
                            </span>
                          </div>
                        </td>

                        <td
                          width="2%"
                          style={{ maxWidth: '60px' }}
                          className="description"
                        >
                          <div className="suggestionBox dFlex alignCenter justifyEnd mr5">
                            {sku?.suggestionsEan?.length ||
                            sku?.suggestionsBrands?.length ? (
                              <img
                                width="10px"
                                src={ClipboardBlueIcon}
                                alt="Suggestions"
                              />
                            ) : null}
                          </div>
                        </td>

                        <td width="31%" className="description">
                          <div className='dFlex justifyBetween'>
                            <Link
                              onClick={(e) => {
                                handleSelectedSku(e, sku)
                              }}
                              to={getRedirectUrl(sku.id)}
                            >
                              <Tooltip
                                title={sku?.description}
                                placement="bottom-start"
                              >
                                {ValidationLength(
                                  sku.description,
                                  bigScreen ? 35 : 32,
                                  true
                                )}
                              </Tooltip>
                            </Link>
                            {isAdminPage || isAdminMaster && autoCataloguing &&
                              <div>
                                <Tooltip
                                  title='Catalogação automática'
                                  placement="bottom-start"
                                >
                                  <img
                                    width="12px"
                                    src={AutoCataloguing}
                                    alt="autoCataloguing"
                                  />
                                </Tooltip>
                              </div>
                            }
                          </div>
                        </td>

                        <td width="7%" className="description right pl8 ">
                          {(sku.quantity ? sku.quantity : 0).toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 2 }
                          )}
                        </td>

                        <td
                          width="3%"
                          style={{ maxWidth: '80px' }}
                          className="description center"
                        >
                          {sku.unitsMeasurements != null
                            ? sku.unitsMeasurements.abbreviation
                            : null}
                        </td>

                        <td width="2%" className="description">
                          <div
                            className="dFlex alignCenter justifyCenter"
                            onClick={(e) => {
                              isAdminPage || isAdminMaster && handleSelectImage(e, sku?.id);
                            }}
                          >
                            <ImageWrapper
                              width="30px"
                              height="28px"
                              url={sku?.skuBloom?.images?.url}
                              borderType={"EXTERNAL"}
                            />
                            <input
                              id={`fileInput${sku?.id}`}
                              type="file"
                              onChange={(e) => onChangeImage(e, sku?.id)}
                              style={{ display: 'none' }}
                            />
                          </div>
                        </td>

                        <td className="description pr12 pl12" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                          <Tooltip
                            title={sku?.products[0]?.products?.categories?.family}
                            placement="bottom-start"
                          >
                            {ValidationLength(
                              sku?.products?.length
                                ? sku?.products[0]?.products?.categories
                                    ?.description
                                : '-',
                              10
                            )}
                          </Tooltip>
                        </td>

                        <td width="9%" className="description pl12" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                          {sku.ean ? ValidationLength(sku.ean, 10) : '-'}
                        </td>

                        <td width="22%" className="description left pl12" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                          <Tooltip
                            title={
                              <div
                                className="dFlex flexColumn"
                                style={{ padding: '4px 6px 2px' }}
                              >
                                {sku?.brands?.description?.length > 15 && (
                                  <p className="mb2">
                                    {sku?.brands?.description}
                                  </p>
                                )}

                                {sku?.brands?.producers?.description && (
                                  <p className="mb2 dFlex alignCenter">
                                    <span className="bold mr3">Fabricante: </span>

                                    {sku?.brands?.producers?.description}
                                  </p>
                                )}
                              </div>
                            }
                            placement="bottom-start"
                            disableHoverListener={
                              (sku?.brands?.description?.length < 15 ||
                                !sku?.brands?.description) &&
                              !sku?.brands?.producers?.description
                            }
                          >
                            <span
                              className={
                                sku?.brands?.producers?.description &&
                                'cursorPointer'
                              }
                            >
                              {sku?.brands?.description.length > 15
                                ? `${sku?.brands?.description?.substring(
                                    0,
                                    15
                                  )}...`
                                : sku?.brands?.description || '-'}
                            </span>
                          </Tooltip>
                        </td>

                        {!isAdminPage && !isAdminMaster ? (
                          <>
                            <td
                              width="6%"
                              className="description right pr8"
                              style={{ borderLeft: 'solid 2px #73004C' }}
                            >
                              {sku.restaurants != null && sku.restaurants.length > 0
                                ? (sku.restaurants[0].purchaseAverage
                                    ? sku.restaurants[0].purchaseAverage
                                    : 0
                                  ).toLocaleString('pt-br', {
                                    minimumFractionDigits: 3
                                  })
                                : '-'}
                            </td>

                            <td width="7%" className="description right p12" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                              <div style={{marginRight: `${sku.hasPlatformProviders < 1 ? '21px' : '0'}`}}>
                                <Tooltip
                                  title={
                                    <div
                                      className="dFlex flexColumn"
                                      style={{ padding: '4px 2px' }}
                                    >
                                      <p className="mb0">
                                        <b>DATA:</b>{' '}
                                        {moment(
                                          sku?.lastReceivedProvider?.recivedDate
                                        ).format('DD/MM/YYYY')}
                                      </p>

                                      <p className="mb0">
                                        <b>FORNECEDOR:</b>{' '}
                                        {sku?.lastReceivedProvider?.name}
                                      </p>

                                      <p className="mb0">
                                        <b>CNPJ:</b>{' '}
                                        {formatDocumentNumber(
                                          sku?.lastReceivedProvider?.suppliers
                                            ?.cnpjNumber
                                        )}
                                      </p>

                                      <p className="mb0">
                                        <b>EMBALAGEM:</b> E
                                        {sku?.lastReceivedProvider?.quantity}x
                                        {sku?.quantity}
                                        {sku?.unitsMeasurements?.abbreviation}
                                      </p>
                                    </div>
                                  }
                                  disableHoverListener={!sku.lastReceivedProvider}
                                  aria-label="add"
                                >
                                  <span>
                                    {sku.restaurants != null &&
                                    sku.restaurants.length > 0
                                      ? (sku.restaurants[0].purchaseLast
                                          ? sku.restaurants[0].purchaseLast
                                          : 0
                                        ).toLocaleString('pt-br', {
                                          minimumFractionDigits: 3
                                        })
                                      : '-'}
                                  </span>
                                </Tooltip>

                                {sku.hasPlatformProviders > 0 ?
                                  <Tooltip
                                    title={
                                      <div
                                        className="dFlex flexColumn"
                                        style={{ padding: '4px 2px' }}
                                      >
                                        <p className="mb0">
                                          <b>SKU</b> sugerido
                                        </p>

                                        <p className="mb0">
                                          <b>{sku?.hasPlatformProviders} {' '}{sku?.hasPlatformProviders === 1 ? 'Fornecedor disponível' : 'Fornecedores disponíveis'}</b>
                                        </p>
                                      </div>
                                    }
                                    disableHoverListener={!sku.lastReceivedProvider}
                                    aria-label="add"
                                  >
                                    <img
                                      src={BloomIcon}
                                      alt="BloomIcon"
                                      style={{ width: '16px', marginLeft: '5px'}}
                                    />
                                  </Tooltip>
                                :
                                  <div style={{ width: '16px', marginLeft: '5px'}}/>
                                }
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td
                              width="6%"
                              className="description right pr8"
                              style={{ borderLeft: 'solid 2px #73004C' }}
                            >
                              {(sku?.costsInfo?.minCost || 0).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })}
                            </td>

                            <td
                              width="6%"
                              className="description right pr8"
                              style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                            >
                              <Tooltip
                                title={
                                  <div
                                    className="dFlex flexColumn"
                                    style={{ padding: '4px 2px' }}
                                  >
                                    <p className="mb10">
                                      <b>ÚTIMO PREÇO</b>
                                    </p>

                                    <p className="mb0">
                                      <b>
                                        R$ {lastReceivedProvider?.purchaseLastUnitary?.toLocaleString('pt-br', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}
                                      </b>
                                    </p>

                                    <p className="mb0">
                                      <b>
                                        {moment(
                                          lastReceivedProvider?.recivedDate
                                        ).format('DD/MM/YYYY')}
                                      </b>
                                    </p>

                                    <p className="mb0">
                                      <b>{lastReceivedProvider?.name}</b>
                                    </p>
                                  </div>
                                }
                                disableHoverListener={!lastReceivedProvider}
                                aria-label="add"
                              >
                                <span>
                                  {(sku?.costsInfo?.maxCost || 0).toLocaleString('pt-br', {
                                    minimumFractionDigits: 3
                                  })}
                                </span>
                              </Tooltip>
                            </td>

                            <td width="7%" className="description right p12" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                              {(sku?.costsInfo?.variationCost || 0).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })}
                            </td>
                          </>
                        )}

                        {(!isAdminPage && !isAdminMaster) && (
                          <td
                            width="5%"
                            className="description right p12"
                            style={{ borderLeft: 'solid 2px #4D0073' }}
                          >
                            {sku.restaurants != null && sku.restaurants.length > 0
                              ? (sku.restaurants[0].stock
                                  ? sku.restaurants[0].stock
                                  : 0
                                ).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })
                              : '-'}
                          </td>
                        )}

                        <td
                          width="3%"
                          className="description center p8"
                          style={{ borderLeft: 'solid 2px #313347' }}
                        >
                          <span
                            className="lightBlue cursorPointer"
                            onClick={() => {
                              const productId = sku?.products.length
                                ? sku?.products[0]?.products?.id
                                : '';
                                getOptions(
                                  `${environments.catalog}/products/${productId}`,
                                  'products',
                                  selectedProduct,
                                  setSelectedProduct,
                                  getId,
                                  null,
                                  null,
                                  null,
                                  true
                                );
                              setOnlyViewProduct(true);
                              setModalSettings(
                                modalSettings.map((modal) =>
                                  modal.name === 'productsAssociation'
                                    ? { ...modal, open: true }
                                    : modal
                                )
                              );
                              setSkuAssociation(sku);
                              setProductsSku(sku.products)
                            }}
                          >
                            {sku.products != null ? sku.products.length : 0}
                          </span>
                        </td>

                        <td width="3%" className="description center p8" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                          {!isAdminPage && !isAdminMaster ? (
                            <span
                              className="lightBlue cursorPointer"
                              onClick={() => {
                                setModalSettings(
                                  modalSettings.map((modal) =>
                                    modal.name === 'cardsAssociation'
                                      ? { ...modal, open: true }
                                      : modal
                                  )
                                );
                                setSkuAssociation(sku);
                                setSkuCurrentIdAssociation(sku.id);
                              }}
                            >
                              {sku?.countCards ? sku?.countCards : 0}
                            </span>
                          ) : (
                            <Tooltip
                              title={
                                sku.skuBloom?.values?.length
                                  ? 'O sku possui tags'
                                  : 'Esse sku não possui tags'
                              }
                              aria-label="add"
                            >
                              <span
                                className="dFlex alignCenter justifyCenter lightBlue cursorPointer"
                                onClick={() => {
                                  setModalSettings(
                                    modalSettings.map((modal) =>
                                      modal.name === 'skuTags'
                                        ? { ...modal, open: true, sku }
                                        : modal
                                    )
                                  );
                                }}
                              >
                                <div
                                  className="cursorPointer"
                                  style={{
                                    width: 10,
                                    height: 10,
                                    borderRadius: '50%',
                                    background: sku.skuBloom?.values?.length
                                      ? '#5062F0'
                                      : '#A8A8A8'
                                  }}
                                />
                              </span>
                            </Tooltip>
                          )}
                        </td>

                        <td width="3%" className="description center p8" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                          <span
                            className="lightBlue cursorPointer"
                            onClick={() => {
                              setModalSettings(
                                modalSettings.map((modal) =>
                                  modal.name === 'providersAssociation'
                                    ? {
                                        ...modal,
                                        open: true,
                                        sku: sku.description
                                      }
                                    : modal
                                )
                              );
                              setSkuAssociation(sku);
                            }}
                          >
                            {sku.providers != null ? sku.providers.length : 0}
                          </span>
                        </td>

                        <td width="3%" className="description center p8" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                          {handleQuotations(
                            sku,
                            setDialogQuotesSettings,
                            dialogQuotesSettings,
                            setSkuAssociation
                          )}
                        </td>

                        {(!isAdminPage && !isAdminMaster) && (
                          <td width="2%" className="description center p8" style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}>
                            {sku?.restaurants[0]?.control &&
                              <Tooltip title="SKU controlado" aria-label="add">
                                <span
                                  onClick={() => {
                                    targetSkuControl(sku, false)

                                  }}
                                >
                                  <IconButton
                                    className="p0 w100 mAuto"
                                    edge="start"
                                    color="inherit"
                                    style={{ outline: 0 }}
                                    aria-label="add"
                                  >
                                    <img style={{ width: '16px' }} src={checkIcon} alt="AddIcon" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            } 
                            {!sku?.restaurants[0]?.control &&
                              <Tooltip title="SKU não controlado" aria-label="add">
                                <span
                                  onClick={() => {
                                    targetSkuControl(sku, true)
                                  }}
                                >
                                  <IconButton
                                    className="p0 w100 mAuto"
                                    edge="start"
                                    color="inherit"
                                    style={{ outline: 0 }}
                                    aria-label="add"
                                  >
                                    <img style={{ width: '16px' }} src={checkIconGray} alt="AddIcon" />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            } 
                          </td>
                        )}
                        
                        {(isAdminPage || isAdminMaster) && (
                          <td
                            width="5%"
                            className="description center p0"
                            style={{ borderLeft: 'solid 1px rgba(208, 211, 226, 1)' }}
                          >
                            <span
                              className="lightBlue cursorPointer"
                              onClick={async () => {
                                setDialogRestaurantSettings(true);
                                const restaurants = await fetchRestaurantsData(
                                  sku?.id
                                );
                                setDialogRestaurantData(restaurants || []);
                              }}
                            >
                              {sku?.nRestaurants?.split(',').length || 0}
                            </span>
                          </td>
                        )}

                        {(isAdminPage || isAdminMaster) && (
                          <td
                            width="1%"
                            className="description center p8"
                            style={{ borderLeft: 'solid 1px #ECEDF1' }}
                          >
                            <IconButton
                              aria-label="delete"
                              className="w100"
                              onClick={() => (
                                handleDialogClickOpen(), setDeleteData(sku)
                              )}
                              style={{ padding: 0, outline: 0 }}
                            >
                              <DeleteOutline style={{ width: 21 }} />
                            </IconButton>
                          </td>
                        )}
                      </tr>

                      <tr>
                        <td colSpan="19">
                          <hr style={{ marginTop: '-1px' }} />
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>

            <div className="pagination">
              <Pagination
                count={totalPages}
                onChange={(event, page) => {
                  setCallSettings({ ...callSettings, page });
                  getMainContent(page, size, true);
                }}
                variant="outlined"
                shape="rounded"
                size="small"
                color="primary"
              />

              <ItemsPerPage
                numberOfElements={numberOfElements}
                size={totalElements}
              />
            </div>
          </Container>

          {dialogRestaurantSettings && (
            <RestaurantsDialog
              openModal={dialogRestaurantSettings}
              handleClose={() => setDialogRestaurantSettings(false)}
              restaurantData={dialogRestaurantData}
            />
          )}

          {dialogQuotesSettings.open && (
            <DialogQuotes
              userInfo={userInfo}
              open={dialogQuotesSettings.open}
              PaperComponent={PaperComponent}
              dialogQuotesSettings={dialogQuotesSettings}
              setDialogQuotesSettings={setDialogQuotesSettings}
              originId={
                (!isAdminPage && !isAdminMaster) || !filterRestaurantsSkuSelected
                  ? paramsID
                  : originsId?.content?.map((restaurant) => restaurant._id)
              }
              setOpenSuccessDeleteQuotation={setOpenSuccessDeleteQuotation}
            />
          )}

          {modalSettings.find((modal) => modal.name === 'skuTags').open &&
            (isAdminPage || isAdminMaster) && (
              <DialogSkuTags
                open={
                  modalSettings.find((modal) => modal.name === 'skuTags').open
                }
                PaperComponent={PaperComponent}
                dialogTagsSettings={modalSettings}
                setDialogTagsSettings={setModalSettings}
                originId={paramsID}
              />
            )
          }

          {modalSettings.find((modal) => modal.name === 'productsAssociation')
            .open &&
            productInfo?.data && (
              <RegisterProductModal
                dialogOpen={
                  modalSettings.find(
                    (modal) => modal.name === 'productsAssociation'
                  ).open
                }
                isModal
                onlyView={onlyViewProduct}
                consumptionUnitOptions={consumptionUnits}
                periodicityOptions={periodicities}
                storageLocationOptions={storageLocations}
                onClose={() => {
                  setEditProducts('');
                  setModalSettings(
                    modalSettings.map((modal) =>
                      modal.name === 'productsAssociation'
                        ? { ...modal, open: false }
                        : modal
                    )
                  );
                }}
                skuRoute
                productsSku={productsSku}
              />
            )
          }

          {skuCurrentIdAssociation && (
            <DialogCards
              fullScreen={
                modalSettings.find((modal) => modal.name === 'cardsAssociation')
                  .fullScreen
              }
              maxWidth={
                modalSettings.find((modal) => modal.name === 'cardsAssociation')
                  .maxWidth
              }
              open={
                modalSettings.find((modal) => modal.name === 'cardsAssociation')
                  .open
              }
              onCloseFunc={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'cardsAssociation'
                      ? { ...modal, open: false }
                      : modal
                  )
                );
                setSkuAssociation(null);
              }}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              PaperComponent={PaperComponent}
              requestProps={{ skuId: skuCurrentIdAssociation, originId: getId }}
            />
          )}

          <Dialog
            fullScreen={fullScreen}
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              className="modalHeader navyBlue"
              style={{ fontWeight: 'bold', cursor: 'move', fontFamily: 'Lato' }}
              id="draggable-dialog-title"
            >
              <Typography>Deseja realmente excluir esse SKU?</Typography>
            </DialogTitle>

            <DialogContent className="modalContent">
              <Typography>
                Esta ação não pode ser desfeita. Deseja continuar?
              </Typography>
            </DialogContent>

            <DialogActions className="justifyCenter">
              <Button
                className="defaultButton backButton"
                design="outlined"
                onClick={handleDialogClose}
                label="Cancelar"
                color="primary"
              />
              <Button
                className="defaultButton submitButton"
                autoFocus
                design="contained"
                label="Confirmar"
                onClick={() => handleDelete()}
                color="primary"
              />
            </DialogActions>
          </Dialog>

          <Snackbar
            open={deleteSuccess}
            autoHideDuration={2000}
            onClose={() => handleCloseAll(handleDeleteSuccess)}
          >
            <Alert
              onClose={() => handleCloseAll(handleDeleteSuccess)}
              severity="success"
            >
              Sku deletado com sucesso!
            </Alert>
          </Snackbar>

          <Snackbar
            open={deleteError}
            autoHideDuration={2000}
            onClose={() => handleCloseAll(handleDeleteError)}
          >
            <Alert
              onClose={() => handleCloseAll(handleDeleteError)}
              severity="error"
            >
              Ocorreu um erro ao salvar as informações.
            </Alert>
          </Snackbar>

          {canOpenSkuModal ? (
            <SkuModal
              selectedStores={selectedStores}
              storeOptions={storeOptions}
              setSkusList={setSkus}
              mainContent={skus}
              fullScreen
              maxWidth={handleModal('skuModal').maxWidth}
              fullWidth={handleModal('skuModal').fullWidth}
              open={handleModal('skuModal').open}
              data={selectedSku?.providers || []}
              getId={getId}
              editMode={isEditSku}
              setUpdateMainList={setUpdateMainList}
              sku={selectedSku}
              userInfo={userInfo}
              items={items}
              providers={providers}
              packageTypes={packageTypes}
              products={products}
              categories={categories}
              brands={brands}
              storageLocations={storageLocations}
              periodicities={periodicities}
              unitsMeasurements={unitsMeasurements}
              consumptionUnits={consumptionUnits}
              skus={skus}
              onClose={() => handleCloseSkuModal()}
              handleOnSave={(isAdminPage || isAdminMaster || isCatalogProfile) ? handleOnSaveSkuPlatform : false}
              adminCatalogModal={(!!isAdminPage || !!isCatalogProfile || !!isAdminMaster)}
              onlyViewMode={
                (isAdminPage || isCatalogProfile || isAdminMaster) ? filterRestaurantsSkuSelected : isEditSku
              }
              originPage="skus"
            />
          ) : null}

          {(isAdminPage || isAdminMaster) && (
            <ImageCropper
              PaperComponent={PaperComponent}
              modalSettings={modalSettings}
              setModalSettings={setModalSettings}
              getCropData={getCropData}
              obj={currentSkuImage}
              setObj={setCurrentSkuImage}
              setCropper={setCropper}
              handleSelectImage={handleSelectImage}
              onChangeImage={onChangeImage}
            />
          )}

          {selectedCard && (
            <TechnicalSheetDialog
              maxWidth={null}
              open={handleModal('technicalSheetModal').open}
              handleClose={() =>
                handleModal('technicalSheetModal', { open: false })
              }
              cardDataTable={cardDataTable}
              cardTransformation={cardTransformation}
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
              ucPrimarySelectedId={ucPrimarySelectedId}
            />
          )}

          <ProvidersAssociation
            skusList={skus}
            providers={providers}
            skuAssociation={skuAssociation}
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            setSkuAssociation={setSkuAssociation}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'providersAssociation'
                    ? { ...modal, open: false, sku: null }
                    : modal
                )
              );
              setSkuAssociation(null);
              getMainContent(page, size, true);
            }}
          />

          {(isAdminPage || isAdminMaster) && (
            <Tooltip
              className="floatButtonFirst"
              title={
                unifySkusPopover?.skus.length < 2
                  ? 'Selecione ao menos 2 Skus'
                  : 'Unificar Skus'
              }
            >
              <Fab
                color="primary"
                className={`${
                  unifySkusPopover?.skus.length < 2
                    ? 'cursorAuto'
                    : 'cursorPointer'
                }`}
                aria-label="add"
                size="medium"
                style={{ outline: 0 }}
                onClick={() => {
                  if (unifySkusPopover?.skus.length < 2) return;

                  setUnifySkusPopover({
                    ...unifySkusPopover,
                    open: !unifySkusPopover?.open
                  });
                }}
              >
                <Add style={{ color: 'white' }} id="skuCatalogAddItem" />
              </Fab>
            </Tooltip>
          )}

          <UnifySkusPlatform
            validationLength={ValidationLength}
            handleClosePopover={handleClosePopover}
            unifySkusPopover={unifySkusPopover}
            setUnifySkusPopover={setUnifySkusPopover}
            handleUnifySkus={handleUnifySkus}
            isLoading={isLoading}
            handleSelectedSku={handleSelectedSku}
          />

          <CommonAlert
            open={toastOpen}
            onClose={handleCloseToast}
            severity={toastProps?.severity}
            messagePersonal={
              toastProps?.customMessage ? toastProps?.customMessage : null
            }
            indexMessage={toastProps?.message}
            statusCode={statusCode}
          />
        </LocalizationContext.Provider>
      </LocalizationContext.Provider>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...SkusActions, ...ProductsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Skus);
