import React, { useState , useEffect } from 'react';

import { TextField } from '@material-ui/core';
import Axios from 'axios';

import AutoCompleteField from 'components/PageTitleAndFilter/AutoCompleteField/AutoCompleteField';
import DateRangeField from 'components/PageTitleAndFilter/DateRangeField/DateRangeField';
import './style.scss';
import Environment from 'utils/environments';

const environments = Environment(process.env.REACT_APP_ENV);

export default function CustomFilter({
  restaurantId,
  rangeDate,
  setRangeDate,
  productFilter,
  setProductFilter
}) {
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [pdvCodeValue, setPdvCodeValue] = useState('');
  const [debounceTimeList, setDebounceTimeList] = useState([]);

  function ord(a, b) {
    const descriptA = a.description.toUpperCase();
    const descriptB = b.description.toUpperCase();

    if (descriptA < descriptB) {
      return -1;
    }
    if (descriptA > descriptB) {
      return 1;
    }
    return 0;
  }

  const handleFilterCodePdv = (e) => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      setProductFilter({
        ...productFilter,
        code: e?.target?.value || ''
      });
    }, 1500);

    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  const handleDate = (period, date) => {
    const periodFormated = period === 'dateStart' ? 'startDate' : 'endDate';
    setRangeDate({
      ...rangeDate,
      [periodFormated]: date
    });
  };

  const handleAutoComplete = (param, a, value, c) => {
    setProductFilter({
      ...productFilter,
      itemDescription: value?.description || ''
    });
  };

  const handleClear = () => {
    setProductFilter({
      itemDescription: '',
      code: ''
    });
  };

  const initialDate = new Date(rangeDate.startDate);
  initialDate.setDate(initialDate.getDate() + 1);
  const endDate = new Date(rangeDate.endDate);
  endDate.setDate(endDate.getDate() + 1);

  useEffect(async () => {
    const resp = await Axios.get(
      `${environments.catalog}/menu?originId=${restaurantId}&autoComplete=true`
    );

    const data = await resp.data;

    setAutoCompleteOptions([...data.sort(ord)]);
  }, []);

  return (
    <div className="filterWrapper">
      <AutoCompleteField
        urlPath={`${environments.catalog}/menu?originId=${restaurantId}&autoComplete=true`}
        label='Cardápio bloom'
        keyField='product'
        optionReference='description'
        options={autoCompleteOptions}
        filterName='autocomplete'
        placeHolder="Selecione..."
        handleFilter={handleAutoComplete}
        size='medium'
        allowClear
        handleClear={handleClear}
      />

      <TextField
        onChange={(e) => {
          setPdvCodeValue(e?.target?.value)
          handleFilterCodePdv(e)
        }}
        variant="outlined"
        label="Código PDV"
        fullWidth
        size="small"
        value={pdvCodeValue}
        className="textFieldSmall"
        placeholder="Digite aqui..."
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          maxLength: 8,
          style: { fontFamily: 'Lato', fontSize: '12px' }
        }}
      />

      <DateRangeField
        size="tiny"
        handleFilter={(callback) => {
          handleDate(callback[0][0], callback[0][1]);
        }}
        inputSizes={{ tiny: 90 }}
        filterNameStart="dateStart"
        filterNameFinal="dateEnd"
        initialDate={initialDate.getTime()}
        endDate={endDate.getTime()}
        minDate={false}
        maxDate={false}
        hideClearButton
      />
    </div>
  );
}
