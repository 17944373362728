/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';

import { Tooltip, Typography, LinearProgress } from '@material-ui/core';
import { Assignment, Settings } from '@material-ui/icons';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import Clipboard from 'images/icons/objects/clipboards/clipboard.svg';
import useFetch from 'services/useFetch';
import { GlobalStyle, Container } from 'styles/general';
import Environment from 'utils/environments';
import {
  handleFilterOptions,
  handleMultipleFilters,
  getFilterOptions
} from 'utils/handleFilterActions';
import { providerNameValidation } from 'utils/providerNameValidation';
import ValidationLength from 'utils/utils';

export function SugestaoPorFornecedor({ userInfo }) {
  const environment = Environment(process.env.REACT_APP_ENV);
  const originIds = userInfo.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat()
    .map((item) => item.id);
  const [stores, setStores] = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );

  const [filters] = useState([
    {
      param: 'originId',
      value: originIds,
      urlParam: `originId=${originIds}`
    }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const { status, data, loading } = useFetch(
    'suggestionByProvider',
    filtersChanged
  );
  const [ordersSuggestions, setOrdersSuggestions] = useState([]);
  const [hasRupture, setHasRupture] = useState(false);

  const arrFilterFields = [
    {
      label: 'Fornecedores',
      filterName: 'providerId',
      placeHolder: 'Selecione...',
      type: 'autoComplete',
      urlPath: `${environment.catalog}/suppliers/groups`,
      key: 'providersIdList',
      optionReference: 'description',
      options: [],
      size: 'large'
    },
    {
      label: 'SKU',
      filterName: 'skuId',
      placeHolder: 'Selecione...',
      type: 'autoComplete',
      urlPath: `${environment.catalog}/quotations/manager/autocomplete/skus`,
      key: 'skus',
      optionReference: 'description',
      options: [],
      size: 'large'
    },
    {
      label: 'Lojas',
      filterName: 'originId',
      placeHolder: 'Selecione...',
      type: 'autoComplete',
      key: 'stores',
      optionReference: 'name',
      options: stores,
      size: 'large'
    },
    {
      label: 'Projeção de compra',
      filterName: 'isIntention',
      placeHolder: 'Selecione...',
      type: 'autoComplete',
      optionReference: 'isIntention',
      defaultValue: { title: 'Todos', value: '' },
      options: [
        { title: 'Todos', value: '' },
        { title: 'Definido pelo usuário', value: 'true' },
        { title: 'Sugestão Bloom', value: 'false' }
      ],
      size: 'medium_plus'
    }
  ];

  if (userInfo.systemType !== 'QUOTATION') {
    arrFilterFields.push({
      label: 'Ruptura',
      filterName: 'hasRupture',
      type: 'switchField'
    });
  }

  const [filtersFields, setFiltersFields] = useState([arrFilterFields, []]);

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterHandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filtersFields,
      setFiltersFields,
      optionReference,
      originIds,
      false
    );
  };

  const handleFilter = (param, value) => {
    if (param === 'originId' && !value) {
      value = originIds;
    }

    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      true
    );
  };

  const setHasRuptureOnChange = () => {
    setHasRupture((value) => !value);
  };

  useEffect(() => {
    const { content } = data;
    if (!content) return;

    setOrdersSuggestions(content);
  }, [data]);

  return (
    <Layout>
      <TitleHead title="Sugestão por fornecedor" />

      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          activeFilterFilds={filtersFields}
          handleFilter={handleFilter}
          getFilterhandle={getFilterHandle}
          onChange={setHasRuptureOnChange}
          dynamicJustifyOff
          title={
            <Typography style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>
              Pedidos por Fornecedor
            </Typography>
          }
        />
        <table
          style={{ tableLayout: 'fixed' }}
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="2%" className="subtitle" />

              <th width="40%" className="subtitle">
                FORNECEDOR
                <OrderBy
                  filterName="providerName"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="16%" className="subtitle">
                GRUPO
              </th>

              <th width="16%" className="subtitle right p12">
                QUANTIDADE RESTAURANTES
                <OrderBy
                  filterName="quantityRestaurants"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="16%" className="subtitle right p12">
                TOTAL ITENS
                <OrderBy
                  filterName="quantityItems"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="16%" className="subtitle right">
                R$ TOTAL
                <OrderBy
                  filterName="totalValueOrders"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="2%" className="subtitle" />
              <th width="2%" className="subtitle" />
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th
                  colSpan="17"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  <LinearProgress id="loading" variant="query" />
                </th>
              </tr>
            )}

            {ordersSuggestions?.length ? (
              ordersSuggestions.map((orderSuggestion) => (
                <>
                  <tr
                    style={
                      providerNameValidation(orderSuggestion) == 'SEM COTAÇÃO'
                        ? { background: '#FFF4D7', color: '#C38D00' }
                        : {}
                    }
                  >
                    <td width="2%" />

                    <td width="44%" className="description">
                      {ValidationLength(
                        providerNameValidation(orderSuggestion),
                        60
                      )}
                    </td>

                    <td width="16%" className="description">
                      {orderSuggestion?.providerGroup?.description}
                    </td>

                    <td width="16%" className="description right p12">
                      {orderSuggestion.quantityRestaurants}
                    </td>

                    <td width="16%" className="description right p12">
                      <div className="dFlex justifyEnd">
                        {orderSuggestion.quantityItems}

                        <div className="ml8" style={{ width: '20px' }}>
                          {orderSuggestion.isIntention ? (
                            <Tooltip title="Intenção">
                              <Assignment
                                style={{ width: '20px' }}
                                className="lightBlue"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Sugestão">
                              <Settings
                                style={{ width: '20px' }}
                                className="lightBlue"
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </td>

                    <td width="16%" className="description right">
                      {(orderSuggestion?.totalValueOrders || 0).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3 }
                      )}
                    </td>

                    <td width="2%" />

                    <td width="2%">

                      <Tooltip
                        title={
                          providerNameValidation(orderSuggestion) ==
                          'SEM COTAÇÃO'
                            ? 'Ir para cotações'
                            : 'Ver sugestão'
                        }
                      >
                        <Link
                          to={
                            providerNameValidation(orderSuggestion) ==
                            'SEM COTAÇÃO'
                              ? {
                                  pathname: `/cotacao/gestor/${userInfo.companiesActive.id}`,
                                  state: [{ items: orderSuggestion.items }]
                                }
                              : {
                                  pathname: `/pedidos/cadastro/${userInfo.companiesActive.id}`,
                                  state: [
                                    {
                                      source: 'SUGGESTION',
                                      providerId: orderSuggestion.providerId,
                                      ordersId: orderSuggestion.ordersId,
                                      providerGroup:
                                        orderSuggestion?.providerGroup
                                    }
                                  ]
                                }
                          }
                        >
                          <img
                            style={{ width: '12px', height: '14px' }}
                            src={Clipboard}
                            alt="Clipboard"
                          />
                        </Link>
                      </Tooltip>
                    </td>

                    <td width="2%" />
                  </tr>

                  <tr>
                    <td colSpan="10">
                      <hr />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <th
                  colSpan="14"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  Não há nenhuma sugestão
                </th>
              </tr>
            )}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan="20">
                <hr />
              </td>
            </tr>

            <tr>
              <td width="2%" />

              <td
                width="48%"
                className="description"
                style={{ fontWeight: 'bold' }}
              >
                Total
              </td>

              <td width="20%" />
              <td width="16%" />
              <td width="16%" />

              <td
                width="16%"
                className="description right"
                style={{ fontWeight: 'bold' }}
              >
                {ordersSuggestions && ordersSuggestions.length
                  ? ordersSuggestions
                      .reduce(
                        (accumulator, orderSuggestion) =>
                          accumulator +
                          Number(orderSuggestion.totalValueOrders),
                        0
                      )
                      .toLocaleString('pt-br', { minimumFractionDigits: 3 })
                  : '0,000'}
              </td>
              
              <td width="2%" />
            </tr>
          </tfoot>
        </table>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(SugestaoPorFornecedor);
