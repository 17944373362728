export const handleColorType = color => {
  switch (color) {
    case "primary":
      return "color: #004085;";
    case "secondary":
      return "color: #383d41;";
    case "success":
      return "color: #155724;";
    case "danger":
      return "color: #721c24;";
    case "warning":
      return "color: #856404;";
    case "production":
        return "background: #DADDF3;";
    case "info":
      return "color: #0c5460;";
    case "dark":
      return "color: #1b1e21;";
    default:
      return "color: #818182;";
  }
}

export const handleBackgroundType = color => {
  switch (color) {
    case "primary":
      return "background: #cce5ff;";
    case "secondary":
      return "background: #e2e3e5;";
    case "success":
      return "background: #d4edda;";
    case "danger":
      return "background: #f8d7da;";
    case "warning":
      return "background: #fff3cd;";
    case "production":
        return "background: #DADDF3;";
    case "info":
      return "background: #d1ecf1;";
    case "dark":
      return "background: #d6d8d9;";
    default:
      return "background: #fefefe;";
  }
}