const INITIAL = {}

export default function menuInfo(state = INITIAL, action) {
  switch (action.type) {
    case "SET_MENU":
      return (
        {
          action: "EDIT",
          data: action.data
        }
      )
    default:
      return state;
  }
}