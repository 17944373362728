/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import PaperComponent from 'components/PaperComponent';

import StepView from '../../components/StepView';

function SelectCloneStoreDialog({
  open,
  onClose,
  onCancel,
  onConfirm,
  selectedGroupObj,
  selectedCloneStoreObj,
  setSelectedCloneStoreObj
}) {
  return (
    <Dialog
      open={open}
      className="defaultModal fakePopover"
      PaperComponent={PaperComponent}
      onClose={() => {
        onClose();
      }}
    >
      <StepView currentStep="3" />

      <DialogContent
        className="modalContent bgWhite"
        style={{ padding: '11px 11px 16px', minWidth: 300 }}
      >
        <Typography className="bold" style={{ marginBottom: 40 }}>
          Selecione a Loja:
        </Typography>

        <div className="dFlex flexColumn">
          <Autocomplete
            required
            style={{ marginBottom: 40 }}
            options={selectedGroupObj?.group?.subgroups}
            value={selectedCloneStoreObj?.subgroup?.description}
            getOptionLabel={(option) => option?.description}
            onChange={(_, value) => {
              setSelectedCloneStoreObj({
                ...selectedCloneStoreObj,
                subgroup: value,
                store: ''
              });
            }}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params?.description}
              </Typography>
            )}
            className="autocompleteSmall"
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                required
                name="subgroup"
                label="Subgrupo"
                variant="outlined"
                placeholder="Selecione..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />

          <Autocomplete
            key={Math.random()}
            options={selectedCloneStoreObj?.subgroup?.stores}
            value={selectedCloneStoreObj?.store}
            disabled={!selectedCloneStoreObj?.subgroup}
            getOptionLabel={(option) => option?.description}
            onChange={(_, value) => {
              setSelectedCloneStoreObj({
                ...selectedCloneStoreObj,
                store: value
              });
            }}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params?.description}
              </Typography>
            )}
            className="autocompleteSmall"
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                required
                name="store"
                label="Loja"
                variant="outlined"
                placeholder="Selecione..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </div>
      </DialogContent>

      <DialogActions className="dFlex justifyEnd pb10">
        <Button
          className="defaultButton backButton mr8"
          autoFocus
          onClick={() => {
            onCancel ? onCancel() : onClose();
          }}
        >
          Cancelar
        </Button>

        <Button
          className={`defaultButton ${
            !selectedCloneStoreObj?.store ? 'backButton' : 'submitButton'
          } ml0`}
          disabled={!selectedCloneStoreObj?.store}
          onClick={() => {
            onConfirm();
          }}
        >
          Avançar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectCloneStoreDialog;
