/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format } from 'date-fns';

import AutoCompleteField from 'components/PageTitleAndFilter/AutoCompleteField/AutoCompleteField';
import makeGet from 'services/useFetch/makeGet';
import makePost from 'services/useFetch/makePost';

import { MobileDialogContent } from './style';

function DialogItemNote({
  handleOpenItemNote,
  setHandleOpenItemNote,
  order,
  receiverData,
  setStatusCard,
  handleChangeStatus
}) {
  const [IsNewOrder, setIsNewOrder] = useState(false);
  const [selectedReceiveDate, setSelectedReceiveDate] = useState(Date.now());
  const [providersSku, setProvidersSku] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [contactTel, setContactTel] = useState('');
  const [providerId, setProviderId] = useState('');
  const [newOrderDate, setNewOrderDate] = useState(new Date());
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastError, setToastError] = useState(false);

  const handleCreateNewOrder = () => {
    function handleResponseNewOrder(status, response) {
      if (status) {
        setToastSuccess(true);
        setIsNewOrder(false);
      } else {
        setToastError(true);
        setIsNewOrder(false);
      }
    }

    const body = {
      user: receiverData.user,
      status: 'PENDING',
      statusPaymentDocument: 'WAITING',
      quotation: false,
      orderDate: format(new Date(), 'yyyy/MM/dd'),
      deliveryDate: format(newOrderDate, 'yyyy/MM/dd'),
      providerId: inputValue.providerId,
      contact: `55${contactTel
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/\-/g, '')
        .replace(/\ /g, '')}`,
      type: 'M',
      ordersList: [receiverData]
    };
    handleChangeStatus('NOT RECEIVED');
    makePost('ordersEndPoint', body, handleResponseNewOrder);
  };

  const handleReceiveDateChange = (value) => {
    setSelectedReceiveDate(value);
  };

  const handleNewOrderDateChange = (value) => {
    setNewOrderDate(value);
  };

  const getAutoCompleteOptions = () => {
    const handleResponseProviders = (response) => {
      const { content = [] } = response;
      const filteredContent = content.map((n) => ({
        ...n,
        description: n.fantasyName
      }));
      setProvidersSku(filteredContent);
    };
    makeGet('providersQuotes', [], handleResponseProviders);
  };

  const getSelectedOptions = (filterName, value, data) => {
    setProviderId(data.providerId);
  };

  const handleCloseToast = () => {
    setToastSuccess(false);
    setToastError(false);
  };

  useEffect(() => {
    const id = `/${providerId}`;

    const handleResponseProviders = (response) => {
      const { contacts = [] } = response;
      const filteredContacts = contacts.map((n) => ({
        ...n,
        name: n.fullName,
        contact: n.telephoneNumber
      }));
      setContacts(filteredContacts);
    };
    makeGet('providersQuotes', [], handleResponseProviders, id);
  }, [providerId]);

  return (
    <Dialog
      className="defaultModal mobileModal"
      fullWidth
      maxWidth="sm"
      open={handleOpenItemNote}
      aria-labelledby="draggable-dialog-title"
    >
      <Snackbar
        open={toastSuccess}
        autoHideDuration={2000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity="success">
          Novo pedido criado com sucesso
        </Alert>
      </Snackbar>

      <Snackbar
        open={toastError}
        autoHideDuration={2000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity="error">
          Erro ao criar o novo pedido
        </Alert>
      </Snackbar>

      <DialogTitle
        className="modalHeader navyBlue bold cursorMove textAlignCenter"
        id="draggable-dialog-title"
      >
        <Typography>{order?.sku?.description}</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setHandleOpenItemNote(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        className="modalContent p0"
        style={{ backgroundColor: 'white' }}
      >
        <MobileDialogContent style={{ height: '100%' }}>
          <div>
            <div className="MobileButtons">
              <div>
                <Button
                  variant="contained"
                  style={{
                    padding: '0px',
                    boxShadow: 'none',
                    background: 'transparent',
                    color: '#F2B90D',
                    borderRadius: '8px',
                    textTransform: 'initial',
                    height: '43px',
                    border: '1px solid #F2B90D',
                    width: '136px',
                    marginRight: '8px',
                    fontWeight: '700'
                  }}
                  onClick={() => {
                    handleChangeStatus('NOT RECEIVED');
                    setHandleOpenItemNote(false);
                  }}
                >
                  Não recebido
                </Button>

                <Button
                  variant="contained"
                  style={{
                    padding: '0',
                    boxShadow: 'none',
                    background: 'transparent',
                    color: '#F53D4C',
                    borderRadius: '8px',
                    border: 'solid 1px #F53D4C',
                    textTransform: 'initial',
                    fontFamily: 'Lato',
                    height: '43px',
                    width: '136px',
                    fontWeight: '700'
                  }}
                  onClick={() => {
                    handleChangeStatus('RETURNED');
                    setHandleOpenItemNote(false);
                  }}
                >
                  Devolver
                </Button>
              </div>
              
              <Button
                variant="contained"
                color="primary"
                style={{
                  boxShadow: 'none',
                  color: '#FFFFFF',
                  borderRadius: '8px',
                  textTransform: 'initial',
                  fontFamily: 'Lato',
                  height: '40px'
                }}
                fullWidth
                onClick={() => {
                  handleChangeStatus('RECEIVED');
                  setHandleOpenItemNote(false);
                }}
              >
                Receber
              </Button>
            </div>

            <p
              onClick={() => setIsNewOrder(!IsNewOrder)}
              style={{
                textAlign: 'center',
                margin: '10px 0',
                textDecoration: 'underline',
                fontWeight: '700'
              }}
            >
              Novo Pedido
            </p>

            <hr />

            {IsNewOrder ? (
              <div className="modalReceiverForm">
                <div>
                  <AutoCompleteField
                    style={{
                      fontSize: '12px',
                      lineHeight: '16px',
                      width: '100%'
                    }}
                    options={providersSku}
                    getFilterhandle={getAutoCompleteOptions}
                    renderInput="Selecione"
                    inputSizes={{
                      small: 280
                    }}
                    size="small"
                    handleFilter={getSelectedOptions}
                    filterName="justification"
                    placeHolder="Fornecedores"
                  />
                </div>

                <div style={{ margin: '10px 0px' }}>
                  <AutoCompleteField
                    style={{
                      fontSize: '12px',
                      lineHeight: '16px',
                      width: '100%'
                    }}
                    options={contacts}
                    renderInput="Selecione"
                    inputSizes={{
                      small: 280
                    }}
                    size="small"
                    handleFilter={() => {}}
                    filterName="justification"
                    disable={contacts.length == 0}
                    placeHolder="Contatos"
                  />
                </div>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    size="small"
                    className="dateSmall"
                    inputVariant="outlined"
                    views={['year', 'month', 'date']}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    value={newOrderDate}
                    onChange={handleNewOrderDateChange}
                  />
                </MuiPickersUtilsProvider>
              </div>
            ) : (
              <div />
            )}
          </div>
          
          {IsNewOrder ? (
            <Button
              className="receive"
              variant="contained"
              fullWidth
              onClick={handleCreateNewOrder}
              style={{
                background: '#5062F0',
                color: '#FFFFFF',
                borderRadius: '8px',
                textTransform: 'initial',
                fontFamily: 'Lato',
                fontWeight: '700',
                height: '40px',
                margin: '20px 0'
              }}
            >
              Salvar
            </Button>
          ) : (
            ''
          )}
        </MobileDialogContent>
      </DialogContent>
    </Dialog>
  );
}

export default DialogItemNote;
