/* eslint-disable no-unused-expressions */
import React, { useState, useCallback, useEffect } from 'react';

import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BlueSelectBox from 'components/BlueSelectBox';
import { ConfigOptions } from 'components/MainInfoCard/utils';
import VisionsEditMode from 'components/MainInfoCard/VisionsEditMode';
import * as UserActions from 'store/actions/user';

import EditModeHeader from './EditModeHeader';
import {
  SubHomeHeader,
  StyledSection,
  ChildrensContainer,
  HeaderTitle,
  ConfigIconsContainer,
  ConfigIcon
} from './index.style.js';

function MainHomeContainer({
  companiesActive,
  stores,
  cardsInfo,
  resetCardInfo,
  setCardInfo,
  children,
  lastDaysOptions,
  lastDayOption,
  handleLastDayOption,
  userInfo,
  updateRestaurantSelected,
  setFilterRestaurants,
  setFilterRestaurantName
}) {
  const handleFullScreen = useFullScreenHandle();
  const [openEditMode, setOpenEditMode] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const storeOptions = useState(
    [
      { language: 'pt-BR', id: stores.map((item) => item.id), name: 'Todos' },
      userInfo.companies.groups
        .reduce((acc, group) => [...acc, group.subGroups], [])
        .flat()
        .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
        .flat()
    ].flat()
  );
  const [restaurantStores, setRestaurantStores] = useState([
    stores.find((item) => item.id === userInfo?.companiesActive?.id)
  ]);

  const handleClick = ({ resetInfo }) => {
    if (resetInfo) resetCardInfo();
    setOpenEditMode(!openEditMode);
  };

  const reportChange = useCallback((state) => {
    if (state === true) {
      setFullScreen(true);
    } else if (state === false) {
      setFullScreen(false);
    }
  });

  useEffect(() => {
    setRestaurantStores([
      stores.find((item) => item.id === userInfo?.companiesActive?.id)
    ]);
    setFilterRestaurants([
      stores.find((item) => item.id === userInfo?.companiesActive?.id).id
    ]);
  }, [updateRestaurantSelected, userInfo.companiesActive]);

  return (
    <FullScreen handle={handleFullScreen} onChange={reportChange}>
      <div
        className={`indicatorsPage dFlex alignCenter justifyCenter ${
          fullScreen ? 'alignCenter' : 'alignStart'
        } ${fullScreen && 'p20'}`}
        style={{ backgroundColor: '#fafafa', width: '100%', height: '100%' }}
      >
        <StyledSection className="w100">
          <SubHomeHeader>
            {openEditMode ? (
              <EditModeHeader handleClick={handleClick} />
            ) : (
              <>
                <HeaderTitle className="headerTitle">
                  <p
                    className="mb0 bold"
                    style={{ fontSize: 16, lineHeight: 1.2 }}
                  >
                    Indicadores
                  </p>

                  <div className="dFlex">
                    <div className="dFlex alignEnd mr20 pRelative">
                      <span
                        className="bold"
                        style={{ lineHeight: 1.3, marginRight: 15 }}
                      >
                        RESTAURANTES
                      </span>

                      <Autocomplete
                        className="autocompleteSmall multipleNoExpand"
                        multiple
                        disablePortal
                        limitTags={1}
                        style={{ width: 330 }}
                        options={storeOptions[0]}
                        value={restaurantStores}
                        size="small"
                        getOptionLabel={(option) => option?.name}
                        disableCloseOnSelect
                        getOptionDisabled={(option) => {
                          if (
                            restaurantStores.some(
                              (el) => el.name === 'Todos'
                            ) &&
                            option.name !== 'Todos'
                          ) {
                            return true;
                          }
                        }}
                        onChange={(e, value) => {
                          if (value.length) {
                            if (
                              value.filter((item) => item.name === 'Todos')
                                .length
                            ) {
                              setFilterRestaurants([
                                value.find((item) => item.name === 'Todos').id
                              ]);
                              setRestaurantStores([
                                value.find((item) => item.name === 'Todos')
                              ]);
                              setFilterRestaurantName('Todos');
                            } else {
                              setFilterRestaurants(
                                value?.map((item) => item?.id)
                              );
                              setRestaurantStores(value);
                              setFilterRestaurantName('');

                              value.length === 1 &&
                                companiesActive(
                                  stores.find(
                                    (item) => item.id === value[0]?.id
                                  ).id,
                                  stores.find(
                                    (item) => item.id === value[0]?.id
                                  ).name,
                                  stores.find(
                                    (item) => item.id === value[0]?.id
                                  ).index,
                                  stores.find(
                                    (item) => item.id === value[0]?.id
                                  ).segment
                                );
                            }
                          } else {
                            setFilterRestaurants([
                              stores.find(
                                (item) =>
                                  item.id === userInfo?.companiesActive?.id
                              ).id
                            ]);
                            setRestaurantStores([
                              stores.find(
                                (item) =>
                                  item.id === userInfo?.companiesActive?.id
                              )
                            ]);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={params.id}
                            type="text"
                            name="products"
                            placeholder="Digite aqui..."
                            variant="outlined"
                            required
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                          >
                            {params?.name}
                          </Typography>
                        )}
                      />
                    </div>

                    <div className="dFlex alignEnd">
                      <span className="bold" style={{ lineHeight: 1.3 }}>
                        PERÍODO
                      </span>

                      <BlueSelectBox
                        options={lastDaysOptions}
                        option={lastDayOption}
                        handleOption={handleLastDayOption}
                      />
                    </div>
                  </div>
                </HeaderTitle>

                <ConfigIconsContainer>
                  <ConfigIcon
                    src={ConfigOptions.visualization}
                    onClick={() => {
                      if (fullScreen) {
                        handleFullScreen.exit();
                      } else {
                        handleFullScreen.enter();
                      }

                      setFullScreen(!fullScreen);
                    }}
                  />
                  <ConfigIcon src={ConfigOptions.edit} onClick={handleClick} />
                </ConfigIconsContainer>
              </>
            )}
          </SubHomeHeader>

          {openEditMode ? (
            <VisionsEditMode cardsInfo={cardsInfo} setCardInfo={setCardInfo} />
          ) : (
            <ChildrensContainer>{children}</ChildrensContainer>
          )}
        </StyledSection>
      </div>
    </FullScreen>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(null, mapDispatchToProps)(MainHomeContainer);
