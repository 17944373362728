/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { Tooltip } from '@material-ui/core';

import selectorInputArrow from 'images/icons/arrows/selectorInputArrow.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

import { SelectIcon } from './index.style';

function InternalList({
  data,
  ValidationItem,
  setOpenCollapseListItens = () => {},
  openCollapseListItens = [],
  colorStyleOff = false,
  selectedSku,
  setSelectedSku,
  modalSettings,
  setModalSettings,
  getId
}) {
  const colorStyle =
    data.orderItems.length &&
    data?.orderItems[0]?.sku?.notValidated &&
    !colorStyleOff
      ? '#FF0000'
      : '#5062F0';
  const environments = Environment(process.env.REACT_APP_ENV);
  const [openTreeList, setOpenTreeList] = useState(false);

  const handleOpenCollapse = () => {
    data.isCollapsedList = !data.isCollapsedList;
    openCollapseListItens.shift();
    setOpenCollapseListItens([
      ...openCollapseListItens,
      data.orderItems[0]?.sku?.id
    ]);
    setOpenTreeList(data.isCollapsedList);
  };

  return (
    <>
      {!(data.orderItems.length > 1) && (
        <>
          <td className="description" style={{ color: `${colorStyle}` }}>
            <Tooltip
              disableHoverListener={colorStyle !== '#FF0000'}
              placement="right-end"
              title={
                <p className="mb0" style={{ padding: '4px 6px' }}>
                  Sku não catalogado
                </p>
              }
            >
              <div
                className="cursorPointer"
                onClick={() => {
                  getOptions(
                    `${environments.catalog}/skus/${data?.orderItems[0]?.sku?.id}`,
                    'skus',
                    selectedSku,
                    setSelectedSku,
                    getId,
                    null,
                    null,
                    null,
                    true
                  );
                  setModalSettings(
                    modalSettings.map((modal) =>
                      modal.name === 'skuModal'
                        ? { ...modal, open: true }
                        : modal
                    )
                  );
                }}
              >
                {data.orderItems?.length > 0
                  ? ValidationItem(data?.orderItems[0]?.sku, 25)
                  : null}
              </div>
            </Tooltip>
          </td>

          <td className="description right">
            {data.orderItems?.length > 0
              ? Number(
                  data?.orderItems[0]?.costs?.quantityTotal
                    ? data?.orderItems[0]?.costs?.quantityTotal
                    : data?.orderItems[0]?.costs?.quantity
                ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
              : null}
          </td>

          <td className="description center">
            {data.orderItems?.length > 0 && data.orderItems[0]?.consumptionUnits
              ? data.orderItems[0]?.consumptionUnits?.abbreviation
              : data.orderItems.length > 0
              ? data.orderItems[0]?.unitsMeasurements?.abbreviation
              : null}
          </td>

          <td className="description right">
            {data.orderItems?.length > 0
              ? data?.orderItems[0]?.sku && !data?.orderItems[0]?.sku?.id
                ? Number(
                    data?.orderItems[0]?.costs?.packageValue
                  ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
                : data?.orderItems[0]?.costs?.unitaryValue
                ? Number(
                    data?.orderItems[0]?.costs?.unitaryValue
                  ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
                : '0,000'
              : null}
          </td>

          <td className="description right pr8">
            {(data?.totalOrderAmount
              ? Number(data.totalOrderAmount)
              : data?.orderItems[0]?.costs?.total
              ? parseFloat(data?.orderItems[0]?.costs?.total)
              : 0
            ).toLocaleString('pt-br', { minimumFractionDigits: 3 })}
          </td>
        </>
      )}

      {data.orderItems.length > 1 && (
        <>
          <td
            className="itensSizeOrdersPage"
            style={{ display: 'inline-block' }}
            onClick={handleOpenCollapse}
          >
            <SelectIcon
              src={selectorInputArrow}
              alt="selectorInputArrow"
              upArrow={openTreeList}
            />
            <b style={{ marginLeft: '5px', fontSize: '12px' }}>
              {`${data.orderItems.length} itens`}
            </b>
          </td>

          <td className="description right" />
          <td className="description center" />
          <td className="description right" />

          <td className="description right pr8">
            {(data.totalOrderAmount
              ? Number(data.totalOrderAmount)
              : data.orderItems[0].costs.total
              ? parseFloat(data.orderItems[0].costs.total)
              : 0
            ).toLocaleString('pt-br', { minimumFractionDigits: 3 })}
          </td>
        </>
      )}
    </>
  );
}

export default InternalList;
