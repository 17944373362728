import styled from 'styled-components'

export const ConsumeDialogHead = styled.div`
  background-color: #F2F3F8;
  color: #454A67;
  padding: 4px 12px;
  p {
    margin: 0;
    font-size: 12px;
  }
`
export const ConsumeDialogCard = styled.div`
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    p {
      margin: 0;
      color: #1F2445;
      font-size: 12px;
    }
  } 

  hr {
    margin: 4px 12px;
  }
`

export const ConsumeDialogTotal = styled.div`
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px 8px 12px;
  }

  p {
    margin: 0;
    color: #1F2445;
    font-size: 16px;
    font-weight: 700;
  }
`
