const calcNewValues = (parent) => {
  const getSalesAverage = () => {
    const values = parent.menu
      .map((item) => item.salesAverage)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr) / values.length;
  };

  const getSalesAverageDiscount = () => {
    const values = parent.menu
      .map((item) => item.salesAverageDiscount)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr) / values.length;
  };

  const getPercentageDiscount = () => {
    const values = parent.menu
      .map((item) => item.percentageDiscount)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr) / values.length;
  };

  const getQuantity = () => {
    const values = parent.menu
      .map((item) => item.quantity)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr);
  };

  const getSalesQuantityAverageTotal = () => {
    const values = parent.menu
      .map((item) => item.salesQuantityAverageTotal)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr);
  };

  const getMargin = () => {
    const values = parent.menu
      .map((item) => item.margin)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr) / values.length;
  };

  const getCostTotal = () => {
    const values = parent.menu
      .map((item) => item.costTotal)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr);
  };

  const getCost = () => {
    const values = parent.menu
      .map((item) => item.cost)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr);
  };

  const getCMV = () => {
    const values = parent.menu
      .map((item) => item.cmv)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr);
  };

  const getMKP = () => {
    const values = parent.menu
      .map((item) => item.mkp)
      .filter((i) => i !== null && i !== undefined);

    if (!values.length) return 0;

    return values.reduce((acc, curr) => acc + curr);
  };

  parent.salesAverage = getSalesAverage();
  parent.salesAverageDiscount = getSalesAverageDiscount();
  parent.percentageDiscount = getPercentageDiscount();
  parent.quantity = getQuantity();
  parent.salesQuantityAverageTotal = getSalesQuantityAverageTotal();
  parent.margin = getMargin();

  parent.costTotal = getCostTotal();
  parent.cost = getCost();
  parent.cmv = getCMV();
  parent.mkp = getMKP();
};

const findAndReplaceCategory = (
  data,
  action,
  newData,
  newDataMenuId = 0,
  localStates,
  setState
) => {
  const { editData, unitsMeasurements, periods, cards } = localStates;

  const replaceMenuLocation = (menuItem) => {
    const findToReplace = (parent) => {
      if (newData.categoryId === parent.id) {
        const alreadyPushed = parent.menu.find((m) => m.id === editData.id);
        if (alreadyPushed) return;
        menuItem.categoryId = newData.categoryId;
        parent.menu.push(menuItem);
        calcNewValues(parent);
        return;
      }

      if (parent.children) {
        if (parent.children.length) {
          parent.children.forEach((c) => {
            findToReplace(c);
          });
        }
      }
    };

    const removeItem = (parent) => {
      if (parent.menu.length > 0) {
        const newMenu = parent.menu.filter((p) => p.categoryId === parent.id);
        parent.menu = newMenu;
      }

      if (parent.children) {
        if (parent.children.length) {
          parent.children.forEach((p) => {
            removeItem(p);
          });
        }
      }
    };

    data.forEach((element) => {
      findToReplace(element);
    });

    data.forEach((element) => {
      removeItem(element);
    });
  };

  const actionsApplier = (element) => {
    const actions = {
      addOnMenu: (element) => {
        newData.pdv = {};
        element.menu = [...element.menu, newData];
      },
      editMenuDescription: (element) => {
        const menuItem = element.menu.find((menu) => menu.id === editData.id);

        if (menuItem) {
          menuItem.description = newData.description;
          const {
            unitsMeasurementsId,
            period,
            pdv,
            startDateCurrent,
            endDateCurrent,
            cardsId
          } = newData;

          const newUnitsMeasurements = unitsMeasurements.find(
            (i) => i.id === unitsMeasurementsId
          );
          const newPeriods = periods.filter((p) => {
            const n = period.find((m) => m.periodId === p.id);
            return n;
          });

          menuItem.unitsMeasurements = newUnitsMeasurements;
          menuItem.periods = newPeriods;
          menuItem.pdv = pdv;
          menuItem.startDateCurrent = startDateCurrent;
          menuItem.endDateCurrent = endDateCurrent;

          if (cardsId) {
            const newCards = cards.find((card) => card.id === cardsId);
            menuItem.cards = newCards;
          } else {
            menuItem.cards = null;
          }
          if (newData.categoryId !== editData.categoryId) {
            replaceMenuLocation(menuItem);
          }
          setState({ element });
        }
      }
    };
    actions[action](element);
  };

  const getChildren = (parent) => {
    if (parent?.menu) {
      if (parent?.menu.length) {
        if (parent?.id === editData?.categories?.id) {
          actionsApplier(parent);
          return;
        }
      }
    }

    if (parent?.children) {
      if (parent?.id === editData?.categories?.id) {
        actionsApplier(parent);
        return parent;
      }

      if (parent?.children?.length) {
        parent?.children?.forEach((c) => {
          getChildren(c);
        });
      }
    }
  };

  data.forEach((element) => {
    getChildren(element);
  });
};

export default findAndReplaceCategory;
