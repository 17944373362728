/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';

import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Alert, Pagination } from '@material-ui/lab';
import axios from 'axios';
import { addHours, isBefore, startOfToday } from 'date-fns';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import EditPainIcon from 'images/icons/objects/pencils/editPain.svg';
import StarIcon from 'images/icons/objects/stars/starIcon.svg';
import StarIconBlue from 'images/icons/objects/stars/starIconBlue.svg';
import StoreIcon from 'images/icons/objects/stores/storeIcon.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import DeleteBlancIcon from 'images/icons/signals/removesAndDeletes/deleteBlanc.svg';
import getOptions from 'services/getOptions';
import * as OrderActions from 'store/actions/order';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import { providerNameValidation } from 'utils/providerNameValidation';
import ValidationLength from 'utils/utils';

import { TrStyled } from './index.style';
import InternalList from './InternalList';
import ApproveOrdersList from './platform/ApproveOrdersList';


const useStyles = makeStyles((theme) => ({
  inputRoot: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  input: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  totalKey: {
    marginBottom: '0px',
    fontFamily: 'Lato',
    textAlign: 'right',
    fontSize: '14px',
    padding: '0'
  },
  totalValue: {
    marginBottom: '0px',
    fontFamily: 'Lato',
    textAlign: 'right',
    fontSize: '14px',
    padding: '0'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const handleColorType = (color) => {
  switch (color) {
    case 'primary':
      return 'color: #004085;';
    case 'secondary':
      return 'color: #383d41;';
    case 'success':
      return 'color: #155724;';
    case 'danger':
      return 'color: #721c24;';
    case 'warning':
      return 'color: #856404;';
    case 'info':
      return 'color: #0c5460;';
    case 'dark':
      return 'color: #1b1e21;';
    default:
      return 'color: #818182;';
  }
};

const handleBackgroundType = (color) => {
  switch (color) {
    case 'primary':
      return 'background: #cce5ff;';
    case 'secondary':
      return 'background: #e2e3e5;';
    case 'success':
      return 'background: #d4edda;';
    case 'danger':
      return 'background: #f8d7da;';
    case 'warning':
      return 'background: #fff3cd;';
    case 'info':
      return 'background: #d1ecf1;';
    case 'dark':
      return 'background: #d6d8d9;';
    default:
      return 'background: #fefefe;';
  }
};

const ButtonChip = styled(Chip)`
  width: 80px;
  height: 20px;
  font-family: Lato;
  font-size: 11px;
  margin: 0;
  padding: 0;
  line-height: 35px;
  cursor: pointer;
  ${({ background }) => handleBackgroundType(background)};
  ${({ color }) => handleColorType(color)};
  :hover {
    ${({ background }) => handleBackgroundType(background)};
    ${({ color }) => handleColorType(color)};
  }
`;

export function ValidationItem(data, lenght) {
  if (data?.description?.length > lenght) {
    return (
      <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={
          data?.notValidated ? 'Este item não foi catalogado' : data.description
        }
      >
        <span
          style={{ border: 'none', fontFamily: 'Lato' }}
          align="left"
          variant="body"
        >
          {`${data.description.substring(0, lenght)}...`}
        </span>
      </Tooltip>
    );
  }
  return (
    <td
      style={{ border: 'none', fontFamily: 'Lato' }}
      align="left"
      variant="body"
    >
      {data?.description}
    </td>
  );
}

export function OrdersList({ userInfo, setOrder, createOrder }) {
  const originIds = userInfo.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat()
    .map((item) => item.id);
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const environments = Environment(process.env.REACT_APP_ENV);
  const [state, setState] = useState('');
  const { id: paramsID } = useParams();
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusCode, setStatusCode] = useState();
  const items = JSON.parse(localStorage.getItem('token'));
  const profileType = items.data.profile[0][0].name;
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 50,
    filters: [
      {
        param: 'originId',
        urlParam: `originId=${
          profileType === 'quoter'
            ? originIds
            : profileType === 'admin'
            ? ''
            : paramsID
        }`,
        value: `${
          profileType === 'quoter'
            ? originIds
            : profileType === 'admin'
            ? ''
            : paramsID
        }`
      }
    ],
    numberOfElements: 0,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    filters,
    numberOfElements,
    totalElements
  } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [collapseSettings, setCollapseSettings] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [deleteError, handleDeleteError] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [open, setOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentTab, setCurrentTab] = useState(
    profileType === 'admin' ? 'approveList' : 'orderList'
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const id = open ? 'simple-popover' : undefined;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openCollapseListItens, setOpenCollapseListItens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [providerOptions, setProviderOptions] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [selectedSku, setSelectedSku] = useState('');
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'skuModal', open: false, fullScreen: false, maxWidth: 'sm' },
    {
      id: 2,
      name: 'manualOrdersModal',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      totalOrders: 0
    }
  ]);
  const ordersUrlRegexr = /((\/profile\/)\w+)/g;
  const ordersUrl = window?.location?.pathname || '';
  const isProfile = ordersUrl.match(ordersUrlRegexr);
  const [filterFields, setFilterFields] = useState([
    [
      profileType === 'quoter' && {
        label: 'PRODUTOS',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.orders}/quotations/products`,
        key: 'ordersQuotation',
        optionReference: 'description',
        options: [],
        size: 'large',
        searchByTermMode: true
      },
      {
        label: 'SKU',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/quotations/manager/autocomplete/skus`,
        key: 'skus',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true
      },

      profileType === 'admin' || profileType === 'quoter'
        ? {
            label: 'LOJAS',
            filterName: 'originId',
            placeHolder: 'Selecione...',
            urlPath: `${environments.restaurants}/companies`,
            type: 'autoComplete',
            key: 'stores',
            options: [],
            optionReference: 'companyName',
            size: 'medium_plus',
            filterParamsOff: true
          }
        : {},
      {
        label: 'FORNECEDOR',
        filterName: 'providerIds',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/suppliers/groups`,
        key: 'providersIdList',
        optionReference: 'description',
        options: [],
        size: 'medium_plus'
      },

      {
        label: 'STATUS',
        filterName: 'status',
        placeHolder: 'Selecione...',
        size: 'medium_plus',
        type: 'autoComplete',
        key: 'providers',
        optionReference: 'providerName',
        options: [
          { title: 'Todas', value: null },
          { title: 'Em andamento', value: 'in progress' },
          { title: 'Atrasado', value: 'overdue' },
          { title: 'Recebido', value: 'received' },
          { title: 'Devolvido', value: 'returned' },
          { title: 'Cancelado', value: 'canceled' },
          { title: 'Aprovação', value: 'pending' }
        ]
      },

      profileType !== 'admin' &&
        profileType !== 'quoter' &&
        !isProfile?.length && {
          label: 'CURVA',
          type: 'multipleCheckbox',
          checkBoxInputs: [{ name: 'A' }, { name: 'B' }, { name: 'C' }]
        }
    ],
    []
  ]);

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'manualOrdersModal'
          ? { ...modal, open: false, totalOrders: 0 }
          : modal
      )
    );
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  useEffect(() => {
    setCallSettings({
      ...callSettings,
      getId: paramsID,
      filters: [
        {
          param: 'originId',
          urlParam: `originId=${
            profileType === 'quoter'
              ? originIds
              : profileType === 'admin'
              ? ''
              : paramsID
          }`,
          value: `${
            profileType === 'quoter'
              ? originIds
              : profileType === 'admin'
              ? ''
              : paramsID
          }`
        }
      ]
    });
  }, [paramsID]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    const delayFilterFetchData = setTimeout(() => {
      if (currentTab !== 'approveList') {
        (async () => {
          setLoading(true);
          if (!filtersChanged.find((item) => item.param === 'originId')) {
            filtersChanged.push({
              param: 'originId',
              urlParam: `originId=${
                profileType === 'quoter'
                  ? originIds
                  : profileType === 'admin'
                  ? ''
                  : paramsID
              }`,
              value: `${
                profileType === 'quoter'
                  ? originIds
                  : profileType === 'admin'
                  ? ''
                  : paramsID
              }`
            });
          }

          const filterParams = filtersChanged
          .filter((item) => item?.param !== "originId")
          const params = filterParams
            .map((filter) => filter?.urlParam)
            .join('&');
          const response = await fetch(
            `${environments.orders}/orders?page=${page}&size=${size}&originId=${paramsID}&${params}`
          ).catch((err) => {
            setAlertErr(true);
            setAlertMessage(err.response.data.message);
            setStatusCode(err.response.status);
          });

          if (!response?.json) return;

          const items = await response.json();
          setLoading(false);
          setOrders(items.content);

          setCollapseSettings(
            items?.content?.map((order) => ({
              id: order._id,
              open: false
            }))
          );

          setCallSettings({
            ...callSettings,
            totalPages: items.totalPages,
            numberOfElements: items.numberOfElements,
            totalElements: items.totalElements
          });
        })();
      }
    }, 500);

    return () => clearTimeout(delayFilterFetchData);
  }, [getId, page, size, filtersChanged, currentTab]);

  useEffect(() => {
    orders?.length &&
      orders.map((order) => {
        const exists = collapseSettings
          ? collapseSettings.find((x) => x.id === order._id)
          : null;
        if (collapseSettings?.length < orders.length && !exists) {
          collapseSettings.push({ id: order._id, open: false });
        }
      });
  }, [orders]);

  useEffect(() => {
    axios
      .get(`${environments.catalog}/unitsMeasurements?page=1&size=50`)
      .then((response) => {
        const unidades = response.data.content;
        setUnitsMeasurements(unidades);
      })
      .catch((error) => {
        setAlertErr(true);
        setAlertMessage(error.response.data.message);
        setStatusCode(error.response.status);
      });
  }, []);

  useEffect(() => {
    if (!modalSettings.find((modal) => modal.name === 'skuModal').open) return;

    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        getId
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        getId
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        getId
      );
    !providerOptions.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providerOptions,
        setProviderOptions,
        getId,
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        getId
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        getId,
        null,
        null,
        10
      );
  }, [getId, modalSettings.find((modal) => modal.name === 'skuModal').open]);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  }))(Tooltip);

  function HasBonus(data) {
    let count = 0;
    data.orderItems.map((item) => {
      if (item.bonus && item.bonus.total) {
        count += 1;
      }
    });

    if (count) {
      return (
        <td className="description center">
          <HtmlTooltip
            title={
              <>
                <Typography
                  color="inherit"
                  style={{
                    borderBottom: '1px solid #EEE',
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                    fontSize: '16px'
                  }}
                >
                  Itens com bonificação:
                </Typography>
                <br />
                {data.orderItems.map((item) => {
                  if (item.bonus && item.bonus.total) {
                    return (
                      <div>
                        <Grid
                          container
                          // direction="row"
                          // justify="center"
                          // alignItems="flex-start"
                          spacing={1}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              borderBottom: '1px solid #EEE',
                              fontFamily: 'Lato',
                              fontWeight: 'bold'
                            }}
                          >
                            item.sku.description
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '12px',
                                fontWeight: 'bold'
                              }}
                            >
                              Qtd.:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '12px',
                                textAlign: 'right'
                              }}
                            >
                              {Number(
                                item.bonus.quantity ? item.bonus.quantity : 0
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '12px',
                                fontWeight: 'bold'
                              }}
                            >
                              Un.:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '12px',
                                textAlign: 'right'
                              }}
                            >
                              {item.bonus.unitsMeasurements.abbreviation}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '12px',
                                fontWeight: 'bold'
                              }}
                            >
                              R$ Unit.:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '12px',
                                textAlign: 'right'
                              }}
                            >
                              {Number(
                                item.bonus.unitaryValue
                                  ? item.bonus.unitaryValue
                                  : 0
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '12px',
                                fontWeight: 'bold'
                              }}
                            >
                              R$ Total:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontSize: '12px',
                                textAlign: 'right'
                              }}
                            >
                              {Number(
                                item.bonus.total ? item.bonus.total : 0
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })}
                            </Typography>
                          </Grid>
                        </Grid>
                        <br />
                      </div>
                    );
                  }
                })}
              </>
            }
          >
            <IconButton
              aria-label="delete"
              style={{ padding: '3px', outline: 0 }}
            >
              <img alt="StarIconBlue" src={StarIconBlue} />
            </IconButton>
          </HtmlTooltip>
        </td>
      );
    }
    return (
      !isProfile?.length &&
      profileType !== 'quoter' && (
        <td className="description center">
          <Tooltip title="Não há itens bonificados.">
            <IconButton
              aria-label="bonus"
              style={{
                padding: '8px',
                outline: 0,
                width: '20px',
                height: '20px'
              }}
            >
              <img alt="StarIcon" src={StarIcon} />
            </IconButton>
          </Tooltip>
        </td>
      )
    );
  }

  function handleDelete(order) {
    new Promise((resolve, reject) => {
      const baseUrl = `${environments.orders}/orders`;
      const data = orders;
      const index = data.indexOf(order);
      setTimeout(() => {
        {
          data[index].status = 'CANCELED';
          setState({ orders }, () => resolve());
        }
        axios
          .delete(`${baseUrl}/${data[index]._id}`, config)
          .then((response) => {
            resolve();
            handleDialogClose();
          })
          .catch((error) => {
            setAlertErr(true);
            setAlertMessage(error.response.data.message);
            setStatusCode(error.response.status);
            reject();
          });
      }, 1000);
    });
  }

  function handleFilter(param, value, filter, paramSecond) {
    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (param === 'status') {
      setCurrentTab('orderList');
    }

    let skuListOnProductFilter = null;
    if (param === 'skus') {
      const { content: { skus = [] } = {} } = filter;
      skuListOnProductFilter = skus.map((el) => el.id);
    }

    if (!value && (hasFilterWithFirstName || hasFilterWithSecondName)) {
      formattedFilters = filtersChanged?.filter(
        (el) => el?.param !== paramSecond && el?.param !== param
      );
    }

    handleFilterOptions(
      param,
      skuListOnProductFilter || value,
      formattedFilters,
      setFiltersChanged,
      null,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  }

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    const filterParamsOff =
      label === 'LOJAS' || profileType === 'quoter' ? true : null;
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      label === 'LOJAS' ? '0' : originIds,
      filtersChanged,
      page,
      true,
      profileType
    );
  };

  const addMenuButton = () => {
    if (getId > 0) {
      return (
        <Tooltip title="Adicionar pedido" aria-label="add">
          <Link
            className="ml10"
            to={`/pedidos/cadastro/${paramsID}`}
            onClick={() => createOrder(null)}
          >
            <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
          </Link>
        </Tooltip>
      );
    }
  };

  return (
    <Layout>
      <TitleHead title="Pedidos" />

      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Pedidos de Compra"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          additionalbutton={addMenuButton}
          dynamicJustifyOff
          getFilterhandle={getFilterhandle}
        />

        {profileType === 'admin' && (
          <div className="orderTabsBox dFlex">
            <div
              onClick={() => {
                setCurrentTab('approveList');
                // let newFilters = [...filterFields]

                // newFilters[0][0].updateDefaultValue = newFilters[0][0].updateDefaultValue === null ? '' : null
                // newFilters[0][1].updateDefaultValue = newFilters[0][0].updateDefaultValue === null ? '' : null
                // newFilters[0][2].updateDefaultValue = newFilters[0][0].updateDefaultValue === null ? '' : null
                // newFilters[0][3].updateDefaultValue = newFilters[0][0].updateDefaultValue === null ? '' : null

                // setFilterFields(newFilters)
                // setCallSettings({ ...callSettings, filters: [] })
              }}
              className={`defaultTab ${
                currentTab === 'approveList' && 'active'
              }`}
            >
              <p className="mb0">APROVAÇÃO</p>
            </div>

            <div
              onClick={() => setCurrentTab('orderList')}
              className={`defaultTab ${currentTab === 'orderList' && 'active'}`}
            >
              <p className="mb0">OUTROS STATUS</p>
            </div>
          </div>
        )}

        {profileType === 'admin' && currentTab === 'approveList' ? (
          <ApproveOrdersList
            filters={filters}
            filtersChanged={filtersChanged}
            profileType={profileType}
            currentTab={currentTab}
          />
        ) : (
          <>
            <table
              className="desktop"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
            >
              <tr className="headerTitle">
                <th width="6%" className="subtitle pr0">
                  NRO{' '}
                  <OrderBy
                    filterName="_id"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>
                {profileType !== 'quoter' && (
                  <th width="4%" className="subtitle center">
                    TP{' '}
                    <OrderBy
                      filterName="type"
                      handleFilter={handleOrdernationFilter}
                    />
                  </th>
                )}
                <th
                  width={profileType !== 'quoter' ? '7%' : '10%'}
                  className="subtitle center"
                >
                  DATA
                  <OrderBy
                    ascFirst
                    filterName="deliveryDate"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>
                <th width="7%" className="subtitle center p8">
                  STATUS{' '}
                  <OrderBy
                    filterName="status"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>
                <th width="28%" className="subtitle">
                  FORNECEDOR
                </th>
                <th width="18%" className="subtitle">
                  SKU
                </th>
                <th width="6%" className="subtitle right">
                  QTDE.
                </th>
                <th width="3%" className="subtitle center">
                  UN.
                </th>
                <th width="6%" className="subtitle right">
                  R$ UN.
                </th>
                <th
                  width="8%"
                  className="subtitle right"
                  style={{ paddingRight: isProfile?.length && '0px' }}
                >
                  R$ TOTAL{' '}
                  <OrderBy
                    filterName="totalOrderAmount"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>
                {!isProfile?.length && <th width="2%" />}
                {!isProfile?.length && <th width="2%" />}
                {!isProfile?.length && <th width="2%" />}
              </tr>

              {loading && (
                <tr>
                  <th
                    colSpan="17"
                    style={{ margin: '10px', textAlign: 'center' }}
                  >
                    <LinearProgress
                      style={{ height: '7px' }}
                      id="loading"
                      variant="query"
                    />
                  </th>
                </tr>
              )}

              {orders?.length ? (
                orders.map((order, index) => {
                  const sum = order.orderItems.reduce(
                    (total, valor) =>
                      valor.costs
                        ? total + Number(valor.costs.total)
                        : total + 0,
                    0
                  );
                  let style = '';
                  const background = '';
                  let text = '';

                  switch (order.status.toLowerCase()) {
                    case 'sent':
                      style = {
                        background: 'lightgray',
                        color: 'black',
                        borderRadius: '4px'
                      };
                      text = 'Enviado';
                      break;
                    case 'return':
                      style = { background: 'lightblue', borderRadius: '4px' };
                      text = 'Retorno';
                      break;
                    case 'in progress':
                      if (
                        !isBefore(
                          addHours(new Date(order.deliveryDate), 3),
                          startOfToday()
                        )
                      ) {
                        style = {
                          background: '#d4edda',
                          color: '#155724',
                          borderRadius: '4px'
                        };
                        text = 'Em andamento';
                      } else {
                        style = {
                          background: '#FCF5E3',
                          color: '#E6A117',
                          borderRadius: '4px'
                        };
                        text = 'Atrasado';
                      }
                      break;
                    case 'overdue':
                      style = {
                        background: '#FCF5E3',
                        color: '#E6A117',
                        borderRadius: '4px'
                      };
                      text = 'Atrasado';
                      break;
                    case 'received':
                      style = {
                        background: '#d4edda',
                        color: '#155724',
                        borderRadius: '4px'
                      };
                      text = 'Recebido';
                      break;
                    case 'not received':
                      style = {
                        background: '#f8d7da',
                        color: '#721c24',
                        borderRadius: '4px'
                      };
                      text = 'Não Recebido';
                      break;
                    case 'returned':
                      style = {
                        background: '#f8d7da',
                        color: '#721c24',
                        borderRadius: '4px'
                      };
                      text = 'Devolvido';
                      break;
                    case 'canceled':
                      style = {
                        background: '#f8d7da',
                        color: '#721c24',
                        borderRadius: '4px'
                      };
                      text = 'Cancelado';
                      break;
                    case 'pending':
                      style = {
                        background: '#D3D3D3',
                        color: '#4F4F4F',
                        borderRadius: '4px'
                      };
                      text = 'Aprovação';
                      break;
                    default:
                      style = {
                        color: 'white',
                        background: 'lightgray',
                        borderRadius: '4px'
                      };
                      text = order.status;
                      break;
                  }

                  return (
                    <>
                      <tr key={order._id} valign="top">
                        <td className="description left pl12">
                          {profileType !== 'quoter'
                            ? order._id
                            : order.orderItems[0]?.itemsPaymentDocument
                                ?.paymentDocumentNumber || ''}
                        </td>

                        {profileType !== 'quoter' && (
                          <td className="description center">{order.type}</td>
                        )}

                        <Tooltip
                          title={`Data: ${new Date(
                            order.orderDate
                          ).toLocaleDateString()}`}
                        >
                          <td className="description center p8">
                            {order.deliveryDate
                              ? new Date(
                                  order.deliveryDate
                                ).toLocaleDateString()
                              : order.orderDate
                              ? new Date(order.orderDate).toLocaleDateString()
                              : ''}
                          </td>
                        </Tooltip>

                        <td className="description center p8">
                          <ButtonChip label={text} style={style} />
                        </td>

                        <td className="description center">
                          {profileType === 'admin' ||
                          profileType === 'quoter' ? (
                            <div className="dFlex alignCenter">
                              {ValidationLength(
                                providerNameValidation(order),
                                40
                              )}

                              <Tooltip title={`${order.store.name}`}>
                                <img
                                  src={StoreIcon}
                                  alt="StoreIcon"
                                  style={{ width: 15, marginLeft: 10 }}
                                />
                              </Tooltip>
                            </div>
                          ) : (
                            <>
                              {ValidationLength(
                                providerNameValidation(order),
                                40
                              )}
                            </>
                          )}
                        </td>

                        <InternalList
                          data={order}
                          ValidationItem={ValidationItem}
                          setOpenCollapseListItens={setOpenCollapseListItens}
                          openCollapseListItens={openCollapseListItens}
                          orders={orders}
                          setOrders={setOrders}
                          colorStyleOff={profileType === 'quoter'}
                          selectedSku={selectedSku}
                          setSelectedSku={setSelectedSku}
                          modalSettings={modalSettings}
                          setModalSettings={setModalSettings}
                          getId={getId}
                        />

                        {profileType !== 'admin' &&
                        profileType !== 'quoter' &&
                        !isProfile?.length ? (
                          <td>
                            <Link
                              to={`/pedidos/cadastro/${paramsID}`}
                              onClick={() => setOrder(order)}
                            >
                              <IconButton
                                aria-label="edit"
                                disabled={
                                  (order.status &&
                                    order.status.toLowerCase() ===
                                      'canceled') ||
                                  (order.statusPaymentDocument &&
                                    order.statusPaymentDocument.toLowerCase() !==
                                      'pending')
                                }
                                style={{ padding: '8px', outline: 0 }}
                              >
                                <img alt="EditPainIcon" src={EditPainIcon} />
                              </IconButton>
                            </Link>
                          </td>
                        ) : (
                          !isProfile?.length && <td>&nbsp;</td>
                        )}

                        {HasBonus(order)}

                        {profileType !== 'admin' &&
                        profileType !== 'quoter' &&
                        !isProfile?.length ? (
                          <td>
                            <IconButton
                              aria-label="delete"
                              disabled={
                                (order.status &&
                                  order.status.toLowerCase() === 'canceled') ||
                                (order.statusPaymentDocument &&
                                  order.statusPaymentDocument.toLowerCase() !==
                                    'pending')
                              }
                              onClick={() => {
                                handleDialogClickOpen(), setDeleteData(order);
                              }}
                              style={{ padding: '3px', outline: 0 }}
                              className="w100"
                            >
                              <img
                                alt="DeleteBlancIcon"
                                src={DeleteBlancIcon}
                              />
                            </IconButton>
                          </td>
                        ) : (
                          !isProfile?.length && <td colSpan={2}>&nbsp;</td>
                        )}
                      </tr>

                      {order?.orderItems?.map((item) => (
                        <TrStyled
                          collapse={order.isCollapsedList}
                          openCollapseListItens={openCollapseListItens.find(
                            (el) => el === item.sku.id
                          )}
                        >
                          <td colSpan={profileType !== 'quoter' ? '5' : '4'} />

                          <td
                            style={{
                              color: `${
                                item?.sku?.notValidated &&
                                profileType !== 'quoter'
                                  ? '#FF0000'
                                  : '#5062F0'
                              }`,
                              fontSize: '12px'
                            }}
                          >
                            <div
                              className="cursorPointer"
                              onClick={() => {
                                getOptions(
                                  `${environments.catalog}/skus/${item?.sku?.id}`,
                                  'skus',
                                  selectedSku,
                                  setSelectedSku,
                                  getId,
                                  null,
                                  null,
                                  null,
                                  true
                                );
                                setModalSettings(
                                  modalSettings.map((modal) =>
                                    modal.name === 'skuModal'
                                      ? { ...modal, open: true }
                                      : modal
                                  )
                                );
                              }}
                            >
                              {ValidationItem(item.sku, 25)}
                            </div>
                          </td>

                          <td
                            className="description right"
                            style={{ fontSize: '12px' }}
                          >
                            {Number(
                              item.costs?.quantityTotal
                                ? item.costs.quantityTotal
                                : item.costs?.quantity
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                          </td>

                          <td className="description center">
                            {item.consumptionUnits
                              ? item.consumptionUnits?.abbreviation
                              : item.unitsMeasurements?.abbreviation}
                          </td>

                          <td className="description right">
                            {item.sku && !item.sku.id
                              ? Number(item.costs?.packageValue).toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 3
                                  }
                                )
                              : item.costs?.unitaryValue
                              ? Number(item.costs?.unitaryValue).toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 3
                                  }
                                )
                              : '0,000'}
                          </td>

                          <td className="description right pr8">
                            {item.costs?.total
                              ? Number(item.costs?.total).toLocaleString(
                                  'pt-br',
                                  { minimumFractionDigits: 3 }
                                )
                              : '0,000'}
                          </td>
                        </TrStyled>
                      ))}

                      <tr>
                        <td colSpan="13">
                          <hr />
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <th
                    colSpan="13"
                    style={{ margin: '10px', textAlign: 'center' }}
                  >
                    Não foi encontrado
                  </th>
                </tr>
              )}
            </table>

            <div className="pagination">
              <Pagination
                count={totalPages}
                onChange={(_event, page) =>
                  setCallSettings({ ...callSettings, page })
                }
                variant="outlined"
                shape="rounded"
                size="small"
                color="primary"
                showFirstButton
                showLastButton
              />

              <ItemsPerPage
                numberOfElements={numberOfElements}
                size={totalElements}
              />
            </div>
          </>
        )}

        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            className="modalHeader navyBlue bold cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>Deseja realmente excluir esse pedido?</Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography>
              Esta ação não pode ser desfeita. Deseja continuar?
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={handleDialogClose}
              label="Cancelar"
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              autoFocus
              design="contained"
              label="Confirmar"
              onClick={() => handleDelete(deleteData)}
              color="primary"
            />
          </DialogActions>
        </Dialog>

        {modalSettings.find((modal) => modal.name === 'skuModal').open &&
        selectedSku?.description &&
        unitsMeasurements.length &&
        brands.length &&
        packageTypes.length ? (
          <SkuModal
            fullScreen={
              modalSettings.find((modal) => modal.name === 'skuModal')
                .fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'skuModal').maxWidth
            }
            open={modalSettings.find((modal) => modal.name === 'skuModal').open}
            data={selectedSku?.providers || []}
            getId={getId}
            editMode
            sku={selectedSku}
            userInfo={userInfo}
            providers={providerOptions}
            packageTypes={packageTypes}
            categories={categories}
            brands={brands}
            storageLocations={storageLocations}
            periodicities={periodicities}
            unitsMeasurements={unitsMeasurements}
            consumptionUnits={consumptionUnits}
            skus={[]}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'skuModal'
                    ? {
                        ...modal,
                        open: false
                      }
                    : modal
                )
              );

              setSelectedSku('');
            }}
            adminCatalogModal={isAdminPage}
            onlyViewMode
          />
        ) : null}

        {modalSettings.find((modal) => modal.name === 'manualOrdersModal')
          .open ? (
          <Dialog
            fullScreen={
              modalSettings.find((modal) => modal.name === 'manualOrdersModal')
                .fullScreen
            }
            open={
              modalSettings.find((modal) => modal.name === 'manualOrdersModal')
                .open
            }
            onClose={handleDialogClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              className="modalHeader navyBlue cursorMove bold"
              id="draggable-dialog-title"
            >
              <Typography>Pedidos manuais</Typography>
            </DialogTitle>

            <DialogContent className="modalContent">
              <Typography>
                {
                  modalSettings.find(
                    (modal) => modal.name === 'manualOrdersModal'
                  ).message
                }
              </Typography>
            </DialogContent>

            <DialogActions className="justifyCenter">
              <Button
                className="defaultButton submitButton"
                autoFocus
                design="contained"
                label="OK"
                onClick={handleDialogClose}
                color="primary"
              />
            </DialogActions>
          </Dialog>
        ) : null}

        <Snackbar
          open={deleteSuccess}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteSuccess)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteSuccess)}
            severity="success"
          >
            Pedido cancelado com sucesso!
          </Alert>
        </Snackbar>

        <Snackbar
          open={deleteError}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteError)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteError)}
            severity="error"
          >
            Ocorreu um erro ao salvar as informações.
          </Alert>
        </Snackbar>
      </Container>
      <CommonAlert
        open={alertErr}
        onClose={() => {
          setAlertErr(false);
        }}
        indexMessage={alertMessage}
        statusCode={statusCode}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  orderInfo: state.order
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
