import React, { useState, useEffect } from 'react';


import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  Snackbar,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Add, DeleteOutline } from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createGlobalStyle } from 'styled-components';

import Button from 'components/Button/button';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as UnitsMeasurement from 'store/actions/unitsMeasurements';
import { Container } from 'styles/general';
import Environment from 'utils/environments';

const GlobalStyle = createGlobalStyle`
.alnright { text-align: right; }
.alncenter { text-align: center; }
.alnleft { text-align: left; }

.responsive {
  @media (max-width: 1024px) {
    display: none;
  }
}

table.mobile { display: none }
table.desktop {
  width: 100%;
    table-layout: auto;
    empty-cells: hide;
    background: #FFF;
    border-spacing: 1px 0;
    border-collapse: collapse;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    padding-bottom: 15px;
    display: table;
    font-family: "Lato";
  
  tr {
    line-height: 30px;
    border: none;
  }
  .titleLineBorder {
    border-bottom: 1px solid rgb(211, 211, 211);
  }
  .bigTitle {
    font-family: Lato;
    font-size: 16px;
    line-height: 60px;
  }
  .title {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    line-height: 30px;
    border-bottom: 1px solid #ccc;
  }
  .subtitle {
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
  }
  .left {
    text-align: left;
  }
  .center {
    text-align: center;
  }
  .right {
    text-align: right;
  }
  .description {
    font-family: Lato;
    font-size: 12px;
    line-height: 30px;
  }
  .editIcon {
    width: 20px;
    height: 20px;
  }
  .deleteIcon {
    width: 20px;
    height: 20px;
  }
  hr {
    margin: 0;
  }
  .pagination {
    font-family: Lato;
    margin: 20px 0 10px 0;
    justify-content: flex-end;
    display: flex;
  } 
}

@media (max-width: 1024px) {
  table.mobile {
    width: 100%;
    table-layout: auto;
    empty-cells: hide;
    background: #FFF;
    border-spacing: 1px 0;
    border-collapse: collapse;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-bottom: 15px;
    display: table;
    font-family: "Lato";

    td:nth-child(1) {
      width: 30%;
    }
    
    td:nth-child(2) {
      width: 70%;
    }

    .bigTitle {
      width: 100%;
      font-family: Lato;
      font-size: 16px;
      line-height: 30px;
      text-align: center !important;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
    }
    .subtitle {
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      line-height: 30px;
      padding-right: 10px;
    }
    .description {
      font-family: Lato;
      font-size: 14px;
      line-height: 30px;
      text-align: left;
    }
    .actions {
      width: 100%;
      padding: 10px;
      text-align: center;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    hr {
      margin: 0;
    }
    .pagination {
      font-family: Lato;
      margin: 20px 0 10px 0;
      justify-content: center;
      display: flex;
    } 
  }
}

.headerTitle {
      th,
      td {
        background: #313347;
        position: sticky;
        top: -6px;
        z-index: 99;
        color: #FFF;

        &:first-of-type {
          overflow: hidden;
          border-top-left-radius: 4px;
        }

        &:last-of-type {
          overflow: hidden;
          border-top-right-radius: 4px;
        }

        &:first-of-type,
        &:last-of-type {
          padding: 0 12px;
        }
      }
    }
    
.titleCenter {
  display: none;
  @media (max-width: 1024px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
}

.filters {
  margin: 10px;
  padding: 0 10px;
  border-radius: 20px;
}
`;

function UnidadeDeMedidas({
  setUnitsMeasurements,
  createUnitsMeasurements
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [unitsMeasurements, setUnMeasurements] = useState([]);
  const [state, setState] = useState('');
  const [deleteData, setDeleteData] = useState();
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [deleteError, handleDeleteError] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleOpenAll(handle) {
    handle(true);
  }

  function handleCloseAll(handle) {
    handle(false);
  }

  function handleDelete() {
    new Promise((resolve, reject) => {
      const baseUrl = `${environments.catalog}/unitsMeasurements`;
      const data = unitsMeasurements;
      const index = data.indexOf(deleteData);
      unitsMeasurements.splice(index, 1);
      setState(unitsMeasurements);

      axios
        .delete(`${baseUrl}/${deleteData.id}`, config)
        .then((response) => {
          resolve();
          handleDialogClose();
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  }

  useEffect(() => {
    (async () => {
      const response = await axiosInstanceCatalog.get(
        `${environments.catalog}/unitsMeasurements?page=${page}&size=10`
      );
      const unitsMeasurements = await response.data;
      setUnMeasurements(unitsMeasurements.content);
      setTotalPages(unitsMeasurements.totalPages);
    })();
  }, [page]);

  const addProvider = () => (
    <Tooltip title="Cadastrar unidade de medida" aria-label="add">
      <Link
        to="/admin/unidades-de-medidas/cadastro/0"
        onClick={(e) => createUnitsMeasurements()}
      >
        <IconButton
          className="p0"
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
        </IconButton>
      </Link>
    </Tooltip>
  );

  const { t } = useTranslation('translations');

  return (
    <Layout>
      <TitleHead title={t('units of measurement')} />
      <GlobalStyle />
      <Container>
        <div className="mobile titleCenter">
          <div>
            <Typography style={{ fontFamily: 'Lato', fontSize: '16px' }}>
              Unidades de Medidas
            </Typography>
          </div>
          <div>
            <Tooltip title="Cadastrar Unidade de medida" aria-label="filters">
              <IconButton
                edge="start"
                color="inherit"
                style={{ padding: 0 }}
                aria-label="add"
              >
                <Link
                  to="/admin/unidades-de-medidas/cadastro/0"
                  onClick={(e) => createUnitsMeasurements()}
                >
                  <Add />
                </Link>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <table
          className="mobile"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="48%" className="subtitle">
                DESCRIÇÃO
              </th>
              <th width="48%" className="subtitle">
                ABREVIAÇÃO
              </th>
              <th width="3%" className="subtitle" />
            </tr>
            <tr>
              <td colSpan="3">
                <hr />
              </td>
            </tr>
          </thead>
          <tbody>
            {unitsMeasurements.map((unitsMeasurement) => (
              <>
                <tr>
                  <td width="48%" className="p10">
                    <Link
                      to="/admin/unidades-de-medidas/cadastro/0"
                      onClick={(e) => setUnitsMeasurements(unitsMeasurement)}
                    >
                      <td className="description" style={{ textAlign: 'left' }}>
                        {unitsMeasurement.description}
                      </td>
                    </Link>
                  </td>
                  <td width="48%">{unitsMeasurement.abbreviation}</td>
                  <td width="3%" className="subtitle">
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => (
                        handleDialogClickOpen(), setDeleteData(unitsMeasurement)
                      )}
                      style={{ padding: 0, outline: 0 }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">
                    <hr />
                  </td>
                </tr>
              </>
            ))}
          </tbody>
          <tfoot className="responsive">
            <tr>
              <td colSpan="3">
                <div className="pagination">
                  <Pagination
                    count={totalPages}
                    onChange={(event, page) => setPage(page)}
                    variant="outlined"
                    shape="rounded"
                    size="small"
                    color="primary"
                  />
                </div>
              </td>
            </tr>
          </tfoot>
        </table>

        <table width="100%" className="responsive">
          <PageTitleAndFilter
            title="Unidades de Medidas cadastradas"
            additionalbutton={addProvider}
          />

          <table
            className="desktop"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th width="48%" className="subtitle">
                  DESCRIÇÃO
                </th>
                <th width="48%" className="subtitle">
                  ABREVIAÇÃO
                </th>
                <th width="3%" className="subtitle" />
              </tr>
            </thead>
            <tbody>
              {unitsMeasurements.map((unitsMeasurement) => (
                <>
                  <tr>
                    <td className="p10">
                      <Link
                        to="/admin/unidades-de-medidas/cadastro/0"
                        onClick={(e) => setUnitsMeasurements(unitsMeasurement)}
                      >
                        {unitsMeasurement.description}
                      </Link>
                    </td>
                    <td>{unitsMeasurement.abbreviation}</td>
                    <td className="center">
                      <IconButton
                        aria-label="delete"
                        onClick={(e) => (
                          handleDialogClickOpen(),
                          setDeleteData(unitsMeasurement)
                        )}
                        style={{ padding: 0, outline: 0 }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">
                      <hr />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </table>
        <tfoot>
          <tr>
            <td colSpan="3">
              <div className="pagination">
                <Pagination
                  count={totalPages}
                  onChange={(event, page) => setPage(page)}
                  variant="outlined"
                  shape="rounded"
                  size="small"
                  color="primary"
                />
              </div>
            </td>
          </tr>
        </tfoot>

        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            Deseja realmente excluir essa unidade de medida?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Esta ação não pode ser desfeita. Deseja continuar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              design="outlined"
              onClick={handleDialogClose}
              label="Cancelar"
              color="primary"
            />
            <Button
              autoFocus
              design="contained"
              label="Confirmar"
              onClick={(e) => handleDelete()}
              color="primary"
              autoFocus
            />
          </DialogActions>
        </Dialog>
        <Snackbar
          open={deleteSuccess}
          autoHideDuration={2000}
          onClose={(e) => handleCloseAll(handleDeleteSuccess)}
        >
          <Alert
            onClose={(e) => handleCloseAll(handleDeleteSuccess)}
            severity="success"
          >
            Unidade de medida deletada com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar
          open={deleteError}
          autoHideDuration={2000}
          onClose={(e) => handleCloseAll(handleDeleteError)}
        >
          <Alert
            onClose={(e) => handleCloseAll(handleDeleteError)}
            severity="error"
          >
            Ocorreu um erro ao salvar as informações.
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  unitMeasurementsInfo: state.unitsMeasurement
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UnitsMeasurement, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnidadeDeMedidas);
