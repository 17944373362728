/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-spread */
/* eslint-disable default-param-last */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import axios from 'axios';
import moment from 'moment';

import Environment from 'utils/environments';

import {
  canSetDiffInCatalog,
  calculateDifference
} from '../../calcDivergentParams';

const environments = Environment(process.env.REACT_APP_ENV);

export const unifiedSkus = (skus) => {
  const mainObj = skus;
  const skuConfigs = [];
  const skusToUnify = [];

  skus.forEach((sku) => {
    const configExists = skuConfigs.find(
      (config) =>
        config.quantity === sku.quantity &&
        config.unitId === sku.unitsMeasurements.id
    );

    if (!configExists)
      skuConfigs.push({
        quantity: sku.quantity,
        unitId: sku.unitsMeasurements.id
      });
  });

  skuConfigs.forEach((value) => {
    const items = skus.filter(
      (sku) =>
        sku.quantity === value.quantity &&
        sku.unitsMeasurements.id === value.unitId
    );

    if (items.length > 1) {
      skusToUnify.push({
        config: value,
        items
      });

      items.forEach((item) => {
        const skuIndex = mainObj.indexOf(item);
        mainObj.splice(skuIndex, 1);
      });
    }
  });

  skusToUnify.forEach((value) => {
    const { items } = value;
    const unifiedSku =
      items.find((el) => el?.providers?.some((item) => item?.labelQuantity)) ||
      items[0];

    unifiedSku.description = `Skus unificados (${items.length})`;
    const providersToUnify = unifiedSku.providers.sort(
      (a, b) => b.stock - a.stock
    );

    items.forEach((item) => {
      const providers = item.providers.filter(
        (pro) => !providersToUnify.find((x) => x.quantity === pro.quantity)
      );
      providersToUnify.push(providers);
    });

    unifiedSku.providers = providersToUnify.flat();
    mainObj.push(unifiedSku);
  });

  return mainObj;
};

export const handleChangeRadio = (
  event,
  date,
  countId,
  getId,
  assortmentDate,
  setLoading,
  setProducts
) => {
  setLoading(true);

  if (event.target.value === 'PHYSICAL') {
    axios
      .get(
        `${
          environments.catalog
        }/inventories/${countId}?originId=${getId}&countDate=${date}&assortmentDate=${moment(
          assortmentDate?.date
        ).format('YYYY-MM-DD')}`
      )
      .then((response) => {
        const responseProducts = response.data.products.map((product) => {
          if (product.unifiedSkus && product.skus.length > 1) {
            return { ...product, skus: unifiedSkus(product.skus) };
          }

          return product;
        });

        setLoading(false);
        setProducts(responseProducts);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  } else {
    axios
      .get(
        `${environments.catalog}/inventories/${countId}?originId=${getId}&countDate=${date}&assortmentType=ALPHABETICAL`
      )
      .then((response) => {
        const responseProducts = response.data.products.map((product) => {
          if (product.unifiedSkus && product.skus.length > 1) {
            return { ...product, skus: unifiedSkus(product.skus) };
          }

          return product;
        });

        setLoading(false);
        setProducts(responseProducts);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
};

export const productDifference = (product) =>
  product?.realStock !== null ||
  product?.realStock !== undefined ||
  product?.otherStock !== null ||
  product?.otherStock !== undefined
    ? (product?.otherStock ? parseFloat(Number(product?.otherStock)) : 0) +
      (product?.realStock ? parseFloat(Number(product?.realStock)) : 0) -
      (product?.theoricalStock || product?.theoreticalStock
        ? parseFloat(
            Number(product?.theoricalStock || product?.theoreticalStock)
          )
        : 0)
    : null;

export const handleSelectedRealDiff = (
  type,
  orderByQuantityDesc,
  setProducts,
  setIsSelectedRealDiff,
  setIsSelectedQuantity,
  setIsSelectedCost
) => {
  if (type === 'qtde') {
    setIsSelectedRealDiff(false);
    setIsSelectedQuantity(true);
    setIsSelectedCost(false);
    return;
  }

  setIsSelectedRealDiff(true);
  setIsSelectedCost(true);
  setIsSelectedQuantity(false);

  if (orderByQuantityDesc) {
    setProducts((prevProducts) =>
      prevProducts
        .map((product) => {
          const difference = productDifference(product);
          const differenceInReal =
            typeof parseFloat(product.realStock) === 'number'
              ? Number(difference) * Number(product?.cost)
              : null;

          return {
            ...product,
            differenceInReal
          };
        })
        .sort((a, b) => b.differenceInReal - a.differenceInReal)
    );
  } else {
    setProducts((prevProducts) =>
      prevProducts
        .map((product) => {
          const difference = productDifference(product);
          const differenceInReal =
            typeof parseFloat(product.realStock) === 'number'
              ? Number(difference) * Number(product?.cost)
              : null;

          return {
            ...product,
            differenceInReal
          };
        })
        .sort((a, b) => a.differenceInReal - b.differenceInReal)
    );
  }
};

export const productPercentage = (product, difference) =>
  product?.consumed
    ? (difference / parseFloat(Number(product?.consumed))) * 100
    : 0;

export const productLossPercentage = (product) =>
  product?.lossPercentage ? parseFloat(product?.lossPercentage) : 0;

export const filterProducts = (type, filterValues = false, products) => {
  let positiveProducts = [];
  let expectedProducts = [];
  let negativeProducts = [];
  const productsFiltered = filterValues
    ? products?.filter?.((el) => el.realStock !== null)
    : products;

  productsFiltered.map((product) => {
    const difference = productDifference(product);
    const percentage = productPercentage(product, difference);
    const lossPercentage = productLossPercentage(product);

    const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];

    if (!product?.consumed) {
      difference === 0
        ? (positiveProducts = [...positiveProducts, product])
        : difference > 0
        ? (expectedProducts = [...expectedProducts, product])
        : (negativeProducts = [...negativeProducts, product]);
    } else {
      percentage >= range[0] && percentage <= range[1]
        ? (positiveProducts = [...positiveProducts, product])
        : percentage >= lossPercentage
        ? (expectedProducts = [...expectedProducts, product])
        : (negativeProducts = [...negativeProducts, product]);
    }
  });

  if (type === 'positive') {
    return positiveProducts;
  }

  if (type === 'expected') {
    return expectedProducts;
  }

  return negativeProducts;
};

export const filterByDiff = (
  type,
  enabledFilterByDiff,
  products,
  setEnabledFilterByDiff,
  setProducts,
  isReportTab = false
) => {
  const isEnabled = enabledFilterByDiff.includes(type);
  const validateNotCounted = isEnabled ? enabledFilterByDiff?.length - 1 < 3 && enabledFilterByDiff?.length - 1 !== 0 : enabledFilterByDiff?.length + 1 !== 3

  const countedProducts = products?.filter?.((el) => el.realStock !== null);
  let productsFiltered = [];

  setEnabledFilterByDiff(
    isEnabled
      ? enabledFilterByDiff.filter((el) => el !== type)
      : [...enabledFilterByDiff, type]
  );

  if (isEnabled) {
    productsFiltered = enabledFilterByDiff
      .filter((el) => el !== type)
      .map((el) => filterProducts(el, true, products))
      .flat();
  } else {
    productsFiltered = [...enabledFilterByDiff, type]
      .map((el) => filterProducts(el, true, products))
      .flat();
  }

  setProducts(
    [
      products?.filter?.((el) => el.realStock === null)?.map((val) => ({
        ...val,
        hide: isReportTab || validateNotCounted
      })),
      countedProducts.map((val) => {
        if (productsFiltered.map((el) => el.id)?.includes(val?.id)) {
          return {
            ...val,
            hide: false
          };
        }

        return {
          ...val,
          hide: true
        };
      })
    ].flat()
  );
};

export const handleFilterOnlyNotCounted = (
  checked,
  setFilterOnlyNotCounted,
  setProducts,
  products
) => {
  setFilterOnlyNotCounted(checked);

  if (checked) {
    setProducts(
      products.map((val) => {
        if (val?.realStock === null) {
          return {
            ...val,
            hide: false
          };
        }

        return {
          ...val,
          hide: true
        };
      })
    );
  } else {
    setProducts(
      products.map((val) => ({
        ...val,
        hide: false
      }))
    );
  }
};

export const handleOrderByQuantity = (
  value,
  products,
  isSelectedRealDiff,
  setOrderByQuantityDesc,
  setProducts
) => {
  setOrderByQuantityDesc(value);
  let productsOrdered = '';

  if (value) {
    productsOrdered = products
      .map((product) => {
        const difference = productDifference(product);
        const differenceInReal =
          typeof parseFloat(product.realStock) === 'number'
            ? Number(difference) * Number(product?.cost)
            : null;
        const currentValue = isSelectedRealDiff ? differenceInReal : difference;

        return {
          ...product,
          difference,
          differenceInReal,
          currentValue
        };
      })
      .sort((a, b) => b.currentValue - a.currentValue);
  } else {
    productsOrdered = products
      .map((product) => {
        const difference = productDifference(product);
        const differenceInReal =
          typeof parseFloat(product.realStock) === 'number'
            ? Number(difference) * Number(product?.cost)
            : null;
        const currentValue = isSelectedRealDiff ? differenceInReal : difference;

        return {
          ...product,
          difference,
          differenceInReal,
          currentValue
        };
      })
      .sort((a, b) => a.currentValue - b.currentValue);
  }

  setProducts(productsOrdered);
};

export const mapShowProductDescription = (skus) =>
  [].concat.apply(
    [],
    skus.map((sku, skuIndex) => {
      const { providers } = sku;
      return [].concat.apply(
        [],
        providers.map((provider, providerIndex) => {
          const { showOnCount, showAdditionalOnCount } = provider;
          return {
            skuIndex,
            providerIndex,
            showOnCount,
            showAdditionalOnCount
          };
        })
      );
    })
  );

export const assembleShowProducts = (mappedShowProducts) => {
  const show = [];

  for (const mappedShowProduct of mappedShowProducts) {
    const hasShow = show.findIndex((s) => s === true);
    const { showOnCount, showAdditionalOnCount } = mappedShowProduct;

    if (hasShow < 0) {
      if (showOnCount || showAdditionalOnCount) {
        show.push(true);
      } else {
        show.push(false);
      }
    } else {
      show.push(false);
    }
  }

  return show;
};

export const setSkus = (product) => {
  const {
    skus,
    fakeAdditional,
    consumptionUnitsPrimary: { abbreviation: productConsumptionUnit }
  } = product;
  const rSkus = [];

  if (skus?.length) {
    for (const sku of skus) {
      const { quantity: skuQuantity, providers } = sku;
      const quantitiesProviders = providers.map(
        (provider) => provider.quantity
      );
      const hasShowSku = providers.some((provider) => provider.showOnCount);
      const hasAdditional =
        skuQuantity !== 1 &&
        quantitiesProviders.some((q) => q !== 1) &&
        productConsumptionUnit !== 'un';
      const hasShowAdditional = hasAdditional
        ? providers.some((provider) => provider.showAdditionalOnCount)
        : false;

      if (hasShowSku || fakeAdditional || (hasAdditional && hasShowAdditional)) {
        rSkus.push({ ...sku });
      }
    }
  }

  return rSkus;
};

export const setProviders = (sku, providers, productConsumptionUnit, product, products) => {
  const { quantity: skuQuantity, unityAdditional: unityAdditionalCounted } =
    sku;
  const rProviders = [];

  if (providers?.length) {
    for (const provider of providers) {
      const { quantity: providerQuantity, stock } = provider;

      if (provider.showOnCount || stock !== null) {
        rProviders.push({
          ...provider,
          isAdditional: false,
        });
      }

      if (
        providerQuantity !== 1 &&
        providers?.every(el => el?.quantity !== 1) &&
        skuQuantity !== 1 &&
        productConsumptionUnit !== 'un' &&
        (provider.showAdditionalOnCount || unityAdditionalCounted !== null)
      ) {
        rProviders.push({
          ...provider,
          isAdditional: true,
        });
      }
    }
  }

  return rProviders;
};

export const updateStock = (product) => {
  const mainObj = product.skus.reduce(
    (totalSkus, sku) =>
      totalSkus +
      sku.providers.reduce((total, provider) => {
        if (provider.stock !== null && !Number.isNaN(provider.stock)) {
          let sum;
          const productUnit = product.consumptionUnitsPrimary.abbreviation?.toLowerCase();
          if (
            productUnit === 'un'
          ) {
            const currentQuantity = productUnit === sku.unitsMeasurements.abbreviation?.toLowerCase() ? parseFloat(sku.quantity) * parseFloat(provider?.quantity) : parseFloat(provider.quantity)
            sum = parseFloat(provider.stock) * currentQuantity;
          } else {
            sum =
              parseFloat(provider.stock) *
              parseFloat(provider.quantity) *
              parseFloat(sku.quantity);
            const unit = sku.unitsMeasurements.abbreviation;

            if (unit === 'ml' || unit === 'g') {
              sum /= 1000;
            } else if (unit === 'mg') {
              sum /= 1000000;
            }
          }
          return parseFloat(sum) + parseFloat(total);
        }
        return total;
      }, 0),
    0
  );
  return mainObj;
};

export const updateProducts = (newProduct, products, setProducts) => {
  const {
    id,
    additional,
    realStock,
    total,
    unityAdditional,
    skuId,
    isAdditional,
    isUnityAdditional,
    countedStocks,
    labelAdditional,
    totalStocks,
    oldCountedStocks,
    labelQuantity,
    labelUnityAdditional,
    providerId
  } = newProduct;
  const newProducts = products?.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        realStock,
        additional:
          isAdditional && !isUnityAdditional
            ? total == null
              ? parseFloat(additional)
              : parseFloat(total)
            : isUnityAdditional
            ? item.additional
            : additional,
        labelAdditional:
          isAdditional && !isUnityAdditional && !isNaN(additional)
            ? total == null && additional !== 0
              ? parseFloat(additional?.replaceAll('.', '')?.replaceAll(',', '.'))
              : labelAdditional ? parseFloat(labelAdditional?.replaceAll('.', '')?.replaceAll(',', '.')) : additional === 0 ? '0' : ''
            : isUnityAdditional
            ? item.labelAdditional
            : labelAdditional,
        stocks: {
          countedStocks,
          totalStocks,
          oldCountedStocks
        },
        skus: item.skus.map((sku) => {
          if (sku.id === skuId) {
            return {
              ...sku,
              unityAdditional: Number.isNaN(unityAdditional)
                ? null
                : unityAdditional,
              labelUnityAdditional
            };
          }
          return sku;
        })
      };
    }
    return item;
  });
  products.forEach((element) => {
    if (element.id === newProduct.id) {
      const currentProduct = newProducts.find(
        (nElement) => nElement.id === element.id
      );

      currentProduct.skus.forEach((sku) => {
        if (sku.id === skuId) {
          const [firstProvider] = sku?.providers;
          if (!providerId || providerId === null) {
            firstProvider.stock = Number.isNaN(firstProvider.stock)
              ? null
              : firstProvider.stock;
            firstProvider.labelQuantity = labelQuantity;
          }

          if (providerId) {
            const providersList = sku?.providers;

            providersList.forEach((element) => {
              if (element.id === providerId) {
                element.stock = Number.isNaN(element.stock)
                  ? null
                  : element.stock;
                element.labelQuantity = labelQuantity;
              }
            });
          }
        }
      });

      element.additional = Number.isNaN(newProduct.additional)
        ? null
        : newProduct.additional;
      element.labelAdditional = Number.isNaN(newProduct.labelAdditional)
        ? null
        : `${newProduct.labelAdditional}`;
      element.averagePrice = newProduct.averagePrice;
      element.realStock = currentProduct.realStock;
      element.stocks = currentProduct.stocks;
      element.skus = [...currentProduct.skus];
    }
  });

  setProducts(newProducts);
};

export const putDiffInCatalog = ({
  productId,
  countId,
  restaurantId,
  difference,
  userInfo
}) => {
  const URI = `${environments.catalog}/products/${productId}/extracts/difference/${countId}`;
  const body = {
    restaurantId,
    difference,
    user: userInfo?.user
  };

  return axios.put(URI, body);
};

export const handleFocus = (
  dataProps,
  lastProductCounted,
  parametersInfos,
  countId,
  getId,
  userInfo
) => {
  const { product } = dataProps;

  if (lastProductCounted) {
    if (
      product?.id !== lastProductCounted.productId &&
      lastProductCounted.realStock !== null
    ) {
      const canSetDifference = canSetDiffInCatalog({
        maxPercentage: parametersInfos?.percentageDifference,
        currentTheoreticalStock: lastProductCounted.theoreticalStock,
        currentRealStock: lastProductCounted.realStock,
        historicalTheoreticalStock:
          lastProductCounted.historical.theoreticalStock,
        historicalRealStock: lastProductCounted.historical.realStock
      });

      if (canSetDifference) {
        const { difference } = calculateDifference(
          lastProductCounted.realStock,
          lastProductCounted.theoreticalStock
        );
        putDiffInCatalog({
          productId: lastProductCounted.productId,
          countId,
          restaurantId: getId,
          difference,
          userInfo
        })
          .then()
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
};

export const focusOnNextField = (e, isLast, timeOutValue) => {
  let currentProductGroupId;
  let currentSkuIdNumber;
  let currentProviderIdNumber;
  let currentItemType;
  let isTablet = '';

  if(e.target.id.split('tablet').length > 1){
    currentProductGroupId = parseFloat(
      e.target.closest('.singleCountItem').id.split('-')[1]
    );
    currentSkuIdNumber = parseFloat(e.target.id.split('-')[2]) || 0;
    currentProviderIdNumber = parseFloat(e.target.id.split('-')[3]) || 0;
    currentItemType = e.target.id.split('-')[0];

    isTablet = '-tablet';
  }else{
    currentProductGroupId = parseFloat(
      e.target.closest('.singleCountItem').id.split('-')[1]
    );
    currentSkuIdNumber = parseFloat(e.target.id.split('-')[2]) || 0;
    currentProviderIdNumber = parseFloat(e.target.id.split('-')[3]) || 0;
    currentItemType = e.target.id.split('-')[0];
  }

  if (isLast) {
    if (
      !document.querySelector(
        `#countInput-${currentProductGroupId + 1}-${0}-${0}${isTablet}`
      )
    ) {
      document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });

      setTimeout(() => {
        document.querySelector(`#countInput-0-0-0`)?.focus();
      }, 1000);
    } else {
      document
        .querySelector(`#countInput-${currentProductGroupId + 1}-${0}-${0}${isTablet}`)
        ?.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        });

      setTimeout(() => {
        document
          .querySelector(`#countInput-${currentProductGroupId + 1}-${0}-${0}${isTablet}`)
          ?.focus();
      }, 400);
    }

    return;
  }

  if (
    document.querySelector(
      `#additionalSkuInput-${currentProductGroupId}-${currentSkuIdNumber}-${currentProviderIdNumber}${isTablet}`
    ) &&
    currentItemType !== 'additionalSkuInput'
  ) {
    document
      .querySelector(
        `#additionalSkuInput-${currentProductGroupId}-${currentSkuIdNumber}-${currentProviderIdNumber}${isTablet}`
      )
      ?.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });

    setTimeout(() => {
      document
        .querySelector(
          `#additionalSkuInput-${currentProductGroupId}-${currentSkuIdNumber}-${currentProviderIdNumber}${isTablet}`
        )
        ?.focus();
    }, timeOutValue);
  } else if (
    document.querySelector(
      `#countInput-${currentProductGroupId}-${currentSkuIdNumber}-${
        currentProviderIdNumber + 1
      }${isTablet}`
    )
  ) {
    document
      .querySelector(
        `#countInput-${currentProductGroupId}-${currentSkuIdNumber}-${
          currentProviderIdNumber + 1
        }${isTablet}`
      )
      ?.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });

    setTimeout(() => {
      document
        .querySelector(
          `#countInput-${currentProductGroupId}-${currentSkuIdNumber}-${
            currentProviderIdNumber + 1
          }${isTablet}`
        )
        ?.focus();
    }, timeOutValue);
  } else if (
    document.querySelector(
      `#countInput-${currentProductGroupId}-${currentSkuIdNumber + 1}-${0}${isTablet}`
    )
  ) {
    document
      .querySelector(
        `#countInput-${currentProductGroupId}-${currentSkuIdNumber + 1}-${0}${isTablet}`
      )
      ?.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });

    setTimeout(() => {
      document
        .querySelector(
          `#countInput-${currentProductGroupId}-${currentSkuIdNumber + 1}-${0}${isTablet}`
        )
        ?.focus();
    }, timeOutValue);
  } else if (
    document.querySelector(
      `#countInput-${currentProductGroupId}-${
        currentSkuIdNumber + 1
      }-${currentProviderIdNumber}${isTablet}`
    )
  ) {
    document
      .querySelector(
        `#countInput-${currentProductGroupId}-${
          currentSkuIdNumber + 1
        }-${currentProviderIdNumber}${isTablet}`
      )
      ?.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });

    setTimeout(() => {
      document
        .querySelector(
          `#countInput-${currentProductGroupId}-${
            currentSkuIdNumber + 1
          }-${currentProviderIdNumber}${isTablet}`
        )
        ?.focus();
    }, timeOutValue);
  } else if (
    document.querySelector(
      `#additionalInputSecondaryUnit-${currentProductGroupId}${isTablet}`
    )
  ) {
    document
      .querySelector(
        `#additionalInputSecondaryUnit-${currentProductGroupId}${isTablet}`
      )
      ?.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });

    setTimeout(() => {
      document
        .querySelector(
          `#additionalInputSecondaryUnit-${currentProductGroupId}${isTablet}`
        )
        ?.focus();
    }, timeOutValue);
  } else {
    document
      .querySelector(`#additionalInput-${currentProductGroupId}${isTablet}`)
      ?.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });

    setTimeout(() => {
      document
        .querySelector(`#additionalInput-${currentProductGroupId}${isTablet}`)
        ?.focus();
    }, timeOutValue);
  }
};

export const addCountedProducts = (productId, countedProducts) => {
  const exists = countedProducts.find((x) => x === productId);
  if (!exists) {
    countedProducts.push(productId);
  }
};

export const removeCountedProducts = (
  productId,
  countedProducts,
  setCountedProducts
) => {
  const exists = countedProducts.find((x) => x === productId);
  if (exists)
    setCountedProducts(countedProducts.filter((value) => value !== productId));
};

export const formatCountValues = (dataProps, textValue, products, isValidation = false) => {
  const {
    product,
    sku,
    quantity,
    additional,
    skuQuantity,
    skuAdditional,
    inputType,
    provider,
    realStock: datapropsRealStock
  } = dataProps;
  
  let total = textValue ? parseFloat(textValue) : null;
  const selectedProduct = products?.find((p) => p.id === product.id);
  const selectedSku = selectedProduct.skus.find((s) => s.id === sku.id);
  const selectedProvider = selectedSku.providers.find(
    (p) => p.id === provider.id
  );
  let notSelectedSkuSum = 0;
  const unit = sku.unitsMeasurements.abbreviation?.toLowerCase();
  const productConsumptionUnitPrimary = product?.consumptionUnitsPrimary
  const productConsumptionUnitSecondary = product?.consumptionUnitsSecondary
  const hasProductAdditional = !!(
    (selectedProduct.skus.some((item) =>
      item.providers.some((el) => el.quantity > 1)
    ) ||
      selectedProduct.skus.some((item) => item.quantity !== 1)) &&
    (selectedProduct.consumptionUnitsPrimary.abbreviation ||
      selectedProduct.consumptionUnitsSecondary)
  );
  const hasProductConsumptionUnitSecondaryAdditional = productConsumptionUnitPrimary?.abbreviation?.toLowerCase() === 'un' && 
  (productConsumptionUnitSecondary?.abbreviation?.toLowerCase() === 'kg' || productConsumptionUnitSecondary?.abbreviation?.toLowerCase() === 'lt')

  const validateConsumptionUnits = 
  (productConsumptionUnitPrimary?.abbreviation?.toLowerCase() === 'kg' || productConsumptionUnitPrimary?.abbreviation?.toLowerCase() === 'lt') 
  || hasProductConsumptionUnitSecondaryAdditional

  const hasSkuAdditional = !!(
    selectedProduct.skus.some((item) =>
      item.providers.some((el) => el.quantity > 1)
    ) &&
    sku.quantity !== 1 &&
    validateConsumptionUnits
  );

  for (const s of selectedProduct.skus) {
    if (
      s.id !== sku.id &&
      s.unityAdditional !== null &&
      !Number.isNaN(s.unityAdditional)
    ) {
      let sum = s.quantity * s.unityAdditional;
      const currentUnit = s?.unitsMeasurements?.abbreviation
      if (currentUnit === 'ml' || currentUnit === 'g') {
        sum /= 1000;
      } else if (currentUnit === 'mg') {
        sum /= 1000000;
      }
      notSelectedSkuSum += sum;
    }
  }

  if (unit === 'un') {
    if (!additional && !skuAdditional) {
      total = parseFloat(total);
    }
  }

  const stockValue = isValidation ? null : additional || skuAdditional ? parseFloat(quantity) : parseFloat(total);

  selectedProvider.stock = stockValue;

  let skuQuantityUnit = 0;
  
  if (unit === 'ml' || unit === 'g') {
    skuQuantityUnit = selectedSku.quantity / 1000;
  } else if (unit === 'mg') {
    skuQuantityUnit = selectedSku.quantity / 1000000;
  } else {
    skuQuantityUnit = selectedSku.quantity;
  }

  if (hasProductConsumptionUnitSecondaryAdditional && additional) {
    total = total * 1 / skuQuantityUnit 
  }

  let addit =
    additional && !skuAdditional
      ? total
      : skuAdditional
      ? product.additional
      : parseFloat(Number.isNaN(quantity) ? 0 : Number(quantity));
  let skuAddit =
    skuAdditional && !additional
      ? total
      : parseFloat(Number.isNaN(skuQuantity) ? 0 : Number(skuQuantity));


  skuAddit =
    skuAddit !== null && !Number.isNaN(skuAddit)
      ? skuAddit * skuQuantityUnit
      : 0;

  addit = !Number.isNaN(addit) ? addit : 0;

  let realStock = updateStock(product);

  if (hasProductAdditional && hasSkuAdditional) {
    realStock += addit + skuAddit + notSelectedSkuSum;
  } else if (hasProductAdditional) {
    realStock += addit + notSelectedSkuSum;
  } else if (hasSkuAdditional) {
    realStock += skuAddit + notSelectedSkuSum;
  } else if (hasProductConsumptionUnitSecondaryAdditional) {
    realStock += addit + skuAddit + notSelectedSkuSum;
  } else {
    realStock += notSelectedSkuSum;
  }

  return {
    realStock,
    total,
    hasProductAdditional,
    hasSkuAdditional,
    addit,
    selectedSku,
    hasProductConsumptionUnitSecondaryAdditional
  }
}