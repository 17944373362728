/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { CircularProgress, Snackbar, Tooltip, Fade } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import format from 'date-fns/format';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import InvoiceModal from 'components/Dialogs/InvoiceModal';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import WarningYellowIcon from 'images/icons/signals/alerts/warningYellowIcon.svg';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import {
  ButtonsContainer,
  ClosureStatusContainer,
  LastDayInfosBox,
  ProcessingInfosContainer
} from './styles';

const environments = Environment(process.env.REACT_APP_ENV);

function ClosureAndSuggestions({ userInfo }) {
  const actualDate = new Date();
  const actualHour = new Date().getHours();
  const dayLess = actualHour >= 0 && actualHour <= 4 ? 2 : 1;
  const actualDateLessDay = new Date(
    new Date().setDate(new Date().getDate() - dayLess)
  );
  const actualDatePlusOne = new Date(
    new Date().setDate(new Date().getDate() + 1)
  );

  const { id: paramsID } = useParams();

  const callSettings = useMemo(
    () => ({
      getId: paramsID,
      page: 1,
      totalPages: 0,
      size: 100
    }),
    [paramsID]
  );

  const { getId } = callSettings;

  const [isLoadingSalesInfos, setLoadingSalesInfos] = useState(false);
  const [salesInfos, setSalesInfos] = useState([]);
  const [salesInfosLessOneDay, setSalesInfosLessOneDay] = useState([]);
  const [refreshPage, setRefreshPage] = useState();
  const [parametersInfos, setParametersInfos] = useState([]);
  const [salesProcessInfos, setSalesProcessInfos] = useState();
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const [rangeDate, setRangeDate] = useState({
    startDate: '',
    endDate: ''
  });
  const [rangeDateEx, setRangeDateEx] = useState({
    startDate: '',
    endDate: ''
  });
  const [productFilter, setProductFilter] = useState('');

  const [rangeDatePartial, setRangeDatePartial] = useState({
    startDate: '',
    endDate: ''
  });
  const [rangeDatePartialEx, setRangeDatePartialEx] = useState({
    startDate: '',
    endDate: ''
  });
  const [productFilterPartial, setProductFilterPartial] = useState('');

  const resetFirstDate = () => {
    setRangeDateEx({
      startDate: moment(actualDateLessDay).format('YYYY-MM-DD'),
      endDate: moment(actualDateLessDay).format('YYYY-MM-DD')
    });
    setRangeDatePartialEx({
      startDate: moment(actualDate).format('YYYY-MM-DD'),
      endDate: moment(actualDate).format('YYYY-MM-DD')
    });
  };

  useEffect(() => {
    resetFirstDate();
  }, []);

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const [loadingObj, setLoadingObj] = useState({
    closure: false,
    suggestion: false
  });

  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const isRunningClosure = useMemo(() => {
    if (parametersInfos?.closure?.isRunning || loadingObj.closure) {
      return true;
    }
    return false;
  }, [loadingObj.closure, parametersInfos?.closure?.isRunning]);

  const isRunningSuggestion = useMemo(() => {
    if (parametersInfos?.engine?.isRunning || loadingObj.suggestion) {
      return true;
    }
    return false;
  }, [loadingObj.suggestion, parametersInfos?.engine?.isRunning]);

  const getSalesInfos = useCallback(
    (currentDate = false) => {
      setLoadingSalesInfos(true);

      const formatDate = format(
        new Date(actualDate).valueOf() -
          new Date().getTimezoneOffset() * 60 * 1000,
        'yyyy-MM-dd'
      );

      const lastFormatDate = format(
        new Date(actualDateLessDay).valueOf() -
          new Date().getTimezoneOffset() * 60 * 1000,
        'yyyy-MM-dd'
      );

      const date = currentDate ? formatDate : lastFormatDate;

      axios
        .get(
          `${environments.dashBoard}/cmvView/totals?restaurantIds=${getId}&startDate=${date}&endDate=${date}`
        )
        .then((response) => {
          currentDate
            ? setSalesInfos(response?.data || {})
            : setSalesInfosLessOneDay(response?.data || {});
        })
        .catch((error) => {
          currentDate ? setSalesInfos({}) : setSalesInfosLessOneDay({});
          console.log(error);
        })
        .finally(() => setLoadingSalesInfos(false));
    },
    [getId]
  );

  const getParametersInfos = useCallback(() => {
    axios
      .get(
        `${environments.restaurants}/companies/${userInfo.companiesActive.id}/parameters`
      )
      .then((response) => {
        setParametersInfos(response?.data || {});
      })
      .catch((error) => {
        setParametersInfos({});
        console.log(error);
      });
  }, [userInfo.companiesActive.id]);

  const getSalesProcessInfos = useCallback(() => {
    axios
      .get(
        `${environments.catalog}/queue/sales/health?restaurantId=${userInfo.companiesActive.id}`
      )
      .then((response) => {
        setSalesProcessInfos(response?.data || {});
      })
      .catch((error) => {
        setSalesProcessInfos({});
        console.log(error);
      });
  }, [userInfo.companiesActive.id]);

  useEffect(() => {
    getParametersInfos();
    getSalesInfos();
    getSalesProcessInfos();
    getSalesInfos(true);
  }, [getParametersInfos, getSalesInfos, getSalesProcessInfos]);

  const isAbleSuggestion = useMemo(() => {
    const isRunning = parametersInfos?.closure?.isRunning;
    const runToday = parametersInfos?.engine?.runToday;
    const isRunningGenerateSuggestion = parametersInfos?.engine?.isRunning;

    if (!isRunning && (!runToday || isRunningGenerateSuggestion)) {
      return true;
    }
    return false;
  }, [
    parametersInfos?.closure?.isRunning,
    parametersInfos?.engine?.runToday,
    parametersInfos?.engine?.isRunning
  ]);

  const generateSuggestion = () => {
    setLoadingObj({ ...loadingObj, suggestion: true });

    const body = {
      restaurantId: getId,
      user: userInfo.user,
      isManualSuggestion: true
    };

    axios
      .post(`${environments.engine}/suggestion`, body, config)
      .then((res) => {
        setLoadingObj({ ...loadingObj, suggestion: false });
        setParametersInfos({
          ...parametersInfos,
          engine: {
            ...parametersInfos.engine,
            lastExecution: moment().subtract(3, 'hours'),
            user: userInfo.user,
            isRunning: true
          }
        });

        let descriptionMessage = '';
        let statusMessage = '';

        if (!res.data?.errorNotes?.length) {
          descriptionMessage = 'Gerando sugestões...';
          statusMessage = 'warning';
        } else {
          descriptionMessage = 'Erro ao gerar sugestões';
          statusMessage = 'error';
        }

        setMessage({ description: descriptionMessage, status: statusMessage });
      })
      .catch(() => {
        setLoadingObj({ ...loadingObj, suggestion: false });
        const errorMessage = 'Erro ao gerar sugestões';

        setMessage({
          description: errorMessage,
          status: 'error'
        });
      });
  };

  const handleSalesProcessStyles = (value) => {
    let text = '';
    let color = '';

    if (value === 'ACTIVE' || value === 'NORMAL') {
      text = value === 'ACTIVE' ? 'Ativo' : 'Normal';
      color = '#5062F0';
    } else if (value === 'INACTIVE') {
      text = 'Inativo';
      color = '#F53D4C';
    } else {
      text = 'Alto';
      color = '#F2B90D';
    }

    return {
      text,
      color
    };
  };

  const closureTotal = useMemo(() => {
    if (salesInfos.length) {
      const total = salesInfos.find(el => el._id === parseFloat(getId))?.total || 0

      return total;
    }
    return 0;
  }, [salesInfos]);

  const closureTotalLessOneDay = useMemo(() => {
    if (salesInfosLessOneDay.length) {
      const total = salesInfosLessOneDay.find(el => el._id === parseFloat(getId))?.total || 0

      return total;
    }
    return 0;
  }, [salesInfosLessOneDay]);

  const closureStatus = useMemo(() => {
    if (parametersInfos?.closure) {
      const {
        status,
        message: closureMessage,
        lastExecution
      } = parametersInfos.closure;

      return (
        <>
          <span className="lightBlue bold">
            {`${format(
              new Date(lastExecution).valueOf() +
                new Date().getTimezoneOffset() * 60 * 1000,
              'dd/MM/yyyy'
            )}
            às
            ${format(
              new Date(lastExecution).valueOf() +
                new Date().getTimezoneOffset() * 60 * 1000,
              'HH:mm'
            )}`}

            {parametersInfos?.closure?.closureTotalValue &&
              `
              -
              R$ ${parametersInfos.closure.closureTotalValue.toLocaleString(
                'pt-br',
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }
              )}
            `}
          </span>

          {status === 'SUCCESS' ? (
            <CheckCircle style={{ color: '#5062F0', fontSize: '16px' }} />
          ) : (
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={closureMessage}
            >
              <img src={WarningYellowIcon} alt="Warning divergent Icon" />
            </Tooltip>
          )}
        </>
      );
    }
    return null;
  }, [parametersInfos]);

  const handleClosure = () => {
    setLoadingObj({ ...loadingObj, closure: true });

    const body = {
      restaurantId: getId,
      date: format(new Date(), 'yyyy-MM-dd'),
      closureValue: closureTotal,
      user: userInfo.user
    };

    axios
      .put(`${environments.catalog}/inventories/counts/internal`, body)
      .then((res) => {
        setLoadingObj({ ...loadingObj, closure: false });
        setParametersInfos({
          ...parametersInfos,
          closure: {
            ...parametersInfos.closure,
            lastExecution: moment().subtract(3, 'hours'),
            user: userInfo.user,
            isRunning: true
          }
        });

        if (!res.data?.errorNotes?.length) {
          setMessage({
            description: 'Efetuando fechamento...',
            status: 'warning'
          });
        } else {
          setMessage({
            description: 'Erro ao efetuar fechamento',
            status: 'error'
          });
        }
      })
      .catch(() => {
        setLoadingObj({ ...loadingObj, closure: false });
        setMessage({
          description: 'Erro ao efetuar fechamento',
          status: 'error'
        });
      });
  };

  useEffect(() => {
    if (isRunningClosure) {
      setTimeout(() => {
        setRefreshPage(Math.random());
        window.location.reload();
      }, 60000);
    }
  }, [isRunningClosure, refreshPage]);

  return (
    <Layout>
      <TitleHead title="Fechamento e Sugestões" />

      <GlobalStyle />

      <Container className="closureAndSuggestionsPage">
        <PageTitleAndFilter title="Fechamento e Sugestões" />

        <section className="dFlex flexColumn">
          <div className="cardBlueTitleDefault closureCard">
            <div className="cardHeader">
              <p>FECHAMENTO</p>
            </div>

            <LastDayInfosBox grayBg withShadow>
              <p className="dFlex justifyEnd">
                <span className="mr10">Último concluído:</span>

                <span className="bold" style={{ color: '#454A67' }}>
                  {format(
                    new Date(actualDateLessDay).valueOf() -
                      new Date().getTimezoneOffset() * 60 * 1000,
                    'dd/MM/yyyy'
                  )}
                </span>
              </p>

              <p className="dFlex justifyEnd">
                <span className="mr10 dFlex alignCenter">
                  Vendas:{' '}
                  <InvoiceModal
                    restaurantId={getId}
                    rangeDate={rangeDate}
                    setRangeDate={setRangeDate}
                    rangeDateEx={rangeDateEx}
                    productFilter={productFilter}
                    setProductFilter={setProductFilter}
                    handleModalOpen={resetFirstDate}
                  />
                </span>

                <span className="bold" style={{ color: '#7583F0' }}>
                  {isLoadingSalesInfos ? (
                    <CircularProgress size={20} />
                  ) : (
                    `R$ ${closureTotalLessOneDay.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}`
                  )}
                </span>
              </p>
            </LastDayInfosBox>

            <div className="cardContent">
              <div className="totalSalesInfo dFlex flexColumn justifyEnd">
                <p className="dFlex justifyEnd">
                  <span className="mr10">Em aberto ou parcial:</span>

                  <span className="bold">
                    {format(
                      new Date(actualDate).valueOf() -
                        new Date().getTimezoneOffset() * 60 * 1000,
                      'dd/MM/yyyy'
                    )}
                  </span>
                </p>

                <p className="dFlex justifyEnd">
                  <span className="mr10 dFlex alignCenter">
                    Vendas:{' '}
                    <InvoiceModal
                      restaurantId={getId}
                      rangeDate={rangeDatePartial}
                      rangeDateEx={rangeDatePartialEx}
                      setRangeDate={setRangeDatePartial}
                      productFilter={productFilterPartial}
                      setProductFilter={setProductFilterPartial}
                      handleModalOpen={resetFirstDate}
                    />
                  </span>

                  <span className="lightBlue bold">
                    {isLoadingSalesInfos ? (
                      <CircularProgress size={20} />
                    ) : (
                      `R$ ${closureTotal.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}`
                    )}
                  </span>
                </p>
              </div>

              {/* <ButtonsContainer>
                <Button
                  className="defaultButton submitButton"
                  design="contained"
                  onClick={handleClosure}
                  disabled={!closureTotal || isRunningClosure}
                  label={
                    isRunningClosure ? 'Processando...' : 'Efetuar Fechamento'
                  }
                />
              </ButtonsContainer> */}

              <div className="footerInfos">
                <ProcessingInfosContainer>
                  {parametersInfos?.closure?.isRunning && (
                    <>
                      <p className="error">Processando, aguarde...</p>

                      <p
                        className="lightBlue m0 cursorPointer"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        Clique aqui para atualizar
                      </p>
                    </>
                  )}
                </ProcessingInfosContainer>

                <ClosureStatusContainer>
                  <p>Último fechamento executado: {closureStatus}</p>
                </ClosureStatusContainer>

                <p>
                  Usuário:
                  <span className="lightBlue bold">
                    {parametersInfos?.closure?.user}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="cardBlueTitleDefault salesCard">
            <div className="cardHeader">
              <p>PROCESSAMENTO DE VENDAS</p>
            </div>

            <div className="cardContent">
              <div className="saleInfoLine processStatus">
                <p className="mb0">Processamento:</p>

                <p
                  className="mb0"
                  style={{
                    color: handleSalesProcessStyles(
                      salesProcessInfos?.statusProcessing
                    ).color
                  }}
                >
                  {
                    handleSalesProcessStyles(
                      salesProcessInfos?.statusProcessing
                    ).text
                  }
                </p>
              </div>

              <div className="saleInfoLine volumeStatus">
                <p className="mb0">Volume:</p>

                <p
                  className="mb0"
                  style={{
                    color: handleSalesProcessStyles(
                      salesProcessInfos?.statusVolume
                    ).color
                  }}
                >
                  {
                    handleSalesProcessStyles(salesProcessInfos?.statusVolume)
                      .text
                  }
                </p>
              </div>
            </div>
          </div>

          <div className="cardBlueTitleDefault suggestionsCard">
            <div className="cardHeader">
              <p>SUGESTÕES</p>
            </div>

            <LastDayInfosBox>
              <p className="dFlex justifyEnd">
                <span className="mr10">Serão geradas para:</span>

                <span className="bold">
                  {format(
                    new Date(actualDatePlusOne).valueOf() -
                      new Date().getTimezoneOffset() * 60 * 1000,
                    'dd/MM/yyyy'
                  )}
                </span>
              </p>
            </LastDayInfosBox>

            <div className="cardContent pt0">
              <ButtonsContainer>
                <Button
                  id="generateSuggestionsButton"
                  className="defaultButton submitButton"
                  design="contained"
                  disabled={!isAbleSuggestion || isRunningSuggestion}
                  label={isRunningSuggestion ? 'Gerando...' : 'Gerar Sugestões'}
                  onClick={() => {
                    generateSuggestion();
                  }}
                />
              </ButtonsContainer>

              <p className="error">
                Importante: Esta ação só poderá ser executada 1 vez por dia
              </p>

              <div className="footerInfos">
                <ProcessingInfosContainer
                  show={parametersInfos?.engine?.isRunning}
                >
                  {parametersInfos?.engine?.isRunning && (
                    <>
                      <p className="error">Gerando, aguarde...</p>

                      <p
                        className="lightBlue m0 cursorPointer"
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        Clique aqui para atualizar
                      </p>
                    </>
                  )}
                </ProcessingInfosContainer>

                <p>
                  Último fechamento executado:
                  {parametersInfos?.engine?.lastExecution && (
                    <span className="lightBlue bold">
                      {`${format(
                        new Date(
                          parametersInfos?.engine?.lastExecution
                        ).valueOf() +
                          new Date(
                            parametersInfos?.engine?.lastExecution
                          ).getTimezoneOffset() *
                            60 *
                            1000,
                        'dd/MM/yyyy'
                      )}
                      às
                      ${format(
                        new Date(
                          parametersInfos?.engine?.lastExecution
                        ).valueOf() +
                          new Date(
                            parametersInfos?.engine?.lastExecution
                          ).getTimezoneOffset() *
                            60 *
                            1000,
                        'HH:mm'
                      )}`}
                    </span>
                  )}
                </p>

                <p>
                  Usuário:
                  <span className="lightBlue bold">
                    {parametersInfos?.engine?.user}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>

        <Snackbar
          open={!!message.description}
          autoHideDuration={2000}
          onClose={() => setMessage({ description: '', status: '' })}
        >
          <Alert
            onClose={() => setMessage({ description: '', status: '' })}
            severity={message.status}
            color={message.status}
          >
            {message.description}
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClosureAndSuggestions);
