import React from 'react';
import { Label, InformationFieldWrapper } from './index.style';

const InformationField = ({
    label, 
    content, 
    underline, 
    align, 
    color, 
    idCopyClipboard,
    ExtraComponentLabel
}) => {
    return(
        <InformationFieldWrapper underline={underline} color={color}>
            <Label style={{ textAlign: align }} color='#0F1225' bold>
                {label}
            </Label>

            <Label id={idCopyClipboard + '-' || ''} style={{ textAlign: align }} color='#666770'>
                {content}
                
                {ExtraComponentLabel && 
                    <ExtraComponentLabel />
                }
            </Label>
        </InformationFieldWrapper>
    );
}

export default InformationField;
