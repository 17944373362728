/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import axios from 'axios';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import PaperComponent from 'components/PaperComponent';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import ExtractCountModal from './dialogs/ExtractCountsModal';
import ExtractCountsTable from './ExtractCountsTable';

function ExtratoContagem({ userInfo }) {
  const { id: getId } = useParams();
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'counts', open: false, count: null }
  ]);
  const [filters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtersChanged, setFiltersChanged] = useState([]);
  const environments = Environment(process.env.REACT_APP_ENV);
  const [extractCounts, setExtractCounts] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [scrollY, setScrollY] = useState(0);
  const [periodicityId, setPeriodicityId] = useState(0);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Contagem',
        filterName: 'periodicityId',
        placeHolder: 'Selecione',
        size: 'medium',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/periodicities`,
        key: 'periodicities',
        optionReference: 'description',
        options: []
      },
      {
        label: 'Início',
        type: 'dateInputField',
        filterName: 'initialDate',
        size: 'small',
        isExtract: true,
        disabled: true,
        disabledDays: [],
        tooltipText: 'Necessário selecionar uma Contagem.'
      },
      {
        label: 'Fim',
        type: 'dateInputField',
        filterName: 'finalDate',
        size: 'small',
        isExtract: true,
        disabled: true,
        disabledDays: [],
        tooltipText: 'Necessário selecionar uma Contagem.'
      },
      {
        label: 'Produto',
        filterName: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        optionReference: 'description',
        size: 'medium',
        options: []
      },
      {
        label: 'Variação',
        filterNameFirst: 'variationInit',
        filterNameSecond: 'variationEnd',
        placeHolder: '0,00%',
        type: 'range',
        size: 'small'
      }
    ],
    []
  ]);

  const handleFilterCounts = (date, periodicityId) => {
    axios
      .get(
        `${
          environments.catalog
        }/inventories/counts/periodicities?originId=${getId}&periodicityId=${periodicityId}&month=${
          date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1
        }&year=${date.getFullYear()}`
      )
      .then((response) => {
        const newFilters = [...filterFields];

        newFilters[0][1].disabledDays = response.data.map((item) => item.date);
        newFilters[0][2].disabledDays = response.data.map((item) => item.date);

        setFilterFields(newFilters);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFilter = (param, value) => {
    if (param === 'initialDate') {
      setInitialDate(new Date(value).valueOf() + 86400000);
    } else if (param === 'finalDate') {
      setEndDate(new Date(value).valueOf() + 86400000);
    }

    if (param === 'periodicityId') {
      const newFilters = [...filterFields];

      newFilters[0][1].disabled = !value;
      newFilters[0][1].tooltipText = value
        ? ''
        : 'Necessário selecionar uma Contagem.';
      newFilters[0][2].disabled = !value;
      newFilters[0][2].tooltipText = value
        ? ''
        : 'Necessário selecionar uma Contagem.';

      setFilterFields(newFilters);
      setPeriodicityId(value);

      handleFilterCounts(new Date(), value);
      !value && setExtractCounts([]);
    }

    if (param === 'extractDate' && periodicityId) {
      handleFilterCounts(value, periodicityId);
    } else {
      handleFilterOptions(param, value, filtersChanged, setFiltersChanged);
    }
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged
    );
  };

  function logit() {
    setScrollY(
      document.querySelector('main .extractCountsListTable').scrollTop
    );
  }

  useEffect(() => {
    function watchScroll() {
      document
        .querySelector('main .extractCountsListTable')
        .addEventListener('scroll', logit);
    }
    watchScroll();
    return () => {
      document
        .querySelector('main .extractCountsListTable')
        .removeEventListener('scroll', logit);
    };
  });

  const columns = useMemo(() => [
    {
      id: 'expander',
      Header: 'CATEGORIA',
      className: 'defaultHeader category',
      columns: [
        {
          Header: '',
          accessor: 'category',
          className: 'defaultSubtitle withoutRightBorder withLeftDefaultBorder',
          Cell: ({ row }) => {
            if (!row.original.diffs) {
              return (
                <Grid container spacing={1} alignItems="center">
                  <Grid
                    container
                    item
                    xs={12}
                    justify="flex-start"
                    className="p0"
                  >
                    <span
                      {...row.getToggleRowExpandedProps({
                        style: {
                          paddingLeft: `${row.depth * 0.6}rem`
                        }
                      })}
                    >
                      {row.isExpanded ? <ExpandMore /> : <ChevronRight />}
                    </span>

                    <Typography
                      style={{
                        margin: 0,
                        fontFamily: 'Lato',
                        fontSize: '11px',
                        textAlign: 'center',
                        padding: '3px 0'
                      }}
                    >
                      {ValidationLength(row.original.description, 20)}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }

            return <span>&nbsp;</span>;
          }
        }
      ]
    },
    {
      Header: 'CONTAGENS/ITEM',
      className: 'defaultHeader itemCounts',
      columns: [
        {
          Header: '',
          accessor: 'itemCounts',
          className: 'defaultSubtitle withoutBorder',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <Link
                  className="dFlex alignCenter"
                  onClick={() => {
                    setModalSettings(
                      modalSettings.map((modal) =>
                        modal.name === 'counts'
                          ? { ...modal, open: true, count: props.row.original }
                          : modal
                      )
                    );
                  }}
                >
                  <span className="diffCounts">
                    {props.row.original.countsLength}
                  </span>

                  {ValidationLength(props.row.original.description, 25)}
                </Link>
              );
            }
            return <span>&nbsp;</span>;
          }
        },
        {
          Header: 'PREÇO R$',
          accessor: 'itemCountsPrice',
          className: 'defaultSubtitle withRightBorder price',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.averageCost
                    ? props.row.original.averageCost.toLocaleString('pt-br', {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3
                      })
                    : '0,000'}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffAvgPrice
                    ? props.row.original.diffAvgPrice.toLocaleString('pt-br', {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3
                      })
                    : '0,000'}
                </p>
              );
            }
            return <span>&nbsp;</span>;
          }
        }
      ]
    },
    {
      Header: 'DIFERENÇA',
      className: 'defaultHeader difference',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'differenceQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffQuantityTotal) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffQuantityTotal.toLocaleString(
                    'pt-br',
                    { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                  )}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'differenceTotal',
          className: 'defaultSubtitle total',
          Cell: (props) => {
            if (props.row.original.diffValueTotal) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffValueTotal.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                  })}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'VAR VENDAS',
          accessor: 'differenceSalesVariation',
          className: 'defaultSubtitle withRightBorder salesVar',
          Cell: (props) => {
            if (props.row.original.diffSalesVariation) {
              return (
                <p
                  className="cellWithStyle"
                  style={{
                    margin: 0,
                    textAlign: 'right',
                    color:
                      props.row.original.diffSalesVariation > 0
                        ? '#009934'
                        : '#990000',
                    background:
                      props.row.original.diffSalesVariation > 0
                        ? '#DFF7E8'
                        : '#F7DFDF'
                  }}
                >
                  {props.row.original.diffSalesVariation.toLocaleString(
                    'pt-br',
                    { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                  )}{' '}
                  %
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000%</p>;
          }
        }
      ]
    },
    {
      Header: `CONTAGEM ${
        scrollY > 80
          ? `- ${format(new Date(endDate), 'dd/MMM').toUpperCase()}`
          : ''
      }`,
      className: 'defaultHeader mainCount',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'mainCountQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.counts.length
                          ? parseFloat(diff.counts[1].quantity) +
                            parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.countLastQuantityTotal
                    ? props.row.original.countLastQuantityTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'mainCountTotal',
          className: 'defaultSubtitle withRightBorder total',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.counts.length
                          ? parseFloat(diff.counts[1].value) + parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.countLastValueTotal
                    ? props.row.original.countLastValueTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        }
      ]
    },
    {
      Header: 'ESTOQUE TEÓRICO',
      className: 'defaultHeader theoreticalStock',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'theoreticalStockQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.theoreticalStock
                    ? props.row.original.theoreticalStock.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.theoreticalStockQuantityTotal
                    ? props.row.original.theoreticalStockQuantityTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'theoreticalStockTotal',
          className: 'defaultSubtitle withRightBorder total',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.theoreticalCostTotalStock
                    ? props.row.original.theoreticalCostTotalStock.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.theoreticalStockValueTotal
                    ? props.row.original.theoreticalStockValueTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        }
      ]
    },
    {
      Header: `CONTAGEM ${
        scrollY > 80
          ? `- ${format(new Date(initialDate), 'dd/MMM').toUpperCase()}`
          : ''
      }`,
      className: 'defaultHeader count',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'countQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs[0].counts.length &&
                  props.row.original.diffs[0].counts[0].quantity
                    ? props.row.original.diffs[0].counts[0].quantity.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.countFirstQuantityTotal
                    ? props.row.original.countFirstQuantityTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'countTotal',
          className: 'defaultSubtitle withRightBorder total',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs[0].counts.length &&
                  props.row.original.diffs[0].counts[0].value
                    ? props.row.original.diffs[0].counts[0].value.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.countFirstValueTotal
                    ? props.row.original.countFirstValueTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        }
      ]
    },
    {
      Header: 'COMPRAS',
      className: 'defaultHeader shopping',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'shoppingQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.shopping
                          ? parseFloat(diff.shopping.quantity) +
                            parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.shoppingQuantityTotal
                    ? props.row.original.shoppingQuantityTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'shoppingTotal',
          className: 'defaultSubtitle withRightBorder total',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.shopping
                          ? parseFloat(diff.shopping.value) + parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.shoppingValueTotal
                    ? props.row.original.shoppingValueTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        }
      ]
    },
    {
      Header: 'PRODUZIDO',
      className: 'defaultHeader produced',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'producedQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.productionProduced
                          ? parseFloat(diff.productionProduced.quantity) +
                            parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.productionProducedQuantityTotal
                    ? props.row.original.productionProducedQuantityTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'producedTotal',
          className: 'defaultSubtitle withRightBorder total',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.productionProduced
                          ? parseFloat(diff.productionProduced.value) +
                            parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.productionProducedValueTotal
                    ? props.row.original.productionProducedValueTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        }
      ]
    },
    {
      Header: 'VENDAS',
      className: 'defaultHeader sales',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'salesQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.sales
                          ? parseFloat(diff.sales.quantity) + parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.salesQuantityTotal
                    ? props.row.original.salesQuantityTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'salesTotal',
          className: 'defaultSubtitle withRightBorder total',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.sales
                          ? parseFloat(diff.sales.value) + parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.salesValueTotal
                    ? props.row.original.salesValueTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        }
      ]
    },
    {
      Header: 'CONSUMO PRODUÇÃO',
      className: 'defaultHeader productionConsumption',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'productionConsumptionQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.consumedProduction
                          ? parseFloat(diff.consumedProduction.quantity) +
                            parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.consumedProdQuantityTotal
                    ? props.row.original.consumedProdQuantityTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'productionConsumptionTotal',
          className: 'defaultSubtitle withRightBorder total',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.consumedProduction
                          ? parseFloat(diff.consumedProduction.value) +
                            parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.consumedProdValueTotal
                    ? props.row.original.consumedProdValueTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        }
      ]
    },
    {
      Header: 'PERDAS',
      className: 'defaultHeader losses',
      columns: [
        {
          Header: 'QTDE.',
          accessor: 'lossesQuantity',
          className: 'withBorder defaultSubtitle quantity',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.discard
                          ? parseFloat(diff.discard.quantity) +
                            parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.discardQuantityTotal
                    ? props.row.original.discardQuantityTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        },
        {
          Header: 'TOTAL R$',
          accessor: 'lossesTotal',
          className: 'defaultSubtitle withRightDefaultBorder total',
          Cell: (props) => {
            if (props.row.original.diffs && props.row.original.diffs.length) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.diffs
                    .reduce(
                      (total, diff) =>
                        diff.discard
                          ? parseFloat(diff.discard.value) + parseFloat(total)
                          : total,
                      0
                    )
                    .toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })}
                </p>
              );
            }
            if (!props.row.family) {
              return (
                <p style={{ margin: 0, textAlign: 'right' }}>
                  {props.row.original.discardValueTotal
                    ? props.row.original.discardValueTotal.toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3, maximumFractionDigits: 3 }
                      )
                    : '0,000'}
                </p>
              );
            }
            return <p style={{ margin: 0, textAlign: 'right' }}>0,000</p>;
          }
        }
      ]
    }
  ]);

  const getExtractCount = (SetData) => {
    setLoading(true);
    const params = filtersChanged.map((filter) => filter.urlParam).join('&');

    axios
      .get(
        `${environments.catalog}/products/extracts/counts?originId=${getId}&${params}`
      )
      .then((response) => {
        SetData(response.data.content);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetData([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    getExtractCount(setExtractCounts);
  }, [getId, filtersChanged, userInfo]);

  return (
    <Layout>
      <TitleHead title="Extrato de Contagens" />

      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Diferenças nas Contagens"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          getFilterhandle={getFilterhandle}
          dynamicJustifyOff
        />

        <ExtractCountsTable
          columns={columns}
          data={extractCounts}
          className="desktop extractCountsListTable"
          initialDate={initialDate}
          endDate={endDate}
          loading={loading}
        />
      </Container>

      <ExtractCountModal
        open={modalSettings.find((modal) => modal.name === 'counts').open}
        onClose={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'counts'
                ? { ...modal, open: false, count: null }
                : modal
            )
          );
        }}
        paperComponent={PaperComponent}
        count={modalSettings.find((modal) => modal.name === 'counts').count}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(ExtratoContagem);
