import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
    width: ${({ dynamicWidth }) => dynamicWidth ? "inherit" : "100%"};
    width: 100%;
    margin-top: 10px;
    font-style: normal;
    table-layout: ${({ tableFixed }) => tableFixed ? 'fixed' : 'auto'};
    empty-cells: hide;
    background: #FFF;
    border-spacing: 1px 0;
    border-collapse: collapse;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    padding-bottom: 15px;
    display: table;
    font-family: "Lato";

    @media only screen and (max-width: 960px) {
        display: none;
    }
    
    thead {
        tr {
            th:first-of-type{
                border-top-left-radius: 4px;
            }
            th:last-of-type{
                border-top-right-radius: 4px;
            }
        }
    }
    tbody {
        tr {
            ${(eachTD) => eachTD &&
        css`
                    td:nth-of-type(${eachTD} ) {
                        border-right: 1px solid #d2d3d7;
                    }
                `
    }
            
        }
    }

    td,
    tr {
        overflow: hidden;
    }
`;

export const StyledTR = styled.tr`
    background-color: ${({ backGroundColor }) => backGroundColor ? backGroundColor : 'transparent'};
    color: ${({ backGroundColor }) => backGroundColor ? 'white' : '#0B0D1D'};
    ${({ withLine }) => withLine && css`
        border-bottom: 1px solid #ECEDF1;
    `}
    ${({ hoverActive }) => hoverActive && css`
        &:hover{
            background-color: #E6E8FF;
        }
    `}
    th {
        position: sticky;
        top: -10px;
        z-index: 999;
    }
`;

export const StyledTH = styled.th`
    border-right: ${({ borderRight }) => borderRight && '1px solid #d2d3d7'};
    padding: 8px;
    font-size: 9px;
    line-height: 12px;
    font-weight: 600;
    background: ${({ backGroundColor }) => backGroundColor ? backGroundColor : '#313347'};
    border-color: ${({ borderColor }) => borderColor ? borderColor : '#d2d3d7'};
`;

export const StyledTD = styled.td`
    position: relative;
    border-right: ${({ borderRight }) => borderRight && '1px solid'};
    border-color: ${({ borderColor }) => borderColor ? borderColor : '#d2d3d7'};
    border-bottom: 1px solid #b0b5c9;
    border-top: 1px solid #b0b5c9;
    padding: ${({ padding }) => !padding && '8px'};
    font-size: 12px;
    line-height: 16px;
    text-align: ${({ align }) => align && 'right'};
    ${({ buttonRectColor }) => buttonRectColor && css`
        background-color: ${buttonRectColor.background};
        color: ${buttonRectColor.color};
    `}
`;

export const StyleTableBody = styled.tbody`
    background-color: white; 
    border-radius: 4px;
`;

export const SubStyledTR = styled.tr`
    background-color: #F2F2F4;
    color: #666770;
    font-size: 9px;
    padding: 8px;

    th {
        position: sticky;
        top: 14px;
        z-index: 999;
        background-color: #F2F2F4;
    }
`;

export const SubStyledTH = styled.th`
    border-right: ${({ borderRight }) => borderRight && '1px solid'};
    border-color: ${({ borderColor }) => borderColor ? borderColor : '#d2d3d7'};
    padding: 4px;
    padding-left: 8px;
    text-align: ${({ alignRight }) => alignRight && 'right'};
    color: ${({ fontColor }) => fontColor && fontColor};
    white-space: nowrap;
    ${({textAlign}) => textAlign && css`
        text-align: ${textAlign};
    `}
`;

export const InputWrapper = styled.div`
    display: ${({display}) => display ? display : "flex"};
    ${({ alignCenter }) => alignCenter && 
        css`
            text-align: center;
        `
    }
    ${({ typeAlign }) => typeAlign &&
        css`
            float: right;
        `
    }
    ${({ inputSize }) => inputSize &&
        css`
            width: ${inputSize};
        `
    }
    ${({ contentBlueCard }) => contentBlueCard &&
        css`
            background-color: ${contentBlueCard.backGroundColor};
            padding: 2px 2px 2px 9px;
            color: ${contentBlueCard.color};
            border-radius: 4px;
            ${contentBlueCard.border && css`
                border: 2px solid ${contentBlueCard.border};
            `}
        `
    }
`;
