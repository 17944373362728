import styled from 'styled-components';

export const SelectIcon = styled.img`
    width: 11px;
    cursor: pointer;
    transition: all 250ms ease-in;
    transform: ${({upArrow}) => upArrow ? 'rotate(180deg)' : 'none'};  
`;

export const TrStyled = styled.tr`
    background-color: #FAFAFA;
    display: ${({collapse}) => collapse ? 'table-row-group' : 'none'};
`;
