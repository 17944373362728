import styled from 'styled-components';

export const CreateNewTag = styled.div`
  width: 221px;
  margin: 0;
  .values {
    background-color: #f2f2f4;
    padding: 4px 8px;
    margin: 8px 0;
    p {
      color: #666770;
      font-size: 9px;
      font-weight: 700;
    }
  }
  .addValue {
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
`;

export const DialogTitleTag = styled.div`
  width: 221px;
  height: 28px;
  background-color: #333541;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  p {
    color: #ffffff;
    font-size: 9px;
    font-weight: 700;
    margin-left: 8px;
  }
`;

export const TagButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;

  p {
    margin: 0;
    color: #5062f0;
    cursor: pointer;
    text-decoration: underline;
  }

  button {
    padding: 8px;
    width: 65px;
    height: 32px;
    border-radius: 8px;
    background-color: #5062f0;
    color: white;
    font-size: 12px;
  }
`;

export const InputTags = styled.div`
  width: 100%;
  padding: 4px 8px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  .MuiInputBase-input {
    width: 203px;
    font-size: 12px;

    &::-webkit-input-placeholder {
      font-style: italic;
    }
  }
`;

export const CreateTagImgDiv = styled.div`
  right: ${({ type }) => (type === 'editTag' ? '20px' : '10px')};
  cursor: pointer;
  position: absolute;
`;
