import React from 'react';

import { Portal, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import { safeTranslate } from 'utils/translationUtils';

function CommonAlert({
  open,
  autoHideDuration,
  onClose,
  indexMessage,
  messagePersonal,
  severity,
  statusCode,
  width
}) {
  const { t } = useTranslation('translations');

  const cleanIndexMessage = indexMessage?.replace(/[.'"]/g, '');

  return (
    <Portal>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration || 6000}
        onClose={onClose}
        style={{
          width
        }}
      >
        <Alert
          severity={severity || 'error'}
          onClose={onClose}
          className="textAlignCenter"
        >
          {t(
            messagePersonal ||
              (indexMessage && safeTranslate(cleanIndexMessage)
                ? cleanIndexMessage
                : statusCode)
          )}
        </Alert>
      </Snackbar>
    </Portal>
  );
}

export default CommonAlert;
