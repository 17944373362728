import { createSlice } from '@reduxjs/toolkit';

const initialState = { status: false };

export const loadingSlice = createSlice({
  name: 'Loading',
  initialState,
  reducers: {
    enable(state) {
      state.status = true;
    },
    disable(state) {
      state.status = false;
    },
  },
});

export const { enable, disable } = loadingSlice.actions;

export default loadingSlice.reducer;
