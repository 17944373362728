import styled from 'styled-components';

export const PackageMobilePackageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
`;

export const Content = styled.div`
    width: 300px;
    box-shadow: 0px 2px 6px 2px rgb(1 3 17 / 45%);
    border-radius: 8px;
    background-color: white;
    z-index: 1150;
`;

export const ContentHead = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: white;
    background-color: #1F2445;
    padding: 8px 12px 8px 12px;
    font-size: 12px;
    border-radius: 8px 8px 0 0;
`;

export const FormControlWrapper = styled.div`
    width: 100%;
    padding: 8px;
    color: #010311;
    font-weight: normal;
    font-size: 12px;
`;
