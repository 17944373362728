import React from "react";

const SelectIcon = (props) => {
    return (
        <>
        {
            props.disable && 
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
                <path
                d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
                fill="#D0D3E2"
                />
                <path
                d="M13.2345 4.17748C12.9183 3.90992 12.445 3.94935 12.1775 4.26556C11.8909 4.60359 11.6009 4.93875 11.3097 5.27279C10.7812 5.87901 10.0674 6.6858 9.3239 7.49131C8.57798 8.29939 7.81213 9.09519 7.17716 9.6848C7.08047 9.77458 6.35742 10.5523 6.19312 10.4627C6.1047 10.4145 5.98852 10.3327 5.84747 10.2118C5.56549 9.97013 5.25253 9.63152 4.95117 9.26988C4.56879 8.81103 4.2076 8.3312 3.87473 7.83502C3.64726 7.49493 3.17522 7.39848 2.83397 7.62598C2.48933 7.85574 2.39631 8.32157 2.62608 8.66622C2.98826 9.2081 3.38163 9.72951 3.79883 10.2302C4.12247 10.6185 4.49701 11.0299 4.87128 11.3507C5.2554 11.68 5.7239 12 6.25 12C6.99566 12 7.6934 11.2524 8.19784 10.784C8.87537 10.1548 9.67202 9.32565 10.4261 8.50873C11.1826 7.68923 11.9063 6.87102 12.4403 6.25849C12.7364 5.91889 13.0312 5.57814 13.3225 5.23447C13.5901 4.91827 13.5507 4.44503 13.2345 4.17748Z"
                fill="white"
                />
            </svg>
        }
        {
            props.selected && !props.disable &&
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
                <path
                d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
                fill="#5062F0"
                />
                <path
                d="M13.2345 4.17748C12.9183 3.90992 12.445 3.94935 12.1775 4.26556C11.8909 4.60359 11.6009 4.93875 11.3097 5.27279C10.7812 5.87901 10.0674 6.6858 9.3239 7.49131C8.57798 8.29939 7.81213 9.09519 7.17716 9.6848C7.08047 9.77458 6.35742 10.5523 6.19312 10.4627C6.1047 10.4145 5.98852 10.3327 5.84747 10.2118C5.56549 9.97013 5.25253 9.63152 4.95117 9.26988C4.56879 8.81103 4.2076 8.3312 3.87473 7.83502C3.64726 7.49493 3.17522 7.39848 2.83397 7.62598C2.48933 7.85574 2.39631 8.32157 2.62608 8.66622C2.98826 9.2081 3.38163 9.72951 3.79883 10.2302C4.12247 10.6185 4.49701 11.0299 4.87128 11.3507C5.2554 11.68 5.7239 12 6.25 12C6.99566 12 7.6934 11.2524 8.19784 10.784C8.87537 10.1548 9.67202 9.32565 10.4261 8.50873C11.1826 7.68923 11.9063 6.87102 12.4403 6.25849C12.7364 5.91889 13.0312 5.57814 13.3225 5.23447C13.5901 4.91827 13.5507 4.44503 13.2345 4.17748Z"
                fill="white"
                />
            </svg>
        }

        {
            !props.selected && !props.disable &&
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"  {...props}>
                <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#0E1337"/>
            </svg>
        }
        </>
    );
}
  

export default SelectIcon;
