const menuLocalize = {
  'need to send restaurantId in body to complete request':
    'Por favor, envie o id do restaurante no corpo para concluir a solicitação',
  'need to send date in body to complete request':
    'Por favor, envie uma data no corpo para concluir a solicitação',
  'not found any subsidiaryCode for this company':
    'Não foi encontrado nenhum código subsidiário para esta empresa',
  'not found sales for this day': 
    'Não foram encontradas vendas para este dia',
  'there is a reprocess for this date in progress':
    'Há um reprocesso para esta data em andamento',
  'no new sales to reprocess':
    'O total de vendas para esta data já constam no Bloom',
  "date range limit reached, set a smaller range": 'limite de intervalo de datas atingido, defina um intervalo menor'
};

export default menuLocalize;
