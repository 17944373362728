/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import MobileInputNumber from 'components/BloomForms/MobileInputNumber';
import DialogIcon from 'images/icons/components/dialogIcon';
import SVGStoreIconGreen from 'images/icons/components/storeIconGreen';
import StoreIcon from 'images/icons/objects/clipboards/fileBlueOutline.svg';
import RequestQuotes from 'images/icons/objects/clipboards/requestQuotes.svg';
import numberToReal from 'utils/numberToReal';

import {
  CollapsedHead,
  StoreContainer,
  ProductCategoryWrapper,
  ColapseItem,
  QuotesWrapper,
  ProviderRelation,
  QuotesButton,
  LastSevenDaysCard,
  TotalWrapper
} from './index.style';

import StorePurchaseSuggestionsDialog from '../../../dialogs/StorePurchaseSuggestionsDialog';
import ConsumeDialog from '../../dialogs/ConsumeDialog/ConsumeDialog';
import InflationMobileDialog from '../../dialogs/InflationDialog/InflationMobileDialog';
import NegociationDialog from '../../dialogs/Negociation/Negociation';
import QuantityAndDurationDialog from '../../dialogs/QuantityAndDuration/QuantityAndDurationDialog';
import RelatedProvidersDialog from '../../dialogs/RelatedProviders/RelatedProvidersDialog';
import SkuDialog from '../../dialogs/SkuDialog/skuDialog';
import removeUnityMeasurementsFromString, {
  formatInflationValue
} from '../../services';
import {
  CardItem,
  CardItemTitle,
  CardItemSubTitle,
  CardItensWrapper,
  CardItemInflation,
  CardItemSub
} from '../index.style';

const getQuotationOption = (quotes) => {
  if (quotes === 'p') {
    return 'Sem cotação cadastrada';
  }

  if (quotes === 'c') {
    return 'Sem cotação cadastrada';
  }

  if (quotes === 'm') {
    return 'CADASTRADA MANUALMENTE';
  }

  return 'none';
};

function QuotationCollapsed({
  cardItem,
  originIds,
  userInfo,
  restaurantsIds,
  tableToModalProps,
  tableSettings
}) {
  const { content: orderSuggestion = [] } = cardItem[3];
  const [orderValue, orderRestaurants = '0'] = orderSuggestion;

  const { content: productCategory = '' } = cardItem[1];
  const { content: skuQuantity = 0 } = cardItem[2];

  const { rightIcon: { setOption } = {}, content: quoteVolume = '' } =
    cardItem[6];
  const { content: quoteUnityValue } = cardItem[4];

  const { content: packageQuotes = [] } = cardItem[5];
  const [packageQuotesFirst = 0, packageQuotesSecond = ''] = packageQuotes;

  const {
    content: relationalProviders = '',
    rightIcon: relationProvidersOptions = []
  } = cardItem[7];
  const { content: inflationValue = '', buttonLink: inflationButtonLink = {} } =
    cardItem[9];

  const { content: inflationLastValue = '' } = cardItem[8];

  const { content: consLastSevenDays = [] } = cardItem[10];
  const { content: volLastSevenDays = '' } = cardItem[11];
  const { content: unityStock = '' } = cardItem[12];
  const { content: stockQunatity = '', buttonLink: stockQuantiyColors = {} } =
    cardItem[13];

  const { content: durationValue = '' } = cardItem[14];
  const { content: totalQuote = '' } = cardItem[15];

  const [openSkuDialog, setOpenSkuDialog] = useState(false);
  const [openConsumeDialog, setOpenConsumeDialog] = useState(false);
  const [openRelatedProvidersDialog, setOpenRelatedProvidersDialog] =
    useState(false);
  const [openNegociationDialog, setOpenNegociationDialog] = useState(false);
  const [openInflationMobileDialog, setOpenInflationMobileDialog] =
    useState(false);
  const [openQuantityAndDurationDialog, setOpenQuantityAndDurationDialog] =
    useState(false);
  const [orderQuantity, setOrderQuantity] = useState(orderValue);
  const [
    openStorePurchaseSuggestionsDialog,
    setOpenStorePurchaseSuggestionsDialog
  ] = useState({ modalPurchaseSuggestions: false });

  const handleCloseStorePurchaseSuggestionsDialog = () => {
    setOpenStorePurchaseSuggestionsDialog({
      modalPurchaseSuggestions: false
    });
  };

  const handleInputChange = (value) => {
    cardItem[3].content[0] = parseFloat(value);
    cardItem[0].orderQuantity = parseFloat(value);
    setOrderQuantity(parseFloat(value));
  };

  const productsIds = cardItem[0]?.productsIds?.join(',');

  return (
    <div style={{ width: '100%' }}>
      {openSkuDialog && (
        <SkuDialog
          openSkuDialog={openSkuDialog}
          setOpenSkuDialog={setOpenSkuDialog}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      )}

      {openStorePurchaseSuggestionsDialog.modalPurchaseSuggestions && (
        <StorePurchaseSuggestionsDialog
          openModal={openStorePurchaseSuggestionsDialog}
          handleClose={handleCloseStorePurchaseSuggestionsDialog}
          userInfo={userInfo}
          restaurantsIds={restaurantsIds}
          productsIds={productsIds}
          tableToModalProps={tableToModalProps}
          tableSettings={tableSettings}
          mobileBehavior
          cardItem={cardItem}
          handleInputChange={handleInputChange}
        />
      )}

      {openConsumeDialog && (
        <ConsumeDialog
          openConsumeDialog={openConsumeDialog}
          setOpenConsumeDialog={setOpenConsumeDialog}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      )}

      {openRelatedProvidersDialog && (
        <RelatedProvidersDialog
          openRelatedProvidersDialog={openRelatedProvidersDialog}
          setOpenRelatedProvidersDialog={setOpenRelatedProvidersDialog}
          restaurantsIds={originIds}
          productsIds={productsIds}
          productName={cardItem[0].content}
        />
      )}

      {openNegociationDialog && (
        <NegociationDialog
          openNegociationDialog={openNegociationDialog}
          setOpenNegociationDialog={setOpenNegociationDialog}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      )}
      
      {openInflationMobileDialog && (
        <InflationMobileDialog
          openInflationMobileDialog={openInflationMobileDialog}
          setOpenInflationMobileDialog={setOpenInflationMobileDialog}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      )}

      {openQuantityAndDurationDialog && (
        <QuantityAndDurationDialog
          openQuantityAndDurationDialog={openQuantityAndDurationDialog}
          setOpenQuantityAndDurationDialog={setOpenQuantityAndDurationDialog}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      )}

      <CollapsedHead>
        <MobileInputNumber
          label="SUGESTÃO DE PEDIDO"
          content={orderQuantity}
          handleInputValue={handleInputChange}
          sizes={{
            totalWidth: '188px',
            label: '60%',
            input: '40%'
          }}
        />

        <StoreContainer
          onClick={() =>
            setOpenStorePurchaseSuggestionsDialog({
              modalPurchaseSuggestions: true
            })
          }
        >
          para {orderRestaurants?.replace('/', '')} lojas{' '}

          <img src={StoreIcon} alt="store icons" />
        </StoreContainer>
      </CollapsedHead>

      <ColapseItem>
        <ProductCategoryWrapper>
          {productCategory}
        </ProductCategoryWrapper>

        <StoreContainer onClick={() => setOpenSkuDialog(true)}>
          {skuQuantity} SKUs Cadastrados{' '}

          <img src={StoreIcon} alt="store icons" />
        </StoreContainer>
      </ColapseItem>

      <QuotesWrapper>
        <div>COTAÇÃO</div>

        <div>{getQuotationOption(setOption)}</div>
      </QuotesWrapper>

      <CardItensWrapper>
        <CardItem>
          <CardItemTitle>UNIDADE R$</CardItemTitle>

          <CardItemSubTitle>
            {numberToReal(quoteUnityValue[0])}
            <CardItemSub>{quoteUnityValue[1]}</CardItemSub>
          </CardItemSubTitle>
        </CardItem>

        <CardItem>
          <CardItemTitle>EMBALAGEM R$</CardItemTitle>

          <CardItemSubTitle>
            {numberToReal(packageQuotesFirst)}
            <CardItemSub>{packageQuotesSecond}</CardItemSub>
          </CardItemSubTitle>
        </CardItem>

        <CardItem>
          <CardItemTitle>VOLUME R$</CardItemTitle>

          <CardItemSubTitle>{numberToReal(quoteVolume)}</CardItemSubTitle>
        </CardItem>
      </CardItensWrapper>

      <ProviderRelation>
        <div>FORNECEDORES RELACIONADOS</div>

        <div onClick={() => setOpenRelatedProvidersDialog(true)}>
          {relationalProviders} <img src={StoreIcon} alt="store icons" />
        </div>
      </ProviderRelation>

      <ColapseItem>
        <QuotesButton
          width="132px"
          marginRight
          isDesableButton={relationProvidersOptions[0]?.setOption === 'inValid'}
          onClick={() => setOpenNegociationDialog(true)}
        >
          <DialogIcon
            alt="negotiation icon"
            color={
              relationProvidersOptions[0]?.setOption === 'inValid'
                ? '#454A67'
                : 'white'
            }
          />

          {relationProvidersOptions[0]?.setOption === 'inValid' &&
            'Sem negociação'}

          {/* mock here - see later */}
          {relationProvidersOptions[0]?.setOption !== 'inValid' &&
            '0 Negociações'}
        </QuotesButton>
        
        <QuotesButton width="152px" outlineButton>
          <img src={RequestQuotes} alt="quotes request" />

          {relationProvidersOptions[1]?.tooltip}
        </QuotesButton>
      </ColapseItem>

      <QuotesWrapper width100>
        <div>INFLAÇÃO</div>
      </QuotesWrapper>

      <CardItensWrapper spaceEvenly>
        <CardItem>
          <CardItemTitle>ÚLTimo PREÇO</CardItemTitle>

          <CardItemSubTitle>
            <CardItemSub>R$</CardItemSub>
            {removeUnityMeasurementsFromString(inflationLastValue).replace(
              '/',
              ''
            )}
          </CardItemSubTitle>
        </CardItem>

        <CardItemInflation
          backgroundColor={inflationButtonLink?.backgroundColor}
          color={inflationButtonLink?.color}
          underline
          onClick={() => setOpenInflationMobileDialog(true)}
        >
          {formatInflationValue(inflationValue)} de inflação{' '}

          <SVGStoreIconGreen
            alt="store icons green"
            color={inflationButtonLink?.color}
          />
        </CardItemInflation>
      </CardItensWrapper>

      <QuotesWrapper width100 paddinBottom="4px">
        <div>CONSUMO 7 DIAS</div>
      </QuotesWrapper>

      <ColapseItem>
        <StoreContainer onClick={() => setOpenConsumeDialog(true)}>
          {numberToReal(consLastSevenDays[0])}{' '}
          {quoteUnityValue[1]?.replace('/', '')} em{' '}
          {consLastSevenDays[1]?.replace('/', '')} lojas{' '}
          <img src={StoreIcon} alt="store icons" />
        </StoreContainer>

        <ProductCategoryWrapper>
          R$ {numberToReal(volLastSevenDays)} em volume
        </ProductCategoryWrapper>
      </ColapseItem>

      <QuotesWrapper>
        <div style={{ width: '65%' }}>ESTOQUE</div>
        <div style={{ width: '35%' }}>{durationValue} DE DURAÇÃO</div>
      </QuotesWrapper>

      <CardItensWrapper spaceEvenly>
        <CardItem>
          <CardItemTitle>UNIDADE R$</CardItemTitle>

          <CardItemSubTitle>
            {numberToReal(unityStock)} {quoteUnityValue[1]}
          </CardItemSubTitle>
        </CardItem>

        <CardItemInflation
          backgroundColor={stockQuantiyColors?.backgroundColor}
          color={stockQuantiyColors?.color}
          underline
          onClick={() => setOpenQuantityAndDurationDialog(true)}
        >
          {removeUnityMeasurementsFromString(stockQunatity).replace('/', '')} em{' '}

          {consLastSevenDays[1]?.replace('/', '')} lojas{' '}

          <SVGStoreIconGreen
            alt="store icons green"
            color={stockQuantiyColors?.color}
          />
        </CardItemInflation>
      </CardItensWrapper>

      <TotalWrapper>
        <div>TOTAL R$</div>
        
        <div>R$ {numberToReal(totalQuote)}</div>
      </TotalWrapper>
    </div>
  );
}

export default QuotationCollapsed;
