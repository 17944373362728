/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, Suspense } from 'react';

import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import axios from 'axios';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import Button from 'components/Button/button';
import SendIcon from 'images/icons/arrows/sendIcon.svg';
import SendIconDisabled from 'images/icons/arrows/sendIconDisabled.svg';
import OrderIcon from 'images/icons/objects/clipboards/setupGrayIcon.svg';
import WhatsApp from 'images/icons/socialMedias/whatsApp.svg';
import WhatsAppGreen from 'images/icons/socialMedias/whatsAppGreen.svg';
import Environment from 'utils/environments';
import { providerNameValidation } from 'utils/providerNameValidation';
import ValidationLength from 'utils/utils';

function ApproveOrdersList({ filtersChanged, profileType, currentTab }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { id: paramsID } = useParams();
  const [anchorEl, setAnchorEl] = useState();
  const [secondCallSettings, setSecondCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 10,
    loading: false,
    numberOfElements: 0,
    totalElements: 0
  });
  const { getId, page, size, totalPages, numberOfElements, totalElements } =
    secondCallSettings;
  const [confirmationMessage, setConfirmationMessage] = useState(false);
  const [approveOrders, setApproveOrders] = useState([]);
  const [approveListCollapseSettings, setApproveListCollapseSettings] =
    useState([]);
  const [selectedProviderContact, setSelectedProviderContact] = useState();
  const [selectedProvider, setSelectedProvider] = useState();
  const [restaurantName, setRestaurantName] = useState();
  const [restaurantOrders, setRestaurantOrders] = useState([]);
  const [modalStates, setModalStates] = useState({
    restaurantOrder: false,
    modalProviderContacts: false
  });
  const RestaurantOrdersDialog = React.lazy(() =>
    import('pages/ordered/platform/dialogs/RestaurantOrdersDialog')
  );
  const ProviderContactsDialog = React.lazy(() =>
    import('pages/ordered/platform/dialogs/ProviderContactsDialog')
  );

  useEffect(() => {
    if (profileType === 'admin' && currentTab === 'approveList') {
      (async () => {
        setSecondCallSettings({ ...secondCallSettings, loading: true });
        const params = filtersChanged
          .map((filter) => filter.urlParam)
          .join('&');
        const response = await fetch(
          `${environments.orders}/orders/providers?page=${page}&size=${size}&${params}`
        );
        const items = await response.json();
        setApproveOrders(
          items.map((order, index) => ({
            ...order,
            index
          }))
        );
        setApproveListCollapseSettings(
          items.map((order, index) => ({
            id: order.providerId,
            open: false,
            index
          }))
        );
        setSecondCallSettings({
          ...secondCallSettings,
          loading: false,
          numberOfElements,
          totalElements
        });
      })();
    }
  }, [getId, page, size, filtersChanged, currentTab]);

  const handleClose = () => {
    setModalStates({
      restaurantOrder: false,
      modalProviderContacts: false
    });

    setConfirmationMessage(false);
  };

  const approveOrder = (order, registered, providerContact) => {
    setSecondCallSettings({ ...secondCallSettings, loading: true });
    const ordersIds = order.restaurants
      .map((restaurant) =>
        restaurant.orders.map((order) => order.orderId).flat()
      )
      .flat();
    const contact = providerContact
      ? providerContact.telephoneNumber
      : order.providerContact;

    axios
      .get(
        `${environments.orders}/orders/message?contact=${contact}&sended=${
          order.messageSent
        }&ordersId=${ordersIds}${registered ? `&registered=true` : ''}`
      )
      .then((response) => {
        if (!registered) {
          window.open(response.data.message, '_blank');
        }

        setApproveOrders(
          approveOrders.map((orderItem, index) => {
            if (order.index === index) {
              return {
                ...orderItem,
                messageSent: true,
                confirmedMessageSent: registered,
                registeredDate: registered ? new Date() : '',
                providerContact: providerContact
                  ? providerContact.telephoneNumber
                  : orderItem.providerContact
              };
            }
            return { ...orderItem };
          })
        );
        handleClose();
        setSecondCallSettings({ ...secondCallSettings, loading: false });
      })
      .catch((error) => {
        console.log(error.response.data);
        setSecondCallSettings({ ...secondCallSettings, loading: false });
      });
  };

  return (
    <table
      className="desktop approveOrdersListTable"
      width="100%"
      cellPadding="0"
      cellSpacing="0"
      border="0"
    >
      <thead>
        <tr className="headerTitle">
          <th className="subtitle" width="34%">
            FORNECEDOR
          </th>
          <th className="subtitle" width="28.9%">
            RESTAURANTES
          </th>
          <th className="subtitle">PEDIDOS</th>
          <th className="subtitle" width="10%">
            R$ TOTAL
          </th>
          <th className="subtitle" width="2%" />
        </tr>

        <tr className="headerBorder">
          <th colSpan="6" className="titleLineBorder" />
        </tr>
      </thead>

      <tbody>
        {approveOrders && approveOrders.length ? (
          <>
            {approveOrders.map((order, index) => {
              const countRestaurants =
                order.restaurantsIds && order.restaurantsIds.length
                  ? [...new Set(order.restaurantsIds)].length
                  : 0;

              return (
                <>
                  <tr>
                    <td className="description" colSpan="4" width="90%">
                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        style={{ display: 'inline' }}
                      >
                        <ListItem
                          button
                          onClick={(e) =>
                            setApproveListCollapseSettings(
                              approveListCollapseSettings.map((item) =>
                                item?.index === order.index
                                  ? { ...item, open: !item.open }
                                  : item
                              )
                            )
                          }
                          style={{ padding: 0 }}
                        >
                          <td
                            id={`provider${index}`}
                            width="37.7%"
                            className="description dFlex alignCenter justifyBetween pl12"
                          >
                            {ValidationLength(
                              providerNameValidation(order),
                              40
                            )}

                            {approveListCollapseSettings.find(
                              (item) => item.index === order.index
                            ) ? (
                              approveListCollapseSettings.find(
                                (item) => item.index === order.index
                              ).open ? (
                                <ExpandLess className="ml10 mr10" />
                              ) : (
                                <ExpandMore className="ml10 mr10" />
                              )
                            ) : (
                              false
                            )}
                          </td>

                          <table
                            width="62.3%"
                            cellPadding="0"
                            cellSpacing="0"
                            border="0"
                          >
                            <tr>
                              <td
                                id={`restaurantName${index}`}
                                width="51.7%"
                                style={{ display: 'inline-block' }}
                              >
                                <b>
                                  {countRestaurants}{' '}
                                  {countRestaurants === 1
                                    ? 'restaurante'
                                    : 'restaurantes'}
                                </b>
                              </td>

                              <td
                                id={`totalOrders${index}`}
                                className="description"
                                width="30.3%"
                                style={{ display: 'inline-block' }}
                              >
                                <b>
                                  {order.countOrders}/{order.countTotalOrders}{' '}
                                  {order.countTotalOrders === 1
                                    ? 'enviado'
                                    : 'enviados'}
                                </b>
                              </td>

                              <td
                                id={`allRestaurantsTotal${index}`}
                                className="description right pr12"
                                width="18%"
                                style={{ display: 'inline-block' }}
                              >
                                <b>
                                  {order.totalOrders
                                    ? order.totalOrders.toLocaleString(
                                        'pt-br',
                                        { minimumFractionDigits: 3 }
                                      )
                                    : '0,000'}
                                </b>
                              </td>
                            </tr>
                          </table>
                        </ListItem>

                        <Collapse
                          className={
                            approveListCollapseSettings.find(
                              (item) => item.index === order.index
                            ) &&
                            approveListCollapseSettings.find(
                              (item) => item.index === order.index
                            ).open
                              ? 'opened'
                              : ''
                          }
                          style={{ margin: 0 }}
                          in={
                            approveListCollapseSettings.find(
                              (item) => item.index === order.index
                            )
                              ? approveListCollapseSettings.find(
                                  (item) => item.index === order.index
                                ).open
                              : false
                          }
                          timeout="0"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            <ListItem
                              style={{
                                padding: '0 0 0 0px',
                                flexDirection: 'column'
                              }}
                            >
                              <table
                                width="100%"
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                                style={{ background: '#FFF' }}
                              >
                                {order.restaurants.map((item, itemIndex) => (
                                  <>
                                    {itemIndex === 0 && (
                                      <tr>
                                        <td colSpan="4">
                                          <hr
                                            style={{
                                              width: '65.2%',
                                              marginLeft: '37%'
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    )}

                                    <tr style={{ backgroundColor: '#FFF' }}>
                                      <td
                                        id={`restaurantName${itemIndex}`}
                                        width="70%"
                                        style={{
                                          display: 'inline-block',
                                          paddingLeft: '37.8%'
                                        }}
                                      >
                                        {item.name}
                                      </td>

                                      <td
                                        id={`restaurantOrders${itemIndex}`}
                                        width="20%"
                                        className="description orderModalItem"
                                        style={{ display: 'inline-block' }}
                                      >
                                        <div
                                          className="dFlex alignCenter"
                                          onClick={() => {
                                            setRestaurantName(item.name);
                                            setRestaurantOrders(item.orders);
                                            setModalStates({
                                              ...modalStates,
                                              restaurantOrder: true
                                            });
                                          }}
                                        >
                                          <img
                                            style={{
                                              width: 13,
                                              marginRight: 8
                                            }}
                                            alt={OrderIcon}
                                            src={OrderIcon}
                                          />

                                          {item.orders.length === 1 ? (
                                            <p className="mb0">
                                              <b>{item.orders.length} pedido</b>
                                            </p>
                                          ) : (
                                            <p className="mb0">
                                              <b>
                                                {item.orders.length} pedidos
                                              </b>
                                            </p>
                                          )}

                                          {item.countAcceptedSkus <
                                            item.countTotalSkus && (
                                            <div className="refusedSkuAlert">
                                              <p className="mb0">
                                                {item.countAcceptedSkus}/
                                                {item.countTotalSkus}{' '}
                                                {item.countAcceptedSkus === 1
                                                  ? 'SKU aceito'
                                                  : 'SKUS aceitos'}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </td>

                                      <td
                                        id={`restaurantTotal${itemIndex}`}
                                        width="10%"
                                        className="description right pr12"
                                        style={{ display: 'inline-block' }}
                                      >
                                        {item.totalByRestaurant
                                          ? Number(
                                              item.totalByRestaurant
                                            ).toLocaleString('pt-br', {
                                              minimumFractionDigits: 3
                                            })
                                          : '0,000'}
                                      </td>
                                    </tr>

                                    {order.restaurants[itemIndex + 1] && (
                                      <tr>
                                        <td>
                                          <hr
                                            style={{
                                              width: '65.2%',
                                              marginLeft: '37%'
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                ))}
                              </table>

                              {order.notReceived.length ? (
                                <table
                                  width="100%"
                                  cellPadding="0"
                                  cellSpacing="0"
                                  border="0"
                                  style={{ background: '#FFF' }}
                                >
                                  <thead style={{ background: '#FFF' }}>
                                    <tr className="headerTitle">
                                      <th
                                        width="65.4%"
                                        style={{
                                          display: 'inline-block',
                                          marginLeft: '36.85%',
                                          color: '#666770',
                                          background: '#F2F2F4'
                                        }}
                                        className="subtitle"
                                      >
                                        PEDIDOS NÃO ENVIADOS
                                      </th>
                                    </tr>

                                    <tr>
                                      <th>
                                        <hr
                                          style={{
                                            width: '65.2%',
                                            marginLeft: '37%'
                                          }}
                                        />
                                      </th>
                                    </tr>
                                  </thead>

                                  {order.notReceived.map((item, itemIndex) => (
                                    <>
                                      {itemIndex === 0 && (
                                        <tr>
                                          <td>
                                            <hr
                                              style={{
                                                width: '65.2%',
                                                marginLeft: '37%'
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      )}

                                      <tr style={{ backgroundColor: '#FFF' }}>
                                        <td
                                          id={`restaurantName${itemIndex}`}
                                          width="70%"
                                          style={{
                                            display: 'inline-block',
                                            paddingLeft: '37.8%'
                                          }}
                                        >
                                          {item.name}
                                        </td>

                                        <td
                                          id={`restaurantOrders${itemIndex}`}
                                          width="20%"
                                          className="description orderModalItem"
                                          style={{ display: 'inline-block' }}
                                        >
                                          <div
                                            className="dFlex alignCenter"
                                            onClick={() => {
                                              setRestaurantName(item.name);
                                              setRestaurantOrders(item.orders);
                                              setModalStates({
                                                ...modalStates,
                                                restaurantOrder: true
                                              });
                                            }}
                                          >
                                            <img
                                              style={{
                                                width: 13,
                                                marginRight: 8
                                              }}
                                              src={OrderIcon}
                                              alt={OrderIcon}
                                            />

                                            <p className="mb0">
                                              <b>1 pedido</b>
                                            </p>

                                            {/* {item.orders.length === 1 ? (
                                                <>
                                                  <p className="mb0">
                                                    <b>{item.orders.length} pedido</b>
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <p className="mb0">
                                                    <b>{item.orders.length} pedidos</b>
                                                  </p>
                                                </>
                                              )} */}
                                          </div>
                                        </td>

                                        <td
                                          id={`restaurantTotal${itemIndex}`}
                                          width="10%"
                                          className="description right pr12"
                                          style={{ display: 'inline-block' }}
                                        >
                                          {item.totalByRestaurant
                                            ? Number(
                                                item.totalByRestaurant
                                              ).toLocaleString('pt-br', {
                                                minimumFractionDigits: 3
                                              })
                                            : '0,000'}
                                        </td>
                                      </tr>

                                      {order.restaurants[itemIndex + 1] && (
                                        <tr>
                                          <td>
                                            <hr
                                              style={{
                                                width: '65.2%',
                                                marginLeft: '37%'
                                              }}
                                            />
                                          </td>
                                        </tr>
                                      )}
                                    </>
                                  ))}
                                </table>
                              ) : null}
                            </ListItem>
                          </List>
                        </Collapse>
                      </List>
                    </td>

                    <td
                      id={`status${index}`}
                      className="description right statusColumn"
                      width="100%"
                      height="31px"
                      style={{
                        display: 'inline-block',
                        cursor: order.countOrders > 0 ? 'pointer' : 'default'
                      }}
                    >
                      {!order.messageSent ? (
                        <Tooltip
                          title={
                            order.countOrders > 0
                              ? `Enviar pedido de ${
                                  order.restaurants
                                    .map((restaurant) =>
                                      restaurant.orders
                                        .map((order) => order.orderItems)
                                        .flat()
                                    )
                                    .flat().length
                                } SKUs para Fornecedor`
                              : `Não é possível enviar`
                          }
                        >
                          <img
                            onClick={() => {
                              if (order.countOrders > 0) {
                                setModalStates({
                                  ...modalStates,
                                  modalProviderContacts: true
                                });
                                setSelectedProvider(order);
                              }
                            }}
                            style={{
                              width: 13,
                              marginRight: 8,
                              cursor:
                                order.countOrders > 0 ? 'pointer' : 'default'
                            }}
                            alt={order.countOrders}
                            src={
                              order.countOrders > 0
                                ? SendIcon
                                : SendIconDisabled
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            order.confirmedMessageSent ? (
                              <>
                                Envio de Whatsapp registrado em{' '}
                                {format(
                                  new Date(order.registeredDate),
                                  'dd/MM/yyyy - HH:mm'
                                )}
                              </>
                            ) : (
                              <>
                                Whatsapp não confirmado,
                                <br />
                                clique para registrar ou reenviar
                              </>
                            )
                          }
                        >
                          <img
                            onClick={(event) => {
                              setSelectedProvider(order);

                              if (
                                order.messageSent &&
                                !order.confirmedMessageSent
                              ) {
                                setAnchorEl(event.currentTarget);
                                setConfirmationMessage(true);
                              } else {
                                approveOrder(order);
                              }
                            }}
                            style={{ width: 13, marginRight: 8 }}
                            alt={order.confirmedMessageSent}
                            src={
                              order.confirmedMessageSent
                                ? WhatsAppGreen
                                : WhatsApp
                            }
                          />
                        </Tooltip>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="5" className="p0">
                      <hr className="m0" />
                    </td>
                  </tr>
                </>
              );
            })}
          </>
        ) : secondCallSettings.loading ? (
          <tr>
            <td colSpan="5" className="m10">
              <LinearProgress variant="query" />
            </td>
          </tr>
        ) : (
          <tr>
            <td colSpan="5" className="pt5 textAlignCenter">
              Nenhum dado disponível
            </td>
          </tr>
        )}
      </tbody>

      <Popover
        open={confirmationMessage}
        anchorEl={anchorEl}
        onClose={() => setConfirmationMessage(false)}
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', maxWidth: '400px' }
        }}
        className="approveOrderPopover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Typography className="mb20">
          <b>Você deseja reenviar, registrar ou trocar contato?</b>
        </Typography>

        <div className="dFlex alignCenter justifyBetween">
          <Button
            className="defaultButton submitButton"
            design="contained"
            label="Reenviar"
            onClick={() => approveOrder(selectedProvider)}
            color="primary"
          />
          <Button
            className="defaultButton submitButton"
            design="contained"
            label="Registrar"
            onClick={() => approveOrder(selectedProvider, true)}
            color="primary"
          />
          <Button
            className="defaultButton submitButton"
            design="contained"
            label="Trocar Contato"
            onClick={() =>
              setModalStates({ ...modalStates, modalProviderContacts: true })
            }
            color="primary"
          />
        </div>
      </Popover>

      <Suspense fallback={<span />}>
        <RestaurantOrdersDialog
          openModal={modalStates}
          handleClose={handleClose}
          restaurantName={restaurantName}
          restaurantOrders={restaurantOrders}
        />
      </Suspense>

      {selectedProvider && (
        <Suspense fallback={<span />}>
          <ProviderContactsDialog
            openModal={modalStates}
            handleClose={handleClose}
            provider={selectedProvider}
            selectedProviderContact={selectedProviderContact}
            setSelectedProviderContact={setSelectedProviderContact}
            approveOrder={approveOrder}
          />
        </Suspense>
      )}
    </table>
  );
}

export default ApproveOrdersList;
