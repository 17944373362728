/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';

import notCheckedIcon from 'images/icons/empties/notChecked.svg';
import checkedIcon from 'images/icons/signals/radioChecks/checked.svg';

import {
  LabelTitle,
  FormControlStyles,
  FormControlLabelStyled,
  MultipleCheckboxContainer
} from './index.style';

const useStyles = makeStyles(FormControlStyles);

function MultipleRadiobox({
  title,
  checkBoxInputs,
  defaultValue,
  handleFilter,
  topAdjustment,
  color
}) {
  const classes = useStyles(color);
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (name, referenceName, filterValue) => {
    setSelectedValue(name);
    handleFilter(referenceName, filterValue);
  };

  useEffect(() => {
    checkBoxInputs.forEach((item) => {
      if (item.checked) {
        setSelectedValue(item.name);
      }
    });
  }, [checkBoxInputs]);

  return (
    <MultipleCheckboxContainer topAdjustment={topAdjustment}>
      <LabelTitle>{title}</LabelTitle>

      <FormControl classes={{ root: classes.root }}>
        {checkBoxInputs.map(({ name, referenceName, value }) => (
          <FormControlLabelStyled
            marginTop={title}
            color={color}
            control={
              <Radio
                checked={selectedValue === name}
                onChange={() => handleChange(name, referenceName, value)}
                value={name}
                name="radio-button-demo"
                fontSize="small"
                icon={<img src={notCheckedIcon} />}
                checkedIcon={<img src={checkedIcon} />}
              />
            }
            label={name}
          />
        ))}
      </FormControl>
    </MultipleCheckboxContainer>
  );
}
export default MultipleRadiobox;
