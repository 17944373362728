import makePost from 'services/useFetch/makePost';
import makePut from 'services/useFetch/makePut';

import { calculateDifference } from '../../calcDivergentParams';

const getFormatedMonth = (date) => {
  const monthDate = date.getMonth() + 1;
  return monthDate > 9 ? monthDate : `0${monthDate}`;
};

const handleCountData = (data) => {
  if (!data.length) return [];

  return [].concat.apply(
    [],
    data.map((content) => {
      const eventDate = new Date(content.date);
      const date = `${eventDate.getDate()}/${getFormatedMonth(eventDate)}`;
      const {
        products,
        id: countId,
        setup: {
          id: setupId,
          storageLocation: { description: storageDescription }
        }
      } = content;

      return products.map((product) => {
        const { skus, createAdditional } = setAdditionalOfSkuByProduct(
          product.skus
        );

        return {
          countId,
          date,
          setupId,
          storageDescription,
          createAdditional,
          ...product,
          skus
        };
      });
    })
  );
};

const setAdditionalOfSkuByProduct = (skus) => {
  const newSkus = [];
  let createAdditional = false;

  for (const sku of skus) {
    const {
      providers,
      quantity: skuQuantity,
      unitsMeasurements: { abbreviation: unitMeasurement }
    } = sku;

    newSkus.push({
      ...sku,
      isAdditional: false
    });

    for (const provider of providers) {
      const { quantity: providerQuantity } = provider;

      if (
        skuQuantity !== 1 &&
        providerQuantity !== 1 &&
        unitMeasurement !== 'g' &&
        unitMeasurement !== 'ml'
      ) {
        newSkus.push({
          ...sku,
          description: `${skuQuantity}${unitMeasurement}`,
          isAdditional: true
        });
      }

      if (skuQuantity !== 1 || providerQuantity !== 1) {
        createAdditional = true;
      }
    }
  }

  return {
    skus: newSkus,
    createAdditional
  };
};

const setDataToSend = ({ contentModal, restaurantId, user }) => {
  const dataToSend = contentModal.map((item) => {
    const {
      skus,
      id: productId,
      setupId,
      countId,
      additional,
      labelAdditional,
      realStock,
      theoricalStock
    } = item;

    return skus
      .filter((sku) => !sku.isAdditional)
      .map((sku) => {
        const {
          id: skuId,
          unityAdditional,
          labelUnityAdditional,
          providers: [{ stock: quantityCounted, labelQuantity, id: providerId }]
        } = sku;

        return {
          productId,
          skuId,
          providerId,
          setupId,
          restaurantId,
          realStock,
          quantity: quantityCounted,
          labelQuantity,
          additional,
          labelAdditional,
          unityAdditional,
          labelUnityAdditional,
          user,
          countId,
          difference: calculateDifference(realStock, theoricalStock)
        };
      });
  });

  return [].concat.apply([], dataToSend);
};

export const updateCountRecursively = async (
  content,
  handleCallback,
  getId,
  user
) => {
  const callBackResponse = [];
  let recursivelyIndex = 0;

  const dataToSend = setDataToSend({
    contentModal: content,
    restaurantId: getId,
    user
  });

  const BASE_ENDPOINT_COUNT = `{countId}/products/{productId}?originId=${getId}`;
  const BASE_ENDPOINT_DIFFERENCE = `{productId}/extracts/difference/{countId}`;

  const differenceData = {
    restaurantId: getId,
    difference: dataToSend[recursivelyIndex]?.difference.difference
  };

  if (!dataToSend.length) {
    handleCallback([false]);
    return;
  }

  const handleInventoriesCallback = (status, response) => {
    callBackResponse.push({ status, response });
    recursivelyIndex += 1;

    if (dataToSend.length === recursivelyIndex) {
      handleCallback(callBackResponse);
      return;
    }

    makePost(
      'saveInventoriesProducts',
      dataToSend[recursivelyIndex],
      handleInventoriesCallback,
      replaceInfosEndpoint({
        countId: dataToSend[recursivelyIndex].countId,
        productId: dataToSend[recursivelyIndex].productId,
        endpoint: BASE_ENDPOINT_COUNT
      })
    );

    makePut(
      'productsCatalog',
      differenceData,
      () => {},
      replaceInfosEndpoint({
        countId: dataToSend[recursivelyIndex].countId,
        productId: dataToSend[recursivelyIndex].productId,
        endpoint: BASE_ENDPOINT_DIFFERENCE
      })
    );
  };

  makePost(
    'saveInventoriesProducts',
    dataToSend[recursivelyIndex],
    handleInventoriesCallback,
    replaceInfosEndpoint({
      countId: dataToSend[recursivelyIndex].countId,
      productId: dataToSend[recursivelyIndex].productId,
      endpoint: BASE_ENDPOINT_COUNT
    })
  );

  makePut(
    'productsCatalog',
    differenceData,
    () => {},
    replaceInfosEndpoint({
      countId: dataToSend[recursivelyIndex].countId,
      productId: dataToSend[recursivelyIndex].productId,
      endpoint: BASE_ENDPOINT_DIFFERENCE
    })
  );
};

const replaceInfosEndpoint = ({ countId, productId, endpoint }) =>
  endpoint.replace('{countId}', countId).replace('{productId}', productId);

export const setCountedStocks = (product) => {
  const {
    realStock,
    stocks: { countedStocks, totalStocks }
  } = product;
  const oldCountedStocks = countedStocks;
  const hasValues = setHasCounted(product);
  let newCountedStocks = oldCountedStocks;

  if (hasValues && realStock == null) {
    newCountedStocks += 1;
  } else if (hasValues && realStock == 0 && totalStocks !== countedStocks) {
    newCountedStocks = oldCountedStocks;
  } else if (hasValues && realStock != null && totalStocks !== countedStocks) {
    newCountedStocks += 1;
  } else if (
    !hasValues &&
    realStock !== null &&
    countedStocks > oldCountedStocks
  ) {
    newCountedStocks = oldCountedStocks;
  } else if (
    !hasValues &&
    realStock !== null &&
    countedStocks == oldCountedStocks
  ) {
    newCountedStocks -= 1;
  } else if (!hasValues && countedStocks < oldCountedStocks) {
    newCountedStocks = countedStocks;
  }

  return newCountedStocks;
};

const setHasCounted = (product) => {
  const { additional, skus } = product;
  const skusHasCounted = skus
    .map((sku) => {
      const {
        unityAdditional,
        providers: [{ stock: quantity }]
      } = sku;
      return (
        (unityAdditional != null && !isNaN(unityAdditional)) ||
        (quantity != null && !isNaN(quantity))
      );
    })
    .some((hasCounted) => hasCounted);

  return skusHasCounted || (additional != null && !isNaN(additional));
};

export default handleCountData;
