import React, { useState, useRef, useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import useOnClickOutside from 'hooks/useOnClickOutside';
import selectorInputArrow from 'images/icons/arrows/selectorInputArrow.svg';
import CloseBlackIcon from 'images/icons/signals/closesAndCancels/closeBlack.svg';
import makeGet from 'services/useFetch/makeGet';

import filterElementInCategory from './filterElementInCategory';
import {
  InputSelector,
  LabelSelector,
  SelectIcon,
  TreeListWrapper,
  NewTreeSelector,
  CloseIconBlack,
  IconWrapper
} from './style';
import TreeList from './TreeList';

function TreeSelector({
  label,
  inputOptions,
  countCategoryDescription,
  setCountCategoryDescription = () => {},
  StylePageTitleAnFilter = false,
  styleAutocompleteForm = false,
  styleAutocompleteFromSkuModal = false,
  handleFilter,
  filterName,
  placeHolder,
  handleSelectedCategory = null,
  size,
  boxSize,
  justifyBoxContent,
  customInputSize,
  inputSizes = {
    small: 110,
    medium: 155,
    medium_to_plus: 165,
    medium_plus: 210,
    large: 250
  }
}) {
  const [inputDescription, setInputDescription] = useState(
    countCategoryDescription
  );
  const [contentList, setContentList] = useState([]);
  const [initialContentList, setInitialContentList] = useState([]);
  const [openTreeList, setOpenTreeList] = useState(true);
  const [loadingTreeList, setLoadingTreeList] = useState(false);

  const [debounceTimers, setDebounceTimers] = useState([]);

  const { id: paramsID } = useParams();

  const {
    originId = '',
    type = '',
    endpoint = '',
    getIdFromParams = false
  } = inputOptions;
  const filters = [
    {
      param: 'originId',
      value: getIdFromParams ? paramsID : originId,
      urlParam: `originId=${getIdFromParams ? paramsID : originId}`
    },
    { param: 'type', value: type, urlParam: `type=${type}` },
    { param: 'size', value: '100', urlParam: `size=100` },
    { param: 'page', value: '1', urlParam: `page=1` }
  ];

  const ref = useRef();
  useOnClickOutside(ref, () => setOpenTreeList(true));

  useEffect(() => {
    if (!countCategoryDescription) return;
    setInputDescription(countCategoryDescription);
  }, [countCategoryDescription]);

  const handleInputValue = (value) => {
    setInputDescription(value);

    if (debounceTimers.length) {
      debounceTimers.forEach((element, index) => {
        if (index + 1 !== debounceTimers.length) {
          clearTimeout(element);
        } else {
          setContentList(initialContentList);
        }
      });
    }

    const newTimer = setTimeout(() => {
      if (value.length > 1) {
        filterElementInCategory(contentList, value, setContentList);
      } else {
        setContentList(initialContentList);
      }
    }, 1000);
    setDebounceTimers([...debounceTimers, ...[newTimer]]);
  };

  const handleCategory = (value, selectElement) => {
    setCountCategoryDescription(value);
    setInputDescription(value);
    setOpenTreeList(!openTreeList);
    if (handleFilter) {
      const { description } = selectElement;
      handleFilter(filterName, description);
    }
    if (handleSelectedCategory) {
      handleSelectedCategory(selectElement);
    }
  };

  const handleGetResponse = (response) => {
    const { content = [] } = response;
    if (!content) return;
    setContentList(content);
    setInitialContentList(content);
    setOpenTreeList(!openTreeList);
    setLoadingTreeList(false);
  };

  const handleOpenTreeList = () => {
    if (initialContentList.length) {
      setOpenTreeList(!openTreeList);
      return;
    }
    setLoadingTreeList(true);
    makeGet(endpoint, filters, handleGetResponse, null);
  };

  const clearInputDesciptionValue = () => {
    setInputDescription('');
    setCountCategoryDescription('');
    setContentList(initialContentList);
    if (handleFilter) {
      handleFilter(filterName, '');
    }
  };

  const handleBlurInputValue = () => {
    if (!inputDescription) {
      clearInputDesciptionValue();
    }
  };

  useEffect(() => {
    setContentList([]);
    setInitialContentList([]);
    if (!inputDescription) {
      clearInputDesciptionValue();
    }
  }, [paramsID]);

  return (
    <NewTreeSelector
      ref={ref}
      styleAutocompleteFromSkuModal={styleAutocompleteFromSkuModal}
      id={`${filterName}Box`}
      style={{ width: boxSize || 'auto' }}
      justifyContent={justifyBoxContent}
    >
      <LabelSelector
        styleAutocompleteFromSkuModal={styleAutocompleteFromSkuModal}
      >
        {label}
        <InputSelector
          tabIndex="-1"
          value={inputDescription}
          onChange={(e) => {
            handleInputValue(e.target.value);
          }}
          onFocus={handleOpenTreeList}
          onBlur={handleBlurInputValue}
          StylePageTitleAnFilter={StylePageTitleAnFilter}
          placeholder={placeHolder}
          styleAutocompleteForm={styleAutocompleteForm}
          styleAutocompleteFromSkuModal={styleAutocompleteFromSkuModal}
          style={{ width: customInputSize || (size && inputSizes[size]) }}
        />
        <IconWrapper
          styleAutocompleteForm={styleAutocompleteForm}
          styleAutocompleteFromSkuModal={styleAutocompleteFromSkuModal}
        >
          {!loadingTreeList && !inputDescription && (
            <SelectIcon
              src={selectorInputArrow}
              alt="selectorInputArrow"
              upArrow={openTreeList}
              onClick={handleOpenTreeList}
            />
          )}
          {loadingTreeList && <CircularProgress color="inherit" size={8} />}
          {!loadingTreeList && inputDescription && (
            <CloseIconBlack
              src={CloseBlackIcon}
              alt="input description"
              id="treeSelector-closeIcon"
              onClick={() => {
                clearInputDesciptionValue();
                setOpenTreeList(true);
              }}
            />
          )}
        </IconWrapper>
      </LabelSelector>

      <TreeListWrapper
        openList={openTreeList}
        StylePageTitleAnFilter={StylePageTitleAnFilter}
        styleAutocompleteForm={styleAutocompleteForm}
        styleAutocompleteFromSkuModal={styleAutocompleteFromSkuModal}
        customInputSize={customInputSize}
      >
        {contentList.map((element) => (
          <TreeList itemList={element} handleCategory={handleCategory} />
        ))}
      </TreeListWrapper>
    </NewTreeSelector>
  );
}

export default TreeSelector;
