import { createSlice } from '@reduxjs/toolkit';

const initialState = { status: false, title: null, content: null, closable: true };

export const simpleModalSlice = createSlice({
  name: 'simpleModal',
  initialState,
  reducers: {
    open(state) {
      state.status = true;
    },
    close(state) {
      state.status = false;
    },
    canClose(state, action) {
      state.closable = action.payload;
    },
    define(state, action) {
      state.content = action.payload;
    },
    defineTitle(state, action) {
      state.title = action.payload;
    },
  },
});

export const { open, close, define, defineTitle, canClose } = simpleModalSlice.actions;

export default simpleModalSlice.reducer;
