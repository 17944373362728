/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, Suspense, useMemo } from 'react';

import {
  Checkbox,
  Fab,
  IconButton,
  LinearProgress,
  Tooltip
} from '@material-ui/core';
import { Add, Pause } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import ItemsPerPage from 'components/ItemsPerPage';
import { LocalizationContext } from 'components/Localization';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import ArrowBackWhite from 'images/icons/arrows/arrowBackWhiteOutline.svg';
import IcmsstIcon from 'images/icons/objects/currencies/dolar.svg';
import ProviderButtonIcon from 'images/icons/objects/trucks/providerButton.svg';
import { handleAprovedSku } from 'pages/admin/cataloguing/dialogs/comparationSkuDialog/services';
import getOptions from 'services/getOptions';
import * as SkusActions from 'store/actions/skus';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import { headerStyles } from './styles';

function Skus({ userInfo }) {
  const { id: paramsId } = useParams();
  const queryParams = useLocation()?.search;
  const isAdminMaster = userInfo?.profile?.[0]?.[0]?.name === 'admin_master';
  const isDraftParam = isAdminMaster ? '' : '?draft=true'
  const [callSettings, setCallSettings] = useState({
    getId: paramsId,
    page: 1,
    totalPages: 0,
    size: 100,
    totalElements: 0,
    numberOfElements: 0,
    loading: false,
    mainContent: [],
    filters: [{ param: 'status', value: 'catalog', urlParam: `status=catalog` }]
  });
  const {
    getId,
    page,
    size,
    totalPages,
    mainContent,
    loading,
    numberOfElements,
    totalElements
  } = callSettings;
  const [wasReceivedQueryParam, setWasReceivedQueryParam] = useState(
    queryParams?.includes('wasReceived')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('wasReceived'))
          ?.split('=')[1]
      : ''
  );
  const DEFAULT_FILTERS = useMemo(
    () => [
      wasReceivedQueryParam && {
        param: 'wasReceived',
        value: wasReceivedQueryParam,
        urlParam: `wasReceived=${wasReceivedQueryParam}`
      },
      
    ].filter(value => value),
    [
      wasReceivedQueryParam,
    ]
  );
  const [filtersChanged, setFiltersChanged] = useState(DEFAULT_FILTERS);
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'filters',
      open: true,
      fullScreen: null,
      maxWidth: null,
      fullWidth: null
    },
    {
      id: 2,
      name: 'skuModal',
      open: false,
      fullScreen: true,
      maxWidth: null,
      fullWidth: true
    }
  ]);
  const [selectedSku, setSelectedSku] = useState([]);
  const [sku, setSku] = useState({
    ean: null,
    status: 'ANALYZE',
    description: '',
    specification: null,
    brandsId: null,
    restaurants: null,
    providers: selectedSku,
    product: null,
    categoryId: null,
    quantity: 1,
    unitsMeasurementsId: null,
    user: userInfo.user
  });
  const [open, setOpen] = useState(true);
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusCode, setStatusCode] = useState();
  const environments = Environment(process.env.REACT_APP_ENV);
  const classes = headerStyles();
  const [providers, setProviders] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [unifiedSkus, setUnifiedSkus] = useState(false);
  const [noteMeasurement, setNoteMeasurement] = useState('')
  const [toastProps, setToastProps] = useState();
  const [updateMainList, setUpdateMainList] = useState(false);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'SKU A Catalogar',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'skus',
        urlPath: `${environments.catalog}/skus/providers`,
        optionReference: 'descriptionSku',
        options: [],
        size: 'large',
        searchByTermMode: true
      },

      {
        label: 'EAN',
        filterName: 'ean',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'large'
      },

      {
        label: 'Fornecedores',
        filterName: 'providerIds',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/suppliers/groups`,
        key: 'providersIdList',
        optionReference: 'description',
        options: [],
        size: 'large'
      },

      {
        label: 'Opções',
        filterName: 'status',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        optionReference: 'name',
        defaultValue: { title: 'Todos', value: '' },
        options: [
          { title: 'Todos', value: '' },
          { title: 'Catalogar', value: 'catalog' },
          { title: 'Catalogar Depois', value: 'paused' }
        ],
        size: 'large'
      }
    ]
  ]);
  const ProviderGroupAssociation = React.lazy(() =>
    import('./components/ProviderGroupAssociation')
  );

  const [modalStates, setModalStates] = useState({
    modalProviders: false
  });

  function handleModal(title, value) {
    if (value) {
      const key = Object.keys(value)[0];
      const objValue = Object.values(value)[0];

      setModalSettings(
        modalSettings.map((x) =>
          x.name === title ? { ...x, [key]: objValue } : x
        )
      );
    } else {
      const { id } = modalSettings.find((x) => x.name === title);
      const { name } = modalSettings.find((x) => x.name === title);
      const { open } = modalSettings.find((x) => x.name === title);
      const { fullWidth } = modalSettings.find((x) => x.name === title);
      const { maxWidth } = modalSettings.find((x) => x.name === title);
      return { id, name, open, fullWidth, maxWidth };
    }

    if (title === 'skuModal' && value.open === false) {
      setOpen(false);
      setSelectedSku([]);
    }
  }

  const getInfoProvider = (provider, code) => {
    axios.get(`${environments.orders}/orders/skus/provider/${provider}?codeProvider=${code}&restaurantId=${getId}`)
      .then((response) => {
        setNoteMeasurement(response.data[0].noteMeasurementUnit)
      }).catch(() => {
        setNoteMeasurement('')
      })
  }

  function handleSelectedSku(checked, sku) {
    const currentIndex = selectedSku.indexOf(sku);
    const newChecked = [...selectedSku];

    if (checked) {
      if (!selectedSku.length) {
        setSku((item) => ({
          ...item,
          ean: sku.ean ? sku.ean : null,
          status: 'ANALYZE',
          description: sku.descriptionSku ? sku.descriptionSku : '',
          specification: '',
          quantity: sku.quantity ? sku.quantity : null,
          unitsMeasurementsId: sku.unitsMeasurements
            ? sku.unitsMeasurements.id
            : null,
          unitsMeasurements: sku.unitsMeasurements
            ? sku.unitsMeasurements
            : null
        }));
      }
      newChecked.push(sku);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedSku(newChecked);
  }

  function handleFilter(param, value, _, paramSecond) {
    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!value && (hasFilterWithFirstName || hasFilterWithSecondName)) {
      formattedFilters = filtersChanged?.filter(
        (el) => el?.param !== paramSecond && el?.param !== param
      );
    }

    handleFilterOptions(
      param,
      value,
      formattedFilters,
      setFiltersChanged,
      null,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  }

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page,
      true
    );
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClose = () => {
    setModalStates({
      modalProviders: false
    });
  };

  const handleResponseAproved = (status, response) => {
    if (status) {
      setToastProps({
        severity: 'success',
        message: 'Cadastro aprovado com sucesso.'
      });
      handleModal('skuModal', { open: false })

      setTimeout(() => {
        selectedSku.forEach((sku) => {
          sku.isDisableSku = true;
        });

        setUpdateMainList(!updateMainList)

        setSelectedSku([]);
      }, 800);

      return;
    }

    setToastProps({
      severity: 'error',
      message:
        response?.data?.message
    });
  };

  function handleDesktopRows() {
    return (
      <>
        {loading && (
          <tr>
            <th
              colSpan="17"
              className="textAlignCenter"
              style={{ margin: '10px' }}
            >
              <LinearProgress
                style={{ height: '7px' }}
                id="loading"
                variant="query"
              />
            </th>
          </tr>
        )}

        {mainContent?.length ? (
          mainContent.map((item, index) => (
            <>
              <tr>
                <td className="pr12">
                  <div className="dFlex alignCenter">
                    <Checkbox
                      checked={selectedSku.some((el) => el === item)}
                      size="small"
                      onChange={(event, checked) =>
                        handleSelectedSku(checked, item)
                      }
                    />

                    {item?.descriptionSku &&
                    item.descriptionSku.length < 30 &&
                    item.descriptionSku &&
                    item.wasReceived ? (
                      <Tooltip title="Foi recebido" aria-label="info">
                        <p
                          className="mb0"
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '12px',
                            color: `${item.wasReceived ? '#FF0000' : 'none'}`
                          }}
                        >
                          {item.descriptionSku && item.wasReceived
                            ? `${item.descriptionSku}`
                            : item.descriptionSku && !item.wasReceived
                            ? item.descriptionSku
                            : ''}
                        </p>
                      </Tooltip>
                    ) : (
                      ValidationLength(
                        item.descriptionSku && item.wasReceived
                          ? `${item.descriptionSku} Foi recebido`
                          : item.descriptionSku && !item.wasReceived
                          ? item.descriptionSku
                          : '',
                        30
                      )
                    )}
                  </div>
                </td>

                <td className="description">{item.ean ? item.ean : ''}</td>

                <td className="description">
                  {item.codeProvider
                    ? ValidationLength(item.codeProvider, 10)
                    : ''}
                </td>

                <td className="description">
                  {item.name ? ValidationLength(item.name, 25) : ''}
                </td>

                <td className="description center">
                <div
                  style={{gap: '10px'}}
                  className={`${classes.descriptionSku}`}
                  onMouseEnter={() => getInfoProvider(item.providerId, item.codeProvider)}
                  onMouseLeave={() => setNoteMeasurement('')}
                >
                  <Tooltip title={noteMeasurement} aria-label="info">
                    <div>
                      {item.unitsMeasurements
                        ? item.unitsMeasurements.abbreviation
                        : ''}
                    </div>
                  </Tooltip>
                </div>

                </td>

                <td
                  className="description right"
                  style={{ borderLeft: 'solid 1px #ECEDF1' }}
                >
                  {(item.purchaseLastUnitary
                    ? item.purchaseLastUnitary
                    : 0
                  ).toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                  {item.icmsst !== 0 && (
                    <Tooltip
                      title={
                        <div className="dFlex alignCenter flexColumn">
                          <p className="mb5 bold">Substituição Tributária</p>
                          <p className="mb0 bold">
                            R${' '}
                            {parseFloat(item.icmsst).toLocaleString('pt-br', {
                              minimumFractionDigits: 2
                            })}
                          </p>
                        </div>
                      }
                      aria-label="info"
                    >
                      <img
                        style={{
                          width: '15px',
                          marginLeft: '5px',
                          marginBottom: '2px'
                        }}
                        src={IcmsstIcon}
                        alt="IcmsstIcon"
                      />
                    </Tooltip>
                  )}
                </td>

                <td className="description right">
                  {item.purchaseCount ? item.purchaseCount : 0}
                </td>

                <td className="description right">
                  {(item.purchaseAverage
                    ? item.purchaseAverage
                    : 0
                  ).toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                </td>

                <td className="description right">
                  {(item.purchaseLast ? item.purchaseLast : 0).toLocaleString(
                    'pt-br',
                    { minimumFractionDigits: 3 }
                  )}
                </td>

                <td
                  className="description right"
                  style={{ paddingRight: '12px' }}
                >
                  {(item.purchaseTotal ? item.purchaseTotal : 0).toLocaleString(
                    'pt-br',
                    { minimumFractionDigits: 3 }
                  )}
                </td>
              </tr>

              {index !== mainContent.length - 1 && (
                <tr className="headerBorder">
                  <th
                    colSpan="10"
                    className="titleLineBorder"
                    style={{ zIndex: 9 }}
                  >
                    <hr />
                  </th>
                </tr>
              )}
            </>
          ))
        ) : (
          <tr>
            <th
              colSpan="13"
              className="textAlignCenter"
              style={{ margin: '10px' }}
            >
              Nenhum catalogo registrado
            </th>
          </tr>
        )}
      </>
    );
  }

  function handleSubmitPauseItems(obj, type) {
    const body = {
      user: userInfo.user,
      providers: obj
    };

    setTimeout(() => {
      axios
        .put(`${environments.catalog}/skus/providers/toPaused`, body)
        .then(() => {
          setCallSettings({ ...callSettings, loading: false });
          setSelectedSku([]);
          handleFilter('status', type === 'paused' ? 'catalog' : 'paused');
        })
        .catch((err) => {
          setCallSettings({ ...callSettings, loading: false });
          setAlertErr(true);
          setAlertMessage(err.response.data.message);
          setStatusCode(err.response.status);
        });
    }, 1000);
  }

  const providerGroupAssociationButton = () => (
    <Tooltip title="Associação de Fornecedores em Grupo" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          setModalStates({ ...modalStates, modalProviders: true });
        }}
      >
        <img
          style={{ width: '25px' }}
          src={ProviderButtonIcon}
          alt="ProviderButtonIcon"
        />
      </IconButton>
    </Tooltip>
  );

  
  useEffect(() => {
    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        getId
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        getId
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        getId
      );
    !providers.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        getId,
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        getId
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        getId,
        null,
        null,
        10
      );
  }, [getId]);

  useEffect(() => {
    (async () => {
      const hasStatusParams = filtersChanged.length
        ? filtersChanged.find((element) => element.param === 'status')
        : false;

      if (!hasStatusParams) {
        filtersChanged.push({
          param: 'status',
          value: 'catalog',
          urlParam: `status=catalog`
        });
      }

      setCallSettings({ ...callSettings, loading: true });
      const params = filtersChanged.map((filter) => filter.urlParam).join('&');
      const response = await fetch(
        `${environments.catalog}/skus/providers?page=${page}&size=${size}&originId=${getId}&${params}`
      );
      const items = await response.json();

      setOpen(true);

      setCallSettings({
        ...callSettings,
        totalPages: items.totalPages,
        mainContent: items.content ? items.content : [],
        loading: false,
        numberOfElements: items.numberOfElements,
        totalElements: items.totalElements
      });
    })();
  }, [getId, page, filtersChanged, open, updateMainList]);

  // Parâmetros
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${getId}/parameters`
      );
      const parameters = await response.json();
      setUnifiedSkus(parameters.counts ? parameters.counts.unifiedSkus : false);
    })();
  }, [getId]);

  return (
    <>
      <Layout>
        <LocalizationContext.Provider value={{ nameSpace: 'marcas' }}>
          <LocalizationContext.Provider value={{ nameSpace: 'fabricantes' }}>
            <TitleHead title="Catalogação" />

            <GlobalStyle />

            <Container>
              <CommonAlert
                open={alertErr}
                onClose={() => {
                  setAlertErr(false);
                }}
                indexMessage={alertMessage}
                statusCode={statusCode}
              />

              <CommonAlert
                open={toastProps?.message}
                severity={toastProps?.severity}
                indexMessage={toastProps?.message}
                messagePersonal={toastProps?.message}
                onClose={() => {
                  setToastProps({
                    severity: '',
                    message: ''
                  });
                }}
                id="catalogToastMessage"
              />

              <PageTitleAndFilter
                title="Catalogação"
                activeFilterFilds={filterFields}
                handleFilter={handleFilter}
                getFilterhandle={getFilterhandle}
                additionalbutton={providerGroupAssociationButton}
              />

              <table
                className="desktop"
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
              >
                <thead>
                  <tr className="headerTitle">
                    <th width="23%" className="subtitle">
                      SKU{' '}
                      <OrderBy
                        filterName="descriptionSku"
                        handleFilter={handleOrdernationFilter}
                      />
                    </th>

                    <th width="10%" className="subtitle">
                      EAN{' '}
                      <OrderBy
                        filterName="ean"
                        handleFilter={handleOrdernationFilter}
                      />
                    </th>

                    <th width="11%" className="subtitle">
                      CÓD. FORNEC.{' '}
                      <OrderBy
                        filterName="codeProvider"
                        handleFilter={handleOrdernationFilter}
                      />
                    </th>

                    <th width="18%" className="subtitle">
                      FORNECEDOR{' '}
                      <OrderBy
                        filterName="name"
                        handleFilter={handleOrdernationFilter}
                      />
                    </th>

                    <th width="3%" className="subtitle center">
                      UN.
                    </th>

                    <th
                      colSpan="5"
                      className="subtitle left"
                      style={{
                        borderLeft: 'solid 1px #ECEDF1',
                        background: '#73004C'
                      }}
                    >
                      COMPRAS / INTEGRAÇÃO
                    </th>
                  </tr>

                  <tr className="headerSubtitle">
                    <th colSpan="5">&nbsp;</th>

                    <th
                      width="7%"
                      className="subtitle right"
                      style={{ borderLeft: 'solid 1px #ECEDF1' }}
                    >
                      UNIT. R${' '}
                      <OrderBy
                        filterName="purchaseLastUnitary"
                        handleFilter={handleOrdernationFilter}
                        tinyArrow
                      />
                    </th>

                    <th width="5%" className="subtitle right">
                      QTDE.{' '}
                      <OrderBy
                        filterName="purchaseCount"
                        handleFilter={handleOrdernationFilter}
                        tinyArrow
                      />
                    </th>

                    <th width="7%" className="subtitle right">
                      MÉDIO R${' '}
                      <OrderBy
                        filterName="purchaseAverage"
                        handleFilter={handleOrdernationFilter}
                        tinyArrow
                      />
                    </th>

                    <th width="8%" className="subtitle right">
                      ÚLTIMO R${' '}
                      <OrderBy
                        filterName="purchaseLast"
                        handleFilter={handleOrdernationFilter}
                        tinyArrow
                      />
                    </th>

                    <th width="9%" className="subtitle right">
                      TOTAL R${' '}
                      <OrderBy
                        filterName="purchaseTotal"
                        handleFilter={handleOrdernationFilter}
                        tinyArrow
                      />
                    </th>
                  </tr>
                </thead>

                <tbody>{handleDesktopRows()}</tbody>
              </table>

              <div className="pagination">
                <Pagination
                  count={totalPages}
                  onChange={(event, page) =>
                    event ? setCallSettings({ ...callSettings, page }) : null
                  }
                  variant="outlined"
                  shape="rounded"
                  size="small"
                  color="primary"
                />
                
                <ItemsPerPage
                  numberOfElements={numberOfElements}
                  size={totalElements}
                />
              </div>

              {handleModal('skuModal').open && (
                <SkuModal
                  fullScreen={handleModal('skuModal').fullScreen}
                  maxWidth={handleModal('skuModal').maxWidth}
                  fullWidth={handleModal('skuModal').fullWidth}
                  open={handleModal('skuModal').open}
                  data={selectedSku}
                  getId={getId}
                  editMode
                  sku={sku}
                  userInfo={userInfo}
                  providers={providers}
                  packageTypes={packageTypes}
                  categories={categories}
                  brands={brands}
                  storageLocations={storageLocations}
                  periodicities={periodicities}
                  unitsMeasurements={unitsMeasurements}
                  consumptionUnits={consumptionUnits}
                  unifiedSkus={unifiedSkus}
                  onClose={() => handleModal('skuModal', { open: false })}
                  originPage='catalog'
                  handleOnSave={({ body, isEditMode, multipleSkuData, product }) => {
                    if (multipleSkuData) {
                      body.product = product;
                    }

                    const formattedBody = {
                      ...body,
                      id: 1,
                      comparationSkuId: 1,
                      groups: null,
                      product: {
                        ...body?.product,
                        restaurantId: 0,
                        skus: body?.product?.skus?.length 
                          ? body?.product?.skus?.map(el => ({
                            ...el,
                            restaurants: el?.restaurants?.length
                            ? [...el?.restaurants || '', {
                                ...el?.restaurants[0],
                                description: "Plataforma",
                                restaurantId: 0
                              }].filter(item => item)
                            : el?.restaurants || []
                          }))
                          : body?.product?.skus
                      },
                      providers: body?.providers?.map(el => ({
                        ...el,
                        skuId: 1
                      })),
                      restaurants: body?.restaurants?.length
                        ? [...body?.restaurants || '', {
                            ...body?.restaurants[0],
                            description: "Plataforma",
                            restaurantId: 0
                          }].filter(item => item)
                        : body?.restaurants
                    };

                    delete formattedBody?.product?.id
                    delete formattedBody?.product?.id
                    delete formattedBody?.product?.items
                    delete formattedBody?.product?.itemsTransformations
                    delete formattedBody?.product?.lastReceivedProvider
                    delete formattedBody?.product?.setups
                    delete formattedBody?.product?.historical

                    handleAprovedSku({
                      body: formattedBody,
                      isEditMode,
                      totalSelectedSKU: 0,
                      handleResponseAproved,
                      multipleSkuData,
                      isDraft: isDraftParam
                    });
                  }}
                />
              )}

              <Suspense fallback={<span />}>
                <ProviderGroupAssociation
                  openModal={modalStates}
                  handleClose={handleClose}
                  getId={getId}
                />
              </Suspense>
            </Container>
          </LocalizationContext.Provider>
        </LocalizationContext.Provider>
      </Layout>

      <Tooltip className="floatButtonSecond" title="Associar itens">
        <Fab
          color="primary"
          className={classes.fab}
          aria-label="add"
          size="medium"
          style={{ outline: 0 }}
          onClick={() => handleModal('skuModal', { open: true })}
        >
          <Add style={{ color: 'white' }} id="skuCatalogAddItem" />
        </Fab>
      </Tooltip>

      <Tooltip
        className="floatButtonFirst"
        title={
          filtersChanged.find((item) => item.value === 'paused')
            ? 'Enviar para catalogação'
            : 'Catalogar depois'
        }
      >
        <Fab
          color="primary"
          className={classes.fab}
          aria-label="pause"
          size="medium"
          style={{ outline: 0 }}
          onClick={() =>
            handleSubmitPauseItems(
              selectedSku,
              filtersChanged.find((item) => item.value === 'catalog') &&
                'paused'
            )
          }
        >
          {filtersChanged.find((item) => item.value === 'paused') ? (
            <img
              style={{ width: '18px' }}
              src={ArrowBackWhite}
              alt="ArrowBackWhite"
            />
          ) : (
            <Pause style={{ color: 'white' }} />
          )}
        </Fab>
      </Tooltip>
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(SkusActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Skus);
