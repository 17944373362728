/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import {
  IconButton,
  LinearProgress,
  Tooltip,
  Modal,
  Link,
  Grid,
  TextField
} from '@material-ui/core';
import { default as MaterialButton } from '@material-ui/core/Button';
import { DeleteOutline } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import invalidCertificate from 'images/icons/combinations/invalidCertificate.svg';
import offCertificate from 'images/icons/combinations/offCertificate.svg';
import validCertificate from 'images/icons/combinations/validCertificate.svg';
import contact from 'images/icons/contacts/chats/contact.svg';
import infoAdd from 'images/icons/objects/calendars/calendarInfo.svg';
import clockTime from 'images/icons/objects/clocks/clockTime.svg';
import addressHouse from 'images/icons/objects/houses/addressHouse.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import failAlert from 'images/icons/signals/alerts/failAlert.svg';
import { InputFile } from 'pages/quotesUpload/style';
import getOptions from 'services/getOptions';
import axiosInstanceRestaurants from 'services/middlewares/apis/restaurants_validate';
import * as UserActions from 'store/actions/user';
import Environment from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import { handleMultipleFilters } from 'utils/handleFilterActions';

import {
  AddressDialog,
  CertificateModal,
  DeleteDialog
} from './dialogs/dialogs';
import StoreForm from './StoreForm';
import { OffSpan, TableData, Tbody } from './styles';

const environments = Environment(process.env.REACT_APP_ENV);

export function ListagemLojas() {
  const { id: paramsID } = useParams();
  const [dataTable, setDataTable] = useState();
  const [loading, setLoading] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [id, setId] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [date, setDate] = useState(new Date());
  const [password, setPassword] = useState('');
  const [openModalAdress, setOpenModalAdress] = useState(false);
  const [openModalResponsable, setOpenModalResponsable] = useState(false);
  const [openModalInfos, setOpenModalInfos] = useState(false);
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [openModalCertificate, setOpenModalCertificate] = useState(false);
  const [dataCertificate, setDateCertificate] = useState({
    restaurantname: '',
    cnpj: '',
    restaurantId: ''
  });
  const [filterCertificate, setFilterCertificate] = useState();
  const [toastError, setToastError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [severity, setSeverity] = useState('error');
  const [statusCode, setStatusCode] = useState();
  const [providerModal, setProviderModal] = useState(false);
  const [editRestaurant, setEditRestaurant] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [findResponse, setFindResponse] = useState();
  const [filtersChanged, setFiltersChanged] = useState([]);
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 0,
    totalPages: 0,
    size: 100,
    filters: [],
    loading: false,
    numberOfElements: 0,
    totalElements: 0
  });
  const {
    getId,
    page,
    totalPages,
    numberOfElements,
    totalElements
  } = callSettings;

  const getCompanies = async () => {
    setLoading(true);
    setCallSettings({ ...callSettings, loading: true });

    await axiosInstanceRestaurants
      .get(
        `${environments.restaurants}/companies?page=0&size=1000&originId=undefined`
      )
      .then((res) => {
        const data = res?.data;
        setDataTable(data.content);
        setCallSettings({
          ...callSettings,
          totalElements: data.totalElements,
          totalPages: data.totalPages,
          loading: false,
          numberOfElements: data.numberOfElements
        });
        setLoading(false);
      });
  };

  const handleOpenModalDelete = (id) => {
    setOpenModalDelete(true);
    setId(id);
  };

  const handleOpenDelete = async () => {
    await axiosInstanceRestaurants
      .delete(`${environments.restaurants}/companies/${id}`)
      .catch((error) => {
        setToastError(true);
        setMessageError(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
      })
      .finally(() => {
        setOpenModalDelete(false);
      });
  };

  const handleOpenModalAdress = (id) => {
    const findResponse = dataTable.find((items) => items._id === id);
    setFindResponse(findResponse?.address);
    setOpenModalAdress(true);
  };

  const handleOpenResponsable = (id) => {
    const findResponse = dataTable.find((items) => items._id === id);
    setFindResponse(findResponse?.companyResponsible);
    setOpenModalResponsable(true);
  };

  const handleOpenInfos = (id) => {
    const findResponse = dataTable.find((items) => items._id === id);
    setFindResponse(findResponse?.additionalInformation);
    setOpenModalInfos(true);
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const handleModalCreateAndEdit = (id) => {
    const findResponse = dataTable.find((items) => items._id === id);
    setFindResponse(findResponse);
    setProviderModal(!providerModal);
    setEditRestaurant(true);

    if (providerModal === false) {
      setUpdateList(false);
    }
  };

  const toggleProviderModal = () => {
    setProviderModal(!providerModal);

    if (providerModal === false) {
      setUpdateList(false);
    }
  };

  const handleClickFakeImportPdf = () => {
    document.querySelector('#pdfImport').click();
  };

  const toBase64 = async (file) => {
    const reader = new FileReader();
    let base64 = null;
    if (!file) {
      return {
        base64
      };
    }
    const promiseReader = new Promise((resolve) => {
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result);
      };
    });
    base64 = await promiseReader.then((r) => r);
    return {
      base64
    };
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileNameParts = file.name.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1];

      if (fileExtension.toLowerCase() === 'pfx') {
        setSelectedFile(file);
      } else {
        setToastError(true);
        setMessageError('Por favor, selecione um arquivo PFX válido.');
      }
    }
  };

  const handleOnSave = async () => {
    await axios
      .post(`${environments.integrationSefaz}/certificate`, {
        restaurantName: dataCertificate.restaurantname,
        cnpj: dataCertificate.cnpj,
        // validate: format(new Date(date), 'yyyy-MM-dd'),
        password,
        restaurantId: dataCertificate.restaurantId,
        fileBase64: (await toBase64(selectedFile))?.base64?.split(',')?.[1]
      })
      .then(() => {
        setOpenModalCertificate(false);
        setToastError(true);
        setMessageError('Certificado salvo com sucesso!');
        setSeverity('success');

        getCompanies();
      })
      .catch((error) => {
        console.log(error);
        setToastError(true);
        setMessageError(
          error?.response?.data?.message || 'Erro ao salvar, tente novamente!'
        );
        setStatusCode(error?.response?.status);
      });
  };

  const handleCloseCertificate = () => {
    setOpenModalCertificate(false);
    setSelectedFile(null);
    setDateCertificate({});
    setFilterCertificate({})
    setPassword('');
    setDate('');
  };

  const truncateName = (name, maxLength) => {
    if (name.length <= maxLength) {
      return name;
    }

    const truncatedName = `${name.substring(0, maxLength - 3)}...`;
    return truncatedName;
  };

  const handleCertificateModal = (id) => {
    const filterCertificateObj = dataTable?.filter((items) => items?.companyId === id)

    setFilterCertificate(filterCertificateObj)
    setPassword(filterCertificateObj[0]?.validityCertificate?.password)
    setDate(filterCertificateObj[0]?.validityCertificate?.date)
  }

  const handleModalCertficate = (restaurantName, cnpj, restaurantId, certificateValidate) => {
    setOpenModalCertificate(true);

    if(certificateValidate !== null) {
      handleCertificateModal(restaurantId)
    }
    
    setDateCertificate({
      restaurantId,
      restaurantname: restaurantName,
      cnpj
    });
  };


  const certificateIcon = (certficate, id) => {
    if (certficate?.valid === true) {
      return (
        <Tooltip
          title={
            <span>
              Certificado digital válido <br />
              Validade: {format(new Date(certficate?.date), 'dd/MM/yyyy')}
            </span>
          }
        >
          <img src={validCertificate} alt="certificado valido" />
        </Tooltip>
      );
    }
    if (certficate?.valid === false) {
      return (
        <Tooltip
          title={
            <span>
              Certificado digital inválido <br />
              Validade: {format(new Date(certficate?.date), 'dd/MM/yyyy')}
            </span>
          }
        >
          <img src={invalidCertificate} alt="certificado inválido" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Sem certificado digital">
        <img src={offCertificate} alt="sem certificado" />
      </Tooltip>
    );
  };

  const statusIcon = (status) => {
    if (status === 'PROCESSING') {
      return (
        <Tooltip title="Aguarde, loja sendo gerada...">
          <img src={clockTime} alt="ClockTime" style={{ marginLeft: '4px' }} />
        </Tooltip>
      );
    }
    if (status === 'ERROR') {
      return (
        <Tooltip title="Falha na criação da loja">
          <img src={failAlert} alt="FailAlert" style={{ marginLeft: '4px' }} />
        </Tooltip>
      );
    }
  };

  const addProvider = () => (
    <Tooltip title="Cadastro de Fornecedor" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={toggleProviderModal}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  const { t } = useTranslation('translations');

  useEffect(() => {
    getCompanies();

    getOptions(
      `${environments.catalog}/segments`,
      'segmentOptions',
      segmentOptions,
      setSegmentOptions,
      getId,
      null,
      null,
      10
    );
  }, [updateList, openModalDelete, page, filtersChanged]);

  return (
    <Layout>
      <TitleHead title={t('stores')} />

      <div style={{ padding: '0px 10px', marginBottom: '10px' }}>
        <PageTitleAndFilter
          title="Lojas cadastradas"
          additionalbutton={addProvider}
        />
      </div>

      <TableData
        className="desktop"
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        border="0"
      >
        <thead>
          <tr className="headerTitle">
            <th width="5%" className="subtitle">
              CNPJ{' '}
              <OrderBy
                filterName="cnpjNumber"
                handleFilter={handleOrdernationFilter}
              />
            </th>

            <th width="5%" className="subtitle">
              RAZÃO SOCIAL{' '}
              <OrderBy
                filterName="providerName"
                handleFilter={handleOrdernationFilter}
              />
            </th>

            <th width="5%" className="subtitle">
              NOME FANTASIA{' '}
              <OrderBy
                filterName="fantasyName"
                handleFilter={handleOrdernationFilter}
              />
            </th>

            <th width="3%" className="subtitle center pl8">
              TELEFONE
            </th>

            <th width="2%" className="subtitle center pl8">
              SEGMENTO
            </th>

            <th width="1%" className="subtitle center pl8">
              DELIVERY
            </th>

            <th width="2%" className="subtitle" />

            <th width="0.1%" className="subtitle" />
          </tr>
        </thead>

        <Tbody>
          {loading && (
            <tr>
              <th
                className="m10 textAlignCenter"
                colSpan="18"
                style={{ background: '#fff' }}
              >
                <LinearProgress
                  style={{ height: '7px' }}
                  id="loading"
                  variant="query"
                />
              </th>
            </tr>
          )}

          {dataTable?.length ? (
            dataTable.map((provider) => (
              <>
                <tr
                  style={{
                    color:
                      provider?.status === 'PROCESSING' ||
                      provider?.status === 'ERROR'
                        ? '#9296AC'
                        : ''
                  }}
                >
                  <td className="description p12">
                    {formatDocumentNumber(provider?.cnpjNumber)}
                  </td>

                  <td className="description" style={{ maxWidth: '200px' }}>
                    {provider?.status === 'PROCESSING' ||
                    provider?.status === 'ERROR' ? (
                      <>{truncateName(provider.companyName, 28)}</>
                    ) : (
                      <Link
                        onClick={() => {
                          setEditRestaurant(true);
                          handleModalCreateAndEdit(provider._id);
                        }}
                      >
                        {truncateName(provider.companyName, 28)}
                      </Link>
                    )}
                  </td>

                  <td className="description" style={{ maxWidth: '200px' }}>
                    {truncateName(provider.fantasyName, 28)}
                  </td>

                  <td className="description center">
                    {provider.telephoneNumber}
                  </td>

                  <td className="description center">{provider.segment}</td>

                  <td className="description center p8">
                    {provider.delivery === true ? 'Sim' : 'Não'}
                  </td>

                  <td style={{ borderLeft: '1px solid #D2D3D7' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding:
                          provider?.status !== 'PROCESSING' ||
                          (provider?.status !== 'ERROR' && '19px')
                      }}
                    >
                      {provider?.status === 'PROCESSING' ||
                      provider?.status === 'ERROR' ? (
                        ''
                      ) : (
                        <>
                          <IconButton
                            onClick={() => handleOpenModalAdress(provider._id)}
                          >
                            <Tooltip title="Endereços">
                              <img src={addressHouse} alt="Endereço" />
                            </Tooltip>
                          </IconButton>

                          <IconButton
                            onClick={() => handleOpenResponsable(provider._id)}
                          >
                            <Tooltip title="Contato">
                              <img src={contact} alt="Contato" />
                            </Tooltip>
                          </IconButton>

                          <IconButton
                            onClick={() => handleOpenInfos(provider._id)}
                          >
                            <Tooltip title="Informação Adicional">
                              <img src={infoAdd} alt="Informação Adicional" />
                            </Tooltip>
                          </IconButton>

                          <IconButton
                            onClick={() =>
                              handleModalCertficate(
                                provider.companyName,
                                provider?.cnpjNumber,
                                provider._id,
                                provider?.validityCertificate
                              )
                            }
                          >
                            {certificateIcon(provider?.validityCertificate, provider._id)}
                          </IconButton>
                        </>
                      )}
                    </div>
                  </td>

                  <td
                    width="0"
                    className="description p8"
                    style={{ borderLeft: '1px solid #D2D3D7' }}
                  >
                    {provider?.status === 'PROCESSING' ||
                    provider?.status === 'ERROR' ? (
                      <>{statusIcon(provider?.status)}</>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        style={{ padding: 0, outline: 0 }}
                        onClick={() => handleOpenModalDelete(provider._id)}
                      >
                        <Tooltip title="Deletar">
                          <DeleteOutline />
                        </Tooltip>
                      </IconButton>
                    )}
                  </td>
                </tr>

                <tr className="tr-hr">
                  <td colSpan="10">
                    <hr />
                  </td>
                </tr>
              </>
            ))
          ) : (
            <tr>
              <th
                colSpan="13"
                className="m10 textAlignCenter"
                style={{ background: '#fff', color: '#000', fontSize: '12px' }}
              >
                Não há nenhuma Loja Cadastrada
              </th>
            </tr>
          )}
        </Tbody>
      </TableData>

      <div className="pagination">
        <Pagination
          count={totalPages}
          onChange={(_, page) => setCallSettings({ ...callSettings, page })}
          variant="outlined"
          shape="rounded"
          size="small"
          color="primary"
        />

        <ItemsPerPage
          numberOfElements={numberOfElements}
          size={totalElements}
        />
      </div>

      <Modal
        className="defaultModal modalRegisterContainer registerStoreDialog"
        open={providerModal}
        onClose={() => {
          toggleProviderModal();
          setFindResponse()
          setEditRestaurant(false);
        }}
        aria-labelledby="Provider Modal"
      >
        <StoreForm
          isModal
          segmentOptions={segmentOptions}
          redirectPath={null}
          onClose={() => {
            toggleProviderModal();
            setFindResponse()
            setEditRestaurant(false);
          }}
          setUpdateList={setUpdateList}
          dataTable={findResponse}
          editRestaurant={editRestaurant}
        />
      </Modal>

      {openModalDelete && (
        <DeleteDialog
          openDelete={openModalDelete}
          close={() => setOpenModalDelete(false)}
          handleRemove={handleOpenDelete}
        />
      )}

      {openModalAdress && (
        <AddressDialog
          open={openModalAdress}
          close={() => setOpenModalAdress(false)}
          title="Endereços"
        >
          <TableData
            className="desktop"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th width="10%" className="subtitle-modal">
                  Tipo de endereço
                  <OrderBy
                    filterName="cnpjNumber"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th width="5%" className="subtitle-modal">
                  CEP
                  <OrderBy
                    filterName="providerName"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th width="5%" className="subtitle-modal">
                  País
                  <OrderBy
                    filterName="fantasyName"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th width="5%" className="subtitle-modal">
                  Estado
                </th>

                <th width="4%" className="subtitle-modal">
                  Cidade
                </th>

                <th width="4%" className="subtitle-modal">
                  Logradouro
                </th>

                <th width="4%" className="subtitle-modal">
                  Numero
                </th>

                <th width="4%" className="subtitle-modal">
                  Complemento
                </th>
              </tr>
            </thead>

            <Tbody>
              {loading && (
                <tr>
                  <th className="m10 textAlignCenter" colSpan="18">
                    <LinearProgress
                      style={{ height: '7px' }}
                      id="loading"
                      variant="query"
                    />
                  </th>
                </tr>
              )}

              {findResponse?.length ? (
                findResponse.map((address) => (
                  <>
                    <tr>
                      <td className="subtitle-modal p12">
                        {address?.typeAddress}
                      </td>

                      <td
                        className="subtitle-modal"
                        style={{ maxWidth: '350px' }}
                      >
                        {address.zipCode}
                      </td>

                      <td
                        className="subtitle-modal"
                        style={{ maxWidth: '350px' }}
                      >
                        {address.country}
                      </td>

                      <td className="subtitle-modal center">
                        {address.states}
                      </td>

                      <td className="subtitle-modal center">{address.city}</td>

                      <td className="subtitle-modal center p8">
                        {address.street}
                      </td>

                      <td className="subtitle-modal center p8">
                        {address.number}
                      </td>

                      <td className="subtitle-modal center p8">
                        {address.complement}
                      </td>
                    </tr>

                    <tr className="tr-hr">
                      <td colSpan="10">
                        <hr />
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <th
                    colSpan="13"
                    className="m10 textAlignCenter"
                    style={{
                      background: '#fff',
                      color: '#000',
                      fontSize: '12px'
                    }}
                  >
                    Não há nenhuma Loja Cadastrada
                  </th>
                </tr>
              )}
            </Tbody>
          </TableData>
        </AddressDialog>
      )}

      {openModalResponsable && (
        <AddressDialog
          open={openModalResponsable}
          close={() => setOpenModalResponsable(false)}
          title="Responsável"
        >
          <TableData
            className="desktop"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th width="5%" className="subtitle-modal">
                  Nome Completo
                  <OrderBy
                    filterName="cnpjNumber"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th width="5%" className="subtitle-modal">
                  Telefone
                  <OrderBy
                    filterName="providerName"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th width="5%" className="subtitle-modal">
                  E-mail
                  <OrderBy
                    filterName="fantasyName"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>
              </tr>
            </thead>

            <Tbody>
              {loading && (
                <tr>
                  <th className="m10 textAlignCenter" colSpan="18">
                    <LinearProgress
                      style={{ height: '7px' }}
                      id="loading"
                      variant="query"
                    />
                  </th>
                </tr>
              )}

              {findResponse?.length ? (
                findResponse.map((responsable) => (
                  <>
                    <tr>
                      <td className="subtitle-modal p12">
                        {responsable?.fullName}
                      </td>

                      <td
                        className="subtitle-modal"
                        style={{ maxWidth: '350px' }}
                      >
                        {responsable.telephoneNumber}
                      </td>

                      <td
                        className="subtitle-modal"
                        style={{ maxWidth: '350px' }}
                      >
                        {responsable.emailAddress}
                      </td>
                    </tr>

                    <tr className="tr-hr">
                      <td colSpan="10">
                        <hr />
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <th
                    colSpan="13"
                    className="m10 textAlignCenter"
                    style={{
                      background: '#fff',
                      color: '#000',
                      fontSize: '12px'
                    }}
                  >
                    Não há dados a serem exibidos.
                  </th>
                </tr>
              )}
            </Tbody>
          </TableData>
        </AddressDialog>
      )}

      {openModalInfos && (
        <AddressDialog
          open={openModalInfos}
          close={() => setOpenModalInfos(false)}
          title="Informação Adicional"
        >
          <TableData
            className="desktop"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th width="5%" className="subtitle-modal">
                  Faturamento médio mensal
                  <OrderBy
                    filterName="cnpjNumber"
                    handleFilter={handleOrdernationFilter}
                  />
                </th>

                <th width="5%" className="subtitle-modal">
                  numeros de pedidos/dia
                </th>

                <th width="5%" className="subtitle-modal">
                  ticket médio
                </th>

                <th width="5%" className="subtitle-modal">
                  volume de pedidos/mês
                </th>
              </tr>
            </thead>

            <Tbody>
              {loading && (
                <tr>
                  <th className="m10 textAlignCenter" colSpan="18">
                    <LinearProgress
                      style={{ height: '7px' }}
                      id="loading"
                      variant="query"
                    />
                  </th>
                </tr>
              )}

              {findResponse?.length ? (
                findResponse.map((infos) => (
                  <>
                    <tr>
                      <td className="subtitle-modal p12">
                        {infos?.orderVolume}
                      </td>

                      <td
                        className="subtitle-modal"
                        style={{ maxWidth: '350px' }}
                      >
                        {infos.numberOrdersDays}
                      </td>

                      <td className="subtitle-modal">{infos.averageTicket}</td>

                      <td className="subtitle-modal">
                        {infos.averageBillingMonthly}
                      </td>
                    </tr>

                    <tr className="tr-hr">
                      <td colSpan="10">
                        <hr />
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <th
                    colSpan="13"
                    className="m10 textAlignCenter"
                    style={{
                      background: '#fff',
                      color: '#000',
                      fontSize: '12px'
                    }}
                  >
                    Não há dados a serem exibidos.
                  </th>
                </tr>
              )}
            </Tbody>
          </TableData>
        </AddressDialog>
      )}

      {openModalCertificate && (
        <CertificateModal
          open={openModalCertificate}
          close={handleCloseCertificate}
        >
          <Grid
            container
            style={{
              flexDirection: 'column'
            }}
            className="cardDefault withoutInputs mr10"
          >
            <div>
              <div style={{ display: 'flex', gap: '5px', height: '20px' }}>
                <p style={{ fontWeight: 700 }}>Arquivo:</p>

                {selectedFile ? (
                  <Tooltip
                    title={`Certificado digital válido Validade: ${date}`}
                  >
                    <img src={validCertificate} alt="certificado valido" />
                  </Tooltip>
                ) : filterCertificate?.length ? (
                  <Tooltip
                  title={`Certificado digital ${filterCertificate[0]?.validityCertificate?.valid ? 
                    "válido" : "inválido"} 
                    Validade: ${format(new Date(filterCertificate[0]?.validityCertificate?.date), 'dd/MM/yyyy')}
                  `}
                >
                  <img src={filterCertificate[0]?.validityCertificate?.valid ? validCertificate : invalidCertificate} alt="certificado valido" />
                </Tooltip>
                ) : (
                  <Tooltip title="Sem certificado digital">
                    <img src={offCertificate} alt="sem certificado" />
                  </Tooltip>
                )}

                <MaterialButton
                  onClick={handleClickFakeImportPdf}
                  className="defaultButton backButton fontSizeDefault pr20 pl20 pt3 pb3 personal"
                >
                  <InputFile
                    type="file"
                    title="Enviar"
                    id="pdfImport"
                    name="pdfImport"
                    onChange={handleFileChange}
                    accept=".pfx"
                    aria-label="upload"
                    className="dNone"
                  />

                  <span>Selecionar</span>
                </MaterialButton>
              </div>
              {selectedFile ? (
                <Tooltip
                  title={selectedFile.name}
                >
                  <div>
                    {truncateName(selectedFile.name, 30)}
                  </div>
                </Tooltip>
              ) : 
                filterCertificate?.length ? 
                  <Tooltip
                    title={filterCertificate[0]?.validityCertificate?.fileCertificateName}
                  >
                    <div>
                      {truncateName(filterCertificate[0]?.validityCertificate?.fileCertificateName, 30)}
                    </div>
                </Tooltip>
               :
              <OffSpan style={{ fontStyle: 'italic' }}>
              Nenhum arquivo selecionado
            </OffSpan>
              }
            </div>

            <div style={{ display: 'flex', marginTop: '30px', gap: '30px' }}>
              <div>
                <Grid style={{ width: '100%' }}>
                  <TextField
                    label="Senha"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    name="description"
                    variant="outlined"
                    size="small"
                    value={password}
                    fullWidth
                    required="required"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </div>
            </div>
            
            <div
              style={{
                marginTop: '25px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <MaterialButton
                design="contained"
                className="defaultButton"
                style={{ 
                  marginLeft: '20px',
                  backgroundColor: password === '' ? "transparent" : "#5062f0",
                  color: password === '' ? "#77798b" : "#fff",
                  border: 'solid 1px #77798b'
                }}
                onClick={handleOnSave}
                disabled={password === ''}
              >
                Salvar
              </MaterialButton>
            </div>
          </Grid>
        </CertificateModal>
      )}

      <CommonAlert
        open={toastError}
        onClose={() => setToastError(false)}
        indexMessage={messageError}
        messagePersonal={messageError}
        severity={severity}
        statusCode={statusCode}
        width="50%"
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListagemLojas);
