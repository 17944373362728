import styled from 'styled-components';
import { lightHouseColors } from '../utils';

export const InfoContainer = styled.div`
    height: ${({ subInfos }) => !subInfos && `190px`};;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 9px 12px 7px;
`;

export const InfoCardWrap = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: ${({ marginButton }) => marginButton};
`;

export const InfoCard = styled.div`
    width: ${({ width }) => width ? width : `48%`};
    padding-left: ${({ padding }) => padding ? padding : `10px`};
    padding-right: ${({ paddingRight }) => paddingRight ? paddingRight : `0px`};
    color: ${({ color }) => color};
    ${({ color }) =>
        color && (`border-left: 1px solid`)
    };
    margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : `0`};

    p {
        font-weight: bold;
    }
`;

export const InconWrapTrend = styled.div`
    width: 24px;
`;

export const InfoNumber = styled.p`
    font-size: 20px;
    font-weight: ${({ bold }) => bold ? 'bold' : 'none'};
    color: ${({ color }) => lightHouseColors[color]};
    span {
        font-size: 12px;
        color: black;
        font-weight: 100;
        margin-left: 6px;
    };
    text-align: ${({ align }) => align ? align : 'left'};
    padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
    margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '0px'};
    margin-top: ${({ marginTop }) => marginTop ? marginTop : '0px'};
    text-shadow: ${({ textShadow }) => textShadow && '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'};
`;

export const PercentCard = styled.div`
    display: flex;
    font-size: 12px;
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'space-between'};
`;

export const PercentCardWrap = styled.div`
    font-size: 12px;
    padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
`;

export const PercentInfo = styled.div`
    position: relative;
    margin-top: 0px;
    color: ${({ color }) => lightHouseColors[color]};
    font-size: ${({ fontPlus }) => fontPlus && '16px'};
    text-align: ${({ align }) => align ? align : 'left'};
    font-weight: ${({ bold }) => bold ? bold : 'normal'};
    margin-top: ${({ marginTop }) => marginTop ? marginTop : '0px'};
`;

export const InforNameContainer = styled.div`
    display: flex; 
    justify-content: space-between;
    font-size: 12px;

    span {
        color: #FFFFFF;
        font-weight: bold;

        &:first-of-type {
            font-size: 14px;
            text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        }
    }
`;

export const InfoDetail = styled.span`
    font-size: 10px; 
    margin-top: 4px;
    margin-left: ${({ margin }) => margin};
    font-weight: 100;
    cursor: ${({ cursor }) => cursor ? cursor : 'pointer'};
    display: flex;
    justify-content: flex-end;
    color: ${({ color }) => color ? color : '#51B7E3'};
`;

export const InfoDetailSimple = styled.span`
    font-size: 10px; 
    margin-top: 3px;
    margin-left: ${({ margin }) => margin};
    font-weight: 100;
    display: block;
    text-align: right;
    cursor: ${({ cursor }) => cursor ? cursor : 'pointer'};
    padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
    color: ${({ color }) => color ? color : '#51B7E3'};
`;

export const InfoNumberTotalCost = styled.span`
    font-size: 12px;
    padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
`;

export const InfoNumberTotalCostSimple = styled.span`
    font-size: ${({ fontSize }) => fontSize ? fontSize : '12px'};
    display: block;
    text-align: right;
    display: flex;
    justify-content: ${({ justify }) => justify ? justify : "flex-end"};
    padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
    color: ${({ color }) => lightHouseColors[color]};
    margin-top: ${({ marginTop }) => marginTop && marginTop};
    text-shadow: ${({ textShadow }) => textShadow && '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'};
`;

export const InfoCardSecondName = styled.p`
    width: auto;
    font-size: ${({ fontSize }) => fontSize ? fontSize : '12px'};
    margin: 0 0 0px;
    color: ${({ color }) => lightHouseColors[color]};
    text-align: ${({ align }) => align ? align : 'left'};
    padding-right: ${({ paddingRight }) => paddingRight && paddingRight};
    margin-top: ${({ marginTop }) => marginTop && marginTop};
`;

export const IntIconImg = styled.img`
    width: 12px;
    position: absolute;
    left: -15px;
    top: 2px;
`; 
