export const setPopoverDataStatus = (type, status) => {
  if (type === 'RECIPE' && status === 'START') {
    return 'start';
  }

  if (type === 'TRANSFORMATION' && status === 'START') {
    return 'finishTransformation';
  }

  return 'waiting';
};
