import react from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Typography,
  DialogContent,
  IconButton
} from '@material-ui/core';
import { default as MaterialButton } from '@material-ui/core/Button';
import Draggable from 'react-draggable';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeModal.svg';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function DeleteDialog({ openDelete, close, handleRemove }) {
  return (
    <Dialog
      open={openDelete}
      maxWidth="xs"
      fullWidth
      className="defaultModal mediumModal"
      onClose={close}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="modalHeader navyBlue bold cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Deseja realmente excluir essa Loja?</Typography>
      </DialogTitle>

      <DialogContent>
        <Typography>
          Esta ação não pode ser desfeita. Deseja continuar?
        </Typography>
      </DialogContent>

      <DialogActions
        className="justifyCenter pb20 pt0"
        style={{ padding: '16px' }}
      >
        <MaterialButton
          className="defaultButton backButton"
          design="outlined"
          onClick={close}
        >
          Cancelar
        </MaterialButton>

        <MaterialButton
          design="contained"
          className="defaultButton submitButton"
          onClick={handleRemove}
          style={{ marginLeft: '20px' }}
        >
          Confirmar
        </MaterialButton>
      </DialogActions>
    </Dialog>
  );
}

export function AddressDialog({ open, close, children, title }) {
  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="60%"
      fullWidth
      style={{ margin: '10%' }}
    >
      {children}
    </Dialog>
  );
}

export function CertificateModal({ children, open, close }) {
  return (
    <Dialog
      open={open}
      onClose={close}
      className="defaultModal certificate-modal"
      fullWidth
      style={{ margin: '10%' }}
    >
      <div>
        <DialogTitle
          id="draggable-dialog-title"
          style={{ padding: '0 10px', background: '#5062F0' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 0
            }}
          >
            <Typography
              className="bold textAlignStart"
              style={{
                fontSize: '18px',
                color: '#fff'
              }}
            >
              CERTIFICADO DIGITAL
            </Typography>

            <IconButton edge="start" color="inherit" onClick={close}>
              <img
                style={{ width: '16px', height: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent style={{ overflowY: 'unset' }}>{children}</DialogContent>
      </div>
    </Dialog>
  );
}
