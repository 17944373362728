/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';

import { Checkbox, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import EmptyCheckboxIcon from 'images/icons/empties/emptyCheckboxIcon.svg';
import EmptyCheckboxIconWhite from 'images/icons/empties/emptyCheckboxIconWhite.svg';

import {
  LabelTitle,
  FormControlStyles,
  FormControlLabelStyled,
  MultipleCheckboxContainer
} from './index.style';

const useStyles = makeStyles(FormControlStyles);

function MultipleCheckbox({
  title,
  checkBoxInputs,
  handleFilter,
  topAdjustment,
  color,
  storedValue,
  defaultValue,
  searchByTermMode,
  shouldCleanFilter,
  customClassName
}) {
  const classes = useStyles(color);
  const [checkedList, setCheckedList] = useState(
    checkBoxInputs
      .filter((item) => item?.checked)
      .map((el) => el?.referenceName) || []
  );

  useEffect(() => {
    const hasValue = storedValue?.length;
    if (hasValue || defaultValue) {
      setCheckedList(storedValue || defaultValue);
    }
  }, [defaultValue, storedValue]);

  const handleCheckBox = (
    event,
    checked,
    referenceName,
    filterValue,
    keepFilter
  ) => {
    const { target: { name = '' } = {} } = event;
    const index = checkedList.indexOf(name);

    if (checked) {
      if (keepFilter && referenceName) {
        setCheckedList([...checkedList, referenceName]);
        handleFilter(referenceName, checked, null, null, searchByTermMode);
        return;
      }

      if (filterValue) {
        handleFilter(referenceName, filterValue, null, null, searchByTermMode);
        return;
      }

      if (referenceName) {
        setCheckedList([...checkedList, referenceName]);
        handleFilter(referenceName, checked, null, null, searchByTermMode);
        return;
      }
      setCheckedList([...checkedList, name]);
      handleFilter(
        'curve',
        [...checkedList, name].join(),
        null,
        null,
        searchByTermMode
      );
    } else {
      if (!referenceName && index > -1 && !keepFilter) {
        checkedList.splice(index, 1);
        handleFilter(
          'curve',
          checkedList.length ? checkedList.join() : null,
          null,
          null,
          searchByTermMode
        );
        return;
      }

      if (keepFilter && referenceName) {
        checkedList.splice(index, 1);
        handleFilter(referenceName, null, null, null, searchByTermMode);

        checkedList.map((item) => {
          handleFilter(item, checked, null, null, searchByTermMode);
        });
        return;
      }

      if (referenceName) {
        setCheckedList(checkedList.filter((item) => item !== referenceName));
        handleFilter(referenceName, false, null, null, searchByTermMode);
      }
    }
  };

  useEffect(() => {
    if (shouldCleanFilter) {
      setCheckedList(defaultValue || []);
    }
  }, [defaultValue, shouldCleanFilter]);

  return (
    <MultipleCheckboxContainer topAdjustment={topAdjustment} className={customClassName}>
      <LabelTitle>{title}</LabelTitle>
      <FormControl classes={{ root: classes.root }}>
        {checkBoxInputs.map(
          ({ name, referenceName, filterValue, keepFilter }) => {
            const newName = referenceName || name;
            const checkedItems = checkedList.some((item) => item === newName);

            return (
              <FormControlLabelStyled
                marginTop={title}
                color={color}
                control={
                  <Checkbox
                    checked={checkedItems}
                    name={newName}
                    icon={
                      <img
                        src={
                          color
                            ? EmptyCheckboxIconWhite
                            : EmptyCheckboxIcon
                        }
                      />
                    }
                    checkedIcon={
                      <img
                        src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                      />
                    }
                    size="small"
                    onChange={(event, checked) =>
                      handleCheckBox(
                        event,
                        checked,
                        referenceName,
                        filterValue,
                        keepFilter
                      )
                    }
                    fontSize="small"
                  />
                }
                label={name}
              />
            );
          }
        )}
      </FormControl>
    </MultipleCheckboxContainer>
  );
}

export default MultipleCheckbox;
