import React, { useState } from 'react';

import {
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';

import AddIcon from 'images/icons/signals/addsAndCreates/addCardBlue.svg';
import CreateNewTagDiaglog from 'pages/admin/createTag/Dialogs/CreateNewTag/CreateNewTag';

import Button from '../../Button/button';
import './styles.scss';

function AddTag({
  tags,
  tag,
  productTags,
  setTag,
  tagsUpdated,
  setTagsUpdated,
  onlyViewMode,
  addNewTag
}) {
  const [createNewDialog, setCreateNewDialog] = useState(false);
  const [message, setMessage] = useState('');

  const createTagSuccessMessage = () => {
    setMessage('Tag cadastrada com sucesso');
  };

  return (
    <section className="addTagContainer">
      <table
        className="desktop addTagTable"
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        border="0"
      >
        <thead>
          <tr className="headerTitle">
            <th
              className="subtitle noBorderRadiusLeft pRelative"
              style={{ top: 0 }}
            >
              INCLUSÃO DE TAGS
            </th>
          </tr>

          <tr className="headerSubtitle">
            <th className="subtitle left pl12 pRelative" style={{ top: 0 }}>
              TAGS
            </th>
          </tr>
        </thead>

        <tbody>
          <tr className="withoutHover">
            <td id="addTagInput" className="description p12">
              <Autocomplete
                options={tags || []}
                getOptionLabel={(option) => option.description}
                getOptionDisabled={(option) =>
                  productTags?.some((tag) => tag.id === option.id) &&
                  productTags?.find((item) => item.id === option.id)?.status !==
                    'DELETED'
                }
                onFocus={() => setTagsUpdated(!tagsUpdated)}
                value={tag}
                size="small"
                onChange={(_, value) => {
                  if (value) {
                    setTag(value);
                  } else {
                    setTag({});
                  }
                }}
                className="autocompleteSmall"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={params.id}
                    type="text"
                    name="tags"
                    variant="outlined"
                    placeholder="Selecione..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => (
                  <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                    {params.description}
                  </Typography>
                )}
                disabled={onlyViewMode}
              />
            </td>
          </tr>

          {false && (
            <tr className="withoutHover">
              <td colSpan="1">
                <LinearProgress variant="query" />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="dFlex justifyEnd pr8 mt8 mb8">
        <IconButton
          disabled={onlyViewMode}
          className="p0 createTagButton"
          color="primary"
          aria-label="upload picture"
          component="span"
          onClick={() => {
            setCreateNewDialog(true);
          }}
        >
          <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />

          <Typography>Criar Tag</Typography>
        </IconButton>

        <Button
          className="defaultButton submitButton"
          design="contained"
          disabled={!tag?.description || onlyViewMode}
          onClick={() => {
            addNewTag();
          }}
          label={false ? 'Processando...' : 'Adicionar'}
        />
      </div>

      <Snackbar
        open={message}
        autoHideDuration={6000}
        onClose={() => setMessage('')}
      >
        <Alert onClose={() => setMessage('')} severity="success">
          {message}
        </Alert>
      </Snackbar>

      {createNewDialog && (
        <CreateNewTagDiaglog
          openCreateNewTagDialog={createNewDialog}
          setOpenCreateNewTagDialog={setCreateNewDialog}
          getTagListing={() => setTagsUpdated(!tagsUpdated)}
          handleOpenSucessMessage={createTagSuccessMessage}
        />
      )}
    </section>
  );
}

export default AddTag;
