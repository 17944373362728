/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useMemo
} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import Draggable from 'react-draggable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import CostTimeline from 'components/Dialogs/CostTimeline';
import DialogCards from 'components/Dialogs/DialogCards';
import ProvidersDialog from 'components/Dialogs/ProvidersDialog';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import ImageWrapper from 'components/ImageWrapper';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import ProviderIcon from 'images/icons/objects/trucks/truckWhiteOutline.svg';
import DialogProducts from 'pages/catalog/products/dialogs/dialogProducts'
import getOptions from 'services/getOptions';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import axiosInstanceOrder from 'services/middlewares/apis/orders_validate';
import * as ProductsActions from 'store/actions/products';
import { changeFilter, clearFilter } from 'store/reducers/productsFilterSlice';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

const TheoryStockDialog = React.lazy(() =>
  import('components/Dialogs/ExtractsDialog')
);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function MonthlyEvolution({ userInfo, setEditProducts }) {
  const dispatch = useDispatch();
  const { id: paramsID } = useParams();
  const history = useHistory();
  const prevPath = history?.location?.state?.prevPath;
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [loading, setLoading] = useState(false);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [updateAutocomplete, setUpdateAutocomplete] = useState(false)
  const [openFirstDate, setOpenFirstDate] = useState(false)
  const [openSecondDate, setOpenSecondDate] = useState(false)
  const [firstDate, setFirstDate] = useState(moment().startOf('isoWeek').subtract(2, 'week'))
  const [secondDate, setSecondDate] = useState(moment().startOf('isoWeek').subtract(1, 'week'))
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const profile = userInfo?.profile[0][0]?.name;
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const storedProductsFilter = useSelector(
    (currState) => currState.productsFilter.filters
  );
  const [checkConsume, setCheckConsume] = useState(false);

  const shouldUseStoredFilters = useMemo(
    () => storedProductsFilter && prevPath,
    [prevPath, storedProductsFilter]
  );

  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    mainLoading: false,
    page: 1,
    totalPages: 0,
    size: 100,
    filters: shouldUseStoredFilters ? storedProductsFilter : [],
    numberOfElements: 0,
    totalElements: 0
  });

  const {
    getId,
    mainLoading,
    page,
    size,
    totalPages,
    numberOfElements,
    totalElements
  } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState(callSettings?.filters);
  const [requestStorages, setRequestStorages] = useState(false)
  const cancelTokenSource = axios.CancelToken.source();

  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'providersAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      product: null
    },
    {
      id: 2,
      name: 'productsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      product: null
    },
    {
      id: 3,
      name: 'cardsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      product: null,
      items: null
    },
    { id: 4, name: 'filters', open: false, fullScreen: null, maxWidth: null },
    { id: 5, name: 'skuModal', open: false, fullScreen: false, maxWidth: 'sm' },
    {
      id: 6,
      name: 'technicalSheet',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    { id: 7, name: 'productEdit', open: false, fullScreen: false, maxWidth: 'lg'},
    { id: 8, name: 'productTransference', open: false, fullScreen: false, maxWidth: 'lg'},
    { id: 9, name: 'costTimeline', open: false, fullScreen: false, maxWidth: 'lg', product: ''}
  ]);
  const [mainList, setMainList] = useState([]);
  const [totalInfos, setTotalInfos] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentOrder, setCurrentOrder] = useState();
  const [providers, setProviders] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });

  const [selectedCard, setSelectedCard] = useState();
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();

  const [modalStates, setModalStates] = useState({
    modalProduct: false,
    modalProductSetups: false,
    modalTypeOfUnification: false,
    modalDiscard: false
  });

  const [productExtractDescription, setProductExtractDescription] =
    useState('-');
  const [parameters, setParameters] = useState('');
  const [selectedSku, setSelectedSku] = useState([]);
  const [productModal, setProductModal] = useState({});
  const [dialogProvidersList, setDialogProvidersList] = useState([]);
  const [brands, setBrands] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [productDataModal, setProductDataModal] = useState({data: {}})
  const isGroupManage = getId?.includes(',');

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Produto',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'products',
        optionReference: 'description',
        urlPath: `${environments.catalog}/products`,
        options: [],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue:
          (shouldUseStoredFilters &&
            storedProductsFilter.find(
              (filter) => filter.filterName === 'productDescription'
            )?.productDescription) ||
          storedProductsFilter.find(
            (filter) => filter.filterName === 'productId'
          )?.textValue
      },
      {
        label: 'SKU',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/skus`,
        key: 'skus',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue:
          (shouldUseStoredFilters &&
            storedProductsFilter.find(
              (filter) => filter.filterName === 'skuDescription'
            )?.skuDescription) ||
          storedProductsFilter.find((filter) => filter.filterName === 'skuId')
            ?.textValue
      },
      {
        label: 'Categoria',
        filterName: 'categoryDescription',
        placeHolder: 'Selecione...',
        type: 'newTreeSelector',
        urlPath: `${environments.catalog}/categories`,
        key: 'categoryDescription',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_to_plus',
        storedValue:
          shouldUseStoredFilters &&
          storedProductsFilter.find(
            (filter) => filter.filterName === 'category'
          )?.textValue
      },
      {
        label: 'Fornecedores',
        filterName: 'providerName',
        filterNameSecond: 'providerIds',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/suppliers/groups`,
        key: 'providersIdList',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        filterParamsOff: true,
        disableFilterNameSecond: false,
        searchByTermMode: true,
        ignoreFilterContent: true
      },
      {
        label: 'Tipo',
        filterName: 'type',
        placeHolder: 'Selecione...',
        size: 'medium_plus',
        type: 'autoComplete',
        key: 'type',
        isSelect: true,
        allowClear: true,
        options: [
          { title: 'Todos', value: '' },
          { title: 'Inflação', value: 'inflation' },
          { title: 'Deflação', value: 'deflation' }
        ]
      },
    ]
  ]);

  const clearFilterValues = (param) => {
    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((item) => {
          if (item?.filterName === param) {
            return {
              ...item,
              storedValue: ''
            };
          }

          return item;
        });
      }

      return filterLine;
    });

    setFilterFields(FILTER_FIELDS);
  };

  const handleFilter = useCallback(
    (param, value, filter, paramSecond) => {
      const hasFilterWithFirstName = filtersChanged?.filter(
        (el) => el?.param === param
      )?.length;
      const hasFilterWithSecondName = filtersChanged?.filter(
        (el) => el?.param === paramSecond
      )?.length;

      let formattedFilters = hasFilterWithSecondName
        ? filtersChanged?.filter((el) => el?.param !== paramSecond)
        : filtersChanged;

      if (!value) {
        clearFilterValues(param);

        dispatch(clearFilter());

        if (hasFilterWithFirstName || hasFilterWithSecondName) {
          formattedFilters = filtersChanged?.filter(
            (el) => el?.param !== paramSecond && el?.param !== param
          );
        }
      }

      dispatch(
        changeFilter({
          param,
          value,
          textValue: filter?.title
        })
      );

      cancelTokenSource.cancel();

      handleFilterOptions(param, value, formattedFilters, setFiltersChanged);
    },
    [dispatch, filtersChanged]
  );

  // GET PRODUCTS
  const getMainContentByGroup = useCallback(
    (currPage, currSize, getTotals = false) => {
      (async () => {
        setMainList([]);
        setCallSettings((prevState) => ({ ...prevState, mainLoading: true }));
        setUpdateAutocomplete(!updateAutocomplete)

        let params = [];

        if (shouldUseStoredFilters) {
          const parsedFilters = storedProductsFilter
            .map((filter) => {
              const key = Object.keys(filter)[0];

              if (filter[key]) {
                return `${key}=${filter[key]}&`;
              }
              return null;
            })
            .join('');

          params = parsedFilters;
        } else {
          params = filtersChanged.map((filter) => filter.urlParam).join('&');
        }

        const totalsParam = getTotals ? `&totals=true` : ''
        const firstDateFormatted = moment(firstDate).format('YYYY-MM-DD')
        const secondDateFormatted = moment(secondDate).format('YYYY-MM-DD')
        const hasOrderParam = params?.includes('orderBy')
        const orderParam = hasOrderParam && currentOrder === 'first' 
          ? `&dateToOrder=${firstDateFormatted}` 
          : hasOrderParam && currentOrder === 'second' 
            ? `&dateToOrder=${secondDateFormatted}` 
            : ''

        try {
          const response = await axiosInstanceCatalog.get(
            `${environments.dashBoard}/cockpit/monthlyEvolution?page=${currPage}&size=${currSize}&restaurantId=${getId}&startDates=${`${firstDateFormatted}, ${secondDateFormatted}`}&${params}${orderParam}${totalsParam}`,
            { cancelToken: cancelTokenSource.token }
          );

          const mainListResponse = await response.data;
          if (!getTotals) {
            setMainList(mainListResponse.content || []);
          } else {
            setTotalInfos(mainListResponse.content || [])
          }
          setCallSettings((prevState) => ({
            ...prevState,
            totalPages: mainListResponse.totalPages,
            mainLoading: false,
            totalElements: mainListResponse.totalElements,
            numberOfElements: mainListResponse.numberOfElements
          }));
        } catch (error) {
          setMainList([]);

          setCallSettings((prevState) => ({
            ...prevState,
            totalPages: 0,
            mainLoading: false,
            totalElements: 0,
            numberOfElements: 0
          }));

          if (axios.isCancel(error)) {
            console.log('Requisição cancelada:', error.message);
          } else {
            console.error('Erro na requisição:', error);
          }
        }
      })();
    },
    [shouldUseStoredFilters, getId, storedProductsFilter, filtersChanged, firstDate, secondDate]
  );

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStockStock?.toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 3 }
                    )
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'technicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  const handleClose = () => {
    setModalStates({
      modalProduct: false,
      modalProductSetups: false,
      modalDiscard: false
    });
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page
    );
  };

  const getProvidersByGroup = useCallback((product) => {
    (async () => {
      const response = await axiosInstanceOrder.get(
        `${environments.orders}/cache/products/uniqueproviders/${product?._id}/${getId}`
      );

      const providersResponse = await response?.data?.content;

      const formattedProviders = providersResponse
        ?.map((provider) => provider.sku)
        .map((sku) => ({
          ...sku.skus.providers,
          skuInfo: {
            unitsMeasurements: sku.skus.unitsMeasurements,
            quantity: sku.skus.quantity
          }
        }));

      const uniqueProviders = [];
      formattedProviders.flat().map((provider) => {
        if (
          uniqueProviders.some((el) => el.providerId === provider.providerId)
        ) {
          return null;
        }

        uniqueProviders.push(provider);
      });

      setDialogProvidersList(formattedProviders);
    })();
  }, []);

  const disabledDays = (date) => date.getDay() !== 1

  const evolutionTagBg = (el) => {
    let bg = '#5062F0'

    if (!el) {
      bg = '#D0D3E2'
    }

    if (el > 0) {
      bg = '#F53D4C'
    }

    return bg
  }

  const firstDateCalcValue = () => {
    const totalValueLastWeek = totalInfos[0]?.totalLastWeek

    const totalValue = totalInfos[0]?.totalCurrentWeek

    const totalDiff = totalInfos[0]?.difference

    const totalEvolution = (totalDiff / totalValueLastWeek * 100)

    const totalValueLastWeekFormatted = totalValueLastWeek?.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    const totalValueFormatted = totalValue?.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    const totalDiffFormatted = totalDiff?.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    const totalEvolutionFormatted = isNaN(totalEvolution) 
      ? '-' 
      : `${totalEvolution?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}%`

    return {
      totalValueLastWeekFormatted,
      totalValueFormatted,
      totalDiff,
      totalDiffFormatted,
      totalEvolutionFormatted,
      totalEvolution
    }
  }

  const secondDateCalcValue = () => {
    const totalValueLastWeek = totalInfos[1]?.totalLastWeek

    const totalValue = totalInfos[1]?.totalCurrentWeek

    const totalDiff = totalInfos[1]?.difference

    const totalEvolution = (totalDiff / totalValueLastWeek * 100)

    const totalValueLastWeekFormatted = totalValueLastWeek?.toLocaleString('pt-br', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    })
    const totalValueFormatted = totalValue?.toLocaleString('pt-br', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    })
    const totalDiffFormatted = totalDiff?.toLocaleString('pt-br', {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    })
    const totalEvolutionFormatted = isNaN(totalEvolution) 
      ? '-' 
      : `${totalEvolution?.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}%`

    return {
      totalValueLastWeekFormatted,
      totalValueFormatted,
      totalDiff,
      totalDiffFormatted,
      totalEvolutionFormatted,
      totalEvolution
    }
  }
  
  const handleOrdinationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  useEffect(() => {
    setCallSettings((prevState) => ({ ...prevState, getId: paramsID }));
  }, [paramsID]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    if (!prevPath) {
      dispatch(clearFilter());
    }
  }, [dispatch, prevPath]);

  useEffect(() => {
    if (!storedProductsFilter?.filter((el) => el?.filterName.includes('Id')).length) {
      getMainContentByGroup(page, size)
      getMainContentByGroup(page, size, true)
    }
  }, [
    getId,
    filtersChanged,
    page,
    size,
    firstDate,
    secondDate,
    storedProductsFilter?.filter((el) => el?.filterName.includes('Id'))?.length
  ]);

  useEffect(() => {
    if (storedProductsFilter?.filter((el) => el?.filterName.includes('Id'))?.length) {
      getMainContentByGroup(page, size)
      getMainContentByGroup(page, size, true)
    }
  }, [storedProductsFilter]);

  useEffect(() => {
    if (!modalSettings.find((modal) => modal.name === 'skuModal').open) return;

    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !providers.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        10
      );
  }, [getId, modalSettings.find((modal) => modal.name === 'skuModal').open]);

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  useEffect(() => {
    (async () => {
      const currentId = isGroupManage ? getId?.split(',')[0] : getId
      const response = await fetch(
        `${environments.restaurants}/companies/${currentId}/parameters`
      );
      const parametersResponse = await response.json();
      if (parametersResponse) {
        const { product: { type = '' } = {} } = parametersResponse;
        setParameters(type);
      }
    })();
  }, [getId]);

  // Periodicidade
  useEffect(() => {
    (async () => {
      const currentId = isGroupManage ? getId?.split(',')[0] : getId
      const response = await fetch(
        `${environments.catalog}/periodicities?page=1&size=100&originId=${currentId}`
      );
      const items = await response.json();
      setPeriodicities(items.content);
    })();
  }, [userInfo]);

  useEffect(() => {
    setCallSettings((prevState) => ({
      ...prevState,
      filters: [
        ...prevState.filters,
        {
          param: 'productId',
          value: '',
          urlParam: ''
        }
      ]
    }));

    const newArr = [...filterFields];
    if (newArr[0][1]?.options?.length < 1) {
      newArr[0][1].options = getFilterOptions(
        `${environments.catalog}/products`,
        'Produto',
        'products',
        filterFields,
        setFilterFields,
        'description',
        isGroupManage ? getId : filtersChanged?.find((el) => el.param === 'restaurantId')?.value ||
          stores[0]?.find((el) => el.name === userInfo.companiesActive.name)?.id,
        null,
        page
      );
    }

    setFilterFields(newArr);
  }, [
    filtersChanged?.find((filter) => filter.param === 'restaurantId')?.value, updateAutocomplete
  ]);

  return (
    <Layout>
      <TitleHead title="Compras - Evolução Mensal" />
      <GlobalStyle />

      <Container className="monthlyEvolutionPage">
        <PageTitleAndFilter
          title="Compras - Evolução Mensal"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          getFilterhandle={getFilterhandle}
          dynamicJustifyOff
        />

        <section className="totalInfosHeader">
          <div className="oldInfos">
            <span className="bold lightBlue fakeDatePicker">
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={ptLocale}
              >
                <KeyboardDatePicker
                  open={openFirstDate}
                  onOpen={() => setOpenFirstDate(true)}
                  onClose={() => setOpenFirstDate(false)}
                  className="dateSmall"
                  size="small"
                  id="time-picker"
                  placeholder="00/00/0000"
                  format="dd/MM"
                  ampm={false}
                  inputVariant="outlined"
                  clearable
                  fullWidth
                  autoOk
                  shouldDisableDate={disabledDays}
                  maxDate={moment(secondDate).add(3, 'hours')}
                  value={moment(firstDate).add(3, 'hours')}
                  onChange={(value) => {
                    if (value) {
                      setFirstDate(value)
                    }
                  }}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  KeyboardButtonProps={{
                    size: 'small',
                    'aria-label': 'change time',
                    style: { outline: 0 }
                  }}
                  TextFieldComponent={(params) => (
                    <TextField
                      {...params}
                      onClick={() => setOpenFirstDate(true)}
                    />
                  )}
                  PopoverProps={{ style: { outline: 0 } }}
                />
              </MuiPickersUtilsProvider>
            </span>

            <span className="totalCost">
              &nbsp;
            </span>

            <span className="evolutionTag customMarginRight" style={{ background: evolutionTagBg(firstDateCalcValue()?.totalEvolution) }}>
              {firstDateCalcValue()?.totalEvolutionFormatted}
            </span>

            <span className="totalLast">
              {firstDateCalcValue()?.totalValueLastWeekFormatted}
            </span>

            <span className="totalValue">
              {firstDateCalcValue()?.totalValueFormatted}
            </span>

            <span className="bold totalDiff" style={{ color: firstDateCalcValue()?.totalDiff > 0 ? "#F53D4C" : "#0B1A8E" }}>
              {firstDateCalcValue()?.totalDiffFormatted}
            </span>

            <span className="totalProvider">
              &nbsp;
            </span>
          </div>

          <div className="evolutionInfos">
            <span className="bold lightBlue fakeDatePicker">
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={ptLocale}
              >
                <KeyboardDatePicker
                  open={openSecondDate}
                  onOpen={() => setOpenSecondDate(true)}
                  onClose={() => setOpenSecondDate(false)}
                  className="dateSmall"
                  size="small"
                  id="time-picker"
                  placeholder="00/00/0000"
                  format="dd/MM"
                  ampm={false}
                  inputVariant="outlined"
                  clearable
                  fullWidth
                  autoOk
                  shouldDisableDate={disabledDays}
                  minDate={moment(firstDate).add(3, 'hours')}
                  value={moment(secondDate).add(3, 'hours')}
                  onChange={(value) => {
                    if (value) {
                      setSecondDate(value)
                      
                    }
                  }}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  KeyboardButtonProps={{
                    size: 'small',
                    'aria-label': 'change time',
                    style: { outline: 0 }
                  }}
                  TextFieldComponent={(params) => (
                    <TextField
                      {...params}
                      onClick={() => setOpenSecondDate(true)}
                    />
                  )}
                  PopoverProps={{ style: { outline: 0 } }}
                />
              </MuiPickersUtilsProvider>
            </span>

            <span className="totalCost">
              &nbsp;
            </span>
            
            <span className="evolutionTag customMarginRight" style={{ background: evolutionTagBg(secondDateCalcValue()?.totalEvolution) }}>
              {secondDateCalcValue()?.totalEvolutionFormatted}
            </span>

            <span className="totalLast">
              {secondDateCalcValue()?.totalValueLastWeekFormatted}
            </span>

            <span className="totalValue">
              {secondDateCalcValue()?.totalValueFormatted}
            </span>

            <span className="bold totalDiff" style={{ color: firstDateCalcValue()?.totalDiff > 0 ? "#F53D4C" : "#0B1A8E" }}>
              {secondDateCalcValue()?.totalDiffFormatted}
            </span>

            <span className="totalProvider">
              &nbsp;
            </span>
          </div>
        </section>

        <table className="desktop" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr className="headerTitle">
              <th
                width='18%'
                className="subtitle"
                onClick={() => {
                  setCurrentOrder('')
                }}
              >
                SKU{' '}

                <OrderBy
                  filterName="sku"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th
                width='2%'
                className="subtitle"
              >
                &nbsp;
              </th>

              <th
                width='2%'
                className="subtitle center"
              >
                UN.{' '}
              </th>

              <th
                width='9%'
                className="subtitle"
                onClick={() => {
                  setCurrentOrder('')
                }}
              >
                CATEGORIA{' '}
                <OrderBy
                  filterName="category"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th 
                style={{
                  borderLeft: 'solid 2px #AA8208',
                }} 
                width="4.5%" 
                className="subtitle right pr8"
              >
                QTDE{' '}
              </th>

              <th 
                width="5%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('first')
                }}
              >
                PREÇO R${' '}
                <OrderBy
                  filterName="cost"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th 
                width="5%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('first')
                }}
              >
                EVOL. %{' '}
                <OrderBy
                  filterName="evolution"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th 
                width="6%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('first')
                }}
              >
                ANTERIOR R${' '}
                <OrderBy
                  filterName="totalValueLastWeek"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th 
                width="5%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('first')
                }}
              >
                ATUAL R${' '}
                <OrderBy
                  filterName="totalValue"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th 
                width="5.5%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('first')
                }}
              >
                DIFERENÇA{' '}
                <OrderBy
                  filterName="difference"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th 
                width="2.5%" 
                className="subtitle center pr0"
              >
                <Tooltip
                  placement="bottom-start"
                  title='Fornecedores'
                >
                  <img style={{ width: '19px' }} src={ProviderIcon} alt="ProviderIcon" />
                </Tooltip>{' '}
              </th>

              <th
                style={{
                  borderLeft: 'solid 2px #00094C',
                }}
                width="4.5%" 
                className="subtitle right pr8"
              >
                QTDE{' '}
              </th>

              <th
                width="5%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('second')
                }}
              >
                PREÇO R${' '}
                <OrderBy
                  filterName="cost"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th
                width="5%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('second')
                }}
              >
                EVOL. %{' '}
                <OrderBy
                  filterName="evolution"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th
                width="6%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('second')
                }}
              >
                ANTERIOR R${' '}
                <OrderBy
                  filterName="totalValueLastWeek"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th
                width="5%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('second')
                }}
              >
                ATUAL R${' '}
                <OrderBy
                  filterName="totalValue"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>
              
              <th
                width="5.5%" 
                className="subtitle right pr8"
                onClick={() => {
                  setCurrentOrder('second')
                }}
              >
                DIFERENÇA{' '}
                <OrderBy
                  filterName="difference"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                  customMargin="0 0 0 5px"
                />
              </th>

              <th 
                width="2.5%" 
                className="subtitle center pl0 pr0"
              >
                <Tooltip
                  placement="bottom-start"
                  title='Fornecedores'
                >
                  <img style={{ width: '19px' }} src={ProviderIcon} alt="ProviderIcon" />
                </Tooltip>{' '}
              </th>
            </tr>
          </thead>

          <tbody>
            {mainLoading && (
              <tr>
                <th
                  colSpan='18'
                  className="m10 textAlignCenter"
                >
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {mainList?.length ? (
              mainList.map((sku) => {
                const firstDateFormatted = moment(firstDate).format('YYYY-MM-DD')
                const secondDateFormatted = moment(secondDate).format('YYYY-MM-DD')
                const firstDateEl = sku?.weeks?.find(el => el?.date === firstDateFormatted)
                const secondDateEl = sku?.weeks?.find(el => el?.date === secondDateFormatted)

                return (
                  <>
                    <tr className="lineBorder">
                      <td
                        className="description left pr8 pl12"
                        style={{ maxWidth: '195px', minWidth: '195px' }}
                      >
                        <div className="dFlex alignCenter w100">
                          <div className="dFlex alignCenter justifyBetween w100">
                            <span>
                              {ValidationLength(sku?.sku || '-', 25)}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className="right">
                        <div className="dFlex justifyCenter">
                          <ImageWrapper
                            width="30px"
                            height="28px"
                            url={sku?.image}
                          />
                        </div>
                      </td>

                      <td
                        className="description center"
                        style={{ maxWidth: '10px' }}
                      >
                        {sku?.unit || '-'}
                      </td>

                      <td
                        width="120"
                        style={{
                          maxWidth: '120px',
                          minWidth: '120px'
                        }}
                        className="description left"
                      >
                        {ValidationLength(sku?.category, 12)}
                      </td>

                      <td
                        className="description right pr8"
                        style={{
                          borderLeft: 'solid 2px #AA8208'
                        }}
                      >
                        {(firstDateEl?.quantity || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>

                      <td
                        className="description right pr8"
                      >
                        <Tooltip
                          title={
                            <div
                              className="dFlex flexColumn"
                              style={{ padding: '4px 2px' }}
                            >
                              <p className="mb0">
                                <b>Preço anterior:</b>{' '}
                                R$ {firstDateEl?.costLastWeek?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </p>

                              <p className="mb0">
                                <b>Data:</b>{' '}
                                {moment(firstDateEl?.lastPurchaseDate).format('DD/MM/YYYY')}
                              </p>
                            </div>
                          }
                          aria-label="add"
                        >
                          <span className="cursorPointer lightBlue">
                            {(firstDateEl?.cost || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </span>
                        </Tooltip>
                      </td>

                      <td
                        className="description right pr8"
                      >
                        <span className="evolutionTag" style={{ background: evolutionTagBg(firstDateEl?.evolution) }}>
                          {firstDateEl?.evolution
                            ? `${firstDateEl?.evolution?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`
                            : '-'    
                          }
                        </span>
                      </td>

                      <td
                        className="description right pr8"
                      >
                        {firstDateEl?.totalValueLastWeek
                          ? firstDateEl?.totalValueLastWeek?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          : '-'
                        }
                      </td>

                      <td
                        className="description right pr8"
                      >
                        {firstDateEl?.totalValue
                          ? firstDateEl?.totalValue?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          : '-'
                        }
                      </td>

                      <td className="description right pr8 bold" style={{ color: firstDateEl?.difference > 0 ? "#F53D4C" : "#0B1A8E" }}>
                        {firstDateEl?.difference
                          ? firstDateEl?.difference?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          : '-'
                        }
                      </td>

                      <td
                        className="description center"
                      >
                        <Tooltip
                          title={
                            <div
                              className="dFlex flexColumn"
                              style={{ padding: '4px 2px' }}
                            >
                              <p className="mb0">
                                <b>Última compra:</b>{' '}
                              </p>

                              <p className="mb0 bold">
                                {firstDateEl?.lastPurchaseProvider}
                              </p>

                              <p className="mb0">
                                <b>Data:</b>{' '}
                                {moment(firstDateEl?.lastPurchaseDate).format('DD/MM/YYYY')}
                              </p>

                              <div className="dFlex flexColumn mt20">
                                {firstDateEl?.providers?.map(provider => (
                                  <span className="bold mb0">
                                    {provider}
                                  </span>
                                ))}
                              </div>
                            </div>
                          }
                          aria-label="add"
                          disableHoverListener={!firstDateEl?.providerCount}
                        >
                          <span className="lightBlue cursorAuto">
                            {firstDateEl?.providerCount}
                          </span>
                        </Tooltip>
                      </td>

                      <td
                        className="description right pr8"
                        style={{
                          borderLeft: 'solid 2px #00094C'
                        }}
                      >
                        {(secondDateEl?.quantity || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      </td>

                      <td className="description right pr8">
                        <Tooltip
                          title={
                            <div
                              className="dFlex flexColumn"
                              style={{ padding: '4px 2px' }}
                            >
                              <p className="mb0">
                                <b>Preço anterior:</b>{' '}
                                R$ {secondDateEl?.costLastWeek?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </p>

                              <p className="mb0">
                                <b>Data:</b>{' '}
                                {moment(secondDateEl?.lastPurchaseDate).format('DD/MM/YYYY')}
                              </p>
                            </div>
                          }
                          aria-label="add"
                        >
                          <span className="cursorPointer lightBlue">
                            {(secondDateEl?.cost || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                          </span>
                        </Tooltip>
                      </td>

                      <td className="description right pr8">
                        <span className="evolutionTag" style={{ background: evolutionTagBg(secondDateEl?.evolution) }}>
                          {secondDateEl?.evolution
                            ? `${secondDateEl?.evolution?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`
                            : '-'    
                          }
                        </span>
                      </td>

                      <td className="description right pr8">
                        {secondDateEl?.totalValueLastWeek
                          ? secondDateEl?.totalValueLastWeek?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          : '-'
                        }
                      </td>

                      <td className="description right pr8">
                        {secondDateEl?.totalValue
                          ? secondDateEl?.totalValue?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          : '-'
                        }
                      </td>

                      <td className="description right pr8 bold" style={{ color: secondDateEl?.difference > 0 ? "#F53D4C" : "#0B1A8E" }}>
                        {secondDateEl?.difference
                          ? secondDateEl?.difference?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                          : '-'
                        }
                      </td>

                      <td
                        className="description center"
                      >
                        <Tooltip
                          title={
                            <div
                              className="dFlex flexColumn"
                              style={{ padding: '4px 2px' }}
                            >
                              <p className="mb0">
                                <b>Última compra:</b>{' '}
                              </p>

                              <p className="mb0 bold">
                                {secondDateEl?.lastPurchaseProvider}
                              </p>

                              <p className="mb0">
                                <b>Data:</b>{' '}
                                {moment(secondDateEl?.lastPurchaseDate).format('DD/MM/YYYY')}
                              </p>

                              <div className="dFlex flexColumn mt20">
                                {secondDateEl?.providers?.map(provider => (
                                  <span className="bold mb0">
                                    {provider}
                                  </span>
                                ))}
                              </div>
                            </div>
                          }
                          aria-label="add"
                          disableHoverListener={!secondDateEl?.providerCount}
                        >
                          <span className="lightBlue cursorAuto">
                            {secondDateEl?.providerCount}
                          </span>
                        </Tooltip>
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan='18'
                        className="m10 textAlignCenter"
                      >
                        <hr style={{ marginTop: '-1px' }} />
                      </td>
                    </tr>
                  </>
                )
              })
            ) : (
              <tr>
                <th
                  colSpan='18'
                  className="m10 textAlignCenter"
                >
                  Não há nenhum produto
                </th>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(event, nextPage) => {
              if (event) {
                setCallSettings({ ...callSettings, page: nextPage });
                getMainContentByGroup(nextPage, size)
              }
            }}
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>

        {modalSettings.find((modal) => modal.name === 'costTimeline')
          .open && (
          <CostTimeline
            open={
              modalSettings.find((modal) => modal.name === 'costTimeline')
                .open
            }
            product={
              modalSettings.find((modal) => modal.name === 'costTimeline')
                .product
            }
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'costTimeline'
                    ? { ...modal, open: false, product: null, item: null }
                    : modal
                )
              );
            }}
            restaurantId={getId}
          />
        )}

        {modalSettings.find((modal) => modal.name === 'skuModal').open &&
        selectedSku &&
        unitsMeasurements.length &&
        brands.length ? (
          <SkuModal
            fullScreen={
              modalSettings.find((modal) => modal.name === 'skuModal')
                .fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'skuModal').maxWidth
            }
            open={modalSettings.find((modal) => modal.name === 'skuModal').open}
            data={selectedSku?.providers || []}
            getId={getId}
            editMode
            sku={selectedSku}
            userInfo={userInfo}
            brands={brands}
            packageTypes={packageTypes}
            categories={categories}
            providers={providers}
            storageLocations={storageLocations}
            periodicities={periodicities}
            unitsMeasurements={unitsMeasurements}
            consumptionUnits={consumptionUnits}
            skus={[]}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'skuModal'
                    ? {
                        ...modal,
                        open: false
                      }
                    : modal
                )
              );

              setSelectedSku('');
            }}
            adminCatalogModal={isAdminPage}
            onlyViewMode
          />
        ) : null}

        {modalSettings.find((modal) => modal.name === 'providersAssociation')
          .open && (
          <ProvidersDialog
            fullScreen={
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).fullScreen
            }
            maxWidth={
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).maxWidth
            }
            open={
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).open
            }
            loading={loadingProviders}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'providersAssociation'
                    ? { ...modal, open: false, product: null, item: null }
                    : modal
                )
              );
              setDialogProvidersList([]);
              setSelectedProduct(null);
            }}
            title={
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).product
            }
            providers={dialogProvidersList}
          />
        )}

        {modalSettings.find((modal) => modal.name === 'cardsAssociation')
          .open && (
          <DialogCards
            fullScreen={
              modalSettings.find((modal) => modal.name === 'cardsAssociation')
                .fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'cardsAssociation')
                .maxWidth
            }
            open={
              modalSettings.find((modal) => modal.name === 'cardsAssociation')
                .open
            }
            onCloseFunc={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'cardsAssociation'
                    ? { ...modal, open: false, product: null, item: null }
                    : modal
                )
              );
            }}
            loading={loading}
            selectedCard={selectedCard}
            selectedProduct={selectedProduct}
            setSelectedCard={setSelectedCard}
            PaperComponent={PaperComponent}
            requestProps={{
              originId: getId,
              productDescription: modalSettings.find(
                (modal) => modal.name === 'cardsAssociation'
              ).product,
              items: modalSettings.find(
                (modal) => modal.name === 'cardsAssociation'
              ).items
            }}
            setRequestStorages={setRequestStorages}
            requestStorages={requestStorages}
          />
        )}

        {modalSettings.find((modal) => modal.name === 'productEdit')
          .open && (
          <Suspense fallback={<span />}>
            <DialogProducts
              fullScreen={
                modalSettings.find((modal) => modal.name === 'productEdit')
                  .fullScreen
              }
              maxWidth={
                modalSettings.find((modal) => modal.name === 'productEdit')
                  .maxWidth
              }
              open={modalSettings.find((modal) => modal.name === 'productEdit')
              .open}
              onCloseFunc={() => setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'productEdit' ? { ...modal, open: false } : modal
                )
              )}
              PaperComponent={PaperComponent}
              productData={productDataModal}
              setMessage={setMessage}
              message={message}
            />
          </Suspense>
        )}

        {modalStates?.modalProduct && (
          <Suspense fallback={<span />}>
            <TheoryStockDialog
              getId={getId}
              checkConsume={checkConsume}
              setCheckConsume={setCheckConsume}
              openModal={modalStates}
              handleClose={handleClose}
              productExtractDescription={productExtractDescription}
              productModal={productModal}
              setProductModal={setProductModal}
              parameters={parameters}
              originPage="productPage"
            />
          </Suspense>
        )}

        {selectedCard && (
          <TechnicalSheetDialog
            maxWidth={null}
            open={
              modalSettings.find((modal) => modal.name === 'technicalSheet')
                .open
            }
            handleClose={() =>
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'technicalSheet'
                    ? { ...modal, open: false }
                    : modal
                )
              )
            }
            cardDataTable={cardDataTable}
            cardTransformation={cardTransformation}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            ucPrimarySelectedId={ucPrimarySelectedId}
            setRequestStorages={setRequestStorages}
            requestStorages={requestStorages}
          />
        )}
      </Container>

      <CommonAlert
        open={message?.description}
        onClose={() => setMessage({ description: '', status: '' })}
        severity={message?.status}
        indexMessage={message?.description}
        messagePersonal={message?.description}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyEvolution);