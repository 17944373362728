const getSessionStorageId = () => {
    var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    return tabID;
}

const getLocalStorageDefault = (key, value) => {
    const localDefault = localStorage.getItem(key);
    if(value) {
        localStorage.setItem(key, JSON.stringify({
            value: value
        }));
        return false;
    }
    if (!localDefault) {
        if(!value) return;
        localStorage.setItem(key, JSON.stringify({
            value: value
        }));
        return false;
    };
    const item = JSON.parse(localDefault);
    return item.value;
}

const setLocalStorage = (key, value) => {
    const {keycloakId} = value
    const newKey = keycloakId ? key + keycloakId : key + getSessionStorageId()
    const now = new Date()

    const item = {
        value: value,
        expiry: now.getTime() + 5000,
        storageId: getSessionStorageId()
    }
    getLocalStorageDefault(key, value);
    localStorage.setItem(newKey, JSON.stringify(item));
}

const getLocalStorage = (key) => {
    const newKey = key + getSessionStorageId();
    const itemStr = localStorage.getItem(newKey)

    if (!itemStr) {
        const defaultLocal = getLocalStorageDefault(key)
        return defaultLocal;
    }

    const item = JSON.parse(itemStr)
    return item.value
}

const cleanCurrentLocalStorage = (key) => {
    const newKey = key + getSessionStorageId();
    localStorage.removeItem(newKey);
    return null;
}

const localStorageManager = {
    setLocalStorage,
    getLocalStorage,
    cleanCurrentLocalStorage,
}

export default localStorageManager;
