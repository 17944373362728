export function setCountId(id, origin, date, assortmentInfo, subtractDays) {
  return {
    type: 'SET_COUNT_ID',
    origin,
    id,
    date,
    assortmentInfo,
    subtractDays
  };
}
