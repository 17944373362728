/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import {
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  Dialog,
  Paper,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import Periodicity from 'data/periodicities';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import FullScreenLoading from 'components/FullScreenLoading';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as PeriodicitiesActions from 'store/actions/periodicities';
import { Container } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

const arrayDays = [
  {
    "description": "Todos"
  },
  {
    "id": 0,
    "description": "Domingo"
  },
  {
    "id": 1,
    "description": "Segunda"
  },
  {
    "id": 2,
    "description": "Terça"
  },
  {
    "id": 3,
    "description": "Quarta"
  },
  {
    "id": 4,
    "description": "Quinta"
  },
  {
    "id": 5,
    "description": "Sexta"
  },
  {
    "id": 6,
    "description": "Sábado"
  }
]

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-periodicity"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function PeriodicityForm({
  userInfo,
  PeriodicitiesInfo,
  redirectPath,
  isModal,
  onClose,
  setUpdateList,
  selectedItems,
  setSelectedItems,
  jsonBody,
  setJsonBody,
  openModal
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm();
  const [alertErr, setAlertErr] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [editMode, setEditMode] = useState(
    PeriodicitiesInfo?.action?.toLowerCase() === 'edit' || false
  );
  const [PeriodicitiesId, setPeriodicitiesId] = useState(
    editMode ? PeriodicitiesInfo.data.id : ''
  );
  const [description, setDescription] = useState(
    editMode ? PeriodicitiesInfo.data.description : ''
  );
  const [getOriginId, setOriginId] = useState(userInfo.companiesActive.id);
  const [getOriginName, setOriginName] = useState();
  const [query, setQuery] = useState('');
  const [arrayPeriodicity, setArrayPeriodicity] = useState([]);
  const [activeSwitch, setActiveSwitch] = useState(true);
  const [cmvSwitch, setCmvSwitch] = useState(editMode ? PeriodicitiesInfo.data.isCmvCount : false)
  const [validFields, setValidFields] = useState({
    days: false,
    weekDays: false,
    dayOneSelect: false,
    dayTwoSelect: false,
    dayOne: false,
    dayTwo: false
  });

  const [itemSemanaSelected1, setItemSemanaSelected1] = useState(
    editMode && PeriodicitiesInfo.data.first === 0
      ? {
        id: 0,
        description: 'Domingo'
      }
      : editMode && PeriodicitiesInfo.data.first === 1
        ? {
          id: 1,
          description: 'Segunda'
        }
        : editMode && PeriodicitiesInfo.data.first === 2
          ? {
            id: 2,
            description: 'Terça'
          }
          : editMode && PeriodicitiesInfo.data.first === 3
            ? {
              id: 3,
              description: 'Quarta'
            }
            : editMode && PeriodicitiesInfo.data.first === 4
              ? {
                id: 4,
                description: 'Quinta'
              }
              : editMode && PeriodicitiesInfo.data.first === 5
                ? {
                  id: 5,
                  description: 'Sexta'
                }
                : editMode && PeriodicitiesInfo.data.first === 6
                  ? {
                    id: 6,
                    description: 'Sabado'
                  }
                  : ''
  );

  const [itemSemanaSelected2, setItemSemanaSelected2] = useState(
    editMode && PeriodicitiesInfo.data.second === 0
      ? {
        id: 0,
        description: 'Domingo'
      }
      : editMode && PeriodicitiesInfo.data.second === 1
        ? {
          id: 1,
          description: 'Segunda'
        }
        : editMode && PeriodicitiesInfo.data.second === 2
          ? {
            id: 2,
            description: 'Terça'
          }
          : editMode && PeriodicitiesInfo.data.second === 3
            ? {
              id: 3,
              description: 'Quarta'
            }
            : editMode && PeriodicitiesInfo.data.second === 4
              ? {
                id: 4,
                description: 'Quinta'
              }
              : editMode && PeriodicitiesInfo.data.second === 5
                ? {
                  id: 5,
                  description: 'Sexta'
                }
                : editMode && PeriodicitiesInfo.data.second === 6
                  ? {
                    id: 6,
                    description: 'Sabado'
                  }
                  : ''
  );

  const [itemSemanaSelected3, setItemSemanaSelected3] = useState(
    editMode && PeriodicitiesInfo.data.type === 'BIMONTHLY'
      ? {
        id: 1,
        description: 'Bimensal',
        label: 'BIMONTHLY'
      }
      : editMode && PeriodicitiesInfo.data.type === 'BIWEEKLY'
        ? {
          id: 2,
          description: 'Bissemanal',
          label: 'BIWEEKLY'
        }
        : editMode && PeriodicitiesInfo.data.type === 'DIARY'
          ? {
            id: 3,
            description: 'Diario',
            label: 'DIARY'
          }
          : editMode && PeriodicitiesInfo.data.type === 'MONTHLY'
            ? {
              id: 4,
              description: 'Mensal',
              label: 'MONTHLY'
            }
            : editMode && PeriodicitiesInfo.data.type === 'WEEKLY'
              ? {
                id: 5,
                description: 'Semanal',
                label: 'WEEKLY'
              }
              : editMode && PeriodicitiesInfo.data.type === 'FREE_WEEKLY'
                ? {
                  id: 6,
                  description: 'Semanal Livre',
                  label: 'FREE_WEEKLY'
                }
                : editMode && PeriodicitiesInfo.data.type === 'FREE_MONTHLY'
                  ? {
                    id: 0,
                    description: 'Mensal Livre',
                    label: 'FREE_MONTHLY'
                  }
                  : ''
  );

  const [diary, setDiary] = useState('false');
  const [dia1, setDia1] = useState(
    editMode ? PeriodicitiesInfo.data.first : ''
  );
  const [dia2, setDia2] = useState(
    editMode && PeriodicitiesInfo.data.second
      ? PeriodicitiesInfo.data.second
      : ''
  );
  const [freeWeeklyDays, setFreeWeeklyDays] = useState(
    editMode && PeriodicitiesInfo.data.type === 'FREE_WEEKLY'
      ? PeriodicitiesInfo.data.days
        .split(',')
        .map(
          (day) => arrayDays.filter((item) => item.id === parseInt(day))[0]
        )
      : []
  );
  const [freeMonthlyDays, setFreeMonthlyDays] = useState(
    editMode && PeriodicitiesInfo.data.type === 'FREE_MONTHLY'
      ? PeriodicitiesInfo.data.days.split(',').map((day) => parseInt(day))
      : []
  );
  const freeMonthDayOptions = Array.from({ length: 31 }, (_, i) =>
    (i + 1).toString()
  );

  const validateRequiredFields = () => {
    const currentLabel = itemSemanaSelected3?.label

    if (currentLabel === 'DIARY') {
      return true
    }

    if (currentLabel === 'BIMONTHLY') {
      setValidFields({
        ...validFields,
        dayOne: !dia1,
        dayTwo: !dia2
      })

      return dia1 && dia2
    }

    if (currentLabel === 'BIWEEKLY') {
      setValidFields({
        ...validFields,
        dayOneSelect: !itemSemanaSelected1?.description,
        dayTwoSelect: !itemSemanaSelected2?.description
      })

      return itemSemanaSelected1?.description && itemSemanaSelected2?.description
    }

    if (currentLabel === 'MONTHLY') {
      setValidFields({
        ...validFields,
        dayOne: !dia1,
      })

      return dia1
    }

    if (currentLabel === 'WEEKLY') {
      setValidFields({
        ...validFields,
        dayOneSelect: !itemSemanaSelected1?.description,
      })

      return itemSemanaSelected1?.description
    }

    if (currentLabel === 'FREE_WEEKLY') {
      setValidFields({
        ...validFields,
        days: !freeWeeklyDays?.length,
      })

      return freeWeeklyDays?.length
    }

    if (currentLabel === 'FREE_MONTHLY') {
      setValidFields({
        ...validFields,
        weekDays: !freeMonthlyDays?.length,
      })

      return freeMonthlyDays?.length
    }
  }

  const convertStatus = (value) => {
    if (value === 'ACTIVE') return true;
    if (value === 'INACTIVE') return false;
    if (value === true) return 'ACTIVE';
    if (value === false) return 'INACTIVE';
  };

  const onSubmitPeriodicity = (values) => {
    let first = null;
    let second = null;
    let free = [];

    switch (itemSemanaSelected3.label) {
      case 'BIWEEKLY':
        first = itemSemanaSelected1?.id;
        second = itemSemanaSelected2?.id;
        break;
      case 'BIMONTHLY':
        first = dia1;
        second = dia2;
        break;
      case 'WEEKLY':
        first = itemSemanaSelected1?.id;
        break;
      case 'MONTHLY':
        first = dia1;
        break;
      case 'DIARY':
        setDiary('true');
        break;
      case 'FREE_WEEKLY':
        free = freeWeeklyDays.map((day) => day?.id);
        break;
      case 'FREE_MONTHLY':
        free = freeMonthlyDays.map((day) => parseInt(day));
        break;
      default:
        break;
    }

    if (!validateRequiredFields()) return null

    setLoading(true);

    const headers = {
      headers: {
        User: `${userLocal?.user}`
      }
    };

    if (editMode) {
      axios
        .put(
          `${environments.catalog}/periodicities/${PeriodicitiesId}`,
          {
            method: 'PUT',
            description,
            restaurantId: userInfo.companiesActive.id,
            type: itemSemanaSelected3.label,
            days: free,
            status: convertStatus(activeSwitch),
            diary,
            first: parseInt(first),
            second: parseInt(second),
            user: userInfo.user,
            isCmvCount: cmvSwitch
          },
          headers
        )
        .then((res) => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
          // const { data } = res
        })
        .catch((error) => {
          setLoading(false);
          setAlertErr(true);
          setMessageError(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setUpdateList && setUpdateList(false);
        });
    } else {
      axios
        .post(
          `${environments.catalog}/periodicities`,
          {
            method: 'POST',
            description,
            restaurantId: userInfo.companiesActive.id,
            type: itemSemanaSelected3.label,
            days: free,
            diary,
            first: parseInt(first),
            second: parseInt(second),
            user: userInfo.user,
            isCmvCount: cmvSwitch
          },
          headers
        )
        .then((response) => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);

          if (isModal && jsonBody) {
            setSelectedItems({
              ...selectedItems,
              product: {
                ...selectedItems?.product,
                frequencies: [
                  {
                    days: response?.data?.days,
                    description: response?.data?.description,
                    first: response?.data?.first,
                    id: response?.data?.id,
                    second: response?.data?.second,
                    type: response?.data?.type
                  }
                ]
              }
            });

            setJsonBody({
              ...jsonBody,
              product: {
                ...jsonBody.product,
                frequencies: [
                  {
                    id: response?.data?.id,
                    description: response?.data?.description,
                    type: response?.data?.type
                  }
                ]
              }
            });
          }

          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          setMessageError(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    }
  };

  // Seta Origin ID e Name
  useEffect(() => {
    setOriginId(userInfo.companiesActive.id);
    setOriginName(userInfo.companiesActive.name);
    setArrayPeriodicity(Periodicity);
    setActiveSwitch(convertStatus(PeriodicitiesInfo?.data?.status));
  }, []);

  const handleQuery = (event) => {
    setQuery(event.target.value);
  };

  const handleClose = (reason) => {
    if (redirectPath !== null) {
      history.push(redirectPath);
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Dialog
      className="defaultModal periodicityRegisterDialog"
      fullWidth
      open={openModal}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title-periodicity"
      transitionDuration={0}
    >
      <Container className="w100">
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          style={{ borderRadius: '4px 4px 0 0' }}
          id="draggable-dialog-title-periodicity"
        >
          <Typography>CADASTRO DE CONTAGEM</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={(e) => {
              onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        {alertErr ? (
          <CommonAlert
            open={alertErr}
            onClose={() => setAlertErr(false)}
            indexMessage={messageError}
            statusCode={statusCode}
            width="50%"
          />
        ) : null}

        <FullScreenLoading display={loading} description="Salvando" />

        <div className="cardDefault pt16" style={{ borderRadius: '0' }}>
          <form onSubmit={handleSubmit(onSubmitPeriodicity)}>
            <Grid className="m0" container xs={12} sm={12} spacing={4}>
              <Grid item xs={3} sm={3} className="pl0">
                <TextField
                  size="small"
                  label="Descrição"
                  onChange={(e) => setDescription(e.target.value)}
                  name="description"
                  variant="outlined"
                  defaultValue={
                    editMode == true ? PeriodicitiesInfo.data.description : null
                  }
                  fullWidth
                  required="required"
                  inputProps={{ maxLength: 50 }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <Autocomplete
                  getOptionSelected={(arrayPeriodicity, value) =>
                    arrayPeriodicity.label === value.label
                  }
                  getOptionLabel={(arrayPeriodicity) =>
                    arrayPeriodicity.description
                  }
                  options={arrayPeriodicity}
                  size="small"
                  fullWidth
                  value={itemSemanaSelected3}
                  onChange={(event, itemSemanaSelected3) => {
                    itemSemanaSelected3 != null
                      ? setItemSemanaSelected3(itemSemanaSelected3)
                      : setItemSemanaSelected3('');
                  }}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="type"
                      label="Tipo"
                      onChange={(event) => handleQuery(event)}
                      variant="outlined"
                      required="required"
                      placeholder="Digite aqui..."
                      style={{ width: 150 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>

              <Grid
                className={`${validFields?.dayOne && 'labelError'}`}
                item
                xs={3}
                sm={3}
                style={
                  itemSemanaSelected3.label !== 'MONTHLY' &&
                    itemSemanaSelected3.label !== 'BIMONTHLY'
                    ? { display: 'none' }
                    : null
                }
              >
                <TextField
                  size="small"
                  onChange={(e) => setDia1(e.target.value)}
                  name="dia1"
                  label="Dia 1"
                  variant="outlined"
                  defaultValue={
                    editMode == true ? PeriodicitiesInfo.data.first : null
                  }
                  value={dia1}
                  type="number"
                  fullWidth
                  required={
                    itemSemanaSelected3.label !== 'MONTHLY' &&
                      itemSemanaSelected3.label !== 'BIMONTHLY'
                      ? false
                      : 'required'
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  inputProps={{ min: '1', max: '31', step: '1', maxLength: 2 }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid
                className={`${validFields?.dayTwo && 'labelError'}`}
                item
                xs={3}
                sm={3}
                style={
                  itemSemanaSelected3.label !== 'BIMONTHLY'
                    ? { display: 'none' }
                    : null
                }
              >
                <TextField
                  size="small"
                  onChange={(e) => setDia2(e.target.value)}
                  name="dia2"
                  label="Dia 2"
                  variant="outlined"
                  defaultValue={
                    editMode == true ? PeriodicitiesInfo.data.second : null
                  }
                  value={dia2}
                  type="number"
                  fullWidth
                  required={
                    itemSemanaSelected3.label !== 'BIMONTHLY'
                      ? false
                      : 'required'
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  inputProps={{ min: '1', max: '31', step: '1', maxLength: 2 }}
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid
                className={`${validFields?.dayOneSelect && 'labelError'}`}
                item
                xs={3}
                sm={3}
                style={
                  itemSemanaSelected3.label !== 'WEEKLY' &&
                    itemSemanaSelected3.label !== 'BIWEEKLY'
                    ? { display: 'none' }
                    : null
                }
              >
                <Autocomplete
                  getOptionSelected={(option, value) =>
                    option.description === value.description
                  }
                  getOptionLabel={(option) => option.description}
                  options={arrayDays.filter(
                    (item) => item.description !== 'Todos'
                  )}
                  size="small"
                  value={itemSemanaSelected1}
                  onChange={(event, itemSemanaSelected1) => {
                    setItemSemanaSelected1(itemSemanaSelected1);
                  }}
                  fullWidth
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="type"
                      label="Dia 1"
                      onChange={(event) => handleQuery(event)}
                      variant="outlined"
                      required={
                        itemSemanaSelected3.label !== 'WEEKLY' &&
                          itemSemanaSelected3.label !== 'BIWEEKLY'
                          ? false
                          : 'required'
                      }
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>

              <Grid
                className={`${validFields?.dayTwoSelect && 'labelError'}`}
                item
                xs={3}
                sm={3}
                style={
                  itemSemanaSelected3.label !== 'BIWEEKLY'
                    ? { display: 'none' }
                    : null
                }
              >
                <Autocomplete
                  getOptionSelected={(option, value) =>
                    option.description === value.description
                  }
                  getOptionLabel={(option) => option.description}
                  options={arrayDays}
                  size="small"
                  fullWidth
                  value={itemSemanaSelected2}
                  onChange={(event, itemSemanaSelected2) => {
                    setItemSemanaSelected2(itemSemanaSelected2);
                  }}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="type"
                      label="Dia 2"
                      onChange={(event) => handleQuery(event)}
                      variant="outlined"
                      required={
                        itemSemanaSelected3.label !== 'BIWEEKLY'
                          ? false
                          : 'required'
                      }
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>

              <Grid
                className={`${validFields?.days && 'labelError'}`}
                item
                xs={5}
                sm={5}
                style={
                  itemSemanaSelected3.label !== 'FREE_WEEKLY'
                    ? { display: 'none' }
                    : null
                }
              >
                <Autocomplete
                  getOptionSelected={(option, value) =>
                    option.description === value.description
                  }
                  getOptionLabel={(option) => option.description}
                  options={arrayDays}
                  size="small"
                  fullWidth
                  multiple
                  limitTags={4}
                  value={freeWeeklyDays}
                  required={
                    itemSemanaSelected3.label !== 'FREE_WEEKLY'
                      ? false
                      : 'required'
                  }
                  onChange={(event, value) => {
                    if (value.find((item) => item.description === 'Todos')) {
                      setFreeWeeklyDays(
                        arrayDays.filter((item) => item.description !== 'Todos')
                      );
                    } else {
                      setFreeWeeklyDays(value);
                    }
                  }}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="type"
                      label="Dias"
                      variant="outlined"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>

              <Grid
                className={`${validFields?.weekDays && 'labelError'}`}
                item
                xs={5}
                sm={5}
                style={
                  itemSemanaSelected3.label !== 'FREE_MONTHLY'
                    ? { display: 'none' }
                    : null
                }
              >
                <Autocomplete
                  getOptionSelected={(option, value) => option === value}
                  getOptionLabel={(option) => option}
                  options={freeMonthDayOptions}
                  multiple
                  limitTags={10}
                  size="small"
                  fullWidth
                  value={freeMonthlyDays}
                  required={
                    itemSemanaSelected3.label !== 'FREE_MONTHLY'
                      ? false
                      : 'required'
                  }
                  onChange={(event, value) => {
                    setFreeMonthlyDays(value);
                  }}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="type"
                      label="Dias"
                      variant="outlined"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              className="m0"
              container
              xs={12}
              sm={12}
              spacing={4}
            >
              <Grid
                xs={2}
              >
                <FormControlLabel
                  className="toggleDefault"
                  control={
                    <Switch
                      onChange={() => {
                        setCmvSwitch(!cmvSwitch)
                      }}
                      name="isCmvCount"
                      checked={cmvSwitch}
                    />
                  }
                  label="CMV"
                />
              </Grid>

              {editMode && (
                <Grid
                  xs={2}
                >
                  <FormControlLabel
                    className="toggleDefault"
                    control={
                      <Switch
                        onChange={() => {
                          setActiveSwitch(!activeSwitch);
                        }}
                        name="draft"
                        checked={activeSwitch}
                      />
                    }
                    label="Ativo"
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </div>

        <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Cadastro realizado com sucesso!
          </Alert>
        </Snackbar>

        <Grid
          className="dFlex justifyEnd bgWhite"
          item
          xs={12}
          sm={12}
          style={{
            padding: '0 16px 16px',
            borderRadius: '0 0 4px 4px'
          }}
        >
          {!isModal && (
            <Link to="/inventario/periodicidade/">
              <Button
                className="defaultButton backButton"
                design="outlined"
                label="Voltar"
                style={{ marginRight: '20px' }}
              />
            </Link>
          )}

          <Button
            className="defaultButton submitButton"
            type="submit"
            label="Salvar"
            design="contained"
            onClick={handleSubmit(onSubmitPeriodicity)}
          />
        </Grid>
      </Container>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  PeriodicitiesInfo: state.periodicities
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(PeriodicitiesActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PeriodicityForm);
