import styled from 'styled-components';

export const DateInputContainer = styled.div`
  margin-right: 35px;

  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
  label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #00020f;
  }
  input {
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: #77798b;

    &::placeholder {
      font-style: italic;
    }
  }
`;
