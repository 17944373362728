export const addUniqueIdAutoComplete = (appendName = 'bloom') => {
  setTimeout(() => {
    const autocompletePopper = document.getElementsByClassName(
      'MuiAutocomplete-popper'
    );

    if (autocompletePopper?.length) {
      const liTagLIst = autocompletePopper[0].getElementsByTagName('li');

      if (!liTagLIst) return;

      for (let index = 0; index < liTagLIst.length; index++) {
        const element = liTagLIst[index];
        element.setAttribute('data-bloom-id', `${appendName}-${index}`);
      }
    }
  }, 550);
};
