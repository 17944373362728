import React from 'react';
import { StyledButton } from './index.style';

const Button = ({
    label, 
    primary, 
    secondary, 
    redlink, 
    secondaryDisabled,
    redlinkDisabled,
    onClick = () => {}, 
    disable = false,
    width = null,
    marginOff = false,
    id = "",
}) => {

    return (
        <StyledButton 
            primary={primary}
            secondary={secondary}
            secondaryDisabled={secondaryDisabled}
            redlink={redlink}
            redlinkDisabled={redlinkDisabled}
            onClick={(event, value) => {
                if(disable) return;
                onClick(event, value);
            }}
            disable={disable}
            width={width}
            marginOff={marginOff}
            id={id}
        >
            {label}
        </StyledButton>
    );
}

export default Button;
