import styled from 'styled-components'

export const ContainerSales = styled.div`
    width: 480px;
    height: 95px;
    border-radius: 8px;
    margin: 0px 0px 0px 0px;
    box-shadow: 0px 1px 4px rgb(1 3 17 / 45%);
`
export const YourSalesContainer = styled.div`
    width: 263px;
    height: 100%;
    padding: 8px;
    float: left;
`
export const BloomContainer = styled.div`
    width: 115px;
    height: 100%;
    background-color: #5062f0;
    padding: 6px;
    float: left;
`
export const YourPotentialContainer = styled.div`
    width: 100px;
    height: 100%;
    padding: 8px;
    float: left;
`
export const Title = styled.p`
    font-size: ${({ size }) => size ? size : '12px'};
    text-align: ${({ align }) => align ? align : 'start'};
    color: ${({ color }) => color ? color : 'black'};
    margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '0px'};
    line-height: 16px;
    font-weight: 700;
`
export const Row = styled.div`
    display: flex;
`
export const Subtitle = styled.p`
    font-size: 9px;
    line-height: 12px;
    font-weight: 700;
    width: 40px;
    color: #9296ac;
    margin-right: 8px;
    margin-top: 4px;
`
export const Values = styled.p`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    width: 88px;
    color: ${({ color }) => color ? color : '#010311'};
    text-align: end;
`
export const Line = styled.hr`
    height: 1px;
    width: ${({ width }) => width ? width : '134px'};
    color: ${({ color }) => color ? color : '#e6e7f0'};
    margin-top: 0px;
    margin-left: 0px;
    margin-bottom: 8px;
    margin-right: 0px;
`
export const BorderPercent = styled.div`
    width: 110px;
    height: 21px;
    border: solid 1px #5062f0;
    border-radius: 16px;
    margin-top: -3px;
    margin-left: 8px;
`
export const PercentFill = styled.div`
    height: 100%;
    width: ${({ width }) => width ? width : '0%'};
    border-radius: 16px;
    background-color: #1f2445;
    transition: width 1s ease-in-out;
`
export const PercentText = styled.p`
    font-size: 9px;
    font-weight: 500;
    line-height: 19px;
    color: white;
    text-align: end;
    vertical-align: middle;
    margin-right: 2px;
`
export const BloomIcon = styled.img`
    width: 12px;
    margin-top: -2px;
    margin-right: 2px;
`