import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { Html5QrcodeScanner } from 'html5-qrcode';
import Axios from 'axios';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';

const environments = Environment(process.env.REACT_APP_ENV);

function DialogNfce({
  userInfo,
  openModal,
  handleCloseModal,
  PaperComponent,
  alertNfce,
  setAlertNfce,
  setInputKey,
  setRequestValid,
  setDataReceives,
  setModalSettings,
  modalSettings,
  setNfceType
}) {
  const [data, setData] = useState();
  const [typeFormatScanner, setTypeFormatScanner] = useState({
    width: 250,
    height: 250,
    type: 'qrCode'
  });
  const restaurantId = userInfo?.companiesActive?.id;

  const cancelQrCode = () => {
    handleCloseModal();
    setData('');
  };

  const getQrCode = () => {
    Axios.get(`${environments.integration}/nfce?qrCode=${data}&state=RJ&restaurantId=${restaurantId}`)
      .then((res) => {
        if (res.data.message) {
          setNfceType({valid: true, code: data})
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'findNoteOnSefazModal'
                ? { ...modal, open: true }
                : modal
            )
          );
          setAlertNfce({
            message: 'Nota não encontrada em nossa base de dados',
            status: 'error'
          });
        } else {
          setDataReceives([res.data])
          setAlertNfce({
            message: 'Nota integrada com sucesso',
            status: 'success'
          });
        }
        
      })
      .catch((error) => {
        setAlertNfce({
          message: 'Erro ao enviar nota',
          status: 'error'
        });
      })
      .finally(cancelQrCode);
  };

  useEffect(() => {
    if (openModal) {
      // Salva o método console.warn original
      const originalWarn = console.warn;

      // Substitui console.warn para suprimir mensagens específicas
      console.warn = (message, ...optionalParams) => {
        if (typeof message === "string" && message.includes("No MultiFormat Readers were able to detect the code")) {
          return; // Ignora mensagens com esse texto
        }
        originalWarn(message, ...optionalParams);
      };

      let scanner;

      const initializeScanner = () => {
        const element = document.getElementById("reader");
        if (element) {
          scanner = new Html5QrcodeScanner("reader", {
            qrbox: { width: typeFormatScanner.width, height: typeFormatScanner.height },
            fps: 5,
            facingMode: "environment",
            verbose: true
          });

          scanner.render(success, error);

          function success(result) {
            scanner.clear();
            setData(result);
          }

          function error(err) {
            // Você pode tratar outros erros aqui, se necessário
          }
        }
      };

      const timeoutId = setTimeout(initializeScanner, 1000);

      // Limpa o scanner e restaura o console.warn original ao desmontar o componente ou alterar o scanner
      return () => {
        clearTimeout(timeoutId);
        console.warn = originalWarn;
        if (scanner) scanner.clear();
      };
    }
  }, [openModal, typeFormatScanner]);

  useEffect(() => {
    if (data?.length === 44) {
      setInputKey(data);
      cancelQrCode();
      setRequestValid(true);
    } else if (data) {
      getQrCode();
      cancelQrCode();
    }
  }, [data]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={openModal}
      onClose={cancelQrCode}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Escanear Nota</Typography>
        <IconButton edge="start" color="inherit" onClick={cancelQrCode}>
          <img style={{ width: '16px' }} src={CloseIconNavy} alt="CloseIconNavy" />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent p12">
        <Grid container spacing={6} className="w100 alignCenter justifyCenter m0">
          {!data &&
            <div
              style={{ border: '1px solid black', margin: '10px 0' }}
              className="w100 qr-scanner"
            >
              <div id="reader" />
            </div>
          }
        </Grid>

        <Grid container spacing={6} className="w100 m0">
          <div style={{ gap: '10px' }} className="w100 dFlex alignCenter justifyCenter pb8">
            <Button
              variant="contained"
              style={{
                background: typeFormatScanner.type === 'qrCode' ? '#5062F0' : '#AFBCF3',
                color: '#FFFFFF',
                borderRadius: '8px',
                textTransform: 'initial',
                fontFamily: 'Lato',
                height: '40px'
              }}
              fullWidth
              disabled={typeFormatScanner.type === 'qrCode'}
              onClick={() => setTypeFormatScanner({ width: 250, height: 250, type: 'qrCode' })}
            >
              QrCode
            </Button>
            <Button
              variant="contained"
              style={{
                background: typeFormatScanner.type === 'barCode' ? '#5062F0' : '#AFBCF3',
                color: '#FFFFFF',
                borderRadius: '8px',
                textTransform: 'initial',
                fontFamily: 'Lato',
                height: '40px'
              }}
              fullWidth
              disabled={typeFormatScanner.type === 'barCode'}
              onClick={() => setTypeFormatScanner({ width: 250, height: 90, type: 'barCode' })}
            >
              Código de Barras
            </Button>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default DialogNfce;
