/* eslint-disable no-nested-ternary */
import React from 'react';

import { format } from 'date-fns';
import moment from 'moment';

function StockRow({ item, extractItems, parameters, productModal, isMobileScreen }) {
  const count = extractItems.find(
    (itemToFind) =>
      itemToFind.type === 'COUNT' &&
      itemToFind.date ===
        format(new Date(new Date().valueOf() - 86400000), 'yyyy-MM-dd')
  );

  const countItem =
    item.type === 'Estoque' &&
    moment(item.date).isSame(moment().subtract(1, 'day'), 'day')
      ? item
      : null;

  if (count && countItem !== null) {
    return (
      <tr>
        <td className="description pr12 pl16">
          ({format(new Date(`${countItem.date}T03:00:00.000Z`), 'dd/MM/yyyy')}){' '}

          {countItem.type}:{' '}

          {countItem.type === 'Estoque' && countItem.cards
            ? countItem.cards.description
            : ''
          }
        </td>

        <td className="description right p12">
          <span style={{ marginLeft: '5px' }}>
            +
            {countItem.quantity
              ? parseFloat(countItem.quantity).toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })
              : '0,000'
            }{' '}

            {productModal.consumptionUnitsPrimary &&
              productModal.consumptionUnitsPrimary.abbreviation
            }
          </span>
        </td>

        <td className="description right pl12 pr16" />

        {!isMobileScreen &&
          <td className="description right pl12 pr16" />
        }
      </tr>
    );
  }

  return null;
}

export default StockRow;
