import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makeGet from 'services/useFetch/makeGet';
import { shortenString } from 'utils/shortenString';

import { SkuTagBody, SkuTagDiv, SkuTagThead } from './style';

function SkuTagDialog({
  openSkuTagDialog,
  setOpenSkuTagDialog,
  tagId,
  tagName
}) {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setLoading(true);
    function handleResponse(response) {
      if (response) {
        setItems(response);
        setLoading(false);
        return;
      }
      setLoading(false);
    }

    makeGet('tagCreation', [], handleResponse, `/${tagId}/skus`);
  }, []);

  const handleClose = () => {
    setOpenSkuTagDialog(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={openSkuTagDialog}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="modalHeader navyBlue"
        style={{ minWidth: '550px', margin: '0', padding: '0' }}
        id="draggable-dialog-title"
      >
        <Typography
          style={{
            fontSize: '9px',
            fontWeight: '700'
          }}
        >
          SKUS da TAG {tagName}
        </Typography>
        <IconButton
          onClick={() => setOpenSkuTagDialog(false)}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>
      <SkuTagDiv>
        <table>
          <SkuTagThead>
            <tr>
              <th style={{ paddingLeft: '12px' }}>SUB</th>
              <th style={{ padding: '0 10px' }}>PRODUTO</th>
              <th>SKU</th>
            </tr>
          </SkuTagThead>
          {loading && (
            <tr>
              <th colSpan="3" style={{ margin: '10px', textAlign: 'center' }}>
                <LinearProgress id="loading" variant="query" />
              </th>
            </tr>
          )}
          {items.length == 0 ? (
            <tr>
              <th colSpan="3" style={{ margin: '10px', textAlign: 'center' }}>
                <Typography style={{ fontSize: '12px' }}>
                  Não há nenhum SKU
                </Typography>
              </th>
            </tr>
          ) : (
            <SkuTagBody>
              {items?.map((data) => (
                <tr key={data?.id}>
                  <td>{data?.descriptionCategory}</td>
                  <td style={{ padding: '0 10px' }}>
                    {shortenString(data?.descriptionProduct, 25)}
                  </td>
                  <td>{shortenString(data?.descriptionSku, 25)}</td>
                </tr>
              ))}
            </SkuTagBody>
          )}
        </table>
      </SkuTagDiv>
    </Dialog>
  );
}

export default SkuTagDialog;
