/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Paper,
  Tooltip,
  Fade,
  CircularProgress
} from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment/moment';
import Draggable from 'react-draggable';

import ImageWrapper from 'components/ImageWrapper';
import StockInOutIcon from 'images/icons/objects/clocks/clockArrowBackLightBlueOutline.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

function setColor(percentage, lossPercentage, isFirst, diff, consumed) {
  const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];
  let color;

  if (isFirst)
    color =
      percentage >= range[0] && percentage <= range[1]
        ? '#D2F9D6'
        : percentage >= lossPercentage
          ? '#FFF2CC'
          : '#F7D4D7';
  else
    color =
      percentage >= range[0] && percentage <= range[1]
        ? '#006608'
        : percentage >= lossPercentage
          ? '#AA8208'
          : '#F53D4C';

  if (!consumed) {
    if (isFirst)
      color = diff == 0 ? '#D2F9D6' : diff > 0 ? '#FFF2CC' : '#F7D4D7';
    else color = diff == 0 ? '#006608' : diff > 0 ? '#AA8208' : '#F53D4C';
  }

  return color;
}

function validateNumber(number) {
  if (number === '-0,000') number = number.replace('-', '');

  return number;
}

function StockDetailedDialog({
  modalSettings,
  setModalSettings,
  currentProduct,
  originId,
  handleGetExtract,
  consumedInOutInfos,
  loadingInOutInfos,
  setLoadingInOutInfos
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [show, setShow] = useState(false);
  const [customProductInfos, setCustomProductInfos] = useState()
  const unitsMeasurements = currentProduct?.consumptionUnitsPrimary?.abbreviation;
  const lossPercentage = currentProduct?.lossPercentage
    ? parseFloat(currentProduct?.lossPercentage)
    : 0;
  const theoreticalStock =
    currentProduct?.theoreticalStock || currentProduct?.theoricalStock || 0;
  const difference =
    !currentProduct?.productId
      ? currentProduct?.realStock !== null ||
        currentProduct?.realStock !== undefined ||
        currentProduct?.otherStock !== null ||
        currentProduct?.otherStock !== undefined
          ? (currentProduct?.otherStock 
            ? parseFloat(Number(currentProduct?.otherStock)) : 0) 
            + (currentProduct?.realStock ? parseFloat(Number(currentProduct?.realStock)) : 0) 
            - (currentProduct?.theoricalStock ? parseFloat(Number(currentProduct?.theoricalStock)) : 0)
          : null
      : currentProduct?.realStock !== null || currentProduct?.realStock !== undefined
        ? (currentProduct?.realStock ? parseFloat(Number(currentProduct?.realStock)) : 0) -
          (theoreticalStock ? parseFloat(Number(theoreticalStock)) : 0)
        : null;
  const percentage = currentProduct?.consumed
    ? (difference / parseFloat(Number(currentProduct?.consumed))) * 100
    : 0;
  const percent = percentage
    ? percentage.toLocaleString('pt-br', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
    : '0,00';
  const differenceInReal =
    typeof parseFloat(currentProduct?.realStock) === 'number'
      ? Number(difference) * Number(currentProduct?.cost)
      : null;
  const parsedDifferenceInReal = differenceInReal
    ? differenceInReal.toLocaleString('pt-br', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
    : '0,000';
  const diff = difference
    ? difference.toLocaleString('pt-br', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    })
    : '0,000';

  useEffect(() => {
    if (currentProduct?.skus) {
      for (const sku of currentProduct?.skus) {
        for (const provider of sku.providers) {
          if (provider.stock != null && !isNaN(provider.stock)) {
            setShow(true);
          } else {
            setShow(false);
          }
        }
      }
    }
  }, [currentProduct])

  useEffect(() => {
    const productId = currentProduct?.id || currentProduct?.productId

    if (currentProduct?.productId) {
      Axios
        .get(
          `${environments.catalog}/products/${productId}?originId=${originId}`
        )
        .then((response) => {
          setCustomProductInfos({
            image: response.data?.image?.url,
            type: response.data?.type
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [])

  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'stockDetailedDialog')
          .fullScreen
      }
      open={
        modalSettings.find((modal) => modal.name === 'stockDetailedDialog').open
      }
      onClose={() => {
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'stockDetailedDialog'
              ? { 
                  ...modal, 
                  open: false,
                  product: '' 
                }
              : modal
          )
        );
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal dialog100 stockDetailedDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
      >
        <Typography id="draggable-dialog-title" className="w100 textAlignLeft">
          DETALHAMENTO DE ESTOQUE
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'stockDetailedDialog'
                  ? { ...modal, open: false }
                  : modal
              )
            );
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent">
        <section className="cardDefault pt16 pb16">
          <div className="dFlex alignCenter pb16">
            {!currentProduct?.productId ?  
              <ImageWrapper
                width="94px"
                height="94px"
                url={currentProduct?.type === "EXTERNAL" 
                  ? currentProduct?.skus?.length 
                    ? currentProduct?.skus[0]?.images?.url
                    : ''
                  : currentProduct?.image
                }
                borderType={currentProduct?.type}
              />
            : 
              <ImageWrapper
                width="94px"
                height="94px"
                url={customProductInfos?.image}
                borderType={customProductInfos?.type}
              />
            }

            <span className="ml10">
              {currentProduct?.description}
            </span>
          </div>

          <table>
            <thead>
              <tr>
                <th width="17%" className="subtitle white right p8 pt8 pb8" style={{ background: '#0B1A8E' }}>
                  ESTOQUE <br/> 
                  INICIAL
                </th>

                <th width="17%" className="subtitle white right p8" style={{ background: '#0B1A8E', borderLeft: 'solid 1px #E6E7F0' }}>
                  ENTRADAS/ <br/>
                  SAÍDAS
                </th>

                <th width="17%" className="subtitle white right p8" style={{ background: '#0B1A8E', borderLeft: 'solid 1px #E6E7F0' }}>
                  ESTOQUE <br/>
                  TEÓRICO
                </th>

                <th width="17%" className="subtitle white right p8 pb8" style={{ background: '#0B1A8E', borderLeft: 'solid 1px #E6E7F0', verticalAlign: 'bottom' }}>
                  CONTAGEM
                </th>

                <th width="17%" className="subtitle white right p8 pb8" style={{ background: '#5062F0', borderLeft: 'solid 2px #5062F0', verticalAlign: 'bottom' }}>
                  EM QTDE
                </th>

                <th width="15%" className="subtitle white right p8" style={{ background: '#5062F0', borderLeft: 'solid 1px #E6E7F0' }}>
                  % / <br/>
                  CONSUMO
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="description right p8 pt5 pb5" style={{ color: '#333541' }}>
                  {(currentProduct?.historical?.realStock || 0)?.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })} <br/>
                  {unitsMeasurements}
                </td>

                <td className="description right p8 pt5 pb5" style={{ borderLeft: 'solid 1px #E6E7F0' }}>
                  <div className="dFlex justifyEnd alignStart">
                    <div className="dFlex">
                      {(currentProduct?.consumedInOut || 0).toLocaleString('pt-br', {
                        maximumFractionDigits: 3,
                        minimumFractionDigits: 3
                      })} <br/>
                      {unitsMeasurements}
                    </div>

                    <Tooltip
                      onMouseEnter={() =>
                        handleGetExtract(currentProduct, false)
                      }
                      enterTouchDelay={0}
                      leaveDelay={5000}
                      leaveTouchDelay={5000}
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title={
                        <div
                          className="dFlex flexColumn"
                          style={{ paddingTop: 5 }}
                        >
                          {loadingInOutInfos ?
                            <div className="countsCircularProgressBar dFlex justifyCenter">
                              <CircularProgress color="#FFF" />
                            </div>  
                          : null}

                          {!loadingInOutInfos && !consumedInOutInfos?.positive?.length && !consumedInOutInfos?.negative?.length ?
                            <span className="white mb5">
                              Nenhuma entrada ou saída encontrada
                            </span>
                          : null}

                          {consumedInOutInfos?.positive?.length ?
                            <div className="consumedEntryBox mb10">
                              <span className="consumedEntryTitle">
                                ENTRADAS
                              </span>

                              {consumedInOutInfos?.positive.map((item) => (
                                <div className="dFlex justifyStart">
                                  <p>
                                    {moment(item?.date).format('DD/MM')} - 
                                  </p>

                                  <p className="ml2">
                                    {item?.description}:
                                  </p>

                                  <p className="ml2">
                                    {item.quantity
                                      ? parseFloat(item.quantity).toLocaleString('pt-br', {
                                        minimumFractionDigits: 3
                                      })
                                      : '0,000'
                                    } {" "}
                                    {item?.unity?.abbreviation}
                                  </p>
                                </div>
                              ))}
                            </div>
                          : null}

                          {consumedInOutInfos?.negative?.length ?
                            <div className="consumedExitBox">
                              <span className="consumedExitTitle">
                                SAÍDAS
                              </span>

                              {consumedInOutInfos?.negative.map((item) => (
                                <div className="dFlex justifyStart">
                                  <p>
                                    {moment(item?.date).format('DD/MM')} - 
                                  </p>

                                  <p className="ml2">
                                    {item?.description}:
                                  </p>

                                  <p className="ml2">
                                    {item.quantity
                                      ? parseFloat(Math.abs(item.quantity)).toLocaleString('pt-br', {
                                        minimumFractionDigits: 3
                                      })
                                      : '0,000'
                                    } {" "}
                                    {item?.unity?.abbreviation}
                                  </p>
                                </div>
                              ))}
                            </div>
                          : null}
                        </div>
                      }
                    >
                      <img 
                        src={StockInOutIcon} 
                        alt="Stock In and Out Icon" 
                        style={{ marginLeft: 5, marginTop: 5, width: 14 }} 
                      />
                    </Tooltip>
                  </div>
                </td>

                <td className="description right p8 pt5 pb5" style={{ borderLeft: 'solid 1px #E6E7F0', color: '#454A67' }}>
                  {(currentProduct?.theoricalStock || currentProduct?.theoreticalStock || 0).toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })} <br/>
                  {unitsMeasurements}
                </td>

                <td className="description right p8 pt5 pb5 bold" style={{ borderLeft: 'solid 1px #E6E7F0', color: '#0B1A8E' }}>
                  {typeof parseFloat(currentProduct?.realStock) === 'number'
                    ? (currentProduct?.realStock + (currentProduct?.otherStock || 0)).toLocaleString('pt-br', {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3
                    })
                    : '0,000'
                  } <br/>
                  {unitsMeasurements}
                </td>

                <td 
                  className="description right p8 pt5 pb5 bold" 
                  style={{ 
                    borderLeft: 'solid 2px #5062F0',
                    background: !currentProduct?.productId
                      ? show
                        ? currentProduct?.realStock == null && currentProduct?.otherStock == null
                          ? 'transparent'
                          : setColor(
                              percentage,
                              lossPercentage,
                              true,
                              difference,
                              currentProduct?.consumed
                            )
                        : 'transparent'
                      : currentProduct?.realStock == null || (currentProduct?.stocks?.countedStocks !== currentProduct?.stocks?.totalStocks)
                        ? '#000'
                        : setColor(
                          percentage,
                          lossPercentage,
                          true,
                          difference,
                          currentProduct?.consumed
                        ),
                    color: setColor(
                      percentage,
                      lossPercentage,
                      false,
                      difference,
                      currentProduct?.consumed
                    )
                  }}
                >
                  {!currentProduct?.productId
                    ?
                      show
                        ? (currentProduct?.realStock != null || currentProduct?.otherStock != null) &&
                          difference != null
                            ? <>
                                {validateNumber(diff)} <br/>
                                {unitsMeasurements}
                              </>
                            : '-'
                        : null
                    :
                      currentProduct?.realStock != null && (currentProduct?.stocks?.countedStocks === currentProduct?.stocks?.totalStocks)
                        ? difference != null
                          ? <>
                              {validateNumber(diff)} <br/>
                              {currentProduct?.consumptionUnity}
                            </>
                          : '-'
                        : '-'
                  }
                </td>

                <td 
                  className="description right p8 pt5 pb5 bold" 
                  style={{ 
                    verticalAlign: 'baseline',
                    borderLeft: 'solid 1px #E6E7F0',
                    background: !currentProduct?.productId
                      ? show
                        ? currentProduct?.realStock == null && currentProduct?.otherStock == null
                          ? 'transparent'
                          : setColor(
                              percentage,
                              lossPercentage,
                              true,
                              difference,
                              currentProduct?.consumed
                            )
                        : 'transparent'
                      : currentProduct?.realStock == null || (currentProduct?.stocks?.countedStocks !== currentProduct?.stocks?.totalStocks)
                        ? '#000'
                        : setColor(
                          percentage,
                          lossPercentage,
                          true,
                          difference,
                          currentProduct?.consumed
                      ),
                    color: setColor(
                      percentage,
                      lossPercentage,
                      false,
                      difference,
                      currentProduct?.consumed
                    ),
                  }}
                >
                  {!currentProduct?.productId
                    ?
                      show
                        ? (currentProduct?.realStock != null || currentProduct?.otherStock != null) &&
                          percentage != null
                          ? currentProduct?.consumed
                            ? `${validateNumber(percent)} %`
                            : '-'
                          : null
                        : null
                    :
                      currentProduct?.realStock != null && (currentProduct?.stocks?.countedStocks === currentProduct?.stocks?.totalStocks)
                        ? percentage != null
                          ? currentProduct?.consumed
                            ? `${validateNumber(percent)} %`
                            : '-'
                          : null
                        : '-'
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </DialogContent>
    </Dialog>
  );
}

export default StockDetailedDialog;