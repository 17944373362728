import FourthStepActive from 'images/icons/objects/lists/listBlue.svg';
import FourthStep from 'images/icons/objects/lists/listGray.svg';
import FirstStepActive from 'images/icons/objects/stores/storeBlue.svg';
import FirstStep from 'images/icons/objects/stores/storeGray.svg';
import SecondStepActive from 'images/icons/objects/stores/threeStoresBlue.svg';
import SecondStep from 'images/icons/objects/stores/threeStoresGray.svg';
import ThirdStepActive from 'images/icons/objects/stores/threeStoresHorizontalBlue.svg';
import ThirdStep from 'images/icons/objects/stores/threeStoresHorizontalGray.svg';
import FifthStepActive from 'images/icons/users/userProfileBlue.svg';
import FifthStep from 'images/icons/users/userProfileGray.svg';

function StepView({ currentStep }) {
  const currentStepNumberAndName = () => {
    let stepNumberAndName = '1/5 - LOJA';

    if (currentStep === '2') {
      stepNumberAndName = '2/5 - GRUPO';
    }

    if (currentStep === '3') {
      stepNumberAndName = '3/5 - CLONE';
    }

    if (currentStep === '4') {
      stepNumberAndName = '4/5 - CATEGORIAS';
    }

    if (currentStep === '5') {
      stepNumberAndName = '5/5 - USUÁRIOS';
    }

    return stepNumberAndName;
  };

  return (
    <article className="storeCloneStepViewBox">
      <span className="lightBlue bold">{currentStepNumberAndName()}</span>

      <div className={`stepBox ${currentStep === '1' && 'active'}`}>
        <img
          style={{ width: '20px' }}
          src={currentStep === '1' ? FirstStepActive : FirstStep}
          alt={currentStep === '1' ? 'FirstStepActive' : 'FirstStep'}
        />
      </div>

      <div className={`stepBox ${currentStep === '2' && 'active'}`}>
        <img
          style={{ width: '20px' }}
          src={currentStep === '2' ? SecondStepActive : SecondStep}
          alt={currentStep === '2' ? 'SecondStepActive' : 'SecondStep'}
        />
      </div>

      <div className={`stepBox ${currentStep === '3' && 'active'}`}>
        <img
          style={{ width: '25px' }}
          src={currentStep === '3' ? ThirdStepActive : ThirdStep}
          alt={currentStep === '3' ? 'ThirdStepActive' : 'ThirdStep'}
        />
      </div>

      <div className={`stepBox ${currentStep === '4' && 'active'}`}>
        <img
          style={{ width: '18px' }}
          src={currentStep === '4' ? FourthStepActive : FourthStep}
          alt={currentStep === '4' ? 'FourthStepActive' : 'FourthStep'}
        />
      </div>

      <div className={`stepBox ${currentStep === '5' && 'active'}`}>
        <img
          style={{ width: '18px' }}
          src={currentStep === '5' ? FifthStepActive : FifthStep}
          alt={currentStep === '5' ? 'FifthStepActive' : 'FifthStep'}
        />
      </div>
    </article>
  );
}

export default StepView;
