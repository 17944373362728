export const getRestaurantsArray = (companies) =>{
  const restaurants = companies.groups.reduce((acc, group) => {
    group.subGroups.forEach((subGroup) => {
      acc.push(...subGroup.stores);
    });
    return acc;
  }, []);

  return restaurants;
}

export const getRestaurantbyId = (id, companies) => {
  const restaurant = getRestaurantsArray(companies).filter(item => item.id === id);
  return restaurant[0];
}