import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makeGet from 'services/useFetch/makeGet';

import SkuDialogCards from './skuDialogCard';
import { SkuDialogBody, SkuDialogHead } from './styles';

function SkuDialog({
  openSkuDialog,
  setOpenSkuDialog,
  restaurantsIds, 
  productsIds, 
}) {
  const [description, setDescription] = useState()
  const [skus, setSkus] = useState([])
  const [loading, setLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [filters, setFilters] = useState([
    {
      param: 'originId',
      value: restaurantsIds,
      urlParam: `originId=${restaurantsIds}`
    },
    {
      param: 'productId',
      value: productsIds,
      urlParam: `productId=${productsIds}`
    },
  ]);

  const handleItems = (response) =>  {
    if (response?.skus?.length == 0) {
      setLoading(false)
      setIsEmpty(true)
      return 
    }
    setDescription(response?.description)
    setSkus(response?.skus)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    makeGet('modalSkusQuotation', filters, handleItems);
  }, [])

  const getColorStatus = (descri, price) => {
    const skusArr = skus.reduce((acc, sku) => (
      [...acc, { description: sku.description, price: sku.priceUc }]
    ), []).flat()

    const skusSort = skusArr.sort((a, b) => a.price - b.price || a.description - b.description);

    const index = skusSort.indexOf(skusArr.find(item => item.price === price && item.description === descri))

    if (index === 0) {
      return {
        backgroundColor: '#DFF7E8',
        color: '#3DCC6E'
      }
    } if (index < 3) {
      return {
        backgroundColor: '#FCF5E3',
        color: '#996600'
      }
    }
    return false;
  }
  
  
  return (
    <Dialog
      className="defaultModal mobileModal"
      fullWidth
      maxWidth="sm"
      open={openSkuDialog}
      aria-labelledby="draggable-dialog-title"
     >
     <DialogTitle className="modalHeader navyBlue" style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'move', fontFamily: 'Lato', fontSize: "16px" }} id="draggable-dialog-title">
       <Typography style={{ fontWeight: 'normal', cursor: 'move', fontFamily: 'Lato', fontSize: "16px" }}>
          {description}
       </Typography>

       <IconButton edge="start" color="inherit"
       onClick={() => setOpenSkuDialog(false)}
       >
        <img src={CloseIconNavy} style={{ width: "8px" }} alt="close" />
       </IconButton>
     </DialogTitle>

     {loading && <div sytle={{ margin: "10px" }}><LinearProgress style={{ height: "7px" }} variant="query" /></div>}

      <DialogContent className="modalContent p0" style={{backgroundColor: "white"}} >
        {isEmpty ? 
          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
            <Typography >
              Sem Skus
            </Typography>  
          </div>
        :
          <SkuDialogBody>
            <SkuDialogHead>
              <div>
                <p>SKU</p>
                <div>
                  <p style={{marginRight: "14px"}} >U.C. R$</p>
                  <p>COTAÇÃO R$</p>
                </div>
              </div>
            </SkuDialogHead>

            {skus?.map((sku, index )=> (
              <SkuDialogCards
                  skus={sku}
                  index={index}
                  getColorStatus={getColorStatus}
                />
            ))}
          </SkuDialogBody>
        }
      </DialogContent>
     </Dialog>
  )
}

export default SkuDialog;
