import React from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function ConfirmationDialog({
  openConfirmatioDialog,
  setOpenConfirmatioDialog,
  handleSendReceiverRequest,
  modalLoading,
  sendReceivedscards
}) {
  const quantityReceived = sendReceivedscards?.filter(
    (item) => item?.status == 'RECEIVED'
  );
  const quantityReturned = sendReceivedscards?.filter(
    (item) => item?.status == 'RETURNED'
  );
  const quantityNotReceived = sendReceivedscards?.filter(
    (item) => item?.status == 'NOT RECEIVED'
  );

  const handleQuantityPhrase = (number) => {
    if (number > 1) {
      return `${number} itens`;
    }
    return `${number} item`;
  };

  const receivedPhrase = `O recebimento de ${handleQuantityPhrase(
    quantityReceived.length
  )}`;
  const returnedPhrase = `A devolução de ${handleQuantityPhrase(
    quantityReturned.length
  )}`;
  const notReceivedPhrase = `O não recebimento de ${handleQuantityPhrase(
    quantityNotReceived.length
  )}`;

  return (
    <Dialog
      className="defaultModal mobileModal"
      fullWidth
      maxWidth="sm"
      open={openConfirmatioDialog}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"

        id="draggable-dialog-title"
      >
        <Typography>Confirmar Ação</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => setOpenConfirmatioDialog(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {modalLoading && (
        <div style={{ margin: '10px' }}>
          <LinearProgress style={{ height: '7px' }} variant="query" />
        </div>
      )}

      <DialogContent className="modalContent p0">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            paddingLeft: '16px'
          }}
        >
          <Typography
            style={{
              fontSize: '16px'
            }}
          >
            Gostaria de confirmar:
          </Typography>

          <Typography
            style={{
              fontSize: '12px'
            }}
          >
            {quantityReceived.length > 0 ? receivedPhrase : null}
          </Typography>

          <Typography
            style={{
              fontSize: '12px'
            }}
          >
            {quantityReturned.length > 0 ? returnedPhrase : null}
          </Typography>

          <Typography
            style={{
              fontSize: '12px'
            }}
          >
            {quantityNotReceived.length > 0 ? notReceivedPhrase : null}
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            margin: '12px 0'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              width: '120px',
              outline: 'none'
            }}
            onClick={handleSendReceiverRequest}
          >
            Confirmar
          </Button>
          
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenConfirmatioDialog(false)}
            style={{
              width: '120px',
              outline: 'none'
            }}
          >
            Cancelar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationDialog;
