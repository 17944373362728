import styled, {css} from 'styled-components';

export const InputManufactureWrapper = styled.div`
  display: grid;
  justify-content: center;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const InputManufactureInput = styled.input`
    width: ${({size}) => size ? size : '45px'};
    height: 16px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    text-align: right;
    font-size: 12px;
    line-height: 20px;
    border: none;
    background-color: transparent;

    ${({backgroundColor}) => 
      backgroundColor && css`
        padding: 2px;
        color: ${backgroundColor.color};
        background-color: ${backgroundColor.backgroundColor};
        border-bottom: solid 1px ${backgroundColor.border};
    `}

    ${({backgroundColor}) => 
      !backgroundColor && css`
         border-bottom: ${({validColor}) => validColor ? 'solid 1px #333541' : 'solid 1px #E22121'};
         color: ${({validColor}) => validColor ? 'inherit' : '#E22121'};
    `}
    margin: auto;
    outline: none;
`;

export const InputManufactureInputContainer = styled.div`
  margin: auto;
`;

