const isEmpty = (value, zeroIsEmpty = false) => {
    if (value === undefined || value === null || value === "") {
        return true
    }

    if (Array.isArray(value) && value.length === 0) {
        return true
    }

    if (typeof value === 'object' && Object.values(value).length === 0) {
        return true
    }

    if (zeroIsEmpty) {
        if (value === 0) {
            return true
        }
    }

    return false
}

module.exports = {
    isEmpty
}