export default function maskCnpj(value){
    // Remove tudo que não é dígito
    value = value.replace(/\D/g, '');

    // Adiciona a formatação de CNPJ
    if (value.length > 14) {
      value = value.substring(0, 14);
    }
    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
    return value;
  };