/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { TextField, Tooltip } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { isValid } from 'date-fns';
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';

import { DateInputContainer } from './index.style';

function DateInputField({
  label,
  handleFilter,
  filterName,
  size,
  inputSizes,
  initialDate,
  minDate,
  maxDate,
  isExtract,
  disabled,
  disabledDays,
  tooltipText,
  resetDate,
  setInputResetDate = () => {},
  resetDateWithDelete = false
}) {
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const handleDateChange = (date) => {
    if (!isValid(date)) {
      if (resetDateWithDelete) {
        setSelectedDate();
        handleFilter(filterName, null);
      }
      return;
    }

    setSelectedDate(date);
    handleFilter(filterName, format(date, 'yyyy-MM-dd'));
  };

  const disableDay = (date) =>
    !disabledDays.includes(format(date, 'yyyy-MM-dd'));

  function TextFieldWithTooltip(props) {
    return tooltipText ? (
      <Tooltip placement="bottom" title={tooltipText}>
        <TextField {...props} />
      </Tooltip>
    ) : (
      <TextField {...props} />
    );
  }

  useEffect(() => {
    if (resetDate) {
      setSelectedDate(initialDate);
      setInputResetDate(false);
    }
  }, [resetDate]);

  return (
    <DateInputContainer>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          variant="inline"
          autoOk
          minDate={minDate && minDate}
          maxDate={maxDate && maxDate}
          label={label}
          style={{ width: inputSizes[size] }}
          format="dd/MM"
          value={selectedDate || null}
          disabled={disabled}
          shouldDisableDate={disabledDays && disableDay}
          placeholder="Selecione..."
          onChange={handleDateChange}
          onMonthChange={(date) => {
            if (isExtract) {
              handleFilter('extractDate', date);
            }
          }}
          onYearChange={(date) => {
            if (isExtract) {
              handleFilter('extractDate', date);
            }
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          TextFieldComponent={TextFieldWithTooltip}
          InputLabelProps={{
            shrink: true
          }}
          PopoverProps={{ className: isExtract && filterName }}
        />
      </MuiPickersUtilsProvider>
    </DateInputContainer>
  );
}

export default DateInputField;
