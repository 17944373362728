/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';

import CloseIcon from 'images/icons/signals/closesAndCancels/closeModal.svg';
import makeGet from 'services/useFetch/makeGet';

import { ProductWrapper, ProductTop, ProductListItem } from './index.style';

import {
  OtherStocksWrapper,
  Content,
  ContentHead
} from '../OtherStocksDialog/index.style';

const productFilteredAndSorteredList = (productList) => productList.sort((a, b) =>
    a.product.description.localeCompare(b.product.description)
  );

function ItemListDialog({
  reference,
  handleClose,
  dialogProps = {},
  paramsID
}) {
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const { countId = null } = dialogProps;

  const handleProductsData = (response) => {
    setLoading(false);

    if (!response.length) return;
    const uniqueProducts = [];
    response.map((pro) =>
      !uniqueProducts.find((x) => x.productId === pro.productId)
        ? uniqueProducts.push(pro)
        : null
    );

    const filteredProducts = productFilteredAndSorteredList(uniqueProducts);
    setProductList(filteredProducts);
  };

  useEffect(() => {
    setLoading(true);
    const params = [
      { urlParam: `originId=${paramsID}` },
      { urlParam: `countId=${countId}` }
    ];

    makeGet(
      'inventories',
      params,
      handleProductsData,
      `/setups/count/products`
    );
  }, [dialogProps]);

  return (
    <OtherStocksWrapper>
      <Content ref={reference} ItensListDialog>
        <ContentHead displayFlexBetween>
          <div>
            Contagem: {dialogProps?.title}
          </div>
          
          <div>
            <img src={CloseIcon} alt="close divergent" onClick={handleClose} />
          </div>
        </ContentHead>

        <ProductTop>
          <div>Produto</div>
          <div>UN.</div>
        </ProductTop>

        {loading && <LinearProgress />}

        <ProductWrapper>
          {productList.map(
            (
              {
                product: {
                  consumptionUnitsPrimary: { abbreviation = '' } = {},
                  description
                } = {}
              },
              index
            ) => (
              <>
                <ProductListItem>
                  <div>{description}</div>
                  <div>{abbreviation}</div>
                </ProductListItem>
                {!(productList.length + 1 === index) && <hr />}
              </>
            )
          )}
        </ProductWrapper>
      </Content>
    </OtherStocksWrapper>
  );
}

export default ItemListDialog;
