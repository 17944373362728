import styled, { css } from 'styled-components';

export const CountPeriodCard = styled.div`
    width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(1, 3, 17, 0.45);
    padding: 8px;
    margin-top: 8px;
`;

export const CountPeriodCardTopInfo = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DescriptionCard = styled.span`
    color: #010311;
    font-size: 16px;
    margin-left: 8px;
`;

export const ItensDescription = styled.span`
    color: #5062F0;
    font-size: 12px;
    margin-right: 12px;
`;

export const Slidebar = styled.div(({ hasError, percent }) => `
    width: 192px;
    height: 12px;
    border: ${hasError ? '1px solid #AA0816' : '1px solid #5062F0'};
    position: relative;
    border-radius: 6px;
    &::after {
        content: "";
        position: absolute;
        width: ${hasError ? '100%' : `${percent}%`};
        height: 102%;
        background: ${hasError ? 'rgba(226, 33, 33, 0.5)' : '#5062F0'};
        border-radius: 6px;
    }
`);

export const PartialWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`;

export const PartialCard = styled.div`
    color: ${({hasError}) => hasError ? '#AA0816' : '#333541'};
    font-size: 12px;
    width: 110px;
    text-align: end;
`;

export const DateWrapper = styled.span`
    color: #333541;
    font-size: 12px;
    margin-top: 10px;
`;
