import { Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';

export const AutocompleteContainer = styled.div`
  display: flex;
  align-items: center;
  background: #d0d3e2;
  padding: 5px 16px;

  span {
    color: #0e1337;
  }
`;

export const CustomAutocomplete = styled(Autocomplete)`
  width: 60%;
  margin: 0 5px 0 10px;

  input {
    color: #0e1337;

    ::placeholder {
      color: #666770;
      opacity: 1; /* Firefox */
    }
  }
`;

export const GridOptions = styled(Grid)`
    @media (max-width: 870px) and (orientation: portrait) {
      margin-top: 30px;
    }
`;

export const GridOrdination = styled(Grid)`
  @media (max-width: 870px) and (orientation: portrait) {
    justify-content: space-between;
  }
`;

export const GridNotification = styled(Grid)`
  @media (max-width: 870px) and (orientation: portrait) {
    display: none;
  }
`;

export const TrDesk = styled.tr`
        th,
        td {
          background: #313347;
          position: sticky;
          top: -6px;
          z-index: 99;
          color: #FFF;

          &:first-of-type {
            overflow: hidden;
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            overflow: hidden;
            border-top-right-radius: 4px;
          }

          &:first-of-type,
          &:last-of-type {
            padding: 0 12px;
          }
        }
        
        @media (max-width: 870px) and (orientation: portrait) {
          display: none;
        }
`;

export const ThDifDesk = styled.th`
    .spanDesk {
      @media (max-width: 870px) and (orientation: portrait) {
          display: none !important;
        }
    }
    .spanTablet {
      @media (min-width: 870px) {
          display: none !important;
        }
    }
`;

export const ThTablet = styled.th`
    @media (max-width: 870px) and (orientation: portrait) {
          display: none !important;
    }
`;

export const TdHidden = styled.th`
    @media (max-width: 870px) and (orientation: portrait) {
      display: none !important;
    }
`;

export const TrTablet = styled.tr`
  @media (max-width: 870px) and (orientation: portrait) {
        th,
        td {
          background: #313347;
          position: sticky;
          top: -6px;
          z-index: 99;
          color: #FFF;

          &:first-of-type {
            overflow: hidden;
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            overflow: hidden;
            border-top-right-radius: 4px;
          }

          &:first-of-type {
            padding: 0 12px;
          }
        }
}

        @media (min-width: 870px) {
          display: none;
        }

`;
