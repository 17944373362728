import Axios from 'axios';

import { queries } from './queries';

const makeGet = (endpoint, params, handler, appendEndpoint) => {
  const flatParams = params.map((param) => param.urlParam).join('&');
  const query = `${queries[endpoint]}${appendEndpoint || ''}?${flatParams}`;

  Axios
    .get(query)
    .then((response) => {
      const { data = {} } = response;
      handler(data);
    })
    .catch((error) => {
      handler(error);
    });
};

export default makeGet;
