import styled, { css } from "styled-components";

export const CardHeder = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px;
`;

export const Card = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'calt' off;
    color: #010311;
    border-bottom: 1px solid #9296AC;
    ${({doubleBottomBorder}) => doubleBottomBorder && css`
        border-bottom: 4px solid #454A67;
    `};
`;

export const CardDescriptionWrapper = styled.div`
    display: flex;
    transition: all 150ms ease-in;

    img {
        margin-right: 4px;
    }
    div {
        margin-right: 4px;
    }
`   
export const CardItensWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${({spaceEvenly}) => spaceEvenly && css`
        justify-content: space-evenly;
        align-items: center;
    `};
`;

export const CardItem = styled.div`
    text-align: right;
    margin-right: ${({marginRight}) => marginRight && marginRight};
    padding: 0 7px 8px 8px;
`;

export const CardItemTitle = styled.p`
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    font-weight: bold;
    font-size: 9px;
    line-height: 12px;
    color: #454A67;
    margin: 0;
`;

export const CardItemSubTitle = styled.p`
   margin: 0;
    padding-top: 4px;
`;

export const CardItemInflation = styled.div`
    min-width: 53px;
    height: 24px;
    padding: 4px;
    margin-top: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    background-color: ${({backgroundColor}) => backgroundColor && backgroundColor};
    color: ${({color}) => color && color};
    ${({underline}) => underline && css`
        text-underline-offset: 2px;
        text-decoration-line: underline;
    `};
`;

export const Arrow = styled.img`
    transition: all 150ms ease-in;
    transform: ${({rotate}) => rotate && "rotate(180deg)"};
`;

export const CardItensContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const CardItemSub = styled.span`
    font-size: 12px;
`;