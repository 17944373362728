const INITIAL = {}

export default function orderInfo(state = INITIAL, action) {
    switch (action.type) {
        case "SET_SQUARES_DATA":
            return (
                {
                    action: "EDIT",
                    data: action.data
                }
            )
        case "CREATE_SQUARES_DATA":
            return (
                {
                    action: "CREATE",
                    data: {}
                }
            )
        default:
            return state;
    }
}