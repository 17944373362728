import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  filters: []
};

export const cardsFilterSlice = createSlice({
  name: 'cardsFilter',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { filters } = currState;
      const { param, value, textValue } = payload;

      // Check if filter is already set
      const filterChanged = filters?.find((filter) => {
        const filterKey = Object.keys(filter).find(
          (key) => key !== 'textValue'
        );
        return param === filterKey;
      });

      if (filterChanged) {
        const newFilters = filters.map((filter) => {
          if (filter === filterChanged) {
            return {
              [param]: value,
              filterName: param,
              textValue
            };
          }

          return filter;
        });

        // Using createSlice we can modify the state directly
        state.filters = newFilters;
      } else {
        state.filters.push({
          [param]: value,
          filterName: param,
          textValue
        });
      }
    },
    clearFilter: (state, action) => {
      const { payload } = action;
      const currState = current(state)
      const { filters } = currState;
      const { param } = payload || {};
      
      const removeFilter = filters?.filter((item) => {
        if(param === 'cardDescription' || param === 'cardId') {
          return item.filterName !== 'cardDescription' && item.filterName !== 'cardId'
        }

        if (param === 'productDescription' || param === 'productId') {
          return item.filterName !== 'productDescription' && item.filterName !== 'productId'
        }

        return item?.filterName !== param
      })

      state.filters = removeFilter;
    }
  }
});

export const { changeFilter, clearFilter } = cardsFilterSlice.actions;

export default cardsFilterSlice.reducer;
