/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-new */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import Axios from 'axios';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { useParams } from 'react-router';

import Button from 'components/Button/button';
import PaperComponent from 'components/PaperComponent';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function ProductTransference({
  product,
  modalSettings,
  onClose,
  userInfo,
  setToastProps,
  setToastOpen,
  refreshProducts
}) {
  const { id: paramsID } = useParams();
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [stores, setStores] = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  )
  const [isLoading, setIsLoading] = useState(false)
  const [transferenceInfos, setTransferenceInfos] = useState({
    quantity: '',
    restaurant: '',
    date: new Date(moment().subtract(1, 'day').set({ hour: 13, minute: 0, second: 0 }))
  });
  const [transfers, setTransfers] = useState()
  const [transferTypes, setTransferTypes] = useState({
    PRODUCTION_PRODUCED: [],
    TRANSFER_OUT: [],
    TRANSFER_ENTRY: [],
    SHOPPING: [],
    CONSUMED_PRODUCTION: [],
    DISCARD: [],
    SALES: []
  })

  const [totalUpdateValue, setTotalUpdateValue] = useState(0)

  const disableToTransfer = isLoading || !transferenceInfos?.quantity || !transferenceInfos?.restaurant
  const actualDate = moment(new Date()).format('YYYY-MM-DD')

  const disabledMessageTooltip = () => {
    if (!transferenceInfos?.restaurant || !transferenceInfos?.quantity) {
      return 'Preencha os campos obrigatórios'
    } 
    if (parseFloat(transferenceInfos?.quantity?.replace(',', '.')) <= totalUpdateValue) {
      return ''
    }
    
    return 'Estoque não disponível! Tem certeza que deseja seguir com a transferência deste produto?'
    
  }

  useEffect(() => {
    Axios.get( 
      `${environments.restaurants}/groups?groupId=${userInfo?.companies?.groups[0]?.id}`
    ).then((response) => {
      const mappedStores = response?.data?.content
        .reduce((acc, group) => [...acc, group.subgroups], [])
        .flat()
        .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
        .flat()
        .map(s => ({
          id: s.storeId,
          name: s.description
        }))
        .filter(s => s.id !== Number(paramsID))
        .sort((a, b) => a.name.localeCompare(b.name))

      setStores(mappedStores)
    })
      .catch((error) => {
        console.log(error);
      });
  }, [userInfo.companies]);

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };


  const existingReleases = () => {
    const originCardIdParam = product?.originIdCard ? `&cardId=${product?.originIdCard}` : ''

    Axios.get(`${environments.catalog}/extracts?originId=${paramsID}&date=${actualDate}&productId=${product?.id}&allExtracts=true${originCardIdParam}`)
      .then((response) => {
        
        const productionProduceType = response.data.filter((type) => type.type === 'PRODUCTION_PRODUCED')
        const transferOutType = response.data.filter((type) => type.type === 'TRANSFER_OUT')
        const transferEntryType = response.data.filter((type) => type.type === 'TRANSFER_ENTRY')
        const shoppingType = response.data.filter((type) => type.type === 'SHOPPING')
        const consumedProductionType = response.data.filter((type) => type.type === 'CONSUMED_PRODUCTION')
        const discardType = response.data.filter((type) => type.type === 'DISCARD')
        const salesType = response.data.filter((type) => type.type === 'SALES')
        const countType = response.data.filter((type) => type.type === 'COUNT')

        const filterFirstValue = response.data.filter((type) => type.type === 'PRODUCTION_PRODUCED' || type.type === 'SHOPPING' || type.type === 'TRANSFER_ENTRY')
        .reduce((accumulator, item) => accumulator + item.quantity, 0)
        const filterSecondValue = response.data.filter((type) => type.type !== 'PRODUCTION_PRODUCED' && type.type !== 'SHOPPING' && type.type !== 'TRANSFER_ENTRY' && type.type !== 'COUNT')
        .reduce((accumulator, item) => accumulator + item.quantity, 0)

        setTotalUpdateValue(product?.realStock + ( - filterSecondValue) + filterFirstValue)
        setTransfers(response?.data)
        setTransferTypes({
          PRODUCTION_PRODUCED: {
            quantityTotal: productionProduceType.reduce((accumulator, item) => accumulator + item.quantity, 0),
            items: productionProduceType
          },
          TRANSFER_OUT: {
            quantityTotal: transferOutType.reduce((accumulator, item) => accumulator + item.quantity, 0),
            items: transferOutType
          },
          TRANSFER_ENTRY: {
            quantityTotal: transferEntryType.reduce((accumulator, item) => accumulator + item.quantity, 0),
            items: transferEntryType
          },
          SHOPPING: {
            quantityTotal: shoppingType.reduce((accumulator, item) => accumulator + item.quantity, 0),
            items: shoppingType
          },
          CONSUMED_PRODUCTION: {
            quantityTotal: consumedProductionType.reduce((accumulator, item) => accumulator + item.quantity, 0),
            items: consumedProductionType
          },
          DISCARD: {
            quantityTotal: discardType.reduce((accumulator, item) => accumulator + item.quantity, 0),
            items: discardType
          },
          SALES: {
            quantityTotal: salesType.reduce((accumulator, item) => accumulator + item.quantity, 0),
            items: salesType
          },
          COUNT: {
            quantityTotal: countType.reduce((accumulator, item) => accumulator + item.quantity, 0),
            items: countType
          }
        })
      })
  }

  useEffect(() => {
    existingReleases()
  }, [])

  const convertType = (type) => {
    if (type === 'PRODUCTION_PRODUCED') {
      return 'Produzido'
    } if (type === 'TRANSFER_OUT') {
      return 'Transf. enviada'
    } if (type === 'TRANSFER_ENTRY') {
      return 'Transf. recebida'
    } if (type === 'SHOPPING'){
      return 'Compra'
    } if (type === 'CONSUMED_PRODUCTION') {
      return 'Consumido'
    } if (type === 'DISCARD') {
      return 'Descarte'
    } if (type === 'SALES') {
      return 'Venda'
    } if (type === 'COUNT') {
      return 'Diferença de estoque'
    }
  }

  const colorValue = (quantity, type) => {
    if(type === 'PRODUCTION_PRODUCED' || type === 'SHOPPING' || type === 'TRANSFER_ENTRY') {
      return <h2 className='positiveValue'>+{quantity.toLocaleString('pt-br', {
        minimumFractionDigits: 3
      })} {product?.consumptionUnitsPrimary?.abbreviation}</h2>
    } 
    return <h2 className='negativeValue'>-{quantity.toLocaleString('pt-br', {
      minimumFractionDigits: 3
    })} {product?.consumptionUnitsPrimary?.abbreviation}</h2>
  }

  const sendTransference = () => {
    setIsLoading(true)

    const dateFormatted = (transferenceInfos.date ? moment(transferenceInfos.date) : moment().subtract(1, 'day')).format('YYYY-MM-DD')

    const body = {
      fromRestaurantId: parseFloat(paramsID),
      toRestaurantId: transferenceInfos?.restaurant?.id,
      productId: product?.id,
      quantity: parseFloat(transferenceInfos?.quantity?.replace(',', '.')),
      date: dateFormatted,
      user: userLocal?.user
    }

    Axios.post(
      `${environments.catalog}/products/transfer`,
      body,
      config
    )
      .then(() => {
        setIsLoading(false)
        onClose()
        setToastOpen(true)
        setToastProps({
          message: 'Produtos transferidos com sucesso.',
          severity: 'success',
          timeOutOff: true
        });
        refreshProducts()
      })
      .catch(() => {
        setIsLoading(false)
        setToastOpen(true)
        setToastProps({
          severity: 'error',
          message: 'Houve algum error ao tentar transferir o produto.'
        });
      });
  };

  const stockValue = () => {
    const yesterdayFormatted = moment().subtract('1', 'day')?.format('YYYY-MM-DD')

    if (product?.lastCountToday && (moment(product?.lastCountToday)?.format('YYYY-MM-DD') === yesterdayFormatted)) {
      return product?.realStock
    }

    return product?.theoreticalStock
  }

  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'productTransference')
          .fullScreen
      }
      maxWidth={
        modalSettings.find((modal) => modal.name === 'productTransference')
          .maxWidth
      }
      open={
        modalSettings.find((modal) => modal.name === 'productTransference').open
      }
      onClose={() => {
        onClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal miniModal productTransferenceDialog"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>TRANSFERÊNCIA ENTRE LOJAS</Typography>
        
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img src={CloseIcon} alt="CloseIcon" />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent">
        <section className="cardDefault pt16">
          <div className="dFlex flexColumn mb16">
            <span className="bold fontSizeDefault mb5">Produto</span>

            <span className="fontSizeDefault">
              {ValidationLength(product?.description, 30)}
            </span>
          </div>

          <Autocomplete
            className="autocompleteSmall mt30"
            options={stores}
            value={transferenceInfos?.restaurant}
            size="small"
            getOptionLabel={(option) => option?.name}
            onChange={(_, value) => {
              if (value) {
                setTransferenceInfos({
                  ...transferenceInfos,
                  restaurant: value
                })
              }
            }}
            renderOption={(params) => (
              <Typography
                style={{ fontSize: '12px', fontFamily: 'Lato' }}
              >
                {params?.name}
              </Typography>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Restaurante"
                placeholder="Selecionar..."
                InputLabelProps={{
                  style: { fontFamily: 'Lato', fontSize: '12px' }
                }}
              />
            )}
          />

          <section className="dFlex mt20 mb16">
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={ptLocale}
            >
              <KeyboardDatePicker
                style={{ width: "17%", marginRight: 55 }}
                className="dateSmall"
                disableFuture="true"
                size="small"
                id="time-picker"
                label="Data"
                placeholder="00/00"
                format="dd/MM"
                ampm={false}
                inputVariant="outlined"
                clearable
                fullWidth
                value={transferenceInfos?.date}
                onChange={(value) => {
                  if (value) {
                    setTransferenceInfos({
                      ...transferenceInfos,
                      date: value
                    })
                  }
                }}
                InputProps={{
                  style: { fontFamily: 'Lato', fontSize: '12px', padding: "3px 0 5px !important" }
                }}
                KeyboardButtonProps={{
                  size: 'small',
                  'aria-label': 'change time',
                  style: { outline: 0 }
                }}
                PopoverProps={{ style: { outline: 0 } }}
              />
            </MuiPickersUtilsProvider>

            <div className="dFlex flexColumn alignEnd" style={{ marginRight: 60, width: '22%' }}>
              <span className="bold fontSizeDefault" style={{ marginBottom: 6 }}>Estoque Atual</span>

              <span className="fontSizeDefault textAlignRight">
                {stockValue()?.toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })} {" "}
                {product?.consumptionUnitsPrimary?.abbreviation}
              </span>
            </div>

            <div className="dFlex flexColumn mr16">
              <span className="bold fontSizeDefault mb5 textAlignRight">Qtde à transferir</span>

              <CurrencyTextField
                variant="outlined"
                size="small"
                currencySymbol=""
                minimumValue="0"
                outputFormat="string"
                decimalCharacter=","
                digitGroupSeparator="."
                decimalPlaces={3}
                decimalPlacesShownOnFocus={3}
                type="text"
                name="quantity"
                required
                fullWidth
                style={{ width: '100px', marginTop: -8 }}
                className="textFieldSmall right"
                placeholder="Digite aqui..."
                InputProps={{ style: { fontFamily: 'Lato' } }}
                onChange={(e) => {
                  setTransferenceInfos({
                    ...transferenceInfos,
                    quantity: e?.target?.value
                  })
                }}
              />
            </div>

            <div className="dFlex flexColumn">
              <span className="bold fontSizeDefault mb5">Un.</span>

              <span className="fontSizeDefault">
                {product?.consumptionUnitsPrimary?.abbreviation}
              </span>
            </div>
          </section>

          <hr/>

          <section className="dFlex mt20 mb16 justifyEnd">
            <div className="dFlex flexColumn alignEnd" style={{ marginRight: 52, width: '22%' }}>
              <span className="bold fontSizeDefault" style={{ marginBottom: 6 }}>Custo unitário</span>

              <span className="fontSizeDefault textAlignRight">
                R$ {product?.lastCost?.toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })} {" "}
              </span>
            </div>
            
            <div className="dFlex flexColumn alignEnd" style={{ marginRight: 35 }}>
              <span className="bold fontSizeDefault" style={{ marginBottom: 6 }}>Custo transferência</span>

              <span className="fontSizeDefault textAlignRight">
                {transferenceInfos.quantity ? `R$ ${(parseFloat(transferenceInfos.quantity.replace(',', '.')) * product?.lastCost)?.toLocaleString('pt-br', {minimumFractionDigits: 3})}`: "-" }
              </span>
            </div>
          </section>
          
          {transfers?.filter((item) => item.type !== 'COUNT').length
            && transfers?.filter((item) => item.date === actualDate).length ?
            <section className='tableTransfer'>
              <hr style={{ 'background-color': 'black' }}/>

              <thead>
                <th className='lineBottom' colSpan={2}>
                  <th className='td'>
                    Total por operação hoje
                  </th>
                  <th width='70px' />
                </th>
              </thead>

              <tbody>
                {transferTypes?.PRODUCTION_PRODUCED?.items?.length ?
                  <tr>
                    <td className='lineBottom'> 
                      {convertType(transferTypes?.PRODUCTION_PRODUCED?.items[0].type)}
                    </td>
                    <td width='65px' className='lineBottom'>
                      {colorValue(transferTypes?.PRODUCTION_PRODUCED?.quantityTotal, transferTypes?.PRODUCTION_PRODUCED?.items[0].type)}
                    </td>
                  </tr>
                : ''}
                
                {transferTypes?.SHOPPING?.items?.length ?
                  <tr>
                    <td className='lineBottom'> 
                      {convertType(transferTypes?.SHOPPING?.items[0].type)}
                    </td>
                    <td width='65px' className='lineBottom'>
                      {colorValue(transferTypes?.SHOPPING?.quantityTotal, transferTypes?.SHOPPING?.items[0].type)}
                    </td>
                  </tr>
                : ''}

                {transferTypes?.TRANSFER_ENTRY?.items?.length ?
                  <tr>
                    <td className='lineBottom'> 
                      {convertType(transferTypes?.TRANSFER_ENTRY?.items[0].type)}
                    </td>
                    <td width='65px' className='lineBottom'>
                      {colorValue(transferTypes?.TRANSFER_ENTRY?.quantityTotal, transferTypes?.TRANSFER_ENTRY?.items[0].type)}
                    </td>
                  </tr>
                : ''}

                {transferTypes?.CONSUMED_PRODUCTION?.items?.length ?
                  <tr>
                    <td className='lineBottom'> 
                      {convertType(transferTypes?.CONSUMED_PRODUCTION?.items[0].type)}
                    </td>
                    <td width='65px' className='lineBottom'>
                      {colorValue(transferTypes?.CONSUMED_PRODUCTION?.quantityTotal, transferTypes?.CONSUMED_PRODUCTION?.items[0].type)}
                    </td>
                  </tr>
                : ''}

                {transferTypes?.DISCARD?.items?.length ?
                  <tr>
                    <td className='lineBottom'> 
                      {convertType(transferTypes?.DISCARD?.items[0].type)}
                    </td>
                    <td width='65px' className='lineBottom'>
                      {colorValue(transferTypes?.DISCARD?.quantityTotal, transferTypes?.DISCARD?.items[0].type)}
                    </td>
                  </tr>
                : ''}

                {transferTypes?.TRANSFER_OUT?.items?.length ?
                  <tr>
                    <td className='lineBottom'> 
                      {convertType(transferTypes?.TRANSFER_OUT?.items[0].type)}
                    </td>
                    <td width='65px' className='lineBottom'>
                      {colorValue(transferTypes?.TRANSFER_OUT?.quantityTotal, transferTypes?.TRANSFER_OUT?.items[0].type)}
                    </td>
                  </tr>
                : ''}

                {transferTypes?.SALES?.items?.length ?
                  <tr>
                    <td className='lineBottom'> 
                      {convertType(transferTypes?.SALES?.items[0].type)}
                    </td>
                    <td width='65px' className='lineBottom'>
                      {colorValue(transferTypes?.SALES?.quantityTotal, transferTypes?.SALES?.items[0].type)}
                    </td>
                  </tr>
                : ''}
              </tbody>

              <div className='updateStock'>
                <span className='titleUpdate'>
                  Estoque atualizado
                </span>

                <span className='valueUpdate'>
                  {totalUpdateValue?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })} {product?.consumptionUnitsPrimary?.abbreviation }
                </span>
              </div>
            </section>
          : ''}
        </section>
        
        <Tooltip title={disabledMessageTooltip()} aria-label="filters">
          <div className="dBlock mlAuto floatRight">
            <Button
              className={`${disableToTransfer ? 'cursorAuto backButton' : 'submitButton'} defaultButton mt16`}
              label="Enviar"
              design="contained"
              onClick={() => {
                if (disableToTransfer) return null

                sendTransference()
              }}
            />
          </div>
        </Tooltip>
      </DialogContent>
    </Dialog>
  );
}

export default ProductTransference;