import styled from 'styled-components';

export const DivergenteWrapper = styled.div`
    display: ${({open}) => open ? "block" : "none"};
    padding: 10px 8px;
    position: absolute;
    top: 22%;
    left: 0;
    background-color: #FFE299;
    width: 100%;
    height: 100%;
    z-index: 999;
`;

export const DivergentHead = styled.div`
    color: #996600;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const StockInfo = styled.div`
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    background-color: #454A67;
    font-size: 16px;
    padding: 4px 8px;
`;
