import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
    position: absolute;
    top: 28px;
    background: #E7DAF3;
    box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */
    font-feature-settings: 'tnum' on, 'lnum' on;

    /* Roxo/P4 */

    color: #4D0891;
    padding: 4px;
    z-index: 1000;
`;

export const ContentTitle = styled.div`
    
`;

export const ContentList = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ContentItem = styled.div``;


export const DifferenceInCountContainer = styled.div`
    position: relative;
    
`;

export const DifferenceInCountDescription = styled.div`
    display: flex;

    img {
        transition: all 250ms ease-in;
        margin-left: 4px;
        ${({open}) => open && css`
            transform: rotate(180deg);
        `}
    }
    max-width: 135px;
    justify-content: space-between;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #4D0891;
    background: #E7DAF3;
    border-radius: 4px;
    padding: 4px;
`;

