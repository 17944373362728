/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Paper
} from '@material-ui/core';
import Axios from 'axios';
import Draggable from 'react-draggable';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

function OtherStocksDialog({
  modalSettings,
  setModalSettings,
  countDate,
  currentProduct
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [realStock, setRealStock] = useState(false);

  useEffect(() => {
    const productId = currentProduct?.id || currentProduct?.productId

    Axios
      .get(
        `${environments.catalog}/inventories/counts/${productId}/stocks/${countDate}`
      )
      .then((response) => {
        setRealStock(response.data.content);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])

  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'otherStocksDialog')
          .fullScreen
      }
      open={
        modalSettings.find((modal) => modal.name === 'otherStocksDialog').open
      }
      onClose={() => {
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'otherStocksDialog'
              ? { ...modal, open: false }
              : modal
          )
        );
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal dialog100 stockDetailedDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
      >
        <Typography id="draggable-dialog-title" className="w100 textAlignLeft">
          OUTROS ESTOQUES
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'otherStocksDialog'
                  ? { ...modal, open: false }
                  : modal
              )
            );
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <table>
          <thead>
            <tr>
              <th width="800" className="subtitle left p12 pt8 pb8">
                DESCRIÇÃO
              </th>

              <th width="200" className="subtitle right p12">
                QTDE
              </th>

              <th width="200" className="subtitle left p12">
                USUÁRIO
              </th>
            </tr>

            <tr>
              <td colSpan="7" className="p0">
                <hr className="titleLineBorder m0" />
              </td>
            </tr>
          </thead>

          <tbody>
            {realStock && realStock.length ? 
              realStock.map((stock, stockIndex) => {
                const isLastElement = stockIndex === realStock?.length - 1;

                return (
                  <>
                    <tr>
                      <td className="description left pl12">
                        <p
                          style={{
                            width: '50%',
                            padding: '0 8px 0 0',
                            margin: '0px 5px 5px 0px',
                            border: 'none',
                            fontFamily: 'Lato',
                            fontSize: 12
                          }}
                        >
                          {stock?.stock}
                        </p>
                      </td>

                      <td className="description right pl12">
                        {parseFloat(stock?.quantity || 0).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })}
                      </td>

                      <td className="description left pl12">
                        {stock?.user || '-'}
                      </td>
                    </tr>

                    {!isLastElement && (
                      <tr>
                        <td colSpan="7" className="p0">
                          <hr
                            className="titleLineBorder m0"
                            style={{ height: '2px' }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                )
              }) : (
                <tr>
                  <td colSpan="10" className="description center">
                    <Typography className="fontSizeDefault bold" style={{ margin: '5px', textAlign: 'center' }}>
                      Não há nenhum estoque
                    </Typography>
                  </td>
                </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}
export default OtherStocksDialog;