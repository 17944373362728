/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { LinearProgress, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

import SimpleLineGraphic from '../graphics/SimpleLineGraphic';

function SalesInfosArea({ 
  salesInfos,
  cmvInfos,
  paramsID,
  actualDateLessOne,
  actualDateLessFourteen
}) {
  const cmvObj = () => {
    if (!cmvInfos?.content) return

    const { realCmv, goalCmv, hasCmvGoal } = cmvInfos?.content

    let bg = '#9296AC';
    let tooltip = 'Sem meta cadastrada'

    if (hasCmvGoal && realCmv < goalCmv) {
      bg = '#08AA15'
      tooltip = `Abaixo da meta (${goalCmv?.toLocaleString('pt-br', {
        minimumFractionDigits: 2
      })}%)`
    }

    if (hasCmvGoal && realCmv > goalCmv) {
      bg = '#F53D4C'
      tooltip = `Acima da meta (${goalCmv?.toLocaleString('pt-br', {
        minimumFractionDigits: 2
      })}%)`
    }

    return {
      bg,
      tooltip,
      realCmv: `${realCmv?.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}%`
    }
  }

  return (
    <section className="cockpitSalesInfosContainer cockpitDefaultContainer">
      {/* <div className="headerOutsideTitle">
        <span className="title">
          CMV CARDÁPIO
        </span>

        <Tooltip
          title={
            <p className="mb0 bold" style={{ padding: '2px 6px' }}>
              {cmvObj()?.tooltip}
            </p>
          }
          aria-label="filters"
          placement="right-start"
        >
          <span className="percent" style={{ background: cmvObj()?.bg }}>
            {cmvObj()?.realCmv}
          </span>
        </Tooltip>
        
        <Tooltip
          TransitionProps={{ timeout: 600 }}
          title={
            <span
              className="dBlock"
              style={{ padding: '2px 5px' }}
            >
              CMV detalhado
            </span>
          }
        >
          <Link
            target="_blank"
            to={{
              pathname: `/cmv/${paramsID}`
            }}
          >
            <div className="cmvIcon">
              $
            </div>
          </Link>
        </Tooltip>
      </div> */}

      <div className="graphicContainer">
        <div className="graphicBox">
          <div className="graphicItem">
            <span className="graphicTitle">
              vendas
            </span>

            {salesInfos?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <SimpleLineGraphic 
                paramsID={paramsID}
                mainContent={salesInfos}
                actualDateLessOne={actualDateLessOne}
                actualDateLessFourteen={actualDateLessFourteen}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesInfosArea);
