import React, { useEffect, useState } from 'react';

import './index.scss';
import { Line } from 'react-chartjs-2';

import Environments from 'utils/environments';
import numberToReal from 'utils/numberToReal';

import {
  filterChartData,
  filterChartLineOptions
} from '../../MainInfoCard/CardInfoChart/utils';

function Index({ providerId, type, filter, filtersChanged }) {
  const environments = Environments(process.env.REACT_APP_ENV);
  const [filters, setFilters] = useState([
    {
      param: 'type',
      value: type,
      urlParam: `type=${type}`
    }
  ]);
  const [months, setMonths] = useState([0]);
  const [sales, setSales] = useState([0]);
  const [shopping, setShopping] = useState([0]);
  const [data, setData] = useState([]);

  useEffect(() => {
    const delayFilterFetchData = setTimeout(() => {
      (async () => {
        const params = filters.map((filter) => filter.urlParam).join('&');
        const response = await fetch(
          `${environments.catalog}/providers/graph/${providerId}?${params}`
        );
        const content = await response.json();

        setData(content);
      })();
    }, 1000);

    return () => clearTimeout(delayFilterFetchData);
  }, [providerId, filters]);

  useEffect(() => {
    const newFilter = [
      {
        param: 'type',
        value: type,
        urlParam: `type=${type}`
      }
    ];

    filter.map((fil) => {
      newFilter.push({
        param: fil.param,
        value: fil.value,
        urlParam: fil.urlParam
      });
    });

    setFilters(newFilter);
  }, [filter, filtersChanged]);

  useEffect(() => {
    if (data?.content?.length) {
      const dataMonths = data.content.map((item) => item.date);
      const dataSales = data.content.map((item) => item.sale);
      const dataShopping = data.content.map((item) => item.shopping);

      setMonths(dataMonths);
      setSales(dataSales);
      setShopping(dataShopping);
    } else {
      setMonths([0]);
      setSales([0]);
      setShopping([0]);
    }
  }, [data]);

  const cardInfo = {
    title: 'Vendas e Compras',
    type: 4,
    size: 3,
    chartType: 'lineChart',
    detailDropDown: [],
    details: 'cardapio',
    labels: months,
    datasets: [
      {
        label: 'Vendas',
        color: '#1F2445',
        data: sales,
        borderDash: [5, 5]
      },
      {
        label: 'Compras',
        color: '#5062F0',
        data: shopping
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'right',
      fontFamily: 'Lato'
    },
    layout: {
      padding: {
        left: 0,
        right: 100,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.2,
          gridLines: {
            offsetGridLines: false
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            max: 600,
            min: 0,
            stepSize: 100
          }
        }
      ]
    }
  };

  const chartLineOptions = {
    ...chartOptions,
    legend: {
      display: false
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) =>
          `R$ ${parseFloat(tooltipItem.value).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          gridLines: {
            display: true,
            zeroLineWidth: 0
          }
        }
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: true,
            zeroLineWidth: 2,
            zeroLineColor: '#B8BFF5'
          },
          ticks: {
            max: 300000,
            min: 10000,
            stepSize: 10000,
            callback(value) {
              const newValue = numberToReal(value, 0);
              return `${newValue}`;
            }
          }
        }
      ]
    }
  };

  return (
    <div className="chartCard" style={{ height: '143px' }}>
      <div className="chartDescription">
        <h1 className="title">Vendas x Potencial</h1>
        <div className="descriptionSalesPurchase">
          <div className="descriptionSales">
            <svg
              width="32"
              height="2"
              viewBox="0 0 32 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H31"
                stroke="#1F2445"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="2 4"
              />
            </svg>
            <span className="descriptionTitle">Vendas realizadas</span>
          </div>
          <div className="descriptionPurchases">
            <svg
              width="32"
              height="2"
              viewBox="0 0 32 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1H31"
                stroke="#5062F0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="descriptionTitle">Potencial Bloom</span>
          </div>
        </div>
      </div>
      <div className="chart">
        <Line
          width={335}
          height={155}
          data={filterChartData(cardInfo)}
          options={filterChartLineOptions(
            chartLineOptions,
            cardInfo.datasets,
            true
          )}
        />
      </div>
    </div>
  );
}

export default Index;
