import React, { useState } from "react";

import ArrowDiffInCount from "images/icons/arrows/arrowDiffInCount.svg";

import DifferenceInCountContent from './index';
import {
    DifferenceInCountContainer,
    DifferenceInCountDescription
} from './index.style';

function DifferenceInCountWrapper({count, date, diffInCount}) {
    const [openDifferenceInCount, setOpenDifferenceInCount] = useState(false);
    return (
        <DifferenceInCountContainer style={{marginTop: "4px"}}>
            <DifferenceInCountDescription 
                onClick={() => setOpenDifferenceInCount(!openDifferenceInCount)}
                open={openDifferenceInCount} 
            >
                Diferença {(diffInCount == 0 ? "" : (diffInCount > 0 ? "Positiva" : "Negativa"))}   
                   
                <img 
                    src={ArrowDiffInCount} 
                    alt="diff in count" 
                />
            </DifferenceInCountDescription>
            
            {
                openDifferenceInCount &&
                <DifferenceInCountContent count={count} date={date} />
            }
        </DifferenceInCountContainer>
    );
}

export default DifferenceInCountWrapper;
