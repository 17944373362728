/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';

import {
    Fade,
    Grid,
    LinearProgress,
    Paper,
    Snackbar,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    Typography,
    IconButton
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

import CopyClipBoardIcon from 'images/icons/copyClipboards/copyClipboard.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import copyClipBoard from 'utils/copyClipboard';
import ValidationLength from 'utils/utils';
import Environment from 'utils/environments';
import Axios from 'axios';


export default function ProvidersTable({providersPlataform, setData, data, unit, skuId, restId, setAddProvider, setMessageProvider, messageProvider}) {
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [filterProviders, setFilterProviders] = useState()

    const environments = Environment(process.env.REACT_APP_ENV);

    const IncludeProvider = (skuId, provider) => {
      const body = {
        ...provider,
        restaurantId: restId
      }

      Axios.post(`${environments.catalog}/skus/${skuId}/providers`,
        body
      ).then(() => {
          setData([...data, provider])
          setAddProvider(true)
          setMessageProvider({
            message: 'Fornecedor Incluído',
            severity: 'success'
          })
        })
        .catch(() => {
          setMessageProvider({
            message: 'Falha ao incluir fornecedor',
            severity: 'error'
          })
        });
    };
    const areObjectsEqual = (obj1, obj2) => obj1.id === obj2.id && obj1.descriptionSku === obj2.descriptionSku;

    useEffect(() => {
      
      const uniqueToFirstArray = providersPlataform?.filter(
        obj1 => !data?.some(obj2 => areObjectsEqual(obj1, obj2))
      );

      setFilterProviders(uniqueToFirstArray)
    }, [data, providersPlataform])
  
    const useStyles = makeStyles(() => ({
        headerCell: {
          fontFamily: 'Lato',
          fontWeight: 'bold',
          fontSize: '9px',
          margin: '0 10px',
          whiteSpace: 'nowrap',
          background: '#5062F0',
          color: '#FFF',
          padding: '0 5px'
        },
        headerCellEnd: {
          fontFamily: 'Lato',
          fontWeight: 'bold',
          fontSize: '9px',
          margin: '0 10px',
          whiteSpace: 'nowrap',
          background: '#5062F0',
          color: '#FFF',
          padding: '0 5px',
          textAlign: 'end'
        },
        bodyCell:{
            fontFamily: 'Lato',
            fontSize: '12px',
            whiteSpace: 'nowrap'
          },
        bodyCellAdd: {
          fontFamily: 'Lato',
          fontSize: '12px',
          whiteSpace: 'nowrap',
          padding: '5px'
        },
        descriptionSku: {
          display: 'inline-grid',
          gridAutoFlow: 'column'
        },
        copyText: {
          cursor: 'pointer'
        },
        textSku: {
          width: '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          border: 'none',
          textOverflow: 'ellipsis'
        },
        headerRow: {
          borderBottom: '1px solid #000'
        },
        bodyRow:{
            background: '#fff'
          },
        headerActions: {
          width: '7%',
          minWidth: '60px',
          maxWidth: '60px',
          padding: '0',
          textAlign: 'center',
          background: '#5062F0'
        },
        bodyActions: {
          // padding: '10px',
          whiteSpace: 'nowrap',
          padding: '0'
        },
        actionIcon:{
            margin: '0',
            color: '#757575'
          },
        inputRoot: {
          fontFamily: 'Lato',
          fontSize: '12px'
        },
        intput: {
          fontFamily: 'Lato',
          fontSize: '12px'
        },
        option: {
          fontFamily: 'Lato',
          fontSize: '12px'
        }
      }));
    const classes = useStyles();

    
    return (

        <TableContainer
          component={Paper}
          style={{
            borderRadius: 4,
            padding: '0',
            boxShadow: '8px 8px 4px 0px #CECECF'
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow classes={{ head: classes.headerRow }}>
                <TableCell width="20%" className={classes.headerCell}>
                  FORNECEDOR
                </TableCell>
                <TableCell width="5%" className={classes.headerCell}>
                  UF
                </TableCell>
                <TableCell width="10%" className={classes.headerCell}>
                  CÓD. FORNEC.
                </TableCell>
                <TableCell width="41%" className={classes.headerCell}>
                  DESCRIÇÃO FORNECEDOR
                </TableCell>
                <TableCell width="6%" className={`${classes.headerCellEnd} p10`}>
                  R$ EMB.
                </TableCell>
                <TableCell width="4%" className={`${classes.headerCell} p20h`}>
                  EMBALAGEM
                </TableCell>
                <TableCell width="6%" className={classes.headerCellEnd}>
                  QTD. EMB.
                </TableCell>
                <TableCell width="10%" className={classes.headerCellEnd}>
                  R$ SKU
                </TableCell>
                <TableCell className={classes.headerActions} />
              </TableRow>
            </TableHead>
  
            <TableBody>
            {filterProviders?.length ? filterProviders?.map((provider, index) => (
                <TableRow hover={false}>
                  <TableCell
                    className={classes.bodyCellAdd}
                    style={{ paddingRight: 0 }}
                  >
                      {ValidationLength(
                        `${provider.name || ''}`,
                        25
                      )}
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                      {provider?.suppliers?.addresses[0]?.state || '-'}
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                    {ValidationLength(
                      provider.codeProvider ? provider.codeProvider : '',
                      15
                    )}
                  </TableCell>

                  <TableCell className={`${classes.bodyCellAdd}`}>
                    <div className={`${classes.descriptionSku}`}
                    id={`provider-description-${index}`}>
                      {provider?.descriptionSku && provider?.descriptionSku !== null ? (
                        <Tooltip
                            placement="bottom"
                            title={copied ? 'Copiado' : 'Copiar'}
                          >
                            <a
                              onMouseLeave={() => {
                                setTimeout(() => {
                                  setCopied(false);
                                }, 1000);
                              }}
                              onClick={() => {
                                copyClipBoard(
                                  'provider-description',
                                  index,
                                  ''
                                );
                                setCopied(true);
                              }}
                              className={classes.copyText}
                            >
                              <img
                                style={{
                                  marginRight: '5px',
                                  marginBottom: '3px'
                                }}
                                src={CopyClipBoardIcon}
                              />
                            </a>
                          </Tooltip>
                      ) : null}

                      <Tooltip
                        placement="bottom"
                        title={provider?.descriptionSku}
                        disableHoverListener={provider?.descriptionSku?.length < 30}
                      >
                        <div
                          className={classes.textSku}
                        >
                          {provider?.descriptionSku}
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>

                  <TableCell className={`${classes.bodyCellAdd} alnright`}>
                    <CurrencyTextField
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      digitGroupSeparator="."
                      decimalCharacter=","
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      required
                      disabled
                      name="providerPurchaseLast"
                      fullWidth
                      placeholder="R$ Emb."
                      className="textFieldSmall right"
                      value={
                        // eslint-disable-next-line no-nested-ternary
                        provider.skuId && provider.purchaseLast
                          ? provider.purchaseLast || 0
                          : provider.purchaseLastUnitary
                          ? provider.purchaseLastUnitary * (provider.quantity || 0)
                          : 0
                      }
                    />
                  </TableCell>

                  <TableCell className={`${classes.bodyCellAdd} p20h`}>
                    {`E${provider.quantity ? provider.quantity : 0}x${
                      provider.quantity || 0
                    }${unit ? unit.abbreviation : ''}`}
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                    <CurrencyTextField
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      digitGroupSeparator="."
                      decimalCharacter=","
                      decimalPlaces={2}
                      decimalPlacesShownOnFocus={2}
                      type="text"
                      required
                      disabled
                      name="packageQuantity"
                      fullWidth
                      placeholder="Qtd. Embalagem"
                      className="textFieldSmall right"
                      value={provider.quantity || 0}
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      
                    />
                  </TableCell>

                  <TableCell
                    className={`${classes.bodyCellAdd} alnright pRelative`}
                    style={{padding: '5px'}}
                  >
                      <div>
                        <CurrencyTextField
                          size="small"
                          currencySymbol=""
                          minimumValue="0"
                          outputFormat="string"
                          digitGroupSeparator="."
                          decimalCharacter=","
                          decimalPlaces={3}
                          decimalPlacesShownOnFocus={3}
                          type="text"
                          required
                          disabled
                          name="skuPurchaseLast"
                          fullWidth
                          placeholder="R$ SKU"
                          className="textFieldSmall right"
                          value={
                            provider.skuId &&
                            provider.purchaseLast &&
                            provider.quantity
                              ? provider.purchaseLast / provider.quantity || 0
                              : (provider.purchaseLastUnitary
                                  ? provider.purchaseLastUnitary
                                  : 0) || 0
                          }
                          
                        />
                      </div>
                  </TableCell>

                  <TableCell
                    className={`${classes.bodyCellAdd} alnright`}
                    style={{padding: '5px 15px 5px 5px'}}
                  >
                    <Tooltip
                      placement="bottom-start"
                      title='Utilizar'
                    >
                      <IconButton
                        className="p0"
                        edge="start"
                        color="inherit"
                        style={{ outline: 0 }}
                        aria-label="add"
                        type='submit'
                        onClick={() => {
                          IncludeProvider(skuId, provider)
                        }}
                      >
                        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            :
            <TableRow hover={false} colSpan="8">
              <TableCell
                  className={classes.bodyCellAdd}
                  style={{ width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}
                  colSpan="8"
                >
                  Nenhum Fornecedor/Embalagem disponivel para este sku
                </TableCell>
            </TableRow>
            }
  
              {loading ? (
                <TableRow>
                  <TableCell colSpan="8" sytle={{ margin: '10px' }}>
                    <LinearProgress variant="query" />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>

        </TableContainer>
    )
}