import styled from 'styled-components';

export const QuotesWrap = styled.div`
    margin: 8px;
    padding: 12px;
    background-color: #FFFFFF; 
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    border-radius: 4px;
    font-family: Lato;
    font-style: normal;
`;

export const QuotesTitle = styled.h1`
    margin: 0;
    color: #5062F0;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
`;

export const QuotesParagraph = styled.p`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
`;

export const FormSection = styled.section`
    margin-top: 22px;
    display: flex;
    justify-content: flex-start;
`;

export const InputFile = styled.input`    
    width: 100%;
    opacity: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;

    input {
        cursor: pointer;
    }
`;

export const UploadButton = styled.button`
    position: relative;
    transition: all 200ms ease-out;
    background-color:${({disabled}) => disabled ? '#939bdd' : '#5062F0'};
    width: ${({width}) => width ? width : '91px'};
    height: 40px;
    color: white;
    border-radius: 8px;
    margin-top: 5px;
    cursor: pointer;
    border: none;
`;

export const UploadText = styled.span`
    font-size: 12px;
    cursor: pointer;
    img {
        margin-right: 5px;
        margin-top: -5px;
    }
    span {
        margin-right: 5px;
    }
`;

export const FileNameWrapper = styled.div`
    padding: 15px;
    img { 
        margin-bottom: 10px;
        cursor: pointer;
        margin-left: 4px;
        width: 7px;
    }
`;
