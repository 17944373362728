import React from 'react';

export function CalendarIcon({ fill }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8 1C5.8 0.447715 5.35228 0 4.8 0C4.24772 0 3.8 0.447715 3.8 1V2H2.2C0.984973 2 0 2.98497 0 4.2V13.8C0 15.015 0.984974 16 2.2 16H13.8C15.015 16 16 15.015 16 13.8V4.2C16 2.98497 15.015 2 13.8 2H12.2V1C12.2 0.447715 11.7523 0 11.2 0C10.6477 0 10.2 0.447715 10.2 1V2H5.8V1ZM10.2 4H5.8C5.8 4.55228 5.35228 5 4.8 5C4.24772 5 3.8 4.55228 3.8 4H2.2C2.08954 4 2 4.08954 2 4.2V6H14V4.2C14 4.08954 13.9105 4 13.8 4H12.2C12.2 4.55228 11.7523 5 11.2 5C10.6477 5 10.2 4.55228 10.2 4ZM2 13.8V8H14V13.8C14 13.9105 13.9105 14 13.8 14H2.2C2.08954 14 2 13.9105 2 13.8Z"
        fill={fill}
      />
    </svg>
  );
}
