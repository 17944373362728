import React, { useState } from 'react';

import { Typography } from '@material-ui/core';
import { KeyboardArrowUp } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import numberToReal from 'utils/numberToReal';
import { shortenString } from 'utils/shortenString';

import { QDCollapse, QuantityAndDurationDialogCard } from './styles';

function QuantityCard({restaurant, getColorStatus}) {
  const [openCollapse, setOpenCollapse] = useState(false)

  return (
    <QuantityAndDurationDialogCard>
        <div>
          <div>
            <div>
              <Typography style={{fontSize: "12px", width: "120px"}} >
                {shortenString(restaurant?.fantasyName, 16)}
              </Typography> 
            </div>
            <div className="quantity" style={getColorStatus(restaurant.isBreak)} >
              {numberToReal(restaurant?.realStock, 2)}Kg
            </div>
          </div>
            <div>
            
            <div className="duration" >
              {restaurant?.duration} Dias
            </div>
            
            <div onClick={() => setOpenCollapse(!openCollapse)} >
              {openCollapse ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
            </div>
          </div>  
        </div>
        {openCollapse ? 
        <QDCollapse>
          <div>
            <p style={{fontWeight: "700", fontSize: "9px"}} >UNIT R$</p>
            <Typography style={{fontSize: "12px"}} >
              {numberToReal(restaurant?.costStock, 2)}/Kg
            </Typography>
          </div>
          <div style={{marginLeft: "12px", marginRight: "8px"}} >
            <p style={{fontWeight: "700", fontSize: "9px"}} >TOTAL R$</p>
            <Typography style={{fontSize: "12px"}} >
              {numberToReal(restaurant?.totalPriceStock, 2)}
            </Typography>
          </div>
        </QDCollapse>
        
        : null}
        <hr style={{margin: "8px 12px"}}  />
        
      </QuantityAndDurationDialogCard>
  )
}

export default QuantityCard;