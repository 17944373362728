/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useParams } from 'react-router';

import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import categorieArrowProduct from 'images/icons/arrows/categorieArrowProduct.svg';
import makeGet from 'services/useFetch/makeGet';
import makePost from 'services/useFetch/makePost';
import makePut from 'services/useFetch/makePut';
import { ShortenStringTooltip } from 'utils/shortenString';

import {
  ProductItem,
  ProductDescription,
  SkuItemWrapper,
  ProductCategorieSection,
  LinkWrapper
} from './style';

import ProductFormDialog from '../catalog/products/dialogs/productFormDialog';

function ProductItemCategory({ node, getCategories, userInfo, skuDialog }) {
  const isAdminPage = userInfo?.profile?.[0]?.[0]?.name === 'admin';
  const [toastProps, setToastProps] = useState();
  const [toastOpen, setToastOpen] = useState(false);
  const [openProductTree, setOpenProductTree] = useState(false);
  const [openSkuTree, setOpenSkuTree] = useState([]);
  const [openProductForm, setOpenProductForm] = useState(false);
  const [openSkuDialog, setOpenSkuDialog] = useState(false);
  const [currentProductId, setCurrentProductId] = useState(null);
  const [currentSku, setCurrentSku] = useState({});
  const [currentSkuData, setCurrentSkuData] = useState([]);
  const [currentSkuList, setCurrentSkuList] = useState([]);
  const { id: paramsID } = useParams();

  const handleOnSaveSkuPlatform = ({ body, isEditMode }) => {
    const handleResponse = (status, response) => {
      const verb = isEditMode ? 'atualizado' : 'criado';

      if (status) {
        setToastProps({
          severity: 'success',
          message: `SKU ${verb} atualizado com sucesso`,
          timeOutOff: true
        });

        setToastOpen(true);
        getCategories();
        setOpenSkuDialog(!openSkuDialog);
        return;
      }
      setToastProps({
        severity: 'error',
        message: 'Algo deu errado, por favor tente mais tarde',
        timeOutOff: true
      });

      setToastOpen(true);
      setOpenSkuDialog(!openSkuDialog);
    };

    const { id } = body;

    if (isEditMode) {
      makePut('skuCards', body, handleResponse, `/platform/${id}`);
    } else {
      makePost('skuCards', body, handleResponse, `/platform`);
    }
  };

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleOpenSkuTree = (id) => {
    const isAlreadyAdd = openSkuTree.find((item) => item === id);
    if (isAlreadyAdd) {
      const newSkuTree = openSkuTree.filter((item) => item !== id);
      setOpenSkuTree([...newSkuTree]);
      return;
    }
    setOpenSkuTree([...openSkuTree, id]);
  };

  const handleSkuResponse = (response) => {
    if (!response) return;
    const { content = [] } = response;

    if (!content.length) return;

    const [firstContent] = content;

    const { providers = [] } = firstContent;

    setCurrentSku(firstContent);
    setCurrentSkuList(content);
    setCurrentSkuData(providers);
    setOpenSkuDialog(!openSkuDialog);
  };

  const handleOpenSkuDialog = (id, skuDescription) => {
    makeGet(
      isAdminPage ? 'skuPlatformCards' : 'skuCards',
      [
        { urlParam: `originId=${isAdminPage ? 0 : paramsID}` },
        { urlParam: 'page=1' },
        { urlParam: 'size=1000' },
        { urlParam: `descriptionSku=${skuDescription}` }
      ],
      handleSkuResponse
    );
  };

  return (
    <ProductItem openProductTree={openProductTree}>
      <section
        onClick={() => {
          setOpenProductTree(!openProductTree);
        }}
      >
        <div>
          {node?.products?.length}{' '}
          {node?.products?.length > 1 ? 'PRODUTOS' : 'PRODUTO'}
        </div>

        <div>
          <img src={categorieArrowProduct} alt="Arrow product" />
        </div>
      </section>

      {openProductTree &&
        node?.products?.map((product) => {
          const isSkuTreeOpen = !!openSkuTree.find(
            (item) => item === product.id
          );
          return (
            <ProductDescription>
              <ProductCategorieSection
                className="dFlex justifyBetween cursorPointer"
                onClick={() => {
                  !!product.skus?.length && handleOpenSkuTree(product.id);
                }}
                openSkuTree={isSkuTreeOpen}
              >
                <LinkWrapper
                  onClick={() => {
                    setOpenProductForm(!openProductForm);
                    setCurrentProductId(product.id);
                  }}
                >
                  {product.description}
                </LinkWrapper>

                <div className="dFlex">
                  {product.skus.length ? (
                    <p className="mb0 mr10">{product.skus?.length}</p>
                  ) : null}

                  {!!product.skus?.length && (
                    <img src={categorieArrowProduct} alt="Arrow product" />
                  )}
                </div>
              </ProductCategorieSection>

              {!!product.skus?.length && isSkuTreeOpen && (
                <SkuItemWrapper>
                  {product.skus.map((sku) => (
                    <LinkWrapper
                      onClick={() => {
                        handleOpenSkuDialog(sku?.skuId, sku?.skus?.description);
                      }}
                    >
                      <p>
                        <ShortenStringTooltip
                          string={sku.skus.description}
                          length={48}
                        />
                      </p>
                    </LinkWrapper>
                  ))}
                </SkuItemWrapper>
              )}

              {!isSkuTreeOpen && (
                <hr
                  style={{
                    marginTop: '5px',
                    marginBottom: '0px',
                    borderTop: '2px solid #9296AC'
                  }}
                />
              )}
            </ProductDescription>
          );
        })}

      {openProductForm && (
        <ProductFormDialog
          openDialog={openProductForm}
          consumptionUnits={skuDialog.consumptionUnits}
          storageLocations={skuDialog.storageLocations}
          periodicities={skuDialog.periodicities}
          onClose={(refreshList) => {
            setOpenProductForm(!openProductForm);
            if (refreshList) {
              getCategories();
            }
          }}
          currentProductId={currentProductId}
        />
      )}

      {openSkuDialog && (
        <SkuModal
          fullScreen
          maxWidth={null}
          fullWidth
          open={openSkuDialog}
          data={currentSkuData}
          getId={paramsID}
          editMode
          setUpdateMainList={() => {
            getCategories();
          }}
          sku={currentSku}
          userInfo={userInfo}
          loadModal
          providers={skuDialog.providers}
          packageTypes={skuDialog.packageTypes}
          categories={skuDialog.categories}
          brands={skuDialog.brands}
          storageLocations={skuDialog.storageLocations}
          periodicities={skuDialog.periodicities}
          unitsMeasurements={skuDialog.unitsMeasurements}
          skus={currentSkuList}
          adminCatalogModal={!!isAdminPage}
          onClose={() => {
            setOpenSkuDialog(!openSkuDialog);
          }}
          handleOnSave={isAdminPage ? handleOnSaveSkuPlatform : false}
        />
      )}

      <Snackbar
        open={toastOpen}
        onClose={handleCloseToast}
        autoHideDuration={2000}
      >
        <Alert onClose={handleCloseToast} severity={toastProps?.severity}>
          {toastProps?.message}
        </Alert>
      </Snackbar>
    </ProductItem>
  );
}

export default ProductItemCategory;
