/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';

import TextField from '@material-ui/core/TextField';

import {
  formatCountValues
} from 'pages/inventory/diary-count/count/MainFunctions';

const formatValue = (value) => {
  if (!value && value !== 0) return value;

  if (typeof value !== 'string') {
    return new Intl.NumberFormat('pt-br', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    }).format(value);
  }

  const matchValues = value.match(/[+,]/g);

  if (!matchValues) {
    const number = parseFloat(value);
    return new Intl.NumberFormat('pt-br', {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3
    }).format(number);
  }
  return value;
};

function CurrencyTextFieldSumeValues({
  id,
  key,
  name,
  placeholder,
  onKeyDown,
  onBlur,
  onFocus,
  confirmModalAction,
  onFocusProps,
  onBlurProps,
  defaultValue,
  type,
  label,
  disabled = false,
  isLast = false,
  sourcePage = '',
  inputMode,
  setModalStates,
  modalStates,
  setModalConfirmCountObj,
  handleBlurCountObj,
  parametersInfos,
  product,
  products,
  countObj,
  updatedValue,
  setUpdateValueByModal,
  setCurrentInput,
  updateQuantityInitialValue,
  handleSetLastProductCounted = () => { }
}) {
  const [textValue, setTextValue] = useState(formatValue(defaultValue));
  const [originalDefaultValue, setOriginalDefaultValue] = useState(formatValue(defaultValue));
  const [somaBleValues, setSomableValues] = useState([]);
  const [stringSomableValues, setStringSomableValues] = useState('');
  const textFieldRef = useRef(null);

  const isInvalidToCount = (replacedValue) => {
    const differenceRealAndTheoretical = 100 - ((formatCountValues(countObj, replacedValue, products)?.realStock / product?.theoricalStock) || 0) * 100;

    return Math.abs(differenceRealAndTheoretical) > parametersInfos.counts.differencePercentage 
  }

  const valueToSum = (value) => {
    if (value === '+') {
      return;
    }
    if (value === '') {
      setTextValue(null);
      setSomableValues([]);
      return;
    }
    const replacedValues = value.replace(/[^0-9+,]/g, '');
    setTextValue(replacedValues);
    setStringSomableValues(replacedValues);
    const splitedValues = value.split('+');
    setSomableValues(splitedValues);
  };

  const handleOnBlur = (e) => {
    const validList = somaBleValues.filter((element) => element.length > 0);

    const reducer = (accumulator, currentValue) => {
      const newAccumulator =
        typeof accumulator === 'number'
          ? accumulator
          : accumulator.replaceAll('.', '').replaceAll(',', '.');
      const newCurrentValue =
        typeof currentValue === 'number'
          ? currentValue
          : currentValue.replaceAll('.', '').replaceAll(',', '.');
      return parseFloat(newAccumulator) + parseFloat(newCurrentValue);
    };

    const someValues = validList?.length ? validList?.reduce(reducer) : textValue;
    const replacedValue =
      typeof someValues === 'number'
        ? someValues
        : someValues?.replaceAll('.', '')?.replaceAll(',', '.');

    if (countObj) {
      setCurrentInput({
        input: e,
        isLast,
        ref: textFieldRef
      })

      const isLastStockCount = product?.stocks?.countedStocks + 1 >= product?.stocks?.totalStocks
      
      // if (isInvalidToCount(replacedValue) && isLastStockCount && e?.target?.value !== '') {
      //   if (parseFloat(defaultValue) === parseFloat(e.target.value?.replaceAll(',', '.'))) {
      //     const formattedValue = formatValue(e.target.value);
      //     setTextValue(formattedValue);
      //     return;
      //   };
      //   setModalStates({
      //     ...modalStates,
      //     confirmCount: true
      //   });
      //   setModalConfirmCountObj({
      //     stringSomableValues,
      //     replacedValue,
      //     handleBlurCountObj,
      //     defaultValue,
      //     product
      //   });
      //   setStringSomableValues('')
      //   return;
      // }
    }
    
    if (textValue && !somaBleValues?.length) {
      valueToSum(textValue);
    }

    const formattedValue = formatValue(e.target.value);
    const validateDefaultValue = typeof defaultValue === 'number' ? defaultValue === null || defaultValue === '' : !defaultValue?.length
    const invalidNumber = someValues 
      ? 
        typeof someValues === 'number' ? someValues < 0.001 : parseFloat(someValues.replaceAll('.', '').replaceAll(',', '.')) < 0.001
      :
        true

    setTextValue(formattedValue);

    if (!validList.length) {

      if (validateDefaultValue && !stringSomableValues) return;

      if (originalDefaultValue === textValue) return;

      onBlur(stringSomableValues, null, e, isLast);
      setUpdateValueByModal && setUpdateValueByModal(0)
      setOriginalDefaultValue(textValue);
    } else {
      if ((!somaBleValues?.length || !stringSomableValues) && validateDefaultValue)
        return;

      if (formatValue(defaultValue) === textValue) return;

      if (invalidNumber && sourcePage !== 'countPage') {
        setTextValue(formatValue(defaultValue));
        setModalStates({
          ...modalStates,
          modalInvalidQuantity: true
        });
      } else {
        onBlur(stringSomableValues, replacedValue, e, isLast);
        setUpdateValueByModal && setUpdateValueByModal(0)
        setOriginalDefaultValue(textValue);
      }
    }
  };

  const handleValue = (e) => {
    e.preventDefault();
    const { value } = e.target;
    
    valueToSum(value);
  };

  const handleFocus = () => {
    if (originalDefaultValue === textValue) return;

    onFocus(onFocusProps);
  };

  useEffect(() => {
    textFieldRef.current.setAttribute('autocomplete', 'off');
  }, []);

  useEffect(() => {
    if (!updatedValue) return;
    let formattedValue = formatValue(originalDefaultValue);
    if(confirmModalAction === 'confirm'){
      setOriginalDefaultValue(formatValue(defaultValue));
      formattedValue = formatValue(defaultValue);
    }
    if(confirmModalAction === 'cancel'){
      formatCountValues(countObj, defaultValue, products, true);
    }

    setTextValue(formattedValue);
  }, [updatedValue])

  return (
    <TextField
      inputProps={{
        inputMode
      }}
      disabled={disabled}
      tabIndex={1}
      id={id}
      key={key}
      className="textFieldSmall"
      type={type || 'text'}
      variant="outlined"
      size="small"
      name={name}
      fullWidth
      placeholder={placeholder}
      InputProps={{ style: { fontFamily: 'Lato', textAlign: 'right' } }}
      onKeyDown={(e) => {
        const KeyID = window.e ? e.keyCode : e.keyCode;
        onKeyDown && onKeyDown(e, isLast);
        
        if (e.key === 'Tab' || e.key === 'Enter') {
          e.preventDefault();
          textFieldRef.current.focus();
        }
        
        if (sourcePage === 'countPage') return;
       
        if (KeyID === 13 || KeyID === 9) {
          handleOnBlur(e);
        }
      }}
      value={textValue === 'NaN' || textValue === NaN || textValue === null ? '' : textValue}
      onBlur={(e) => handleOnBlur(e)}
      onChange={(e) => handleValue(e)}
      defaultValue={defaultValue}
      autocomplete="off"
      inputRef={textFieldRef}
      onFocus={handleFocus}
      label={label}
    />
  );
}

export default CurrencyTextFieldSumeValues;