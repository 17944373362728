/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import orderbyBinary from 'images/icons/arrows/orderbyBinary.svg';
import orderbyBinaryGray from 'images/icons/arrows/orderbyBinaryGray.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function DiscardPercentageAndValue({
  open,
  skuDescription,
  setModalStates,
  modalStates,
  handleClose,
  setProductModal,
  countDate,
  infoDiscard,
  setInfoDiscard,
  currentRestaurant,
  setModalSettings,
  modalSettings,
  initialDate,
  countId,
  countGroupInfos
}) {
  const { id: paramsID } = useParams();
  const userInfo = useSelector((currState) => currState.user);
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  // const { currentRestaurant, currentStartDate, currentEndDate } =
  //   modalSettings.find((modal) => modal.name === 'discardPercentageAndValue');
  const [initialValue, setInitialValue] = useState(skuDescription);
  const [discardReq, setDiscardReq] = useState(0)
  const [products, setProducts] = useState([]);
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    mainLoading: false,
    page: 1,
    totalPages: 0,
    size: 100,
    filters: [
      currentRestaurant && {
        param: 'restaurantId',
        value: stores[0].find((el) => el.name === currentRestaurant)?.id,
        urlParam: `restaurantId=${
          stores[0].find((el) => el.name === currentRestaurant)?.id
        }`
      },
      {
        param: 'startDate',
        value: moment(initialDate || countDate).format('YYYY-MM-DD'),
        urlParam: `startDate=${moment(initialDate || countDate).format('YYYY-MM-DD')}`
      },
      {
        param: 'endDate',
        value: moment(countDate).format('YYYY-MM-DD'),
        urlParam: `endDate=${moment(countDate).format('YYYY-MM-DD')}`
      }
    ],
    numberOfElements: 0,
    totalElements: 0
  });

  const { getId, mainLoading, page, size, filters } = callSettings;
  const [currentOrder, setCurrentOrder] = useState({
    value: '',
    type: ''
  });
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Restaurantes',
        filterName: 'restaurantId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'stores',
        optionReference: 'name',
        options: stores[0],
        size: 'medium',
        storedValue: stores[0].find((el) => el.name === currentRestaurant)
          ?.name,
        disableGetOptionsOnFocus: true,
        allowClear: true,
        isSelect: true
      },
      {
        label: 'Produto',
        filterName: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'products',
        page: 1,
        options: [],
        size: 'medium',
        allowClear: true
      },
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'startDate',
        filterNameSecond: 'endDate',
        initialDate: moment(initialDate || countDate).add(3, 'hours'),
        endDate: moment(countDate).add(3, 'hours'),
        minDate: true,
        maxDate: true
      },
      {
        label: 'Variação',
        filterNameFirst: 'variationInit',
        filterNameSecond: 'variationEnd',
        placeHolder: '0,00%',
        type: 'range',
        size: 'small'
      }
    ]
  ]);

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page
    );
  };

  const handleFilter = useCallback(
    (param, value, filter, _, searchByTerm) => {
      const searchText = filter?.title || filter?.description || value;
      const filterValue = searchByTerm ? searchText : value;
      const formattedFiltersChanged =
        param === 'restaurantId' &&
        filtersChanged.find((el) => el.param === 'restaurantId').value !== value
          ? filtersChanged.map((el) => {
              if (el.param === 'productId') {
                return {
                  ...el,
                  value: '',
                  urlParam: ''
                };
              }

              return { ...el };
            })
          : filtersChanged;

      setInitialValue('');
      handleFilterOptions(
        param,
        filterValue,
        formattedFiltersChanged,
        setFiltersChanged
      );
    },
    [filtersChanged]
  );

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  }

  const getMainContent = useCallback(
    (currPage, currSize, samePage) => {
      (async () => {
        setCallSettings((prevState) => ({ ...prevState, mainLoading: true }));

        let params = filtersChanged.map((filter) => filter.urlParam).join('&');
        const countIdFormatted = !countId
          ? countGroupInfos?.countGroup?.counts
              ?.map((el) => el?.id)
              ?.filter((el) => el)
          : countId;

        if (initialValue || !filtersChanged?.some((el) => el.value)) {
          params = [`descriptionSku=${initialValue}`];
        }

        if (!discardReq && countId) {
          const response = await fetch(
            `${environments.dashBoard}/cmvView/discards?page=${
              samePage ? currPage : 1
            }&size=${currSize}&${params}&countId=${countIdFormatted}`
          );

          const productsJson = await response.json();
          setInfoDiscard(productsJson?.sort((a, b) => a?.discardMonetary > b?.discardMonetary ? 1 : -1) || []);
          setCallSettings((prevState) => ({
            ...prevState,
            totalPages: productsJson.totalPages,
            mainLoading: false,
            page: samePage ? currPage : 1,
            numberOfElements: productsJson.numberOfElements,
            totalElements: productsJson.totalElements
          }));

          setCurrentOrder({
            value: '',
            type: ''
          })

          setDiscardReq(discardReq + 1)
        } else {
          const response = await fetch(
            `${environments.dashBoard}/cmvView/discards?page=${
              samePage ? currPage : 1
            }&size=${currSize}&${params}&countId=${countIdFormatted}`
            );
            const productsJson = await response.json();
            setInfoDiscard(productsJson?.sort((a, b) => a?.discardMonetary > b?.discardMonetary ? 1 : -1) || []);
            setCallSettings((prevState) => ({
            ...prevState,
            totalPages: productsJson.totalPages,
            mainLoading: false,
            page: samePage ? currPage : 1,
            numberOfElements: productsJson.numberOfElements,
            totalElements: productsJson.totalElements
          }));
        } 
      })();
    },
    [filtersChanged, initialValue, setProducts]
  );

  const orderIcon = (value) => {
    let mainObj = {
      img: orderbyBinaryGray,
      rotate: 'rotate(0deg)',
    }

    if (currentOrder?.value === value && !currentOrder?.type) {
      mainObj = {
        ...mainObj,
        img: orderbyBinary
      }
    } 

    if (currentOrder?.value === value && currentOrder?.type === "DESC") {
      mainObj = {
        ...mainObj,
        img: orderbyBinary,
      }
    }

    if (currentOrder?.value === value && currentOrder?.type === "ASC") {
      mainObj = {
        ...mainObj,
        img: orderbyBinary,
        rotate: 'rotate(180deg)'
      }
    }

    return mainObj
  }

  const orderBy = (value, orderValue) => {
    if (currentOrder?.value !== value) {
      setCurrentOrder({
        value,
        type: 'DESC'
      })
      setInfoDiscard(infoDiscard?.sort((a, b) => a[orderValue] > b[orderValue] ? 1 : -1))
    } 
    
    if (currentOrder?.value === value && currentOrder?.type === 'DESC') {
      setCurrentOrder({
        ...currentOrder,
        type: 'ASC'
      })
      setInfoDiscard(infoDiscard?.sort((a, b) => a[orderValue] < b[orderValue] ? 1 : -1))
    }

    if (currentOrder?.value === value && currentOrder?.type === 'ASC') {
      setCurrentOrder({
        value: '',
        type: ''
      })
      setInfoDiscard(infoDiscard?.sort((a, b) => a?.discardMonetary > b?.discardMonetary ? 1 : -1))
    }
  }

  useEffect(() => {
    getMainContent(page, size, true);
  }, [getId, filtersChanged, getMainContent, page, size]);

  useEffect(() => {
    setCallSettings((prevState) => ({
      ...prevState,
      filters: [
        ...prevState.filters,
        {
          param: 'productId',
          value: '',
          urlParam: ''
        }
      ]
    }));

    const newArr = [...filterFields];
    newArr[0][1].updateDefaultValue = {
      name: '',
      title: '',
      description: ''
    };
    newArr[0][1].options = getFilterOptions(
      `${environments.catalog}/products`,
      'Produto',
      'products',
      filterFields,
      setFilterFields,
      'description',
      filtersChanged?.find((el) => el.param === 'restaurantId').value ||
        stores[0].find((el) => el.name === currentRestaurant)?.id,
      null,
      page
    );

    setFilterFields(newArr);
  }, [filtersChanged?.find((filter) => filter.param === 'restaurantId').value]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={() => {
        handleClose()
        setDiscardReq(0)
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal similarSkuDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>DESCARTES</Typography>

        <IconButton edge="start" color="inherit" onClick={() => handleClose()}>
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="close modal"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent pt5">
        <PageTitleAndFilter
          iconFilterOff
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          handleMultipleFilters={handleMultipleFiltersFunc}
          getFilterhandle={getFilterhandle}
          dynamicJustifyOff
        />

        <table>
          <thead>
            <tr>
              <th width="60%" className="subtitle pr0">
                PRODUTO
                <img
                  className="cursorPointer ml5"
                  style={{ width: 10, marginBottom: 1, transform: orderIcon('product')?.rotate }}
                  src={orderIcon('product')?.img}
                  alt={orderIcon('product')?.img}
                  onClick={() => {
                    orderBy('product', 'description')
                  }}
                />
              </th>

              <th width="8%" className="subtitle textAlignRight">
                &nbsp;
              </th>

              <th width="11%" className="subtitle textAlignRight">
                QTDE.
                <img
                  className="cursorPointer ml5"
                  style={{ width: 10, marginBottom: 1, transform: orderIcon('quantity')?.rotate }}
                  src={orderIcon('quantity')?.img}
                  alt={orderIcon('quantity')?.img}
                  onClick={() => {
                    orderBy('quantity', 'discardQuantity')
                  }}
                />
              </th>

              <th width="11%" className="subtitle textAlignRight">
                VALOR
                <img
                  className="cursorPointer ml5"
                  style={{ width: 10, marginBottom: 1, transform: orderIcon('value')?.rotate }}
                  src={orderIcon('value')?.img}
                  alt={orderIcon('value')?.img}
                  onClick={() => {
                    orderBy('value', 'discardMonetary')
                  }}
                />
              </th>

              <th width="10%" className="subtitle textAlignRight">
                %
                <img
                  className="cursorPointer ml5"
                  style={{ width: 10, marginBottom: 1, transform: orderIcon('percent')?.rotate }}
                  src={orderIcon('percent')?.img}
                  alt={orderIcon('percent')?.img}
                  onClick={() => {
                    orderBy('percent', 'discardPercentage')
                  }}
                />
              </th>
            </tr>

            <tr>
              <td colSpan="5" className="p0">
                <hr className="titleLineBorder m0" style={{ height: 0 }} />
              </td>
            </tr>
          </thead>

          <tbody>
            {infoDiscard.length ? (
              infoDiscard?.map((product, productIndex) => {
                const isLastElement = productIndex === products.length - 1;

                return (
                  <>
                    <tr>
                      <td className="description pr0 bgWhite">
                        <div
                          className="cursorPointer lightBlue"
                          onClick={() => {
                            setModalStates({ ...modalStates, modalProduct: true });
                            setProductModal(product);
                            setModalSettings(
                              modalSettings.map((modal) =>
                                modal.name === 'extracts'
                                  ? {
                                      ...modal,
                                      open: true,
                                      startDate: filtersChanged?.find(
                                        (el) => el.param === 'startDate'
                                      ).value,
                                      endDate: filtersChanged?.find(
                                        (el) => el.param === 'endDate'
                                      ).value,
                                      extractType: 'DISCARD',
                                      currentRestaurant: stores[0].find(
                                        (el) => el.name === currentRestaurant
                                      )?.id
                                    }
                                  : modal
                              )
                            );
                          }}
                        >
                          {ValidationLength(product?.description || '-', 60)}
                        </div>
                      </td>

                      <td className="description textAlignRight bgWhite">
                        {`${product?.days} ${
                          product?.days !== 1 ? 'dias' : 'dia'
                        }`}
                      </td>

                      <td className="description textAlignRight bgWhite">
                        {product?.discardQuantity
                          ? product.discardQuantity?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          : '0,000'}
                        {product?.consumptionUnitsPrimary?.abbreviation
                          ? ` ${product.consumptionUnitsPrimary.abbreviation}`
                          : ''}
                      </td>

                      <td className="description textAlignRight pr12 bgWhite">
                        {product?.discardMonetary
                          ? `R$ ${numberToReal(product?.discardMonetary, 2)}`
                          : '-'}
                      </td>

                      <td className="description textAlignRight pr12 bgWhite">
                        {`${
                          product?.discardPercentage
                            ?.toFixed(2)
                            ?.toString()
                            ?.replace('.', ',') || '0,00'
                        }%`}
                      </td>
                    </tr>

                    {!isLastElement && (
                      <tr>
                        <td colSpan="5" className="p0 bgWhite">
                          <hr
                            className="titleLineBorder m0"
                            style={{
                              height: '0px'
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan="5" className="description textAlignCenter pb8">
                  Não há itens para serem exibidos.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default DiscardPercentageAndValue;
