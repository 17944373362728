const marcasLocalizeEN = {
  title: 'Trademarks',
  headTitle: 'Brands',
  description: 'Description',
  producer: 'Producer',
  producer_plural: 'Producers',
  brandRecord: 'Record brand',
  brandRecord_plural: 'Record brands',
  registerBrand: 'Register brand',
  registerBrand_plural: 'Register brands',
  deleteTitle: 'You really like to delete this brand?',
  deleteMessageQuestion: "This action cannot be undo. You'd like to prossed?",
  deletedSuccess: 'Deleted branded Successfully!',
  saveInfoError: 'Something went wrong.',
  brands: 'Brands'
};

export default marcasLocalizeEN;
