/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import { Collapse, Divider, List, ListItem } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import './sidebar.scss';
import Graphic from 'images/icons/arrows/arrowGoalWhiteOutline.svg';
import Bell from 'images/icons/objects/bells/Bell.svg';
import Home from 'images/icons/objects/houses/Home.svg';
import Admin from 'images/menu-items/admin.svg';
import Catalogo from 'images/menu-items/catalog.svg';
import Clientes from 'images/menu-items/clients.svg';
import Cardapio from 'images/menu-items/menu.svg';
import Pedido from 'images/menu-items/orders.svg';
import Producao from 'images/menu-items/production.svg';
import Estoque from 'images/menu-items/stocks.svg';
import history from 'utils/history';
import localStorageManager from 'utils/localStorageManager';

function SidebarItem({
  depthStep = 40,
  depth = 0,
  expanded,
  item,
  open,
  ...rest
}) {
  const [collapsed, setCollapsed] = useState(false);
  const { label, subs } = item;
  const items = JSON.parse(localStorage.getItem('token'));
  const profileType = items.data.profile[0][0].name;
  const { id: companieActiveId } =
    localStorageManager.getLocalStorage('companiesActive');

  function toggleCollapse() {
    setCollapsed((prevValue) => !prevValue);
  }

  function onClickProp(e, clickItem) {
    history.push(
      clickItem.url === '/'
        ? `/${companieActiveId}`
        : `/${clickItem.url}/${companieActiveId}`
    );
  }

  function onClick(e) {
    if (Array.isArray(subs) && subs.length === 1) {
      onClickProp(e, item);
    }
    if (Array.isArray(subs) && subs.length !== 0) {
      toggleCollapse();
    }
    if (Array.isArray(subs) ? subs.length === 0 : subs === undefined) {
      onClickProp(e, item);
    }
  }

  let expandIcon;

  if (Array.isArray(subs) && subs.length && subs.length > 1) {
    expandIcon = collapsed ? (
      <ExpandLess
        className={
          'sidebar-item-expand-arrow' + ' sidebar-item-expand-arrow-expanded'
        }
      />
    ) : (
      <ExpandMore className="sidebar-item-expand-arrow" />
    );
  }

  if (!open && collapsed) {
    setCollapsed(false);
  }

  return profileType === 'admin' ? (
    <>
      <ListItem
        className={`sidebar-item ${profileType === 'admin' && 'dNone'}`}
        onClick={onClick}
        button
        dense
        {...rest}
      >
        <div className="sidebar-item-text">
          <span className="sidebar-label">{label}</span>
        </div>
      </ListItem>

      {Array.isArray(subs) ? (
        <List disablePadding dense>
          {open &&
            subs.map((subItem, index) => (
              <React.Fragment key={index}>
                {subItem === 'divider' ? (
                  <Divider style={{ margin: '6px 0' }} />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthStep={depthStep}
                    item={subItem}
                    className="sidebar-subitem"
                  />
                )}
              </React.Fragment>
            ))}
        </List>
      ) : null}
    </>
  ) : (
    <>
      <ListItem
        className="sidebar-item"
        onClick={onClick}
        button
        dense
        {...rest}
      >
        <div
          style={{ paddingLeft: depth * depthStep }}
          className="sidebar-item-content"
        >
          <div className="sidebar-item-text">
            {depth === 0 && label === 'Catálogo' ? (
              <img src={Catalogo} className="sidebar-item-img" alt="Catálogo" />
            ) : label === 'Clientes' ? (
              <img src={Clientes} className="sidebar-item-img" alt="Clientes" />
            ) : label === 'Cardápio' ? (
              <img src={Cardapio} className="sidebar-item-img" alt="Cardápio" />
            ) : label === 'Pedidos' ? (
              <img src={Pedido} className="sidebar-item-img" alt="Pedidos" />
            ) : label === 'Estoque' ? (
              <img src={Estoque} className="sidebar-item-img" alt="Estoque" />
            ) : label === 'Produção' ? (
              <img src={Producao} className="sidebar-item-img" alt="Produção" />
            ) : label === 'Admin' ? (
              <img src={Admin} className="sidebar-item-img" alt="Admin" />
            ) : label === 'Home' ? (
              <img src={Home} className="sidebar-item-img" alt="Home" />
            ) : label === 'Indicadores' ? (
              <img src={Graphic} className="sidebar-item-img" alt="Graphic" />
            ) : label === 'Notificações' ? (
              <img src={Bell} className="sidebar-item-img" alt="Graphic" />
            ) : label === 'Admin' ? (
              <img src={Admin} className="sidebar-item-img" alt="Admin" />
            ) : (
              ''
            )}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span className="sidebar-label">{label}</span>
            {expandIcon}
          </div>
        </div>
      </ListItem>

      <Collapse in={collapsed} timeout="auto" unmountOnExit>
        {Array.isArray(subs) ? (
          <List disablePadding dense>
            {subs.map((subItem, index) => (
              <React.Fragment key={index}>
                {subItem === 'divider' ? (
                  <Divider style={{ margin: '6px 0' }} />
                ) : (
                  <SidebarItem
                    depth={depth + 1}
                    depthStep={depthStep}
                    item={subItem}
                    className="sidebar-subitem"
                  />
                )}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}

function Sidebar({ depthStep, depth, expanded, open }) {
  const items = JSON.parse(localStorage.getItem('token'));

  if (localStorage.getItem('token') !== null) {
    const insolvedItems = items.data.roles.flat();

    return (
      <div className="sidebar">
        <List
          disablePadding
          dense
          className={`sidebar-list-menu ${!open && 'w100'}`}
        >
          {insolvedItems.map((sidebarItem, index) => (
            <React.Fragment key={index}>
              {sidebarItem === 'divider' ? (
                <Divider style={{ margin: '6px 0' }} />
              ) : (
                <SidebarItem
                  depthStep={depthStep}
                  depth={depth}
                  expanded={expanded}
                  item={sidebarItem}
                  open={open}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </div>
    );
  }
  return history.push('/');
}

export default Sidebar;
