const providersLocalize = {
    "Fail delete users": "Falha ao deletar usuário.",
    "Not Found users": "Usuário não encontrado",
    "It is impossible to change this information": "É impossível alterar esta informação",
    "The new passwords entered do not match": "As novas senhas inseridas não coincidem",
    "Not Found provider information": "Informações do fornecedor não encontradas",
    "ErrorEdit": "Erro ao Editar",
    "ErrorRegister": "Erro ao Cadastrar",
    "Data truncated for column 'quantity' at row 1": 'Quantidade da embalagem deve ser maior que 0',
    "Data truncated for column 'purchaseLast' at row 1": 'Valor da embalagem deve ser maior que 0'

};

export default providersLocalize;