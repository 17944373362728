import React from 'react';

import { Route, Redirect } from 'react-router-dom';

function AuthRoute(props) {
  const isLogged = !!localStorage.getItem('token');
  return isLogged ? <Redirect to="/" /> : <Route {...props} />;
}

export default AuthRoute;
