/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

function DialogCostsItems({
  openCostsModal,
  handleCloseModal,
  handleClose,
  PaperComponent,
  order
}) {
  // Modal de acréscimos/descontos de cada item da nota
  const { _id, orderItems, status } = order;
  const isReceivedOrder = status === 'RECEIVED';
  const showShippingColumns = order?.shipping > 0;
  const showSegCostsColumns = order?.segCosts > 0;
  const showDiscountColumns = order?.discount > 0;
  const showOtherCostsColumns = order?.otherCosts > 0;
  const showIcmsColumns = order?.icmsst > 0;
  const showIpiColumns = order?.ipi > 0;
  const showFcpstColumns = order?.fcpst > 0;

  const calcNumberOfColumns = useMemo(() => {
    let numberOfColumns = 5;

    if (showShippingColumns) {
      numberOfColumns += 2;
    }

    if (showSegCostsColumns) {
      numberOfColumns += 2;
    }

    if (showDiscountColumns) {
      numberOfColumns += 2;
    }

    if (showOtherCostsColumns) {
      numberOfColumns += 2;
    }

    if (showIcmsColumns) {
      numberOfColumns += 2;
    }

    if (showIpiColumns) {
      numberOfColumns += 2;
    }

    if (showFcpstColumns) {
      numberOfColumns += 2;
    }

    return numberOfColumns;
  }, [
    showDiscountColumns,
    showFcpstColumns,
    showIcmsColumns,
    showIpiColumns,
    showOtherCostsColumns,
    showSegCostsColumns,
    showShippingColumns
  ]);

  const calcPriceFinal = (itemUnitaryValue, total) => {
    if (isReceivedOrder) {
      return itemUnitaryValue;
    }

    return itemUnitaryValue + total;
  };

  const calcPackageValue = (itemUnitaryValue, total) => {
    if (isReceivedOrder) {
      return itemUnitaryValue - total;
    }

    return itemUnitaryValue;
  };

  const orderShippingTotal = useMemo(() => {
    let orderShippingTotalValues = null;

    orderItems.map((item) => {
      const itemQuantity =
        item.itemsPaymentDocument?.quantity || item.costs?.quantity;
      const itemShipping = item.sku.provider.shipping;
      const shippingTotal = itemQuantity * itemShipping;

      orderShippingTotalValues += shippingTotal;
    });

    return orderShippingTotalValues;
  }, [orderItems]);

  const orderTotalFinal = useMemo(() => {
    let orderTotalFinalValues = null;

    orderItems.map((item) => {
      const itemQuantity =
        item.itemsPaymentDocument?.quantity || item.costs?.quantity;
      const itemUnitaryValue =
        item.itemsPaymentDocument?.packageValue || item.costs.packageValue;
      const orderTotal = itemQuantity * itemUnitaryValue;

      orderTotalFinalValues += orderTotal;
    });

    if (isReceivedOrder) {
      return orderTotalFinalValues - orderShippingTotal;
    }

    return orderTotalFinalValues;
  }, [orderItems]);

  const orderSegCostsTotal = useMemo(() => {
    let orderSegCostsTotalValues = null;

    orderItems.map((item) => {
      const itemQuantity =
        item.itemsPaymentDocument?.quantity || item.costs?.quantity;
      const itemSegCosts = item.sku.provider.segCosts;
      const segCostsTotal = itemQuantity * itemSegCosts;

      orderSegCostsTotalValues += segCostsTotal;
    });

    return orderSegCostsTotalValues;
  }, [orderItems]);

  const orderDiscountTotal = useMemo(() => {
    let orderDiscountTotalValues = null;

    orderItems.map((item) => {
      const itemQuantity =
        item.itemsPaymentDocument?.quantity || item.costs?.quantity;
      const itemDiscount = item.sku.provider.discount;
      const discountTotal = itemQuantity * itemDiscount;

      orderDiscountTotalValues += discountTotal;
    });

    return orderDiscountTotalValues;
  }, [orderItems]);

  const orderOtherCostsTotal = useMemo(() => {
    let orderOtherCostsTotalValues = null;

    orderItems.map((item) => {
      const itemQuantity =
        item.itemsPaymentDocument?.quantity || item.costs?.quantity;
      const itemOtherCosts = item.sku.provider.otherCosts;
      const otherCostsTotal = itemQuantity * itemOtherCosts;

      orderOtherCostsTotalValues += otherCostsTotal;
    });

    return orderOtherCostsTotalValues;
  }, [orderItems]);

  const orderIcmsTotal = useMemo(() => orderItems?.length
      ? orderItems.reduce(
          (acc, item) => acc + item?.sku?.provider?.icmsst || 0,
          0
        )
      : 0, [orderItems]);

  const orderIpiTotal = useMemo(() => {
    let orderIpiTotalValues = null;

    orderItems.map((item) => {
      const itemQuantity =
        item.itemsPaymentDocument?.quantity || item.costs?.quantity;
      const itemIpi = item.sku.provider.ipi;
      const ipiTotal = itemQuantity * itemIpi;

      orderIpiTotalValues += ipiTotal;
    });

    return orderIpiTotalValues;
  }, [orderItems]);

  const orderFcpstTotal = useMemo(() => {
    let orderFcpstTotalValues = null;

    orderItems.map((item) => {
      const itemQuantity =
        item.itemsPaymentDocument?.quantity || item.costs?.quantity;
      const itemFcpst = item.sku.provider.fcpst;
      const fcpstTotal = itemQuantity * itemFcpst;

      orderFcpstTotalValues += fcpstTotal;
    });

    return orderFcpstTotalValues;
  }, [orderItems]);

  const orderPriceFinalTotal = useMemo(() => {
    let orderPriceFinalValues = null;

    orderItems.map((item) => {
      const itemShipping = item.sku.provider.shipping;
      const itemSegCosts = item.sku.provider.segCosts;
      const itemDiscount = item.sku.provider.discount;
      const itemIcms = item.sku.provider.icmsst;
      const itemIpi = item.sku.provider.ipi;
      const itemFcpst = item.sku.provider.fcpst;
      const itemOtherCosts = item.sku.provider.otherCosts;
      const itemQuantity =
        item.itemsPaymentDocument?.quantity || item.costs?.quantity;
      const itemUnitaryValue =
        item.itemsPaymentDocument?.packageValue || item.costs.packageValue;
      const total =
        itemShipping +
        itemSegCosts +
        itemOtherCosts +
        itemIcms +
        itemIpi +
        itemFcpst -
        itemDiscount;

      orderPriceFinalValues += itemQuantity * (itemUnitaryValue + total);
    });

    if (isReceivedOrder) {
      return orderPriceFinalValues - orderShippingTotal;
    }

    return orderPriceFinalValues;
  }, [orderItems]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openCostsModal}
      onClose={handleCloseModal}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal largeModal dialog100 costItemsDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>{`ACRÉSCIMOS E DESCONTOS DO PEDIDO ${_id}`}</Typography>

        <IconButton edge="start" color="inherit" onClick={() => handleClose()}>
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <table className="costsTable">
          <thead>
            <tr className="headerTitle">
              <th width="15%" className="subtitle right pr0 pl16 pb5 pt5">
                &nbsp;
              </th>

              <th
                width={`${85 / calcNumberOfColumns}%`}
                className="subtitle right pr8 pl8 pb5 pt5"
                style={{ borderRight: 'solid 1px rgba(0,0,0,.1)' }}
              >
                TOTAL
              </th>

              <th
                colSpan={(calcNumberOfColumns - 5) / 2}
                className="subtitle center white pr0 pl0 pb5 pt5 bgNavyBlue"
              >
                VALOR TOTAL R$
              </th>

              <th
                width={`${85 / calcNumberOfColumns}%`}
                className="subtitle right pr8 pl8 pb5 pt5"
                style={{
                  borderLeft: 'solid 1px rgba(0,0,0,.1)'
                }}
              >
                &nbsp;
              </th>

              <th
                width={`${85 / calcNumberOfColumns}%`}
                className="subtitle right pr8 pl8 pb5 pt5"
                style={{
                  borderRight: 'solid 1px rgba(0,0,0,.1)'
                }}
              >
                &nbsp;
              </th>

              <th
                colSpan={(calcNumberOfColumns - 5) / 2}
                className="subtitle center white pr0 pl0 pb5 pt5 bgNavyBlue"
              >
                VALOR UNITÁRIO R$
              </th>

              <th
                width={`${85 / calcNumberOfColumns}%`}
                className="subtitle right pr16 pl8 pb5 pt5"
                style={{ borderLeft: 'solid 1px rgba(0,0,0,.1)' }}
              >
                PREÇO
              </th>
            </tr>

            <tr className="headerSubtitle">
              <th width="15%" className="subtitle right pr0 pl16 pt5 pb5">
                &nbsp;
              </th>

              <th
                width={`${85 / calcNumberOfColumns}%`}
                className="subtitle right pr8 pl8 pt5 pb5"
                style={{ borderRight: 'solid 1px rgba(0,0,0,.1)' }}
              >
                R$
              </th>

              {showShippingColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  FRETE
                </th>
              )}

              {showSegCostsColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  SEGURO
                </th>
              )}

              {showDiscountColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  DESCONTO
                </th>
              )}

              {showIcmsColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  ICMSST
                </th>
              )}

              {showIpiColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  IPI
                </th>
              )}

              {showFcpstColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  FCP ST
                </th>
              )}

              {showOtherCostsColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  OUTRAS
                </th>
              )}

              <th
                width={`${85 / calcNumberOfColumns}%`}
                className="subtitle right pr8 pl8 pt5 pb5"
                style={{ borderLeft: 'solid 1px rgba(0,0,0,.1)' }}
              >
                QTDE
              </th>

              <th
                width={`${85 / calcNumberOfColumns}%`}
                className="subtitle right pr8 pl8 pt5 pb5"
                style={{ borderRight: 'solid 1px rgba(0,0,0,.1)' }}
              >
                EMB R$
              </th>

              {showShippingColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  FRETE
                </th>
              )}

              {showSegCostsColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  SEGURO
                </th>
              )}

              {showDiscountColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  DESCONTO
                </th>
              )}

              {showIcmsColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  ICMS ST
                </th>
              )}

              {showIpiColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  IPI
                </th>
              )}

              {showFcpstColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  FCP ST
                </th>
              )}

              {showOtherCostsColumns && (
                <th
                  width={`${85 / calcNumberOfColumns}%`}
                  className="subtitle right pr8 pl8 pt5 pb5"
                >
                  OUTRAS
                </th>
              )}

              <th
                width={`${85 / calcNumberOfColumns}%`}
                className="subtitle right pr16 pl8 pt5 pb5"
                style={{ borderLeft: 'solid 1px rgba(0,0,0,.1)' }}
              >
                FINAL R$
              </th>
            </tr>

            <tr className="headerBorder">
              <th colSpan={calcNumberOfColumns} className="titleLineBorder" />
            </tr>
          </thead>

          <tbody>
            {orderItems && orderItems.length ? (
              <>
                {orderItems.map((item, index) => {
                  let orderTotal = null;
                  const itemShipping = item?.sku?.provider?.shipping;
                  const itemSegCosts = item?.sku?.provider?.segCosts;
                  const itemDiscount = item?.sku?.provider?.discount;
                  const itemIpi = item?.sku?.provider?.ipi;
                  const itemFcpst = item?.sku?.provider?.fcpst;
                  const itemOtherCosts = item?.sku?.provider?.otherCosts;
                  const itemUnitaryValue =
                    item?.itemsPaymentDocument?.packageValue ||
                    item?.costs?.packageValue;
                  const itemQuantity =
                    item.itemsPaymentDocument?.quantity || item.costs?.quantity;
                  const itemIcms =
                    item?.sku?.provider?.icmsst && itemQuantity
                      ? item?.sku?.provider?.icmsst / itemQuantity
                      : 0;

                  const total =
                    itemShipping +
                    itemSegCosts +
                    itemOtherCosts +
                    itemIcms +
                    itemIpi +
                    itemFcpst -
                    itemDiscount;

                  const shippingTotal = itemQuantity * itemShipping;
                  if (isReceivedOrder) {
                    orderTotal =
                      itemQuantity * itemUnitaryValue - shippingTotal;
                  } else {
                    orderTotal = itemQuantity * itemUnitaryValue;
                  }
                  const segCostsTotal = itemQuantity * itemSegCosts;
                  const discountTotal = itemQuantity * itemDiscount;
                  const icmsTotal = itemQuantity * itemIcms;
                  const ipiTotal = itemQuantity * itemIpi;
                  const fcpstTotal = itemQuantity * itemFcpst;
                  const otherCostsTotal = itemQuantity * itemOtherCosts;

                  const itemPriceFinal = calcPriceFinal(
                    itemUnitaryValue,
                    total
                  );

                  const itemPackageValue = calcPackageValue(
                    itemUnitaryValue,
                    total
                  );

                  return (
                    <>
                      <tr>
                        <td
                          id={`skuDescription${index}`}
                          className="description pl16 pr0"
                        >
                          {ValidationLength(
                            item.sku.description || '-',
                            calcNumberOfColumns < 6 ? 40 : 25
                          )}
                        </td>

                        <td
                          id={`orderTotal${index}`}
                          className="description right pr8 pl8"
                          style={{ borderRight: 'solid 1px rgba(0,0,0,.1)' }}
                        >
                          {numberToReal(orderTotal)}
                        </td>

                        {showShippingColumns && (
                          <td
                            id={`shippingTotal${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(shippingTotal)}
                          </td>
                        )}

                        {showSegCostsColumns && (
                          <td
                            id={`segCostsTotal${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(segCostsTotal)}
                          </td>
                        )}

                        {showDiscountColumns && (
                          <td
                            id={`discountTotal${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(discountTotal)}
                          </td>
                        )}

                        {showIcmsColumns && (
                          <td
                            id={`icmsTotal${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(icmsTotal)}
                          </td>
                        )}

                        {showIpiColumns && (
                          <td
                            id={`ipiTotal${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(ipiTotal)}
                          </td>
                        )}

                        {showFcpstColumns && (
                          <td
                            id={`fcpstTotal${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(fcpstTotal)}
                          </td>
                        )}

                        {showOtherCostsColumns && (
                          <td
                            id={`otherCostsTotal${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(otherCostsTotal)}
                          </td>
                        )}

                        <td
                          id={`itemQuantity${index}`}
                          className="description right pr8 pl8"
                          style={{ borderLeft: 'solid 1px rgba(0,0,0,.1)' }}
                        >
                          {numberToReal(itemQuantity)}
                        </td>

                        <td
                          id={`itemPackageValue${index}`}
                          className="description right pr8 pl8"
                          style={{ borderRight: 'solid 1px rgba(0,0,0,.1)' }}
                        >
                          {numberToReal(itemPackageValue)}
                        </td>

                        {showShippingColumns && (
                          <td
                            id={`itemShipping${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(itemShipping)}
                          </td>
                        )}

                        {showSegCostsColumns && (
                          <td
                            id={`itemSegCosts${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(itemSegCosts)}
                          </td>
                        )}

                        {showDiscountColumns && (
                          <td
                            id={`itemDiscount${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(itemDiscount)}
                          </td>
                        )}

                        {showIcmsColumns && (
                          <td
                            id={`itemIcms${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(itemIcms)}
                          </td>
                        )}

                        {showIpiColumns && (
                          <td
                            id={`itemIpi${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(itemIpi)}
                          </td>
                        )}

                        {showFcpstColumns && (
                          <td
                            id={`itemFcpst${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(itemFcpst)}
                          </td>
                        )}

                        {showOtherCostsColumns && (
                          <td
                            id={`itemOtherCosts${index}`}
                            className="description right pr8 pl8"
                          >
                            {numberToReal(itemOtherCosts)}
                          </td>
                        )}

                        <td
                          id={`itemPriceFinal${index}`}
                          className="description right pr16 pl8"
                          style={{ borderLeft: 'solid 1px rgba(0,0,0,.1)' }}
                        >
                          {numberToReal(itemPriceFinal)}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={calcNumberOfColumns} className="p0">
                          <hr className="m0" />
                        </td>
                      </tr>
                    </>
                  );
                })}

                <tr>
                  <td colSpan={calcNumberOfColumns} className="p0">
                    <hr style={{ borderTop: '1px solid' }} className="m0" />
                  </td>
                </tr>

                <tr>
                  <td id={`order${_id}`} className="description pr0 pl16">
                    <b>{ValidationLength('Pedido', 30, true)}</b>
                  </td>

                  <td
                    id={`orderTotalFinal${_id}`}
                    className="description right pr8 pl8"
                    style={{ borderRight: 'solid 1px rgba(0,0,0,.1)' }}
                  >
                    <b>{numberToReal(orderTotalFinal)}</b>
                  </td>

                  {showShippingColumns && (
                    <td
                      id={`orderShippingTotal${_id}`}
                      className="description right pr8 pl8"
                    >
                      <b>{numberToReal(orderShippingTotal)}</b>
                    </td>
                  )}

                  {showSegCostsColumns && (
                    <td
                      id={`orderSegCostsTotal${_id}`}
                      className="description right pr8 pl8"
                    >
                      <b>{numberToReal(orderSegCostsTotal)}</b>
                    </td>
                  )}

                  {showDiscountColumns && (
                    <td
                      id={`orderDiscountTotal${_id}`}
                      className="description right pr8 pl8"
                    >
                      <b>{numberToReal(orderDiscountTotal)}</b>
                    </td>
                  )}

                  {showIcmsColumns && (
                    <td
                      id={`orderIcmsTotal${_id}`}
                      className="description right pr8 pl8"
                    >
                      <b>{numberToReal(orderIcmsTotal)}</b>
                    </td>
                  )}

                  {showIpiColumns && (
                    <td
                      id={`orderIpiTotal${_id}`}
                      className="description right pr8 pl8"
                    >
                      <b>{numberToReal(orderIpiTotal)}</b>
                    </td>
                  )}

                  {showFcpstColumns && (
                    <td
                      id={`orderFcpstTotal${_id}`}
                      className="description right pr8 pl8"
                    >
                      <b>{numberToReal(orderFcpstTotal)}</b>
                    </td>
                  )}

                  {showOtherCostsColumns && (
                    <td
                      id={`orderOtherCostsTotal${_id}`}
                      className="description right pr8 pl8"
                    >
                      <b>{numberToReal(orderOtherCostsTotal)}</b>
                    </td>
                  )}

                  <td
                    id={`orderShipping${_id}`}
                    className="description right pr8 pl8"
                    style={{ borderLeft: 'solid 1px rgba(0,0,0,.1)' }}
                  >
                    &nbsp;
                  </td>

                  <td
                    id={`orderShipping${_id}`}
                    className="description right pr8 pl8"
                  >
                    &nbsp;
                  </td>

                  {showShippingColumns && (
                    <td
                      id={`orderShipping${_id}`}
                      className="description right pr8 pl8"
                    >
                      &nbsp;
                    </td>
                  )}

                  {showSegCostsColumns && (
                    <td
                      id={`orderShipping${_id}`}
                      className="description right pr8 pl8"
                    >
                      &nbsp;
                    </td>
                  )}

                  {showDiscountColumns && (
                    <td
                      id={`orderShipping${_id}`}
                      className="description right pr8 pl8"
                    >
                      &nbsp;
                    </td>
                  )}

                  {showOtherCostsColumns && (
                    <td
                      id={`orderShipping${_id}`}
                      className="description right pr8 pl8"
                    >
                      &nbsp;
                    </td>
                  )}

                  <td
                    id={`orderTotal${_id}`}
                    className="description right pr16 pl8"
                  >
                    {/* <b>{numberToReal(orderPriceFinalTotal)}</b> */}
                    &nbsp;
                  </td>
                </tr>
              </>
            ) : (
              <tr>
                <td
                  colSpan={calcNumberOfColumns}
                  className="pt5 textAlignCenter"
                >
                  Nenhum dado disponível
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default DialogCostsItems;
