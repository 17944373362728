/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import selectAllEmpty from 'images/icons/empties/selectAllIconEmpty.svg';
import selectAllEmptyWhite from 'images/icons/empties/selectAllIconEmptyWhite.svg';
import selectAll from 'images/icons/signals/removesAndDeletes/selectAllIcon.svg';
import { selectAllProducts } from 'store/reducers/quotationSelectProducts';

import { SelectAllIcon } from './style';

function SelectAll({
  tableData,
  setTableData,
  tableSelectedItems,
  setTableSelectedItems,
  description,
  darkMode,
  currentPage,
  pageNumber,
  storedQuotationPagesSelected
}) {
  const [selectAllState, setSelectAllState] = useState(
    storedQuotationPagesSelected?.includes(pageNumber)
  );

  const dispatch = useDispatch();
  const { collunms = [] } = tableData;
  const allFirstItems = collunms.map((item) => item[0]);

  useEffect(() => {
    if (currentPage && pageNumber) {
      setSelectAllState(storedQuotationPagesSelected?.includes(pageNumber));
      return;
    }

    if (!tableSelectedItems?.length) {
      setSelectAllState(false);
    }
  }, [tableSelectedItems, pageNumber]);

  const handleSelectAll = () => {
    const newTableData = tableData.collunms.map((element) =>
      element.map((item) => {
        if (item.action === 'selectItem') {
          item.selected = !selectAllState;
        }
        return item;
      })
    );

    setTableData({ ...tableData, collunms: newTableData });
    setSelectAllState(!selectAllState);

    if (selectAllState && tableSelectedItems.length) {
      setTableSelectedItems([]);
      return;
    }

    setTableSelectedItems(allFirstItems.filter((el) => !el?.disabledMessage));
  };

  const handleReduxSelectAll = (check) => {
    setSelectAllState(check);

    dispatch(
      selectAllProducts({
        selectedProducts: allFirstItems.filter((el) => !el?.disabledMessage),
        selectAll: check,
        currentPage: pageNumber
      })
    );
  };

  return (
    <>
      <SelectAllIcon
        onClick={() =>
          currentPage
            ? handleReduxSelectAll(!selectAllState)
            : handleSelectAll()
        }
        src={
          selectAllState && storedQuotationPagesSelected[0] === 1
            ? selectAll
            : darkMode
            ? selectAllEmptyWhite
            : selectAllEmpty
        }
      />

      {description ? (
        <span
          onClick={() =>
            currentPage
              ? handleReduxSelectAll(!selectAllState)
              : handleSelectAll()
          }
          className="ml5 cursorPointer fontSizeDefault"
        >
          {description}
        </span>
      ) : null}
    </>
  );
}

export default SelectAll;
