/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = '#1F2445';
ChartJS.defaults.font.family = 'Lato'

function SimpleLineGraphic({ 
  mainContent, 
  paramsID, 
  actualDateLessOne,
  actualDateLessFourteen 
}) {
  const isHoursType = mainContent?.customYLabelAfter === 'hs'
  const yMin = mainContent ? (mainContent?.yMin || Math.min(...mainContent?.contentFormatted?.map(el => el?.data)?.flat()?.map(el => el))) : 0
  const yMax = mainContent ? (mainContent?.yMax || Math.max(...mainContent?.contentFormatted?.map(el => el?.data)?.flat()?.map(el => el))) : 0
  const formattedYMin = yMin
  const formattedYMax = yMax - yMin < 2 ? yMax + 1 : yMax

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (e, value) => {
      const date = mainContent?.dates[value[0]?.index];

      if (value?.length && value[0].datasetIndex === 0) {
        window.open(
          `/cardapio/${paramsID}?startAt=${date || actualDateLessFourteen}&endAt=${date || actualDateLessOne}`,
          '_blank'
        );
      }

      if (value?.length && value[0].datasetIndex === 1) {
        window.open(
          `/admin/projecao-de-vendas/cadastro/${paramsID}?date=${date}`,
          '_blank'
        );
      }
    },
    plugins: {
      legend: {
        display: !mainContent?.noLegend,
        position: 'bottom',
        align: 'end',
        labels: {
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            weight: 700,
          }
        }
      },
      tooltip: {
        callbacks: {
          title() {
            return null
          },
          label(context) {
            let currentLabel = ''

            mainContent?.contentFormatted?.map(el => {
              if (el?.title === context?.dataset?.label) {
                currentLabel = el?.dataFormatted?.find(item => item?.index === context?.dataIndex)?.item
              } 
            })

            return currentLabel
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          color: '#915'
        },
        ticks: {
          font: {
            weight: 'bold'
          } 
        },
        grid: {
          color: '#FFF',
          borderColor: '#FFF',
          tickColor: '#FFF'
        },
        border: {
          color: '#FFF',
        }
      },
      y: {
        suggestedMin: formattedYMin,
        suggestedMax: formattedYMax,
        title: {
          color: '#915'
        },
        ticks: {
          precision: 0,
          font: {
            weight: 'bold'
          },
          callback: (value, index, labels) => {
            const afterContent = mainContent?.customYLabelAfter || ''
            
            if (isHoursType && value >= 24) {
              return `${parseFloat((value - 24)?.toFixed(0))}${afterContent}`
            }
            
            if ((value - Math.floor(value)) !== 0){
              return '';
            }
            
            return `${(value || 0)?.toLocaleString('pt-br', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}${afterContent}`;
          }
        },
        grid: {
          color: '#FFF',
          borderColor: '#FFF',
          tickColor: '#FFF'
        },
        border: {
          color: '#FFF',
        }
      }
    }
  };

  const labels = mainContent?.labels || [];
  
  const data = {
    labels,
    datasets: mainContent?.contentFormatted?.length ? 
      mainContent?.contentFormatted?.map((el, index) => (
        {
          label: el?.title,
          data: el?.data,
          dataFormatted: el?.dataFormatted,
          borderColor: el?.color,
          backgroundColor: el?.color,
          borderRadius: 15,
          barThickness: 10,
          borderDash: index === 1 ? [5, 5] : [0, 0],
        }
      )) 
    : [],
  };

  return (
    <Line options={options} data={data} />
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleLineGraphic);
