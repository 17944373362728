import styled from 'styled-components';

export const ClosureStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-right: 5px;
  }

  img {
    cursor: pointer;
    height: 16px;
  }
`;

export const LastDayInfosBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding: 12px;
  background: ${({ grayBg }) => (grayBg ? 'rgba(146, 150, 172, 0.2)' : '#FFF')};
  box-shadow: ${({ withShadow }) =>
    withShadow ? '0px 0px 7px rgb(4 6 26 / 40%)' : '0'};
  z-index: 9;

  p {
    margin-bottom: 9px;
    font-size: 16px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      &:last-of-type {
        min-width: 25%;
        text-align: right;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ProcessingInfosContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  height: 50px;

  p {
    &:first-of-type {
      margin: 0 0 3px 0 !important;
    }
  }

  &:last-of-type {
    height: auto;
    margin-bottom: ${({ show }) => (show ? '10px' : '0')};
  }
`;
