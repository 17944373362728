export function setProcess(data) {
  return {
      type: 'SET_PROCESS_DATA',
      data,
  };
}

export function createProcess(data) {
  return {
      type: 'CREATE_PROCESS_DATA',
      data,
  };
}