import styled from 'styled-components'

export const SkuDialogBody = styled.div`
`;

export const SkuDialogHead = styled.div`
  div {
    background-color: #F2F3F8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 8px;
    margin-bottom: 8px;

    p {
        font-weight: 700;
        color: #454A67;
        margin: 0;
        font-size: 9px;
      }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      p {
        font-weight: 700;
        color: #454A67;
        margin: 0;
        font-size: 9px;
      }
    }
  }
`;

export const SkuDialogCard = styled.div`
  padding: 0 8px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;

    div:first-child {
      width: 120px;
      height: 32px;
      font-size: 12px;
    }

    .uc {
      background-color: #D2F9D6;
      border-radius: 2px;
      width: 66px;
      height: 36px;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding: 2px 3.5px;
      p {
        margin: 0;
        color: #08AA15;
      }
    }

    .contacoes {
      color: #AA0816;
      width: 66px;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 4px 3.5px;
      p {
        margin: 0;
      }
      p:last-child {
        font-size: 9px;
      }
    }
    
    div:last-child {
     
    }
  }  
`;

export const CardOpenCollapse = styled.div`
  display: flex;
  flex-direction: column;
  .cardHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    div:first-child {
      
      width: 134px;
      height: 24px;
      background-color: #F2F3F8;
      border-radius: 2px;
      color: #D0D3E2;
      p {
        margin: 0;
      }
    }

    div:last-child {
      border-radius: 2px;
      width: 134px;
      height: 24px;
      background-color: #DADDF3;
      color: #0B1A8E;

      p {
        margin: 0;
      }
    }
  }
`

export const CardInfos = styled.div`
  width: 100%;
  height: 80px;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .firstData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      width: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      p:first-child {
        color: #454A67;
        font-weight: 700;
      }

      p {
        margin: 0; 
      }
    }
  }

  div:last-child {
    div {
      width: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      p:first-child {
        font-weight: 700;
        color: #454A67;
      }

      p {
        margin: 0; 
      }
    }

    div:first-child {
      width: 80px;
      font-size: 12px;
      div {
        height: 24px;
        width: 80px;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        p {
          margin: 0;
        }
      }
      
    }

    .quote {
      font-size: 12px;
      width: 80px;
    }

    .validate {
      font-size: 12px;
      width: 80px;
    }

  }
`