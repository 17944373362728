import React from 'react';

import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import 'normalize.css';
import './index.css';
import ReactGA from 'react-ga4';
import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module';

import App from './containers/App';
import i18n from './i18n/i18n';

const TRACKING_ID = 'G-55CX5E3ZE8';
ReactGA.initialize(TRACKING_ID, {
  // debug: true,
});

const tagManagerArgs = {
  gtmId: 'GTM-TTXLQMV'
};

TagManager.initialize(tagManagerArgs);

const CACHE_NAME = 'bloom-cache-v1';

self.addEventListener('activate', event => {
  event.waitUntil(
    caches.keys().then(cacheNames => Promise.all(
      cacheNames.map(cacheName => {
        if (cacheName !== CACHE_NAME) {
          return caches.delete(cacheName);
        }
      })
    ))
  );
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
