import React from 'react';

import { Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import CheckBoxIconEmpty from 'images/icons/empties/checkboxIconEmpty.svg';
import CheckBoxIcon from 'images/icons/signals/checks/checkboxIcon.svg';
import DisableCheckBox from 'images/icons/signals/removesAndDeletes/disableCheckbox.svg';
import { handleProduct } from 'store/reducers/quotationSelectProducts';

import { SelectItem } from './style';

function SelectItemList({
  selectOption,
  item,
  tableData,
  setTableData,
  tableSelectedItems = [],
  setTableSelectedItems,
  currentPage,
  disabledMessage = false
}) {
  const dispatch = useDispatch();

  const handleSelectItem = () => {
    item.selected = !selectOption;
    const isSelected = tableSelectedItems.find(
      (element) => element.id === item.id
    );
    if (isSelected) {
      const newSelectedItems = tableSelectedItems.filter(
        (element) => element.id !== item.id
      );
      setTableSelectedItems(newSelectedItems);
      setTableData({ ...tableData });
      return;
    }
    setTableSelectedItems([...tableSelectedItems, item]);
    setTableData({ ...tableData });
  };

  const handleReduxSelectItem = () => {
    dispatch(
      handleProduct({
        item
      })
    );
  };

  return disabledMessage ? (
    <Tooltip title={disabledMessage}>
      <SelectItem src={DisableCheckBox} style={{ width: 12 }} />
    </Tooltip>
  ) : (
    <SelectItem
      onClick={() =>
        currentPage ? handleReduxSelectItem() : handleSelectItem()
      }
      src={selectOption ? CheckBoxIcon : CheckBoxIconEmpty}
    />
  );
}

export default SelectItemList;
