const inventoriesLocalize = {
    "need to send originId": "need to send originId",
    "if withoutSetup is true it's necessary send the type": "if withoutSetup is true it's necessary send the type",
    "not found results according to these parameters": "not found results according to these parameters",
    "there are mandatory keys missing from request": "there are mandatory keys missing from request",
    "setups can't be empty": "setups can't be empty",
    "product not found with id": "product not found with id",
    "setup not found with id ": "setup not found with id ",
    "this association already exists": "this association already exists",
    "associate product count done": "associate product count done",
    "product not found": "product not found",
    "setup not found": "setup not found",
    "need to send a productId": "need to send a productId",
    "need to send a setupId": "need to send a setupId",
    "need to send a countDate": "need to send a countDate",
    "product already inactive in this setup": "product already inactive in this setup",
    "not found results according to these parameters": "not found results according to these parameters",
    "not found categories": "not found categories",

};

export default inventoriesLocalize;