const ProdutosLocalizeEn = {
  'need to send originId on body to complete request':
    'need to send originId on body to complete request',
  'counter successfully updated': 'counter successfully updated',
  'Need send originId': 'Need send originId',
  'status not accepted': 'status not accepted',
  'need to send noteKey and originId to complete request':
    'need to send noteKey and originId to complete request',
  'need to send restaurantIds and productIds in query parameter':
    'need to send restaurantIds and productIds in query parameter',
  'need to send providerId and ordersId':
    'need to send providerId and ordersId',
  'originId is a required parameter': 'originId is a required parameter',
  'need send query parameters': 'need send query parameters',
  'some information is missing to be able update':
    'some information is missing to be able update',
  'need send parameter': 'need send parameter',
  'need to send startDate and finishDate':
    'need to send startDate and finishDate',
  'need to send at least one item to receive':
    'need to send at least one item to receive',
  'some information is missing to be able update':
    'some information is missing to be able update',
  'items is empty': 'items is empty',
  'need send intention': 'need send intention',
  'need to send productGroupId in route params to complete request':
    'need to send productGroupId in route params to complete request',
  'objeto quotationProduct não encontrado para o identificador informado':
    'quoteProduct object not found for given identifier',
  'need to send originsId': 'need to send originsId',
  'necessário enviar o productGroupId ou associations':
    'need to send the productGroupId or associations',
  'necessário enviar pelo menos os ids do restaurante ou os ids do produto':
    'need to send at least restaurant ids or product ids',
  'need to send originIds to complete request':
    'need to send originIds to complete request',
  'Is missing information restaurantId in worksheet control':
    'Is missing information restaurantId in worksheet control.'
};

export default ProdutosLocalizeEn;
