import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makeGet from 'services/useFetch/makeGet';
import numberToReal from 'utils/numberToReal';

import { ConsumeDialogCard, ConsumeDialogHead, ConsumeDialogTotal } from './styles';

function ConsumeDialog({
  openConsumeDialog,
  setOpenConsumeDialog,
  restaurantsIds, 
  productsIds, 
}) {
  const [data, setData] = useState({})
  const [totalConsumed, setTotalConsumed] = useState(0)
  const [restaurants, setRestaurants] = useState([])
  const [loading, setLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [filters, setFilters] = useState([
    {
      param: 'originId',
      value: restaurantsIds,
      urlParam: `originId=${restaurantsIds}`
    },
    {
      param: 'productId',
      value: productsIds,
      urlParam: `productId=${productsIds}`
    },
  ]);
  
  function calculateTotalKg(response) {
    let count = 0

    for (const res of response) {
      count += parseFloat(res.consumed)
    }
    setTotalConsumed(count)
  }

  const handleItems = (response) =>  {
    if (response?.restaurants?.length == 0) {
      setLoading(false)
      setIsEmpty(true)
      return 
    }
    setData(response)
    setRestaurants(response?.restaurants)
    calculateTotalKg(response?.restaurants)
    setLoading(false)
  }

  useEffect(() => {
    makeGet('modalStoreConsumption', filters, handleItems, null);
  }, [])
  
  return (
    <Dialog
      className="defaultModal mobileModal"
      fullWidth
      maxWidth="sm"
      open={openConsumeDialog}
      aria-labelledby="draggable-dialog-title"
     >
     <DialogTitle className="modalHeader navyBlue" style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'move', fontFamily: 'Lato' }} id="draggable-dialog-title">
      <Typography>
        CONSUMO POR LOJA
      </Typography>

       <IconButton edge="start" color="inherit"
        onClick={() => setOpenConsumeDialog(false)}
       >
        <img src={CloseIconNavy} style={{ width: "8px" }} alt="close" />
       </IconButton>
     </DialogTitle>
     {loading && <div sytle={{ margin: "10px" }}><LinearProgress style={{ height: "7px" }} variant="query" /></div>}
     <DialogContent className="modalContent p0" style={{backgroundColor: "white"}} >
        {
          isEmpty ? 
          <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
            <Typography >
              Sem informação de consumo
            </Typography>  
          </div>
          :
          <>
            <ConsumeDialogHead>
              <p>{data?.productDescription}</p>
            </ConsumeDialogHead>
        
            {restaurants?.map(restaurant => (
              <ConsumeDialogCard>
                  <div>
                    <p>{restaurant?.companyName}</p>
                    <p>{numberToReal(restaurant?.consumed, 2)}{data.consumptionUnits}</p>
                  </div>
                  <hr />
                </ConsumeDialogCard>
            ))}
            <ConsumeDialogTotal>
              <div>
                <p>Total</p>
                <p>{numberToReal(totalConsumed, 2)}Kg</p>
              </div>
            </ConsumeDialogTotal>
          </>
        }
        
        
      
     
      
     </DialogContent>
     </Dialog>
  )
}

export default ConsumeDialog;
