import React, { useState, useEffect } from 'react';

import RadioButtonIcon from './RadioButtonIcon';
import SelectAllIcon from './selectAllIcon';
import SelectIcon from './SelectIcon';

function SelectButton({
  isSelected,
  setIsSelected,
  selectAllIcon = false,
  radioButton = false,
  disable = false,
  id,
  className,
  customFill
}) {
  const [internalIsSelected, setInternalIsSelected] = useState(isSelected);

  useEffect(() => {
    setInternalIsSelected(isSelected);
  }, [isSelected]);

  const handleSelectedAction = () => {
    if (disable) return;

    setIsSelected(!isSelected);
  };

  return (
    <>
      {!selectAllIcon && !radioButton && (
        <SelectIcon
          selected={internalIsSelected}
          onClick={handleSelectedAction}
          style={{ cursor: 'pointer' }}
          disable={disable}
          id={id}
          className={className}
        />
      )}

      {selectAllIcon && (
        <SelectAllIcon
          selected={internalIsSelected}
          onClick={handleSelectedAction}
          style={{ cursor: 'pointer' }}
          id={id}
          className={className}
        />
      )}

      {radioButton && (
        <RadioButtonIcon
          customFill={customFill}
          selected={isSelected}
          onClick={handleSelectedAction}
          style={{ cursor: 'pointer' }}
          disable={disable}
          id={id}
          className={className}
        />
      )}
    </>
  );
}

export default SelectButton;
