/* eslint-disable no-unused-expressions */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography
} from '@material-ui/core';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-confirm-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmModal({
  open,
  title,
  content,
  confirmText,
  cancelText,
  onConfirm,
  onClose,
  onCancel,
  disableBackDrop = false,
  disabledButton = false,
  customClass,
  loadingConfirmText = ''
}) {
  return (
    <Dialog
      open={open}
      className={`defaultModal ${disableBackDrop && 'fakePopover'} ${customClass && customClass
        }`}
      PaperComponent={PaperComponent}
      onClose={() => {
        onClose();
      }}
    >
      {!!title && (
        <DialogTitle
          id="draggable-dialog-confirm-title"
          className="modalHeader bold textAlignCenter cursorMove navyBlue"
        >
          <Typography className="white fontSizeDefault">{title}</Typography>
        </DialogTitle>
      )}

      {!!content && (
        <DialogContent
          id="draggable-dialog-confirm-title"
          className="modalContent bgWhite"
          style={{ textAlign: 'center', maxWidth: '350px' }}
        >
          {content}
        </DialogContent>
      )}

      <DialogActions className="dFlex justifyCenter">
        {!!onClose && cancelText && (
          <Button
            className="defaultButton backButton mr20"
            autoFocus
            disabled={disabledButton}
            onClick={() => {
              onCancel ? onCancel() : onClose();
            }}
          >
            {cancelText}
          </Button>
        )}

        <Button
          className="defaultButton submitButton"
          disabled={disabledButton}
          onClick={() => {
            onConfirm();
          }}
        >
          {loadingConfirmText || confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmModal;
