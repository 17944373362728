import styled, { css } from 'styled-components';

export const LabelSelector = styled.label`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  color: #00020f;
  ${({ styleAutocompleteFromSkuModal }) =>
    styleAutocompleteFromSkuModal &&
    css`
      display: flex;
      flex-flow: column;
    `};
`;

export const InputSelector = styled.input`
  border-color: transparent;
  border-bottom: ${({ StylePageTitleAnFilter }) =>
    StylePageTitleAnFilter ? '1px solid #949494' : '1px solid #313347'};
  background-color: transparent;
  outline: none;
  margin-left: 12px;
  cursor: pointer;
  ${({ styleAutocompleteForm }) =>
    styleAutocompleteForm &&
    css`
      margin-left: 0px;
      margin-top: 15px;
      width: 100%;
    `};
  ${({ styleAutocompleteFromSkuModal }) =>
    styleAutocompleteFromSkuModal &&
    css`
      margin-left: 0px;
      margin-top: 12.1px;
      width: 220px;
    `};

  &::placeholder {
    font-style: italic;
    color: #77798b;
    opacity: 0.5 !important;
  }

  @media (max-width: 850px) {
    width: 80px !important;
  }
`;

export const IconWrapper = styled.div`
  background-color: white;
  ${({ styleAutocompleteForm }) =>
    styleAutocompleteForm &&
    css`
      bottom: 5px;
    `};
  position: absolute;
  margin-top: 15px;
  right: 0;
  ${({ styleAutocompleteFromSkuModal }) =>
    styleAutocompleteFromSkuModal &&
    css`
      margin-top: 30px;
    `};
`;

export const SelectIcon = styled.img`
  width: 8px;
  cursor: pointer;
  transition: all 150ms ease-in;
  transform: ${({ upArrow }) => (upArrow ? 'none' : 'rotate(180deg)')};
`;

export const TreeListWrapper = styled.div`
  ${({ StylePageTitleAnFilter }) =>
    StylePageTitleAnFilter &&
    css`
      top: 30px !important;
      width: ${({ customInputSize }) => customInputSize || '165px'}; ;
    `}
  ${({ StylePageTitleAnFilter }) =>
    !StylePageTitleAnFilter &&
    css`
      top: 25px;
      width: ${({ customInputSize }) => customInputSize || '210px'}; ;
    `}
    ${({ styleAutocompleteForm }) =>
    styleAutocompleteForm &&
    css`
      top: 32px;
      width: ${({ customInputSize }) => customInputSize || '100%'};
      left: 0px;
    `}

    ${({ styleAutocompleteFromSkuModal }) =>
    styleAutocompleteFromSkuModal &&
    css`
      top: 46px;
      width: ${({ customInputSize }) => customInputSize || '220px'};
      left: 0px;
    `};
  transition: all 150ms ease-in;
  right: 0;
  font-size: 12px;
  position: absolute;

  height: ${({ openList }) => (openList ? '0px' : 'auto')};
  max-height: 250px;
  overflow: overlay;
  overflow-x: hidden;
  z-index: 9000;
  background-color: white;
  box-shadow: ${({ openList }) =>
    openList ? 'none' : '0px 2px 2px 2px rgba(1, 3, 17, 0.2)'};
`;

export const NewTreeSelector = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  position: relative;
  ${({ styleAutocompleteFromSkuModal }) =>
    styleAutocompleteFromSkuModal &&
    css`
      margin-left: 16px;
    `};
`;

export const StyleDD = styled.dd`
  cursor: pointer;
  padding: 4px;
  margin-bottom: 0;
  padding-left: 4px;
  &:hover {
    background-color: rgb(80, 98, 240, 0.1);
  }
  width: 100%;
`;

export const StyleDT = styled.dt`
  cursor: pointer;
  padding: 4px;
  margin-bottom: 0;
  &:hover {
    background-color: rgb(80, 98, 240, 0.1);
  }
  width: 100%;
`;

export const StyleDL = styled.dt`
  margin-bottom: 0;
  margin-left: 3px;
  section {
    padding-left: 4px;
    span {
      position: relative;
      left: 17%;
    }
  }
  width: 100%;
`;

export const ArrowDrillDown = styled.img`
  cursor: pointer;
  transition: all 150ms ease-in;
  transform: ${({ upArrow }) => (upArrow ? 'rotate(-90deg)' : 'none')};
`;

export const CategoryWrapper = styled.div`
  display: flex;
`;

export const CloseIconBlack = styled.img`
  width: 8px;
  cursor: pointer;
`;

export const SubCategoriesWrapper = styled.div`
  img {
    margin-left: 14px;
  }
`;
