/* eslint-disable no-unused-expressions */
import makePost from 'services/useFetch/makePost';
import makePut from 'services/useFetch/makePut';
import convertToBase64 from 'utils/convertToBase64';

const checkXlsxFile = (file) => {
  const { name = '' } = file;
  const extention = name.match(/\.[0-9a-z]+$/i);
  if (extention[0] !== '.xlsx') return false;
  return true;
};

const queueProcessor = async (
  filesList,
  setFilesList,
  index,
  setFilesListChanged,
  setAssertButtonStatus,
  resetMultipleFilesRef,
  currentUploadProviderId,
  quoteDate,
  expirationDate
) => {
  if (!filesList.length) return;

  if (index > filesList.length - 1) {
    setAssertButtonStatus(false);
    resetMultipleFilesRef && resetMultipleFilesRef();
    return;
  }

  const handleResponsePost = (status, response) => {
    const { data: { message = '', nSkus = '' } = {} } = response;

    if (!status) {
      if (
        message === 'the filename sent differs from the filename of the quote'
      ) {
        filesList[index].status = {
          name: 'Erro - Arquivo nome errado',
          color: '#E22121'
        };
      } else {
        filesList[index].status = {
          name: 'Erro - Algo deu errado',
          color: '#E22121'
        };
      }
    }

    if (status) {
      if (nSkus) {
        filesList[index].status = {
          name: `Finalizado / ${nSkus} SKUs novos importados`,
          color: '#5062F0'
        };
      } else {
        filesList[index].status = {
          name: 'Finalizado',
          color: '#5062F0'
        };
      }
    }

    setFilesList(filesList);
    setFilesListChanged(filesList.values());
    queueProcessor(
      filesList,
      setFilesList,
      index + 1,
      setFilesListChanged,
      setAssertButtonStatus,
      resetMultipleFilesRef && resetMultipleFilesRef,
      currentUploadProviderId && currentUploadProviderId,
      quoteDate && quoteDate,
      expirationDate && expirationDate
    );
  };

  const spreadsheetBase64 = await convertToBase64(filesList[index]);

  const { name = '' } = filesList[index];

  filesList[index].status = {
    name: 'Processando...',
    color: '#333541'
  };
  setFilesList(filesList);
  setFilesListChanged(filesList.values());

  const checkFileType = checkXlsxFile(filesList[index]);

  if (!checkFileType) {
    filesList[index].status = {
      name: 'Erro - Tipo de arquivo inválido',
      color: '#E22121'
    };
    setFilesList(filesList);
    setFilesListChanged(filesList.values());
    queueProcessor(
      filesList,
      setFilesList,
      index + 1,
      setFilesListChanged,
      setAssertButtonStatus,
      resetMultipleFilesRef && resetMultipleFilesRef,
      currentUploadProviderId && currentUploadProviderId,
      quoteDate && quoteDate,
      expirationDate && expirationDate
    );
  }

  if (spreadsheetBase64 && checkFileType) {
    const data = {
      filename: name,
      user: 'bloom',
      spreadsheet: spreadsheetBase64,
      quoteDate: quoteDate || '',
      expirationDate: expirationDate || ''
    };

    if (currentUploadProviderId && currentUploadProviderId) {
      data.providerId = currentUploadProviderId && currentUploadProviderId;
    }

    if (resetMultipleFilesRef) {
      makePost('quotationsPlatformSpreadsheet', data, handleResponsePost);
    } else {
      makePut('quotationManagerSpreadsheets', data, handleResponsePost);
    }
  }
};

const multiFilesProcessing = (
  files,
  setFilesList,
  setFilesListChanged,
  setAssertButtonStatus,
  resetMultipleFilesRef,
  currentUploadProviderId,
  quoteDate,
  expirationDate
) => {
  const newList = [];

  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    element.status = {
      name: 'Aguardando...',
      color: '#A0A1A7'
    };
    newList.push(element);
  }
  setFilesList(newList);
  queueProcessor(
    newList,
    setFilesList,
    0,
    setFilesListChanged,
    setAssertButtonStatus,
    resetMultipleFilesRef && resetMultipleFilesRef,
    currentUploadProviderId && currentUploadProviderId,
    quoteDate && quoteDate,
    expirationDate && expirationDate
  );
};

export default multiFilesProcessing;
