/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import {
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import typeDiscard from 'data/type.json';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as UserActions from 'store/actions/user';
import { Container } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

function Discard({
  userInfo,
  discardsInfo,
  redirectPath,
  isModal,
  onClose,
  setUpdateList,
  createMode
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { id: getId } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();
  const [message, setMessage] = useState({
    description: '',
    status: ''
  })

  const onDismiss = () => setMessage({description: '', status: ''});
  const [restaurantGroups, setRestaurantGroups] = useState([
    userInfo.companies.groups[0]
  ]);
  const validGroup = userInfo.companiesActive.id.length > 0

  function removeDuplicate(array) {
    const uniqueMap = {};
    return array?.filter(obj => {
        const key = JSON.stringify(obj);
        return uniqueMap.hasOwnProperty(key) ? false : (uniqueMap[key] = true);
    });
  }

  const [editMode, setEditMode] = useState(
    createMode !== true
      ? discardsInfo.action
        ? discardsInfo.action.toLowerCase() === 'edit'
        : false
      : false
  );
  const [discardsId, setDiscardsId] = useState(
    editMode ? discardsInfo.data.id : ''
  );
  const [description, setDescription] = useState(
    editMode ? discardsInfo.data.description : ''
  );
  const [typeCard, setTypeDiscard] = useState(
    editMode ? removeDuplicate(discardsInfo.data.type) : []
  );
  const [updateTypeDiscard, setUpdateTypeDiscard] = useState(removeDuplicate(discardsInfo.data.type))
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const onSubmit = (data) => {
    const storeIds = getId?.split(',').map(Number) || []
    let groups = restaurantGroups || null;

    if (storeIds.length) {
      groups = groups
        .map(group => {
          const subGroups = group?.subGroups?.filter(subGroup =>
            subGroup.stores.some(store => storeIds.includes(store.id))
          )

          return {
            ...group,
            subGroups
          }
        })
        .filter(group => group?.subGroups?.length > 0)
    }

    setLoading(true);

    if (editMode) {
      if (!validGroup) {
        axios
          .put(`${environments.catalogV2}/discards/${discardsId}`, {
            description,
            discardTypes: updateTypeDiscard,
            user: userInfo.user,
            groups
          }, config)
          .then(() => {
            setOpen(true);
            setLoading(false);
            setUpdateList && setUpdateList(true);
            onClose &&
              setTimeout(() => {
                onClose();
              }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            setMessage({
              description: error.response.data.message,
              status: error.response.status
            })
            setUpdateList && setUpdateList(false);
          });
      } else {
        axios
        .put(`${environments.catalogV2}/discards/${discardsId}?group=true`, {
          description,
          discardTypes: updateTypeDiscard,
          user: userInfo.user,
          groups
        }, config)
        .then(() => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          setMessage({
            description: error.response.data.message,
            status: error.response.status
          })
          setUpdateList && setUpdateList(false);
        });
      }
    } else {
      axios
        .post(`${environments.catalogV2}/discards`, {
          restaurantId: userInfo.companiesActive.id[1] ? '' : userInfo.companiesActive.id,
          description,
          discardTypeId: updateTypeDiscard?.map((item) => item?.id),
          user: userInfo.user,
          groups
        }, config)
        .then(() => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          setMessage({
            description: error.response.data.message === "Cannot read property 'description' of undefined" ? 'Tipo de descarte ja existe' : error.response.data.message            ,
            status: error.response.status
          })
          setUpdateList && setUpdateList(false);
        });
    }
  };

  const handleClose = (event, reason) => {
    if (redirectPath !== null) {
      history.push(redirectPath);
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="defaultModal" style={{ width: '40%' }}>
      <Container className="w100">
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          style={{ borderRadius: '4px 4px 0 0' }}
          id="draggable-dialog-title"
        >
          <Typography>CADASTRO DE TIPOS DE DESCARTE</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        {loading ? <LinearProgress /> : null}

        <div className="cardDefault pt16" style={{ borderRadius: '0' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid className="m0" container xs={12} sm={12} spacing={4}>
              <Grid item xs={12} sm={6} className="pl0">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    name="description"
                    label="Descrição"
                    autoFocus
                    required="required"
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setDescription(e.target.value)}
                    defaultValue={discardsInfo?.data?.description || null}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} className="pr0">
                <Autocomplete
                  id="controllable-states-demo"
                  options={typeDiscard}
                  limitTags={2}
                  getOptionLabel={(option) => option?.description || option?.type?.description }
                  size="small"
                  multiple
                  value={typeCard}
                  onChange={(_, value, reason, detail) => {
                    const valueNotDuplicate = removeDuplicate(value)
                    if (reason === 'remove-option') {
                      setTypeDiscard(valueNotDuplicate)
                      if (!validGroup) {
                      setUpdateTypeDiscard(updateTypeDiscard.map((type) => {
                        if(type?.id === detail?.option?.id) {
                          return {...type, id: type.id, action: 'REMOVE'}
                        } 
                          return {
                            ...type
                          }
                        
                      }))
                      } else {
                        setUpdateTypeDiscard(updateTypeDiscard.map((type) => {
                          if(type?.discardTypeId === detail?.option?.discardTypeId) {
                            return {...type, id: type.discardTypeId, action: 'REMOVE'}
                          } 
                            return {
                              ...type
                            }
                          
                        }))
                      }
                    } else if (reason === 'clear') {
                      setTypeDiscard(valueNotDuplicate)
                      setUpdateTypeDiscard(updateTypeDiscard.map((type) => ({...type, action: 'REMOVE'})))
                    } else {
                      const existingValue = updateTypeDiscard?.find((type) => type?.id === detail?.option?.id)
                      const newType = {id: detail.option.id, action: 'ADD'}
                      setTypeDiscard(valueNotDuplicate)
                      if (existingValue) {
                        setUpdateTypeDiscard(updateTypeDiscard.map((type) => {
                          if (type.id === detail.option.id) {
                            return {...type, action: ''}
                          } return {...type}
                        }))
                      } else {
                        setUpdateTypeDiscard([updateTypeDiscard, newType].flat())
                      }
                    }
                  }}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="typeDiscard"
                      label="Tipo"
                      variant="outlined"
                      required="required"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}

                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>

            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                {editMode ? 'Atualizado' : 'Cadastro realizado'} com sucesso!
              </Alert>
            </Snackbar>

            <Grid
              className="dFlex justifyEnd p0 bgWhite"
              item
              xs={12}
              sm={12}
              style={{ borderRadius: '0 0 4px 4px' }}
            >
              {!isModal && (
                <Link to="/admin/tipo-descarte/">
                  <Button
                    className="defaultButton backButton mr20"
                    design="outlined"
                    label="Voltar"
                  />
                </Link>
              )}

              <Button
                className="defaultButton submitButton"
                disabled={!description || !typeCard.length}
                type="submit"
                label="Salvar"
                design="contained"
                onClick={handleSubmit(onSubmit)}
              />
            </Grid>
          </form>
        </div>
      </Container>

      <CommonAlert
        open={!!message.description}
        onClose={onDismiss}
        messagePersonal={message.description}
        width="50%"
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  discardsInfo: state.discards
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Discard);
