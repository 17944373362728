import React, { useEffect, useState } from 'react';

import Axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import { isEmpty } from 'utils/isEmpty';

import CountInfosArea from './homeInfos/CountInfosArea';
import ProductionInfosArea from './homeInfos/ProductionInfosArea';
import ReceivementInfosArea from './homeInfos/ReceivementInfosArea';

const environments = Environment(process.env.REACT_APP_ENV);

function Home({ userInfo }) {
  const { id: paramsID } = useParams();
  const actualDate = new Date();
  const actualDateLessOne = moment(actualDate)?.subtract(1, 'day')?.format('YYYY-MM-DD');
  const actualDateLessFortyNine = moment(actualDate)?.subtract(49, 'day')?.format('YYYY-MM-DD');
  const [filters] = useState([
    { param: 'originId', value: paramsID, urlParam: `originId=${paramsID}` },
    { param: 'days', value: 7, urlParam: `days=7` }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [countPercentConclusion, setCountPercentConclusion] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [countDiff, setCountDiff] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [countClosure, setCountClosure] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [productionCopilotInfos, setProductionCopilotInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [productionDiff, setProductionDiff] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [productionOffForecastAndSchedule, setProductionOffForecastAndSchedule] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [productionExcessInventoryAndDailySchedule, setProductionExcessInventoryAndDailySchedule] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [receivementOpenOrders, setReceivementOpenOrders] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })

  const [courtId, setCourtId] = useState(null)
  const [courtDescription, setCourtDescription] = useState(null)

  const [courtIdCopilot, setCourtIdCopilot] = useState()
  const [courtDescriptionCopilot, setCourtDescriptionCopilot] = useState()

  const productionDiffTooltipFormatted = (status, el) => {
    let mainObj = {
      item: '',
      tooltip: ''
    }

    const noDiffProducts = el?.noDiffProducts || 0

    if (status === 'accepted') {
      mainObj = {
        item: `${el?.acceptedProducts} ${el?.acceptedProducts === 1 ? 'produção' : 'produções'} aceitas`,
        tooltip: `${noDiffProducts} ${noDiffProducts === 1 ? 'produção' : 'produções'} c/ diferença 0`
      }
    }

    if (status === 'leftover') {
      mainObj = {
        item: `${el?.leftoverProducts} ${el?.leftoverProducts === 1 ? 'produção' : 'produções'} c/ sobra`,
        tooltip: `${noDiffProducts} ${noDiffProducts === 1 ? 'produção' : 'produções'} c/ diferença 0`
      }
    }

    if (status === 'lack') {
      mainObj = {
        item: `${el?.lackProducts} ${el?.lackProducts === 1 ? 'produção' : 'produções'} c/ falta`,
        tooltip: `${noDiffProducts} ${noDiffProducts === 1 ? 'produção' : 'produções'} c/ diferença 0`
      }
    }

    return mainObj
  }

  const getCountPercentConclusion = () => {
    setCountPercentConclusion({
      ...countPercentConclusion,
      loading: true
    })

    Axios.get(`${environments.dashBoardV3}/home/counts/percentages?restaurantId=${paramsID}&startDate=${actualDateLessFortyNine}&endDate=${actualDateLessOne}&rangeDays=7`)
      .then((response) => {
        const orderedItem = response?.data?.content?.sort((a, b) => (a?.date > b?.date ? 1 : -1))

        setCountPercentConclusion({
          content: orderedItem?.map(el => el?.percentage),
          contentFormatted: orderedItem?.map((el, index) => ({
            index,
            item: `${parseFloat(el?.percentage?.toFixed(0))}% concluído`
          })),
          labels: orderedItem?.map(el => moment(el?.date)?.format('DD/MM')),
          customYLabelAfter: '%',
          noLegend: true,
          yMin: 0,
          yMax: 100,
          loading: false
        })
      })
      .catch((error) => {
        setCountPercentConclusion({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getCountDifferences = () => {
    setCountDiff({
      ...countDiff,
      loading: true
    })

    Axios.get(`${environments.dashBoardV3}/home/counts/diff?restaurantId=${paramsID}&startDate=${actualDateLessFortyNine}&endDate=${actualDateLessOne}&rangeDays=7`)
      .then((response) => {
        const orderedItem = response?.data?.sort((a, b) => (a?.days > b?.days ? -1 : 1))

        setCountDiff({
          contentFormatted: [
            {
              title: 'aceitas',
              data: orderedItem?.map(el => el?.acceptedProducts),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: `${el?.acceptedProducts} ${el?.acceptedProducts === 1 ? 'produto' : 'produtos'}`
              })),
              color: '#08AA15'
            },
            {
              title: 'sobras',
              data: orderedItem?.map(el => el?.leftoverProducts),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: `${el?.leftoverProducts} ${el?.leftoverProducts === 1 ? 'produto' : 'produtos'}`
              })),
              color: '#F2B90D'
            },
            {
              title: 'faltas',
              data: orderedItem?.map(el => el?.lackProducts),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: `${el?.lackProducts} ${el?.lackProducts === 1 ? 'produto' : 'produtos'}`
              })),
              color: '#F53D4C'
            }
          ],
          labels: orderedItem?.map(el => `${el?.days} dias`),
          loading: false
        })
      })
      .catch((error) => {
        setCountDiff({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getCountClosure = () => {
    setCountClosure({
      ...countClosure,
      loading: true
    })

    Axios.get(`${environments.dashBoardV3}/home/counts/closingInformations?restaurantId=${paramsID}&startDate=${actualDateLessFortyNine}&endDate=${actualDateLessOne}&rangeDays=7`)
      .then((response) => {
        const orderedItem = response?.data?.content?.sort((a, b) => (a?.dateRef > b?.dateRef ? 1 : -1))
        const lastSaleNumbersAsTime = orderedItem?.map(el => moment(el?.lastSale).format('HH:mm'))
        const lastSaleNumbers = lastSaleNumbersAsTime.map(el => parseFloat(`${parseFloat(el?.split(':')[0])}.${parseFloat(el?.split(':')[1]) / 10}`))
        const formattedLastSaleNumbers = lastSaleNumbers.map(el => el >= 0 && el <= 4 ? el + 24 : el)
        
        const lastCountNumbersAsTime = orderedItem?.map(el => moment(el?.lastCount).format('HH:mm'))
        const lastCountNumbers = lastCountNumbersAsTime.map(el => parseFloat(`${parseFloat(el?.split(':')[0])}.${parseFloat(el?.split(':')[1]) / 10}`))
        const formattedLastCountNumbers = lastCountNumbers.map(el => el >= 0 && el <= 4 ? el + 24 : el)
        const lastSaleAndCountNumbers = [formattedLastSaleNumbers?.filter(el => el || el === 0), formattedLastCountNumbers?.filter(el => el || el === 0)].flat()
        const yMinEl = Math.min(...lastSaleAndCountNumbers?.map(el => el))
        const yMaxEl = Math.max(...lastSaleAndCountNumbers?.map(el => el))

        setCountClosure({
          contentFormatted: [
            {
              title: 'última venda',
              data: formattedLastSaleNumbers,
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: `${moment(el?.lastSale)?.format('HH:mm')} hs`
              })),
              color: '#5062F0'
            },
            {
              title: 'última contagem',
              data: formattedLastCountNumbers,
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: `${parseFloat(el?.percentageCount?.toFixed(0))}% concluído`
              })),
              color: '#F2B90D'
            },
          ],
          customYLabelAfter: 'hs',
          yMin: yMinEl < 2 ? 2 : yMinEl,
          yMax: yMaxEl > 21 ? yMaxEl : 21,
          labels: orderedItem?.map(el => moment(el?.dateRef)?.format('DD/MM')),
          loading: false
        })
      })
      .catch((error) => {
        setCountClosure({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getProductionCopilot = () => {
    setProductionCopilotInfos({
      ...productionCopilotInfos,
      loading: true
    })

    let URL = `${environments.dashBoardV3}/home/manufactures/copilotClosingInformations?restaurantId=${paramsID}&startDate=${actualDateLessFortyNine}&endDate=${actualDateLessOne}&rangeDays=7`

    if (!isEmpty(courtIdCopilot)) {
      URL += `&courtId=${courtIdCopilot}`
    }

    if (!isEmpty(courtDescriptionCopilot)) {
      URL += `&courtDescription=${courtDescriptionCopilot}`
    }

    Axios.get(URL)
      .then((response) => {
        const mainObj = response?.data?.content?.sort((a, b) => (a?.date > b?.date ? 1 : -1))
        const yMinEl = Math.min(...mainObj?.map(el => el?.time ? parseFloat(el?.time.split(":")[0]) : 1)?.map(el => el))
        const yMaxEl = Math.max(...mainObj?.map(el => el?.time ? parseFloat(el?.time.split(":")[0]) : 24)?.map(el => el))

        setProductionCopilotInfos({
          contentFormatted: [
            {
              title: 'última venda',
              data: mainObj?.map(el => parseFloat(el?.time ? el?.time.split(":")[0] : "")),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${el?.time ? el?.time.split(":")[0] : "00"}:${el?.time ? el?.time.split(":")[1] : "00"} hs`
              })),
              color: '#5062F0'
            },
          ],
          customYLabelAfter: 'hs',
          yMin: mainObj?.some(el => parseFloat(el?.time ? el?.time.split(":")[0] : 0) < 7) ? 7 : yMinEl,
          yMax: mainObj?.some(el => parseFloat(el?.time ? el?.time.split(":")[0] : 0) > 12) ? yMaxEl : 12,
          labels: mainObj?.map(el => moment(el?.date)?.format('DD/MM')),
          loading: false
        })
      })
      .catch((error) => {
        setProductionCopilotInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getProductionDifferences = () => {
    setProductionDiff({
      ...productionDiff,
      loading: true
    })

    let URL = `${environments.dashBoardV3}/home/manufactures/diff?restaurantId=${paramsID}&startDate=${actualDateLessFortyNine}&endDate=${actualDateLessOne}&rangeDays=7`

    if (!isEmpty(courtId)) {
      URL += `&courtId=${courtId}`
    }

    if (!isEmpty(courtDescription)) {
      URL += `&courtDescription=${courtDescription}`
    }

    Axios.get(URL)
      .then((response) => {
        const orderedItem = response?.data?.sort((a, b) => (a?.days > b?.days ? -1 : 1))

        setProductionDiff({
          contentFormatted: [
            {
              title: 'aceitas',
              data: orderedItem?.map(el => el?.acceptedProducts),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: productionDiffTooltipFormatted('accepted', el)?.item,
                tooltipExtraInfo: productionDiffTooltipFormatted('accepted', el)?.tooltip,
              })),
              color: '#08AA15'
            },
            {
              title: 'sobras',
              data: orderedItem?.map(el => el?.leftoverProducts),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: productionDiffTooltipFormatted('leftover', el)?.item,
                tooltipExtraInfo: productionDiffTooltipFormatted('leftover', el)?.tooltip
              })),
              color: '#F2B90D'
            },
            {
              title: 'faltas',
              data: orderedItem?.map(el => el?.lackProducts),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: productionDiffTooltipFormatted('lack', el)?.item,
                tooltipExtraInfo: productionDiffTooltipFormatted('lack', el)?.tooltip
              })),
              color: '#F53D4C'
            }
          ],
          labels: orderedItem?.map(el => `${el?.days} dias`),
          loading: false
        })
      })
      .catch((error) => {
        setProductionDiff({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getOffForecastAndSchedule = () => {
    setProductionOffForecastAndSchedule({
      ...productionOffForecastAndSchedule,
      loading: true
    })

    Axios.get(`${environments.dashBoardV3}/home/manufactures/outScheduleAndForecast?restaurantId=${paramsID}&startDate=${actualDateLessFortyNine}&endDate=${actualDateLessOne}&rangeDays=7`)
      .then((response) => {
        const orderedItem = response?.data?.sort((a, b) => (a?.days > b?.days ? -1 : 1))

        setProductionOffForecastAndSchedule({
          contentFormatted: [
            {
              title: 'fora da previsão',
              data: orderedItem?.map(el => el?.outForecast),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: `${el?.outForecast} ${el?.outForecast === 1 ? 'produção' : 'produções'}`
              })),
              color: '#F2B90D'
            },
            {
              title: 'fora da agenda',
              data: orderedItem?.map(el => el?.outSchedule),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: `${el?.outSchedule} ${el?.outSchedule === 1 ? 'produção' : 'produções'}`
              })),
              color: '#F53D4C'
            },
          ],
          labels: orderedItem?.map(el => `${el?.days} dias`),
          loading: false
        })
      })
      .catch((error) => {
        setProductionOffForecastAndSchedule({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getExcessInventoryAndDailySchedule = () => {
    setProductionExcessInventoryAndDailySchedule({
      ...productionExcessInventoryAndDailySchedule,
      loading: true
    })

    Axios.get(`${environments.dashBoardV3}/home/manufactures/excessInventory?restaurantId=${paramsID}&startDate=${actualDateLessFortyNine}&endDate=${actualDateLessOne}&rangeDays=7`)
      .then((response) => {
        const orderedItem = response?.data?.sort((a, b) => (a?.days > b?.days ? -1 : 1))

        setProductionExcessInventoryAndDailySchedule({
          contentFormatted: [
            {
              title: '',
              data: orderedItem?.map(el => el?.noSuggestion),
              dataFormatted: orderedItem?.map((el, index) => ({
                index,
                item: `${el?.noSuggestion} ${el?.noSuggestion === 1 ? 'produção' : 'produções'}`
              })),
              color: '#F53D4C'
            },
          ],
          noLegend: true,
          labels: orderedItem?.map(el => `${el?.days} dias`),
          loading: false
        })
      })
      .catch((error) => {
        setProductionExcessInventoryAndDailySchedule({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getCountInfos = () => {
    getCountPercentConclusion()
    getCountDifferences()
    getCountClosure()
  };

  const getProductionInfos = () => {
    getProductionCopilot()
    getProductionDifferences()
    getOffForecastAndSchedule()
    getExcessInventoryAndDailySchedule()
  };

  const getReceivementInfos = () => {
    setReceivementOpenOrders({
      ...receivementOpenOrders,
      loading: true
    })

    Axios.get(`${environments.dashBoardV3}/home/orders/total?originId=${paramsID}&startDate=${actualDateLessFortyNine}&endDate=${actualDateLessOne}&rangeDays=7`)
      .then((response) => {
        const mainObj = response?.data
        const yMinEl = Math.min(...mainObj?.map(el => el?.count)?.map(el => el))
        const yMaxEl = Math.max(...mainObj?.map(el => el?.count)?.map(el => el))

        setReceivementOpenOrders({
          contentFormatted: [
            {
              title: 'em aberto',
              data: mainObj?.map(el => el?.count),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${el?.count} ${el?.count === 1 ? 'pedido' : 'pedidos'}`
              })),
              color: '#F2B90D'
            },
          ],
          yMin: yMinEl,
          yMax: yMaxEl,
          labels: mainObj?.map(el => moment(el?.date)?.format('DD/MM')),
          loading: false
        })
      })
      .catch((error) => {
        setReceivementOpenOrders({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  };

  useEffect(() => {
    getCountInfos()
    getProductionInfos()
    getReceivementInfos()
  }, [paramsID])

  useEffect(() => {
    getProductionDifferences()
  }, [courtId, courtDescription])

  useEffect(() => {
    getProductionCopilot()
  }, [courtIdCopilot, courtDescriptionCopilot])

  return (
    <Layout>
      <GlobalStyle/>
      
      <TitleHead title="Home" />

      <Container className="homeInfosPage">
        <PageTitleAndFilter
          title='Gerencie sua operação'
        />

        <CountInfosArea 
          countPercentConclusion={countPercentConclusion}
          countDiff={countDiff}
          countClosure={countClosure}
        />

        <section className="dFlex productionAndReceivementArea">
          <ProductionInfosArea 
            productionCopilotInfos={productionCopilotInfos}
            productionDiff={productionDiff}
            productionOffForecastAndSchedule={productionOffForecastAndSchedule}
            productionExcessInventoryAndDailySchedule={productionExcessInventoryAndDailySchedule}
            setCourtId={setCourtId}
            setCourtDescription={setCourtDescription}
            setCourtIdCopilot={setCourtIdCopilot}
            setCourtDescriptionCopilot={setCourtDescriptionCopilot}
          />
          
          <ReceivementInfosArea 
            receivementOpenOrders={receivementOpenOrders} 
          />
        </section>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
