/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useCallback, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from 'axios';
import deLocale from "date-fns/locale/pt-BR";
import { connect } from 'react-redux';
import { useParams, Redirect } from "react-router-dom";
import { bindActionCreators } from 'redux';

import Button from 'components/BloomForms/Button';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddButton from 'images/icons/signals/addsAndCreates/add.svg';
import TrashIcon from 'images/icons/signals/removesAndDeletes/trashMobileIcon.svg';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import {
    CardWrapper,
    CardItem,
    CardItemLavel,
    CardProductItem
} from './index.style';

function CountAdditionalItens({ userInfo, countInfo }) {
    const environments = Environment(process.env.REACT_APP_ENV)
    const [ redirectToCounts, setRedirectToCounts ] = useState(false);
    const { id: paramsID } = useParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [products, setProducts] = useState([]);
    const [state, setState] = useState();
    const [countId, setCountId] = useState(countInfo && countInfo.id ? countInfo.id : null);
    const [productOptions, setProductOptions] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState();
    const [selectedProductList, setSelectedProductList] = useState([]);
    
    useEffect(() => {
        axios.get(`${environments.catalog}/inventories/${countId}?originId=${paramsID}`)
            .then((response) => {
                const { data: { products = [] } = {} } = response;
                setProducts(products);
            })
            .catch((error) => {
                console.log(error);
            });

    }, [countId, paramsID, countInfo])

    const handleBackButtonFilter = () => {
        setRedirectToCounts(true)
    }

    const settingsMobile = [
        {
            description: "Pesquisar na Contagem", 
            action: () => {},
            backgroundBlue: true
        },
    ];

    const handleDateChange = (date) => {
        setSelectedDate(date)
    };

    const getOptions = useCallback((endpoint, key, hook, setHook, originId, filters) => {
        (async () => {
          let params = ""
    
          if (filters !== undefined && filters) {
            const keys = Object.keys(filters);
            const values = Object.values(filters);
            keys.map((key, index) => params += `&${key}=${values[index]}`);
          }
    
          const response = await fetch(`${endpoint}?page=${Number(!(key === "providers"))}&size=1000&originId=${originId}${params}`);
          const item = await response.json();

          if (!item.content) return;
          setHook(item.content);
          setState({ hook });
        })();
    }, []);

    const handleAddProductToCount = () => {
        if (!selectedProduct) return;

        const { id: selectedProductId } = selectedProduct;
        
        const isAlreadyAdd = selectedProductList.filter(product => product.id === selectedProductId);

        if (isAlreadyAdd.length) return;

        setSelectedProductList([...selectedProductList, ...[selectedProduct]]);
    }

    const handleDeleteProduct = (productId) => {
        const newSeletedProduct = selectedProductList.filter(({id}) => id !== productId);
        setSelectedProductList(newSeletedProduct);
    }

    const handleSaveAdditionalItensCount = () => {
        console.log(selectedProductList);
    }

    return (
        <Layout>
            <TitleHead title='Contagem adicional'/>

            <GlobalStyle/>
            <Container>
                <PageTitleAndFilter 
                    title="Contagem Adicional de Itens"
                    // activeFilterFilds={filterFields} 
                    // handleFilter={handleFilter} 
                    // handleMultipleFilters={handleMultipleFiltersFunc}
                    // additionalbutton={addMenuButton} 
                    // getFilterhandle={getFilterhandle}
                    settings
                    filterOff
                    backButton={{
                        action: handleBackButtonFilter,
                        description: "Voltar",
                    }}
                    settingsMobile={settingsMobile}
                
                />

                <CardWrapper>

                    <CardItem>
                        <CardItemLavel>Data Inicial</CardItemLavel>
                        <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <DatePicker
                                inputVariant="outlined"
                                size="small"
                                fullWidth
                                className="dateSmall"
                                format="dd/MM/yyyy"
                                value={selectedDate}
                                onChange={handleDateChange}
                                InputProps={{ style: { fontFamily: 'Lato', fontSize: 12 } }}
                                style={{ width: "45%", paddingBottom: "6px" }}
                            />
                        </MuiPickersUtilsProvider>
                    </CardItem>

                    <CardItem>
                        <CardItemLavel>Produtos</CardItemLavel>

                        <div>
                            <Autocomplete
                                size="small"
                                id="asynchronous-demo"
                                onFocus={event => getOptions(`${environments.catalog}/products`, products, productOptions, setProductOptions, paramsID)}
                                onChange={(option, value) => value ? setSelectedProduct(value) : null}
                                getOptionLabel={option => option.description}
                                options={productOptions}
                                className="autocompleteSmall"
                                style={{ width: "70%" }}
                                renderInput={params => (
                                <TextField
                                    {...params}
                                    type="text"
                                    variant="outlined"
                                    placeholder="Selecione..."

                                />
                                )}
                                renderOption={params => (
                                        <Typography
                                        style={{ fontSize: "12px", fontFamily: "Lato" }}
                                        >
                                        {params.description}
                                        </Typography>
                                    )}
                            />

                            <img 
                                src={AddButton} 
                                alt="add contagem adicional de item"
                                onClick={handleAddProductToCount} 
                            />
                        </div>
                    </CardItem>

                </CardWrapper>

                {
                    selectedProductList.map(({ description = "", id }) => (
                        <CardWrapper padding="8px" marginTop="12px">
                            <CardProductItem >
                                <div>
                                    <div> { description } </div>
                                    <img src={TrashIcon} alt="delete product" onClick={() => handleDeleteProduct(id)} />
                                </div>
                            </CardProductItem>
                        </CardWrapper>
                    ))
                }               

                {
                    redirectToCounts &&
                    <Redirect to={`/inventario/contagens/${paramsID}`} />
                }

                <div 
                    style={{ 
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "12px"
                    }}
                >
                    <Button 
                        label="Cancelar" 
                        secondary 
                        onClick={() => {setRedirectToCounts(true)}} 
                        width="48%"
                        marginOff
                    />
                    <Button 
                        label="Salvar" 
                        primary 
                        onClick={handleSaveAdditionalItensCount} 
                        width="48%"
                        marginOff
                    />
                </div>

            </Container>

        </Layout>
    );
}

const mapStateToProps = state => ({
    userInfo: state.user,
    countInfo: state.count
  })
  
const mapDispatchToProps = dispatch =>
    bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CountAdditionalItens);

