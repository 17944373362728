export function setPeriodicities(data) {
    return {
        type: 'SET_PERIODICITIES_DATA',
        data,
    };
}

export function createPeriodicities(data) {
    return {
        type: 'CREATE_PERIODICITIES_DATA',
        data,
    };
}