import React from 'react';

import { Line } from 'react-chartjs-2';

import { ChartContainer } from './index.style';
import { filterChartData, filterChartLineOptions } from './utils';

const chartOptions = {
  maintainAspectRatio: false,
  bezierCurve: false,
  legend: {
    display: true,
    position: 'right',
    fontFamily: 'Lato'
  },
  layout: {
    padding: {
      left: 0,
      right: 100,
      top: 0,
      bottom: 0
    }
  },
  elements: {
    line: {
      tension: 0
    }
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.2,
        gridLines: {
          offsetGridLines: false
        }
      }
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          max: 600,
          min: 0,
          stepSize: 100
        }
      }
    ]
  }
};

const chartLineOptions = {
  ...chartOptions,
  legend: {
    display: false
  },
  layout: {
    padding: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  },
  interaction: {
    mode: 'index',
    intersect: false
  },
  hover: {
    mode: 'nearest',
    intersect: true
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) =>
        `R$ ${parseFloat(tooltipItem.value).toLocaleString('pt-br', {
          minimumFractionDigits: 2
        })}`
    },
    mode: 'index',
    intersect: false
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          display: true,
          zeroLineWidth: 0,
          color: '#A0A1A7'
        }
      }
    ],
    yAxes: [
      {
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: {
          display: true,
          zeroLineWidth: 2,
          zeroLineColor: '#B8BFF5',
          color: '#A0A1A7',
          drawBorder: false
        },
        ticks: {
          max: 300000,
          min: 10000,
          stepSize: 10000,
          callback(value) {
            const newValue = value.toLocaleString('pt-br', {
              minimumFractionDigits: 2
            });
            return `R$ ${newValue}`;
          }
        }
      }
    ]
  }
};

function CardInfoChart({ cardInfo }) {
  const { chartType, datasets } = cardInfo;

  return (
    <ChartContainer>
      {chartType === 'lineChart' && (
        <Line
          width={140}
          height={225}
          data={filterChartData(cardInfo)}
          options={filterChartLineOptions(chartLineOptions, datasets)}
        />
      )}
    </ChartContainer>
  );
}

export default CardInfoChart;
