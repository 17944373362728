/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { Tooltip } from '@material-ui/core';

import ConfigColumnsIcon from 'images/icons/columns/collumnsWhiteOutline.svg';

function TheoryStockHead({ extractItems, showColumns, setAnchorEl, isMobileScreen }) {
  return (
    <thead style={{ position: 'sticky', top: '110px', zIndex: 1 }}>
      <tr>
        <th
          className="description white pt0 pb0 pr16 pl16 fontSizeDefault"
          style={{ width: '18%', top: '-16px' }}
        >
          <div className="dFlex alignCenter">
            DATA

            {!isMobileScreen ?
              <p
                className="m0 ml3 cursorPointer dFlex justifyEnd"
                style={{
                  color: 'rgba(80,98,240,1)',
                  textDecoration: 'underline',
                }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <Tooltip
                  placement="top"
                  title={
                    <p className="mb0" style={{ padding: '4px 2px' }}>
                      Exibir/Ocultar colunas
                    </p>
                  }
                >
                  <img src={ConfigColumnsIcon} alt="Config Show Columns" />
                </Tooltip>
              </p>
            : null}
          </div>
        </th>

        <th
          style={{ width: '355px', top: '-16px' }}
          className="description white pt0 pb0 pl5 pr16 fontSizeDefault"
        >
          <div className="dFlex justifyBetween alignCenter">
            <span
              className={
                extractItems?.some((el) => el?.type === 'Diferença de estoque') && 'pr10 pl5'
              }
            >
              LANÇAMENTO
            </span>

            <span>QTDE</span>
          </div>
        </th>

        <th
          style={{ width: '140px', top: '-16px' }}
          className={`description white pt0 pb0 pr16 textAlignRight fontSizeDefault ${(isMobileScreen || !showColumns.filter((el) => el.includes('CUSTO'))?.length) && 'dNone'}`}
        >
          CUSTO
        </th>

        <th
          style={{ width: '140px', top: '-16px' }}
          className={`description white pt0 pb0 pr16 textAlignRight fontSizeDefault ${(isMobileScreen || !showColumns.filter((el) => el.includes('VALOR UNIT'))?.length) && 'dNone'}`}
        >
          VALOR
        </th>

        <th
          style={{ width: '125px', top: '-16px' }}
          className={`description white pt0 pb0 pr16 textAlignRight fontSizeDefault ${(isMobileScreen || !showColumns.filter((el) => el.includes('QTDE FINAL'))?.length) && 'dNone'}`}
        >
          QTDE FINAL
        </th>

        <th
          style={{ width: '120px', top: '-16px' }}
          className={`description white pt0 pb0 pr16 textAlignRight fontSizeDefault ${(isMobileScreen || !showColumns.filter((el) => el.includes('VALOR FINAL'))?.length) && 'dNone'}`}
        >
          VALOR FINAL
        </th>

        {/* <th
          style={{ width: '6px', top: '-16px' }}
          className="description white pt0 pb0 pr8 textAlignRight fontSizeDefault"
        >
          &nbsp;
        </th> */}
      </tr>
    </thead>
  );
}

export default TheoryStockHead;
