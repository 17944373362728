import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/close-icon-white.svg';
import * as ProductsActions from 'store/actions/products';
import ValidationLength from 'utils/utils';

function ProvidersDialog({
  fullScreen,
  maxWidth,
  open,
  onClose,
  title,
  loading,
  providers
}) {
  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal providersDialog"
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>{title}</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <table>
          <thead>
            <tr>
              <th width="50%" className="subtitle">
                DESCRIÇÃO
              </th>

              <th width="15%" className="subtitle">
                CNPJ
              </th>

              <th width="10%" className="subtitle right">
                QTD.
              </th>

              <th width="5%" className="subtitle">
                UN.
              </th>

              <th width="10%" className="subtitle right">
                UNIDADES
              </th>

              <th width="10%" className="subtitle">
                EMBALAGEM
              </th>
            </tr>

            <tr>
              <td colSpan="6" className="p0">
                <hr className="titleLineBorder m0" style={{ height: '2px' }} />
              </td>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th colSpan="6" className="textAlignCenter">
                  <LinearProgress
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {providers.length ? (
              providers.map((provider, index) => {
                provider.type = `E${parseFloat(provider.quantity)}x${
                  provider.skuInfo.quantity
                }${provider.skuInfo.unitsMeasurements.abbreviation}`;

                const sameNameAsLastIndex =
                  index &&
                  providers[index - 1].providerId === provider.providerId;

                const hasBorderLine =
                  providers.length - 1 !== index &&
                  providers[index + 1].providerId !== provider.providerId;

                const providerName = sameNameAsLastIndex ? null : provider.name;

                return (
                  <>
                    <tr>
                      <td className="description">
                        {ValidationLength(providerName, 30)}
                      </td>

                      <td className="description">
                        {provider?.suppliers?.cnpjNumber}
                      </td>

                      <td className="description right">
                        {(provider.skuInfo.quantity || 0).toLocaleString(
                          'pt-br',
                          {
                            minimumFractionDigits: 3
                          }
                        )}
                      </td>

                      <td className="description">
                        {provider?.skuInfo?.unitsMeasurements?.abbreviation}
                      </td>

                      <td className="description right">
                        {parseFloat(provider?.quantity || 0).toLocaleString(
                          'pt-br',
                          {
                            minimumFractionDigits: 3
                          }
                        )}
                      </td>

                      <td className="description">{provider?.type}</td>
                    </tr>

                    {hasBorderLine ? (
                      <tr>
                        <td colSpan="6" className="p0">
                          <hr
                            className="titleLineBorder m0"
                            style={{ height: '2px' }}
                          />
                        </td>
                      </tr>
                    ) : null}
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="description textAlignCenter">
                  <Typography style={{ margin: '5px' }}>
                    Não há fornecedores associados.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersDialog);
