import React from 'react';

import { Paper } from '@material-ui/core';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable
      handle={props.handleId || '#draggable-dialog-title'}
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds={props?.bounds ? 'parent' : null}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default PaperComponent;
