/**
 *
 * @param {Number} numberToComplete Integer to be completed with decimal places
 * @param {Number} decimalPLaces Optional parameter. Indicates how many decimal places the number must have.
 * @returns Returns the number to be completed with the number of decimal places
 */

const padNumber = (number, decimalPlaces) =>
  String(number).padStart(decimalPlaces, 0);

export const completeNumberWithDecimal = (
  numberToComplete,
  { decimalPlaces } = { decimalPlaces: 3 }
) => {
  if (typeof numberToComplete !== 'number') {
    return;
  }

  if (typeof decimalPlaces !== 'number') {
    throw new Error('decimalPlaces type must be number');
  }

  const numberInString = numberToComplete.toString();
  const lastNumbers = numberInString.substr(-decimalPlaces);

  const firstNumbers =
    numberInString.length > decimalPlaces
      ? numberInString.substring(0, numberInString.length - decimalPlaces)
      : undefined;
  const padLastNumbers = padNumber(lastNumbers, decimalPlaces);

  return firstNumbers
    ? `${firstNumbers},${padLastNumbers}`
    : `0,${padLastNumbers}`;
};
