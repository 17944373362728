import React from 'react';

import { LinearProgress, Tooltip, Fade } from '@material-ui/core';

import InputNumber from 'components/Inputs/InputNumber';
import OrderBy from 'components/OrderBY';
import RightIconTooltip from 'components/RightIconTooltip';
import SelectItemList from 'components/SelectItemList';
import TableIcon from 'components/TableIcon';

import InfoIconTooltip from './components/InfoIconTooltip';
import ItemSelect from './components/ItemSelect';
import LeftIconLighthouse from './components/LeftIconLighthouse';
import SelectAll from './components/SelectAll';
import {
  StyledTable,
  StyledTH,
  StyledTD,
  StyledTR,
  StyleTableBody,
  SubStyledTR,
  SubStyledTH,
  InputWrapper
} from './style';

function BloomTable({
  tableData,
  setTableData,
  setModalStates,
  modalStates,
  setProductsIds,
  setUcValue,
  tableSelectedItems,
  setTableSelectedItems,
  setCurrentModalTitle,
  handleOrdernationFilter,
  setTableToModalProps,
  loading,
  erroMensagem,
  setCurrentProduct,
  setDataDialog,
  tableFixed = true,
  dynamicWidth = false
}) {
  const getColSpanSize = (headerSize, subHeaderSize) => {
    if (!headerSize) return 1;
    const internalHeaderSize = headerSize + 1;
    return internalHeaderSize / subHeaderSize;
  };

  const handleTableValues = (inputValue, item, tableData, itens) => {
    const { modalToTableProps: { quantityeditByModal } = {} } = item;
    if (quantityeditByModal) {
      item.modalToTableProps.quantityeditByModal = false;
    }

    if (Array.isArray(item.content)) {
      const [_, second] = item.content;
      const newList = [inputValue, second];
      item.content = newList;
    } else {
      item.content = inputValue;
    }

    if (item) {
      itens[0][item?.key] = inputValue;
    }

    if (itens[0]?.selected) {
      const newSelectedItens = tableSelectedItems.map((el) => {
        if (el.id === item.id) {
          el[item?.key] = inputValue;
        }
        return el;
      });
      setTableSelectedItems(newSelectedItens);
    }
    setTableData(tableData);
  };

  return (
    <StyledTable eachTD={4} tableFixed={tableFixed} dynamicWidth={dynamicWidth}>
      <thead>
        <StyledTR backGroundColor="#313347">
          {tableData.header.map((data) => (
            <>
              <StyledTH
                backGroundColor={data.backgroundColor}
                width={data.size}
                colSpan={data.subHeaders.length}
                borderRight={
                  !!(
                    data?.groupHeaders?.length == 0 ||
                    data?.groupHeaders?.length == undefined
                  )
                }
                borderColor={data.borderColor}
              >
                {data.isBloom && <TableIcon icon="bloomSM" />}
                {data.name}
                {data.iconName && (
                  <TableIcon
                    icon={data.iconName}
                    name={data?.infoIcon?.toolTip}
                  />
                )}
                {data.infoIcon && (
                  <InfoIconTooltip title={data?.infoIcon?.toolTip} />
                )}
                {data.order && (
                  <OrderBy
                    filterName={data?.order?.filterName}
                    handleFilter={handleOrdernationFilter}
                  />
                )}
              </StyledTH>

              {data?.groupHeaders?.map((element, index) => (
                <StyledTH
                  width={element.size}
                  borderRight={data.groupHeaders.length - 1 == index}
                >
                  {element.name}

                  {element.iconName && (
                    <TableIcon
                      icon={element.iconName}
                      name={element?.infoIcon?.toolTip}
                    />
                  )}

                  {element.infoIcon && (
                    <InfoIconTooltip title={element?.infoIcon?.toolTip} />
                  )}
                  {element.order && (
                    <OrderBy
                      filterName={element?.order?.filterName}
                      handleFilter={handleOrdernationFilter}
                    />
                  )}
                </StyledTH>
              ))}
            </>
          ))}
        </StyledTR>

        <SubStyledTR>
          {tableData.header.map((data) =>
            data?.subHeaders?.map((element, index) => (
              <SubStyledTH
                width={element.size}
                colSpan={element.colSpan}
                borderRight={data.subHeaders.length - 1 == index}
                alignRight={element.alignRight}
                textAlign={element.textAlign}
                borderColor={data.borderColor}
                fontColor={element.fontColor}
              >
                {element.action === 'selectAll' && (
                  <SelectAll
                    tableData={tableData}
                    setTableData={setTableData}
                    tableSelectedItems={tableSelectedItems}
                    setTableSelectedItems={setTableSelectedItems}
                  />
                )}

                {element.name}

                {element.iconName && (
                  <TableIcon
                    icon={element.iconName}
                    name={element?.infoIcon?.toolTip}
                  />
                )}

                {element.infoIcon && (
                  <InfoIconTooltip title={element?.infoIcon?.toolTip} />
                )}
                {element.order && (
                  <OrderBy
                    filterName={element?.order?.filterName}
                    handleFilter={handleOrdernationFilter}
                    tinyArrow
                  />
                )}
              </SubStyledTH>
            ))
          )}
        </SubStyledTR>
      </thead>

      <StyleTableBody>
        {loading && (
          <tr>
            <th colSpan="17" style={{ margin: '10px', textAlign: 'center' }}>
              <LinearProgress id="loading" variant="query" />
            </th>
          </tr>
        )}

        {tableData?.collunms?.length ? (
          tableData.collunms.map((itens) => (
            <StyledTR withLine hoverActive>
              {itens.map((item) => (
                <StyledTD
                  borderRight={item.borderTable}
                  align={item.align === 'number'}
                  padding={item.padding === '0px'}
                  borderColor={item.borderColor}
                  buttonRectColor={item.buttonRectColor}
                >
                  <Tooltip
                    TransitionComponent={Fade}
                    disableFocusListener={!item.buttonTooltip}
                    disableHoverListener={!item.buttonTooltip}
                    TransitionProps={{ timeout: 600 }}
                    title={item.buttonTooltip || null}
                  >
                    <InputWrapper
                      typeAlign={item.align === 'number'}
                      inputSize={
                        item.containerSize ? item.containerSize : item.inputSize
                      }
                      contentBlueCard={item.contentBlueCard}
                      alignCenter={item.align === 'center'}
                      display={item.display}
                    >
                      {item.action === 'selectItem' && (
                        <SelectItemList
                          selectOption={item.selected}
                          item={item}
                          tableData={tableData}
                          setTableData={setTableData}
                          setTableSelectedItems={setTableSelectedItems}
                          tableSelectedItems={tableSelectedItems}
                        />
                      )}

                      {!Array.isArray(item.content) && (
                        <ItemSelect
                          setCurrentProduct={
                            item?.setCurrentProduct && setCurrentProduct
                          }
                          productsIds={item.productsIds && item.productsIds}
                          setProductsIds={setProductsIds}
                          ucValue={item.ucValue && item.ucValue}
                          setUcValue={setUcValue}
                          setModalStates={setModalStates}
                          modalStates={modalStates}
                          itemName={item.content}
                          item={item?.item}
                          action={item.action}
                          secondAction={item?.secondAction}
                          validColor={item?.valid}
                          type={item.type}
                          setCurrentModalTitle={setCurrentModalTitle}
                          modalTitle={itens[0].content}
                          tooltip={item.tooltip}
                          buttonTooltip={item.buttonTooltip}
                          itemLength={item.itemLength}
                          buttonLinkColor={item.buttonLink}
                          inputSize={item.inputSize}
                          modalToTableProps={item}
                          setTableToModalProps={setTableToModalProps}
                          fractionDigits={item.fractionDigits}
                          setDataDialog={setDataDialog}
                          dataToDialog={item.dataToDialog}
                          contentBlueCard={item.contentBlueCard}
                          buttonRectColor={item.buttonRectColor}
                          underline={item.underline}
                          fontStyle={item.fontStyle}
                        />
                      )}

                      {item.leftIconLighthouse && (
                        <LeftIconLighthouse color={item.leftIconLighthouse} />
                      )}

                      {Array.isArray(item.content) &&
                        item.action === 'editable' && (
                          <>
                            <InputNumber
                              initialValue={item.content[0]}
                              handleValue={handleTableValues}
                              item={item}
                              itens={itens}
                              tableData={tableData}
                              disableConvertion={item?.disableConvertion}
                              size={item?.inputSize}
                              validColor={item?.valid}
                              fractionDigits={item.fractionDigits}
                            />
                            <ItemSelect
                              productsIds={item.productsIds && item.productsIds}
                              setProductsIds={setProductsIds}
                              setModalStates={setModalStates}
                              modalStates={modalStates}
                              itemName={item.content[1]}
                              action={item.secondAction}
                              validColor={item?.valid}
                              type={item.type}
                              setCurrentModalTitle={setCurrentModalTitle}
                              modalTitle={itens[0].content}
                              tooltip={item.tooltip}
                              buttonTooltip={item.buttonTooltip}
                              itemLength={item.itemLength}
                              buttonLinkColor={item.buttonLink}
                              inputSize={item.inputSize}
                              modalToTableProps={item}
                              setTableToModalProps={setTableToModalProps}
                              fractionDigits={item.fractionDigits}
                              contentBlueCard={item.contentBlueCard}
                              buttonRectColor={item.buttonRectColor}
                              underline={item.underline}
                              fontStyle={item.fontStyle}
                            />
                          </>
                        )}

                      {Array.isArray(item.content) &&
                        item.action !== 'editable' && (
                          <>
                            <ItemSelect
                              productsIds={item.productsIds && item.productsIds}
                              setProductsIds={setProductsIds}
                              setModalStates={setModalStates}
                              modalStates={modalStates}
                              itemName={item.content[0]}
                              action={item.secondAction}
                              validColor={item?.valid}
                              type={item.type}
                              setCurrentModalTitle={setCurrentModalTitle}
                              modalTitle={itens[0].content}
                              tooltip={item.tooltip}
                              buttonTooltip={item.buttonTooltip}
                              itemLength={item.itemLength}
                              buttonLinkColor={item.buttonLink}
                              inputSize={item.inputSize}
                              modalToTableProps={item}
                              setTableToModalProps={setTableToModalProps}
                              fractionDigits={item.fractionDigits}
                              contentBlueCard={item.contentBlueCard}
                              buttonRectColor={item.buttonRectColor}
                              underline={item.underline}
                              fontStyle={item.fontStyle}
                            />
                            <ItemSelect
                              productsIds={item.productsIds && item.productsIds}
                              setProductsIds={setProductsIds}
                              setModalStates={setModalStates}
                              modalStates={modalStates}
                              itemName={item.content[1]}
                              action={item.secondAction}
                              validColor={item?.valid}
                              type={item.type}
                              setCurrentModalTitle={setCurrentModalTitle}
                              modalTitle={itens[0].content}
                              tooltip={item.tooltip}
                              buttonTooltip={item.buttonTooltip}
                              itemLength={item.itemLength}
                              buttonLinkColor={item.buttonLink}
                              inputSize={item.inputSize}
                              modalToTableProps={item}
                              setTableToModalProps={setTableToModalProps}
                              fractionDigits={item.fractionDigits}
                              secondContentGray={item.secondContentGray}
                              contentBlueCard={item.contentBlueCard}
                              buttonRectColor={item.buttonRectColor}
                              underline={item.underline}
                              fontStyle={item.fontStyle}
                            />
                          </>
                        )}

                      {item.rightIcon &&
                        Array.isArray(item.rightIcon) &&
                        item.rightIcon.map((rightIcon) => (
                          <>
                            {rightIcon.show && (
                              <RightIconTooltip
                                productsIds={
                                  rightIcon.productsIds && rightIcon.productsIds
                                }
                                setProductsIds={setProductsIds}
                                setModalStates={setModalStates}
                                modalStates={modalStates}
                                description={rightIcon.tooltip}
                                itemOption={rightIcon}
                                action={rightIcon.action || null}
                              />
                            )}
                          </>
                        ))}

                      {item.rightIcon &&
                        !Array.isArray(item.rightIcon) &&
                        item.rightIcon.show && (
                          <RightIconTooltip
                            productsIds={item.productsIds && item.productsIds}
                            setProductsIds={setProductsIds}
                            setModalStates={setModalStates}
                            modalStates={modalStates}
                            description={item.rightIcon.tooltip}
                            itemOption={item.rightIcon}
                            action={item.rightIcon.action || null}
                          />
                        )}
                    </InputWrapper>
                  </Tooltip>
                </StyledTD>
              ))}
            </StyledTR>
          ))
        ) : (
          <tr>
            <th colSpan="13" style={{ margin: '10px', textAlign: 'center' }}>
              {erroMensagem}
            </th>
          </tr>
        )}
      </StyleTableBody>
    </StyledTable>
  );
}

export default BloomTable;
