const INITAL = 0;

export default function countInfo(state = INITAL, action) {
  switch (action.type) {
    case 'SET_COUNT_ID':
      return {
        ...state,
        origin: action.origin,
        id: action.id,
        date: action.date,
        assortmentInfo: action.assortmentInfo,
        subtractDays: action.subtractDays
      };
    default:
      return state;
  }
}
