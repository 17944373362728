import greenUpArrow from 'images/icons/arrows/greenUpArrowWhite.svg';
import moreInfoArrow from 'images/icons/arrows/moreInfoArrowGray.svg';
import moreInfoArrowBlue from 'images/icons/arrows/moreInfoArrowLightBlue.svg';
import redDownArrow from 'images/icons/arrows/redDownArrowWhite.svg';
import addVisionIcon from 'images/icons/combinations/addVisionIcon.svg';
import editCardIconNegative from 'images/icons/combinations/editCardIcon-negative.svg';
import editCardIcon from 'images/icons/configsAndSetups/configicon.svg';
import changeVisualizationIcon from 'images/icons/objects/eyes/changeVisualizationIcon.svg';
import visualizationIcon from 'images/icons/objects/monitors/visualizationIcon.svg';
import addCardIcon from 'images/icons/objects/stores/addCardIcon.svg';
import addNewCardIcon from 'images/icons/signals/addsAndCreates/addNewCardIcon.svg';
import yellowExclamation from 'images/icons/signals/alerts/yellowExclamationWhite.svg';
import correctIcon from 'images/icons/signals/checks/correct-icon.svg';
import xIcon from 'images/icons/signals/closesAndCancels/x-icon.svg';
import institutionalIcon from 'images/icons/signals/infos/institutionalIcon.svg';
import trashIcon from 'images/icons/signals/removesAndDeletes/trashIcon.svg';

export const iconTrendPaths = {
  green: greenUpArrow,
  red: redDownArrow,
  yellow: yellowExclamation
};

export const ConfigOptions = {
  addCard: addCardIcon,
  changeVisualization: changeVisualizationIcon,
  configIcon: editCardIcon,
  visualization: visualizationIcon,
  edit: editCardIcon
};

export const EditModeIcons = {
  xicon: xIcon,
  correctIcon,
  editCard: editCardIconNegative,
  addNewCard: addNewCardIcon,
  addVision: addVisionIcon,
  trashIcon
};

export const MoreInfoArrowBlue = moreInfoArrowBlue;
export const MoreInfoArrow = moreInfoArrow;

export const InstitutionalIcon = institutionalIcon;

export const lightHouseColors = {
  green: '#3DCC6E',
  red: '#FF9999',
  yellow: '#FFBF3E',
  neutroN5: '#A0A1A7',
  white: '#FFFFFF',
  gray: '#A0A1A7'
};
