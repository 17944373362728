export function setSkus(data) {
    return {
        type: 'SET_SKUS_DATA',
        data,
    };
}

export function createSkus(data) {
    return {
        type: 'CREATE_SKUS_DATA',
        data,
    };
}