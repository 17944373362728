/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { connect } from 'react-redux';

import ForwardArrow from 'images/icons/arrows/forwardArrowGray.svg';
import ForwardWhite from 'images/icons/arrows/forwardWhite.svg';
import Clipboard from 'images/icons/objects/clipboards/clipboard.svg';
import ClockYellow from 'images/icons/objects/clocks/clockNavyYellow.svg';
import CheckGray from 'images/icons/signals/checks/checkGray.svg';
import CheckGreen from 'images/icons/signals/checks/checkOutlineGreen.svg';
import Environment from 'utils/environments';

import DialogOrderStatus from '../dialogs/DialogOrderStatus';

function QuotationOrderStatus({
  value,
  item,
  isIntention,
  userInfo,
  onClickApprove
}) {
  const restaurants = userInfo.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat()
    .map((item) => item);
  const environments = Environment(process.env.REACT_APP_ENV);
  const enableOpenDialog =
    value === 'INCLUDED' ||
    value === 'IN PROGRESS' ||
    value === 'RECEIVED' ||
    value?.indexOf('PARTIALLY_RECEIVED') >= 0 ||
    value === 'RECEIVED_DIVERGENCE';
  const [orderStatusContent, setOrderStatusContent] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loadingMainContent, setLoadingMainContent] = useState(false);
  const [modalStatesObj, setModalStatesObj] = useState({
    orderStatus: {
      open: false
    }
  });
  const [orderStatus, setOrderStatus] = useState({
    background: '',
    content: '',
    color: '',
    icon: ''
  });

  const handleGetOrderStatusContent = (item) => {
    setLoadingMainContent(true);

    const skuIds =
      [
        ...new Set(
          item?.products
            ?.map((product) => product?.skus?.flat())
            .flat()
            .map((sku) => sku?.skuId)
        )
      ] || '';

    axios
      .get(
        `${environments.orders}/quotations/orders?ids=${item?.orders?.orderIds}&skuIds=${skuIds}`
      )
      .then((response) => {
        setModalStatesObj({
          ...modalStatesObj,
          orderStatus: {
            open: true
          }
        });
        setOrderStatusContent(response?.data);
        setLoadingMainContent(false);
      })
      .catch((error) => {
        setErrorMessage('Erro ao buscar dados.');
        setLoadingMainContent(false);
        console.log('error =>', error);
      });
  };

  const statusInfo = (status) => {
    let [title, bgColor] = '';

    if (
      status?.indexOf('PARTIALLY_RECEIVED') >= 0 &&
      status?.split('-')[0]?.trim() === 'PARTIALLY_RECEIVED'
    ) {
      title = 'Parcialmente recebido';
      bgColor = '#FFF2CC';
    }

    switch (status) {
      case 'APPROVED':
        title = 'Aprovado';
        bgColor = '#D2F9D6';
        break;
      case 'INCLUDED':
        title = 'Incluído';
        bgColor = '#E6E7F0';
        break;
      case 'IN PROGRESS':
        title = 'Andamento';
        bgColor = '#DADDF3';
        break;
      case 'RECEIVED':
        title = 'Recebido';
        bgColor = '#D2F9D6';
        break;
      case 'RECEIVED_DIVERGENCE':
        title = 'Divergente';
        bgColor = '#FFF2CC';
        break;
      case 'TO APPROVE':
        title = 'A aprovar';
        bgColor = '#FFF2CC';
        break;
    }

    return {
      title,
      bgColor
    };
  };

  const statusIcon = (status, white) => {
    if (
      status?.indexOf('PARTIALLY_RECEIVED') >= 0 &&
      status?.split('-')[0]?.trim() === 'PARTIALLY_RECEIVED'
    ) {
      return CheckGray;
    }

    switch (status) {
      case 'APPROVED':
        return CheckGreen;
      case 'INCLUDED':
        return Clipboard;
      case 'IN PROGRESS':
        return white ? ForwardWhite : ForwardArrow;
      case 'RECEIVED':
        return CheckGray;
      case 'RECEIVED_DIVERGENCE':
        return CheckGray;
      case 'TO APPROVE':
        return ClockYellow;
    }
  };

  const statusTranslate = (status) => {
    if (
      status?.indexOf('PARTIALLY_RECEIVED') >= 0 &&
      status?.split('-')[0]?.trim() === 'PARTIALLY_RECEIVED'
    ) {
      return `Parcial ${status.split('-')[1]?.trim()}`;
    }

    switch (status) {
      case 'APPROVED':
        return `Aprovado ${
          item?.orders?.quantityStatus && !isIntention
            ? item?.orders?.quantityStatus
            : ''
        }`;
      case 'INCLUDED':
        return `Incluído ${
          item?.orders?.quantityStatus && !isIntention
            ? item?.orders?.quantityStatus
            : ''
        }`;
      case 'IN PROGRESS':
        return `Andam. ${
          item?.orders?.quantityStatus && !isIntention
            ? item?.orders?.quantityStatus
            : ''
        }`;
      case 'RECEIVED':
        return `Recebido ${
          item?.orders?.quantityStatus && !isIntention
            ? item?.orders?.quantityStatus
            : ''
        }`;
      case 'RECEIVED_DIVERGENCE':
        return `Recebido ${
          item?.orders?.quantityStatus && !isIntention
            ? item?.orders?.quantityStatus
            : ''
        }`;
      case 'TO APPROVE':
        return `A aprovar ${
          item?.orders?.quantityStatus && !isIntention
            ? item?.orders?.quantityStatus
            : ''
        }`;
    }
  };

  const buildOrderStatus = (value) => {
    let background = '';
    let content = '';
    let color = '';
    let icon = '';

    if (
      value?.indexOf('PARTIALLY_RECEIVED') >= 0 &&
      value?.split('-')[0]?.trim() === 'PARTIALLY_RECEIVED'
    ) {
      background =
        item?.orders?.hasDivergence && !isIntention ? '#FFF2CC' : '#D2F9D6';
      content = statusTranslate(value);
      color = '#9296AC';
      icon = statusIcon(value);
    }

    if (
      item?.orders?.hasDivergence &&
      !isIntention &&
      value &&
      value !== 'NONE'
    ) {
      background = '#F2B90D';
      content = statusTranslate(value);
      color = '#FFFFFF';
      icon = statusIcon(value);
    } else {
      switch (value) {
        case 'APPROVED':
          background = '#D2F9D6';
          content = statusTranslate(value);
          color = '#08AA15';
          icon = statusIcon(value);
          break;
        case 'INCLUDED':
          background = '#E6E7F0';
          content = statusTranslate(value);
          color = '#9296AC';
          icon = statusIcon(value);
          break;
        case 'IN PROGRESS':
          background = '#DADDF3';
          content = statusTranslate(value);
          color = '#9296AC';
          icon = statusIcon(value);
          break;
        case 'RECEIVED':
          background = '#D2F9D6';
          content = statusTranslate(value);
          color = '#9296AC';
          icon = statusIcon(value);
          break;
        case 'RECEIVED_DIVERGENCE':
          background = '#FFF2CC';
          content = statusTranslate(value);
          color = '#9296AC';
          icon = statusIcon(value);
          break;
        case 'TO APPROVE':
          background = '#FFF2CC';
          content = statusTranslate(value);
          color = '#AA8208';
          icon = statusIcon(value);
          break;
      }
    }

    setOrderStatus({
      background,
      content,
      color,
      icon
    });
  };

  useEffect(() => {
    buildOrderStatus(value);
    setOrderStatusContent([]);
  }, [value, item]);

  return (
    <>
      <div
        className={`${orderStatus?.content ? 'dFlex' : 'dNone'} ${
          enableOpenDialog && 'cursorPointer'
        } alignCenter justifyCenter`}
        style={{
          minWidth: '100px',
          maxWidth: '100%',
          background: orderStatus?.background,
          padding: '5px',
          borderRadius: '4px',
          paddingLeft: isIntention ? '10px' : '7px',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          cursor: onClickApprove ? 'pointer' : 'default'
        }}
        onClick={() => {
          if (!enableOpenDialog) return null;

          handleGetOrderStatusContent(item);
        }}
      >
        <span
          className="mr5 fontSizeDefault"
          style={{ color: orderStatus?.color }}
        >
          {orderStatus?.content}
        </span>

        {orderStatus?.icon ? (
          <img
            style={{ width: '12px' }}
            src={orderStatus?.icon}
            alt={`${orderStatus?.icon}`}
          />
        ) : null}
      </div>

      {modalStatesObj?.orderStatus?.open ? (
        <DialogOrderStatus
          open={modalStatesObj?.orderStatus?.open}
          loadingMainContent={loadingMainContent}
          handleClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              orderStatus: {
                open: false
              }
            });
          }}
          errorMessage={errorMessage}
          statusInfo={statusInfo}
          orderStatusContent={orderStatusContent}
          restaurants={restaurants}
          item={item}
        />
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(QuotationOrderStatus);
