/* eslint-disable no-unused-expressions */
import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import negotiation from 'images/icons/contacts/chats/negotiation.svg';
import negotiationFull from 'images/icons/contacts/chats/negotiationFull.svg';
import isPlatformIconEmpty from 'images/icons/empties/isPlatformIconEmpty.svg';
import lastQuotation from 'images/icons/lettersAndWords/letters/lastQuotation.svg';
import manualQuotation from 'images/icons/lettersAndWords/letters/manualQuotation.svg';
import systemQuotation from 'images/icons/lettersAndWords/letters/systemQuotation.svg';
import withoutQuotation from 'images/icons/lettersAndWords/letters/withoutQuotation.svg';
import isPlatformIcon from 'images/icons/logo/isPlatformIcon.svg';
import providerIconValid from 'images/icons/objects/clipboards/providerIcon.svg';
import providerIconInValid from 'images/icons/objects/clipboards/providerIconEmpty.svg';
import rightIcon from 'images/icons/signals/checks/richtIcon.svg';
import rightIconFull from 'images/icons/signals/checks/rightIconFull.svg';
import CloseIconBlack from 'images/icons/signals/closesAndCancels/closeBlack.svg';
import CloseIconGray from 'images/icons/signals/closesAndCancels/closeGray.svg';
import none from 'images/icons/signals/removesAndDeletes/none.svg';
import QuoatationByVolumeIcon from 'images/icons/volumes/quotationByVolumeIcon.svg';
import QuoatationByVolumeIconFull from 'images/icons/volumes/quotationByVolumeIconFull.svg';

import { RightItemIcon } from './style';

function RightIconTooltip({
  productsIds,
  setProductsIds,
  setModalStates,
  modalStates,
  description,
  itemOption,
  action
}) {
  const itemOptions = {
    quotationByVolume: {
      valid: QuoatationByVolumeIconFull,
      inValid: QuoatationByVolumeIcon
    },
    originQuotation: {
      none,
      f: systemQuotation,
      m: manualQuotation,
      c: lastQuotation,
      p: withoutQuotation
    },
    quotationNegotiation: {
      valid: negotiation,
      inValid: negotiationFull
    },
    rightIcon: {
      valid: rightIconFull,
      inValid: rightIcon
    },
    isPlatformIcon: {
      valid: isPlatformIcon,
      inValid: isPlatformIconEmpty
    },
    providerIcon: {
      valid: providerIconValid,
      inValid: providerIconInValid
    },
    deleteIcon: {
      valid: CloseIconBlack,
      inValid: CloseIconGray
    }
  };

  return (
    <Tooltip
      title={<span style={{ padding: '6px' }}>{description}</span>}
      placement="right"
    >
      <RightItemIcon
        onClick={() => {
          setModalStates && setModalStates({ ...modalStates, [action]: true });
          productsIds && setProductsIds(productsIds);
          itemOption.action &&
            itemOption.action(
              itemOption?.providersQuotes,
              itemOption?.quotationElement
            );
        }}
        src={itemOptions[itemOption.iconName][itemOption.setOption]}
        typeLink={itemOption.setOption === 'valid'}
      />
    </Tooltip>
  );
}

export default RightIconTooltip;
