/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DeleteOutline } from '@material-ui/icons';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import ProductsDialog from 'components/Dialogs/SimpleDialogs/ProductsDialog';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import StorageLocationForm from 'pages/inventory/storage-Locations/StorageLocationForm';
import * as StorageLocationsActions from 'store/actions/storageLocations';
import { GlobalStyle, Container } from 'styles/general';
import Environment from 'utils/environments';

function StorageLocations({
  userInfo,
  setStorageLocations,
  createStorageLocations
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [callSettings, setCallSettings] = useState({
    getId: userInfo.companiesActive.id,
    page: 1,
    totalPages: 0,
    size: 100,
    mainContent: [],
    numberOfElements: 0,
    loading: true,
    totalElements: 0
  });
  const { getId, page, size, mainContent, loading } = callSettings;
  const [activeStatusFilter, setActiveStatusFilter] = useState(true);
  const [storageLocationModal, setStorageLocationModal] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [personalMessage, setPersonalMessage] = useState();
  const [productsModal, setProductsModal] = useState()
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'productModal', open: false, fullScreen: false }
  ]);

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  function handleDelete() {
    const baseUrl = `${environments.catalog}/storageLocations`;
    setUpdateList(true);

    axios
      .delete(`${baseUrl}/${deleteData.id}`, {
        method: 'DELETE',
        user: userInfo.user,
        headers: { User: `${userLocal?.user}` }
      })
      .then((response) => {
        setAlertErr(true);
        setPersonalMessage('Local de Estoque deletado com sucesso!');
        setUpdateList(false);
        handleDialogClose();
      })
      .catch((error) => {
        setAlertErr(true);
        setAlertMessage(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
      });
  }

  const toggleStorageLocationModal = () => {
    setStorageLocationModal(!storageLocationModal);

    if (storageLocationModal === false) {
      setUpdateList(false);
    }
  };

  const addStorageLocation = () => (
    <Tooltip title="Cadastrar Local de Estoque" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          toggleStorageLocationModal();
          createStorageLocations();
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  const orderArray = (array) =>
    array.sort((a, b) => {
      const descriptionA = a.description.toUpperCase();
      const descriptionB = b.description.toUpperCase();

      if (descriptionA < descriptionB) {
        return -1;
      }
      if (descriptionA > descriptionB) {
        return 1;
      }
      return 0;
    });

  const statusFilter = () => (
    <FormControlLabel
      className="toggleDefault m0 dFlex"
      control={
        <Switch
          checked={activeStatusFilter}
          onChange={(checked) => {
            setActiveStatusFilter(!activeStatusFilter);

            if (checked) {
              setCallSettings({
                ...callSettings,
                mainContent: [],
                loading: false
              });
            }
          }}
          name="Active Status Filter"
        />
      }
      label="Ativo"
    />
  );

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useEffect(() => {
    (async () => {
      setCallSettings({
        ...callSettings,
        loading: true
      });
      const statusFilterValue = activeStatusFilter ? 'ACTIVE' : 'INACTIVE';

      const response = await fetch(
        `${environments.catalog}/storageLocations?page=${page}&size=${size}&originId=${getId}&status=${statusFilterValue}`
      );
      const storageLocations = await response.json();
      setCallSettings({
        ...callSettings,
        mainContent: storageLocations.content || [],
        loading: false
      });
    })();
  }, [getId, page, updateList, activeStatusFilter]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: userInfo.companiesActive.id });
  }, [userInfo]);

  const requestProducts = (id) => {
    axios
      .get(
        `${environments.catalog}/storageLocations/${id}/products`
      ).then((response) => {
        setProductsModal(response.data)
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'productModal' ? { ...modal, open: true } : modal
          )
        );
      })
  }

  return (
    <Layout>
      <TitleHead title="Locais de Estoque" />
      
      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Locais de Estoque Cadastrados"
          additionalbutton={addStorageLocation}
          afterTitleButton={statusFilter}
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="76%" className="subtitle">
                DESCRIÇÃO
              </th>

              <th width="20%" className="subtitle p12">
                Produtos
              </th>

              <th width="5%" className="subtitle">
                STATUS
              </th>

              <th width="2%">&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th colSpan="3" className="textAlignCenter m10">
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {mainContent?.length ? (
              orderArray(mainContent).map((storageLocation) => (
                <>
                  <tr>
                    <td className="description p12">
                      <Link
                        onClick={() => {
                          setStorageLocations(storageLocation);
                          toggleStorageLocationModal();
                        }}
                      >
                        {storageLocation.description}
                      </Link>
                    </td>

                    <td className="description p12 cursorPointer lightBlue">
                      <div
                        onClick={() => requestProducts(storageLocation.id)}
                      >
                        {storageLocation.nProducts}
                      </div>
                    </td>

                    <td className="left">
                      <span className="fontSizeDefault">
                        {storageLocation.status === 'ACTIVE'
                          ? 'Ativo'
                          : 'Inativo'}
                      </span>
                    </td>

                    <td className="right p12">
                      {storageLocation.status === 'ACTIVE' && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => (
                            handleDialogClickOpen(),
                            setDeleteData(storageLocation)
                          )}
                          style={{ padding: 0, outline: 0 }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="4">
                      <hr />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <th colSpan="4" className="m10 textAlignCenter">
                  Nenhum item encontrado
                </th>
              </tr>
            )}
          </tbody>
        </table>

        {storageLocationModal && (
          <StorageLocationForm
            isModal
            openModal={storageLocationModal}
            redirectPath={null}
            onClose={toggleStorageLocationModal}
            updateList={updateList}
            setUpdateList={setUpdateList}
          />
        )}

        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            className="modalHeader navyBlue bold cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>
              Deseja realmente excluir esse local de estoque?
            </Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            {deleteData?.hasOpenCount ? (
              <Typography>
                Atenção! Este Local de estoque possui contagens ativas e
                esta ação excluirá as contagens. Deseja continuar?
              </Typography>
            ) : null}
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={handleDialogClose}
              label="Cancelar"
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              autoFocus
              design="contained"
              label="Confirmar"
              onClick={() => handleDelete()}
              color="primary"
            />
          </DialogActions>
        </Dialog>

        <ProductsDialog 
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          products={productsModal}
        />
      </Container>

      <CommonAlert
        open={alertErr}
        onClose={() => {
          setAlertErr(false);
        }}
        messagePersonal={personalMessage}
        indexMessage={alertMessage}
        statusCode={statusCode}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(StorageLocationsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StorageLocations);
