import React from 'react';

import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import { GlobalStyle } from 'styles/general';

function Blank() {
  return (
    <Layout>
      <GlobalStyle />

      <TitleHead title="Home" />
    </Layout>
  );
}

export default Blank;
