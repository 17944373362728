/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React from 'react';

import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

import {
  InfoContainer,
  InfoCardWrap,
  InfoCard,
  InconWrapTrend,
  InfoNumber,
  PercentCard,
  PercentCardWrap,
  PercentInfo,
  InforNameContainer,
  InfoDetail,
  InfoDetailSimple,
  InfoNumberTotalCost,
  InfoNumberTotalCostSimple,
  InfoCardSecondName,
  IntIconImg
} from './index.style';

import {
  iconTrendPaths,
  MoreInfoArrow,
  MoreInfoArrowBlue,
  InstitutionalIcon
} from '../utils';

function CardInfoComparation({
  filterRestaurantName,
  stores,
  cardInfo,
  lastDayOption,
  getId,
  filterRestaurants,
  companiesActive
}) {
  const { infos = [], subInfos } = cardInfo;
  const { name: lastDayName } = lastDayOption;
  return (
    <InfoContainer subInfos={subInfos}>
      {infos.map((info) => (
        <InfoCardWrap marginButton={subInfos ? '5px' : '20px'}>
          <InconWrapTrend>
            <img
              alt={iconTrendPaths[info.iconTrend]}
              src={iconTrendPaths[info.iconTrend]}
            />
          </InconWrapTrend>

          <InfoCard width="50%">
            {cardInfo.simple ? (
              <>
                <InforNameContainer>
                  <span>{info.name}</span>
                  <span>semana</span>
                </InforNameContainer>

                <InfoNumber
                  textShadow
                  align="right"
                  bold
                  color={info.iconTrend}
                >
                  {info.numberOfThen}
                </InfoNumber>

                <Tooltip
                  title={
                    <p className="indicatorValueTooltips mb0">{info.raw}</p>
                  }
                  disableHoverListener={
                    info.raw === undefined || info.raw === null
                  }
                  placement="right"
                >
                  <InfoNumberTotalCostSimple
                    textShadow={!(info?.numberOfThen !== '')}
                    color={info?.iconTrend}
                    fontSize={info?.numberOfThen !== '' ? '12px' : '20px'}
                  >
                    {info.totalCost}
                  </InfoNumberTotalCostSimple>
                </Tooltip>
              </>
            ) : (
              <>
                <InforNameContainer>
                  <span>{info.name?.toUpperCase()}</span>
                </InforNameContainer>

                <Tooltip
                  title={
                    <p className="indicatorValueTooltips mb0">{info.raw}</p>
                  }
                  disableHoverListener={
                    info.raw === undefined || info.raw === null
                  }
                  placement="right"
                >
                  <InfoNumber
                    textShadow
                    align="right"
                    bold
                    color={info.iconTrend}
                    marginTop={subInfos && '-5px'}
                  >
                    {info.numberOfThen}
                  </InfoNumber>
                </Tooltip>

                <InfoNumberTotalCost>{info.totalCost}</InfoNumberTotalCost>
              </>
            )}

            <PercentCardWrap>
              {info.percentInfo.map((percent) => (
                <PercentCard
                  justifyContent={percent.name ? 'space-between' : 'flex-end'}
                >
                  {percent.name && (
                    <Tooltip
                      title={
                        <p className="indicatorValueTooltips mb0">
                          {percent.raw}
                        </p>
                      }
                      disableHoverListener={
                        percent.raw === undefined || percent.raw === null
                      }
                      placement="bottom"
                    >
                      <PercentInfo color="white" style={{ width: '50%' }}>
                        {percent.instIcon && (
                          <Tooltip
                            title={
                              <p className="indicatorValueTooltips mb0">
                                {percent.name === 'Inflação'
                                  ? 'ÍNDICE DE INFLAÇÃO'
                                  : 'VENDAS X COMPRAS'}
                              </p>
                            }
                          >
                            <IntIconImg src={InstitutionalIcon} />
                          </Tooltip>
                        )}
                        {percent.name}
                      </PercentInfo>
                    </Tooltip>
                  )}

                  <PercentInfo
                    marginTop={subInfos && '-5px'}
                    color={percent.percentColor}
                    fontPlus={percent.fontPlus}
                    style={{ width: '50%' }}
                    align="right"
                  >
                    {percent.content}
                  </PercentInfo>
                </PercentCard>
              ))}
            </PercentCardWrap>

            {!cardInfo.simple && !subInfos && (
              <Link
                onClick={() => {
                  filterRestaurants?.length === 1 &&
                    filterRestaurantName !== 'Todos' &&
                    companiesActive(
                      stores.find((item) => item.id === filterRestaurants[0])
                        .id,
                      stores.find((item) => item.id === filterRestaurants[0])
                        .name,
                      stores.find((item) => item.id === filterRestaurants[0])
                        .index,
                      stores.find((item) => item.id === filterRestaurants[0])?.segment
                    );
                }}
                to={`${cardInfo.details}/${
                  filterRestaurants?.length === 1 &&
                  filterRestaurantName !== 'Todos'
                    ? filterRestaurants[0]
                    : getId
                }`}
                style={{
                  display: subInfos && !cardInfo.details && 'none',
                  cursor: cardInfo?.details ? 'pointer' : 'default'
                }}
              >
                <InfoDetail
                  color={!cardInfo.details && '#A0A1A7'}
                  cursor={cardInfo?.details ? 'pointer' : 'default'}
                >
                  detalhes
                  <img
                    alt={cardInfo?.details}
                    src={cardInfo?.details ? MoreInfoArrowBlue : MoreInfoArrow}
                    style={{
                      marginLeft: 3,
                      cursor: cardInfo?.details ? 'pointer' : 'default'
                    }}
                  />
                </InfoDetail>
              </Link>
            )}

            {cardInfo.simple && (
              <Link
                onClick={() => {
                  filterRestaurants?.length === 1 &&
                    filterRestaurantName !== 'Todos' &&
                    companiesActive(
                      stores.find((item) => item.id === filterRestaurants[0])
                        .id,
                      stores.find((item) => item.id === filterRestaurants[0])
                        .name,
                      stores.find((item) => item.id === filterRestaurants[0])
                        .index,
                      stores.find((item) => item.id === filterRestaurants[0])?.segment
                    );
                }}
                to={`${
                  cardInfo.detailsOrder && info.name === 'Pedidos'
                    ? `${cardInfo.detailsOrder}/${
                        filterRestaurants?.length === 1 &&
                        filterRestaurantName !== 'Todos'
                          ? filterRestaurants[0]
                          : getId
                      }`
                    : `${cardInfo.detailsStock}/${
                        filterRestaurants?.length === 1 &&
                        filterRestaurantName !== 'Todos'
                          ? filterRestaurants[0]
                          : getId
                      }`
                }`}
                style={{
                  cursor:
                    info.name === 'Pedidos'
                      ? cardInfo?.detailsOrder
                        ? 'pointer'
                        : 'default'
                      : cardInfo.detailsStock
                      ? 'pointer'
                      : 'default'
                }}
              >
                <InfoDetailSimple
                  color={
                    info.name === 'Pedidos'
                      ? !cardInfo?.detailsOrder && '#A0A1A7'
                      : !cardInfo?.detailsStock && '#A0A1A7'
                  }
                  cursor={
                    info.name === 'Pedidos'
                      ? cardInfo?.detailsOrder
                        ? 'pointer'
                        : 'default'
                      : cardInfo.detailsStock
                      ? 'pointer'
                      : 'default'
                  }
                >
                  detalhes
                  <img
                    src={
                      info.name === 'Pedidos'
                        ? cardInfo?.detailsOrder
                          ? MoreInfoArrowBlue
                          : MoreInfoArrow
                        : cardInfo?.detailsStock
                        ? MoreInfoArrowBlue
                        : MoreInfoArrow
                    }
                    alt={cardInfo?.detailsOrder}
                    style={{
                      marginLeft: 3,
                      cursor:
                        info.name === 'Pedidos'
                          ? cardInfo?.detailsOrder
                            ? 'pointer'
                            : 'default'
                          : cardInfo.detailsStock
                          ? 'pointer'
                          : 'default'
                    }}
                  />
                </InfoDetailSimple>
              </Link>
            )}
          </InfoCard>

          <InfoCard width="50%" color="#A0A1A7" marginLeft="10px">
            {cardInfo.simple ? (
              <>
                <InfoCardSecondName color="white" align="right">
                  semana -1
                </InfoCardSecondName>

                <InfoNumber color="gray" align="right" bold marginTop="4px">
                  {info.lastSevenDays.numberOfThen}
                </InfoNumber>
              </>
            ) : (
              <>
                <InfoCardSecondName align="right" color="white">
                  {lastDayName}
                </InfoCardSecondName>

                <Tooltip
                  title={
                    <p className="indicatorValueTooltips mb0">
                      {info.lastSevenDays.raw}
                    </p>
                  }
                  disableHoverListener={
                    info.lastSevenDays.raw === undefined ||
                    info.lastSevenDays.raw === null
                  }
                  placement="right"
                >
                  <InfoNumber
                    bold
                    align="right"
                    color="gray"
                    marginTop={subInfos ? '-2px' : '4px'}
                  >
                    {info.lastSevenDays.numberOfThen}
                  </InfoNumber>
                </Tooltip>
              </>
            )}

            {info.lastSevenDays.percentInfo.filter((item) => item.name)
              .length ? (
              <PercentCardWrap>
                {info.lastSevenDays.percentInfo.map((percent) => (
                  <PercentCard>
                    {percent.name && (
                      <Tooltip
                        title={
                          <p className="indicatorValueTooltips mb0">
                            {percent.raw}
                          </p>
                        }
                        disableHoverListener={
                          percent.raw === undefined || percent.raw === null
                        }
                        placement="bottom"
                      >
                        <PercentInfo color="gray" style={{ width: '57%' }}>
                          {percent.name}
                        </PercentInfo>
                      </Tooltip>
                    )}

                    <PercentInfo
                      color={percent.percentColor}
                      fontPlus={percent.fontPlus}
                      style={{ width: '43%' }}
                      align="right"
                    >
                      {percent.content}
                    </PercentInfo>
                  </PercentCard>
                ))}
              </PercentCardWrap>
            ) : (
              <>
                <Tooltip
                  title={
                    <p className="indicatorValueTooltips mb0">
                      {info.lastSevenDays.raw}
                    </p>
                  }
                  disableHoverListener={
                    info.lastSevenDays.raw === undefined ||
                    info.lastSevenDays.raw === null
                  }
                  placement="right"
                >
                  <InfoNumberTotalCostSimple
                    paddingRight={cardInfo.simple && '0'}
                    fontSize={info?.numberOfThen !== '' ? '12px' : '20px'}
                    marginTop="-1px"
                  >
                    {info.lastSevenDays.totalCost}
                  </InfoNumberTotalCostSimple>
                </Tooltip>

                <PercentCardWrap paddingRight={cardInfo.simple && '0'}>
                  {info.lastSevenDays.percentInfo.map((percent) => (
                    <>
                      {percent.name && (
                        <PercentInfo align="right">{percent.name}</PercentInfo>
                      )}

                      <PercentInfo
                        color={percent.percentColor}
                        align="right"
                        fontPlus={percent.fontPlus}
                      >
                        {percent.content}
                      </PercentInfo>
                    </>
                  ))}
                </PercentCardWrap>
              </>
            )}
          </InfoCard>
        </InfoCardWrap>
      ))}
    </InfoContainer>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(null, mapDispatchToProps)(CardInfoComparation);
