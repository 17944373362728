import styled from 'styled-components'

export const NegociationHead = styled.div`
  background-color: #F2F3F8;
  color: #454A67;
  padding: 4px 12px;

  font-size: 12px;
  
`
export const NegociationBody = styled.div`
  color: #454A67;
  padding: 4px 12px;
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 12px;
    }
  }
`