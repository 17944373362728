import styled from 'styled-components';

export const ProductWrapper = styled.div`
    max-height: 60vh;
    overflow: scroll;
    hr { 
        margin: 12px 8px;
    }
`;

export const ProductTop = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 4px 12px;
    background: #F2F3F8;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    line-height: 12px;
    color: #454A67;
`;

export const ProductListItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 12px 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    color: #1F2445;
`;
