/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import format from 'date-fns/format';
import Draggable from 'react-draggable';

import ArrowDownIcon from 'images/icons/arrows/arrowDrillDown.svg';
import CopyClipBoardIcon from 'images/icons/copyClipboards/copyClipboard.svg';
import CheckOutlineGreen from 'images/icons/signals/checks/checkOutlineGreen.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import copyClipBoard from 'utils/copyClipboard';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

import Button from '../../Button/button';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-brand-suggestion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function CreateBrandPaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-create-brand-suggestion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function BrandSuggestion({
  brandSuggestions,
  openedByGeneralSuggestion,
  setHeaderDateTimeTitle,
  setBrandSugg,
  setNewBrandSugg,
  modalSettings,
  setModalSettings,
  multipleSkuData,
  setMultipleSkuData,
  jsonBody,
  setJsonBody,
  userInfo,
  selectedItems,
  setSelectedItems,
  skuDefaultDescription,
  setSkuDefaultDescription,
  onlyView,
  suggestionsSelected,
  setSuggestionsSelected
}) {
  const currentDialogSettings = openedByGeneralSuggestion
    ? 'generalSuggestions'
    : 'brandSuggestions';
  const currentSkuIndex = modalSettings.find(
    (modal) => modal.name === currentDialogSettings
  )?.currentSkuIndex;
  const packageIds = modalSettings.find(
    (modal) => modal.name === currentDialogSettings
  )?.packageIds;
  const environments = Environment(process.env.REACT_APP_ENV);
  const isMultipleSku = currentSkuIndex || currentSkuIndex === 0;
  const [copied, setCopied] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const [newBrandName, setNewBrandName] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [internalModalSetting, setInternalModalSetting] = useState([
    {
      id: 1,
      name: 'createBrand',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    }
  ]);
  const [brandSuggestionMessage, setBrandSuggestionMessage] = useState({
    description: '',
    status: ''
  });
  const [findTotal, setFindTotal] = useState(0);

  const uniqueUrls = (arr) =>
    [...new Set(arr.map((item) => item.url))].map((url) => ({
      url,
      retiable: arr.find((el) => el.url === url).retiable
    }));

  const calcFindTotal = (suggestions) => {
    let total = 0;

    for (const suggestion of suggestions) {
      total += uniqueUrls(suggestion.urls || []).length;
    }

    setFindTotal(total);
  };

  const calcPercentage = (arr) => {
    const urls = uniqueUrls(arr?.urls || []);

    return findTotal ? (urls.length * 100) / findTotal : arr.percentage;
  };

  const orderedSuggestions = (suggestions) => {
    const relevantItems = suggestions
      .filter((element) => element.relevant)
      .sort((a, b) => (a.percentage < b.percentage ? 1 : -1));
    const otherItems = suggestions
      .filter((element) => !element.relevant)
      .sort((a, b) => (a.percentage < b.percentage ? 1 : -1));

    return [relevantItems, otherItems].flat();
  };

  const handleChangeRadio = (event, sugg) => {
    if (!sugg?.brand) {
      setInternalModalSetting(
        internalModalSetting.map((modal) =>
          modal.name === 'createBrand'
            ? {
                ...modal,
                open: true,
                value: event.target.value,
                suggestion: sugg
              }
            : modal
        )
      );
      setNewBrandName(event.target.value);
    } else {
      setRadioValue(event.target.value);

      openedByGeneralSuggestion
        ? setBrandSugg(sugg)
        : handleFinishProcess(sugg);
      openedByGeneralSuggestion &&
        setSuggestionsSelected({ ...suggestionsSelected, brand: '' });
    }
  };

  const updateBrand = (obj, newBrand) => {
    const body = {
      urls: obj?.urls || [],
      description: obj?.description || '',
      type: 'BRAND',
      user: userInfo.user
    };

    axios
      .put(`${environments.catalog}/providers/${packageIds}/searchs`, body)
      .then((response) => {
        if (isMultipleSku) {
          setMultipleSkuData(
            multipleSkuData.map((sku, i) => {
              if (currentSkuIndex === i) {
                return {
                  ...sku,
                  brandsId: newBrand
                    ? newBrand?.id
                    : obj?.brand?.id || sku.brandsId,
                  brand: newBrand ? newBrand?.brand : obj?.brand || sku.brand,
                  skuDefaultDescription: {
                    ...sku.skuDefaultDescription,
                    brand: newBrand
                      ? newBrand?.description
                      : obj?.brand?.description ||
                        sku.skuDefaultDescription.brand
                  }
                };
              }
              return sku;
            })
          );
        } else {
          setSelectedItems({
            ...selectedItems,
            brand: newBrand || obj?.brand || selectedItems.brand
          });
          setJsonBody({
            ...jsonBody,
            sku: {
              ...jsonBody.sku,
              brandsId: newBrand
                ? newBrand?.id
                : obj?.brand?.id || jsonBody.sku.brandsId
            }
          });

          setSkuDefaultDescription({
            ...skuDefaultDescription,
            brand: newBrand
              ? newBrand?.description
              : obj?.brand?.description || skuDefaultDescription.brand
          });
        }

        setSuggestions([]);
        setLoading(false);
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'brandSuggestions'
              ? { ...modal, open: false }
              : modal
          )
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setBrandSuggestionMessage({
          description: error.response.data.message,
          status: 'error'
        });
      });
  };

  const handleFinishProcess = (obj) => {
    setBrandSuggestionMessage({
      ...brandSuggestionMessage,
      description: '',
      status: ''
    });
    setLoading(false);

    if (onlyView || !obj) {
      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'brandSuggestions' ? { ...modal, open: false } : modal
        )
      );
    } else {
      updateBrand(obj);
    }
  };

  const getSuggestions = () => {
    setLoading(true);

    axios
      .get(`${environments.catalog}/providers/${packageIds}/searchs?type=BRAND`)
      .then((response) => {
        setSuggestions(response?.data);
        calcFindTotal(response?.data);
        setRadioValue(
          response?.data?.find((el) => el.selected)?.description || ''
        );
        setHeaderDateTimeTitle &&
          setHeaderDateTimeTitle(
            new Date(response?.data[0]?.created_at || Date())
          );
        setLoading(false);
      })
      .catch((error) => {
        setSuggestions([]);
        setLoading(false);
        console.log(error);
      });
  };

  const createBrand = (obj) => {
    setLoading(true);

    const body = {
      description: newBrandName,
      originId: userInfo.companiesActive.id,
      user: userInfo.user,
      producersId: null
    };

    axios
      .post(`${environments.catalog}/brands`, body)
      .then((res) => {
        setSuggestions(
          suggestions.map((sugg) => {
            if (obj?.suggestion?.description === sugg.description) {
              return {
                ...sugg,
                brand: res?.data
              };
            }
            return sugg;
          })
        );

        setRadioValue(res?.data?.description);
        setLoading(false);
        setBrandSuggestionMessage({
          description: 'Marca criada com sucesso',
          status: 'success'
        });

        openedByGeneralSuggestion
          ? setNewBrandSugg(res?.data)
          : updateBrand(obj?.suggestion, res?.data);
      })
      .catch((error) => {
        setLoading(false);
        setBrandSuggestionMessage({
          description: error.response.data.message,
          status: 'error'
        });
      });
  };

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  useEffect(() => {
    if (brandSuggestions?.length) {
      setSuggestions(brandSuggestions);
      calcFindTotal(brandSuggestions);
      setHeaderDateTimeTitle(new Date());
    } else {
      getSuggestions();
    }
  }, []);

  return (
    <>
      <ConditionalWrapper
        condition={!openedByGeneralSuggestion}
        wrapper={(children) => (
          <Dialog
            fullScreen={
              modalSettings.find((modal) => modal.name === 'brandSuggestions')
                .fullScreen
            }
            open={
              modalSettings.find((modal) => modal.name === 'brandSuggestions')
                .open
            }
            onClose={() => {
              handleFinishProcess();
            }}
            className="defaultModal brandSuggestionDialog customZIndex"
            PaperComponent={PaperComponent}
          >
            {children}
          </Dialog>
        )}
      >
        <section
          className="brandSuggestionDialog flexColumn"
          style={{ width: openedByGeneralSuggestion && '30%' }}
        >
          <DialogTitle
            className="modalHeader navyBlue bold textAlignCenter"
            style={{
              cursor: openedByGeneralSuggestion ? 'auto' : 'move'
            }}
            id="draggable-dialog-brand-suggestion"
            fullWidth
          >
            <Typography
              style={{ fontSize: openedByGeneralSuggestion ? 10 : 12 }}
            >
              {`RESULTADOS DA BUSCA DE MARCA
                ${
                  suggestions?.length && !openedByGeneralSuggestion
                    ? `(${format(
                        new Date(suggestions[0].created_at),
                        'dd/MM/yy - HH'
                      )}hs)`
                    : ''
                }
              `}
            </Typography>

            {!openedByGeneralSuggestion && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  handleFinishProcess();
                }}
                aria-label="close"
                style={{ outline: '0', background: 'none', align: 'right' }}
              >
                <img
                  style={{ width: '16px' }}
                  src={CloseIconNavy}
                  alt="CloseIconNavy"
                />
              </IconButton>
            )}
          </DialogTitle>

          <DialogContent className="modalContent bgWhite dFlex flexColumn">
            {suggestions?.length ? (
              orderedSuggestions(suggestions).map((suggestion, index) => (
                <div className="suggestionItem dFlex flexColumn">
                  <div className="suggestionMainInfo dFlex pRelative">
                    {!onlyView ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="type"
                          name="type"
                          value={
                            suggestionsSelected?.brand?.description ||
                            radioValue
                          }
                          onChange={(e) => handleChangeRadio(e, suggestion)}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel
                            value={suggestion?.description}
                            control={<Radio required size="small" />}
                            label={
                              suggestion?.relevant ? (
                                <Tooltip
                                  TransitionProps={{ timeout: 600 }}
                                  title={
                                    <p
                                      className="mb0"
                                      style={{ padding: '2px 6px' }}
                                    >
                                      Resultado mais relevante
                                    </p>
                                  }
                                  placement="right-end"
                                >
                                  <span
                                    id={`suggestion-brand-name-${index}`}
                                    className="suggestionName relevant bold fontSizeDefault"
                                  >
                                    {ValidationLength(
                                      suggestion?.description || '-',
                                      25
                                    )}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span
                                  id={`suggestion-brand-name-${index}`}
                                  className="suggestionName bold fontSizeDefault"
                                >
                                  {ValidationLength(
                                    suggestion?.description || '-',
                                    25
                                  )}
                                </span>
                              )
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : suggestion?.relevant ? (
                      <Tooltip
                        TransitionProps={{ timeout: 600 }}
                        title={
                          <p className="mb0" style={{ padding: '2px 6px' }}>
                            Resultado mais relevante
                          </p>
                        }
                        placement="right-end"
                      >
                        <span
                          id={`suggestion-brand-name-${index}`}
                          className="ml0 suggestionName relevant bold fontSizeDefault"
                        >
                          {ValidationLength(suggestion?.description || '-', 25)}
                        </span>
                      </Tooltip>
                    ) : (
                      <span
                        id={`suggestion-brand-name-${index}`}
                        className="ml0 suggestionName bold fontSizeDefault"
                      >
                        {ValidationLength(suggestion?.description || '-', 25)}
                      </span>
                    )}

                    <Tooltip
                      placement="right"
                      title={
                        <p style={{ padding: '4px 6px' }}>
                          {copied ? 'Copiado' : 'Copiar'}
                        </p>
                      }
                    >
                      <a
                        onMouseLeave={() => {
                          setTimeout(() => {
                            setCopied(false);
                          }, 1000);
                        }}
                        onClick={() => {
                          copyClipBoard('suggestion-brand-name', index, '');
                          setCopied(true);
                        }}
                        className="dFlex"
                        style={{ marginLeft: 5, cursor: 'pointer' }}
                      >
                        <img
                          style={{ marginRight: '4px' }}
                          src={CopyClipBoardIcon}
                          alt="CopyClipboard"
                        />
                      </a>
                    </Tooltip>

                    <span
                      className="fontSizeDefault ml8"
                      style={{ color: '#9296AC' }}
                    >
                      {calcPercentage(suggestion).toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      }) || '-'}
                      % / {uniqueUrls(suggestion?.urls || []).length || '-'}{' '}
                      {uniqueUrls(suggestion?.urls || []).length === 1
                        ? 'site'
                        : 'sites'}
                    </span>

                    {suggestion.whiteListCount > 0 ? (
                      <div className="dFlex alignCenter ml10">
                        <img
                          style={{ marginRight: 3 }}
                          src={CheckOutlineGreen}
                          alt="CheckOutlineGreen"
                        />

                        <p
                          className="mb0 fontSizeDefault"
                          style={{ color: '#08AA15' }}
                        >
                          {suggestion.whiteListCount}{' '}
                          {suggestion.whiteListCount === 1
                            ? 'confiável'
                            : 'confiáveis'}
                        </p>
                      </div>
                    ) : null}

                    <img
                      className={`pAbsolute cursorPointer ${
                        suggestion.open && 'rotate'
                      }`}
                      style={{ width: '16px', right: '0' }}
                      src={ArrowDownIcon}
                      alt="ArrowDownIcon"
                      onClick={() => {
                        setSuggestions(
                          suggestions.map((sugg) => {
                            if (sugg.description === suggestion.description) {
                              return { ...sugg, open: !sugg.open };
                            }
                            return { ...sugg };
                          })
                        );
                      }}
                    />
                  </div>

                  {suggestion?.urls?.length && suggestion.open ? (
                    <div className="suggestionLinks dFlex flexColumn">
                      {uniqueUrls(suggestion.urls).map((sugg) => (
                        <div
                          className="dFlex alignCenter"
                          style={{ marginLeft: 2 }}
                        >
                          <a
                            target={sugg?.url ? '_blank' : '_self'}
                            href={sugg?.url ? sugg?.url : '#'}
                            rel="noreferrer"
                          >
                            {ValidationLength(sugg?.url || '-', 50)}
                          </a>

                          {sugg.retiable ? (
                            <div
                              className="dFlex alignCenter ml10"
                              style={{ marginTop: 2 }}
                            >
                              <img
                                style={{ marginRight: 3 }}
                                src={CheckOutlineGreen}
                                alt="CheckOutlineGreen"
                              />

                              <p
                                className="mb0 fontSizeDefault"
                                style={{ color: '#08AA15' }}
                              >
                                site confiável
                              </p>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              ))
            ) : (
              <div className="dFlex alignCenter justifyCenter">
                <p className="mb0 bold">Nenhuma informação encontrada</p>
              </div>
            )}
          </DialogContent>

          {loading && <LinearProgress variant="query" />}

          <Snackbar
            open={!!brandSuggestionMessage.description}
            autoHideDuration={2000}
            onClose={() => {
              setInternalModalSetting(
                internalModalSetting.map((modal) =>
                  modal.name === 'createBrand'
                    ? { ...modal, open: false }
                    : modal
                )
              );
              setBrandSuggestionMessage({ description: '', status: '' });
            }}
          >
            <Alert
              onClose={() => {
                setInternalModalSetting(
                  internalModalSetting.map((modal) =>
                    modal.name === 'createBrand'
                      ? { ...modal, open: false }
                      : modal
                  )
                );
                setBrandSuggestionMessage({ description: '', status: '' });
              }}
              severity={brandSuggestionMessage.status}
            >
              {brandSuggestionMessage.description}
            </Alert>
          </Snackbar>
        </section>
      </ConditionalWrapper>

      <Dialog
        fullScreen={
          internalModalSetting.find((modal) => modal.name === 'createBrand')
            .fullScreen
        }
        maxWidth={
          internalModalSetting.find((modal) => modal.name === 'createBrand')
            .maxWidth
        }
        open={
          internalModalSetting.find((modal) => modal.name === 'createBrand')
            .open
        }
        onClose={() => {
          setInternalModalSetting(
            internalModalSetting.map((modal) =>
              modal.name === 'createBrand' ? { ...modal, open: false } : modal
            )
          );
        }}
        PaperComponent={CreateBrandPaperComponent}
        aria-labelledby="draggable-dialog-create-brand-suggestion"
      >
        <DialogTitle
          id="draggable-dialog-create-brand-suggestion"
          className="modalHeader navyBlue mb10 bold textAlignCenter cursorMove"
        >
          <Typography>
            Marca não cadastrada, deseja cadastrá-la agora?
          </Typography>
        </DialogTitle>

        <DialogContent className="modalContent pt20 mb10 pr16 pl16">
          <TextField
            label="Nova Marca"
            name="newBrand"
            id="new-brand-suggestion"
            fullWidth
            variant="outlined"
            inputProps={{
              maxLength: 20
            }}
            value={newBrandName}
            onChange={(e) => setNewBrandName(e.target.value)}
            className="textFieldSmall"
            placeholder="Digite aqui..."
            InputLabelProps={{
              shrink: true
            }}
            size="small"
          />
        </DialogContent>

        <DialogActions className="justifyCenter pb20">
          <Button
            className="defaultButton backButton"
            design="outlined"
            label="Não Cadastrar"
            onClick={() => {
              setInternalModalSetting(
                internalModalSetting.map((modal) =>
                  modal.name === 'createBrand'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            style={{ outline: 0 }}
          />

          <Button
            design="contained"
            className="defaultButton submitButton"
            onClick={() => {
              createBrand(
                internalModalSetting.find(
                  (modal) => modal.name === 'createBrand'
                )
              );
            }}
            style={{ marginLeft: '20px' }}
            label={loading ? 'Cadastrando...' : 'Cadastrar'}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BrandSuggestion;
