export function setUnitsMeasurements(data) {
    return {
        type: 'SET_UNITSMEASUREMENTS_DATA',
        data,
    };
}

export function createUnitsMeasurements(data) {
    return {
        type: 'CREATE_UNITSMEASUREMENTS_DATA',
        data,
    };
}