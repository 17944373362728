import styled from 'styled-components';

export const CountInputWrapper = styled.div`
    display: flex;
    justify-content: center;
    background-color: #1F2445;
    border-radius: 8px;
    width: ${({width}) => width ? width : "142px"};
    height: 32px;
    overflow: hidden;
    input {
        height: 100%;
        border: 1px solid #010311;
        border-radius: 8px;
        padding: 8px;
        color: #010311;
        font-size: 12px;
        text-align: end;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
`;

export const CountInputLabel = styled.div`
    width: ${({width}) => width && width};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-feature-settings: 'tnum' on, 'lnum' on;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
`;
