import React, { useState, useEffect, useCallback } from 'react';

import {
  Paper,
  IconButton,
  Typography,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  DialogTitle
} from '@material-ui/core';
import {
  Add,
  CheckOutlined,
  CloseOutlined,
  DeleteOutline
} from '@material-ui/icons';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import axios from 'axios';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tree } from 'shineout';

import categoriesExpand from 'images/icons/signals/addsAndCreates/categoriesExpand.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeDialog.svg';
import categoriesCollapsed from 'images/icons/signals/removesAndDeletes/categoriesCollapsed.svg';
import * as UserActions from 'store/actions/user';
import Environment from 'utils/environments';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-categorie"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function TreeContainer({
  onOpenDialog,
  handleClose,
  getId,
  profileType,
  userInfo,
  setOutsideData = () => {}
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const BaseUrl = `${environments.catalog}/categories`;
  const [dataArray, setDataArray] = useState([]);
  const [buttonAddClick, setButtonAddClick] = useState(false);
  const [childId, setChildId] = useState('');
  const [hasCmvGoalChild, setHasCmvGoalChild] = useState(false);
  const [description, setDescription] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nodeClick, setNodeClick] = useState('');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [addRootCategory, setAddRootCategory] = useState('');
  const [lossPercentage, setLossPercentage] = useState(null);
  const [goalCmvChild, setGoalCmvChild] = useState(0);
  const [hasCmvGoal, setHasCmvGoal] = useState(false);
  const [goalCmv, setGoalCmv] = useState(0);
  const [deleteHas] = useState([]);
  const typeCategory = 'Shopping';

  const getCategories = useCallback(
    (typeCategory) => {
      (async () => {
        setLoading(true);
        const categoriesApiSales = await fetch(
          `${environments.catalog}/categories?page=1&size=100&originId=${getId}&type=${typeCategory}`
        );
        const data = await categoriesApiSales.json();
        setDataArray(data.content);
        setLoading(false);
      })();
    },
    [getId]
  );

  useEffect(() => {
    getCategories(typeCategory);
  }, [getId]);

  const headers = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const addNewObj = () => {
    axios
      .post(
        `${BaseUrl}?updateMenu=true`,
        {
          method: 'POST',
          originId: getId,
          description,
          parentId: childId,
          type: 'Shopping',
          hasCmvGoal,
          goalCmv,
          lossPercentage,
          user: userInfo.user
        },
        headers
      )
      .then((res) => {
        setHasCmvGoalChild(false);
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage == 'Description has been already registered.') {
          // setAlertErrorDuplicated(true)
        } else {
          // setAlertErr(true)
        }
      })
      .finally((res) => {
        setButtonAddClick(false);
      });
  };

  const cancelAddObject = () => {
    setButtonAddClick(false);
  };

  const updateData = (id, description, hasCmvGoal, goalCmv, lossPercentage) => {
    if (description != '') {
      axios
        .put(
          `${environments.catalog}/categories/${id}`,
          {
            description,
            hasCmvGoal,
            goalCmv,
            lossPercentage: lossPercentage || 0,
            user: userInfo.user
          },
          headers
        )
        .then((res) => {
          setTimeout(() => getCategories(typeCategory), 300);
        })
        .catch((error) => {
          // setAlertErrUpdate(true)
        });
    }
  };

  const valuesChild = (node) => {
    setChildId(node.id);
    setButtonAddClick(true);
  };

  let count;
  const countCategories = (node) => {
    count = node.children.length;
    node.children.forEach((obj) => {
      if (obj.children) {
        count = parseInt(count) + parseInt(obj.children.length);
      } else {
        countCategories(obj.children);
      }
    });
  };

  let arrayClickDelete;
  let findProduct = false;
  let findSku = false;
  let findCard = false;

  const handleDeleteFlags = (node) => {
    if (node.hasProduct) {
      deleteHas.push(`&updateProduct=${node.hasProduct}`);
    }
    if (node.hasCard) {
      deleteHas.push(`&updateCard=${node.hasCard}`);
    }
    if (node.hasSku) {
      deleteHas.push(`&updateSku=${node.hasSku}`);
    }
    if (node.parentId == null) {
      deleteHas.push(`&categoryNew=1&updateMenu=true`);
    } else {
      deleteHas.push(`&categoryNew=${node.parentId}&updateMenu=true`);
    }
  };

  const removeObj = (node) => {
    handleDeleteFlags(node);
    // let data  = dataArray
    // let index = dataArray.indexOf(node)
    // data.splice(index,1)
    axios
      .delete(
        `${environments.catalog}/categories/${node.id}?originId=${getId}${deleteHas}`,
        headers
      )
      .then((res) => {
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch((error) => {
        //   setAlertErrDelete(true)
      });
    setOpen(false);
  };

  const searchTrue = (nodesearch) => {
    if (nodesearch.parent === true) {
      for (let i = 0; i < nodesearch.children.length; i++) {
        const children = nodesearch.children[i];
        if (children.hasSku === true) {
          findSku = true;
        }
        if (children.hasProduct === true) {
          findProduct = true;
        }
        if (children.hasCard === true) {
          findCard = true;
        }

        if (children.parent === true) {
          for (let a = 0; a < children.children.length; a++) {
            const children2 = children.children[a];
            if (children2.hasSku === true) {
              findSku = true;
            }
            if (children2.hasProduct === true) {
              findProduct = true;
            }
            if (children2.hasCard === true) {
              findCard = true;
            }

            if (children2.parent === true) {
              for (let b = 0; b < children2.children.length; b++) {
                const children3 = children2.children[b];
                if (children3.hasSku === true) {
                  findSku = true;
                }
                if (children3.hasProduct === true) {
                  findProduct = true;
                }
                if (children3.hasCard === true) {
                  findCard = true;
                }

                if (children3.parent === true) {
                  for (let c = 0; c < children3.children.length; c++) {
                    const children4 = children3.children[c];
                    if (children4.hasSku === true) {
                      findSku = true;
                    }
                    if (children4.hasProduct === true) {
                      findProduct = true;
                    }
                    if (children4.hasCard === true) {
                      findCard = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const deleteCategorie = (node) => {
    countCategories(node);
    setNodeClick(node);
    let arrayClickDelete;
    arrayClickDelete = node;
    searchTrue(arrayClickDelete);

    if (
      node.children.length > 0 &&
      !node.hasSku &&
      !node.hasCard &&
      !node.hasProduct
    ) {
      setMessage(
        ` A categoria ${node.description} não categorizou sku´s, produtos ou fichas mas possui ${count} categoria(s) filha(s), deseja realmente deletar ? `
      );
    }

    if (
      node?.children?.length === 0 &&
      !node.hasSku &&
      !node.hasCard &&
      !node.hasProduct
    ) {
      setMessage(
        `Deseja deletar a categoria ${node.description}? Ela não possui categorias filhas e também não categorizou Sku´s, Produtos ou Fichas  `
      );
    }

    if (findProduct === true || findCard === true || findSku === true) {
      setMessage(` A categoria ${
        node.description
      } possui categoria(s) filha(s) que tem 
            ${findCard ? 'Fichas, ' : ''}
            ${findSku ? 'Sku, ' : ''}
            ${findProduct ? 'Produto' : ''}
          categorizados que serão movidos para uma categoria 'Nao Categorizados', deseja realmente deletar ? `);
    }

    if (node.hasSku || node.hasCard || node.hasProduct) {
      setMessage(` Deletar a categoria
            ${node.description} irá descategorizar
            ${node.hasCard ? 'Fichas, ' : ''}
            ${node.hasSku ? 'Sku, ' : ''}
            ${
              node.hasProduct ? 'Produto' : ''
            } que será movido para uma categoria "Não Categorizados",
            porém ela não tem categorias filhas, deseja realmente deletar? `);
    }

    if (
      node.children.length > 0 &&
      (node.hasSku || node.hasCard || node.hasProduct)
    ) {
      setMessage(` A categoria ${
        node.description
      } possui ${count} categoria(s) filha(s), 
            irá descategorizar 
            ${node.hasCard ? 'Fichas, ' : ''}
            ${node.hasSku ? 'Sku, ' : ''}
            ${node.hasProduct ? 'Produto' : ''}
            que será movido para uma categoria "Não Categorizados",
            deseja realmente deletar? `);
    }

    handleClickOpen();
  };
  const addNewObjPai = () => {
    axios
      .post(
        BaseUrl,
        {
          method: 'POST',
          originId: getId,
          description,
          parentId: null,
          type: 'Shopping',
          hasCmvGoal,
          goalCmv,
          user: userInfo.user,
          lossPercentage
        },
        headers
      )
      .then((res) => {
        setAddRootCategory('');
        setHasCmvGoal(false);
        setGoalCmv(0);
        setLossPercentage(null);
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch((error) => {
        // setAlertErr(true)
      });
  };
  const renderItem = (node) => (
    <div
      className={!!node.products?.length && !!node.children?.length && 'teste'}
    >
      <TextField
        margin="dense"
        size="small"
        variant="outlined"
        defaultValue={node.description}
        inputProps={{ maxLength: 50 }}
        onBlur={(e) =>
          updateData(
            node.id,
            e.target.value,
            node.hasCmvGoal,
            node.goalCmv,
            node.lossPercentage
          )
        }
        style={{
          width:
            typeCategory === 'Shopping' && profileType !== 'admin'
              ? '180px'
              : '320px'
        }}
        id={`item-${node.id}`}
      />

      <span style={{ position: 'relative', top: '8px' }}>
        {typeCategory === 'Shopping' && profileType !== 'admin' ? (
          <CurrencyTextField
            className="textFieldSmall right"
            type="text"
            placeholder="% de Perda"
            size="small"
            variant="outlined"
            minimunValue="0"
            decimalPlaces={2}
            decimalPlacesShowOnFocus={2}
            name="lossPercentage"
            outputFormat="string"
            currencySymbol=""
            decimalCharacter=","
            digitGroupSeparator="."
            defaultValue={node.lossPercentage}
            style={{ width: '75px', marginLeft: '5px', marginTop: '-6px' }}
            onBlur={(e) => {
              const lossPercentage =
                e.target.value != ''
                  ? parseFloat(
                      e.target.value.replace('.', '').replace(',', '.')
                    )
                  : 0;
              updateData(
                node.id,
                node.description,
                node.hasCmvGoal,
                node.goalCmv,
                lossPercentage
              );
            }}
          />
        ) : null}

        {profileType !== 'admin' ? (
          <FormControlLabel
            style={{ marginTop: '-.312rem', height: '13px' }}
            control={
              <Checkbox
                name="checkGoalCmv"
                color="primary"
                size="small"
                defaultChecked={node.hasCmvGoal}
                style={{ marginLeft: '.6rem', transform: 'scale(0.75)' }}
                onChange={(e) => {
                  updateData(
                    node.id,
                    node.description,
                    e.target.checked,
                    node.goalCmv,
                    node.lossPercentage
                  );
                }}
              />
            }
            label={
              <span
                style={{
                  fontSize: '12px',
                  marginLeft: '1px',
                  fontFamily: 'Lato'
                }}
              >
                {' '}
                Meta CMV{' '}
              </span>
            }
          />
        ) : null}

        {node.hasCmvGoal && profileType !== 'admin' ? (
          <CurrencyTextField
            className="textFieldSmall right"
            type="text"
            placeholder="Meta CMV"
            size="small"
            variant="outlined"
            minimunValue="0"
            decimalPlaces={2}
            decimalPlacesShowOnFocus={2}
            name="goalCmv"
            outputFormat="number"
            currencySymbol=""
            decimalCharacter=","
            digitGroupSeparator="."
            defaultValue={node.goalCmv > 0 ? node.goalCmv : null}
            onBlur={(e) => {
              updateData(
                node.id,
                node.description,
                node.hasCmvGoal,
                e.target.value,
                node.lossPercentage
              );
            }}
            style={{ width: '75px', marginTop: '-.375rem', marginLeft: '1px' }}
          />
        ) : null}

        <IconButton
          aria-label="add"
          disabled={node.nivel === 15}
          onClick={() => valuesChild(node)}
          style={{ outline: 6 }}
        >
          <Add aria-label="add" fontSize="small" />
        </IconButton>

        <IconButton
          aria-label="delete"
          onClick={() => deleteCategorie(node)}
          style={{ outline: 6 }}
        >
          <DeleteOutline aria-label="delete" fontSize="small" />
        </IconButton>
      </span>

      {buttonAddClick && childId === node.id ? (
        <div className="addItemContainer">
          <Grid
            style={{ marginLeft: '16px', paddingTop: 0 }}
            // className={classes.buttonRowChild}
            container
            spacing={12}
          >
            <Grid>
              {/* <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Categoria pai: {childInputName}
                  </Typography> */}

              <TextField
                className="textFieldSmall"
                placeholder="Adicionar..."
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />

              {typeCategory === 'Shopping' && profileType !== 'admin' ? (
                <CurrencyTextField
                  className="textFieldSmall right"
                  type="text"
                  placeholder="% de Perda"
                  size="small"
                  variant="outlined"
                  minimunValue="0"
                  decimalPlaces={2}
                  decimalPlacesShowOnFocus={2}
                  name="lossPercentage"
                  outputFormat="string"
                  currencySymbol=""
                  decimalCharacter=","
                  digitGroupSeparator="."
                  required
                  value={addRootCategory}
                  style={{ width: '75px', marginLeft: '5px' }}
                  onChange={(e) => {
                    const lossPercentage = parseFloat(
                      e.target.value.replace('.', '').replace(',', '.')
                    );
                    setLossPercentage(lossPercentage);
                  }}
                />
              ) : null}

              {profileType !== 'admin' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkGoalCmvChild"
                      color="primary"
                      size="small"
                      className="smallCheckbox"
                      onChange={() => setHasCmvGoalChild(!hasCmvGoalChild)}
                      checked={hasCmvGoalChild}
                      style={{ marginLeft: '.6rem', transform: 'scale(0.75)' }}
                    />
                  }
                  label={
                    <span
                      style={{
                        fontSize: '12px',
                        marginLeft: '1px',
                        fontFamily: 'Lato'
                      }}
                    >
                      {' '}
                      Meta CMV{' '}
                    </span>
                  }
                />
              ) : null}

              {profileType !== 'admin' && hasCmvGoalChild ? (
                <CurrencyTextField
                  className="textFieldSmall right"
                  type="text"
                  placeholder="Meta CMV"
                  size="small"
                  variant="outlined"
                  minimunValue="0"
                  decimalPlaces={2}
                  decimalPlacesShowOnFocus={2}
                  name="goalCmv"
                  outputFormat="number"
                  currencySymbol=""
                  decimalCharacter=","
                  digitGroupSeparator="."
                  onChange={(e) => {
                    setGoalCmvChild(parseFloat(e.target.value));
                  }}
                  style={{ width: '75px' }}
                />
              ) : null}

              <IconButton
                className="smallIcons"
                aria-label="check"
                onClick={addNewObj}
                style={{ outline: 6 }}
                disabled={description == null || description == ''}
              >
                <CheckOutlined size="small" />
              </IconButton>

              <IconButton
                className="smallIcons"
                aria-label="cancel"
                onClick={cancelAddObject}
                style={{ outline: 6 }}
              >
                <CloseOutlined size="small" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );

  const handleInternalClose = () => {
    setOutsideData([...dataArray]);
    handleClose();
  };

  return (
    <Dialog
      maxWidth="xl"
      open={onOpenDialog}
      onClose={handleInternalClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title-categorie"
      transitionDuration={0}
      className="customZIndex"
    >
      <DialogTitle
        className="modalHeader navyBlue dialog-title-supplier bold textAlignCenter cursorMove"
        id="draggable-dialog-title-categorie"
      >
        <Typography>
          <span
            style={{
              fontWeight: '700',
              fontSize: '12px'
            }}
          >
            GERIR CATEGORIAS
          </span>
        </Typography>

        <IconButton edge="start" color="inherit" onClick={handleInternalClose}>
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <div
        style={{
          width: '680px',
          height: '289px',
          padding: '12px 24px',
          overflowX: 'scroll'
        }}
      >
        {loading && <LinearProgress variant="query" />}
        <Tree
          data={dataArray}
          keygen={(node) => node.description}
          // defaultExpanded={defaultExpanded}
          renderItem={renderItem}
          className="categoriesTree"
          expandIcons={[
            <img src={categoriesExpand} alt="expandIcon" />,
            <img src={categoriesCollapsed} alt="collapse" />
          ]}
          // expanded={expandedTree}
        />
        {typeCategory && (
          <Grid container spacing={12}>
            <Grid className="fixedRootCategoriesOnDialog">
              <TextField
                className="textFieldSmall"
                placeholder="Adicionar..."
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                value={addRootCategory}
                InputLabelProps={{ shrink: true }}
                required
                onChange={(e) => {
                  setDescription(e.target.value);
                  setAddRootCategory(e.target.value);
                }}
              />

              {typeCategory === 'Shopping' && profileType !== 'admin' ? (
                <CurrencyTextField
                  className="textFieldSmall right"
                  type="text"
                  placeholder="% de Perda"
                  size="small"
                  variant="outlined"
                  minimunValue="0"
                  decimalPlaces={2}
                  decimalPlacesShowOnFocus={2}
                  name="lossPercentage"
                  outputFormat="string"
                  currencySymbol=""
                  decimalCharacter=","
                  digitGroupSeparator="."
                  required
                  value={addRootCategory}
                  style={{ width: '75px', marginLeft: '5px' }}
                  onChange={(e) => {
                    const lossPercentage = parseFloat(
                      e.target.value.replace('.', '').replace(',', '.')
                    );
                    setLossPercentage(lossPercentage);
                  }}
                />
              ) : null}
              <span style={{ position: 'relative', left: '10px' }}>
                {profileType !== 'admin' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkGoalCmv"
                        color="primary"
                        size="small"
                        className="smallCheckbox checkboxCategorieDialog"
                        onChange={() => setHasCmvGoal(!hasCmvGoal)}
                        checked={hasCmvGoal}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: '12px',
                          marginLeft: '1px',
                          fontFamily: 'Lato'
                        }}
                      >
                        {' '}
                        Meta CMV{' '}
                      </span>
                    }
                  />
                ) : null}

                {hasCmvGoal && profileType !== 'admin' ? (
                  <CurrencyTextField
                    className="textFieldSmall right"
                    type="text"
                    placeholder="Meta CMV"
                    size="small"
                    variant="outlined"
                    minimunValue="0"
                    decimalPlaces={2}
                    decimalPlacesShowOnFocus={2}
                    name="goalCmv"
                    outputFormat="number"
                    currencySymbol=""
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(e) => {
                      setGoalCmv(parseFloat(e.target.value));
                    }}
                    style={{ width: '80px', marginLeft: '1px' }}
                  />
                ) : null}

                <IconButton
                  className="smallIcons"
                  aria-label="check"
                  onClick={addNewObjPai}
                  style={{
                    outline: 6,
                    width: '5px',
                    position: 'relative',
                    top: '-3px',
                    left: '-10px',
                    marginLeft: '12px'
                  }}
                >
                  <CheckOutlined size="small" />
                </IconButton>
              </span>
            </Grid>
          </Grid>
        )}
      </div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography
            className="textAlignCenter bold"
            style={{
              fontSize: '18px'
            }}
          >
            {message}
          </Typography>
        </DialogContent>

        <div>
          <DialogActions className="dFlex justifyEnd">
            <Button
              className="defaultButton backButton"
              onClick={() => {
                setOpen(false);
              }}
              style={{ outline: 0 }}
              color="primary"
            >
              Cancelar
            </Button>

            <Button
              className="defaultButton submitButton"
              onClick={() => removeObj(nodeClick)}
              style={{ outline: 0 }}
              color="primary"
              autoFocus
            >
              Deletar
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TreeContainer);
