export function setCancellationReasons(data) {
  return {
      type: 'SET_DISCARDS_DATA',
      data,
  };
}

export function createCancellationReasons(data) {
  return {
      type: 'CREATE_DISCARDS_DATA',
      data,
  };
}