/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import ArrowDrillDown from 'images/icons/arrows/arrowDrillDown.svg';
import SelectIcon from 'images/icons/empties/selectIcon.svg';
import SelectFullIcon from 'images/icons/signals/checks/selectFullIcon.svg';
import numberToReal from 'utils/numberToReal';
import { shortenString } from 'utils/shortenString';

import {
  Card,
  CardHeder,
  CardDescriptionWrapper,
  CardItensWrapper,
  CardItem,
  CardItemTitle,
  CardItemSubTitle,
  CardItemInflation,
  Arrow,
  CardItensContainer,
  CardItemSub
} from './index.style';
import QuotationCollapsed from './QuotationCollapsed';

import removeUnityMeasurementsFromString, {
  formatInflationValue
} from '../services';

function QuotationCard({
  cardItem,
  setSectedItens,
  selectedItens,
  originIds,
  userInfo,
  restaurantsIds,
  productsIds,
  tableToModalProps,
  tableSettings
}) {
  const [iconIsSeleted, setIconIsSelected] = useState(false);
  const [openDrilDown, setOpenDrilDown] = useState(false);

  const { content: description } = cardItem[0];
  const { content: packageQuotesUnitys = [] } = cardItem[4];
  const { content: packageQuotes = [] } = cardItem[5];
  const [packageQuotesFirst = 0, packageQuotesSecond = ''] = packageQuotes;

  const { content: lastPrice = '' } = cardItem[8];

  const parsedLastPrice = lastPrice ? lastPrice.replace(',', '.') : '';

  const { content: inflationValue = '', buttonLink: inflationButtonLink = {} } =
    cardItem[9];

  const handleSelectItem = () => {
    setIconIsSelected(!iconIsSeleted);
    const isAddItem = !iconIsSeleted;

    if (!isAddItem) {
      const newNewSelectedItens = selectedItens.filter(
        (element) => element?.id !== cardItem[0]?.id
      );
      setSectedItens(newNewSelectedItens);

      return;
    }

    setSectedItens([...selectedItens, cardItem[0]]);
  };

  return (
    <Card doubleBottomBorder={openDrilDown}>
      <CardHeder>
        <CardDescriptionWrapper>
          <img
            src={iconIsSeleted ? SelectFullIcon : SelectIcon}
            alt="select item"
            onClick={handleSelectItem}
          />
          
          <div onClick={() => setOpenDrilDown(!openDrilDown)}>
            {shortenString(description, 24)}
          </div>
        </CardDescriptionWrapper>
        
        <Arrow
          src={ArrowDrillDown}
          alt="arrow drill down"
          rotate={openDrilDown}
          onClick={() => setOpenDrilDown(!openDrilDown)}
        />
      </CardHeder>

      <CardItensWrapper>
        {!openDrilDown && (
          <>
            <CardItem>
              <CardItemTitle>COTAÇÃO EMBALAGEM</CardItemTitle>

              <CardItemSubTitle>
                <CardItemSub>R$</CardItemSub>

                {numberToReal(packageQuotesFirst)}

                <CardItemSub>
                  {removeUnityMeasurementsFromString(packageQuotesSecond)}
                  {packageQuotesUnitys[1]?.replace('/', '')}
                </CardItemSub>
              </CardItemSubTitle>
            </CardItem>

            <CardItem>
              <CardItemTitle>ÚLTiMO PREÇO</CardItemTitle>

              <CardItemSubTitle>
                <CardItemSub>R$</CardItemSub>

                {numberToReal(parseFloat(parsedLastPrice))}
              </CardItemSubTitle>
            </CardItem>

            <CardItem>
              <CardItemTitle>INFLAÇÃO</CardItemTitle>

              <CardItemInflation
                backgroundColor={inflationButtonLink?.backgroundColor}
                color={inflationButtonLink?.color}
              >
                {formatInflationValue(inflationValue)}
              </CardItemInflation>
            </CardItem>
          </>
        )}

        {openDrilDown && (
          <QuotationCollapsed
            userInfo={userInfo}
            restaurantsIds={restaurantsIds}
            productsIds={productsIds}
            tableToModalProps={tableToModalProps}
            tableSettings={tableSettings}
            cardItem={cardItem}
            originIds={originIds}
          />
        )}
      </CardItensWrapper>
    </Card>
  );
}

export default QuotationCard;
