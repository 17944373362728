const fabricantesLocalize = {
  title: 'Fabricantes Cadastradas',
  headTitle: 'Fabricantes',
  description: 'Descrição',
  producer: 'Fabricante',
  producer_plural: 'Fabricantes',
  registerProducer: 'Cadastrar Fabricante',
  registerProducer_plural: 'Cadastrar Fabricantes',
  deleteTitle: 'Deseja realmente excluir esse fabricante?',
  deleteMessageQuestion: "This action cannot be undo. You'd like prossed?",
  deletedSuccess: 'Fabricante deletado com sucesso!',
  saveInfoError: 'Something went wrong.',
  producers: 'Fabricantes'
};

export default fabricantesLocalize;
