/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Cropper from 'react-cropper';
import Draggable from 'react-draggable';

import Button from 'components/Button/button';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import ValidationLength from 'utils/utils';

import 'cropperjs/dist/cropper.css';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-image-cropper"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ImageCropper({
  modalSettings,
  setModalSettings,
  obj,
  getCropData,
  setCropper,
  handleSelectImage,
  handleClearFileInput,
  setObj,
  objMultiple = '',
  setObjMultiple,
  currentItem
}) {
  const currentObj = objMultiple ? currentItem : obj?.data
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const handleClose = () => {
    setMessage({ ...message, description: '', status: '' });
    setLoading(false);
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'imageCropper' || modal.name === 'searchImage'
          ? { ...modal, open: false }
          : modal
      )
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    getCropData();
    handleClose();
  };

  const handleClearImageData = () => {
    handleClearFileInput();

    if (currentItem) {
      setObjMultiple((prevState) => ({
        ...prevState,
        data: objMultiple?.data?.map((el) => {
          if (el.id === currentItem?.id) {
            return {
              ...el,
              images: {
                name: '',
                baseImage: '',
                id: '',
                url: null
              }
            }
          }
          return { ...el}
        })
      }))
    } else {
      setObj({
        ...obj,
        data: {
          ...obj.data,
          images: {
            name: '',
            baseImage: '',
            id: '',
            url: null
          }
        }
      });
    }
  };

  const clearInfos = () => {
    if (currentItem) {
      currentItem?.images?.url && handleClearImageData()
    } else {
      !obj?.data?.images?.url && handleClearImageData();
    }
  };

  return (
    <>
      <Dialog
        fullScreen={
          modalSettings.find((modal) => modal.name === 'imageCropper')
            .fullScreen
        }
        open={modalSettings.find((modal) => modal.name === 'imageCropper').open}
        onClose={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'imageCropper' ? { ...modal, open: false } : modal
            )
          );
          clearInfos();
        }}
        className="defaultModal imageCropperDialog customZIndex"
        PaperComponent={PaperComponent}
      >
        <DialogTitle
          className="modalHeader bold textAlignCenter cursorMove"
          id="draggable-dialog-image-cropper"
          fullWidth
        >
          <Typography>COMPARAÇÃO</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'imageCropper'
                    ? { ...modal, open: false }
                    : modal
                )
              );
              clearInfos();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent bgWhite dFlex justifyBetween">
          <div className="imgCropBox">
            <Cropper
              style={{ height: 550, width: '100%' }}
              preview=".img-preview"
              src={currentObj?.images?.url}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive
              autoCropArea={1}
              // aspectRatio={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides
            />
          </div>

          <div className="dFlex flexColumn justifyCenter imgPreviewBox">
            {currentObj?.images?.name && (
              <p>{ValidationLength(currentObj?.images?.name, 25)}</p>
            )}

            <div
              className="img-preview"
              style={{
                width: '100%',
                float: 'left',
                height: 350,
                overflow: 'hidden'
              }}
            />

            <div className="actionButtons dFlex justifyBetween">
              <Button
                className="defaultButton backButton"
                design="outlined"
                label="Escolher outra imagem"
                onClick={(e) => {
                  handleSelectImage(e);
                }}
                style={{ outline: 0 }}
              />

              <Button
                className="defaultButton submitButton"
                design="contained"
                label={!loading ? 'Cortar e Salvar' : 'Salvando...'}
                style={{ outline: 0, margin: '0 0 0 5px' }}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </DialogContent>

        {loading && <LinearProgress variant="query" />}
      </Dialog>

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => {
          message.status !== 'error' && handleClose()
        }}
      >
        <Alert
          onClose={() => {
            message.status !== 'error' && handleClose()
          }}
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ImageCropper;
