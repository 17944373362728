const storageLocationsLocalize = {
  "need to send restaurantId and description in body to complete request": "Precisa enviar o ID do restaurante e a descrição no corpo para concluir a solicitação",
  "already exists a storageLocation with this description": "Já existe um local de armazenamento com esta descrição",
  "Need to send the originId": "Precisa enviar o originId",
  "invalid status": "Status inválido",
  "not found results according to these parameters": "Não encontrei resultados de acordo com esses parâmetros",
  "Já existe uma contagem definida como CMV para o restaurante atual": "Já existe uma contagem definida como CMV para o restaurante atual.",
  "Já existe um local de armazenamento ativo com a contagem de CMV habilitada Não é possível ativar mais de um local de armazenamento com essa configuração": "Já existe uma periodicidade ativa com a contagem de CMV habilitada. Não é possível ativar mais de uma periodicidade com essa configuração."
};

export default storageLocationsLocalize;