/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Cropper from 'react-cropper';
import Draggable from 'react-draggable';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import ValidationLength from 'utils/utils';

import Button from '../../Button/button';

import 'cropperjs/dist/cropper.css';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-image-cropper"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ImageCropper({
  modalSettings,
  setModalSettings,
  obj,
  getCropData,
  setCropper,
  handleSelectImage,
  handleClearFileInput,
  setObj
}) {
  const openedByGeneralSuggestion = modalSettings.find(
    (modal) => modal.name === 'imageCropper'
  )?.openedByGeneralSuggestion;
  const currentSkuIndex = modalSettings.find(
    (modal) => modal.name === 'imageCropper'
  )?.currentSkuIndex;
  const currentId = modalSettings.find(
    (modal) => modal.name === 'imageCropper'
  )?.skuId;
  const isMultipleSku = currentSkuIndex || currentSkuIndex === 0;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const handleClose = () => {
    setMessage({ ...message, description: '', status: '' });
    setLoading(false);
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'imageCropper' || modal.name === 'searchImage'
          ? { ...modal, open: false }
          : modal
      )
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    isMultipleSku ? getCropData(currentSkuIndex) : getCropData();
    handleClose();
  };

  const handleClearImageData = () => {
    if (!currentId) {
      isMultipleSku
        ? handleClearFileInput(currentSkuIndex)
        : handleClearFileInput();
    }

    isMultipleSku
      ? setObj(
          obj.map((sku, i) => {
            if (currentSkuIndex === i) {
              return {
                ...sku,
                images: {
                  name: '',
                  baseImage: '',
                  id: '',
                  url: null
                }
              };
            }
            return sku;
          })
        )
      : setObj({
          ...obj,
          sku: {
            ...obj.sku,
            images: {
              name: '',
              baseImage: '',
              id: '',
              url: null
            }
          }
        });
  };

  const clearInfos = () => {
    if (isMultipleSku) {
      !obj.find((el, index) => index === currentSkuIndex)?.images?.url &&
        handleClearImageData();
    } else {
      !isMultipleSku && !obj?.sku?.images?.url && handleClearImageData();
    }
  };

  return (
    <>
      <Dialog
        fullScreen={
          modalSettings.find((modal) => modal.name === 'imageCropper')
            .fullScreen
        }
        open={modalSettings.find((modal) => modal.name === 'imageCropper').open}
        onClose={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'imageCropper' ? { ...modal, open: false } : modal
            )
          );
          !openedByGeneralSuggestion && clearInfos();
        }}
        className="defaultModal imageCropperDialog customZIndex"
        PaperComponent={PaperComponent}
      >
        <DialogTitle
          className="modalHeader bold textAlignCenter cursorMove"
          id="draggable-dialog-image-cropper"
          fullWidth
        >
          <Typography>COMPARAÇÃO</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'imageCropper'
                    ? { ...modal, open: false }
                    : modal
                )
              );
              !openedByGeneralSuggestion && clearInfos();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent bgWhite dFlex justifyBetween">
          <div className="imgCropBox">
            <Cropper
              style={{ height: 550, width: '100%' }}
              preview=".img-preview"
              src={
                isMultipleSku
                  ? obj.find((el, index) => index === currentSkuIndex)?.images
                      ?.baseImage
                  : obj?.sku?.images?.baseImage
              }
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive
              autoCropArea={1}
              // aspectRatio={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides
            />
          </div>

          <div className="dFlex flexColumn justifyCenter imgPreviewBox">
            {obj?.sku?.images?.name && (
              <p>{ValidationLength(obj?.sku?.images?.name, 25)}</p>
            )}

            <div
              className="img-preview"
              style={{
                width: '100%',
                float: 'left',
                height: 350,
                overflow: 'hidden'
              }}
            />

            <div className="actionButtons dFlex justifyBetween">
              <Button
                className="defaultButton backButton"
                design="outlined"
                label="Escolher outra imagem"
                onClick={(e) => {
                  isMultipleSku
                    ? handleSelectImage(e, currentSkuIndex)
                    : currentId
                    ? handleSelectImage(e, currentId)
                    : handleSelectImage(e);
                }}
                style={{ outline: 0 }}
              />

              <Button
                className="defaultButton submitButton"
                design="contained"
                label={!loading ? 'Cortar e Salvar' : 'Salvando...'}
                style={{ outline: 0, margin: '0 0 0 5px' }}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </DialogContent>

        {loading && <LinearProgress variant="query" />}
      </Dialog>

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={(e) => message.status !== 'error' && handleClose()}
      >
        <Alert
          onClose={(e) => message.status !== 'error' && handleClose()}
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ImageCropper;
