import axios from 'axios';

const instance = axios.create();

instance.interceptors.response.use(
  (response) => {
    const version = localStorage.getItem('version');
    const listVersion = version?.length > 7 ? JSON?.parse(version) : version
    const versionAuth = version?.length > 7 ? listVersion?.auth_version : version
    const versionValidate = versionAuth > 4 ? JSON?.parse(versionAuth)?.length : JSON?.parse(version)?.length

    const currentVersion = response?.headers?.version;
    const objectVersions = {
      catalog_version: listVersion?.catalog_version,
      auth_version: response?.headers?.version,
      orders_version: listVersion?.orders_version,
      manufactures_version: listVersion?.manufactures_version,
      providers_version: listVersion?.providers_version,
      restaurants_version: listVersion?.restaurants_version,
      cache_version: listVersion?.cache_version,
    }

    if (!versionAuth || versionAuth !== currentVersion) {
      localStorage.setItem('version', JSON.stringify(objectVersions));
    }

    if (
      versionValidate &&
      versionAuth !== currentVersion &&
      versionAuth !== null &&
      versionAuth !== 'undefined' &&
      currentVersion !== undefined
    ) {
      location.reload(true);
    }

    return response;
  },
  (error) => Promise.reject(error)
);

export default instance;
