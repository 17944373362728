import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import moment from 'moment';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makeGet from 'services/useFetch/makeGet';
import numberToReal from 'utils/numberToReal';

import { RPDialogBody } from './styles';

import { ItemWrapper } from '../../../dialogs/style';

function RelatedProvidersDialog({
  openRelatedProvidersDialog,
  setOpenRelatedProvidersDialog,
  restaurantsIds, 
  productsIds, 
  productName
}) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [filters, setFilters] = useState([
    {
      param: 'originId',
      value: restaurantsIds,
      urlParam: `originId=${restaurantsIds}`
    },
    {
      param: 'productId',
      value: productsIds,
      urlParam: `productId=${productsIds}`
    },
  ]);

  const handleItems = (response) =>  {
    if (response?.length == 0) {
      setLoading(false)
      setIsEmpty(true)
      return 
    }
    setData(response)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    makeGet('modalProvires', filters, handleItems, null);
  }, [])

  const getColorStatus = (descri, price) => {
    const skusArr = data.reduce((acc, sku) => (
      [...acc, { description: sku.description, price: sku.priceUc }]
    ), []).flat()

    const skusSort = skusArr.sort((a, b) => a.price - b.price || a.description - b.description);

    const index = skusSort.indexOf(skusArr.find(item => item.price === price && item.description === descri))

    if (index === 0) {
      return {
        backgroundColor: '#DFF7E8',
        color: '#3DCC6E'
      }
    } if (index < 3) {
      return {
        backgroundColor: '#FCF5E3',
        color: '#996600'
      }
    }
    return false;
  }
  
  
  return (
    <Dialog
      className="defaultModal mobileModal"
      fullWidth
      maxWidth="sm"
      open={openRelatedProvidersDialog}
      aria-labelledby="draggable-dialog-title"
     >
     <DialogTitle className="modalHeader navyBlue" style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'move', fontFamily: 'Lato' }} id="draggable-dialog-title">
       <Typography>
          {productName}
       </Typography>

       <IconButton edge="start" color="inherit"
       onClick={() => setOpenRelatedProvidersDialog(false)}
       >
        <img src={CloseIconNavy} style={{ width: "8px" }} alt="close" />
       </IconButton>
     </DialogTitle>
     {loading && <div sytle={{ margin: "10px" }}><LinearProgress style={{ height: "7px" }} variant="query" /></div>}
     <DialogContent className="modalContent p0 relatedProvider-modal-content" style={{backgroundColor: "white", height: data?.length >= 3 ? "450px" : "auto"}} >
        {
        isEmpty ? 
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
          <Typography >
            Sem Fornecedores Relacionados
          </Typography>  
        </div>
        :
        data?.map(dt => (
          <RPDialogBody>
            <div>
              <Typography style={{fontWeight: "700"}} >
                FORNECEDOR 
              </Typography>
              <Typography>
                {dt?.provider}
              </Typography>
            </div>
            <div className="dataInfos" >
              <div>
                <Typography style={{fontWeight: "700"}} >
                  SKU 
                </Typography>
                <Typography>
                  {dt?.skuDescription}
                </Typography>
              </div>
              <div className="dataInfosButtons" >
                  <div
                  style={{
                    backgroundColor: dt?.brandFavorite ?  "#DADDF3" : "#F2F3F8" ,
                    }} >
                  <p  
                  style={{
                    color: dt?.brandFavorite ? "#5062F0" : "#D0D3E2"
                    }}
                    >
                    Preferida</p>
                </div>
                <div
                style={{
                    backgroundColor: dt?.brandFavorite ?  "#DADDF3" : "#F2F3F8" ,
                  }} >
                  <p
                  style={{
                    color: dt?.brandFavorite ? "#5062F0" : "#D0D3E2"
                    }}
                  >Aceita</p>
                </div>
              </div>
            </div>
            <div className="RPvaluesInfos" >
              <div>
                <Typography style={{marginBottom: "2px", marginRight: "8px"}} >
                  U.C. R$
                </Typography>
                <div style={{ width: "67px", fontSize: "12px", fontWeight: "400"}} >
                <ItemWrapper buttonLink={getColorStatus(data.description, data.priceUc)}>
                  {numberToReal(dt?.priceUc, 2)}
                </ItemWrapper>
                </div>
              </div>
              <div>
                <Typography>
                  UNID R$
                </Typography>
                <Typography>
                  {numberToReal(dt?.unitaryValue, 2)}
                </Typography>                
              </div>
              <div>
                <Typography>
                  EMB R$
                </Typography>
                <Typography>
                  {numberToReal(dt?.packageValue, 2)}
                </Typography>                
              </div>
              <div style={{marginRight: "12px"}} >
                <Typography>
                  VALIDADE
                </Typography>
                <Typography style={{color: moment().isAfter(dt?.expirationDate) ? "#AA0816" : "#0E1337"}}>
                {
                !dt?.expirationDate ?
                "................."
                :
                format(new Date(dt?.expirationDate).valueOf() + 10800000, 'dd/MM/yyyy')}
                </Typography>                
              </div>
            </div>
            <hr style={{margin: "4px 12px 8px 12px", color: "#454A67"}} />
          </RPDialogBody>
        ))
        }
        {}
     </DialogContent>
     </Dialog>
  )
}

export default RelatedProvidersDialog;
