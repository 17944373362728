/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-new */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import PaperComponent from 'components/PaperComponent';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function ProvidersAssociation({
  skusList,
  providers,
  skuAssociation,
  modalSettings,
  setModalSettings,
  setSkuAssociation,
  onClose
}) {
  const { id: paramsID } = useParams();
  const userInfo = useSelector((currState) => currState.user);
  const userLocal = JSON.parse(localStorage.getItem('companies'))

  const [validProviderDescription, setValidProviderDescription] =
    useState(true);

  const [validProviderAssociationUnit, setValidProviderAssociationUnit] =
    useState(true);

  const [associationData, setAssociationData] = useState({
    provider: {
      restaurantId: paramsID,
      providerId: null,
      name: null,
      quantity: null,
      volume: skuAssociation ? skuAssociation.quantity : null,
      type: null,
      user: userInfo.paramsID
    },
    products: {},
    cards: {}
  });

  const [internalModalSettings, setInternalModalSettings] = useState([
    {
      id: 1,
      name: 'confirmDialog',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    },
  ]);

  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const confirmDialogInfos = internalModalSettings.find((modal) => modal.name === 'confirmDialog')

  const disableToAssociate =  skuAssociation?.providers?.find(el => el.quantity === parseFloat(associationData?.provider?.quantity) && el.providerId === associationData?.provider?.providerId)

  const disabledButton = !parseFloat(associationData?.provider?.quantity) || !associationData?.provider?.name

  const [getProviders, setGetProviders] = useState([]);

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  }

  const disassociateProvider = (provider, sku) => {
    Axios.delete(
      `${environments.catalog}/skus/${sku.id}/providers/${provider.id}`,
      config
    )
      .then(() => {
        setSkuAssociation((prevState) => ({
          ...prevState,
          providers: prevState.providers?.filter(el => el.id !== provider.id)
        }));

        setInternalModalSettings(
          internalModalSettings.map((modal) =>
            modal.name === 'confirmDialog'
              ? {
                  ...modal,
                  open: false,
                  currentProvider: null,
                  skuAssociation: null,
                }
              : modal
          )
        );

        setMessage({
          description: 'Embalagem desassociada com sucesso.',
          status: 'success'
        });
      })
      .catch((error) => {
        console.log(error);
        setMessage({
          description: "Não foi possível desassociar a embalagem.",
          status: 'error'
        });
      });
  };

  const validProviderAssociationAttributes = () => {
    setValidProviderAssociationUnit(!!associationData.provider.quantity);
    setValidProviderDescription(!!associationData.provider.name);

    return (
      !!associationData.provider.name && !!associationData.provider.quantity
    );
  };

  const associateProvider = (value) => {
    if (validProviderAssociationAttributes()) {
      const providerNew = associationData.provider;

      providerNew.quantity = parseFloat(providerNew.quantity)

      providerNew.user = userInfo.user;

      associationData.provider.quantity.toLocaleString('pt-br', {
        minimumFractionDigits: 3
      });
        
      Axios.post(
        `${environments.catalog}/skus/${value.id}/providers`,
        providerNew, config
      )
        .then((response) => {
          setSkuAssociation((prevState) => ({
            ...prevState,
            providers: [...prevState.providers, { ...providerNew, id: response?.data?.id }]
          }));
        })
        .catch((error) => {
          console.log('error', error)
        });
    }
  };

  const handleKeyDownQtde = (event) => {
    // Verifica se a tecla pressionada é "-" e impede a entrada se for
    if (event.key === '-' || event.key === 'e' || event.key === '.' || event.key === ',') {
      event.preventDefault();
    }
  };

  const getProvidersQuery = () => {
    if (!getProviders.length) {
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setGetProviders,
        paramsID,
        null,
        null,
        2000
      );
    }
  };

  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'providersAssociation')
          .fullScreen
      }
      maxWidth={
        modalSettings.find((modal) => modal.name === 'providersAssociation')
          .maxWidth
      }
      open={
        modalSettings.find((modal) => modal.name === 'providersAssociation')
          .open
      }
      onClose={() => {
        if (onClose) {
          onClose()
        } else {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'providersAssociation'
                ? { ...modal, open: false, sku: null }
                : modal
            )
          );
          setSkuAssociation(null);
        }
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal providersDialog"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Fornecedores</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            if (onClose) {
              onClose()
              setAssociationData({
                ...associationData,
                provider: {
                  restaurantId: paramsID,
                  providerId: null,
                  name: null,
                  quantity: null,
                  volume: skuAssociation ? skuAssociation.quantity : null,
                  type: null,
                  user: userInfo.paramsID
                }
              })
            } else {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'providersAssociation'
                    ? { ...modal, open: false, sku: null }
                    : modal
                )
              );
              setSkuAssociation(null);
              setAssociationData({
                ...associationData,
                  provider: {
                    restaurantId: paramsID,
                    providerId: null,
                    name: null,
                    quantity: null,
                    volume: skuAssociation ? skuAssociation.quantity : null,
                    type: null,
                    user: userInfo.paramsID
                  }
                })
            }
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img src={CloseIcon} alt="CloseIcon" />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent">
        <p colSpan="12" className="mb16" style={{ fontSize: '14px' }}>
          <b>
            {
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).sku
            }
          </b>
        </p>

        <Grid container spacing={5} className="mt16">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                size="small"
                onChange={(e, value) => {
                  setAssociationData({
                    ...associationData,
                    provider: {
                      ...associationData.provider,
                      name: value ? value.providerName : null,
                      providerId: value ? value.providerId || value._id : null,
                      descriptionSku: modalSettings?.find(
                        (modal) => modal.name === 'providersAssociation'
                      )?.sku,
                      suppliers: {
                        cnpjNumber: value?.cnpjNumber
                      }
                    }
                  });
                }}
                variant="outlined"
                getOptionLabel={(option) => option.providerName}
                options={getProviders}
                onFocus={getProvidersQuery}
                className="autocompleteSmall"
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    id={params.id}
                    variant="outlined"
                    label="Fornecedores"
                    name="providers"
                    required
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
                renderOption={(params) => (
                  <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                    {params.providerName}
                  </Typography>
                )}
              />
            </FormControl>

            {!validProviderDescription && (
              <p className="requiredWarning">Campo obrigatório</p>
            )}
          </Grid>

          <Grid item xs={12}>
            <Grid container xs={11} spacing={1}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <CurrencyTextField
                    variant="outlined"
                    size="small"
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={skuAssociation?.unitsMeasurements.abbreviation === 'un' ? 0 : 3}
                    decimalPlacesShownOnFocus={skuAssociation?.unitsMeasurements.abbreviation === 'un' ? 0 : 3}
                    type="text"
                    name="quantity"
                    fullWidth
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    label="Quantidade"
                    style={{ width: '100px' }}
                    disabled
                    value={skuAssociation ? skuAssociation.quantity : null}
                    InputProps={{ style: { fontFamily: 'Lato' } }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    label="Un."
                    name="quantity"
                    disabled
                    value={
                      skuAssociation
                        ? skuAssociation.unitsMeasurements.abbreviation
                        : null
                    }
                    style={{ width: '50px' }}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ style: { fontFamily: 'Lato' } }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={3}>
                <FormControl fullWidth>
                  <CurrencyTextField
                    variant="outlined"
                    size="small"
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="quantity"
                    required
                    fullWidth
                    label="Unidades"
                    onKeyDown={handleKeyDownQtde}
                    style={{ width: '100px' }}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputProps={{ style: { fontFamily: 'Lato' } }}
                    onChange={(e) => {
                      setAssociationData({
                        ...associationData,
                        provider: {
                          ...associationData.provider,
                          quantity: e?.target?.value.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        }
                      });
                    }}
                  />
                </FormControl>

                {!validProviderAssociationUnit && (
                  <p className="requiredWarning">Campo obrigatório</p>
                )}
              </Grid>

              <Grid item xs={1}>
                <Tooltip title={disableToAssociate ? "Fornecedor e embalagem já cadastrados" : "Associar fornecedor"}>
                  <IconButton
                    color="inherit"
                    size="small"
                    disabled={disabledButton}
                    onClick={() => {
                      if (disableToAssociate) return null;

                      associateProvider(skuAssociation);
                    }}
                    aria-label="add"
                    className={`${disableToAssociate ? 'cursorAuto' : 'cursorPointer'}`}
                    style={{ outline: '0', background: 'none' }}
                  >
                    <img
                      style={{ width: '25px' }}
                      src={AddIcon}
                      alt="AddIcon"
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <table
          className="mt16"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr>
              <th width="40%" className="subtitle p12">
                DESCRIÇÃO
              </th>

              <th width="10%" className="subtitle p12">
                CÓD.
              </th>

              <th width="18%" className="subtitle">
                CNPJ
              </th>

              <th width="10%" className="subtitle">
                QTD.
              </th>

              <th width="2%" className="subtitle">
                UN.
              </th>

              <th width="10%" className="subtitle">
                UNIDADES
              </th>

              <th width="10%" className="subtitle">
                EMBALAGEM
              </th>

              <th
                width="2%"
                className="subtitle"
                style={{ borderLeft: 'solid 1px #ECEDF1' }}
              />
            </tr>

            <tr>
              <td className="m0 p0" colSpan="8">
                <hr className="titleLineBorder m0" style={{ height: '2px' }} />
              </td>
            </tr>
          </thead>

          <tbody>
            {skuAssociation?.providers?.length ? (
              skuAssociation.providers.map((provider, providerIndex) => {
                provider.type = `E${parseFloat(provider.quantity)}x${
                  skuAssociation.quantity
                }${skuAssociation.unitsMeasurements.abbreviation}`;

                const isLastElement =
                  providerIndex === skuAssociation?.providers?.length - 1;

                return (
                  <>
                    <tr>
                      <td className="description pl12">
                        {ValidationLength(provider?.name, 35)}
                      </td>

                      <td className="description pl12">
                        {ValidationLength(provider?.codeProvider, 10)}
                      </td>

                      <td className="description center">
                        {formatDocumentNumber(provider?.suppliers?.cnpjNumber)}
                      </td>

                      <td className="description center">
                        {(skuAssociation?.quantity || 0).toLocaleString(
                          'pt-br',
                          {
                            minimumFractionDigits: 3
                          }
                        )}
                      </td>

                      <td className="description center">
                        {skuAssociation?.unitsMeasurements?.abbreviation}
                      </td>

                      <td className="description center">
                        {parseFloat(provider?.quantity || 0).toLocaleString(
                          'pt-br',
                          {
                            minimumFractionDigits: 3
                          }
                        )}
                      </td>

                      <td className="description center">{provider?.type}</td>

                      <td
                        className="p0"
                        style={{ borderLeft: 'solid 1px #ECEDF1' }}
                      >
                        <Tooltip title="Desassociar fornecedor">
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              setInternalModalSettings(
                                internalModalSettings.map((modal) =>
                                  modal.name === 'confirmDialog'
                                    ? {
                                        ...modal,
                                        open: true,
                                        currentProvider: provider,
                                        skuAssociation,
                                      }
                                    : modal
                                )
                              );
                            }}
                            aria-label="add"
                            size="small"
                            style={{ outline: '0', background: 'none' }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>

                    {!isLastElement && (
                      <tr>
                        <td className="m0 p0" colSpan="8">
                          <hr
                            className="titleLineBorder m0"
                            style={{ height: '2px' }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan="7" className="description center">
                  <Typography style={{ margin: '5px', textAlign: 'center' }}>
                    Não há Fornecedores associados.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>

      {confirmDialogInfos?.open && (
        <ConfirmModal
          open={
            confirmDialogInfos.open
          }
          title={
            confirmDialogInfos?.title
          }
          content={
            <Typography className="bold">
              Deseja realmente desassociar essa embalagem?
            </Typography>
          }
          onClose={() => {
            setInternalModalSettings(
              internalModalSettings.map((modal) =>
                modal.name === 'confirmDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentProvider: null,
                      skuAssociation: null,
                    }
                  : modal
              )
            );
          }}
          cancelText="Não"
          onConfirm={() => {
            disassociateProvider(
              confirmDialogInfos?.currentProvider, 
              confirmDialogInfos?.skuAssociation)
            ;
          }}
          confirmText="Sim"
        />
      )}

      <CommonAlert
        open={!!message.description}
        severity={message.status}
        autoHideDuration={2000}
        onClose={() => {
          setMessage({ ...message, description: '', status: '' })
        }}
        messagePersonal={message.description}
        width="50%"
      />
    </Dialog>
  );
}

export default ProvidersAssociation;
