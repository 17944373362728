export function setProducers(data) {
    return {
        type: 'SET_PRODUCERS_DATA',
        data,
    };
}

export function createProducers(data) {
    return {
        type: 'CREATE_PRODUCERS_DATA',
        data,
    };
}