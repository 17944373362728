import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled, { css } from 'styled-components';

export const SwitchContainer = styled.div`
  ${({ onFormStyle }) =>
    onFormStyle &&
    css`
      position: absolute;
      top: -41px;
    `}
  ${({ marginOf }) =>
    !marginOf &&
    css`
      margin-top: ${({ topAdjustment }) => (topAdjustment ? '-25px' : '12px')};
      margin-right: ${({ topAdjustment }) => (topAdjustment ? '35px' : '0')};
    `}
  display: flex;
  align-items: center;
  margin-left: -12px;
  margin-right: 16px;
`;

export const LabelTitle = styled.p`
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: ${({ titleColor }) => titleColor || '#00020F'};
  margin-bottom: -10px;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-top: ${({ marginTop }) => (marginTop ? 0 : '16px')};

  .MuiSwitch-root {
    margin-bottom: -10px;
  }

  span {
    font-family: 'Lato';
    font-style: normal;
    font-size: 12px;
  }

  img {
    margin-left: 2px;
  }
`;

export const FormControlStyles = {
  root: {
    flexDirection: 'row',
    color: '#0B0D1D',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    marginTop: '-5px'
  }
};
