const Stores = {
  stores: 'Lojas',
  'CNPJ Invalid': 'CNPJ inválido',
  'CNPJ already registered': 'CNPJ já cadastrado',
  'keystore password was incorrect': 'A senha do certificado esta incorreta',
  'The certificate does not belong to this store': 'O certificado não pertence a esta loja',
  'Validity period of this certificate is expiring': 'O período de validade deste certificado está expirado'
};

export default Stores;
