import React, { useState } from 'react';

import format from "date-fns/format";
import { useParams, Redirect } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';

import PlayCountMobileIcon from 'images/icons/signals/playsAndStarts/playCountMobileIcon.svg';
import TrashMobileIcon from 'images/icons/signals/removesAndDeletes/trashMobileIcon.svg';
import DeleteCountDialog from 'pages/inventory/Counts/dialogs/DeleteCountDialog';
import numberToDecimalPlace from 'utils/numberToDecimalPlace';

import {
    CountPeriodCard,
    CountPeriodCardTopInfo,
    DescriptionCard,
    ItensDescription,
    PartialWrapper,
    PartialCard,
    Slidebar,
    DateWrapper
} from './index.style';

import DifferenceInCountWrapper from '../DifferenceInCountContent/DifferenceInCountWrapper';
import ItemListDialog from '../ItensListDialog';
import PendingCountsDialog from '../PendingCountsDialog';

function PeriodicityCountPage({ periodicity = {}, inventories, selectedPeriodDate }) {
    const { counts = [], periodicity: { description: periodDescription = '' } = {} } = periodicity;
    const [ redirectToCountspage, setRedirectToCountspage ] = useState();
    const [ openPendingCountsDialog, setOpenPendingCountsDialog ] = useState(false);
    const [ currentCount, setCurrentCount ] = useState();
    const [openDeleteCountDialog, setOpenDeleteCountDialog] = useState(false);
    const [countIdToDelete, setCountIdToDelete] = useState();
    const [itensListDialogOpen,  setItensListDialogOpen] = useState();
    const [currentUrl, setCurrentUrl] = useState();

    const { id: paramsID } = useParams();

    const handleCountStatus = (status, percentage, hasError = false) => {
        if (hasError) {
            return `Erro`
        }

        const statusOprions = {
            "IN PROGRESS": "Parcial",
            "WAITING": "Não iniciada",
            "FINISHED": "Finalizada"
        }

        const percent = status === "IN PROGRESS" ? ` (${numberToDecimalPlace(percentage, 2)}%)` : ''

        return `${statusOprions[status]}${percent}`
    }

    const handlePlayCounts = (count, url) => {
        const dayAgoInventory = inventories.filter(
            (inventory) =>
              inventory.date ===
              format(new Date().setDate(new Date().getDate() - 1), "yyyy-MM-dd")
          )[0];
          let dayAgoCounts = [];
          if (dayAgoInventory) {
            dayAgoCounts = dayAgoInventory.periodicities
              .reduce((acc, periodicity) => [...acc, periodicity.counts], [])
              .flat();
          }
          if (
            selectedPeriodDate === format(new Date(), "yyyy-MM-dd") &&
            dayAgoCounts.filter((count) => count.status !== "FINISHED").length > 0
          ) {
            setOpenPendingCountsDialog(true);
            setCurrentCount(count);
            setCurrentUrl(url);
          } else {
            setRedirectToCountspage(url);
          }
    };

    const handleDeletePreviousCount = (url) => {
        setRedirectToCountspage(url);
    }

    const handleOpenDeleteDialog = ({ countId }) => {
        setCountIdToDelete(countId);
        setOpenDeleteCountDialog(true);
    }

    const onCloseDeleteDialog = (options) => {
        if (options.refreshData) {
            const i = counts.findIndex(count => count.id == countIdToDelete);

            counts.splice(i, 1)
        }

        setOpenDeleteCountDialog(false);
    }

    return (
        <PullToRefresh className="customPullToRefresh" onRefresh={() => window.location.reload()}>
            <DeleteCountDialog
                openState={openDeleteCountDialog}
                onCloseProductDialog={onCloseDeleteDialog}
                countIdToDelete={countIdToDelete}
                isMobile
            />

            {
                counts.map(count => {
                    const { 
                        status = '', 
                        percentage = 0, 
                        date = '', 
                        assortmentInfo: { date: assortmentDate = '', user = '' } = {  }, 
                        id,
                        setup: { storageLocation: { description: storageLocationDescription } } = { },
                        nExternalProducts = 0,
                        nInternalProducts = 0,
                    } = count;
                    const urlParams = `?countId=${id}&countDate=${date}&categoryDescription=&assortmentDate=${assortmentDate}&selectItem=0`;
                    const productsCount = Number(nExternalProducts) + Number(nInternalProducts);
                    
                    return(
                        <CountPeriodCard>
                            <CountPeriodCardTopInfo>
                                <div>
                                        <img 
                                            src={PlayCountMobileIcon} 
                                            alt="mobilePlayIcon" 
                                            onClick={() => handlePlayCounts(count, `/inventario/contagem-diaria-mobile/${paramsID}/0/${urlParams}`)} 
                                        />
                                    <DescriptionCard>{storageLocationDescription}</DescriptionCard> 
                                </div>
                                <div>
                                    <ItensDescription 
                                        onClick={() => setItensListDialogOpen({
                                            title: `${storageLocationDescription}/${periodDescription}`,
                                            countId: id
                                        })}
                                    >
                                        {productsCount} itens
                                    </ItensDescription>
                                    <img 
                                        src={TrashMobileIcon} 
                                        alt="mobileTrashIcon" 
                                        onClick={() => handleOpenDeleteDialog({ countId: id })}
                                    />
                                </div>
                            </CountPeriodCardTopInfo>
                            
                            <PartialWrapper>
                                <Slidebar percent={percentage} hasError={count?.hasError}/>
                                <PartialCard hasError={count?.hasError}>{handleCountStatus(status, percentage, count?.hasError)}</PartialCard>
                            </PartialWrapper>

                            <div style={{marginTop: "4px"}}> 
                                <DateWrapper>{format(new Date(date), "dd/MM/yyyy HH:mm'h'")} por {user}</DateWrapper>
                            </div>

                            {
                                count?.hasDiffInCount && 
                                <DifferenceInCountWrapper count={count} date={selectedPeriodDate} diffInCount={count?.diffInCount} />
                            }
                            
                            {
                                openPendingCountsDialog && 
                                <PendingCountsDialog 
                                    onClose={() => setOpenPendingCountsDialog(false)}
                                    handleDeletePreviousCount={() => handleDeletePreviousCount(currentUrl)}
                                />
                            }
                        </CountPeriodCard>
                    )
                })
            }
            {
                !!redirectToCountspage &&
                <Redirect to={redirectToCountspage} />
            }
            {
                !!itensListDialogOpen &&
                <ItemListDialog 
                    handleClose={() => setItensListDialogOpen()}
                    dialogProps={itensListDialogOpen}
                    paramsID={paramsID}
                />
            }
            
        </PullToRefresh>
    );
}

export default PeriodicityCountPage;
