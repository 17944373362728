import React, { useState } from 'react';

import selectorInputArrow from 'images/icons/arrows/selectorInputArrow.svg';

import {
  StyleDD,
  StyleDT,
  StyleDL,
  ArrowDrillDown,
  CategoryWrapper,
  SubCategoriesWrapper
} from './style';

function LoopGetChildrens({
  itemList,
  handleCategory,
  childrenElement,
  nestedCount = 0,
  handleDrillDownMap,
  drillDownMap = {}
}) {
  const [firstLevelDisplay, setFirstLevelDisplay] = useState(false);
  const [secondLevelDisplay, setSecondLevelDisplay] = useState(false);

  const handleLevelDisplay = () => {
    setFirstLevelDisplay(!firstLevelDisplay);
  };

  const handleSecondLevelDisplay = (_, id) => {
    setSecondLevelDisplay(!secondLevelDisplay);
    const newDrilMap = { ...drillDownMap, [id]: !secondLevelDisplay };
    handleDrillDownMap(newDrilMap);
  };

  return (
    <>
      {childrenElement && (
        <CategoryWrapper>
          {!!itemList.children.length && (
            <ArrowDrillDown
              onClick={handleLevelDisplay}
              src={selectorInputArrow}
              upArrow={firstLevelDisplay}
            />
          )}
          <StyleDD
            onClick={() => handleCategory(itemList.description, itemList)}
          >
            {itemList.description}
          </StyleDD>
        </CategoryWrapper>
      )}

      {!childrenElement && (
        <CategoryWrapper>
          {!!itemList.children.length && (
            <ArrowDrillDown
              onClick={handleLevelDisplay}
              src={selectorInputArrow}
              upArrow={firstLevelDisplay}
            />
          )}
          <StyleDT
            onClick={() => handleCategory(itemList.description, itemList)}
          >
            {itemList.description}
          </StyleDT>
        </CategoryWrapper>
      )}

      {itemList.children.map((element, index) => {
        if (element.children.length) {
          return (
            <SubCategoriesWrapper
              id="subCategories"
              style={{ display: firstLevelDisplay ? 'none' : 'inherit' }}
            >
              <CategoryWrapper>
                {!!itemList.children.length && (
                  <ArrowDrillDown
                    onClick={() => handleSecondLevelDisplay(index, element.id)}
                    src={selectorInputArrow}
                    upArrow={drillDownMap[element.id] || false}
                  />
                )}

                <span>
                  <StyleDD
                    onClick={() => handleCategory(element.description, element)}
                    paddingLeft={nestedCount}
                  >
                    {element.description}
                  </StyleDD>
                </span>
              </CategoryWrapper>

              <div
                id="categoriesWrapper"
                style={{
                  display: drillDownMap[element.id] || false ? 'none' : 'inherit'
                }}
              >
                {element.children.map((children) => (
                  <section>
                    <LoopGetChildrens
                      itemList={children}
                      handleCategory={handleCategory}
                      childrenElement
                      nestedCount={nestedCount}
                    />
                  </section>
                ))}
              </div>
            </SubCategoriesWrapper>
          );
        }

        return (
          <span style={{ display: firstLevelDisplay ? 'none' : 'inherit' }}>
            <StyleDD
              onClick={() => handleCategory(element.description, element)}
              extraPadding={!!itemList.children.length}
            >
              {element.description}
            </StyleDD>
          </span>
        );
      })}
    </>
  );
}

function TreeList({ handleCategory, itemList }) {
  const [drillDownMap, setDrillDownMap] = useState({});

  const handleDrillDownMap = (value) => {
    setDrillDownMap(value);
  };

  return (
    <StyleDL>
      <LoopGetChildrens
        itemList={itemList}
        handleCategory={handleCategory}
        handleDrillDownMap={handleDrillDownMap}
        drillDownMap={drillDownMap}
      />
    </StyleDL>
  );
}

export default TreeList;
