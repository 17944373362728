/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useMemo, useCallback } from 'react';

import { CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import PlayIcon from 'images/icons/signals/playsAndStarts/playCountMobileIcon.svg';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

const environments = Environment(process.env.REACT_APP_ENV);

function BackOffice({ userInfo }) {
  const actualDate = new Date();
  const actualDateLessOne = new Date(
    new Date().setDate(new Date().getDate() - 1)
  );
  const actualDateLessTwo = new Date(
    new Date().setDate(new Date().getDate() - 2)
  );
  const actualDatePlusOne = new Date(
    new Date().setDate(new Date().getDate() + 1)
  );

  const { id: paramsID } = useParams();

  const callSettings = useMemo(
    () => ({
      getId: paramsID,
      page: 1,
      totalPages: 0,
      size: 100
    }),
    [paramsID]
  );

  const { getId } = callSettings;

  const [closureInfos, setClosureInfos] = useState();
  const [countManuallyInfos, setCountManuallyInfos] = useState();
  const [salesInfos, setSalesInfos] = useState();

  const [loadingObj, setLoadingObj] = useState({
    closure: false,
    countManually: false,
    sales: false
  });

  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const getClosureInfos = useCallback(() => {
    setLoadingObj({ ...loadingObj, closure: true });
    const initialDateFormatted = moment(actualDateLessTwo).format('YYYY-MM-DD');
    const finalDateFormatted = moment(actualDateLessOne).format('YYYY-MM-DD');

    axios
      .get(
        `${environments.catalog}/inventories/internal/manuallyDailyStock?startDate=${initialDateFormatted}&finishDate=${finalDateFormatted}&restaurantId=${getId}`
      )
      .then((response) => {
        setClosureInfos(response?.data || {});
        setLoadingObj({ ...loadingObj, closure: false });
      })
      .catch((error) => {
        setClosureInfos({});
        setLoadingObj({ ...loadingObj, closure: false });
        console.log(error);
      });
  }, [userInfo.companiesActive.id]);

  const getCountManuallyInfos = useCallback(() => {
    setLoadingObj({ ...loadingObj, countManually: true });

    const initialDateFormatted = moment(actualDate).format('YYYY-MM-DD');
    const finalDateFormatted = moment(actualDatePlusOne).format('YYYY-MM-DD');

    axios
      .get(
        `${environments.worker}/config/openManuallyCount?initialDate=${initialDateFormatted}&finalDate=${finalDateFormatted}&restaurantId=${getId}`
      )
      .then((response) => {
        setCountManuallyInfos(response?.data || {});
        setLoadingObj({ ...loadingObj, countManually: false });
      })
      .catch((error) => {
        setCountManuallyInfos({});
        setLoadingObj({ ...loadingObj, countManually: false });

        console.log(error);
      });
  }, [userInfo.companiesActive.id]);

  const getSalesInfos = useCallback(() => {
    setLoadingObj({ ...loadingObj, sales: true });

    const body = {
      restaurantId: getId
    };

    axios
      .post(`${environments.sales}/sales/reprocess`, body)
      .then((response) => {
        setSalesInfos(response?.data || {});
        setLoadingObj({ ...loadingObj, sales: false });
      })
      .catch((error) => {
        setSalesInfos({});
        setLoadingObj({ ...loadingObj, sales: false });

        console.log(error);
      });
  }, [userInfo.companiesActive.id]);

  return (
    <Layout>
      <TitleHead title="Back Office" />

      <GlobalStyle />

      <Container className="closureAndSuggestionsPage">
        <PageTitleAndFilter title="Back Office" />

        <section className="dFlex flexColumn">
          <div className="cardBlueTitleDefault closureCard">
            <div className="cardHeader">
              <p>Back Office</p>
            </div>

            <div className="cardContent flexColumn dFlex">
              <div className="dFlex mb10 justifyBetween">
                <div className="dFlex" onClick={() => getClosureInfos()}>
                  <img
                    src={PlayIcon}
                    alt="playIcon"
                    className="mr10 cursorPointer"
                    style={{ width: 20 }}
                  />

                  <p className="mb0 cursorPointer">Fechamento</p>
                </div>

                {loadingObj.closure ? (
                  <CircularProgress size={20} />
                ) : (
                  <span
                    className={closureInfos && 'bold'}
                    style={{
                      color: closureInfos ? '#08AA15' : '#0F1225'
                    }}
                  >
                    {closureInfos ? 'OK' : '-'}
                  </span>
                )}
              </div>

              <div className="dFlex mb10 justifyBetween">
                <div className="dFlex" onClick={() => getCountManuallyInfos()}>
                  <img
                    src={PlayIcon}
                    alt="playIcon"
                    className="mr10 cursorPointer"
                    style={{ width: 20 }}
                  />

                  <p className="mb0 cursorPointer">Abertura de contagens</p>
                </div>

                {loadingObj.countManually ? (
                  <CircularProgress size={20} />
                ) : (
                  <span
                    className={countManuallyInfos?.message && 'bold'}
                    style={{
                      color: countManuallyInfos?.message ? '#08AA15' : '#0F1225'
                    }}
                  >
                    {countManuallyInfos?.message ? 'OK' : '-'}
                  </span>
                )}
              </div>

              <div className="dFlex justifyBetween">
                <div className="dFlex" onClick={() => getSalesInfos()}>
                  <img
                    src={PlayIcon}
                    alt="playIcon"
                    className="mr10 cursorPointer"
                    style={{ width: 20 }}
                  />

                  <p className="mb0 cursorPointer">Vendas</p>
                </div>

                {loadingObj.sales ? (
                  <CircularProgress size={20} />
                ) : (
                  <span
                    className={salesInfos?.message && 'bold'}
                    style={{
                      color: salesInfos?.message ? '#08AA15' : '#0F1225'
                    }}
                  >
                    {salesInfos?.message ? 'OK' : '-'}
                  </span>
                )}
              </div>
            </div>
          </div>
        </section>

        <Snackbar
          open={!!message.description}
          autoHideDuration={2000}
          onClose={() => setMessage({ description: '', status: '' })}
        >
          <Alert
            onClose={() => setMessage({ description: '', status: '' })}
            severity={message.status}
            color={message.status}
          >
            {message.description}
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BackOffice);
