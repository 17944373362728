import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
  } from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

import RegisterProductModal from '../../ProductForm';

function DialogProducts({
    open,
    PaperComponent,
    onCloseFunc,
    productData,
    maxWidth,
    fullScreen,
    setMessage,
    message
}) {
    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={() => {
                onCloseFunc();
            }}
            className="defaultModal productTableDialog"
            PaperComponent={PaperComponent}
            maxWidth={maxWidth}
        >
            <DialogTitle
                className="modalHeader bold textAlignCenter cursorMove"
                id="draggable-dialog-title"
                fullWidth
            >
            <Typography>
                <b>Cadastro de Produtos</b>
            </Typography>

            <IconButton
                edge="start"
                color="inherit"
                onClick={() => onCloseFunc()}
                aria-label="close"
                style={{ outline: '0', background: 'none', align: 'right' }}
            >
                <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
                />
            </IconButton>

            </DialogTitle>
            <DialogContent className="modalContent onlyTableProducts pb0 pt0 p20">
                <RegisterProductModal 
                    productDataModal={productData} 
                    onCloseFunc={onCloseFunc} 
                    setMessage={setMessage} 
                    message={message}/>
            </DialogContent>
        </Dialog>
    )
}

export default DialogProducts;
