export const filterChartData = (chartData) => {
    const { labels, datasets } = chartData; 

    datasets.forEach(element => {
        element.fill = false; 
        element.type = 'line';
        element.borderDashOffset = 0.0;
        element.pointRadius = 1;
        element.backgroundColor = element.color;
        element.borderColor = element.color;
        element.pointBorderColor = element.color;
        element.pointHoverBackgroundColor = element.color;
        element.pointHoverBorderColor = element.color;
    });

    return { labels: labels, datasets };
}

const decimalAdjust = (type, value, exp) => {

    if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
    }
    value = +value;
    exp = +exp;

    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
    }

    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));

    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

const round = ({ value, param }) => {
    return Math.ceil(value / param) * param;
}

export const filterChartLineOptions = (chartLineOptions, datasets, isProvider = false) => {
    const { scales: { yAxes } } = chartLineOptions; 
    const [ axe ] = yAxes;
    const { ticks } = axe;

    const maxValuesList = [];
    const minValueList = [];

    datasets.forEach(({data}) => {
        const max = data.reduce((a, b) => {
            return Math.max(a, b);
        });

        const min = data.reduce((a, b) => {
            return Math.min(a, b);
        });

        maxValuesList.push(max);
        minValueList.push(min);
    });

     const maxValue = maxValuesList.reduce((a, b) => {
        return Math.max(a, b);
    }) * 1.2;

    const minValue = minValueList.reduce((a, b) => {
        return Math.min(a, b);
    });

    const ticksMax = Math.round(maxValue);
    const ticksMin = Math.round(minValue);

    if ((ticksMax - ticksMin) > 100000) {
        ticks.stepSize = 40000;
    } else if (!isProvider) {
        ticks.stepSize = 10000;
    }

    if (isProvider) {
        let roundTicksMax = 0;
        let roundTicksMin = 0;

        if (ticksMax > 0 && ticksMax <= 50) {
            roundTicksMax = round({ value: ticksMax, param: 10 })
        }
        else if (ticksMax > 50 && ticksMax <= 200) {
            roundTicksMax = round({ value: ticksMax, param: 50 })
        }
        else if (ticksMax > 200 && ticksMax <= 500) {
            roundTicksMax = round({ value: ticksMax, param: 100 })
        }
        else if (ticksMax > 500 && ticksMax <= 2000) {
            roundTicksMax = round({ value: ticksMax, param: 500 })
        }
        else {
            roundTicksMax = round({ value: ticksMax, param: 1000 })
        }

        let stepSize = round({ value: roundTicksMax / 3, param: 10 })

        if (stepSize === 0) {
            ticks.beginAtZero = true
            stepSize = 1
        }

        ticks.max = roundTicksMax;
        ticks.min = roundTicksMin;
        ticks.stepSize = stepSize;
    }
    else {
        ticks.max = decimalAdjust('round', ticksMax, 3);
        ticks.min = decimalAdjust('round', ticksMin, 3);
    }

    return chartLineOptions;
}