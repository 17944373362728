export const icons = {
  filterIcon: `${process.env.PUBLIC_URL}/SVGicons/filterIcon.svg`,
  filterIconDisabled: `${process.env.PUBLIC_URL}/SVGicons/filterIconLightBlueNoBgRounded.svg`
};

export const inputSizes = {
  small: 100,
  small_to_medium: 125,
  medium: 155,
  medium_to_plus: 165,
  medium_custom: 180,
  medium_plus: 210,
  medium_to_large: 230,
  large: 250,
  large_plus: 400
};
