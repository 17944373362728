import styled from "styled-components";

export const ProductItem = styled.div`
    width: 100%;
    margin-top: 5px;
    section {
        position: relative;
        width: 420px;
        background-color: #D0D3E2;
        color: #454A67;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 2px 8px 2px 8px;
        font-weight: 700;
        cursor: pointer;
        margin-left: 16px;
        transition: all 250ms ease-out;
        font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
        img {
            transition: all 250ms ease-out;
            transform: ${({ openProductTree }) => openProductTree ? "rotate(180deg)" : "rotate(0deg)"};
        }
        &::before{
            content: "";
            position: absolute;
            width: 11px;
            height: 1px;
            background-color: #BFC0CB;
            left: -11px;
            top: 9px;
        }
        &::after{
            content: "";
            position: absolute;
            width: 1px;
            height: calc(100% + 8px);
            background-color: #BFC0CB;
            left: -11px;
            top: -9px;
        }
        // &:last-of-type {
        //     &::after{
        //         height: 19px;   
        //     }
        // }
    }
`;

export const ProductDescription = styled.div`
    position: relative;
    background-color: #E6E7F0;
    color: #454A67;
    font-weight: 400;
    font-size: 12px;
    text-decoration-line: underline;
    text-underline-offset: 2px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    padding: 8px 8px 12px 8px;
    width: 420px;
    margin-left: 16px;
`;

export const SkuItemWrapper = styled.div`
    background-color: white;
    width: 404px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #454A67;
    text-decoration-line: underline;
    text-underline-offset: 2px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    padding: 4px 8px 4px 8px;
    margin-top: 4px;
    border-bottom: 2px solid #9296AC;
    p {
        margin: 8px 0 0 0;
    }
`;

export const ProductCategorieSection = styled.div`
    display: flex;
    justify-content: space-between;
    cursor: pointerspace-between;
    img {
        margin-top: 5px;
        transition: all 250ms ease-out;
        transform: ${({ openSkuTree }) => openSkuTree ? "rotate(180deg)" : "rotate(0deg)"};
    }
`;

export const LinkWrapper = styled.div`
    cursor: pointer;
    a {
        color: inherit;
        text-decoration: none;
        text-decoration-line: none;
    }
`;

export const SwitchContainer = styled.div`
    display: flex;
    align-items: end;
    margin-left: 32px;
`;

export const SwitchDescription = styled.div`
    color: #010311;
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    margin-left: 32px;
`;
