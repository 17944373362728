import Environment from '../../utils/environments';
const env = Environment(process.env.REACT_APP_ENV);

const unitsConversion = async (value, primary, units, callBack) => {
    const locality = 'BR';
    const query = `${env.catalog}/unitsMeasurements/converters?value=${value}&consumptionUnitsPrimary=${primary}&unitsMeasurements=${units}&locality=${locality}`;
    const response = await fetch(query);
    const content = await response.json();
    if (content?.error || !content) return;
    callBack(content);
}

export default unitsConversion;
