/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react';

import {
  IconButton,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Add,
  CheckOutlined,
  CloseOutlined,
  DeleteOutline
} from '@material-ui/icons';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Tree } from 'shineout';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import categoriesExpand from 'images/icons/signals/addsAndCreates/categoriesExpand.svg';
import categoriesCollapsed from 'images/icons/signals/removesAndDeletes/categoriesCollapsed.svg';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

function RegisterModelCategories({ userInfo }) {
  const [alertErrorDuplicated, setAlertErrorDuplicated] = useState(false);
  const [alertErrUpdate, setAlertErrUpdate] = useState(false);
  const [alertErrDelete, setAlertErrDelete] = useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const closeMessage = () => setAlertErrorDuplicated(false);
  const onDismiss = () => setAlertErr(false);
  const onDismissUpdate = () => setAlertErrUpdate(false);
  const onDismissDelete = () => setAlertErrDelete(false);
  const { id: paramsID } = useParams();
  const profileType = userInfo.profile[0][0].name;
  const environments = Environment(process.env.REACT_APP_ENV);
  const BaseUrl = `${environments.catalog}/models/categories/`;
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [defaultExpanded] = useState(['Delivery']);
  const [expandedTree, setExpandedTree] = useState([]);
  const [description, setDescription] = useState(null);
  const [subDescription, setSubDescription] = useState();
  const [dataArray, setDataArray] = useState([]);
  const [buttonAddClick, setButtonAddClick] = useState(false);
  const [childId, setChildId] = useState('');
  const [typeClick, setTypeClick] = useState('Shopping');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [nodeClick, setNodeClick] = useState('');
  const [typeCategory, setTypeCategory] = useState('Shopping');
  const [getId, setGetId] = useState(paramsID);
  const [loading, setLoading] = useState(false);
  const [segments, setSegments] = useState([]);
  const [typeSegments, setTypeSegments] = useState('0');
  const [idSegment, setIdSegment] = useState(0);

  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275
    },

    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)'
    },

    title: {
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      color: '#000'
    },

    buttonStyle: {
      display: 'inline-block',
      padding: '6px 4px',
      border: '1px solid #ccc'
    },

    margin: {
      margin: theme.spacing(1)
    },

    Alert: {
      width: '50%',
      height: '30px',
      backgroundColor: 'white',
      border: '1px solid black'
    },

    buttonRow: {
      paddingTop: '20px',
      marginLeft: '55px'
    },

    buttonRowChild: {
      paddingTop: '10px'
    },

    formControl: {
      marginTop: '10px',
      paddingLeft: '10px',
      minWidth: '200px'
    },

    selectEmpty: {
      marginTop: theme.spacing(1)
    },

    paper: {
      width: 200,
      height: 230,
      overflow: 'auto'
    },

    button: {
      margin: theme.spacing(0.5, 0)
    }
  }));

  const classes = useStyles();

  const getCategories = useCallback(
    (typeCategory) => {
      (async () => {
        setLoading(true);
        axiosInstanceCatalog
          .get(
            `${environments.catalog}/models/categories?page=1&size=100&originId=${getId}&segmentId=${idSegment}&type=${typeCategory}`
          )
          .then((response) => {
            setDataArray(response?.data?.content);
            setLoading(false);
          })
          .catch(() => {
            setDataArray([]);
            setLoading(false);
          });
      })();
    },
    [getId, idSegment]
  );

  const getSegments = useCallback(() => {
    (async () => {
      setLoading(true);
      const categoriesApiSales = await axiosInstanceCatalog.get(
        `${environments.catalog}/segments?page=1&size=10`
      );
      const data = await categoriesApiSales.data;
      setSegments(data.content);
      setLoading(false);
    })();
  }, [getId]);

  useEffect(() => {
    getSegments();
  }, []);

  // Origin Id
  useEffect(() => {
    setGetId(paramsID);
  }, [paramsID]);

  // Categorias
  useEffect(() => {
    getCategories(typeCategory);
  }, [getId, idSegment]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeType = (event) => {
    setTypeClick(event.target.value);
    setTypeCategory(event.target.value);
    getCategories(event.target.value);
  };

  const handleChangeSegment = (event) => {
    setTypeSegments(event.target.value);
  };

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  // adiciona categoria filha
  const addNewObj = () => {
    axios
      .post(
        `${BaseUrl}?updateMenu=true`,
        {
          originId: getId,
          description: subDescription,
          parentId: childId,
          type: typeClick,
          segmentId: idSegment,
          user: userInfo.user
        },
        config
      )
      .then(() => {
        setSubDescription('');
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage === 'Description has been already registered.') {
          setAlertErrorDuplicated(true);
        } else {
          setAlertErr(true);
        }
      })
      .finally(() => {
        setButtonAddClick(false);
      });
  };

  const cancelAddObject = () => {
    setButtonAddClick(false);
  };

  // adiciona categoria pai
  const addNewCategory = () => {
    axios
      .post(
        `${environments.catalog}/models/categories`,
        {
          description,
          segmentId: idSegment,
          parentId: null,
          type: typeClick,
          user: userInfo.user
        },
        config
      )
      .then(() => {
        setDescription('');
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        if (errorMessage === 'Description has been already registered.') {
          setAlertErrorDuplicated(true);
        } else {
          setAlertErr(true);
        }
      })
      .finally(() => {
        setButtonAddClick(false);
      });
  };

  // funcao para remover a categoria
  const removeObj = (node) => {
    axios
      .delete(`${environments.catalog}/models/categories/${node?.id}`, config)
      .then(() => {
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch(() => {
        setAlertErrDelete(true);
      });
    handleClose();
  };

  // funcao para mandar a edicao da categoria para o back
  const updateData = (id, description) => {
    if (description !== '') {
      axios
        .put(
          `${environments.catalog}/models/categories/${id}`,
          {
            description,
            user: userInfo.user
          },
          config
        )
        .then(() => {
          setTimeout(() => getCategories(typeCategory), 300);
        })
        .catch(() => {
          setAlertErrUpdate(true);
        });
    }
  };

  // estados da categoria filha
  const valuesChild = (node) => {
    setChildId(node.id);
    setButtonAddClick(true);
  };

  // funcao recursiva para contar quantas categorias filhas existe, na pai
  let count;
  const countCategories = (node) => {
    count = node.children.length;
    node.children.forEach((obj) => {
      if (obj.children) {
        count = parseInt(count, 10) + parseInt(obj.children.length, 10);
      } else {
        countCategories(obj.children);
      }
    });
  };

  const deleteCategory = (node) => {
    countCategories(node);
    setNodeClick(node);
    setMessage(`Deseja deletar a categoria ${node.description} ?`);
    handleClickOpen();
  };

  const isDisableAddOrderCategory =
    description === null || description === '' || false;

  const isDisableAddOrderSubCategory =
    subDescription === null || subDescription === '' || false;

  // renderiza os objetos com os botoes edit, del, add
  const renderItem = (node) => (
    <div
      className={!!node.products?.length && !!node.children?.length && 'teste'}
    >
      <TextField
        margin="dense"
        size="small"
        variant="outlined"
        defaultValue={node.description}
        inputProps={{ maxLength: 50 }}
        onBlur={(e) => updateData(node.id, e.target.value)}
        style={{
          width:
            typeCategory === 'Shopping' && profileType !== 'admin'
              ? '180px'
              : '320px'
        }}
        id={`item-${node.id}`}
      />

      <span style={{ position: 'relative', top: '8px' }}>
        <IconButton
          aria-label="add"
          disabled={node.nivel === 15}
          onClick={() => valuesChild(node)}
          style={{ outline: 6 }}
        >
          <Add aria-label="add" fontSize="small" />
        </IconButton>

        <IconButton
          aria-label="delete"
          onClick={() => deleteCategory(node)}
          style={{ outline: 6 }}
        >
          <DeleteOutline aria-label="delete" fontSize="small" />
        </IconButton>
      </span>

      {buttonAddClick && childId === node.id ? (
        <div className="addItemContainer">
          <Grid
            style={{ marginLeft: '16px', paddingTop: 0 }}
            className={classes.buttonRowChild}
            container
            spacing={12}
          >
            <Grid>
              <TextField
                className="textFieldSmall"
                placeholder="Adicionar..."
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                value={subDescription}
                onChange={(e) => {
                  setSubDescription(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />

              <IconButton
                className="smallIcons"
                aria-label="check"
                onClick={() => addNewObj()}
                style={{ outline: 6 }}
                disabled={isDisableAddOrderSubCategory}
              >
                <CheckOutlined size="small" />
              </IconButton>
              {idSegment && (
                <IconButton
                  className="smallIcons"
                  aria-label="cancel"
                  onClick={() => cancelAddObject()}
                  style={{ outline: 6 }}
                >
                  <CloseOutlined size="small" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );

  return (
    <Layout>
      <TitleHead title="Categorias" />
      <GlobalStyle />

      <Container>
        {alertErr ? (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErr}
            toggle={onDismiss}
          >
            Erro ao cadastrar
          </Alert>
        ) : null}

        {alertErrUpdate ? (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErr}
            toggle={onDismissUpdate}
          >
            Erro ao atualizar
          </Alert>
        ) : null}

        {alertErrDelete ? (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErr}
            toggle={onDismissDelete}
          >
            Erro ao deletar
          </Alert>
        ) : null}

        {alertErrorDuplicated && (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErrorDuplicated}
            toggle={closeMessage}
          >
            Uma categoria não pode ter o mesmo nome que a categoria pai ou 2 de
            mesmo nível.
          </Alert>
        )}

        <div style={{ marginTop: 4, marginBottom: 3 }}>
          <PageTitleAndFilter title="Categorias modelo cadastradas" />
        </div>

        <Grid className="cardDefault dFlex withoutInputs mb16">
          <div style={{ width: '268px' }}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Selecione um segmento *
            </Typography>

            <FormControl
              variant="outlined"
              size="small"
              className="selectSmallBox w100"
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={typeSegments}
                onChange={handleChangeSegment}
                displayEmpty
                className="selectSmall"
              >
                <MenuItem value="0" onClick={() => setIdSegment(0)}>
                  <em>Selecione aqui...</em>
                </MenuItem>
                {segments?.map((item) => (
                    <MenuItem
                      value={item.description}
                      onClick={() => setIdSegment(item.id.toString())}
                    >
                      {item?.description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: '268px', marginLeft: '10px' }}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Selecione um tipo de categoria
            </Typography>

            <FormControl
              variant="outlined"
              size="small"
              className="selectSmallBox w100"
            >
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={typeClick}
                onChange={handleChangeType}
                displayEmpty
                className="selectSmall"
              >
                <MenuItem value="">
                  <em>Selecione aqui...</em>
                </MenuItem>
                <MenuItem value="Sales">Venda</MenuItem>
                <MenuItem value="Shopping">Compra</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
        {idSegment !== 0 && (
          <Grid className="cardDefault withoutInputs pRelative">
            {loading && (
              <div sytle={{ margin: '10px' }}>
                <LinearProgress variant="query" />
              </div>
            )}
            <Tree
              data={dataArray}
              keygen={(node) => node.description}
              defaultExpanded={defaultExpanded}
              renderItem={renderItem}
              className="categoriesTree"
              expandIcons={[
                <img src={categoriesExpand} alt="expandIcon" />,
                <img src={categoriesCollapsed} alt="collapse" />
              ]}
              expanded={expandedTree}
            />

            {typeCategory && (
              <Grid container spacing={12}>
                <Grid
                  className={`fixedRootCategories ${
                    !dataArray?.length && 'noLines'
                  }`}
                >
                  <TextField
                    className="textFieldSmall"
                    placeholder="Adicionar..."
                    size="small"
                    variant="outlined"
                    inputProps={{ maxLength: 50 }}
                    value={description}
                    InputLabelProps={{ shrink: true }}
                    required
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                  <IconButton
                    className="smallIcons"
                    aria-label="check"
                    onClick={addNewCategory}
                    style={{ outline: 6 }}
                    disabled={isDisableAddOrderCategory}
                  >
                    <CheckOutlined size="small" />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography
              className="bold textAlignCenter"
              style={{
                fontSize: '18px'
              }}
            >
              {message}
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter pb20">
            <Button
              className="defaultButton backButton"
              onClick={handleClose}
              style={{ outline: 0 }}
              color="primary"
            >
              Cancelar
            </Button>

            <Button
              className="defaultButton submitButton"
              onClick={() => removeObj(nodeClick)}
              style={{ outline: 0 }}
              color="primary"
              autoFocus
            >
              Deletar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Layout>
  );
}
const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterModelCategories);
