import makePost from '../../../services/useFetch/makePost';

const saveUnityAdditionalCount = (itemContent, append, props) => {

   const { productID, providerId, restaurantId, setupId, user } = props;
   const { 
      currentProduct: { realStock, additional, unityAdditional, labelUnityAdditional } = {},
      sku: { id: skuId } = {  } 
   } = itemContent;

   const data = {
      labelUnityAdditional: labelUnityAdditional,
      unityAdditional: unityAdditional, 
      productId: productID,
      providerId: providerId,
      realStock: realStock,
      restaurantId: restaurantId,
      setupId: setupId,
      skuId: skuId,
      user: user
   };

   makePost('saveInventoriesProducts', data, () => {}, append);
}

const saveAdditionalSkuCount = (itemContent, append, props) => {

   const { productID, providerId, restaurantId, setupId, user } = props;
   const { 
      currentProduct: { realStock, additional } = {},
      sku: { id: skuId } = {  } 
   } = itemContent;

   const data = {
      additional: additional,
      labelAdditional: typeof additional === "nummber" ?  additional.toString() : additional, 
      productId: productID,
      providerId: providerId,
      realStock: realStock,
      restaurantId: restaurantId,
      setupId: setupId,
      skuId: skuId,
      user: user
   };

   makePost('saveInventoriesProducts', data, () => {}, append);
}

const saveCountItem = (value, itemContent, type, user) => {

   const { 
      id: countID, 
      currentProduct: { id: productID, realStock } = {}, 
      restaurantId, 
      sku: { providers = [], id: skuId } = {},
      setup: { id: setupId } = {},
   } = itemContent;

   const [ firstProvider ] = providers;
   const { id: providerId, quantity: providerQuantity } = { ...firstProvider };
   
   const append = `${countID}/products/${productID}/?originId=${restaurantId}`;

   if (type === "additional") {
      saveUnityAdditionalCount(
         itemContent, 
         append, 
      { 
         productID: productID, 
         providerId: providerId, 
         restaurantId: restaurantId, 
         setupId: setupId,
         user: user
      });
      return;
   }

   if (type === "additionalSKU" ) {
      saveAdditionalSkuCount(
         itemContent, 
         append, 
         { 
            productID: productID, 
            providerId: providerId, 
            restaurantId: restaurantId, 
            setupId: setupId,
            user: user
         });
      return;
   }

   const data = {
      additional: null,
      labelAdditional: null, 
      labelQuantity: value?.toString(),
      labelUnityAdditional: null,
      productId: productID,
      providerId: providerId,
      quantity: parseFloat(value),
      realStock: realStock,
      restaurantId: restaurantId,
      setupId: setupId,
      skuId: skuId,
      unityAdditional: null, 
      user: user
   };

   makePost('saveInventoriesProducts', data, () => {}, append);

}

export default saveCountItem;
