import React from 'react';

import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogActions
} from '@material-ui/core';

import Button from 'components/Button/button';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

function CountDivergentCheckDialog({ openModal, handleClose, backFunction }) {
  const { countDivergentCheckDialog } = openModal;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={countDivergentCheckDialog}
      onClose={() => handleClose('countDivergentCheckDialog')}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal modalCardWidth"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold cursorMove textAlignCenter"
        id="draggable-dialog-title"
      >
        <Typography>Divergência em contagem</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => handleClose('countDivergentCheckDialog')}
        >
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent">
        <Typography
          style={{
            fontSize: '14px'
          }}
        >
          Existem contagens com possível divergência. Deseja sair da página ou
          continuar e checar as divergências?
        </Typography>
      </DialogContent>

      <DialogActions className="justifyCenter">
        <Button
          className="defaultButton backButton"
          design="outlined"
          onClick={backFunction}
          label="Sair"
          color="primary"
          style={{ outline: 0, marginRight: '15px' }}
        />

        <Button
          className="defaultButton submitButton"
          autoFocus
          style={{ outline: 0 }}
          design="contained"
          label="Continuar"
          onClick={() => handleClose('countDivergentCheckDialog')}
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
}

export default CountDivergentCheckDialog;
