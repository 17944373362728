import React, { useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import MainInfoCard from 'components/MainInfoCard';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import SelectRestaurantDialog from 'components/Dialogs/SelectRestaurantDialog';
import MainHomeContainer from 'containers/mainHomeContainer';
import useFetch from 'services/useFetch';
import * as MenuActions from 'store/actions/menu';
import { handleFilterOptions } from 'utils/handleFilterActions';

const lastDaysOptions = [
  { name: 'últimos 7 dias', value: 7 },
  { name: 'últimos 14 dias', value: 14 },
  { name: 'últimos 21 dias', value: 21 },
  { name: 'últimos 28 dias', value: 28 },
  { name: 'últimos 35 dias', value: 35 }
];

function Indicators({ userInfo }) {
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const [cardInfo, setCardInfo] = useState([]);
  const { id: paramsID } = useParams();
  const [getId, setId] = useState(paramsID);
  const [filterRestaurants, setFilterRestaurants] = useState([]);
  const [filterRestaurantName, setFilterRestaurantName] = useState([]);
  const [lastDayOption, setLastDayOptions] = useState(lastDaysOptions[0]);
  const [filters] = useState([
    { param: 'originId', value: getId, urlParam: `originId=${getId}` },
    { param: 'days', value: 7, urlParam: `days=7` }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [openSelectRestaurantDialog, setOpenSelectRestaurantDialog] =
    useState(true);
  const [updateRestaurantSelected, setUpdateRestaurantSelected] =
    useState(true);
  const { data, loading } = useFetch('dash-board-api-home', filtersChanged);
  const isRestaurant = userInfo?.profile?.[0]?.[0]?.name === 'restaurants';

  useEffect(() => {
    handleFilterOptions(
      'originId',
      filterRestaurants?.length ? filterRestaurants : getId,
      filtersChanged,
      setFiltersChanged
    );
  }, [getId, filterRestaurants]);

  const handleLastDayOption = (indexOption) => {
    setLastDayOptions(lastDaysOptions[indexOption]);
    handleFilterOptions(
      'days',
      lastDaysOptions[indexOption].value,
      filtersChanged,
      setFiltersChanged
    );
  };

  const clearLinks = () => {
    setCardInfo(
      data.map((item) => {
        if (item.title === 'Vendas') {
          return {
            ...item,
            details: ''
          };
        }
        if (item.title === 'Ticket médio') {
          return {
            ...item,
            details: '',
            subInfos: {
              ...item.subInfos,
              details: ''
            }
          };
        }
        if (item.title === 'CMV') {
          return {
            ...item,
            details: ''
          };
        }
        if (item.title === 'Contagem Diária') {
          return {
            ...item,
            details: ''
          };
        }
        if (item.title === 'Pedidos e Estoque') {
          return {
            ...item,
            detailsOrder: '',
            detailsStock: ''
          };
        }
        if (item.title === 'Vendas e Compras') {
          return {
            ...item,
            details: ''
          };
        }
      })
    );
  };

  const addLinks = () => {
    setCardInfo(
      data.map((item) => {
        if (item.title === 'Vendas') {
          return {
            ...item,
            details: '/cardapio'
          };
        }
        if (item.title === 'Ticket médio') {
          return {
            ...item,
            details: '',
            subInfos: {
              ...item.subInfos,
              details: '/producao'
            }
          };
        }
        if (item.title === 'CMV') {
          return {
            ...item,
            details: '/cardapio'
          };
        }
        if (item.title === 'Contagem Diária') {
          return {
            ...item,
            details: '/inventario/contagens'
          };
        }
        if (item.title === 'Pedidos e Estoque') {
          return {
            ...item,
            detailsOrder: '/pedidos',
            detailsStock: '/estoque'
          };
        }
        if (item.title === 'Vendas e Compras') {
          return {
            ...item,
            details: '/cardapio'
          };
        }
      })
    );
  };

  useEffect(() => {
    setId(paramsID);

    if (filterRestaurants?.length > 1 || filterRestaurantName === 'Todos') {
      clearLinks();
    } else {
      addLinks();
    }
  }, [paramsID, data]);

  const resetCardInfo = () => {
    setCardInfo(data);
  };

  return (
    <Layout homeGrid>
      <TitleHead title="Home" />

      <MainHomeContainer
        stores={stores[0]}
        cardsInfo={cardInfo}
        setCardInfo={setCardInfo}
        resetCardInfo={resetCardInfo}
        lastDaysOptions={lastDaysOptions}
        lastDayOption={lastDayOption}
        handleLastDayOption={handleLastDayOption}
        userInfo={userInfo}
        filterRestaurants={filterRestaurants}
        setFilterRestaurants={setFilterRestaurants}
        setFilterRestaurantName={setFilterRestaurantName}
        updateRestaurantSelected={updateRestaurantSelected}
      >
        {cardInfo.length
          ? cardInfo.map((card) => (
              <MainInfoCard
                filterRestaurantName={filterRestaurantName}
                stores={stores[0]}
                cardInfo={card}
                lastDayOption={lastDayOption}
                getId={getId}
                filterRestaurants={filterRestaurants}
              />
            ))
          : !loading && (
              <p className="textAlignCenter w100 bold mt20">
                Erro ao carregar informações
              </p>
            )}

        {loading && (
          <LinearProgress
            style={{ height: '7px', width: '99.5%' }}
            id="loading"
            variant="query"
          />
        )}

        {openSelectRestaurantDialog &&
          !userInfo?.firstSelectRestaurant &&
          isRestaurant &&
          stores?.[0].length > 1 && (
            <SelectRestaurantDialog
              openModal={openSelectRestaurantDialog}
              setOpenSelectRestaurantDialog={setOpenSelectRestaurantDialog}
              setFilterRestaurants={setFilterRestaurants}
              updateRestaurantSelected={updateRestaurantSelected}
              setUpdateRestaurantSelected={setUpdateRestaurantSelected}
            />
          )}
      </MainHomeContainer>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(MenuActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Indicators);
