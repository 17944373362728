import React, { useMemo } from 'react';

import { format } from 'date-fns';

function NextCountRow({ productModal, isMobileScreen }) {
  const nextCountDate = useMemo(() => {
    if (
      productModal?.setups?.length &&
      productModal?.setups[0]?.config?.nextCount
    ) {
      return format(
        new Date(productModal.setups[0].config.nextCount),
        'dd/MM/yyyy'
      );
    }
    return '';
  }, [productModal?.setups]);

  return (
    <tr>
      <td className="description right mb0 pl16">&nbsp;</td>
      <td colSpan={isMobileScreen ? "2" : "6"} className="description right mb0 pr16 ">
        <span>
          <b className="mr10">Próxima contagem: </b>
          {nextCountDate}
        </span>
      </td>
    </tr>
  );
}

export default NextCountRow;
