const calculator = ({parsedPackageSize, parsedQuantitySku, parsedValue, parsedAdditional, unity, productUnity, parsedUnityAdditional}) => {
    if ((unity === "ml" || unity === "g") && productUnity === "un") {

        if (parsedUnityAdditional) {
            return (parsedPackageSize * parsedValue) + (parsedAdditional || 0) + parsedUnityAdditional || 0; 
        } 

        if(parsedAdditional) {
            return (parsedPackageSize * (parsedValue || 0 )) + parsedAdditional; 
        }

        return (parsedPackageSize * parsedValue);
    }

    return (parsedPackageSize * parsedQuantitySku * parsedValue);
}

const getRealStockInRemainSkus = (skus, skuId, productUnity) => {
    const getRemainSkus = skus.filter(sku => sku.id !== skuId);
    
    const calcsList = [];

    getRemainSkus.forEach(element => {
        const { 
            quantity: quantitySku, 
            providers = [],
            unitsMeasurements: { abbreviation: skuUnity = '' } = {},
            id: skuId,
        } = element;

        const [ firstProvider ] = providers; 
        const {quantity: providerQuantity, stock } = firstProvider;

        const parsedPackageSize = parseFloat(typeof providerQuantity === 'string' ? providerQuantity?.replace(',', '.') : providerQuantity);
        const parsedQuantitySku = parseFloat(typeof quantitySku === 'string' ? quantitySku?.replace(',', '.') : quantitySku);
        const parsedValue = parseFloat(typeof stock === 'string' ? stock?.replace(',', '.') : stock);
        // const parsedAdditional = additional ? parseFloat(typeof additional === 'string' ? additional?.replace(',', '.') : additional) : null;

        const result = calculator({
            parsedPackageSize: isNaN(parsedPackageSize) ? 0 : parsedPackageSize,
            parsedQuantitySku: isNaN(parsedQuantitySku) ? 0 : parsedQuantitySku,
            parsedValue: isNaN(parsedValue) ? 0 : parsedValue,
            // parsedAdditional: parsedAdditional,
            unity: skuUnity,
            productUnity: productUnity
        });
        calcsList.push(result);
    });

    return calcsList.reduce((acc, curr) =>  acc + curr, 0);
}

const calcRealStock = ({ 
    quantitySku = 1, 
    providerQuantity = 1, 
    countValue, 
    additional, 
    unity, 
    productUnity, 
    skus = [],
    skuId,
    unityAdditional
}) => {

    if (!providerQuantity && !countValue) return 0;

    const parsedPackageSize = parseFloat(typeof providerQuantity === 'string' ? providerQuantity?.replace(',', '.') : providerQuantity);
    const parsedQuantitySku = parseFloat(typeof quantitySku === 'string' ? quantitySku?.replace(',', '.') : quantitySku);
    const parsedValue = parseFloat(typeof countValue === 'string' ? countValue?.replace(',', '.') : countValue);
    const parsedAdditional = additional ? parseFloat(typeof additional === 'string' ? additional?.replace(',', '.') : additional) : null;
    const parsedUnityAdditional = unityAdditional ? parseFloat(typeof unityAdditional === 'string' ? unityAdditional?.replace(',', '.') : unityAdditional) : null;

    const realStockInRemainSkus = skus.length >= 2 ? getRealStockInRemainSkus(skus, skuId, productUnity) : null;
    const result = calculator({
        parsedPackageSize: isNaN(parsedPackageSize) ? 0 : parsedPackageSize,
        parsedQuantitySku: isNaN(parsedQuantitySku) ? 0 : parsedQuantitySku,
        parsedValue: isNaN(parsedValue) ? 0 : parsedValue,
        parsedAdditional: parsedAdditional,
        parsedUnityAdditional: parsedUnityAdditional,
        unity: unity,
        productUnity: productUnity,
    });

    return result + (realStockInRemainSkus ? realStockInRemainSkus : 0); 
}

export default calcRealStock; 
