export function setAssortment(data) {
    return {
        type: 'SET_ASSORTMENT_DATA',
        data,
    };
}

export function createAssortment(data) {
    return {
        type: 'CREATE_ASSORTMENT_DATA',
        data,
    };
}