import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CardsFormContainer = styled.div`
  margin-bottom: 0;
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-family: Lato;
  background-color: ${(props) =>
    props.isProvider ? '#F7DFDF' : 'transparent'};
  color: ${(props) => (props.isProvider ? '#D94141' : '#000')};
  width: calc(100% + 32px);
  margin: -8px -16px;
  padding: 13px;
`;

export const StyledTabs = styled.div`
  display: flex;
  justify-content: space-between;

  .tabsList{
    display: flex;
    padding-left: 4px;
    gap: 5px;
    margin-top: 10px;
    .tab{
      width: 200px;
      height: 20px;
      background-color: #fff;
      display: flex;
      align-items: center;
      border: 0.5px solid #1F2445;
      box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
      border-radius: 4px 4px 0px 0px;
      justify-content: space-between;
      padding: 0px 10px;
      cursor: pointer;
      &.is-active{
        background-color: #5062F0;
        color: #fff;
      }
      span{
        font-family: Lato;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }

  .menuLowAutomaticInfosBox {
    display: flex;
    align-items: center;

    .defaultInfoBox {
      display: flex;
      align-items: center;

      span {
        margin-bottom: 0;
        color: #010311;
        margin-left: 30px;
        font-weight: bold;
        font-size: 12px;
      }

      .chipBox {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-radius: 50px;
        min-width: 80px;
        padding: 0px 10px;
        margin-left: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        span {
          margin: 0;
          font-weight: normal
        }
      }
    }
  }
`;
