const round = (number, decimalPlaces = 1) => {
    if(number === 0) return '0';
    if(!number) return '';
    if(isNaN(number)) return '';
    const factorOfTen = Math.pow(10, decimalPlaces)
    return Math.round(number * factorOfTen) / factorOfTen
}

export const roundStartZero = (number, decimalPlaces = 1) => {
    if(number === 0) return '0';
    if(!number) return '';
    if(isNaN(number)) return '';
    const factorOfTen = Math.pow(10, decimalPlaces)
    const result = Math.round(number * factorOfTen) / factorOfTen;
    return result <= 9 && result >= -9 ? `0${result}` : result;
}

export default round;
