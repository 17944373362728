/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.color = '#1F2445';
ChartJS.defaults.font.family = 'Lato'

function SimpleVerticalBarGraphic({ userInfo, mainContent = [] }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: !mainContent?.noLegend,
        position: 'bottom',
        align: 'end',
        labels: {
          boxHeight: 10,
          usePointStyle: true,
          pointStyle: 'circle',
          font: {
            weight: 700,
          }
        }
      },
      tooltip: {
        callbacks: {
          title() {
            return null
          },
          label(context) {
            return mainContent?.contentFormatted?.find(el => el?.index === context?.dataIndex)?.item;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          color: '#915'
        },
        ticks: {
          font: {
            weight: 'bold'
          } 
        },
        grid: {
          color: '#FFF',
          borderColor: '#FFF',
          tickColor: '#FFF'
        },
        border: {
          color: '#FFF',
        }
      },
      y: {
        suggestedMin: mainContent?.yMin || Math.min(...mainContent?.content?.map(el => el)),
        suggestedMax: mainContent?.yMax || Math.max(...mainContent?.content?.map(el => el)),
        title: {
          color: '#915'
        },
        ticks: {
          font: {
            weight: 'bold'
          },
          callback: (value) => {
            if (mainContent?.customYLabelAfter === 'hs' && value > 24) {
              return '24hs'
            }
            
            if ((value - Math.floor(value)) !== 0){
              return '';
            }

            const afterContent = mainContent?.customYLabelAfter || ''
            
            return `${parseFloat((value || 0)?.toFixed(0))}${afterContent}`;
          }
        },
        grid: {
          color: '#FFF',
          borderColor: '#FFF',
          tickColor: '#FFF'
        },
        border: {
          color: '#FFF',
        }
      }
    }
  };
  
  const labels = mainContent?.labels || [];

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: mainContent?.content,
        backgroundColor: mainContent?.content.map(el => parseFloat((el || 0)?.toFixed(0)) === 100 ? '#5062F0' : 'rgba(242, 185, 13, 1)'),
        borderRadius: 15,
        barThickness: 10,
      },
    ],
  };

  return <Bar options={options} data={data} />;
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleVerticalBarGraphic);
