import React from 'react';

import {
  Dialog,
  Paper,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import Draggable from 'react-draggable';

import Button from 'components/Button/button';
import Environment from 'utils/environments';

const userLocal = JSON.parse(localStorage.getItem('companies'));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const config = {
  headers: {
    User: `${userLocal?.user}`
  }
};

function DeleteCountDialog({
  openState,
  onCloseProductDialog,
  countIdToDelete,
  isMobile = false,
  setToastProps,
  setToastOpen
}) {
  const environments = Environment(process.env.REACT_APP_ENV);

  const deleteCountByID = () => {
    axios
      .delete(
        `${environments.catalog}/inventories/counts/${countIdToDelete}`,
        config
      )
      .then(() => {
        onCloseProductDialog({ refreshData: true });
        setToastProps({
          severity: 'success',
          message: 'Contagem deletada com sucesso!'
        });

        setToastOpen(true);
      })
      .catch((error) => {
        setToastProps({
          severity: 'error',
          message: error?.response?.data?.message || 'Não foi possível deletar a contagem. Tente novamente mais tarde.'
        });

        setToastOpen(true);
      });
  };

  const handleDeleteCount = () => {
    deleteCountByID();
  };

  const styles = isMobile
    ? {
      fontSize: '12px'
    }
    : {};

  return (
    <Dialog
      open={openState}
      onClose={onCloseProductDialog}
      PaperComponent={PaperComponent}
      PaperProps={{ style: { borderRadius: 20 } }}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="modalHeader navyBlue bold cursorMove"
        id="draggable-dialog-title"
      >
        <Typography style={styles}>
          Deseja realmente excluir essa contagem?
        </Typography>
      </DialogTitle>

      <DialogContent className="modalContent">
        <Typography style={styles}>
          Esta ação não pode ser desfeita. Deseja continuar?
        </Typography>
      </DialogContent>

      <DialogActions className="justifyCenter">
        <Button
          className="defaultButton backButton"
          design="outlined"
          onClick={onCloseProductDialog}
          label="Cancelar"
          color="primary"
          style={{ outline: 0 }}
        />

        <Button
          className="defaultButton submitButton"
          autoFocus
          style={{ outline: 0 }}
          design="contained"
          label="Confirmar"
          onClick={handleDeleteCount}
          color="primary"
        />
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCountDialog;
