import styled from 'styled-components';

export const ReceivesSkeletonLoading = styled.div`
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  margin: 8px 0;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const MobileReceiver = styled.div`
  display: none;
  width: ${( {width} ) => width};
  
  @media screen and (min-device-width: 320px) and (max-device-width: 480px), screen and (max-device-width: 1366px) and (min-device-height: 1024px) {
    display: block;
  }
`;

export const CardReceiver = styled.div`
  display: ${( {open} ) => open ? 'none' : 'block'};
  width: ${( {width} ) => width};
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  margin: 10px 0;
  border-radius: 4px;

  @media only screen and (max-width: 480px) {
    display: flex;
    padding: 8px 8px;
    flex-direction: column;
    
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .nameAndStatus {
      display: flex;
      align-items: flex-end;
      padding: 0 0px;
      font-size: 12px;
      margin-bottom: 2px;

      p {
        margin: 0;
        font-size: 12px;
        font-weight: 700;
        color: #1F2445;
      }

      div {
        font-weight: 400;
        width: 86px;
        height: 20px;
        font-size: 12px;
        padding: 2px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #DADDF3;
        color: #0B1A8E;
      }
    }

    .description {
      display: flex;
      align-items: flex-end;
      padding: 0 0px;

      div:first-child {
        width: 150px;
        font-size: 12px;
        background-color: #D2F9D6;
        color: #08AA15;
        display: flex;
        justify-content: flex-start;

        p {
          padding-left: 2px;
          margin: 0;
          font-size: 12px;
        }

        p:last-child {
          padding: 2px 2px 2px 2px;
        }
      }
      
      p {
        margin: 0;
        font-size: 12px;
      }
    }
  }
`;