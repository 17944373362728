/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import InformationBox from 'components/InformationBox/InformationBox';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import dataBotafogo from 'data/homeResumoBotafogo.json';
import dataLittle from 'data/homeResumoLittle.json';
import dataRD from 'data/homeResumoRD.json';
import * as MenuActions from 'store/actions/menu';

import FilterItem from './FilterItem';
import HomeHeader from './HomeHeader';
import { Information, ItemsContainer } from './styles';

function HomeInfos({ userInfo }) {
  const location = useLocation();
  const currentData = location.state.fakeData
    ? location.state.fakeData
    : userInfo.companiesActive.id === 2
    ? dataLittle
    : userInfo.companiesActive.id === 1
    ? dataBotafogo
    : dataRD;
  const fakeDataOperational = currentData
    .reduce((acc, item) => [...acc, item.operational], [])
    .flat()
    .reduce((acc, operation) => [...acc, operation.modal], [])
    .flat();

  const fakeDataManagements = currentData
    .reduce((acc, item) => [...acc, item.managements], [])
    .flat()
    .reduce((acc, management) => [...acc, management.modal], [])
    .flat();

  const fakeData = [fakeDataOperational, fakeDataManagements]
    .flat()
    .filter((item) => item !== undefined);
  const [selectedMainTypes, setSelectedMainTypes] = useState([
    location.state.selectedCard
      ? location.state.selectedCard.toUpperCase()
      : 'GENERAL'
  ]);
  const [selectedType, setSelectedType] = useState('all');

  function setFilterMainOptions(param) {
    const array = [...selectedMainTypes];
    const index = array.indexOf(param);

    if (selectedMainTypes.includes(param)) {
      array.splice(index, 1);
      setSelectedMainTypes(array);
    } else {
      setSelectedMainTypes([...selectedMainTypes, param]);
    }
  }

  function setFilterOptions(param) {
    if (param === selectedType) {
      setSelectedType('all');
    } else {
      setSelectedType(param);
    }
  }

  return (
    <Layout noMarginGrid>
      <TitleHead title="Home" />

      <HomeHeader />

      <section className="homeInfosContainer">
        <Information>
          <FilterItem
            title="GERAL"
            type="GENERAL"
            setFilterMainOptions={setFilterMainOptions}
            setFilterOptions={setFilterOptions}
            selectedMainTypes={selectedMainTypes}
            fakeData={fakeData}
          />

          <FilterItem
            title="1 - CATÁLOGO"
            type="CATALOG"
            setFilterMainOptions={setFilterMainOptions}
            setFilterOptions={setFilterOptions}
            selectedMainTypes={selectedMainTypes}
            fakeData={currentData[1]}
          />

          <FilterItem
            title="2 - CARDÁPIO"
            type="MENU"
            setFilterMainOptions={setFilterMainOptions}
            setFilterOptions={setFilterOptions}
            selectedMainTypes={selectedMainTypes}
            fakeData={currentData[2]}
          />

          <FilterItem
            title="3 - ESTOQUE"
            type="INVENTORY"
            setFilterMainOptions={setFilterMainOptions}
            setFilterOptions={setFilterOptions}
            selectedMainTypes={selectedMainTypes}
            fakeData={currentData[3]}
          />

          <FilterItem
            title="4 - PEDIDOS"
            type="ORDERS"
            setFilterMainOptions={setFilterMainOptions}
            setFilterOptions={setFilterOptions}
            selectedMainTypes={selectedMainTypes}
            fakeData={currentData[4]}
          />

          <FilterItem
            title="5 - PRODUÇÃO"
            type="PRODUCTION"
            setFilterMainOptions={setFilterMainOptions}
            setFilterOptions={setFilterOptions}
            selectedMainTypes={selectedMainTypes}
            fakeData={currentData[5]}
          />
        </Information>

        <ItemsContainer>
          {fakeData.map((item) => (
            <InformationBox
              data={item}
              selected={selectedType}
              selectedMainTypes={selectedMainTypes}
              class={item.type}
            />
          ))}
        </ItemsContainer>
      </section>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(MenuActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeInfos);
