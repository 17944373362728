export function setEditProducts(data) {
    return {
        type: 'SET_PRODUCTS_DATA',
        data,
    };
  }
  
  export function createProducts(data) {
    return {
        type: 'CREATE_PRODUCTS_DATA',
        data,
    };
  }
  
