import styled from 'styled-components';

export const Th = styled.th`
  width: ${(props) => props.width && props.width};
  @media (max-width: 1000px) {
    width: ${(props) => (props.widthMobile ? props.widthMobile : props.width)};
  }

  .borderLeft {
    border-left: 1px solid #454a67;
  }

  &.subtitle {
    font-size: 12px !important;
  }

  &.bgBlue {
    background-color: #5062f0 !important;
  }
  border-bottom: 2px solid #333541;
`;

export const ThBorder = styled.th`
  width: ${(props) => props.width && props.width};
  border-left: solid 1px #333541;
  @media (max-width: 1000px) {
    width: ${(props) => (props.widthMobile ? props.widthMobile : props.width)};
  }
  &.subtitle {
    font-size: 12px !important;
  }
  &.bgBlue {
    background-color: #5062f0 !important;
  }
  border-bottom: 2px solid #454a67;

  .borderGray {
      border-left: solid 1px #666770 !important;
  }
`;

export const Td = styled.td`
  width: ${(props) => props.width && props.width};
  border-bottom: 1px solid #454a67 !important;
  @media (max-width: 1000px) {
    width: ${(props) => (props.widthMobile ? props.widthMobile : props.width)};
  }

  .borderLeft {
    border-left: 1px solid #333541;
  }
`;

export const TdBorder = styled.td`
  width: ${(props) => props.width && props.width};
  border-left: 1px solid #333541;
  border-bottom: 1px solid #333541 !important;

  @media (max-width: 1000px) {
    width: ${(props) => (props.widthMobile ? props.widthMobile : props.width)};
  }

  &.borderLeftSeparator {
    border-left: 2px solid #333541;
  }
  &.borderLeft {
    border-left: 1px solid #333541;
  }
  &.noBorderBottom {
    border-bottom: none !important;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  @media (max-width: 1200px) {
    display: none;
  }
`;

export const ParagraphMobile = styled.p`
  display: none;
  margin: 0;

  @media (max-width: 1200px) {
    display: contents;
  }
`;

export const ResponsiveContent = styled.div`
  overflow-x: auto;
`;

export const TitleContent = styled.div`
  margin-left: 5px;
`

export const DescriptionContent = styled.div`
  color: #666770;
  margin: 25px 5px;
`

export const ParagraphDescription = styled.p`
  color: #666770;
  margin: 0;
  padding: 0 0 10px 0;
`
