import ReactDOM from 'react-dom';

export const handleDeleteButton = (tableRef, event) => {
  const tableButtonElement = ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('MuiIconButton-root');
  
  const element = event?.target;
  const TRindexElement = element?.closest('tr')?.getAttribute('index');

  if (TRindexElement) {
    if (tableButtonElement.length) {
      tableButtonElement[0].click();
    }
    setTimeout(() => {
      const indexElement = parseFloat(TRindexElement);
      const deleteOutlineIcons = ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('DeleteOutlineIcon');
      const deleteIcon = deleteOutlineIcons[indexElement];
      const tableButtonElement = ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('MuiIconButton-root');
      if(!deleteIcon) {
        return;
      }
      const deleteButton = deleteIcon?.closest('button');

      if(!deleteButton) {
        return;
      }

      deleteButton.click();

      if (tableButtonElement.length) {
        tableButtonElement[0].style.display = 'none';
      }
    }, 500);
  }

}

const activeClickAction = (tableRef) => {
  if (!ReactDOM.findDOMNode(tableRef.current)) return;

  const tableButtonElement = ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('MuiIconButton-root');
  setTimeout(() => {
    if (tableButtonElement.length) {
      tableButtonElement[0].style.display = 'none';
      tableButtonElement[0].click();
    }
  }, 400);

  const editElementIn = ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('editableSvgItem');
  setTimeout(() => {
    if (editElementIn.length) {
      for (let index = 0; index < editElementIn.length; index++) {
        const element = editElementIn[index];
        element.style.display = 'none';
      }
    }
  }, 400);

  const inputFields = ReactDOM.findDOMNode(tableRef.current).getElementsByTagName('input');

  setInterval(removeRequiredInput, 500);

  function removeRequiredInput() {
    

    if(inputFields.length) {
      const inputFieldsList = Object.values(inputFields);

      inputFieldsList.forEach(element => {
        element.removeAttribute('required');
      });
    }
  }

  const ClearButton = ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('ClearButtonSvg');
  setTimeout(() => {
    if (ClearButton.length) {
      const { parentElement: { parentElement } = {} } = ClearButton[0];
      const tableButtonElement = tableRef.current && ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('MuiIconButton-root');

      parentElement.style.display = 'none';

      if (tableButtonElement && tableButtonElement.length) {
        tableButtonElement[0].style.display = 'none';
      }
    }
  }, 400);


  const intervalCallId = setInterval(findTableTRElement, 400);

  function findTableTRElement() {
    if (!ReactDOM.findDOMNode(tableRef.current)) return;
    const tableTRElement = ReactDOM.findDOMNode(tableRef.current).getElementsByClassName('Mui-selected');

    if (tableTRElement.length) {
      for (let index = 0; index < tableTRElement.length; index++) {
        const element = tableTRElement[index];
        element.style.opacity = '1'
        element.style.backgroundColor = 'white';

        const elementButton = element.getElementsByTagName('button');

        if (elementButton.length) {
          for (const button of elementButton) {
            button.classList.remove('Mui-disabled');
            button.disabled = false;
          }
        }
      }
      clearInterval(intervalCallId);
    }
  }
}

export default activeClickAction;
