/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import statusArrowDown from 'images/icons/arrows/statusArrowDown.svg';
import statusArrowTop from 'images/icons/arrows/statusArrowTop.svg';
import CloseIconDialog from 'images/icons/signals/closesAndCancels/closeDialog.svg';
import * as UserActions from 'store/actions/user';
import ValidationLength from 'utils/utils';

function RecipeItemsDiffQuantities({
  popoverOpen,
  anchorEl,
  handleClosePopover,
  onSubmit,
  loading,
  selectedCard
}) {
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const filteredCards = selectedCard?.items?.filter(
    (item) =>
      (item?.manufactureInProgress?.yield || 0) +
        (item?.theoreticalStock || 0) -
        (item?.quantity || 0) <
        0 && item?.type === 'INTERNAL'
  );

  return (
    <Popover
      open={popoverOpen}
      anchorEl={anchorEl}
      onClose={() => {
        handleClosePopover();
      }}
      PaperProps={{
        style: { borderRadius: '0px 4px 4px 4px', maxWidth: '400px' }
      }}
      className="itemsDiffQuantitiesPopover"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <article className="itemsDiffQuantitiesPopoverHeader">
        <div className="dFlex mb10">
          <p className="mb0 bold">ATENÇÃO!</p>

          <img src={selectedCard?.yield > selectedCard?.quantity ? statusArrowTop : statusArrowDown} alt="Projection" />
        </div>

        <span>
          Qtde definida para esta ficha é DESPROPORCIONAL em relação aos insumos
          necessários para a produção
        </span>

        <IconButton
          className="pAbsolute"
          edge="start"
          color="inherit"
          onClick={() => {
            handleClosePopover();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right', right: 0, top: 3 }}
        >
          <img
            src={CloseIconDialog}
            alt="CloseIconNavy"
            style={{ width: 12 }}
          />
        </IconButton>
      </article>

      <div className="cardDescriptionBox">
        <p className="mb0">{ValidationLength(selectedCard?.description, 50)}</p>
      </div>

      <section className="tableInfosBox">
        <div className="dFlex alignCenter justifyEnd">
          <p className="mb0">Qtde definida para esta produção:</p>
          
          <span className="bold pr12" style={{ color: '#F53D4C' }}>
            {selectedCard?.yield?.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })}{' '}
            {selectedCard?.un?.abbreviation}
          </span>
        </div>

        <div className="dFlex alignCenter justifyEnd">
          <p className="mb0">Consumo previsto total:</p>
          
          <span className="bold pr12" style={{ color: '#000' }}>
            {selectedCard?.quantity?.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })}{' '}
            {selectedCard?.un?.abbreviation}
          </span>
        </div>

        <table className="desktop">
          <thead>
            <tr className="headerTitle">
              <th width="44%" className="subtitle">
                FICHAS RELACIONADAS
              </th>

              <th width="21%" className="subtitle right">
                ESTOQUE / PRODUÇÃO
              </th>

              <th width="18%" className="subtitle right">
                QTDE NECESSÁRIA
              </th>

              <th width="17%" className="subtitle right">
                DIFERENÇA
              </th>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th colSpan="4" className="m10 textAlignCenter">
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {filteredCards?.length ? (
              filteredCards.map((item, itemIndex) => {
                const isLastElement =
                  itemIndex === filteredCards.length - 1;
                const unit = item?.un?.abbreviation || '';
                const stockProduction =
                  item?.theoreticalStock +
                  (item?.manufactureInProgress?.yield || 0);

                return (
                  <>
                    <tr className="lineBorder">
                      <td className="description pl12">
                        {ValidationLength(item?.item, 30)}
                      </td>

                      <td className="description right pr0">
                        <Tooltip
                          title={
                            <div
                              className="dFlex flexColumn"
                              style={{ padding: 2 }}
                            >
                              <span
                                className="bold"
                                style={{ marginBottom: 2 }}
                              >
                                Estoque:{' '}
                                {item?.theoreticalStock?.toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 3
                                  }
                                )}{' '}
                                {unit}
                              </span>

                              <span className="bold">
                                Em Produção:{' '}
                                {item?.manufactureInProgress?.yield?.toLocaleString(
                                  'pt-br',
                                  {
                                    minimumFractionDigits: 3
                                  }
                                )}{' '}
                                {unit}
                              </span>
                            </div>
                          }
                        >
                          <span>
                            {stockProduction?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}{' '}
                            {unit}
                          </span>
                        </Tooltip>
                      </td>

                      <td className="description right pr0 bold">
                        {item?.quantity?.toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })}{' '}
                        {unit}
                      </td>

                      <td
                        className="description right pr12"
                        style={{ color: '#F53D4C' }}
                      >
                        {(
                          stockProduction - (item?.quantity || 0)
                        )?.toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })}{' '}
                        {unit}
                      </td>
                    </tr>

                    {!isLastElement && (
                      <tr>
                        <td colSpan="4" className="pr0 pl0 pt0 pb0">
                          <hr
                            className="titleLineBorder m0"
                            style={{
                              height: '0px',
                              backgroundColor: '#D0D3E2'
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan="4" className="description textAlignCenter">
                  <Typography className="m5 bold">
                    Não há items a serem exibidos.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>

      <div className="dFlex alignStart justifyStart flexColumn">
        <Grid container className="dFlex justifyEnd m0">
          <Button
            className="defaultButton backButton mt10 mr10 mb10"
            design="contained"
            onClick={() => {
              handleClosePopover();
            }}
            label="Cancelar"
          />

          <Button
            className="defaultButton submitButton mt10 mr10 mb10"
            design="contained"
            onClick={(e) => {
              onSubmit(e);
            }}
            label={loading ? 'Processando...' : 'Confirmar'}
          />
        </Grid>
      </div>

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => {
          message.status !== 'error' &&
            setMessage({ ...message, description: '', status: '' });
        }}
      >
        <Alert
          onClose={() => {
            message.status !== 'error' &&
              setMessage({ ...message, description: '', status: '' });
          }}
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </Popover>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipeItemsDiffQuantities);
