/* eslint-disable no-underscore-dangle */
import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  products: [],
  selectedPages: []
};

export const quotationSelectProducts = createSlice({
  name: 'quotationSelect',
  initialState,
  reducers: {
    handleProduct: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { products } = currState;
      const { item } = payload;

      const isSelected = products.find((element) => element.id === item.id);

      if (isSelected) {
        const newSelectedItems = products.filter(
          (element) => element.id !== item.id
        );

        state.products = newSelectedItems;
      } else {
        state.products = [...products, item];
      }
    },
    selectManyProducts: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { products } = currState;
      const { selectedProducts } = payload;

      const isSelected = products.filter((element) =>
        selectedProducts?.map((el) => el.id).includes(element.id)
      );

      if (isSelected?.length) {
        state.products = [...new Set([products, selectedProducts].flat())];
      } else {
        state.products = [...products, selectedProducts].flat();
      }
    },
    selectAllProducts: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { products, selectedPages } = currState;
      const { selectedProducts, selectAll, currentPage } = payload;

      const uniqueProducts = selectedProducts?.filter(
        (el) => !products?.map((item) => item?.id).includes(el?.id)
      );

      if (selectAll) {
        state.products = [...new Set([products, uniqueProducts].flat())];
        state.selectedPages = [...new Set([...selectedPages, currentPage])];
      } else {
        state.products = products?.filter(
          (element) =>
            !selectedProducts?.map((el) => el.id).includes(element.id)
        );

        state.selectedPages = selectedPages?.filter(
          (element) => element !== currentPage
        );
      }
    },
    updateProduct: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { products } = currState;
      const { item } = payload;

      const currentProduct = products.find(
        (element) => element?.id === item?._id
      );

      if (currentProduct) {
        const newSelectedItems = products.filter(
          (element) => element?.id !== item?._id
        );

        state.products = [...newSelectedItems, { ...currentProduct, item }];
      }
    },
    clearAll: (state) => {
      state.products = [];
      state.selectedPages = [];
    }
  }
});

export const {
  handleProduct,
  clearAll,
  selectManyProducts,
  selectAllProducts,
  updateProduct
} = quotationSelectProducts.actions;

export default quotationSelectProducts.reducer;
