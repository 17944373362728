import React from 'react';

function ItemsPerPage({ numberOfElements, size }) {
  return (
    <div className="itemsPerPage">
      Exibindo resultados {numberOfElements} de {size}
    </div>
  );
}

export default ItemsPerPage;
