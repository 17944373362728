import * as csvtojson from 'csvtojson'; 

const reader = new FileReader();

export const checkCSVFile = (file) => {
    const { name = '' } = file;
    const extention = name.match(/\.[0-9a-z]+$/i);
    if(extention[0] !== '.csv') return false; 
    return true;
}

const checkCollunmsCSVFile = (jsonObj) => {
    const [ firstOcurrence ] = jsonObj;
    const firstOcurrenceList = Object.values(firstOcurrence);
    if(
        !firstOcurrenceList[0] || 
        !firstOcurrenceList[1] ||
        !firstOcurrenceList[5]
    ) {
        return false
    }

    return true;
}

const handleCSVFile = (file, handleJSONConverter, fileName) => {
    if(!file) return;
    const newConverter = csvtojson({
        trim:true,
        delimiter: [",",";","$","|"]
    });

    const CSVStringToJson = (CSVString) => {
        (async () => {
            if(CSVString.length > 23579703) {
                handleJSONConverter(false, 'Arquivo maior que o limite esperado');
                return;
            }
            const jsonObj = await newConverter.fromString(CSVString);
            const isValidCollumns = checkCollunmsCSVFile(jsonObj);
            if(!isValidCollumns){
                handleJSONConverter(false, 'Formato de colunas inválido');
                return;
            } 
            handleJSONConverter(jsonObj, '', fileName);
        })();
    }
    reader.onload = (event) => {
        CSVStringToJson(event.target.result);
    };
    reader.readAsText(file);
}

export default handleCSVFile;
