import styled from "styled-components";

export const TableData = styled.table`
  box-shadow: 0px 0px 8px rgba(4,6,26,0.25);
        th {
          background: #313347;
          position: sticky;
          top: -6px;
          z-index: 99;
          font-size: 9px;
          padding: 7px;
          color: #FFF;

          &:first-of-type {
            overflow: hidden;
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            overflow: hidden;
            border-top-right-radius: 4px;
          }

          &:first-of-type,
          &:last-of-type {
            padding: 0 12px;
          }
        }

        .subtitle-modal{
          font-size: 12px;
        }
`;

export const Tbody = styled.tbody`
    tr {
        background-color: #fff;
        height: 0;
    }

    td {
      font-size: 12px;
    }

    hr {
        margin: 0 !important;
    }

    .tr-hr {
        height: 0;
    }
        
    .subtitle-modal{
      font-size: 12px;
      padding: 5px;
    }

`;

export const OffSpan = styled.span`
    font-style: italic !important;
    font-size: 12px;
    color: #666770;
`;