import styled from 'styled-components'

import banner from 'images/backgrounds/homeBackground.png'

export const Header = styled.div`
  width: 100%;
  background-color: rgba(25, 42, 129);
  background-image: url(${banner});
  align-items: center;

  .HeaderData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 20px;

    .pageTitle {
      p {
        color: #FFFFFF;
        margin-bottom: 0;
        font-size: 12px;
      }
    }

    .restaurantStep {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      span,
      p {
        font-family: 'Lato';
      }
      
      span {
        color: #FFFFFF;
        font-size: 12px;
        margin-bottom: 5px;
        display: block;
      }

      p {
        background: #FFFFFF;
        color: #5062F0;
        padding: 2px 4px;
        margin-bottom: 0;
        font-size: 12px;
        text-align: center;
        display: block;
        margin: 0 auto;
      }
    }
    
    .ColumnGrid {
      display: flex;
      margin-bottom: 10px;

      small {
        font-family: 'Lato';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
      }

      td {
        font-style: normal;
        font-weight: bold;
        font-size: 24px !important;
        line-height: 44px;
        color: #FFFFFF;
        line-height: .8;
        margin-left: 5px;
        display: block;
      }
    }

    .sideGrid {
      display: flex;
      flex-direction: row;
      width: 40%;
      align-items: center;
      justify-content: space-between;

      .btnGrid {
        width: auto;
        display: flex;
        align-items: flex-end;

        span {
          padding: 4px 8px;
        }
      }

      div {
        display: flex;
        flex-direction: column;

        span:nth-child(2) {
          width: 50px;
          margin-left: 63%;
        }

        span {
          background: #5062F0;
          align-items: center;
          justify-content: center;
          justify-self: end;
          display: flex;
          margin-top: 10px;
          border-radius: 8px;
          height: 23px;
          color: #FFFFFF;
          font-family: 'Lato';
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          cursor: pointer;
          transition: background 0.2s;

          &:hover {
            background: #6d7ced;
          }
        }
      }
    }

    .ProgressBarBox {
      width: 70%;
    }
    
    .ProgressBar {
      display: flex;
      flex-direction: row !important;

      &:first-of-type {
        margin-bottom: 10px;
      }

      .section1, 
      .section2, 
      .section3, 
      .section4 {
        width: 48%;
        display: flex;
        align-items: center;
        flex-direction: row !important;
        background: #996600;
        border-radius: 20px 0px 0px 20px;
        border-right: 1px solid #FFFFFF;
        padding: 7px 15px;
        height: 25px;

        img {
          width: 24px;
          height: 18px;   
          color: #FFFFFF; 
          margin-right: 10px;
        }
        
        strong {
          font-family: 'Lato';
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;
          font-feature-settings: 'tnum' on, 'lnum' on;
          color: #FFFFFF;
        }
      }

      .section2, 
      .section4 {
        border-radius: 0px 20px 20px 0px;
        border-right: none;
        background: #FCF5E3;
        width: 52%;
        padding: 0;

        .progressPercentage {
          border-radius: 0px 20px 20px 0px;
          justify-content: center;
          height: 100%;
          display: flex;
          align-items: flex-end;
        }

        strong {
          font-weight: normal;
          font-size: 16px;
          padding: 7px 15px;
        }
      }

      .section2 {
        .progressPercentage {
          background: #E6A117;
        }

        .percentageColored {
          color: #E5A117;
        }
      }

      .section3 {
        background: #009934;
      }

      .section4 {
        .progressPercentage {
          background: #3DCC6E;
        }

        .percentageColored {
          color: #3DCC6E;
        }
      }
    }
  }
`

export const Information = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  justify-content: space-between;

  .filterItemBox {
    margin-left: 8px;
    border: 1px solid rgba(80, 98, 240, 0.75);
    border-radius: 4px;
    justify-content: space-between;
    transition: all 0.3s;

    &:first-of-type {
      margin-left: 0;
    }

    &.active {
      background: #FFFFFF;
      transition: all 0.3s;

      .filterTitle {
        font-weight: bold;
      }
    }

    .filterTitle,
    .defaultInfos,
    .borderSeparator {
      cursor: pointer;
    }

    .filterTitle {
      padding: 12px 12px 0 12px;
      color: rgba(80, 98, 240, 0.75);
    }

    .borderSeparator {
      background: rgba(80, 98, 240, 0.25);
      height: 1px;
      margin: 8px 12px;
    }

    .filterActions {
      padding: 0 12px;
    }

    .defaultInfos {
      display: flex;
      justify-content: space-between;
      padding: 0 12px 12px 12px;

      .defaultInfoBox {
        display: flex;

        .infoIconBox,
        .infoValue {
          display: flex;
          align-items: center;
        }

        .infoIconBox {
          background: #5062F0;
          border-radius: 20px 0px 0px 20px;
          padding: 5px 8px;

          img {
            height: 10px;
          }
        }

        .infoValue {
          background: rgba(80, 98, 240, 0.75);
          border-radius: 0px 20px 20px 0px;
          padding: 4px 10px 4px 4px;

          p {
            color: #FFF;
            margin-bottom: 0;
            font-size: 12px;
          }
        }

        &:last-of-type {
          margin-left: 5px;
          
          .infoIconBox {
            img {
              height: 15px;
            }
          }
        }
      }
    }
  }

  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #313347;
  }

  .operational, 
  .manager,
  .inactive {
    padding: 4px 8px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;

    /* identical to box height, or 133% */
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    transition: background 0.2s;

    /* Neutro/0 */
    color: #FFFFFF;

    &:focus {
      outline: none;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  .inactive {
    background: transparent;
    border: solid 1px #773795;
    color: #773795;
    
    &:first-of-type {
      border: solid 1px #2F8590;
      color: #2F8590;
    }
  }

  .manager {
    background: #773795;
    border: solid 1px #773795;

    &:hover {
      background: #a86cc4;
    }
  }

  .operational {
    background: #2F8590;
    border: solid 1px #2F8590;
    
    &:hover {
      background: #499ca6;
    }
  }
`

export const ItemsContainer = styled.div`
  width: auto;
  margin: 18px 20px 0;
  display: flow-root;
`
