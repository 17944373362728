import React from 'react';

import { Dialog } from '@material-ui/core';

import makeDelete from 'services/useFetch/makeDelete';

import { DeleteTagContainer, DeleteTagSubTitle, DeleteTagTitle } from './style';

function DeleteTagDialog({
  openDeleteTagDialog,
  setOpenDeleteTagDialog,
  skusLength,
  tag,
  getTagListing
}) {
  const handleClose = () => {
    setOpenDeleteTagDialog(false);
  };

  const handleDeleteTag = () => {
    const handleResponse = (status, response) => {
      if (!status) {
        console.log(response);
        return;
      }
      getTagListing();
      handleClose();
    };

    makeDelete('tagCreation', [], handleResponse, `/${tag?.id}`);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={openDeleteTagDialog}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DeleteTagContainer>
        <DeleteTagTitle>Deseja realmente excluir essa tag?</DeleteTagTitle>
        <DeleteTagSubTitle>
          Essa tag está associada a {skusLength} SKUs.
        </DeleteTagSubTitle>
        <div>
          <button onClick={handleClose}>Voltar</button>
          <button onClick={handleDeleteTag}>Excluir</button>
        </div>
      </DeleteTagContainer>
    </Dialog>
  );
}

export default DeleteTagDialog;
