import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Typography } from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makeGet from 'services/useFetch/makeGet';
import { shortenString } from 'utils/shortenString';

import QuantityCard from './QuantityCard';
import { QDHead } from './styles';

function QuantityAndDurationDialog({
  openQuantityAndDurationDialog,
  setOpenQuantityAndDurationDialog,
  restaurantsIds, 
  productsIds, 
}) {
  const [productDescription, setProductDescription] = useState({})
  const [restaurants, setRestaurants] = useState([])
  const [isEmpty, setIsEmpty] = useState(false)
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState([
    {
      param: 'originId',
      value: restaurantsIds,
      urlParam: `originId=${restaurantsIds}`
    },
    {
      param: 'productId',
      value: productsIds,
      urlParam: `productId=${productsIds}`
    },
  ]);

  const handleItems = (response) =>  {
    if (response?.restaurants?.length == 0) {
      setLoading(false)
      setIsEmpty(true)
      return 
    }
    setProductDescription(response?.productDescription)
    setRestaurants(response?.restaurants)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    makeGet('modalStockPerStore', filters, handleItems, null);
  }, [])

  const getColorStatus = (status) => {
    if (status) {
      return {
        backgroundColor: '#F7DFDF',
        color: '#E22121'
      }
    }
    return {
      backgroundColor: '#DFF7E8',
      color: '#3DCC6E'
    }
  }
  
  
  return (
    <Dialog
      className="defaultModal mobileModal"
      fullWidth
      maxWidth="sm"
      open={openQuantityAndDurationDialog}
      aria-labelledby="draggable-dialog-title"
     >
     <DialogTitle className="modalHeader navyBlue" style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'move', fontFamily: 'Lato' }} id="draggable-dialog-title">
       <Typography style={{fontWeight: "400"}} >
          {shortenString(`ESTOQUE POR LOJA - ${productDescription}`, 30)}
       </Typography>

       <IconButton edge="start" color="inherit"
       onClick={() => setOpenQuantityAndDurationDialog(false)}
       >
        <img src={CloseIconNavy} style={{ width: "8px" }} alt="close" />
       </IconButton>
     </DialogTitle>
     {loading && <div sytle={{ margin: "10px" }}><LinearProgress style={{ height: "7px" }} variant="query" /></div>}
     <DialogContent className="modalContent p0" style={{backgroundColor: "white"}} >
     {
      isEmpty ? 
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
        <Typography >
          Quantidade e Duração não disponíveis 
        </Typography>  
      </div>
      :
      <>
        <QDHead>
          <div>
            <p>LOJA</p>
            <div>
              <p style={{marginRight: "38px"}} >QTDE</p>
              <p style={{marginRight: "18px"}} >DURAÇÃO</p>
            </div>
          </div>
        </QDHead>

        {restaurants?.map(res => (
          <QuantityCard 
          getColorStatus={getColorStatus}
          restaurant={res}
          />
        ))}
      </>
     }
     
     </DialogContent>
     </Dialog>
  )
}

export default QuantityAndDurationDialog;
