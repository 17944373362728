/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format } from 'date-fns';

import AutoCompleteField from 'components/PageTitleAndFilter/AutoCompleteField/AutoCompleteField';
import makeGet from 'services/useFetch/makeGet';
import makePost from 'services/useFetch/makePost';
import makePut from 'services/useFetch/makePut';
import Environment from 'utils/environments';

import { MobileDialogContent } from './style';

function DialogCompleteNoteMobile({
  openModalOrder,
  handleCloseModal,
  PaperComponent,
  submitted,
  setSubmitted,
  errMsg,
  handleClose,
  order,
  receiverData,
  isCompleteNote,
  currentQuantity,
  isDivergency,
  setIsDivergency,
  getData,
  handleChangeStatusCard,
  orders,
  setOrders,
  forceUpdate
}) {
  const [IsNewOrder, setIsNewOrder] = useState(false);
  const [selectedReceiveDate, setSelectedReceiveDate] = useState(Date.now());
  const [providersSku, setProvidersSku] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [contactTel, setContactTel] = useState('');
  const [skuSelect, setSkuSelect] = useState('');
  const [providerId, setProviderId] = useState('');
  const [newOrderDate, setNewOrderDate] = useState(new Date());

  const submitNewOrder = (data) => {
    if (contactTel !== '') {
      data.orderItems.map((order) => {
        if (order.sku.id === skuSelect.id) {
          data.orderItems = [
            {
              unitsMeasurements: order.unitsMeasurements,
              sku: order.sku,
              costs: order.costs
            }
          ];
        }
      });

      function handleResponseNewOrder(status, response) {
        console.log(status);
        console.log(response);
      }

      const body = {
        user: receiverData.user,
        status: 'IN PROGRESS',
        statusPaymentDocument: 'WAITING',
        quotation: false,
        orderDate: format(new Date(), 'dd/MM/yyyy'),
        deliveryDate: format(newOrderDate, 'dd/MM/yyyy'),
        providerId: inputValue.providerId,
        contact:
          `55${ 
          contactTel
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/\-/g, '')
            .replace(/\ /g, '')}`,
        type: 'M',
        ordersList: []
      };

      makePost('ordersEndPoint', body, handleResponseNewOrder);
    } else {
      //
    }
  };

  const putEditOrders = (newStatus) => {
    const handleResponser = (stts, response) => {
      getData();
      handleClose();
    };

    const dataComplete = {
      status: newStatus,
      user: receiverData.user
    };

    const divergenceStatus = '';

    const data = {
      adjustOrder:
        !!(divergenceStatus && divergenceStatus == 'adjustOrder'),
      averageValue: order?.sku?.averageValue,
      description: order?.sku?.description,
      id: order?.sku?.id,
      itemId: order._id,
      lastValue: order?.sku?.lastValue,
      newOrder:
        !!(divergenceStatus && divergenceStatus == 'newOrder'),
      receivedPrice: currentQuantity?.package,
      receivedPriceTotal:
        parseFloat(currentQuantity?.quantity ? currentQuantity?.quantity : 0) *
        parseFloat(currentQuantity?.package ? currentQuantity?.package : 0),
      receivedQuantity: currentQuantity?.quantity,
      status: newStatus,
      stock: order?.sku?.stock,
      user: receiverData.user
    };

    const paramUrlCompleteNote = `${receiverData._id}/status/skus${
      newStatus === 'RECEIVED' ||
      newStatus === 'NOT RECEIVED' ||
      newStatus === 'RETURNED'
        ? `?receiveDate=${format(selectedReceiveDate, 'yyyy-MM-dd')}`
        : ''
    }`;
    const paramUrl = `${receiverData._id}/skus${
      newStatus === 'RECEIVED' ||
      newStatus === 'NOT RECEIVED' ||
      newStatus === 'RETURNED'
        ? `?receiveDate=${format(selectedReceiveDate, 'yyyy-MM-dd')}`
        : ''
    }`;

    makePut(
      'ordersEndPoint',
      isCompleteNote ? dataComplete : data,
      handleResponser,
      isCompleteNote ? paramUrlCompleteNote : paramUrl
    );
  };

  const handleReceiveDateChange = (value) => {
    setSelectedReceiveDate(value);
  };

  const handleNewOrderDateChange = (value) => {
    setNewOrderDate(value);
  };

  const getAutoCompleteOptions = () => {
    const handleResponseProviders = (response) => {
      const { content = [] } = response;
      const filteredContent = content.map((n) => ({ ...n, description: n.fantasyName }));
      setProvidersSku(filteredContent);
    };
    makeGet('providersQuotes', [], handleResponseProviders);
  };

  useEffect(() => {
    const id = `/${providerId}`;

    const handleResponseProviders = (response) => {
      const { contacts = [] } = response;
      const filteredContacts = contacts.map((n) => ({ ...n, name: n.fullName, contact: n.telephoneNumber }));
      setContacts(filteredContacts);
    };
    makeGet('providersQuotes', [], handleResponseProviders, id);
  }, [providerId]);

  const getSelectedOptions = (filterName, value, data) => {
    setProviderId(data.providerId);
  };

  const getContactSelectedOptions = (filterName, value, data) => {
    // console.log(data)
  };

  return (
    <Dialog
        className="defaultModal mobileModal"
        fullWidth
        maxWidth="sm"
        open={openModalOrder}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          className="modalHeader navyBlue bold cursorMove textAlignCenter"
          id="draggable-dialog-title"
        >
          <Typography>
            {isDivergency
              ? 'Nota com divergência'
              : isCompleteNote
              ? 'Nota Completa'
              : order?.sku?.description}
          </Typography>

          <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          className="modalContent p0"
          style={{ backgroundColor: 'white' }}
        >
          {submitted && <LinearProgress variant="query" />}

          <MobileDialogContent style={{ height: '100%' }}>
            <div className="MobileDialogDate">
              <p>Data de Recebimento</p>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  style={{ width: '35%', marginLeft: '10px' }}
                  format="dd/MM"
                  size="small"
                  className="dateSmall"
                  inputVariant="outlined"
                  autoOk
                  value={selectedReceiveDate}
                  onChange={handleReceiveDateChange}
                  views={['year', 'month', 'date']}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            
            <div>
              {isDivergency ? (
                <button>Ajustar Pedido</button>
              ) : (
                <div className="MobileButtons">
                    <div>
                      <Button
                        variant="contained"
                        style={{
                          padding: '0px',
                          boxShadow: 'none',
                          background: 'transparent',
                          color: '#F2B90D',
                          borderRadius: '8px',
                          textTransform: 'initial',
                          height: '43px',
                          border: '1px solid #F2B90D',
                          width: '136px',
                          marginRight: '8px',
                          fontWeight: '700'
                        }}
                        disabled={submitted}
                        onClick={() => {
                          setSubmitted(true);
                          putEditOrders('NOT RECEIVED');
                        }}
                      >
                        Não recebido
                      </Button>

                      <Button
                        variant="contained"
                        style={{
                          padding: '0',
                          boxShadow: 'none',
                          background: 'transparent',
                          color: '#F53D4C',
                          borderRadius: '8px',
                          border: 'solid 1px #F53D4C',
                          textTransform: 'initial',
                          fontFamily: 'Lato',
                          height: '43px',
                          width: '136px',
                          fontWeight: '700'
                        }}
                        disabled={submitted}
                        onClick={() => {
                          setSubmitted(true);
                          putEditOrders('RETURNED');
                        }}
                      >
                        Devolver
                      </Button>
                    </div>

                    <Button
                      variant="contained"
                      style={{
                        boxShadow: 'none',
                        background: submitted ? '#AFBCF3' : '#5062F0',
                        color: '#FFFFFF',
                        borderRadius: '8px',
                        textTransform: 'initial',
                        fontFamily: 'Lato',
                        height: '40px',
                        marginBottom: '20px'
                      }}
                      fullWidth
                      disabled={submitted}
                      onClick={() => {
                        setSubmitted(true);
                        putEditOrders('RECEIVED');
                      }}
                    >
                      Receber
                    </Button>
                  </div>
              )}

              {IsNewOrder ? (
                <div className="modalReceiverForm">
                  <div>
                    <AutoCompleteField
                      style={{
                        fontSize: '12px',
                        lineHeight: '16px',
                        width: '100%'
                      }}
                      options={providersSku}
                      getFilterhandle={getAutoCompleteOptions}
                      renderInput="Selecione"
                      inputSizes={{
                        small: 280
                      }}
                      size="small"
                      handleFilter={getSelectedOptions}
                      filterName="justification"
                      placeHolder="Fornecedores"
                    />
                  </div>

                  <div style={{ margin: '10px 0px' }}>
                    <AutoCompleteField
                      style={{
                        fontSize: '12px',
                        lineHeight: '16px',
                        width: '100%'
                      }}
                      options={contacts}
                      handleFilter={getContactSelectedOptions}
                      renderInput="Selecione"
                      inputSizes={{
                        small: 280
                      }}
                      size="small"
                      filterName="justification"
                      disable={contacts.length == 0}
                      placeHolder="Contatos"
                    />
                  </div>

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      size="small"
                      className="dateSmall"
                      inputVariant="outlined"
                      views={['year', 'month', 'date']}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      value={newOrderDate}
                      onChange={handleNewOrderDateChange}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              ) : (
                <div />
              )}
            </div>
            
            {IsNewOrder ? (
              <Button
                className="receive"
                variant="contained"
                fullWidth
                onClick={submitNewOrder}
                style={{
                  background: '#5062F0',
                  color: '#FFFFFF',
                  borderRadius: '8px',
                  textTransform: 'initial',
                  fontFamily: 'Lato',
                  fontWeight: '700',
                  height: '40px',
                  margin: '20px 0',
                  outline: 'none'
                }}
              >
                Salvar
              </Button>
            ) : (
              ''
            )}
          </MobileDialogContent>
        </DialogContent>
      </Dialog>
  );
}

export default DialogCompleteNoteMobile;
