export const calculateDifference = (realStock, theoreticalStock) => {
    const difference = realStock - theoreticalStock

    if (theoreticalStock === 0 && realStock !== 0) {
        return {
            difference,
            percentage: realStock > 0 ? 100 : -100
        }
    }

    const percentage = (difference / theoreticalStock) * 100

    return {
        difference,
        percentage
    }
}

const canSetDifference = ({ maxPercentage, currentPercentage, historicalPercentage }) => {
    const minPercentage = maxPercentage * -1
    const currentIsPositive = Boolean(Math.sign(currentPercentage) + 1)
    const historicalIsPositive = Boolean(Math.sign(historicalPercentage) + 1)

    if ((currentIsPositive && historicalIsPositive) || (!currentIsPositive && !historicalIsPositive)) {
        return false
    } else {
        if ((currentPercentage > maxPercentage || currentPercentage < minPercentage) &&
            (historicalPercentage > maxPercentage || historicalPercentage < minPercentage)) {
            return true
        }
        return false
    }
}

export const canSetDiffInCatalog = ({ maxPercentage, currentRealStock, currentTheoreticalStock, historicalRealStock, historicalTheoreticalStock }) => {

    const diffCurrentStock = calculateDifference(currentRealStock, currentTheoreticalStock)
    const diffHistoricalStock = calculateDifference(historicalRealStock, historicalTheoreticalStock)

    return canSetDifference({
        maxPercentage: maxPercentage,
        currentPercentage: diffCurrentStock.percentage,
        historicalPercentage: diffHistoricalStock.percentage
    })
}