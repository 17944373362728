import styled, {css} from 'styled-components';

export const OtherStocksWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1290;
    left: 0;
`;

export const Content = styled.div`
    width: 270px;
    box-shadow: 0px 2px 6px 2px rgb(1 3 17 / 10%);
    border-radius: 8px;
    background-color: white;
    
    ${({ItensListDialog}) => ItensListDialog && css`
        width: 304px;
        position: absolute;
        top: 30vh;
    `}
`;

export const ContentHead = styled.div`
    width: 100%;
    color: white;
    background-color: #1F2445;
    padding: 8px 12px 8px 12px;
    font-size: 12px;
    border-radius: 8px 8px 0 0;
    ${({displayFlexBetween}) => displayFlexBetween && 
        css`
            display: flex;
            justify-content: space-between;
        `
    };
`;

export const ContentList = styled.div`
    margin-top: 9px;
`;

export const ContentItem = styled.div`
    padding: 8px 12px 8px 12px;
    color: #1F2445;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
`;
