import React, { useMemo } from 'react';

import { LinearProgress } from '@material-ui/core';
import matchSorter from 'match-sorter';
import {
  useTable,
  useExpanded,
  useFilters,
  useGlobalFilter
} from 'react-table';

const fuzzyTextFilterFn = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
fuzzyTextFilterFn.autoRemove = (val) => !val;

function CmvTable({ columns: userColumns, data, className, loading }) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        })
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: userColumns,
        data,
        getSubRows: (row) =>
          row.children && row.children.length ? row.children : row.products,
        filterTypes,
        autoResetExpanded: false
      },
      useFilters,
      useGlobalFilter,
      useExpanded
    );

  return (
    <div className={`${className && className}`}>
      <table {...getTableProps()} border="0">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  style={{
                    width:
                      column.Header === 'GRUPO, SUBGRUPO, RESTAURANTE'
                        ? '21%'
                        : ''
                  }}
                  {...column.getHeaderProps([
                    {
                      className: `${column.className} borderBottomBlack`
                    }
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {loading && (
          <tr className="linearProgressBar">
            <th colSpan="12" className="m10 textAlignCenter">
              <LinearProgress id="loading" variant="query" />
            </th>
          </tr>
        )}

        <tbody {...getTableBodyProps()}>
          {rows?.length ? (
            rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()} className={`rowDepth${row.depth}`}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps([
                          {
                            className: `${cell.column.className} ${
                              row?.canExpand && 'borderTopBlack'
                            }`
                          }
                        ])}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
            )
          ) : (
            <tr>
              <th colSpan="12" className="m10 textAlignCenter">
                Nenhum registro encontrado.
              </th>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CmvTable;
