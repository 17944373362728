import styled, { css } from 'styled-components';

export const Label = styled.p`
    display: flex;
    gap: 4px;
    font-family: Lato;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${({ color }) => color && '#0F1225'};
    font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
    margin: 0 0 4px;
`;

export const InformationFieldWrapper = styled.div`
    background-color: ${({ color }) => color ? color : '#FFFFFF'}
    ${({ underline }) => underline && css`
        border-bottom: 1px solid;
    `};
`;
