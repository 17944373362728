/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import useFetch from 'services/useFetch';
import Environment from 'utils/environments';

function DialogCards({
  fullScreen,
  maxWidth,
  open,
  onCloseFunc,
  PaperComponent,
  requestProps,
  selectedCard,
  setSelectedCard,
  loading,
  setRequestStorages,
  requestStorages,
  setOpenModalMenu
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { skuId, originId, productDescription, items } = requestProps;
  const [skuAssociation, setSkuAssociation] = useState([]);
  const [filters] = useState([
    {
      param: 'originId',
      value: originId,
      urlParam: `originId=${originId}`
    }
  ]);

  const { __, data } = useFetch(
    'skuCards', filters, false, `${skuId}/cards/`
  );

  useEffect(() => {
    if (!data.length && !items?.length) {
      setSkuAssociation([]);
      return;
    }

    setSkuAssociation(items?.length ? items : data);
  }, [data]);

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      onClose={() => {
        onCloseFunc();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal"
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>{productDescription || `Fichas`}</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            onCloseFunc();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <table>
          <thead>
            <tr>
              <th width="500" className="subtitle">
                DESCRIÇÃO
              </th>
            </tr>

            <tr>
              <td colSpan="5" className="p0">
                <hr
                  className="titleLineBorder"
                  style={{ margin: '0', height: '2px' }}
                />
              </td>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th colSpan="5" className="textAlignCenter">
                  <LinearProgress
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {skuAssociation.map((item) => (
              <>
                <tr>
                  <td className="description">
                    <div
                      className="cursorPointer lightBlue"
                      onClick={() => {
                        getOptions(
                          `${environments.catalog}/cards/${
                            items?.length ? (item?.cardsId || item?.id) : item?.id
                          }`,
                          'cards',
                          selectedCard,
                          setSelectedCard,
                          originId,
                          null,
                          null,
                          null,
                          true
                        );
                        setRequestStorages(!requestStorages)
                        setOpenModalMenu(true)
                      }}
                    >
                      {item?.description || item?.cardsOrigin?.description}
                    </div>
                  </td>
                </tr>

                <tr>
                  <td colSpan="5" className="p0">
                    <hr
                      className="titleLineBorder"
                      style={{ margin: '0', height: '2px' }}
                    />
                  </td>
                </tr>
              </>
            ))}

            {!skuAssociation?.length && (
              <tr>
                <td colSpan="5" className="description center">
                  <Typography style={{ margin: '5px', textAlign: 'center' }}>
                    Não há fichas associadas.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default DialogCards;
