import styled from 'styled-components';

export const InputFile = styled.input`    
    width: 100%;
    opacity: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
`;
