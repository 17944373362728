/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';

import PaperComponent from 'components/PaperComponent';
import SinglePagePDFViewer from 'components/PdfSinglePage';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

function PdfViewer({ modalSettings, setModalSettings, pdfFile }) {
  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'pdfViewer').fullScreen
      }
      open={modalSettings.find((modal) => modal.name === 'pdfViewer').open}
      onClose={() => {
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'pdfViewer' ? { ...modal, open: false } : modal
          )
        );
      }}
      className="defaultModal pdfViewerDialog customZIndex"
      PaperComponent={PaperComponent}
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-image-cropper"
        fullWidth
      >
        <Typography>{pdfFile?.name || 'PDF'}</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'pdfViewer' ? { ...modal, open: false } : modal
              )
            );
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent bgWhite dFlex flexColumn justifyBetween">
        <SinglePagePDFViewer pdf={pdfFile?.url} />
      </DialogContent>
    </Dialog>
  );
}

export default PdfViewer;
