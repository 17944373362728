import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';

import PaperComponent from 'components/PaperComponent';
import Smalltable from 'components/SmallTable';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

import handleFetchData from './utils';

function RestaurantsDialogs({
  openModal,
  handleClose,
  restaurants,
  descriptionSku
}) {
  const { modalRestaurants } = openModal;

  const [tableData, setTableData] = useState({
    header: [
      { name: 'RESTAURANTE', size: 'auto', paddingLeft: '16px' },
      { name: 'UNITÁRIO R$', size: 'auto' },
      { name: 'QTDE NFS', size: 'auto' },
      { name: 'MÉDIO R$', size: 'auto' },
      { name: 'ÚLTIMO R$', size: 'auto' },
      { name: 'TOTAL R$', size: 'auto' }
    ],
    collunms: []
  });

  const handleTitle = ({ description }) => {
    let title = `Restaurantes`;

    if (description) {
      title += ` - ${description}`;
    }

    return title;
  };

  useEffect(() => {
    handleFetchData(restaurants, tableData, setTableData);
  }, [restaurants]);

  return (
    <Dialog
      open={modalRestaurants}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal skuRestaurantsDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        style={{
          borderRadius: '4px 4px 0 0'
        }}
        id="draggable-dialog-title"
      >
        <Typography>{handleTitle({ description: descriptionSku })}</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <Smalltable tableData={tableData} grayHeader />
      </DialogContent>
    </Dialog>
  );
}

export default RestaurantsDialogs;
