import styled from 'styled-components';

export const CircularProgressWrapp = styled.div`
  width: 140px;
  height: 115px;
  border-radius: 12px;
  background-color: #7583f0;
  color: white;
  text-align: center;
  padding: 18px;

  .MuiCircularProgress-colorPrimary {
    color: white;
  }
`;

export const CircularDescription = styled.p`
  margin-top: 12px;
  font-weight: 600;
`;

export const BackgroundCircularProgress = styled.div`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: #1f244561;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 99999999;
`;
