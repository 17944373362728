/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import { Tooltip } from '@material-ui/core';

import EditIcon from 'images/icons/objects/pencils/editIcon.svg';
import DeleteTagIcon from 'images/icons/signals/removesAndDeletes/deleteTagIcon.svg';
import dataSortable from 'utils/dataSortable';
import { shortenString } from 'utils/shortenString';

import DeleteTagDialog from './Dialogs/DeleteTag/DeleteTag';
import EditTagDialog from './Dialogs/EditTag/EditTagDialog';
import SkuTagDialog from './Dialogs/SkuTag/SkuTag';
import { Td, Tr } from './style';

function TagData({
  tag,
  tagData,
  setTagData,
  getTagListing,
  handleOpenEditSuccessMessage
}) {
  const [internalTagList, setInternalTagList] = useState();
  const [openEditTagDialog, setOpenEditTagDialog] = useState(false);
  const [openDeleteTagDialog, setOpenDeleteTagDialog] = useState(false);
  const [openSkuTagDialog, setOpenSkuTagDialog] = useState(false);
  const [skusLength, setSkusLength] = useState(0);

  useEffect(() => {
    const products = tag?.products?.map((el) => el?.product?.skus);
    setSkusLength(products.flat(Infinity).length);
    const currentTag = tagData.find((item) => item.id === tag.id);

    dataSortable(currentTag.values);
    setInternalTagList(currentTag);
  }, [tag]);

  return (
    <>
      {openDeleteTagDialog && (
        <DeleteTagDialog
          openDeleteTagDialog={openDeleteTagDialog}
          setOpenDeleteTagDialog={setOpenDeleteTagDialog}
          tag={tag}
          skusLength={skusLength}
          getTagListing={getTagListing}
        />
      )}

      {openSkuTagDialog && (
        <SkuTagDialog
          openSkuTagDialog={openSkuTagDialog}
          setOpenSkuTagDialog={setOpenSkuTagDialog}
          tagId={tag.id}
          tagName={tag.description}
        />
      )}

      {openEditTagDialog && (
        <EditTagDialog
          openEditTagDialog={openEditTagDialog}
          setOpenEditTagDialog={setOpenEditTagDialog}
          tag={tag}
          handleOpenEditSuccessMessage={handleOpenEditSuccessMessage}
          tagData={tagData}
          setTagData={setTagData}
          getTagListing={getTagListing}
        />
      )}

      <Tr>
        <Td style={{ textAlign: 'left' }}>
          {shortenString(tag?.description, 30)}
        </Td>

        <Td style={{ textAlign: 'left', cursor: 'default' }}>
          <Tooltip
            placement="bottom"
            title={
              <div>
                {internalTagList?.values &&
                  internalTagList.values
                    .filter((item) => item.status !== 'DELETED')
                    .map((value) => (
                      <p style={{ margin: '0' }}>{value.description}</p>
                    ))}
              </div>
            }
          >
            <div style={{ width: '20px' }}>
              {internalTagList?.values &&
                internalTagList.values.filter(
                  (item) => item.status !== 'DELETED'
                ).length}
            </div>
          </Tooltip>
        </Td>

        <Td style={{ textAlign: 'left' }}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenSkuTagDialog(true)}
          >
            {skusLength}
          </span>
        </Td>

        <Td style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <img
            style={{ cursor: 'pointer', marginRight: '8px' }}
            onClick={() => setOpenEditTagDialog(true)}
            src={EditIcon}
            alt="edit"
          />
          <img
            style={{ cursor: 'pointer', marginRight: '12px' }}
            onClick={() => setOpenDeleteTagDialog(true)}
            src={DeleteTagIcon}
            alt="delete"
          />
        </Td>
      </Tr>
    </>
  );
}

export default TagData;
