/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import {
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  ListSubheader,
  MenuItem,
  Typography
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { connect } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';
import history from 'utils/history';

function SelectRestaurantDialog({
  openModal,
  setOpenSelectRestaurantDialog,
  setSelectedIndex,
  setAnchorEl,
  companyFirstSelect,
  userInfo,
  companiesActive,
  setFilterRestaurants,
  updateRestaurantSelected,
  setUpdateRestaurantSelected
}) {
  const [collapsed, setCollapsed] = useState(
    userInfo?.companies?.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat() || []
  );
  const locPages = useLocation();
  const { path } = useRouteMatch();

  useEffect(() => {
    history.push(locPages, {
      selectdItems: userInfo.companies.groups[0].subGroups[0].stores[0]
    });
  }, []);

  const handleClick = (store) => {
    companiesActive(store.id, store.name, store.index, store?.segment);
    const newUrl = path.replace(/\:.*$/, store.id);
    history.push(newUrl, { selectdItems: store.id });
    setOpenSelectRestaurantDialog(false);
  };

  const handleMenuItemClick = (store) => {
    companyFirstSelect(true);
    setFilterRestaurants && setFilterRestaurants([store.id]);

    setUpdateRestaurantSelected &&
      setUpdateRestaurantSelected(!updateRestaurantSelected);

    setSelectedIndex && setSelectedIndex(store);

    setAnchorEl && setAnchorEl(null);

    handleClick(store);
  };

  return (
    <Dialog className="defaultModal selectRestaurantDialog" open={openModal}>
      <DialogTitle className="modalHeader navyBlue bold">
        <Typography
          style={{
            textTransform: 'inherit'
          }}
        >
          Escolha a loja que deseja trabalhar:
        </Typography>
      </DialogTitle>

      <DialogContent>
        {userInfo.companies.groups.map((group, index) =>
          group.subGroups.map((subGroup, index) => (
            <>
              {subGroup.hasOwnProperty('id') && (
                <ListSubheader
                  key={index}
                  className="subGroupHeader"
                  onClick={() => {
                    setCollapsed(
                      collapsed.map((item) =>
                        item.id === subGroup.id
                          ? { ...item, open: !item.open }
                          : item
                      )
                    );
                  }}
                >
                  {subGroup.name}

                  {!collapsed?.find((item) => item.id === subGroup.id)?.open ? (
                    <ExpandLess className="cursorPointer white" />
                  ) : (
                    <ExpandMore className="cursorPointer white" />
                  )}
                </ListSubheader>
              )}

              <Collapse
                in={
                  !collapsed.find((item) => item.id === subGroup.id)?.open ||
                  false
                }
                timeout={0}
              >
                {subGroup.stores.map((store, index) => (
                  <MenuItem
                    className="storeHeader"
                    key={index}
                    selected={store.id === userInfo?.companiesActive?.id}
                    onClick={() => handleMenuItemClick(store)}
                    style={{ fontFamily: 'Lato', width: '250px' }}
                  >
                    {store.name}
                  </MenuItem>
                ))}
              </Collapse>
            </>
          ))
        )}
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectRestaurantDialog);
