import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeGray.svg';
import { ShortenStringTooltip } from 'utils/shortenString';

function RestaurantsDialog({ openModal, handleClose, restaurantData = [] }) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openModal}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Restaurantes</Typography>

        <IconButton edge="start" color="inherit" onClick={handleClose}>
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <table
          className="contagem"
          width="100%"
          height="70%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr>
              <th valign="bottom" width="300" className="subtitle">
                RESTAURANTES
              </th>
            </tr>
          </thead>

          <tbody>
            {restaurantData.length ? (
              restaurantData.map((restaurant) => (
                <>
                  <tr>
                    <td width="200" className="description pr0">
                      <ShortenStringTooltip
                        string={
                          restaurant?.companyName?.toUpperCase() ||
                          restaurant?.description.toUpperCase()
                        }
                        length={60}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="9" className="pr0">
                      <hr
                        className="titleLineBorder"
                        style={{ margin: '0', height: '2px' }}
                      />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td className="description center">
                  <Typography style={{ margin: '5px', textAlign: 'center' }}>
                    Não há restaurantes.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default RestaurantsDialog;
