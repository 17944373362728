const INITIAL = {}

export default function orderInfo(state = INITIAL, action) {
  switch (action.type) {
    case "SET_ORDER_DATA":
      return (
        {
          action: "EDIT",
          data: action.data 
        }
      )
    case "CREATE_ORDER_DATA":
      return(
        {
          action: "CREATE"
        }
      )
    default:
      return state;
  }
}