/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';

import { LinearProgress } from '@material-ui/core';
import matchSorter from 'match-sorter';
import {
  useTable,
  useExpanded,
  useFilters,
  useGlobalFilter
} from 'react-table';

import InvoiceModal from 'components/Dialogs/InvoiceModal';

const fuzzyTextFilterFn = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function MenuTable({
  loading,
  columns: userColumns,
  data,
  className,
  setRangeDate,
  rangeDate,
  rangeDateEx,
  restaurantId,
  setProductFilter,
  productFilter,
  projOrReal,
  showColumns,
  filters
}) {
  const quantity = data.reduce(
    (total, value) =>
      value.quantity
        ? parseFloat(total) + parseFloat(Number(value.quantity))
        : parseFloat(total),
    0
  );
  const total = data.reduce(
    (total, value) =>
      value.total
        ? parseFloat(total) + parseFloat(Number(value.total))
        : parseFloat(total),
    0
  );
  const costTotal = data.reduce(
    (total, value) =>
      value.costTotal
        ? parseFloat(total) + parseFloat(Number(projOrReal === 'projected' ? value?.costTotal : value.realTotalCost))
        : parseFloat(total),
    0
  );
  const cost = data.filter((el) => el.cost !== 0).length
    ? data.reduce(
        (total, value) =>
          value.cost
            ? parseFloat(total) + parseFloat(Number(projOrReal === 'projected' ? value?.cost : value?.realCost))
            : parseFloat(total),
        0
      ) / data.filter((el) => el.cost).length
    : 0;
  const margin =
    costTotal && total && quantity
      ? (parseFloat(total) - parseFloat(costTotal)) / parseFloat(quantity)
      : 0;
  const cmv =
    costTotal && total ? (parseFloat(costTotal) / parseFloat(total)) * 100 : 0;
  const mkp =
    costTotal && total ? parseFloat(total) / parseFloat(costTotal) : 0;
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        })
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: userColumns,
        data,
        getSubRows: (row) =>
        row?.menu?.length && row?.children?.length ? row.menu.concat(row.children) : row.children && row.children.length ? row.children : row.menu,        filterTypes,
        autoResetExpanded: false
      },
      useFilters,
      useGlobalFilter,
      useExpanded
    );

  return (
    <div className={`${className && className}`}>
      <table {...getTableProps()} border="0">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className
                    }
                  ])}
                  colSpan={
                    column?.columns?.filter(
                      (el) => !el?.className?.includes('dNone')
                    )?.length
                  }
                  style={{
                    width:
                      column.render('Header') === 'Item' &&
                      `${
                        27 +
                        column?.columns?.filter((el) =>
                          el?.className?.includes('dNone')
                        )?.length *
                          6
                      }%`
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {loading && (
          <tr>
            <th
              colSpan={`${
                headerGroups[1]?.headers?.filter(
                  (el) => !el?.className?.includes('dNone')
                )?.length
              }`}
              className="textAlignCenter m10"
            >
              <LinearProgress
                style={{ height: '7px' }}
                id="loading"
                variant="query"
              />
            </th>
          </tr>
        )}

        <tbody {...getTableBodyProps()}>
          {rows?.length ? (
            rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr
                    {...row.getRowProps()}
                    className={`${
                      row.original.pdv && 'itemBg'
                    } backgroundCustomColor`}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps([
                          { className: cell.column.className }
                        ])}
                        style={{ border: '1px solid #313347' }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
            )
          ) : (
            <tr>
              <th colSpan="13" className="textAlignCenter m10">
                Não há nenhum Menu
              </th>
            </tr>
          )}
        </tbody>

        <tfoot>
          <tr
            className="mb30"
            style={{ borderTop: '2px solid #313347', lineHeight: '30px' }}
          >
            {!rows[0] ? (
              <tr>
                <th
                  colSpan={`${
                    headerGroups[1]?.headers?.filter(
                      (el) => !el?.className?.includes('dNone')
                    )?.length
                  }`}
                  className="m10"
                >
                  <LinearProgress variant="query" />
                </th>
              </tr>
            ) : (
              <>
                <td
                  className="subtitle textAlignRight bold pr12"
                  style={{ fontSize: '11px' }}
                >
                  &nbsp;
                </td>

                <td
                  className="subtitle textAlignRight pr12 right"
                  style={{ fontSize: '12px', fontWeight: 'bold' }}
                >
                  {!showColumns.length ? (
                    <div
                      className="dFlex alignCenter w100 justifyEnd"
                      style={{ gap: '40px' }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontWeight: 'bold',
                          fontSize: '12px'
                        }}
                      >
                        Total:
                      </p>
                      <InvoiceModal
                        setRangeDate={setRangeDate}
                        rangeDate={rangeDate}
                        rangeDateEx={rangeDateEx}
                        restaurantId={restaurantId}
                        setProductFilter={setProductFilter}
                        productFilter={productFilter}
                        filters={filters}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div
                    className="dFlex alignCenter w100 justifyEnd"
                    style={{ gap: '40px' }}
                  >
                  {showColumns.length > 0 &&
                  !showColumns?.includes('MÉD. R$') &&
                  !showColumns?.includes('DESC.') || 
                  showColumns.length > 0 && 
                  !showColumns?.includes('MÉD. R$') && 
                  !showColumns?.includes('CARD. R$')
                    ? 'Total:'
                    : ''}

                  {showColumns.length &&
                  !showColumns?.includes('CARD. R$') &&
                  !showColumns?.includes('MÉD. R$') && 
                  !showColumns?.includes('DESC.') ? 
                    <InvoiceModal
                      setRangeDate={setRangeDate}
                      rangeDate={rangeDate}
                      rangeDateEx={rangeDateEx}
                      restaurantId={restaurantId}
                      setProductFilter={setProductFilter}
                      productFilter={productFilter}
                      filters={filters}
                    />
                    :
                    ''
                  }
                  </div>

                  {!showColumns?.includes('CARD. R$') &&
                   showColumns?.includes('MÉD. R$') && 
                  !showColumns?.includes('DESC.') ? 
                    'Total:'
                    :
                    ''
                  }
                </td>

                <td
                  className={`${!showColumns?.includes('CARD. R$') && 'dNone'} textAlignRight`}
                  style={{ fontSize: '12px', fontWeight: 'bold' }}
                >
                  {!showColumns?.includes('MÉD. R$') &&
                  !showColumns?.includes('DESC.') ? (
                    <InvoiceModal
                      setRangeDate={setRangeDate}
                      rangeDate={rangeDate}
                      rangeDateEx={rangeDateEx}
                      restaurantId={restaurantId}
                      setProductFilter={setProductFilter}
                      productFilter={productFilter}
                      filters={filters}
                    />
                  ) : (
                    ''
                  )}
                  {!showColumns?.includes('MÉD. R$') &&
                  showColumns?.includes('DESC.')
                    ? 'Total:'
                    : ''}

                  {showColumns?.includes('CARD. R$') && showColumns?.includes('MÉD. R$') && !showColumns?.includes('DESC.') ?
                  'Total:'
                  : ''}
                </td>

                <td
                  className={`${!showColumns?.includes('MÉD. R$') && 'dNone'} `}
                  style={{ fontSize: '12px' }}
                  align="right"
                >
                  {showColumns?.includes('DESC.') ? (
                    <span className="mr8 fontSizeDefault bold justifyEnd">
                      Total:
                    </span>
                  ) : (
                    ''
                  )}

                 {!showColumns?.includes('CARD. R$') &&
                   showColumns?.includes('MÉD. R$') && 
                  !showColumns?.includes('DESC.') ? 
                    <InvoiceModal
                      setRangeDate={setRangeDate}
                      rangeDate={rangeDate}
                      rangeDateEx={rangeDateEx}
                      restaurantId={restaurantId}
                      setProductFilter={setProductFilter}
                      productFilter={productFilter}
                      filters={filters}
                    />
                    :
                    ''
                  }

                  {showColumns?.includes('CARD. R$') && showColumns?.includes('MÉD. R$') && !showColumns?.includes('DESC.') ?
                  <InvoiceModal
                    setRangeDate={setRangeDate}
                    rangeDate={rangeDate}
                    rangeDateEx={rangeDateEx}
                    restaurantId={restaurantId}
                    setProductFilter={setProductFilter}
                    productFilter={productFilter}
                    filters={filters}
                  />
                  : ''}
                </td>

                <td
                  className={`${
                    !showColumns?.includes('DESC.') && 'dNone'
                  } subtitle right textAlignRight`}
                  style={{ fontSize: '11px' }}
                >
                  {showColumns?.includes('MÉD. R$') ? (
                    <InvoiceModal
                      setRangeDate={setRangeDate}
                      rangeDate={rangeDate}
                      rangeDateEx={rangeDateEx}
                      restaurantId={restaurantId}
                      setProductFilter={setProductFilter}
                      productFilter={productFilter}
                      filters={filters}
                    />
                  ) : (
                    ''
                  )}
                  {!showColumns?.includes('MÉD. R$') &&
                  showColumns?.includes('DESC.') ? (
                    <InvoiceModal
                      setRangeDate={setRangeDate}
                      rangeDate={rangeDate}
                      rangeDateEx={rangeDateEx}
                      restaurantId={restaurantId}
                      setProductFilter={setProductFilter}
                      productFilter={productFilter}
                      filters={filters}
                    />
                  ) : (
                    ''
                  )}
                </td>

                <td
                  className={`subtitle right bold textAlignRight ${
                    !showColumns?.includes('QTDE.') && 'dNone'
                  }`}
                  align="right"
                  style={{
                    border: '1px solid rgb(191, 192, 203)',
                    fontSize: '11px',
                    background: 'rgba(0, 19, 115, .08)'
                  }}
                >
                  {(quantity || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1
                  })}
                </td>

                {/* Vendas Total */}
                <td
                  className={`subtitle right bold textAlignRight ${
                    !showColumns?.includes('TOTAL R$') && 'dNone'
                  }`}
                  align="right"
                  style={{
                    border: '1px solid rgb(191, 192, 203)',
                    fontSize: '11px',
                    background: 'rgba(0, 19, 115, .08)'
                  }}
                >
                  {(total || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>

                {/* Margem */}
                <td
                  className={`subtitle right bold textAlignRight ${
                    !showColumns?.includes('MARG.') && 'dNone'
                  }`}
                  align="right"
                  style={{
                    border: '1px solid rgb(191, 192, 203)',
                    fontSize: '11px',
                    background: 'rgba(0, 19, 115, .08)'
                  }}
                >
                  {(margin || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </td>

                {/* Custo Total */}
                <td
                  className={`subtitle right bold textAlignRight ${
                    !showColumns?.includes('CUSTO TOTAL R$') && 'dNone'
                  }`}
                  align="right"
                  style={{
                    border: '1px solid rgb(191, 192, 203)',
                    fontSize: '11px',
                    borderLeft: '1px solid #ECEDF1',
                    background: 'rgba(115, 0, 76, .08)'
                  }}
                >
                  {(costTotal || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                  })}
                </td>

                {/* Custo Nominal */}
                <td
                  className={`subtitle right bold textAlignRight ${
                    !showColumns?.includes('CUSTO NOMINAL') && 'dNone'
                  }`}
                  align="right"
                  style={{
                    border: '1px solid rgb(191, 192, 203)',
                    fontSize: '11px',
                    background: 'rgba(115, 0, 76, .08)'
                  }}
                >
                  {(cost || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                  })}
                </td>

                {/* CMV */}
                <td
                  className={`subtitle right bold textAlignRight ${
                    !showColumns?.includes('CUSTO % PROJ.') && 'dNone'
                  }`}
                  align="right"
                  style={{
                    border: '1px solid rgb(191, 192, 203)',
                    fontSize: '11px',
                    background: 'rgba(115, 0, 76, .08)'
                  }}
                >
                  {`${(cmv || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                  })}%`}
                </td>

                {/* MKP */}
                <td
                  className={`subtitle right bold textAlignRight ${
                    !showColumns?.includes('CUSTO % REAL') && 'dNone'
                  }`}
                  align="right"
                  style={{
                    border: '1px solid rgb(191, 192, 203)',
                    fontSize: '11px',
                    background: 'rgba(115, 0, 76, .08)'
                  }}
                >
                  {(mkp || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3
                  })}
                </td>
              </>
            )}
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default MenuTable;
