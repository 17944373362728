/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { useParams } from 'react-router-dom';

import FullScreenLoading from 'components/FullScreenLoading';
import useForceUpdate from 'hooks/useForceUptate';
import MobileWhiteArrow from 'images/icons/arrows/mobileWhiteArrow.svg';
import { updateCountRecursively } from "pages/inventory/diary-count/dialogs/CountDivergentDialog/utils";
import calcRealStock from "pages/inventory/services/calcRealStock";
import makeGet from "services/useFetch/makeGet";
import numberToReal from 'utils/numberToReal';

import CloseIcon from './closeIcon.svg';
import { DivergenteWrapper, DivergentHead, StockInfo } from './index.style';

import CountInput from '../CountInput';
import {
  BackTocounts,
  CardItemContainer,
  ItemTitle,
  CardTop,
  ItemType,
  CardItemStockAndCount,
  ItemStock,
  ItemCounts,
  CardRealAndTheorical,
  CardRealAndTheoricalItem,
  PackagesList,
  PackageItem,
  PackageWrapper,
  Line,
  CollapseCardContainer,
  OtherStocks,
  OtherStocksButton,
  BackAndForwardWrapper,
  NextFowardButton
} from '../CountPage/index.style';

function DivergentMobileDialog({
  open,
  handleClose,
  item,
  currentData,
  countDate,
  userInfo
}) {
  const { id: paramsID } = useParams();
  const [productsList, setProductsList] = useState([]);
  const [currentSelectItem, setCurrentSelectItem] = useState({});
  const [currentSelectIndex, setCurrentSelectIndex] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const forceUpdate = useForceUpdate();

  const getFormatedMonth = (date) => {
    const monthDate = date.getMonth() + 1;
    return monthDate > 9 ? monthDate : `0${monthDate}`;
  };

  const handleResponseData = (response) => {
    const { content } = response;
    if (!response || !content?.length) return;

    const productList = content.map((el) => {
      const eventDate = new Date(el.date);
      const date = `${eventDate.getDate()}/${getFormatedMonth(eventDate)}`;

      const {
        products = [],
        setup: {
          storageLocation: { description: storageDescription = '' } = {},
          id: setupId
        } = {},
        date: productDate,
        id: countId
      } = el;
      const [firstProduct] = products;

      return {
        ...firstProduct,
        storageDescription,
        productDate,
        setup: el.setup,
        countId,
        setupId,
        date,
        user: userInfo
      };
    });

    if (!productList) return;

    setCurrentSelectItem(productList[0]);
    setProductsList(productList);
    setCurrentSelectIndex(0);
  };

  const handleOnBlurInputCount = (input, dataCount) => {
    const {
      sku: {
        quantity: quantitySku,
        providers = [],
        unitsMeasurements: { abbreviation: skuUnity = '' } = {},
        id: skuId
      } = {},
      currentProduct: {
        consumptionUnitsPrimary: { abbreviation: productUnity = '' } = {},
        additional,
        skus = []
      } = {},
      type
    } = dataCount;
    const [firstProvider] = providers;
    const { quantity: providerQuantity, stock } = firstProvider;

    if (type === 'count') {
      currentSelectItem.realStock = calcRealStock({
        quantitySku,
        providerQuantity,
        countValue: input,
        additional,
        unity: skuUnity,
        productUnity,
        skus,
        skuId
      });
    }

    if (type === 'additionalSKU') {
      currentSelectItem.realStock = calcRealStock({
        quantitySku,
        providerQuantity,
        countValue: stock,
        additional: input,
        unity: skuUnity,
        productUnity,
        skus,
        skuId
      });
    }

    if (type === 'additional') {
      currentSelectItem.realStock = calcRealStock({
        quantitySku,
        providerQuantity,
        countValue: stock,
        // additional: input,
        unity: skuUnity,
        productUnity,
        skus,
        skuId,
        unityAdditional: input
      });
    }

    setCurrentSelectItem(currentSelectItem);
    // saveCountItem(input, dataCount, type);
    forceUpdate();
  };

  const handlePreviousitem = () => {
    if (currentSelectIndex === 0) return;

    setCurrentSelectIndex(currentSelectIndex - 1);
  };

  const handleNextItem = () => {
    if (currentSelectIndex + 1 >= productsList.length) return;

    setCurrentSelectIndex(currentSelectIndex + 1);
  };

  const handleSaveDivergent = () => {
    const handleCallbackUpdateCount = (response) => {
      handleClose();
      setSaveLoading(false);
    };
    setSaveLoading(true);
    updateCountRecursively(
      productsList,
      handleCallbackUpdateCount,
      paramsID,
      userInfo
    );
  };

  useEffect(() => {
    const params = [{ urlParam: `restaurantId=${paramsID}` }];

    makeGet(
      'inventories',
      params,
      handleResponseData,
      `/counts/${countDate}/differences/${item.id}`
    );
  }, [item]);

  useEffect(() => {
    setCurrentSelectItem(productsList[currentSelectIndex]);
  }, [currentSelectIndex]);

  return (
    <DivergenteWrapper open={open}>
      <DivergentHead>
        <div>
          Possível divergência na contagem.
        </div>

        <div>
          <img src={CloseIcon} alt="close divergent" onClick={handleClose} />
        </div>
      </DivergentHead>

      <CardItemContainer isDivergentModal>
        <CardTop>
          <ItemTitle>
            {currentSelectItem?.description}
          </ItemTitle>

          <ItemType color={currentSelectItem?.type == 'INTERNAL'}>
            {currentSelectItem?.type == 'INTERNAL' ? 'PROD' : 'MERC'}
          </ItemType>
        </CardTop>

        <CardItemStockAndCount>
          <ItemStock>
            ESTOQUES
          </ItemStock>

          <ItemCounts>
            CONTADOS 
            {currentSelectItem?.stocks?.countedStocks}/
            {currentSelectItem?.stocks?.totalStocks}
          </ItemCounts>
        </CardItemStockAndCount>

        <CardRealAndTheorical>
          <CardRealAndTheoricalItem>
            <span>REAL</span> {numberToReal(currentSelectItem?.realStock)}{' '}

            {currentSelectItem?.consumptionUnitsPrimary?.abbreviation}
          </CardRealAndTheoricalItem>

          <CardRealAndTheoricalItem grayBackground>
            <span>TEÓRICO</span>{' '}

            {numberToReal(currentSelectItem?.theoricalStock)}{' '}

            {currentSelectItem?.consumptionUnitsPrimary?.abbreviation}
          </CardRealAndTheoricalItem>
        </CardRealAndTheorical>

        <CollapseCardContainer>
          <OtherStocks>
            <span>OUTROS ESTOQUES</span>

            {currentSelectItem?.otherStock}{' '}

            {currentSelectItem?.consumptionUnitsPrimary?.abbreviation}
          </OtherStocks>

          <OtherStocksButton>
            Ver outros estoques
          </OtherStocksButton>
        </CollapseCardContainer>
      </CardItemContainer>

      <StockInfo>
        <div>
          {currentSelectItem?.storageDescription}
        </div>

        <div>
          {currentSelectItem?.productDate &&
            format(new Date(currentSelectItem.productDate), 'dd/MM')}
        </div>
      </StockInfo>

      <PackagesList isDivergentModal>
        {currentSelectItem?.skus?.map((sku, skuIndex) => {
          const { description = '', providers } = sku;

          const [firstProvider = {}] = providers;

          return (
            <>
              {providers.map((provider) => (
                <>
                  {!provider.isAdditional && (
                    <>
                      <PackageItem isDivergentModal>
                        <p>
                          {description}
                        </p>

                        <PackageWrapper>
                          <div>
                            <p>
                              EMBALAGEM
                            </p>

                            <span>{`E${
                              provider.quantity ? provider.quantity : 0
                            }x${sku.quantity ? sku.quantity : 0}${
                              sku.unitsMeasurements
                                ? sku.unitsMeasurements.abbreviation
                                : ''
                            }`}</span>
                          </div>

                          <div>
                            <CountInput
                              label="contagem"
                              content={provider?.stock}
                              handleOnBlur={(input, dataCount) =>
                                handleOnBlurInputCount(input, dataCount)
                              }
                              dataCount={{
                                sku,
                                ...currentData,
                                currentProduct: currentSelectItem,
                                restaurantId: paramsID,
                                type: 'count'
                              }}
                              setCurrentSelectItem={setCurrentSelectItem}
                            />
                          </div>
                        </PackageWrapper>
                      </PackageItem>

                      <Line />
                    </>
                  )}

                  {provider.isAdditional && (
                    <>
                      <PackageItem isDivergentModal>
                        <p />

                        <PackageWrapper>
                          <div>
                            <p>
                              EMBALAGEM
                            </p>

                            <span>{`${sku.quantity ? sku.quantity : 0}${
                              sku.unitsMeasurements
                                ? sku.unitsMeasurements.abbreviation
                                : ''
                            }`}
                            </span>
                          </div>

                          <div>
                            <CountInput
                              label="adicional"
                              content={
                                sku?.labelUnityAdditional
                                  ? sku?.labelUnityAdditional
                                  : sku?.unityAdditional
                              }
                              handleOnBlur={(input, dataCount) =>
                                handleOnBlurInputCount(input, dataCount)
                              }
                              dataCount={{
                                sku,
                                ...currentData,
                                currentProduct: currentSelectItem,
                                restaurantId: paramsID,
                                type: 'additional'
                              }}
                              setCurrentSelectItem={setCurrentSelectItem}
                            />
                          </div>
                        </PackageWrapper>
                      </PackageItem>
                      <Line />
                    </>
                  )}
                </>
              ))}

              {(currentSelectItem?.skus.some((sku) =>
                sku.providers.some((provider) => provider.quantity > 1)
              ) ||
                currentSelectItem?.skus.some((sku) => sku.quantity !== 1)) &&
                (currentSelectItem.consumptionUnitsPrimary.abbreviation ||
                  currentSelectItem.consumptionUnitsSecondary) &&
                skuIndex + 1 === currentSelectItem?.skus?.length && (
                  <>
                    <PackageItem isDivergentModal>
                      <p>
                        Adicional em{' '}
                        {currentSelectItem.consumptionUnitsSecondary &&
                        currentSelectItem.consumptionUnitsSecondary.abbreviation
                          ? currentSelectItem.consumptionUnitsSecondary
                              .abbreviation
                          : currentSelectItem.consumptionUnitsPrimary
                              .abbreviation}
                      </p>

                      <PackageWrapper>
                        <div>
                          <p>EMBALAGEM</p>
                          <span>
                            E1x1
                            {currentSelectItem.consumptionUnitsSecondary &&
                            currentSelectItem.consumptionUnitsSecondary
                              .abbreviation
                              ? currentSelectItem.consumptionUnitsSecondary
                                  .abbreviation
                              : currentSelectItem.consumptionUnitsPrimary
                                  .abbreviation}
                          </span>
                        </div>

                        <div>
                          <CountInput
                            label="adicional"
                            content={
                              currentSelectItem?.labelAdditional
                                ? currentSelectItem?.labelAdditional
                                : currentSelectItem?.additional
                            }
                            handleOnBlur={(input, dataCount) =>
                              handleOnBlurInputCount(input, dataCount)
                            }
                            dataCount={{
                              sku,
                              ...currentData,
                              currentProduct: currentSelectItem,
                              restaurantId: paramsID,
                              type: 'additionalSKU'
                            }}
                            setCurrentSelectItem={setCurrentSelectItem}
                          />
                        </div>
                      </PackageWrapper>
                    </PackageItem>
                    <Line />
                  </>
                )}
            </>
          );
        })}
      </PackagesList>

      <BackAndForwardWrapper>
        {currentSelectIndex !== 0 ? (
          <NextFowardButton onClick={handlePreviousitem}>
            <img src={MobileWhiteArrow} alt="left arrow" />

            <div>
              {productsList[currentSelectIndex - 1]?.storageDescription}
            </div>
          </NextFowardButton>
        ) : (
          <div />
        )}

        {!(currentSelectIndex + 1 >= productsList.length) ? (
          <NextFowardButton rightArrow onClick={handleNextItem}>
            <div>
              {productsList[currentSelectIndex + 1]?.storageDescription}
            </div>
            
            <img src={MobileWhiteArrow} alt="right arrow" />
          </NextFowardButton>
        ) : (
          <div />
        )}
      </BackAndForwardWrapper>

      <BackTocounts onClick={handleSaveDivergent}>
        <span>Salvar Alterações e Sair</span>
      </BackTocounts>

      <FullScreenLoading description="Salvando" display={saveLoading} />
    </DivergenteWrapper>
  );
}

export default DivergentMobileDialog;
