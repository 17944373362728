/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Snackbar,
    Switch,
    Tooltip,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import TrashIcon from 'images/icons/signals/removesAndDeletes/trashIconSecondary.svg';
import ProcessForm from 'pages/production/Process/ProcessForm';
import * as ProcessActions from 'store/actions/process';
import { GlobalStyle, Container } from 'styles/general';
import Environment from 'utils/environments';

function Process({
    userInfo,
    setProcess,
    createProcess
}) {
    const { id: getId } = useParams();
    const restaurantGroups = userInfo.companies.groups
        .reduce((acc, group) => [...acc, group.subGroups], [])
        .flat()
        .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
        .flat()
        .map((el) => el.id);
    const environments = Environment(process.env.REACT_APP_ENV);
    const [callSettings, setCallSettings] = useState({
        page: 1,
        totalPages: 0,
        size: 100,
        mainContent: [],
        numberOfElements: 0,
        loading: true,
        totalElements: 0
    });
    const { page, mainContent, loading } = callSettings;
    const [activeStatusFilter, setActiveStatusFilter] = useState(true);
    const [processModal, setProcessModal] = useState(false);
    const [updateList, setUpdateList] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [deleteSuccess, handleDeleteSuccess] = useState(false);
    const [deleteError, handleDeleteError] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [alertErr, setAlertErr] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [statusCode, setStatusCode] = useState();
    const [alertType, setAlertType] = useState('success');

    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    function handleCloseAll(handle) {
        handle(false);
    }

    function handleDelete() {
        const baseUrl = `${environments.catalog}/processes`;
        setUpdateList(true);

        const body = {
            restaurantId: restaurantGroups,
            user: userInfo.user
        }

        axios
            .put(`${baseUrl}/${deleteData.id}/groups`, body)
            .then((response) => {
                setAlertType('success');
                setAlertMessage(response.data.message)
                setAlertErr(true);
                setUpdateList(false);
                handleDialogClose();
            })
            .catch((error) => {
                setAlertType('error');
                setAlertErr(true);
                setAlertMessage(error?.response?.data?.message);
                setStatusCode(error?.response?.status);
            });
    }

    const toggleProcessModal = () => {
        setProcessModal(!processModal);

        if (processModal === false) {
            setUpdateList(false);
        }
    };

    const addProcess = () => (
        <Tooltip title="Cadastrar Processo" aria-label="add">
            <IconButton
                className="p0"
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => {
                    toggleProcessModal();
                    createProcess();
                }}
            >
                <img style={{ width: '32px' }} src={AddIcon} alt="AddIcon" />
            </IconButton>
        </Tooltip>
    );

    const orderArray = (array) =>
        array.sort((a, b) => {
            const descriptionA = a.description.toUpperCase();
            const descriptionB = b.description.toUpperCase();

            if (descriptionA < descriptionB) {
                return -1;
            }
            if (descriptionA > descriptionB) {
                return 1;
            }
            return 0;
        });

    const statusFilter = () => (
        <FormControlLabel
            className="toggleDefaultProcess m0 dFlex"
            control={
                <Switch
                    checked={activeStatusFilter}
                    onChange={(checked) => {
                        setActiveStatusFilter(!activeStatusFilter);

                        if (checked) {
                            setCallSettings({
                                ...callSettings,
                                mainContent: [],
                                loading: false
                            });
                        }
                    }}
                    name="Active Status Filter"
                />
            }
            label="Ativo"
        />
    );

    useEffect(() => {
        document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
    }, [page]);

    useEffect(() => {
        (async () => {
            setCallSettings({
                ...callSettings,
                loading: true
            });
            const statusFilterValue = activeStatusFilter ? 'ACTIVE' : 'INACTIVE';
            const response = await fetch(
                `${environments.catalog}/processes?restaurantId=${getId}&status=${statusFilterValue}`
            );
            const process = await response.json();
            setCallSettings({
                ...callSettings,
                mainContent: process.content || [],
                loading: false
            });
        })();
    }, [getId, page, updateList, activeStatusFilter]);

    useEffect(() => {
        setCallSettings({ ...callSettings, getId: userInfo.companiesActive.id });
    }, [userInfo]);

    return (
        <Layout>
            <TitleHead title="Processos Cadastrados" />

            <GlobalStyle />

            <Container>
                <PageTitleAndFilter
                    title="Processos cadastrados"
                    additionalbutton={addProcess}
                    afterTitleButton={statusFilter}
                />

                <table
                    className="desktop"
                    width="100%"
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                >
                    <thead>
                        <tr className="headerTitle">
                            <th width="74%" className="subtitle">
                                DESCRIÇÃO
                            </th>

                            <th width="7%" className="subtitle">
                                STATUS
                            </th>

                            <th width="2%">&nbsp;</th>
                        </tr>
                    </thead>

                    <tbody>
                        {loading && (
                            <tr>
                                <th colSpan="3" className="textAlignCenter m10">
                                    <LinearProgress
                                        style={{ height: '7px' }}
                                        id="loading"
                                        variant="query"
                                    />
                                </th>
                            </tr>
                        )}

                        {mainContent?.length ? (
                            orderArray(mainContent).map((process) => (
                                <>
                                    <tr>
                                        <td className="description p12">
                                            <Link
                                                onClick={() => {
                                                    setProcess(process);
                                                    toggleProcessModal();
                                                }}
                                            >
                                                {process.description}
                                            </Link>
                                        </td>

                                        <td className="fontSize12 left">
                                            <th>
                                                {process.status === 'ACTIVE'
                                                    ? 'Ativo'
                                                    : 'Inativo'}
                                            </th>
                                        </td>

                                        <td className="subtitle center p12 borderLGray">
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => (
                                                    handleDialogClickOpen(),
                                                    setDeleteData(process)
                                                )}
                                                style={{ padding: 0, outline: 0 }}
                                                disabled={process.status !== 'ACTIVE'}
                                            >
                                                <img style={{ width: '10px', height: '12px' }} src={TrashIcon} alt="TrashIcon" />
                                            </IconButton>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan="3">
                                            <hr />
                                        </td>
                                    </tr>
                                </>
                            ))
                        ) : (
                            <tr>
                                <th colSpan="3" className="m10 textAlignCenter">
                                    Nenhum item encontrado
                                </th>
                            </tr>
                        )}

                    </tbody>
                </table>

                {processModal && (
                    <ProcessForm
                        isModal
                        openModal={processModal}
                        redirectPath={null}
                        onClose={toggleProcessModal}
                        updateList={updateList}
                        setUpdateList={setUpdateList}
                    />
                )}

                <Dialog
                    fullScreen={fullScreen}
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle
                        className="modalHeader navyBlue bold cursorMove"
                        id="draggable-dialog-title"
                    >
                        <Typography>
                            Deseja realmente excluir esse processo?
                        </Typography>
                    </DialogTitle>

                    <DialogContent className="modalContent">
                        {deleteData?.hasCards === 'true' ? (
                            <Typography>
                                Atenção! Este processo possui relação com alguma(s) ficha(s). Deseja continuar?
                            </Typography>
                        ) : null}
                    </DialogContent>

                    <DialogActions className="justifyCenter">
                        <Button
                            className="defaultButton backButton"
                            design="outlined"
                            onClick={handleDialogClose}
                            label="Cancelar"
                            color="primary"
                        />
                        <Button
                            className="defaultButton submitButton"
                            autoFocus
                            design="contained"
                            label="Confirmar"
                            onClick={() => handleDelete()}
                            color="primary"
                        />
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={deleteSuccess}
                    autoHideDuration={2000}
                    onClose={() => handleCloseAll(handleDeleteSuccess)}
                >
                    <Alert
                        onClose={() => handleCloseAll(handleDeleteSuccess)}
                        severity="success"
                    >
                        Praça deletada com sucesso!
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={deleteError}
                    autoHideDuration={2000}
                    onClose={() => handleCloseAll(handleDeleteError)}
                >
                    <Alert
                        onClose={() => handleCloseAll(handleDeleteError)}
                        severity="error"
                    >
                        Ocorreu um erro ao salvar as informações.
                    </Alert>
                </Snackbar>
            </Container>
            <CommonAlert
                open={alertErr}
                onClose={() => {
                    setAlertErr(false);
                }}
                indexMessage={alertMessage}
                statusCode={statusCode}
                severity={alertType}
            />
        </Layout>
    );
}

const mapStateToProps = (state) => ({
    userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(ProcessActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Process);
