import styled, { css } from 'styled-components';

export const CardItemContainer = styled.div`
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 1px 4px rgba(1, 3, 17, 0.45);
    border-radius: 4px;
    ${({isDivergentModal}) => isDivergentModal && css`
        background-color: white;
        padding-bottom: 8px;
    `}
`;

export const ItemTitle = styled.div`
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 16px;
    max-width: 245px;
`;

export const CardTop = styled.div`
    width: 100%;
    background-color: #1F2445;
    padding: 4px 8px 4px 8px;
    display: flex;
    justify-content: space-between;
    position: relative;
`;

export const ItemType = styled.div`
    background-color: ${({ color }) => color ? "#5062F0" : "#8C36E2"};
    color: white;
    padding: 8px;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CardItemDetails = styled.div`

`;

export const CardItemValueCollapsed = styled.div`
    color: #5062F0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 8px;
`;

export const CardItemDifference = styled.div`
    color: #454A67;
    font-weight: bold;
    font-size: 9px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
`;

export const DifferencePercent = styled.div`
    background-color: ${({ color }) => color ? "#08AA15" : "#F53D4D"};
    font-size: 12px;
    color: #D2F9D6;
    padding: 6px;
    margin-left: 8px;
`;

export const CardItemStockAndCount = styled.div`
    width: 100%;
    background-color: #E6E7F0;
    font-size: 9px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
`;

export const ItemStock = styled.div`
    padding: 4px 4px 4px 8px;
    color: #454A67;
`;

export const ItemCounts = styled.div`
    padding: 4px 4px 4px 8px;
    color: #FFFFFF;
    background-color: #9296AC;
    font-size: 10px;
`;

export const CardRealAndTheorical = styled.div`
    margin: 4px 8px 8px 8px;
    display: flex;
    justify-content: space-between;
`;

export const CardRealAndTheoricalItem = styled.div`
    color: #010311;
    font-weight: bold;
    font-size: 16px;
    background-color: ${({grayBackground}) => grayBackground && "#E6E7F0"};
    padding: ${({grayBackground}) => grayBackground ?  "4px" : "4px 4px 4px 0px"};
    transition: all 250ms ease-in-out;
    
    ${({rotate}) => rotate && css`
        display: grid;
        text-align: end;    
        width: 45%;
    `}

    span {
        color: #454A67;
        font-weight: bold;
        font-size: 9px;
    }
`;

export const PackagesList = styled.div`
    margin-top: 12px;
    padding: 8px;
    width: 100%;
    box-shadow: 0px 1px 4px rgba(1, 3, 17, 0.45);
    border-radius: 4px;
    ${({isDivergentModal}) => isDivergentModal && css`
        background-color: white;
        margin-top: 0px;
    `}
`;

export const PackageItem = styled.div`
    p {
        color: #010311;
        font-size: 12px;
    }
    div {
        p {
            color: #454A67;
            font-size: 9px;
            margin: 0;
        }
        span {
            color: #010311;
            font-size: 12px;
        }
    }
    ${({isDivergentModal}) => isDivergentModal && css`
        background-color: white;
        margin-top: 0px;
    `}
`;

export const PackageWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Line = styled.hr`
    color: #D0D3E2;
    background-color: #D0D3E2;
    margin-top: 6px;
    margin-bottom: 6px;
`;

export const ArrowDrillDown = styled.img`
    transition: all 150ms ease-in;
    transform: ${({rotate}) => rotate && "rotate(180deg)"};
`;

export const CollapsedCard = styled.div`
    display: ${({display}) => !display && "none"};
`;

export const CollapseCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const OtherStocks = styled.div`
    color: #010311;
    font-size: 12px;
    display: grid;
    /* justify-content: flex-end; */
    text-align: right;
    width: 45%;
    span {
        font-weight: bold;
        font-size: 9px;
        color: #454A67;
    }
`;

export const OtherStocksButton = styled.button`
    border: none;
    background-color: #5062F0;
    color: white;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
    font-size: 10px;
    margin-right: 8px;
`;

export const CollapsedCardLine = styled.div`
    background-color: #F2F3F8;
    color: #454A67;
    font-size: 9px;
    font-weight: bold;
    padding: 4px 4px 4px 8px;
    margin: 12px 0 12px;
`;

export const DiferenceCard = styled.div`
    width: 75%;
    padding: 4px 12px;
    margin: 4px;
    background-color: ${({color}) => color ? "#D2F9D6" : "#F2D9DC"};
    p {
        color: #454A67;
        font-size: 9px;
        font-weight: bold;
        text-align: center;
    }
    span {
        color: ${({color}) => color ? "#08AA15" : "#F53D4D"};
        font-size: 16px;
        font-weight: bold;
    }
    div {
        display: flex;
        justify-content: space-between;
    }
`;

export const BackAndForwardWrapper = styled.div`
    margin: 12px 0;
    display: flex;
    justify-content: space-between;

    ${({width100}) => width100 &&
        css`
            a {
                width: 100%;
            }
    `}
`;

export const NextFowardButton = styled.button`
    border: none;
    background-color: ${({secondary}) => secondary ? "white" : "#5062F0"};
    color: ${({secondary}) => secondary ? "#5062F0" : "white"};
    border-radius: 8px;
    padding: 12px;
    font-size: 10px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    div {
        text-transform: uppercase;
    }
    img {
        margin: 0 6px 0 6px;
    }
    ${({rightArrow}) => rightArrow && css`
        img {
            transform: rotate(180deg);
        }
    `}
`;

export const BackTocounts = styled.div`
    width: 100%;
    color: #5062F0;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 100px;
    span {
        position: relative;
        &::after {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #5062F0;
            position: absolute;
            position: absolute;
            bottom: -4px;
            left: 0;
        }
    }
`;

export const DivergentMessageWrapper = styled.div`
    width: 100%;
    text-align: center;
    color: white;
    padding: 4px;
    background-color: #F53D4C;
    margin-bottom: 8px;
    font-size: 16px;
`;

export const BackToLastCount = styled.button`
    border: 1px solid #5062F0;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    padding: 12px;
    background-color: white;
    color: #5062F0;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
`;
