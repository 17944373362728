/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Paper
} from '@material-ui/core';
import Draggable from 'react-draggable';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import ValidationLength from 'utils/utils';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
function StockDialog({
  modalSettings,
  setModalSettings,
  stocks,
}) {
  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'stockModal')
          .fullScreen
      }
      open={
        modalSettings.find((modal) => modal.name === 'stockModal').open
      }
      onClose={() => {
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'stockModal'
              ? { ...modal, open: false }
              : modal
          )
        );
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal dialog100"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
      >
        <Typography id="draggable-dialog-title" className="w100 textAlignLeft">
          Outros Estoques
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'stockModal'
                  ? { ...modal, open: false }
                  : modal
              )
            );
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <table>
          <thead>
            <tr>
              <th width="800" className="subtitle left p12 pt8 pb8">
                DESCRIÇÃO
              </th>

              <th width="200" className="subtitle right p12">
                QTDE
              </th>

              <th width="200" className="subtitle left p12">
                USUÁRIO
              </th>
            </tr>

            <tr>
              <td colSpan="7" className="p0">
                <hr className="titleLineBorder m0" />
              </td>
            </tr>
          </thead>

          <tbody>
            {stocks && stocks.length ? 
              stocks.map((stock, stockIndex) => {
                const isLastElement = stockIndex === stocks?.length - 1;

                return (
                  <>
                    <tr>
                      <td className="description left pl12">
                        <div>
                          {ValidationLength(
                            stock.stock,
                            40
                          )}
                        </div>
                      </td>

                      <td className="description right pl12">
                        <div>
                          {stock?.quantity ? stock?.quantity?.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          }) : '0,00'}
                        </div>
                      </td>

                      <td className="description left pl12">
                        <div>
                          {stock.user ? stock.user : '-'}
                        </div>
                      </td>
                    </tr>

                    {!isLastElement && (
                      <tr>
                        <td colSpan="7" className="p0">
                          <hr
                            className="titleLineBorder m0"
                            style={{ height: '2px' }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                )
              }) : (
            <tr>
              <td colSpan="10" className="description center">
                <Typography className="fontSizeDefault bold" style={{ margin: '5px', textAlign: 'center' }}>
                  Não há nenhum estoque
                </Typography>
              </td>
            </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}
export default StockDialog;