import React from 'react'

import Layout from 'containers/layouts/layout'

import RegisterProductModal from './ProductForm';

function RegisterManufacturer() {
  return (
    <Layout>
      <RegisterProductModal isModal={false} />
    </Layout>
  )
}

export default RegisterManufacturer;