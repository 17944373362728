/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// eslint-disable-next-line no-lone-blocks

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { LinearProgress, Typography, useMediaQuery } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import getOptions from 'services/getOptions';
import { changeFilter, clearFilter } from 'store/reducers/cardsFilterSlice';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import axiosInstanceManufactures from '../../../services/middlewares/apis/manufactures_validate'

const environments = Environment(process.env.REACT_APP_ENV);

function Structure({ userInfo }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const smallScreen = useMediaQuery('(max-width: 1366px)');
  const queryParams = useLocation()?.search;

  const dayWeek = new Date().getDay();
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [selectedCard, setSelectedCard] = useState('');
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'modalTechnicalSheet', open: false, fullScreen: false }
  ]);

  const [currentDateFilterDefaultValue, setCurrentDateFilterDefaultValue] =
    useState(
      queryParams?.includes('currentDate')
        ? queryParams
            ?.split('&')
            ?.find((el) => el.includes('currentDate'))
            ?.split('=')[1]
        : ''
    );

  const DEFAULT_FILTERS = useMemo(
    () => [
      currentDateFilterDefaultValue && {
        param: 'currentDate',
        value: currentDateFilterDefaultValue,
        urlParam: `currentDate=${currentDateFilterDefaultValue}`
      }
    ],
    [currentDateFilterDefaultValue]
  );

  const storedCardsFilter = useSelector(
    (currState) => currState.cardsFilter.filters
  );

  const { id: paramsID, cardId } = useParams();
  const prevPath = history?.location?.state?.prevPath;

  const shouldUseStoredFilters = useMemo(
    () => storedCardsFilter && prevPath,
    [prevPath, storedCardsFilter]
  );

  const [productionStructureList, setProductionStructureList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 100,
    filters: shouldUseStoredFilters ? storedCardsFilter : DEFAULT_FILTERS,
    numberOfElements: 0,
    totalElements: 0
  });
  const { getId, page, filters, numberOfElements, totalElements } =
    callSettings;
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Ficha',
        filterName: 'cardDescription',
        filterNameSecond: 'cardId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/cards`,
        key: 'cards',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find(
            (filter) => filter.filterName === 'cardDescription'
          )?.cardDescription
      },
      {
        label: 'Tipo',
        filterName: 'type',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        page: 1,
        key: 'brands',
        allowClear: true,
        optionReference: 'description',
        isSelect: true,
        options: [
          { title: 'Receita', value: 'RECIPE' },
          { title: 'Transformação', value: 'TRANSFORMATION' }
        ],
        size: 'medium_plus',
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find((filter) => filter.filterName === 'type')
            ?.textValue
      },
      {
        label: 'Produto',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find(
            (filter) => filter.filterName === 'productDescription'
          )?.productDescription
      },
      {
        label: 'Praça',
        filterName: 'courtId',
        placeHolder: 'Selecione...',
        type: 'newTreeSelector',
        urlPath: `${environments.catalog}/courts`,
        key: 'courts',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_to_plus',
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find(
            (filter) => filter.filterName === 'courtId'
          )?.textValue
      },
      {
        type: 'multipleCheckbox',
        filterName: 'currentDate',
        checkBoxInputs: [
          {
            name: 'Fichas de hoje',
            referenceName: 'currentDate',
            checked: currentDateFilterDefaultValue
          }
        ],
        searchByTermMode: true
      }
    ]
  ]);

  const getScheduleContent = useCallback(async () => {
    setLoading(true);
    let params = [];

    if (shouldUseStoredFilters) {
      const parsedFilters = storedCardsFilter
        .map((filter) => {
          const key = Object.keys(filter)[0];
          const isFalse = filter[key] === false;

          if (filter[key]) {
            return `${key}=${filter[key]}&`;
          }
          return isFalse ? `${key}=false` : null;
        })
        .join('');

      params = parsedFilters;
    } else {
      params = filtersChanged?.map((filter) => filter?.urlParam).join('&');
    }

    const headers = {
      headers: {
        User: `${userLocal?.user}`
      }
    };

    const response = await axiosInstanceManufactures.get(
      `${environments.manufactures}/cardsStructure?restaurantId=${paramsID}&${params}`,
      headers
    );
    const cards = await response.data;

    setLoading(false);
    setProductionStructureList(cards || []);

    setCallSettings((prevState) => ({
      ...prevState,
      totalPages: cards.totalPages,
      numberOfElements: cards.numberOfElements,
      totalElements: cards.totalElements
    }));

    if (!cards.content && !cards.content?.length) return;
  }, [filtersChanged, paramsID, shouldUseStoredFilters, storedCardsFilter]);

  const onClearFilters = () => {
    dispatch(clearFilter());

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((filter) => ({
          ...filter,
          storedValue: ''
        }));
      }

      return filterLine;
    });

    setFiltersChanged(DEFAULT_FILTERS);
    setFilterFields(FILTER_FIELDS);
  };

  const clearFilterValues = (param) => {
    setCurrentDateFilterDefaultValue(
      param === 'currentDate' ? '' : currentDateFilterDefaultValue
    );

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((item) => {
          if (item?.filterName === param) {
            return {
              ...item,
              storedValue: ''
            };
          }

          return item;
        });
      }

      return filterLine;
    });

    setFilterFields(FILTER_FIELDS);
  };

  const handleFilter = useCallback(
    (param, value, filter, paramSecond) => {
      const hasFilterWithFirstName = filtersChanged?.filter(
        (el) => el?.param === param
      )?.length;
      const hasFilterWithSecondName = filtersChanged?.filter(
        (el) => el?.param === paramSecond
      )?.length;

      let formattedFilters = hasFilterWithSecondName
        ? filtersChanged?.filter((el) => el?.param !== paramSecond)
        : filtersChanged;

      if (!value) {
        clearFilterValues(param);

        if (hasFilterWithFirstName || hasFilterWithSecondName) {
          formattedFilters = filtersChanged?.filter(
            (el) => el?.param !== paramSecond && el?.param !== param
          );
        }
      }

      dispatch(
        changeFilter({
          param,
          value,
          textValue: filter?.title
        })
      );

      handleFilterOptions(param, value, formattedFilters, setFiltersChanged);
    },
    [dispatch, filtersChanged, callSettings]
  );

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      paramsID,
      filtersChanged,
      page
    );
  };

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card?.itemsTransformations?.productId,
        stock: card?.itemsTransformations?.products?.realStock,
        consumptionUnitsPrimaryId:
          card?.itemsTransformations?.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card?.itemsTransformations?.unitsMeasurements.abbreviation,
        cost: card?.itemsTransformations?.cost,
        averageCost: card?.itemsTransformations?.products?.averageCostStock,
        lastCost: card?.itemsTransformations?.products?.lastCostStock
          ? card?.itemsTransformations?.products?.lastCostStock
          : card?.itemsTransformations?.products?.approximateCost
          ? card?.itemsTransformations?.products?.approximateCost
          : '0,000',
        quantity: card?.itemsTransformations?.quantity,
        product: card?.itemsTransformations?.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'modalTechnicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  const itemsOnDay = (item, day, maxItemsSize) => {
    const itemsOfDay = item?.daysWeeks?.find((el) => el?.day === day)?.items;
    const itemsToMaxSize = [
      ...Array(maxItemsSize - (itemsOfDay?.length || 0)).keys()
    ];
    const emptyItemsGenerator = itemsToMaxSize?.map((el) => ({
      emptyElement: el
    }));

    return itemsOfDay?.length === maxItemsSize
      ? itemsOfDay
      : [itemsOfDay, ...emptyItemsGenerator].flat();
  };

  const isTransformation = (item) => item?.type === 'TRANSFORMATION';
  const isRecipe = (item) => item?.type === 'RECIPE';

  const itemStyle = (item) => {
    let bg = 'transparent';

    if (isTransformation(item)) {
      bg = 'rgba(247, 213, 110, .2)';
    }

    if (isRecipe(item)) {
      bg = 'rgba(117, 131, 240, .2)';
    }

    return {
      bg
    };
  };

  const arrayWithMostItems = (item) =>
    item?.daysWeeks
      ?.map((el) => el?.items)
      ?.reduce((maxArray, currentArray) => {
        if (currentArray?.length > maxArray?.length) {
          return currentArray;
        }

        return maxArray;
      }, []);

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  useEffect(() => {
    getScheduleContent();
  }, [getId, page, filtersChanged, paramsID, getScheduleContent]);

  return (
    <Layout>
      <GlobalStyle />

      <TitleHead title="Estrutura das Produções" />

      <Container>
        <PageTitleAndFilter
          title="Estrutura das Produções"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          getFilterhandle={getFilterhandle}
          onClear={() => onClearFilters()}
          classOnClear="mt20 ml16"
          dynamicJustifyOff
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="19.5%" className="subtitle">
                FICHA{' '}
              </th>

              <th
                width="11.5%"
                className="subtitle center p12"
                style={{
                  borderLeft: 'solid 1px #454A67'
                }}
              >
                <div
                  className="dFlex center justifyCenter alignCenter"
                  style={{
                    height: '20px',
                    background: `${dayWeek === 1 ? '#5062F0' : 'none'}`,
                    borderRadius: '20px'
                  }}
                >
                  SEGUNDA
                </div>
              </th>

              <th
                width="11.5%"
                className="subtitle center p12"
                style={{
                  borderLeft: 'solid 1px #454A67'
                }}
              >
                <div
                  className="dFlex center justifyCenter alignCenter"
                  style={{
                    height: '20px',
                    background: `${dayWeek === 2 ? '#5062F0' : 'none'}`,
                    borderRadius: '20px'
                  }}
                >
                  TERÇA
                </div>
              </th>

              <th
                width="11.5%"
                className="subtitle center p12"
                style={{
                  borderLeft: 'solid 1px #454A67'
                }}
              >
                <div
                  className="dFlex center justifyCenter alignCenter"
                  style={{
                    height: '20px',
                    background: `${dayWeek === 3 ? '#5062F0' : 'none'}`,
                    borderRadius: '20px'
                  }}
                >
                  QUARTA
                </div>
              </th>

              <th
                width="11.5%"
                className="subtitle center p12"
                style={{
                  borderLeft: 'solid 1px #454A67'
                }}
              >
                <div
                  className="dFlex center justifyCenter alignCenter"
                  style={{
                    height: '20px',
                    background: `${dayWeek === 4 ? '#5062F0' : 'none'}`,
                    borderRadius: '20px'
                  }}
                >
                  QUINTA
                </div>
              </th>

              <th
                width="11.5%"
                className="subtitle center p12"
                style={{
                  borderLeft: 'solid 1px #454A67'
                }}
              >
                <div
                  className="dFlex center justifyCenter alignCenter"
                  style={{
                    height: '20px',
                    background: `${dayWeek === 5 ? '#5062F0' : 'none'}`,
                    borderRadius: '20px'
                  }}
                >
                  SEXTA
                </div>
              </th>

              <th
                width="11.5%"
                className="subtitle center p12"
                style={{
                  borderLeft: 'solid 1px #454A67'
                }}
              >
                <div
                  className="dFlex center justifyCenter alignCenter"
                  style={{
                    height: '20px',
                    background: `${dayWeek === 6 ? '#5062F0' : 'none'}`,
                    borderRadius: '20px'
                  }}
                >
                  SÁBADO
                </div>
              </th>

              <th
                width="11.5%"
                className="subtitle center p12"
                style={{
                  borderLeft: 'solid 1px #454A67'
                }}
              >
                <div
                  className="dFlex center justifyCenter alignCenter"
                  style={{
                    height: '20px',
                    background: `${dayWeek === 7 ? '#5062F0' : 'none'}`,
                    borderRadius: '20px'
                  }}
                >
                  DOMINGO
                </div>
              </th>
            </tr>
          </thead>

          {loading && (
            <tr>
              <th colSpan="8" className="m10 textAlignCenter">
                <LinearProgress id="loading" variant="query" />
              </th>
            </tr>
          )}

          <tbody>
            {productionStructureList?.length ? (
              productionStructureList.map((item) => {
                const itemsToSum = arrayWithMostItems(item)?.length;

                return (
                  <>
                    <tr>
                      <td className="description p12 verticalAlignBaseline">
                        <Typography
                          onClick={() => {
                            getOptions(
                              `${environments.catalog}/cards/${item?.cardId}`,
                              'cards',
                              selectedCard,
                              setSelectedCard,
                              getId,
                              null,
                              null,
                              null,
                              true
                            );
                          }}
                          className="dFlex alignCenter cursorPointer lightBlue p0 childTdFontSize11"
                          style={{ minHeight: 30 }}
                        >
                          {ValidationLength(item?.description, 35)}
                        </Typography>
                      </td>

                      <td
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #454A67'
                        }}
                      >
                        <div className="dFlex flexColumn">
                          {itemsOnDay(item, 1, itemsToSum)?.length
                            ? itemsOnDay(item, 1, itemsToSum).map(
                                (dayItem, dayItemIndex) => (
                                  <span
                                    className="p12 w100"
                                    style={{
                                      height: 30,
                                      color: '#0F1225',
                                      borderTop:
                                        dayItemIndex > 0
                                          ? 'solid 1px #D2D3D7'
                                          : 'none',
                                      background: itemStyle(dayItem)?.bg
                                    }}
                                  >
                                    <Typography
                                      onClick={() => {
                                        if (!dayItem?.description) return;

                                        getOptions(
                                          `${environments.catalog}/cards/${dayItem?.cardId}`,
                                          'cards',
                                          selectedCard,
                                          setSelectedCard,
                                          getId,
                                          null,
                                          null,
                                          null,
                                          true
                                        );
                                      }}
                                      className={`dFlex alignCenter ${
                                        dayItem?.description && 'cursorPointer'
                                      } p0 childTdFontSize11`}
                                      style={{ minHeight: 30 }}
                                    >
                                      {ValidationLength(
                                        dayItem?.description,
                                        smallScreen ? 15 : 18
                                      )}
                                    </Typography>
                                  </span>
                                )
                              )
                            : null}
                        </div>
                      </td>

                      <td
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #454A67'
                        }}
                      >
                        <div className="dFlex flexColumn">
                          {itemsOnDay(item, 2, itemsToSum)?.length
                            ? itemsOnDay(item, 2, itemsToSum).map(
                                (dayItem, dayItemIndex) => (
                                  <span
                                    className="p12 w100"
                                    style={{
                                      height: 30,
                                      color: '#0F1225',
                                      borderTop:
                                        dayItemIndex > 0
                                          ? 'solid 1px #D2D3D7'
                                          : 'none',
                                      background: itemStyle(dayItem)?.bg
                                    }}
                                  >
                                    <Typography
                                      onClick={() => {
                                        if (!dayItem?.description) return;

                                        getOptions(
                                          `${environments.catalog}/cards/${dayItem?.cardId}`,
                                          'cards',
                                          selectedCard,
                                          setSelectedCard,
                                          getId,
                                          null,
                                          null,
                                          null,
                                          true
                                        );
                                      }}
                                      className={`dFlex alignCenter ${
                                        dayItem?.description && 'cursorPointer'
                                      } p0 childTdFontSize11`}
                                      style={{ minHeight: 30 }}
                                    >
                                      {ValidationLength(
                                        dayItem?.description,
                                        smallScreen ? 15 : 18
                                      )}
                                    </Typography>
                                  </span>
                                )
                              )
                            : null}
                        </div>
                      </td>

                      <td
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #454A67'
                        }}
                      >
                        <div className="dFlex flexColumn">
                          {itemsOnDay(item, 3, itemsToSum)?.length
                            ? itemsOnDay(item, 3, itemsToSum).map(
                                (dayItem, dayItemIndex) => (
                                  <span
                                    className="p12 w100"
                                    style={{
                                      height: 30,
                                      color: '#0F1225',
                                      borderTop:
                                        dayItemIndex > 0
                                          ? 'solid 1px #D2D3D7'
                                          : 'none',
                                      background: itemStyle(dayItem)?.bg
                                    }}
                                  >
                                    <Typography
                                      onClick={() => {
                                        if (!dayItem?.description) return;

                                        getOptions(
                                          `${environments.catalog}/cards/${dayItem?.cardId}`,
                                          'cards',
                                          selectedCard,
                                          setSelectedCard,
                                          getId,
                                          null,
                                          null,
                                          null,
                                          true
                                        );
                                      }}
                                      className={`dFlex alignCenter ${
                                        dayItem?.description && 'cursorPointer'
                                      } p0 childTdFontSize11`}
                                      style={{ minHeight: 30 }}
                                    >
                                      {ValidationLength(
                                        dayItem?.description,
                                        smallScreen ? 15 : 18
                                      )}
                                    </Typography>
                                  </span>
                                )
                              )
                            : null}
                        </div>
                      </td>

                      <td
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #454A67'
                        }}
                      >
                        <div className="dFlex flexColumn">
                          {itemsOnDay(item, 4, itemsToSum)?.length
                            ? itemsOnDay(item, 4, itemsToSum).map(
                                (dayItem, dayItemIndex) => (
                                  <span
                                    className="p12 w100"
                                    style={{
                                      height: 30,
                                      color: '#0F1225',
                                      borderTop:
                                        dayItemIndex > 0
                                          ? 'solid 1px #D2D3D7'
                                          : 'none',
                                      background: itemStyle(dayItem)?.bg
                                    }}
                                  >
                                    <Typography
                                      onClick={() => {
                                        if (!dayItem?.description) return;

                                        getOptions(
                                          `${environments.catalog}/cards/${dayItem?.cardId}`,
                                          'cards',
                                          selectedCard,
                                          setSelectedCard,
                                          getId,
                                          null,
                                          null,
                                          null,
                                          true
                                        );
                                      }}
                                      className={`dFlex alignCenter ${
                                        dayItem?.description && 'cursorPointer'
                                      } p0 childTdFontSize11`}
                                      style={{ minHeight: 30 }}
                                    >
                                      {ValidationLength(
                                        dayItem?.description,
                                        smallScreen ? 15 : 18
                                      )}
                                    </Typography>
                                  </span>
                                )
                              )
                            : null}
                        </div>
                      </td>

                      <td
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #454A67'
                        }}
                      >
                        <div className="dFlex flexColumn">
                          {itemsOnDay(item, 5, itemsToSum)?.length
                            ? itemsOnDay(item, 5, itemsToSum).map(
                                (dayItem, dayItemIndex) => (
                                  <span
                                    className="p12 w100"
                                    style={{
                                      height: 30,
                                      color: '#0F1225',
                                      borderTop:
                                        dayItemIndex > 0
                                          ? 'solid 1px #D2D3D7'
                                          : 'none',
                                      background: itemStyle(dayItem)?.bg
                                    }}
                                  >
                                    <Typography
                                      onClick={() => {
                                        if (!dayItem?.description) return;

                                        getOptions(
                                          `${environments.catalog}/cards/${dayItem?.cardId}`,
                                          'cards',
                                          selectedCard,
                                          setSelectedCard,
                                          getId,
                                          null,
                                          null,
                                          null,
                                          true
                                        );
                                      }}
                                      className={`dFlex alignCenter ${
                                        dayItem?.description && 'cursorPointer'
                                      } p0 childTdFontSize11`}
                                      style={{ minHeight: 30 }}
                                    >
                                      {ValidationLength(
                                        dayItem?.description,
                                        smallScreen ? 15 : 18
                                      )}
                                    </Typography>
                                  </span>
                                )
                              )
                            : null}
                        </div>
                      </td>

                      <td
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #454A67'
                        }}
                      >
                        <div className="dFlex flexColumn">
                          {itemsOnDay(item, 6, itemsToSum)?.length
                            ? itemsOnDay(item, 6, itemsToSum).map(
                                (dayItem, dayItemIndex) => (
                                  <span
                                    className="p12 w100"
                                    style={{
                                      height: 30,
                                      color: '#0F1225',
                                      borderTop:
                                        dayItemIndex > 0
                                          ? 'solid 1px #D2D3D7'
                                          : 'none',
                                      background: itemStyle(dayItem)?.bg
                                    }}
                                  >
                                    <Typography
                                      onClick={() => {
                                        if (!dayItem?.description) return;

                                        getOptions(
                                          `${environments.catalog}/cards/${dayItem?.cardId}`,
                                          'cards',
                                          selectedCard,
                                          setSelectedCard,
                                          getId,
                                          null,
                                          null,
                                          null,
                                          true
                                        );
                                      }}
                                      className={`dFlex alignCenter ${
                                        dayItem?.description && 'cursorPointer'
                                      } p0 childTdFontSize11`}
                                      style={{ minHeight: 30 }}
                                    >
                                      {ValidationLength(
                                        dayItem?.description,
                                        smallScreen ? 15 : 18
                                      )}
                                    </Typography>
                                  </span>
                                )
                              )
                            : null}
                        </div>
                      </td>

                      <td
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #454A67'
                        }}
                      >
                        <div className="dFlex flexColumn">
                          {itemsOnDay(item, 0, itemsToSum)?.length
                            ? itemsOnDay(item, 0, itemsToSum).map(
                                (dayItem, dayItemIndex) => (
                                  <span
                                    className="p12 w100"
                                    style={{
                                      height: 30,
                                      color: '#0F1225',
                                      borderTop:
                                        dayItemIndex > 0
                                          ? 'solid 1px #D2D3D7'
                                          : 'none',
                                      background: itemStyle(dayItem)?.bg
                                    }}
                                  >
                                    <Typography
                                      onClick={() => {
                                        if (!dayItem?.description) return;

                                        getOptions(
                                          `${environments.catalog}/cards/${dayItem?.cardId}`,
                                          'cards',
                                          selectedCard,
                                          setSelectedCard,
                                          getId,
                                          null,
                                          null,
                                          null,
                                          true
                                        );
                                      }}
                                      className={`dFlex alignCenter ${
                                        dayItem?.description && 'cursorPointer'
                                      } p0 childTdFontSize11`}
                                      style={{ minHeight: 30 }}
                                    >
                                      {ValidationLength(
                                        dayItem?.description,
                                        smallScreen ? 15 : 18
                                      )}
                                    </Typography>
                                  </span>
                                )
                              )
                            : null}
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="8">
                        <hr style={{ borderTop: 'solid 1px #1F2445' }} />
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <th colSpan="8" className="m10 textAlignCenter">
                  Não há nenhuma agenda
                </th>
              </tr>
            )}
          </tbody>
        </table>

        {selectedCard && (
          <TechnicalSheetDialog
            maxWidth={null}
            open={
              modalSettings.find(
                (modal) => modal.name === 'modalTechnicalSheet'
              ).open
            }
            handleClose={() =>
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'modalTechnicalSheet'
                    ? { ...modal, open: false }
                    : modal
                )
              )
            }
            cardDataTable={cardDataTable}
            cardTransformation={cardTransformation}
            selectedCard={selectedCard}
            ucPrimarySelectedId={ucPrimarySelectedId}
          />
        )}
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(CardsActions, dispatch);

export default connect(mapStateToProps)(Structure);
