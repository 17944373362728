const dataSortable = (data, productType = false) => {
  if (productType) {
    const newDataSortable = data.sort((current, nextitem) =>
      current?.products?.description?.localeCompare(
        nextitem?.products?.description,
        'pt-BR',
        { ignorePunctuation: true }
      )
    );

    return newDataSortable;
  }

  const newDataSortable = data.sort((current, nextitem) =>
    current?.description?.localeCompare(nextitem.description, 'pt-BR', {
      ignorePunctuation: true
    })
  );

  return newDataSortable;
};

export default dataSortable;
