/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import { Document, Page, pdfjs } from 'react-pdf';

import MoreInfoArrowBlue from 'images/icons/arrows/moreInfoArrow.svg';
import MoreInfoArrowDisabled from 'images/icons/arrows/moreInfoArrowGray.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SinglePage({ pdf }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <>
      <Document
        file={pdf}
        options={{ workerSrc: '/pdf.worker.js' }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading="Carregando..."
      >
        <Page loading="Carregando..." pageNumber={pageNumber} />
      </Document>

      <div className="dFlex justifyCenter alignCenter mt20">
        <img
          className={`mr10 ${pageNumber <= 1 ? 'cursorAuto' : 'cursorPointer'}`}
          style={{ rotate: '90deg', width: 20 }}
          src={pageNumber <= 1 ? MoreInfoArrowDisabled : MoreInfoArrowBlue}
          onClick={() => {
            if (pageNumber <= 1) return;

            previousPage();
          }}
          alt="Anterior"
        />

        <p className="textAlignCenter bold mb0">
          {pageNumber || (numPages ? 1 : '--')} de {numPages || '--'}
        </p>

        <img
          className={`ml10 ${
            pageNumber >= numPages ? 'cursorAuto' : 'cursorPointer'
          }`}
          style={{ rotate: '-90deg', width: 20 }}
          src={
            pageNumber >= numPages ? MoreInfoArrowDisabled : MoreInfoArrowBlue
          }
          onClick={() => {
            if (pageNumber >= numPages) return;

            nextPage();
          }}
          alt="Anterior"
        />
      </div>
    </>
  );
}
