/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import SelectRestaurantDialog from 'components/Dialogs/SelectRestaurantDialog';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as UserActions from 'store/actions/user';
import { changeFilter as changeFilterCard } from 'store/reducers/cardsFilterSlice';
import { updateFilter as changeFilterCount } from 'store/reducers/countFilterSlice';
import { changeFilter as changeFilterProduction } from 'store/reducers/productionsFilterSlice';
import { changeFilter as changeFilterProduct } from 'store/reducers/productsFilterSlice';
import Environment from 'utils/environments';
import history from 'utils/history';

function CompaniesCombo({ userInfo, companiesActive, responsive }) {
  const { id: getId } = useParams();
  const locPages = useLocation();
  const dispatch = useDispatch();
  const environments = Environment(process.env.REACT_APP_ENV);
  const companies = userInfo.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat();
  const [anchorEl, setAnchorEl] = useState(null);
  const [updatedRestaurantName, setUpdatedRestaurantName] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(userInfo.companiesActive);
  const [collapsed, setCollapsed] = useState(false);
  const isProvider = userInfo?.profile?.[0]?.[0]?.name === 'providers';
  const isQuoter = userInfo?.profile?.[0]?.[0]?.name === 'quoter';
  const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';
  const storedCardsFilters = useSelector(
    (currState) => currState.cardsFilter.filters
  );
  const storedProductsFilters = useSelector(
    (currState) => currState.productsFilter.filters
  );
  const storedProductionsFilter = useSelector(
    (currState) => currState.productionsFilter.filters
  );
  const storedCountsFilter = useSelector(
    (currState) => currState.countFilter.filters
  );

  const [openSelectRestaurantDialog, setOpenSelectRestaurantDialog] = useState(
    !isQuoter
  );

  const { path } = useRouteMatch();

  const transformText = (text) =>
    text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[çÇ]/g, 'c');

  const getCurrentCardIdOfRestaurant = (
    param,
    description,
    originPage,
    currentStoreId
  ) => {
    axiosInstanceCatalog
      .get(
        `${environments.catalog}/cards?cardDescription=${description}&page=1&size=10&originId=${currentStoreId}&autoComplete=true`
      )
      .then((response) => {
        const currentCard = response?.data?.content?.length
          ? response?.data?.content?.find(
              (el) =>
                transformText(el?.description) === transformText(description)
            )
          : '';

        if (originPage === 'cards') {
          dispatch(
            changeFilterCard({
              param,
              value: currentCard?.id || '0',
              textValue: description
            })
          );
        } else if (originPage === 'products') {
          dispatch(
            changeFilterProduct({
              param,
              value: currentCard?.id || '0',
              textValue: description
            })
          );
        } else if (originPage === 'production') {
          dispatch(
            changeFilterProduction({
              param,
              value: currentCard?.id || '0',
              textValue: description
            })
          );
        } else {
          dispatch(
            changeFilterCount({
              param,
              value: currentCard?.id || '0',
              textValue: description
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrentProductIdOfRestaurant = (
    param,
    description,
    originPage,
    currentStoreId
  ) => {
    axiosInstanceCatalog
      .get(
        `${environments.catalog}/products?productDescription=${description}&page=1&size=10&originId=${currentStoreId}&autoComplete=true`
      )
      .then((response) => {
        const currentProduct = response?.data?.content?.length
          ? response?.data?.content?.find(
              (el) =>
                transformText(el?.description)?.toUpperCase() === transformText(description)?.toUpperCase()
            )
          : '';

        if (originPage === 'cards') {
          dispatch(
            changeFilterCard({
              param,
              value: currentProduct?.id || '0',
              textValue: description
            })
          );
        } else if (originPage === 'products') {
          dispatch(
            changeFilterProduct({
              param,
              value: currentProduct?.id || '0',
              textValue: description
            })
          );
        } else if (originPage === 'production') {
          dispatch(
            changeFilterProduction({
              param,
              value: currentProduct?.id || '',
              textValue: description
            })
          );
        } else {
          dispatch(
            changeFilterCount({
              param,
              value: currentProduct?.id || '0',
              textValue: description
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setCurrentItems = (
    isCardFilter,
    currentStoredFilter,
    originPage,
    store
  ) => {
    if (isCardFilter) {
      getCurrentCardIdOfRestaurant(
        currentStoredFilter[0]?.filterName,
        currentStoredFilter[0]?.textValue,
        originPage,
        store
      );
    } else {
      getCurrentProductIdOfRestaurant(
        currentStoredFilter[0]?.filterName,
        currentStoredFilter[0]?.textValue,
        originPage,
        store
      );
    }

    localStorage.setItem(
      currentStoredFilter[0]?.filterName,
      currentStoredFilter[0]?.textValue
    );
  };

  function handleClick(store, mainObj) {
    const storeFormatted =
      store instanceof Array
        ? {
            id: store?.map((el) => el?.id),
            name: mainObj.name,
            index: store?.map((el) => el?.index),
            segment: store?.map(el => el?.segment)
          }
        : {
            id: store.id,
            name: store.name,
            index: store.index,
            segment: store?.segment
          };
    
    const hasIdOnStoredCardsFilter = storedCardsFilters?.filter((el) =>
      el?.filterName?.includes('Id')
    );
    const hasIdOnStoredProductsFilter = storedProductsFilters?.filter((el) =>
      el?.filterName?.includes('Id')
    );
    const hasIdOnStoredProductionsFilter = storedProductionsFilter?.filter(
      (el) => el?.filterName?.includes('Id')
    );
    const hasIdOnStoredCountsFilter = storedCountsFilter?.filter((el) =>
      el?.filterName?.includes('Id')
    );

    if (hasIdOnStoredCardsFilter?.length) {
      setCurrentItems(
        hasIdOnStoredCardsFilter[0]?.filterName?.includes('card'),
        hasIdOnStoredCardsFilter,
        'cards',
        storeFormatted?.id
      );
    }

    if (hasIdOnStoredProductsFilter?.length) {
      setCurrentItems(
        hasIdOnStoredProductsFilter[0]?.filterName?.includes('card'),
        hasIdOnStoredProductsFilter,
        'products',
        storeFormatted?.id
      );
    }

    if (hasIdOnStoredProductionsFilter?.length) {
      setCurrentItems(
        hasIdOnStoredProductionsFilter[0]?.filterName?.includes('card'),
        hasIdOnStoredProductionsFilter,
        'production',
        storeFormatted?.id
      );
    }

    if (hasIdOnStoredCountsFilter?.length) {
      setCurrentItems(
        hasIdOnStoredCountsFilter[0]?.filterName?.includes('card'),
        hasIdOnStoredCountsFilter,
        'counts',
        storeFormatted?.id
      );
    }

    companiesActive(
      storeFormatted?.id,
      storeFormatted?.name,
      storeFormatted?.index,
      storeFormatted?.segment
    );
    let newUrl = path.replace(/\:.*$/, storeFormatted?.id);

    if (newUrl?.includes('/inventario/contagem-diaria/')) {
      newUrl = `/inventario/contagens/${storeFormatted?.id}`;
    }

    history.push(newUrl, {
      selectdItems: storeFormatted?.id,
      prevPath: 'menuHeader'
    });
  }

  const handleClickListItem = (event) => {
    if (isQuoter && path !== '/pedidos/:id') return;
    setAnchorEl(event.currentTarget);
    setCollapsed(!collapsed);
  };

  const handleMenuItemClick = (e, store, mainObj) => {
    const storeFormatted = store instanceof Array ? mainObj : store;
    setSelectedIndex(storeFormatted);
    setAnchorEl(null);
    handleClick(store, mainObj);
    setCollapsed(!collapsed);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCollapsed(!collapsed);
  };

  const validPath = () => 
      path.includes('ficha') 
      || path.includes('sku')
      || path.includes('produto')
      || path.includes('categorias')
      || path.includes('pedidos') 
      || path.includes('cardapio') 
      || path.includes('estoque')
      // || path.includes('producao')
      || path.includes('fornecedores')
      || path.includes('producao')
      || path.includes('usuarios')
      || path.includes('tipo-descarte')
      || path.includes('processos')
      || path.includes('relatorio-transferencias')

  // const validPath = () => path.includes('catalogo') 
  //     || path.includes('pedidos') 
  //     || path.includes('estoque') 
  //     || path.includes('cardapio') 
  //     || path.includes('estoque')
  //     || path.includes('producao')
  //     || path.includes('fornecedores')
  //     || path.includes('usuarios')
  //     || path.includes('tipo-descarte')

  useEffect(() => {
    history.push(locPages, {
      selectdItems: userInfo.companies.groups[0].subGroups[0].stores[0]
    });
  }, []);

  useEffect(()=>{
    if(JSON.parse(localStorage.getItem('companiesActive'))?.value?.id === undefined){
      setOpenSelectRestaurantDialog(true);
    }
  },[]);

  useEffect(() => {
    const formattedName =
      userInfo?.companiesActive?.id instanceof Array
        ? userInfo?.companiesActive?.name
        : companies.find((el) => el?.id === parseFloat(getId))?.name;
    setUpdatedRestaurantName(formattedName);
  }, [userInfo.companiesActive]);

  useEffect(() => {
    const isGroupOrSubgroup = userInfo?.companiesActive?.id instanceof Array
    const firstStore = userInfo.companies?.groups[0].subGroups[0]?.stores[0]

    if (isGroupOrSubgroup && validPath()) return null

    if (isGroupOrSubgroup) {
      handleClick(firstStore)
      setUpdatedRestaurantName(firstStore?.name)

      return null
    }

    const formattedName =
      userInfo?.companiesActive?.id instanceof Array
        ? userInfo?.companiesActive?.name
        : companies.find((el) => el?.id === parseFloat(getId))?.name;

    setUpdatedRestaurantName(isGroupOrSubgroup ? firstStore.name : formattedName)
  }, [path])

  return (
    <>
      <List
        component="nav"
        aria-label="Device settings"
        style={{ display: 'inline-flex', padding: 0 }}
      >
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
          style={{
            height: 'auto',
            color: '#FFF',
            textAlign: 'left',
            fontFamily: 'Lato',
            padding: 0
          }}
        >
          <ListItemText
            className="list-companies-text cursorPointer"
            primary={
              isQuoter
                ? userInfo?.companies?.groups[0]?.name
                : updatedRestaurantName || selectedIndex?.name
            }
            style={{ fontFamily: 'Lato' }}
            primaryTypographyProps={{ style: { fontFamily: 'Lato' } }}
          />
        </ListItem>

        {(!isProvider || isQuoter) && userInfo.companies.groups[0].id ? (
          isQuoter ? null : collapsed ? (
            <ExpandLess
              onClick={handleClickListItem}
              className={
                'sidebar-item-expand-arrow' +
                ' sidebar-item-expand-arrow-expanded'
              }
            />
          ) : (
            <ExpandMore
              onClick={handleClickListItem}
              className="sidebar-item-expand-arrow"
            />
          )
        ) : null}
      </List>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="restaurantHeaderSelect"
      >
        {userInfo.companies.groups.map((group, index) => (
          <>
            {group.hasOwnProperty('id') && (
              <ListSubheader
                onClick={(event) => {
                  if (!validPath()) return null

                  handleMenuItemClick(
                    event,
                    group?.subGroups?.map((subGroup) =>
                      subGroup?.stores?.map((store) => store)?.flat()
                    )?.flat(),
                    group
                  )
                }}
                key={index}
                className={`groupHeader ${validPath() && 'cursorPointer'}`}
              >
                {group.name}
              </ListSubheader>
            )}

            {group?.subGroups
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              ?.map((subGroup, index) => (
                <>
                  {subGroup.hasOwnProperty('id') && (
                    <ListSubheader
                      onClick={(event) => {
                        if (!validPath()) return null

                        handleMenuItemClick(
                          event,
                          subGroup?.stores?.map((el) => el),
                          subGroup
                        )
                      }}
                      key={index}
                      className={`subGroupHeader ${validPath() && 'cursorPointer'}`}
                    >
                      {subGroup.name}
                    </ListSubheader>
                  )}

                  {subGroup?.stores
                    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                    ?.map((store, index) => (
                      <MenuItem
                        className="storeHeader"
                        key={index}
                        disabled={store.id === userInfo?.companiesActive?.id}
                        selected={store.id === userInfo?.companiesActive?.id}
                        onClick={(event) => handleMenuItemClick(event, store)}
                        style={{ fontFamily: 'Lato', width: '250px' }}
                      >
                        {store.name}
                      </MenuItem>
                    ))}
                </>
              ))}
          </>
        ))}
      </Menu>

      {openSelectRestaurantDialog &&
        !responsive &&
        !userInfo?.firstSelectRestaurant &&
        !isAdmin &&
        companies.length > 1 && (
          <SelectRestaurantDialog
            openModal={openSelectRestaurantDialog}
            setOpenSelectRestaurantDialog={setOpenSelectRestaurantDialog}
            setSelectedIndex={setSelectedIndex}
            setAnchorEl={setAnchorEl}
          />
        )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesCombo);
