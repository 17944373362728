/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';

import Axios from 'axios';

const useAxiosFetch = ({
  endpoint,
  params = {},
  enabled = true,
  appendEndpoint,
  requestConfig,
  onSuccess,
  onError,
  onSettled
}) => {
  const [queryParams, setQueryParams] = useState(params);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [reload, setReload] = useState(0);

  const getParamsList = (paramsObj) => {
    const paramsArr = Object.keys(paramsObj);
    return paramsArr
      .map((paramKey) => `${paramKey}=${paramsObj[paramKey]}`)
      .join('&');
  };

  const flatParams = queryParams && getParamsList(queryParams);

  const query = `${endpoint}${appendEndpoint ? `/${appendEndpoint}` : ''}${
    flatParams ? `?${flatParams}` : ''
  }`;

  const refetch = useCallback((newParams) => {
    setReload((prev) => prev + 1);

    if (newParams) {
      setQueryParams((prevState) => ({
        ...prevState,
        ...newParams
      }));
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    if (!query || !enabled) {
      setIsLoading(false);
      return () => controller.abort();
    }

    const fetchData = async () => {
      try {
        const response = await Axios.get(query, {
          ...requestConfig,
          signal: controller.signal
        });

        setData(response.data);
        onSuccess?.(response.data);
      } catch (err) {
        setError(err.message);
        onError?.(err);
        console.log('error: ', err.message);
      } finally {
        setIsLoading(false);
        onSettled?.();
      }
    };

    fetchData();

    return () => controller.abort();
  }, [enabled, reload]);

  useEffect(() => {
    window.addEventListener('focus', () => refetch);

    return () => window.removeEventListener('focus', refetch);
  }, [refetch]);

  return { data, isLoading, error, refetch };
};

export default useAxiosFetch;
