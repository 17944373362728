import finishedIcon from 'images/icons/signals/checks/finishedIconRounded.svg';
import cancelIcon from 'images/icons/signals/closesAndCancels/canceledIconRounded.svg';
import ellipsisIcon from 'images/icons/signals/moreInfos/ellipsisIconRounded.svg';
import pauseIcon from 'images/icons/signals/pausesAndStops/pauseIconRounded.svg';
import playIcon from 'images/icons/signals/playsAndStarts/playIconRounded.svg';

import * as S from './style';

const statusDay = (status) => {
    if (status === 'WAITING') {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                }}
            >
                <S.Status color='#9296AC'>
                    <img style={{ width: '16px' }} src={playIcon} alt="playIcon" />
                    <p style={{ color: '#FFFFFF', margin: 0, fontSize: '10px' }}>
                        Iniciar
                    </p>
                </S.Status>
            </div>
        );
    }
    if (status === 'START') {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                }}
            >
                <S.Status color='#7583F0'>
                    <img
                        style={{ width: '16px' }}
                        src={ellipsisIcon}
                        alt="ellipsisIcon"
                    />
                    <p style={{ color: '#FFFFFF', margin: 0, fontSize: '10px' }}>
                        Produzindo
                    </p>
                </S.Status>
            </div>
        );
    }
    if (status === 'FINISH') {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                }}
            >
                <S.Status color='#3CDD49'>
                    <img
                        style={{ width: '16px' }}
                        src={finishedIcon}
                        alt="finishedIcon"
                    />
                    <p style={{ color: '#FFFFFF', margin: 0, fontSize: '10px' }}>
                        Finalizado
                    </p>
                </S.Status>
            </div>
        );
    }
    if (status === 'CANCELED') {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                }}
            >
                <S.Status color='#F53D4C'>
                    <img style={{ width: '16px' }} src={cancelIcon} alt="cancelIcon" />
                    <p style={{ color: '#FFFFFF', margin: 0, fontSize: '10px' }}>
                        Cancelado
                    </p>
                </S.Status>
            </div>
        );
    }
    if (status === 'PAUSE') {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                }}
            >
                <S.Status color='#F2B90D'>
                    <img style={{ width: '16px' }} src={pauseIcon} alt="cancelIcon" />
                    <p style={{ color: '#FFFFFF', margin: 0, fontSize: '10px' }}>
                        Pausado
                    </p>
                </S.Status>
            </div>
        );
    }
    if (status === 'DEFAULT') {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px'
                }}
            >
                <S.Status color='#DADDF3' />
            </div>
        );
    }
    return '-';
};

export default statusDay