/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import Button from 'components/BloomForms/Button';
import CloseWhiteIcon from 'images/icons/signals/closesAndCancels/closeModal.svg';

import {
  OtherStocksWrapper,
  Content,
  ContentHead
} from '../OtherStocksDialog/index.style';

function PendingCountsDialog({
  reference = null,
  onClose,
  handleDeletePreviousCount
}) {
  return (
    <OtherStocksWrapper>
      <Content ref={reference}>
        <ContentHead>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              Existem contagens pendentes do dia anterior. Deseja continuar?
            </div>

            <div>
              <img src={CloseWhiteIcon} alt="Close icon" onClick={onClose} />
            </div>
          </div>
        </ContentHead>

        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '12px' }}
        >
          <Button label="Não" secondary onClick={onClose} />
          <Button label="Sim" primary onClick={handleDeletePreviousCount} />
        </div>
      </Content>
    </OtherStocksWrapper>
  );
}

export default PendingCountsDialog;
