const common = {
  save: 'Salvar',
  cancel: 'Cancelar',
  confirmed: 'Confirmar',
  ErrorToRecord: 'Erro ao cadastrar',
  TypeHere: 'Digite aqui...',
  select: 'Select...',
  succussesRegister: 'Succusses Registered!',
  back: 'Voltar',
  name: 'Nome',
  200: 'Cadastro Realizado com Sucesso',
  201: 'Cadastrado com Sucesso',
  400: 'Requisição Inválida',
  401: 'Não Autorizado',
  403: 'Acesso Proibido',
  404: 'Não Encontrado',
  500: 'Erro Interno do Servidor',
  503: 'Serviço Indisponível',
  504: 'Erro Interno do Servidor'
};

export default common;
