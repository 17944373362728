/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';

import {
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { DeleteOutline, ExpandLess, ExpandMore } from '@material-ui/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';

import VirtualProduction from 'images/icons/signals/alerts/alertWhiteLightBlueBgCircle.svg';
import { isEmpty } from 'utils/isEmpty';
import ValidationLength from 'utils/utils';

import * as S from './styles';

import TotalByTypeRow from '../TotalByTypeRow';

function TheoryOrRealStockRow({
  extractItems,
  setExtractItems,
  index,
  isLastIndex,
  item,
  productModal,
  checkConsume,
  extractType,
  fetchCards,
  extractDateAndDescription,
  analyticFilter,
  handleGetDiffs,
  differenceInExtractTooltip,
  setDifferenceInExtractTooltip,
  getId,
  extractStartDate,
  extractEndDate,
  handleClickSales,
  modalSettings,
  setModalSettings,
  setDrillDownInfo,
  drillDownInfo,
  setSelectedItem,
  showColumns,
  isMobileScreen
}) {
  const isBetween = (currentValue, min, max) => currentValue >= min && currentValue <= max;

  const stockDifferenceStyle = (extractItem) => {
    const lossPercentage = extractItem?.product?.lossPercentage
    const variationPercentage = extractItem?.cards?.lossPercentage || extractItem?.product?.lossPercentage || 0

    const diff = extractItem?.quantity
    const consumed = extractItem?.consumed || extractItem?.product?.historical?.some((el) => el?.consumed)
      ? parseFloat(extractItem?.product?.historical[0]?.consumed)
      : '';

    const percentage = consumed ? (diff / consumed) * 100 : 0;

    const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];
    let [textColor, bgColor, text] = '';

    const diffPer = extractItem.diffQuantity || 0
    // const diffPer = extractItem.quantity - (extractItem?.product?.yield || extractItem?.yield || 0)
    // const diffPercentCalc = (100 * diffPer) / extractItem?.product?.yield;
    const diffPercentCalc = extractItem.diffPercent || 0

    const max = variationPercentage || 0;
    const min = variationPercentage ? variationPercentage * -1 : 0;
    const isBtw = isBetween(diffPercentCalc, min, max);
    const notDiff = diffPer === 0 || diffPer === NaN

    if (percentage >= range[0] && percentage <= range[1]) {
      bgColor = '#D4F7EB';
      textColor = '#089163';
      text = 'Sem diferença';
    } else if (percentage >= lossPercentage) {
      bgColor = '#FFF2CC';
      textColor = '#AA8208';
      text = 'Sobra';
    } else {
      bgColor = '#F7D4D7';
      textColor = '#F53D4C';
      text = 'Falta';
    }

    if (extractItem?.description === 'Produzido') {
      if (notDiff) {
        bgColor = "#E6E7F0"
        textColor = "#0E1337"
        text = "Sem diferença"
      } else {
        if (isBtw) {
          bgColor = '#D4F7EB';
          textColor = '#089163';
          text = 'Sem diferença';
        } else if (diffPer > 0) {
          bgColor = '#FFF2CC';
          textColor = '#AA8208';
          text = 'Sobra';
        } else {
          bgColor = '#F7D4D7';
          textColor = '#F53D4C';
          text = 'Falta';
        }
      }
    } else if (!consumed) {
      bgColor = diff === 0 ? '#D4F7EB' : diff > 0 ? '#FFF2CC' : '#F7D4D7';
      textColor = diff === 0 ? '#089163' : diff > 0 ? '#AA8208' : '#F53D4C';
      text = diff === 0 ? 'Sem diferença' : diff > 0 ? 'Sobra' : 'Falta';
    }

    return {
      color: textColor,
      background: bgColor,
      textFormatted: text
    };
  };

  const hasActionOnClick = (type) =>
    type === 'Venda' ||
    type === 'Compra' ||
    type === 'Descarte' ||
    type === 'Consumido em produção' ||
    type === 'Produzido';

  const hasActionOnClickLink = (type, validateGroup) =>
    type === 'Venda' && !validateGroup ||
    type === 'Compra' && !validateGroup ||
    type === 'Descarte' ||
    type === 'Consumido em produção' ||
    type === 'Produzido';

  const handleClickDiscard = (item, productModal, extractItems) => {
    setModalSettings((modal) =>
      modal.map((el) =>
        el.name === 'updateDiscard'
          ? {
              ...el,
              open: true,
              extract: item,
              extractItems,
              discard: item?.discard,
              product: item?.product,
              date: item?.date,
              quantity: item?.quantity,
              unity:
                productModal?.consumptionUnitsPrimary?.abbreviation ||
                productModal.consumptionUnitsPrimary
            }
          : el
      )
    );
  };


  const deleteDiscard = (itemExtract) => {
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'confirmDialog'
          ? {
              ...modal,
              open: true,
              body: {
                extract: itemExtract,
                discard: itemExtract?.discard,
                product: itemExtract?.product,
                quantity: itemExtract?.quantity
              }
            }
          : modal
      )
    );
  };

  const handleClickByType = (extractItem, validateGroup) => {
    if (extractItem?.description === 'Venda' && !validateGroup) {
      handleClickSales(extractItem);
    }

    if (extractItem?.description === 'Compra' && !validateGroup) {
      const productIds = !isEmpty(productModal.productIds) ? productModal.productIds : productModal.id
      window.open(
        `/recebimento/${getId}?listReceivedQueryParam=false&productId=${productIds}&startAt=${moment(extractItem?.date).format('YYYY-MM-DD')}&endAt=${moment(
          extractItem?.date
        ).format('YYYY-MM-DD')}&dateType=true&status=RECEIVED`,
        '_blank'
      );
    }

    if (extractItem?.description === 'Descarte') {
      handleClickDiscard(extractItem, productModal);
    }

    if (extractItem?.description === 'Consumido em produção') {
      const productIds = !isEmpty(productModal.productIds) ? productModal.productIds : productModal.id
      window.open(
        `/producao/${getId}?productId=${productIds}&startAt=${moment(
          extractItem?.date
        ).format('YYYY-MM-DD')}&endAt=${moment(extractItem?.date).format(
          'YYYY-MM-DD'
        )}&status=FINISH&deflectType=CONSUMED_PRODUCTION`,
        '_blank'
      );
    }

    if (extractItem?.description === 'Produzido') {
      window.open(
        `/producao/${getId}?cardId=${extractItem?.cards?.cardId}&cardDescription=${extractItem?.cards?.cardDescription}&startAt=${moment(
          extractItem?.date
        ).format('YYYY-MM-DD')}&endAt=${moment(extractItem?.date).format(
          'YYYY-MM-DD'
        )}&status=FINISH&deflectType=PRODUCTION_PRODUCED`,
        '_blank'
      );
    }

    if(["Falta", "Sobra"].includes(extractItem?.description) && extractItem?.counts){
      const [countInfo = undefined] = extractItem?.counts

      const countId = countInfo ? countInfo.countId : undefined
      const countDate = extractItem?.date
      const productDescription = extractItem?.product?.description
      const assortmentUser = countInfo ? countInfo.assortment?.user : undefined
      const assortmentDate = countInfo ? countInfo.assortment?.date : undefined

      if (countInfo) {
        window.open(`/inventario/contagem-diaria/${getId}/${countId}?countDate=${countDate}&descriptionName=${productDescription}&countAssortDate=${assortmentDate}&countAssortUser=${assortmentUser}&forceReport=true`, "_blank")
      }
    }
  };

  const showCollapseButton =
    !analyticFilter &&
    extractItems.filter((el) => el.parentItem === item.isParent).length &&
    !isLastIndex
    // isHeader;

  const isEven = (num) => num % 2 === 0

  const formattedDayOfWeek = useMemo(() => {
    const formattedDate = moment(new Date(item.date), 'D_M_YYYY')
      .add(3, 'hours')
      .format('dddd');

    if (isMobileScreen) {
      return formattedDate?.substring(0,3)
    }

    if (formattedDate.includes('-feira')) {
      return formattedDate.replace(/-feira/g, '');
    }

    return formattedDate;
  }, [item.date]);

  const cellStyle = (isLastExtractItemIndex, extractItem) => {
    const backgroundColor = !index || index === 0 || isEven(index) ? '#FFF' : 'rgba(218, 221, 243, .5)'
    const isTheoreticalStock = extractItem?.type === 'THEORICAL';

    if (isLastExtractItemIndex) {
      return {
        background: backgroundColor,
        color: isTheoreticalStock ? '#9296AC' : '#0F1225',
        fontWeight: 'bold',
        borderBottom: 'solid 1px #0E1337',
        fontStyle: isTheoreticalStock ? 'italic' : 'normal' 
      };
    }

    return {
      background: backgroundColor,
      cursor: 'pointer',
      borderBottom: 'solid 1px #D2D3D7',
    };
  };

  const itemValue = (extractItem) => {
    let signal = '';
    let value = extractItem?.value || 0;

    if (isLastIndex && extractItem?.description !== 'Contagem') {
      signal = extractItem?.quantity < 0 ? '-' : extractItem?.description?.includes('Transferido para')|| extractItem.description === 'DEVOLVIDO PARA' ? '-' : '+';
    }

    if (!isLastIndex) {
      value = Math.abs(value);
    }

    return {
      valueFormatted: `
        ${signal} 
        R$ 
        ${parseFloat(value).toLocaleString('pt-br', {
          minimumFractionDigits: 3
        })}
      `,
      costFormatted: `
        ${signal} 
        R$ 
        ${parseFloat(Math.abs(value / extractItem?.quantity) || 0).toLocaleString(
          'pt-br',
          {
            minimumFractionDigits: 3
          }
        )}
      `,
      value: parseFloat(value)
    };
  };

  const collapseButton = useMemo(() => {
    if (item.opened) {
      return (
        <ExpandLess
          className="cursorPointer"
          onClick={() => {
            setExtractItems(
              extractItems.map((el, elIndex) => {
                if (index === elIndex || el?.parentItem === item.isParent) {
                  return {
                    ...el,
                    opened: !el?.opened,
                    hideItem: !el?.hideItem
                  };
                }

                return {
                  ...el
                };
              })
            );
          }}
        />
      );
    }

    return (
      <ExpandMore
        className="cursorPointer"
        onClick={() => {
          setExtractItems(
            extractItems.map((el, elIndex) => {
              if (index === elIndex || el?.parentItem === item.isParent) {
                return {
                  ...el,
                  opened: !el?.opened,
                  hideItem: !el?.hideItem
                };
              }

              return {
                ...el
              };
            })
          );
        }}
      />
    );
  }, [extractItems, index, item.isParent, item.opened, setExtractItems]);

  const validateGroup = getId?.includes(',');

  const formatStockDiffType = (lossPercentage, diff, consumed, element) => {
    let textFormatted = element?.description

    if (element?.type === 'Diferença de estoque') {
      textFormatted = stockDifferenceStyle(lossPercentage, diff, consumed)?.textFormatted
    }

    if (element?.type === "Estoque Inicial") {
      textFormatted = "Estoque Inicial"
    }

    return textFormatted
  }

  return (
    <>
      {item?.extracts?.map((extractItem, extractItemIndex) => {
        const isFirstExtractItem = extractItemIndex === 0;
        const isLastItem = index === extractItems?.length - 1;
        const isLastExtractItem = extractItemIndex === item?.extracts?.length - 1;
        const isTheoreticalStock = extractItem?.type === 'THEORICAL';
        const upperCaseDescription = extractItem?.type === "END" || isTheoreticalStock
        const isStockDifference = extractItem?.type === 'SURPLUS' || extractItem?.type === 'LACK' || extractItem?.type === 'NO_DIFF';
        const isStockDifferenceColor = isStockDifference || extractItem?.description === 'Produzido';

        function subtractHours(timeString, hoursToSubtract) {
          // Converter a string para um objeto Date
          const [hours, minutes, seconds] = timeString.split(':');
          const date = new Date();
          date.setHours(parseInt(hours));
          date.setMinutes(parseInt(minutes));
          date.setSeconds(parseInt(seconds));
          
          // Subtrair as horas
          date.setHours(date.getHours() - hoursToSubtract);
          
          // Formatar de volta para 'HH:mm:ss'
          const newHours = String(date.getHours()).padStart(2, '0');
          const newMinutes = String(date.getMinutes()).padStart(2, '0');
          const newSeconds = String(date.getSeconds()).padStart(2, '0');
          
          return `${newHours}:${newMinutes}`;
      }

        const isHeader =
          item.type === 'Estoque Inicial'
        
        return (
          <tr
            className={`withBorder ${
              !analyticFilter &&
              extractItem?.hideItem &&
              'dNone'
            }`}
            id={`dialogStock-${`${extractItem?.description}-${index}`}`}
          >
            <td style={cellStyle(isLastExtractItem, extractItem)} className={`description pr12 pl16 ${!isLastExtractItem && 'disableBorder'}`}>
              <div
                role="button"
                tabIndex={0}
                style={{
                  cursor: extractItem?.description === 'Venda' ? 'pointer' : 'auto'
                }}
              >
                <Grid container spacing={0} className="p0">
                  <Grid container item xs={11} spacing={0} className="bold">
                    {(isFirstExtractItem || (!analyticFilter && !extractItem?.hideItem && isLastItem)) &&
                      <>
                        {isMobileScreen
                          ? moment(item.date).format('DD/MM') 
                          : extractDateAndDescription(item)
                        }

                        <span className={`lowercase ml3 ${isTheoreticalStock && 'italic'}`}> 
                          - {formattedDayOfWeek}
                        </span>
                      </>
                    }
                  </Grid>

                  <Grid item xs={1}>
                    {extractItem?.isVirtual ? (
                      <Tooltip
                        title={
                          <>
                            <p className="p0 m0">
                              <b>PRODUÇÃO VIRTUAL</b>
                            </p>

                            <p className="p0 m0">
                              Produção sem controle de inventário.
                            </p>

                            <p className="p0 m0">
                              Apenas para projeção de entrada e saída do estoque.
                            </p>
                          </>
                        }
                        aria-label="filters"
                        placement="right-start"
                      >
                        <img alt="" src={VirtualProduction} />
                      </Tooltip>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
            </td>

            <td
              style={cellStyle(isLastExtractItem, extractItem)}
              className={`${
                hasActionOnClick(extractItem?.description) && 'materialBlue cursorPointer'
              } description right pr10 pl0`}
            >
              <S.StockDifference
                className="dFlex justifyBetween alignCenter"
                background={
                  isStockDifference &&
                  stockDifferenceStyle(
                    extractItem
                  ).background
                }
                border={isStockDifference}
              >
                <div className="pr0">
                  <span
                    className={`${upperCaseDescription && 'uppercase'} ${isTheoreticalStock && 'italic'} ${
                      hasActionOnClickLink(extractItem?.description, validateGroup) && 'materialBlue cursorPointer'
                    } childTdFontSize14`}
                    style={{
                      color: isTheoreticalStock ? '#9296AC' : '#0F1225'
                    }}
                    onClick={() => {
                      handleClickByType(extractItem, validateGroup);
                      setSelectedItem(extractItem)
                    }}
                  >
                    {!extractItem?.description?.includes('Transferido de') && !extractItem?.description?.includes('Devolvido para') 
                      ? extractItem?.description
                      : ''
                    } 

                    {extractItem?.description?.includes('Transferido para') || extractItem?.description?.includes('Devolvido de') && 
                      ValidationLength(
                      ` ${extractItem?.transfer?.toRestaurantName}`,
                      20
                    )}

                    {(extractItem?.description?.includes('Transferido de') || extractItem?.description?.includes('Devolvido para')) &&
                      <Link
                        id="catalogProductAddButton"
                        style={{color: '#3f51b5'}}
                        target="_blank"
                        to={{
                          pathname: `/recebimento/${extractItem?.transfer?.toRestaurantId}?orderValidId=1&orderId=${extractItem?.transfer?.orderIds}`,
                          state: { orderId: extractItem?.transfer?.orderId}
                        }}
                        onClick={() => localStorage.setItem('orderIdValid', 1)}
                      >
                        {
                          ValidationLength(
                            extractItem?.description?.includes('Transferido de')
                              ? `Transferido de ${extractItem?.transfer?.fromRestaurantName}`
                              : `Devolvido para ${extractItem?.transfer?.fromRestaurantName}`,
                            35
                          )
                        }
                      </Link>
                    }
                  </span>

                  {extractItem?.description === 'Descarte' ? (
                    <>
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        onOpen={() => handleGetDiffs(item)}
                        onClose={() => setDifferenceInExtractTooltip(null)}
                        title={
                          <div
                            className="dFlex flexColumn"
                            style={{ width: '220px', paddingTop: 5 }}
                          >
                            <div
                              className="dFlex flexColumn"
                            >
                              <p className="mb0">
                                Qtde: {' '}
                                {`${extractItem?.quantity?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })}${extractItem?.unity?.abbreviation}`}
                              </p>

                              <p className="mb0">
                                Motivo: {' '}
                                {extractItem?.discard?.description}
                              </p>

                              
                              <p className="mb0">
                                Horário: {' '}
                                {extractItem?.discard?.hour ? subtractHours(extractItem?.discard?.hour, 3) : ''}
                              </p>

                              <span>
                                Usuário: {' '}
                                {extractItem?.discard?.user ? extractItem?.discard?.user : '-'}
                              </span>
                            </div>
                          </div>
                        }
                      >
                        <img
                          alt="Discard Type"
                          src={VirtualProduction}
                          className="ml5"
                        />
                      </Tooltip>

                      <Tooltip title="Excluir descarte">
                        <IconButton
                          color="inherit"
                          onClick={() => {
                            deleteDiscard(extractItem);
                          }}
                          aria-label="add"
                          size="small"
                          style={{
                            outline: '0',
                            background: 'none'
                          }}
                        >
                          <DeleteOutline style={{ width: 16 }} />
                        </IconButton>
                      </Tooltip> 
                    </>
                  ) : null}
                </div>

                <div className="dFlex alignCenter">
                  {extractItem?.diffPercent && isStockDifference ? (
                    <div
                      style={{
                        marginLeft: 10,
                        color: stockDifferenceStyle(
                          extractItem
                        ).color
                      }}
                    >
                      (
                      {(extractItem?.diffPercent || 0).toLocaleString('pt-br', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                      %)
                    </div>
                  ) : null}

                  <div
                    style={{
                      color:
                        (isStockDifferenceColor &&
                          stockDifferenceStyle(
                            extractItem
                          ).color) ||
                        (extractItem?.description === 'Descarte' && '#F53D4C')
                        || isTheoreticalStock && '#9296AC',
                      background:
                        extractItem?.description === 'Produzido' &&
                        stockDifferenceStyle(
                          extractItem
                        ).background,
                      borderRadius: extractItem?.description === 'Produzido' && '17px',
                      padding: extractItem?.description === 'Produzido' && '0 10px',
                      marginRight: extractItem?.description === 'Produzido' && '-10px'
                      
                    }}
                    className={`ml5 ${!validateGroup && 'colorFont'} ${isTheoreticalStock && 'italic'}`}
                    onClick={() => {
                      if (
                        extractItem?.description !== 'Diferença de estoque' &&
                        extractItem?.description !== 'Fechamento do dia' &&
                        extractItem?.description !== 'Estoque teórico' &&
                        extractItem?.description !== 'Contagem'
                      ) {
                        setDrillDownInfo({ ...drillDownInfo, info: extractItem, productsIds: extractItem?.details?.map(el => el?.productId) });

                        if (validateGroup) {
                          setModalSettings(
                            modalSettings.map((modal) =>
                              modal.name === 'drillDownDialog'
                                ? {
                                    ...modal,
                                    open: true
                                  }
                                : modal
                            )
                          );
                        }
                      }
                    }}
                  >
                    {extractItem?.diffPercent && extractItem?.description === 'Produzido' ? (
                      <span
                        style={{
                          marginLeft: 10,
                          color: stockDifferenceStyle(
                            extractItem
                          ).color
                        }}
                      >
                        (
                          {(extractItem?.diffPercent || 0).toLocaleString('pt-br', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })} %
                        ) {' '}
                      </span>
                    ) : null}
                    
                    {extractItem?.description !== 'Contagem' && item?.quantity !== 0
                      ? extractItem?.quantity < 0
                        ? '-'
                        : extractItem?.description?.includes('Transferido para')|| extractItem.description === 'DEVOLVIDO PARA' ? '-' : '+'
                      : null
                    }

                    {
                      parseFloat(
                        Math.abs(extractItem?.quantity || 0)
                      ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
                    }
                    
                    {(productModal?.consumptionUnitsPrimary?.abbreviation)}
                  </div>
                </div>
              </S.StockDifference>
            </td>

            <td style={cellStyle(isLastExtractItem, extractItem)} className={`description right pr16 pl0 ${(isMobileScreen || !showColumns.filter((el) => el.includes('CUSTO'))?.length) && 'dNone'}`}>
              <span className={`${isTheoreticalStock && 'italic'}`}>
                {itemValue(extractItem)?.costFormatted}
              </span>
            </td>

            <td style={cellStyle(isLastExtractItem, extractItem)} className={`description right pr16 pl0 ${(isMobileScreen || !showColumns.filter((el) => el.includes('VALOR UNIT'))?.length) && 'dNone'}`}>
              <span className={`${isTheoreticalStock && 'italic'}`}>
                {itemValue(extractItem)?.valueFormatted}
              </span>
            </td>

            <td style={cellStyle(isLastExtractItem, extractItem)} className={`description right pr16 pl0 ${(isMobileScreen || !showColumns.filter((el) => el.includes('QTDE FINAL'))?.length) && 'dNone'}`}>
              {extractType === 'ALL'
                ? `${parseFloat(
                    extractItem?.finalQuantity || 0
                  ).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
                  
                  ${
                    productModal?.consumptionUnitsPrimary?.abbreviation ||
                    productModal.consumptionUnitsPrimary
                  }`
                : null
              }
            </td>

            <td style={cellStyle(isLastExtractItem, extractItem)} className={`description right pr16 pl0 ${(isMobileScreen || !showColumns.filter((el) => el.includes('VALOR FINAL'))?.length) && 'dNone'}`}>
              {extractType === 'ALL'
                ? `R$ ${parseFloat(
                    extractItem.finalValue || 0
                  ).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}`
                : null
              }
            </td>

            {/* <td style={cellStyle(isLastExtractItem, extractItem)} className="description right pr8 pl0">
              {showCollapseButton ? collapseButton : null}
            </td> */}
          </tr>
        )
      })}

      {!analyticFilter && index === 0 && (
        <TotalByTypeRow
          extractItems={extractItems}
          productModal={productModal}
          item={item}
          handleClickSales={handleClickSales}
          stockDifferenceStyle={stockDifferenceStyle}
          handleClickDiscard={handleClickDiscard}
          deleteDiscard={deleteDiscard}
          differenceInExtractTooltip={differenceInExtractTooltip}
          setDifferenceInExtractTooltip={setDifferenceInExtractTooltip}
          handleGetDiffs={handleGetDiffs}
          isMobileScreen={isMobileScreen}
        />
      )}
    </>
  );
}

export default TheoryOrRealStockRow;
