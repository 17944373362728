const handleMultipleFilters = (
  paramsAndValues,
  filters,
  setFiltersChanged,
  defaultPage,
  callSettingsOptions
) => {
  const newFilters = [...filters];
  const pageParamIndex = filters.findIndex(
    (filter) => filter?.param === 'page'
  );

  paramsAndValues.forEach(([param, value]) => {
    const index = filters.findIndex((filter) => filter?.param === param);
    if (index < 0) {
      if (value) {
        newFilters.push({ param, value, urlParam: `${param}=${value}` });
      }
    } else if (value !== null && value !== '') {
      newFilters[index] = {
        ...filters[index],
        value,
        urlParam: `${param}=${value}`
      };
    } else {
      newFilters.splice(index, 1);
    }
  });

  const hasSetedFilters = filters.find((filter) => {
    const { param, value } = filter;
    if (param !== 'page' && param !== 'originId' && param !== 'size') {
      if (value) {
        return filter;
      }
    }
    return null;
  });

  if (pageParamIndex >= 0 && hasSetedFilters) {
    newFilters[pageParamIndex] = {
      ...filters[pageParamIndex],
      value: defaultPage,
      urlParam: `page=${defaultPage}`
    };
  }

  if (pageParamIndex < 0) {
    if (callSettingsOptions) {
      const { callSettings, setCallSettings } = callSettingsOptions;
      setCallSettings({ ...callSettings, page: defaultPage });
    }
  }

  setFiltersChanged(newFilters);
};
export default handleMultipleFilters;
