/* eslint-disable no-unused-expressions */
import React from 'react';

import { Tooltip, Fade } from '@material-ui/core';

import numberToReal from 'utils/numberToReal';

import { ItemWrapper } from './style';

function ItemSelect({
  productsIds,
  setProductsIds,
  setModalStates,
  modalStates,
  itemName,
  itemUnit,
  action,
  secondAction,
  validColor = true,
  type,
  fractionDigits = 3,
  setCurrentModalTitle,
  modalTitle,
  tooltip,
  itemLength,
  buttonLinkColor,
  inputSize,
  modalToTableProps,
  setTableToModalProps,
  dataToDialog,
  setDataDialog,
  secondContentGray,
  buttonRectColor,
  contentBlueCard,
  underline,
  buttonTooltip,
  fontStyle,
  truncateOff = false,
  setDisabledApproveproduct,
  setCurrentProduct,
  item,
  itens,
  hasValue,
  width,
  setProductGroupId,
  lineProductGroupId
}) {
  return (
    <ItemWrapper
      onClick={() => {
        if (action === 'consumeByStore') {
          dataToDialog && setDataDialog(dataToDialog);
          setModalStates && setModalStates({ ...modalStates, [action]: true });
          productsIds && setProductsIds(productsIds);
          setCurrentModalTitle &&
            itens?.length >= 2 &&
            setCurrentModalTitle(itens[2]?.content);
          setDisabledApproveproduct &&
            itens?.length &&
            setDisabledApproveproduct(itens[0]?.disabledMessage);
          setTableToModalProps && setTableToModalProps(item);
          setCurrentProduct &&
            itens?.length &&
            setCurrentProduct(itens[0]?.item);
        } else {
          dataToDialog && setDataDialog(dataToDialog);
          secondAction && setCurrentProduct(item);
          setModalStates && secondAction
            ? setModalStates({ ...modalStates, [secondAction]: true })
            : setModalStates({ ...modalStates, [action]: true });
          productsIds && setProductsIds(productsIds);
          setCurrentModalTitle && setCurrentModalTitle(modalTitle);
          setTableToModalProps && setTableToModalProps(modalToTableProps);
          lineProductGroupId && setProductGroupId(lineProductGroupId);
        }
      }}
      typeLink={!!action}
      typeLinkColor={!!contentBlueCard || !!buttonRectColor}
      buttonLink={buttonLinkColor || false}
      validColor={validColor}
      inputSize={inputSize}
      secondContentGray={secondContentGray}
      buttonRectColor={buttonRectColor}
      underline={underline}
      fontStyle={fontStyle}
      truncateOff={truncateOff}
      width={width}
    >
      {type === 'text' && itemName}
      {type === 'richNumber' && (
        <>
          {isNaN(parseFloat(itemName)) && (
            <>
              {tooltip && tooltip !== undefined && (
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={tooltip}
                >
                  <span>{itemName}</span>
                </Tooltip>
              )}
              {!tooltip && hasValue && itemName}
            </>
          )}

          {!isNaN(parseFloat(itemName)) && (
            <>
              {tooltip && tooltip !== undefined && (
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={tooltip}
                >
                  <span>{numberToReal(itemName, fractionDigits)}</span>
                </Tooltip>
              )}
              {!tooltip && (
                <span>
                  {numberToReal(itemName, fractionDigits)}
                  <span style={{ fontSize: '12px', color: '#9296AC' }}>
                    {itemUnit}
                  </span>
                </span>
              )}
            </>
          )}
        </>
      )}

      {!type ||
        (type === 'number' && (
          <>
            {tooltip && tooltip !== undefined && (
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={tooltip}
              >
                <span>{itemName}</span>
              </Tooltip>
            )}
            {!tooltip && itemName}
          </>
        ))}
    </ItemWrapper>
  );
}

export default ItemSelect;
