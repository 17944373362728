import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled, { createGlobalStyle } from 'styled-components';

import { mediaQueries } from 'styles/mediaQueries';

export const InputContainer = styled.div`
  margin-right: 0px;
  margin-top: 3px;
  width: auto;
  label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #00020f;
  }

  .MuiAutocomplete-popperDisablePortal {
    top: 30px;
    left: 0px;
    width: 340px !important;
  }

  .MuiInput-underline::before {
    border-bottom: none !important;
  }

  input {
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: #77798b;

    &::placeholder {
      font-style: italic;
    }
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const LocalStyle = createGlobalStyle`
  .desktop {
    .headerSubtitle {
      th,
      td {
        top: -19px !important;
      }
    }

    .headerBorder {
      th {
        top: 39px !important;
      }
    }
  }

  .hideAlertMobile {
    @media (max-width: 1024px) {
      display: none !important;
    }
  }

  &.filterBtn {
    margin-top: 0px !important;
  }

  .tableSkus {
    width: 100%;
    table-layout: auto;
    empty-cells: hide;
    background: #FFF;
    border-collapse: separate;
    border-spacing: 10px 0;
    display: table;
  }

  ::-webkit-scrollbar-track {
    background-color: #AAA;
  }

  ::-webkit-scrollbar {
    width: 0.1px;
  }

  ::-webkit-scrollbar-thumb {
    background: green;
  }

  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .mobileFilterInfo, .tabletFilterInfo {
    display: none;
  }

  @media (max-width: 1024px) {
    .desktopFilterInfo {
      display: none;
    }

    table.mobile {
      .list {
        padding: 3px;
        display: flex;
      }

      .listItem {
        font-family: Lato;
        font-size: 14px;
        color: #000;
        padding: 0;
      }

      .listItems {
        .subtitle {
          font-family: Lato;
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
          padding-right: 10px;
        }

        .description {
          font-family: Lato;
          font-size: 14px;
          line-height: 30px;
        }
      }

      .listItemsRecipe {
        .bigtitle {
          font-family: Lato;
          font-size: 14px;
          font-weight: bold;
          line-height: 35px;
          background: #eaeaea;
        }

        .subtitle {
          font-family: Lato;
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
        }

        .description {
          font-family: Lato;
          font-size: 14px;
          line-height: 30px;
        }
      }

      .listItemsTransformation {
        .bigtitle {
          font-family: Lato;
          font-size: 14px;
          font-weight: bold;
          line-height: 35px;
          background: #eaeaea;
        }

        .subtitle {
          font-family: Lato;
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
        }

        .description {
          font-family: Lato;
          font-size: 14px;
          line-height: 30px;
        }
      }

      .status {
        display: flex;
      }
    }

    .mobilefilter {
      display: flex;
    }

    .numberPreparations {
      font-size: 16px;
    }


    .mobile-btn-no {
        width: 96px;
        height: 44px;
        padding: 12px;
        border-radius: 12px;
        border-color: #5062F0;
        color: #5062F0;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
      }

      .mobile-btn-yes {
        width: 96px;
        height: 44px;
        padding: 12px;
        border-radius: 12px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
      }

      .mobile-close-icon {
        width: 8px !important;
      }
  }

  @media only screen and (max-width: 320px) {
      .inputFilter {
        border-bottom: 1px solid rgb(146, 150, 172);
        display: flex;
        align-items: center;
        margin-right: 15px;
        width: 236px !important;
      }
    }

    @media only screen and (max-width: 480px) {

      .modal-stock .MuiDialog-paper {
        margin: 0px;
      }

      .modal-stock .MuiDialog-paperFullWidth {
        width: 100%;
      }

      .alertMessage {
        margin-top: 67px;
      }

      .mobileFilterInfo {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
      }

      .tabletFilterInfo {
        display: none;
      }

      .inputFilter {
        border-bottom: 1px solid rgb(146, 150, 172);
        display: flex;
        align-items: center;
        margin-right: 10px;
        width: 266px;
      }

      .mobile-title-dialog {
        font-style: normal;
        font-weight: normal;
        font-size: 12px !important;
        line-height: 20px;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mobile-dialog-control-production {
        width: 95% !important;
      }

      .mobile-dialog-control-production .MuiDialog-paper {
        margin: 0px;
      }

      .div-quantity-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 18px;
      }

      .mobile-quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1F2445;
        border-radius: 12px;
        width: 220px;
        height: 44px;
      }

      .div-mobile-quantity-number {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #1F2445;
        border-radius: 12px;
      }

      .mobile-quantity-text {
        color: #fff;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
        padding: 12px;
      }

      .mobile-quantity-number {
        width: 50%;
      }

      .mobile-quantity-number fieldset {
        border: none;
      }

      .mobile-quantity-number input {
        padding: 0px;
        margin: 0px;
      }

      .mobile-title-product {
        width: 100%;
        margin-bottom: 8px;
        word-break: break-all;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }

      .mobile-title-product td, .mobile-title-product div {
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 20px !important;
        width: 50% !important;
        padding: 0px !important;
      }

      .mobile-storage-location-text, .mobile-periodicity-text {
        color: #454A67;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        line-height: 12px;
        margin-bottom: 6px;
      }

      .mobile-storage-location, .mobile-periodicity {
        margin-bottom: 24px;
      }

      .mobile-storage-location input, .mobile-periodicity input {
        padding-left: 8px !important;
      }

      .mobile-div-btn {
        padding-right: 24px;
        justify-content: flex-end;
      }
    }

    @media only screen and (min-width: 768px) {

      &.filterBtn {
        margin-top: 10px !important;
      }
      .tabletFilterInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .tabletFilterInfo .mobilefilter {
        display: flex;
        justify-content: space-between;
      }

      .mobileFilterInfo {
        display: none;
      }

      .inputFilter {
        border-bottom: 1px solid rgb(146, 150, 172);
        display: flex;
        align-items: center;
        margin-right: 15px;
        margin-left: 10px;
        width: 100%;
      }

      .mobile-title-dialog {
        font-style: normal;
        font-weight: normal;
        font-size: 16px !important;
        line-height: 20px;
        margin-right: 10px;
      }

      .div-quantity-description {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 18px;
      }

      .mobile-quantity {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1F2445;
        border-radius: 12px;
        width: 220px;
        height: 44px;
      }

      .div-mobile-quantity-number {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #1F2445;
        border-radius: 12px;
      }

      .mobile-quantity-text {
        color: #fff;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
        padding: 12px;
      }

      .mobile-quantity-number {
        width: 50%;
      }

      .mobile-quantity-number fieldset {
        border: none;
      }

      .mobile-quantity-number input {
        padding: 0px;
        margin: 0px;
      }

      .mobile-title-product {
        width: 50%;
        word-break: break-all;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
      }

      .mobile-title-product td, .mobile-title-product div {
        font-family: Lato !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 16px !important;
        line-height: 20px !important;
        width: 50% !important;
        padding: 0px !important;
      }

      .mobile-storage-location-text, .mobile-periodicity-text {
        color: #454A67;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        line-height: 12px;
        margin-bottom: 6px;
      }

      .mobile-storage-location, .mobile-periodicity {
        margin-bottom: 24px;
      }

      .mobile-storage-location input, .mobile-periodicity input {
        padding-left: 8px !important;
      }

      .mobile-div-btn {
        padding-right: 24px;
        justify-content: flex-end;
      }
    }

    @media only screen and (min-width: 1025px) {
      .alert-desktop {
        display: block;
      }

      .alert-mobile {
        display: none;
      }

      .mobile-btn-no {
        width: 40px;
        border-radius: 8px;
        border-color: #313347;
        color: #313347;
      }

      .mobile-btn-yes {
        width: 40px;
        border-radius: 8px;
      }

      .mobileFilterInfo {
        display: none;
      }

      .tabletFilterInfo {
        display: none;
      }

      .mobile-production-control {
        display: none;
      }

      .desktop-prduction-control {
        display: block;
      }

      .mobile-add-stock-text {
        display: block;
      }

      .mobile-div-btn {
        justify-content: center;
      }

      .mobile-close-icon {
        width: 16px !important;
      }
    }

    @media only screen and (max-width: 1024px) {
      .alert-desktop {
        display: none;
      }

      .alert-mobile {
        display: block;
      }

      .numberPreparations {
      font-size: 12px;
      }

      .desktop-prduction-control {
        display: none !important;
      }

      .mobile-production-control {
        display: block;
        margin-top: 19px;
      }

      .mobile-add-stock-text {
        display: block;
      }
    }

  .tableModal {
    display: inline;

    .bigtitle {
      font-family: Lato;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
    }

    .subtitle {
      font-family: Lato;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
    }

    .description {
      font-family: Lato;
      font-size: 14px;
      line-height: 20px;
    }

    hr {
      margin: 0;
    }
  }`;

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },

  nested: {
    paddingLeft: theme.spacing(4)
  },

  inputRoot: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },

  input: {
    fontFamily: 'Lato',
    fontSize: '12px',
    textAlign: 'right'
  },

  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  }
}));

export const StyledTypography = styled(Typography)`
  background-color: ${(props) =>
    props.controlProduction ? 'transparent' : '#d6d8d9'};
  width: calc(100% + 32px);
  margin: -8px -16px;
  padding: 13px;
`;

export const InputManufactureWrapper = styled.div`
  display: grid;
  justify-content: center;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InputManufactureLabel = styled.label`
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
`;

export const InputManufactureInputContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: baseline;
`;

export const InputManufactureInput = styled.input`
  text-align: ${({ align }) => align || 'left'};
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  border: none;
  border-bottom: solid 1px #333541;
  width: 60px;
  margin: auto;
  outline: none;
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const ProductionCardFilter = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
  border-radius: 8px;

  @media only screen and (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 304px;
    border-radius: 8px;
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 52px;
    border-radius: 8px;
  }

  @media only screen and (min-width: 1025px) {
    width: 100%;
    height: 52px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 5px;
  }

  label {
    margin-bottom: 0;
  }

  @media only screen and (max-device-width: ${mediaQueries.desktop}) {
    margin-bottom: 16px;
    margin-top: 10px;
    margin-right: 8px;
  }
`;

export const ImgIcon = styled.img`
  width: ${(props) => props.widthImg ? props.widthImg : '25px'};
`

export const BoxPlate = styled.div`
  display: flex;
  margin-top: -12px;
  margin-bottom: 10px;
  gap: 7px;
  width: 100%;
`

export const PlateFilter = styled.button`
  background: ${(props) => props.background ? props.background : '#fff'};
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 12px;
  font-weight: 700;
  border: ${(props) => props.activeBorder ? '1px solid #666770' : 'none'};
  border-radius: 4px;
  min-width: 100px;
`