import React from "react";

function SVGStoreIconGreen(props) {
  return <svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.17753 1.25H9.75C10.7165 1.25 11.5 2.0335 11.5 3V10.25C11.5 11.2165 10.7165 12 9.75 12H2.75C1.7835 12 1 11.2165 1 10.25V3C1 2.0335 1.7835 1.25 2.75 1.25H3.32247C3.53761 0.527128 4.20724 0 5 0H7.5C8.29276 0 8.96239 0.527128 9.17753 1.25ZM3.41841 2.75H2.75C2.61193 2.75 2.5 2.86193 2.5 3V10.25C2.5 10.3881 2.61193 10.5 2.75 10.5H9.75C9.88807 10.5 10 10.3881 10 10.25V3C10 2.86193 9.88807 2.75 9.75 2.75H9.08159C8.80073 3.34124 8.1981 3.75 7.5 3.75H5C4.3019 3.75 3.69927 3.34124 3.41841 2.75ZM4.75 1.75C4.75 1.61193 4.86193 1.5 5 1.5H7.5C7.63807 1.5 7.75 1.61193 7.75 1.75V2C7.75 2.13807 7.63807 2.25 7.5 2.25H5C4.86193 2.25 4.75 2.13807 4.75 2V1.75Z"
      fill={props.color}
    />
  </svg>
}

export default SVGStoreIconGreen;