import styled from 'styled-components'; 

export const WrapperContainer = styled.div`
    margin: 5px;
`;

export const FormContainer = styled.div`
    /* background-color: white; 
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    border-radius: 0px 4px 4px 4px; */
    width: ${({width}) => width && width};
    margin: 3px;
`;

export const FormContainerTitle = styled.div`
    color: white; 
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    background-color: #333541;
    border-radius: 4px 4px 0px 0px;
    text-transform: ${({toUppercase}) => toUppercase && 'uppercase'};
    display: flex;
`;
