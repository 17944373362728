/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
import React, {
  useState,
  useEffect,
  Suspense,
  useRef,
  createRef,
  useCallback
} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { format } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import useAxiosFetch from 'hooks/useAxiosFetch';
import useDidMountEffect from 'hooks/useDidMountEffect';
import DownloadIcon from 'images/icons/downloadUpload/download.svg';
import UploadIcon from 'images/icons/downloadUpload/upload.svg';
import UploadGrayIcon from 'images/icons/downloadUpload/uploadGrayBg.svg';
import { GlobalStyle, Container } from 'styles/general';
import Environments from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import MultipleUploadDialog from './dialogs/multipeUploadDialog';
import { InputFile } from './style';

const environments = Environments(process.env.REACT_APP_ENV);

const PrioritySkusDialog = React.lazy(() =>
  import('./dialogs/PrioritySkusDialog')
);

function QuoteSolicitations() {
  const multipleFilesRef = useRef(null);
  const { id: paramsID } = useParams();
  const userInfo = useSelector((currState) => currState.user);

  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const items = JSON.parse(localStorage.getItem('token'));
  const profileType = items.data.profile[0][0].name;

  const [selectedInitialDate, setSelectedInitialDate] = useState(moment());
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().add(7, 'days')
  );
  const [providers, setProviders] = useState([]);
  const [prioritySkus, setPrioritySkus] = useState();
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 100,
    loading: false,
    numberOfElements: 0,
    totalElements: 0
  });
  const { getId, page, size, totalPages, numberOfElements, totalElements } =
    callSettings;

  const [filters, setFilters] = useState([
    {
      param: 'originId',
      value: `${profileType !== 'admin' ? getId : ''}`,
      urlParam: `originId=${profileType !== 'admin' ? getId : ''}`
    },
    {
      param: 'page',
      value: 1,
      urlParam: `page=1`
    },
    {
      param: 'size',
      value: 100,
      urlParam: `size=100`
    },
    {
      param: 'isQuote',
      value: true,
      urlParam: `isQuote=true`
    }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [modalStates, setModalStates] = useState({
    prioritySkus: false,
    multipleUpload: false
  });

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Fornecedores',
        filterName: 'providerName',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.providers}/providers`,
        key: 'providersQuotationAdmin',
        optionReference: 'providerName',
        options: [],
        size: 'medium_plus'
      },

      {
        label: 'SKU',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: isAdminPage
          ? `${environments.catalog}/skus/platform`
          : `${environments.catalog}/skus`,
        key: 'skus',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true
      },

      {
        label: 'Nome do Arquivo',
        filterName: 'quoteFileName',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },

      {
        label: 'Data',
        type: 'dateInputField',
        filterName: 'quoteDate',
        size: 'small',
        resetDateWithDelete: true
      },

      {
        label: 'Validade',
        type: 'dateInputField',
        filterName: 'quoteExpirationDate',
        size: 'small',
        resetDateWithDelete: true
      },

      {
        label: 'Status',
        filterName: 'quoteStatus',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        optionReference: 'name',
        defaultValue: { title: 'Todos', value: '' },
        options: [
          { title: 'Todos', value: 'ALL' },
          { title: 'Recebidos', value: 'FULL UPLOADED' },
          { title: 'Parcialmente recebidos', value: 'PARTIAL UPLOADED' },
          { title: 'Não recebidos', value: 'DOWNLOADED' },
          { title: 'Não baixados', value: 'NOT DOWNLOADED' }
        ],
        size: 'medium_plus',
        isSelect: true
      }
    ]
  ]);
  const [multipleUploadFiles, setMultipleUploadFiles] = useState([]);
  const [expirationTime, setExpirationTime] = useState(7);
  const [currentUploadProviderId, setCurrentUploadProviderId] = useState(null);
  const [listRefs, setListRefs] = useState([]);

  // GET Main Content
  const getMainContent = useCallback(
    (currPage, currSize) => {
      (async () => {
        setCallSettings((prevState) => ({ ...prevState, mainLoading: true }));

        let params = [];

        params = filtersChanged.map((filter) => filter.urlParam).join('&');

        const response = await fetch(
          `${environments.providers}/providersQuotation?page=${currPage}&size=${currSize}&${params}`
        );
        const quotationResponse = await response.json();

        setProviders(quotationResponse.content || []);
        setCallSettings((prevState) => ({
          ...prevState,
          totalPages: quotationResponse.totalPages,
          mainLoading: false,
          totalElements: quotationResponse.totalElements,
          numberOfElements: quotationResponse.numberOfElements
        }));
      })();
    },
    [getId, filtersChanged]
  );

  const resetMultipleFilesRef = () => {
    multipleFilesRef.current.value = null;
    setCurrentUploadProviderId(null);
    const newListRefs = listRefs.map((item) => {
      if (item.current) {
        item.current.value = null;
      }
      return item;
    });
    setListRefs(newListRefs);
    setFiltersChanged(filtersChanged);
    getMainContent(page, size);
  };

  const handleMultipleFileChanges = (event) => {
    const { files } = event.target;
    if (files.length) {
      setMultipleUploadFiles(files);
      setModalStates({ ...modalStates, multipleUpload: true });
    }
  };

  const handleOrdinationFilter = (paramsAndValues) => {
    handleMultipleFilters(
      paramsAndValues,
      filtersChanged,
      setFiltersChanged,
      page
    );
  };

  const handleFilter = (param, value, _, paramSecond) => {
    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!value && (hasFilterWithFirstName || hasFilterWithSecondName)) {
      formattedFilters = filtersChanged?.filter(
        (el) => el?.param !== paramSecond && el?.param !== param
      );
    }

    if (
      param === 'providerName' &&
      value &&
      formattedFilters.find((item) => item.param === 'quoteStatus')
    ) {
      const newArr = [...filterFields];

      newArr[0][5].updateDefaultValue = {
        title: 'Todos',
        value: ''
      };

      setFilterFields(newArr);

      formattedFilters.find((item) => item.param === 'quoteStatus').value = '';
      formattedFilters.find((item) => item.param === 'quoteStatus').urlParam =
        '';
    }

    handleFilterOptions(
      param,
      value,
      formattedFilters,
      setFiltersChanged,
      null,
      true,
      '0'
    );
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page,
      true,
      profileType
    );
  };

  const downloadSpreadsheet = (providerId) => {
    const BASE_URL = `${environments.catalog}/quotations/platform/spreadsheet`;
    const uri = providerId
      ? `${BASE_URL}?providerId=${providerId}&expiresIn=${expirationTime}`
      : BASE_URL;

    const link = document.createElement('a');
    link.href = uri;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    fetch(uri, {
      method: 'GET'
    }).then(() => {
      getMainContent(page, size);
    });
  };

  const handleUploadSpreadsheet = (event, providerId) => {
    const { files } = event.target;
    if (files.length) {
      setMultipleUploadFiles(files);
      setModalStates({ ...modalStates, multipleUpload: true });
      setCurrentUploadProviderId(providerId);
    }
  };

  const handleStatus = (status) => {
    let label = '';
    let statusStyle = '';

    switch (status) {
      case 'NOT DOWNLOADED':
        label = 'Não Baixou Arquivo';
        statusStyle = 'notDownloaded';
        break;
      case 'DOWNLOADED':
        label = 'Não Recebida';
        statusStyle = 'downloaded';
        break;
      case 'PARTIAL UPLOADED':
        label = 'Recebida Parcial';
        statusStyle = 'partialUploaded';
        break;
      case 'FULL UPLOADED':
        label = 'Recebida Completa';
        statusStyle = 'fullUploaded';
        break;
      default:
        label = '-';
        statusStyle = '-';
    }

    return (
      <div className={`statusBox ${statusStyle}`}>
        <p className="m0">{label}</p>
      </div>
    );
  };

  const handleExpirationTime = (initialDate, endDate) => {
    setExpirationTime(moment(endDate).diff(moment(initialDate), 'days'));
  };

  const handleClose = (obj) => {
    setModalStates({
      prioritySkus: false,
      multipleUpload: false
    });

    if (obj) {
      handleFilter('providerIds', obj.providers);
      getFilterhandle(
        `${environments.catalog}/skus`,
        'SKU',
        'providersSku',
        'description'
      );

      const newArr = [...filterFields];
      newArr[0][1].updateDefaultValue = {
        title: obj.description,
        value: obj.providers
      };

      setFilterFields(newArr);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.catalog}/quotations/skus/request?count=true`
      );
      const count = await response.json();
      setPrioritySkus(count);
    })();
  }, []);

  useEffect(() => {
    if (providers.length) {
      const newRefsList = providers.map(() => createRef());
      setListRefs(newRefsList);
    }
  }, [providers]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    getMainContent(page, size);
  }, [getId, filtersChanged, getMainContent, page, size]);

  return (
    <Layout>
      <GlobalStyle />

      <TitleHead title="Solicitações de Cotação" />

      <Container>
        <PageTitleAndFilter
          title="Cotação"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          getFilterhandle={getFilterhandle}
        />

        <section className="quoteSolicitationsActionButtonsContainer">
          <Button
            className="defaultButton backButton"
            onClick={() =>
              setModalStates({ ...modalStates, prioritySkus: true })
            }
            style={{ outline: 0 }}
            color="primary"
          >
            {`Ver os ${prioritySkus && prioritySkus.length} SKUS prioritários`}
          </Button>

          <div className="dFlex">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
              <KeyboardDatePicker
                className="start-date"
                margin="normal"
                format="dd/MM/yyyy"
                variant="inline"
                id="date-picker-dialog"
                label="Data inicial"
                disablePast
                value={selectedInitialDate}
                onChange={(date) => {
                  if (moment(date).isAfter(selectedEndDate)) {
                    setSelectedEndDate(date);
                  }
                  setSelectedInitialDate(date);
                  handleExpirationTime(date, selectedEndDate);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />

              <KeyboardDatePicker
                className="end-date"
                format="dd/MM/yyyy"
                margin="normal"
                variant="inline"
                id="date-picker-dialog"
                label="Data final"
                minDate={new Date()}
                value={selectedEndDate}
                onChange={(date) => {
                  setSelectedEndDate(date);
                  handleExpirationTime(selectedInitialDate, date);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </MuiPickersUtilsProvider>

            <Button
              className="defaultButton submitButton"
              style={{ outline: 0, position: 'relative' }}
              color="primary"
            >
              <InputFile
                type="file"
                name="quotation_upload_files"
                accept=".xlsx"
                multiple
                ref={multipleFilesRef}
                onChange={handleMultipleFileChanges}
              />
              <img
                style={{ width: '25px' }}
                src={UploadIcon}
                alt="UploadIcon"
              />
              Enviar vários arquivos
            </Button>

            <Button
              className="defaultButton submitButton"
              onClick={() => downloadSpreadsheet(null)}
              style={{ outline: 0 }}
              color="primary"
            >
              <img
                style={{ width: '25px' }}
                src={DownloadIcon}
                alt="DownloadIcon"
              />
              Baixar toda base
            </Button>
          </div>
        </section>

        <table
          className="desktop quoteSolicitationsTable"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="30%" className="subtitle">
                FORNECEDOR{' '}
                <OrderBy
                  filterName="providerName"
                  handleFilter={handleOrdinationFilter}
                />
              </th>
              <th width="10%" className="subtitle">
                PLANILHA
              </th>
              <th width="25%" className="subtitle">
                ARQUIVO{' '}
                <OrderBy
                  filterName="fileName"
                  handleFilter={handleOrdinationFilter}
                />
              </th>
              <th width="10%" className="subtitle">
                DATA
              </th>
              <th width="10%" className="subtitle">
                VALIDADE
              </th>
              <th width="15%" className="subtitle">
                STATUS
              </th>
            </tr>
          </thead>

          <tbody>
            {providers && providers.length ? (
              providers.map((provider, providerIndex) => (
                <>
                  <tr>
                    <td className="description p12">
                      {ValidationLength(
                        (provider?.groupName
                          ? provider.groupName
                          : provider.providerName || '-'
                        ).toUpperCase(),
                        35
                      )}
                    </td>

                    <td className="description">
                      <div className="fileActionsBox">
                        <Tooltip placement="bottom" title="Download">
                          <IconButton
                            className="modalButton"
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => downloadSpreadsheet(provider._id)}
                          >
                            <img
                              style={{ width: '25px' }}
                              src={DownloadIcon}
                              alt="DownloadIcon"
                            />
                          </IconButton>
                        </Tooltip>

                        {!provider.quotation?.[0]?.fileName ? (
                          <Tooltip
                            placement="bottom"
                            title="Faça o download do arquivo antes"
                          >
                            <img
                              style={{ width: '25px' }}
                              src={UploadGrayIcon}
                              alt="UploadGrayIcon"
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip placement="bottom" title="Upload">
                            <IconButton
                              disabled={!provider.quotation?.[0]?.fileName}
                              className="modalButton"
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <InputFile
                                type="file"
                                name="quotation_upload_files"
                                accept=".xlsx"
                                ref={listRefs[providerIndex]}
                                onChange={(event) =>
                                  handleUploadSpreadsheet(
                                    event,
                                    provider?.providerIds
                                      ? provider.providerIds
                                      : provider.providerId,
                                    providerIndex
                                  )
                                }
                              />
                              <img
                                style={{ width: '25px' }}
                                src={UploadIcon}
                                alt="UploadIcon"
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </td>

                    <td className="description">
                      {provider.quotation?.[0] &&
                        ValidationLength(provider.quotation?.[0]?.fileName, 35)}
                    </td>

                    <td className="description">
                      {provider.quotation?.[0]?.date
                        ? format(
                            new Date(provider.quotation?.[0]?.date).valueOf() +
                              14400000,
                            'dd/MM/yyyy'
                          )
                        : '-'}
                    </td>

                    <td className="description">
                      {provider.quotation?.[0]?.expirationDate
                        ? format(
                            new Date(
                              provider.quotation?.[0]?.expirationDate
                            ).valueOf() + 14400000,
                            'dd/MM/yyyy'
                          )
                        : '-'}
                    </td>

                    <td className="description p12">
                      {handleStatus(
                        provider.quotation?.[0]?.status
                          ? provider.quotation?.[0]?.status
                          : 'NOT DOWNLOAD'
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="6">
                      <hr />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <Typography
                    style={{
                      margin: '5px 0',
                      fontFamily: 'Lato',
                      textAlign: 'center'
                    }}
                  >
                    Não há registros para exibir.
                  </Typography>
                </td>
              </tr>
            )}

            <tr>
              <td colSpan="6">
                {callSettings.loading ? (
                  <LinearProgress variant="query" />
                ) : (
                  <hr className="" />
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(event, nextPage) => {
              setCallSettings({ ...callSettings, page });
              getMainContent(nextPage, size);
            }}
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>

        {modalStates?.prioritySkus && (
          <Suspense fallback={<span />}>
            <PrioritySkusDialog
              openModal={modalStates}
              handleClose={handleClose}
            />
          </Suspense>
        )}

        {modalStates?.multipleUpload && (
          <Suspense fallback={<span />}>
            <MultipleUploadDialog
              openModal={modalStates}
              handleClose={handleClose}
              multipleUploadFiles={multipleUploadFiles}
              resetMultipleFilesRef={resetMultipleFilesRef}
              currentUploadProviderId={currentUploadProviderId}
              quoteDate={selectedInitialDate}
              expirationDate={selectedEndDate}
            />
          </Suspense>
        )}
      </Container>
    </Layout>
  );
}

export default QuoteSolicitations;
