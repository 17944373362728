import React from 'react';

import { format } from 'date-fns';
import PullToRefresh from 'react-simple-pull-to-refresh';

import history from 'utils/history';
import numberToReal from 'utils/numberToReal';
import { shortenString } from 'utils/shortenString';

import SkeletonLoading from './SkeletonLoading';
import { CardReceiver, MobileReceiver } from './style';

function MobileRecebimento({ data, getId, loading }) {
  function handleDetails(receiverId) {
    history.push(`/recebimento/${getId}/${receiverId}`);
  }

  function backgroundColorStatus(status, dlvrDate) {
    if (status.toUpperCase() === 'IN PROGRESS') {
      if (Date() > dlvrDate) {
        return '#FFF2CC';
      }
      return '#DADDF3';
    }

    if (status.toUpperCase() === 'RECEIVED') {
      return '#D2F9D6';
    }

    if (
      status.toUpperCase() === 'RETURNED' ||
      status.toUpperCase() === 'NOT RECEIVED'
    ) {
      return '#F7D4D7';
    }

    if (status.toUpperCase() === 'PENDING') {
      return '#d3d3d3';
    }
  }

  function wordColorStatus(status, dlvrDate) {
    if (status.toUpperCase() === 'IN PROGRESS') {
      if (Date() > dlvrDate) {
        return '#AA8208';
      }
      return '#0B1A8E';
    }

    if (status.toUpperCase() === 'RECEIVED') {
      return '#08AA15';
    }

    if (
      status.toUpperCase() === 'RETURNED' ||
      status.toUpperCase() === 'NOT RECEIVED'
    ) {
      return '#AA0816';
    }

    if (status.toUpperCase() === 'PENDING') {
      return '#0B1A8E';
    }
  }

  function handleTranslateStatus(status, dlvrDate, orderDate) {
    const stts = {
      RECEIVED: 'Recebido',
      'IN PROGRESS': Date() > dlvrDate ? 'Atrasado' : 'Andamento',
      RETURNED: 'Devolvido',
      'NOT RECEIVED': 'Não Recebido',
      PENDING: 'Aprovação'
    };
    return stts[status];
  }

  function backgroundColorNf(status) {
    if (status?.toUpperCase() === 'VALIDATE') {
      return '#D2F9D6';
    }
    return '#FFF2CC';
  }

  function wordColorNf(status) {
    if (status?.toUpperCase() === 'VALIDATE') {
      return '#08AA15';
    }
    return '#AA8208';
  }

  const skeletonArray = [];

  return (
    <PullToRefresh
      className="customPullToRefresh"
      onRefresh={() => window.location.reload()}
    >
      <MobileReceiver>
        {loading && <SkeletonLoading skeletonArray={skeletonArray} />}

        {data.map((dataRecebimento) => (
          <CardReceiver onClick={() => handleDetails(dataRecebimento._id)}>
            <div className="nameAndStatus">
              <p>{shortenString(dataRecebimento.providerName, 25)}</p>

              <div
                style={{
                  backgroundColor: backgroundColorStatus(
                    dataRecebimento.status,
                    dataRecebimento.deliveryDate,
                    dataRecebimento.orderDate
                  ),
                  color: wordColorStatus(
                    dataRecebimento.status,
                    dataRecebimento.deliveryDate,
                    dataRecebimento.orderDate
                  )
                }}
              >
                {handleTranslateStatus(
                  dataRecebimento.status,
                  dataRecebimento.deliveryDate,
                  dataRecebimento.orderDate
                )}
              </div>
            </div>

            <div className="description">
              <div
                style={{
                  backgroundColor: backgroundColorNf(
                    dataRecebimento.statusPaymentDocument
                  ),
                  color: wordColorNf(dataRecebimento.statusPaymentDocument),
                  width: '150px'
                }}
              >
                {' '}
                <p>
                  NF{' '}
                  {shortenString(
                    dataRecebimento?.orderItems[0]?.itemsPaymentDocument
                      ?.paymentDocumentNumber,
                    8
                  )}
                </p>{' '}

                <p>
                  {dataRecebimento.statusPaymentDocument === 'VALIDATE'
                    ? 'validado'
                    : 'não validado'}
                </p>
              </div>

              <div
                style={{
                  backgroundColor: 'transparent',
                  color: 'black',
                  fontSize: '12px',
                  margin: '0 4px'
                }}
              >
                {' '}
                {format(
                  new Date(dataRecebimento.deliveryDate).valueOf() + 10800000,
                  'dd/MM'
                )}
              </div>

              <p>
                R${numberToReal(dataRecebimento.totalOrderAmount)}
              </p>
            </div>
          </CardReceiver>
        ))}
      </MobileReceiver>
    </PullToRefresh>
  );
}

export default MobileRecebimento;
