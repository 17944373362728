export function setOrder(data) {
    return {
        type: 'SET_ORDER_DATA',
        data,
    };
}

export function createOrder(data) {
    return {
        type: 'CREATE_ORDER_DATA',
        data,
    };
}