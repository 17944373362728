import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const middleware = [thunk];
const store = configureStore({ reducer: rootReducer, middleware });

export { store };
