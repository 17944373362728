const periodicitiesLocalize = {
    "need to send restaurantId and description in body to complete request": "Precisa enviar o ID do restaurante e a descrição no corpo para concluir a solicitação",
    "already exists a periodicity with this description": "Já existe uma contagem com esta descrição",
    "Need to send the originId": "Precisa enviar o originId",
    "invalid status": "Status inválido",
    "not found results according to these parameters": "Não encontrei resultados de acordo com esses parâmetros",
    "Já existe uma contagem definida como CMV para o restaurante atual": "Já existe uma contagem definida como CMV para o restaurante atual.",
    "Já existe uma periodicidade ativa com a contagem de CMV habilitada Não é possível ativar mais de uma periodicidade com essa configuração": "Já existe uma periodicidade ativa com a contagem de CMV habilitada. Não é possível ativar mais de uma periodicidade com essa configuração."
};

export default periodicitiesLocalize;