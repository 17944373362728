import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';

import ProdIcon from 'images/icons/lettersAndWords/words/prodIcon.svg';
import SkusIcon from 'images/icons/lettersAndWords/words/sku.svg';
import TagIcon from 'images/icons/lettersAndWords/words/tag.svg';
import BloomIconSmall from 'images/icons/logo/bloomIconSmall.svg';
import QuotesIcon from 'images/icons/objects/clipboards/quotesIcon.svg';
import HouseIcon from 'images/icons/objects/houses/house.svg';
import CardIcon from 'images/icons/objects/tags/cardIcon.svg';
import ProviderIcon from 'images/icons/objects/trucks/providerIcon.svg';

const icons = {
  production: ProdIcon,
  card: CardIcon,
  provider: ProviderIcon,
  quote: QuotesIcon,
  sku: SkusIcon,
  bloomSM: BloomIconSmall,
  tag: TagIcon,
  house: HouseIcon
};

function TableIcon({ icon, name, className }) {
  return (
    <Tooltip title={name} className={className}>
      <img alt={name} src={icons[icon]} />
    </Tooltip>
  );
}

export default TableIcon;
