/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import 'shineout/dist/theme.antd.css';
import {
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import countries from 'data/country';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { useViaCep } from 'use-via-cep';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import typesAddress from 'data/addressTypes.json';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as ProvidersActions from 'store/actions/fornecedores';
import { Container } from 'styles/general';
import Environment from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import history from 'utils/history';
import maskCnpj from 'utils/masks/maskCnpj'
import maskCpf from 'utils/masks/maskCpf'
import unformatDocumentNumber from 'utils/unformatDocumentNumber';

function ProviderForm({
  userInfo,
  providerInfo,
  redirectPath,
  isModal,
  onClose,
  setUpdateList,
  createMode,
  skuModal,
  dialogProvidersSettings,
  multipleSkuData,
  setMultipleSkuData,
  isMultipleSku,
  selectedItems,
  setSelectedItems
}) {
  const { id: paramsID } = useParams();
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidDocNumber, setInvalidDocNumber] = useState(false);
  const [messageError, setMessageError] = useState('');
  const { handleSubmit, register, control, errors, value } = useForm();
  const [alertErr, setAlertErr] = useState(false);
  const onDismiss = () => setAlertErr(false);
  const [editMode, setEditMode] = useState(
    createMode !== true
      ? providerInfo.action
        ? providerInfo.action.toLowerCase() === 'edit'
        : false
      : false
  );
  const [typeAddress, setTypeAddress] = useState(editMode ? providerInfo?.data?.addresses[0] ? {
    "id": null,
    "description": providerInfo?.data?.addresses[0]?.typeAddress
  } :
    {
      "id": 1,
      "description": 'Cobrança'
    } : '');
  const [country, setCountry] = useState({
    name: 'Brasil',
    code: 'BR',
    phone_code: '+55'
  });
  const [typeDocumentNumber, setTypeDocumentNumber] = useState(
    editMode ? providerInfo.data.documentType : 'CNPJ'
  );
  const [documentNumber, setDocumentNumber] = useState(
    editMode ? providerInfo.data.cnpjNumber : ''
  );
  const { cep, data, setCep } = useViaCep();
  const environments = Environment(process.env.REACT_APP_ENV);

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const companiesActive = userInfo?.companiesActive?.id.length ? userInfo?.companiesActive?.id?.map((item) => item) : userInfo?.companiesActive?.id
  const allCompanies = userInfo?.companies?.groups[0]?.subGroups
    .map((item) => item?.stores?.map((subGroup) => subGroup))
  const linkCompanies = allCompanies?.flat()
  const filterCompanies = companiesActive.length ? linkCompanies?.filter((item) => companiesActive?.includes(item.id)) : linkCompanies?.filter((item) => item.id === companiesActive)
  const stores = filterCompanies.map((store) => (
    {
      companyId: store.id,
      companyName: store.name
    }
  ))

  const onSubmitProvider = (data) => {
    setTimeout(() => {
      if (editMode) {
        setLoading(true);
        axios
          .put(
            `${environments.providers}/providers/${providerInfo.data.id}`,
            {
              cnpjNumber: unformatDocumentNumber(
                data.cnpjNumber,
                typeDocumentNumber
              ),
              parentCnpjNumber: unformatDocumentNumber(
                data.parentCnpjNumber,
                'CNPJ'
              ),
              providerName: data.providerName,
              fantasyName: data.fantasyName,
              addresses: [{
                typeAddress: typeAddress ? typeAddress.description : 'Cobrança',
                zipCode: data.zipCode.replace(/[^\d]+/g, ''),
                country: country.name,
                state: data.state,
                city: data.city,
                street: data.street,
                number: data.number,
                complement: data.complement
              }],
              stateRegistration: data.stateRegistration,
              municipalRegistration: data.municipalRegistration,
              contacts: [{
                fullName: data.fullName,
                telephoneNumber: data.telephoneNumber.replace(/[^\d]+/g, ''),
                emailAddress: data.emailAddress,
                department: data.department
              }],
              companies: stores,
              active: 'true',
              user: userInfo.user
            },
            config
          )
          .then((res) => {
            setOpen(true);
            setLoading(false);
            setUpdateList && setUpdateList(true);
            onClose &&
              setTimeout(() => {
                onClose();
              }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.data.message) {
              setMessageError('ErrorEdit');
            } else {
              setMessageError('common.ErrorToRecord');
            }

            setAlertErr(true);
            setUpdateList && setUpdateList(false);
          });
      } else {
        setLoading(true);
        const body = {
          parentCnpjNumber: unformatDocumentNumber(
            data.parentCnpjNumber,
            'CNPJ'
          ),
          cnpjNumber: unformatDocumentNumber(
            data.cnpjNumber,
            typeDocumentNumber
          ),
          providerName: data.providerName.toUpperCase(),
          hasBloomAssociation: true,
          fantasyName: data.fantasyName.toUpperCase(),
          municipalRegistration: data.municipalRegistration,
          stateRegistration: data.stateRegistration,
          typeDocumentNumber,
          addresses: [
            {
              typeAddress: typeAddress ? typeAddress.description : 'Cobrança',
              zipCode: data.zipCode.replace(/[^\d]+/g, ''),
              country: country.name,
              state: data.state,
              city: data.city,
              street: data.street,
              number: data.number,
              complement: data.complement
            }
          ],
          contacts: [
            {
              fullName: data.fullName,
              telephoneNumber: data.telephoneNumber.replace(/[^\d]+/g, ''),
              emailAddress: data.emailAddress,
              department: data.department
            }
          ],
          companies: stores,
          user: userInfo.user
        };

        axios
          .post(`${environments.providers}/providers`, body, config)
          .then((res) => {
            setOpen(true);
            setLoading(false);
            skuModal && updateSkuModalObject(res.data);
            setUpdateList && setUpdateList(true);
            onClose &&
              setTimeout(() => {
                onClose();
              }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.data.message) {
              setMessageError('ErrorRegister');
            } else {
              setMessageError('common.ErrorToRecord');
            }
            setAlertErr(true);
            setUpdateList && setUpdateList(false);
          });
      }
    }, 400);
  };

  const updateSkuModalObject = (obj) => {
    if (isMultipleSku) {
      setMultipleSkuData(
        multipleSkuData.map((sku, i) => {
          if (dialogProvidersSettings.currentSku === i) {
            return {
              ...sku,
              providerToAssociate: obj,
              itemToAssociate: {
                ...sku?.itemToAssociate,
                providerId: obj.providerId,
                name: obj.providerName
              }
            };
          }
          return sku;
        })
      );
    } else {
      setSelectedItems({
        ...selectedItems,
        provider: obj,
        item: {
          ...selectedItems.item,
          providerId: obj.providerId,
          name: obj.providerName
        }
      });
    }
  };

  const handleClose = (reason) => {
    if (redirectPath !== null) {
      history.push(redirectPath);
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const validateCpfOrCnpj = (value) => {
    const replacedValue = value?.replace(/[/.-]/g, '');

    axios
      .get(
        `${environments.providers
        }/providers?page=1&size=10000&isListProviders=true&originId=${isAdmin !== 'admin' ? paramsID : ''
        }&cnpjNumber=${typeDocumentNumber === 'CNPJ' ? 'j' : 'f'
        }${replacedValue}`
      )
      .then((response) => {
        setInvalidDocNumber(response?.data?.content?.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleChangeRadio(event) {
    setTypeDocumentNumber(event.target.value);
    setDocumentNumber('');
  }

  function setLabelDocumentNumber() {
    return typeDocumentNumber;
  }

  function canShowFieldsByCnpj() {
    return typeDocumentNumber === 'CNPJ';
  }

  return (
    <div className="defaultModal">
      <Container className={!isModal && 'p0 mt20'}>
        {isModal && (
          <DialogTitle
            className="modalHeader navyBlue bold textAlignCenter cursorMove"
            style={{
              borderRadius: '4px 4px 0 0'
            }}
            id="draggable-dialog-title"
          >
            <Typography>CADASTRO DE FORNECEDORES</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => {
                onClose();
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>
        )}

        <CommonAlert
          open={alertErr}
          onClose={() => setAlertErr(false)}
          indexMessage={messageError}
          width="50%"
        />

        {loading ? <LinearProgress /> : null}

        <div
          className="cardDefault pt16"
          style={{ borderRadius: isModal && '0' }}
        >
          <form
            onSubmit={handleSubmit(onSubmitProvider)}
            className="providerForm"
          >
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="type"
                name="type"
                value={typeDocumentNumber}
                onChange={handleChangeRadio}
                style={{ flexDirection: 'row', marginBottom: 18 }}
              >
                <FormControlLabel
                  value="CNPJ"
                  disabled={editMode}
                  control={
                    <Radio id="formProviderCnpj" required size="small" />
                  }
                  label={
                    <span
                      className="bold"
                      style={{
                        fontSize: '13px',
                        color: '#5062F0'
                      }}
                    >
                      CNPJ
                    </span>
                  }
                />
                <FormControlLabel
                  value="CPF"
                  disabled={editMode}
                  control={<Radio id="formProviderCpf" required size="small" />}
                  label={
                    <span
                      className="bold"
                      style={{
                        fontFamily: 'Lato',
                        fontSize: '13px',
                        color: '#5062F0'
                      }}
                    >
                      CPF
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>

            <Grid
              container
              spacing={4}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <Grid item xs={3} sm={3}>
                {typeDocumentNumber === 'CNPJ' && 
                  <TextField
                    label={setLabelDocumentNumber()}
                    name="cnpjNumber"
                    fullWidth
                    variant="outlined"
                    defaultValue={
                      editMode === true
                        ? formatDocumentNumber(
                          providerInfo.data.cnpjNumber,
                          typeDocumentNumber
                        )
                        : null
                    }
                    inputProps={{
                      maxLength: 18
                    }}
                    onInput={(e) => {
                      const targeValue = 
                      maskCnpj(
                        e.target.value
                      )
                      setDocumentNumber(targeValue);
                    }}
                    onBlur={(e) => {
                      if (e.target.value) {
                        validateCpfOrCnpj(e.target.value);
                      } else {
                        setInvalidDocNumber(false);
                      }
                    }}
                    inputRef={register}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={maskCnpj(documentNumber)}
                  />
                }
                {typeDocumentNumber !== 'CNPJ' && 
                  <TextField
                    label={setLabelDocumentNumber()}
                    name="cnpjNumber"
                    fullWidth
                    variant="outlined"
                    defaultValue={
                      editMode === true
                        ? formatDocumentNumber(
                          providerInfo.data.cnpjNumber,
                          typeDocumentNumber
                        )
                        : null
                    }
                    inputProps={{
                      maxLength: 14
                    }}
                    onInput={(e) => {
                      const targetValue = maskCpf(
                        e.target.value
                      );
                      setDocumentNumber(targetValue);
                    }}
                    onBlur={(e) => {
                      if (e.target.value) {
                        validateCpfOrCnpj(e.target.value);
                      } else {
                        setInvalidDocNumber(false);
                      }
                    }}
                    inputRef={register}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={maskCpf(documentNumber)}
                  />
                }
                {invalidDocNumber ? (
                  <span className="error mt5 dBlock">
                    {`${typeDocumentNumber} já cadastrado`}
                  </span>
                ) : null}
              </Grid>

              <Grid item xs={3} sm={3}>
                {canShowFieldsByCnpj() ? (
                  <TextField
                    label="CNPJ pai"
                    name="parentCnpjNumber"
                    fullWidth
                    variant="outlined"
                    defaultValue={
                      editMode === true
                        ? formatDocumentNumber(
                          providerInfo.data.parentCnpjNumber,
                          'CNPJ'
                        )
                        : null
                    }
                    inputProps={{
                      maxLength: 18
                    }}
                    onInput={(e) => {
                      e.target.value = maskCnpj(
                        e.target.value
                      );
                    }}
                    inputRef={register}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                ) : null}
              </Grid>

              <Grid item xs={3} sm={3}>
                {canShowFieldsByCnpj() ? (
                  <TextField
                    label="Inscrição Municipal"
                    name="municipalRegistration"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 16
                    }}
                    defaultValue={
                      editMode === true
                        ? providerInfo.data?.municipalRegistration
                        : null
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    inputRef={register}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                ) : null}
              </Grid>

              <Grid item xs={3} sm={3}>
                {canShowFieldsByCnpj() ? (
                  <TextField
                    label="Inscrição Estadual"
                    name="stateRegistration"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 16
                    }}
                    defaultValue={
                      editMode === true
                        ? providerInfo.data?.stateRegistration
                        : null
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    inputRef={register}
                    size="small"
                    error={errors.stateRegistration}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                ) : null}
              </Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <Grid item xs={6} sm={6}>
                <TextField
                  label="Razão Social"
                  name="providerName"
                  fullWidth
                  variant="outlined"
                  required
                  inputRef={register({ required: true })}
                  error={!!errors.providerName}
                  helperText={errors?.providerName ? '' : ''}
                  inputProps={{ maxLength: 70 }}
                  defaultValue={
                    editMode == true ? providerInfo.data?.providerName : null
                  }
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={6} sm={6}>
                <TextField
                  label="Nome Fantasia"
                  name="fantasyName"
                  fullWidth
                  variant="outlined"
                  required
                  inputRef={register({ required: true })}
                  error={!!errors.fantasyName}
                  helperText={errors?.fantasyName ? '' : ''}
                  defaultValue={
                    editMode == true ? providerInfo.data?.fantasyName : null
                  }
                  inputProps={{ maxLength: 70 }}
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <Grid item xs={3} sm={3}>
                <Autocomplete
                  id="asynchronous-demo"
                  getOptionLabel={(option) => option.description}
                  getOptionSelected={(option, value) =>
                    option.description === value.description
                  }
                  options={typesAddress}
                  value={typeAddress}
                  required={editMode !== true}
                  onChange={(event, typeAddress) => {
                    setTypeAddress(typeAddress);
                  }}
                  disabled={false}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de Endereço"
                      variant="outlined"
                      name="typeAddress"
                      size="small"
                      type="text"
                      value={typeAddress}
                      required={editMode !== true}
                      disabled
                      inputRef={register({ required: true })}
                      error={!!errors.typeAddress}
                      helperText={errors?.typeAddress ? '' : ''}
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <TextField
                  label="Cep"
                  name="zipCode"
                  fullWidth
                  variant="outlined"
                  required={editMode !== true}
                  onChange={({ target }) => setCep(target.value)}
                  inputRef={register({ required: true })}
                  error={!!errors.zipCode}
                  helperText={errors?.zipCode ? '' : ''}
                  defaultValue={
                    editMode === true ? providerInfo?.data?.addresses[0]?.zipCode : null
                  }
                  inputProps={{ maxLength: 9 }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9-]/g, '')
                      // .replace(/^(\d{2})\-(\d{3})(\d)/, "$1.$2.$3")
                      .replace(/(\d{5})(\d)/, '$1-$2');
                  }}
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <TextField
                  name="street"
                  label="Logradouro"
                  fullWidth
                  variant="outlined"
                  required={false}
                  inputRef={register({ required: true })}
                  error={!!errors.street}
                  helperText={errors?.street ? '' : ''}
                  value={data.logradouro == '' ? null : data.logradouro}
                  inputProps={{ maxLength: 50 }}
                  size="small"
                  disabled={false}
                  defaultValue={
                    editMode === true ? providerInfo.data?.addresses[0]?.street : null
                  }
                  className="textFieldSmall"
                  placeholder={editMode ? '' : 'Digite aqui...'}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={1} sm={1}>
                <TextField
                  label={isModal ? 'Nº' : 'Número'}
                  name="number"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength: 6
                  }}
                  defaultValue={
                    editMode === true ? providerInfo.data?.addresses[0]?.number : null
                  }
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(
                      /[^0-9][^a-zA-Z]/g,
                      ''
                    );
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.number}
                  helperText={errors?.number ? '' : ''}
                  size="small"
                  required={false}
                  disabled={false}
                  className="textFieldSmall"
                  placeholder={editMode ? '' : 'Digite aqui...'}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <TextField
                  label="Complemento"
                  name="complement"
                  fullWidth
                  variant="outlined"
                  inputRef={register}
                  defaultValue={
                    editMode === true ? providerInfo.data?.addresses[0]?.complement : null
                  }
                  inputProps={{ maxLength: 50 }}
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <Grid item xs={2} sm={2}>
                <TextField
                  name="city"
                  label="Cidade"
                  fullWidth
                  variant="outlined"
                  required={editMode !== true}
                  defaultValue={
                    editMode === true ? providerInfo.data?.addresses[0]?.city : null
                  }
                  inputRef={register({ required: true })}
                  error={!!errors.city}
                  helperText={errors?.city ? '' : ''}
                  value={data.localidade}
                  inputProps={{ maxLength: 50 }}
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={1} sm={1}>
                <TextField
                  label="UF"
                  name="state"
                  fullWidth
                  variant="outlined"
                  required={editMode !== true}
                  defaultValue={
                    editMode === true ? providerInfo.data?.addresses[0]?.state : null
                  }
                  inputRef={register({ required: true })}
                  error={!!errors.state}
                  helperText={errors?.state ? '' : ''}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
                  }}
                  value={editMode === true ? providerInfo.data?.addresses[0]?.state : data.uf}
                  inputProps={{ maxLength: 2 }}
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <Autocomplete
                  id="asynchronous-demo"
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  options={countries}
                  value={country}
                  required={editMode !== true}
                  className="autocompleteSmall"
                  onChange={(event, country) => {
                    setCountry(country);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="País"
                      variant="outlined"
                      size="small"
                      type="text"
                      required={editMode !== true}
                      value={country}
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                      renderOption={(params) => (
                        <Typography
                          style={{ fontSize: '12px', fontFamily: 'Lato' }}
                        >
                          {params.description}
                        </Typography>
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={4}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <Grid item xs={3} sm={3}>
                <TextField
                  label="Nome do Contato"
                  name="fullName"
                  fullWidth
                  variant="outlined"
                  required={editMode !== true}
                  defaultValue={
                    editMode === true ? providerInfo.data?.contacts[0]?.fullName : null
                  }
                  inputRef={register({ required: true })}
                  error={!!errors.fullName}
                  helperText={errors?.fullName ? '' : ''}
                  inputProps={{ maxLength: 80 }}
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <TextField
                  type="email"
                  label="E-mail"
                  name="emailAddress"
                  fullWidth
                  variant="outlined"
                  required={editMode !== true}
                  defaultValue={
                    editMode === true ? providerInfo.data?.contacts[0]?.emailAddress : null
                  }
                  inputRef={register({ required: true })}
                  error={!!errors.emailAddress}
                  helperText={errors?.emailAddress ? '' : ''}
                  inputProps={{ maxLength: 50 }}
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <TextField
                  label="Telefone"
                  name="telephoneNumber"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    maxLength: 14
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value
                      .replace(/[^0-9()-]/g, '')
                      .replace(/^(\d{2})(\d)/, '($1)$2')
                      .replace(/(\d{5})(\d)/, '$1-$2');
                  }}
                  inputRef={register({ required: true })}
                  error={!!errors.telephoneNumber}
                  helperText={errors?.telephoneNumber ? '' : ''}
                  size="small"
                  required={editMode !== true}
                  defaultValue={
                    editMode === true ? providerInfo.data?.contacts[0]?.telephoneNumber : null
                  }
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>

              <Grid item xs={3} sm={3}>
                <TextField
                  label="Departamento"
                  name="department"
                  fullWidth
                  variant="outlined"
                  required={editMode !== true}
                  inputRef={register({ required: true })}
                  error={!!errors.department}
                  helperText={errors?.department ? '' : ''}
                  defaultValue={
                    editMode == true ? providerInfo.data.contacts[0]?.department : null
                  }
                  inputProps={{ maxLength: 30 }}
                  size="small"
                  className="textFieldSmall"
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </form>
        </div>

        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Cadastro realizado com sucesso!
          </Alert>
        </Snackbar>

        <Grid
          item
          xs={12}
          sm={12}
          style={{
            padding: '0px 16px 16px',
            display: 'flex',
            justifyContent: 'flex-end',
            background: '#FFF',
            borderRadius: '0 0 4px 4px'
          }}
        >
          {!isModal && (
            <Link to="/fornecedores/">
              <Button
                className="defaultButton backButton"
                design="outlined"
                label="Voltar"
                style={{ marginRight: '20px' }}
              />
            </Link>
          )}

          <Button
            className="defaultButton submitButton"
            label="Salvar"
            design="contained"
            onClick={handleSubmit(onSubmitProvider)}
            disabled={invalidDocNumber}
          />
        </Grid>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  providerInfo: state.fornecedores
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProvidersActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProviderForm);
