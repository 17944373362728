import React, { useEffect, useState } from "react";

import { LinearProgress } from '@material-ui/core';

import makeGet from "services/useFetch/makeGet";
import numberToReal from "utils/numberToReal";

import { 
    ContentWrapper,
    ContentTitle,
    ContentList,
    ContentItem
} from "./index.style";

function DifferenceInCountContent({count, date}) {
    const [diffContent, setDiffContent] = useState();

    const handleResponse = (response) => {
        setDiffContent(response);
    }

    useEffect(() => {
        const { id } = count;
        const params = [
            {urlParam: `date=${date}`}
        ];
        makeGet('inventories', params, handleResponse, `/counts/${id}/differences/`);
    }, []);

    return (
        <ContentWrapper>
            <ContentTitle>DIFERENÇA NA CONTAGEM</ContentTitle>

            {
                !diffContent && <LinearProgress />
            }

            {
                diffContent && 
                <>
                    <ContentList>
                        <ContentItem>Total negativo: </ContentItem>
                        <ContentItem>R$ {numberToReal(diffContent?.negativeTotal)} </ContentItem>
                    </ContentList>

                    <ContentList>
                        <ContentItem>Total positivo: </ContentItem>
                        <ContentItem>R$ {numberToReal(diffContent?.positiveTotal)}</ContentItem>
                    </ContentList>

                    <ContentList>
                        <ContentItem>Diferença: </ContentItem>
                        <ContentItem>R$ {numberToReal(diffContent?.difference)}</ContentItem>
                    </ContentList>
                </>
            }

        </ContentWrapper>
    );
}

export default DifferenceInCountContent;
