import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
    position: relative;
    width: ${({width}) => width ? width : "70px" };
    height: 32px;
    font-size: 12px;
    font-family: Lato;
    border-style: none; 
    outline: none;
    border-radius: 8px;
    padding: 8px;
    transition: all 400ms;
    margin-left: ${({marginOff}) => marginOff ? "unset" : "10px"};

    ${({redlink}) => redlink && css`
        color: #FF3900;
        text-decoration: underline;
        background-color: transparent;
    `};

    ${({redlinkDisabled}) => redlinkDisabled && css`
        color: #9296AC;
        text-decoration: underline;
        background-color: transparent;
    `};

    ${({secondary}) => secondary && css`
        color: #202442;
        border: 1px solid #202442;
        background-color: transparent;
    `};

    ${({secondaryDisabled}) => secondaryDisabled && css`
        color: #9296AC;
        border: 1px solid #202442;
        background-color: transparent;
    `};

    ${({primary}) => primary && css`
        color: ${({disable}) => disable ? "#9296AC" : "white"}; 
        background-color: ${({disable}) => disable ? "#D0D3E2" : "#5062F0"};
    `};
`;
