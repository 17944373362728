import Axios from 'axios';

import { queries } from './queries';

const makePut = async (endpoint, data, handleResponsePost, appendEndpoint) => {
  const query = `${queries[endpoint]}${appendEndpoint || ''}`;
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  let numberOfAjaxCAllPending = 0;

  // Add a request interceptor
  Axios.interceptors.request.use(
    (config) => {
      numberOfAjaxCAllPending++;
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a response interceptor
  Axios.interceptors.response.use(
    (response) => {
      numberOfAjaxCAllPending--;
      return response;
    },
    (error) => {
      numberOfAjaxCAllPending--;
      return Promise.reject(error);
    }
  );

  return await Axios
    .put(query, data, config)
    .then((response) => {
      handleResponsePost(true, response, numberOfAjaxCAllPending);
    })
    .catch((error) => {
      handleResponsePost(false, error.response, 0);
    });
};

export default makePut;
