import Axios from "axios"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Container, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Grid, IconButton, TextField, Typography, Tooltip } from "@material-ui/core"
import moment from "moment"
import CloseIconNavy from "images/icons/signals/closesAndCancels/closeTransparent.svg"
import Button from "components/Button/button"
import Environment from "utils/environments"

function Index({
    open,
    close,
    product,
    editMode,
    restaurantId,
    userInfo,
    getReportInfos,
    type // Indica qual é o tipo da tabela (COUNT ou PRODUCTION)
}) {

    const environments = Environment(process.env.REACT_APP_ENV)
    const { handleSubmit } = useForm()
    const isCount = type === "COUNT"

    const setReasonValue = () => {
        if (editMode) {
            if (isCount) {
                return product?.countReason?.reason
            } else {
                return product?.reason?.reason
            }
        }

        return ""
    }

    const setActionPlanValue = () => {
        if (editMode) {
            if (isCount) {
                return product?.countReason?.actionPlan
            } else {
                return product?.reason?.actionPlan
            }
        }

        return ""
    }

    const [reason, setReason] = useState(setReasonValue())
    const [reasonError, setReasonError] = useState(false)
    const [actionPlan, setActionPlan] = useState(setActionPlanValue())
    const [actionPlanError, setActionPlanError] = useState(false)

    const setCreatedAt = () => {
        if (isCount) {
            return product?.countReason?.createdAt ? moment(product?.countReason?.createdAt).format("DD/MM/YYYY") : ""
        } else {
            return product?.reason?.createdAt ? moment(product?.reason?.createdAt).format("DD/MM/YYYY") : ""
        }
    }

    const setUpdatedAt = () => {
        if (isCount) {
            return product?.countReason?.updatedAt ? moment(product?.countReason?.updatedAt).format("DD/MM/YYYY") : ""
        } else {
            return product?.reason?.updatedAt ? moment(product?.reason?.updatedAt).format("DD/MM/YYYY") : ""
        }
    }

    const setUpdatedBy = () => {
        if (isCount) {
            return product?.countReason?.updatedBy || ""
        } else {
            return product?.reason?.updatedBy || ""
        }
    }

    const onSubmit = () => {
        setReasonError(false)
        setActionPlanError(false)

        const body = {
            reason: undefined,
            actionPlan: undefined,
            restaurantId: restaurantId,
            user: userInfo.user,
        }

        if (editMode) {
            let reasonEditMode = setReasonValue()
            let actionPlanEditMode = setActionPlanValue()

            if (!Boolean(reasonEditMode)) {
                setReasonError(true)
                return
            }

            if (!Boolean(actionPlanEditMode)) {
                setActionPlanError(true)
                return
            }

            body.reason = Boolean(reason) ? reason : reasonEditMode
            body.actionPlan = Boolean(actionPlan) ? actionPlan : actionPlanEditMode
        } else {
            if (!Boolean(reason)) {
                setReasonError(true)
                return
            }

            if (!Boolean(actionPlan)) {
                setActionPlanError(true)
                return
            }

            body.reason = reason
            body.actionPlan = actionPlan
        }

        let foreignId = undefined

        if (isCount) {
            body.countId = product?.countIds && Array.isArray(product?.countIds) ? product?.countIds[0] : null
            body.productId = product?.id
        } else if (!isCount && product._id) {
            foreignId = product._id
        }

        if (editMode) {
            delete body.restaurantId
            delete body.countId
            delete body.productId

            if (isCount) {
                if (product?.countReason && product?.countReason?.id) {
                    foreignId = product?.countReason?.id
                }
            }
        }

        let url = undefined

        switch (type) {
            case "COUNT":
                url = editMode && foreignId ? `${environments.catalog}/inventories/counts/reason/${foreignId}` : `${environments.catalog}/inventories/counts/reason`
                break
            case "PRODUCTION":
                url = foreignId ? `${environments.manufactures}/manufactures/dailySchedule/reason/${foreignId}` : undefined
                break
        }

        if ((editMode && url) || !isCount) {
            Axios.put(url, body)
                .then((response) => {
                    getReportInfos()
                    close()
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            Axios.post(url, body)
                .then((response) => {
                    getReportInfos()
                    close()
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const onClose = () => {
        setReason(undefined)
        setReasonError(false)
        setActionPlan(undefined)
        setActionPlanError(false)
        close()
    }

    useEffect(() => {
        const closeEsc = (e) => {
            if (e.code === 'Escape' || e.key === 'Escape') {
                onClose()
            }
        }

        window.addEventListener("keydown", closeEsc)

        return () => {
            window.removeEventListener("keydown", closeEsc)
        }
    }, [])

    return (
        <Dialog
            className="defaultModal actionPlanDialog"
            open={open}
        >
            <DialogTitle
                className="modalHeader navyBlue"
            >
                <Typography
                    style={{
                        textTransform: "inherit",
                        fontSize: "9px",
                        fontWeight: "700"
                    }}
                >
                    JUSTIFICATIVA E PLANO DE AÇÃO
                </Typography>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => onClose()}
                    aria-label="close"
                    style={{ outline: "0", background: "none" }}
                >
                    <img
                        style={{ width: "9px" }}
                        src={CloseIconNavy}
                        alt="CloseIconNavy"
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent
                className="modalContent pt5"
                style={{ padding: "8px", background: "#fff" }}
            >
                <Container
                    className="alignCenter justifyCenter"
                >
                    <Grid
                        container
                        item
                        xs={12}
                        justify="flex-start"
                        alignItems="center"
                    >
                        <div
                            className="dFlex flexColumn mr20"
                        >
                            <span
                                className="bold fontSizeDefault"
                            >
                                Produto
                            </span>
                            <span
                                className="fontSizeDefault"
                            >
                                {product?.description}
                            </span>
                        </div>
                    </Grid>
                    <br />
                    <div style={{ height: "21px" }}></div>
                    <form
                        className="Form"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid
                            item
                            xs={12}
                            className="w100"
                        >
                            <FormControl
                                fullWidth
                            >
                                <TextField
                                    type="text"
                                    name="reason"
                                    label="Motivo"
                                    autofocus
                                    multiline
                                    rows={4}
                                    maxRows={6}
                                    required
                                    inputProps={{ maxLength: 500 }}
                                    variant="outlined"
                                    size="small"
                                    defaultValue={(product?.countReason?.reason || product?.reason?.reason) || null}
                                    className="textFieldSmall"
                                    placeholder="Digite aqui..."
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        setReason(e.target.value)
                                        setReasonError(!Boolean(e.target.value))
                                    }}
                                />
                                <Tooltip
                                    title="Preencha este campo."
                                    placement="bottom-center"
                                    open={reasonError}
                                    arrow
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                >
                                    <span
                                        width="1px"
                                        style={{ marginTop: "5px" }}
                                    />
                                </Tooltip>
                            </FormControl>
                        </Grid>
                        <br />
                        <div style={{ height: "21px" }}></div>
                        <Grid
                            item
                            xs={12}
                            className="w100"
                        >
                            <FormControl
                                fullWidth
                            >
                                <TextField
                                    type="text"
                                    name="actionPlan"
                                    label="Plano de ação"
                                    autofocus
                                    required
                                    multiline
                                    rows={4}
                                    maxRows={6}
                                    inputProps={{ maxLength: 1000 }}
                                    variant="outlined"
                                    size="small"
                                    defaultValue={(product?.countReason?.actionPlan || product?.reason?.actionPlan) || null}
                                    className="textFieldSmall"
                                    placeholder="Digite aqui..."
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        setActionPlan(e.target.value)
                                        setActionPlanError(!Boolean(e.target.value))
                                    }}
                                />
                                <Tooltip
                                    title="Preencha este campo."
                                    placement="bottom-center"
                                    open={actionPlanError}
                                    arrow
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                >
                                    <span
                                        width="1px"
                                        style={{ marginTop: "5px" }}
                                    />
                                </Tooltip>
                            </FormControl>
                        </Grid>
                    </form>
                </Container>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    item
                    xs={12}
                    justify="flex-start"
                    alignItems="center"
                >
                    {
                        (product?.countReason?.createdAt || product?.reason?.createdAt) ?
                            <div
                                className="dFlex flexColumn mr20"
                            >
                                <span
                                    className="bold fontSizeDefault"
                                >
                                    Data de inclusão: {" "}
                                    {setCreatedAt()}
                                </span>
                                <span
                                    className="bold fontSizeDefault"
                                >
                                    Última atualização: {" "}
                                    {setUpdatedAt()}
                                </span>
                                <span
                                    className="bold fontSizeDefault"
                                >
                                    Usuário: {setUpdatedBy()}
                                </span>
                            </div>
                            : null
                    }
                    <Button
                        className="defaultButton backButton"
                        design="outlined"
                        label="Voltar"
                        onClick={close}
                        style={{ outline: 0, marginLeft: (product?.countReason?.createdAt || product?.reason?.createdAt) ? "85px" : "280px", width: "64px", height: "40px" }}
                    />
                    <Button
                        type="submit"
                        className="defaultButton submitButton"
                        design="contained"
                        label="Salvar"
                        style={{ marginLeft: "4px", width: "65px", height: "40px" }}
                        onClick={handleSubmit(onSubmit)}
                    />
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default Index