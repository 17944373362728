/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
} from '@material-ui/core';
import axios from 'axios';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';

function DrillDownDialog({
  openModal,
  handleClose,
  stockDescriptionProduct,
  productModal,
  info,
  getId
}) {
  const [drillDownList, setDrillDownList] = useState()

  const environments = Environment(process.env.REACT_APP_ENV);
  const InfoDrilldown = info?.info
  const productIdsDrillDrown = info.productsIds

  const convertType = () => {
    if(InfoDrilldown.type === 'Consumido em produção') {
      return "CONSUMED_PRODUCTION"
    } if (InfoDrilldown.type === 'Compra') {
      return 'SHOPPING'
    } if (InfoDrilldown.type === 'Venda') {
      return 'SALES'
    } if (InfoDrilldown.type === 'Descarte') {
      return 'DISCARD'
    }
  }
  
  const setFinalValues = (obj, product) => {
    const { details } = obj;
    const productParameters = product?.parameters || 'LAST_PURCHASE';
    const cost = product.cost
      ? product.cost
      : productParameters
      ? product.lastCost || product.lastCostStock
      : product.averageCost || product.averageCostStock;
    const filteredExtracts = details.filter(
      (extract) =>
        !(
          extract.type === 'STOCK' &&
          moment(extract.date).isSame(moment().subtract(1, 'day'), 'day')
        )
    );

    const realExtracts = filteredExtracts.map((extract) => {
      const { quantity } = extract;
      const expressedQuantity =
        quantity *
        (extract.type === 'SALES' ||
        extract.type === 'DISCARD' ||
        extract.type === 'CONSUMED_PRODUCTION' ||
        extract.type === 'RETURNED'
          ? -1
          : 1);
      const value = parseFloat(cost) * expressedQuantity;

      return {
        ...extract,
        quantity: expressedQuantity,
        value
      };
    });

    const extractsWithFinalValues = realExtracts.map((extract, index) => {
      if (index) {
        if (extract.type === 'COUNT') {
          return {
            ...extract,
            finalQuantity: extract.quantity,
            finalValue: extract.value
          };
        }
        const subArray = realExtracts
          .filter((ext, currentIndex) => currentIndex <= index)
          .reverse();
        const previousCountExtract = subArray.find(
          (ext) => ext.type === 'COUNT'
        );
        const previousCountExtractIndex = previousCountExtract
          ? realExtracts.indexOf(previousCountExtract)
          : -1;

        if (previousCountExtractIndex > -1) {
          const extractsAfterCount = realExtracts.filter(
            (item, currentIndex) =>
              currentIndex >= previousCountExtractIndex && currentIndex <= index
          );
          return {
            ...extract,
            finalQuantity: extractsAfterCount.reduce(
              (total, item, currentIndex) =>
                currentIndex <= index
                  ? parseFloat(item.quantity) + parseFloat(total)
                  : total,
              0
            ),
            finalValue: extractsAfterCount.reduce(
              (total, item, currentIndex) =>
                currentIndex <= index
                  ? parseFloat(item.value) + parseFloat(total)
                  : total,
              0
            )
          };
        }
        return {
          ...extract,
          finalQuantity: realExtracts.reduce(
            (total, item, currentIndex) =>
              currentIndex <= index
                ? parseFloat(item.quantity) + parseFloat(total)
                : total,
            0
          ),
          finalValue: realExtracts.reduce(
            (total, item, currentIndex) =>
              currentIndex <= index
                ? parseFloat(item.value) + parseFloat(total)
                : total,
            0
          )
        };
      }
      return extract;
    });

    const allCountExtracts = extractsWithFinalValues.filter(
      (ext) => ext.type === 'COUNT'
    );

    allCountExtracts.forEach((extract) => {
      const countExtractIndex = extractsWithFinalValues.indexOf(extract);

      if (countExtractIndex > -1) {
        const previousFinalQuantity =
          extractsWithFinalValues[countExtractIndex - 1]?.finalQuantity;
        const hasDifference = previousFinalQuantity !== extract.quantity;
        const difference =
          parseFloat(extract?.quantity) -
          parseFloat(previousFinalQuantity);

        const item = {
          averageCost: 0,
          cardsId: null,
          date: extract.date,
          lastCost: 0,
          productId: product.id,
          product: extract?.product,
          quantity: difference,
          salesPrice: 0,
          salesQuantity: 0,
          type: 'Diferença de estoque',
          finalQuantity: extract.finalQuantity,
          value: parseFloat(difference) * parseFloat(cost),
          diffPercent: extract?.product?.historical?.some((el) => el?.consumed)
            ? parseFloat(
                (parseFloat(difference) * 100) /
                  extract?.product?.historical[0]?.consumed
              )
            : '',
          finalValue: extract.finalValue
        };
        extractsWithFinalValues.splice(countExtractIndex, 0, item);
      }
    });

    return extractsWithFinalValues;
  };

  const handleTitle = () => {
    const title = ` ${InfoDrilldown.type} - ${InfoDrilldown.product?.description}`;

    return title;
  };

  const fetchDetails = () => {
    axios.get(`${environments.catalog}/extracts/details?originIds=${getId}&productIds=${productIdsDrillDrown}&dateStart=${InfoDrilldown.date}&dateEnd=${InfoDrilldown.date}`)
    .then((response) => {

      const extractsWithFinalValues = setFinalValues(response.data, productModal);

      const parentId = null;

      setDrillDownList(extractsWithFinalValues);
    })
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openModal.find((modal) => modal.name === 'drillDownDialog')?.open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal drillDownModal"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>
          {handleTitle(stockDescriptionProduct)}
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
        >
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <table className="relatedChips">
          <thead>
            <tr>
              <th className="subtitle">RESTAURANTE</th>
              <th className="subtitle right">QTDE.</th>
              <th className="subtitle right">VALOR</th>
            </tr>

            <tr className="headerBorder">
              <th colSpan="5" className="titleLineBorder" />
            </tr>
          </thead>

          <tbody>
            {drillDownList?.filter((drill) => drill.type === convertType())?.map((item, index) => (
                <>
                  <tr>
                    <td className="subtitle">
                      {item?.restaurantInfos?.name}
                    </td>
                    <td className="subtitle right">
                      {(item?.quantity || 0).toLocaleString('pt-br', {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3
                    })} kg
                    </td>
                    <td className="subtitle right">
                      {`R$ ${parseFloat(
                        item.value ? item.value : 0
                      ).toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })}`}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="6" className="p0">
                      <hr className="m0" />
                    </td>
                  </tr>
                </>
              ))}

            <tr>
              <td className="subtitle">
                <b>Total:</b>
              </td>
              <td id="totalConsumption" className="subtitle right bold">
                {drillDownList?.filter((drill) => drill.type === convertType())
                  ?.reduce(
                    (quantityTotal, item) => quantityTotal + item.quantity,
                    0
                  )
                  .toLocaleString('pt-br', { minimumFractionDigits: 3 })} kg
              </td>
              <td className="subtitle right bold">
                R$ {drillDownList?.filter((drill) => drill.type === convertType())
                  ?.reduce(
                    (quantityTotal, item) => quantityTotal +  item.value,
                    0
                  )
                  .toLocaleString('pt-br', { minimumFractionDigits: 3 })}
              </td>
            </tr>
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default DrillDownDialog;
