
const calcTotal = ({ purchaseLast, quantity }) => {
    let total = 0

    if (purchaseLast && quantity) {
        total = purchaseLast * quantity
    }

    return total
}

const handleFetchData = (data, stateData, setNewData) => {
    if (!data.length) return

    const translatedData = data.map(item => {
        const newTranslatedList = [
            {
                name: item?.description || '-', 
                length: 35
            }, 
            {
                name: item?.purchaseLast || '0,000', 
                type: 'richNumber', 
            }, 
            {
                name: item?.purchaseCount || '0,000', 
                type: 'richNumber', 
            }, 
            {
                name: item?.purchaseAverage, 
                type: 'richNumber', 
            }, 
            {
                name: item?.purchaseLast, 
                type: 'richNumber', 
            }, 
            {
                name: calcTotal({ purchaseLast: item?.purchaseLast, quantity: item?.purchaseCount }),
                type: 'richNumber', 
            },
        ]

        return newTranslatedList
    })

    setNewData({...stateData, collunms: translatedData})
}

export default handleFetchData;