/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import format from 'date-fns/format';
import Draggable from 'react-draggable';

import RefreshWhiteIcon from 'images/icons/arrows/refreshWhite.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

import Button from '../../Button/button';
import FullScreenLoading from '../../FullScreenLoading';
import BrandSuggestion from '../BrandSuggestion';
import EanSuggestion from '../EanSuggestion';
import GoogleImageSearcher from '../GoogleImageSearcher';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-brand-suggestion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function GeneralSuggestions({
  suggestionsSelected,
  setSuggestionsSelected,
  generalSuggestionLoading,
  reprocessItem,
  modalSettings,
  setModalSettings,
  multipleSkuData,
  setMultipleSkuData,
  jsonBody,
  setJsonBody,
  userInfo,
  handleClearFileInput,
  selectedItems,
  setSelectedItems,
  skuDefaultDescription,
  setSkuDefaultDescription,
  providers,
  defaultValue
}) {
  const currentSkuIndex = modalSettings.find(
    (modal) => modal.name === 'generalSuggestions'
  )?.currentSkuIndex;
  const currentSku = modalSettings.find(
    (modal) => modal.name === 'generalSuggestions'
  )?.currentSku;
  const eanSuggestions = modalSettings.find(
    (modal) => modal.name === 'generalSuggestions'
  )?.eanSuggestions;
  const brandSuggestions = modalSettings.find(
    (modal) => modal.name === 'generalSuggestions'
  )?.brandSuggestions;
  const isMultipleSku = currentSkuIndex || currentSkuIndex === 0;
  const [headerDateTimeTitle, setHeaderDateTimeTitle] = useState();
  const [showImageOptions, setShowImageOptions] = useState(!currentSku?.ean);
  const [eanSugg, setEanSugg] = useState();
  const [brandSugg, setBrandSugg] = useState();
  const [newBrandSugg, setNewBrandSugg] = useState();
  const [imgSugg, setImgSugg] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [radioOption, setRadioOption] = useState(
    currentSku?.ean
      ? { option: 'ean', value: currentSku?.ean }
      : { option: 'description', value: null }
  );

  const handleClose = () => {
    setMessage({ ...message, description: '', status: '' });
    setEanSugg();
    setBrandSugg();
    setNewBrandSugg();
    setLoading(false);
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'generalSuggestions' ? { ...modal, open: false } : modal
      )
    );
  };

  const handleSubmit = () => {
    setLoading(true);

    if (isMultipleSku) {
      setMultipleSkuData(
        multipleSkuData.map((sku, i) => {
          if (currentSkuIndex === i) {
            return {
              ...sku,
              ean: eanSugg?.description || sku.ean,
              brandsId: newBrandSugg
                ? newBrandSugg?.id
                : brandSugg?.brand?.id || sku.brandsId,
              brand: newBrandSugg || brandSugg?.brand || sku.brand,
              skuDefaultDescription: {
                ...sku.skuDefaultDescription,
                brand: newBrandSugg
                  ? newBrandSugg?.description
                  : brandSugg?.brand?.description ||
                    sku.skuDefaultDescription.brand
              }
            };
          }
          return sku;
        })
      );

      setSuggestionsSelected({
        ean: eanSugg || suggestionsSelected?.ean || '',
        brand:
          newBrandSugg || brandSugg?.brand || suggestionsSelected?.brand || '',
        img: imgSugg >= 0 ? imgSugg : suggestionsSelected?.img || ''
      });
    } else {
      setSelectedItems({
        ...selectedItems,
        brand: newBrandSugg || brandSugg?.brand || selectedItems.brand
      });

      setJsonBody({
        ...jsonBody,
        sku: {
          ...jsonBody.sku,
          ean: eanSugg?.description || jsonBody.sku.ean,
          brandsId: newBrandSugg
            ? newBrandSugg?.id
            : brandSugg?.brand?.id || jsonBody.sku.brandsId
        }
      });

      setSkuDefaultDescription({
        ...skuDefaultDescription,
        brand: newBrandSugg
          ? newBrandSugg?.description
          : brandSugg?.brand?.description || skuDefaultDescription.brand
      });

      setSuggestionsSelected({
        ean: eanSugg || suggestionsSelected?.ean || '',
        brand:
          newBrandSugg || brandSugg?.brand || suggestionsSelected?.brand || '',
        img: imgSugg >= 0 ? imgSugg : suggestionsSelected?.img || ''
      });
    }

    handleClose();
  };

  const handleClearImageData = () => {
    isMultipleSku
      ? handleClearFileInput(currentSkuIndex)
      : handleClearFileInput();

    isMultipleSku
      ? setMultipleSkuData(
          multipleSkuData.map((sku, i) => {
            if (currentSkuIndex === i) {
              return {
                ...sku,
                images: {
                  name: '',
                  baseImage: '',
                  id: '',
                  url: null
                }
              };
            }
            return sku;
          })
        )
      : setJsonBody({
          ...jsonBody,
          sku: {
            ...jsonBody.sku,
            images: {
              name: '',
              baseImage: '',
              id: '',
              url: null
            }
          }
        });
  };

  const handleRadioOption = (option) => {
    setRadioOption({
      option,
      value: option === 'ean' ? currentSku?.ean : null
    });

    setShowImageOptions(option !== 'ean');
  };

  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'generalSuggestions')
          .fullScreen
      }
      open={
        modalSettings.find((modal) => modal.name === 'generalSuggestions').open
      }
      className="defaultModal generalSuggestionsDialog customZIndex"
      PaperComponent={PaperComponent}
    >
      <FullScreenLoading
        display={generalSuggestionLoading}
        description="Reprocessando"
      />

      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-brand-suggestion"
        fullWidth
      >
        <Typography>
          {`PESQUISA CATALOGAÇÃO -
              ${
                headerDateTimeTitle
                  ? `${format(headerDateTimeTitle, 'dd/MM/yy')} às ${format(
                      headerDateTimeTitle,
                      'HH:mm'
                    )}h`
                  : ''
              }
            `}

          <div
            className="cursorPointer"
            style={{ marginLeft: 40 }}
            onClick={() => {
              if (isMultipleSku) {
                reprocessItem(currentSku, currentSkuIndex, true);
              } else {
                reprocessItem(jsonBody?.sku, null, false);
              }
            }}
          >
            <img
              style={{ width: 12, marginRight: 5 }}
              className="cursorPointer"
              src={RefreshWhiteIcon}
              alt="RefreshIcon"
            />

            <span>reprocessar sugestões</span>
          </div>

          <div
            style={{ marginLeft: 40 }}
            className="radioMainInfo dFlex pRelative"
          >
            <FormControl component="fieldset">
              <RadioGroup
                row
                style={{ flexDirection: 'row' }}
                value={radioOption.option}
                onChange={(e) => {
                  handleRadioOption(e.target.value);
                }}
              >
                <Tooltip
                  placement="right"
                  title={
                    <div style={{ padding: '7px 7px' }}>Não possui EAN</div>
                  }
                  disableHoverListener={currentSku?.ean}
                >
                  <FormControlLabel
                    value="ean"
                    disabled={!currentSku?.ean}
                    label={
                      <div
                        className="suggestionName bold fontSizeDefault"
                        style={{
                          padding: '7px 7px'
                        }}
                      >
                        EAN
                      </div>
                    }
                    control={<Radio required size="small" />}
                  />
                </Tooltip>

                <Tooltip
                  placement="right"
                  title={
                    <span style={{ padding: '3px 7px' }}>
                      Não possui Descrição
                    </span>
                  }
                  disableHoverListener={defaultValue}
                >
                  <FormControlLabel
                    style={{ marginLeft: 20 }}
                    value="description"
                    disabled={!defaultValue}
                    label={
                      <span
                        className="suggestionName bold fontSizeDefault"
                        style={{
                          padding: '7px 7px'
                        }}
                      >
                        Descrição
                      </span>
                    }
                    control={<Radio required size="small" />}
                  />
                </Tooltip>
              </RadioGroup>
            </FormControl>
          </div>
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'generalSuggestions'
                  ? { ...modal, open: false }
                  : modal
              )
            );
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent generalSuggestionModalContent bgWhite dFlex">
        <EanSuggestion
          openedByGeneralSuggestion
          setHeaderDateTimeTitle={setHeaderDateTimeTitle}
          setEanSugg={setEanSugg}
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          multipleSkuData={multipleSkuData}
          setMultipleSkuData={setMultipleSkuData}
          jsonBody={jsonBody}
          setJsonBody={setJsonBody}
          suggestionsSelected={suggestionsSelected}
          setSuggestionsSelected={setSuggestionsSelected}
          eanSuggestions={eanSuggestions}
          userInfo={userInfo}
        />

        <BrandSuggestion
          openedByGeneralSuggestion
          setHeaderDateTimeTitle={setHeaderDateTimeTitle}
          setBrandSugg={setBrandSugg}
          setNewBrandSugg={setNewBrandSugg}
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          multipleSkuData={multipleSkuData}
          setMultipleSkuData={setMultipleSkuData}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          jsonBody={jsonBody}
          setJsonBody={setJsonBody}
          skuDefaultDescription={skuDefaultDescription}
          setSkuDefaultDescription={setSkuDefaultDescription}
          userInfo={userInfo}
          suggestionsSelected={suggestionsSelected}
          setSuggestionsSelected={setSuggestionsSelected}
          brandSuggestions={brandSuggestions}
        />

        <GoogleImageSearcher
          eanSuggestions={eanSuggestions}
          openedByGeneralSuggestion
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          multipleSkuData={multipleSkuData}
          setMultipleSkuData={setMultipleSkuData}
          jsonBody={jsonBody}
          setJsonBody={setJsonBody}
          providers={providers}
          defaultValue={defaultValue}
          setImgSugg={setImgSugg}
          setSuggestionsSelected={setSuggestionsSelected}
          suggestionsSelected={suggestionsSelected}
          radioOption={radioOption}
          showImageOptions={showImageOptions}
        />
      </DialogContent>

      <DialogActions className="justifyEnd">
        <Button
          design="contained"
          className="defaultButton submitButton fontSizeDefault"
          onClick={() => {
            handleSubmit();
          }}
          label="Utilizar escolhas"
        />
      </DialogActions>

      {loading && <LinearProgress variant="query" />}

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'generalSuggestions'
                ? { ...modal, open: false }
                : modal
            )
          );
        }}
      >
        <Alert
          onClose={() =>
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'generalSuggestions'
                  ? { ...modal, open: false }
                  : modal
              )
            )
          }
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </Dialog>
  );
}

export default GeneralSuggestions;
