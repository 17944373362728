/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  LinearProgress
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { format, addHours, isBefore } from 'date-fns';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ItemWrapper } from 'components/BloomTable/components/ItemSelect/style';
import ImageWrapper from 'components/ImageWrapper';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import PaperComponent from 'components/PaperComponent';
import BloomBaseLabel from 'images/icons/lettersAndWords/words/bloomBaseLabel.svg';
import LabelYourCatalog from 'images/icons/lettersAndWords/words/labelYourCatalog.svg';
import LabelYourSupplier from 'images/icons/lettersAndWords/words/labelYourSupplier.svg';
import SkuBloomLabel from 'images/icons/lettersAndWords/words/skuBloomLabel.svg';
import BloomModalSearchSkuLabel from 'images/icons/logo/bloomModalSearchSkuLabel.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import ConfigSkuIcon from 'images/icons/signals/moreInfos/threePointsDown.svg';
import ConfigSkuIconBlue from 'images/icons/signals/moreInfos/threePointsDownWhite.svg';
import getOptions from 'services/getOptions';
import * as UserActions from 'store/actions/user';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions
} from 'utils/handleFilterActions';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

import ConfigSkuPopover from '../ConfigSkuPopover';

function ResumedSearchModal({ userInfo, getId, openModal, closeModal }) {
  const isQuoter = userInfo?.profile?.[0]?.[0]?.name === 'quoter';
  const [openModalProduct, setModalProduct] = useState(false);
  const handleCloseModalProduct = () => {
    setModalProduct(false);
  };
  const [callSettings, setCallSettings] = useState({
    originId: getId,
    page: 1,
    totalPages: 0,
    size: 10,
    loading: false,
    mainContent: [],
    filters: []
  });
  const { originId, page, size, totalPages, mainContent, loading } =
    callSettings;
  const [currentPage, setCurrentPage] = useState(page);
  const environments = Environment(process.env.REACT_APP_ENV);
  const [filtersChanged, setFiltersChanged] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);
  const [supplier, setSupplier] = useState([]);
  const [unitsMeasurement, setUnitsMeasurement] = useState('');
  const [skuName, setSkuName] = useState('');
  const [skuQuantity, setSkuQuantity] = useState();
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: '',
        filterName: 'descriptionSku',
        placeHolder: '',
        type: 'defaultInputFieldSearch',
        urlPath: `${environments.catalog}/skus`,
        optionReference: 'description',
        options: [],
        size: 'large',
        focus: openModal
      }
    ]
  ]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [brands, setBrands] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [unifiedSkus, setUnifiedSkus] = useState(false);
  const [selectedSku, setSelectedSku] = useState();
  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([
    {
      name: 'Both',
      isSelected: true
    },
    {
      name: 'Restaurant',
      isSelected: false
    },
    {
      name: 'Bloom',
      isSelected: false
    }
  ]);

  function getColorStatus(expirationDate) {
    const colorStatus = {
      backgroundColor: '#E6E7F0',
      color: '#9296AC',
      borderRadius: '8px',
      quotation: 'Sem cotação'
    };

    if (expirationDate) {
      const expDate = new Date(addHours(new Date(expirationDate), 3));
      const isValid = isBefore(new Date(), expDate);

      if (isValid) {
        colorStatus.backgroundColor = '#D2F9D6';
        colorStatus.color = '#089163';
        colorStatus.quotation = 'Cotação válida';
      } else {
        colorStatus.backgroundColor = '#FFF2CC';
        colorStatus.color = '#AA8208';
        colorStatus.quotation = 'Cotação vencida';
      }
    }

    return colorStatus;
  }

  function handleFilter(param, value) {
    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  }

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      originId,
      filtersChanged,
      page,
      true
    );
  };

  async function handleData() {
    setCallSettings({ ...callSettings, loading: true });

    const originIds = userInfo?.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
      .map((item) => item.id);

    const restaurantId = isQuoter ? originIds : originId;
    const params = filtersChanged?.map((filter) => filter.urlParam).join('&');

    if (params.length > 0) {
      await fetch(
        `${environments.catalog}/skus/search?page=${page}&size=${size}&originId=${restaurantId}&${params}`
      )
        .then(async (response) => {
          const items = await response.json();
          setCallSettings({
            ...callSettings,
            totalPages: items.totalPages,
            mainContent: items?.content || [],
            loading: false
          });
        })
        .catch(() => {
          setCallSettings({
            ...callSettings,
            totalPages: 1,
            mainContent: [],
            loading: false
          });
        });
    } else {
      setCallSettings({
        ...callSettings,
        totalPages: 1,
        mainContent: [],
        loading: false
      });
    }
  }

  function handleClose() {
    closeModal();
    setFiltersChanged(false);
  }

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleDesktopRows() {
    return (
      <>
        {mainContent?.length ? (
          mainContent.map((item, index) => {
            const { skuBloom, providers, restaurants } = item;
            const image = skuBloom?.images?.url;
            const isBloomFilterEnabled = selectedFilter?.find(
              (el) => el.name === 'Bloom'
            )?.isSelected;
            const isCataloged =
              restaurants.some((restaurant) => restaurant.restaurantId > 0) ||
              (isBloomFilterEnabled ? item?.alreadyCatalogued !== 0 : false);
            const bestValue =
              providers.length &&
              providers.reduce((acc, loc) =>
                acc.purchaseLastUnitary < loc.purchaseLastUnitary ? acc : loc
              );
            const quotation = bestValue.quotations
              ? bestValue.quotations[0]
              : null;
            const otherProviders = providers.filter(
              (provider) => provider.name !== bestValue.name
            );

            return (
              <tr>
                <td className="pl12">
                  <div className="dFlex alignCenter">
                    <ImageWrapper url={image || null} />
                  </div>
                </td>

                <td className="description alignCenter sku-name">
                  {ValidationLength(item.description, 30)}
                </td>

                <td className="description pr12" style={{ fontSize: '9px' }}>
                  <div className="dFlex justifyBetween alignCenter">
                    {isCataloged ? (
                      <img src={SkuBloomLabel} alt="SKU Bloom label" />
                    ) : (
                      <img
                        style={{ width: '73%' }}
                        src={LabelYourCatalog}
                        alt="label your catalog "
                      />
                    )}

                    <div className="ml8" style={{ width: 20, height: 20 }}>
                      {!isCataloged && (
                        <div
                          onClick={(e) => {
                            if (popoverOpen && selectedSku?.id === item?.id) {
                              handleClosePopover();
                              setSelectedSku();
                            }

                            handlePopoverClick(e);
                            setSelectedSku(item);
                          }}
                          className="w100 h100 dFlex alignCenter justifyCenter cursorPointer"
                          style={{
                            borderRadius: 4,
                            background:
                              popoverOpen && selectedSku?.id === item?.id
                                ? '#5062F0'
                                : '#DADDF3'
                          }}
                        >
                          <img
                            src={
                              popoverOpen && selectedSku?.id === item?.id
                                ? ConfigSkuIconBlue
                                : ConfigSkuIcon
                            }
                            alt="ConfigSkuIcon"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </td>

                <td className="description pr12" style={{ textAlign: 'end' }}>
                  {!item.providers.length ||
                  item.providers === null ||
                  item.providers === undefined
                    ? '-'
                    : `R$${numberToReal(bestValue.purchaseLastUnitary)}/`}
                  {item?.unitsMeasurements
                    ? item.unitsMeasurements.abbreviation
                    : ''}
                </td>

                <td className="description pr12" style={{ textAlign: 'end' }}>
                  {!item.providers.length ||
                  item.providers === null ||
                  item.providers === undefined
                    ? '-'
                    : `E${bestValue.quantity}x${item.quantity}`}
                  {item?.unitsMeasurements
                    ? item.unitsMeasurements.abbreviation
                    : ''}
                </td>

                <td className="description pr12" style={{ textAlign: 'end' }}>
                  {!item.providers.length ||
                  item.providers === null ||
                  item.providers === undefined
                    ? '-'
                    : `R$${numberToReal(bestValue.purchaseLast)}`}
                </td>

                <td className="description pr12" style={{ textAlign: 'end' }}>
                  <Tooltip
                    TransitionProps={{ timeout: 600 }}
                    placement="right-end"
                    title={
                      <p className="mb0" style={{ padding: '2px 6px' }}>
                        {
                          getColorStatus(
                            quotation ? quotation.expirationDate : null
                          ).quotation
                        }
                      </p>
                    }
                  >
                    <ItemWrapper
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        display: 'block',
                        lineHeight: 1.2,
                        float: 'right'
                      }}
                      buttonLink={getColorStatus(
                        quotation ? quotation.expirationDate : null
                      )}
                    >
                      {quotation
                        ? format(
                            new Date(quotation.expirationDate).valueOf() +
                              14400000,
                            'dd/MM/yyyy'
                          )
                        : '-'}
                    </ItemWrapper>
                  </Tooltip>
                </td>

                <td className="description pl0">
                  <span className="supplier-name">
                    {ValidationLength(
                      bestValue.name ? bestValue.name : '-',
                      35
                    )}
                  </span>
                </td>

                <td className="description pr12" style={{ textAlign: 'end' }}>
                  {otherProviders.length ? (
                    <Tooltip
                      TransitionProps={{ timeout: 600 }}
                      placement="right-end"
                      title={
                        <p className="mb0" style={{ padding: '2px 6px' }}>
                          {`+${otherProviders.length} opções de fornecedores`}
                        </p>
                      }
                    >
                      <span
                        style={{
                          marginRight: '5px',
                          color: '#5062F0',
                          borderBottom: '1px solid #5062F0',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setModalProduct(true);
                          setSupplier(otherProviders);
                          setUnitsMeasurement(
                            item.unitsMeasurements.abbreviation
                          );
                          setSkuName(item.description);
                          setSkuQuantity(item.quantity);
                        }}
                      >
                        {`+${otherProviders.length}`}
                      </span>
                    </Tooltip>
                  ) : null}

                  {item.providers.some((provider) => provider.isPlatform) ? (
                    <img
                      src={BloomModalSearchSkuLabel}
                      alt="Bloom Modal Search SKU label"
                    />
                  ) : null}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <th colSpan="15" style={{ margin: '10px', textAlign: 'center' }}>
              Nenhum catalogo registrado
            </th>
          </tr>
        )}

        <Dialog
          open={openModalProduct}
          onClose={() => {
            handleCloseModalProduct();
          }}
          aria-labelledby="draggable-dialog-title"
          transitionDuration={0}
          PaperComponent={PaperComponent}
        >
          <DialogTitle id="modalProduct" className="sku-search-title">
            <span>{skuName}</span>

            <IconButton
              edge="start"
              color="inherit"
              className="close-dialog-sku-header-search"
              onClick={() => {
                handleCloseModalProduct();
              }}
              aria-label="close"
            >
              <img
                style={{ width: '13px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent style={{ padding: '0px 0px 12px 0px' }}>
            <table
              className="desktop-table-search-sku desktop-table-sku-provider"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
            >
              <thead>
                <tr className="header-title">
                  <th width="240px" className="subtitle">
                    FORNECEDOR
                  </th>

                  <th width="89px" className="subtitle" />

                  <th width="61px" className="subtitle">
                    EMB
                  </th>

                  <th width="70px" className="subtitle">
                    PREÇO R$
                  </th>

                  <th width="85px" className="subtitle">
                    VÁLIDO ATÉ
                  </th>
                </tr>
              </thead>

              <tbody>
                {supplier.length &&
                  supplier.map((provider, index) => {
                    const bestValue =
                      provider.quotations.length &&
                      provider?.quotations.reduce((acc, loc) =>
                        acc.unitaryValue < loc.unitaryValue ? acc : loc
                      );
                    return (
                      <tr>
                        <td className="description pl12">
                          <span className="sku-search-provider-modal">
                            {ValidationLength(provider.name, 30)}
                          </span>
                        </td>

                        <td className="description pl12">
                          {provider.isPlatform === true ? (
                            <img src={BloomBaseLabel} alt="Bloom base label" />
                          ) : (
                            <img
                              src={LabelYourSupplier}
                              alt="label your supplier "
                            />
                          )}
                        </td>

                        <td className="description pl12">
                          E{provider.quantity}x{skuQuantity}
                          {unitsMeasurement}
                        </td>

                        {provider.quotations.length ? (
                          <>
                            <td className="description pl12">
                              {`R$${numberToReal(bestValue.unitaryValue)}`}
                            </td>

                            <td className="description pl12">
                              <span
                                style={{
                                  backgroundColor: moment(
                                    bestValue.expirationDate
                                  ).isSameOrAfter(new Date())
                                    ? '#D2F9D6'
                                    : '#FFF2CC',
                                  padding: '4px',
                                  color: moment(
                                    bestValue.expirationDate
                                  ).isSameOrAfter(new Date())
                                    ? '#08AA15'
                                    : '#AA8208'
                                }}
                              >
                                {moment(bestValue.expirationDate).format(
                                  'DD/MM/YY'
                                )}
                              </span>
                            </td>
                          </>
                        ) : (
                          <td colSpan="2" className="description pl12">
                            Sem cotação
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  useEffect(() => {
    setCallSettings({ ...callSettings, getId });
  }, [userInfo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filtersChanged]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${originId}/parameters`
      );
      const parameters = await response.json();
      setUnifiedSkus(parameters.counts ? parameters.counts.unifiedSkus : false);
    })();
  }, [originId]);

  useEffect(() => {
    !products?.length &&
      getOptions(
        `${environments.catalog}/products`,
        'products',
        products,
        setProducts,
        originId,
        null,
        null,
        10
      );

    !categories?.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        originId,
        null,
        null,
        10
      );

    !unitsMeasurements?.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        originId
      );

    !brands?.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        originId
      );

    !consumptionUnits?.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        originId
      );

    !storageLocations?.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        originId
      );

    !periodicities?.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        originId
      );
  }, [originId]);

  useEffect(() => {
    (async () => {
      await handleData();
    })();
  }, [originId, page, filtersChanged, open]);

  return (
    <div>
      <Dialog
        className="dialog-search-header-sku"
        open={openModal}
        onClose={() => {
          handleClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        transitionDuration={0}
      >
        <DialogTitle className="sku-search-title">
          <span id="draggable-dialog-title">Pesquisar SKU</span>

          <PageTitleAndFilter
            activeFilterFilds={filterFields}
            handleFilter={handleFilter}
            getFilterhandle={getFilterhandle}
          />

          <section id="draggable-dialog-title" className="dFlex ml20">
            <div
              className="dFlex alignCenter cursorPointer"
              onClick={() => {
                setSelectedFilter(
                  selectedFilter.map((item) => {
                    if (item.name === 'Both') {
                      return {
                        ...item,
                        isSelected: true
                      };
                    }
                    return {
                      ...item,
                      isSelected: false
                    };
                  })
                );
                handleFilter('base', '');
              }}
            >
              <div
                className="dFlex justifyCenter alignCenter"
                style={{
                  borderRadius: 50,
                  width: 16,
                  height: 20,
                  background: '#FFF'
                }}
              >
                <div
                  style={{
                    borderRadius: 50,
                    width: 10,
                    height: 10,
                    background: selectedFilter.find(
                      (filter) => filter.name === 'Both'
                    ).isSelected
                      ? '#0E1337'
                      : 'transparent'
                  }}
                />
              </div>

              <span className="fontSizeDefault white ml10">Ambas</span>
            </div>

            <div
              className="dFlex ml20 alignCenter cursorPointer"
              onClick={() => {
                setSelectedFilter(
                  selectedFilter.map((item) => {
                    if (item.name === 'Restaurant') {
                      return {
                        ...item,
                        isSelected: true
                      };
                    }
                    return {
                      ...item,
                      isSelected: false
                    };
                  })
                );
                handleFilter('base', 'RESTAURANT');
              }}
            >
              <div
                className="dFlex justifyCenter alignCenter"
                style={{
                  borderRadius: 50,
                  width: 20,
                  height: 20,
                  background: '#FFF'
                }}
              >
                <div
                  style={{
                    borderRadius: 50,
                    width: 10,
                    height: 10,
                    background: selectedFilter.find(
                      (filter) => filter.name === 'Restaurant'
                    ).isSelected
                      ? '#0E1337'
                      : 'transparent'
                  }}
                />
              </div>

              <span className="fontSizeDefault white ml10">Restaurante</span>
            </div>

            <div
              className="dFlex ml20 alignCenter cursorPointer"
              onClick={() => {
                setSelectedFilter(
                  selectedFilter.map((item) => {
                    if (item.name === 'Bloom') {
                      return {
                        ...item,
                        isSelected: true
                      };
                    }
                    return {
                      ...item,
                      isSelected: false
                    };
                  })
                );
                handleFilter('base', 'BLOOM');
              }}
            >
              <div
                className="dFlex justifyCenter alignCenter"
                style={{
                  borderRadius: 50,
                  width: 20,
                  height: 20,
                  background: '#FFF'
                }}
              >
                <div
                  style={{
                    borderRadius: 50,
                    width: 10,
                    height: 10,
                    background: selectedFilter.find(
                      (filter) => filter.name === 'Bloom'
                    ).isSelected
                      ? '#0E1337'
                      : 'transparent'
                  }}
                />
              </div>
              <span className="fontSizeDefault white ml10">Bloom</span>
            </div>
          </section>

          <IconButton
            edge="start"
            color="inherit"
            className="close-dialog-sku-header-search"
            onClick={() => {
              closeModal();
              setFiltersChanged(false);
            }}
            aria-label="close"
          >
            <img
              style={{ width: '13px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ padding: '0px' }}>
          {loading && (
            <div style={{ height: '4px' }}>
              <LinearProgress id="loading" variant="query" />
            </div>
          )}

          {filtersChanged?.length ? (
            <>
              <table
                className="desktop-table-search-sku"
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
              >
                <thead>
                  <tr className="header-title">
                    <th width="100px" className="subtitle" />

                    <th width="230px" className="subtitle">
                      SKU
                    </th>

                    <th width="80px" className="subtitle" />

                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'end' }}
                    >
                      MENOR PREÇO
                    </th>

                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'end' }}
                    >
                      EMBALAGEM
                    </th>

                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'end' }}
                    >
                      PREÇO EMB.
                    </th>

                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'center' }}
                    >
                      VALIDADE
                    </th>

                    <th width="175px" className="subtitle">
                      FORNECEDORES
                    </th>

                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'center' }}
                    >
                      OPÇÕES
                    </th>
                  </tr>
                </thead>

                <tbody>{handleDesktopRows()}</tbody>
              </table>

              <div className="pagination-and-btnCatalog">
                <div className="pagination div-pagination">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(_, page) => {
                      setCallSettings({ ...callSettings, page });
                      setCurrentPage(page);
                    }}
                    variant="outlined"
                    shape="rounded"
                    size="small"
                    color="primary"
                  />
                </div>
              </div>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      {popoverOpen && (
        <ConfigSkuPopover
          anchorEl={anchorEl}
          popoverOpen={popoverOpen}
          handleClosePopover={handleClosePopover}
          selectedSku={selectedSku}
          productOptions={products}
          categoryOptions={categories}
          unitsMeasurementOptions={unitsMeasurements}
          brandOptions={brands}
          consumptionUnitOptions={consumptionUnits}
          storageLocationOptions={storageLocations}
          periodicityOptions={periodicities}
          handleUpdateMainList={() => {
            handleData();
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResumedSearchModal);
