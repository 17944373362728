import React, { useState } from 'react';

import {
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import cancellationReasons from 'data/cancellationReasons';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as UserActions from 'store/actions/user';
import { Container } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

function CancellationReasons({
  userInfo,
  cancellationReasonsInfo,
  redirectPath,
  isModal,
  onClose,
  setUpdateList,
  createMode
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { id: getId } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();
  const [openAutoComplete, setOpenAutoComplete] = useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const [restaurantGroups, setRestaurantGroups] = useState([userInfo.companies.groups[0]]);
  const [errorMessage, setErrorMessage] = useState("Erro ao cadastrar motivos de cancelamento")

  const [editMode, setEditMode] = useState(
    createMode !== true
      ? cancellationReasonsInfo.action
        ? cancellationReasonsInfo.action.toLowerCase() === 'edit'
        : false
      : false
  );
  const [cancellationReasonsIds, setcancellationReasonsIds] = useState(
    editMode ? cancellationReasonsInfo.data.id : ''
  );
  const [description, setDescription] = useState(
    editMode ? cancellationReasonsInfo.data.description : ''
  );
  const [typeCard, setCancellationReasons] = useState(
    editMode ? cancellationReasonsInfo.data.type : ''
  );
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const onDismiss = () => {
    setAlertErr(false)
    setErrorMessage("Erro ao cadastrar motivos de cancelamento")
  }

  const onSubmit = (data) => {
    let typeDescription;

    cancellationReasons.forEach((cancellationReason) => {
      if (cancellationReason.id === typeCard.id) {
        typeDescription = cancellationReason.description;
      }
    });

    const storeIds = getId?.split(',').map(Number) || []
    let groups = restaurantGroups || null;

    if (storeIds.length) {
      groups = groups
        .map(group => {
          const subGroups = group?.subGroups?.filter(subGroup =>
            subGroup.stores.some(store => storeIds.includes(store.id))
          )

          return {
            ...group,
            subGroups
          }
        })
        .filter(group => group?.subGroups?.length > 0)
    }

    setLoading(true);

    if (editMode) {
      axios
        .put(`${environments.catalog}/discards/${cancellationReasonsIds}`, {
          restaurantId: userInfo.companiesActive.id[1] ? '' : userInfo.companiesActive.id,
          description,
          discardTypeId: parseInt(typeCard.id),
          user: userInfo.user,
          groups
        }, config)
        .then((res) => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          if (error?.response?.data?.message === "already exists a discard with this description") {
            setErrorMessage("Já existe um motivo de cancelamento cadastrado com a descrição informada")
          }

          setLoading(false);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    } else {
      axios
        .post(`${environments.catalog}/discards`, {
          restaurantId: userInfo.companiesActive.id[1] ? '' : userInfo.companiesActive.id,
          description,
          discardTypeId: parseInt(typeCard.id),
          user: userInfo.user,
          groups
        }, config)
        .then((res) => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
        })
        .catch((error) => {
          if (error?.response?.data?.message === "already exists a discard with this description") {
            setErrorMessage("Já existe um motivo de cancelamento cadastrado com a descrição informada")
          }

          setLoading(false);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    }
  };

  const handleClose = (event, reason) => {
    if (redirectPath !== null) {
      history.push(redirectPath);
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div className="defaultModal" style={{ width: '40%' }}>
      <Container className="w100">
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          style={{ borderRadius: '4px 4px 0 0' }}
          id="draggable-dialog-title"
        >
          <Typography>CADASTRO DE MOTIVOS DE CANCELAMENTO</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        {loading ? <LinearProgress /> : null}

        <div className="cardDefault pt16" style={{ borderRadius: '0' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid className="m0" container xs={12} sm={12} spacing={4}>
              <Grid item xs={12} sm={6} className="pl0">
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    name="description"
                    label="Descrição"
                    autoFocus
                    required="required"
                    inputProps={{ maxLength: 50 }}
                    variant="outlined"
                    size="small"
                    onChange={(e) => setDescription(e.target.value)}
                    defaultValue={cancellationReasonsInfo?.data?.description || null}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} className="pr0">
                <Autocomplete
                  id="controllable-states-demo"
                  open={openAutoComplete}
                  onOpen={() => {
                    setOpenAutoComplete(true);
                  }}
                  onClose={() => {
                    setOpenAutoComplete(false);
                  }}
                  getOptionSelected={(option, value) =>
                    option.description === value.description
                  }
                  getOptionLabel={(option) => option.description}
                  options={cancellationReasons}
                  size="small"
                  value={typeCard}
                  onChange={(_, typeCard) => {
                    setCancellationReasons(typeCard);
                  }}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="cancellationReasons"
                      label="Tipo"
                      variant="outlined"
                      required="required"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>

            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                {editMode ? 'Atualizado' : 'Cadastro realizado'} com sucesso!
              </Alert>
            </Snackbar>

            <Grid
              className="dFlex justifyEnd p0 bgWhite"
              item
              xs={12}
              sm={12}
              style={{ borderRadius: '0 0 4px 4px' }}
            >
              {!isModal && (
                <Link to="/admin/motivos-cancelamento/">
                  <Button
                    className="defaultButton backButton mr20"
                    design="outlined"
                    label="Voltar"
                  />
                </Link>
              )}

              <Button
                className="defaultButton submitButton"
                disabled={!description || !typeCard}
                type="submit"
                label="Salvar"
                design="contained"
                onClick={handleSubmit(onSubmit)}
              />
            </Grid>
          </form>
        </div>
      </Container>

      {alertErr ? (
        <Alert
          className="pAbsolute"
          style={{ width: 'auto', bottom: 30 }}
          color="danger"
          isOpen={alertErr}
          toggle={onDismiss}
        >
          {errorMessage}
        </Alert>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  cancellationReasonsInfo: state.cancellationReasons
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CancellationReasons);
