import React, { useCallback, useState, useEffect, Suspense } from 'react';

import { addHours } from 'date-fns';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import TheoryStockDialog from 'components/Dialogs/ExtractsDialog/TheoryStockDialogs';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import makeGet from 'services/useFetch/makeGet';
import Environment from 'utils/environments';

const CardsDialogs = React.lazy(() => import('components/Dialogs/RelatedCardsFullInfosDialog'));
const environments = Environment(process.env.REACT_APP_ENV);
const actualDate = new Date();
const actualDateLessOne = actualDate.setDate(actualDate.getDate() - 1);
const actualDateLessSix = actualDate.setDate(actualDate.getDate() - 6);

function ExtractsDialog({
  getId,
  productExtractDescription,
  productModal,
  productAge,
  parameters,
  originPage,
  countDate,
  defaultStartDate = actualDateLessSix,
  defaultExtractType,
  openModal,
  handleClose,
  checkConsume,
  setCheckConsume,
  setProductModal,
  weekDays,
  customClassName,
  filterDiscardId
}) {
  const isMobileScreen = useMediaQuery('(max-width: 821px)');
  const { modalProduct, modalTechnicalSheet, modalDiscard } = openModal;
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [extractEndDate, setExtractEndDate] = useState(
    countDate ? addHours(new Date(countDate), 3) : actualDateLessOne
  );
  const [showAlert, setShowAlert] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [personalMessage, setPersonalMessage] = useState();
  const [selectedCard, setSelectedCard] = useState();
  const [extractType, setExtractType] = useState(defaultExtractType || 'ALL');
  const [discardId, setDiscardId] = useState(filterDiscardId)
  const [stockDescriptionProduct, setStockDescriptionProduct] = useState('');
  const [stockCardDescription, setstockCardDescription] = useState([]);
  const [salesAverageDiscount, setSalesAverageDiscount] = useState();
  const [stockProduct, setStockProduct] = useState('');
  const [stockAge, setStockAge] = useState(null);
  const [stockUnit, setStockUnit] = useState(null);
  const [autocompleteDescription, setAutocompleteDescription] = useState('');
  const [debounceTimeList, setDebounceTimeList] = useState([]);
  const [differenceInExtractTooltip, setDifferenceInExtractTooltip] =
    useState(null);
  const [modalStates, setModalStates] = useState({
    modalCards: false,
    modalTechnicalSheet: false
  });
  const [rangeDateItems, setRangeDateItems] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectedItem, setSelectedItem] = useState()
  const [analytic, setAnalytic] = useState()

  const getDiffReq = (item) => {
    const { productId, date } = item;

    const handleResponse = (data) => {
      if (data) {
        setDifferenceInExtractTooltip(data);
      }
    };

    makeGet(
      'extractDiscards',
      [{ urlParam: `originId=${getId}&productId=${productId}&date=${date}` }],
      handleResponse,
      ``
    );
  };

  const handleGetDiffs = (item) => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      getDiffReq(item);
    }, 1500);

    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product?.products
                ? product?.products?.description
                : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalStates({ ...modalStates, modalTechnicalSheet: true });
  };

  const fetchCards = useCallback(
    async (product, date, startDate, finalDate, isUnique, checkConsumeValue) => {

      let dateString = `&date=${date}`;

      if(!isUnique){
        dateString = `&initialDate=${startDate}&finalDate=${finalDate}`
      }
      
      const response = await fetch(
        `${environments.catalog}/stocks/cards?originId=${getId}&productId=${
          product.id
        }${dateString}&isCount=${true}&typeData=${checkConsumeValue}`
      );
      const cards = await response.json();
        
      if (Array.isArray(cards) && cards.length > 0) {
        setStockDescriptionProduct(product.description);
        setstockCardDescription(cards);
        setStockAge(date);
        setStockUnit(
          product.consumptionUnitsPrimary
            ? product.consumptionUnitsPrimary.abbreviation
            : null
        );
        setModalStates({ ...modalStates, modalCards: true });
      } else {
        if(cards.message === 'not found cards'){
          setMessageError('not found cards');
          setStatusCode(404);
          setShowAlert(true);
        }
        setModalStates({ ...modalStates, modalEmpty: true });
      }
    },
    [getId, modalStates]
  );

  const extractDateAndDescription = useCallback(
    (item) => `
      ${moment(item.date).format(isMobileScreen ? 'DD/MM' : 'DD/MM/YYYY')}
    `,
    []
  );

  const handleCloseSalesModal = (modal) => {
    if(modal === 'modalCards') setCheckConsume(false);
    setModalStates({ ...modalStates, [modal]: false });
  };

  useEffect(() => {
    if (modalProduct) {
      setAutocompleteDescription(productModal?.description);
    } else {
      setAutocompleteDescription('');
    }
  }, [modalProduct, productModal]);

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  /* MODAL ESTOQUE TEÓRICO */
  return (
    <>
      <TheoryStockDialog
        customClassName={customClassName}
        productExtractDescription={productExtractDescription}
        productAge={productAge}
        modalProduct={modalProduct}
        modalStates={{ ...modalStates, modalTechnicalSheet, modalDiscard}}
        defaultStartDate={defaultStartDate}
        extractEndDate={extractEndDate}
        extractType={extractType}
        discardId={discardId}
        productModal={productModal}
        parameters={parameters}
        setProductModal={setProductModal}
        checkConsume={checkConsume}
        setCheckConsume={setCheckConsume}
        originPage={originPage}
        getId={getId}
        handleClose={handleClose}
        setExtractEndDate={setExtractEndDate}
        setExtractType={setExtractType}
        fetchCards={fetchCards}
        extractDateAndDescription={extractDateAndDescription}
        handleGetDiffs={handleGetDiffs}
        differenceInExtractTooltip={differenceInExtractTooltip}
        setDifferenceInExtractTooltip={setDifferenceInExtractTooltip}
        autocompleteDescription={autocompleteDescription}
        setAutocompleteDescription={setAutocompleteDescription}
        weekDays={weekDays}
        setRangeDateItems={setRangeDateItems}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setSelectedItem={setSelectedItem}
        setAnalytic={setAnalytic}
      />

      <Suspense fallback={<span>Carregando...</span>}>
        <CardsDialogs
          getId={getId}
          checkConsume={checkConsume}
          setCheckConsume={setCheckConsume}
          openModal={modalStates}
          handleClose={handleCloseSalesModal}
          stockDescriptionProduct={stockDescriptionProduct}
          stockProduct={stockProduct}
          stockCardDescription={stockCardDescription}
          stockAge={stockAge}
          stockUnit={stockUnit}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
          rangeDateItems={rangeDateItems?.map(el => el?.extracts).flat()}
          dateStart={startDate}
          dateEnd={endDate}
          selectedItem={selectedItem}
          analytic={analytic}
        />
      </Suspense>

      {selectedCard && (
        <TechnicalSheetDialog
          maxWidth={null}
          open={modalStates.modalTechnicalSheet}
          handleClose={() =>
            setModalStates({ ...modalStates, modalTechnicalSheet: false })
          }
          openModalMenu={modalStates.modalTechnicalSheet}
          cardDataTable={cardDataTable}
          cardTransformation={cardTransformation}
          selectedCard={selectedCard}
          ucPrimarySelectedId={ucPrimarySelectedId}
          salesAverageDiscount={salesAverageDiscount}
          setSalesAverageDiscount={setSalesAverageDiscount}
        />
      )}
      
      <CommonAlert
        open={showAlert}
        onClose={() => {
          setPersonalMessage(null);
          setShowAlert(false);
        }}
        messagePersonal={personalMessage}
        indexMessage={messageError}
        statusCode={statusCode}
        width="50%"
      />
    </>
  );
}

export default ExtractsDialog;
