import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, Typography, LinearProgress } from '@material-ui/core';
import { format } from 'date-fns';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makeGet from 'services/useFetch/makeGet';
import numberToReal from 'utils/numberToReal';

import { NegociationBody, NegociationHead } from './styles';

function NegociationDialog({
  openNegociationDialog,
  setOpenNegociationDialog,
  restaurantsIds, 
  productsIds, 
}) {
  const [allQuotes, setAllQuotes] = useState([])
  const [loading, setLoading] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [filters, setFilters] = useState([
    {
      param: 'originId',
      value: restaurantsIds,
      urlParam: `originId=${restaurantsIds}`
    },
    {
      param: 'productId',
      value: productsIds,
      urlParam: `productId=${productsIds}`
    },
  ]);

  const handleItems = (response) =>  {
    if (response?.quotes?.length == 0) {
      setIsEmpty(true)
      setLoading(false)
      return 
    }
    setAllQuotes(response)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    makeGet('modalTradingHistory', filters, handleItems, null);
  }, [])
  
  return (
    <Dialog
      className="defaultModal mobileModal"
      fullWidth
      maxWidth="sm"
      open={openNegociationDialog}
      aria-labelledby="draggable-dialog-title"
     >
     <DialogTitle className="modalHeader navyBlue" style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'move', fontFamily: 'Lato' }} id="draggable-dialog-title">
       <Typography>
          HISTÓRICO DE NEGOCIAÇÕES
       </Typography>

       <IconButton edge="start" color="inherit"
       onClick={() => setOpenNegociationDialog(false)}
       >
        <img src={CloseIconNavy} style={{ width: "8px" }} alt="close" />
       </IconButton>
     </DialogTitle>
     {loading && <div sytle={{ margin: "10px" }}><LinearProgress style={{ height: "7px" }} variant="query" /></div>}
     <DialogContent className="modalContent p0" style={{backgroundColor: "white"}} >
        {isEmpty ? 
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
          <Typography >
            Sem Negociações 
          </Typography>  
        </div>
        : 
        allQuotes?.quotes?.map(quote => (
          <>
            <NegociationHead>
              {quote?.provider}
            </NegociationHead>
            {quote?.quotes.map(qt => (
              <NegociationBody>
                  <div>
                    <Typography style={{width: "65px"}} >
                      {qt?.type == "MANUAL" ? "Manual" : "Fornecedor"}
                    </Typography>
                    <Typography>
                      {format(new Date(qt?.quoteDate).valueOf(), 'dd/MM/yy')} - {format(new Date(qt?.quoteDate).valueOf(), 'HH:mm')}hrs
                    </Typography>
                    <Typography>
                      R$ {numberToReal(qt?.consumptionUnitValue, 2)}/un
                    </Typography>
                  </div>
                  <hr style={{margin: "4px 12px"}} />
                </NegociationBody>
            ))}
            
          </>
        ))
        }
        
       
     </DialogContent>
     </Dialog>
  )
}

export default NegociationDialog;
