import React, { useState } from 'react';

import {
  ClickAwayListener,
  FormControl,
  Grid,
  LinearProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  CheckRounded,
  ClearRounded,
  Delete,
  EditRounded
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { useViaCep } from 'use-via-cep';

import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import Environments from 'utils/environments';

export default function ModalTable(provider) {
  const [state, setState] = useState();
  const [loading, setLoading] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [enableEditStreet, setEnableEditStreet] = useState(false);
  const [actions, setActions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([
    { id: 1, value: 'Cobrança', description: 'Cobrança' },
    { id: 1, value: 'Entrega', description: 'Entrega' }
  ]);
  const { data, setCep } = useViaCep();
  const [errors, setErrors] = useState([]);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [fieldsSettings, setFieldSettings] = useState([
    { id: 1, name: 'typeAddress', value: null, error: false, errorMessage: '' },
    { id: 2, name: 'zipCode', value: null, error: false, errorMessage: '' },
    { id: 3, name: 'number', value: null, error: false, errorMessage: '' },
    { id: 4, name: 'street', value: null, error: false, errorMessage: '' }
  ]);
  const [newData, setNewData] = useState({
    city: null,
    complement: null,
    country: null,
    number: null,
    state: null,
    street: null,
    typeAddress: null,
    zipCode: null
  });
  const useStyles = makeStyles((theme) => ({
    headerCell: {
      fontFamily: 'Lato',
      fontWeight: 'bold',
      fontSize: '12px',
      margin: '0 10px'
    },
    bodyCell:
      addMode || editMode || deleteMode
        ? {
            fontFamily: 'Lato',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            color: '#e6e6e6'
          }
        : {
            fontFamily: 'Lato',
            fontSize: '12px',
            whiteSpace: 'nowrap'
          },
    bodyCellAdd: {
      fontFamily: 'Lato',
      fontSize: '12px',
      whiteSpace: 'nowrap'
    },
    headerRow: {
      borderBottom: '1px solid #000'
    },
    bodyRow:
      addMode || editMode || deleteMode
        ? {
            background: '#fafafa'
          }
        : {
            background: '#fff'
          },
    headerActions: {
      width: '70px',
      maxWidth: '70px',
      padding: '0',
      textAlign: 'center'
    },
    bodyActions: {
      // padding: '10px',
      whiteSpace: 'nowrap',
      padding: '0'
    },
    actionIcon:
      addMode || editMode || deleteMode
        ? {
            margin: '0',
            color: '#e6e6e6'
          }
        : {
            margin: '0',
            color: '#757575'
          },
    inputRoot: {
      fontFamily: 'Lato',
      fontSize: '12px'
    },
    intput: {
      fontFamily: 'Lato',
      fontSize: '12px'
    },
    option: {
      fontFamily: 'Lato',
      fontSize: '12px'
    }
  }));
  const classes = useStyles();
  const environments = Environments(process.env.REACT_APP_ENV);

  function resetData() {
    setNewData({
      city: null,
      complement: null,
      country: 'Brasil',
      number: null,
      state: null,
      street: null,
      typeAddress: null,
      zipCode: null
    });

    setCep('');
  }

  function validateFields() {
    let count = 0;
    const zipCodePattern = /[0-9]{5}-[\d]{3}/g;

    if (!newData.typeAddress) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'typeAddress'
            ? { ...x, error: true, errorMessage: 'Campo obrigatório.' }
            : x
        )
      );
      count++;
    }
    setState({ fieldsSettings });

    if (!newData.zipCode) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'zipCode'
            ? { ...x, error: true, errorMessage: 'Campo obrigatório.' }
            : x
        )
      );

      count++;
    } else if (!zipCodePattern.test(newData.zipCode)) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'zipCode'
            ? { ...x, error: true, errorMessage: 'CEP inválido' }
            : x
        )
      );

      count++;
    }
    setState({ fieldsSettings });

    if (!newData.number) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'number'
            ? { ...x, error: true, errorMessage: 'Campo obrigatório.' }
            : x
        )
      );

      count++;
    }

    if (!newData.street) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'street'
            ? { ...x, error: true, errorMessage: 'Campo obrigatório.' }
            : x
        )
      );
      count++;
    }
    setState({ fieldsSettings });

    return count;
  }

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  function addRow() {
    const errors = validateFields();

    if (!errors) {
      Promise((resolve, reject) => {
        setTimeout(() => {
          provider.addresses.push(newData);
          setState({ provider }, () => resolve());
          axios
            .put(
              `${environments.providers}/providers/${provider.providerId}`,
              provider,
              config
            )
            .then((response) => {
              setAddMode(false);
              setLoading(false);
              resolve();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
              reject();
            });
        }, 1000);
      });
    } else {
      setLoading(false);
    }
  }

  function editRow(index, item) {
    const errors = validateFields();

    if (!errors) {
      Promise((resolve, reject) => {
        setTimeout(() => {
          provider.addresses[index] = newData;
          setActions(
            actions.map((action) =>
              action.data === item ? { ...action, action: 'list' } : action
            )
          );
          setState({ provider }, () => resolve());

          axios
            .put(
              `${environments.providers}/providers/${provider.providerId}`,
              provider,
              config
            )
            .then(() => {
              setEditMode(false);
              setLoading(false);
              resolve();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
              reject();
            });
        }, 1000);
      });
    } else {
      setLoading(false);
    }
  }

  function deleteRow(index, data) {
    Promise((resolve, reject) => {
      setTimeout(() => {
        {
          const actionIndex = actions.findIndex((x) => x.data === data);
          provider.addresses.splice(index, 1);
          actions.splice(actionIndex, 1);
          setState({ provider }, () => resolve());
        }
        axios
          .put(
            `${environments.providers}/providers/${provider.providerId}`,
            provider,
            config
          )
          .then(() => {
            setDeleteMode(false);
            setState({ actions });
            setLoading(false);
            resolve();
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            reject();
          });
      }, 1000);
    });
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setActions(actions.map((action) => ({ ...action, action: 'list' })));
        setAddMode(false);
        setEditMode(false);
        setDeleteMode(false);
        resetData();
        setFieldSettings(
          fieldsSettings.map((field) => ({
            ...field,
            error: false,
            errorMessage: ''
          }))
        );
      }}
    >
      <div className="w100 ph16">
        <div className="dFlex alignCenter mt10">
          <p
            style={{
              fontFamily: 'Lato',
              fontSize: 16,
              fontWeight: 'bold',
              marginRight: 10,
              marginBottom: 0
            }}
          >
            Endereços Cadastrados
          </p>

          <Tooltip title="Cadastrar Endereço" aria-label="add">
            <IconButton
              edge="start"
              size="small"
              color="inherit"
              style={{ outline: 0 }}
              aria-label="add"
              onClick={(e) => (setAddMode(!addMode), resetData())}
            >
              <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
            </IconButton>
          </Tooltip>
        </div>

        <TableContainer
          component={Paper}
          className="addressTable mt10"
          style={{ borderRadius: 4 }}
        >
          <Table size="small">
            <TableHead>
              <TableRow classes={{ head: classes.headerRow }}>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                >
                  TIPO
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                >
                  CEP
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 200, minWidth: 200, maxWidth: 200 }}
                >
                  LOGRADOURO
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 50, minWidth: 50, maxWidth: 50 }}
                >
                  Nº
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 70, minWidth: 70, maxWidth: 70 }}
                >
                  COMPL.
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                >
                  CIDADE
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 70, minWidth: 70, maxWidth: 70 }}
                >
                  ESTADO
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 80, minWidth: 80, maxWidth: 80 }}
                >
                  PAÍS
                </TableCell>
                <TableCell className={classes.headerActions} />
              </TableRow>
            </TableHead>

            <TableBody>
              {provider.addresses
                ? provider.addresses.map((item, index) => {
                    const exists = actions.find((x) => x.data === item);
                    if (!exists) {
                      setActions([
                        ...actions,
                        { id: index, data: item, action: 'list' }
                      ]);
                    }

                    if (exists && exists.action === 'edit') {
                      return (
                        <TableRow hover={false}>
                          <TableCell style={{ paddingRight: 0 }}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              classes={{
                                inputRoot: classes.inputRoot,
                                input: classes.input,
                                option: classes.option
                              }}
                              id="asynchronous-demo"
                              onChange={(event, value) =>
                                setNewData({
                                  ...newData,
                                  typeAddress: value.value
                                })
                              }
                              getOptionSelected={(optionsMenu, value) =>
                                optionsMenu.description === value.description
                              }
                              getOptionLabel={(optionsMenu) =>
                                optionsMenu.description
                              }
                              options={typeOptions}
                              value={typeOptions.find(
                                (x) => x.value === newData.typeAddress
                              )}
                              disableClearable
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={
                                    fieldsSettings.find(
                                      (x) => x.name === 'typeAddress'
                                    ).error
                                  }
                                  type="text"
                                  placeholder="Tipo"
                                  name="typeAddress"
                                />
                              )}
                            />

                            <Tooltip
                              title={
                                fieldsSettings.find(
                                  (x) => x.name === 'typeAddress'
                                ).errorMessage
                              }
                              placement="bottom-start"
                              open={
                                fieldsSettings.find(
                                  (x) => x.name === 'typeAddress'
                                ).error
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                            >
                              <span width="1px" />
                            </Tooltip>
                          </TableCell>

                          <TableCell>
                            <TextField
                              fullWidth
                              size="small"
                              placeholder="CEP"
                              name="quantity"
                              value={newData.zipCode}
                              error={
                                fieldsSettings.find((x) => x.name === 'zipCode')
                                  .error
                              }
                              inputProps={{ maxLength: 9 }}
                              onInput={(e) => {
                                e.target.value = e.target.value
                                  .replace(/[^0-9-]/g, '')
                                  .replace(/(\d{5})(\d)/, '$1-$2');
                              }}
                              onChange={(event) => {
                                setCep(event.target.value);
                                setState({ data });
                                setNewData({
                                  ...newData,
                                  zipCode: event.target.value
                                });
                              }}
                              onBlur={(event) => {
                                setNewData({
                                  ...newData,
                                  street: data.logradouro,
                                  city: data.localidade,
                                  state: data.uf,
                                  zipCode: event.target.value
                                });
                                setState({ newData });
                                setEnableEditStreet(!!data.logradouro);
                              }}
                              InputLabelProps={{
                                style: { fontFamily: 'Lato' }
                              }}
                              InputProps={{
                                style: { fontFamily: 'Lato', fontSize: '12px' }
                              }}
                            />

                            <Tooltip
                              title={
                                fieldsSettings.find((x) => x.name === 'zipCode')
                                  .errorMessage
                              }
                              placement="bottom-start"
                              open={
                                fieldsSettings.find((x) => x.name === 'zipCode')
                                  .error
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                            >
                              <span width="1px" />
                            </Tooltip>
                          </TableCell>

                          {!enableEditStreet ? (
                            <TableCell className={classes.bodyCellAdd}>
                              <FormControl required>
                                <TextField
                                  fullWidth
                                  size="small"
                                  placeholder="Logradouro"
                                  name="street"
                                  value={newData.street}
                                  error={
                                    fieldsSettings.find(
                                      (x) => x.name === 'street'
                                    ).error
                                  }
                                  onChange={(event) => {
                                    setFieldSettings(
                                      fieldsSettings.map((x) =>
                                        x.name === 'street'
                                          ? {
                                              ...x,
                                              error: false,
                                              errorMessage: ''
                                            }
                                          : x
                                      )
                                    );
                                    setNewData({
                                      ...newData,
                                      street: event.target.value
                                    });
                                    setState({ newData });
                                  }}
                                  InputLabelProps={{
                                    style: { fontFamily: 'Lato' }
                                  }}
                                  InputProps={{
                                    style: {
                                      fontFamily: 'Lato',
                                      fontSize: '12px'
                                    }
                                  }}
                                />
                                <Tooltip
                                  title={
                                    fieldsSettings.find(
                                      (x) => x.name === 'street'
                                    ).errorMessage
                                  }
                                  placement="bottom-start"
                                  open
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  arrow
                                >
                                  <span width="1px" />
                                </Tooltip>
                              </FormControl>
                            </TableCell>
                          ) : (
                            <TableCell className={classes.bodyCellAdd}>
                              {data.logradouro}
                            </TableCell>
                          )}

                          <TableCell className={classes.bodyCellAdd}>
                            <TextField
                              fullWidth
                              size="small"
                              placeholder="Nº"
                              name="number"
                              required
                              error={
                                fieldsSettings.find((x) => x.name === 'number')
                                  .error
                              }
                              value={newData.number}
                              onChange={(event) => {
                                setNewData({
                                  ...newData,
                                  number: event.target.value
                                });
                                setState({ newData });
                              }}
                              InputLabelProps={{
                                style: { fontFamily: 'Lato' }
                              }}
                              InputProps={{
                                style: { fontFamily: 'Lato', fontSize: '12px' }
                              }}
                            />

                            <Tooltip
                              title={
                                fieldsSettings.find((x) => x.name === 'number')
                                  .errorMessage
                              }
                              placement="bottom-start"
                              open={
                                fieldsSettings.find((x) => x.name === 'number')
                                  .error
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                            >
                              <span width="1px" />
                            </Tooltip>
                          </TableCell>

                          <TableCell className={classes.bodyCellAdd}>
                            <TextField
                              fullWidth
                              size="small"
                              placeholder="Compl."
                              name="complement"
                              value={newData.complement}
                              onChange={(event) => {
                                setNewData({
                                  ...newData,
                                  complement: event.target.value
                                });
                                setState({ newData });
                              }}
                              InputLabelProps={{
                                style: { fontFamily: 'Lato' }
                              }}
                              InputProps={{
                                style: { fontFamily: 'Lato', fontSize: '12px' }
                              }}
                            />
                          </TableCell>

                          <TableCell className={classes.bodyCellAdd}>
                            {newData.city}
                          </TableCell>

                          <TableCell className={classes.bodyCellAdd}>
                            {newData.state}
                          </TableCell>

                          <TableCell className={classes.bodyCellAdd}>
                            {newData.country}
                          </TableCell>

                          <TableCell className={classes.bodyActions}>
                            <Grid container xs={12} justify="center">
                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  disabled={!errors}
                                  edge="start"
                                  type="submit"
                                  size="small"
                                  color="inherit"
                                  className={classes.actionIcon}
                                  onClick={(e) => (
                                    setLoading(true), editRow(index, item)
                                  )}
                                >
                                  <CheckRounded />
                                </IconButton>
                              </Grid>

                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  className={classes.actionIcon}
                                  onClick={(e) => (
                                    setActions(
                                      actions.map((action) =>
                                        action.data === item
                                          ? { ...action, action: 'list' }
                                          : action
                                      )
                                    ),
                                    setEditMode(false)
                                  )}
                                >
                                  <ClearRounded />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    } if (exists && exists.action === 'delete') {
                      return (
                        <TableRow className={classes.bodyRow}>
                          <TableCell
                            className={classes.bodyCellAdd}
                            colSpan={8}
                          >
                            Deseja excluir?
                          </TableCell>

                          <TableCell className={classes.bodyActions}>
                            <Grid container xs={12} justify="center">
                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  onClick={(e) => (
                                    setLoading(true), deleteRow(index, item)
                                  )}
                                >
                                  <CheckRounded />
                                </IconButton>
                              </Grid>

                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  onClick={(e) => (
                                    setActions(
                                      actions.map((action) =>
                                        action.data === item
                                          ? { ...action, action: 'list' }
                                          : action
                                      )
                                    ),
                                    setDeleteMode(false)
                                  )}
                                >
                                  <ClearRounded />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    } 
                      return (
                        <TableRow
                          className={classes.bodyRow}
                          hover={!addMode && !editMode && !deleteMode}
                        >
                          <TableCell className={classes.bodyCell}>
                            {item.typeAddress}
                          </TableCell>

                          <TableCell className={classes.bodyCell}>
                            {item.zipCode
                              ? item.zipCode.replace(/(\d{5})(\d)/, '$1-$2')
                              : null}
                          </TableCell>

                          <TableCell className={classes.bodyCell}>
                            {item.street}
                          </TableCell>

                          <TableCell className={classes.bodyCell}>
                            {item.number}
                          </TableCell>

                          <TableCell className={classes.bodyCell}>
                            {item.complement}
                          </TableCell>

                          <TableCell className={classes.bodyCell}>
                            {item.city}
                          </TableCell>

                          <TableCell className={classes.bodyCell}>
                            {item.state}
                          </TableCell>

                          <TableCell className={classes.bodyCell}>
                            {item.country}
                          </TableCell>

                          <TableCell className={classes.bodyActions}>
                            <Grid container xs={12} justify="center">
                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  edge="start"
                                  style={{ outline: 0 }}
                                  size="small"
                                  color="inherit"
                                  disabled={addMode}
                                  className={classes.actionIcon}
                                  onClick={(e) => (
                                    setActions(
                                      actions.map((action) =>
                                        action.data === item
                                          ? { ...action, action: 'edit' }
                                          : action
                                      )
                                    ),
                                    setNewData(item),
                                    setEditMode(true)
                                  )}
                                >
                                  <EditRounded className={classes.actionIcon} />
                                </IconButton>
                              </Grid>

                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  edge="start"
                                  style={{ outline: 0 }}
                                  size="small"
                                  color="inherit"
                                  disabled={addMode || editMode || deleteMode}
                                  className={classes.actionIcon}
                                  onClick={(e) => (
                                    setActions(
                                      actions.map((action) =>
                                        action.data === item
                                          ? { ...action, action: 'delete' }
                                          : action
                                      )
                                    ),
                                    setDeleteMode(true)
                                  )}
                                >
                                  <Delete className={classes.actionIcon} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    
                  })
                : null}
              {addMode ? (
                <TableRow hover={false}>
                  <TableCell style={{ paddingRight: 0 }}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      classes={{
                        inputRoot: classes.inputRoot,
                        input: classes.input,
                        option: classes.option
                      }}
                      id="asynchronous-demo"
                      onChange={(event, value) =>
                        setNewData({ ...newData, typeAddress: value.value })
                      }
                      getOptionSelected={(optionsMenu, value) =>
                        optionsMenu.description === value.description
                      }
                      getOptionLabel={(optionsMenu) => optionsMenu.description}
                      options={typeOptions}
                      value={typeOptions.find(
                        (x) => x.value === newData.typeAddress
                      )}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            fieldsSettings.find((x) => x.name === 'typeAddress')
                              .error
                          }
                          type="text"
                          placeholder="Tipo"
                          name="typeAddress"
                        />
                      )}
                    />

                    <Tooltip
                      title={
                        fieldsSettings.find((x) => x.name === 'typeAddress')
                          .errorMessage
                      }
                      placement="bottom-start"
                      open={
                        fieldsSettings.find((x) => x.name === 'typeAddress')
                          .error
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                    >
                      <span width="1px" />
                    </Tooltip>
                  </TableCell>

                  <TableCell>
                    <FormControl required>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="CEP"
                        name="quantity"
                        value={newData.zipCode}
                        error={
                          fieldsSettings.find((x) => x.name === 'zipCode').error
                        }
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/[^0-9-]/g, '')
                            .replace(/(\d{5})(\d)/, '$1-$2');
                        }}
                        inputProps={{ maxLength: 9 }}
                        onChange={(event) => {
                          setFieldSettings(
                            fieldsSettings.map((x) =>
                              x.name === 'zipCode'
                                ? { ...x, error: false, errorMessage: '' }
                                : x
                            )
                          );
                          setCep(event.target.value);
                          setNewData({
                            ...newData,
                            zipCode: event.target.value
                          });
                          setState({ newData });
                        }}
                        onBlur={(event) => {
                          setNewData({
                            ...newData,
                            street: data.logradouro,
                            city: data.localidade,
                            state: data.uf,
                            zipCode: event.target.value
                          });
                          setEnableEditStreet(!!data.logradouro);
                          setState({ newData });
                        }}
                        InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                      />
                      <Tooltip
                        title={
                          fieldsSettings.find((x) => x.name === 'zipCode')
                            .errorMessage
                        }
                        placement="bottom-start"
                        open={
                          fieldsSettings.find((x) => x.name === 'zipCode').error
                        }
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                      >
                        <span width="1px" />
                      </Tooltip>
                    </FormControl>
                  </TableCell>

                  {!enableEditStreet ? (
                    <TableCell className={classes.bodyCellAdd}>
                      <FormControl required>
                        <TextField
                          fullWidth
                          size="small"
                          placeholder="Logradouro"
                          name="street"
                          value={newData.street}
                          error={
                            fieldsSettings.find((x) => x.name === 'street')
                              .error
                          }
                          onChange={(event) => {
                            setFieldSettings(
                              fieldsSettings.map((x) =>
                                x.name === 'street'
                                  ? { ...x, error: false, errorMessage: '' }
                                  : x
                              )
                            );
                            setNewData({
                              ...newData,
                              street: event.target.value
                            });
                            setState({ newData });
                          }}
                          InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                          InputProps={{
                            style: { fontFamily: 'Lato', fontSize: '12px' }
                          }}
                        />
                        <Tooltip
                          title={
                            fieldsSettings.find((x) => x.name === 'street')
                              .errorMessage
                          }
                          placement="bottom-start"
                          open
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                        >
                          <span width="1px" />
                        </Tooltip>
                      </FormControl>
                    </TableCell>
                  ) : (
                    <TableCell className={classes.bodyCellAdd}>
                      {data.logradouro}
                    </TableCell>
                  )}

                  <TableCell className={classes.bodyCellAdd}>
                    <FormControl required>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Nº"
                        name="number"
                        value={newData.number}
                        error={
                          fieldsSettings.find((x) => x.name === 'number').error
                        }
                        onChange={(event) => {
                          setFieldSettings(
                            fieldsSettings.map((x) =>
                              x.name === 'number'
                                ? { ...x, error: false, errorMessage: '' }
                                : x
                            )
                          );
                          setNewData({
                            ...newData,
                            number: event.target.value
                          });
                          setState({ newData });
                        }}
                        InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                      />
                      <Tooltip
                        title={
                          fieldsSettings.find((x) => x.name === 'number')
                            .errorMessage
                        }
                        placement="bottom-start"
                        open
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                      >
                        <span width="1px" />
                      </Tooltip>
                    </FormControl>
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                    <FormControl required>
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Compl."
                        name="complement"
                        value={newData.complement}
                        onChange={(event) => {
                          setNewData({
                            ...newData,
                            complement: event.target.value
                          });
                          setState({ newData });
                        }}
                        InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                      />
                    </FormControl>
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                    {data.localidade}
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                    {data.uf}
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                    {newData.country}
                  </TableCell>

                  <TableCell className={classes.bodyActions}>
                    <Grid container xs={12} justify="center">
                      <Grid container item justify="center" xs={6}>
                        <IconButton
                          edge="start"
                          style={{ outline: 0 }}
                          size="small"
                          color="inherit"
                          className={classes.actionIcon}
                          onClick={(e) => (setLoading(true), addRow())}
                        >
                          <CheckRounded />
                        </IconButton>
                      </Grid>

                      <Grid container item justify="center" xs={6}>
                        <IconButton
                          edge="start"
                          style={{ outline: 0 }}
                          size="small"
                          color="inherit"
                          className={classes.actionIcon}
                          onClick={(e) => (resetData(), setAddMode(false))}
                        >
                          <ClearRounded />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : null}

              {loading ? (
                <TableRow>
                  <TableCell colSpan="9" sytle={{ margin: '10px' }}>
                    <LinearProgress variant="query" />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ClickAwayListener>
  );
}
