import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import ValidationLength from 'utils/utils';

function DialogSkuTags({
  open,
  PaperComponent,
  dialogTagsSettings,
  setDialogTagsSettings
}) {
  const sku = dialogTagsSettings.find((item) => item.name === 'skuTags')?.sku;
  const { description } = sku;

  const handleClose = () => {
    setDialogTagsSettings(
      dialogTagsSettings.map((modal) =>
        modal.name === 'skuTags' ? { ...modal, open: false } : modal
      )
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      className="defaultModal mediumModal minWidth"
      PaperComponent={PaperComponent}
      maxWidth="lg"
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
        fullWidth
      >
        <Typography>{description} TAGS</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <table className="desktop" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr className="headerTitle">
              <th width="70%" className="subtitle">
                TAGS
              </th>
              <th width="30%" className="subtitle right p10">
                VALORES
              </th>
            </tr>
          </thead>

          <tbody>
            {sku?.tags.length ? (
              sku?.tags.map((tag) => (
                <>
                  <tr>
                    <td className="description left">
                      {ValidationLength(tag.description, 30)}
                    </td>

                    <td className="description right p10">
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={
                          tag.values.length ? (
                            tag.values.map((tagValue, index) => (
                              <p
                                style={{
                                  marginBottom:
                                    tag.values.length - 1 === index
                                      ? '0'
                                      : '5px'
                                }}
                              >
                                {tagValue.description}
                              </p>
                            ))
                          ) : (
                            <p className="mb0">Nenhum registro</p>
                          )
                        }
                      >
                        <span style={{ cursor: 'default' }}>
                          {tag.values.length}
                        </span>
                      </Tooltip>
                    </td>
                  </tr>

                  <tr>
                    <td className="p0" colSpan="2">
                      <hr />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td
                  className="description center"
                  style={{ fontSize: '14px' }}
                  colSpan="2"
                >
                  Nenhum registro
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default DialogSkuTags;
