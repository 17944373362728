import React, { useState } from 'react';

import { KeyboardArrowUp } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { format } from 'date-fns';
import moment from 'moment';

import numberToReal from 'utils/numberToReal';

import { CardInfos, CardOpenCollapse, SkuDialogCard } from './styles';

import { ItemWrapper } from '../../../dialogs/style';

function SkuDialogCards({skus, getColorStatus}) {
  const [openCardContent, setOpenCardContent] = useState(false)

  return (
  <SkuDialogCard>
      <div>
        {
        !openCardContent ?
        <> 
          <div>{skus?.description}</div>
          <div className="uc" style={{ backgroundColor: "transparent", height: "auto", padding: "4px" }}>
          <ItemWrapper style={{ width: "100%", textAlign: "end" }} buttonLink={getColorStatus(skus.description, skus.priceUc)}>
            {numberToReal(skus?.priceUc, 2)}<br />/{skus?.consumptionUnit}
          </ItemWrapper>
          </div>
          <div className="contacoes" >
            <p style={{color: moment(new Date()).isAfter(skus?.expirationDateBestQuoation) ? "#AA0816" : "#0E1337"}}>{numberToReal(skus?.priceBestQuotation, 2)}</p>
            <p style={{color: moment(new Date()).isAfter(skus?.expirationDateBestQuoation) ? "#AA0816" : "#0E1337"}}>{format(new Date(skus?.expirationDateBestQuoation).valueOf() + 10800000, 'dd/MM/yyyy')}</p>
           
          </div>
        </>
        : <div style={{width: "100%"}} >{skus?.description}</div>
        }
        
        <div onClick={() => setOpenCardContent(!openCardContent)} >
        {openCardContent ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
        </div>
      </div>
      {openCardContent ? 
        <CardOpenCollapse>
          <div style={{width: "100%"}} className="cardHead" >
            <div
            style={{
              backgroundColor: skus?.brandFavorite ?  "#DADDF3" : "#F2F3F8" ,
              color: skus?.brandFavorite ? "#D0D3E2" : "#DADDF3"
              }} >
              <p>Preferida</p>
            </div>
            <div
            style={{
              backgroundColor: skus?.brandFavorite ?  "#DADDF3" : "#F2F3F8" ,
              color: skus?.brandFavorite ? "#D0D3E2" : "#DADDF3"
              }}
            >
              <p>Aceita</p>
            </div>
          </div>
          <CardInfos>
            <div className="firstData" style={{width: "100%"}} >
              <div style={{width: "80px"}} >
                <p style={{ fontSize: "9px" }}>MÉD R$</p>
                <p>{numberToReal(skus?.purchaseAverage, 2)}</p>
              </div>
              <div>
                <p style={{ fontSize: "9px" }}>ULT R$</p>
                <p>{numberToReal(skus?.purchaseLast, 2)}</p>
              </div>
              <div>
                <p style={{ fontSize: "9px" }}>CONV R$</p>
                <p>{numberToReal(skus?.conversion, 2)}</p>
              </div>
            </div>
            <div style={{width: "100%"}} >
              <div >
                <p style={{ fontSize: "9px" }}>U.C. R$</p>
                <div style={{ padding: "0px" }}>
                <ItemWrapper buttonLink={getColorStatus(skus.description, skus.priceUc)}>
                  {numberToReal(skus?.priceUc, 2)}/{skus?.consumptionUnit}
                </ItemWrapper>
                </div>
              </div>
              <div className="quote" >
                <p style={{ fontSize: "9px" }}>COTAÇÃO R$</p>
                <p style={{color: moment(new Date()).isAfter(skus?.expirationDateBestQuoation) ? "#AA0816" : "#0E1337"}}>{numberToReal(skus?.priceBestQuotation, 2)}</p>
              </div>
              <div className="validate" >
                <p style={{ fontSize: "9px" }}>VALIDADE</p>
                <p style={{color: moment(new Date()).isAfter(skus?.expirationDateBestQuoation) ? "#AA0816" : "#0E1337"}} >{format(new Date(skus?.expirationDateBestQuoation).valueOf() + 10800000, 'dd/MM/yyyy')}</p>
              </div>
            </div>
          </CardInfos>
        </CardOpenCollapse>
      : <div />
      }
      <hr style={{margin: "8px 0"}} />
      
    </SkuDialogCard>
  )
}

export default SkuDialogCards;