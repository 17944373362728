import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Typography
} from '@material-ui/core';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

import { CloseIcon, ButtonsWrapper, DialogPragraph } from './style';

function AssertUploadDialog({
  openModal,
  handleClose,
  onClose,
  stopUploadProcess
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      style={{ position: 'absolute', width: '500px' }}
      open={openModal}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal prioritySkusDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Interromper processamento</Typography>

        <IconButton edge="start" color="inherit" onClick={() => handleClose()}>
          <CloseIcon
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent">
        <duv>
          <DialogPragraph>
            Caso saia agora você perderá o processamento dos arquivos. Tem
            certeza que deseja sair?
          </DialogPragraph>
        </duv>

        <ButtonsWrapper>
          <Button
            className="defaultButton backButton"
            onClick={() => handleClose()}
            style={{ outline: 0, position: 'relative', marginRight: '4px' }}
            color="primary"
          >
            Não
          </Button>

          <Button
            className="defaultButton submitButton"
            onClick={() => stopUploadProcess()}
            style={{ outline: 0, position: 'relative' }}
            color="primary"
          >
            Sim
          </Button>
        </ButtonsWrapper>
      </DialogContent>
    </Dialog>
  );
}

export default AssertUploadDialog;
