/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import {
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
  Paper,
  Dialog
} from '@material-ui/core';
import axios from 'axios';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import Localization, { LocalizationPlainText } from 'components/Localization';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import * as UserActions from 'store/actions/user';
import { Container } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-producer-form"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ProducerForm({
  userInfo,
  producersInfo,
  redirectPath,
  isModal,
  onClose,
  setUpdateList,
  createMode,
  openModal
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();
  const [alertErr, setAlertErr] = useState(false);
  const onDismiss = () => setAlertErr(false);
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const [editMode, setEditMode] = useState(
    createMode !== true
      ? !!(
          producersInfo.action && producersInfo.action.toLowerCase() === 'edit'
        )
      : false
  );
  const [producersId, setProducersId] = useState(
    editMode ? producersInfo.data.id : ''
  );
  const [description, setDescription] = useState(
    editMode ? producersInfo.data.description : ''
  );

  useEffect(() => {
    setEditMode(
      !!(producersInfo.action && producersInfo.action.toLowerCase() === 'edit')
    );
    setProducersId(editMode ? producersInfo.data.id : '');
    setDescription(editMode ? producersInfo.data.description : '');
  }, [producersInfo, editMode]);

  const onSubmitProducer = () => {
    setLoading(true);
    if (editMode) {
      axios
        .put(
          `${environments.catalog}/producers/${producersId}`,
          {
            method: 'PUT',
            description,
            user: userInfo.user
          },
          config
        )
        .then(() => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
          // const { data } = res
        })
        .catch(() => {
          setLoading(false);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    } else {
      axios
        .post(
          `${environments.catalog}/producers`,
          {
            method: 'POST',
            description,
            originId: userInfo.companiesActive.id,
            // originId: originIds.map((restaurant) => restaurant.id))
            user: userInfo.user
          },
          config
        )
        .then((res) => {
          setOpen(true);
          setLoading(false);
          setUpdateList && setUpdateList(true);
          onClose &&
            setTimeout(() => {
              onClose();
            }, 1000);
          // const { data } = res
        })
        .catch((error) => {
          setLoading(false);
          setAlertErr(true);
          setUpdateList && setUpdateList(false);
        });
    }
  };

  const handleClose = (reason) => {
    if (redirectPath !== null) {
      history.push(redirectPath);
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Dialog
      className="defaultModal"
      style={{ width: '50%' }}
      maxWidth="xl"
      open={openModal}
      onClose={onClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title-producer-form"
      transitionDuration={0}
    >
      <Container className="w100 mb0">
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          style={{ borderRadius: '4px 4px 0 0' }}
          id="draggable-dialog-title-producer-form"
        >
          <Typography>CADASTRO DE FABRICANTES</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        {alertErr ? (
          <Alert
            style={{ width: '100%', borderRadius: 0, margin: 0 }}
            color="danger"
            isOpen={alertErr}
            toggle={onDismiss}
          >
            <Localization text="ErrorToRecord" />
          </Alert>
        ) : null}

        {loading ? <LinearProgress /> : null}

        <div
          className="cardDefault pt16"
          style={{ borderRadius: '0', width: '614px' }}
        >
          <form onSubmit={handleSubmit(onSubmitProducer)}>
            <Grid className="m0" container xs={12} sm={12} spacing={4}>
              <Grid item xs={12} sm={12} className="pl0 pr0">
                <TextField
                  label="Nome"
                  onChange={(e) => setDescription(e.target.value)}
                  name="description"
                  variant="outlined"
                  value={description}
                  fullWidth
                  size="small"
                  required="required"
                  inputProps={{ maxLength: 50 }}
                  className="textFieldSmall"
                  placeholder={LocalizationPlainText('TypeHere', 'translations')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>

            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="success">
                <Localization text="succussesRegister" />
              </Alert>
            </Snackbar>

            <Grid
              item
              xs={12}
              sm={12}
              className="p0 dFlex justifyEnd bgWhite"
              style={{ borderRadius: '0 0 4px 4px' }}
            >
              {!isModal && (
                <Link to="/catalogo/fabricantes/">
                  <Button
                    className="defaultButton backButton mr20"
                    design="outlined"
                    label={<Localization text="back" />}
                  />
                </Link>
              )}

              <Button
                className="defaultButton submitButton"
                type="submit"
                label="Salvar"
                design="contained"
                onSubmit={handleSubmit(onSubmitProducer)}
              />
            </Grid>
          </form>
        </div>
      </Container>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  producersInfo: state.producers
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProducerForm);
