import React, { useMemo } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Typography
} from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import moment from 'moment';
import { Alert } from 'reactstrap';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

function DialogCompleteNote({
  openModalOrder,
  handleCloseModal,
  PaperComponent,
  selectedReceiveDate,
  handleReceiveDateChange,
  submitted,
  setSubmitted,
  putEditSku,
  errMsg,
  handleClose,
  selectedItem
}) {
  /* Modal Nota Completa */

  const receiveCreatedDateMinusOneDay = useMemo(
    () => moment(selectedItem?.orderDate).subtract(1, 'd'),
    [selectedItem]
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={openModalOrder}
      onClose={handleCloseModal}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Nota completa</Typography>

        <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent p12">
        <Grid container spacing={6} className="w100 m0">
          <Grid
            item
            xs={12}
            sm={6}
            className="pl0 pb10 pr10"
            style={{ paddingTop: '24px' }}
          >
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <DatePicker
                fullWidth
                format="dd/MM/yyyy"
                size="small"
                className="dateSmall"
                inputVariant="outlined"
                label="Data de recebimento"
                autoOk
                views={['year', 'month', 'date']}
                value={selectedReceiveDate}
                onChange={handleReceiveDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                shouldDisableDate={(date) =>
                  date < receiveCreatedDateMinusOneDay
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} sm={6} className="pt20 p10 pr0 pl10">
            <Button
              variant="contained"
              style={{
                background: submitted ? '#AFBCF3' : '#5062F0',
                color: '#FFFFFF',
                borderRadius: '8px',
                textTransform: 'initial',
                fontFamily: 'Lato',
                height: '40px'
              }}
              fullWidth
              disabled={submitted}
              onClick={() => {
                setSubmitted(true);
                putEditSku('RECEIVED', null, true);
              }}
            >
              Receber
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={6} className="w100 m0">
          <Grid item xs={12} sm={6} className="pt20 pb10 pl0 p10">
            <Button
              variant="contained"
              style={{
                background: '#F7DFDF',
                color: '#D94141',
                borderRadius: '8px',
                border: 'solid 1px #D94141',
                textTransform: 'initial',
                fontFamily: 'Lato',
                height: '40px'
              }}
              fullWidth
              disabled={submitted}
              onClick={() => {
                setSubmitted(true);
                putEditSku('RETURNED', null, true);
              }}
            >
              Devolver
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} className="pt20 pb10 pr0 p10">
            <Button
              variant="contained"
              style={{
                background: '#FCF5E3',
                color: '#E6A117',
                borderRadius: '8px',
                textTransform: 'initial',
                fontFamily: 'Lato',
                height: '40px'
              }}
              fullWidth
              disabled={submitted}
              onClick={() => {
                setSubmitted(true);
                putEditSku('NOT RECEIVED', null, true);
              }}
            >
              Não recebido
            </Button>
          </Grid>
        </Grid>

        {submitted && <LinearProgress variant="query" />}

        <Snackbar open={errMsg} autoHideDuration={10000} onClose={handleClose}>
          <Alert className="alert-error" onClose={handleClose} severity="error">
            Preencha os valores de recebimentos.
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
}

export default DialogCompleteNote;
