export function setDiscards(data) {
  return {
      type: 'SET_DISCARDS_DATA',
      data,
  };
}

export function createDiscards(data) {
  return {
      type: 'CREATE_DISCARDS_DATA',
      data,
  };
}