import React, { useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';

import useFetch from 'services/useFetch';
import numberToReal from 'utils/numberToReal';

import {
  OtherStocksWrapper,
  Content,
  ContentHead,
  ContentList,
  ContentItem
} from './index.style';

function OtherStocksDialog({ reference, loadOtherStocksProps }) {
  const [stocksItens, setStockItens] = useState([]);

  const { id = '', date = '' } = loadOtherStocksProps;

  const { data, loading } = useFetch(
    'saveInventoriesProducts',
    false,
    false,
    `${id}/stocks/${date}`
  );

  useEffect(() => {
    if (!data?.content?.length) return;
    const { content = [] } = data;
    setStockItens(content);
  }, [data]);

  return (
    <OtherStocksWrapper>
      <Content ref={reference}>
        <ContentHead>Últimas Contagens</ContentHead>
        <ContentList>
          {loading && <LinearProgress id="loading" variant="query" />}
          {stocksItens.map(({ stock, quantity = 0 }) => (
            <ContentItem>
              <div>{stock}</div>
              <div>{numberToReal(quantity)}</div>
            </ContentItem>
          ))}
        </ContentList>
      </Content>
    </OtherStocksWrapper>
  );
}

export default OtherStocksDialog;
