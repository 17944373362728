import { LinearProgress, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },

  nested: {
    paddingLeft: theme.spacing(4)
  },

  inputRoot: {
    fontFamily: 'Lato'
  },

  intput: {
    fontFamily: 'Lato'
  },

  option: {
    fontFamily: 'Lato'
  }
}));

export const BorderLinearProgress = withStyles({
  root: {
    width: 'auto',
    height: 15,
    borderRadius: 20,
    marginTop: 0,
  },

  bar: {
    borderRadius: 20
  }
})(LinearProgress);

export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

export const TrDesk = styled.thead`
        th,
        td {
          background: #313347;
          position: sticky;
          top: -6px;
          z-index: 99;
          color: #FFF;

          &:first-of-type {
            overflow: hidden;
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            overflow: hidden;
            border-top-right-radius: 4px;
          }

          &:first-of-type,
          &:last-of-type {
            padding: 0 12px;
          }
        }
        
        @media (max-width: 870px) {
          display: none;
        }
`;

export const TrTablet = styled.thead`
  @media (max-width: 870px) {
        th,
        td {
          background: #313347;
          position: sticky;
          top: -6px;
          z-index: 99;
          color: #FFF;

          &:first-of-type {
            overflow: hidden;
            border-top-left-radius: 4px;
          }

          &:last-of-type {
            overflow: hidden;
            border-top-right-radius: 4px;
          }

          &:first-of-type,
          &:last-of-type {
            padding: 0 12px;
          }
        }
}

        @media (min-width: 870px) {
          display: none;
        }

`;

export const DesktopTableWrapper = styled.div`
    @media only screen and (max-width: 768px) {
        display: none;
    }
`;
