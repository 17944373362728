/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  LinearProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import PaperComponent from 'components/PaperComponent';
import TableIcon from 'components/TableIcon';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import { countProviders } from 'pages/catalog/products/index';
import RegisterProductModal from 'pages/catalog/products/ProductForm';
import getOptions from 'services/getOptions';
import * as ProductsActions from 'store/actions/products';
import Environment from 'utils/environments';

import {
  IconsContainer,
  InfoBox,
  MainContainer,
  ProductInfosContainer
} from './styles';

const environments = Environment(process.env.REACT_APP_ENV);

function UnifyProducts({
  setEditProducts,
  productInfo,
  handleClosePopover,
  unifyProductsPopover,
  setUnifyProductsPopover,
  productsToUnifyGroupMainInfos,
  handleUnifyProducts,
  handleSelectTypeOfUnification,
  validationLength,
  isLoading,
  handleClickSkus,
  handleClickCards,
  handleClickProviders
}) {
  const { id: getId } = useParams();
  const [typeOfUnification, setTypeOfUnification] = useState({
    selected: productsToUnifyGroupMainInfos?.description,
    disabled: productsToUnifyGroupMainInfos?.sameType,
    options: ['Restaurante', 'Grupo']
  });
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCard, setSelectedCard] = useState('');
  const [cardDataTable, setCardDataTable] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'modalProduct', open: false, fullScreen: false },
    { id: 2, name: 'modalTechnicalSheet', open: false, fullScreen: false }
  ]);

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'modalTechnicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  useEffect(() => {
    if (!modalSettings.find((modal) => modal.name === 'modalProduct').open)
      return;

    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        getId
      );
  }, [
    getId,
    modalSettings.find((modal) => modal.name === 'modalProduct').open
  ]);

  useEffect(() => {
    if (selectedProduct) {
      const arraySkus = [];
      selectedProduct.skus.forEach((skus) => {
        arraySkus.push({
          id: skus.skus ? skus.skus.id : null,
          description: skus.skus ? skus.skus.description : null,
          quantity: skus.skus
            ? parseFloat(skus.skus.quantity).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          unitsMeasurements: skus.skus
            ? skus.skus.unitsMeasurements.abbreviation
            : null,
          purchaseAverage:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseAverage
              : null,
          purchaseLast:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseLast
              : null,
          conversion: skus.skus
            ? parseFloat(skus.conversion).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          priceUc: skus ? skus.priceUc : null,
          restaurants: skus?.skus?.restaurants || [],
          providers: skus?.skus?.providers || []
        });
      });

      selectedProduct.skus = arraySkus;
    }

    setEditProducts(selectedProduct);

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'modalProduct' ? { ...modal, open: true } : modal
      )
    );
  }, [selectedProduct]);

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  return (
    <>
      <Dialog
        open={unifyProductsPopover?.open}
        onClose={() => handleClosePopover()}
        aria-labelledby="responsive-dialog-title"
        className="unifyProductsPopoverDialog defaultModal fakePopover"
        PaperComponent={(props) => (
          <PaperComponent {...props} handleId="#unifyProductsPopover" />
        )}
      >
        {isLoading ? (
          <LinearProgress
            style={{ height: '7px' }}
            id="loading"
            variant="query"
          />
        ) : null}

        <DialogTitle
          className="modalHeader transparent bold textAlignCenter cursorMove"
          id="unifyProductsPopover"
        >
          <Typography className="dFlex black justifyCenter bold initialTransform w100">
            Tem certeza que deseja unificar o cadastro destes{' '}
            {unifyProductsPopover?.products?.length} produtos?
          </Typography>
        </DialogTitle>

        <DialogContent className="modalContent bgWhite pb0">
          <div className="optionsList dFlex flexColumn">
            <span className="bold mb12 textAlignCenter">
              Selecione o produto que será a referência dos dados desta
              unificação:
            </span>

            <MainContainer>
              <IconsContainer>
                <InfoBox>
                  <TableIcon icon="sku" name="Skus" />
                </InfoBox>

                <InfoBox>
                  <TableIcon icon="card" name="Fichas" />
                </InfoBox>

                <InfoBox>
                  <TableIcon icon="provider" name="Fornecedores" />
                </InfoBox>
              </IconsContainer>

              {unifyProductsPopover?.products?.map((product) => (
                <div className="optionBox dFlex alignCenter">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="type"
                      name="type"
                      value={unifyProductsPopover?.refProduct}
                      onChange={() => {
                        setUnifyProductsPopover({
                          ...unifyProductsPopover,
                          refProduct: product?.id
                        });
                      }}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value={product?.id}
                        control={<Radio required size="small" />}
                        label="Produto:"
                      />
                    </RadioGroup>
                  </FormControl>

                  <Typography
                    style={{ marginTop: 3 }}
                    onClick={() => {
                      if (product?.type === 'INTERNAL') {
                        getOptions(
                          `${environments.catalog}/cards/${product?.originIdCard}`,
                          'cards',
                          selectedCard,
                          setSelectedCard,
                          getId,
                          null,
                          null,
                          null,
                          true
                        );
                      } else {
                        getOptions(
                          `${environments.catalog}/products/${product?.id}`,
                          'products',
                          selectedProduct,
                          setSelectedProduct,
                          getId,
                          null,
                          null,
                          null,
                          true
                        );
                      }
                    }}
                    className="productInfos dFlex mb0 cursorPointer lightBlue p0 fontSizeDefault"
                  >
                    {validationLength(product?.description, 30)}
                  </Typography>

                  <ProductInfosContainer>
                    <InfoBox onClick={() => handleClickSkus(product)}>
                      <span>
                        {Array.isArray(product?.skus)
                          ? product.skus.length
                          : product?.nSkus || 0
                        }
                      </span>
                    </InfoBox>

                    <InfoBox onClick={() => handleClickCards(product)}>
                      <span>
                        {Array.isArray(product?.items)
                          ? product?.items?.filter((item) => item.cardsOrigin)?.length
                          : product?.nCards || 0
                        }
                      </span>
                    </InfoBox>

                    <InfoBox onClick={() => handleClickProviders(product)}>
                      <span>
                        {Array.isArray(product?.skus)
                          ? countProviders(product)
                          : product?.nProviders || 0
                        }
                      </span>
                    </InfoBox>
                  </ProductInfosContainer>
                </div>
              ))}
            </MainContainer>
          </div>

          {!typeOfUnification?.disabled && (
            <div className="dFlex flexColumn mt20">
              <span className="bold mb5">Selecione o tipo de unificação:</span>

              <div className="dFlex">
                {typeOfUnification.options.map((option) => (
                  <div className="optionBox dFlex alignCenter">
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="type"
                        name="type"
                        value={typeOfUnification?.selected}
                        onChange={() => {
                          handleSelectTypeOfUnification(
                            option !== 'Restaurante'
                          );
                          setTypeOfUnification({
                            ...typeOfUnification,
                            selected: option
                          });
                        }}
                        style={{ flexDirection: 'row' }}
                      >
                        <FormControlLabel
                          value={option}
                          disabled={typeOfUnification?.disabled}
                          control={<Radio required size="small" />}
                          label={option}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                ))}
              </div>
            </div>
          )}

          <Typography
            className="dFlex flexColumn uppercase textAlignCenter mt20"
            style={{ color: '#F53D4C' }}
          >
            <span className="bold">Atenção!</span>

            <span>Esta ação alterará também a estrutura de catálogo dos</span>

            <span>restaurantes relacionados.</span>

            <span>Esta ação não poderá ser desfeita.</span>
          </Typography>

          <Tooltip
            placement="bottom"
            title={
              <div style={{ padding: '7px 7px' }}>
                {[
                  ...new Set(
                    unifyProductsPopover?.products
                      ?.map((product) =>
                        product?.skus
                          ?.map((sku) =>
                            sku?.skus?.restaurants?.map(
                              (restaurant) => restaurant?.description
                            )
                          )
                          ?.flat()
                      )
                      ?.flat()
                  )
                ].map((restaurant) => (
                  <p className="mb3">{restaurant}</p>
                ))}
              </div>
            }
          >
            <Typography className="dFlex flexColumn textAlignCenter mt20 bold">
              Restaurantes relacionados:{' '}
              {
                [
                  ...new Set(
                    unifyProductsPopover?.products
                      ?.map((product) =>
                        product?.skus
                          ?.map((sku) =>
                            sku?.skus?.restaurants?.map(
                              (restaurant) => restaurant?.description
                            )
                          )
                          ?.flat()
                      )
                      ?.flat()
                  )
                ].length
              }
            </Typography>
          </Tooltip>
        </DialogContent>

        <div className="dFlex pt12 pb12 pl16 pr16">
          <Grid item xs={6} className="dFlex justifyEnd mr20">
            <Button
              design="outlined"
              className="defaultButton backButton"
              onClick={() => {
                handleClosePopover();
              }}
              label="Cancelar"
            />
          </Grid>

          <Grid item xs={6} className="dFlex justifyStart">
            <Button
              className="defaultButton submitButton"
              disabled={
                !unifyProductsPopover?.refProduct ||
                !typeOfUnification?.selected ||
                isLoading
              }
              autoFocus
              design="contained"
              onClick={() => {
                handleUnifyProducts();
              }}
              label={isLoading ? 'Processando...' : 'Unificar'}
            />
          </Grid>
        </div>
      </Dialog>

      {modalSettings.find((modal) => modal.name === 'modalProduct').open &&
        productInfo?.data && (
          <RegisterProductModal
            dialogOpen={
              modalSettings.find((modal) => modal.name === 'modalProduct').open
            }
            isModal
            onlyView
            consumptionUnitOptions={consumptionUnits}
            periodicityOptions={periodicities}
            storageLocationOptions={storageLocations}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'modalProduct'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
          />
        )}

      {selectedCard && (
        <TechnicalSheetDialog
          maxWidth={null}
          open={
            modalSettings.find((modal) => modal.name === 'modalTechnicalSheet')
              .open
          }
          handleClose={() =>
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'modalTechnicalSheet'
                  ? { ...modal, open: false }
                  : modal
              )
            )
          }
          cardDataTable={cardDataTable}
          cardTransformation={cardTransformation}
          selectedCard={selectedCard}
          ucPrimarySelectedId={ucPrimarySelectedId}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UnifyProducts);
