const brandsLocalize = {
  title: 'Marcas Cadastradas',
  headTitle: 'Marcas',
  description: 'Descrição',
  producer: 'Fabricante',
  producer_plural: 'Fabricantes',
  brandRecord: 'Cadastrar Marca',
  brandRecord_plural: 'Cadastrar Marcas',
  registerBrand: 'Cadastro de Marca',
  registerBrand_plural: 'Cadastro de Marcas',
  deleteTitle: 'Deseja realmente excluir essa marca?',
  deleteMessageQuestion: 'Esta ação não pode ser desfeita. Deseja continuar?',
  deletedSuccess: 'Marca deletada com sucesso!',
  saveInfoError: 'Ocorreu um erro ao salvar as informações.',
  brands: 'Marcas',
  'already exists a brand with this description': 'já existe uma marca com esta descrição'
};

export default brandsLocalize;
