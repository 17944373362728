const INITIAL = {}

export default function skuInfo(state = INITIAL, action) {
    switch (action.type) {
        case "SET_SKUS_DATA":
            return (
                {
                    action: "EDIT",
                    data: action.data
                }
            )
        case "CREATE_SKUS_DATA":
            return (
                {
                    action: "CREATE",
                    data: {}
                }
            )
        default:
            return state;
    }
}