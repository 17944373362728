/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

import { queries } from './queries';

const useFetch = (
  endpoint,
  params,
  fetchControl,
  appendEndpoint,
  refreshFetch
) => {
  const [status, setStatus] = useState({
    loading: false,
    error: false,
    idle: true
  });
  const [data, setData] = useState([]);
  const flatParams = params && params.map((param) => param?.urlParam).join('&');
  const query = `${queries[endpoint]}${appendEndpoint || ''}?${flatParams}`;

  useEffect(() => {
    if (!query) return;
    if (fetchControl) return;
    const fetchData = async () => {
      try {
        setStatus({ ...status, loading: true, idle: false });
        const response = await fetch(query);
        const content = await response.json();
        setData(content);
        setStatus({ ...status, loading: false, idle: true });
      } catch ({ message }) {
        setStatus({ ...status, error: true });
        console.log('error ===>', message);
      }
    };

    fetchData();
  }, [query, flatParams, params, refreshFetch]);

  return { status, data, loading: status.loading };
};

export default useFetch;
