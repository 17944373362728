import styled from 'styled-components';
import { lightHouseColors } from '../utils';

export const SubCardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 9px 12px;
`;

export const PercenInfoConst = styled.span`
    color: ${({color}) => lightHouseColors[color]} !important;
    margin-top: 9px !important;
    display: block;
    font-weight: bold;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
`;

export const InfoDetailMessage = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: ${({color}) => lightHouseColors[color]};
`;

export const InfoDetailWrap = styled.div`
    text-align: start;
`;
