/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  Collapse,
  LinearProgress,
  List,
  ListItem,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import FullScreenLoading from 'components/FullScreenLoading';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import statusDay from 'components/StatusDaySchedule';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import days from 'data/days.json';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import { changeFilter, clearFilter } from 'store/reducers/cardsFilterSlice';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions
} from 'utils/handleFilterActions';
import { translateDays } from 'utils/translateDays';
import ValidationLength from 'utils/utils';

import * as S from './styles';

const environments = Environment(process.env.REACT_APP_ENV);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  input: {
    fontFamily: 'Lato',
    fontSize: 12
  },
  option: {
    fontFamily: 'Lato',
    fontSize: 12
  }
}));

const formattedDaysOptions = days.map((day) => ({
  ...day,
  id: day.id <= 6 ? day.id + 1 : day.id === 7 ? 1 : 0 
}));

function Schedule({ userInfo }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const queryParams = useLocation()?.search;
  const dayWeek = new Date().getDay();
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const [cardIdQueryParam, setCardIdQueryParam] = useState(
    queryParams?.includes('cardId')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('cardId'))
          ?.split('=')[1]
      : ''
  );

  const [cardDescriptionQueryParam, setCardDescriptionQueryParam] = useState(
    queryParams?.includes('cardDescription')
      ? decodeURI(
          queryParams
            ?.split('&')
            ?.find((el) => el.includes('cardDescription'))
            ?.split('=')[1]
        )
      : ''
  );

  const [currentDateFilterDefaultValue, setCurrentDateFilterDefaultValue] =
    useState(
      queryParams?.includes('currentDate')
        ? queryParams
            ?.split('&')
            ?.find((el) => el.includes('currentDate'))
            ?.split('=')[1]
        : ''
    );

  const DEFAULT_FILTERS = useMemo(
    () => [
      currentDateFilterDefaultValue && {
        param: 'currentDate',
        value: currentDateFilterDefaultValue,
        urlParam: `currentDate=${currentDateFilterDefaultValue}`
      },
      cardIdQueryParam && {
        param: 'cardId',
        value: cardIdQueryParam,
        urlParam: `cardId=${cardIdQueryParam}`
      }
    ],
    [currentDateFilterDefaultValue]
  );

  const storedCardsFilter = useSelector(
    (currState) => currState.cardsFilter.filters
  );

  const { id: paramsID, cardId } = useParams();
  const prevPath = history?.location?.state?.prevPath;

  const shouldUseStoredFilters = useMemo(
    () => storedCardsFilter && prevPath,
    [prevPath, storedCardsFilter]
  );

  const [showDeleteLoading, setShowDeleteLoading] = useState(false);
  const [scheduleList, setScheduleList] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState([]);
  const [editedItems, setEditedItems] = useState([]);
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusCode, setStatusCode] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 100,
    filters: shouldUseStoredFilters ? storedCardsFilter : DEFAULT_FILTERS,
    numberOfElements: 0,
    totalElements: 0
  });
  const { getId, page, filters, numberOfElements, totalElements } =
    callSettings;
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Ficha',
        filterName: 'cardDescription',
        filterNameSecond: 'cardId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/cards`,
        key: 'cards',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue:
          cardDescriptionQueryParam ||
          (shouldUseStoredFilters &&
            storedCardsFilter.find(
              (filter) => filter.filterName === 'cardDescription'
            )?.cardDescription)
      },
      {
        label: 'Tipo',
        filterName: 'type',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        page: 1,
        key: 'brands',
        allowClear: true,
        optionReference: 'description',
        isSelect: true,
        options: [
          { title: 'Receita', value: 'RECIPE' },
          { title: 'Transformação', value: 'TRANSFORMATION' }
        ],
        size: 'medium_plus',
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find((filter) => filter.filterName === 'type')
            ?.textValue
      },
      {
        label: 'Produto',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find(
            (filter) => filter.filterName === 'productDescription'
          )?.productDescription
      },
      {
        label: 'Praça',
        filterName: 'courtId',
        placeHolder: 'Selecione...',
        type: 'newTreeSelector',
        urlPath: `${environments.catalog}/courts`,
        key: 'courts',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find(
            (filter) => filter.filterName === 'courtId'
          )?.textValue
      },
      {
        label: 'Agenda definida',
        filterName: 'scheduleDescription',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'schedule',
        page: 1,
        allowClear: true,
        isSelect: true,
        optionReference: 'description',
        options: [
          { title: 'Todos', value: 'ALL' },
          { title: 'Sim', value: 'yes' },
          { title: 'Não', value: 'no' }
        ],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue: 'Todos'
      },
      {
        type: 'multipleCheckbox',
        filterName: 'currentDate',
        checkBoxInputs: [
          {
            name: 'Fichas de hoje',
            referenceName: 'currentDate',
            checked: currentDateFilterDefaultValue
          }
        ],
        searchByTermMode: true
      }
    ]
  ]);

  const getScheduleContent = useCallback(async () => {
    setLoading(true);
    let params = [];

    if (shouldUseStoredFilters) {
      const parsedFilters = storedCardsFilter
        .map((filter) => {
          const key = Object.keys(filter)[0];
          const isFalse = filter[key] === false;

          if (filter[key]) {
            return `${key}=${filter[key]}&`;
          }
          return isFalse ? `${key}=false` : null;
        })
        .join('');

      params = parsedFilters;
    } else {
      params = filtersChanged?.map((filter) => filter?.urlParam).join('&');
    }

    const config = {
      headers: {
        User: `${userLocal?.user}`
      }
    };

    const response = await axiosInstanceCatalog.get(
      `${environments.catalog}/cards/schedule?restaurantId=${paramsID}&${params}`,
      config
    );
    const cards = await response.data;

    setLoading(false);
    setScheduleList(cards?.content?.length 
      ? cards?.content?.map(el => ({
          ...el,
          daysWeeks: el?.daysWeeks?.map(day => ({
            ...day,
            description: translateDays(day?.description)
          }))
        }))
      : []
    );
    setCallSettings((prevState) => ({
      ...prevState,
      totalPages: cards.totalPages,
      numberOfElements: cards.numberOfElements,
      totalElements: cards.totalElements
    }));

    if (!cards.content && !cards.content?.length) return;
    setCollapseOpen(
      cards.content.map((card) => ({
        id: card.id,
        open: false
      }))
    );
  }, [filtersChanged, paramsID, shouldUseStoredFilters, storedCardsFilter]);

  useEffect(() => {
    getScheduleContent();
  }, [getId, page, filtersChanged, paramsID]);

  useEffect(() => {
    (async () => {
      if (cardIdQueryParam) {
        await axios
          .get(
            `${environments.catalog}/cards/${cardIdQueryParam}?originId=${getId}`
          )
          .then((response) => {
            const items = response.data;
            const newFilters = [...filterFields];

            newFilters[0][0].storedValue = items?.description;

            setFilterFields(newFilters);
          })
          .catch((error) => {
            setAlertErr(true);
            setAlertMessage(error.response.data.message);
            setStatusCode(error.response.status);
          });
      }
    })();
  }, [getId]);

  const onClearFilters = () => {
    dispatch(clearFilter());

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((filter) => ({
          ...filter,
          storedValue: ''
        }));
      }

      return filterLine;
    });

    setFiltersChanged(DEFAULT_FILTERS);
    setFilterFields(FILTER_FIELDS);
  };

  const clearFilterValues = (param) => {
    setCurrentDateFilterDefaultValue(
      param === 'currentDate' ? '' : currentDateFilterDefaultValue
    );

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((item) => {
          if (item?.filterName === param) {
            return {
              ...item,
              storedValue: ''
            };
          }

          return item;
        });
      }

      return filterLine;
    });

    setFilterFields(FILTER_FIELDS);
  };

  const handleFilter = useCallback(
    (param, value, filter, paramSecond) => {
      const hasFilterWithFirstName = filtersChanged?.filter(
        (el) => el?.param === param
      )?.length;
      const hasFilterWithSecondName = filtersChanged?.filter(
        (el) => el?.param === paramSecond
      )?.length;

      let formattedFilters = hasFilterWithSecondName
        ? filtersChanged?.filter((el) => el?.param !== paramSecond)
        : filtersChanged;

      if (!value) {
        clearFilterValues(param);

        if (hasFilterWithFirstName || hasFilterWithSecondName) {
          formattedFilters = filtersChanged?.filter(
            (el) => el?.param !== paramSecond && el?.param !== param
          );
        }
      }

      dispatch(
        changeFilter({
          param,
          value,
          textValue: filter?.title
        })
      );

      handleFilterOptions(param, value, formattedFilters, setFiltersChanged);
    },
    [dispatch, filtersChanged, callSettings]
  );

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      paramsID,
      filtersChanged,
      page
    );
  };

  const dayProduction = (item, dayNumber, weekDay) => {
    if (dayWeek === dayNumber && item?.manufactures?.length > 0) {
      return item?.manufactures?.map((manufacture) =>
        statusDay(manufacture?.status)
      );
    }
    if (item?.daysWeeks?.find((day) => day?.description === weekDay)) {
      return statusDay('DEFAULT');
    }
    return '';
  };

  const handleSaveSchedule = () => {
    axios
      .put(
        `${environments.catalog}/cards/schedule`,
        editedItems
      )
      .then((response) => {
        setMessage({
          description: 'Agenda atualizada com sucesso.',
          status: 'success'
        });
      })
      .catch((error) => {
        setMessage({
          description: 'Erro ao atualizar agenda.',
          status: 'error'
        });
        setStatusCode(error?.response?.status);
      });
  }

  return (
    <Layout>
      <GlobalStyle />

      <TitleHead title="Agenda" />
      <Container>
        <PageTitleAndFilter
          title="Agenda"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          getFilterhandle={getFilterhandle}
          onClear={() => onClearFilters()}
          classOnClear="mt20 ml16"
          dynamicJustifyOff
        />

        <FullScreenLoading
          description="Excluindo"
          display={showDeleteLoading}
        />

        <table
          style={{ marginBottom: 60 }}
          className="desktop cardsMainTable"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <tr className="headerTitle">
            <S.Th width="3%" className="subtitle p0" />
            <S.Th width="20%" className="subtitle">
              FICHA{' '}
            </S.Th>
            <S.Th width="5%" widthMobile="7%" className="subtitle center">
              QTDE{' '}
            </S.Th>
            <S.Th width="3%" className="subtitle center">
              UN
            </S.Th>
            <S.Th width="7%" widthMobile="4%" className="subtitle center">
              TIPO{' '}
            </S.Th>
            <S.Th
              width="7%"
              widthMobile="5%"
              className="subtitle center alignCenter "
              style={{
                borderLeft: 'solid 2px #666770',
                padding: '0 2px'
              }}
            >
              <div
                className="dFlex center justifyCenter alignCenter"
                style={{
                  height: '20px',
                  background: `${dayWeek === 1 ? '#5062F0' : 'none'}`,
                  borderRadius: '20px'
                }}
              >
                <S.Paragraph>SEGUNDA</S.Paragraph>
                <S.ParagraphMobile>SEG</S.ParagraphMobile>
              </div>
            </S.Th>
            <S.Th
              width="7%"
              widthMobile="5%"
              className="subtitle center "
              style={{
                borderLeft: 'solid 2px #666770',
                padding: '0 2px'
              }}
            >
              <div
                className="dFlex center justifyCenter alignCenter"
                style={{
                  height: '20px',
                  background: `${dayWeek === 2 ? '#5062F0' : 'none'}`,
                  borderRadius: '20px'
                }}
              >
                <S.Paragraph>TERÇA</S.Paragraph>
                <S.ParagraphMobile>TER</S.ParagraphMobile>
              </div>
            </S.Th>
            <S.Th
              width="7%"
              widthMobile="5%"
              className="subtitle center "
              style={{
                borderLeft: 'solid 2px #666770',
                padding: '0 2px'
              }}
            >
              <div
                className="dFlex center justifyCenter alignCenter"
                style={{
                  height: '20px',
                  background: `${dayWeek === 3 ? '#5062F0' : 'none'}`,
                  borderRadius: '20px'
                }}
              >
                <S.Paragraph>QUARTA</S.Paragraph>
                <S.ParagraphMobile>QUA</S.ParagraphMobile>
              </div>
            </S.Th>
            <S.Th
              width="7%"
              widthMobile="5%"
              className="subtitle center "
              style={{
                borderLeft: 'solid 2px #666770',
                padding: '0 2px'
              }}
            >
              <div
                className="dFlex center justifyCenter alignCenter"
                style={{
                  height: '20px',
                  background: `${dayWeek === 4 ? '#5062F0' : 'none'}`,
                  borderRadius: '20px'
                }}
              >
                <S.Paragraph>QUINTA</S.Paragraph>
                <S.ParagraphMobile>QUI</S.ParagraphMobile>
              </div>
            </S.Th>
            <S.Th
              width="7%"
              widthMobile="5%"
              className="subtitle center "
              style={{
                borderLeft: 'solid 2px #666770',
                padding: '0 2px'
              }}
            >
              <div
                className="dFlex center justifyCenter alignCenter"
                style={{
                  height: '20px',
                  background: `${dayWeek === 5 ? '#5062F0' : 'none'}`,
                  borderRadius: '20px'
                }}
              >
                <S.Paragraph>SEXTA</S.Paragraph>
                <S.ParagraphMobile>SEX</S.ParagraphMobile>
              </div>
            </S.Th>
            <S.Th
              width="7%"
              widthMobile="5%"
              className="subtitle center "
              style={{
                borderLeft: 'solid 2px #666770',
                padding: '0 2px'
              }}
            >
              <div
                className="dFlex center justifyCenter alignCenter"
                style={{
                  height: '20px',
                  background: `${dayWeek === 6 ? '#5062F0' : 'none'}`,
                  borderRadius: '20px'
                }}
              >
                <S.Paragraph>SÁBADO</S.Paragraph>
                <S.ParagraphMobile>SAB</S.ParagraphMobile>
              </div>
            </S.Th>
            <S.Th
              width="7%"
              widthMobile="5%"
              className="subtitle center "
              style={{
                borderLeft: 'solid 2px #666770',
                padding: '0 2px'
              }}
            >
              <div
                className="dFlex center justifyCenter alignCenter"
                style={{
                  height: '20px',
                  background: `${dayWeek === 7 ? '#5062F0' : 'none'}`,
                  borderRadius: '20px'
                }}
              >
                <S.Paragraph>DOMINGO</S.Paragraph>
                <S.ParagraphMobile>DOM</S.ParagraphMobile>
              </div>
            </S.Th>
          </tr>

          {loading && (
            <tr>
              <th colSpan="17" style={{ margin: '10px', textAlign: 'center' }}>
                <LinearProgress id="loading" variant="query" />
              </th>
            </tr>
          )}

          {scheduleList.length ? (
            scheduleList.map((item, itemIndex) => {
              const exists = collapseOpen.find((x) => x.id === item.id);

              if (collapseOpen.length < scheduleList.length && !exists) {
                collapseOpen.push({ id: item.id, open: false });
              }

              const typeProduct = (value) => {
                if (value === 'RECIPE') {
                  return (
                    <>
                      <S.Paragraph>Receita</S.Paragraph>
                      <S.ParagraphMobile>R</S.ParagraphMobile>
                    </>
                  );
                }
                if (value === 'TRANSFORMATION') {
                  return (
                    <>
                      <S.Paragraph>Transformação</S.Paragraph>
                      <S.ParagraphMobile>T</S.ParagraphMobile>
                    </>
                  );
                }
                return '';
              };

              return (
                <tr>
                  <td
                    className="description listTableContainer"
                    colSpan="12"
                    style={{
                      borderBottom: `${
                        !collapseOpen.find((x) => x.id === item.id)?.open &&
                        '1px solid #666770'
                      }`
                    }}
                  >
                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      className={classes.root}
                      style={{ display: 'inline' }}
                    >
                      <ListItem style={{ padding: '0' }}>
                        <table
                          width="100%"
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          className="heightTableMobile"
                        >
                          <tr
                            style={{
                              borderBottom: `${
                                collapseOpen.find((x) => x.id === item.id)?.open
                                  ? '1px solid #666770'
                                  : 'none'
                              }`
                            }}
                          >
                            <S.Td
                              width="3%"
                              className="description bold center cursorPointer"
                              onClick={() =>
                                setCollapseOpen(
                                  collapseOpen.map((x) =>
                                    x.id === item.id
                                      ? { ...x, open: !x.open }
                                      : x
                                  )
                                )
                              }
                            >
                              <div className="h100">
                                {collapseOpen.find((x) => x.id === item.id) ? (
                                  collapseOpen.find((x) => x.id === item.id)
                                    .open ? (
                                    <ExpandLess style={{ width: '20px' }} />
                                  ) : (
                                    <ExpandMore style={{ width: '20px' }} />
                                  )
                                ) : (
                                  false
                                )}
                              </div>
                            </S.Td>
                            <S.Td width="20%" className="description">
                              <div className="h100">
                                <Link
                                  target="_blank"
                                  to={{
                                    pathname: `/catalogo/fichas/${paramsID}/${item.id}`,
                                    state: { cardId: item.id }
                                  }}
                                >
                                  <S.Paragraph>
                                    {ValidationLength(item?.description, 30)}
                                  </S.Paragraph>
                                  <S.ParagraphMobile>
                                    {ValidationLength(item?.description, 50)}
                                  </S.ParagraphMobile>
                                </Link>
                              </div>
                            </S.Td>
                            <S.Td
                              width="5%"
                              widthMobile="7%"
                              className="description center"
                            >
                              <div className="h100">
                                {item?.productions?.yieldTotal.toLocaleString(
                                  'pt-br',
                                  { minimumFractionDigits: 3 }
                                )}
                              </div>
                            </S.Td>
                            <S.Td width="3%" className="description center">
                              <div className="h100">
                                {item?.consumptionUnits?.abbreviation}
                              </div>
                            </S.Td>
                            <S.Td
                              width="7%"
                              widthMobile="4%"
                              className="description center"
                            >
                              <div className="h100">
                                {typeProduct(item.type)}
                              </div>
                            </S.Td>
                            <S.TdBorder
                              width="7%"
                              widthMobile="5%"
                              className="description center"
                              style={{
                                gap: '5px'
                              }}
                            >
                              {dayProduction(item, 1, 'Segunda')}
                            </S.TdBorder>
                            <S.TdBorder
                              width="7%"
                              widthMobile="5%"
                              className="description center"
                              style={{
                                gap: '5px'
                              }}
                            >
                              {dayProduction(item, 2, 'Terça')}
                            </S.TdBorder>
                            <S.TdBorder
                              width="7%"
                              widthMobile="5%"
                              className="description center"
                              style={{
                                gap: '5px'
                              }}
                            >
                              {dayProduction(item, 3, 'Quarta')}
                            </S.TdBorder>
                            <S.TdBorder
                              width="7%"
                              widthMobile="5%"
                              className="description center"
                              style={{
                                gap: '5px'
                              }}
                            >
                              {dayProduction(item, 4, 'Quinta')}
                            </S.TdBorder>
                            <S.TdBorder
                              width="7%"
                              widthMobile="5%"
                              className="description center"
                              style={{
                                gap: '5px'
                              }}
                            >
                              {dayProduction(item, 5, 'Sexta')}
                            </S.TdBorder>
                            <S.TdBorder
                              width="7%"
                              widthMobile="5%"
                              className="description center"
                              style={{
                                gap: '5px'
                              }}
                            >
                              {dayProduction(item, 6, 'Sábado')}
                            </S.TdBorder>
                            <S.TdBorder
                              width="7%"
                              widthMobile="5%"
                              className="description center"
                              style={{
                                gap: '5px'
                              }}
                            >
                              {dayProduction(item, 7, 'Domingo')}
                            </S.TdBorder>
                          </tr>
                        </table>
                      </ListItem>

                      <Collapse
                        in={
                          collapseOpen.find((x) => x.id === item.id)
                            ? collapseOpen.find((x) => x.id === item.id).open
                            : false
                        }
                        timeout={0}
                      >
                        <List component="div" disablePadding>
                          <ListItem
                            className={classes.nested}
                            style={{ padding: '0', background: '#F2F3F8' }}
                          >
                            <table
                              width="100%"
                              cellPadding="0"
                              cellSpacing="0"
                              border="0"
                              colSpan="12"
                            >
                              {item && item?.items ? (
                                <>
                                  {item.items.sort((a, b) => a.id - b.id).map((card) => (
                                    <tr>
                                      <S.Td width="3%" />
                                      <S.Td
                                        width="20%"
                                        className="description center"
                                        style={{
                                          borderBottom: 'solid 1px #9296AC'
                                        }}
                                      >
                                        {card.products
                                          ? ValidationLength(
                                              card.products.description,
                                              100
                                            )
                                          : '-'}
                                      </S.Td>
                                      <S.Td
                                        width="5%"
                                        widthMobile="7%"
                                        className="description center p7"
                                        style={{
                                          borderBottom: 'solid 1px #9296AC'
                                        }}
                                      >
                                        {card?.quantity
                                          ? card?.quantity.toLocaleString(
                                              'pt-br',
                                              { minimumFractionDigits: 3 }
                                            )
                                          : '-'}
                                      </S.Td>
                                      <S.Td
                                        width="3%"
                                        className="description center p6"
                                        style={{
                                          borderBottom: 'solid 1px #9296AC'
                                        }}
                                      >
                                        {
                                          card?.products
                                            ?.consumptionUnitsPrimary
                                            ?.abbreviation
                                        }
                                      </S.Td>
                                      <S.Td
                                        width="7%"
                                        widthMobile="4%"
                                        className="description bold "
                                      />
                                      <S.TdBorder
                                        width="7%"
                                        widthMobile="5%"
                                        className="description bold "
                                      />
                                      <S.TdBorder
                                        width="7%"
                                        widthMobile="5%"
                                        className="description bold "
                                      />
                                      <S.TdBorder
                                        width="7%"
                                        widthMobile="5%"
                                        className="description bold "
                                      />
                                      <S.TdBorder
                                        width="7%"
                                        widthMobile="5%"
                                        className="description bold "
                                      />
                                      <S.TdBorder
                                        width="7%"
                                        widthMobile="5%"
                                        className="description bold "
                                      />
                                      <S.TdBorder
                                        width="7%"
                                        widthMobile="5%"
                                        className="description bold "
                                      />
                                      <S.TdBorder
                                        width="7%"
                                        widthMobile="5%"
                                        className="description bold "
                                      />
                                    </tr>
                                  ))}

                                  <tr>
                                    <S.Td width="3%" />

                                    <S.Td
                                      width="20%"
                                      className="description bold"
                                      style={{ color: '#990000' }}
                                    >
                                      Perda:{' '}
                                      {item?.productions
                                        ? item?.productions?.loss?.toLocaleString(
                                            'pt-br',
                                            { minimumFractionDigits: 3 }
                                          )
                                        : '-'}
                                      %
                                    </S.Td>

                                    <S.Td
                                      width="5%"
                                      widthMobile="7%"
                                      className="description bold p6"
                                    />

                                    <S.Td
                                      width="3%"
                                      className="description bold p6"
                                    />

                                    <S.Td
                                      width="7%"
                                      widthMobile="4%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />
                                  </tr>

                                  <tr>
                                    <S.Td width="3%" />

                                    <S.Td
                                      colSpan={3}
                                      className="description bold"
                                      style={{ color: '#990000' }}
                                    >
                                      <Autocomplete
                                        id="controllable-states-demo"
                                        getOptionSelected={(option, value) => option.id === value.daysWeekId}
                                        getOptionLabel={(option) => option.description}
                                        options={formattedDaysOptions}
                                        multiple
                                        limitTags={4}
                                        size="small"
                                        value={item?.daysWeeks}
                                        className="autocompleteSmall commonInput personalInputDaily"
                                        onChange={(_, value) => {
                                          let formattedObj = []

                                          if (value.find((el) => el.description === 'Todos')) {
                                            formattedObj = {
                                              cardId: item?.id,
                                              daysWeek: formattedDaysOptions.filter(
                                                (el) => el.description !== 'Todos'
                                              )
                                            }
                                          } else {
                                            formattedObj = {
                                              cardId: item?.id,
                                              daysWeek: value
                                            }
                                          }

                                          setEditedItems(
                                            editedItems?.find(el => el?.cardId === item?.id)
                                            ?
                                              editedItems?.map((el) => {
                                                if (item?.id === el?.cardId) {
                                                  return {
                                                    ...el,
                                                    daysWeek: formattedObj?.daysWeek
                                                  }
                                                }

                                                return { ...el }
                                              })
                                            : [...editedItems, formattedObj]
                                          );
                                          setScheduleList(
                                            scheduleList?.map((el, i) => {
                                              if (itemIndex === i) {
                                                return {
                                                  ...el,
                                                  daysWeeks: formattedObj?.daysWeek
                                                }
                                              }

                                              return { ...el }
                                            })
                                          )
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            id={params.id}
                                            name="providers"
                                            label="Dias da semana"
                                            variant="outlined"
                                            placeholder="Selecione..."
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                          />
                                        )}
                                        renderOption={(params) => (
                                          <Typography
                                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                                          >
                                            {params?.description}
                                          </Typography>
                                        )}
                                      />
                                    </S.Td>

                                    <S.Td
                                      width="7%"
                                      widthMobile="4%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />

                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />
                                    
                                    <S.TdBorder
                                      width="7%"
                                      widthMobile="5%"
                                      className="description bold p7"
                                    />
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td className="description center">
                                    <Typography
                                      style={{
                                        margin: '5px',
                                        textAlign: 'center'
                                      }}
                                    >
                                      Não há itens.
                                    </Typography>
                                  </td>
                                </tr>
                              )}
                            </table>
                          </ListItem>
                        </List>
                      </Collapse>
                    </List>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <th colSpan="13" style={{ margin: '10px', textAlign: 'center' }}>
                Não há nenhuma agenda
              </th>
            </tr>
          )}
        </table>

        <section className="dFlex justifyEnd pFixed" style={{ bottom: 10, right: 10 }}>
          <Button
            className="defaultButton submitButton"
            disabled={!editedItems?.length}
            design="outlined"
            label="Salvar"
            onClick={() => {
              handleSaveSchedule()
            }}
            style={{ outline: 0 }}
          />
        </section>
      </Container>

      <CommonAlert
        open={alertErr}
        onClose={() => {
          setAlertErr(false);
        }}
        indexMessage={alertMessage}
        statusCode={statusCode}
      />

      <CommonAlert
        open={!!message.description}
        onClose={() => setMessage({ description: '', status: '' })}
        severity={message.status}
        indexMessage={message.description}
        messagePersonal={message.description}
        statusCode={statusCode}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(Schedule);
