const getFilterOptions = (
  endpoint = '',
  label,
  key,
  hook,
  setHook,
  optionReference,
  originId,
  filters,
  page,
  filterParamsOff,
  profileType,
  size = 1500
) => {
  (async () => {
    let params = '';
    let response;

    const satinitisePage = page || 1;

    if (filters !== undefined && filters) {
      if (Array.isArray(filters)) {
        filters.forEach(
          (filter) => (params += `&${filter?.param}=${filter?.value}`)
        );
      } else {
        const keys = Object.keys(filters);
        const values = Object.values(filters);
        keys.map((key, index) => (params += `&${key}=${values[index]}`));
      }
    }
    const pageNumber =
      key === 'stores' ? Number(!(key === 'stores')) : satinitisePage;

    if (filterParamsOff) {
      if (key === 'productsWSkus' || key === 'productsWSkusAndProductIds') {
        response = await fetch(
          `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true&getSku=true&originId=${originId}`
        ).catch((err) => {
          console.log(err);
        });
      } else if (key === 'skusWProducts') {
        response = await fetch(
          `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true&originId=${originId}&getProduct=true`
        ).catch((err) => {
          console.log(err);
        });
      } else if (key === 'providersSku') {
        response = await fetch(
          `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true&originId=${
            profileType !== 'admin' ? originId : ''
          }&isProvider=true`
        ).catch((err) => {
          console.log(err);
        });
      } else if (key === 'skusDescriptionProviderReceivement') {
        response = await fetch(
          `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true&originId=${originId}&isReceivement=true`
        ).catch((err) => {
          console.log(err);
        });
      } else if (key === 'providersQuotationAdmin') {
        response = await fetch(
          `${endpoint}?page=${pageNumber}&size=${size}&originId=${originId}`
        ).catch((err) => {
          console.log(err);
        });
      } else if (key === 'ordersQuotation') {
        response = await fetch(
          `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true&originsId=${
            profileType !== 'admin' ? originId : ''
          }${params}`
        ).catch((err) => {
          console.log(err);
        });
      } else {
        response = await fetch(
          `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true&originId=${
            profileType !== 'admin' ? originId : ''
          }`
        ).catch((err) => {
          console.log(err);
        });
      }
    } else if (key === 'skusComparation') {
      response = await fetch(
        `${endpoint}?page=${pageNumber}&size=${size}&${params}`
      ).catch((err) => {
        console.log(err);
      });
    } else if (key === 'skusProvidersCatalog') {
      response = await fetch(`${endpoint}`).catch((err) => {
        console.log(err);
      });
    } else if (key === 'ordersQuotation') {
      response = await fetch(
        `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true&originsId=${
          profileType !== 'admin' ? originId : ''
        }${params}`
      ).catch((err) => {
        console.log(err);
      });
    } else if (endpoint.includes('/skus/platform')) {
      response = await fetch(
        `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true${params}`
      ).catch((err) => {
        console.log(err);
      });
    } else {
      response = await fetch(
        `${endpoint}?page=${pageNumber}&size=${size}&autoComplete=true&originId=${
          profileType !== 'admin' ? originId : ''
        }${params}`
      ).catch((err) => {
        console.log(err);
      });
    }
    if (!response?.json) return;
    const item = await response.json();

    if (!item) return;

    const content = item.content ? item.content : item;

    if (!content.length) return;

    const newFilterOptions = content.map((i) => {
      const { providerId, id } = i;

      if (
        key === 'productsStock' ||
        key === 'providersDescription' ||
        key === 'skusDescription' ||
        key === 'cardsDescription' ||
        key === 'brandsDescription'
      ) {
        return {
          title: i[optionReference],
          value: i[optionReference],
          content: i
        };
      }

      if (key === 'providerGroups') {
        return {
          title: i[optionReference],
          value: content
            .filter((item) => item.description === i[optionReference])[0]
            .providers.map((provider) => provider._id)
        };
      }

      if (key === 'providers') {
        return { title: i[optionReference], value: providerId, content: i };
      }

      if (key === 'ordersQuotation') {
        return {
          title: i[optionReference],
          value: i[optionReference],
          content: i
        };
      }

      if (key === 'productsWSkus') {
        const skus = [];
        i.skus.forEach((item) => skus.push(item.skuId));
        return { title: i[optionReference], value: skus, content: i };
      }

      if (key === 'productsWSkusAndProductIds') {
        const skus = [];
        i.skus.forEach((item) => skus.push(item.skuId));

        return {
          title: i[optionReference],
          value: skus,
          productId: i.id,
          content: i
        };
      }

      if (key === 'skusWProducts') {
        const products = [];
        i.products.forEach((item) => products.push(item.productId));

        return {
          title: i[optionReference],
          value: products,
          skuId: i.id,
          content: i
        };
      }

      if (key === 'providersSku') {
        return { title: i[optionReference], value: i.providers, content: i };
      }

      if (key === 'skusDescriptionProvider') {
        return { title: i[optionReference], value: i.skuId, content: i };
      }

      if (key === 'stores') {
        return { title: i[optionReference], value: i._id, content: i };
      }

      if (key === 'groups') {
        return {
          title: i[optionReference],
          value: i.restaurantIds,
          content: i
        };
      }

      if (key === 'providerDescription') {
        return { title: i[optionReference], value: i.providerName, content: i };
      }

      if (key === 'providersIdList') {
        return { title: i[optionReference], value: i.providersId, content: i };
      }

      if (
        key === 'producers' ||
        key === 'brands' ||
        key === 'cards' ||
        key === 'periods' ||
        key === 'periodicities' ||
        key === 'storageLocations' ||
        key === 'products' ||
        key === 'skus' ||
        key === 'skusComparation'
      ) {
        return { title: i[optionReference], value: id, content: i };
      }

      return {
        title: i[optionReference],
        value: i[optionReference],
        content: i
      };
    });

    const newHook = hook.map((firstLevel) =>
      firstLevel.map((secondLevel) => {
        if (secondLevel.label === label) {
          secondLevel.options = newFilterOptions;
          return secondLevel;
        }
        return secondLevel;
      })
    );

    setHook(newHook);
  })();
};

export default getFilterOptions;
