import { useSelector } from 'react-redux';

export const useUserGroups = () => {
  const user = useSelector((currState) => currState.user);
  const companies = user.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat();

  const companiesIds = companies.map((companie) => parseInt(companie.id, 10));

  return { companies, companiesIds };
};
