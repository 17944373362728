import React, { useState, useEffect, useCallback } from 'react';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';

import Button from 'components/Button/button';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';
import './styles.scss';

function DialogTags({
  open,
  PaperComponent,
  dialogTagsSettings,
  setDialogTagsSettings,
  originId,
  setProducts,
  products
}) {
  const [tags, setTags] = useState([]);
  const [message, setMessage] = useState('');
  const environments = Environment(process.env.REACT_APP_ENV);
  const description = dialogTagsSettings.productName;
  const { productId } = dialogTagsSettings;
  const [callSettings, setCallSettings] = useState({
    getId: originId,
    mainLoading: false,
    page: 1,
    filters: []
  });
  const { getId, mainLoading } = callSettings;

  const getProductTags = useCallback(() => {
    (async () => {
      setCallSettings({ ...callSettings, mainLoading: true });
      const response = await fetch(
        `${environments.catalog}/products/${productId}/tags?originId=${getId}`
      );
      const tags = await response.json();
      setTags(tags);
      setCallSettings({ ...callSettings, mainLoading: false });
    })();
  }, [getId]);

  useEffect(() => {
    getProductTags();
  }, [getId]);

  const handleClose = () => {
    const someComparable = tags.map((tag) => tag.comparable).flat();
    setMessage({ ...message, description: '', status: '' });
    setDialogTagsSettings({ ...dialogTagsSettings, open: false });
    setProducts(
      products.map((product) => {
        if (productId === product.id) {
          return {
            ...product,
            tagComparable: someComparable.some((item) => item)
          };
        }
        return product;
      })
    );
  };

  const handleChange = (checked, tagId) => {
    setTags(
      tags.map((tag) => {
        if (tagId === tag.id) {
          return {
            ...tag,
            comparable: !checked
          };
        }
        return tag;
      })
    );
  };

  const handleSubmit = () => {
    setCallSettings({ ...callSettings, mainLoading: true });

    axios
      .put(`${environments.catalog}/products/${productId}/tags`, tags)
      .then((response) => {
        setCallSettings({ ...callSettings, mainLoading: false });
        setMessage({
          description: 'Tags atualizadas com sucesso.',
          status: 'success'
        });
      })
      .catch((error) => {
        setCallSettings({ ...callSettings, mainLoading: false });
        setMessage({
          description: 'Não foi possível atualizar os itens.',
          status: 'error'
        });
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() =>
          setDialogTagsSettings({ ...dialogTagsSettings, open: false })
        }
        className="defaultModal productTagsManagerModal mediumModal minWidth"
        PaperComponent={PaperComponent}
        maxWidth="lg"
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
          fullWidth
        >
          <Typography>{description} TAGS</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setDialogTagsSettings({ ...dialogTagsSettings, open: false });
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent onlyTable">
          <table className="desktop" cellPadding="0" cellSpacing="0" border="0">
            <thead>
              <tr className="headerTitle">
                <th width="70%" className="subtitle">
                  TAGS
                </th>
                <th width="30%" className="subtitle right p10">
                  VALORES
                </th>
              </tr>
            </thead>

            <tbody>
              {tags.length ? (
                tags.map((tag) => (
                  <>
                    <tr>
                      <td className="description left p12">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => {
                                handleChange(tag.comparable, tag.id);
                              }}
                              name="checkedTag"
                              color="primary"
                              checked={tag.comparable}
                            />
                          }
                          label={ValidationLength(tag.description, 30)}
                        />
                      </td>

                      <td className="description right p10">
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={
                            tag.values.length ? (
                              tag.values.map((tagValue, index) => (
                                <p
                                  style={{
                                    marginBottom:
                                      tag.values.length - 1 === index
                                        ? '0'
                                        : '5px'
                                  }}
                                >
                                  {tagValue.description}
                                </p>
                              ))
                            ) : (
                              <p className="mb0">Nenhum registro</p>
                            )
                          }
                        >
                          <span style={{ cursor: 'default' }}>
                            {tag.values.length}
                          </span>
                        </Tooltip>
                      </td>
                    </tr>

                    <tr>
                      <td className="p0" colSpan="2">
                        <hr />
                      </td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td
                    className="description center"
                    style={{ fontSize: '14px' }}
                    colSpan="2"
                  >
                    Nenhum registro
                  </td>
                </tr>
              )}

              {mainLoading && (
                <tr>
                  <th colSpan="2" sytle={{ margin: '10px' }}>
                    <LinearProgress variant="query" />
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </DialogContent>

        {tags.length ? (
          <DialogActions className="justifyEnd">
            <Button
              className="defaultButton submitButton"
              design="contained"
              label={!mainLoading ? 'Salvar' : 'Salvando...'}
              style={{ outline: 0, margin: '0 0 0 5px' }}
              onClick={() => handleSubmit()}
            />
          </DialogActions>
        ) : null}
      </Dialog>

      <Snackbar
        open={!!message.description}
        autoHideDuration={1500}
        onClose={() => handleClose()}
      >
        <Alert onClose={() => handleClose()} severity={message.status}>
          {message.description}
        </Alert>
      </Snackbar>
    </>
  );
}

export default DialogTags;
