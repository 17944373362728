import styled from 'styled-components';
import {lightHouseColors} from '../utils';

export const ImgIcon = styled.img`
    /* padding: 4px; */
`;

export const CardListContainer = styled.div`
    display: grid;
    grid-template-columns:${({gridCollumns}) => gridCollumns ? gridCollumns : '1fr 3fr 2fr 2fr 1fr'};
    font-family: 'Lato';
    font-size: 12px;
    padding: 9px 12px;
    max-height: 33vh;
    overflow: auto;
`;

export const ListItem = styled.span`
    margin-top: ${({marginTop}) => marginTop ? marginTop : '6px'};
    margin-bottom: ${({marginBottom}) => marginBottom && marginBottom};
    margin-right: ${({marginRight}) => marginRight};
    font-weight: ${({bold}) => bold ? 'bold' : 'normal'};
    color: ${({color}) => lightHouseColors[color]};
    text-align: ${({textAlign}) => textAlign};
    width: ${({width}) => width};
    ${({size}) => size && `width: 30px; height: 24px;`}
    text-shadow: ${({textShadow}) => textShadow && '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'};
`;
