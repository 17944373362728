const returnZeros = (fractionDigits) => {
  const zero = '0';
  return fractionDigits > 0 ? `0,${zero.repeat(fractionDigits)}` : '0';
};

function numberToReal(number, fractionDigits = 3) {
  if (!number) {
    return returnZeros(fractionDigits);
  }

  const result = number.toLocaleString('pt-br', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  });
  return result === '-0,00' ? '0,00' : result;
}

export default numberToReal;
