/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { LinearProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

import GroupedVerticalBarGraphic from '../graphics/GroupedVerticalBarGraphic';
import SimpleLineGraphic from '../graphics/SimpleLineGraphic';
import SimpleVerticalBarGraphic from '../graphics/SimpleVerticalBarGraphic';

function CountInfosArea({ 
  userInfo, 
  countPercentConclusion,
  countDiff,
  countClosure 
}) {
  const { id: paramsID } = useParams();

  return (
    <section className="homeCountInfosContainer homeDefaultContainer">
      <div className="homeDefaultInfoTitle">
        <p>
          CONTAGEM
        </p>
      </div>

      <div className="graphicContainer">
        <div className="graphicBox">
          <div className="graphicItem">
            <span className="graphicTitle">
              % conclusão
            </span>

            {countPercentConclusion?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <SimpleVerticalBarGraphic 
                mainContent={countPercentConclusion} 
              />
            }
          </div>

          <div className="graphicItem diffGraphic">
            <span className="graphicTitle">
              diferenças
            </span>

            {countDiff?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <GroupedVerticalBarGraphic 
                mainContent={countDiff} 
              />
            }
          </div>

          <div className="graphicItem">
            <span className="graphicTitle">
              fechamento
            </span>

            {countClosure?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <SimpleLineGraphic 
                mainContent={countClosure}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CountInfosArea);
