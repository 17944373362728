/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Axios from 'axios';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import PaperComponent from 'components/PaperComponent';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import * as ProductsActions from 'store/actions/products';
import Environment from 'utils/environments';
import numberToReal from 'utils/numberToReal';

const environments = Environment(process.env.REACT_APP_ENV);

function ProcessNonIntegratedSales({
  userInfo,
  open,
  fullScreen,
  maxWidth,
  onClose,
  restaurantId
}) {
  const [date, setDate] = useState(new Date());
  const [logsList, setLogsList] = useState([]);
  const [salesInfos, setSalesInfos] = useState();
  const [loading, setLoading] = useState({
    logsList: false,
    process: false
  })
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const renderLogMessage = (log) => {
    const dateFormatted = `${moment(log?.saleDate).format(
      'DD/MM'
    )}` 

    const requestDateFormatted = `(${moment(log?.requestDate).format('DD/MM')} ${moment(log?.requestDate).format('LT')}
    ${log?.user ? `- ${log?.user}` : ''})`

    let mainObj = {
      status: log?.reprocess?.status,
      formattedMessage: '',
      color: ''
    }

    if (mainObj?.status === "SUCCESS") {
      mainObj = {
        ...mainObj,
        formattedMessage: `Dia ${dateFormatted}: 
          R$ ${numberToReal(
            log?.totalIntegratedAmount || 0,
            2
          )} 
        de vendas integradas ${requestDateFormatted}`,
        color: '#0B1A8E'
      }
    }

    if (mainObj?.status === "IN PROGRESS") {
      mainObj = {
        ...mainObj,
        formattedMessage: `Dia ${dateFormatted}: integrando 
          R$ ${numberToReal(
            log?.totalReturnedAmount || 0,
            2
          )}, 
        aguarde... ${requestDateFormatted}`,
        color: '#1F2445'
      }
    }

    if (mainObj?.status === "FAIL") {
      mainObj = {
        ...mainObj,
        formattedMessage: `Erro ao processar dia ${dateFormatted} ${requestDateFormatted}`,
        color: '#E22121'
      }
    }

    return mainObj
  }

  const getSalesTotal = (currentDate) => {
    setLoading({
      ...loading,
      salesTotal: true,
      process: true
    })

    const formattedDate = moment(currentDate || date)?.format(
      'YYYY-MM-DD'
    )

    Axios.get(`${environments.sales}/sales/${restaurantId}/sumInvoices?startDate=${formattedDate}&endDate=${formattedDate}`)
      .then((response) => {
        setSalesInfos({
          ...salesInfos,
          total: response?.data?.length ? response?.data[0]?.total : 0,
          pdv: response?.data?.length ? response?.data[0]?.total : 0
        })
        setLoading({
          ...loading,
          salesTotal: false,
          process: false
        })
      })
      .catch((error) => {
        console.log(error);
        setLoading({
          ...loading,
          salesTotal: false,
          process: false
        })
      });
  };

  const getNonIntegratedSalesLogs = () => {
    setLoading({
      ...loading,
      logsList: true
    })

    Axios.get(`${environments.sales}/sales/teknisa/integration/logs?restaurantId=${restaurantId}`)
      .then((response) => {
        setLogsList(response?.data);
        setLoading({
          ...loading,
          logsList: false
        })
      })
      .catch((error) => {
        console.log(error);
        setLoading({
          ...loading,
          logsList: false
        })
      });
  }

  const processNonIntegratedSales = () => {
    setLoading({
      ...loading,
      process: true
    })

    const body = {
      user: userInfo?.user,
      restaurantId,
      date
    };

    Axios
      .put(
        `${environments.sales}/sales/teknisa/reprocess`,
        body
      )
      .then((response) => {
        setSalesInfos({
          ...salesInfos,
          pdv: response?.data?.returnedAmount
        });
        setLoading({
          ...loading,
          process: false
        })

        setMessage({
          personalMessage: 'Processamento concluído com sucesso.',
          status: 'success'
        });
        getNonIntegratedSalesLogs()
      })
      .catch((error) => {
        if (error?.response?.data?.message === "no new sales to reprocess") {
          setSalesInfos({
            ...salesInfos,
            pdv: error?.response?.data?.returnedAmount
          });
        }
        
        setMessage({
          description: error?.response?.data?.message,
          status: 'error'
        });
        setLoading({
          ...loading,
          process: false
        })
      });
  }

  useEffect(() => {
    getNonIntegratedSalesLogs()
    getSalesTotal()
  }, [])

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      PaperComponent={PaperComponent}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal processNonIntegratedSalesDialog"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>PROCESSAR VENDAS NÃO INTEGRADAS</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img src={CloseIcon} alt="CloseIcon" />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent">
        {loading?.logsList ?
          <div className="w100 dFlex">
            <LinearProgress className="w100" id="loading" variant="query" />
          </div>
        : null}

        <div className="cardDefault dFlex flexColumn pt0 pl16 pr16 pb16 overflowHidden">
          <div className={`mainInfosBox dFlex ${!logsList?.length && 'mb0'}`}>
            <div className="datePicker">
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={ptLocale}
              >
                <KeyboardDatePicker
                  className="dateSmall mt30"
                  size="small"
                  id="time-picker"
                  label="Data"
                  placeholder="00/00/0000"
                  format="dd/MM/yyyy"
                  ampm={false}
                  inputVariant="outlined"
                  clearable
                  fullWidth
                  autoOk
                  value={date}
                  onChange={(value) => {
                    if (value) {
                      setDate(value)
                      getSalesTotal(value)
                    }

                    if (moment().format('YYYY-MM-DD') !== moment(value).format('YYYY-MM-DD')) {
                      setLogsList([])
                    } else {
                      getNonIntegratedSalesLogs()
                    }
                  }}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  KeyboardButtonProps={{
                    size: 'small',
                    'aria-label': 'change time',
                    style: { outline: 0 }
                  }}
                  PopoverProps={{ style: { outline: 0 } }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div className="bloomSalesBox">
              <span className="bold">
                Vendas Bloom
              </span>   

              <span>
                {loading?.salesTotal ?
                  <div className="salesInfosCircularProgressBar dFlex justifyEnd">
                    <CircularProgress />
                  </div>
                : 
                  `R$ ${numberToReal(salesInfos?.total, 2)}`
                }
              </span> 
            </div>

            <div className="pdvSalesBox">
              <span className="bold">
                Vendas PDV
              </span>

              <span>
                {loading?.process ?
                  <div className="salesInfosCircularProgressBar dFlex justifyEnd">
                    <CircularProgress />
                  </div>
                : 
                  `R$ ${numberToReal(salesInfos?.pdv, 2)}` 
                }
              </span> 
            </div>
          </div>

          {logsList?.length ? 
            <>
              <div className="fakeDivider"/>
              
              {logsList?.map(log => (
                <div className="processListInfos">
                  <div className="processItem" style={{ color: renderLogMessage(log)?.color }}>
                    {renderLogMessage(log)?.formattedMessage}
                  </div>
                </div>
              ))}
            </>
          : null}
        </div>

        <DialogActions className="pt16 pb0 pr0 pl0">
          <Button
            onClick={() => onClose()}
            className="defaultButton backButton fontSizeDefault"
            type="submit"
            design="contained"
            label="Voltar"
            style={{ outline: 0 }}
          />
          <Button
            onClick={() => {
              processNonIntegratedSales()
            }}
            className="defaultButton submitButton fontSizeDefault"
            type="submit"
            design="contained"
            disabled={loading?.process}
            label={loading?.process ? 'Processando...' : 'Processar'}
            style={{ outline: 0 }}
          />
        </DialogActions>
      </DialogContent>

      <CommonAlert
        open={!!message.description || !!message?.personalMessage}
        onClose={() => setMessage({ description: '', personalMessage: '', status: '' })}
        severity={message.status}
        indexMessage={message?.description}
        messagePersonal={message?.personalMessage}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProcessNonIntegratedSales);
