/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/close-icon-white.svg';
import getOptions from 'services/getOptions';
import * as ProductsActions from 'store/actions/products';
import Environment from 'utils/environments';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function ProductSkusDialog({
  fullScreen,
  maxWidth,
  open,
  onClose,
  title,
  product,
  modalSettings,
  setModalSettings,
  selectedSku,
  setSelectedSku,
  hasNewSku,
  products,
  setProducts,
  userInfo
}) {
  const { id: paramsID } = useParams();
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (hasNewSku) {
      const body = {
        editedFromPlatform: false,
        user: userInfo?.user
      };

      axios
        .put(
          `${environments.catalog}/products/${product.id}/editedFromPlatform`,
          body
        )
        .then(() => {
          setProducts(
            products.map((el) => ({
              ...el,
              skus: el?.skus.map((sku) => ({
                ...sku,
                editedFromPlatform: false
              }))
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [hasNewSku, product?.id, userInfo?.user]);

  useEffect(() => {
    if (!product?.skus?.length) {
      setTimeout(() => {
        setLoading(false)
      }, 5000)
    } else {
      setLoading(false)
    }
  }, [])

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      open={open}
      fullWidth
      onClose={() => {
        onClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal dialog100"
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>
          <b>{title}</b>
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <table>
          <thead>
            <tr>
              <th width="31%" className="subtitle pr0">
                DESCRIÇÃO
              </th>

              <th width="15%" className="subtitle pr0">
                MARCA
              </th>

              <th width="10%" className="subtitle pr0">
                QTDE/UN.
              </th>

              <th width="13%" className="subtitle right pr0">
                PREÇO MÉDIO
              </th>

              <th width="13%" className="subtitle right pr0">
                ÚLTIMO PREÇO
              </th>

              <th width="5%" className="subtitle right pr0">
                CONVERSÃO
              </th>

              <th width="13%" className="subtitle right">
                UNID. CONSUMO
              </th>
            </tr>

            <tr>
              <td colSpan="7" className="p0">
                <hr className="titleLineBorder m0" />
              </td>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th colSpan="7" className="textAlignCenter">
                  <LinearProgress
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {product?.skus?.length ? (
              product.skus.map((sku, skuIndex) => {
                let priceUc;
                 let conversion

                if(sku.skus){
                  priceUc = sku.priceUc;
                  conversion = sku.conversion;
                  sku = sku.skus
                }

                const isLastElement = skuIndex === product?.skus?.length - 1;

                return (
                  <>
                    <tr>
                      <td className="description pr0">
                        <div
                          className="cursorPointer lightBlue"
                          onClick={() => {
                            getOptions(
                              `${environments.catalog}/skus/${sku?.skuId || sku?.id}`,
                              'skus',
                              selectedSku,
                              setSelectedSku,
                              paramsID,
                              null,
                              null,
                              null,
                              true
                            );
                            setModalSettings(
                              modalSettings.map((modal) =>
                                modal.name === 'skuModal'
                                  ? { ...modal, open: true }
                                  : modal
                              )
                            );
                          }}
                        >
                          {ValidationLength(sku?.description || '-', 35)}
                        </div>
                      </td>

                      <td className="description pr0">
                        {ValidationLength(
                          sku?.brands?.description || '-',
                          20
                        )}
                      </td>

                      <td className="description pr0">
                        {sku?.quantity
                          ? numberToReal(sku?.quantity, 3)
                          : '-'}
                        {sku?.unitsMeasurements?.abbreviation
                          ? `/${sku?.unitsMeasurements.abbreviation}`
                          : ''}
                      </td>

                      <td className="description textAlignRight pr0">
                        R$
                        {sku?.restaurants?.length
                          ? numberToReal(
                              sku?.restaurants[0]?.purchaseAverage,
                              2
                            )
                          : '0,00'}
                      </td>

                      <td className="description textAlignRight pr0">
                        R$
                        {sku?.restaurants?.length
                          ? numberToReal(
                              sku?.restaurants[0]?.purchaseLast,
                              2
                            )
                          : '0,00'}
                      </td>

                      <td className="description textAlignRight pr0">
                        {conversion ? numberToReal(conversion,2) :
                        sku?.products?.length && sku?.products[0]?.conversion
                          ? numberToReal(sku?.products[0]?.conversion || 0, 2)
                          : '0,00'}
                      </td>

                      <td className="description textAlignRight">
                        R$
                        {priceUc? numberToReal(priceUc,2) : sku?.products?.length && sku?.products[0]?.priceUc ? numberToReal(sku?.products[0]?.priceUc || 0, 2) : '0,00'}
                      </td>
                    </tr>

                    {!isLastElement && (
                      <tr>
                        <td colSpan="7" className="p0">
                          <hr
                            className="titleLineBorder m0"
                            style={{ height: '2px' }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })
            ) : (
              <>
                <tr>
                  <td colSpan="7" className="description textAlignCenter">
                    <Typography style={{ margin: '5px' }}>
                      Não há skus associados.
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td colSpan="7" className="pr0 pl0">
                    <hr
                      className="titleLineBorder m0"
                      style={{ height: '2px' }}
                    />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductSkusDialog);