import React from 'react';

function ChildrenContent({ title, children }) {
  return (
    <div className="content-wrapper">
      <head>
        <title>{title ? `${title} | Restaurants` : 'Restaurants'}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </head>

      <div className="main-content">{children}</div>
    </div>
  );
}

export default ChildrenContent;
