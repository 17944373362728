import styled from 'styled-components';

export const DownloadReport = styled.div`
  @media (max-width: 870px) and (orientation: portrait) {
    display: none !important;
  }
`;

export const Td = styled.td`
  @media (max-width: 870px) and (orientation: portrait) {
    vertical-align: baseline;
  }

  .contentPeriod {
    @media (max-width: 870px) and (orientation: portrait) {
      display: none !important;
    }
  }

  .contentPeriodAfter {
    @media (max-width: 870px) and (orientation: portrait) {
      display: flex !important;
    }
  }

  .playCount {
    @media (max-width: 870px) and (orientation: portrait) {
      width: 25px;
    }
  }
`;

export const TdProgressStatus = styled.td`
  @media (max-width: 870px) and (orientation: portrait) {
    .MuiGrid-root {
      margin-left: 0 !important;
      padding: 5px 2px;
      width: 100%;
    }
  }
`;

export const TdLastCount = styled.td`
  @media (max-width: 870px) and (orientation: portrait) {
    display: none !important;
  }
`;

export const TdLastCountTablet = styled.td`
  @media (min-width: 870px) {
    display: none !important;
  }

  @media (max-width: 870px) and (orientation: portrait) {
    img {
      margin-top: 7px;
    }
  }
`;

export const TdIconFiles = styled.td`
  @media (max-width: 870px) and (orientation: portrait) {
    display: none !important;
  }
`;

export const TdIconTrash = styled.td`
  @media (max-width: 870px) and (orientation: portrait) {
    border-left: 1px solid #000;
  }
`;

export const TdDetour = styled.td`
  @media (max-width: 870px) and (orientation: portrait) {
    display: none !important;
  }
`;
