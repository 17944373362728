import React, { useEffect, useState } from 'react';

import numberToReal from 'utils/numberToReal';

import {
  InputManufactureWrapper,
  InputManufactureInput,
  InputManufactureInputContainer
} from './styles';

function InputNumber({
  id,
  initialValue,
  unity,
  disabled,
  handleValue = () => {},
  disableConvertion,
  size,
  validColor = true,
  backgroundColor,
  item,
  tableData,
  itens,
  fractionDigits = 3
}) {
  const parsedValue = disableConvertion
    ? initialValue
    : numberToReal(parseFloat(initialValue), fractionDigits);
  const [inputValue, setInputValue] = useState('');
  const handleInputValue = (e) => {
    const { target: { value } = {} } = e;
    if (!value) setInputValue();
    if (isNaN(parseFloat(value))) return;
    const replacedValues = value.replace(/[^0-9+,]/g, '');
    setInputValue(replacedValues);
  };

  const handleOnBlur = () => {
    if (inputValue === parsedValue) return;
    const newInputValue = inputValue ? inputValue?.replace(',', '.') : '';

    setInputValue(
      disableConvertion
        ? inputValue
        : numberToReal(parseFloat(newInputValue), fractionDigits)
    );
    handleValue(newInputValue, item, tableData, itens);
  };

  useEffect(() => {
    setInputValue(parsedValue);
  }, [parsedValue]);

  const colorType = {
    gray: {
      color: '#A0A1A7',
      backgroundColor: '#F2F2F4',
      border: '#333541'
    },
    green: {
      color: '#3DCC6E',
      backgroundColor: '#DFF7E8',
      border: '#01892F'
    },
    yellow: {
      color: '#FFBF3E',
      backgroundColor: '#FCF5E3',
      border: '#996600'
    }
  };

  return (
    <InputManufactureWrapper>
      <InputManufactureInputContainer id={id}>
        <InputManufactureInput
          onChange={handleInputValue}
          onBlur={handleOnBlur}
          value={inputValue}
          type="text"
          disabled={disabled}
          size={size}
          validColor={validColor}
          backgroundColor={backgroundColor ? colorType[backgroundColor] : false}
        />
        {unity}
      </InputManufactureInputContainer>
    </InputManufactureWrapper>
  );
}

export default InputNumber;
