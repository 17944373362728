const inventoriesLocalize = {
    "need to send originId": "Precisa enviar originId",
    "if withoutSetup is true it's necessary send the type": "Se withoutSetup for verdadeiro é necessário enviar o tipo",
    "not found results according to these parameters": "Não encontrei resultados de acordo com esses parâmetros",
    "there are mandatory keys missing from request": "Há chaves obrigatórias faltando na solicitação",
    "setups can't be empty": "As configurações não podem ficar vazias",
    "product not found with id": "Produto não encontrado com id",
    "setup not found with id": "configuração não encontrada com id",
    "this association already exists": "Esta associação já existe",
    "associate product count done": "Contagem de produtos associados concluída",
    "product not found": "Produto não encontrado",
    "setup not found": "Configuração não encontrada",
    "need to send a productId": "Precisa enviar um productId",
    "need to send a setupId": "Precisa enviar um setupId",
    "need to send a countDate": "Preciso enviar um countDate",
    "product already inactive in this setup": "Produto já inativo nesta configuração",
    "not found categories": "Categorias não encontradas",

};

export default inventoriesLocalize;