import React, { useEffect, useState, lazy, Suspense } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';

import BloomTable from 'components/BloomTable';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import Sales from 'components/Purchases&Sales/Sales/index';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import useFetch from 'services/useFetch';
import Environments from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';

import { handleFetchData } from './functions/handleFetchData';
import { sortData } from './functions/orderData';

function FornecedoresCatalogo({ userInfo }) {
  const dateLessOneDay = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const dateLessThreeMonths = moment()
    .subtract(3, 'months')
    .format('YYYY-MM-DD');

  const originIds = userInfo?.companies?.groups[0].subGroups[0].stores.map(
    (provider) => provider.id
  );

  const environments = Environments(process.env.REACT_APP_ENV);
  const originId = userInfo.companiesActive.id;
  const [options, setOptions] = useState([]);
  const [tableData, setTableData] = useState({
    header: [
      {
        name: 'CADASTRO',
        size: '68%',
        backgroundColor: '#1F2445',
        borderColor: '#1F2445',
        isBloom: false,
        subHeaders: [
          {
            name: 'NOME DO CLIENTE',
            size: '600px',
            order: {
              filterName: 'description'
            }
          },
          {
            name: 'STATUS',
            size: '150px',
            order: {
              filterName: 'status'
            }
          },
          {
            name: 'SKUS',
            size: '10px'
          }
        ]
      },
      {
        name: 'VENDAS REALIZADAS',
        size: '20%',
        backgroundColor: '#1F2445',
        borderColor: '#5062F0',
        isBloom: false,
        subHeaders: [
          {
            name: 'PEDIDOS',
            size: '100px',
            alignRight: true,
            order: {
              filterName: 'salesQuantity'
            }
          },
          {
            name: '\u00A0',
            size: '1px',
            alignRight: true
          },
          {
            name: 'VOLUME',
            size: '100px',
            alignRight: true,
            order: {
              filterName: 'salesVolume'
            }
          },
          {
            name: '\u00A0',
            size: '1px',
            alignRight: true
          }
        ]
      },
      {
        name: '  POTENCIAL BLOOM',
        size: '12%',
        backgroundColor: '#5062F0',
        isBloom: true,
        subHeaders: [
          {
            name: 'PEDIDOS',
            size: '100px',
            alignRight: true,
            order: {
              filterName: 'purchaseQuantity'
            }
          },
          {
            name: 'VOLUME',
            size: '100px',
            alignRight: true,
            order: {
              filterName: 'purchaseVolume'
            }
          }
        ]
      }
    ],
    collunms: [],
    tableActions: []
  });
  const [filters] = useState([
    {
      param: 'startDate',
      value: dateLessThreeMonths,
      urlParam: `startDate=${dateLessThreeMonths}`
    },
    {
      param: 'finishDate',
      value: dateLessOneDay,
      urlParam: `finishDate=${dateLessOneDay}`
    }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Cliente',
        filterName: 'restaurantId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        optionReference: 'description',
        options: [],
        size: 'large'
      },
      {
        label: 'SKU',
        filterName: 'descriptionSku',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/providers/catalog/${originIds}`,
        key: 'skusProvidersCatalog',
        optionReference: 'descriptionSku',
        options: [],
        size: 'large'
      },
      {
        type: 'dateRangeField',
        size: 'medium',
        filterNameFirst: 'startDate',
        filterNameSecond: 'finishDate',
        initialDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        minDate: true,
        maxDate: true,
        views: ['day', 'month', 'year'],
        dateFormat: 'dd/MM/yyyy'
      }
    ]
  ]);
  const [canPlaceOrderFiltered, setCanPlaceOrderFiltered] = useState(null);
  const [values, SetValues] = useState({
    totalQuantity: 0,
    totalVolume: 0,
    purchaseOrders: 0,
    purchaseVolume: 0,
    percentageQuantity: 0,
    percentageVolume: 0,
    potentialOrders: 0,
    potentialVolume: 0
  });
  const { status, data, loading } = useFetch(
    'providersClients',
    filtersChanged,
    false,
    `/${originIds}`,
    ''
  );

  // dialogs
  const SkusDialog = lazy(() => import('./dialogs/skusDialog'));

  const [modalStates, setModalStates] = useState({
    modalSkus: false
  });

  const [skusDialog, setSkusDialog] = useState([]);

  const handleClose = () => {
    setModalStates({
      modalSkus: false
    });

    setSkusDialog([]);
  };

  const handleOrdenation = (paramsAndValues) => {
    const [[a, orderBy], [b, orderOn]] = paramsAndValues;
    const dataSorted = sortData({
      data,
      orderBy,
      orderOn
    });
    handleFetchData(dataSorted, tableData, setTableData);
  };

  const handleFilter = (param, value) => {
    if (param instanceof Array) {
      value = param[0][1];
      param = param[0][0];
    }

    if (param === 'canPlaceOrder') {
      setCanPlaceOrderFiltered(value);
    }

    handleFilterOptions(param, value, filtersChanged, setFiltersChanged);
  };

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterHandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      null,
      filtersChanged
    );
  };

  const allPurchase = (data) => {
    const totalQuantity = data.reduce(
      (accumulator, x) => accumulator + Number(x.salesQuantity),
      0
    );
    const totalVolume = data.reduce(
      (accumulator, x) => accumulator + Number(x.salesVolume),
      0
    );
    const purchaseOrders = data.reduce(
      (accumulator, x) => accumulator + Number(x.purchaseQuantity),
      0
    );
    const purchaseVolume = data.reduce(
      (accumulator, x) => accumulator + Number(x.purchaseVolume),
      0
    );
    const potentialOrders = purchaseOrders - totalQuantity;
    const potentialVolume = purchaseVolume - totalVolume;

    // percentage
    const percentageQuantity =
      totalQuantity && purchaseOrders
        ? Math.round((100 * totalQuantity) / purchaseOrders)
        : 0;
    const percentageVolume =
      totalVolume && purchaseVolume
        ? Math.round((100 * totalVolume) / purchaseVolume)
        : 0;

    return {
      totalQuantity,
      totalVolume,
      purchaseOrders,
      purchaseVolume,
      percentageQuantity,
      percentageVolume,
      potentialOrders,
      potentialVolume
    };
  };

  const fetchAutoCompleteValue = (content) => {
    const options = content?.length
      ? content.map((item) => ({
          value: item.restaurantId,
          description: item.description
        }))
      : [];

    return { options };
  };

  useEffect(() => {
    const content = data && data.message ? [] : data;

    handleFetchData(content, tableData, setTableData);

    if (content?.length) {
      SetValues(allPurchase(content));
    }

    if (!options || options.length === 0) {
      const { options: clientsOptions } = fetchAutoCompleteValue(content);

      filterFields[0][0].options = clientsOptions;
    }
  }, [data]);

  return (
    <Layout>
      <TitleHead title="Fornecedores - Clientes" />

      <div className="cards">
        <h1 className="title">Clientes</h1>

        <Sales
          providerId={originIds}
          type="clients"
          ordersPercentage={values.percentageQuantity}
          volumePercentage={values.percentageVolume}
          yourSalesOrders={values.totalQuantity}
          yourSalesVolume={values.totalVolume}
          purchaseOrders={values.purchaseOrders}
          purchaseVolume={values.purchaseVolume}
          yourPotentialRequests={values.potentialOrders}
          yourPotentialVolume={values.potentialVolume}
          handleFilter={handleFilter}
          canPlaceOrderFiltered={canPlaceOrderFiltered}
          filter={filters}
          filtersChanged={filtersChanged}
        />
      </div>

      <PageTitleAndFilter
        activeFilterFilds={filterFields}
        handleFilter={handleFilter}
        handleMultipleFilters={handleMultipleFiltersFunc}
        getFilterhandle={getFilterHandle}
      />

      <BloomTable
        loading={loading}
        erroMensagem="Nenhuma informação encontrada"
        tableData={tableData}
        setTableData={setTableData}
        tableFixed={false}
        handleOrdernationFilter={handleOrdenation}
        setModalStates={setModalStates}
        modalStates={modalStates}
        setDataDialog={setSkusDialog}
      />

      <Suspense fallback={<span />}>
        <SkusDialog
          data={skusDialog}
          openModal={modalStates}
          handleClose={handleClose}
        />
      </Suspense>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(FornecedoresCatalogo);
