import React from 'react';

import { useParams } from 'react-router-dom';

import graphBlue from 'images/icons/arrows/graphBlue.svg';
import wrenchBlue from 'images/icons/configsAndSetups/wrenchBlue.svg';

import { MainGrid } from './styles';

function InformationBox({
  userInfo,
  selectedMainTypes,
  data,
  isModal,
  classes,
  selected
}) {
  const { id: paramsID } = useParams();

  const goToLink = (link) => {
    const isExternal = link.match(/https/gm);

    if (isExternal) {
      return window.open(link, '_blank');
    }

    return (window.location.href = `${link}/${
      paramsID || userInfo.companiesActive.id
    }`);
  };

  return ((selectedMainTypes.includes(data.category) ||
    selectedMainTypes.includes('GENERAL')) &&
    classes === selected) ||
    selected === 'all' ? (
    <MainGrid isModal={isModal}>
      <div className="header">
        <div className={classes === 'G' ? 'red' : 'green'}>
          <span>{data.title}</span>

          <div className="buttonBox">
            <button>?</button>
          </div>
        </div>
      </div>

      {data.showList.active ?? (
        <div className="completeList">
          <button onClick={() => (window.location.href = data.showList.link)}>
            Ver lista completa
          </button>
        </div>
      )}

      <div className="options">
        <span>OPÇÕES PARA RESOLVER A MISSÃO</span>

        {data.options.map((item) => (
          <div className="items">
            <span>{item.index}</span>
            <input
              onClick={() => goToLink(item.link)}
              placeholder={item.message}
            />
          </div>
        ))}
      </div>

      <div className="improveGrid">
        <span>ISSO VAI MELHORAR</span>

        <div className="items">
          <div className="graph">
            <div className="imgBox">
              <img src={graphBlue} alt="graph" />
            </div>

            <strong>+{data.values.diary}%</strong>
            <span>Potencial Diário</span>
          </div>

          <div className="graph">
            <div className="imgBox">
              <img src={wrenchBlue} alt="graph" />
            </div>

            <strong>+{data.values.bloom}%</strong>
            <span>Potencial Bloom</span>
          </div>
        </div>
      </div>

      {data.tip ? (
        <div className="tipGrid">
          <span>{data.tip}</span>
        </div>
      ) : (
        ''
      )}
    </MainGrid>
  ) : (
    ''
  );
}

export default InformationBox;
