const copyClipBoard = (nameId, index, extraIndex) => {
  const textToCopy = document.getElementById(
    `${nameId}${index !== null ? `-${index}` : ''}${extraIndex}`
  );

  let currentRange;
  if (document.getSelection().rangeCount > 0) {
    currentRange = document.getSelection().getRangeAt(0);
    window.getSelection().removeRange(currentRange);
  } else {
    currentRange = false;
  }

  const CopyRange = document.createRange();

  CopyRange.selectNode(textToCopy);

  window.getSelection().addRange(CopyRange);

  document.execCommand('copy');

  window.getSelection().removeRange(CopyRange);

  if (currentRange) {
    window.getSelection().addRange(currentRange);
  }
};

export default copyClipBoard;
