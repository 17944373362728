/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from 'react';

import {
  IconButton,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Checkbox,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Add,
  CheckOutlined,
  CloseOutlined,
  DeleteOutline
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Tree } from 'shineout';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import SwitchField from 'components/PageTitleAndFilter/SwitchField/SwitchField';
import TreeSelectorField from 'components/PageTitleAndFilter/TreeSelectorField';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import categoriesExpand from 'images/icons/signals/addsAndCreates/categoriesExpand.svg';
import categoriesCollapsed from 'images/icons/signals/removesAndDeletes/categoriesCollapsed.svg';
import getOptions from 'services/getOptions';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import ProductItemCategory from './ProductItemCategorie';
import { SwitchContainer, SwitchDescription } from './style';

function RegisterCategories({ userInfo }) {
  const [alertErrorDuplicated, setAlertErrorDuplicated] = useState(false);
  const [alertErrUpdate, setAlertErrUpdate] = useState(false);
  const [alertErrDelete, setAlertErrDelete] = useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const closeMessage = () => setAlertErrorDuplicated(false);
  const onDismiss = () => setAlertErr(false);
  const onDismissUpdate = () => setAlertErrUpdate(false);
  const onDismissDelete = () => setAlertErrDelete(false);
  const { id: paramsID } = useParams();
  const profileType = userInfo.profile[0][0].name;
  const environments = Environment(process.env.REACT_APP_ENV);
  const BaseUrl = `${environments.catalog}/categories`;
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [defaultExpanded] = useState(['Delivery']);
  const [expandedTree, setExpandedTree] = useState([]);
  const [description, setDescription] = useState(null);
  const [dataArray, setDataArray] = useState([]);
  const [buttonAddClick, setButtonAddClick] = useState(false);
  const [childId, setChildId] = useState('');
  const [typeClick, setTypeClick] = useState('Shopping');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [nodeClick, setNodeClick] = useState('');
  const [typeCategory, setTypeCategory] = useState('Shopping');
  const [getId, setGetId] = useState(paramsID);
  const [addRootCategory, setAddRootCategory] = useState('');
  const [deleteHas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasCmvGoal, setHasCmvGoal] = useState(false);
  const [goalCmv, setGoalCmv] = useState(0);
  const [hasCmvGoalChild, setHasCmvGoalChild] = useState(false);
  const [goalCmvChild, setGoalCmvChild] = useState(0);
  const [lossPercentage, setLossPercentage] = useState(null);
  const [hiddenProductsAndSkus, setHiddenProductsAndSkus] = useState(false);
  const [packageTypes, setPackageTypes] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [providers, setProviders] = useState([]);
  const [toastError, setToastError] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [user, setUser] = useState({
    groups: [],
    subgroups: [],
    stores: [],
  });

  const groupsOptions = [userInfo?.companies?.groups[0]];
  const [formatSubgroupOptions, setFormatSubgroupOptions] = useState(groupsOptions
    .map((el) => el?.subGroups).flat())
  const [formatStoreOptions, setFormatStoreOptions] = useState(groupsOptions
    .map((el) => el.subGroups)
    .flat()
    .map((item) => item.stores)
    .flat())
  const isGroupManage = getId?.includes(',');
  const currentIds = isGroupManage ? getId?.split(',') : getId

  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275
    },

    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)'
    },

    title: {
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      color: '#000'
    },

    buttonStyle: {
      display: 'inline-block',
      padding: '6px 4px',
      border: '1px solid #ccc'
    },

    margin: {
      margin: theme.spacing(1)
    },

    Alert: {
      width: '50%',
      height: '30px',
      backgroundColor: 'white',
      border: '1px solid black'
    },

    buttonRow: {
      paddingTop: '20px',
      marginLeft: '55px'
    },

    buttonRowChild: {
      paddingTop: '10px'
    },

    formControl: {
      marginTop: '10px',
      paddingLeft: '10px',
      minWidth: '200px'
    },

    selectEmpty: {
      marginTop: theme.spacing(1)
    },

    paper: {
      width: 200,
      height: 230,
      overflow: 'auto'
    },

    button: {
      margin: theme.spacing(0.5, 0)
    }
  }));

  const classes = useStyles();

  let arrayClickDelete;
  let findProduct = false;
  let findSku = false;
  let findCard = false;

  const handleDeleteFlags = (node) => {
    if (node.hasProduct) {
      deleteHas.push(`&updateProduct=${node.hasProduct}`);
    }
    if (node.hasCard) {
      deleteHas.push(`&updateCard=${node.hasCard}`);
    }
    if (node.hasSku) {
      deleteHas.push(`&updateSku=${node.hasSku}`);
    }
    if (node.parentId == null) {
      deleteHas.push(`&categoryNew=1&updateMenu=true`);
    } else {
      deleteHas.push(`&categoryNew=${node.parentId}&updateMenu=true`);
    }
  };

  const getCategories = useCallback(
    (typeCategory, customItems) => {
      (async () => {
        setLoading(true);
        const formattedIds = customItems ? customItems?.map(el => el?.id) : currentIds
        const categoriesApiSales = await axiosInstanceCatalog.get(
          `${environments.catalog}/categories?page=1&size=100&originId=${formattedIds}&type=${typeCategory}`
        );
        const data = await categoriesApiSales.data;
        setDataArray(data.content);
        setLoading(false);
      })();
    },
    [getId]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeType = (event) => {
    setTypeClick(event.target.value);
    setTypeCategory(event.target.value);
    getCategories(event.target.value);
  };

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  // adiciona categoria filha
  const addNewObj = () => {
    axios
      .post(
        `${BaseUrl}?updateMenu=true`,
        {
          method: 'POST',
          originId: isGroupManage ? user?.stores?.map(el => el?.id)[0] : getId,
          restaurantId: isGroupManage ? user?.stores?.map(el => el?.id) : '',
          description,
          parentId: childId,
          type: typeClick,
          hasCmvGoal: hasCmvGoalChild,
          goalCmv: goalCmvChild,
          lossPercentage,
          user: userInfo.user
        },
        config
      )
      .then(() => {
        setHasCmvGoalChild(false);
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch((error) => {
        setToastError(true);
        setMessageError(
          error?.response?.data?.message || error?.response?.data?.error
        );
        setStatusCode(error?.response?.status);
      })
      .finally(() => {
        setButtonAddClick(false);
      });
  };

  const cancelAddObject = () => {
    setButtonAddClick(false);
  };

  // adiciona categoria pai
  const addNewObjPai = () => {
    axios
      .post(
        BaseUrl,
        {
          method: 'POST',
          originId: isGroupManage ? user?.stores?.map(el => el?.id)[0] : getId,
          restaurantId: isGroupManage ? user?.stores?.map(el => el?.id) : '',
          description,
          parentId: null,
          type: typeClick,
          hasCmvGoal,
          goalCmv,
          user: userInfo.user,
          lossPercentage
        },
        config
      )
      .then(() => {
        setAddRootCategory('');
        setHasCmvGoal(false);
        setGoalCmv(0);
        setLossPercentage(null);
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch((error) => {
        setToastError(true);
        setMessageError(
          error?.response?.data?.message || error?.response?.data?.error
        );
        setStatusCode(error?.response?.status);
      });
  };

  // funcao para remover a categoria
  const removeObj = (node) => {
    handleDeleteFlags(node);

    axios
      .delete(
        `${environments.catalog}/categories/${node.id}?originId=${currentIds}${deleteHas}`,
        config
      )
      .then(() => {
        setTimeout(() => getCategories(typeCategory), 300);
      })
      .catch((error) => {
        console.log(error);
        setToastError(true);
        setMessageError('Description has been already registered');
        setStatusCode(error?.response?.status);
      });
    handleClose();
  };

  // funcao para mandar a edicao da categoria para o back
  const updateData = (id, description, hasCmvGoal, goalCmv, lossPercentage) => {
    if (description !== '') {
      axios
        .put(
          `${environments.catalog}/categories/${id}`,
          {
            description,
            hasCmvGoal,
            goalCmv,
            lossPercentage: lossPercentage || 0,
            user: userInfo.user
          },
          config
        )
        .then(() => {
          setTimeout(() => getCategories(typeCategory), 300);
        })
        .catch((error) => {
          setToastError(true);
          setMessageError(
            error?.response?.data?.message || error?.response?.data?.error
          );
          setStatusCode(error?.response?.status);
        });
    }
  };

  // estados da categoria filha
  const valuesChild = (node) => {
    setChildId(node.id);
    setButtonAddClick(true);
  };

  // funcao recursiva para contar quantas categorias filhas existe, na pai
  let count;
  const countCategories = (node) => {
    count = node.children.length;
    node.children.forEach((obj) => {
      if (obj.children) {
        count = parseInt(count, 10) + parseInt(obj.children.length, 10);
      } else {
        countCategories(obj.children);
      }
    });
  };

  // funcao que procura se tem sku, card, product cadastrado em alguma categoria
  const searchTrue = (nodesearch) => {
    if (nodesearch.parent === true) {
      for (let i = 0; i < nodesearch.children.length; i++) {
        const children = nodesearch.children[i];
        if (children.hasSku === true) {
          findSku = true;
        }
        if (children.hasProduct === true) {
          findProduct = true;
        }
        if (children.hasCard === true) {
          findCard = true;
        }

        if (children.parent === true) {
          for (let a = 0; a < children.children.length; a++) {
            const children2 = children.children[a];
            if (children2.hasSku === true) {
              findSku = true;
            }
            if (children2.hasProduct === true) {
              findProduct = true;
            }
            if (children2.hasCard === true) {
              findCard = true;
            }

            if (children2.parent === true) {
              for (let b = 0; b < children2.children.length; b++) {
                const children3 = children2.children[b];
                if (children3.hasSku === true) {
                  findSku = true;
                }
                if (children3.hasProduct === true) {
                  findProduct = true;
                }
                if (children3.hasCard === true) {
                  findCard = true;
                }

                if (children3.parent === true) {
                  for (let c = 0; c < children3.children.length; c++) {
                    const children4 = children3.children[c];
                    if (children4.hasSku === true) {
                      findSku = true;
                    }
                    if (children4.hasProduct === true) {
                      findProduct = true;
                    }
                    if (children4.hasCard === true) {
                      findCard = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const deleteCategory = (node) => {
    countCategories(node);
    setNodeClick(node);
    arrayClickDelete = node;
    searchTrue(arrayClickDelete);

    if (
      node.children.length > 0 &&
      !node.hasSku &&
      !node.hasCard &&
      !node.hasProduct
    ) {
      setMessage(
        ` A categoria ${node.description} não categorizou sku´s, produtos ou fichas mas possui ${count} categoria(s) filha(s), deseja realmente deletar ? `
      );
    }

    if (
      node?.children?.length === 0 &&
      !node.hasSku &&
      !node.hasCard &&
      !node.hasProduct
    ) {
      setMessage(
        `Deseja deletar a categoria ${node.description}? Ela não possui categorias filhas e também não categorizou Sku´s, Produtos ou Fichas  `
      );
    }

    if (findProduct === true || findCard === true || findSku === true) {
      setMessage(` A categoria ${
        node.description
      } possui categoria(s) filha(s) que tem
        ${findCard ? 'Fichas, ' : ''}
        ${findSku ? 'Sku, ' : ''}
        ${findProduct ? 'Produto' : ''}
      categorizados que serão movidos para uma categoria 'Nao Categorizados', deseja realmente deletar ? `);
    }

    if (node.hasSku || node.hasCard || node.hasProduct) {
      setMessage(` Deletar a categoria
        ${node.description} irá descategorizar
        ${node.hasCard ? 'Fichas, ' : ''}
        ${node.hasSku ? 'Sku, ' : ''}
        ${
          node.hasProduct ? 'Produto' : ''
        } que será movido para uma categoria "Não Categorizados",
        porém ela não tem categorias filhas, deseja realmente deletar? `);
    }

    if (
      node.children.length > 0 &&
      (node.hasSku || node.hasCard || node.hasProduct)
    ) {
      setMessage(` A categoria ${
        node.description
      } possui ${count} categoria(s) filha(s),
        irá descategorizar
        ${node.hasCard ? 'Fichas, ' : ''}
        ${node.hasSku ? 'Sku, ' : ''}
        ${node.hasProduct ? 'Produto' : ''}
        que será movido para uma categoria "Não Categorizados",
        deseja realmente deletar? `);
    }

    handleClickOpen();
  };

  const isDisableAddOrderCategory =
    description === null || description === '' || false;

  const findCategoryElement = (id) => {
    const currentSelectedCategory = document.getElementById(`item-${id}`);
    if (currentSelectedCategory) {
      const element = currentSelectedCategory;

      element.classList.remove('categorieOutlineNoColor');
      element.classList.add('categorieOutline');

      element.focus();
      setTimeout(() => {
        element.classList.remove('categorieOutline');
        element.classList.add('categorieOutlineNoColor');
      }, 2500);
    }
  };

  const handleCategorieValue = (_, currentCategorie, id) => {
    const newTree = [];
    const filterElementInCategory = (
      data,
      value,
      list = [],
      pastElement = []
    ) => {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const element = data[key];
          newTree.push(element);
          if (
            element.description.toLowerCase() === value.toLowerCase() &&
            element.id === id
          ) {
            list.push(element);

            const treePath = [...pastElement, ...list];
            const treeMap = treePath.map((item) => item.description);
            setExpandedTree(treeMap);
            setTimeout(() => {
              findCategoryElement(id);
            }, 150);
          }

          filterElementInCategory(element.children, value, list, [
            ...[element],
            ...pastElement
          ]);
        }
      }
    };
    filterElementInCategory(dataArray, currentCategorie);
  };

  // renderiza os objetos com os botoes edit, del, add
  const renderItem = (node) => (
    <div
      className={!!node.products?.length && !!node.children?.length && 'teste'}
    >
      <TextField
        margin="dense"
        size="small"
        variant="outlined"
        defaultValue={node.description}
        inputProps={{ maxLength: 50 }}
        onBlur={(e) =>
          updateData(
            node.id,
            e.target.value,
            node.hasCmvGoal,
            node.goalCmv,
            node.lossPercentage
          )
        }
        style={{
          width:
            typeCategory === 'Shopping' && profileType !== 'admin'
              ? '180px'
              : '320px'
        }}
        id={`item-${node.id}`}
      />

      <span style={{ position: 'relative', top: '8px' }}>
        {typeCategory === 'Shopping' && profileType !== 'admin' ? (
          <CurrencyTextField
            className="textFieldSmall right"
            type="text"
            placeholder="% de Perda"
            size="small"
            variant="outlined"
            minimumValue="0"
            decimalPlaces={2}
            decimalPlacesShowOnFocus={2}
            name="lossPercentage"
            outputFormat="string"
            currencySymbol=""
            decimalCharacter=","
            digitGroupSeparator="."
            defaultValue={node.lossPercentage}
            style={{ width: '75px', marginLeft: '5px', marginTop: '-6px' }}
            onBlur={(e) => {
              const lossPercentage =
                e.target.value != ''
                  ? parseFloat(
                      e.target.value.replace('.', '').replace(',', '.')
                    )
                  : 0;
              updateData(
                node.id,
                node.description,
                node.hasCmvGoal,
                node.goalCmv,
                lossPercentage
              );
            }}
          />
        ) : null}

        {profileType !== 'admin' ? (
          <FormControlLabel
            style={{ marginTop: '-.312rem', height: '13px' }}
            control={
              <Checkbox
                name="checkGoalCmv"
                color="primary"
                size="small"
                checked={node?.hasCmvGoal}
                style={{ marginLeft: '.6rem', transform: 'scale(0.75)' }}
                onChange={(e) => {
                  updateData(
                    node.id,
                    node.description,
                    e.target.checked,
                    node.goalCmv,
                    node.lossPercentage
                  );
                }}
              />
            }
            label={
              <span
                style={{
                  fontSize: '12px',
                  marginLeft: '1px',
                  fontFamily: 'Lato'
                }}
              >
                {' '}
                Meta CMV{' '}
              </span>
            }
          />
        ) : null}

        {node.hasCmvGoal && profileType !== 'admin' ? (
          <CurrencyTextField
            className="textFieldSmall right"
            type="text"
            placeholder="Meta CMV"
            size="small"
            variant="outlined"
            minimumValue="0"
            decimalPlaces={2}
            decimalPlacesShowOnFocus={2}
            name="goalCmv"
            outputFormat="number"
            currencySymbol=""
            decimalCharacter=","
            digitGroupSeparator="."
            value={node.goalCmv > 0 ? node.goalCmv : ''}
            onBlur={(e) => {
              updateData(
                node.id,
                node.description,
                node.hasCmvGoal,
                e.target.value,
                node.lossPercentage
              );
            }}
            style={{ width: '75px', marginTop: '-.375rem', marginLeft: '1px' }}
          />
        ) : null}

        <IconButton
          aria-label="add"
          disabled={node.nivel === 15}
          onClick={() => valuesChild(node)}
          style={{ outline: 6 }}
        >
          <Add aria-label="add" fontSize="small" />
        </IconButton>

        <IconButton
          aria-label="delete"
          onClick={() => deleteCategory(node)}
          style={{ outline: 6 }}
        >
          <DeleteOutline aria-label="delete" fontSize="small" />
        </IconButton>
      </span>

      {buttonAddClick && childId === node.id ? (
        <div className="addItemContainer">
          <Grid
            style={{ marginLeft: '16px', paddingTop: 0 }}
            className={classes.buttonRowChild}
            container
            spacing={12}
          >
            <Grid>
              <TextField
                className="textFieldSmall"
                placeholder="Adicionar..."
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />

              {typeCategory === 'Shopping' && profileType !== 'admin' ? (
                <CurrencyTextField
                  className="textFieldSmall right"
                  type="text"
                  placeholder="% de Perda"
                  size="small"
                  variant="outlined"
                  minimumValue="0"
                  decimalPlaces={2}
                  decimalPlacesShowOnFocus={2}
                  name="lossPercentage"
                  outputFormat="string"
                  currencySymbol=""
                  decimalCharacter=","
                  digitGroupSeparator="."
                  required
                  value={addRootCategory}
                  style={{ width: '75px', marginLeft: '5px' }}
                  onChange={(e) => {
                    const lossPercentage = parseFloat(
                      e.target.value.replace('.', '').replace(',', '.')
                    );
                    setLossPercentage(lossPercentage);
                  }}
                />
              ) : null}

              {profileType !== 'admin' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkGoalCmvChild"
                      color="primary"
                      size="small"
                      className="smallCheckbox"
                      onChange={() => setHasCmvGoalChild(!hasCmvGoalChild)}
                      checked={hasCmvGoalChild}
                      style={{ marginLeft: '.6rem', transform: 'scale(0.75)' }}
                    />
                  }
                  label={
                    <span
                      style={{
                        fontSize: '12px',
                        marginLeft: '1px',
                        fontFamily: 'Lato'
                      }}
                    >
                      {' '}
                      Meta CMV{' '}
                    </span>
                  }
                />
              ) : null}

              {profileType !== 'admin' && hasCmvGoalChild ? (
                <CurrencyTextField
                  className="textFieldSmall right"
                  type="text"
                  placeholder="Meta CMV"
                  size="small"
                  variant="outlined"
                  minimumValue="0"
                  decimalPlaces={2}
                  decimalPlacesShowOnFocus={2}
                  name="goalCmv"
                  outputFormat="number"
                  currencySymbol=""
                  decimalCharacter=","
                  digitGroupSeparator="."
                  onChange={(e) => {
                    setGoalCmvChild(parseFloat(e.target.value));
                  }}
                  style={{ width: '75px' }}
                />
              ) : null}

              <IconButton
                className="smallIcons"
                aria-label="check"
                onClick={() => addNewObj()}
                style={{ outline: 6 }}
                disabled={isDisableAddOrderCategory}
              >
                <CheckOutlined size="small" />
              </IconButton>

              <IconButton
                className="smallIcons"
                aria-label="cancel"
                onClick={() => cancelAddObject()}
                style={{ outline: 6 }}
              >
                <CloseOutlined size="small" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ) : null}

      {!!node.products?.length && !hiddenProductsAndSkus && (
        <ProductItemCategory
          node={node}
          getCategories={() => {
            getCategories(typeCategory);
          }}
          userInfo={userInfo}
          skuDialog={{
            packageTypes,
            unitsMeasurements,
            brands,
            consumptionUnits,
            storageLocations,
            periodicities,
            providers,
            categories: dataArray
          }}
        />
      )}
    </div>
  );
  
  // Origin Id
  useEffect(() => {
    setGetId(paramsID);
  }, [paramsID]);

  // Categorias
  useEffect(() => {
    const formattedIds = isGroupManage ? currentIds?.map(el => parseFloat(el)) : [getId]
    const selectedSubGroup = groupsOptions[0]?.subGroups.filter(el => el?.stores?.some(item => formattedIds.includes(item?.id)))
      
    if (isGroupManage) {
      setUser({
        ...user,
        groups: groupsOptions[0],
        subgroups: selectedSubGroup,
        stores: selectedSubGroup?.map(el => el?.stores)?.flat()
      });
    }

    getCategories(typeCategory, isGroupManage ? selectedSubGroup?.map(el => el?.stores)?.flat() : '');
  }, [getId]);

  useEffect(() => {
    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        currentIds[0]
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        currentIds[0]
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        currentIds[0]
      );
    !providers.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        currentIds[0],
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        currentIds[0]
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        currentIds[0]
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        currentIds[0]
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        currentIds[0],
        null,
        null,
        10
      );
  }, [getId]);

  return (
    <Layout>
      <TitleHead title="Categorias" />
      <GlobalStyle />

      <Container>
        {alertErr ? (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErr}
            toggle={onDismiss}
          >
            Erro ao cadastrar
          </Alert>
        ) : null}

        {alertErrUpdate ? (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErr}
            toggle={onDismissUpdate}
          >
            Erro ao atualizar
          </Alert>
        ) : null}

        {alertErrDelete ? (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErr}
            toggle={onDismissDelete}
          >
            Erro ao deletar
          </Alert>
        ) : null}

        {alertErrorDuplicated && (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErrorDuplicated}
            toggle={closeMessage}
          >
            Uma categoria não pode ter o mesmo nome que a categoria pai ou 2 de
            mesmo nível.
          </Alert>
        )}

        <div style={{ marginTop: 4, marginBottom: 3 }}>
          <PageTitleAndFilter title="Categorias cadastradas" />
        </div>

        <Grid className="cardDefault dFlex flexColumn withoutInputs mb16">
          <div className="dFlex">
            <div style={{ width: '300px' }}>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Selecione um tipo de categoria
              </Typography>

              <FormControl
                variant="outlined"
                size="small"
                className="selectSmallBox w100"
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={typeClick}
                  onChange={handleChangeType}
                  displayEmpty
                  className="selectSmall"
                >
                  <MenuItem value="">
                    <em>Selecione aqui...</em>
                  </MenuItem>
                  <MenuItem value="Sales">Venda</MenuItem>
                  <MenuItem value="Shopping">Compra</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{ width: '300px', marginLeft: '32px' }}>
              <TreeSelectorField
                label="Categorias"
                filterName="categories"
                handleFilter={handleCategorieValue}
                key={(node) => node?.id}
                size="extra_large"
                getFilterhandle={() => {}}
                options={[]}
                inputSizes={{
                  extra_large: 300
                }}
                onFormStyle
              />
            </div>

            <SwitchContainer>
              <div className="pRelative">
                <SwitchField
                  onChange={(isChecked) => {
                    setHiddenProductsAndSkus(isChecked);
                  }}
                  handleFilter={() => {}}
                  onFormStyle
                />
              </div>
              <SwitchDescription>Visualizar apenas Categorias</SwitchDescription>
            </SwitchContainer>
          </div>

          {isGroupManage ?
            <Grid className="m0 pt16" container xs={12} sm={12} spacing={4}>
              <Grid item xs={4} sm={4} className="pb5 pl0 pr0" style={{ maxWidth: '300px' }}>
                <Autocomplete
                  disabled
                  options={[groupsOptions]}
                  value={user?.groups}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    setUser({
                      ...user,
                      groups: value,
                      subgroups: [],
                      stores: []
                    });
                  }}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.name}
                    </Typography>
                  )}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="groups"
                      label="Grupos"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4} sm={4} className="pb5 pl0 pr0" style={{ maxWidth: '300px', marginLeft: 32 }}>
                <Autocomplete
                  key={Math.random()}
                  options={formatSubgroupOptions}
                  multiple
                  limitTags={2}
                  value={user?.subgroups}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value, reason, ) => {
                    const isRemoveOption = reason === "remove-option"

                    if (value?.length) {
                      const storesValue = isRemoveOption ? value?.map(el => el?.stores)?.flat() : user?.stores

                      setUser({
                        ...user,
                        subgroups: value,
                        stores: storesValue
                      });

                      setFormatStoreOptions(value?.map(el => el?.stores)?.flat())
                      getCategories(typeCategory, storesValue)
                    } else {
                      setUser({
                        ...user,
                        subgroups: [],
                        stores: []
                      });
                    }
                  }}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.name}
                    </Typography>
                  )}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="subgroups"
                      label="Subgrupos"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4} sm={4} className="pb5 pl0 pr0" style={{ maxWidth: '300px', marginLeft: 32 }}>
                <Autocomplete
                  key={Math.random()}
                  options={formatStoreOptions}
                  multiple
                  limitTags={1}
                  value={user?.stores}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    setUser({
                      ...user,
                      stores: value
                    });

                    getCategories(typeCategory, value)
                  }}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.name}
                    </Typography>
                  )}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="restaurants"
                      label="Restaurantes"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          : null}
        </Grid>

        <Grid className="cardDefault withoutInputs pRelative">
          {loading && (
            <div style={{ margin: '10px' }}>
              <LinearProgress variant="query" />
            </div>
          )}

          <Tree
            data={dataArray}
            keygen={(node) => node.description}
            defaultExpanded={defaultExpanded}
            renderItem={renderItem}
            className="categoriesTree"
            expandIcons={[
              <img src={categoriesExpand} alt="expandIcon" />,
              <img src={categoriesCollapsed} alt="collapse" />
            ]}
            expanded={expandedTree}
          />

          {typeCategory && (
            <Grid container spacing={12}>
              <Grid
                className={`fixedRootCategories ${
                  !dataArray?.length && 'noLines'
                }`}
              >
                <TextField
                  className="textFieldSmall"
                  placeholder="Adicionar..."
                  size="small"
                  variant="outlined"
                  inputProps={{ maxLength: 50 }}
                  value={addRootCategory}
                  InputLabelProps={{ shrink: true }}
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setAddRootCategory(e.target.value);
                  }}
                />

                {typeCategory === 'Shopping' && profileType !== 'admin' ? (
                  <CurrencyTextField
                    className="textFieldSmall right"
                    type="text"
                    placeholder="% de Perda"
                    size="small"
                    variant="outlined"
                    minimumValue="0"
                    decimalPlaces={2}
                    decimalPlacesShowOnFocus={2}
                    name="lossPercentage"
                    outputFormat="string"
                    currencySymbol=""
                    decimalCharacter=","
                    digitGroupSeparator="."
                    required
                    value={addRootCategory}
                    style={{ width: '75px', marginLeft: '5px' }}
                    onChange={(e) => {
                      const lossPercentage = parseFloat(
                        e.target.value.replace('.', '').replace(',', '.')
                      );
                      setLossPercentage(lossPercentage);
                    }}
                  />
                ) : null}

                {profileType !== 'admin' ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkGoalCmv"
                        color="primary"
                        size="small"
                        className="smallCheckbox"
                        onChange={() => setHasCmvGoal(!hasCmvGoal)}
                        checked={hasCmvGoal}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontSize: '12px',
                          marginLeft: '1px',
                          fontFamily: 'Lato'
                        }}
                      >
                        {' '}
                        Meta CMV{' '}
                      </span>
                    }
                  />
                ) : null}

                {hasCmvGoal && profileType !== 'admin' ? (
                  <CurrencyTextField
                    className="textFieldSmall right"
                    type="text"
                    placeholder="Meta CMV"
                    size="small"
                    variant="outlined"
                    minimumValue="0"
                    decimalPlaces={2}
                    decimalPlacesShowOnFocus={2}
                    name="goalCmv"
                    outputFormat="number"
                    currencySymbol=""
                    decimalCharacter=","
                    digitGroupSeparator="."
                    onChange={(e) => {
                      setGoalCmv(parseFloat(e.target.value));
                    }}
                    style={{ width: '75px', marginLeft: '1px' }}
                  />
                ) : null}

                <IconButton
                  className="smallIcons"
                  aria-label="check"
                  onClick={addNewObjPai}
                  style={{ outline: 6 }}
                  disabled={isDisableAddOrderCategory}
                >
                  <CheckOutlined size="small" />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography
              className="bold textAlignCenter"
              style={{
                fontSize: '18px'
              }}
            >
              {message}
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter pb20">
            <Button
              className="defaultButton backButton"
              onClick={handleClose}
              style={{ outline: 0 }}
              color="primary"
            >
              Cancelar
            </Button>

            <Button
              className="defaultButton submitButton"
              onClick={() => removeObj(nodeClick)}
              style={{ outline: 0 }}
              color="primary"
              autoFocus
            >
              Deletar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      
      <CommonAlert
        open={toastError}
        onClose={() => setToastError(false)}
        indexMessage={messageError}
        statusCode={statusCode}
        width="50%"
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCategories);
