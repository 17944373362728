import styled, {css} from 'styled-components';

export const ItemWrapper = styled.span`
    ${({buttonLink}) => buttonLink && css`
        cursor: pointer; 
        padding: 4px;
        color: ${buttonLink.color};
        background-color: ${buttonLink.backgroundColor};
        border-radius: 4px;
        overflow: hidden;
    `};
    ${({inputSize}) => inputSize && css`
        width: ${inputSize};
    `};
`; 
