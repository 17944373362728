/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Snackbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, CheckBoxOutlineBlank, StarBorder } from '@material-ui/icons';
import { Autocomplete, Pagination } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import axios from 'axios';
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import Button from 'components/Button/button';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  inputRoot: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  input: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  MuiCheckbox: {
    root: {
      svg: { color: 'grey' }
    }
  }
}));

const ButtonChip = styled(Chip)`
  width: 80px;
  height: 20px;
  font-family: Lato;
  font-size: 11px;
  margin: 0;
  padding: 0;
  line-height: 35px;
  cursor: pointer;
  ${({ background }) => handleBackgroundType(background)};
  ${({ color }) => handleColorType(color)};
  :hover {
    ${({ background }) => handleBackgroundType(background)};
    ${({ color }) => handleColorType(color)};
  }
`;

const handleColorType = (color) => {
  switch (color) {
    case 'primary':
      return 'color: #004085;';
    case 'secondary':
      return 'color: #383d41;';
    case 'success':
      return 'color: #155724;';
    case 'danger':
      return 'color: #721c24;';
    case 'warning':
      return 'color: #856404;';
    case 'info':
      return 'color: #0c5460;';
    case 'dark':
      return 'color: #1b1e21;';
    default:
      return 'color: #818182;';
  }
};

const handleBackgroundType = (color) => {
  switch (color) {
    case 'primary':
      return 'background: #cce5ff;';
    case 'secondary':
      return 'background: #e2e3e5;';
    case 'success':
      return 'background: #d4edda;';
    case 'danger':
      return 'background: #f8d7da;';
    case 'warning':
      return 'background: #fff3cd;';
    case 'info':
      return 'background: #d1ecf1;';
    case 'dark':
      return 'background: #d6d8d9;';
    default:
      return 'background: #fefefe;';
  }
};

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

export function OrdersList({ location, userInfo }) {
  const [state, setState] = useState('');
  const { id: paramsID } = useParams();
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 50,
    filters: [],
    numberOfElements: 0,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    filters,
    numberOfElements,
    totalElements
  } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState(callSettings.filters);
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'selectedOrders',
      open: false,
      fullWidth: true,
      maxWidth: 'lg'
    },
    { id: 2, name: 'selectSku', open: false, fullWidth: true, maxWidth: 'lg' },
    {
      id: 3,
      name: 'bonifiedSku',
      open: false,
      fullWidth: true,
      maxWidth: 'md'
    },
    { id: 4, name: 'filters', open: false, fullWidth: null, maxWidth: null },
    {
      id: 5,
      name: 'viewSentOrders',
      open: false,
      fullWidth: true,
      maxWidth: 'lg'
    },
    { id: 6, name: 'success', open: false, fullWidth: null, maxWidth: null },
    { id: 7, name: 'error', open: false, fullWidth: null, maxWidth: null }
  ]);
  const [errProviderMsg, setErrProviderMsg] = useState(false);
  const [orders, setOrders] = useState({ main: [], selectedOrders: [] });
  const { main, selectedOrders } = orders;
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [sentOrders, setSentOrders] = useState([]);
  const [productsListToSend, setProductsListToSend] = useState([]);
  const [values, setValues] = useState({ weight: '' });
  const [product, setProduct] = useState();
  const [productData, setProductData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [ordersToSend, setOrdersToSend] = useState([]);
  const environments = Environment(process.env.REACT_APP_ENV);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [optionsProviders, setOptionsProviders] = useState([]);
  const [quantityError, setQuantityError] = useState([]);
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [packageOptions, setPackageOptions] = useState([]);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const [stores, setStores] = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const [orderQuantity, setOrderQuantity] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [toastOrderError, setToastOrderError] = useState(false);
  const [toastProps, setToastProps] = useState();
  const classes = useStyles();
  const query = '';
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickOpenModal = (data) => {
    setOpenModal(true);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Fornecedores',
        filterName: 'providerId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.providers}/providers`,
        key: 'providers',
        optionReference: 'providerName',
        options: [],
        size: 'medium'
      },
      {
        label: 'Lojas',
        filterName: 'originId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'stores',
        optionReference: 'name',
        options: stores,
        size: 'medium'
      },
      {
        label: 'Categoria',
        filterName: 'categoryDescription',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Produtos',
        filterName: 'products',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'productsWSkusAndProductIds',
        optionReference: 'description',
        options: [],
        size: 'large'
      },
      {
        label: 'SKU',
        filterName: 'skus',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/skus`,
        key: 'skusWProducts',
        optionReference: 'description',
        options: [],
        size: 'large'
      }
    ],
    []
  ]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: paramsID });
  }, [paramsID]);

  const [loading, setLoading] = useState(false);

  const getProvidersBySku = useCallback(
    (skuId, orderId) => {
      (async () => {
        const response = await fetch(
          `${environments.providers}/providers?page=1&size=${size}&originId=${getId}&skuId=${skuId}`
        );
        const items = await response.json();

        setSelectedData((prevState) =>
          prevState.map((x) =>
            x.id === orderId
              ? {
                  ...x,
                  providerOptions:
                    items && items.content && items.content.length
                      ? items.content
                      : optionsProviders
                }
              : x
          )
        );
      })();
    },
    [getId]
  );

  function handleOrderState(id, data) {
    setOrders({
      ...orders,
      main: main.map((order) => (order._id === id ? data : order))
    });
  }

  const getProviderQuotation = useCallback(
    (order, skuId, providerId) => {
      (async () => {
        if (!order || !skuId || !providerId) return;

        const response = await fetch(
          `${environments.catalog}/quotations/skus/${skuId}/provider/${providerId}?originId=${getId}`
        );
        const items = await response.json();

        const getQuantyUnitaryValue = () => {
          const { data: { providers = [] } = {} } = response;
          if (!providers) return 0;

          const unitaryValue = providers[0]?.quotations[0]?.unitaryValue;
          if (!unitaryValue) return 0;

          return parseFloat(unitaryValue);
        };

        const getQuantityTotal = () => {
          const { data: { providers = [] } = {} } = response;
          if (!providers) return 0;

          const quantityTotal = providers[0]?.quotations[0]?.quantity;
          if (!quantityTotal) return 0;

          return parseFloat(quantityTotal);
        };

        const newItems = {
          ...items,
          quantityTotal: getQuantityTotal(),
          total: getQuantyUnitaryValue() * getQuantityTotal()
        };
        orderQuantity.push(newItems);
        setOrderQuantity([...orderQuantity]);
      })();
    },
    [getId]
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      const otherRestaurant =
        filtersChanged.find((item) => item.param === 'originId') &&
        filtersChanged.find((item) => item.param === 'originId').value;
      if (otherRestaurant) {
        const index = filtersChanged.indexOf(
          filtersChanged.find((item) => item.name === 'originId')
        );

        filtersChanged.splice(index, 1);
      }

      const params = filtersChanged.map((filter) => filter.urlParam).join('&');
      const response = await fetch(
        `${environments.orders}/orders?page=${page}&size=${size}${
          otherRestaurant
            ? `&originId=${otherRestaurant}`
            : `&originId=${getId}`
        }&status=SUGGESTION&${params}`
      );
      const items = await response.json();
      setLoading(false);
      items.content.map((order, index) => {
        if (checkboxValue.length < items.content.length) {
          setCheckboxValue((value) => [
            ...value,
            { orderIndex: index, checked: false }
          ]);
        }

        if (quantityError.length < items.content.length) {
          setQuantityError((value) => [
            ...value,
            { orderIndex: index, error: false }
          ]);
        }
      });

      setOrders({
        ...orders,
        main: items.content
      });

      setCallSettings({
        ...callSettings,
        totalPages: items.totalPages,
        numberOfElements: items.numberOfElements,
        totalElements: items.totalElements
      });

      for (let index = 0; index < items.content.length; index++) {
        const element = items.content[index];
        getProviderQuotation(
          items.content,
          element?.orderItems[0]?.sku?.id,
          element?.orderItems[0]?.sku?.provider?.id
        );
      }
    })();
  }, [getId, page, filtersChanged]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.orders}/orders?page=${page}&size=${size}&originId=${getId}&createdToday=true&type=S`
      );
      const items = await response.json();
      setSentOrders(
        items.content.sort((a, b) => a.providerName - b.providerName)
      );
    })();
  }, [getId]);

  useEffect(() => {
    if (product)
      (async () => {
        const response = await fetch(
          `${environments.catalog}/products/${product.id}?originId=${getId}`
        );
        const data = await response.json();
        setProductData((productData) =>
          productData.map((item) =>
            item.product.id === product.id
              ? { ...item, options: data.skus }
              : item
          )
        );
      })();
  }, [product]);

  useEffect(() => {
    if (product)
      (async () => {
        const response = await fetch(
          `${environments.catalog}/brands?page=1&size=1000&originId=${getId}`
        );
        const data = await response.json();
        setBrands(data.content);
      })();
  }, [product]);

  useEffect(() => {
    setSelectedData(
      main.map((order) => ({
        id: order._id,
        provider: optionsProviders.find(
          (x) => x.providerId == order.providerId
        ),
        providerOptions: [],
        store: stores.find((x) => x.id === order.store.originId)
      }))
    );
  }, [optionsProviders]);

  // Fornecedores
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.providers}/providers?page=1&size=1000&originId=${getId}`
      );
      const providers = await response.json();

      setOptionsProviders(
        providers.content.filter(
          (x) => x.contacts.filter((y) => y.telephoneNumber).length
        )
      );
    })();
  }, [getId]);

  function handleModal(title, value) {
    if (value) {
      const key = Object.keys(value)[0];
      const objValue = Object.values(value)[0];

      setModalSettings(
        modalSettings.map((x) =>
          x.name === title ? { ...x, [key]: objValue } : x
        )
      );
    } else {
      const { id } = modalSettings.find((x) => x.name === title);
      const { name } = modalSettings.find((x) => x.name === title);
      const { open } = modalSettings.find((x) => x.name === title);
      const { fullWidth } = modalSettings.find((x) => x.name === title);
      const { maxWidth } = modalSettings.find((x) => x.name === title);
      return { id, name, open, fullWidth, maxWidth };
    }
  }

  function handleOrders() {
    const orders = [];
    checkboxValue
      .filter((x) => x.checked)
      .forEach((item, index) => {
        const order = main[item.orderIndex];
        orders.push(order);
      });

    const finalList = [orders, productsListToSend].flat();

    setOrdersToSend(finalList.sort((a, b) => a.providerName - b.providerName));
    setSentOrders(sentOrders.sort((a, b) => a.providerName - b.providerName));
  }

  function handleSelectedOrder(checked, order) {
    const orders = [
      ...productsListToSend.sort((a, b) => a.providerName - b.providerName)
    ];
    const finalList = [orders, ordersToSend].flat();
    let unique = [...new Set(finalList)];

    if (!checked) {
      setSelectedData(
        selectedData.map((x) =>
          x.id === order._id ? { ...x, provider: null, providerOptions: [] } : x
        )
      );
      unique = unique.filter((item) => item._id !== order._id);

      setOrders({
        ...orders,
        main: main.map((item) => {
          if (item._id === order._id) {
            return {
              ...item,
              disableProviderSelection: true,
              orderItems: [
                {
                  ...item.orderItems[0],
                  sku: {
                    ...item.orderItems[0].sku,
                    description: '',
                    quantity: 0,
                    provider: {
                      id: null,
                      name: null,
                      quantity: null
                    },
                    providerOptions: null
                  }
                }
              ]
            };
          }
          return { ...item };
        })
      });
    } else {
      const newOrder = {
        ...order,
        providerId:
          order.orderItems[0].sku.providers.length === 1 &&
          order.orderItems[0].sku.providers[0].providerId,
        providerName:
          order.orderItems[0].sku.providers.length === 1 &&
          order.orderItems[0].sku.providers[0].name,
        orderItems: [
          {
            ...order.orderItems[0],
            consumptionUnits: order.orderItems[0].unitsMeasurements,
            costs: main.find((item) => item._id === order._id).orderItems[0]
              .costs,
            sku: {
              ...order.orderItems[0].sku,
              description: order.orderItems[0].sku.description,
              quantity: order.orderItems[0].sku.quantity,
              provider: {
                id:
                  order.orderItems[0].sku.providers.length === 1 &&
                  order.orderItems[0].sku.providers[0].status === 'ACTIVE' &&
                  order.orderItems[0].sku.providers[0].providerId,
                name:
                  order.orderItems[0].sku.providers.length === 1 &&
                  order.orderItems[0].sku.providers[0].status === 'ACTIVE' &&
                  order.orderItems[0].sku.providers[0].name,
                quantity:
                  order.orderItems[0].sku.providers.length === 1 &&
                  order.orderItems[0].sku.providers[0].status === 'ACTIVE' &&
                  order.orderItems[0].sku.providers[0].quantity
              },
              providerOptions: order.orderItems[0].sku.providers
            }
          }
        ]
      };

      unique.push(newOrder);

      setSelectedData(
        selectedData.map((x) =>
          x.id === order._id
            ? {
                ...x,
                provider:
                  order.orderItems[0].sku.providers.length === 1 &&
                  order.orderItems[0].sku.providers[0].status === 'ACTIVE' &&
                  optionsProviders.find(
                    (x) =>
                      x.providerId ==
                      order.orderItems[0].sku.providers[0].providerId
                  )
              }
            : x
        )
      );

      setOrders({
        ...orders,
        main: main.map((item) => {
          if (item._id === order._id) {
            return {
              ...item,
              disableProviderSelection: false,
              orderItems: [
                {
                  ...item.orderItems[0],
                  consumptionUnits: order.orderItems[0].unitsMeasurements,
                  sku: {
                    ...item.orderItems[0].sku,
                    description: order.orderItems[0].sku.description,
                    quantity: order.orderItems[0].sku.quantity,
                    provider: {
                      id:
                        order.orderItems[0].sku.providers.length === 1 &&
                        order.orderItems[0].sku.providers[0].status ===
                          'ACTIVE' &&
                        order.orderItems[0].sku.providers[0].providerId,
                      name:
                        order.orderItems[0].sku.providers.length === 1 &&
                        order.orderItems[0].sku.providers[0].status ===
                          'ACTIVE' &&
                        order.orderItems[0].sku.providers[0].name,
                      quantity:
                        order.orderItems[0].sku.providers.length === 1 &&
                        order.orderItems[0].sku.providers[0].status ===
                          'ACTIVE' &&
                        order.orderItems[0].sku.providers[0].quantity
                    },
                    providerOptions: order.orderItems[0].sku.providers
                  }
                }
              ]
            };
          }
          return { ...item };
        })
      });
    }

    setProductsListToSend(
      unique.sort((a, b) => a.providerName - b.providerName)
    );
  }

  function handleFilter(param, value, filter) {
    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      filter,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  }

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page,
      true
    );
  };

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOrderError(false);
  };

  function handleDesktopRows() {
    return main?.length ? (
      main.map((order, index) => {
        const item = order.orderItems[0];
        let background = '';
        let color = '';
        let duration = 0;
        let purchaseLast = 0;
        let purchaseLastQuantity = 0;
        let stock = 0;
        let category = '';
        let compLabel = 0;
        const providerPackage = item.sku.provider && item.sku.provider.quantity;
        const multiplePackage =
          item.sku.provider && item.sku.provider.multiplePackage;
        const quantity =
          item.sku.quantity && item.sku.quantity.toLocaleString('pt-br');
        const unit = item.unitsMeasurements
          ? item.unitsMeasurements.abbreviation
          : '-';
        const consumptionUnit = item.consumptionUnits
          ? item.consumptionUnits.abbreviation
          : null;
        let multiplier = 1;

        switch (consumptionUnit && consumptionUnit.toLowerCase()) {
          case 'ml':
            multiplier = 0.001;
            break;
          case 'g':
            multiplier = 0.001;
            break;
          case 'mg':
            multiplier = 0.000001;
            break;
          default:
            multiplier = 1;
            break;
        }

        function handleConversion(value) {
          if (consumptionUnit && consumptionUnit.toLowerCase() !== 'un')
            return (
              parseFloat(value) %
              (parseFloat(item.sku.provider ? item.sku.provider.quantity : 0) *
                parseFloat(multiplier * quantity))
            );
          return (
            parseFloat(value) %
            parseFloat(item.sku.provider ? item.sku.provider.quantity : 0)
          );
        }

        function handleTooltip() {
          let result = 0;

          if (consumptionUnit !== 'un') {
            result = (
              parseFloat(item.sku.provider ? item.sku.provider.quantity : 0) *
              parseFloat(multiplier * quantity)
            ).toLocaleString('pt-br', { minimumFractionDigits: 3 });
            return `Informe um valor múltiplo de ${result} ${consumptionUnit}`;
          }
          result = parseFloat(
            item.sku.provider ? item.sku.provider.quantity : 0
          ).toLocaleString('pt-br', { minimumFractionDigits: 3 });
          return `Informe um valor múltiplo de ${result} ${consumptionUnit}`;
        }

        const orderQuatation = orderQuantity.find((element) => {
          if (element.id === item.sku.id) {
            return element;
          }
        });

        const unityPriceToolTip =
          orderQuatation?.providers[0]?.quotations[0]?.expirationDate;
        const unityPriceTooltipLocale = new Date(
          unityPriceToolTip
        ).toLocaleString('pt-br', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        });

        const quotationQuantity = orderQuatation?.providers[0].quantity;
        const unitaryValue =
          orderQuatation?.providers[0]?.quotations[0]?.unitaryValue;
        const totalQuantity =
          unitaryValue && quotationQuantity
            ? parseFloat(unitaryValue) * parseFloat(item?.costs?.quantityTotal)
            : '';

        const disabledCheckbox = !item.product
          ? !item.costs.quantityTotal ||
            !item.costs.unitaryValue ||
            !order.providerId ||
            !order.store.name
          : true;

        const unitaryValueCurrentState = () => {
          if (item.costs.hasOwnProperty('unitaryValueHasChanged')) {
            if (isNaN(item.costs.unitaryValueHasChanged)) {
              return true;
            }
          }

          if (unitaryValue || item.costs.unitaryValueHasChanged) {
            return false;
          }

          if (!unitaryValue) {
            if (item.costs.unitaryValueHasChanged) {
              return false;
            }
            return true;
          }
          if (
            !item.costs.unitaryValueHasChanged ||
            isNaN(item.costs.unitaryValueHasChanged) ||
            item.costs.unitaryValueHasChanged === undefined
          ) {
            return true;
          }
          return false;
        };

        if (!item?.costs?.total || item?.costs?.total === '0') {
          item.costs.total = totalQuantity;
        }

        if (!item?.costs?.unitaryValue) {
          item.costs.unitaryValue = unitaryValue;
        }
        const isUnityExpirationTip = unityPriceToolTip
          ? new Date() > new Date(unityPriceToolTip)
          : false;

        // if(!item.product){
        category = item.categories ? item.categories.description : '';
        duration = item.sku.duration ? item.sku.duration : 0;
        purchaseLast = parseFloat(item.sku.lastValue ? item.sku.lastValue : 0);
        purchaseLastQuantity = item.costs.lastQuantityTotal
          ? item.costs.lastQuantityTotal
          : 0;
        stock = parseFloat(item.sku.stock ? item.sku.stock : 0);
        compLabel = purchaseLast
          ? (1 -
              purchaseLast /
                (parseFloat(item.costs.unitaryValue)
                  ? parseFloat(item.costs.unitaryValue)
                  : purchaseLast)) *
            100
          : 0;
        // }

        if (compLabel > 0) {
          background = 'warning';
          color = 'warning';
        } else if (compLabel < 0) {
          background = 'success';
          color = 'success';
        } else {
          background = 'dark';
          color = 'dark';
        }

        return (
          <>
            <tr valign="center">
              <td>
                <Checkbox
                  disabled={
                    unitaryValueCurrentState() ? true : disabledCheckbox
                  }
                  icon={
                    <CheckBoxOutlineBlank
                      style={
                        disabledCheckbox || unitaryValueCurrentState()
                          ? { color: 'grey' }
                          : null
                      }
                    />
                  }
                  classes={classes.MuiCheckbox}
                  onChange={(e) => {
                    setCheckboxValue((value) =>
                      value.map((x) =>
                        x.orderIndex === index
                          ? { ...x, checked: e.target.checked }
                          : x
                      )
                    );
                  }}
                  name="quote"
                  style={{ padding: 0 }}
                  size="small"
                  checked={
                    !order.orderItems[0].product
                      ? checkboxValue.find(
                          (value) => value.orderIndex === index
                        ).checked
                      : productData.find(
                          (item) =>
                            item.product.id === order.orderItems[0].product.id
                        ) &&
                        productData.find(
                          (item) =>
                            item.product.id === order.orderItems[0].product.id
                        ).selected.length
                      ? productData
                          .find(
                            (item) =>
                              item.product.id === order.orderItems[0].product.id
                          )
                          .options.some(
                            (item) =>
                              item.id ===
                              productData.find(
                                (item) =>
                                  item.product.id ===
                                  order.orderItems[0].product.id
                              ).selected[0].id
                          )
                      : false
                  }
                />
              </td>

              <td className="description p8">
                {!item.product ? (
                  ValidationLength(item.sku ? item.sku.description : null, 14)
                ) : (
                  <span
                    className="lightBlue cursorPointer"
                    onClick={(e) => {
                      const productExists = productData.find(
                        (x) => x.product.id === item.product.id
                      );
                      setProduct(item.product);
                      if (!productExists)
                        setProductData([
                          ...productData,
                          {
                            order,
                            product: item.product,
                            options: [],
                            selected: []
                          }
                        ]);
                      handleClickOpenModal();
                    }}
                  >
                    {ValidationLength(
                      item.product ? item.product.description : null,
                      14
                    )}
                  </span>
                )}
              </td>

              <td className="description left">
                {ValidationLength(category, 9)}
              </td>

              <td className="description center p8">{duration}</td>

              <td className="description pr8">
                <Autocomplete
                  size="small"
                  classes={{
                    inputRoot: classes.inputRoot,
                    input: classes.input,
                    option: classes.option
                  }}
                  id={`provider_${index}`}
                  onChange={(event, value) => {
                    if (!value) {
                      setSelectedData(
                        selectedData.map((x) =>
                          x.id === order._id ? { ...x, provider: null } : x
                        )
                      );

                      if (
                        productsListToSend.some(
                          (item) => item._id === order._id
                        )
                      ) {
                        setProductsListToSend(
                          productsListToSend.map((element) => {
                            if (element._id === order._id) {
                              return {
                                ...element,
                                orderItems: order.orderItems.map((stateItem) =>
                                  stateItem.sku.id === item.sku.id
                                    ? {
                                        ...stateItem,
                                        sku: {
                                          ...stateItem.sku,
                                          provider: {
                                            ...stateItem.sku.provider,
                                            quantity: 0,
                                            multiplePackage: false
                                          }
                                        }
                                      }
                                    : stateItem
                                )
                              };
                            }
                            return { ...element };
                          })
                        );
                      }

                      handleOrderState(order._id, {
                        ...order,
                        orderItems: order.orderItems.map((stateItem) =>
                          stateItem.sku.id === item.sku.id
                            ? {
                                ...stateItem,
                                sku: {
                                  ...stateItem.sku,
                                  provider: {
                                    ...stateItem.sku.provider,
                                    quantity: 0,
                                    multiplePackage: false
                                  }
                                }
                              }
                            : stateItem
                        )
                      });

                      return;
                    }

                    if (
                      productsListToSend.some((item) => item._id === order._id)
                    ) {
                      setProductsListToSend(
                        productsListToSend.map((element) => {
                          if (element._id === order._id) {
                            return {
                              ...element,
                              providerId: value.providerId,
                              providerName: value.providerName,
                              orderItems: order.orderItems.map((stateItem) =>
                                stateItem.sku.id === item.sku.id
                                  ? {
                                      ...stateItem,
                                      sku: {
                                        ...stateItem.sku,
                                        provider: {
                                          ...stateItem.sku.provider,
                                          id: value.providerId,
                                          name: value.providerName,
                                          quantity:
                                            stateItem.sku.providerOptions.filter(
                                              (element) =>
                                                element.providerId ===
                                                value.providerId
                                            ).length === 1 &&
                                            stateItem.sku.providerOptions.filter(
                                              (element) =>
                                                element.providerId ===
                                                value.providerId
                                            )[0].quantity,
                                          multiplePackage:
                                            stateItem.sku.providerOptions.filter(
                                              (element) =>
                                                element.providerId ===
                                                value.providerId
                                            ).length !== 1
                                        }
                                      }
                                    }
                                  : stateItem
                              )
                            };
                          }
                          return { ...element };
                        })
                      );
                    }

                    setSelectedData(
                      selectedData.map((x) =>
                        x.id === order._id ? { ...x, provider: value } : x
                      )
                    );
                    handleOrderState(order._id, {
                      ...order,
                      providerId: value ? value.providerId : null,
                      providerName: value ? value.providerName : null,
                      orderItems: order.orderItems.map((stateItem) =>
                        stateItem.sku.id === item.sku.id
                          ? {
                              ...stateItem,
                              sku: {
                                ...stateItem.sku,
                                provider: {
                                  ...stateItem.sku.provider,
                                  id: value.providerId,
                                  name: value.providerName,
                                  quantity:
                                    stateItem?.sku?.providerOptions?.filter(
                                      (element) =>
                                        element.providerId === value.providerId
                                    ).length === 1 &&
                                    stateItem?.sku?.providerOptions?.filter(
                                      (element) =>
                                        element.providerId === value.providerId
                                    )[0]?.quantity,
                                  multiplePackage:
                                    stateItem?.sku?.providerOptions?.filter(
                                      (element) =>
                                        element.providerId === value.providerId
                                    )?.length !== 1
                                }
                              }
                            }
                          : stateItem
                      )
                    });

                    if (!item.product) {
                      getProviderQuotation(
                        order,
                        order?.orderItems[0]?.sku?.id,
                        order?.orderItems[0]?.sku?.provider.id
                      );
                    } else {
                      getProviderQuotation(
                        order,
                        productData.find(
                          (element) => element.product.id === item.product.id
                        ).selected[0].skus.id,
                        value.id
                      );
                    }

                    if (
                      item?.sku?.providerOptions?.filter(
                        (element) => element.providerId === value.providerId
                      )?.length > 1
                    ) {
                      setPackageOptions(
                        item.sku.providerOptions
                          .filter(
                            (item) => item.providerId === value.providerId
                          )
                          .map(
                            (element) =>
                              `E${element.quantity}x${quantity}${unit}`
                          )
                      );
                    }
                  }}
                  disabled={
                    item.product ? order.disableProviderSelection : false
                  }
                  onFocus={(e) =>
                    !item.product
                      ? getProvidersBySku(item.sku.id, order._id)
                      : productData.length &&
                        productData.find(
                          (element) => element.order._id === order._id
                        ).selected.length
                      ? getProvidersBySku(
                          productData.find(
                            (element) => element.order._id === order._id
                          ).selected.length &&
                            productData.find(
                              (element) => element.order._id === order._id
                            ).selected[0].skus.id,
                          productData.find(
                            (element) => element.order._id === order._id
                          ).order._id
                        )
                      : null
                  }
                  getOptionLabel={(option) => option.providerName}
                  className="autocompleteSmall"
                  options={
                    selectedData.find((x) => x.id === order._id)
                      ? selectedData.find((x) => x.id === order._id)
                          .providerOptions
                      : []
                  }
                  value={
                    selectedData.find((x) => x.id === order._id)
                      ? selectedData.find((x) => x.id === order._id).provider
                      : null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      placeholder="Selecione..."
                      variant="standard"
                      size="small"
                    />
                  )}
                />
              </td>

              <td className="description pr8">
                <Autocomplete
                  size="small"
                  classes={{
                    inputRoot: classes.inputRoot,
                    input: classes.input,
                    option: classes.option
                  }}
                  id={`store_${index}`}
                  onChange={(event, value) => {
                    if (value) {
                      handleOrderState(order._id, {
                        ...order,
                        store: { originId: value.id, name: value.name }
                      });
                    } else {
                      handleOrderState(order._id, {
                        ...order,
                        store: { originId: null, name: '' }
                      });
                    }
                  }}
                  className="autocompleteSmall"
                  value={
                    selectedData.find((x) => x.id === order._id)
                      ? selectedData.find((x) => x.id === order._id).store
                      : null
                  }
                  getOptionLabel={(option) => option.name}
                  options={stores}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      placeholder="Selecione..."
                      variant="standard"
                      size="small"
                    />
                  )}
                />
              </td>

              <td className="description center pr8">
                {multiplePackage ? (
                  <Autocomplete
                    size="small"
                    classes={{
                      inputRoot: classes.inputRoot,
                      input: classes.input,
                      option: classes.option
                    }}
                    disableClearable
                    id={`store_${index}`}
                    onChange={(event, value) => {
                      if (
                        productsListToSend.some(
                          (item) => item._id === order._id
                        )
                      ) {
                        setProductsListToSend(
                          productsListToSend.map((element) => {
                            if (element._id === order._id) {
                              return {
                                ...element,
                                orderItems: order.orderItems.map((stateItem) =>
                                  stateItem.sku.id === item.sku.id
                                    ? {
                                        ...stateItem,
                                        sku: {
                                          ...stateItem.sku,
                                          provider: {
                                            ...stateItem.sku.provider,
                                            quantity: parseFloat(
                                              value
                                                .split('x')[0]
                                                .replace(/E/gi, '')
                                            )
                                          }
                                        }
                                      }
                                    : stateItem
                                )
                              };
                            }
                            return { ...element };
                          })
                        );
                      }

                      handleOrderState(order._id, {
                        ...order,
                        orderItems: order.orderItems.map((stateItem) =>
                          stateItem.sku.id === item.sku.id
                            ? {
                                ...stateItem,
                                sku: {
                                  ...stateItem.sku,
                                  provider: {
                                    ...stateItem.sku.provider,
                                    quantity: parseFloat(
                                      value.split('x')[0].replace(/E/gi, '')
                                    )
                                  }
                                }
                              }
                            : stateItem
                        )
                      });
                    }}
                    className="autocompleteSmall p0"
                    value={
                      providerPackage && quantity
                        ? `E${providerPackage}x${quantity + unit}`
                        : '-'
                    }
                    getOptionLabel={(option) => option}
                    options={packageOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        placeholder="Selecione..."
                        variant="standard"
                        size="small"
                      />
                    )}
                  />
                ) : (
                  <Typography style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                    {providerPackage && quantity
                      ? `E${providerPackage}x${quantity + unit}`
                      : '-'}
                  </Typography>
                )}
              </td>

              <td
                className="description right p8"
                style={{ borderLeft: 'solid 1px #ECEDF1' }}
              >
                <Typography style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                  {stock.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                </Typography>
              </td>

              <td className="description p8">
                <CurrencyTextField
                  variant="standard"
                  size="small"
                  currencySymbol=""
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  decimalPlaces={3}
                  decimalPlacesShownOnFocus={3}
                  value={
                    item.costs.quantityTotal ? item.costs.quantityTotal : 0
                  }
                  onBlur={(e, value) => {
                    if (handleConversion(value)) {
                      setQuantityError(
                        quantityError.map((x) =>
                          x.orderIndex === index ? { ...x, error: true } : x
                        )
                      );
                      setState({ quantityError });
                    } else {
                      setQuantityError(
                        quantityError.map((x) =>
                          x.orderIndex === index ? { ...x, error: false } : x
                        )
                      );
                      setState({ quantityError });
                      handleOrderState(order._id, {
                        ...order,
                        orderItems: order.orderItems.map((stateItem) =>
                          stateItem.sku.id === item.sku.id
                            ? {
                                ...stateItem,
                                costs: {
                                  ...stateItem.costs,
                                  quantityTotal: parseFloat(value),
                                  total:
                                    parseFloat(value) *
                                    parseFloat(stateItem.costs.unitaryValue)
                                }
                              }
                            : stateItem
                        )
                      });

                      if (
                        productsListToSend.some(
                          (item) => item._id === order._id
                        )
                      ) {
                        setProductsListToSend(
                          productsListToSend.map((element) => {
                            if (element._id === order._id) {
                              return {
                                ...element,
                                orderItems: element.orderItems.map(
                                  (stateItem) =>
                                    stateItem.sku.id ===
                                    element.orderItems[0].sku.id
                                      ? {
                                          ...stateItem,
                                          costs: {
                                            ...stateItem.costs,
                                            quantityTotal: parseFloat(value),
                                            total:
                                              parseFloat(value) *
                                              parseFloat(
                                                stateItem.costs.unitaryValue
                                              )
                                          }
                                        }
                                      : stateItem
                                )
                              };
                            }
                            return { ...element };
                          })
                        );
                      }
                    }
                  }}
                  type="text"
                  name="unitaryValue"
                  placeholder="Digite aqui..."
                  fullWidth
                  InputProps={{
                    style: {
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      padding: '4px 0 1px'
                    }
                  }}
                />
                <Tooltip
                  title={handleTooltip()}
                  placement="bottom-start"
                  open={quantityError.find((x) => x.orderIndex === index).error}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                >
                  <span width="1px" />
                </Tooltip>
              </td>

              <td
                className="center p8"
                style={{ background: 'rgba(89, 12, 127, .1)' }}
              >
                <Typography style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                  {purchaseLastQuantity.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
                </Typography>
              </td>

              <td className="description center p8">
                {item.unitsMeasurements
                  ? item.unitsMeasurements.abbreviation
                  : '-'}
              </td>

              <td
                className="description center p8"
                style={{ borderLeft: 'solid 1px #ECEDF1' }}
              >
                <Tooltip
                  title={unityPriceToolTip ? unityPriceTooltipLocale : ''}
                >
                  <span>
                    <CurrencyTextField
                      variant="standard"
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      style={{
                        color: 'red'
                      }}
                      value={unitaryValue}
                      onBlur={(e, value) => {
                        handleOrderState(order._id, {
                          ...order,
                          orderItems: order.orderItems.map((stateItem) =>
                            stateItem.sku.id === item.sku.id
                              ? {
                                  ...stateItem,
                                  costs: {
                                    ...stateItem.costs,
                                    unitaryValue: parseFloat(value),
                                    total:
                                      parseFloat(value) *
                                      parseFloat(stateItem.costs.quantityTotal),
                                    unitaryValueHasChanged: parseFloat(value)
                                  }
                                }
                              : stateItem
                          )
                        });

                        if (
                          productsListToSend.some(
                            (item) => item._id === order._id
                          )
                        ) {
                          setProductsListToSend(
                            productsListToSend.map((element) => {
                              if (element._id === order._id) {
                                return {
                                  ...element,
                                  orderItems: element.orderItems.map(
                                    (stateItem) =>
                                      stateItem.sku.id ===
                                      element.orderItems[0].sku.id
                                        ? {
                                            ...stateItem,
                                            costs: {
                                              ...stateItem.costs,
                                              unitaryValue: parseFloat(value),
                                              total:
                                                parseFloat(value) *
                                                parseFloat(
                                                  item.costs.quantityTotal
                                                    ? item.costs.quantityTotal
                                                    : stateItem.costs
                                                        .quantityTotal
                                                ),
                                              unitaryValueHasChanged:
                                                parseFloat(value)
                                            }
                                          }
                                        : stateItem
                                  )
                                };
                              }
                              return { ...element };
                            })
                          );
                        }
                      }}
                      type="text"
                      name="unitaryValue"
                      placeholder="Digite aqui..."
                      fullWidth
                      InputProps={{
                        style: {
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          padding: '4px 0 1px',
                          color: isUnityExpirationTip ? '#d94141' : 'black'
                        }
                      }}
                    />
                  </span>
                </Tooltip>
              </td>

              <td
                className="right p8"
                style={{ background: 'rgba(89, 12, 127, .1)' }}
              >
                <Typography style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                  {purchaseLast.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
                </Typography>
              </td>

              <td
                className="description center p8"
                style={{ borderLeft: 'solid 1px #ECEDF1' }}
              >
                <ButtonChip
                  style={{ borderRadius: '4px' }}
                  label={`${compLabel.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}%`}
                  color={color}
                  background={background}
                />
              </td>

              <td className="description right p8">
                {(item.costs ? Number(item.costs.total) : 0).toLocaleString(
                  'pt-br',
                  { minimumFractionDigits: 3 }
                )}
              </td>

              <td className="description center">
                <Tooltip title="Não há itens bonificados.">
                  <IconButton
                    aria-label="bonus"
                    disabled={
                      !order.orderItems[0].product
                        ? !order.orderItems[0].costs.quantityTotal ||
                          !order.orderItems[0].costs.unitaryValue ||
                          !order.providerId ||
                          !order.store.name
                        : null
                    }
                    style={{ padding: 0 }}
                    onClick={(e) => {
                      setModalSettings(
                        modalSettings.map((modal) =>
                          modal.name === 'bonifiedSku'
                            ? { ...modal, open: true }
                            : modal
                        )
                      );
                      setSelectedOrder(order);
                    }}
                  >
                    <StarBorder />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>

            <tr>
              <td colSpan="16">
                <hr />
              </td>
            </tr>
          </>
        );
      })
    ) : (
      <tr>
        <th colSpan="13" style={{ margin: '10px', textAlign: 'center' }}>
          Não há nenhuma sugestão
        </th>
      </tr>
    );
  }

  function handleSelectedOrdersRows() {
    return ordersToSend.map((order, orderIndex) => {
      const provider = optionsProviders.find(
        (x) => x.providerId === order.providerId
      );
      const ordersCount = ordersToSend.filter(
        (x) => x.providerId === order.providerId
      ).length;

      return order.orderItems.map((item, itemIndex) => (
        <tr>
          <td width="200" className="description">
            {!orderIndex ||
            order.providerName !== ordersToSend[orderIndex - 1].providerName
              ? provider
                ? ValidationLength(provider.providerName, 14)
                : null
              : null}
          </td>
          <td width="200" className="description">
            {!orderIndex ||
            order.providerName !== ordersToSend[orderIndex - 1].providerName
              ? ValidationLength(order.store.name, 14)
              : null}
          </td>
          <td width="200" className="description">
            {item.sku.description
              ? ValidationLength(item.sku.description, 14)
              : null}
          </td>
          <td width="120" className="description">
            {item.costs.total
              ? Number(item.costs.total).toFixed(2).toString().replace('.', ',')
              : 0}
          </td>
          <td width="200" className="description">
            {!orderIndex ||
            order.providerName !== ordersToSend[orderIndex - 1].providerName ? (
              <Autocomplete
                size="small"
                classes={{
                  inputRoot: classes.inputRoot,
                  input: classes.input,
                  option: classes.option
                }}
                defaultValue={
                  provider && provider.contacts && provider.contacts.length == 1
                    ? provider.contacts[0]
                    : null
                }
                id={`store_${itemIndex}`}
                onChange={(e, value) => {
                  if (value) {
                    setOrdersToSend(
                      ordersToSend.map((item) =>
                        item.providerId === order.providerId
                          ? {
                              ...item,
                              providerContact: value.telephoneNumber
                            }
                          : item
                      )
                    );
                    setErrProviderMsg(false);
                  }
                }}
                getOptionLabel={(option) => option.fullName}
                options={provider ? provider.contacts : []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    variant="standard"
                    size="small"
                  />
                )}
              />
            ) : null}
            <Tooltip
              title="Preencha este campo."
              placement="bottom-start"
              open={errProviderMsg}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              arrow
            >
              <span width="1px" />
            </Tooltip>
          </td>

          <td width="120" className="description">
            {!orderIndex ||
            order.providerName !== ordersToSend[orderIndex - 1].providerName ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                <DatePicker
                  fullWidth
                  format="dd/MM/yyyy"
                  size="small"
                  value={selectedStartDate}
                  onChange={(date) => {
                    setOrdersToSend(
                      ordersToSend.map((item) =>
                        item.providerId === order.providerId
                          ? { ...item, orderDate: date }
                          : item
                      )
                    );
                    setSelectedStartDate(date);
                  }}
                />
              </MuiPickersUtilsProvider>
            ) : null}
          </td>

          <td
            width="120"
            style={{ padding: '0 2px' }}
            onClick={() => {
              const orders = ordersToSend.filter(
                (x) => x.providerId === order.providerId
              );

              for (const order of orders) {
                if (
                  !order.providerContact &&
                  provider.contacts &&
                  provider.contacts.length == 1
                )
                  order.providerContact = provider.contacts[0].telephoneNumber;
              }

              sendOrder(orders);
            }}
            className="description right"
          >
            {!orderIndex ||
            order.providerName !== ordersToSend[orderIndex - 1].providerName ? (
              <span
                style={{
                  color: '#5062F0',
                  fontFamily: 'lato',
                  cursor: 'pointer'
                }}
              >
                {`Incluir  ${
                  ordersCount > 1
                    ? `${ordersCount} pedidos`
                    : `${ordersCount} pedido`
                }`}
              </span>
            ) : null}
          </td>
        </tr>
      ));
    });
  }

  function handleSentOrdersRows() {
    return sentOrders.length
      ? sentOrders.map((order, index) => (
          <>
            <tr>
              <td width="200" className="description">
                {!index ||
                order.providerName !== sentOrders[index - 1].providerName
                  ? order.providerName
                    ? ValidationLength(order.providerName, 15)
                    : null
                  : null}
              </td>

              <td width="200" className="description">
                {!index ||
                order.providerName !== sentOrders[index - 1].providerName
                  ? order.store
                    ? ValidationLength(order.store.name, 15)
                    : null
                  : null}
              </td>

              <td width="200" className="description">
                {order.orderItems && order.orderItems[0].sku.description
                  ? ValidationLength(order.orderItems[0].sku.description, 15)
                  : null}
              </td>
              <td width="120" className="description">
                {order.orderItems && order.orderItems[0].costs.total
                  ? Number(order.orderItems[0].costs.total)
                      .toFixed(2)
                      .toString()
                      .replace('.', ',')
                  : 0}
              </td>
              <td width="200" className="description">
                {order._id}
              </td>
            </tr>

            <tr>
              <td colSpan="7">
                <hr className="titleLineBorder" />
              </td>
            </tr>
          </>
        ))
      : null;
  }

  function sendOrder(orders) {
    if (!orders[0].providerContact) {
      setErrProviderMsg(true);

      return;
    }

    const body = {
      user: userInfo.user,
      status: 'PENDING',
      orderDate: orders[0].orderDate,
      deliveryDate: orders[0].deliveryDate
        ? orders[0].deliveryDate
        : new Date(),
      quotation: false,
      providerId: orders[0].providerId,
      providerName: orders[0].providerName,
      contact: `55${orders[0].providerContact
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/\-/g, '')}`,
      type: 'S',
      ordersList: orders.map((order) => ({
        ...order,
        status: 'PENDING',
        totalOrderAmount: order.orderItems[0].costs.total,
        orderItems: order.orderItems.map((item) => ({
          ...item,
          orderId: orders[0]._id,
          deleted: false
        }))
      }))
    };

    axios
      .post(`${environments.orders}/orders`, body, config)
      .then((res) => {
        // window.open(res.data.whats, "_blank")

        orders.forEach((order) => {
          const index = ordersToSend.findIndex((x) => x._id === order._id);
          ordersToSend.splice(index, 1);

          // main list
          const orderIndex = main.findIndex((x) => x._id === order._id);
          main.splice(orderIndex, 1);

          order._id = res.data._id;
          sentOrders.push(order);
        });

        setState({ orders, sentOrders });
        setCheckboxValue((value) =>
          value.map((item) => ({ ...item, checked: false }))
        );
        handleModal('success', { open: true });
      })
      .catch((error) => {
        handleModal('error', { open: true });
      });
  }

  return (
    <Layout>
      <TitleHead title="Sugestão" />

      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          title={
            <Typography style={{ fontFamily: 'Lato', fontWeight: 'bold' }}>
              Sugestões de Compra
              <span
                className="lightBlue cursorPointer"
                style={{ margin: '0 5px' }}
                onClick={(e) =>
                  setModalSettings(
                    modalSettings.map((modal) =>
                      modal.name === 'viewSentOrders'
                        ? { ...modal, open: !modal.open }
                        : modal
                    )
                  )
                }
              >
                {sentOrders.length}{' '}
                {sentOrders.length > 1 ? 'pedidos enviados' : 'pedido enviado'}{' '}
                hoje
              </span>
              Data Pedido: {format(new Date(), 'dd/MM/yyyy')}
            </Typography>
          }
          getFilterhandle={getFilterhandle}
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="1"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th />
              <th width="230" className="subtitle p8">
                SKU{' '}
                <OrderBy
                  filterName="sku"
                  handleFilter={handleOrdernationFilter}
                />
              </th>
              <th width="10" className="subtitle center">
                CATEGORIA
              </th>
              <th width="20" className="subtitle center p8">
                DUR.
              </th>
              <th width="200" className="subtitle pr8">
                FORNECEDOR
              </th>
              <th width="200" className="subtitle pr8">
                LOJA
                <OrderBy
                  filterName="store"
                  handleFilter={handleOrdernationFilter}
                />
              </th>
              <th width="110" className="subtitle center pr8">
                TIPO
              </th>
              <th
                colSpan="4"
                className="subtitle left p12"
                style={{
                  borderLeft: 'solid 1px #ECEDF1',
                  background: '#590C7F'
                }}
              >
                QUANTIDADE
              </th>
              <th
                colSpan="2"
                className="subtitle left p12"
                style={{
                  borderLeft: 'solid 1px #ECEDF1',
                  background: '#590C7F'
                }}
              >
                PREÇO
              </th>
              <th
                className="subtitle left p8"
                style={{ borderLeft: 'solid 1px #ECEDF1' }}
              >
                COMP
              </th>
              <th className="subtitle left">R$ TOTAL</th>
              <th />
            </tr>

            <tr className="headerSubtitle">
              <th colSpan="7">&nbsp;</th>
              <th
                width="6%"
                className="subtitle left pl12"
                style={{ borderLeft: 'solid 1px #ECEDF1' }}
              >
                ESTOQUE
              </th>
              <th width="6%" className="subtitle left p8">
                QTDE.
              </th>
              <th width="4%" className="subtitle left">
                ÚLTIMO
              </th>
              <th width="3%" className="subtitle center p8">
                UN.
              </th>
              <th
                width="7%"
                className="subtitle left pl12"
                style={{ borderLeft: 'solid 1px #ECEDF1' }}
              >
                R$ UNIT.
              </th>
              <th width="5%" className="subtitle left p8">
                R$ ULT.
              </th>
              <th width="3%" className="subtitle center p8">
                &nbsp;
              </th>
              <th width="5%" className="subtitle center p8">
                &nbsp;
              </th>
              <th width="3%" className="subtitle center p8">
                &nbsp;
              </th>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th
                  colSpan="17"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  <LinearProgress id="loading" variant="query" />
                </th>
              </tr>
            )}

            {handleDesktopRows()}
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(event, page) =>
              setCallSettings({ ...callSettings, page })
            }
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
            showFirstButton
            showLastButton
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>
      </Container>

      <Tooltip className="floatButtonFirst" title="Gerar Pedido(s)">
        <Fab
          color="primary"
          className={classes.fab}
          aria-label="add"
          size="medium"
          style={{ outline: 0 }}
          disabled={
            checkboxValue.every((value) => value.checked === false) &&
            (productsListToSend.length
              ? productsListToSend.some(
                  (element) => element.providerId === null
                ) ||
                productsListToSend.some((element) =>
                  element.orderItems.some((item) => item.costs.total === 0)
                ) ||
                productsListToSend.some((element) =>
                  element.orderItems.some(
                    (item) => item.costs.unitaryValue === undefined
                  )
                )
              : true)
          }
          onClick={(e) => {
            const selectedOrders = checkboxValue
              .filter((x) => x.checked)
              .map((item, index) => {
                const order = main[item.orderIndex];
                return order;
              });

            const fieldsError = [];
            selectedOrders.forEach(({ orderItems }) => {
              orderItems.forEach(({ costs }) => {
                const { quantityTotal, unitaryValue } = costs;
                if (!quantityTotal) {
                  fieldsError.push('quantity');
                }
                if (!unitaryValue) {
                  fieldsError.push('unitary');
                }
              });
            });

            if (fieldsError.length) {
              setToastProps({
                severity: 'error',
                message: 'Valor de quantidade ou unitário não pode ser zero.',
                timeOutOff: false
              });
              setToastOrderError(true);
              return;
            }
            handleOrders();
            handleModal('selectedOrders', { open: true });
          }}
        >
          <Add style={{ color: 'white' }} />
        </Fab>
      </Tooltip>

      <Snackbar
        open={toastOrderError}
        autoHideDuration={6000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity={toastProps?.severity}>
          {toastProps?.message}
        </Alert>
      </Snackbar>

      <Dialog
        fullWidth
        name="sentOrders"
        maxWidth={handleModal('selectedOrders').maxWidth}
        open={handleModal('selectedOrders').open}
        onClose={(e) => handleModal('selectedOrders', { open: false })}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal orderModal"
      >
        <DialogTitle
          className="modalHeader"
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            cursor: 'move',
            fontFamily: 'Lato'
          }}
          id="draggable-dialog-title"
        >
          <Typography>GERAR PEDIDOS</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={(e) => handleModal('selectedOrders', { open: false })}
            aria-label="close"
            style={{ background: 'none', align: 'right' }}
          >
            <img style={{ width: '35px' }} src={CloseIcon} alt="CloseIcon" />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          <p className="tableTitle">
            {ordersToSend.length} SKUs selecionados - Valor Total:
            {` ${formatter.format(
              ordersToSend.reduce(
                (total, value) =>
                  value.orderItems[0].costs
                    ? parseFloat(value.orderItems[0].costs.total) +
                      parseFloat(total)
                    : total,
                0
              )
            )}`}
          </p>

          <Grid container>
            <table
              className="tableModal"
              width="100%"
              cellPadding="3"
              cellSpacing="0"
              border="0"
              style={{ width: '100%', padding: 0 }}
            >
              <thead>
                <tr>
                  <th className="subtitle">FORNECEDOR</th>
                  <th className="subtitle right">LOJA</th>
                  <th className="subtitle right">SKU</th>
                  <th className="subtitle right">TOTAL R$</th>
                  <th className="subtitle right">CONTATO</th>
                  <th className="subtitle right" style={{ width: '14%' }}>
                    DATA DE ENTREGA
                  </th>
                  <th className="subtitle right" style={{ width: '11%' }}>
                    &nbsp;
                  </th>
                </tr>

                <tr className="headerBorder">
                  <th colSpan="5" className="titleLineBorder" />
                </tr>
              </thead>

              <tbody>{handleSelectedOrdersRows()}</tbody>
            </table>
          </Grid>

          <p className="tableTitle mt20">Pedidos enviados</p>

          <Grid container>
            <table
              className="tableModal"
              width="100%"
              cellPadding="3"
              cellSpacing="0"
              border="0"
              style={{ width: '100%', padding: 0 }}
            >
              <thead>
                <tr>
                  <th className="subtitle">FORNECEDOR</th>
                  <th className="subtitle right">LOJA</th>
                  <th className="subtitle right">SKU</th>
                  <th className="subtitle right">TOTAL R$</th>
                  <th className="subtitle right">PEDIDO</th>
                </tr>

                <tr className="headerBorder">
                  <th colSpan="5" className="titleLineBorder" />
                </tr>
              </thead>

              <tbody>
                {sentOrders.length ? (
                  handleSentOrdersRows()
                ) : (
                  <tr>
                    <td colSpan="7">
                      <Typography>Não há pedidos enviados.</Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        name="viewSentOrders"
        maxWidth={
          modalSettings.find((modal) => modal.name === 'viewSentOrders')
            .maxWidth
        }
        open={
          modalSettings.find((modal) => modal.name === 'viewSentOrders').open
        }
        onClose={(e) =>
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'viewSentOrders'
                ? { ...modal, open: false }
                : modal
            )
          )
        }
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal orderModal"
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
        >
          <Typography>PEDIDOS ENVIADOS</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={(e) => handleModal('viewSentOrders', { open: false })}
            aria-label="close"
            style={{ background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent onlyTable">
          <Grid container>
            <table
              className="tableModal"
              width="100%"
              cellPadding="3"
              cellSpacing="0"
              border="0"
              style={{ width: '100%', padding: 0 }}
            >
              <thead>
                <tr>
                  <th className="subtitle">FORNECEDOR</th>
                  <th className="subtitle">LOJA</th>
                  <th className="subtitle">SKU</th>
                  <th className="subtitle">TOTAL R$</th>
                  <th className="subtitle">PEDIDO</th>
                </tr>

                <tr className="headerBorder">
                  <th colSpan="5" className="titleLineBorder" />
                </tr>
              </thead>

              <tbody>
                {sentOrders.length ? (
                  handleSentOrdersRows()
                ) : (
                  <tr>
                    <td colSpan="7">
                      <Typography>Não há pedidos enviados.</Typography>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modalSettings.find((modal) => modal.name === 'bonifiedSku').open}
        onClose={() =>
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'bonifiedSku' ? { ...modal, open: false } : modal
            )
          )
        }
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Bonificação</DialogTitle>

        <DialogContent>
          <Grid item xs={12} sm={12}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{ fontFamily: 'Lato', fontWeight: 'bold' }}
                    >
                      SKU
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontFamily: 'Lato', fontWeight: 'bold' }}
                    >
                      Qtd.
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontFamily: 'Lato', fontWeight: 'bold' }}
                    >
                      Un.
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontFamily: 'Lato', fontWeight: 'bold' }}
                    >
                      R$ Unit.
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ fontFamily: 'Lato', fontWeight: 'bold' }}
                    >
                      R$ Total.
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell width="200">
                      <TextField
                        fullWidth
                        type="text"
                        // value={bonifiedSku ? bonifiedSku.description : null}
                        value={
                          selectedOrder
                            ? selectedOrder.orderItems[0].sku.description
                            : ''
                        }
                        placeholder="SKU"
                        disabled
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                        // error={Boolean(errors.quantityBonus)}
                      />
                    </TableCell>

                    <TableCell width="60" align="center">
                      <FormControl>
                        <CurrencyTextField
                          variant="standard"
                          size="small"
                          currencySymbol=""
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          decimalPlaces={3}
                          decimalPlacesShownOnFocus={3}
                          onBlur={(e) => {
                            if (e.target.value) {
                              const data = selectedOrder.orderItems[0];
                              if (!data.bonus) {
                                data.bonus = {
                                  unitsMeasurements: data.unitsMeasurements,
                                  quantity: null,
                                  unitaryValue: null,
                                  total: null
                                };
                              }

                              data.bonus.quantity = parseFloat(
                                e.target.value
                                  .replace(/\./g, '')
                                  .replace(',', '.')
                              );
                              if ((data.bonus.quantity, data.bonus.quantity)) {
                                data.bonus.total =
                                  parseFloat(data.costs.unitaryValue) *
                                  parseInt(data.bonus.quantity);
                                setState({ selectedOrder });
                              } else {
                                data.bonus.total = 0;
                              }
                            } else {
                              return null;
                            }
                          }}
                          type="text"
                          // value={bonifiedSku && bonifiedSku.bonus ? bonifiedSku.bonus.quantity : 0}
                          value={
                            selectedOrder && selectedOrder.orderItems[0].bonus
                              ? selectedOrder.orderItems[0].bonus.quantity
                              : 0
                          }
                          name="quantity"
                          // variant="outlined"
                          placeholder="Qtd."
                          // rules={{ required: "Campo obrigatório" }}
                          InputProps={{ classes: { input: classes.input } }}
                        />
                      </FormControl>
                    </TableCell>

                    <TableCell width="60" align="center">
                      <FormControl>
                        <TextField
                          type="text"
                          disabled
                          InputProps={{
                            style: { fontFamily: 'Lato', fontSize: '12px' }
                          }}
                          value={
                            selectedOrder &&
                            selectedOrder.orderItems[0].unitsMeasurements
                              ? selectedOrder.orderItems[0].unitsMeasurements
                                  .abbreviation
                              : null
                          }
                        />
                      </FormControl>
                    </TableCell>

                    <TableCell width="80" align="center">
                      <FormControl>
                        <CurrencyTextField
                          variant="standard"
                          size="small"
                          fullWidth
                          currencySymbol=""
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          decimalPlaces={3}
                          decimalPlacesShownOnFocus={3}
                          onBlur={(e) => {
                            if (e.target.value) {
                              const data = selectedOrder.orderItems[0];
                              data.bonus.unitaryValue = parseFloat(
                                e.target.value
                                  .replace(/\./g, '')
                                  .replace(',', '.')
                              );
                              if (data.bonus.unitaryValue) {
                                data.bonus.total = parseFloat(
                                  data.bonus.unitaryValue
                                );
                              } else {
                                data.bonus.total = 0;
                              }
                            } else {
                              return null;
                            }
                          }}
                          type="text"
                          name="unitaryValue"
                          value={
                            selectedOrder && selectedOrder.orderItems[0].bonus
                              ? selectedOrder.orderItems[0].bonus.unitaryValue
                              : 0
                          }
                          placeholder="R$ Unit."
                          InputProps={{ classes: { input: classes.input } }}
                        />
                      </FormControl>
                    </TableCell>

                    <TableCell width="100" align="right">
                      {selectedOrder && selectedOrder.orderItems[0].bonus
                        ? selectedOrder.orderItems[0].bonus.total
                        : 0}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() =>
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'bonifiedSku'
                    ? { ...modal, open: false }
                    : modal
                )
              )
            }
            design="outlined"
            label="Confirmar"
          />
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="md"
        open={openModal}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal"
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
        >
          <Typography>
            Selecione o SKU: {product ? product.description : ''}
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent onlyTable">
          <table>
            <thead>
              <tr>
                <th width="1%" className="subtitle pl10" />
                <th width="120" className="subtitle pl0">
                  EAN
                </th>
                <th width="230" className="subtitle">
                  DESCRIÇÃO
                </th>
                <th width="120" className="subtitle">
                  FABRICANTE
                </th>
                <th width="120" className="subtitle">
                  MARCA
                </th>
              </tr>
            </thead>

            <tbody>
              {productData.find((x) => x.product.id === product.id)
                ? productData
                    .find((x) => x.product.id === product.id)
                    .options.map((sku, index) => (
                      <tr>
                        <td className="description pl10">
                          <Checkbox
                            onChange={(e) => {
                              const data = productData.find(
                                (x) => x.product.id === product.id
                              );
                              if (e.target.checked) {
                                data.order = {
                                  ...data.order,
                                  orderItems: [
                                    {
                                      sku: {
                                        id: sku.skus.id,
                                        description: sku.skus.description,
                                        providers: sku.skus.providers,
                                        quantity: sku.skus.quantity
                                      },
                                      costs: {
                                        quantity: 0,
                                        unitaryValue: 0,
                                        total: 0
                                      },
                                      unitsMeasurements:
                                        sku.skus.unitsMeasurements
                                    }
                                  ]
                                };

                                setProductData(
                                  productData.map((item) =>
                                    item.product.id === product.id
                                      ? {
                                          ...item,
                                          selected: [...item.selected, sku]
                                        }
                                      : item
                                  )
                                );
                              } else {
                                setProductData(
                                  productData.map((item) =>
                                    item.product.id === product.id
                                      ? { ...item, selected: [] }
                                      : item
                                  )
                                );
                              }

                              handleSelectedOrder(e.target.checked, data.order);
                            }}
                            name="quote"
                            color="primary"
                            size="small"
                            checked={productData
                              .find((x) => x.product.id === product.id)
                              .selected.find((x) => x.id === sku.id)}
                            style={{ padding: 0 }}
                            disabled={
                              productData.find(
                                (x) => x.product.id === product.id
                              ).selected.length
                                ? productData.find(
                                    (x) => x.product.id === product.id
                                  ).selected[0].id !== sku.id
                                : false
                            }
                          />
                        </td>

                        <td className="description pl0">
                          {sku.skus && sku.skus.ean ? sku.skus.ean : ''}
                        </td>
                        <td className="description">
                          {sku.skus
                            ? ValidationLength(sku.skus.description, 35)
                            : ''}
                        </td>
                        <td className="description">
                          {sku.skus && sku.skus.brandsId
                            ? ValidationLength(
                                brands.find((x) => x.id === sku.skus.brandsId)
                                  .description,
                                10
                              )
                            : ''}
                        </td>
                        <td className="description">
                          {sku.skus && sku.skus.brandsId
                            ? ValidationLength(
                                brands.find((x) => x.id === sku.skus.brandsId)
                                  .producers.description,
                                10
                              )
                            : ''}
                        </td>
                      </tr>
                    ))
                : null}
            </tbody>
          </table>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);

