/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/close-icon-white.svg';
import RegisterProductModal from 'pages/catalog/products/ProductForm';
import getOptions from 'services/getOptions';
import * as ProductsActions from 'store/actions/products';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function AlreadyExistingProducts({
  setEditProducts,
  productInfo,
  open,
  handleClose,
  data,
  productWithSameDescription,
  storageLocations,
  periodicities,
  consumptionUnits
}) {
  const { id: getId } = useParams();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCard, setSelectedCard] = useState('');
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'modalProduct', open: false, fullScreen: false },
    { id: 2, name: 'modalTechnicalSheet', open: false, fullScreen: false }
  ]);
  const alertMessage = productWithSameDescription
    ? 'Foi encontrado um produto já cadastrado com a mesma descrição, para evitar duplicidade, por favor altere a descrição do novo produto.'
    : 'Foram encontrados alguns produtos similares já cadastrados em nossa base.';

  useEffect(() => {
    if (selectedProduct) {
      const arraySkus = [];
      selectedProduct.skus.forEach((skus) => {
        arraySkus.push({
          id: skus.skus ? skus.skus.id : null,
          description: skus.skus ? skus.skus.description : null,
          quantity: skus.skus
            ? parseFloat(skus.skus.quantity).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          unitsMeasurements: skus.skus
            ? skus.skus.unitsMeasurements.abbreviation
            : null,
          purchaseAverage:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseAverage
              : null,
          purchaseLast:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseLast
              : null,
          conversion: skus.skus
            ? parseFloat(skus.conversion).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          priceUc: skus ? skus.priceUc : null,
          restaurants: skus?.skus?.restaurants || [],
          providers: skus?.skus?.providers || []
        });
      });

      selectedProduct.skus = arraySkus;
    }

    setEditProducts(selectedProduct);

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'modalProduct' ? { ...modal, open: true } : modal
      )
    );
  }, [selectedProduct]);

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'modalTechnicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        className="defaultModal alreadyExistingProductsDialog"
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        transitionDuration={0}
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
          fullWidth
        >
          <Typography>PRODUTOS SIMILARES</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent p12 pb12 pt12">
          <p className="textAlignCenter pb16 m0 bold">{alertMessage}</p>

          <section className="alreadyExistingProductsList dFlex flexColumn">
            {data?.map((product) => (
              <Typography
                onClick={() => {
                  if (product?.type === 'INTERNAL') {
                    getOptions(
                      `${environments.catalog}/cards/${product?.originIdCard}`,
                      'cards',
                      selectedCard,
                      setSelectedCard,
                      getId,
                      null,
                      null,
                      null,
                      true
                    );
                  } else {
                    getOptions(
                      `${environments.catalog}/products/${product?.id}`,
                      'products',
                      selectedProduct,
                      setSelectedProduct,
                      getId,
                      null,
                      null,
                      null,
                      true
                    );
                  }
                }}
                className="productInfos dFlex mb5 cursorPointer lightBlue p0 fontSizeDefault"
              >
                {ValidationLength(product?.description, 35)}
              </Typography>
            ))}
          </section>

          <div className="buttonActions dFlex justifyCenter alignCenter pt12">
            <Button
              design="contained"
              className="defaultButton backButton fontSizeDefault mr10"
              onClick={() => {
                handleClose();
              }}
              label={productWithSameDescription ? 'OK' : 'Continuar'}
            />
          </div>
        </DialogContent>
      </Dialog>

      {modalSettings.find((modal) => modal.name === 'modalProduct').open &&
        productInfo?.data && (
          <RegisterProductModal
            dialogOpen={
              modalSettings.find((modal) => modal.name === 'modalProduct').open
            }
            isModal
            onlyView
            consumptionUnitOptions={consumptionUnits}
            periodicityOptions={periodicities}
            storageLocationOptions={storageLocations}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'modalProduct'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
          />
        )}

      {selectedCard && (
        <TechnicalSheetDialog
          maxWidth={null}
          open={
            modalSettings.find((modal) => modal.name === 'modalTechnicalSheet')
              .open
          }
          handleClose={() =>
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'modalTechnicalSheet'
                  ? { ...modal, open: false }
                  : modal
              )
            )
          }
          cardDataTable={cardDataTable}
          cardTransformation={cardTransformation}
          selectedCard={selectedCard}
          ucPrimarySelectedId={ucPrimarySelectedId}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlreadyExistingProducts);
