const numberIsOdd = (num) => {
    return num % 2;
}

const extraquantityCalc = (roundQuantityPerRestaurants, quantityPerRestaurants, totalRestaurants, totalQuantity) => {
    const partialResult = roundQuantityPerRestaurants + ((quantityPerRestaurants / 100) * totalRestaurants);
    
    const restaurantsLessOne = totalRestaurants - 1;
    const partialTotal = (roundQuantityPerRestaurants * restaurantsLessOne) + partialResult;
    const diference = partialTotal - totalQuantity;

    return partialResult - diference;
}

export const calcDistributionByRestaurant = ({contentSuggestion, restaurants}) => {
    const totalQuantity = (typeof contentSuggestion === 'string') ? parseFloat(contentSuggestion?.replace(/,/g, '.')) : parseFloat(contentSuggestion);
    const totalRestaurants = parseInt(restaurants);

    if (totalRestaurants === 1) return [totalQuantity];
    if (!numberIsOdd(totalRestaurants)) {
        const distributionEven = [];
   
        let index = 0;
        do {
            index += 1;
            distributionEven.push(totalQuantity / totalRestaurants);

        } while (index < totalRestaurants);


        return distributionEven;
    }

    const quantityPerRestaurants = totalQuantity / totalRestaurants;
    
    const roundQuantityPerRestaurants = Math.round(quantityPerRestaurants);
    
    const extraquantityResult = extraquantityCalc(roundQuantityPerRestaurants, quantityPerRestaurants, totalRestaurants, totalQuantity);
    
    const distributionQuantityRestaurants = [];
   
    let index = 0;
    do {
        index += 1;
        if (index === totalRestaurants && !!numberIsOdd(totalRestaurants)) {
            distributionQuantityRestaurants.push(extraquantityResult);
        }else{
            distributionQuantityRestaurants.push(roundQuantityPerRestaurants);
        }
    } while (index < totalRestaurants);

    return distributionQuantityRestaurants;
}

const suggestionQuantityDistribution = ({tableToModalProps,  suggestionData, handleUpdateSuggestionList}) => {
    if (!suggestionData) return; 
    const { modalToTableProps: { totalSuggestion, quantityeditByModal = false, suggestionList = [] } = {}, content: [contentSuggestion] = [] } = tableToModalProps;
    const { suggestions = [] } = suggestionData; 

    if (quantityeditByModal) {
        handleUpdateSuggestionList(suggestionList);
        return;
    }

    if (totalSuggestion === contentSuggestion) {
        handleUpdateSuggestionList(suggestions);
        return;
    }
    const distributionResult = calcDistributionByRestaurant({contentSuggestion: contentSuggestion, restaurants: suggestions.length});
    const newSuggestions = suggestions.map((suggestion, index) => {
        return {...suggestion, quantity: distributionResult[index]}
    });
    handleUpdateSuggestionList(newSuggestions);
}

export default suggestionQuantityDistribution;
