import Axios from 'axios';

import { queries } from './queries';

const userLocal = JSON.parse(localStorage.getItem('companies'));

const config = {
    headers: {
        User: `${userLocal?.user}`
    }
};

const makeDelete = (endpoint, params, handler, appendEndpoint) => {
    const flatParams = params.map(param => param.urlParam).join('&');
    const query = `${queries[endpoint]}${appendEndpoint ? appendEndpoint : ''}?${flatParams}`;

    Axios.delete(query, config)
        .then(function (response) {
            const { data = {} } = response;
            handler(true, data);
        })
        .catch(function (error) {
            handler(false, error);
        });
}

export default makeDelete;
