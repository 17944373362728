import styled from 'styled-components';

export const LightHouseWrapper = styled.div`
    position: absolute;
    width: 4px;
    height: 17px; 
    background-color: ${({color}) => color};
    margin-left: -7px;
    left: 6px;
`;
