/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { close } from 'store/reducers/simpleModalSlice';

import './SimpleModal.scss';

export default function SimpleModal({ closeFunction }) {
  const dispatch = useDispatch();

  const { status, title, content, closable } = useSelector(
    (state) => state.simpleModalSlice
  );

  const handleClose = () => {
    if (closeFunction) {
      closeFunction();
    } else {
      if (!closable) return;
      dispatch(close());
    }
  };
  
  return (
    <div className={`modalWrap ${status ? 'is-active' : ''}`}>
      <span className="backgroundModal" onClick={handleClose} />

      <div className="simpleModal">
        <div className="simpleModal--header">
          <h2 className="simpleModal--header__title">
            {title}
          </h2>

          <span
            className="simpleModal--header__closeBtn"
            onClick={handleClose}
          />
        </div>

        <div className="simpleModal--content">
          {content}
        </div>
      </div>
    </div>
  );
}
