/* eslint-disable no-unused-expressions */
import React from 'react';

import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CardInfoChart from './CardInfoChart';
import CardInfoComparation from './CardInfoComparation';
import { InfoDetail } from './CardInfoComparation/index.style';
import CardInfoList from './CardInfoList';
import {
  InfoContainer,
  CardTitle,
  CardSubtitleInfo,
  InfoWrap
} from './index.style.js';
import SubCardinfoComparation from './SubCardInfoComparation';
import { MoreInfoArrowBlue, MoreInfoArrow } from './utils';

import * as UserActions from '../../store/actions/user';

function MainInfoCard({
  filterRestaurantName,
  stores,
  cardInfo,
  lastDayOption,
  getId,
  filterRestaurants,
  companiesActive
}) {
  const { type, title, detailDropDown, subInfos, size, total, raw, datasets } =
    cardInfo;

  const handleDinamicHeight = () => {
    if (!type === 3) return false;
    return true;
  };

  return (
    <InfoWrap
      size={size === 3 && '74.5%'}
      dinamicHeight={handleDinamicHeight()}
    >
      <InfoContainer height={subInfos ? '167px' : '100%'}>
        <CardTitle>
          {title?.toUpperCase()}

          {type === 4 && (
            <div className="dFlex">
              <div className="dFlex mr20">
                {datasets.map(({ label, color }) => (
                  <div className="dFlex mr10 alignCenter">
                    <p className="fontSizeDefault mr5 mb0">{label}:</p>

                    <div
                      style={{
                        backgroundColor: color,
                        width: '30px',
                        height: '5px',
                        borderRadius: '50px'
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {detailDropDown && !cardInfo.chart && (
            <Link
              onClick={() => {
                filterRestaurants?.length === 1 &&
                  filterRestaurantName !== 'Todos' &&
                  companiesActive(
                    stores.find((item) => item.id === filterRestaurants[0]).id,
                    stores.find((item) => item.id === filterRestaurants[0])
                      .name,
                    stores.find((item) => item.id === filterRestaurants[0])
                      .index,
                    stores.find((item) => item.id === filterRestaurants[0])?.segment
                  );
              }}
              to={`${cardInfo.details}/${
                filterRestaurants?.length === 1 &&
                filterRestaurantName !== 'Todos'
                  ? filterRestaurants[0]
                  : getId
              }`}
              style={{
                cursor: cardInfo?.details ? 'pointer' : 'default'
              }}
            >
              <InfoDetail
                margin="8px"
                color={!cardInfo.details && '#A0A1A7'}
                cursor={cardInfo?.details ? 'pointer' : 'default'}
              >
                detalhes
                <img
                  src={cardInfo?.details ? MoreInfoArrowBlue : MoreInfoArrow}
                  alt={cardInfo?.details}
                  style={{
                    marginLeft: 3,
                    cursor: cardInfo?.details ? 'pointer' : 'default'
                  }}
                />
              </InfoDetail>
            </Link>
          )}

          {total && (
            <Tooltip
              title={<p className="mb0 indicatorValueTooltips">{raw}</p>}
              disableHoverListener={raw === undefined || raw === null}
              placement="right"
            >
              <CardSubtitleInfo>
                <span>Total:</span>
                <p>{total}</p>
              </CardSubtitleInfo>
            </Tooltip>
          )}
        </CardTitle>

        {type === 1 && (
          <CardInfoComparation
            filterRestaurantName={filterRestaurantName}
            stores={stores}
            cardInfo={cardInfo}
            lastDayOption={lastDayOption}
            getId={getId}
            filterRestaurants={filterRestaurants}
          />
        )}

        {type === 3 && <CardInfoList cardInfo={cardInfo} />}

        {type === 4 && <CardInfoChart cardInfo={cardInfo} />}
      </InfoContainer>

      {subInfos && (
        <InfoContainer height="97px">
          <CardTitle>
            {subInfos.title?.toUpperCase()}

            <Link
              onClick={() => {
                filterRestaurants?.length === 1 &&
                  filterRestaurantName !== 'Todos' &&
                  companiesActive(
                    stores.find((item) => item.id === filterRestaurants[0]).id,
                    stores.find((item) => item.id === filterRestaurants[0])
                      .name,
                    stores.find((item) => item.id === filterRestaurants[0])
                      .index,
                    stores.find((item) => item.id === filterRestaurants[0])?.segment
                  );
              }}
              to={`${subInfos.details}/${
                filterRestaurants?.length === 1 &&
                filterRestaurantName !== 'Todos'
                  ? filterRestaurants[0]
                  : getId
              }`}
              style={{
                cursor: subInfos?.details ? 'pointer' : 'default'
              }}
            >
              <InfoDetail
                color={!subInfos?.details && '#A0A1A7'}
                cursor={subInfos?.details ? 'pointer' : 'default'}
              >
                detalhes
                <img
                  src={subInfos?.details ? MoreInfoArrowBlue : MoreInfoArrow}
                  alt={subInfos?.details}
                  style={{
                    marginLeft: 3,
                    cursor: subInfos?.details ? 'pointer' : 'default'
                  }}
                />
              </InfoDetail>
            </Link>
          </CardTitle>

          <SubCardinfoComparation subInfos={subInfos} />
        </InfoContainer>
      )}
    </InfoWrap>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(null, mapDispatchToProps)(MainInfoCard);
