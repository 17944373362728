/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { LinearProgress, Tooltip, Fade } from '@material-ui/core';
import { Restore } from '@material-ui/icons';
import { useDispatch } from 'react-redux';

import InfoIconTooltip from 'components/BloomTable/components/InfoIconTooltip';
import ItemSelect from 'components/BloomTable/components/ItemSelect';
import LeftIconLighthouse from 'components/BloomTable/components/LeftIconLighthouse';
import SelectAll from 'components/BloomTable/components/SelectAll';
import ImageWrapper from 'components/ImageWrapper';
import InputNumber from 'components/Inputs/InputNumber';
import OrderBy from 'components/OrderBY';
import RightIconTooltip from 'components/RightIconTooltip';
import SelectItemList from 'components/SelectItemList';
import TableIcon from 'components/TableIcon';
import switchTableIcon from 'images/icons/arrows/switchTableIcon.svg';
import BloomIcon from 'images/icons/logo/bloomIconLarge.svg';
import makePut from 'services/useFetch/makePut';
import {
  selectManyProducts,
  handleProduct
} from 'store/reducers/quotationSelectProducts';
import Environment from 'utils/environments';
import { ShortenStringTooltip } from 'utils/shortenString';
import ValidationLength from 'utils/utils';

import {
  StyledTable,
  StyledTH,
  StyledTD,
  StyledTR,
  StyleTableBody,
  SubStyledTR,
  SubStyledTH,
  InputWrapper,
  SublineWrapper,
  TDContainer,
  SubLineContainer
} from './style';

import { statusIntention } from '../utils';

function QuotationManagerTable({
  tableData,
  setTableData,
  setModalStates,
  modalStatesObj,
  setModalStatesObj,
  modalStates,
  setProductsIds,
  setUcValue,
  tableSelectedItems,
  setTableSelectedItems,
  setCurrentModalTitle,
  handleOrdernationFilter,
  handleFilter,
  setTableToModalProps,
  loading,
  erroMensagem,
  setIconColor,
  setDataDialog,
  tableFixed = true,
  dynamicWidth = false,
  setDisabledApproveProduct,
  invertPositions = {},
  approveProductInfos,
  setCurrentProduct,
  setProductGroupId,
  setIntentionTooltip,
  intentionTooltip,
  openIntentionTooltip,
  storedQuotationProductsSelected,
  storedQuotationPagesSelected,
  page,
  toggleCellPositions = () => {}
}) {
  const dispatch = useDispatch();
  const environments = Environment(process.env.REACT_APP_ENV);

  const getColSpanSize = (headerSize, subHeaderSize) => {
    if (!headerSize) return 1;
    const internalHeaderSize = headerSize + 1;
    return internalHeaderSize / subHeaderSize;
  };

  const handleTableValues = (inputValue, item, tableData, itens) => {
    const { modalToTableProps: { quantityeditByModal } = {} } = item;
    if (quantityeditByModal) {
      item.modalToTableProps.quantityeditByModal = false;
    }

    if (Array.isArray(item.content)) {
      const [_, second] = item.content;
      const newList = [inputValue, second];
      item.content = newList;
    } else {
      item.content = inputValue;
    }

    if (item) {
      itens[0][item?.key] = inputValue;
    }

    if (itens[0]?.selected) {
      const newSelectedItems = storedQuotationProductsSelected.map((el) => {
        if (el.id === item.id) {
          el[item?.key] = inputValue;
        }

        return el;
      });

      dispatch(
        selectManyProducts({
          selectedProducts: newSelectedItems
        })
      );
    }
    setTableData(tableData);
  };

  const buyIntentionText = (status, otherStatus, noHistory) => {
    if (noHistory) {
      return 'Sem Histórico de Compras';
    }
    if (otherStatus && otherStatus !== status) {
      return 'Intenção/Sugestão';
    }
    switch (status) {
      case 'INTENTION':
        return 'Intenção de Compra';
      case 'ENGINE':
        return 'Sugestão';
      case 'HISTORY':
        return 'Histórico de Compras 30 dias';
      default:
        break;
    }
  };

  function handleInputValue(proId, restId, buyInt, groupdId) {
    const updateValue = [
      {
        productId: proId,
        restaurantId: restId,
        quantityIntention: buyInt
      }
    ];

    function handlePutResponse(response) {
      if (!response) {
      }
    }
    makePut(
      'quotationIntention',
      updateValue,
      handlePutResponse,
      `/${groupdId}`
    );
  }

  return (
    <StyledTable
      eachTD={4}
      tableFixed={tableFixed}
      dynamicWidth={dynamicWidth}
      className="quotationManagerTable pRelative"
    >
      <thead>
        <StyledTR
          style={{
            backgroundColor: '#D0D3E2',
            fontSize: '10px',
            fontWeight: 'bold',
            lineHeight: '12px'
          }}
        >
          <SubStyledTH
            style={{
              top: '-6px',
              backgroundColor: '#1F2445',
              height: '20px',
              width: '21px',
              zIndex: 1000
            }}
          >
            <div className="fakeTrLine">
              <SelectAll
                currentPage="quotation"
                tableData={tableData}
                setTableData={setTableData}
                description="Selecionar todos"
                darkMode
                pageNumber={page}
                storedQuotationPagesSelected={storedQuotationPagesSelected}
              />
            </div>
          </SubStyledTH>

          <SubStyledTH
            style={{
              top: '-6px',
              backgroundColor: '#1F2445',
              width: '70px',
              zIndex: 999
            }}
          />
          <SubStyledTH
            style={{ top: '-6px', backgroundColor: '#1F2445', width: '20%' }}
          />
          <SubStyledTH
            style={{ top: '-6px', backgroundColor: '#1F2445', width: '60px' }}
          />
          <SubStyledTH
            style={{
              paddingLeft: 0,
              top: '-6px',
              backgroundColor: '#1F2445',
              width: '12%'
            }}
          />
          <SubStyledTH
            style={{
              paddingLeft: 0,
              top: '-6px',
              backgroundColor: '#1F2445',
              width: '12%'
            }}
          />
          <SubStyledTH
            style={{ top: '-6px', backgroundColor: '#1F2445', width: '8%' }}
          />
          <SubStyledTH
            style={{ top: '-6px', backgroundColor: '#1F2445', width: '10%' }}
          />
          <SubStyledTH
            style={{ top: '-6px', backgroundColor: '#1F2445', width: '10%' }}
          />
          <SubStyledTH
            style={{ top: '-6px', backgroundColor: '#1F2445', width: '10%' }}
          />

          <SubStyledTH
            style={{
              top: '-6px',
              backgroundColor: '#454A67',
              color: 'white',
              padding: '5px',
              width: '12%'
            }}
          >
            ECONOMIA POTENCIAL
          </SubStyledTH>
        </StyledTR>

        <StyledTR backGroundColor="#313347">
          {tableData.header.map((data) => (
            <>
              <StyledTH
                backGroundColor={data.backgroundColor}
                width={data.size}
                padding={data?.padding}
                colSpan={data?.subHeaders?.length}
                borderRight={
                  !!(
                    data?.groupHeaders?.length == 0 ||
                    data?.groupHeaders?.length == undefined
                  )
                }
                borderColor={data.borderColor}
                fontColor={data.fontColor}
              >
                {data.isBloom && <TableIcon icon="bloomSM" />}
                {data.name}
                {data.iconName && (
                  <TableIcon
                    icon={data.iconName}
                    name={data?.infoIcon?.toolTip}
                  />
                )}
                {data.infoIcon && (
                  <InfoIconTooltip title={data?.infoIcon?.toolTip} />
                )}
                {data.order && (
                  <OrderBy
                    filterName={data?.order?.filterName}
                    handleFilter={handleOrdernationFilter}
                    orderbyBinIcon
                    ordenationisTouched={data?.order.ordenationisTouched}
                  />
                )}
              </StyledTH>

              {data?.groupHeaders?.map((element, index) => (
                <StyledTH
                  width={element.size}
                  borderRight={data.groupHeaders.length - 1 == index}
                >
                  {element.name}

                  {element.iconName && (
                    <TableIcon
                      icon={element.iconName}
                      name={element?.infoIcon?.toolTip}
                    />
                  )}

                  {element.infoIcon && (
                    <InfoIconTooltip title={element?.infoIcon?.toolTip} />
                  )}

                  {element.order && (
                    <OrderBy
                      filterName={element?.order?.filterName}
                      handleFilter={handleOrdernationFilter}
                      orderbyBinIcon
                      ordenationisTouched={element?.order.ordenationisTouched}
                    />
                  )}
                </StyledTH>
              ))}
            </>
          ))}
        </StyledTR>

        <SubStyledTR>
          {tableData.header.map((data) =>
            data?.subHeaders?.map((element, index) => (
              <SubStyledTH
                width={element.size}
                colSpan={element.colSpan}
                padding={element?.padding}
                borderRight={data.subHeaders.length - 1 == index}
                alignRight={element.alignRight}
                textAlign={element.textAlign}
                borderColor={data.borderColor}
                fontColor={element.fontColor}
              >
                {element.action === 'selectAll' && (
                  <SelectAll
                    currentPage="quotation"
                    tableData={tableData}
                    setTableData={setTableData}
                    pageNumber={page}
                    storedQuotationPagesSelected={storedQuotationPagesSelected}
                  />
                )}
                {element.switchTableInformation && (
                  <img
                    src={switchTableIcon}
                    width="12px"
                    style={{
                      position: 'absolute',
                      left: '8px',
                      cursor: 'pointer'
                    }}
                    onClick={() => toggleCellPositions(element.label)}
                    alt="SwitchIcon"
                  />
                )}
                {!element.appendColumn && (
                  <>
                    {element.name}
                    {element.order && (
                      <OrderBy
                        filterName={element?.order?.filterName}
                        ascFirst={element?.order?.ascFirst}
                        handleFilter={(orderBy, orderClassification) =>
                          handleOrdernationFilter(
                            orderBy,
                            orderClassification,
                            element.label
                          )
                        }
                        tinyArrow
                        orderbyBinIcon
                        ordenationisTouched={element?.order.ordenationisTouched}
                      />
                    )}
                  </>
                )}

                {!!element.appendColumn && (
                  <div style={{ display: 'flex' }}>
                    <div>
                      {element.name}
                      {element.order && (
                        <OrderBy
                          filterName={element?.order?.filterName}
                          ascFirst={element?.order?.ascFirst}
                          handleFilter={(orderBy, orderClassification) =>
                            handleOrdernationFilter(
                              orderBy,
                              orderClassification,
                              element.label
                            )
                          }
                          tinyArrow
                          orderbyBinIcon
                          ordenationisTouched={
                            element?.order.ordenationisTouched
                          }
                        />
                      )}
                    </div>
                    <div style={{ marginLeft: '41px' }}>
                      {element.appendColumn.name}
                      <OrderBy
                        filterName={element?.appendColumn?.order?.filterName}
                        ascFirst={element?.appendColumn?.order?.ascFirst}
                        handleFilter={(orderBy, orderClassification) =>
                          handleOrdernationFilter(
                            orderBy,
                            orderClassification,
                            element.appendColumn.label
                          )
                        }
                        tinyArrow
                        orderbyBinIcon
                        ordenationisTouched={
                          element?.appendColumn?.order.ordenationisTouched
                        }
                      />
                    </div>
                  </div>
                )}
                {element.iconName && (
                  <TableIcon
                    icon={element.iconName}
                    name={element?.infoIcon?.toolTip}
                  />
                )}

                {element.infoIcon && (
                  <InfoIconTooltip title={element?.infoIcon?.toolTip} />
                )}
              </SubStyledTH>
            ))
          )}
        </SubStyledTR>
      </thead>

      <StyleTableBody>
        {loading && (
          <tr>
            <th colSpan="17" style={{ margin: '10px', textAlign: 'center' }}>
              <LinearProgress id="loading" variant="query" />
            </th>
          </tr>
        )}

        {tableData?.collunms?.length ? (
          tableData.collunms.map((itens) => (
            <StyledTR withLine hoverActive>
              {itens?.map((item) => (
                <StyledTD
                  borderRight={item.borderTable}
                  align={item.align === 'number'}
                  padding={item.padding}
                  borderColor={item.borderColor}
                  buttonRectColor={item.buttonRectColor}
                  topAlign={item.topAlign}
                  onClick={() => {
                    if (!item.iconAction) {
                      item.dataToDialog && setDataDialog(item.dataToDialog);

                      setModalStates &&
                        !item.disableWrapperClick &&
                        setModalStates({ ...modalStates, [item.action]: true });
                      item.productsIds && setProductsIds(item.productsIds);
                      item.lineProductGroupId &&
                        setProductGroupId(item.lineProductGroupId);
                      setCurrentModalTitle &&
                        setCurrentModalTitle(itens[2].content);
                      setDisabledApproveProduct &&
                        setDisabledApproveProduct(itens[0].disabledMessage);
                      setTableToModalProps && setTableToModalProps(item);
                      setCurrentProduct && setCurrentProduct(itens[0].item);
                    }
                  }}
                  // cursorPointer={item.action}
                  backGroundColor={item.backGroundColor}
                  size={item.inputSize}
                  maxSize={item?.maxSize}
                >
                  <TDContainer
                    contentBlueCard={item.contentBlueCard}
                    specialDisplayCollumn={item.specialDisplayCollumn}
                    containerWidth={item.containerWidth}
                    textAlign={item.textAlign}
                    horizontalAlignment={item.horizontalAlignment1}
                    tdPadding={item?.tdPadding}
                  >
                    {item.type !== 'image' && (
                      <Tooltip
                        TransitionComponent={Fade}
                        disableHoverListener={!item.buttonTooltip}
                        TransitionProps={{ timeout: 600 }}
                        title={item.buttonTooltip || null}
                      >
                        <InputWrapper
                          typeAlign={item.align === 'number'}
                          inputSize={
                            item.containerSize
                              ? item.containerSize
                              : item.inputSize
                          }
                          alignItems={item?.alignItems}
                          contentBlueCard={item.contentBlueCard}
                          alignCenter={item.align === 'center'}
                          display={item.display}
                          invertOrder={invertPositions[item.label]}
                          truncateBySize={item.truncateBySize}
                          truncateonTwoLine={item.truncateonTwoLine}
                          padding={item.padding}
                          paddingDiv={item.paddingDiv}
                          specialDisplayCollumn={item.specialDisplayCollumn}
                          horizontalAlignment={item.horizontalAlignment1}
                        >
                          {item.action === 'selectItem' && (
                            <SelectItemList
                              currentPage="quotation"
                              selectOption={storedQuotationProductsSelected
                                ?.map((el) => el?.id)
                                .includes(item?.id)}
                              item={item}
                              tableData={tableData}
                              setTableData={setTableData}
                              disabledMessage={item.disabledMessage}
                            />
                          )}

                          {!Array.isArray(item.content) && (
                            <ItemSelect
                              productsIds={item.productsIds && item.productsIds}
                              setProductsIds={setProductsIds}
                              ucValue={item.ucValue && item.ucValue}
                              setUcValue={setUcValue}
                              setModalStates={setModalStates}
                              modalStates={modalStates}
                              itemName={item.content}
                              action={item.action}
                              validColor={item?.valid}
                              type={item.type}
                              setCurrentModalTitle={setCurrentModalTitle}
                              modalTitle={itens[1].content}
                              tooltip={item.tooltip}
                              buttonTooltip={item.buttonTooltip}
                              itemLength={item.itemLength}
                              buttonLinkColor={item.buttonLink}
                              inputSize={item.inputSize}
                              modalToTableProps={item}
                              setTableToModalProps={setTableToModalProps}
                              fractionDigits={item.fractionDigits}
                              setDataDialog={setDataDialog}
                              dataToDialog={item.dataToDialog}
                              contentBlueCard={item.contentBlueCard}
                              buttonRectColor={item.buttonRectColor}
                              underline={item.underline}
                              fontStyle={item.fontStyle}
                              truncateOff={item.truncateOff}
                              item={item}
                              itens={itens}
                              hasValue={itens[10].content === '-'}
                            />
                          )}

                          {item.leftIconLighthouse && (
                            <LeftIconLighthouse
                              color={item.leftIconLighthouse}
                            />
                          )}

                          {item?.buyIntention && (
                            <Tooltip
                              onMouseEnter={() => {
                                if (item?.lineProductGroupId) {
                                  openIntentionTooltip(item.lineProductGroupId);
                                }
                              }}
                              onMouseLeave={() => setIntentionTooltip(null)}
                              placement="right"
                              title={
                                <p
                                  className="mb0 dFlex flexColumn"
                                  style={{ padding: '2px 6px' }}
                                >
                                  <span
                                    style={{
                                      color:
                                        item?.buyIntention?.type ===
                                          'HISTORY' &&
                                        !item?.totalQuantity &&
                                        !item?.totalValue
                                          ? '#9296AC'
                                          : '#1D8FC9'
                                    }}
                                    className="mb5 bold"
                                  >
                                    {buyIntentionText(
                                      item?.buyIntention?.type,
                                      item?.buyIntention?.otherType,
                                      item?.buyIntention?.type === 'HISTORY' &&
                                        !item?.totalQuantity &&
                                        !item?.totalValue
                                    )}
                                  </span>

                                  {intentionTooltip?.totalValue &&
                                  intentionTooltip?.totalRestaurants ? (
                                    <>
                                      {intentionTooltip.totalValue?.toLocaleString(
                                        'pt-br',
                                        { minimumFractionDigits: 3 }
                                      )}
                                      {item?.consumptionUnit}/
                                      {intentionTooltip?.totalRestaurants}
                                    </>
                                  ) : null}

                                  {item?.buyIntention?.type === 'HISTORY' &&
                                    (item?.products.filter(
                                      (el) => el?.intention?.type === 'HISTORY'
                                    ).length === 1
                                      ? `${
                                          item?.products.filter(
                                            (el) =>
                                              el?.intention?.type === 'HISTORY'
                                          ).length
                                        } Restaurante`
                                      : !item?.totalQuantity &&
                                        !item?.totalValue
                                      ? `${item?.products?.length} Restaurantes`
                                      : `${
                                          item?.products.filter(
                                            (el) =>
                                              el?.intention?.type === 'HISTORY'
                                          ).length
                                        }/${
                                          item?.products?.length
                                        } Restaurantes`)}
                                </p>
                              }
                            >
                              <div
                                className={`cursorPointer intentionIcon ${
                                  item?.hideIcon && 'dNone'
                                }`}
                                onClick={() => {
                                  if (item.iconAction) {
                                    if (
                                      item?.buyIntention?.type === 'HISTORY' &&
                                      !item?.totalQuantity &&
                                      !item?.totalValue
                                    ) {
                                      setIconColor('gray');
                                    } else {
                                      setIconColor('blue');
                                    }
                                    item.lineProductGroupId &&
                                      setProductGroupId(
                                        item.lineProductGroupId
                                      );
                                    item.dataToDialog &&
                                      setDataDialog(item.dataToDialog);
                                    setModalStates &&
                                      setModalStates({
                                        ...modalStates,
                                        [item.iconAction]: true
                                      });
                                    item.productsIds &&
                                      setProductsIds(item.productsIds);
                                    setCurrentModalTitle &&
                                      setCurrentModalTitle(itens[2].content);
                                    setTableToModalProps &&
                                      setTableToModalProps(item);
                                    setDisabledApproveProduct &&
                                      setDisabledApproveProduct(
                                        itens[0].disabledMessage
                                      );
                                  }
                                }}
                              >
                                {item?.buyIntention?.type === 'HISTORY' &&
                                !item?.totalQuantity &&
                                !item?.totalValue ? (
                                  <Restore
                                    style={{ width: 24 }}
                                    className="#9296AC"
                                  />
                                ) : (
                                  statusIntention(
                                    item?.buyIntention?.type,
                                    item?.buyIntention?.otherType,
                                    true
                                  )
                                )}
                              </div>
                            </Tooltip>
                          )}

                          {Array.isArray(item.content) &&
                            item.action === 'editable' && (
                              <>
                                <InputNumber
                                  initialValue={item.content[0]}
                                  handleValue={handleTableValues}
                                  item={item}
                                  itens={itens}
                                  tableData={tableData}
                                  disableConvertion={item?.disableConvertion}
                                  size={item?.inputSize}
                                  validColor={item?.valid}
                                  fractionDigits={item.fractionDigits}
                                />
                                <ItemSelect
                                  productsIds={
                                    item.productsIds && item.productsIds
                                  }
                                  setProductsIds={setProductsIds}
                                  setModalStates={setModalStates}
                                  modalStates={modalStates}
                                  itemName={item.content[1]}
                                  action={item.secondAction}
                                  validColor={item?.valid}
                                  type={item.type}
                                  setCurrentModalTitle={setCurrentModalTitle}
                                  modalTitle={itens[1].content}
                                  tooltip={item.tooltip}
                                  buttonTooltip={item.buttonTooltip}
                                  itemLength={item.itemLength}
                                  buttonLinkColor={item.buttonLink}
                                  inputSize={item.inputSize}
                                  modalToTableProps={item}
                                  setTableToModalProps={setTableToModalProps}
                                  fractionDigits={item.fractionDigits}
                                  contentBlueCard={item.contentBlueCard}
                                  buttonRectColor={item.buttonRectColor}
                                  underline={item.underline}
                                  fontStyle={item.fontStyle}
                                  truncateOff={item.truncateOff}
                                  item={item}
                                  itens={itens}
                                />
                              </>
                            )}

                          {Array.isArray(item.content) &&
                            item.action !== 'editable' && (
                              <>
                                {item.horizontalAlignment && (
                                  <>
                                    <div
                                      style={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                      }}
                                    >
                                      <ItemSelect
                                        productsIds={
                                          item.productsIds && item.productsIds
                                        }
                                        setProductsIds={setProductsIds}
                                        setModalStates={setModalStates}
                                        modalStates={modalStates}
                                        itemName={item.content[0]}
                                        itemUnit={item.content[1]}
                                        action={item.secondAction}
                                        validColor={item?.valid}
                                        type={item.type}
                                        setCurrentModalTitle={
                                          setCurrentModalTitle
                                        }
                                        modalTitle={itens[1].content}
                                        tooltip={item.tooltip}
                                        buttonTooltip={item.buttonTooltip}
                                        itemLength={item.itemLength}
                                        buttonLinkColor={item.buttonLink}
                                        inputSize={item.inputSize}
                                        modalToTableProps={item}
                                        setTableToModalProps={
                                          setTableToModalProps
                                        }
                                        fractionDigits={item.fractionDigits}
                                        contentBlueCard={item.contentBlueCard}
                                        buttonRectColor={item.buttonRectColor}
                                        underline={item.underline}
                                        fontStyle={item.fontStyle}
                                        truncateOff={item.truncateOff}
                                        setDataDialog={setDataDialog}
                                        dataToDialog={item.dataToDialog}
                                        setDisabledApproveProduct={
                                          setDisabledApproveProduct
                                        }
                                        setCurrentProduct={setCurrentProduct}
                                        item={item}
                                        itens={itens}
                                      />
                                    </div>

                                    {/* {item.content[1] && (
                                      <div style={{ width: '30%' }}>
                                        <ItemSelect
                                          productsIds={
                                            item.productsIds && item.productsIds
                                          }
                                          setProductsIds={setProductsIds}
                                          setModalStates={setModalStates}
                                          modalStates={modalStates}
                                          itemName={item.content[1]}
                                          action={item.secondAction}
                                          validColor={item?.valid}
                                          type={item.type}
                                          setCurrentModalTitle={
                                            setCurrentModalTitle
                                          }
                                          modalTitle={itens[1].content}
                                          tooltip={item.tooltip}
                                          buttonTooltip={item.buttonTooltip}
                                          itemLength={item.itemLength}
                                          buttonLinkColor={item.buttonLink}
                                          inputSize={item.inputSize}
                                          modalToTableProps={item}
                                          setTableToModalProps={
                                            setTableToModalProps
                                          }
                                          fractionDigits={item.fractionDigits}
                                          secondContentGray={
                                            item.secondContentGray
                                          }
                                          contentBlueCard={item.contentBlueCard}
                                          buttonRectColor={item.buttonRectColor}
                                          underline={item.underline}
                                          fontStyle={item.fontStyleSecond}
                                          truncateOff={item.truncateOff}
                                          setDataDialog={setDataDialog}
                                          dataToDialog={item.dataToDialog}
                                          setDisabledApproveProduct={
                                            setDisabledApproveProduct
                                          }
                                          setCurrentProduct={setCurrentProduct}
                                          item={item}
                                          itens={itens}
                                        />
                                      </div>
                                    )} */}
                                  </>
                                )}

                                {!item.horizontalAlignment && (
                                  <>
                                    <ItemSelect
                                      productsIds={
                                        item.productsIds && item.productsIds
                                      }
                                      setProductsIds={setProductsIds}
                                      lineProductGroupId={
                                        item?.lineProductGroupId
                                      }
                                      setProductGroupId={setProductGroupId}
                                      setModalStates={setModalStates}
                                      modalStates={modalStates}
                                      itemName={item.content[0]}
                                      itemUnit={item.content[1]}
                                      action={item.secondAction}
                                      validColor={item?.valid}
                                      type={item.type}
                                      setCurrentModalTitle={
                                        setCurrentModalTitle
                                      }
                                      modalTitle={
                                        item?.secondAction === 'buyIntention'
                                          ? ''
                                          : itens[1].content
                                      }
                                      tooltip={item.tooltip}
                                      buttonTooltip={item.buttonTooltip}
                                      itemLength={item.itemLength}
                                      buttonLinkColor={item.buttonLink}
                                      inputSize={item.inputSize}
                                      modalToTableProps={item}
                                      setTableToModalProps={
                                        setTableToModalProps
                                      }
                                      fractionDigits={item.fractionDigits}
                                      contentBlueCard={item.contentBlueCard}
                                      buttonRectColor={item.buttonRectColor}
                                      underline={item.underline}
                                      fontStyle={item.fontStyle}
                                      truncateOff={item.truncateOff}
                                      setDataDialog={setDataDialog}
                                      dataToDialog={item.dataToDialog}
                                      item={item}
                                      itens={itens}
                                    />
                                    <ItemSelect
                                      productsIds={
                                        item.productsIds && item.productsIds
                                      }
                                      setProductsIds={setProductsIds}
                                      setProductGroupId={setProductGroupId}
                                      lineProductGroupId={
                                        item?.lineProductGroupId
                                      }
                                      setModalStates={setModalStates}
                                      modalStates={modalStates}
                                      itemName={item.content[1]}
                                      action={item.secondAction}
                                      validColor={item?.valid}
                                      type={item.type}
                                      setCurrentModalTitle={
                                        setCurrentModalTitle
                                      }
                                      modalTitle={
                                        item?.secondAction === 'buyIntention'
                                          ? ''
                                          : itens[1].content
                                      }
                                      tooltip={item.tooltip}
                                      buttonTooltip={item.buttonTooltip}
                                      itemLength={item.itemLength}
                                      buttonLinkColor={item.buttonLink}
                                      inputSize={item.inputSize}
                                      modalToTableProps={item}
                                      setTableToModalProps={
                                        setTableToModalProps
                                      }
                                      fractionDigits={item.fractionDigits}
                                      secondContentGray={item.secondContentGray}
                                      contentBlueCard={item.contentBlueCard}
                                      buttonRectColor={item.buttonRectColor}
                                      underline={item.underline}
                                      fontStyle={item.fontStyleSecond}
                                      truncateOff={item.truncateOff}
                                      setDataDialog={setDataDialog}
                                      dataToDialog={item.dataToDialog}
                                      item={item}
                                      itens={itens}
                                      width={item?.fontStyle?.container?.width}
                                    />
                                  </>
                                )}
                              </>
                            )}

                          {item?.bloomOptionsToEvaluate?.amount ? (
                            <Tooltip
                              TransitionComponent={Fade}
                              disableHoverListener={
                                !item?.bloomOptionsToEvaluate?.amount
                              }
                              TransitionProps={{ timeout: 600 }}
                              title={
                                item?.bloomOptionsToEvaluate?.tooltip || null
                              }
                            >
                              <div
                                className="dFlex flexColumn alignCenter justifyCenter pAbsolute"
                                style={{ right: 4, top: 8, width: 20 }}
                              >
                                <img
                                  src={BloomIcon}
                                  width="14px"
                                  alt="BloomIcon"
                                />
                                <span
                                  className="lightBlue bold"
                                  style={{ fontSize: 9, lineHeight: 1.4 }}
                                >
                                  +{item?.bloomOptionsToEvaluate?.amount}
                                </span>
                              </div>
                            </Tooltip>
                          ) : null}

                          {item.rightIcon &&
                            Array.isArray(item.rightIcon) &&
                            item.rightIcon.map(
                              (rightIcon) =>
                                rightIcon.show && (
                                  <RightIconTooltip
                                    productsIds={
                                      rightIcon.productsIds &&
                                      rightIcon.productsIds
                                    }
                                    setProductsIds={setProductsIds}
                                    setModalStates={setModalStates}
                                    modalStates={modalStates}
                                    description={rightIcon.tooltip}
                                    itemOption={rightIcon}
                                    action={rightIcon.action || null}
                                  />
                                )
                            )}

                          {item.rightIcon &&
                            !Array.isArray(item.rightIcon) &&
                            item.rightIcon.show && (
                              <RightIconTooltip
                                productsIds={
                                  item.productsIds && item.productsIds
                                }
                                setProductsIds={setProductsIds}
                                setModalStates={setModalStates}
                                modalStates={modalStates}
                                description={item.rightIcon.tooltip}
                                itemOption={item.rightIcon}
                                action={item.rightIcon.action || null}
                              />
                            )}
                        </InputWrapper>
                      </Tooltip>
                    )}

                    {item.type === 'image' && (
                      <ImageWrapper
                        url={item.content.image}
                        skuId={item.content.skuId}
                        skusImageUrls={item.content.images}
                        productsIds={item.productsIds && item.productsIds}
                        setProductsIds={setProductsIds}
                        setModalStates={setModalStates}
                        modalStates={modalStates}
                        action={item.action}
                        showOnlyImages={false}
                        solidBgColor="#FFF"
                        waterMarkWidth={15}
                        showWaterMark={item?.hasWaterMark}
                      />
                    )}

                    <SubLineContainer
                      borderRadius={
                        item.subLine?.fontStyle?.container?.borderRadius
                      }
                      boxShadow={item.subLine?.fontStyle?.container?.boxShadow}
                      width={item.subLine?.fontStyle?.container?.width}
                      height={item.subLine?.fontStyle?.container?.height}
                      marginTop={item.subLine?.fontStyle?.container?.marginTop}
                      marginLeft={
                        item.subLine?.fontStyle?.container?.marginLeft
                      }
                      marginRight={
                        item.subLine?.fontStyle?.container?.marginRight
                      }
                      padding={item.subLine?.fontStyle?.container?.padding}
                      rectStyles={
                        item.subLine?.fontStyle?.container?.rectStyles
                      }
                      invertOrder={invertPositions[item.label]}
                      onClick={() => {
                        if (
                          item?.subLine?.action === 'buyIntention' &&
                          item?.subLine?.buyIntention?.type !== 'HISTORY'
                        ) {
                          item?.subLine?.dataToDialog &&
                            setDataDialog(item?.subLine?.dataToDialog);
                          setModalStates &&
                            setModalStates({
                              ...modalStates,
                              [item?.subLine?.action]: true
                            });
                          item.lineProductGroupId &&
                            setProductGroupId(item.lineProductGroupId);
                          item?.subLine?.productsIds &&
                            setProductsIds(item?.subLine?.productsIds);
                          setCurrentModalTitle &&
                            setCurrentModalTitle(itens[2].content);
                          setTableToModalProps &&
                            setTableToModalProps(item?.subLine);
                        } else {
                          item?.subLine?.dataToDialog &&
                            setDataDialog(item?.subLine?.dataToDialog);

                          item.lineProductGroupId &&
                            setProductGroupId(item.lineProductGroupId);

                          setModalStates &&
                            !storedQuotationProductsSelected
                              ?.map((el) => el?.id)
                              .includes(item?.id) &&
                            setModalStates({
                              ...modalStates,
                              [item?.subLine?.action]: true
                            });

                          item?.subLine?.validateAction &&
                            !storedQuotationProductsSelected
                              ?.map((el) => el?.id)
                              .includes(item?.id) &&
                            dispatch(
                              handleProduct({
                                item
                              })
                            );

                          setModalStatesObj({
                            ...modalStatesObj,
                            [item?.subLine?.action]: {
                              open: item?.subLine.singleApproval
                                ? item?.subLine?.validateAction
                                : true,
                              singleApproval: item?.subLine?.singleApproval
                            }
                          });

                          item?.subLine?.productsIds &&
                            setProductsIds(item?.subLine?.productsIds);
                          setCurrentModalTitle &&
                            setCurrentModalTitle(item?.subLine?.modalTitle);
                          setTableToModalProps &&
                            setTableToModalProps(
                              item?.subLine?.modalToTableProps
                            );
                        }
                      }}
                      cursorPointer={item?.subLine?.action}
                      paragraphStyles={item?.subLine?.paragraphStyles}
                    >
                      {item?.subLine?.action === 'editable' && (
                        <InputNumber
                          initialValue={item.subLine.content}
                          handleValue={(targetValue) =>
                            handleInputValue(
                              item?.subLine?.productsIds[0],
                              item?.subLine?.restaurantIds[0],
                              targetValue,
                              item.subLine.modalToTableProps.productGroupId
                            )
                          }
                          fractionDigits="3"
                        />
                      )}
                      {item.subLine && item?.subLine?.action !== 'editable' && (
                        <SublineWrapper
                          color={item.subLine?.fontStyle?.color}
                          fontSize={item.subLine?.fontStyle?.fontSize}
                          textAlign={item.subLine?.fontStyle?.textAlign}
                          width={item.subLine?.fontStyle?.inputSize}
                          fontWeight={item.subLine?.fontStyle?.fontWeight}
                          marginTop={item.subLine?.fontStyle?.marginTop}
                          marginBottom={item.subLine?.fontStyle?.marginBottom}
                        >
                          {Array.isArray(item?.subLine?.content) ? (
                            <div
                              style={{ display: 'flex', width: 'max-content' }}
                            >
                              {item.subLine.tooltip ? (
                                <ShortenStringTooltip
                                  string={item?.subLine?.content[0]}
                                  length={30}
                                />
                              ) : (
                                <div
                                  className={`dFlex ${
                                    (
                                      item?.subLine.content[0] +
                                      item?.subLine?.content[1]
                                    ).length > 25 && 'flexColumn'
                                  }`}
                                >
                                  <div
                                    className="dFlex alignCenter"
                                    style={{
                                      color:
                                        item.subLine?.fontStyle?.color ||
                                        '#9296AC',
                                      fontSize:
                                        item.subLine?.fontStyle?.fontSize ||
                                        '9px'
                                    }}
                                  >
                                    {item?.subLine?.content[0]}
                                  </div>

                                  <div
                                    className="dFlex alignCenter"
                                    style={{
                                      color:
                                        item.subLine?.fontStyle?.secundColor ||
                                        '#0E1337',
                                      fontSize: '12px'
                                    }}
                                  >
                                    {ValidationLength(
                                      item?.subLine?.content[1],
                                      30
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            item.subLine.content
                          )}
                        </SublineWrapper>
                      )}
                    </SubLineContainer>
                  </TDContainer>
                </StyledTD>
              ))}
            </StyledTR>
          ))
        ) : (
          <tr>
            <th colSpan="13" style={{ margin: '10px', textAlign: 'center' }}>
              {erroMensagem}
            </th>
          </tr>
        )}
      </StyleTableBody>
    </StyledTable>
  );
}

export default QuotationManagerTable;
