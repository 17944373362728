const INITIAL = {}

export default function assortmentInfo(state = INITIAL, action) {
  switch (action.type) {
    case "SET_ASSORTMENT_DATA":
      return {
          action: "EDIT",
          data: action.data 
      }
      case "CREATE_BRANDS_DATA":
        return (
          {
            action: "CREATE",
            data: {}
          }
        )
    default:
      return state;
  }
}