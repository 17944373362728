import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { isValid } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { DateInputContainer } from './style';

function DateInput({ initialValue, disabled = false, handleValue = () => {} }) {
  const [inputValue, setInputValue] = useState(initialValue);
  const handleDateChange = (date) => {
    if (!isValid(date)) return;
    setInputValue(date);
    handleValue(date);
  };

  return (
    <DateInputContainer>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-input"
          format="dd/MM/yyyy"
          value={initialValue === 'undefined' ? null : initialValue}
          onChange={handleDateChange}
          style={{ width: '77px' }}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </DateInputContainer>
  );
}

export default DateInput;
