import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import format from 'date-fns/format';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/close-icon-white.svg';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

function DialogImportedNfeHistory({ openModal, handleCloseModal, originId }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [loading, setLoading] = useState(false);
  const [nfesHistory, setNfesHistory] = useState([]);

  const getNfesHistory = () => {
    setLoading(true);

    axios
      .get(`${environments.integration}/logs/${originId}`)
      .then((response) => {
        setNfesHistory(response?.data || []);
        setLoading(false);
      })
      .catch((error) => {
        setNfesHistory([]);
        setLoading(false);
        console.log(error);
      });
  };

  const statusInfos = (status, message) => {
    const statusObj = {
      statusColor: '',
      messageTranslate: ''
    };

    switch (status) {
      case 'ERROR':
        statusObj.statusColor = '#AA0816';
        break;
      case 'IN PROCCESS':
        statusObj.statusColor = '#808080';
        break;
      case 'SUCCESS':
        statusObj.statusColor = '#08AA15';
        break;
      default:
        statusObj.statusColor = '-';
    }

    switch (message) {
      case 'Invalid file format':
        statusObj.messageTranslate = 'Formato de arquivo inválido';
        break;
      case 'Cnpj not compatible with notes':
        statusObj.messageTranslate = 'Chave não pertence a Loja de origem ';
        break;
      case 'Error to send to proccessing':
        statusObj.messageTranslate = 'Erro no envio para processamento';
        break;
      case 'type of operation not pemited':
        statusObj.messageTranslate = 'Tipo de operção não permitida';
        break;
      case 'Sended To proccess with success':
        statusObj.messageTranslate = 'Enviado para Processamento';
        break;
      case 'waiting for processing':
        statusObj.messageTranslate = 'Aguardando Processamento';
        break;
      case 'Integrated with success':
        statusObj.messageTranslate = 'Importado com sucesso';
        break;
      case 'Imported whith success':
        statusObj.messageTranslate = 'Importado com sucesso';
        break;
      case 'key already successfully integrated':
        statusObj.messageTranslate = 'Nota já importada';
        break;
      default:
        statusObj.messageTranslate = '-';
    }

    return statusObj;
  };

  useEffect(() => {
    getNfesHistory();
  }, []);

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={handleCloseModal}
      className="defaultModal importedNfeHistoryDialog"
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
        fullWidth
      >
        <Typography>
          {`NOTAS INTEGRADAS EM ${format(new Date(), 'dd/MM/yy')}`}
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={handleCloseModal}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable bgWhite dFlex flexColumn pb0">
        <table className="desktop">
          <thead className="headerTitle">
            <tr>
              <th
                width="170px"
                className="subtitle"
                style={{ padding: '4px 4px 4px 16px' }}
              >
                DATA
              </th>

              <th width="340px" className="subtitle" style={{ padding: '4px' }}>
                CHAVE
              </th>

              <th width="90px" className="subtitle center" style={{ padding: '4px' }}>
                NÚMERO
              </th>

              <th
                width="255px"
                className="subtitle textAlignLeft"
                style={{ padding: '4px 16px 4px 4px' }}
              >
                STATUS
              </th>
            </tr>
          </thead>

          <tbody>
            {nfesHistory?.length ? (
              nfesHistory
                ?.sort((a, b) => (a?.emitDate < b?.emitDate ? 1 : -1))
                ?.map((nfe) => (
                  <>
                    <tr>
                      <td
                        width="170px"
                        className="description fontSizeDefault textAlignLeft"
                        style={{
                          fontFamily: 'Lato',
                          padding: '4px 4px 4px 16px',
                          color: '#0F1225'
                        }}
                      >
                        {nfe?.emitDate
                          ? `${format(
                              new Date(nfe.emitDate).valueOf() +
                                new Date(nfe.emitDate).getTimezoneOffset() *
                                  60 *
                                  1000,
                              "dd/MM/yyyy - HH:mm'h'"
                            )}`
                          : '-'}
                      </td>

                      <td colSpan={3} className="p0">
                          <div className="dFlex alignCenter">
                            <td
                              width="340px"
                              className="description fontSizeDefault textAlignLeft"
                              style={{
                                fontFamily: 'Lato',
                                padding: '4px',
                                color: '#0F1225'
                              }}
                            >
                              {ValidationLength(nfe?.chnfe, 50)}
                            </td>

                            <td
                              width="90px"
                              className="description fontSizeDefault center"
                              style={{
                                fontFamily: 'Lato',
                                padding: '4px',
                                color: '#0F1225'
                              }}
                            >
                              {nfe?.nnf ? nfe?.nnf : '-'}
                            </td>

                            <td
                              width="255px"
                              className="description fontSizeDefault textAlignLeft"
                              style={{
                                fontFamily: 'Lato',
                                padding: '4px 16px 4px 4px',
                                color: statusInfos(nfe.status, '')?.statusColor
                              }}
                            >
                              {statusInfos('', nfe.message)?.messageTranslate}
                            </td>
                          </div>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="4" className="p0">
                        <hr
                          className="titleLineBorder"
                          style={{ margin: '0', height: '0' }}
                        />
                      </td>
                    </tr>
                  </>
                ))
            ) : (
              <tr>
                <td colSpan="4" className="description center">
                  <Typography style={{ margin: '5px', textAlign: 'center' }}>
                    Nenhuma informação encontrada.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {loading && <LinearProgress variant="query" />}
      </DialogContent>
    </Dialog>
  );
}

export default DialogImportedNfeHistory;
