import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Tooltip,
  Fade
} from '@material-ui/core';

import Button from 'components/Button/button';
import FullScreenLoading from 'components/FullScreenLoading';
import ImageWrapper from 'components/ImageWrapper';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/close-icon-white.svg';
import numberToReal from 'utils/numberToReal';

function ValidationLength(data, charactersCount) {
  if (data && data.length > charactersCount) {
    return (
      <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={data}
      >
        <span className="content-data">
          {`${data.substring(0, charactersCount)}...`}
        </span>
      </Tooltip>
    );
  }
  return <span className="content-data">{data}</span>;
}

const removeRepeated = ({ array, param, ignoreNull = false }) => {
  const arr = [];

  array.filter((item) => {
    const i = arr.findIndex((x) => x[`${param}`] == item[`${param}`]);

    if (ignoreNull) {
      if (i <= -1 || !item[`${param}`]) {
        arr.push(item);
      }
    } else if (i <= -1) {
      arr.push(item);
    }
  });

  return arr;
};

function SkuPreview({
  openModal,
  handleClose,
  currentSkuData = {},
  selectedStores,
  handleSelectedSku,
  handleAssociateSkuToRestaurants,
  loading,
  productOptions,
  setUpdateMainList = () => {}
}) {
  const [onSave, setOnSave] = useState(false);
  const providers = currentSkuData?.providers?.length
    ? removeRepeated({
        array: currentSkuData?.providers,
        param: 'name',
        ignoreNull: true
      })
    : [];

  useEffect(() => {
    const listener = (event) => {
      if (
        (event.code === 'Enter' || event.code === 'NumpadEnter') &&
        selectedStores?.filter(
          (item) =>
            !currentSkuData.restaurantsIds
              ?.split(',')
              ?.map((el) => parseInt(el))
              .includes(item._id)
        ).length
      ) {
        event.preventDefault();
        handleAssociateSkuToRestaurants(true);
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [currentSkuData, selectedStores]);

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={handleClose}
      className="defaultModal skuPreviewDialog"
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue textAlignCenter cursorMove bold"
        id="draggable-dialog-title"
        fullWidth
      >
        <Typography style={{ fontSize: 10 }}>SKU CATALOGADO</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent p0">
        <section className="defaultSkuInfoBox">
          <p className="boxTitle mb0">PRODUTO</p>

          <div className="dFlex boxItem">
            <span className="itemLabel">DESCRIÇÃO:</span>

            <span className="itemContent">
              {ValidationLength(
                currentSkuData?.products?.length
                  ? currentSkuData?.products[0]?.products?.description
                  : '-',
                35
              )}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">UNID. PRIM.:</span>

            <span className="itemContent" style={{ width: '10%' }}>
              {(currentSkuData?.products?.length &&
                currentSkuData?.products[0]?.products?.consumptionUnitsPrimary?.abbreviation?.toUpperCase()) ||
                '-'}
            </span>

            <span className="itemLabel">UNID. SEC.:</span>

            <span className="itemContent">
              {(currentSkuData?.products?.length &&
                currentSkuData?.products[0]?.products?.consumptionUnitsSecondary?.abbreviation?.toUpperCase()) ||
                '-'}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">CATEGORIA:</span>

            <span className="itemContent">
              {(currentSkuData?.products?.length &&
                currentSkuData?.products[0]?.products?.categories
                  ?.description) ||
                '-'}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">SKUS ASSOC.:</span>

            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              disableHoverListener={!currentSkuData?.products?.length}
              title={
                currentSkuData?.products?.length &&
                productOptions
                  .find(
                    (item) =>
                      item.id === currentSkuData?.products[0]?.products?.id
                  )
                  ?.skus?.map((sku) => (
                    <p className="mb0" style={{ padding: '4px 0px' }}>
                      {sku?.skus?.description}
                    </p>
                  ))
              }
            >
              <span className="itemContent">
                {(currentSkuData?.products?.length &&
                  productOptions.find(
                    (item) =>
                      item.id === currentSkuData?.products[0]?.products?.id
                  )?.skus?.length) ||
                  '-'}
                {currentSkuData?.products?.length &&
                productOptions.find(
                  (item) =>
                    item.id === currentSkuData?.products[0]?.products?.id
                )?.skus?.length === 1
                  ? ' SKU'
                  : ' SKUS'}
              </span>
            </Tooltip>
          </div>
        </section>

        <section className="defaultSkuInfoBox">
          <p className="boxTitle mb0">SKU</p>

          <div className="dFlex boxItem">
            <span className="itemLabel">EAN:</span>

            <span className="itemContent">{currentSkuData?.ean}</span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">DESCRIÇÃO:</span>

            <span className="itemContent">
              {ValidationLength(currentSkuData?.description, 35)}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">QTDE/UN:</span>

            <span className="itemContent">
              {`${
                currentSkuData?.quantity
              } ${currentSkuData?.unitsMeasurements?.abbreviation.toUpperCase()}`}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">TIPO EMB.:</span>

            <span className="itemContent">
              {currentSkuData?.typePackages?.description}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">MARCA:</span>

            <span className="itemContent">
              {currentSkuData?.brands?.description}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">FORNEC.:</span>

            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={providers?.map((provider) => (
                <p className="mb0" style={{ padding: '4px 0px' }}>
                  {provider.name ? provider.name : '-'}
                </p>
              ))}
            >
              <span className="itemContent">
                {currentSkuData?.providers?.length === 1
                  ? '1 CADASTRADO'
                  : `${currentSkuData?.providers?.length || 0} CADASTRADOS`}
              </span>
            </Tooltip>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">PREÇO MÉDIO:</span>

            <span className="itemContent withBg">
              {currentSkuData?.providers?.length
                ? `R$: ${numberToReal(
                    (currentSkuData?.providers?.length &&
                      currentSkuData.providers[0].purchaseAverageUnitary) ||
                      0,
                    3
                  )}`
                : '-'}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">ÚLT. PREÇO:</span>

            <span className="itemContent withBg">
              {currentSkuData?.providers?.length
                ? `R$: ${numberToReal(
                    (currentSkuData?.providers?.length &&
                      currentSkuData.providers[0].purchaseLastUnitary) ||
                      0,
                    3
                  )}`
                : '-'}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">CONVERSÃO:</span>

            <span className="itemContent withBg">
              {currentSkuData?.providers?.length
                ? `R$: ${numberToReal(
                    (currentSkuData?.products?.length &&
                      currentSkuData.products[0].conversion) ||
                      0,
                    3
                  )}`
                : '-'}
            </span>
          </div>

          <div className="dFlex boxItem">
            <span className="itemLabel">UN. CONSUMO:</span>

            <span className="itemContent withBg">
              {currentSkuData?.providers?.length
                ? `R$: ${numberToReal(
                    (currentSkuData?.products?.length &&
                      currentSkuData.products[0].priceUc) ||
                      0,
                    3
                  )}`
                : '-'}
            </span>
          </div>

          <div className="skuImgBox">
            <ImageWrapper
              url={currentSkuData?.skuBloom?.images?.url}
              width="150px"
              height="150px"
            />
          </div>
        </section>

        <div className="buttonActions dFlex justifyEnd alignCenter">
          <Button
            design="contained"
            className="defaultButton backButton fontSizeDefault mr10"
            onClick={() => {
              handleClose();
            }}
            label="Cancelar"
          />

          <Button
            design="contained"
            className="defaultButton secondActionButton fontSizeDefault mr10"
            onClick={() => {
              handleSelectedSku(
                currentSkuData,
                selectedStores?.filter(
                  (item) =>
                    !currentSkuData.restaurantsIds
                      ?.split(',')
                      ?.map((el) => parseInt(el))
                      .includes(item._id)
                ).length
              );
            }}
            label="Editar"
          />

          {selectedStores?.filter(
            (item) =>
              !currentSkuData.restaurantsIds
                ?.split(',')
                ?.map((el) => parseInt(el))
                .includes(item._id)
          ).length ? (
            <Button
              design="contained"
              disable={loading}
              className="defaultButton submitButton fontSizeDefault"
              onClick={() => {
                handleAssociateSkuToRestaurants(true);
              }}
              label={loading ? 'Associando...' : 'Associar'}
            />
          ) : null}
        </div>

        {onSave && (
          <FullScreenLoading description="Processando..." display={onSave} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default SkuPreview;
