import axios from 'axios';

const instance = axios.create();

instance.interceptors.response.use(
  (response) => {
    const version = localStorage.getItem('version');
    const listVersion = version?.length > 7 ? JSON?.parse(version) : version
    const versionManufactures = version?.length > 7 ? listVersion?.manufactures_version : version
    const versionValidate = versionManufactures > 4 ? JSON?.parse(versionManufactures)?.length : JSON?.parse(version)?.length

    const currentVersion = response?.headers?.version;
    const objectVersions = {
      catalog_version: listVersion?.catalog_version,
      auth_version: listVersion?.auth_version,
      orders_version: listVersion?.orders_version,
      manufactures_version: response?.headers?.version,
      providers_version: listVersion?.providers_version,
      restaurants_version: listVersion?.restaurants_version,
      cache_version: listVersion?.cache_version,
    }

    if (!versionManufactures || versionManufactures !== currentVersion) {
      localStorage.setItem('version', JSON.stringify(objectVersions));
    }

    if (
      versionValidate &&
      versionManufactures !== currentVersion &&
      versionManufactures !== null &&
      versionManufactures !== 'undefined' &&
      currentVersion !== undefined
    ) {
      location.reload(true);
    }

    return response;
  },
  (error) => Promise.reject(error)
);

export default instance;
