import React, { useState } from "react"
import { Tooltip } from "@material-ui/core"
import EditIcon from "images/icons/objects/pencils/editIcon.svg"
import ActionPlan from "components/Dialogs/ActionPlanDailySchedule/actionPlan"

function ReasonsTable({
    products,
    isTopTable,
    restaurantId,
    userInfo,
    getReportInfos,
    type
}) {

    const [open, setOpen] = useState(false)
    const [product, setProduct] = useState({})
    const isCount = type === "COUNT"

    const handleClickOpenActionPlan = () => {
        setOpen(true)
    }

    const handleCloseActionPlan = () => {
        setOpen(false)
    }

    const validLength = (value, length) => {
        if (String(value).length > length) {
            return `${String(value).substring(0, length)}...`
        }

        return value
    }

    const setLineHeight = (index) => {
        let lineHeight = "26px"

        if (isCount) {
            if (index === 0) {
                lineHeight = "26px"
            } else {
                lineHeight = "22.156px"
            }
        } else {
            if (index === 0) {
                lineHeight = "39px"
            } else {
                lineHeight = "40.156px"
            }
        }

        return lineHeight
    }

    return (
        <>
            {
                products && products.length ? (
                    <div
                        style={{ width: "38%" }}
                        className={isTopTable ? "marginReasonsTableTop" : "marginReasonsTableBottom"}
                    >
                        <div className={isTopTable ? "headerSubtitleReasonsTop" : "headerSubtitleReasonsBottom pRelative"}>
                            <div className="subheader">
                                <th
                                    width="40.2%"
                                    className="subtitle"
                                >
                                    MOTIVO{" "}
                                </th>

                                <th
                                    width="59.8%"
                                    className="subtitle lineLeft"
                                >
                                    PLANO DE AÇÃO{" "}
                                </th>
                            </div>
                        </div>

                        <table
                            className={isTopTable ? "tableNegative" : "tableReasonsPositive"}
                            width="100%"
                            cellPadding="0"
                            cellSpacing="0"
                            border="0"
                        >
                            {/* <thead>
                                <tr className="headerTitle">
                                    <th className="subtitle" colSpan={12}>
                                        &nbsp;
                                    </th>
                                </tr>
                            </thead> */}

                            <tbody>
                                {products.slice(0, 5).map((item, index) => (
                                    <>
                                        <tr className="list">
                                            <td width="224px" className="pb0" style={{ lineHeight: setLineHeight(index), fontSize: "12px" }}>
                                                {
                                                    isCount ?
                                                        item?.countReason?.reason ? `${validLength(item.countReason.reason, 30)}` : "-"
                                                        :
                                                        item?.reason?.reason ? `${validLength(item.reason.reason, 30)}` : "-"
                                                }
                                            </td>
                                            <td width="305px" className="pb0 lineLeft" style={{ lineHeight: setLineHeight(index), fontSize: "12px" }}>
                                                {
                                                    isCount ?
                                                        item?.countReason?.actionPlan ? `${validLength(item.countReason.actionPlan, 40)}` : "-"
                                                        :
                                                        item?.reason?.actionPlan ? `${validLength(item.reason.actionPlan, 40)}` : "-"
                                                }
                                            </td>
                                            <td width="10px" className="pb0 lineLeft" style={{ lineHeight: setLineHeight(index), fontSize: "12px" }}>
                                                <Tooltip
                                                    placement="bottom"
                                                    title="Cadastro de Justificativa e Plano de ação"
                                                >
                                                    <img
                                                        onClick={() => {
                                                            setProduct(item)
                                                            handleClickOpenActionPlan()
                                                        }}
                                                        style={{ cursor: "pointer", marginRight: "-10px" }}
                                                        src={EditIcon}
                                                        alt="edit"
                                                    />
                                                </Tooltip>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan="7">
                                                <hr className="m0" />
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </table>

                        <ActionPlan
                            open={open}
                            close={handleCloseActionPlan}
                            product={product}
                            editMode={(product?.countReason && product?.countReason?.id) || (product?.reason && product?.reason?._id) ? true : false}
                            restaurantId={restaurantId}
                            userInfo={userInfo}
                            getReportInfos={getReportInfos}
                            type={type}
                        ></ActionPlan>

                    </div>
                )
                    : null
            }
        </>
    )
}

export default ReasonsTable