import numberToReal from 'utils/numberToReal';

const calcTotalCountValue = ({ cost = 0, real = 0 }) => {
    const parsedCost = parseFloat(Number(cost));
    const parsedReal = parseFloat(Number(real));

    return numberToReal(parsedCost * parsedReal);
}

export default calcTotalCountValue;
