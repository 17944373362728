/* eslint-disable no-nested-ternary */
import React from 'react';

import ItemSelect from 'components/BloomTable/components/ItemSelect';
import DateInput from 'components/Inputs/DateInput';
import InputNumber from 'components/Inputs/InputNumber';
import RightIconTooltip from 'components/RightIconTooltip';
import SelectItemList from 'components/SelectItemList';
import { ShortenStringTooltip } from 'utils/shortenString';

import { StyledTable, StyledTH, StyledTD, StyledTR } from './style';

function Smalltable({
  tableData,
  grayHeader = false,
  setTableData = () => {},
  setTableEditData = () => {}
}) {
  const handleChangeTableValue = (value, item, table, itens) => {
    if (!item) return;
    item.name = item?.type === 'number' ? parseFloat(value) : value;

    if (item.relation) {
      const nextModifier = itens.find(
        (el) => el.relation === item.relation && el.key !== item.key
      );
      nextModifier.name = nextModifier.editableRelationFunc(
        nextModifier.relationParam,
        value
      );
      setTableData(table);
      setTableEditData(true);
      return;
    }
    setTableData(table);
    setTableEditData(true);
  };

  return (
    <>
      <StyledTable grayHeader={grayHeader}>
        <thead>
          <StyledTR
            backGroundColor={grayHeader ? ' #F2F2F4;' : '#313347'}
            grayHeader={grayHeader}
          >
            {tableData?.header?.map((data, index) => (
              <StyledTH
                width={data.size ? data.size : index === 0 ? '40%' : 'auto'}
                grayHeader={grayHeader}
                paddingLeft={data.paddingLeft}
              >
                {data?.name}
              </StyledTH>
            ))}
          </StyledTR>
        </thead>

        <tbody>
          {tableData?.collunms?.map((itens) => (
            <StyledTR withLine>
              {itens?.map((item) => (
                <StyledTD grayHeader={grayHeader} alignCenter={item.iconField}>
                  {item.action === 'selectItem' && (
                    <SelectItemList
                      selectOption={item.selected}
                      item={item}
                      tableData={tableData}
                      setTableData={setTableData}
                    />
                  )}

                  {item.leftIcon &&
                    Array.isArray(item.leftIcon) &&
                    item.leftIcon.map((IconItens) => (
                      <RightIconTooltip description="" itemOption={IconItens} />
                    ))
                  }

                  {item.leftIcon && !Array.isArray(item.leftIcon) && (
                    <RightIconTooltip
                      description=""
                      itemOption={item.leftIcon}
                    />
                  )}

                  {item?.name || item?.name === 0 ? (
                    <>
                      {(item?.action === 'editable' && item?.name) ||
                      item?.name === 0 ? (
                        <>
                          {item?.action === 'editable' && (
                            <>
                              {item?.type === 'number' && (
                                <InputNumber
                                  initialValue={item?.name}
                                  backgroundColor={item.color}
                                  handleValue={handleChangeTableValue}
                                  item={item}
                                  tableData={tableData}
                                  itens={itens}
                                  fractionDigits={2}
                                />
                              )}

                              {item?.type === 'date' && (
                                <DateInput
                                  initialValue={item?.name}
                                  handleValue={handleChangeTableValue}
                                  item={itens}
                                  tableData={tableData}
                                  disabled={item.disable}
                                />
                              )}
                            </>
                          )}

                          {/* {item.type === 'richNumber' && (
                            <ItemSelect
                              itemName={item?.name}
                              action={item?.action}
                              validColor={item?.valid}
                              type={item.type}
                              tooltip={item?.tooltip}
                              buttonLinkColor={item?.buttonLinkColor}
                              fractionDigits={2}
                            />
                          )} */}

                          {item.action !== 'editable' &&
                            item.type !== 'richNumber' && (
                              <ShortenStringTooltip
                                string={item?.name}
                                length={item.length}
                              />
                            )}
                        </>
                      ) : item.iconField ? (
                        <RightIconTooltip
                          description=""
                          itemOption={item.iconField}
                        />
                      ) : (
                        ''
                      )}
                      
                      {item?.action !== 'editable' &&
                        item?.type !== 'richNumber' && (
                          <ShortenStringTooltip
                            string={item?.name}
                            length={32}
                          />
                        )
                      }

                      {item?.type === 'richNumber' && (
                        <div style={{ textAlign: 'right' }}>
                          <ItemSelect
                            itemName={parseInt(item?.name, 10)}
                            action={item?.action}
                            validColor={item?.valid}
                            type={item.type}
                            tooltip={item?.tooltip}
                            buttonLinkColor={item?.buttonLinkColor}
                            fractionDigits={2}
                          />
                        </div>
                      )}
                    </>
                  ) : item.iconField ? (
                    <RightIconTooltip
                      description={item.tooltipDescription}
                      itemOption={item.iconField}
                    />
                  ) : (
                    ''
                  )}
                </StyledTD>
              ))}
            </StyledTR>
          ))}
        </tbody>
      </StyledTable>
      
      {tableData?.collunms?.length == 0 && (
        <div style={{ textAlign: 'center' }}>Cotações não encontradas</div>
      )}
    </>
  );
}

export default Smalltable;
