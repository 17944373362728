/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import Layout from 'containers/layouts/layout';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import OperatingExpensesTable from './OperatingExpensesTable';

function OperatingExpenses({ userInfo }) {
  const { id: paramsID } = useParams();
  const [getId, setId] = useState(paramsID);
  const [filters] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [operatingExpenses, setOperatingExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const environments = Environment(process.env.REACT_APP_ENV);
  const actualDate = new Date();
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Contagem',
        filterName: 'periodicityId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/periodicities`,
        key: 'periodicityId',
        optionReference: 'description',
        size: 'medium',
        options: []
      },
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'initialDate',
        filterNameSecond: 'finalDate',
        initialDate: actualDate.setDate(actualDate.getDate() - 7),
        endDate: new Date()
      }
    ],
    []
  ]);

  const getOperatingExpenses = (filters, setData) => {
    if (filters.length < 3 || !filters.length) return;
    const params = filters.map((filter) => filter.urlParam).join('&');
    setLoading(true);
    axios
      .get(
        `${environments.catalog}/operatingExpenses?size=1000&page=1&originId=${getId}&${params}`
      )
      .then((response) => {
        const { data: { content } = {} } = response;
        if (!content) return;
        setData(content);
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getOperatingExpenses(filtersChanged, setOperatingExpenses);
  }, [filters, getId, filtersChanged]);

  useEffect(() => {
    setId(paramsID);
  }, [paramsID]);

  const handleFilter = (param, value) => {
    handleFilterOptions(param, value, filtersChanged, setFiltersChanged);
  };

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged
    );
  };

  const columns = useMemo(() => [
    {
      id: 'expander',
      Header: 'CATEGORIA',
      className: 'defaultHeader category',
      columns: [
        {
          Header: '',
          accessor: 'category',
          className: 'defaultSubtitle',
          Cell: ({ row }) => {
            if (row.original.family) {
              return (
                <Grid container spacing={1} alignItems="center">
                  <Grid
                    container
                    item
                    xs={12}
                    justify="flex-start"
                    className="p0"
                  >
                    <span
                      {...row.getToggleRowExpandedProps({
                        style: {
                          paddingLeft: `${row.depth * 0.6}rem`
                        }
                      })}
                    >
                      {row.isExpanded ? <ExpandMore /> : <ChevronRight />}
                    </span>

                    <Typography
                      style={{
                        margin: 0,
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        textAlign: 'center',
                        padding: '3px 0'
                      }}
                    >
                      {row.original.description === 'Não categorizado'
                        ? row.original.description
                        : ValidationLength(row.original.description, 50)}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }

            return <span>&nbsp;</span>;
          }
        }
      ]
    },
    {
      Header: 'ITEM',
      className: 'defaultHeader items',
      columns: [
        {
          Header: '',
          accessor: 'itemDescription',
          className: 'defaultSubtitle p12',
          Cell: ({ row }) => {
            if (!row.original.family) {
              return (
                <Grid container spacing={1} alignItems="center">
                  <Grid container item xs={12} justify="flex-start">
                    <Typography
                      style={{
                        margin: 0,
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        textAlign: 'center',
                        padding: '3px 0'
                      }}
                    >
                      {row.original.description === 'Não categorizado'
                        ? row.original.description
                        : ValidationLength(row.original.description, 50)}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }
            return <span>&nbsp;</span>;
          }
        }
      ]
    },
    {
      Header: 'CONSUMO',
      className: 'defaultHeader consumption',
      columns: [
        {
          Header: '',
          accessor: 'consumptionDescription',
          className: 'defaultSubtitle right p12',
          Cell: ({ row }) => {
            const { subRows = [] } = row;
            const { original: { consumedPeriodicity } = {} } = row;
            if (!subRows.length) {
              return (
                <Grid container spacing={1} alignItems="center">
                  <Grid
                    container
                    item
                    xs={12}
                    justify="flex-start"
                    className="justifyEnd"
                  >
                    <Typography
                      style={{
                        margin: 0,
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        textAlign: 'right',
                        padding: '3px 0'
                      }}
                    >
                      {consumedPeriodicity
                        ? consumedPeriodicity.toLocaleString('pt-br', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          })
                        : '0,000'}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }
            return (
              <span>
                {consumedPeriodicity
                  ? consumedPeriodicity.toLocaleString('pt-br', {
                      minimumFractionDigits: 3,
                      maximumFractionDigits: 3
                    })
                  : '0,000'}
              </span>
            );
          }
        }
      ]
    }
  ]);

  return (
    <Layout>
      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Gastos Operacionais por Categoria"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          handleMultipleFilters={handleMultipleFiltersFunc}
          getFilterhandle={getFilterhandle}
        />

        <OperatingExpensesTable
          loading={loading}
          columns={columns}
          data={operatingExpenses}
          className="desktop menuListTable operatingExpensesTable"
        />
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(OperatingExpenses);
