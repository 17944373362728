import numberToReal from 'utils/numberToReal';

export const calcMkpValue = (salesAverageDiscount, costTotal) => {
    const averageString = salesAverageDiscount?.toString();
    const costTotalString = costTotal?.toString();

    if (!averageString || !costTotalString) return '0,000';

    const parsedSalesAverageDiscount = parseFloat(salesAverageDiscount);
    const parsedCostTotal = parseFloat(costTotal);

    const result = parsedSalesAverageDiscount / parsedCostTotal; 

    if (!isFinite(result) || isNaN(result)) return '0,000';

    return numberToReal(result);
}

export const calcCMVValue = (salesAverageDiscount, costTotal) => {
    const averageString = salesAverageDiscount?.toString();
    const costTotalString = costTotal?.toString();

    if (!averageString || !costTotalString) return '0,000';

    const parsedSalesAverageDiscount = parseFloat(salesAverageDiscount);
    const parsedCostTotal = parseFloat(costTotal);

    const result = (parsedCostTotal / parsedSalesAverageDiscount) * 100; 

    if (!isFinite(result) || isNaN(result)) return '0,000';

    return numberToReal(result);
}

export const calcMargin = (salesAverage, costTotal) => {
    const averageString = salesAverage?.toString();
    const costTotalString = costTotal?.toString();

    if (!averageString || !costTotalString) return '0,000';

    const parsedSalesAverage = parseFloat(salesAverage);
    const parsedCostTotal = parseFloat(costTotal);

    const result = parsedSalesAverage - parsedCostTotal; 

    if (!isFinite(result) || isNaN(result)) return '0,000';

    return numberToReal(result);
}
