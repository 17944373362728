import React, { useState } from 'react';

import {
  DialogTitle,
  IconButton,
  Typography,
  Modal,
  Popover
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'components/BloomForms/Button';
import AutoCompleteField from 'components/PageTitleAndFilter/AutoCompleteField/AutoCompleteField';
import InputFieldSearch from 'components/PageTitleAndFilter/InputFieldSearch/InputFiledSearch';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import makeGet from 'services/useFetch/makeGet';
import { Container } from 'styles/general';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2)
  }
}));

function RejectConfirmModal({ modalStates, handleOnclose, handleRejection }) {
  const classes = useStyles();
  const [justificationOptions, setJustificationOptions] = useState([]);
  const [justificationValue, setJustificationValue] = useState({});
  const [justificationDescription, setJustificationDescription] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMessage, setOpenMessage] = useState(false);

  const handleJustificationTypes = (
    urlPath,
    label,
    keyField,
    optionReference
  ) => {
    const optionsResponse = (data) => {
      setJustificationOptions(data.message ? [] : data);
    };
    makeGet(urlPath, [{}], optionsResponse);
  };

  const handleReject = (event) => {
    setAnchorEl(event.currentTarget);
    if (!justificationValue.id) {
      setOpenMessage(true);
      return;
    }
    handleRejection('REFUSED', justificationValue, justificationDescription);
  };

  const handleDescriptionValue = (filterName, value) => {
    setJustificationDescription(value);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMessage(false);
  };

  return (
    <Modal
      className="defaultModal modalRegisterContainer"
      open={modalStates}
      onClose={handleOnclose}
      aria-labelledby="Periodicity Modal"
    >
      <div className="defaultModal">
        <Container>
          <DialogTitle
            className="modalHeader navyBlue bold textAlignCenter cursorMove"
            style={{
              borderRadius: '4px 4px 0 0'
            }}
            id="draggable-dialog-title"
          >
            <Typography>Justificativa de rejeição</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleOnclose();
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>

          <div className="cardDefault pt16" style={{ borderRadius: '0' }}>
            <div style={{ display: 'flex' }}>
              <AutoCompleteField
                urlPath="justificationTypes"
                label="Tipo"
                placeHolder="digite aqui..."
                filterName="justification"
                handleFilter={(_, id, __) => {
                  const currentOption = justificationOptions.find(
                    ({ id: optionID }) => optionID === id
                  );
                  setJustificationValue(currentOption);
                }}
                size="medium"
                getFilterhandle={handleJustificationTypes}
                options={justificationOptions}
                defaultValue={justificationValue}
              />

              <InputFieldSearch
                label="Descrição"
                placeHolder="descrição de recusa"
                filterName="description"
                handleFilter={handleDescriptionValue}
                size="large"
                withoutIcon
              />
            </div>

            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                label="Cancelar"
                secondary
                onClick={() => handleOnclose()}
              />
              <Button
                label="Confirmar"
                redlink
                onClick={handleReject}
                id="comparationDialogRejectionConfirm"
              />
              <Popover
                id="popOverID"
                open={openMessage}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <Typography className={classes.typography}>
                  Preencha o tipo da rejeição, por favor.
                </Typography>
              </Popover>
            </div>
          </div>
        </Container>
      </div>
    </Modal>
  );
}

export default RejectConfirmModal;
