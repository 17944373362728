import styled from 'styled-components';

export const CloseIcon = styled.img`
    float: right;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const DialogPragraph = styled.p`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #333541;
`;
