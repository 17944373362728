/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  Typography,
  TextField,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Add,
  CheckOutlined,
  CloseOutlined,
  DeleteOutline
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { Tree } from 'shineout';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import SwitchField from 'components/PageTitleAndFilter/SwitchField/SwitchField';
import TreeSelectorFieldSquares from 'components/PageTitleAndFilter/TreeSelectorFieldSquares';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import SquareExpand from 'images/icons/signals/addsAndCreates/categoriesExpand.svg';
import SquareCollapsed from 'images/icons/signals/removesAndDeletes/categoriesCollapsed.svg';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as SquaresActions from 'store/actions/squares';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import CardItemSquare from './CardItemSquare';
import { SwitchContainer, SwitchDescription } from './styles';

function Squares({ userInfo }) {
  const [alertErrorDuplicated, setAlertErrorDuplicated] = useState(false);
  const closeMessage = () => setAlertErrorDuplicated(false);
  const { id: paramsID } = useParams();
  const profileType = userInfo.profile[0][0].name;
  const environments = Environment(process.env.REACT_APP_ENV);
  const BaseUrl = `${environments.catalog}/courts`;
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [defaultExpanded] = useState(['Delivery']);
  const [expandedTree, setExpandedTree] = useState([]);
  const [description, setDescription] = useState(null);
  const [buttonAddClick, setButtonAddClick] = useState(false);
  const [childId, setChildId] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [nodeClick, setNodeClick] = useState('');
  const [getId, setGetId] = useState(paramsID);
  const [addRootSquare, setAddRootSquare] = useState('');
  const [deleteHas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hiddenCards, setHiddenCards] = useState(false);
  const [squares, setSquares] = useState([]);
  const [toastMessage, setToastMessage] = useState({
    customDescription: '',
    description: '',
    status: ''
  });
  const [hasChange, setHasChange] = useState(false);
  const [user, setUser] = useState({
    groups: [],
    subgroups: [],
    stores: [],
  });

  const groupsOptions = [userInfo?.companies?.groups[0]];
  const [formatSubgroupOptions, setFormatSubgroupOptions] = useState(groupsOptions
    .map((el) => el?.subGroups).flat())
  const [formatStoreOptions, setFormatStoreOptions] = useState(groupsOptions
    .map((el) => el.subGroups)
    .flat()
    .map((item) => item.stores)
    .flat())
  const isGroupManage = getId?.includes(',');
  const currentIds = isGroupManage ? getId?.split(',') : getId

  const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: 275
    },

    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)'
    },

    title: {
      fontSize: '12px',
      fontWeight: 'bold',
      fontFamily: 'Lato',
      color: '#000'
    },

    buttonStyle: {
      display: 'inline-block',
      padding: '6px 4px',
      border: '1px solid #ccc'
    },

    margin: {
      margin: theme.spacing(1)
    },

    Alert: {
      width: '50%',
      height: '30px',
      backgroundColor: 'white',
      border: '1px solid black'
    },

    buttonRow: {
      paddingTop: '20px',
      marginLeft: '55px'
    },

    buttonRowChild: {
      paddingTop: '10px'
    },

    formControl: {
      marginTop: '10px',
      paddingLeft: '10px',
      minWidth: '200px'
    },

    selectEmpty: {
      marginTop: theme.spacing(1)
    },

    paper: {
      width: 200,
      height: 230,
      overflow: 'auto'
    },

    button: {
      margin: theme.spacing(0.5, 0)
    }
  }));

  const classes = useStyles();

  let arrayClickDelete;
  let findCard = false;

  const handleDeleteFlags = (node) => {
    if (node.hasCard) {
      deleteHas.push(`&updateCard=${node.hasCard}`);
    }

    if (node.parentId == null) {
      deleteHas.push(`&categoryNew=1&updateMenu=true`);
    } else {
      deleteHas.push(`&categoryNew=${node.parentId}&updateMenu=true`);
    }
  };

  const getSquares = useCallback(
    (customItems) => {
      (async () => {
        setLoading(true);
        const formattedIds = customItems ? customItems?.map(el => el?.id) : currentIds
        // const statusFilterValue = activeStatusFilter ? 'ACTIVE' : 'INACTIVE';

        const squaresApiSales = await axiosInstanceCatalog.get(
          `${environments.catalog}/courts?restaurantId=${formattedIds}`
        );
        const data = await squaresApiSales.data;
        setSquares(data.content);
        setLoading(false);
      })();
    },
    [getId]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  // adiciona praça filha
  const addNewObj = () => {
    axios
      .post(
        `${BaseUrl}?updateMenu=true`,
        {
          method: 'POST',
          restaurantId: isGroupManage ? user?.stores?.map(el => el?.id)[0] : getId,
          description,
          parentId: childId,
          user: userInfo.user
        },
        config
      )
      .then(() => {
        setToastMessage({
          customDescription: 'Praça criada com sucesso',
          description: '',
          status: 'success'
        });
        setDescription('');
        setTimeout(() => getSquares(), 300);
      })
      .catch((error) => {
          setToastMessage({
            customDescription: '',
            description: error?.response?.data?.message || error?.response?.data?.error,
            status: 'error'
          });
        });
  }

  const cancelAddObject = () => {
    setButtonAddClick(false);
  };

  // adiciona praça pai
  const addNewObjPai = () => {
    axios
      .post(
        BaseUrl,
        {
          method: 'POST',
          restaurantId: isGroupManage ? user?.stores?.map(el => el?.id)[0] : getId,
          description: addRootSquare,
          parentId: null,
          user: userInfo.user,
        },
        config
      )
      .then(() => {
        setToastMessage({
          customDescription: 'Praça criada com sucesso',
          description: '',
          status: 'success'
        });
        setAddRootSquare('');
        setTimeout(() => getSquares(), 300);
      })
      .catch((error) => {
        setToastMessage({
          customDescription: '',
          description: error?.response?.data?.message || error?.response?.data?.error,
          status: 'error'
        });
     
      });
  };

  // funcao para remover a praça
  const removeObj = (node) => {
    handleDeleteFlags(node);

    axios
      .delete(
        `${environments.catalog}/courts/${node.id}?originId=${currentIds}${deleteHas}`,
        config
      )
      .then(() => {
        setToastMessage({
          customDescription: 'Praça deletada com sucesso',
          description: '',
          status: 'success'
        });
        setTimeout(() => getSquares(), 300);
      })
      .catch((error) => {
        console.log(error);
        setToastMessage({
          customDescription: '',
          description: 'Description has been already registered',
          status: 'error'
        });
      });
    handleClose();
  };

  // funcao para mandar a edicao da praça para o back
  const updateData = (id, description, hasCmvGoal, goalCmv, lossPercentage) => {
    if (description !== '' && hasChange) {
      axios
        .put(
          `${environments.catalog}/courts/${id}`,
          {
            description,
            restaurantId: isGroupManage ? user?.stores?.map(el => el?.id)[0] : getId,
            lossPercentage: lossPercentage || 0,
            user: userInfo.user
          },
          config
        )
        .then(() => {
          setToastMessage({
            customDescription: 'Praça atualizada com sucesso',
            description: '',
            status: 'success'
          });
          setTimeout(() => getSquares(), 300);
        })
        .catch((error) => {
          setToastMessage({
            customDescription: '',
            description: error?.response?.data?.message || error?.response?.data?.error,
            status: 'error'
          });
        });
    }
  };

  // estados da praça filha
  const valuesChild = (node) => {
    setChildId(node.id);
    setButtonAddClick(true);
  };

  // funcao recursiva para contar quantas praças filhas existe, na pai
  let count;
  const countSquares = (node) => {
    count = node.children.length;
    node.children.forEach((obj) => {
      if (obj.children) {
        count = parseInt(count, 10) + parseInt(obj.children.length, 10);
      } else {
        countSquares(obj.children);
      }
    });
  };

  // funcao que procura se tem card cadastrado em alguma praça
  const searchTrue = (nodesearch) => {
    if (nodesearch.parent === true) {
      for (let i = 0; i < nodesearch.children.length; i++) {
        const children = nodesearch.children[i];
        if (children.hasCard === true) {
          findCard = true;
        }

        if (children.parent === true) {
          for (let a = 0; a < children.children.length; a++) {
            const children2 = children.children[a];
            if (children2.hasCard === true) {
              findCard = true;
            }

            if (children2.parent === true) {
              for (let b = 0; b < children2.children.length; b++) {
                const children3 = children2.children[b];
                if (children3.hasCard === true) {
                  findCard = true;
                }

                if (children3.parent === true) {
                  for (let c = 0; c < children3.children.length; c++) {
                    const children4 = children3.children[c];
                    if (children4.hasCard === true) {
                      findCard = true;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const deleteSquares = (node) => {
    countSquares(node);
    setNodeClick(node);
    arrayClickDelete = node;
    searchTrue(arrayClickDelete);

    if (
      node.children.length > 0 &&
      !node.hasCard
    ) {
      setMessage(
        ` A praça ${node.description} não está associada a nenhuma ficha mas possui ${count} praça(s) filha(s), deseja realmente deletar ? `
      );
    }

    if (
      node?.children?.length === 0 &&
      !node.hasCard
    ) {
      setMessage(
        `Deseja deletar a praça ${node.description}? Ela não possui praças filhas e também não está associada a nenhuma Ficha  `
      );
    }

    if (findCard === true) {
      setMessage(` A praça ${
        node.description
      } possui praça(s) filha(s) que tem
        ${findCard ? 'Fichas, ' : ''}
      que serão movidas para uma praça 'Arquivados', deseja realmente deletar ? `);
    }

    if (node.hasCard) {
      setMessage(` Deletar a praça
        ${node.description} irá remover a praça de
        ${node.hasCard ? 'Fichas, ' : ''}
        e será movido para uma praça "Arquivados",
        porém ela não tem praças filhas, deseja realmente deletar? `);
    }

    if (
      node.children.length > 0 &&
      node.hasCard
    ) {
      setMessage(` A praça ${
        node.description
      } possui ${count} praça(s) filha(s),
        irá remover a praça de
        ${node.hasCard ? 'Fichas, ' : ''}
        e será movido para uma praça "Arquivados",
        deseja realmente deletar? `);
    }

    handleClickOpen();
  };

  const isDisableAddOrderSquare =
    description === null || description === '' || false;

  const isDisableAddOrderSquareRoot =
    addRootSquare === null || addRootSquare === '' || false;

  const findSquareElement = (id) => {
    const currentSelectedSquare = document.getElementById(`item-${id}`);
    if (currentSelectedSquare) {
      const element = currentSelectedSquare;

      element.classList.remove('categorieOutlineNoColor');
      element.classList.add('categorieOutline');

      element.focus();
      setTimeout(() => {
        element.classList.remove('categorieOutline');
        element.classList.add('categorieOutlineNoColor');
      }, 2500);
    }
  };

  const handleSquareValue = (_, currentSquare, id) => {
    setHasChange(false)
    const newTree = [];
    const filterElementInSquare = (
      data,
      value,
      list = [],
      pastElement = []
    ) => {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const element = data[key];
          newTree.push(element);
          if (
            element.description.toLowerCase() === value.toLowerCase() &&
            element.id === id
          ) {
            list.push(element);

            const treePath = [...pastElement, ...list];
            const treeMap = treePath.map((item) => item.description);
            setExpandedTree(treeMap);
            setTimeout(() => {
              findSquareElement(id);
            }, 150);
          }

          filterElementInSquare(element.children, value, list, [
            ...[element],
            ...pastElement
          ]);
        }
      }
    };
    filterElementInSquare(squares, currentSquare);
  };

  // renderiza os objetos com os botoes edit, del, add
  const renderItem = (node) => (
    <div
      className={!!node.cards?.length && !!node.children?.length && 'teste'}
    >
      <TextField
        margin="dense"
        size="small"
        variant="outlined"
        defaultValue={node.description}
        inputProps={{ maxLength: 50 }}
        onChange={() => {
          setHasChange(true)
        }}
        onBlur={(e) => {
          updateData(
            node.id,
            e.target.value,
            node.hasCmvGoal,
            node.goalCmv,
            node.lossPercentage
          )
        }}
        style={{
          width:
            profileType !== 'admin'
              ? '180px'
              : '320px'
        }}
        id={`item-${node.id}`}
      />

      <span style={{ position: 'relative', top: '8px' }}>
        <IconButton
          aria-label="add"
          disabled={node.nivel === 15}
          onClick={() => valuesChild(node)}
          style={{ outline: 6 }}
        >
          <Add aria-label="add" fontSize="small" />
        </IconButton>

        <IconButton
          aria-label="delete"
          onClick={() => deleteSquares(node)}
          style={{ outline: 6 }}
        >
          <DeleteOutline aria-label="delete" fontSize="small" />
        </IconButton>
      </span>

      {buttonAddClick && childId === node.id ? (
        <div className="addItemContainer">
          <Grid
            style={{ marginLeft: '16px', paddingTop: 0 }}
            className={classes.buttonRowChild}
            container
            spacing={12}
          >
            <Grid>
              <TextField
                className="textFieldSmall"
                placeholder="Adicionar..."
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />

              <IconButton
                className="smallIcons"
                aria-label="check"
                onClick={() => addNewObj()}
                style={{ outline: 6 }}
                disabled={isDisableAddOrderSquare}
              >
                <CheckOutlined size="small" />
              </IconButton>

              <IconButton
                className="smallIcons"
                aria-label="cancel"
                onClick={() => cancelAddObject()}
                style={{ outline: 6 }}
              >
                <CloseOutlined size="small" />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      ) : null}

      {!!node.cards?.length && !hiddenCards && (
        <CardItemSquare
          node={node}
          getSquares={() => {
            getSquares();
          }}
          userInfo={userInfo}
        />
      )}
    </div>
  );

  // Origin Id
  useEffect(() => {
    setGetId(paramsID);
  }, [paramsID]);

  // praças
  useEffect(() => {
    const formattedIds = isGroupManage ? currentIds?.map(el => parseFloat(el)) : [getId]
    const selectedSubGroup = groupsOptions[0]?.subGroups.filter(el => el?.stores?.some(item => formattedIds.includes(item?.id)))
      
    if (isGroupManage) {
      setUser({
        ...user,
        groups: groupsOptions[0],
        subgroups: selectedSubGroup,
        stores: selectedSubGroup?.map(el => el?.stores)?.flat()
      });
    }

    getSquares(isGroupManage ? selectedSubGroup?.map(el => el?.stores)?.flat() : '');
  }, [getId]);

  return (
    <Layout>
      <TitleHead title="Praças cadastradas" />
      <GlobalStyle />

      <Container>
        {alertErrorDuplicated && (
          <Alert
            className="w100"
            color="danger"
            isOpen={alertErrorDuplicated}
            toggle={closeMessage}
          >
            Uma praça não pode ter o mesmo nome que a praça pai ou 2 de
            mesmo nível.
          </Alert>
        )}

        <div style={{ marginTop: 4, marginBottom: 3 }}>
          <PageTitleAndFilter title="Praças cadastradas" />
        </div>

        <Grid className="cardDefault dFlex flexColumn withoutInputs mb16">
          <div className="dFlex">
            <div style={{ width: '300px', marginLeft: '0' }}>
              <TreeSelectorFieldSquares
                label="Praças"
                filterName="courts"
                handleFilter={handleSquareValue}
                key={(node) => node?.id}
                size="extra_large"
                getFilterhandle={() => {}}
                options={[]}
                inputSizes={{
                  extra_large: 300
                }}
                onFormStyle
              />
            </div>

            <SwitchContainer>
              <div className="pRelative">
                <SwitchField
                  onChange={(isChecked) => {
                    setHiddenCards(isChecked);
                  }}
                  handleFilter={() => {}}
                  onFormStyle
                />
              </div>
              <SwitchDescription>Visualizar apenas praças</SwitchDescription>
            </SwitchContainer>
          </div>

          {isGroupManage ?
            <Grid className="m0 pt16" container xs={12} sm={12} spacing={4}>
              <Grid item xs={4} sm={4} className="pb5 pl0 pr0" style={{ maxWidth: '300px' }}>
                <Autocomplete
                  disabled
                  options={[groupsOptions]}
                  value={user?.groups}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    setUser({
                      ...user,
                      groups: value,
                      subgroups: [],
                      stores: []
                    });
                  }}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.name}
                    </Typography>
                  )}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="groups"
                      label="Grupos"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4} sm={4} className="pb5 pl0 pr0" style={{ maxWidth: '300px', marginLeft: 32 }}>
                <Autocomplete
                  key={Math.random()}
                  options={formatSubgroupOptions}
                  multiple
                  limitTags={2}
                  value={user?.subgroups}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value, reason, ) => {
                    const isRemoveOption = reason === "remove-option"

                    if (value?.length) {
                      const storesValue = isRemoveOption ? value?.map(el => el?.stores)?.flat() : user?.stores

                      setUser({
                        ...user,
                        subgroups: value,
                        stores: storesValue
                      });

                      setFormatStoreOptions(value?.map(el => el?.stores)?.flat())
                      getSquares(storesValue)
                    } else {
                      setUser({
                        ...user,
                        subgroups: [],
                        stores: []
                      });
                    }
                  }}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.name}
                    </Typography>
                  )}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="subgroups"
                      label="Subgrupos"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={4} sm={4} className="pb5 pl0 pr0" style={{ maxWidth: '300px', marginLeft: 32 }}>
                <Autocomplete
                  key={Math.random()}
                  options={formatStoreOptions}
                  multiple
                  limitTags={1}
                  value={user?.stores}
                  getOptionLabel={(option) => option?.name}
                  onChange={(_, value) => {
                    setUser({
                      ...user,
                      stores: value
                    });

                    getSquares(value)
                  }}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.name}
                    </Typography>
                  )}
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="restaurants"
                      label="Restaurantes"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          : null}
        </Grid>

        <Grid className="cardDefault withoutInputs pRelative">
          {loading && (
            <div style={{ margin: '10px' }}>
              <LinearProgress variant="query" />
            </div>
          )}

          <Tree
            data={squares}
            keygen={(node) => node.description}
            defaultExpanded={defaultExpanded}
            renderItem={renderItem}
            className="categoriesTree"
            expandIcons={[
              <img src={SquareExpand} alt="expandIcon" />,
              <img src={SquareCollapsed} alt="collapse" />
            ]}
            expanded={expandedTree}
          />

          <Grid container spacing={12}>
            <Grid
              className={`fixedRootCategories ${
                !squares?.length && 'noLines'
              }`}
            >
              <TextField
                className="textFieldSmall"
                placeholder="Adicionar..."
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                value={addRootSquare}
                InputLabelProps={{ shrink: true }}
                required
                onChange={(e) => {
                  setAddRootSquare(e.target.value);
                }}
              />

              <IconButton
                className="smallIcons"
                aria-label="check"
                onClick={addNewObjPai}
                style={{ outline: 6 }}
                disabled={isDisableAddOrderSquareRoot}
              >
                <CheckOutlined size="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography
              className="bold textAlignCenter"
              style={{
                fontSize: '18px'
              }}
            >
              {message}
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter pb20">
            <Button
              className="defaultButton backButton"
              onClick={handleClose}
              style={{ outline: 0 }}
              color="primary"
            >
              Cancelar
            </Button>

            <Button
              className="defaultButton submitButton"
              onClick={() => removeObj(nodeClick)}
              style={{ outline: 0 }}
              color="primary"
              autoFocus
            >
              Deletar
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
      
      <CommonAlert
        severity={toastMessage?.status}
        open={toastMessage?.description || toastMessage?.customDescription }
        onClose={() => {
          setToastMessage({
            customDescription: '',
            description: '',
            status: ''
          });
        }}
        messagePersonal={toastMessage?.description || toastMessage?.customDescription }
        indexMessage={toastMessage?.description || toastMessage?.customDescription }
        width="50%"
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(SquaresActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Squares);
