import styled from 'styled-components';

export const Wrapper = styled.table`
  table-layout: fixed;
  width: 100%;
  border: 0;
`;

export const TableHeader = styled.thead`
  padding: 0 35px;

  tr {
    th:first-of-type {
      border-top-left-radius: initial !important;
      padding-left: 10px;
    }

    th:last-of-type {
      border-top-right-radius: initial !important;
    }
  }

  td,
  th {
    background: #333541 !important;
    color: #fff;
    font-size: 9px !important;

    span {
      img {
        width: 9px;
      }
    }
  }

  th {
    z-index: 9;
    position: sticky;
    top: 66px;

    .modalButton {
      padding: 0;
      margin-left: 10px;
    }

    .pendentImg {
      width: 15px !important;
    }
  }
`;

export const StyledTBody = styled.tbody`
  td {
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"] {
      padding-bottom: 7px;
    }
  }
`;