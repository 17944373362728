import styled, {css} from 'styled-components';

export const DialogContentWrapper = styled.div`
    padding: 12px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    button {
        width: 104px;
        height: 32px;
    }
`;

export const FileListName = styled.span`
    color: #333541;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    margin-right: 14px;
`;

export const FileListStatus = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: ${({color}) => color};
`; 

export const StyledButton = styled.button`
    border: none;
    outline: none;
    width: 104px;
    height: 32px;
    background-color: ${({disableButton}) => disableButton ? '#D4D6E5' : '#5062F0'};
    color: ${({disableButton}) => disableButton ? '#8387A1' : '#FFFFFF'};
    border-radius: 8px;
`;
