/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
`;

const BlueCircle = styled(Circle)`
  background-color: #5062f0;
`;

const GrayCircle = styled(Circle)`
  background-color: #a8a8a8;
`;

export default function hasMainTags(
  product,
  setDialogTagsSettings,
  dialogTagsSettings
) {
  let tooltip;
  let circle;

  if (product.tagComparable) {
    tooltip = 'O produto possui tags comparáveis';
    circle = <BlueCircle />;
  } else {
    tooltip = 'Esse produto não possui tags comparáveis';
    circle = <GrayCircle />;
  }

  return (
    <Tooltip title={tooltip} aria-label="add">
      <Link
        onClick={() => {
          setDialogTagsSettings({
            ...dialogTagsSettings,
            open: true,
            productName: product.description,
            productId: product.id
          });
        }}
        className="dFlex alignCenter justifyCenter"
      >
        {circle}
      </Link>
    </Tooltip>
  );
}
