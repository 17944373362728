/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { LinearProgress, Tooltip, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { isValid } from 'date-fns';
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';

import rotateLeftIcon from 'images/icons/arrows/RotateLeftIcon.svg';
import ValidationLength from 'utils/utils';

function ScoreCardInfos({
  salesScoreInfo,
  scoreBoardCallSettings,
  handleFilterScoreBoard,
  initialDate,
  endDate,
  reprocessAll
}) {
  const [startDate, setStartDate] = useState(
    new Date(initialDate).setDate(new Date(initialDate).getDate() + 1)
  );
  const [finalDate, setFinalDate] = useState(
    new Date(endDate).setDate(new Date(endDate).getDate() + 1)
  );

  const handleStartDateChange = (date) => {
    if (!isValid(date)) return;

    setStartDate(date);

    if (new Date(finalDate) < date) {
      handleFilterScoreBoard([
        ['startDate', format(date, 'yyyy-MM-dd')],
        ['endDate', format(date, 'yyyy-MM-dd')]
      ]);
      setFinalDate(date);
      return;
    }

    handleFilterScoreBoard([['startDate', format(date, 'yyyy-MM-dd')]]);
  };

  const handleFinalDateChange = (date) => {
    if (!isValid(date)) return;

    setFinalDate(date);

    if (date < new Date(startDate)) {
      handleFilterScoreBoard([
        ['startDate', format(date, 'yyyy-MM-dd')],
        ['endDate', format(date, 'yyyy-MM-dd')]
      ]);
      setStartDate(date);
      return;
    }

    handleFilterScoreBoard([['endDate', format(date, 'yyyy-MM-dd')]]);
  };

  return (
    <section className="salesScoreCardInfosContainer">
      <div className="cardDefault dFlex">
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <KeyboardDatePicker
            margin="normal"
            id="dataInicio"
            variant="inline"
            autoOk
            label="Inicio"
            format="dd/MM"
            className="dateSmall"
            value={startDate || null}
            maxDate={finalDate}
            placeholder="Selecione"
            maxDateMessage="A data inicial deve ser menor que a data final"
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            InputLabelProps={{
              shrink: true
            }}
          />

          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            variant="inline"
            autoOk
            label="Fim"
            format="dd/MM"
            className="dateSmall"
            value={finalDate || null}
            minDate={startDate}
            placeholder="Selecione"
            minDateMessage="A data final deve ser maior que a data inicial"
            onChange={handleFinalDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
        </MuiPickersUtilsProvider>
      </div>

      <table
        className="desktop salesScoreCardTable"
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        border="0"
      >
        <thead>
          <tr className="headerTitle">
            <th className="subtitle">RESTAURANTE</th>
            <th className="subtitle textAlignRight pr8">SUCESSO</th>
            <th className="subtitle textAlignRight pr8">ERRO</th>
            <th className="subtitle textAlignRight">TOTAL</th>
          </tr>
        </thead>

        <tbody>
          {salesScoreInfo?.length ? (
            salesScoreInfo.map((scoreInfo, scoreIndex) => (
              <>
                <tr className="withoutHover">
                  <td id={`id${scoreIndex}`} className="description pl12">
                    {ValidationLength(scoreInfo?.unitName, 25)}
                  </td>

                  <td
                    id={`successScore${scoreIndex}`}
                    className="description pr8 textAlignRight"
                  >
                    {scoreInfo?.countSent || '0'}
                  </td>

                  <td
                    id={`errorScore${scoreIndex}`}
                    className="description pr8 textAlignRight"
                  >
                    <div className="dFlex justifyEnd pRelative">
                      {scoreInfo?.countError || '0'}

                      {scoreInfo?.countError ? (
                        <Tooltip
                          TransitionProps={{ timeout: 600 }}
                          title={
                            <p className="mb0" style={{ padding: '3px 5px' }}>
                              Reprocessar item
                            </p>
                          }
                          placement="right-end"
                        >
                          <img
                            className="cursorPointer"
                            onClick={() =>
                              reprocessAll(scoreInfo?.restaurantId)
                            }
                            style={{
                              width: '12px',
                              position: 'absolute',
                              right: '-20px',
                              top: '1.4vh'
                            }}
                            src={rotateLeftIcon}
                            alt="Reprocess Items"
                          />
                        </Tooltip>
                      ) : null}
                    </div>
                  </td>

                  <td
                    id={`totalScore${scoreIndex}`}
                    className="description pl12 pr12 textAlignRight"
                  >
                    {scoreInfo?.countTotal || '0'}
                  </td>
                </tr>

                <tr className="withoutHover">
                  <td colSpan="4">
                    <hr />
                  </td>
                </tr>
              </>
            ))
          ) : (
            <tr>
              <td colSpan="4">
                <Typography
                  style={{
                    fontSize: '12px',
                    margin: '10px 0',
                    fontFamily: 'Lato',
                    textAlign: 'center'
                  }}
                >
                  Não há registros para exibir.
                </Typography>
              </td>
            </tr>
          )}

          <tr className="withoutHover">
            <td colSpan="4">
              {scoreBoardCallSettings?.scoreBoardLoading ? (
                <LinearProgress variant="query" />
              ) : (
                <hr className="" />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

export default ScoreCardInfos;
