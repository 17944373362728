const TYPE_ORDERS = {
  description: 'string',
  status: 'string',
  salesQuantity: 'number',
  salesVolume: 'number',
  purchaseQuantity: 'number',
  purchaseVolume: 'number'
};

const setOperatorOrder = ({ a, b, typeOrder, orderBy, orderOn }) => {
  if (typeOrder === 'string') {
    if (orderOn === 'desc') {
      return b[orderBy].localeCompare(a[orderBy]);
    }

    return a[orderBy].localeCompare(b[orderBy]);
  }
  if (typeOrder === 'number') {
    if (orderOn === 'desc') {
      return b[orderBy] - a[orderBy];
    }

    return a[orderBy] - b[orderBy];
  }
};

export const sortData = ({ data, orderBy, orderOn }) => {
  const typeOfOrder = TYPE_ORDERS[orderBy];

  return data.sort((a, b) =>
    setOperatorOrder({
      typeOrder: typeOfOrder,
      orderBy,
      orderOn,
      a,
      b
    })
  );
};
