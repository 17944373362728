const providersLocalize = {
  "Fail delete users": "Fail delete users",
  "Not Found users": "Not Found users",
  "It is impossible to change this information": "It is impossible to change this information",
  "The new passwords entered do not match": "The new passwords entered do not match",
  "Not Found provider information": "Not Found provider information",
  "ErrorEdit": "error when editing",
  "ErrorRegister": "error when registering",

};
export default providersLocalize;
