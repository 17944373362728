import styled from "styled-components";

export const QuotationManagerMobileWrapper = styled.div`
    position: relative;
    display: none;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    border-radius: 4px;

    @media only screen and (max-width: 1024px) {
        .mobile-card-loading-data > .mobile-card-loading-quotation-skeleton {
            margin-bottom: 8px;
        }

        .mobile-card-loading-data {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            padding: 0px 12px;
        }

        .mobile-card-loading-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .mobile-card-loading-title {
            margin-bottom: 8px;
            margin-top: 8px;
            padding: 0px 12px;
        }

        .mobile-card-loading-content {
            border-bottom: 1px solid #9296AC;
        }

        .mobile-card-loading {
            display: flex;
            flex-direction: column;
            box-shadow: 0px 0px 8px rgba(4,6,26,0.25);
            border-radius: 4px;
            background: #FFFFFF;
            height: 100%;
        }
    }
    
    @media only screen and (max-width: 959px) {
        display: block;
    }
`;

export const SendQuotesButton = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    bottom: 9px;
    left: 0;
    width: 100%;
    color: #FFFFFF;
    background-color: #5062F0;
    box-shadow: 0px 2px 6px 2px rgba(1, 3, 17, 0.45);
    border-radius: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    div {
        &:first-child {
            font-weight: 700;
            font-size: 12px;
        }
        img {
            margin-left: 3px;
        }
    }
`;

export const Divider = styled.div`
    width: 1px;
    height: 28px;
    background-color: white;
    margin: 0 8px 0 8px;
`;
