export function setStorageLocations(data) {
  return {
      type: 'SET_STORAGE_LOCATIONS_DATA',
      data,
  };
}

export function createStorageLocations(data) {
  return {
      type: 'CREATE_STORAGE_LOCATIONS_DATA',
      data,
  };
}