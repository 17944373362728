import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import {
  CircularProgressWrapp,
  CircularDescription,
  BackgroundCircularProgress
} from './index.style';

function FullScreenLoading({
  description,
  display,
  widthPage,
  width,
  height,
  leftPage
}) {
  return (
    <BackgroundCircularProgress
      style={{ width: `${widthPage}vw`, left: `${leftPage}px` }}
      display={display}
    >
      <CircularProgressWrapp
        style={{ height: `${height}px`, width: `${width}px` }}
      >
        <CircularProgress color="primary" />
        <CircularDescription>{description}...</CircularDescription>
      </CircularProgressWrapp>
    </BackgroundCircularProgress>
  );
}

export default FullScreenLoading;
