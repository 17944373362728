import React from 'react';
import { Tooltip, Fade } from '@material-ui/core';

export const shortenString = (string, lenght) => {
    if (!string) return '-';
    if (string.length > lenght) {
        return string.substring(0, lenght) + "...";
    }
    return string;
}

export const ShortenStringTooltip = ({string, length}) => {
    if (!string) return '-'
    if (string.length > length) {
        return (
            <Tooltip TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} title={string}>
                <span>
                    {string.substring(0, length) + "..."}
                </span>
            </Tooltip>
        )
    }
    return (
        <>
        {string}
        </>
    )
}
