const userLocalize = {
    "need to send at least one restaurant when user is not admin": "need to send at least one restaurant when user is not admin",
    "incorrect password": "incorrect password",
    "not found profile": "not found profile",
    "incorrect password": "incorrect password",
    "Not Found users information": "Not Found users information",
    "Fail login users": "Fail login users",
    "Can't change this information": "Can't change this information",
    "The new passwords entered do not match": "The new passwords entered do not match",
    "not found user with id ${id}": "not found user",
    "Fail delete users": 'Unable to remove this user'
};

export default userLocalize;