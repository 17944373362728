import styled from 'styled-components';

export const DateWrapper = styled.p`
    color: #333541;
    font-size: 12px;
    padding-left: 6px;
    margin: 16px 0 8px 0;
    font-weight: 700;
`;

export const CountCard = styled.div`
    width: 100%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(1, 3, 17, 0.45);
    padding: 8px;
    margin-top: 12px;
`;

export const DescriptionCard = styled.p`
    color: #333541;
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 0;
`;

export const CountPageWrapper = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        margin-bottom: 70px;
        
        .customPullToRefresh {
            padding-bottom: 10px;
        }
    }
`;
