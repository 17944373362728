/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import * as CountActions from 'store/actions/count';

import DateFnsUtils from '@date-io/date-fns';
import 'moment/locale/pt-br';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add, Close, Delete, PlayCircleOutline } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import axios from 'axios';
import { differenceInDays, isToday, isTomorrow } from 'date-fns';
import format from 'date-fns/format';
import ptLocale from 'date-fns/locale/pt-BR';
import Moment from 'moment';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Layout from 'containers/layouts/layout';
import getOptions from 'services/getOptions';
import { ButtonChip } from 'styles/buttons';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';
import ValidationLength from 'utils/utils';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function ListSetup({ location, userInfo, setCountId }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [state, setState] = useState('');
  const [formatDate, setFormatDate] = useState(null);
  const [count, setCount] = useState();
  const [filters, setFilters] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [updateData, setUpdateData] = useState();
  const [periodicities, setPeriodicities] = useState([]);
  const [storages, setStorages] = useState([]);
  const [getId, setGetId] = useState(userInfo.companiesActive.id);
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [updateList, setUpdateList] = useState(false);
  const [periodicitiesDialog, setPeriodicitiesDialog] = useState(false);
  const handleClose = () => {
    setOpen(false);
    // setCount(0)
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [products, setProducts] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [inventories, setInventories] = useState([]);
  const id = location.state;
  const { id: paramsID } = useParams();
  const [newId, setNewId] = useState();
  const [editData, setEditData] = useState();
  const [editProducts, setEditProducts] = useState();
  const [alertErr, setAlertErr] = useState(false);
  const [alertErrItens, setAlertErrItens] = useState(false);
  const [getAlert, setAlert] = useState('');
  const onDismiss = () => {
    setAlertErr(false);
    setAlertErrItens(false);
  };
  const [date, setDate] = useState(null);

  const query = '';

  // Alterar Contagem
  function handlePeriodicityChange(value, oldData) {
    new Promise((resolve, reject) => {
      const body = {
        restaurantId: getId,
        storageLocationId: oldData.storageLocation.id,
        periodicityId: value.id,
        user: userInfo.user
      };
      setTimeout(() => {
        axios
          .put(
            `${environments.catalog}/inventories/setup/${oldData.id}/setupProduct`,
            body
          )
          .then((response) => {
            resolve();
            setUpdateList(!updateList);
            handleCloseAll(setPeriodicitiesDialog);
          })
          .catch((error) => {
            handleCloseAll(setPeriodicitiesDialog);
            reject();
          });
      }, 1000);
    });
  }

  useEffect(() => {
    setGetId(userInfo.companiesActive.id);
  }, [userInfo, userInfo.companiesActive.id]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.catalog}/products?page=1&size=10&originId=${getId}&description=${query}&noSkuProduct=false`
      );
      const listProducts = await response.json();
      setListProducts(listProducts.content);
    })();
  }, [getId, query]);

  // Lista de Inventário
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.catalog}/products?page=1&size=30&originId=${getId}&setupId=${count}`
      );
      const items = await response.json();
      setProducts(items ? items.content : []);
    })();
  }, [count, getId, updateList]);

  useEffect(() => {
    (async () => {
      const params = filtersChanged.map((filter) => filter.urlParam).join('&');
      const response = await fetch(
        `${environments.catalog}/inventories/setup?originId=${getId}&user=${userInfo.user}&${params}`
      );
      const inventories = await response.json();
      setInventories(
        inventories.content && inventories.content.length
          ? inventories.content
          : []
      );
    })();
  }, [getId, filtersChanged, updateList]);

  useEffect(() => {
    !storages.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storages,
        setStorages,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
  }, [getId]);

  function handleOpenAll(handle) {
    handle(true);
  }

  function handleCloseAll(handle) {
    handle(false);
  }

  const handleStartCounts = (id, idCount, status) => {
    if (status === 'Não iniciada') {
      setCountId(id, 'INVENTORIES', date);
      history.push({
        pathname: `/inventario/contagem-diaria/${paramsID}/${idCount}`,
        state: { date: formatDate }
      });
    } else {
      setCountId(id, 'INVENTORIES', date);
      history.push({
        pathname: `/inventario/contagem-diaria/${paramsID}/${idCount}`,
        state: { date: formatDate }
      });
    }
  };

  function handleFilter(param, value) {
    const index = filtersChanged.findIndex((filter) => filter.param === param);

    if (index < 0) {
      if (value) {
        setFilters([
          ...filtersChanged,
          { param, value, urlParam: `${param}=${value}` }
        ]);
      }
    } else if (value !== null) {
      filtersChanged[index] = {
        ...filtersChanged[index],
        value,
        urlParam: `${param}=${value}`
      };
    } else {
      filtersChanged.splice(index, 1);
    }
    setFiltersChanged(filtersChanged);
  }

  return (
    <Layout>
      <GlobalStyle />

      <Container>
        {alertErr ? (
          <Alert
            className="alertError"
            color="danger"
            isOpen={alertErr}
            toggle={onDismiss}
          >
            {getAlert || 'Primeiro precisa associar ao menos um sku'}
          </Alert>
        ) : null}

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr>
              <td colSpan="7">
                <Grid
                  container
                  justify="center"
                  style={{
                    paddingTop: '10px',
                    paddingLeft: '10px',
                    marginBottom: 10
                  }}
                >
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={ptLocale}
                    >
                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          size="small"
                          id="time-picker"
                          label="Data Inicial"
                          format="dd/MM/yyyy"
                          ampm={false}
                          clearable
                          fullWidth
                          inputVariant="outlined"
                          value={date}
                          onChange={(date) => {
                            setDate(date);
                            handleFilter(
                              'date',
                              format(new Date(date), 'yyyy-MM-dd') !==
                                '1969-12-31'
                                ? format(new Date(date), 'yyyy-MM-dd')
                                : null
                            );
                          }}
                          KeyboardButtonProps={{
                            size: 'small',
                            'aria-label': 'change time'
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={4}
                    justify="center"
                    alignItems="center"
                  >
                    <Typography
                      style={{
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: 'bold'
                      }}
                    >
                      Inventários configurados
                    </Typography>
                  </Grid>

                  <Grid container item xs={4} justify="flex-end">
                    <IconButton
                      edge="start"
                      color="inherit"
                      style={{ outline: 0 }}
                      aria-label="add"
                    >
                      <Link to="/inventario/contagem-avulsa/">
                        <Add />
                      </Link>
                    </IconButton>
                  </Grid>
                </Grid>
              </td>
            </tr>

            <tr>
              <td colSpan="7">
                <hr />
              </td>
            </tr>

            <tr>
              <th />
              <th className="subtitle">Contagem</th>
              <th className="subtitle">Local de Estoque</th>
              <th className="subtitle">Próxima</th>
            </tr>

            <tr>
              <td colSpan="7">
                <hr className="titleLineBorder" />
              </td>
            </tr>
          </thead>

          <tbody>
            {inventories.map((inventory) => {
              let status = '';
              const lastCount = inventory.lastCount
                ? Moment(inventory.lastCount).format('LLL')
                : null;
              if (inventory.status) {
                switch (inventory.status.toUpperCase()) {
                  case 'WAITING':
                    status = 'Não iniciada';
                    break;
                  case 'IN PROGRESS':
                    status = 'Parcial';
                    break;
                  case 'FINISHED':
                    if (
                      isToday(new Date(inventory.nextCount)) ||
                      Moment().isAfter(new Date(inventory.nextCount), 'day')
                    ) {
                      inventory.status = 'WAITING';
                      status = 'Finalizada';
                    } else {
                      status = 'Finalizada';
                    }
                    break;
                  default:
                    status = inventory.status;
                    break;
                }
              }

              let nextDate;
              const days =
                differenceInDays(new Date(inventory.nextCount), new Date()) + 1;
              const dayString = `${days} dias`;

              if (isToday(new Date(inventory.nextCount))) {
                if (inventory.status !== 'WAITING') {
                  nextDate = (
                    <ButtonChip
                      label="Hoje"
                      color="success"
                      background="success"
                    />
                  );
                } else {
                  nextDate = (
                    <ButtonChip
                      label="Hoje"
                      color="danger"
                      background="danger"
                    />
                  );
                }
              } else if (isTomorrow(new Date(inventory.nextCount))) {
                nextDate = (
                  <ButtonChip
                    label="Amanhã"
                    color="warning"
                    background="warning"
                  />
                );
              } else if (days > 1) {
                nextDate = (
                  <ButtonChip
                    label={dayString}
                    color="success"
                    background="success"
                  />
                );
              } else if (
                Moment().isAfter(new Date(inventory.nextCount), 'day')
              ) {
                nextDate = (
                  <ButtonChip
                    label="Atrasado"
                    color="danger"
                    background="danger"
                  />
                );
              }

              const periodicity = periodicities[
                periodicities.findIndex(
                  (x) => x.id === inventory.periodicity.id
                )
              ]
                ? periodicities[
                    periodicities.findIndex(
                      (x) => x.id === inventory.periodicity.id
                    )
                  ].description
                : '';

              function howManyItems() {
                return inventory.items.filter((value) =>
                  value.product.skus.some((sku) => sku.skus.providers.length)
                ).length;
              }

              return (
                <>
                  <tr key={inventory.id} valign="top">
                    <td>
                      <IconButton
                        aria-label="playIcon"
                        // disabled={inventory.status === "FINISHED"}
                        onClick={(e) => {
                          if (inventory.items.length === 0) {
                            setAlertErr(true);
                          } else {
                            handleStartCounts(
                              inventory.id,
                              inventory.idCount,
                              inventory.status
                            );
                          }
                        }}
                        style={{ color: '#000000', padding: 0, outline: 0 }}
                      >
                        <PlayCircleOutline
                          className="playIcon"
                          style={{ color: '000000' }}
                          // style={inventory.status === "FINISHED" ? null : { color: '#000000' }}
                        />
                      </IconButton>
                    </td>

                    <td className="description">
                      {periodicity !== undefined
                        ? ValidationLength(periodicity.toString(), 15)
                        : ''}
                    </td>
                    {/* <Link onClick={e =>  {handleOpenAll(setPeriodicitiesDialog); setUpdateData(inventory); setState({ updateData })}}>
                      {periodicity !== undefined ? ValidationLength(periodicity.toString()) : ""}
                    </Link> */}

                    <td className="description">
                      {inventory.storageLocation.description}
                    </td>

                    <td className="description">{nextDate}</td>
                  </tr>

                  <tr>
                    <td colSpan="4" className="p0">
                      <hr />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>

        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={periodicitiesDialog}
          onClose={(e) => handleCloseAll(setPeriodicitiesDialog)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-count"
        >
          <DialogTitle
            style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'move' }}
            id="draggable-dialog-title"
          >
            Contagem
            <IconButton
              edge="start"
              color="inherit"
              onClick={(e) => handleCloseAll(setPeriodicitiesDialog)}
              aria-label="close"
              style={{
                background: 'none',
                position: 'absolute',
                top: '0',
                right: '0',
                align: 'right'
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent style={{ padding: '10px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  onChange={(event, value) =>
                    value
                      ? (setNewId(value), setState({ newId }))
                      : (setNewId(null), setState({ newId }))
                  }
                  size="small"
                  variant="outlined"
                  getOptionSelected={(option, value) =>
                    option.description === value.description
                  }
                  getOptionLabel={(option) => option.description}
                  options={periodicities}
                  value={updateData && updateData.periodicity}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      id={params.id}
                      variant="outlined"
                      label="Contagem"
                      name="periodicities"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={(e) => handleCloseAll(setPeriodicitiesDialog)}
                >
                  Cancelar
                </Button>
              </Grid>

              <Grid item xs={6} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => handlePeriodicityChange(newId, updateData)}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  countInfo: state.count
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CountActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListSetup);
