/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  LinearProgress,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DeleteOutline } from '@material-ui/icons';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import ProductsDialog from 'components/Dialogs/SimpleDialogs/ProductsDialog';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import PeriodicityForm from 'pages/inventory/periodicity/PeriodicityForm';
import * as PeriodicitiesActions from 'store/actions/periodicities';
import { GlobalStyle, Container } from 'styles/general';
import Environment from 'utils/environments';

function Periodicidade({ userInfo, setPeriodicities, createPeriodicities }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [callSettings, setCallSettings] = useState({
    getId: userInfo.companiesActive.id,
    page: 1,
    totalPages: 0,
    size: 100,
    mainContent: [],
    numberOfElements: 0,
    loading: true,
    totalElements: 0
  });
  const { getId, page, size, mainContent, loading } = callSettings;
  const [activeStatusFilter, setActiveStatusFilter] = useState(true);
  const [periodicityModal, setPeriodicityModal] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [personalMessage, setPersonalMessage] = useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [productsModal, setProductsModal] = useState()
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'productModal', open: false, fullScreen: false }
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  const togglePeriodicityModal = () => {
    setPeriodicityModal(!periodicityModal);

    if (periodicityModal === false) {
      setUpdateList(false);
    }
  };

  function handleDelete() {
    const baseUrl = `${environments.catalog}/periodicities`;
    setUpdateList(true);

    axios
      .delete(`${baseUrl}/${deleteData.id}`, {
        method: 'DELETE',
        user: userInfo.user,
        headers: { User: `${userLocal?.user}` }
      })
      .then((response) => {
        setPersonalMessage('Contagem deletada com sucesso!');
        setShowAlert(true);
        setUpdateList(false);
        handleDialogClose();
      })
      .catch((error) => {
        setMessageError(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
        setShowAlert(true);
      });
  }

  const addPeriodicity = () => (
    <Tooltip title="Cadastro de Contagem" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          togglePeriodicityModal();
          createPeriodicities();
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  const orderArray = (array) =>
    array.sort((a, b) => {
      const descriptionA = a.description.toUpperCase();
      const descriptionB = b.description.toUpperCase();

      if (descriptionA < descriptionB) {
        return -1;
      }
      if (descriptionA > descriptionB) {
        return 1;
      }
      return 0;
    });

  const statusFilter = () => (
    <FormControlLabel
      className="toggleDefault m0 dFlex"
      control={
        <Switch
          checked={activeStatusFilter}
          onChange={(checked) => {
            setActiveStatusFilter(!activeStatusFilter);

            if (checked) {
              setCallSettings({
                ...callSettings,
                mainContent: [],
                loading: false
              });
            }
          }}
          name="Active Status Filter"
        />
      }
      label="Ativo"
    />
  );

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useEffect(() => {
    (async () => {
      setCallSettings({
        ...callSettings,
        loading: true
      });
      const statusFilterValue = activeStatusFilter ? 'ACTIVE' : 'INACTIVE';

      const response = await fetch(
        `${environments.catalog}/periodicities?page=${page}&size=${size}&originId=${getId}&status=${statusFilterValue}`
      );
      const periodicity = await response.json();
      setCallSettings({
        ...callSettings,
        mainContent: periodicity.content || [],
        loading: false
      });
    })();
  }, [getId, page, updateList, activeStatusFilter]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: userInfo.companiesActive.id });
  }, [userInfo]);

  const requestProducts = (id) => {
    axios
      .get(
        `${environments.catalog}/periodicities/${id}/products`
      ).then((response) => {
        setProductsModal(response.data)
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'productModal' ? { ...modal, open: true } : modal
          )
        );
      })
  }

  return (
    <Layout>
      <TitleHead title="Contagem" />
      
      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Contagens Cadastradas"
          additionalbutton={addPeriodicity}
          afterTitleButton={statusFilter}
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="31%" className="subtitle">
                DESCRIÇÃO
              </th>

              <th width="25%" className="subtitle">
                TIPO
              </th>

              <th width="16%" className="subtitle">
                DIAS
              </th>

              <th width="13%" className="subtitle p12">
                PRODUTOS
              </th>

              <th width="13%" className="subtitle">
                STATUS
              </th>

              <th width="2%" className="subtitle" />
            </tr>
          </thead>

          {loading && (
            <tr>
              <th colSpan="6" className="textAlignCenter m10">
                <LinearProgress
                  style={{ height: '7px' }}
                  id="loading"
                  variant="query"
                />
              </th>
            </tr>
          )}

          <tbody>
            {mainContent?.length ? (
              orderArray(mainContent).map((item) => {
                let type = '';
                let first = '';
                let second = '';
                let days = [];
                switch (item.type) {
                  case 'DIARY':
                    type = 'Diário';
                    break;
                  case 'MONTHLY':
                    type = 'Mensal';
                    break;
                  case 'BIMONTHLY':
                    type = 'Bimensal';
                    break;
                  case 'WEEKLY':
                    type = 'Semanal';
                    break;
                  case 'BIWEEKLY':
                    type = 'Bissemanal';
                    break;
                  case 'FREE_WEEKLY':
                    type = 'Semenal Livre';
                    break;
                  case 'FREE_MONTHLY':
                    type = 'Mensal Livre';
                    break;
                  default:
                    break;
                }

                if (item.type === 'WEEKLY' || item.type === 'BIWEEKLY') {
                  switch (item.first) {
                    case 1:
                      first = 'Segunda';
                      break;
                    case 2:
                      first = 'Terça';
                      break;
                    case 3:
                      first = 'Quarta';
                      break;
                    case 4:
                      first = 'Quinta';
                      break;
                    case 5:
                      first = 'Sexta';
                      break;
                    case 6:
                      first = 'Sábado';
                      break;
                    case 0:
                      first = 'Domingo';
                      break;
                    default:
                      break;
                  }

                  switch (item.second) {
                    case 1:
                      second += 'Segunda';
                      break;
                    case 2:
                      second += 'Terça';
                      break;
                    case 3:
                      second += 'Quarta';
                      break;
                    case 4:
                      second += 'Quinta';
                      break;
                    case 5:
                      second += 'Sexta';
                      break;
                    case 6:
                      second += 'Sábado';
                      break;
                    case 0:
                      second += 'Domingo';
                      break;
                    default:
                      break;
                  }
                } else {
                  first = item.first;
                  second = item.second;
                }
                if (item.type === 'FREE_WEEKLY') {
                  const weekDays =
                    item?.days &&
                    item?.days.split(',').map((day) => parseInt(day));
                  if (weekDays == '') return;
                  weekDays?.map((day) => {
                    switch (day) {
                      case 1:
                        days.push('Segunda');
                        break;
                      case 2:
                        days.push('Terça');
                        break;
                      case 3:
                        days.push('Quarta');
                        break;
                      case 4:
                        days.push('Quinta');
                        break;
                      case 5:
                        days.push('Sexta');
                        break;
                      case 6:
                        days.push('Sábado');
                        break;
                      case 0:
                        days.push('Domingo');
                        break;
                      default:
                        break;
                    }
                  });

                  days = days.map(
                    (day, index) => `${index !== 0 ? `, ${day}` : day}`
                  );
                }

                if (item.type === 'FREE_MONTHLY') {
                  const monthlyDays =
                    item.days && item.days.split(',').map((day) => day);
                  if (monthlyDays == '') return;
                  days = monthlyDays.map(
                    (day, index) => `${index !== 0 ? `, ${day}` : day}`
                  );
                }

                return (
                  <>
                    <tr>
                      <td className="description p12">
                        <span 
                          className="cursorPointer lightBlue"
                          onClick={() => {
                            setPeriodicities(item);
                            togglePeriodicityModal();
                          }}
                        >
                          {item.description}
                        </span>
                      </td>

                      <td className="description">{type}</td>

                      <td className="description">
                        {item.days ? (
                          <> {days} </>
                        ) : (
                          <>
                            {first} {second && `e ${second}`}
                          </>
                        )}
                      </td>

                      <td className="description p12 lightBlue cursorPointer">
                        <div
                          onClick={() => requestProducts(item.id)}
                        >
                          {item.nProducts}
                        </div>
                      </td>

                      <td className="description">
                        {item.status === 'ACTIVE' ? 'Ativo' : 'Inativo'}
                      </td>

                      <td>
                        {item.status === 'ACTIVE' && (
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => (
                              handleDialogClickOpen(), setDeleteData(item)
                            )}
                            style={{ padding: 0, outline: 0 }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="6">
                        <hr />
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <th colSpan="5" className="m10 textAlignCenter">
                  Nenhum item encontrado
                </th>
              </tr>
            )}
          </tbody>
        </table>

        {periodicityModal && (
          <PeriodicityForm
            openModal={periodicityModal}
            isModal
            redirectPath={null}
            onClose={togglePeriodicityModal}
            setUpdateList={setUpdateList}
          />
        )}

        <ProductsDialog 
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          products={productsModal}
        />

        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            className="modalHeader navyBlue bold cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>
              Deseja realmente excluir essa Contagem?
            </Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            {deleteData?.hasOpenCount ? (
              <Typography>
                Atenção! Esta contagem possui contagens ativas e esta ação
                excluirá as contagens. Deseja continuar?
              </Typography>
            ) : null}
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={handleDialogClose}
              label="Cancelar"
              color="primary"
            />
            <Button
              autoFocus
              className="defaultButton submitButton"
              design="contained"
              label="Confirmar"
              onClick={() => handleDelete()}
              color="primary"
            />
          </DialogActions>
        </Dialog>

        <CommonAlert
          open={showAlert}
          onClose={() => {
            setPersonalMessage(null);
            setShowAlert(false);
          }}
          messagePersonal={personalMessage}
          indexMessage={messageError}
          statusCode={statusCode}
          width="50%"
        />
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(PeriodicitiesActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Periodicidade);

