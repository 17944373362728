import React, { Suspense } from 'react';

import { useTranslation } from 'react-i18next';

import LocalizationPlainText from './LocalizationPlainText';
import { LocalizationContext } from './provider';

export { LocalizationContext };
export { LocalizationPlainText };

function Loader() {
  return <>...</>;
}

function Text({ text, nameSpace, toUppercase, count }) {
  const { t } = useTranslation(nameSpace);
  return toUppercase ? t(text, { count }).toUpperCase() : t(text);
}

function Localization({ text, toUppercase, count }) {
  return (
    <Suspense fallback={Loader}>
      <LocalizationContext.Consumer>
        {({ nameSpace }) => (
          <Text
            text={text}
            nameSpace={nameSpace}
            toUppercase={toUppercase}
            count={count}
          />
        )}
      </LocalizationContext.Consumer>
    </Suspense>
  );
}

export default Localization;
