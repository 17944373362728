const INITIAL = {};

export default function productInfo(state = INITIAL, action) {
  switch (action.type) {
    case 'SET_PRODUCTS_DATA':
      return {
        action: 'EDIT',
        data: action.data
      };
    case 'CREATE_PRODUCTS_DATA':
      return {
        action: 'CREATE',
        data: {}
      };
    default:
      return state;
  }
}
