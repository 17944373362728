/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

import {
  Fade,
  Grid,
  LinearProgress,
  Paper,
  Snackbar,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import Draggable from 'react-draggable';

import DialogChangeSkuProduct from 'components/Dialogs/ChangeProductSku';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import ChangeSkuIcon from 'images/icons/arrows/changeIcon.svg';
import DeleteIcon from 'images/icons/signals/removesAndDeletes/deleteBlanc.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

const useStyles = makeStyles(() => ({
  headerCell: {
    fontFamily: 'Lato',
    fontWeight: 'bold',
    fontSize: '9px',
    margin: '0 10px',
    padding: '0 0 0 16px'
  },
  bodyCell: {
    fontFamily: 'Lato',
    fontSize: '12px',
    whiteSpace: 'nowrap'
  },
  bodyCellAdd: {
    fontFamily: 'Lato',
    fontSize: '12px',
    whiteSpace: 'nowrap'
  },
  headerRow: {
    borderBottom: '1px solid #000',
    background: '#F2F2F4'
  },
  bodyRow: {
    background: '#fff'
  },
  headerActions: {
    width: '70px',
    maxWidth: '70px',
    padding: '0',
    textAlign: 'center'
  },
  bodyActions: {
    whiteSpace: 'nowrap',
    padding: '0'
  },
  actionIcon: {
    margin: '0',
    color: '#757575'
  },
  inputRoot: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  intput: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  right: {
    textAlign: 'right'
  }
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function SkusTable({
  data,
  setJsonBody,
  originId,
  brands,
  jsonBody,
  setUpdateMainList,
  selectedItems,
  setSelectedItems,
  products,
  userInfo,
  items,
  providers,
  packageTypes,
  categories,
  storageLocations,
  periodicities,
  unitsMeasurements,
  consumptionUnits,
  isAdmin,
  onlyViewMode = false,
  openedSkus: isOpenSkus,
  handleOnSave,
  smallPopover = false
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [skus, setSkus] = useState(data ?? []);
  const [openedSkus, setOpenedSkus] = useState(isOpenSkus);
  const [selectedSku, setSelectedSku] = useState(null);
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'skuModal', open: false, fullScreen: false, maxWidth: 'sm' }
  ]);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [dialogChangeSkuSettings, setDialogChangeSkuSettings] = useState({
    open: false,
    skuName: '',
    oldProductId: null,
    skuId: null,
    skuUnitAbbreviation: ''
  });

  useEffect(() => {
    if (data) {
      setSkus(data);
    }
  }, [data, selectedItems]);

  const handleRemoveSku = (productId, skuId) => {
    setLoading(true);

    axios
      .delete(`${environments.catalog}/products/${productId}/skus/${skuId}`)
      .then(() => {
        setLoading(false);

        const updatedSkus = skus.filter((item) => item.skuId !== skuId);
        setSkus(updatedSkus);
        setJsonBody({
          ...jsonBody,
          product: {
            ...jsonBody.product,
            skus: updatedSkus
          }
        });
        setMessage({
          description: 'Skus atualizados com sucesso.',
          status: 'success'
        });
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          description: 'Não foi possível atualizar os skus.',
          status: 'error'
        });
      });
  };

  const handleClose = () => {
    setMessage({ description: '', status: '' });
  };

  return (
    <div style={{padding: '12px 16px'}}>
        <Grid
          container
          xs={12}
          justify="center"
          className={`${!smallPopover && 'mb10'}`}
        >
          {/* Título da tabela */}
          <Grid
            container
            item
            xs={8}
            justify="flex-start"
            alignItems="center"
          >
            <Typography className="bold fontSizeDefault">
              SKU'S ASSOCIADOS
            </Typography>
              <p className="ml5 m0 bold">({skus?.length})</p>
          </Grid>
            {/* Toolbar */}
            <Grid container item xs={4} justify="flex-end" />
          </Grid>
    <TableContainer
      component={Paper}
      style={{
        borderRadius: 4,
        boxShadow: '0px 4px 4px 0px #00000080'

      }}
    >
      <Table
        size="small"
        className="desktop"
        style={{
          boxShadow: smallPopover && 'none',
          borderRadius: smallPopover && 0
        }}
      >
        <TableHead>
          <TableRow
            classes={{ head: classes.headerRow }}
            style={{
              background: smallPopover ? '#E6E7F0' : '#454A67'
            }}
          >
            <TableCell
              style={{
                width: smallPopover ? '34%' : '34%',
                lineHeight: smallPopover ? 'normal' : '12px',
                borderBottom: smallPopover && 'solid 1px #9296AC',
                fontWeight: 'bold',
                color: '#FFFFFF',
                fontSize: 9,
                height: '12px',
                padding: '0 5px'
              }}
              className={`description ${!smallPopover ? 'pl5' : 'pl0'} pr0`}
            >
              DESCRIÇÃO
            </TableCell>

            <TableCell
              style={{
                width: smallPopover ? '11%' : '4%',
                lineHeight: smallPopover ? 'normal' : '30px',
                borderBottom: smallPopover && 'solid 1px #9296AC',
                fontWeight: 'bold',
                color: '#FFFFFF',
                fontSize: 9,
                height: '12px',
                padding: '0 5px'
              }}
              className="description pr0 left"
            >
              QTDE.
            </TableCell>

            <TableCell
              style={{
                width: smallPopover ? '7%' : '3%',
                lineHeight: smallPopover ? 'normal' : '30px',
                borderBottom: smallPopover && 'solid 1px #9296AC',
                fontWeight: 'bold',
                color: '#FFFFFF',
                fontSize: 9,
                height: '12px',
                padding: '0 5px'
              }}
              className="description pr0 left"
            >
              UN.
            </TableCell>

            <TableCell
              style={{
                width: smallPopover ? '9%' : '7%',
                lineHeight: smallPopover ? 'normal' : '30px',
                borderBottom: smallPopover && 'solid 1px #9296AC',
                fontWeight: 'bold',
                color: '#FFFFFF',
                fontSize: 9,
                height: '12px',
                padding: '0 5px'
              }}
              className="description pl16 pr0 right"
            >
              {smallPopover ? 'MÉDIO' : 'PREÇO MÉDIO'}
            </TableCell>

            <TableCell
              style={{
                width: smallPopover ? '9%' : '7%',
                lineHeight: smallPopover ? 'normal' : '30px',
                borderBottom: smallPopover && 'solid 1px #9296AC',
                fontWeight: 'bold',
                color: '#FFFFFF',
                fontSize: 9,
                height: '12px',
                padding: '0 5px'
              }}
              className="description pl16 pr0 right"
            >
              {smallPopover ? 'ÚLTIMO' : 'ÚLTIMO PREÇO'}
            </TableCell>

            <TableCell
              style={{
                width: smallPopover ? '9%' : '7%',
                lineHeight: smallPopover ? 'normal' : '30px',
                borderBottom: smallPopover && 'solid 1px #9296AC',
                fontWeight: 'bold',
                color: '#FFFFFF',
                fontSize: 9,
                height: '12px',
                padding: '0 5px'
              }}
              className="description pl16 pr0 right"
            >
              {smallPopover ? 'CONV.' : 'CONVERSÃO'}
            </TableCell>

            <TableCell
              style={{
                width: smallPopover ? '14%' : '8%',
                lineHeight: smallPopover ? 'normal' : '30px',
                borderBottom: smallPopover && 'solid 1px #9296AC',
                fontWeight: 'bold',
                color: '#FFFFFF',
                fontSize: 9,
                height: '12px',
                padding: '0 5px'
              }}
              className="description pr0 pl16
              right"
            >
              UNID. CONSUMO
            </TableCell>

            {!smallPopover && (
              <TableCell
                style={{ width: '17%', fontSize: 9, height: '12px', padding: '0 5px' }}
                className="description pl16 pr0"
              >
                &nbsp;
              </TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {skus?.length
            ? skus
                .sort((a) => (a.skuId === jsonBody?.sku?.id ? -1 : 1))
                .map((item) => (
                  <TableRow
                    className={
                      openedSkus.includes(item.skuId)
                        ? 'cursorAuto'
                        : 'cursorPointer'
                    }
                    onClick={() => {
                      if (openedSkus.includes(item.skuId)) return;

                      getOptions(
                        `${environments.catalog}/skus/${item?.skuId}`,
                        'skus',
                        selectedSku,
                        setSelectedSku,
                        originId,
                        null,
                        null,
                        null,
                        true
                      );

                      setOpenedSkus([...openedSkus, item.skuId]);

                      setModalSettings(
                        modalSettings.map((modal) =>
                          modal.name === 'skuModal'
                            ? { ...modal, open: true }
                            : modal
                        )
                      );
                    }}
                    style={{
                      background:
                        item.skuId === jsonBody?.sku?.id
                          ? 'rgba(80, 98, 240, .2)'
                          : 'transparent'
                    }}
                  >
                    <TableCell
                      style={{
                        width: '34%',
                        fontWeight:
                          item.skuId === jsonBody?.sku?.id ? 'bold' : 'normal',
                        padding: '0 5px',
                        background: '#FFFFFF'
                      }}
                      className={`description ${
                        smallPopover ? 'pl0' : 'pl5'
                      } pr0`}
                    >
                      {item?.skus?.description
                        ? ValidationLength(
                            item.skus.description,
                            smallPopover ? 30 : 45
                          )
                        : ''}
                    </TableCell>

                    {/* <TableCell
                      style={{
                        width: smallPopover ? '12%' : '11%',
                        fontWeight:
                          item.skuId === jsonBody?.sku?.id ? 'bold' : 'normal',
                        padding: '0 5px'
                      }}
                      className="description pl16 pr0"
                    >
                      {item?.skus?.brands?.id
                        ? ValidationLength(
                            item?.skus?.brands?.description,
                            smallPopover ? 8 : 15
                          )
                        : '-'}
                    </TableCell> */}

                    <TableCell
                      style={{
                        width: smallPopover ? '11%' : '4%',
                        fontWeight:
                          item.skuId === jsonBody?.sku?.id ? 'bold' : 'normal',
                        padding: '0 5px',
                        background: '#FFFFFF'
                      }}
                      className="description pr0 left"
                    >
                      {item?.skus?.quantity
                        ? item.skus.quantity.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : '0,000'}
                    </TableCell>

                    <TableCell
                      style={{
                        width: smallPopover ? '7%' : '3%',
                        fontWeight:
                          item.skuId === jsonBody?.sku?.id ? 'bold' : 'normal',
                        padding: '0 5px',
                        background: '#FFFFFF'
                      }}
                      className="description pr0 left"
                    >
                      {item?.skus?.unitsMeasurements?.abbreviation
                        ? `${item.skus.unitsMeasurements.abbreviation}`
                        : '-'}
                    </TableCell>

                    <TableCell
                      style={{
                        width: smallPopover ? '9%' : '7%',
                        fontWeight:
                          item.skuId === jsonBody?.sku?.id ? 'bold' : 'normal',
                        padding: '0 5px',
                        background: '#FFFFFF'
                      }}
                      className="description pl16 pr0 right"
                    >
                      R$
                      {item?.skus?.restaurants?.length
                        ? item.skus.restaurants[0].purchaseAverage?.toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 3 }
                          ) || '0,000'
                        : '0,000'}
                    </TableCell>

                    <TableCell
                      style={{
                        width: smallPopover ? '9%' : '7%',
                        fontWeight:
                          item.skuId === jsonBody?.sku?.id ? 'bold' : 'normal',
                        padding: '0 5px',
                        background: '#FFFFFF'
                      }}
                      className="description pl16 pr0 right"
                    >
                      R$
                      {item?.skus?.restaurants?.length
                        ? item.skus.restaurants[0].purchaseLast?.toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 3 }
                          ) || '0,000'
                        : '0,000'}
                    </TableCell>

                    <TableCell
                      style={{
                        width: smallPopover ? '9%' : '7%',
                        fontWeight:
                          item.skuId === jsonBody?.sku?.id ? 'bold' : 'normal',
                        padding: '0 5px',
                        background: '#FFFFFF'
                      }}
                      className="description pl16 pr0 right"
                    >
                      {item?.conversion
                        ? item.conversion.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : '0,000'}
                    </TableCell>

                    <TableCell
                      style={{
                        width: smallPopover ? '15%' : '8%',
                        fontWeight:
                          item.skuId === jsonBody?.sku?.id ? 'bold' : 'normal',
                        padding: '0 5px',
                        background: '#FFFFFF'
                      }}
                      className="description pl16 pr0 right"
                    >
                      R$
                      {item?.priceUc
                        ? `${item.priceUc.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}${
                            item?.consumptionUnitsPrimary?.abbreviation
                              ? `/${item?.consumptionUnitsPrimary?.abbreviation}`
                              : ''
                          }`
                        : '0,000'}
                    </TableCell>

                    {!smallPopover && (
                      <TableCell
                        style={{ width: '17%', padding: '0 5px',
                          background: '#FFFFFF' }}
                        className="description right"
                      >
                        {item.skuId !== jsonBody?.sku?.id && !smallPopover && (
                          <div className="dFlex justifyEnd">
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Trocar SKU de Produto"
                            >
                              <img
                                style={{ width: '12px', cursor: 'pointer' }}
                                src={ChangeSkuIcon}
                                alt="DeleteIcon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDialogChangeSkuSettings({
                                    ...dialogChangeSkuSettings,
                                    open: true,
                                    skuName: item?.skus?.description
                                      ? item.skus.description
                                      : '',
                                    oldProductId: item?.productId,
                                    skuId: item?.skuId,
                                    skuUnitAbbreviation:
                                      item?.skus?.unitsMeasurements
                                        ?.abbreviation
                                  });
                                }}
                              />
                            </Tooltip>

                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              title="Retirar SKU desse Produto"
                            >
                              <img
                                style={{ width: '12px', cursor: 'pointer' }}
                                src={DeleteIcon}
                                alt="DeleteIcon"
                                className="ml10"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveSku(item?.productId, item?.skuId);
                                }}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))
            : 
            <TableRow>
              <TableCell
                style={{
                  width: '100%',
                  lineHeight: smallPopover ? 'normal' : '30px',
                  borderBottom: smallPopover && 'solid 1px #9296AC',
                  fontWeight: 'bold',
                  color: '#000',
                  fontSize: 12,
                  height: '12px',
                  padding: '0 5px'
                }}
                className="description pl16 pr0 center"
              >
                Não há sku's associados.
              </TableCell>
            </TableRow>
            }
        </TableBody>

        {loading && (
          <tr>
            <th colSpan="2" className="m10">
              <LinearProgress variant="query" />
            </th>
          </tr>
        )}
      </Table>

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => {
          handleClose();
          if (setUpdateMainList) {
            setUpdateMainList(true);
          }
        }}
      >
        <Alert onClose={() => handleClose()} severity={message.status}>
          {message.description}
        </Alert>
      </Snackbar>

      {dialogChangeSkuSettings.open && (
        <DialogChangeSkuProduct
          open={dialogChangeSkuSettings.open}
          PaperComponent={PaperComponent}
          dialogChangeSkuSettings={dialogChangeSkuSettings}
          setDialogChangeSkuSettings={setDialogChangeSkuSettings}
          products={products}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          originId={originId}
          userInfo={userInfo}
        />
      )}

      {modalSettings.find((modal) => modal.name === 'skuModal').open &&
        selectedSku &&
        products?.length &&
        unitsMeasurements?.length &&
        brands?.length && (
          <SkuModal
            fullScreen={
              modalSettings.find((modal) => modal.name === 'skuModal')
                .fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'skuModal').maxWidth
            }
            open={modalSettings.find((modal) => modal.name === 'skuModal').open}
            data={selectedSku?.providers || []}
            getId={originId}
            editMode={!smallPopover}
            sku={selectedSku}
            userInfo={userInfo}
            items={items}
            providers={providers}
            packageTypes={packageTypes}
            products={products}
            categories={categories}
            brands={brands}
            storageLocations={storageLocations}
            periodicities={periodicities}
            unitsMeasurements={unitsMeasurements}
            consumptionUnits={consumptionUnits}
            openedSkus={openedSkus}
            onlyViewMode={onlyViewMode}
            skus={[]}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'skuModal'
                    ? {
                        ...modal,
                        open: false
                      }
                    : modal
                )
              );

              setOpenedSkus(openedSkus.filter((sku) => sku !== selectedSku.id));

              setSelectedSku('');
            }}
            handleOnSave={handleOnSave || null}
            adminCatalogModal={isAdmin}
          />
        )}
    </TableContainer>
    </div>
  );
}
