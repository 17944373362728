/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';

import { IconButton, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';

import EmptyCheckboxIcon from 'images/icons/empties/emptyCheckboxIcon.svg';
import AddCardBlueIcon from 'images/icons/signals/addsAndCreates/addCardBlue.svg';
import DeleteBlancBlue from 'images/icons/signals/removesAndDeletes/deleteBlancBlue.svg';
import { addUniqueIdAutoComplete } from 'utils/customMaterialTable/addUniqueIdAutocomplete';

import {
  ActionButtons,
  InputContainer,
  FormControlLabelStyled,
  StyledCheckBox,
  TextFieldContainer,
  StyledTypography
} from './index.style';

function AutoCompleteField({
  urlPath,
  label,
  optionReference,
  placeHolder,
  options,
  filterName,
  filterNameSecond,
  handleFilter,
  customSize,
  size,
  inputSizes = {
    small: 110,
    medium: 155,
    medium_plus: 210,
    medium_to_large: 230,
    large: 250,
    large_plus: 400
  },
  internalCheckBox,
  getFilterhandle,
  keyField,
  userInfo,
  defaultValue,
  actionButtons,
  disable,
  updateDefaultValue,
  multiple,
  notMultipleDefaultValue,
  color,
  inputHeight,
  marginTop,
  marginRight,
  storedValue,
  nolabel = false,
  searchByTermMode = false,
  isBloom = false,
  disableGetOptionsOnFocus = false,
  allowClear,
  handleClear,
  isSelect,
  shouldCleanFilter,
  ignoreFilterContent = false,
  disableFilterNameSecond,
  disabledParamCondition = '',
  disabledParamArr = [],
  changeRest
}) {
  const blankValue = multiple ? [ {
    name: '',
    title: '',
    description: ''
  }] : {
    name: '',
    title: '',
    description: ''
  };
  const { id: userId } = userInfo.companiesActive;
  const textField = useRef(null);
  const [open, setOpen] = useState(false);
  const [initialValue, setInitialValue] = useState('');
  const [autoCompleteValue, setAutoCompleteValue] = useState(
    multiple || notMultipleDefaultValue
      ? defaultValue || []
      : defaultValue || {}
  );
  const [getId, setGetId] = useState(userInfo.companiesActive.id);
  const [internalCompleteValue, setInternalCompleteValue] = useState({
    name: ''
  });
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [autoIsSetonOption, setAutoIsSetonOption] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([
    { name: storedValue, value: storedValue }
  ]);

  function transformText(text) {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[çÇ]/g, 'c');
  }

  const parsedOptions = useMemo(() => {
    if (isSelect) return options;

    if (options?.length > 0) {
      return options.filter((option) => {
        const optionText =
          option.name || option.title || option.description || '';
        const formatOptionText = transformText(optionText);

        const term = internalCompleteValue?.name || '';
        const formatTerm = transformText(term);

        return formatOptionText
          ?.toLowerCase()
          .includes(formatTerm?.toLowerCase());
      });
    }

    return options;
  }, [internalCompleteValue?.name, isSelect, options]);

  const inputValue = useMemo(() => {
    if (multiple && parsedOptions) {
      const selectedOptions = options.filter(
        (el) =>
          autoCompleteValue.includes(el.title) ||
          autoCompleteValue.map((item) => item?.title).includes(el.title)
      );

      return selectedOptions;
    }

    if (internalCompleteValue?.name) return internalCompleteValue;
    if (defaultValue) return defaultValue;

    return initialValue || defaultOptions[0] || '';
  }, [
    autoCompleteValue,
    defaultOptions,
    defaultValue,
    initialValue,
    internalCompleteValue,
    multiple,
    options,
    parsedOptions
  ]);

  const handleOptions = useCallback(() => {
    if (typeof getFilterhandle !== 'function') return;
    if (userId !== getId) {
      getFilterhandle(urlPath, label, keyField, optionReference);
      setGetId(userId);
      return;
    }

    getFilterhandle(urlPath, label, keyField, optionReference);
  }, [
    urlPath,
    label,
    keyField,
    optionReference,
    userId,
    getId,
    getFilterhandle
  ]);

  const applyListSearch = () => {
    const elementInput = textField.current.getElementsByTagName('input');
    if (!elementInput[0]) return;
  };

  const handleMultipleAutocompleteValue = (value) => {
    if (
      value.filter((item) => item.title === 'Todos').length &&
      value?.at(-1)?.title === 'Todos'
    ) {
      setAutoCompleteValue(value.filter((item) => item.title === 'Todos'));
    } else {
      setAutoCompleteValue(
        value
          .filter((item) => item.title !== 'Todos')
          .map((item) => item?.title || item?.description || item?.name)
      );
    }
  };

  const handleFilterValue = (filter) => {
    const filterNameFormatted = disableFilterNameSecond ? filterName : filterNameSecond || filterName;

    if (searchByTermMode) {
      applyListSearch();
    } else {
      !multiple
        ? setAutoCompleteValue({
            name: filter?.title || filter?.description || filter?.name
          })
        : handleMultipleAutocompleteValue(filter);
      setAutoIsSetonOption(true);
      setAutoCompleteOpen(false);
    }

    setDefaultOptions([blankValue]);

    setInternalCompleteValue({
      name: filter?.title || filter?.description || filter?.name || ''
    });

    if (!filter || filter === null) {
      setAutoIsSetonOption(false);
      handleFilter(filterNameFormatted, '', null, filterName);
      setAutoCompleteOpen(false);
      return;
    }

    if (multiple) {
      let value = filter.map((item) => item.value)
      
      if (
        filter.filter((item) => item.title === 'Todos').length &&
        filter?.at(-1)?.title === 'Todos'
      ) {
        value = value.filter((item) => item.title === 'Todos');
      } 

      handleFilter(filterNameFormatted, value, filter, filterName);
      return;
    }

    const { value = '', id = '', title } = filter;

    const filterValue = filter?.content && !ignoreFilterContent
      ? (filter?.content?.id || filter?.content?._id) 
      : disableFilterNameSecond 
        ? title 
        : value || id

    handleFilter(filterNameFormatted, filterValue, filter, filterName);

    setAutoCompleteOpen(false);
  };

  const handleCheckboxValue = (_, checked) => {
    if (checked) {
      handleFilter(
        internalCheckBox.filterName,
        checked,
        null,
        filterNameSecond
      );
      return;
    }
    handleFilter(internalCheckBox.filterName, null, null);
  };

  const handleTextValue = (event) => {
    setInitialValue('');
    const { value } = event.target;
    setInternalCompleteValue({ name: value });

    if (!value.length && !multiple) {
      handleFilter(filterName, '', '', filterNameSecond);
      handleOptions();
    }
  };

//   useEffect(() => {
//     if (filterName === 'skuDescription') {
//       setInternalCompleteValue({ name: '' });
//     }
// }, [changeRest])

  const handleSearchFilter = (filterValue) => {
    handleFilter(
      filterName,
      filterValue.name,
      filterValue.name,
      filterNameSecond
    );
    applyListSearch();
    setAutoCompleteOpen(false);
  };

  const handleCloseAutoComplete = () => {
    setOpen(false);
  };

  const getCurrentSelectedValue = useCallback(() => {
    if (multiple) {
      return options?.filter(
        (el) =>
          autoCompleteValue.includes(el.title) ||
          autoCompleteValue.map((item) => item?.title).includes(el.title)
      );
    }

    if (storedValue && !!options.length) {
      const firstOption = options[0];
      const firstOptionKeys = Object.keys(firstOption);
      const wichKeyToUse = firstOptionKeys.find((key) => key !== 'value');

      const currentSelectedStoredValue = options.find(
        (iOption) => iOption[wichKeyToUse] === storedValue
      );

      return currentSelectedStoredValue;
    }

    return autoCompleteValue;
  }, [autoCompleteValue, options, multiple, storedValue]);

  const onClickClear = () => {
    setAutoCompleteValue(defaultValue || blankValue);
    setInternalCompleteValue(defaultValue || blankValue);
    setDefaultOptions([defaultValue || blankValue]);
    setInitialValue(defaultValue || blankValue);
    localStorage.removeItem(filterNameSecond);
    handleClear && handleClear();
  };

  useEffect(() => {
    if (!open) return;
    handleOptions();
  }, [getId, open]);

  useEffect(() => {
    if (!searchByTermMode && !autoIsSetonOption) {
      setInternalCompleteValue({ name: '' });
    }
  }, [autoCompleteOpen]);

  useEffect(() => {
    const elementInput = textField.current.getElementsByTagName('input');
    if (!elementInput[0]) return;

    elementInput[0].addEventListener('blur', () => {
      setAutoCompleteOpen(false);
    });
  }, [textField]);

  useEffect(() => {
    if (updateDefaultValue || !updateDefaultValue) {
      setAutoCompleteValue(
        multiple || notMultipleDefaultValue
          ? updateDefaultValue || []
          : updateDefaultValue || null
      );

      setInternalCompleteValue(updateDefaultValue);
    }
  }, [updateDefaultValue]);

  useEffect(() => {
    if (shouldCleanFilter) {
      onClickClear();
    }
  }, [shouldCleanFilter]);

  useEffect(() => {
    setDefaultOptions([{ name: storedValue, value: storedValue }]);
  }, [storedValue]);

  return (
    <InputContainer
      id={`${filterName}Box`}
      // className={`${
      //   multiple && 'multipleAutocompleteContainer'
      // }`}
      multiple={multiple}
      multpleItensOpen={open}
      color={color}
      inputHeight={inputHeight}
      marginTop={marginTop}
      marginRight={marginRight}
    >
      <Autocomplete
        className={`${(searchByTermMode || allowClear) && 'searchByTermMode'} ${
          multiple && 'multipleNoExpand autocompleteSmall'
        }`}
        multiple={multiple}
        id={filterName}
        value={
          multiple
            ? options.length
              ? options.filter(
                  (el) =>
                    autoCompleteValue.includes(el.title) ||
                    autoCompleteValue
                      .map((item) => item?.title)
                      .includes(el.title)
                )
              : storedValue
            : inputValue
        }
        inputValue={multiple ? internalCompleteValue?.name || '' : undefined}
        clearOnBlur={false}
        open={autoCompleteOpen}
        onOpen={() => {
          addUniqueIdAutoComplete();
          setAutoCompleteOpen(!autoCompleteOpen);

          if (!disableGetOptionsOnFocus) {
            setOpen(true);
          }
        }}
        onChange={(_, value) => handleFilterValue(value)}
        onClose={handleCloseAutoComplete}
        options={parsedOptions || defaultOptions}
        getOptionLabel={(option) =>
          option.title || option.name || option.description || ''
        }
        getOptionDisabled={(option) => {
          if (disabledParamCondition && disabledParamArr?.length) {
            return disabledParamArr?.some((el) => el[disabledParamCondition] === option[disabledParamCondition]) 
          }

          return false
        }}
        style={{ width: customSize || inputSizes[size] }}
        disabled={disable}
        limitTags={1}
        ChipProps={{
          size: 'small',
          style: { fontFamily: 'Lato', fontSize: 16, height: 20 }
        }}
        renderInput={(params) => (
          <TextFieldContainer>
            <TextField
              {...params}
              label={nolabel ? null : label}
              placeholder={placeHolder}
              fullWidth
              disabled={disable}
              onChange={handleTextValue}
              ref={textField}
              onKeyDown={(event) => {
                event.keyCode === 8 &&
                  searchByTermMode &&
                  internalCompleteValue?.name?.length < 1 &&
                  handleSearchFilter(internalCompleteValue);
                event.keyCode === 13 &&
                  searchByTermMode &&
                  handleSearchFilter(internalCompleteValue);
              }}
              InputProps={{
                ...params.InputProps,
                style: multiple ? { overflowY: 'auto', overflowX: 'clip', gap: '5px' } : {},
                endAdornment: (
                  <>
                    {/* {loading[0] ? <CircularProgress color="inherit" size={12} /> : null} */}

                    {params.InputProps.endAdornment}

                    {searchByTermMode || allowClear ? (
                      <Close
                        className="pAbsolute customClearIcon"
                        onClick={() => {
                          onClickClear();
                          setAutoCompleteOpen(false);
                          setOpen(false);

                          handleFilter(filterName, '', null, filterNameSecond);
                        }}
                        style={{ width: '19px', right: '25px' }}
                      />
                    ) : null}
                  </>
                )
              }}
              InputLabelProps={{
                shrink: true
              }}
            />

            {internalCheckBox && (
              <FormControlLabelStyled
                control={
                  <StyledCheckBox
                    name={internalCheckBox.label}
                    icon={
                      <img
                        alt="Empty"
                        src={EmptyCheckboxIcon}
                      />
                    }
                    checkedIcon={
                      <img
                        alt="Checked"
                        src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                      />
                    }
                    size="small"
                    onChange={handleCheckboxValue}
                    fontSize="small"
                  />
                }
                label={internalCheckBox.label}
              />
            )}
          </TextFieldContainer>
        )}
        renderOption={(params) => {
          if (multiple) {
            const selected =
              autoCompleteValue && autoCompleteValue.length
                ? (
                    autoCompleteValue?.filter(
                      (item) => item.title || item.name || item.description
                    ) || []
                  ).some((item) => item?.title === params?.title)
                : false;

            return (
              <StyledTypography
                noWrap
                selected={selected}
                style={{ fontSize: '12px', fontFamily: 'Lato' }}
              >
                {params.title || params.name || params.description}
              </StyledTypography>
            );
          }

          return (
            <StyledTypography
              isBloom={isBloom && params?.content?.statusBloom === 'BLOOM'}
            >
              {params.title || params.name || params.description}
            </StyledTypography>
          );
        }}
      />

      {actionButtons && (
        <ActionButtons>
          <IconButton color="primary" component="span">
            <img style={{ width: '8px' }} src={AddCardBlueIcon} alt="AddIcon" />
          </IconButton>

          <IconButton color="primary" component="span">
            <img
              style={{ width: '10px' }}
              src={DeleteBlancBlue}
              alt="DeleteIcon"
            />
          </IconButton>
        </ActionButtons>
      )}
    </InputContainer>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(AutoCompleteField);
