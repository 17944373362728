import React, { useEffect, useState } from 'react';

import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';

import DateInputField from 'components/PageTitleAndFilter/DateInputField/DateInputField';
import Smalltable from 'components/SmallTable';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import useFetch from 'services/useFetch';
import Environment from 'utils/environments';
import handleFilterOptions from 'utils/handleFilterActions/handleFilterOptions';
import numberToReal from 'utils/numberToReal';
import { shortenString } from 'utils/shortenString';

import { DanteRangeContainer, DialogContentContainer } from './style';

function DialogQuotes({
  open,
  PaperComponent,
  dialogQuotesSettings,
  setDialogQuotesSettings,
  originId,
  setOpenSuccessDeleteQuotation,
  userInfo
}) {
  const actualDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const actualDatePlus30 = moment().add(7, 'days').format('YYYY-MM-DD');
  const [openErrorToast, setOpenErrorToast] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [statusCode, setStatusCode] = useState();
  const env = Environment(process.env.REACT_APP_ENV);
  const { obj } = dialogQuotesSettings;
  const { description } = obj;
  const isRestaurant = userInfo?.profile?.[0]?.[0]?.name === 'restaurants';

  const [rangeDates] = useState({
    startDate: actualDate,
    endDate: actualDatePlus30
  });

  const [tableData, setTableData] = useState({
    header: [
      { name: 'FORNECEDOR' },
      { name: 'EMBALAGEM' },
      { name: 'PREÇO' },
      { name: 'DATA COTAÇÃO' },
      { name: 'VALIDADE' },
      { ...(!isRestaurant ? { name: '' } : {}) }
    ],
    collunms: []
  });

  const [filters] = useState([
    { param: 'originId', value: originId, urlParam: `originId=${originId}` },
    {
      param: 'startDate',
      value: rangeDates.startDate,
      urlParam: `startDate=${rangeDates.startDate}`
    },
    {
      param: 'endDate',
      value: rangeDates.endDate,
      urlParam: `endDate=${rangeDates.endDate}`
    }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);

  const { status, data } = useFetch(
    'quotationsHistory',
    filtersChanged,
    !open,
    obj?.id
  );

  function handleDisableQuotation(providerInfo, quotationInfo) {
    const body = {
      codeProvider: providerInfo?.codeProvider,
      providerId: providerInfo?.providerId,
      uniqueId: quotationInfo?.uniqueId,
      quoteDate: quotationInfo?.quoteDate,
      user: quotationInfo?.user
    };
    Axios.delete(`${env.catalog}/quotations`, { data: body })
      .then((res) => {
        setOpenSuccessDeleteQuotation(true);
        setDialogQuotesSettings({ ...dialogQuotesSettings, open: false });
      })
      .catch((error) => {
        setOpenErrorToast(true)
        setAlertMessage(error.response.data, message)
        setStatusCode(error.response.status)
      });
  }

  useEffect(() => {
    if (!data) return;
    const { content = [] } = data;
    const [contentList] = content;

    if (!contentList) {
      setTableData({ ...tableData, collunms: [] });
      return;
    }

    const { providers = [] } = contentList;

    const newCollunms = providers.map((element) => {
      const { quotations, quantity } = element;
      return quotations.map((quotes) => {
        const newName = shortenString(element?.name, 30);
        const newPackage = `E${quantity || 0}x${obj.quantity ? obj.quantity : 0
          }${obj?.unitsMeasurements ? obj?.unitsMeasurements?.abbreviation : ''}`;
        const newPrice = `R$ ${numberToReal(quotes.unitaryValue)}`;
        const newQuoteDate = new Intl.DateTimeFormat('pt-br').format(
          new Date(quotes.quoteDate)
        );
        const newExpirationDate = quotes.expirationDate
          ? new Intl.DateTimeFormat('pt-br').format(
            new Date(quotes.expirationDate)
          )
          : '-';
        const disableQuotation = {
          name: '',
          tooltipDescription: 'Inativar cotação',
          iconField: {
            iconName: 'deleteIcon',
            setOption: 'valid',
            action: handleDisableQuotation,
            providersQuotes: element,
            quotationElement: quotes
          }
        };

        return [
          newName,
          newPackage,
          newPrice,
          newQuoteDate,
          newExpirationDate,
          ...(!isRestaurant ? [disableQuotation] : [])
        ];
      });
    });

    setTableData({ ...tableData, collunms: newCollunms.flat() });
  }, [status, data]);

  const handleDateRange = (name, value) => {
    handleFilterOptions(name, value, filtersChanged, setFiltersChanged);
  };

  const handleCloseToast = () => {
    setOpenErrorToast(false);
  };

  return (
    <>

      <CommonAlert
        open={openErrorToast}
        onClose={handleCloseToast}
        indexMessage={alertMessage}
        statusCode={statusCode}
      />

      <Dialog
        open={open}
        onClose={() =>
          setDialogQuotesSettings({ ...dialogQuotesSettings, open: false })
        }
        className="defaultModal"
        PaperComponent={PaperComponent}
        maxWidth="md"
      >
        <DialogTitle
          className="modalHeader bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
          fullWidth
        >
          <Typography>{shortenString(description, 50)}</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={(e) => {
              setDialogQuotesSettings({ ...dialogQuotesSettings, open: false });
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </IconButton>
        </DialogTitle>

        <DialogContentContainer>
          <DanteRangeContainer>
            <DateInputField
              label="Data Início"
              handleFilter={handleDateRange}
              filterName="startDate"
              size="small"
              inputSizes={{
                small: 110,
                medium: 155,
                medium_plus: 210,
                large: 250
              }}
              initialDate={rangeDates.startDate}
            />
            <DateInputField
              label="Data Fim"
              handleFilter={handleDateRange}
              filterName="endDate"
              size="small"
              inputSizes={{
                small: 110,
                medium: 155,
                medium_plus: 210,
                large: 250
              }}
              initialDate={rangeDates.endDate}
            />
          </DanteRangeContainer>
          <Smalltable tableData={tableData} />
        </DialogContentContainer>
      </Dialog>
    </>
  );
}

export default DialogQuotes;
