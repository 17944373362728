import React from 'react';

import { Skeleton } from '@material-ui/lab';

import { ReceivesSkeletonLoading } from './style';

function SkeletonLoading({skeletonArray}) {
  for (let index = 0; index < 9; index++) {
    skeletonArray.push(
      <ReceivesSkeletonLoading>
        <div>
          <Skeleton variant="text" animation="wave" width="65%" height={18} />
          <Skeleton variant="text" animation="wave" width="20%" height={18} />
        </div>
        
        <div>
          <Skeleton variant="text" animation="wave" width="35%" height={18} />
          <Skeleton variant="text" animation="wave" width="20%" height={18} />
          <Skeleton variant="text" animation="wave" width="20%" height={18} />
        </div>
      </ReceivesSkeletonLoading>
    )
  }
  return (
    skeletonArray.map((content) => <div>{content}</div>)
  )
}

export default SkeletonLoading;