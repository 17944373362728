export function setSubGroupsAndStores({ subGroups, originIds }) {
    const selectedSubGroups = [];
    const selectedStores = [];
    
    for (const subGroup of subGroups) {
        for (const store of subGroup.stores) {
    
            if (originIds.includes(store.id)) {
                selectedStores.push(store);
    
                const i = selectedSubGroups.findIndex(sub => sub.id === subGroup.id);
    
                if (i < 0) {
                    selectedSubGroups.push(subGroup);
                }
            }
        }
    }
    
    return { selectedSubGroups, selectedStores }
}