/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import RadioGroup from 'components/RadioGroup/radioGroup';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import down from 'images/icons/arrows/arrowDownGrayOutline.svg';
import toggleFilter2 from 'images/icons/filters/toggleFilter2.svg';
import windowSvg from 'images/icons/objects/books/window.svg';
import eye from 'images/icons/objects/eyes/eye.svg';
import * as MenuActions from 'store/actions/menu';
import rankingMJP from 'temp/ranking.json';
import rankingLM from 'temp/rankingLM.json';
import {
  getFilterOptions,
  handleFilterOptions
} from 'utils/handleFilterActions';
import numberToReal from 'utils/numberToReal';

import {
  Header,
  FilterIcon,
  FilterButtonWrap,
  ActionsButtonsWrap,
  TableHeaderTitle,
  LineTable
} from './styles';

import './Styles.scss';

function Ranking({ userInfo }) {
  const actualDate = new Date();
  const dateLessSeven = new Date().setDate(actualDate.getDate() - 7);
  const dateLessOne = new Date().setDate(new Date().getDate() - 1);
  const [grouped, setByGroup] = useState(true);
  const [filters, setFilters] = useState([
    {
      param: 'dateStart',
      value: format(dateLessSeven, 'yyyy-MM-dd'),
      urlParam: `dateStart=${format(dateLessSeven, 'yyyy-MM-dd')}`
    },
    {
      param: 'dateEnd',
      value: format(actualDate, 'yyyy-MM-dd'),
      urlParam: `dateEnd=${format(dateLessOne, 'yyyy-MM-dd')}`
    }
  ]);
  const [ranking, setRanking] = useState(rankingMJP);
  const [rankingHeader, setRankingHeader] = useState({
    sales: 0,
    salesRanking: 0,
    clients: 0,
    clientsRanking: 0,
    ticket: 0,
    ticketRanking: 0,
    orders: 0,
    ordersRanking: 0,
    stock: 0,
    stockRanking: 0,
    cmv: 0,
    cmvRanking: 0,
    mkp: 0,
    mkpRanking: 0
  });
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const { id: paramsID } = useParams();
  const [getId, setId] = useState(paramsID);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [toggleFilter, setToggleFilter] = useState(true);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: '',
        filterName: 'status',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        optionReference: 'name',
        defaultValue: { title: 'Visão Vendas', value: 'salesVision' },
        options: [
          { title: 'Visão Vendas', value: 'salesVision' },
          { title: 'Visão Clientes', value: 'clientsVision' },
          { title: 'Visão Pedidos', value: 'ordersVision' }
        ],
        size: 'medium',
        actionButtons: true
      },
      {
        label: 'Grupo',
        filterName: 'category',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Início',
        type: 'dateInputField',
        filterName: 'dateStart',
        size: 'small',
        initialDate: dateLessSeven
      },
      {
        label: 'Fim',
        type: 'dateInputField',
        filterName: 'dateEnd',
        size: 'small',
        initialDate: dateLessOne
      },
      {
        label: 'Dividir em grupos',
        filterName: 'groupBy',
        type: 'switchField'
      }
    ],
    []
  ]);
  const [option, setOption] = useState('cmv');
  const [currentIndexOver, setCurrentIndexOver] = useState(null);

  const handleMouseOverLine = (index) => {
    if (!index) {
      setCurrentIndexOver(null);
      return;
    }
    setCurrentIndexOver(index);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFIlterToggle = () => {
    setToggleFilter(!toggleFilter);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userInfo.companiesActive.id === 2) {
      setRanking(rankingLM);
      setRankingHeader({
        sales: '231.857,19',
        salesRanking: '9',
        clients: '5.153',
        clientsRanking: '7',
        ticket: '46,96',
        ticketRanking: '10',
        orders: '89.349,790',
        ordersRanking: '10',
        stock: '34.259,280',
        stockRanking: '10',
        cmv: '34,400',
        cmvRanking: '4',
        mkp: '34,400',
        mkpRanking: '4'
      });
    } else if (userInfo.companiesActive.id === 1) {
      setRanking(rankingMJP);
      setRankingHeader({
        sales: '855.289,90',
        salesRanking: '2',
        clients: '9.456',
        clientsRanking: '5',
        ticket: '88,03',
        ticketRanking: '5',
        orders: '271.180,830',
        ordersRanking: '5',
        stock: '152.044,290',
        stockRanking: '1',
        cmv: '30,700',
        cmvRanking: '6',
        mkp: '30,700',
        mkpRanking: '6'
      });
    } else if (userInfo.companiesActive.id === 4) {
      setRanking(rankingMJP);
      setRankingHeader({
        sales: '855.289,90',
        salesRanking: '2',
        clients: '9.456',
        clientsRanking: '5',
        ticket: '88,03',
        ticketRanking: '5',
        orders: '271.180,830',
        ordersRanking: '5',
        stock: '152.044,290',
        stockRanking: '1',
        cmv: '30,700',
        cmvRanking: '6',
        mkp: '30,700',
        mkpRanking: '6'
      });
    }
  }, [userInfo.companiesActive.id]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [visibleGrid, setVisible] = useState({
    ranking: true,
    vendas: true,
    ticket: true,
    pedidos: true,
    clients: true,
    estoque: true,
    cmv: true
  });

  const [allHide, setAllHide] = useState(false);

  const [visibleRank, setVisibleRank] = useState({
    vendas: true,
    ticket: true,
    pedidos: true,
    estoque: true,
    clients: true,
    cmv: true
  });

  function showAllRanks() {
    setVisibleRank({
      vendas: true,
      ticket: true,
      clients: true,
      pedidos: true,
      estoque: true,
      cmv: true
    });
    setAllHide(false);
  }

  const counter = {
    first: 0,
    sales: 0,
    clients: 0,
    ticket: 0,
    orders: 0,
    stock: 0,
    cmv: 0
  };
  function setSelectedFirstRestaurant(where) {
    if (counter[where] === 0) {
      counter[where]++;
      if (where === 'first') {
        return 'restFirstContent';
      }
      return 'firstContent';
    }
  }

  function hideAllRanks() {
    setVisibleRank({
      sales: false,
      ticket: false,
      clients: false,
      pedidos: false,
      estoque: false,
      cmv: false
    });

    setAllHide(true);
  }

  function changeRankVisibility(key) {
    setVisibleRank((old) => ({
      ...old,
      [key]: !visibleRank[key]
    }));
  }

  function changeVisibility(key) {
    setVisible((old) => ({
      ...old,
      [key]: !visibleGrid[key]
    }));
  }

  function getTotal(items, objectName, where) {
    let count = 0;
    items.map((item) => {
      item[objectName].map((iterator) => {
        count = iterator[where] ? count + iterator[where] : count;
      });
    });
    return count;
  }

  function setByGroupOnChange() {
    setByGroup((value) => !value);
  }

  function handleFilter(param, value) {
    handleFilterOptions(param, value, filtersChanged, setFiltersChanged);
  }

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged
    );
  };

  return (
    <Layout>
      <TitleHead title="Home" />

      <Popover
        id="popOver"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <div className="inputDiv">
            <div
              onClick={() => changeVisibility('vendas')}
              className="inputLabel"
            >
              <input
                checked={visibleGrid.vendas}
                id="checkbox1"
                type="checkbox"
              />
              <label className="checkbox__control">Vendas</label>
            </div>

            <div
              onClick={() => changeVisibility('ticket')}
              className="inputLabel"
            >
              <input
                checked={visibleGrid.ticket}
                id="checkbox2"
                type="checkbox"
              />
              <label className="checkbox__control">Ticket Médio</label>
            </div>

            <div
              onClick={() => changeVisibility('clients')}
              className="inputLabel"
            >
              <input
                checked={visibleGrid.clients}
                id="checkbox6"
                type="checkbox"
              />
              <label className="checkbox__control">Clientes</label>
            </div>

            <div
              onClick={() => changeVisibility('pedidos')}
              className="inputLabel"
            >
              <input
                checked={visibleGrid.pedidos}
                id="checkbox3"
                type="checkbox"
              />
              <label className="checkbox__control">Pedidos</label>
            </div>

            <div
              onClick={() => changeVisibility('estoque')}
              className="inputLabel"
            >
              <input
                checked={visibleGrid.estoque}
                id="checkbox4"
                type="checkbox"
              />
              <label className="checkbox__control">Estoque</label>
            </div>

            <div onClick={() => changeVisibility('cmv')} className="inputLabel">
              <input checked={visibleGrid.cmv} id="checkbox5" type="checkbox" />
              <label className="checkbox__control">CMV/Markup</label>
            </div>
          </div>
        </ClickAwayListener>
      </Popover>

      <Header>
        <div className="initial">
          <strong>Ranking</strong>

          <div className="card">
            <span>Vendas</span>

            <div className="content">
              <span>R$ {rankingHeader.sales}</span>
              <small>Ranking: {rankingHeader.salesRanking}</small>
            </div>
          </div>

          <div className="card">
            <span>Clientes</span>
            <div className="content">
              <span>{rankingHeader.clients}</span>
              <small>Ranking: {rankingHeader.clientsRanking}</small>
            </div>
          </div>

          <div className="card">
            <span>Ticket Medio</span>

            <div className="content">
              <span>R$ {rankingHeader.ticket}</span>
              <small>Ranking: {rankingHeader.ticketRanking}</small>
            </div>
          </div>

          <div className="card">
            <span>Pedidos</span>

            <div className="content">
              <span>R$ {rankingHeader.orders}</span>
              <small>Ranking: {rankingHeader.ordersRanking}</small>
            </div>
          </div>

          <div className="card">
            <span>Estoque</span>

            <div className="content">
              <span>R$ {rankingHeader.stock}</span>
              <small>Ranking: {rankingHeader.stockRanking}</small>
            </div>
          </div>

          <div className="card">
            <span>CMV</span>

            <div className="content">
              <span>{rankingHeader.cmv} %</span>
              <small>Ranking: {rankingHeader.cmvRanking}</small>
            </div>
          </div>

          <div className="card">
            <span>MARKUP</span>

            <div className="content">
              <span>{rankingHeader.mkp} %</span>
              <small>Ranking: {rankingHeader.mkpRanking}</small>
            </div>
          </div>
        </div>

        <ActionsButtonsWrap>
          <FilterButtonWrap>
            <FilterIcon onClick={handleClick} alt="window" src={windowSvg} />
          </FilterButtonWrap>

          <FilterButtonWrap>
            <FilterIcon
              onClick={handleFIlterToggle}
              alt="filter"
              src={toggleFilter2}
            />
          </FilterButtonWrap>
        </ActionsButtonsWrap>
      </Header>

      <PageTitleAndFilter
        onChange={setByGroupOnChange}
        title=""
        activeFilterFilds={filterFields}
        handleFilter={handleFilter}
        getFilterhandle={getFilterhandle}
        dynamicJustifyOff
        iconFilterOff
        toggleFilter={toggleFilter}
        externalTo
      />

      {/* { rankingEnable ? (
              <Filter>
            </Filter>
            ) : '' } */}
      <div className="rankingContainer">
        <div className="RankingMainInformation">
          {visibleGrid.ranking ? (
            <div className="ranking">
              <TableHeaderTitle>Ranking</TableHeaderTitle>
              {!allHide ? (
                <small
                  style={{
                    marginTop: '6px',
                    paddingLeft: '6px',
                    paddingBottom: '4px'
                  }}
                  onClick={() => hideAllRanks()}
                >
                  <img alt="eye" className="eye" src={eye} />
                  Esconder todos os rankings
                </small>
              ) : (
                <small
                  style={{
                    marginTop: '6px',
                    paddingLeft: '6px',
                    paddingBottom: '4px'
                  }}
                  onClick={() => showAllRanks()}
                >
                  <img alt="eye" className="eye" src={eye} />
                  Mostrar todos os rankings
                </small>
              )}

              {ranking.map((item, index) => (
                <div className="Data">
                  {grouped ? (
                    <div className={`divisor${index}`}>
                      <span>{item.group}</span>
                    </div>
                  ) : (
                    ''
                  )}

                  <div
                    className={
                      index === 0 ? setSelectedFirstRestaurant('first') : ''
                    }
                  >
                    <div className="restContent">
                      {item.sales.map((item) => (
                        <LineTable
                          style={{
                            padding: '8px'
                          }}
                          backgroundColor={currentIndexOver === item.name}
                          onMouseOver={() => handleMouseOverLine(item.name)}
                          onMouseLeave={() => handleMouseOverLine()}
                        >
                          {item.name}
                        </LineTable>
                      ))}
                    </div>
                  </div>

                  {grouped ? (
                    <div className="first">
                      <span>Total</span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}

              {!grouped ? (
                <div className="first">
                  <span>Total Geral</span>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {visibleGrid.vendas ? (
            <div className="vendas">
              <TableHeaderTitle>
                <img
                  alt="eye"
                  onClick={() => changeRankVisibility('vendas')}
                  className="eye"
                  src={eye}
                />
                Vendas R$
              </TableHeaderTitle>

              <div className="rowed">
                <small>
                  total
                  <img alt="down" src={down} />
                </small>
                <small>
                  loja
                  <img alt="down" src={down} />
                </small>
                <small>
                  delivery
                  <img alt="down" src={down} />
                </small>
              </div>

              {ranking.map((item, index) => (
                <div className="Data">
                  {grouped ? <div className={`divisor${index}`} /> : ''}

                  <div
                    className={
                      index === 0 ? setSelectedFirstRestaurant('sales') : ''
                    }
                  >
                    <div className="content">
                      {visibleRank.vendas ? (
                        <div className="rankingContent">
                          {item.sales.map((index) => (
                            <strong>{index.ranking}</strong>
                          ))}
                        </div>
                      ) : (
                        <span className="fixed" />
                      )}

                      <div className="totalContent">
                        {item.sales.map((item) => (
                          <LineTable
                            style={{ width: '75px' }}
                            backgroundColor={currentIndexOver === item.name}
                            onMouseOver={() => handleMouseOverLine(item.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {item.total.toLocaleString('pt-br', {
                              minimumFractionDigits: 2
                            })}
                          </LineTable>
                        ))}
                      </div>

                      <div className="lojaContent">
                        {item.sales.map((index) => (
                          <LineTable
                            style={{ width: index.store ? '78px' : '75px' }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {index.store.toLocaleString('pt-br', {
                              minimumFractionDigits: 2
                            })}
                          </LineTable>
                        ))}
                      </div>

                      <div className="deliveryContent">
                        {item.sales.map((index) => (
                          <LineTable
                            style={{ width: '70px' }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {index.delivery.toLocaleString('pt-br', {
                              minimumFractionDigits: 2
                            })}
                          </LineTable>
                        ))}
                      </div>
                    </div>
                  </div>

                  {grouped ? (
                    <div className="total">
                      <span className="fixed" />
                      <span style={{ textAlign: 'right' }}>
                        {item.salesTotal.toLocaleString('pt-br', {
                          minimumFractionDigits: 2
                        })}
                      </span>
                      <span style={{ textAlign: 'right' }}>
                        {item.salesStoreTotal.toLocaleString('pt-br', {
                          minimumFractionDigits: 2
                        })}
                      </span>
                      <span style={{ textAlign: 'right' }}>
                        {item.salesDeliveryTotal.toLocaleString('pt-br', {
                          minimumFractionDigits: 2
                        })}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}

              {!grouped ? (
                <div className="total">
                  <span className="fixed" />
                  <span style={{ textAlign: 'right' }}>
                    {getTotal(ranking, 'sales', 'total').toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 2 }
                    )}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {getTotal(ranking, 'sales', 'total').toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 2 }
                    )}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {getTotal(ranking, 'sales', 'delivery').toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 2 }
                    )}
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {visibleGrid.clients ? (
            <div className="ticket">
              <TableHeaderTitle>
                <img
                  alt="eye"
                  onClick={() => changeRankVisibility('clients')}
                  className="eye"
                  src={eye}
                />
                Clientes
              </TableHeaderTitle>

              <div className="rowed">
                <small>
                  Loja
                  <img alt="down" src={down} />
                </small>
                <small>
                  deliv
                  <img alt="down" src={down} />
                </small>
              </div>

              {ranking.map((item, index) => (
                <div className="Data">
                  {grouped ? <div className={`divisor${index}`} /> : ''}

                  <div
                    className={
                      index === 0 ? setSelectedFirstRestaurant('clients') : ''
                    }
                  >
                    <div className="content">
                      {visibleRank.clients ? (
                        <div className="rankingContent">
                          {item.clients.map((index) => (
                            <strong>{index.ranking}</strong>
                          ))}
                        </div>
                      ) : (
                        <span className="fixed" />
                      )}

                      <div className="lojaContent">
                        {item.clients.map((index) => (
                          <LineTable
                            style={{ width: index.store ? 'auto' : '66px' }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.store)}
                          </LineTable>
                        ))}
                      </div>

                      <div className="deliveryContent">
                        {item.clients.map((index) => (
                          <LineTable
                            style={{ width: '87px' }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.delivery)}
                          </LineTable>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* item.clientStoreAverage
                item.clientDeliveryAverage */}
                  {grouped ? (
                    <div className="total">
                      <span className="fixed" />
                      <span style={{ textAlign: 'right' }}>
                        {item.customerStoreTotal.toLocaleString('pt-br', {
                          minimumFractionDigits: 2
                        })}
                      </span>
                      <span style={{ textAlign: 'right' }}>
                        {item.customerDeliveryTotal.toLocaleString('pt-br', {
                          minimumFractionDigits: 2
                        })}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}

              {!grouped ? (
                <div className="total">
                  <span className="fixed" />
                  <span style={{ textAlign: 'right' }}>
                    {getTotal(ranking, 'clients', 'store').toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 2 }
                    )}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {getTotal(ranking, 'clients', 'delivery').toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 2 }
                    )}
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {visibleGrid.ticket ? (
            <div className="ticket">
              <TableHeaderTitle>
                <img
                  alt="eye"
                  onClick={() => changeRankVisibility('ticket')}
                  className="eye"
                  src={eye}
                />
                ticket medio
              </TableHeaderTitle>

              <div className="rowed">
                <small>
                  Loja
                  <img alt="down" src={down} />
                </small>
                <small>
                  deliv
                  <img alt="down" src={down} />
                </small>
              </div>

              {ranking.map((item, index) => (
                <div className="Data">
                  {grouped ? <div className={`divisor${index}`} /> : ''}

                  <div
                    className={
                      index === 0
                        ? setSelectedFirstRestaurant('ticket')
                        : 'generalTicket'
                    }
                  >
                    <div className="content">
                      {visibleRank.ticket ? (
                        <div className="rankingContent">
                          {item.ticket.map((index) => (
                            <strong>{index.ranking}</strong>
                          ))}
                        </div>
                      ) : (
                        <span className="fixed" />
                      )}

                      <div className="lojaContent">
                        {item.ticket.map((index) => (
                          <LineTable
                            style={{
                              width: index.store
                                ? index.store === 36.93
                                  ? '45px'
                                  : 'auto'
                                : '45px'
                            }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.store)}
                          </LineTable>
                        ))}
                      </div>

                      <div
                        className="deliveryContent"
                        style={{ paddingRight: '5px' }}
                      >
                        {item.ticket.map((index) => (
                          <LineTable
                            style={{ width: '45px' }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.delivery)}
                          </LineTable>
                        ))}
                      </div>
                    </div>
                  </div>

                  {grouped ? (
                    <div className="total">
                      <span className="fixed" />
                      <span>{numberToReal(item.ticketStoreAverage)}</span>
                      <span style={{ textAlign: 'right' }}>
                        {numberToReal(item.ticketDeliveryAverage)}
                      </span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}

              {!grouped ? (
                <div className="total">
                  <span className="fixed" />
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(getTotal(ranking, 'ticket', 'store'))}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(getTotal(ranking, 'ticket', 'delivery'))}
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {visibleGrid.pedidos ? (
            <div className="pedidos">
              <TableHeaderTitle>
                <img
                  alt="eye"
                  onClick={() => changeRankVisibility('pedidos')}
                  className="eye"
                  src={eye}
                />
                pedidos
              </TableHeaderTitle>

              <div className="rowed">
                <small>
                  qtde.
                  <img alt="down" src={down} />
                </small>
                <small>
                  R$ valor
                  <img alt="down" src={down} />
                </small>
                <small>
                  inf.%
                  <img alt="down" src={down} />
                </small>
                <small>
                  vxc
                  <img alt="down" src={down} />
                </small>
              </div>

              {ranking.map((item, index) => (
                <div className="Data">
                  {grouped ? <div className={`divisor${index}`} /> : ''}

                  <div
                    className={
                      index === 0
                        ? setSelectedFirstRestaurant('orders')
                        : 'generalOrders'
                    }
                  >
                    <div className="content">
                      {visibleRank.pedidos ? (
                        <div className="rankingContent">
                          {item.orders.map((index) => (
                            <strong>{index.ranking}</strong>
                          ))}
                        </div>
                      ) : (
                        <span className="fixed" />
                      )}

                      <div className="qtdeContent">
                        {item.orders.map((index) => (
                          <LineTable
                            style={{ width: '62px' }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.quantity)}
                          </LineTable>
                        ))}
                      </div>

                      <div className="valueContent">
                        {item.orders.map((index) => (
                          <LineTable
                            style={{ width: '77px' }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.value)}
                          </LineTable>
                        ))}
                      </div>

                      <div className="infContent">
                        {item.orders.map((index) => (
                          <LineTable
                            style={{ width: '77px' }}
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.exchange)}
                          </LineTable>
                        ))}
                      </div>

                      <div className="vxcContent">
                        {item.orders.map((index) => (
                          <LineTable
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.comp)}
                          </LineTable>
                        ))}
                      </div>
                    </div>
                  </div>

                  {grouped ? (
                    <div className="total">
                      <span className="fixed" />
                      <small style={{ marginTop: '15px', textAlign: 'right' }}>
                        {numberToReal(item.ordersQuantityAverage)}
                      </small>
                      <small style={{ marginTop: '15px', textAlign: 'right' }}>
                        {numberToReal(item.ordersValueAverage)}
                      </small>
                      <small style={{ marginTop: '15px', textAlign: 'right' }}>
                        {numberToReal(item.ordersExchangeAverage)}
                      </small>
                      <small style={{ marginTop: '15px', textAlign: 'right' }}>
                        {numberToReal(item.ordersCompTotal)}
                      </small>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}
              {!grouped ? (
                <div className="total">
                  <span className="fixed" />
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(getTotal(ranking, 'orders', 'quantity'))}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(getTotal(ranking, 'orders', 'value'))}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(getTotal(ranking, 'orders', 'exchange'))}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(getTotal(ranking, 'orders', 'comp'))}
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {visibleGrid.estoque ? (
            <div className="estoque">
              <TableHeaderTitle>
                <img
                  alt="eye"
                  onClick={() => changeRankVisibility('estoque')}
                  className="eye"
                  src={eye}
                />
                estoque
              </TableHeaderTitle>

              <div className="rowed">
                <small>
                  valor
                  <img alt="down" src={down} />
                </small>
                <small>
                  giro
                  <img alt="down" src={down} />
                </small>
              </div>

              {ranking.map((item, index) => (
                <div className="Data">
                  {grouped ? <div className={`divisor${index}`} /> : ''}

                  <div
                    className={
                      index === 0 ? setSelectedFirstRestaurant('stock') : ''
                    }
                  >
                    <div className="content">
                      {visibleRank.estoque ? (
                        <div className="rankingContent">
                          {item.stocks.map((index) => (
                            <strong>{index.ranking}</strong>
                          ))}
                        </div>
                      ) : (
                        <span className="fixed" />
                      )}

                      <div className="lojaContent">
                        {item.stocks.map(({ value, name }) =>
                          value ? (
                            <LineTable
                              backgroundColor={currentIndexOver === name}
                              onMouseOver={() => handleMouseOverLine(name)}
                              onMouseLeave={() => handleMouseOverLine()}
                            >
                              {numberToReal(value)}
                            </LineTable>
                          ) : (
                            <LineTable>00,000</LineTable>
                          )
                        )}
                      </div>

                      <div className="deliveryContent">
                        {item.stocks.map((index) => (
                          <LineTable
                            backgroundColor={currentIndexOver === index.name}
                            onMouseOver={() => handleMouseOverLine(index.name)}
                            onMouseLeave={() => handleMouseOverLine()}
                          >
                            {numberToReal(index.turnover)}
                          </LineTable>
                        ))}
                      </div>
                    </div>
                  </div>

                  {grouped ? (
                    <div className="total">
                      <span className="fixed" />
                      <small style={{ marginTop: '15px' }}>
                        {numberToReal(item.stocksValueTotal)}
                      </small>
                      <small style={{ marginTop: '15px' }}>
                        {numberToReal(item.stocksturnoverTotal)}
                      </small>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}

              {!grouped ? (
                <div className="total">
                  <LineTable className="fixed" />
                  <LineTable>
                    {numberToReal(getTotal(ranking, 'stocks', 'value'))}
                  </LineTable>
                  <LineTable>
                    {numberToReal(getTotal(ranking, 'stocks', 'turnover'))}
                  </LineTable>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          {visibleGrid.cmv ? (
            <div className="cmv">
              <div className="dFlex justifyBetween cmvAndMkpCase">
                <TableHeaderTitle>
                  <img
                    alt="eye"
                    onClick={() => changeRankVisibility('cmv')}
                    className="eye"
                    src={eye}
                  />
                  CMV/MKP
                </TableHeaderTitle>

                <div className="toggleDefault small cmvMkpRadio">
                  <RadioGroup
                    id="cmvSwitch"
                    properties={{
                      labelPlacement: 'end',
                      name: 'cmv-mkp-values',
                      radioSize: 'small',
                      row: true
                    }}
                    options={[
                      { label: 'CMV', value: 'cmv', id: 'cmvOption' },
                      { label: 'Markup', value: 'mkp', id: 'mkpOption' }
                    ]}
                    option={option}
                    setOption={setOption}
                  />
                </div>
              </div>

              <div className="rowed">
                <small>
                  meta
                  <img alt="down" src={down} />
                </small>
                <small>
                  real
                  <img alt="down" src={down} />
                </small>
                <small>
                  comp
                  <img alt="down" src={down} />
                </small>
              </div>

              {ranking.map((item, index) => (
                <div className="Data">
                  {grouped ? <div className={`divisor${index}`} /> : ''}

                  <div
                    className={
                      index === 0 ? setSelectedFirstRestaurant('cmv') : ''
                    }
                  >
                    <div className="content">
                      {visibleRank.cmv ? (
                        <div className="rankingContent">
                          {option === 'cmv' ? (
                            <>
                              {item.cmv.map((index) => (
                                <strong>{index.ranking}</strong>
                              ))}
                            </>
                          ) : (
                            <>
                              {item.markup.map((index) => (
                                <strong>{index.ranking}</strong>
                              ))}
                            </>
                          )}
                        </div>
                      ) : (
                        <span className="fixed" />
                      )}

                      <div className="metaContent">
                        {option === 'cmv' ? (
                          <>
                            {item.cmv.map((index) => (
                              <LineTable
                                backgroundColor={
                                  currentIndexOver === index.name
                                }
                                onMouseOver={() =>
                                  handleMouseOverLine(index.name)
                                }
                                onMouseLeave={() => handleMouseOverLine()}
                              >
                                {numberToReal(index.goal)}
                              </LineTable>
                            ))}
                          </>
                        ) : (
                          <>
                            {item.markup.map((index) => (
                              <LineTable
                                backgroundColor={
                                  currentIndexOver === index.name
                                }
                                onMouseOver={() =>
                                  handleMouseOverLine(index.name)
                                }
                                onMouseLeave={() => handleMouseOverLine()}
                              >
                                {numberToReal(index.goal)}
                              </LineTable>
                            ))}
                          </>
                        )}
                      </div>

                      <div className="realContent">
                        {option === 'cmv' ? (
                          <>
                            {item.cmv.map((index) => (
                              <LineTable
                                backgroundColor={
                                  currentIndexOver === index.name
                                }
                                onMouseOver={() =>
                                  handleMouseOverLine(index.name)
                                }
                                onMouseLeave={() => handleMouseOverLine()}
                              >
                                {numberToReal(index.real)}
                              </LineTable>
                            ))}
                          </>
                        ) : (
                          <>
                            {item.markup.map((index) => (
                              <LineTable
                                backgroundColor={
                                  currentIndexOver === index.name
                                }
                                onMouseOver={() =>
                                  handleMouseOverLine(index.name)
                                }
                                onMouseLeave={() => handleMouseOverLine()}
                              >
                                {numberToReal(index.real)}
                              </LineTable>
                            ))}
                          </>
                        )}
                      </div>

                      <div className="compContent">
                        {option === 'cmv' ? (
                          <>
                            {item.cmv.map((index) => (
                              <LineTable
                                backgroundColor={
                                  currentIndexOver === index.name
                                }
                                onMouseOver={() =>
                                  handleMouseOverLine(index.name)
                                }
                                onMouseLeave={() => handleMouseOverLine()}
                              >
                                {numberToReal(index.comp)}
                              </LineTable>
                            ))}
                          </>
                        ) : (
                          <>
                            {item.markup.map((index) => (
                              <LineTable
                                backgroundColor={
                                  currentIndexOver === index.name
                                }
                                onMouseOver={() =>
                                  handleMouseOverLine(index.name)
                                }
                                onMouseLeave={() => handleMouseOverLine()}
                              >
                                {numberToReal(index.comp)}
                              </LineTable>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {grouped ? (
                    <div className="total">
                      <span className="fixed" />
                      <small style={{ marginTop: '15px', textAlign: 'right' }}>
                        {option === 'cmv'
                          ? numberToReal(item.cmvGoalAverage)
                          : numberToReal(item.markupGoalAverage)}
                      </small>
                      <small style={{ marginTop: '15px', textAlign: 'right' }}>
                        {option === 'cmv'
                          ? numberToReal(item.cmvRealAverage)
                          : numberToReal(item.markupRealAverage)}
                      </small>
                      <small style={{ marginTop: '15px', textAlign: 'right' }}>
                        {option === 'cmv'
                          ? numberToReal(item.cmvCompAverage)
                          : numberToReal(item.markupCompAverage)}
                      </small>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ))}

              {!grouped ? (
                <div className="total">
                  <span className="fixed" />
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(
                      getTotal(
                        ranking,
                        `${option === 'cmv' ? 'cmv' : 'markup'}`,
                        'goal'
                      )
                    )}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(
                      getTotal(
                        ranking,
                        `${option === 'cmv' ? 'cmv' : 'markup'}`,
                        'real'
                      )
                    )}
                  </span>
                  <span style={{ textAlign: 'right' }}>
                    {numberToReal(
                      getTotal(
                        ranking,
                        `${option === 'cmv' ? 'cmv' : 'markup'}`,
                        'comp'
                      )
                    )}
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(MenuActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Ranking);
