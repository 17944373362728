import React, { useEffect, useState } from 'react';

import './index.scss';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import useFetch from 'services/useFetch';

import ChartCard from '../Shopping&SalesChart/index';
import SalesProvider from '../YourSales';

function Sales({
  providerId,
  type,
  volumePercentage,
  ordersPercentage,
  yourSalesOrders,
  yourSalesVolume,
  purchaseOrders,
  purchaseVolume,
  yourPotentialRequests,
  yourPotentialVolume,
  handleFilter,
  canPlaceOrderFiltered,
  filter,
  filtersChanged
}) {
  const [companies, setCompanies] = useState(0);
  const [enabled, setEnabled] = useState(0);
  const [associate, setAssociate] = useState(0);

  const { data } = useFetch(
    'providersClients',
    '',
    false,
    `/${providerId}`,
    ''
  );

  useEffect(() => {
    if (data?.length) {
      setCompanies(data.length);

      const companiesEnabled = data.filter(
        (company) => company.status === 'Habilitado'
      ).length;
      const companiesAssociate = data.filter(
        (company) => company.status === 'Associado'
      ).length;

      setEnabled(companiesEnabled);
      setAssociate(companiesAssociate);
    }
  }, [data]);

  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
      marginBottom: '-60px'
    }
  }));

  const classes = useStyles();

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container direction="row" justify="center" spacing={2}>
          <Grid item>
            <div className="groupButton">
              <Button
                className={`buttonAllClients ${
                  canPlaceOrderFiltered === null &&
                  (canPlaceOrderFiltered !== false ||
                    canPlaceOrderFiltered !== true)
                    ? 'btn-active'
                    : ''
                }`}
                onClick={(e) => handleFilter('canPlaceOrder', null)}
              >
                Todos {companies} clientes
              </Button>
              <svg
                width="1"
                height="100%"
                viewBox="0 0 1 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="space"
              >
                <rect width="1" height="32" fill="#7583F0" />
              </svg>
              <Button
                className={`buttonEnableAssociated ${
                  canPlaceOrderFiltered === true &&
                  (canPlaceOrderFiltered !== false ||
                    canPlaceOrderFiltered !== null)
                    ? 'btn-active'
                    : ''
                }`}
                onClick={(e) => handleFilter('canPlaceOrder', true)}
              >
                {enabled} habilitados
              </Button>
              <Button
                className={`buttonEnableAssociated ${
                  canPlaceOrderFiltered === false &&
                  (canPlaceOrderFiltered !== true ||
                    canPlaceOrderFiltered !== null)
                    ? 'btn-active'
                    : ''
                }`}
                onClick={(e) => handleFilter('canPlaceOrder', false)}
              >
                {associate} potencial
              </Button>
            </div>
            <Grid item xs={12}>
              <SalesProvider
                salesQuantity={yourSalesOrders}
                salesVolume={yourSalesVolume}
                percentageQuantity={ordersPercentage}
                percentageVolume={volumePercentage}
                bloomQuantity={purchaseOrders}
                bloomVolume={purchaseVolume}
                potentialQuantity={yourPotentialRequests}
                potentialVolume={yourPotentialVolume}
              />
            </Grid>
          </Grid>
          <Grid item>
            <ChartCard
              providerId={providerId}
              type={type}
              filter={filter}
              filtersChanged={filtersChanged}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Sales;
