const calcSKUPriceFromPackage = (quantityPackage, pricePackage) => {
  const quantity = parseFloat(quantityPackage);
  const price = parseFloat(pricePackage);

  if (price > quantity) {
    return price / quantity;
  }

  return quantity / price;
};

export default calcSKUPriceFromPackage;
