/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';

/* eslint-disable no-unused-expressions */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Axios from 'axios';

import PaperComponent from 'components/PaperComponent';
import Environment from 'utils/environments';

import StepView from '../../components/StepView';

function SelectGroupDialog({
  open,
  onClose,
  onCancel,
  onConfirm,
  selectedGroupObj,
  setSelectedGroupObj
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [restaurantOptions, setRestaurantOptions] = useState([]);

  const getRestaurantsOptions = () => {
    Axios.get(`${environments.restaurants}/groups?size=100`)
      .then((response) => {
        setRestaurantOptions(response?.data?.content);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRestaurantsOptions();
  }, []);

  return (
    <Dialog
      open={open}
      className="defaultModal fakePopover"
      PaperComponent={PaperComponent}
      onClose={() => {
        onClose();
      }}
    >
      <StepView currentStep="2" />

      <DialogContent
        className="modalContent bgWhite"
        style={{ padding: '11px 11px 16px', minWidth: 300 }}
      >
        <Typography className="bold" style={{ marginBottom: 40 }}>
          Selecione o Grupo:
        </Typography>

        <div className="dFlex flexColumn">
          <Autocomplete
            required
            style={{ marginBottom: 40 }}
            options={restaurantOptions}
            value={selectedGroupObj?.group}
            getOptionLabel={(option) => option?.description}
            onChange={(_, value) => {
              if (value) {
                setSelectedGroupObj({
                  ...selectedGroupObj,
                  group: value,
                  subgroup: ''
                });
              } else {
                setSelectedGroupObj({
                  ...selectedGroupObj,
                  group: '',
                  subgroup: ''
                });
              }
            }}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params?.description}
              </Typography>
            )}
            className="autocompleteSmall"
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                required
                name="group"
                label="Grupo"
                variant="outlined"
                placeholder="Selecione..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />

          <Autocomplete
            key={Math.random()}
            options={selectedGroupObj?.group?.subgroups || []}
            value={selectedGroupObj?.subgroup}
            disabled={!selectedGroupObj?.group}
            getOptionLabel={(option) => option?.description}
            onChange={(_, value) => {
              setSelectedGroupObj({
                ...selectedGroupObj,
                subgroup: value
              });
            }}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params?.description}
              </Typography>
            )}
            className="autocompleteSmall"
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                required
                name="subgroup"
                label="Subgrupo"
                variant="outlined"
                placeholder="Selecione..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </div>
      </DialogContent>

      <DialogActions className="dFlex justifyEnd pb10">
        <Button
          className="defaultButton backButton mr8"
          autoFocus
          onClick={() => {
            onCancel ? onCancel() : onClose();
          }}
        >
          Cancelar
        </Button>

        <Button
          className={`defaultButton ${
            !selectedGroupObj?.subgroup ? 'backButton' : 'submitButton'
          } ml0`}
          disabled={!selectedGroupObj?.subgroup}
          onClick={() => {
            onConfirm();
          }}
        >
          Avançar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectGroupDialog;
