/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, Suspense, useCallback } from 'react';

import { Fade, IconButton, LinearProgress, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { format, subDays } from 'date-fns';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TableIcon from 'components/TableIcon';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import getOptions from 'services/getOptions';
import axiosInstanceOrder from 'services/middlewares/apis/orders_validate';
import * as ProductsActions from 'store/actions/products';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import { ButtonChip, HtmlTooltip } from './styles';

import RegisterProductModal from '../catalog/products/ProductForm';

const CardsDialogs = React.lazy(() => import('components/Dialogs/RelatedCardsFullInfosDialog'));
const SkuDialog = React.lazy(() => import('./dialogs/SkuDialogs'));
const DurationDialog = React.lazy(() => import('components/Dialogs/DurationDialog'));
const StockRuptureDialog = React.lazy(() =>
  import('./dialogs/StockRuptureDialogs')
);
const TheoryStockDialog = React.lazy(() =>
  import('components/Dialogs/ExtractsDialog')
);
const EmptyDialog = React.lazy(() => import('components/Dialogs/EmptyDialog'));

const environments = Environment(process.env.REACT_APP_ENV);

export function StocksList({ userInfo, productInfo, setEditProducts }) {
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const [parameters, setParameters] = useState('');
  const [dataStock, setDataStock] = useState([]);
  const [stockProduct, setStockProduct] = useState('');
  const [stockCardDescription, setstockCardDescription] = useState([]);
  const [stockAge, setStockAge] = useState(null);
  const [stockUnit, setStockUnit] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [brands, setBrands] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [modalStates, setModalStates] = useState({
    modalCards: false,
    modalSkus: false,
    modalProduct: false,
    modalDuration: false,
    modalRupture: false,
    modalEmpty: false,
    modalSkuInfos: false,
    modalProductInfos: false,
    modalTechnicalSheet: false
  });
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [selectedCard, setSelectedCard] = useState();
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [skuModal, setSkuModal] = useState([]);
  const [onlyViewProduct, setOnlyViewProduct] = useState(false);
  const [productModal, setProductModal] = useState({});
  const [productExtractDescription, setProductExtractDescription] =
    useState('-');
  const [productAge, setProductAge] = useState(null);
  const [selectedSku, setSelectedSku] = useState([]);
  const [qtdProductDescription, setQtdProductDescription] = useState('');
  const [skuOrderModal, setSkuOrderModal] = useState([]);
  const [durationData, setDurationData] = useState({ product: {}, data: {} });
  const { id: paramsID } = useParams();
  const [getId, setGetId] = useState(paramsID);
  const [loading, setLoading] = useState(false);
  const [filters] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState([]);
  const [initialDate, setInitialDate] = useState(null);
  const [checkConsume, setCheckConsume] = useState(false);
  const isGroupManage = getId?.includes(',');
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Produto',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        optionReference: 'description',
        size: 'medium',
        options: [],
        searchByTermMode: true
      },
      {
        label: 'Categoria',
        filterName: 'category',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Data',
        type: 'dateInputField',
        filterName: 'date',
        size: 'small',
        maxDate: new Date()
      },
      {
        label: 'Contagem',
        filterName: 'periodicities',
        placeHolder: 'Selecione',
        size: 'medium',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/periodicities`,
        key: 'periodicities',
        optionReference: 'description',
        options: []
      },
      {
        label: 'Curva',
        type: 'multipleCheckbox',
        checkBoxInputs: [{ name: 'A' }, { name: 'B' }, { name: 'C' }]
      },
      {
        type: 'multipleCheckbox',
        checkBoxInputs: [
          { name: 'Mercado', referenceName: 'type', filterValue: 'EXTERNAL' },
          { name: 'Produzido', referenceName: 'type', filterValue: 'INTERNAL' },
          { name: 'Ruptura', referenceName: 'isBreak' },
          { name: 'Sem uso', referenceName: 'withoutUse' }
        ]
      }
    ],
    []
  ]);

  useEffect(() => {
    setGetId(paramsID);
  }, [paramsID]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const params = filtersChanged.map((filter) => filter.urlParam).join('&');
      const response = await fetch(`${environments.catalogV2}/stocks?page=1&size=2000&originId=${getId}&${params}`);

      const items = await response.json();
      setDataStock(items?.content || []);
      setLoading(false);
    })();
  }, [getId, filters, filtersChanged]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${isGroupManage ? getId?.split(',')[0] : getId}/parameters`
      );
      const params = await response.json();
      setParameters(params?.product?.type);
    })();
  }, [getId]);

  useEffect(() => {
    if (!modalStates.modalSkuInfos) return;

    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !providers.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        10
      );
  }, [getId, modalStates.modalSkuInfos]);

  useEffect(() => {
    if (selectedProduct) {
      const arraySkus = [];
      selectedProduct.skus.forEach((skus) => {
        arraySkus.push({
          id: skus.skus ? skus.skus.id : null,
          description: skus.skus ? skus.skus.description : null,
          quantity: skus.skus
            ? parseFloat(skus.skus.quantity).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
            : null,
          unitsMeasurements: skus.skus
            ? skus.skus.unitsMeasurements.abbreviation
            : null,
          purchaseAverage:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseAverage
              : null,
          purchaseLast:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseLast
              : null,
          conversion: skus.skus
            ? parseFloat(skus.conversion).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
            : null,
          priceUc: skus ? skus.priceUc : null
        });
      });

      selectedProduct.skus = arraySkus;
    }

    setEditProducts(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
          ? card.unitsMeasurementsMenu.unitsMeasurements
          : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
          _id: card.frequencies.frequencyId,
          description: card.frequencies.description
        }
        : null;

      card.storageLocation = card.storageLocation
        ? {
          _id: card.storageLocation.storageLocationId,
          description: card.storageLocation.description
        }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '0,000',
              listCostLast:
                product.products &&
                  product.products.lastCostStock &&
                  !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : product.products.approximateCost
                    ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                    : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                  : '0,000',
              costLast:
                product.products &&
                  product.products.lastCostStock &&
                  !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                  : product.products.approximateCost
                    ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                    : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                  minimumFractionDigits: 9
                })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
            ? card.itemsTransformations.products.approximateCost
            : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '0,000',
              listCostLast:
                product.products &&
                  product.products.lastCost &&
                  product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : product.products.approximateCost
                    ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                    : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                  : '0,000',
              costLast:
                product.products &&
                  product.products.lastCost &&
                  product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                  : product.products.approximateCost
                    ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                    : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                  minimumFractionDigits: 9
                })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalStates({ ...modalStates, modalTechnicalSheet: true });
  };

  function handleFilter(param, value, filter, paramSecond) {
    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!value && (hasFilterWithFirstName || hasFilterWithSecondName)) {
      formattedFilters = filtersChanged?.filter(
        (el) => el?.param !== paramSecond && el?.param !== param
      );
    }

    if (param === 'date') {
      setInitialDate(
        new Date(value) === new Date()
          ? null
          : new Date(value).valueOf() + 86400000
      );
    }

    handleFilterOptions(param, value, formattedFilters, setFiltersChanged);
  }

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged
    );
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: '#222',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      fontFamily: 'Lato'
    }
  }))(Tooltip);

  const getProductSkusByGroup = useCallback((product) => {
    (async () => {
      const response = await axiosInstanceOrder.get(
        `${environments.catalog}/stocks/${product?._id}/${getId}`
      );

      const productSkusResponse = await response?.data?.content;

      setSkuModal(productSkusResponse || '');
    })();
  }, []);

  const getRelatedCards = async (stock) =>{
    let date = format(new Date(), 'yyyy-MM-dd');
    if (stock.age != null) {
      if (stock.age > 0)
        date = format(subDays(new Date(), parseInt(stock.age)), 'yyyy-MM-dd');
    }
    const checkConsumeValue = checkConsume ? 'sales_total' : 'sales_consumed';
    const response = await fetch(
      `${environments.catalog}/stocks/cards?originId=${getId}&productId=${stock.id}&date=${date}&typeData=${checkConsumeValue}`
    );
    const cards = await response.json();

    if (cards.length > 0) {
      setstockCardDescription(cards);
    }

    return cards;
  }

  // abre modal de fichas
  async function handleClickOpenCards(stock) {
    let date = format(new Date(), 'yyyy-MM-dd');
    if (stock.age != null) {
      if (stock.age > 0)
        date = format(subDays(new Date(), parseInt(stock.age)), 'yyyy-MM-dd');
    }

    
    const cards = await getRelatedCards(stock);

    if (cards.length > 0) {
      setModalStates({ ...modalStates, modalCards: true });
      setStockProduct(stock);
      setStockAge(date);
      setstockCardDescription(cards);
      setStockUnit(
        stock.consumptionUnitsPrimary
          ? stock.consumptionUnitsPrimary.abbreviation
          : null
      );
    } else {
      setModalStates({ ...modalStates, modalEmpty: true });
    }
  }

  // abre modal de skus
  const handleClickOpenSkus = (stock) => {
    if (stock.skus && stock.skus.length) {
      setModalStates({ ...modalStates, modalSkus: true });
      setStockProduct(stock);
    } else {
      setModalStates({ ...modalStates, modalEmpty: true });
    }

    if (isGroupManage) {
      getProductSkusByGroup(stock)
    } else {
      setSkuModal(stock.skus);
    }
  };

  // modal de produtos
  async function handleClickProducts(stock) {
    let date = format(
      initialDate || subDays(new Date(), parseInt(8)),
      'yyyy-MM-dd'
    );
    const finishDate = initialDate
      ? format(initialDate, 'yyyy-MM-dd')
      : stock.nextCount;

    if (stock.age != null) {
      if (stock.age > 0)
        date = format(subDays(new Date(), parseInt(stock.age)), 'yyyy-MM-dd');
    }

    setProductModal(stock);
    setProductExtractDescription(stock.description);
    setProductAge(date);
    setModalStates({ ...modalStates, modalProduct: true });
  }

  const handleClickOpenQtd = (stock) => {
    setModalStates({ ...modalStates, modalRupture: true });

    if (stock.orders.length) {
      const qtdModal = stock.orders.length;
      const skuOrder = stock.orders;
      setQtdProductDescription(qtdModal);
      setSkuOrderModal(skuOrder);
    } else {
      setQtdProductDescription('');
      setSkuOrderModal([]);
    }
  };

  const getDuration = async (productId, stock, product) => {
    const response = await fetch(
      `${environments.engine}/rupture?productId=${productId}&restaurantId=${getId}&days=8&stock=${stock}`
    );
    const items = await response.json();
    if (items) {
      setDurationData({ product, data: items });
      setModalStates({ ...modalStates, modalDuration: true });
    }
  };

  // soma de valores
  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const totalFlat = dataStock.length
    ? dataStock
      .reduce(
        (acc, item) => [
          ...acc,
          parameters === 'LAST_PURCHASE'
            ? item.lastCostStock && item.theoreticalStock
              ? item.lastCostStock * item.theoreticalStock
              : item.lastCost && item.theoreticalStock
                ? item.lastCost * item.theoreticalStock
                : 0
            : item.averageCostStock && item.theoreticalStock
              ? item.averageCostStock * item.theoreticalStock
              : item.averageCost && item.theoreticalStock
                ? item.averageCost * item.theoreticalStock
                : 0
        ],
        []
      )
      .flat()
    : [];

  const totalTT = totalFlat.length ? totalFlat.reduce(reducer) : 0;

  const handleClose = (modal) => {
    setModalStates({ ...modalStates, [modal]: false });
    setCheckConsume(false);
    setStockProduct();
    setSkuModal([])
  };

  const addCountButton = () => (
    <Tooltip title="Contar item" aria-label="add">
      <Link to={`/inventario/contagem-avulsa/${paramsID}`}>
        <IconButton
          className="p0"
          edge="start"
          color="inherit"
          style={{ outline: 0 }}
          aria-label="add"
        >
          <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
        </IconButton>
      </Link>
    </Tooltip>
  );

  useEffect(()=>{
    if(modalStates.modalCards){
      getRelatedCards(stockProduct);
    }
  },[checkConsume])

  return (
    <Layout>
      <GlobalStyle />

      <TitleHead title="Estoque" />

      <Container>
        <PageTitleAndFilter
          title="Estoque"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          additionalbutton={addCountButton}
          getFilterhandle={getFilterhandle}
        />

        <table
          className="desktop withoutHover"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr className="headerTitle">
              <th
                style={{ width: '6%', fontSize: '10px' }}
                className="subtitle pr0"
              >
                TIPO{' '}
                <OrderBy
                  filterName="type"
                  handleFilter={handleOrdernationFilter}
                />
              </th>
              <th
                style={{ width: '22%', fontSize: '10px' }}
                className="subtitle"
              >
                PRODUTO{' '}
                <OrderBy
                  filterName="description"
                  handleFilter={handleOrdernationFilter}
                />
              </th>
              <th
                style={{ width: '9%', fontSize: '10px' }}
                className="subtitle"
              >
                CATEGORIA{' '}
                <OrderBy
                  filterName="category"
                  handleFilter={handleOrdernationFilter}
                />
              </th>
              <th
                colSpan="7"
                className="subtitle left p12"
                style={{
                  borderLeft: 'solid 2px #73004C',
                  background: '#73004C',
                  fontSize: '10px',
                  width: '54%'
                }}
              >
                POSIÇÃO / CUSTOS
              </th>
              <th
                className="subtitle left p12"
                style={{
                  borderLeft: 'solid 2px #001373',
                  background: '#001373',
                  fontSize: '10px',
                  width: '7%'
                }}
              >
                VENDAS
              </th>
              <th
                className="subtitle left"
                colSpan="2"
                style={{
                  borderLeft: 'solid 2px #313347',
                  fontSize: '10px',
                  width: '7%'
                }}
              >
                CATÁLOGO
              </th>
            </tr>

            <tr className="headerSubtitle">
              <th colSpan="3" />
              <th
                width="4%"
                className="subtitle left pl12"
                style={{ borderLeft: 'solid 2px #73004C', fontSize: '9px' }}
              >
                IDADE{' '}
                <OrderBy
                  filterName="age"
                  handleFilter={handleOrdernationFilter}
                  tinyArrow
                />
              </th>
              <th
                width="4%"
                className="subtitle right"
                style={{ fontSize: '9px' }}
              >
                CUSTO R${' '}
                <OrderBy
                  filterName={
                    parameters === 'LAST_PURCHASE'
                      ? 'lastCostStock'
                      : 'averageCostStock'
                  }
                  handleFilter={handleOrdernationFilter}
                  tinyArrow
                />
              </th>
              <th
                width="3%"
                className="subtitle center"
                style={{ fontSize: '9px' }}
              >
                QTDE{' '}
                <OrderBy
                  filterName="theoreticalStock"
                  handleFilter={handleOrdernationFilter}
                  tinyArrow
                />
              </th>
              <th
                width="2%"
                className="subtitle center"
                style={{ fontSize: '9px' }}
              >
                UN.
              </th>
              <th
                width="4%"
                className="subtitle right"
                style={{ fontSize: '9px' }}
              >
                TOTAL R${' '}
                <OrderBy
                  filterName="theoreticalCostTotalStock"
                  handleFilter={handleOrdernationFilter}
                  tinyArrow
                />
              </th>
              <th
                width="4.7%"
                className="subtitle right pl8"
                style={{ fontSize: '9px' }}
              >
                CONSUMO{' '}
                <OrderBy
                  filterName="consumed"
                  handleFilter={handleOrdernationFilter}
                  tinyArrow
                />
              </th>
              <th
                width="4.5%"
                className="subtitle right pl8"
                style={{ borderRight: 'solid 2px #001373', fontSize: '9px' }}
              >
                DURAÇÃO{' '}
                <OrderBy
                  filterName="duration"
                  handleFilter={handleOrdernationFilter}
                  tinyArrow
                />
              </th>
              <th
                width="5.8%"
                className="subtitle right"
                style={{ borderLeft: 'solid 2px #001373', fontSize: '9px' }}
              >
                TOTAL R${' '}
                <OrderBy
                  filterName="salesTotal"
                  handleFilter={handleOrdernationFilter}
                  tinyArrow
                />
              </th>

              <th
                style={{ borderLeft: 'solid 2px #313347' }}
                width="1.5%"
                className="subtitle center"
              >
                <TableIcon icon="sku" name="Skus" />
              </th>

              <th width="1.5%" className="subtitle center">
                <TableIcon icon="card" name="Fichas" />
              </th>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th
                  colSpan="17"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {dataStock?.length ? (
              dataStock.map((stock) => {
                let type = '';
                let ageLabel = '';
                let ageColor = '';
                let ageBackground = '';
                type = 'Produto';

                if (stock.lastTheoricalStock !== null) {
                  ageLabel = '1 dia';
                  ageColor = 'success';
                  ageBackground = ageColor;
                } else if (stock.age === null) {
                  ageLabel = '0 dias';
                } else if (stock.age === 0) {
                  ageLabel = 'Hoje';
                  ageColor = 'success';
                  ageBackground = ageColor;
                } else if (stock.age === 1) {
                  ageLabel = '1 dia';
                  ageColor = 'warning';
                  ageBackground = ageColor;
                } else {
                  ageLabel = `${stock.age.toFixed(0)} dias`;
                  ageColor = 'danger';
                  ageBackground = ageColor;
                }

                return (
                  <>
                    <tr key={stock.id} className="lineBorder">
                      <td className="description p12 pr0">
                        <span
                          style={{
                            background:
                              stock.type === 'INTERNAL' ? '#73004C' : '#996600',
                            color: '#FFF',
                            borderRadius: '20px',
                            padding: '2px 5px'
                          }}
                        >
                          {stock.type === 'INTERNAL' ? 'PROD' : 'MERC'}
                        </span>
                      </td>

                      <td className="description">
                        <div
                          className="cursorPointer lightBlue"
                          onClick={() => {
                            if (stock?.type === 'INTERNAL') {
                              getOptions(
                                `${environments.catalog}/cards/${stock?.originIdCard}`,
                                'cards',
                                selectedCard,
                                setSelectedCard,
                                getId,
                                null,
                                null,
                                null,
                                true
                              );
                            } else {
                              getOptions(
                                `${environments.catalog}/products/${stock?.id}`,
                                'products',
                                selectedProduct,
                                setSelectedProduct,
                                getId,
                                null,
                                null,
                                null,
                                true
                              );
                            }

                            setOnlyViewProduct(true);
                            setModalStates({
                              ...modalStates,
                              modalProductInfos: true
                            });
                          }}
                        >
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            placement="right"
                            title={
                              <span
                                className="dBlock"
                                style={{ padding: '4px 7px' }}
                              >
                                {stock?.id} - {stock.description}
                              </span>
                            }
                          >
                            <span>
                              {stock?.description?.length > 36
                                ? `${stock?.description?.substring(0, 36)}...`
                                : stock?.description}
                            </span>
                          </Tooltip>
                        </div>
                      </td>

                      <LightTooltip
                        title={
                          stock !== null && stock !== undefined
                            ? stock?.categories?.family
                            : null
                        }
                        placement="top"
                        aria-label="Family"
                      >
                        <td className="description m0 p0">
                          {' '}
                          {stock?.categories?.description &&
                            ValidationLength(stock.categories.description, 15)}
                        </td>
                      </LightTooltip>

                      <td
                        className="description center p8"
                        style={{ borderLeft: 'solid 2px #73004C' }}
                      >
                        {ageLabel !== '-' ? (
                          <ButtonChip
                            style={{ width: '65px' }}
                            onClick={() => handleClickProducts(stock)}
                            color={ageColor}
                            background={ageBackground}
                            label={ageLabel}
                          />
                        ) : (
                          ageLabel
                        )}
                      </td>

                      <td align="right" className="description right">
                        {parameters === 'LAST_PURCHASE'
                          ? stock.lastCostStock
                            ? parseFloat(stock.lastCostStock).toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3
                              }
                            )
                            : '0,000'
                          : stock.averageCostStock
                            ? parseFloat(stock.averageCostStock).toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3
                              }
                            )
                            : '0,000'}
                      </td>

                      <td align="right" className="description right">
                        {stock.isBreak && stock.orders.length > 0 ? (
                          <HtmlTooltip
                            title={
                              <>
                                Skus com ruptura de estoque:
                                <br />
                                Pedidos em aberto
                              </>
                            }
                          >
                            <ButtonChip
                              align="right"
                              style={{
                                textAlign: 'right',
                                height: '20px',
                                width: '65px',
                                marginRight: '6px'
                              }}
                              className="description right"
                              label={
                                stock.theoreticalStock
                                  ? stock.theoreticalStock.toLocaleString(
                                    'pt-br',
                                    {
                                      minimumFractionDigits: 3,
                                      maximumFractionDigits: 3
                                    }
                                  )
                                  : '0'
                              }
                              color="warning"
                              background="warning"
                              onClick={() => handleClickProducts(stock)}
                            />
                          </HtmlTooltip>
                        ) : stock.isBreak && stock.orders.length <= 0 ? (
                          <HtmlTooltip
                            title={
                              <>
                                Skus com ruptura de estoque:
                                <br />
                                Sem pedidos em aberto
                              </>
                            }
                          >
                            <ButtonChip
                              align="right"
                              style={{
                                textAlign: 'right',
                                height: '20px',
                                width: '65px',
                                marginRight: '6px'
                              }}
                              label={
                                stock.theoreticalStock
                                  ? parseFloat(
                                    stock.theoreticalStock
                                  ).toLocaleString('pt-br', {
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3
                                  })
                                  : '0,000'
                              }
                              color="danger"
                              background="danger"
                              onClick={() => handleClickProducts(stock)}
                            />
                          </HtmlTooltip>
                        ) : (
                          <ButtonChip
                            align="right"
                            style={{
                              textAlign: 'right',
                              height: '20px',
                              width: '65px',
                              marginRight: '6px'
                            }}
                            label={
                              stock.theoreticalStock
                                ? parseFloat(
                                  stock.theoreticalStock
                                ).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3
                                })
                                : '0,000'
                            }
                            color="dark"
                            onClick={() => handleClickProducts(stock)}
                          />
                        )}
                      </td>

                      <td className="description center">
                        {stock?.consumptionUnitsPrimary
                          ? stock.consumptionUnitsPrimary.abbreviation
                          : '-'}

                        {stock?.consumptionUnitsSecondary
                          ? `/${stock.consumptionUnitsSecondary.abbreviation}`
                          : ''}
                      </td>

                      <td
                        align="right"
                        className="description right p8"
                        style={{
                          borderLeft: 'solid 1px #ECEDF1',
                          backgroundColor: 'rgba(115, 0, 76, .15)',
                          color: '#313347'
                        }}
                      >
                        {parameters === 'LAST_PURCHASE'
                          ? stock.lastCostStock && stock.theoreticalStock
                            ? parseFloat(
                              stock.lastCostStock * stock.theoreticalStock
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                            : '0,00'
                          : stock.averageCostStock && stock.averageCostStock
                            ? parseFloat(
                              stock.averageCostStock * stock.theoreticalStock
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            })
                            : '0,00'}
                        &nbsp;
                      </td>

                      <td align="right" className="description right">
                        {stock.consumed
                          ? stock.consumed.toLocaleString('pt-br', {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3
                          })
                          : '0,000'}
                      </td>

                      <td align="center" className="description center">
                        <Link
                          className={`${!stock.duration && 'default cursorAuto'
                            }`}
                          onClick={() => {
                            if (!stock.duration) return;

                            getDuration(
                              stock.id,
                              stock.theoreticalStock,
                              stock
                            );
                          }}
                        >
                          {stock.duration
                            ? `${stock.duration} ${stock.duration > 1 ? 'dias' : 'dia'
                            }`
                            : '-'}
                        </Link>
                      </td>

                      <td
                        align="right"
                        className="description right p8"
                        style={{
                          borderLeft: 'solid 2px #001373',
                          backgroundColor: 'rgba(0, 19, 115, .15)',
                          color: '#313347'
                        }}
                      >
                        {stock.salesTotal
                          ? stock.salesTotal.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                          : '0,00'}
                        &nbsp;
                      </td>

                      <td
                        className="description center"
                        style={{ borderLeft: 'solid 2px #313347' }}
                      >
                        <span className="lightBlue cursorPointer" onClick={() => handleClickOpenSkus(stock)}>
                          {stock?.uniqueSkus || 0}
                        </span>
                      </td>

                      <td className="description center pr8">
                        <Link onClick={() => handleClickOpenCards(stock)}>
                          {stock?.cardsInExtracts || 0}
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="13">
                        <hr style={{ marginBottom: '-1px' }} />
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <th
                  colSpan="13"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  Nada foi encontrado
                </th>
              </tr>
            )}

            <tr>
              <td colSpan="13">
                <hr style={{ borderBottom: '1px solid #000' }} />
              </td>
            </tr>

            <tr>
              <td className="subtitle p12">TOTAL</td>

              <td className="subtitle fontSizeDefault">
                {dataStock.length} produtos
              </td>

              <td colSpan="5" />

              <td
                className="subtitle right fontSizeDefault"
                style={{ backgroundColor: '#73004C', color: '#FFF' }}
              >
                R${' '}
                {totalTT.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
                &nbsp;
              </td>
            </tr>
          </tbody>
        </table>

        <Suspense fallback={<span />}>
          <SkuDialog
            getId={getId}
            selectedSku={selectedSku}
            setSelectedSku={setSelectedSku}
            openModal={modalStates}
            handleClose={handleClose}
            stockDescriptionProduct={stockProduct?.description}
            skuModal={skuModal}
            userInfo={userInfo}
            modalStates={modalStates}
            setModalStates={setModalStates}
          />
        </Suspense>

        <Suspense fallback={<span />}>
          <DurationDialog
            openModal={modalStates}
            handleClose={handleClose}
            durationData={durationData}
          />
        </Suspense>

        <Suspense fallback={<span />}>
          <StockRuptureDialog
            openModal={modalStates}
            handleClose={handleClose}
            durationData={durationData}
            skuOrderModal={skuOrderModal}
            qtdProductDescription={qtdProductDescription}
          />
        </Suspense>

        {modalStates.modalProduct && (
          <Suspense fallback={<span />}>
            <TheoryStockDialog
              getId={getId}
              openModal={modalStates}
              handleClose={handleClose}
              productExtractDescription={productExtractDescription}
              productAge={productAge}
              productModal={productModal}
              setProductModal={setProductModal}
              parameters={parameters}
              checkConsume={checkConsume}
              setCheckConsume={setCheckConsume}
              originPage="stocksPage"
              selectedCard={selectedCard}
              setSelectedCard={setSelectedCard}
            />
          </Suspense>
        )}

        <Suspense fallback={<span />}>
          <CardsDialogs
            getId={getId}
            openModal={modalStates}
            handleClose={handleClose}
            stockDescriptionProduct={stockProduct?.description}
            stockProduct={stockProduct}
            stockCardDescription={stockCardDescription}
            stockAge={stockAge}
            getRelatedCards={getRelatedCards}
            checkConsume={checkConsume}
            setCheckConsume={setCheckConsume}
            stockUnit={stockUnit}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
          />
        </Suspense>

        <Suspense fallback={<span />}>
          <EmptyDialog openModal={modalStates} handleClose={handleClose} />
        </Suspense>

        {modalStates.modalProductInfos && productInfo?.data && (
          <RegisterProductModal
            dialogOpen={modalStates.modalProductInfos}
            isModal
            onlyView={onlyViewProduct}
            consumptionUnitOptions={consumptionUnits}
            periodicityOptions={periodicities}
            storageLocationOptions={storageLocations}
            onClose={() => {
              setEditProducts('');
              setModalStates({ ...modalStates, modalProductInfos: false });
            }}
          />
        )}

        {modalStates.modalSkuInfos &&
          selectedSku &&
          unitsMeasurements.length &&
          brands.length &&
          packageTypes.length &&
          consumptionUnits.length &&
          storageLocations.length &&
          periodicities.length ? (
          <SkuModal
            fullScreen={false}
            maxWidth="sm"
            open={modalStates.modalSkuInfos}
            data={selectedSku?.providers || []}
            getId={getId}
            editMode
            sku={selectedSku}
            userInfo={userInfo}
            providers={providers}
            packageTypes={packageTypes}
            categories={categories}
            brands={brands}
            storageLocations={storageLocations}
            periodicities={periodicities}
            unitsMeasurements={unitsMeasurements}
            consumptionUnits={consumptionUnits}
            skus={[]}
            onClose={() => {
              setModalStates({ ...modalStates, modalSkuInfos: false });

              setSelectedSku('');
            }}
            adminCatalogModal={isAdminPage}
            onlyViewMode
          />
        ) : null}

        {selectedCard && (
          <TechnicalSheetDialog
            maxWidth={null}
            open={modalStates.modalTechnicalSheet}
            handleClose={() =>
              setModalStates({ ...modalStates, modalTechnicalSheet: false })
            }
            cardDataTable={cardDataTable}
            cardTransformation={cardTransformation}
            selectedCard={selectedCard}
            ucPrimarySelectedId={ucPrimarySelectedId}
          />
        )}
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...UserActions, ...ProductsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StocksList);
