import styled, { css } from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
`;

export const TextFieldDiv = styled.div`
  display: flex;
  input {
    font-size: 12px;
    padding: 2px 2px 2px 0;
  }
`;

export const FieldLine = styled.div`
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate && gridTemplate};
  padding: 8px 12px;
  gap: 30px;
  height: 7.8vh;
`;

export const ButtonsWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  padding: 16px 8px 16px;
`;

export const EmptySku = styled.div`
  width: 100%;
  text-align: center;
`;

export const WithAutoCompleteWrapper = styled.div`
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  img {
    width: 12px;
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const RestaurantDescription = styled.div`
  text-transform: uppercase;
  min-width: 87px;
  padding: 8px 8px 8px 12px;
  color: #ffffff;
  background: #5062f0;
  border-radius: 4px 0px 0px 0px;
`;

export const TopFormContainerWrapper = styled.div`
  padding: 8px;
`;

export const DescriptionContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
  border-radius: 0px 4px 4px 4px;
  position: relative;

  ${({ withMargin }) =>
    withMargin &&
    css`
      margin: 8px 0px 8px;
    `}
`;

export const TableWrapper = styled.div`
  overflow: auto;
  /* Neutro/N0 */

  background: #ffffff;
  /* Shadow/Nível 1 */

  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
  border-radius: 8px 8px 4px 4px;
  max-height: 39.8vh;
`;
