import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
  width: ${({ dynamicWidth }) => (dynamicWidth ? 'inherit' : '100%')};
  width: 100%;
  font-style: normal;
  table-layout: ${({ tableFixed }) => (tableFixed ? 'fixed' : 'auto')};
  empty-cells: hide;
  background: #fff;
  border-spacing: 1px 0;
  border-collapse: collapse;
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #d0d3e2;
  /* box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25); */
  padding-bottom: 15px;
  display: table;
  font-family: 'Lato';

  @media only screen and (max-width: 960px) {
    display: none;
  }

  thead {
    tr {
      th:first-of-type {
        border-top-left-radius: 4px;
      }
      th:last-of-type {
        border-top-right-radius: 4px;
      }
    }
  }
  tbody {
    tr {
      ${(eachTD) =>
        eachTD &&
        css`
          td:nth-of-type(${eachTD}) {
            border-right: 1px solid #d2d3d7;
          }
        `}
    }
  }
`;

export const StyledTR = styled.tr`
  background-color: ${({ backGroundColor }) =>
    backGroundColor || 'transparent'};
  color: ${({ backGroundColor }) => (backGroundColor ? 'white' : '#0B0D1D')};
  ${({ withLine }) =>
    withLine &&
    css`
      border-bottom: 1px solid #ecedf1;
    `}
  ${({ hoverActive }) =>
    hoverActive &&
    css`
      &:hover {
        background-color: #e6e8ff;

        td {
          &:first-of-type {
            background: #ffffff;
          }
        }
      }
    `}
    th {
    position: sticky;
    top: 16.5px;
    z-index: 999;
  }
`;

export const StyledTH = styled.th`
  border-right: ${({ borderRight }) => borderRight && '2px solid #d2d3d7'};
  padding: ${({ padding }) => padding || '8px'};
  font-size: 9px;
  line-height: 12px;
  font-weight: 600;
  background: ${({ backGroundColor }) => backGroundColor || '#313347'};
  border-color: ${({ borderColor }) => borderColor || '#d2d3d7'};
  color: ${({ fontColor }) => fontColor || 'inherit'};
`;

export const StyledTD = styled.td`
  position: relative;
  min-width: ${({ size }) => size && size};
  max-width: ${({ maxSize }) => maxSize && maxSize};
  width: ${({ size }) => size && size};

  border-right: ${({ borderRight }) => borderRight && '2px solid'};
  border-color: ${({ borderColor }) => borderColor || '#d2d3d7'};
  background-color: ${({ backGroundColor }) => backGroundColor};
  border-bottom: 1px solid #b0b5c9;
  border-top: 1px solid #b0b5c9;
  padding: ${({ padding }) => padding || '8px 8px 0px 8px'};
  font-size: 12px;
  line-height: 17px;
  text-align: ${({ align }) => align && 'right'};
  ${({ buttonRectColor }) =>
    buttonRectColor &&
    css`
      background-color: ${buttonRectColor.background};
      color: ${buttonRectColor.color};
    `}
  ${({ topAlign }) =>
    topAlign &&
    css`
      position: relative;
      vertical-align: top;
    `};
  ${({ cursorPointer }) =>
    cursorPointer &&
    css`
      cursor: pointer;
    `};
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyleTableBody = styled.tbody`
  background-color: white;
  border-radius: 4px;
`;

export const SubStyledTR = styled.tr`
  background-color: #f2f2f4;
  color: #666770;
  font-size: 9px;
  padding: 8px;

  th {
    position: sticky;
    top: 44px;
    z-index: 999;
    background-color: #f2f2f4;
  }
`;

export const SubStyledTH = styled.th`
  border-right: ${({ borderRight }) => borderRight && '2px solid'};
  border-color: ${({ borderColor }) => borderColor || '#d2d3d7'};
  padding: ${({ padding }) => padding || '4px'};
  padding-left: 8px;
  text-align: ${({ alignRight }) => alignRight && 'right'};
  color: ${({ fontColor }) => fontColor && fontColor};
  white-space: nowrap;
  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}
`;

export const InputWrapper = styled.div`
  display: ${({ display }) => display || 'flex'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  padding: ${({ paddingDiv }) => paddingDiv || '0px'};
  min-height: 20px;
  ${({ specialDisplayCollumn }) =>
    specialDisplayCollumn &&
    css`
      align-self: end;
    `}
  ${({ alignCenter }) =>
    alignCenter &&
    css`
      text-align: center;
    `}
    ${({ typeAlign }) =>
    typeAlign &&
    css`
      float: left;
    `}
    ${({ inputSize }) =>
    inputSize &&
    css`
      width: ${inputSize};
    `}
    ${({ contentBlueCard }) =>
    contentBlueCard &&
    css`
      background-color: ${contentBlueCard.backGroundColor};
      height: 20px;
      padding: 5px;
      color: ${contentBlueCard.color};
      border-radius: 4px;
      ${contentBlueCard.border &&
      css`
        border: 1px solid ${contentBlueCard.border};
      `}
    `}
    ${({ invertOrder }) =>
    invertOrder &&
    css`
      order: 2;
    `}
    ${({ truncateBySize }) =>
    truncateBySize &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
    ${({ truncateonTwoLine }) =>
    truncateonTwoLine &&
    css`
      display: -webkit-box;
      overflow: hidden;
      white-space: break-spaces;
      text-overflow: ellipsis;
      span {
        white-space: break-spaces;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    `};

  .intentionIcon {
    margin-right: 8px;

    svg {
      width: 20px;
      margin-top: -4px;
    }
  }
`;

export const SublineWrapper = styled.p`
  color: ${({ color }) => color || '#0E1337'};
  font-size: ${({ fontSize }) => fontSize || '12px'};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
  width: ${({ width }) => width};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  line-height: 12px;
  white-space: break-spaces;
`;

export const TDContainer = styled.div`
  ${({ contentBlueCard }) =>
    contentBlueCard &&
    css`
      margin-top: 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
    `};
  ${({ specialDisplayCollumn }) =>
    specialDisplayCollumn &&
    css`
      /* display: grid; */
      display: flex;
      flex-flow: column;
    `};
  width: ${({ containerWidth }) => containerWidth};
  padding: ${({ tdPadding }) => tdPadding};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
  ${({ horizontalAlignment }) =>
    horizontalAlignment &&
    css`
      display: flex;
      justify-content: end;
      width: 100%;
    `};
`;

export const SubLineContainer = styled.div`
  box-shadow: ${({ boxShadow }) => boxShadow};
  border-radius: ${({ borderRadius }) => borderRadius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin-top: ${({ marginTop }) => marginTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  padding: ${({ padding }) => padding};
  ${({ invertOrder }) =>
    invertOrder &&
    css`
      margin-top: ${({ marginTop }) => marginTop};
    `};
  ${({ rectStyles }) =>
    rectStyles &&
    css`
      background-color: ${rectStyles?.background};
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p {
        color: ${rectStyles?.color};
        font-size: ${rectStyles?.fontSize};
        /* height: 10%; */
        margin: 0;
      }
    `};
  ${({ cursorPointer }) =>
    cursorPointer &&
    css`
      cursor: pointer;
    `};
`;
