import React from 'react';

import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import Routes from 'routes/Routes';
import { store } from 'store/index';
import history from 'utils/history';

import 'antd/dist/antd.css';
import './App.scss';
import 'fonts/style.css';

function App() {
  return (
    <Provider store={store}>
      <Router history={history} basename={process.env.REACT_APP_BASENAME || ''}>
        <Routes />
      </Router>
    </Provider>
  );
}

export default App;
