import React, { useState } from 'react';

import {
  LinearProgress,
  Grid,
  Snackbar,
  makeStyles
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import Layout from 'containers/layouts/layout';
import * as UserActions from 'store/actions/user';
import Environment from 'utils/environments';
import history from 'utils/history';
import { Container, GlobalStyle } from 'styles/general';
import TitleHead from 'components/TitleHead';
import PageTitleAndFilter from 'components/PageTitleAndFilter';

function RegisterUnitMeasure({ userInfo, unitsMeasurementsInfo }) {
  const environments = Environment(process.env.REACT_APP_ENV);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit } = useForm();
  const [alertErr, setAlertErr] = useState(false);
  const onDismiss = () => setAlertErr(false);
  const [editMode, setEditMode] = useState(
    unitsMeasurementsInfo.action
      ? unitsMeasurementsInfo.action.toLowerCase() === 'edit'
      : false
  );
  const [unitsId, setUnitsId] = useState(
    editMode ? unitsMeasurementsInfo.data.id : ''
  );
  const [description, setDescription] = useState(
    editMode ? unitsMeasurementsInfo.data.description : ''
  );
  const [abbreviation, setAbbreviation] = useState(
    editMode ? unitsMeasurementsInfo.data.abbreviation : ''
  );
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const onSubmit = (values) => {
    setLoading(true);
    if (editMode) {
      axios
        .put(
          `${environments.catalog}/unitsMeasurements/${unitsId}`,
          {
            method: 'PUT',
            description,
            abbreviation,
            user: userInfo.user
          },
          config
        )
        .then((res) => {
          setOpen(true);
          setLoading(false);
          // const { data } = res
        })
        .catch((error) => {
          setLoading(false);
          setAlertErr(true);
        });
    } else {
      axios
        .post(
          `${environments.catalog}/unitsMeasurements`,
          {
            method: 'POST',
            description,
            abbreviation,
            user: userInfo.user
          },
          config
        )
        .then((res) => {
          setOpen(true);
          setLoading(false);
          // const { data } = res
        })
        .catch((error) => {
          setLoading(false);
          setAlertErr(true);
        });
    }
  };

  const handleClose = (event, reason) => {
    history.push('/admin/unidades-de-medidas/0');
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const form = (
    <Container>
      {alertErr ? (
        <Alert
          style={{ width: '30%' }}
          color="danger"
          isOpen={alertErr}
          toggle={onDismiss}
        >
          Erro ao cadastrar
        </Alert>
      ) : null}

      {loading ? <LinearProgress /> : null}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Descriçao"
              name="description"
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              placeholder="Descriçao"
              className="textFieldSmall"
              defaultValue={
                editMode ? unitsMeasurementsInfo.data.description : null
              }
              fullWidth
              required="required"
              inputProps={{ maxLength: 30 }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="Abreviação"
              name="abbreviation"
              onChange={(e) => setAbbreviation(e.target.value)}
              variant="outlined"
              placeholder="Abreviação"
              className="textFieldSmall ml20"
              defaultValue={
                editMode ? unitsMeasurementsInfo.data.abbreviation : null
              }
              fullWidth
              required="required"
              inputProps={{ maxLength: 3 }}
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  );

  return (
    <Layout>
      <TitleHead title="Unidades de Medida" />
      <GlobalStyle />

      <PageTitleAndFilter
        title="Cadastro de unidades de medida"
      />

      <div className="cardDefault">
        {form}
      </div>

      <Grid className="dFlex justifyEnd" item xs={12} sm={12} style={{ marginTop: 20 }}>
        <Link to="/admin/unidades-de-medidas/0">
          <Button 
            design="outlined" 
            label="Voltar"
            className="defaultButton backButton"
          />
        </Link>

        <Button
          type="submit"
          className="defaultButton submitButton"
          label="Salvar"
          design="contained"
          style={{ marginLeft: '20px' }}
        />
      </Grid>

      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Cadastro realizado com sucesso!
        </Alert>
      </Snackbar>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  unitsMeasurementsInfo: state.unitsMeasurements
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterUnitMeasure);
