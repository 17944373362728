const unformatDocumentNumber = (value, typeDocumentNumber) => {
  if (value) {
    if (value.length === 14 || typeDocumentNumber.toUpperCase() === 'CNPJ') {
      if (value.length === 14) {
        return value.replace(/\./g, '').replace(/\-/g, '').replace('/', '');
      }
    } else if (
      value.length === 11 ||
      typeDocumentNumber.toUpperCase() === 'CPF'
    ) {
      if (value.length === 11) {
        return value.replace(/\./g, '').replace(/\-/g, '');
      }
    }
  }

  return value;
};

export default unformatDocumentNumber;
