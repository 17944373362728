import React, { useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';

import Button from 'components/BloomForms/Button';
import makeGet from 'services/useFetch/makeGet';
import makePut from 'services/useFetch/makePut';

import {
  OtherStocksWrapper,
  Content,
  ContentHead
} from '../OtherStocksDialog/index.style';

function FinishDialog({
  reference,
  currentData,
  onClose,
  params,
  countID,
  paramsID,
  setRedirectToCounts
}) {
  const [uncountedData, setUncountedData] = useState({});
  const [loading, setLoading] = useState(false);

  const finishCount = () => {
    setLoading(true);

    const handleFinishCount = (status, response) => {
      if (status) {
        setRedirectToCounts(true);
      }
      setLoading(false);
    };

    const { products = [], user, setup: { id: setupId } = {} } = uncountedData;

    const content = products?.map(({ id, realStock }) => ({
      productId: id,
      restaurantId: paramsID,
      user,
      realStock,
      setupId
    }));

    makePut(
      'inventories',
      content,
      handleFinishCount,
      `${countID}/uncountedProducts`
    );
  };

  const handleResponseData = (data) => {
    const { products = [] } = data;
    if (!products.length) {
      setRedirectToCounts(true);
      return;
    }

    setLoading(false);
    setUncountedData(data);
  };

  useEffect(() => {
    setLoading(true);
    makeGet('inventories', params, handleResponseData, countID);
  }, []);

  return (
    <OtherStocksWrapper>
      <Content ref={reference}>
        <ContentHead>Finalizar</ContentHead>

        <div style={{ padding: '8px' }}>
          {loading && <LinearProgress />}
          
          {!loading && (
            <>
              <p>
                Ainda existem contagens não efetuadas. Quer finalizar mesmo
                assim?
              </p>

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button label="Cancelar" secondary onClick={onClose} />
                <Button label="Finalizar" primary onClick={finishCount} />
              </div>
            </>
          )}
        </div>
      </Content>
    </OtherStocksWrapper>
  );
}

export default FinishDialog;
