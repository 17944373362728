/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Popover,
  Snackbar,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { TreeSelect } from 'antd';
import Axios from 'axios';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import FullScreenLoading from 'components/FullScreenLoading';
import SkusTable from 'pages/cataloguing/components/SkusTable';
import getOptions from 'services/getOptions';
import * as UserActions from 'store/actions/user';
import Environment from 'utils/environments';

const useStyles = makeStyles(() => ({
  input: {
    fontFamily: 'Lato',
    fontSize: 12
  },
  option: {
    fontFamily: 'Lato',
    fontSize: 12
  }
}));

function ConfigSkuPopover({
  popoverOpen,
  anchorEl,
  handleClosePopover,
  selectedSku,
  productOptions,
  categoryOptions,
  unitsMeasurementOptions,
  brandOptions,
  consumptionUnitOptions,
  storageLocationOptions,
  periodicityOptions,
  handleUpdateMainList
}) {
  const filter = createFilterOptions();
  const userInfo = useSelector((currState) => currState.user);
  const getId = userInfo.companiesActive.id;
  const classes = useStyles();
  const environments = Environment(process.env.REACT_APP_ENV);
  const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';
  const [openedSkus, setOpenedSkus] = useState([]);
  const [debounceTimeList, setDebounceTimeList] = useState([]);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [data, setData] = useState([]);
  const [brands, setBrands] = useState(brandOptions);
  const [products, setProducts] = useState(productOptions);
  const [categories, setCategories] = useState(categoryOptions);
  const [periodicities, setPeriodicities] = useState(periodicityOptions);
  const [storageLocations, setStorageLocations] = useState(
    storageLocationOptions
  );
  const [invalidEan, setInvalidEan] = useState(false);
  const [purchaseSku, setPurchaseSku] = useState(0);
  const [lossPercentageCategory, setLossPercentageCategory] = useState(null);
  const [validateProductArea, setValidateProductArea] = useState(false);
  const [validProductCategory, setValidProductCategory] = useState(true);
  const [validUnitsMeasurements, setValidUnitsMeasurements] = useState(true);
  const [validDescription, setValidDescription] = useState(true);
  const [validQuantity, setValidQuantity] = useState(true);
  const [validProductDescription, setValidProductDescription] = useState(true);
  const [validProductPrimaryUnit, setValidProductPrimaryUnit] = useState(true);
  const [errUnitsMsg, setErrUnitsMsg] = useState(false);
  const [disableProductUnit, setDisableProductUnit] = useState(false);
  const [unifiedSkus, setUnifiedSkus] = useState(false);
  const [unit, setUnit] = useState();
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unitsMeasurements, setUnitsMeasurements] = useState([
    {
      id: 4,
      description: 'grama',
      abbreviation: 'g',
      hasSku: true
    },
    {
      id: 1,
      description: 'litro',
      abbreviation: 'lt',
      hasSku: true
    },
    {
      id: 5,
      description: 'miligrama',
      abbreviation: 'mg',
      hasSku: false
    },
    {
      id: 6,
      description: 'mililitro',
      abbreviation: 'ml',
      hasSku: true
    },
    {
      id: 2,
      description: 'quilograma',
      abbreviation: 'kg',
      hasSku: true
    },
    {
      id: 3,
      description: 'unidade',
      abbreviation: 'un',
      hasSku: true
    }
  ]);
  const [consumptionUnits, setConsumptionUnits] = useState([
    {
      id: 1,
      description: 'litro',
      abbreviation: 'lt'
    },
    {
      id: 2,
      description: 'quilograma',
      abbreviation: 'kg'
    },
    {
      id: 3,
      description: 'unidade',
      abbreviation: 'un'
    }
  ]);
  const [selectedItems, setSelectedItems] = useState({
    item: {},
    product: {},
    storage: [],
    frequencies: [],
    category: {},
    unit: {},
    primaryUnit: {},
    secondaryUnit: {}
  });
  const [userGroups, setUserGroups] = useState({
    groups: [
      {
        subGroups: [
          {
            stores: [{}]
          }
        ]
      }
    ]
  });
  const [jsonBody, setJsonBody] = useState({
    product: {
      useOnCards: true,
      takeInventory: true,
      status: 'ACTIVE',
      description: null,
      restaurantId: getId || null,
      salePrice: null,
      discountPrice: null,
      theoreticalStock: null,
      realStock: null,
      duration: null,
      lastPrice: null,
      averagePrice: null,
      averageCost: null,
      lastCost: null,
      averageCostStock: null,
      lastCostStock: null,
      margin: null,
      markup: null,
      cmv: null,
      curve: null,
      user: userInfo.user,
      consumptionUnitsPrimaryId: null,
      consumptionUnitsSecondaryId: null,
      categoryId: null,
      category: undefined,
      quantityMinStock: 0,
      quantityMaxStock: 0,
      originIdCard: null,
      productsGroupId: null,
      approximateCost: null,
      skus: [],
      storageLocations: [],
      unifiedSkus
    }
  });
  const [skuDefaultDescription, setSkuDefaultDescription] = useState({
    product: '',
    brand: '',
    tags: '',
    packageType: '',
    quantity: '',
    unit: ''
  });

  const [restaurantsId, setRestaurantsId] = useState(
    userInfo.companies.groups[0].subGroups[0].stores
  );
  const [restaurantGroups, setRestaurantGroups] = useState([]);
  const [restaurantSubGroups, setRestaurantSubGroups] = useState([]);
  const [restaurantStores, setRestaurantStores] = useState([]);

  const getRestaurantsIds = useCallback(
    () => restaurantsId.map((restaurant) => restaurant._id),
    [restaurantsId]
  );

  /* Restaurant Groups Obj Builder */
  const pluckIds = (arr) => arr.map((obj) => obj.id);

  const buildStores = (subGroup) => {
    const stores = subGroup.stores.filter((store) =>
      pluckIds(restaurantStores).includes(store.id)
    );

    return stores.map((store) => ({
      id: store.id,
      name: store.name
    }));
  };

  const buildSubGroups = (group) => {
    const subGroups = group.subGroups.filter((subGroup) =>
      pluckIds(restaurantSubGroups).includes(subGroup.id)
    );

    return subGroups.map((subGroup) => ({
      id: subGroup.id,
      name: subGroup.name,
      stores: buildStores(subGroup)
    }));
  };

  const formatThousandNumbers = (number) =>
    number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const validateProductFields = () =>
    !!jsonBody?.product?.consumptionUnitsPrimaryId ||
    !!jsonBody?.product?.description ||
    !!jsonBody?.product?.category;

  const buildSkuDefaultDescription = (sku, onlyProduct = false) => {
    if (sku) {
      const { product, brand, tags, packageType, quantity, unit } =
        sku?.skuDefaultDescription;

      if (onlyProduct) {
        return product ? `${product}` : '';
      }

      return `
          ${brand ?? brand}
          ${
            tags
              ? `${tags.map((tag) => tag.description)}`.replace(/,/g, ' ')
              : ''
          }
          ${packageType ?? packageType}
          ${quantity ?? formatThousandNumbers(quantity)}${unit ?? unit}`
        ?.replace(/\s\s+/g, ' ')
        ?.trim();
    }
    if (onlyProduct) {
      return skuDefaultDescription.product
        ? `${skuDefaultDescription.product}`
        : '';
    }

    return `
          ${skuDefaultDescription.brand}
          ${
            skuDefaultDescription?.tags
              ? `${skuDefaultDescription?.tags?.map(
                  (tag) => tag.description
                )}`?.replace(/,/g, ' ')
              : ''
          }
          ${skuDefaultDescription.packageType}
          ${
            skuDefaultDescription?.quantity
              ? formatThousandNumbers(skuDefaultDescription.quantity)
              : ''
          }${skuDefaultDescription.unit}`
      ?.replace(/\s\s+/g, ' ')
      ?.trim();
  };

  const updateSkuDefaultDescription = () => {
    jsonBody.sku = {
      ...jsonBody.sku,
      description: jsonBody.sku.description
    };
  };

  const parseRestaurantsBody = useCallback(
    () =>
      restaurantsId.map((restaurant) => ({
        description: restaurant.companyName,
        restaurantId: restaurant._id,
        resales: restaurant.resales ? restaurant.resales : false,
        itemPriority: restaurant.itemPriority ? restaurant.itemPriority : false,
        brandFavorite: restaurant.brandFavorite
          ? restaurant.brandFavorite
          : false,
        brandAccepted: restaurant.brandAccepted
          ? restaurant.brandAccepted
          : false,
        purchaseAverage: restaurant.purchaseAverage
          ? restaurant.purchaseAverage
          : 0,
        purchaseLast: restaurant.purchaseLast ? restaurant.purchaseLast : 0,
        unityCost: restaurant.unityCost ? restaurant.unityCost : 0
      })),
    [restaurantsId]
  );

  function checkProperties(obj) {
    if (obj.description !== null) {
      for (const key in obj) {
        if (
          key === 'useOnCards' ||
          key === 'takeInventory' ||
          key === 'consumptionUnitsPrimary' ||
          key === 'consumptionUnitsPrimaryId' ||
          key === 'status'
        ) {
          continue;
        } else if (
          obj[key] !== null &&
          obj[key] !== '' &&
          obj[key] !== [] &&
          obj[key] !== undefined
        ) {
          return false;
        }
      }
    }

    return true;
  }

  const buildBody = () => {
    const uniqueStorageLocations = selectedItems?.product?.storage?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    const uniqueFrequencies = selectedItems?.product?.frequencies?.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    const { id } = jsonBody.sku;
    jsonBody.sku = {
      skus: [
        {
          ...jsonBody.sku,
          id: jsonBody.sku.id,
          ean:
            jsonBody.sku.ean && jsonBody.sku.ean.toUpperCase() === 'SEM GTIN'
              ? null
              : jsonBody.sku.ean,
          description: `${
            jsonBody.sku.descriptionLabel || jsonBody.sku.description
          } ${buildSkuDefaultDescription()}`
            ?.replace(/\s{2,}/g, ' ')
            ?.trim(),
          descriptionLabel:
            jsonBody.sku.descriptionLabel || jsonBody.sku.description,
          providers: data.map((item) => ({
            ...item,
            skuId: id,
            purchaseAverageUnitary:
              parseFloat(item.purchaseAverage) / parseInt(item.quantity, 10),
            purchaseLastUnitary:
              parseFloat(item.purchaseLast) / parseFloat(item.quantity),
            id: item.id ? item.id : null
          })),
          restaurants: [
            ...parseRestaurantsBody(),
            {
              description: userInfo.companiesActive.name,
              restaurantId: userInfo.companiesActive.id,
              resales: jsonBody.sku.restaurants
                ? jsonBody.sku.restaurants.resales
                : false,
              itemPriority: jsonBody.sku.restaurants
                ? jsonBody.sku.restaurants.itemPriority
                : false,
              brandFavorite: jsonBody.sku.restaurants
                ? jsonBody.sku.restaurants.brandFavorite
                : false,
              brandAccepted: jsonBody.sku.restaurants
                ? jsonBody.sku.restaurants.brandAccepted
                : false,
              purchaseAverage: data.length
                ? parseFloat(data[0].purchaseAverage) /
                  parseInt(data[0].quantity, 10)
                : 0,
              purchaseLast: data.length
                ? parseFloat(data[0].purchaseLast) /
                  parseFloat(data[0].quantity)
                : 0,
              unityCost: parseFloat(
                purchaseSku
                  ? purchaseSku.toString().replace(',', '.')
                  : data.length
                  ? data[0].purchaseLastUnitary / data[0].quantity
                  : 0
              )
            }
          ],
          tags: jsonBody.tags
        }
      ],
      product: checkProperties(jsonBody.product)
        ? null
        : {
            ...jsonBody.product,
            id: selectedItems.product.id,
            quantityMinStock: jsonBody.product.quantityMinStock
              ? jsonBody.product.quantityMinStock
              : null,
            quantityMaxStock: jsonBody.product.quantityMaxStock
              ? jsonBody.product.quantityMaxStock
              : null,
            storageLocations: uniqueStorageLocations,
            frequencies: uniqueFrequencies,
            unifiedSkus
          },
      groups: userGroups.groups.map((group) => group.hasOwnProperty('id'))[0]
        ? restaurantGroups.map((group) => ({
            id: group.id,
            name: group.name,
            subGroups: buildSubGroups(group)
          }))
        : null,
      user: userInfo.user,
      restaurants: parseRestaurantsBody(),
      restaurantId: userInfo.companiesActive.id,
      restaurantIds: [
        ...new Set([userInfo.companiesActive.id, ...getRestaurantsIds()])
      ]
    };
  };

  const focusErrorField = (field, type) => {
    const autoComplete = document.querySelector(`#${field} input`);
    const treeSelector = document.querySelector(`#${field} input`);
    const normalInput = document.querySelector(`#${field} input`);

    const scroll = document.querySelector(`#${field} input`);

    if (type === 'autocomplete' && autoComplete && scroll) {
      scroll.scrollIntoView({ behavior: 'smooth' });
      autoComplete.focus();
    }
    if (type === 'treeSelector' && treeSelector && scroll) {
      scroll.scrollIntoView({ behavior: 'smooth' });
      treeSelector.focus();
    }
    if (type === 'input' && normalInput && scroll) {
      scroll.scrollIntoView({ behavior: 'smooth' });
      normalInput.focus();
    }
  };

  const isValid = (validSkus) => {
    setValidDescription(!!validSkus.sku.description);
    setValidQuantity(!!validSkus.sku.quantity);
    setValidUnitsMeasurements(!!validSkus.sku.unitsMeasurementsId);

    if (!validSkus.sku.description) {
      focusErrorField('skuDescriptionField', 'autocomplete');
    } else if (!validSkus.sku.quantity) {
      focusErrorField('skuQuantityField', 'input');
    } else if (!validSkus.sku.unitsMeasurementsId) {
      focusErrorField('skuUnitMeasurementsField', 'autocomplete');
    } else if (invalidEan) {
      focusErrorField('ean', 'input');
    }

    return (
      !invalidEan &&
      !!validSkus.sku.description &&
      !!validSkus.sku.quantity &&
      !!validSkus.sku.unitsMeasurementsId
    );
  };

  const validateProduct = () => {
    setValidProductDescription(!!jsonBody.product.description);
    setValidProductPrimaryUnit(!!jsonBody.product.consumptionUnitsPrimaryId);
    setValidProductCategory(!!jsonBody.product.categoryId);

    if (!jsonBody.product.description) {
      focusErrorField('productDescriptionField', 'autocomplete');
    } else if (!jsonBody.product.consumptionUnitsPrimaryId) {
      focusErrorField('productConsumptionPrimaryUnitField', 'autocomplete');
    } else if (!jsonBody.product.categoryId || !jsonBody.product.category) {
      focusErrorField('productCategoryField', 'treeSelector');
    }

    return (
      !!jsonBody.product.description &&
      !!jsonBody.product.consumptionUnitsPrimaryId &&
      !!jsonBody.product.categoryId
    );
  };

  const validateUnits = (validSkus) => {
    if (!Array.isArray(validSkus)) {
      if (validSkus.product) {
        switch (validSkus?.product?.consumptionUnitsPrimary?.abbreviation) {
          case 'un':
            setErrUnitsMsg(false);
            return true;
          case 'lt':
            if (
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'ml' &&
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'lt'
            ) {
              setErrUnitsMsg(true);
              focusErrorField(
                'productConsumptionPrimaryUnitField',
                'autocomplete'
              );
              return false;
            }
            setErrUnitsMsg(false);
            return true;

          case 'kg':
            if (
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'mg' &&
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'g' &&
              validSkus?.sku?.unitsMeasurements?.abbreviation !== 'kg'
            ) {
              setErrUnitsMsg(true);
              focusErrorField(
                'productConsumptionPrimaryUnitField',
                'autocomplete'
              );
              return false;
            }
            setErrUnitsMsg(false);
            return true;

          default:
            break;
        }
      }
    } else if (jsonBody.product) {
      for (const sku of validSkus) {
        switch (jsonBody?.product?.consumptionUnitsPrimary?.abbreviation) {
          case 'un':
            setErrUnitsMsg(false);
            return true;
          case 'lt':
            if (
              sku?.unitsMeasurements?.abbreviation !== 'ml' &&
              sku?.unitsMeasurements?.abbreviation !== 'lt'
            ) {
              setErrUnitsMsg(true);
              focusErrorField(
                'productConsumptionPrimaryUnitField',
                'autocomplete'
              );
              return false;
            }
            setErrUnitsMsg(false);
            return true;

          case 'kg':
            if (
              sku?.unitsMeasurements?.abbreviation !== 'mg' &&
              sku?.unitsMeasurements?.abbreviation !== 'g' &&
              sku?.unitsMeasurements?.abbreviation !== 'kg'
            ) {
              setErrUnitsMsg(true);
              focusErrorField(
                'productConsumptionPrimaryUnitField',
                'autocomplete'
              );
              return false;
            }
            setErrUnitsMsg(false);
            return true;

          default:
            break;
        }
      }
    }
  };

  const onSubmit = () => {
    updateSkuDefaultDescription();

    if (
      (validateProductArea
        ? validateProduct() && validateUnits(jsonBody)
        : true) &&
      isValid(jsonBody)
    ) {
      setLoading(true);
      setErrUnitsMsg(false);
      setRequestProcessing(true);
      buildBody();

      Axios({
        method: 'POST',
        url: `${environments.catalog}/skus`,
        data: jsonBody.sku,
        headers: {
          User: `${userInfo?.user}`
        }
      })
        .then(() => {
          setLoading(false);
          handleUpdateMainList();
          setMessage({
            description: 'Cadastro realizado com sucesso.',
            status: 'success'
          });
          setOpenedSkus([]);
          handleClosePopover();
        })
        .catch((error) => {
          if (error.response && error.response.status === 409) {
            setErrUnitsMsg(true);
            focusErrorField(
              'productConsumptionPrimaryUnitField',
              'autocomplete'
            );
            setRequestProcessing(false);
            setLoading(false);
          } else {
            setRequestProcessing(false);
            setLoading(false);

            setMessage({
              description: 'Ocorreu um erro ao utilizar o SKU',
              status: 'error'
            });
          }
        });
    }
  };

  const renderProductFull = () => (
    /* Products */
    <Grid className="modalBox justifyStart" container item xs={12} spacing={1}>
      <Grid
        className="modalTitle p0 pb5 bgLightBlue"
        item
        xs={12}
        id="productSwitchMultpleSkusInput"
      >
        <Typography className="white fontSize10 bold pl10 pt10">
          UTILIZAR ESTE SKU NO SEU CATÁLOGO
        </Typography>
      </Grid>

      <Grid
        container
        item
        xs={12}
        spacing={2}
        className={`${
          selectedItems?.product?.skus?.length ||
          (selectedSku?.products?.length &&
            selectedSku?.products[0]?.products?.skus)
            ? 'mb10'
            : 'mb0'
        } modalBox justifyStart pr0 pl10 pr10 m0 pt16`}
      >
        <Grid
          id="productDescriptionField"
          item
          xs={5}
          className={` pl0 ${!validProductDescription && 'labelError'}`}
        >
          <Autocomplete
            options={
              products?.filter((product) => product.description && product) ||
              []
            }
            getOptionLabel={(option) => option.description}
            className="autocompleteSmall"
            classes={{
              inputRoot: classes.inputRoot,
              input: classes.input,
              option: classes.option
            }}
            size="small"
            onInputChange={(_e, value) => {
              if (value) {
                debounceTimeList.forEach((el) => clearTimeout(el));

                const newTimer = setTimeout(() => {
                  getOptions(
                    `${environments.catalog}/products`,
                    'products',
                    products,
                    setProducts,
                    getId,
                    { description: value },
                    null,
                    10
                  );
                }, 1500);

                setDebounceTimeList([...debounceTimeList, newTimer]);
              }

              if (value && value.inputValue) {
                setJsonBody({
                  ...jsonBody,
                  sku: {
                    ...jsonBody.sku,
                    description: !isAdmin
                      ? jsonBody?.sku?.description
                      : value.inputValue,
                    descriptionLabel: !isAdmin
                      ? jsonBody?.sku?.descriptionLabel
                      : jsonBody.sku.descriptionLabel?.trim() ||
                        value.inputValue?.trim()
                  },
                  product: {
                    ...jsonBody.product,
                    description: value.inputValue?.trim(),
                    id: null
                  },
                  tags: []
                });
                setValidateProductArea(true);
                setSkuDefaultDescription({
                  product: !isAdmin
                    ? skuDefaultDescription?.product
                    : value.inputValue,
                  brand:
                    selectedItems?.brand?.id !== 29 &&
                    selectedItems?.brand?.id !== 202
                      ? selectedItems?.brand?.description || ''
                      : skuDefaultDescription.brand || '',
                  tags: '',
                  packageType:
                    selectedItems?.packageType?.description ||
                    skuDefaultDescription.packageType,
                  quantity:
                    jsonBody?.sku?.quantity || skuDefaultDescription.quantity,
                  unit:
                    selectedItems?.unit?.abbreviation?.toLocaleUpperCase() ||
                    jsonBody?.sku?.unitsMeasurements?.abbreviation
                });
              } else if (value) {
                setJsonBody({
                  ...jsonBody,
                  sku: {
                    ...jsonBody.sku,
                    description: !isAdmin ? jsonBody?.sku?.description : value
                  },
                  product: {
                    ...jsonBody.product,
                    description: value,
                    id: null
                  }
                });

                setSkuDefaultDescription({
                  product: !isAdmin ? skuDefaultDescription?.product : value,
                  brand:
                    selectedItems?.brand?.id !== 29 &&
                    selectedItems?.brand?.id !== 202
                      ? selectedItems?.brand?.description || ''
                      : skuDefaultDescription.brand || '',
                  tags: skuDefaultDescription.tags,
                  packageType:
                    selectedItems?.packageType?.description ||
                    skuDefaultDescription.packageType,
                  quantity:
                    jsonBody?.sku?.quantity || skuDefaultDescription.quantity,
                  unit:
                    selectedItems?.unit?.abbreviation?.toLocaleUpperCase() ||
                    skuDefaultDescription.unit?.toLocaleUpperCase()
                });

                setValidateProductArea(true);
              } else {
                setDisableProductUnit(false);
                setSelectedItems({
                  ...selectedItems,
                  product: {},
                  primaryUnit: {},
                  secondaryUnit: {},
                  frequencies: [],
                  storage: []
                });
                setJsonBody({
                  ...jsonBody,
                  product: {
                    useOnCards: true,
                    takeInventory: true,
                    status: 'ACTIVE',
                    description: '',
                    restaurantId: getId || null,
                    salePrice: null,
                    discountPrice: null,
                    theoreticalStock: null,
                    realStock: null,
                    duration: null,
                    lastPrice: null,
                    averagePrice: null,
                    averageCost: null,
                    lastCost: null,
                    averageCostStock: null,
                    lastCostStock: null,
                    margin: null,
                    markup: null,
                    cmv: null,
                    curve: null,
                    user: userInfo.user,
                    consumptionUnitsPrimaryId: null,
                    consumptionUnitsSecondaryId: null,
                    categoryId: null,
                    category: undefined,
                    quantityMinStock: '',
                    quantityMaxStock: '',
                    originIdCard: null,
                    productsGroupId: null,
                    approximateCost: null,
                    skus: [],
                    storageLocations: [],
                    unifiedSkus
                  }
                });

                setValidateProductArea(validateProductFields());
              }
              // handleProductFilter(value);
            }}
            value={selectedItems?.product || {}}
            onBlur={(e) => {
              if (e.target.value) {
                setJsonBody({
                  ...jsonBody,
                  sku: {
                    ...jsonBody.sku,
                    descriptionLabel: !isAdmin
                      ? jsonBody?.sku?.descriptionLabel?.trim()
                      : e.target.value?.trim() ||
                        jsonBody.sku.descriptionLabel?.trim()
                  }
                });

                setSelectedItems({
                  ...selectedItems,
                  product: {
                    ...selectedItems.product,
                    description: e.target.value?.trim()
                  }
                });
              }
            }}
            onFocus={() => {
              if (!products.length) {
                getOptions(
                  `${environments.catalog}/products`,
                  'products',
                  products,
                  setProducts,
                  getId,
                  null,
                  null,
                  10
                );
              }
            }}
            onChange={(_event, value) => {
              if (value && value.inputValue) {
                setSkuDefaultDescription({
                  product: !isAdmin
                    ? skuDefaultDescription?.product?.trim()
                    : value.inputValue?.trim(),
                  brand:
                    selectedItems?.brand?.id !== 29 &&
                    selectedItems?.brand?.id !== 202
                      ? selectedItems?.brand?.description || ''
                      : skuDefaultDescription.brand || '',
                  tags: '',
                  packageType:
                    selectedItems?.packageType?.description ||
                    skuDefaultDescription.packageType,
                  quantity:
                    jsonBody?.sku?.quantity || skuDefaultDescription.quantity,
                  unit:
                    selectedItems?.unit?.abbreviation?.toLocaleUpperCase() ||
                    skuDefaultDescription.unit?.toLocaleUpperCase()
                });

                setSelectedItems({
                  ...selectedItems,
                  primaryUnit: {},
                  secondaryUnit: {},
                  product: {
                    ...selectedItems.product,
                    description: value.inputValue?.trim(),
                    skus: [],
                    id: null
                  }
                });

                setJsonBody({
                  ...jsonBody,
                  sku: {
                    ...jsonBody.sku
                  },
                  product: {
                    description: value.inputValue?.trim(),
                    useOnCards: true,
                    takeInventory: true,
                    unifiedSkus
                  },
                  tags: []
                });

                setValidateProductArea(true);
                setDisableProductUnit(false);
              } else if (value) {
                setSkuDefaultDescription({
                  product: !isAdmin
                    ? skuDefaultDescription?.product
                    : value.description,
                  brand:
                    selectedItems?.brand?.id !== 29 &&
                    selectedItems?.brand?.id !== 202
                      ? selectedItems?.brand?.description || ''
                      : skuDefaultDescription.brand || '',
                  tags: '',
                  packageType:
                    selectedItems?.packageType?.description ||
                    skuDefaultDescription.packageType,
                  quantity:
                    jsonBody?.sku?.quantity || skuDefaultDescription.quantity,
                  unit:
                    selectedItems?.unit?.abbreviation?.toLocaleUpperCase() ||
                    skuDefaultDescription.unit?.toLocaleUpperCase()
                });

                setDisableProductUnit(value?.skus?.length);

                setSelectedItems({
                  ...selectedItems,
                  product: {
                    ...value,
                    id: value.id,
                    primaryUnit: consumptionUnits.find(
                      (cUnit) => cUnit.id === value.consumptionUnitsPrimaryId
                    ),
                    consumptionUnitsPrimary: consumptionUnits.find(
                      (cUnit) => cUnit.id === value.consumptionUnitsPrimaryId
                    ),
                    secondaryUnit: consumptionUnits.find(
                      (cUnit) => cUnit.id === value.consumptionUnitsSecondaryId
                    ),
                    frequencies:
                      value?.setups?.length &&
                      value.setups[0].config?.periodicity
                        ? value.setups.map((setup) =>
                            periodicities.find(
                              (periodicity) =>
                                periodicity.id === setup.config?.periodicity.id
                            )
                          )
                        : [],
                    storage:
                      value?.setups?.length &&
                      value.setups[0].config?.storageLocation
                        ? value.setups.map((setup) =>
                            storageLocations.find(
                              (storageOption) =>
                                storageOption.id ===
                                setup.config?.storageLocation.id
                            )
                          )
                        : []
                  }
                });

                setJsonBody({
                  ...jsonBody,
                  sku: {
                    ...jsonBody.sku,
                    description: !isAdmin
                      ? jsonBody?.sku?.description?.trim()
                      : value.description?.trim(),
                    descriptionLabel: !isAdmin
                      ? jsonBody?.sku?.descriptionLabel?.trim()
                      : jsonBody.sku.descriptionLabel?.trim() ||
                        value.description?.trim()
                  },
                  product: {
                    id: value.id,
                    restaurantId: getId,
                    description: value.description?.trim(),
                    consumptionUnitsPrimaryId: value.consumptionUnitsPrimaryId,
                    consumptionUnitsPrimary: value.consumptionUnitsPrimary,
                    consumptionUnitsSecondaryId:
                      value.consumptionUnitsSecondaryId,
                    storageLocationId: value.storageLocations,
                    categoryId: value.categoryId,
                    category: value?.categories?.description || undefined,
                    lossPercentage: value.lossPercentage
                      ? value.lossPercentage
                      : value.categories?.lossPercentage,
                    lossPercentageChanged: !!value.lossPercentageChanged,
                    quantityMinStock: value.quantityMinStock,
                    quantityMaxStock: value.quantityMaxStock,
                    frequencies: value.frequencies,
                    useOnCards: value.useOnCards ? value.useOnCards : true,
                    takeInventory: value.takeInventory
                      ? value.takeInventory
                      : !!value?.setups?.length,
                    productsGroupId: value.productsGroupId,
                    unifiedSkus
                  },
                  tags:
                    value.template?.map((tag) => ({
                      ...tag,
                      values: []
                    })) || (value.skus.tags ? value.skus.tags : [])
                });

                setValidateProductArea(true);
              } else {
                setSkuDefaultDescription({
                  ...skuDefaultDescription,
                  product: ''
                });

                setDisableProductUnit(false);
                setSelectedItems({
                  ...selectedItems,
                  product: {},
                  primaryUnit: {},
                  secondaryUnit: {},
                  frequencies: [],
                  storage: []
                });
                setJsonBody({
                  ...jsonBody,
                  product: {
                    useOnCards: true,
                    takeInventory: true,
                    status: 'ACTIVE',
                    description: '',
                    restaurantId: getId || null,
                    salePrice: null,
                    discountPrice: null,
                    theoreticalStock: null,
                    realStock: null,
                    duration: null,
                    lastPrice: null,
                    averagePrice: null,
                    averageCost: null,
                    lastCost: null,
                    averageCostStock: null,
                    lastCostStock: null,
                    margin: null,
                    markup: null,
                    cmv: null,
                    curve: null,
                    user: userInfo.user,
                    consumptionUnitsPrimaryId: null,
                    consumptionUnitsSecondaryId: null,
                    categoryId: null,
                    category: undefined,
                    quantityMinStock: '',
                    quantityMaxStock: '',
                    originIdCard: null,
                    productsGroupId: null,
                    approximateCost: null,
                    skus: [],
                    storageLocations: [],
                    unifiedSkus
                  },
                  tags: []
                });

                setValidateProductArea(validateProductFields());
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options || '', params || '');

              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  ...selectedItems.sku,
                  inputValue: params.inputValue,
                  description: `Criar "${params.inputValue}"`
                });
              }

              return filtered;
            }}
            selectOnFocus
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                name="products"
                label="Produto"
                placeholder="Digite aqui..."
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params.description}
              </Typography>
            )}
          />
        </Grid>

        <Grid
          id="productConsumptionPrimaryUnitField"
          item
          xs={2}
          className={` ${
            errUnitsMsg || (!validProductPrimaryUnit && 'labelError')
          }`}
        >
          <Autocomplete
            options={consumptionUnits || []}
            onFocus={() => {
              if (!consumptionUnits?.length) {
                getOptions(
                  `${environments.catalog}/consumptionUnits`,
                  'consumptionUnits',
                  consumptionUnits,
                  setConsumptionUnits,
                  getId,
                  null,
                  null,
                  10
                );
              }
            }}
            getOptionLabel={(option) => option.abbreviation}
            className="autocompleteSmall"
            classes={{
              inputRoot: classes.inputRoot,
              input: classes.input,
              option: classes.option
            }}
            value={selectedItems?.product?.consumptionUnitsPrimary || ''}
            size="small"
            disabled={disableProductUnit}
            onChange={(_event, value) => {
              if (value) {
                setSelectedItems({
                  ...selectedItems,
                  product: {
                    ...selectedItems.product,
                    primaryUnit: value,
                    consumptionUnitsPrimaryId: value ? value.id : null,
                    consumptionUnitsPrimary: value
                  }
                });
                setJsonBody({
                  ...jsonBody,
                  product: {
                    ...jsonBody.product,
                    consumptionUnitsPrimaryId: value ? value.id : null,
                    consumptionUnitsPrimary: value
                  }
                });
                setValidateProductArea(true);
              } else {
                setSelectedItems({
                  ...selectedItems,
                  product: {
                    ...selectedItems.product,
                    consumptionUnitsPrimaryId: null,
                    consumptionUnitsPrimary: null
                  }
                });
                setJsonBody({
                  ...jsonBody,
                  product: {
                    ...jsonBody.product,
                    consumptionUnitsPrimaryId: null,
                    consumptionUnitsPrimary: null
                  }
                });
                setValidateProductArea(validateProductFields());
              }
            }}
            onBlur={() => {
              setValidateProductArea(validateProductFields());
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                name="unitsMeasurementsId"
                label="Un."
                placeholder="Un."
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params.abbreviation}
              </Typography>
            )}
          />

          {errUnitsMsg && (
            <p className="requiredWarning">
              Unidades de medida e consumo não são compatíveis.
            </p>
          )}
        </Grid>

        <Grid
          id="productCategoryField"
          item
          xs={5}
          className={` pRelative pr0 ${!validProductCategory && 'labelError'}`}
        >
          <label
            className="fontSizeDefault pAbsolute bold"
            style={{ top: '-10px' }}
          >
            Categorias*
          </label>

          <Box display="flex" classes={{ root: 'itemWithModal' }}>
            <TreeSelect
              id="skuDialogCategorieTreeSelect"
              suffixIcon={
                <svg
                  className="MuiSvgIcon-root pAbsolute"
                  style={{ right: 0, bottom: '-7px' }}
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M7 10l5 5 5-5z" />
                </svg>
              }
              style={{ width: '100%' }}
              allowClear
              value={jsonBody?.product?.category || undefined}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={categories}
              placeholder="Selecione..."
              showSearch
              onDropdownVisibleChange={() =>
                getOptions(
                  `${environments.catalog}/categories`,
                  'categories',
                  categories,
                  setCategories,
                  getId
                )
              }
              filterTreeNode={(search, item) =>
                item?.title
                  ?.toLowerCase()
                  ?.normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  ?.indexOf(
                    search
                      ?.toLowerCase()
                      ?.normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                  ) >= 0
              }
              treeDefaultExpandAll
              onChange={(element) => {
                if (!element) {
                  setValidateProductArea(validateProductFields());
                  setLossPercentageCategory(null);
                  setJsonBody({
                    ...jsonBody,
                    product: {
                      ...jsonBody?.product,
                      category: undefined,
                      categoryId: null,
                      lossPercentage: null,
                      lossPercentageChanged: false
                    }
                  });
                }
              }}
              onSelect={(element, value) => {
                if (element) {
                  setLossPercentageCategory(
                    element && element.lossPercentage
                      ? element.lossPercentage
                      : null
                  );
                  setSelectedItems({
                    ...selectedItems,
                    product: {
                      ...selectedItems.product,
                      category: value?.title,
                      categoryId: element,
                      lossPercentage: value?.lossPercentage,
                      lossPercentageChanged: false
                    }
                  });
                  setJsonBody({
                    ...jsonBody,
                    product: {
                      ...jsonBody?.product,
                      category: value?.title,
                      categoryId: element,
                      lossPercentage: value?.lossPercentage,
                      lossPercentageChanged: false
                    }
                  });
                  setValidateProductArea(true);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {selectedItems?.product?.skus?.length ||
      (selectedSku?.products?.length &&
        selectedSku?.products[0]?.products?.skus) ? (
        <Grid
          item
          xs={12}
          className="p0 associatedSkusBox mr10 ml10 mb10"
          style={{
            background: '#E6E7F0',
            borderTop: 'solid 1px #5062F0',
            borderBottom: 'solid 1px #5062F0'
          }}
        >
          <SkusTable
            data={
              selectedItems?.product?.skus
                ? selectedItems?.product?.skus
                : selectedSku?.products &&
                  selectedSku?.products[0]?.products?.skus
            }
            products={products}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            jsonBody={jsonBody}
            setJsonBody={setJsonBody}
            brands={brands}
            unitsMeasurements={unitsMeasurementOptions}
            storageLocations={storageLocations}
            periodicities={periodicities}
            consumptionUnits={consumptionUnits}
            categories={categories}
            originId={getId}
            userInfo={userInfo}
            isAdmin={isAdmin}
            openedSkus={openedSkus}
            smallPopover
            onlyViewMode
          />
        </Grid>
      ) : null}
    </Grid>
  );

  useEffect(() => {
    if (!brands.length) {
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        getId,
        null,
        null
      );
    }
  }, [environments.restaurants]);

  useEffect(() => {
    setOpenedSkus([selectedSku?.id]);

    if (selectedSku) {
      const sku = selectedSku;
      const storage =
        sku?.product?.setups?.length &&
        sku.product.setups[0].config?.storageLocation
          ? sku.product.setups.map((setup) =>
              storageLocationOptions.find(
                (storageOption) =>
                  storageOption.id === setup.config?.storageLocation.id
              )
            )
          : [];

      setJsonBody({
        ...jsonBody,
        sku: {
          ...sku,
          ean: sku.ean,
          suggestionsBrands: sku?.suggestionsBrands,
          suggestionsEan: sku?.suggestionsEan
        },
        product: {
          id:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].productId
              : null,
          description:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.description
              : null,
          category:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.categories?.description
              : undefined,
          categoryId:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.categoryId
              : null,
          lossPercentage:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.lossPercentage
              : null,
          quantityMinStock:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.quantityMinStock
              : '',
          quantityMaxStock:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.quantityMaxStock
              : '',
          consumptionUnitsPrimaryId:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.consumptionUnitsPrimaryId
              : '',
          consumptionUnitsPrimary: consumptionUnits.find((el) =>
            el.id ===
            (sku?.products?.length &&
              sku?.products[0]?.products &&
              sku.products[0].products?.consumptionUnitsPrimaryId)
              ? sku.products[0].products?.consumptionUnitsPrimaryId
              : null
          ),
          consumptionUnitsSecondaryId:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.consumptionUnitsSecondaryId
              : null,
          useOnCards:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.useOnCards
              : true,
          storage,
          unifiedSkus
        }
      });

      setSkuDefaultDescription({
        product:
          sku?.products?.length && sku.products[0].products
            ? sku.products[0].products.description
            : '',
        brand:
          sku?.brandsId !== 29 && sku.brandsId !== 202
            ? brands.find((item) => item.id === sku.brandsId)?.description || ''
            : '',
        tags:
          sku?.tags
            ?.filter((item) => item.showInDescription)
            .map((tag) => tag?.values?.map((value) => value))
            .flat()
            .filter((item) => item || null) || '',
        quantity: sku.quantity,
        unit: unitsMeasurements
          ? sku
            ? unitsMeasurements
                .find((item) => item.id === sku.unitsMeasurementsId)
                ?.abbreviation?.toLocaleUpperCase() || ''
            : ''
          : sku
          ? unitsMeasurements
              .find((item) => item.id === sku.unitsMeasurementsId)
              ?.abbreviation?.toLocaleUpperCase() || ''
          : '',
        packageType: sku?.typePackages?.description || ''
      });

      setUnit(
        sku && unitsMeasurements
          ? unitsMeasurements.find(
              (item) => item.id === sku.unitsMeasurementsId
            )
          : {}
      );

      const { products } = {
        ...sku,
        products: sku?.products?.length ? sku?.products : []
      };
      const [firstProduct = {}] = products;
      const { products: secondLevelProducts = {} } = firstProduct;
      const { setups = [] } = secondLevelProducts;

      const mapedSetups = setups.map((setup) =>
        periodicities.find(
          (periodicityOption) =>
            periodicityOption.id === setup?.config?.periodicity?.id
        )
      );
      const filteredSetups = mapedSetups?.length
        ? mapedSetups.filter((value) => !!value)
        : [];

      const storageLocations = setups.map((setup) =>
        storageLocationOptions.find(
          (storageLocationOption) =>
            storageLocationOption.id === setup.config?.storageLocation.id
        )
      );

      setSelectedItems({
        ...selectedItems,
        unit: unitsMeasurementOptions
          ? sku
            ? unitsMeasurementOptions.find(
                (item) => item.id === sku.unitsMeasurementsId
              )
            : {}
          : sku
          ? unitsMeasurements.find(
              (item) => item.id === sku.unitsMeasurementsId
            )
          : {},
        brand:
          sku && sku.brandsId
            ? brands.find((item) => item.id === sku.brandsId)
            : {},
        typePackageId: sku?.typePackages?.id,
        packageType: sku?.typePackages || {},
        product: {
          ...(sku.products?.length
            ? productOptions?.find(
                (product) => product.id === sku.products[0].productId
              )
            : {}),
          id:
            sku?.products?.length && sku.products[0].products
              ? productOptions?.find(
                  (el) =>
                    el?.description === sku?.products[0].products?.description
                )
                ? productOptions?.find(
                    (el) =>
                      el?.description === sku?.products[0].products?.description
                  ).id
                : null
              : sku.products[0].productId,
          description:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.description
              : null,
          categoryId:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.categoryId
              : null,
          quantityMinStock:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.quantityMinStock
              : '',
          quantityMaxStock:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.quantityMaxStock
              : '',
          consumptionUnitsPrimaryId:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.consumptionUnitsPrimaryId
              : '',
          consumptionUnitsPrimary: consumptionUnits.find((el) =>
            el.id ===
            (sku?.products?.length &&
              sku?.products[0]?.products &&
              sku.products[0].products?.consumptionUnitsPrimaryId)
              ? sku.products[0].products?.consumptionUnitsPrimaryId
              : null
          ),
          consumptionUnitsSecondaryId:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.consumptionUnitsSecondaryId
              : null,
          useOnCards:
            sku?.products?.length && sku.products[0].products
              ? sku.products[0].products?.useOnCards
              : true,
          primaryUnit: sku?.products?.[0]?.products
            ? consumptionUnits.find(
                (el) =>
                  el.id === sku.products[0].products?.consumptionUnitsPrimaryId
              )
            : {},
          secondaryUnit:
            sku?.products?.length > 0 && sku.products[0].products
              ? consumptionUnits.find(
                  (el) =>
                    el.id ===
                    sku.products[0].products?.consumptionUnitsSecondaryId
                )
              : {},
          frequencies:
            sku?.products?.length > 0 && sku.products[0]?.products
              ? filteredSetups
              : [],
          storage: setups.every((item) => item?.config) ? storageLocations : []
        }
      });

      setData(sku?.providers);
    }

    setUserGroups({ groups: userInfo.companies.groups });
  }, []);

  useEffect(() => {
    const groupActive =
      userInfo?.companies?.groups?.length > 0
        ? userInfo?.companies?.groups?.find((group) =>
            group?.subGroups?.some((subGroup) =>
              subGroup?.stores?.some(
                (store) => store.id === userInfo?.companiesActive?.id
              )
            )
          )
        : {};
    const subGroupActive =
      userInfo?.companies?.groups?.length > 0
        ? groupActive?.subGroups?.find((subGroup) =>
            subGroup?.stores?.some(
              (store) => store.id === userInfo?.companiesActive?.id
            )
          )
        : {};

    setRestaurantGroups(userInfo?.companies?.groups);
    setRestaurantSubGroups([subGroupActive]);
    setRestaurantStores(subGroupActive?.stores);
  }, [userInfo.companiesActive]);

  return (
    <Popover
      open={popoverOpen}
      anchorEl={anchorEl}
      onClose={() => {
        handleClosePopover();
        setOpenedSkus(openedSkus.filter((sku) => sku !== selectedSku.id));
      }}
      PaperProps={{
        style: { borderRadius: '0px 4px 4px 4px', maxWidth: '400px' }
      }}
      className="configSkuPopover"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <FullScreenLoading display={loading} description="Salvando" />

      <div className="dFlex alignStart justifyStart flexColumn">
        <Grid container className="dFlex justifyCenter m0">
          {renderProductFull()}

          <Button
            id="skuDialogSaveBtn"
            className="defaultButton submitButton mt10 mr10 mb10"
            design="contained"
            disabled={requestProcessing}
            onClick={() => {
              onSubmit();
            }}
            style={{ marginLeft: 'auto' }}
            label={loading ? 'Processando...' : 'Utilizar SKU'}
          />
        </Grid>
      </div>

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => {
          message.status !== 'error' &&
            setMessage({ ...message, description: '', status: '' });
        }}
      >
        <Alert
          onClose={() => {
            message.status !== 'error' &&
              setMessage({ ...message, description: '', status: '' });
          }}
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </Popover>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSkuPopover);
