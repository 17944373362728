import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';

export const MultipleCheckboxContainer = styled.div`
  margin-top: ${({ topAdjustment }) => (topAdjustment ? '-25px' : '0')};
  margin-right: ${({ topAdjustment }) => (topAdjustment ? '35px' : '0')};
`;

export const LabelTitle = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #00020f;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-top: ${({ marginTop }) => (marginTop ? 0 : '16px')};
  margin-bottom: 0;
  span {
    font-family: Lato;
    font-style: normal;
    font-size: 12px;
    color: ${({ color }) => color || '#00020F'};
  }
  img {
    margin-left: 2px;
  }
`;

export const FormControlStyles = {
  root: {
    flexDirection: 'row',
    color: '#0B0D1D',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    marginTop: '-5px'
  }
};
