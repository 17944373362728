import styled, { css } from 'styled-components';

export const StyledTable = styled.table`
    width: 100%;
    margin-top: ${({grayHeader}) => grayHeader ? '0' : '10px'};
    font-style: normal;
    table-layout: auto;
    empty-cells: hide;
    background: #FFF;
    border-spacing: 1px 0;
    border-collapse: collapse;
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    padding-bottom: 15px;
    display: table;
    font-family: "Lato";
`;

export const StyledTR = styled.tr`
    background-color: ${({backGroundColor}) => backGroundColor ? backGroundColor : 'transparent'};
    color: ${({backGroundColor}) => backGroundColor ? 'white' : '#0B0D1D'};
    ${({withLine}) => withLine && css`
        border-bottom: 1px solid #ECEDF1;
    `}
`;

export const StyledTH = styled.th`
    /* background: #313347; */
    position: sticky;
    top: -6px;
    z-index: 999;

    ${({grayHeader}) => grayHeader ? 
    css`
        padding: 4px 10px;
        font-size: 12px;
        line-height: 16px;
        color: #666770;

        &:first-of-type,
        &:last-of-type {
            padding-left: ${({paddingLeft}) => paddingLeft ? paddingLeft : '16px'};
        }
    `
    :
    css`
        padding: 10px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 100;
    `
    }
    
`;

export const StyledTD = styled.td`
    padding: ${({grayHeader}) => grayHeader ? '5px 16px' : '10px'};
    font-size: ${({grayHeader}) => grayHeader ? '11px' : '12px'};
    text-align: ${({alignCenter}) => alignCenter ? 'center' : ''}; 
    line-height: 16px;
`;
