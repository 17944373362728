/* eslint-disable no-underscore-dangle */
import { useCallback, useState } from 'react';

/* eslint-disable no-unused-expressions */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import PaperComponent from 'components/PaperComponent';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import axiosInstanceAuth from 'services/middlewares/apis/auth_validate';
import Environment from 'utils/environments';

import StepView from '../../components/StepView';

function SelectUserDialog({
  open,
  onClose,
  onCancel,
  onConfirm,
  selectedGroupObj,
  selectedStoreUserObj,
  setSelectedStoreUserObj,
  setModalStatesObj,
  modalStatesObj
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [userOptions, setUserOptions] = useState([]);

  const addUser = () => (
    <Tooltip title="Cadastro de Usuário" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          setModalStatesObj({
            ...modalStatesObj,
            createUser: {
              open: true,
              openedByUserSelected: true
            }
          });
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  const getUserOptions = useCallback((subGroup) => {
    (async () => {
      const response = await axiosInstanceAuth.get(
        `${environments.auth}/users?page=1&size=100&originId=0&subGroupsId=${subGroup?.subgroupId}`
      );

      const jsonUsers = await response.data;

      setUserOptions(jsonUsers?.content);
    })();
  }, []);

  return (
    <Dialog
      open={open}
      className="defaultModal fakePopover"
      PaperComponent={PaperComponent}
      onClose={() => {
        onClose();
      }}
    >
      <div className="mb10">
        <StepView currentStep="5" />
      </div>

      <DialogContent
        className="modalContent bgWhite"
        style={{ padding: '11px 11px 16px', width: 300 }}
      >
        <div className="dFlex justifyBetween alignStart">
          <Typography className="bold" style={{ marginBottom: 40 }}>
            Usuários:
          </Typography>

          {addUser()}
        </div>

        <div className="dFlex flexColumn">
          <Autocomplete
            required
            disabled={!selectedGroupObj?.group}
            style={{ marginBottom: 40 }}
            options={selectedGroupObj?.group?.subgroups || []}
            value={selectedStoreUserObj?.subgroup}
            getOptionLabel={(option) => option?.description}
            onChange={(_, value) => {
              setSelectedStoreUserObj({
                ...selectedStoreUserObj,
                subgroup: value
              });

              getUserOptions(value);
            }}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params?.description}
              </Typography>
            )}
            className="autocompleteSmall"
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                required
                name="subgroup"
                label="Subgrupo"
                variant="outlined"
                placeholder="Selecione..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />

          <Autocomplete
            key={Math.random()}
            options={userOptions}
            value={selectedStoreUserObj?.users}
            multiple
            limitTags={1}
            getOptionLabel={(option) => option?._id}
            getOptionDisabled={(option) =>
              selectedStoreUserObj?.users?.some(
                (user) => user._id === option._id
              )
            }
            onChange={(_, value) => {
              if (value?.length) {
                setSelectedStoreUserObj({
                  ...selectedStoreUserObj,
                  users: value
                });
              } else {
                setSelectedStoreUserObj({
                  ...selectedStoreUserObj,
                  users: []
                });
              }
            }}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params?._id}
              </Typography>
            )}
            className="autocompleteSmall"
            renderInput={(params) => (
              <TextField
                {...params}
                id={params._id}
                type="text"
                required
                name="users"
                label="Usuários"
                variant="outlined"
                placeholder="Selecione..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
          />
        </div>
      </DialogContent>

      <DialogActions className="dFlex justifyEnd pb10">
        <Button
          className="defaultButton backButton mr8"
          autoFocus
          onClick={() => {
            onCancel ? onCancel() : onClose();
          }}
        >
          Cancelar
        </Button>

        <Button
          className={`defaultButton ${
            !selectedStoreUserObj?.users?.length ? 'backButton' : 'submitButton'
          } ml0`}
          disabled={!selectedStoreUserObj?.users?.length}
          onClick={() => {
            onConfirm();
          }}
        >
          Avançar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectUserDialog;
