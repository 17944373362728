import React, { useState, useEffect } from 'react';

import { LinearProgress, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import ItemsPerPage from 'components/ItemsPerPage';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

function Sefaz({ userInfo }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );

  const { id: paramsID } = useParams();
  const actualDate = new Date();
  // const dateLessTwo = new Date().setDate(actualDate.getDate() - 2);
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 100,
    loading: false,
    filters: [],
    numberOfElements: 0,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    numberOfElements,
    filters,
    totalElements
  } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [sefazLogs, setSefazLogs] = useState([]);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [filterFields, setFilterFields] = useState([
    [
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'startDate',
        filterNameSecond: 'endDate',
        initialDate: actualDate,
        endDate: actualDate,
        minDate: true,
        maxDate: true
      },
      {
        label: 'Restaurantes',
        filterName: 'restaurantId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'stores',
        optionReference: 'name',
        options: stores[0],
        size: 'medium_plus'
      },
      {
        label: 'Bloqueado',
        filterName: 'block',
        type: 'switchField'
      }
    ]
  ]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: paramsID });
  }, [paramsID]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    (async () => {
      const otherRestaurant =
        filtersChanged.find((item) => item.param === 'restaurantId') &&
        filtersChanged.find((item) => item.param === 'restaurantId').value;

      if (!filtersChanged.find((item) => item.param === 'startDate')) {
        filtersChanged.push(...filtersChanged, {
          param: 'startDate',
          value: format(new Date(actualDate), 'yyyy-MM-dd'),
          urlParam: `startDate=${format(new Date(actualDate), 'yyyy-MM-dd')}`
        });
      }

      if (!filtersChanged.find((item) => item.param === 'endDate')) {
        filtersChanged.push({
          param: 'endDate',
          value: format(new Date(actualDate), 'yyyy-MM-dd'),
          urlParam: `endDate=${format(new Date(actualDate), 'yyyy-MM-dd')}`
        });
      }

      setCallSettings({ ...callSettings, loading: true });
      const params = filtersChanged
        .map((filter) =>
          filter.urlParam.replace(
            `restaurantId=${otherRestaurant || getId}`,
            ''
          )
        )
        .join('&');
      const response = await fetch(
        `${environments.dashBoard}/logs/sefaz?restaurantId=${
          otherRestaurant || getId
        }&${params}`
      );
      const items = await response.json();
      setSefazLogs(items);
      setCallSettings({
        ...callSettings,
        loading: false,
        numberOfElements: items.numberOfElements,
        totalElements: items.totalElements,
        totalPages: items.totalPages
      });
    })();
  }, [getId, page, size, filtersChanged]);

  const handleFilter = (param, value) => {
    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(
      paramsAndValues,
      filtersChanged,
      setFiltersChanged,
      1,
      {
        callSettings,
        setCallSettings
      }
    );
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page
    );
  };

  return (
    <Layout>
      <TitleHead title="Sefaz Logs" />

      <GlobalStyle />

      <Container className="sefazLogsPage">
        <PageTitleAndFilter
          title="Sefaz Logs"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          handleMultipleFilters={handleOrdernationFilter}
          dynamicJustifyOff
          getFilterhandle={getFilterhandle}
        />

        <table
          className="desktop sefazLogsListTable"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th className="subtitle" width="20%">
                DATA
              </th>
              <th className="subtitle" width="15%">
                BLOQUEADO
              </th>
              <th className="subtitle" width="50%">
                MENSAGEM
              </th>
              <th className="subtitle" width="15%">
                INTEGRADO
              </th>
            </tr>

            <tr className="headerBorder">
              <th colSpan="4" className="titleLineBorder" />
            </tr>
          </thead>

          <tbody>
            {sefazLogs && sefazLogs.length ? (
              sefazLogs.map((sefazLog) => (
                <>
                  <tr>
                    <td className="description pl12">
                      {sefazLog?.call
                        ? `${format(
                            new Date(sefazLog.call),
                            "dd/MM/yyyy - HH:mm'h'"
                          )}`
                        : '-'}
                    </td>

                    <td className="description">
                      {sefazLog?.block ? 'Bloqueado' : '-'}
                    </td>

                    <td className="description">
                      {ValidationLength(sefazLog?.message || '-', 120)}
                    </td>

                    <td className="description p12">
                      {sefazLog?.integrated || 0}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="4" className="p0">
                      <hr className="m0" />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="textAlignCenter">
                  <Typography
                    style={{
                      fontSize: '12px',
                      margin: '10px 0',
                      fontFamily: 'Lato',
                      textAlign: 'center'
                    }}
                  >
                    Não há registros para exibir.
                  </Typography>
                </td>
              </tr>
            )}

            {callSettings.loading ? (
              <tr>
                <td colSpan="4" className="m10">
                  <LinearProgress variant="query" />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>

        <div className="pagination">
          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>

        <Snackbar
          open={!!message.description}
          autoHideDuration={2000}
          onClose={(e) => setMessage({ description: '', status: '' })}
        >
          <Alert
            onClose={() => setMessage({ description: '', status: '' })}
            severity={message.status}
          >
            {message.description}
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sefaz);
