import styled from 'styled-components';

export const SubHomeHeader = styled.div`
    height: 5vh;
    display: flex;
    justify-content: space-between;
    font-family: lato; 
    margin: 7px;
    h2 {
        font-size: 24px;
        color: #04061A;
    }
`;

export const StyledSection = styled.section`
    font-family: 'Lato';
`;

export const ChildrensContainer = styled.div`
    display: flex;
    flex-flow: wrap;
    position: relative;
    grid-gap: 7px;
`;

export const HeaderTitle = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 94%;
`;

export const BoardOptions = styled.span`
    margin-top: 20px;
    margin-left: 15px;
    font-size: 12px;
    color: #8B97F2;
    cursor: pointer;
    text-decoration: underline;
`;

export const ConfigIconsContainer = styled.div`
    margin-top: 15px;
    width: 6%;
`;

export const ConfigIcon = styled.img`
    margin-left: 16px;
    cursor: pointer;
`;
