/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { completeNumberWithDecimal } from 'utils/completeNumberDecimal';

import { CountInputWrapper, CountInputLabel } from './index.style';

const parseValue = (value, { hasOperator, valueOperator } = { hasOperator: false, valueOperator: 0 }) => {
    const operator = hasOperator && valueOperator && value ? parseFloat(value.toString().replace(',', '.')) * valueOperator : hasOperator && valueOperator ? value * valueOperator : value
    const newValue = completeNumberWithDecimal(parseInt(operator));
    return parseFloat(newValue.replace(',', '.')).toFixed(3)
}

function CountInput({ label, content, handleOnBlur, dataCount, setCurrentSelectItem }) {
    const [ inputValue, setInputValue ] = useState(parseValue(content, {
        hasOperator: true,
        valueOperator: 1000
    }));

    const handleOnBlurInput = () => {
        handleOnBlur(inputValue, dataCount);
        setCurrentSelectItem(dataCount?.currentProduct);
    };

    useEffect(() => {
        setInputValue(parseValue(content, {
            hasOperator: true,
            valueOperator: 1000
        }));
    }, [content]);

    const handleInputValue = (event) => {
        const {value} = event.target; 
        
        const parsedValue = parseValue(value.replace('.', ''))
        setInputValue(parsedValue);
        const { sku, type, currentProduct } = dataCount;

        if (type === "count") {
            const { providers = [] } = sku;
            const [ firstProvider ] = providers;

            firstProvider.stock = parsedValue;

            return;
        }

        if (type === "additionalSKU") {
            currentProduct.additional = parsedValue;
            currentProduct.labelAdditional = parsedValue;
        }

        if (type === "additional") {
            currentProduct.unityAdditional = parsedValue;
            currentProduct.labelUnityAdditional = parsedValue;
        }
    };

    return (
        <CountInputWrapper>
            <CountInputLabel>{label}</CountInputLabel>
            <div>
                <input 
                    type='number' 
                    value={inputValue} 
                    onBlur={handleOnBlurInput} 
                    onChange={handleInputValue}
                    inputMode="decimal"
                    pattern="[0-9]*"
                />
            </div>
        </CountInputWrapper>
    );
}

export default CountInput;
