import React, { useState, useEffect } from 'react';

import { TreeSelect } from 'antd';
import { connect } from 'react-redux';

import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

const environments = Environment(process.env.REACT_APP_ENV);

function TreeSelectorField({
  urlPath = null,
  label,
  options,
  filterName,
  handleFilter,
  size,
  getFilterhandle,
  userInfo,
  defaultValue,
  multiple,
  notMultipleDefaultValue,
  storedValue,
  inputSizes = {
    small: 110,
    medium: 155,
    medium_to_plus: 165,
    medium_plus: 210,
    large: 250
  },
  onFormStyle,
  marginRight
}) {
  const [initialValue, setInitialValue] = useState(storedValue);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [platformCategories, setPlatformCategories] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState(
    multiple || notMultipleDefaultValue
      ? defaultValue || undefined
      : defaultValue || undefined
  );
  const [open, setOpen] = useState(false);
  const { id: userId } = userInfo.companiesActive;
  const [getId, setGetId] = useState(userInfo.companiesActive.id);
  const loading = open && options && options.length === 0;
  const url = urlPath || `${environments.catalog}/categories`
  const paramRest = label === 'Praça' ? 'restaurantId' : 'originId'

  useEffect(() => {
    if (!categoryOptions.length && platformCategories) {
      getOptions(
        url,
        'categories',
        categoryOptions,
        setCategoryOptions,
        0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        paramRest
      );
      setPlatformCategories(false);
    }
  }, [categoryOptions]);

  useEffect(() => {
    if (userId !== getId) {
      if (typeof getFilterhandle !== 'function') {
        return;
      }

      getOptions(
        url,
        'categories',
        categoryOptions,
        setCategoryOptions,
        getId
      );
      setPlatformCategories(true);
      setGetId(userId);
      return;
    }

    if (!loading || categoryOptions.length) {
      return
    }

    if (typeof getFilterhandle !== 'function') {
      return
    }

    getOptions(
      url,
      'categories',
      categoryOptions,
      setCategoryOptions,
      getId,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      paramRest
    );

    // Quando for a request de categorias setamos o estado para true
    if (label !== 'Praça') {
      setPlatformCategories(true);
    }
  }, [loading, getId, open]);

  const handleFilterValue = (element, obj) => {
    setAutoCompleteValue(element);

    if (!element || element === null) {
      handleFilter(filterName, '');
      return;
    }

    if (multiple) {
      const value = element.map((item) => item.title);
      handleFilter(filterName, value, element, obj);
    } else {
      const { id = '', title = '' } = obj;
      let filterParam = title || id 

      if (label === 'Praça') {
        filterParam = id
      }

      handleFilter(filterName, filterParam, element, obj);
    }
  };

  return (
    <div
      style={{ width: inputSizes[size], marginRight: marginRight || 35 }}
      className="flexColumn dFlex"
    >
      <label
        className="fontSizeDefault pRelative bold mb0"
        style={{ marginTop: onFormStyle ? '4px' : '-3px' }}
      >
        {label}
      </label>

      <TreeSelect
        suffixIcon={
          <svg
            className="MuiSvgIcon-root pAbsolute"
            style={{ right: 0, bottom: '-7px' }}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M7 10l5 5 5-5z" />
          </svg>
        }
        style={{ width: '100%', marginTop: onFormStyle ? '2px' : 'inherit' }}
        allowClear
        value={autoCompleteValue || initialValue || undefined}
        onDropdownVisibleChange={(event) => {
          setOpen(event);
        }}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={categoryOptions}
        placeholder="Selecione..."
        showSearch
        filterTreeNode={(search, item) =>
          item?.title
            ?.toLowerCase()
            ?.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            ?.indexOf(
              search
                ?.toLowerCase()
                ?.normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
            ) >= 0
        }
        treeDefaultExpandAll
        onChange={(element) => {
          setInitialValue('');

          if (!element) {
            handleFilterValue(null, null);
          }
        }}
        onSelect={(element, value) => {
          setInitialValue('');

          if (element) {
            handleFilterValue(element, value);
          }
        }}
        showHitDescendants
        mode={2}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(TreeSelectorField);
