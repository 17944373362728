/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { format } from 'date-fns';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import ValidationLength from 'utils/utils';

function DialogOrderStatus({
  open,
  handleClose,
  errorMessage,
  statusInfo,
  orderStatusContent,
  restaurants,
  loadingMainContent,
  item
}) {
  const getOrderStatusTitle = () => (
    <table width="100%" cellPadding="0" cellSpacing="0" border="0">
      <thead>
        <tr className="headerTitle">
          <th width="10%" className="subtitle center">
            DATA
          </th>

          <th className="subtitle">FORNECEDOR</th>

          <th className="subtitle">LOJA</th>

          <th className="subtitle right">QTDE</th>

          <th className="subtitle right">VALOR R$</th>

          <th width="15%" className="subtitle center">
            STATUS
          </th>
        </tr>
      </thead>

      <tbody>
        {loadingMainContent ? (
          <tr>
            <th colSpan="6" className="dFlex textAlignCenter">
              <LinearProgress
                style={{ height: '7px' }}
                id="loading"
                variant="query"
              />
            </th>
          </tr>
        ) : null}

        {orderStatusContent?.length ? (
          orderStatusContent
            ?.sort((a, b) => (a.date < b.date ? 1 : -1))
            ?.map((order, index) => (
              <>
                <tr>
                  <td
                    className="description fontFamilyDefault"
                    style={{ verticalAlign: 'top' }}
                  >
                    {format(new Date(order?.date), 'dd/MM/yyyy') || '-'}
                  </td>

                  <td
                    className="description fontFamilyDefault"
                    style={{ verticalAlign: 'top' }}
                  >
                    <div className="dFlex flexColumn justifyBetween">
                      <span
                        className={`${
                          order?.status === 'RECEIVED_DIVERGENCE' && 'mb5'
                        }`}
                      >
                        {ValidationLength(order?.providerName, 25) || '-'}
                      </span>

                      {order?.status === 'RECEIVED_DIVERGENCE' ? (
                        <span style={{ color: '#9296AC' }}>NOTA FISCAL</span>
                      ) : null}
                    </div>
                  </td>

                  <td
                    className="description fontFamilyDefault"
                    style={{ verticalAlign: 'top' }}
                  >
                    <div className="dFlex flexColumn justifyBetween">
                      <span
                        className={`${
                          order?.status === 'RECEIVED_DIVERGENCE' && 'mb5'
                        }`}
                      >
                        {ValidationLength(
                          restaurants.find(
                            (restaurant) =>
                              restaurant.id === order?.restaurantId
                          )?.name,
                          22
                        ) || '-'}
                      </span>
                    </div>
                  </td>

                  <td
                    className="description textAlignRight"
                    style={{ verticalAlign: 'top' }}
                  >
                    <div
                      className="dFlex flexColumn justifyBetween"
                      style={{
                        height:
                          order?.status === 'RECEIVED_DIVERGENCE' &&
                          (order?.providerName.length > 25 ||
                            restaurants.find(
                              (restaurant) =>
                                restaurant.id === order?.restaurantId
                            )?.name?.length > 25)
                            ? 47
                            : 'auto'
                      }}
                    >
                      <span className={`${order?.quantityReceived && 'mb5'}`}>
                        {order?.quantity?.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }) || '-'}
                      </span>

                      {order?.quantityReceived ? (
                        <span
                          style={{
                            color:
                              order?.typeDivergence === 'QUANTITY' ||
                              order?.typeDivergence === 'BOTH'
                                ? '#F2B90D'
                                : '#9296AC'
                          }}
                        >
                          {order?.quantityReceived?.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }) || '-'}
                        </span>
                      ) : null}
                    </div>
                  </td>

                  <td
                    className="subtitle textAlignRight"
                    style={{ verticalAlign: 'top' }}
                  >
                    <div
                      className="dFlex flexColumn justifyBetween"
                      style={{
                        height:
                          order?.status === 'RECEIVED_DIVERGENCE' &&
                          (order?.providerName.length > 25 ||
                            restaurants.find(
                              (restaurant) =>
                                restaurant.id === order?.restaurantId
                            )?.name?.length > 25)
                            ? 47
                            : 'auto'
                      }}
                    >
                      <span
                        className={`${order?.unitaryValueReceived && 'mb5'}`}
                      >
                        R${' '}
                        {order?.value?.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }) || '-'}
                      </span>

                      {order?.unitaryValueReceived ? (
                        <span
                          style={{
                            color:
                              order?.typeDivergence === 'VALUE' ||
                              order?.typeDivergence === 'BOTH'
                                ? '#F2B90D'
                                : '#9296AC'
                          }}
                        >
                          R${' '}
                          {order?.unitaryValueReceived?.toLocaleString(
                            'pt-br',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          ) || '-'}
                        </span>
                      ) : null}
                    </div>
                  </td>

                  <td className="subtitle" style={{ verticalAlign: 'top' }}>
                    <div
                      className="dFlex alignCenter justifyCenter"
                      style={{
                        background: statusInfo(order?.status)?.bgColor,
                        borderRadius: 8,
                        padding: '2px 8px',
                        color: '#9296AC'
                      }}
                    >
                      {statusInfo(order?.status)?.title}
                    </div>
                  </td>
                </tr>

                {index !== orderStatusContent?.length - 1 ? (
                  <tr>
                    <td colSpan="6" className="p0">
                      <hr
                        className="titleLineBorder m0"
                        style={{ height: '2px' }}
                      />
                    </td>
                  </tr>
                ) : null}
              </>
            ))
        ) : (
          <tr>
            <th colSpan="6" className="m10 textAlignCenter">
              <div className="dFlex justifyCenter">
                {errorMessage ? <p>{errorMessage}</p> : null}
              </div>
            </th>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        handleClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal quotationOrderStatusDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove pr8 pl8"
        id="draggable-dialog-title"
      >
        <Typography className="dFlex w100">
          <span className="bold mr5">PRODUTO: </span>
          <span>{item?.description}</span>
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => handleClose()}
          style={{
            width: '18px',
            height: '18px'
          }}
        >
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        {getOrderStatusTitle()}
      </DialogContent>
    </Dialog>
  );
}

export default DialogOrderStatus;
