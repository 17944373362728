const storageLocationsLocalize = {
  'need to send restaurantId and description in body to complete request': 'need to send restaurantId and description in body to complete request',
  "already exists a storageLocation with this description": "already exists a storageLocation with this description",
  "Need to send the originId": "Need to send the originId",
  "invalid status": "invalid status",
  "not found results according to these parameters": "not found results according to these parameters",
  "Já existe uma contagem definida como CMV para o restaurante atual": "There is already a count defined as COGS for the current restaurant.",
  "Já existe um local de armazenamento ativo com a contagem de CMV habilitada Não é possível ativar mais de um local de armazenamento com essa configuração": "There is already an active periodicity with CMV counting enabled. It is not possible to activate more than one periodicity with this configuration.",
};

export default storageLocationsLocalize;
