import React, { useEffect, useState, lazy, Suspense } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';

import BloomTable from 'components/BloomTable';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import Sales from 'components/Purchases&Sales/Sales/index';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import Environments from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';

import { handleFetchData } from './functions/handleFetchData';
import { sortData } from './functions/orderData';
import './index.scss';

function FornecedoresCatalogo({ userInfo }) {
  const environments = Environments(process.env.REACT_APP_ENV);
  const originIds = userInfo?.companies?.groups[0].subGroups[0].stores.map(
    (provider) => provider.id
  );
  const originId = userInfo.companiesActive.id;
  const dateLessOneDay = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const dateLessThreeMonths = moment()
    .subtract(3, 'months')
    .format('YYYY-MM-DD');

  const [options, setOptions] = useState([]);
  const [tableData, setTableData] = useState({
    header: [
      {
        name: 'CADASTRO',
        size: '56%',
        backgroundColor: '#1F2445',
        borderColor: '#1F2445',
        isBloom: false,
        subHeaders: [
          {
            name: 'SEU CÓDIGO',
            size: '110px',
            order: {
              filterName: 'codeProvider'
            }
          },
          {
            name: 'ITEM',
            size: '350px',
            order: {
              filterName: 'descriptionSku'
            }
          },
          {
            name: 'EMB.',
            size: '57px'
          },
          {
            name: 'CATEGORIA',
            size: '175px',
            order: {
              filterName: 'category'
            }
          },
          {
            name: 'CLIENTES',
            size: '10px'
          }
        ]
      },
      {
        name: 'VENDAS REALIZADAS',
        size: '25%',
        backgroundColor: '#1F2445',
        borderColor: '#5062F0',
        isBloom: false,
        subHeaders: [
          {
            name: 'PEDIDOS',
            size: '125px',
            alignRight: true,
            order: {
              filterName: 'salesQuantity'
            }
          },
          {
            name: '\u00A0',
            size: '1px',
            alignRight: true
          },
          {
            name: 'R$ MÉDIO',
            size: '125px',
            alignRight: true,
            order: {
              filterName: 'salesAveragePrice'
            }
          },
          {
            name: '\u00A0',
            size: '1px',
            alignRight: true
          },
          {
            name: 'VOLUME',
            size: '20px',
            alignRight: true,
            order: {
              filterName: 'salesVolume'
            }
          },
          {
            name: '\u00A0',
            size: '1px',
            alignRight: true
          }
        ]
      },
      {
        name: '  POTENCIAL BLOOM',
        size: '19%',
        backgroundColor: '#5062F0',
        isBloom: true,
        subHeaders: [
          {
            name: 'PEDIDOS',
            size: '112px',
            alignRight: true,
            order: {
              filterName: 'purchaseQuantity'
            }
          },
          {
            name: 'R$ MÉDIO',
            size: '108px',
            alignRight: true,
            order: {
              filterName: 'purchaseAveragePrice'
            }
          },
          {
            name: 'VOLUME',
            size: '100px',
            alignRight: true,
            order: {
              filterName: 'purchaseVolume'
            }
          }
        ]
      }
    ],
    collunms: [],
    tableActions: []
  });
  const [filters] = useState([
    {
      param: 'startDate',
      value: dateLessThreeMonths,
      urlParam: `startDate=${dateLessThreeMonths}`
    },
    {
      param: 'finishDate',
      value: dateLessOneDay,
      urlParam: `finishDate=${dateLessOneDay}`
    }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Código',
        filterName: 'codeProvider',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Item',
        filterName: 'skuBloomId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        optionReference: 'descriptionSku',
        options: [],
        size: 'medium'
      },
      {
        label: 'Categorias',
        filterName: 'category',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/categories`,
        key: 'category',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium'
      },
      {
        type: 'dateRangeField',
        size: 'medium',
        filterNameFirst: 'startDate',
        filterNameSecond: 'finishDate',
        initialDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        minDate: true,
        maxDate: true,
        views: ['day', 'month', 'year'],
        dateFormat: 'dd/MM/yyyy'
      }
    ]
  ]);
  const [canPlaceOrderFiltered, setCanPlaceOrderFiltered] = useState(null);
  const [values, SetValues] = useState({
    totalQuantity: 0,
    totalVolume: 0,
    purchaseOrders: 0,
    purchaseVolume: 0,
    percentageQuantity: 0,
    percentageVolume: 0,
    potentialOrders: 0,
    potentialVolume: 0
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // dialogs
  const ClientsDialog = lazy(() => import('./dialogs/clientsDialog'));

  const [modalStates, setModalStates] = useState({ modalClients: false });
  const [clientsDialog, setClientsDialog] = useState([]);

  const handleClose = () => {
    setModalStates({ modalClients: false });
    setClientsDialog([]);
  };

  const handleOrdenation = (paramsAndValues) => {
    const [[a, orderBy], [b, orderOn]] = paramsAndValues;
    const dataSorted = sortData({
      data,
      orderBy,
      orderOn
    });
    handleFetchData(dataSorted, tableData, setTableData);
  };

  const handleFilter = (param, value) => {
    if (param instanceof Array) {
      value = param[0][1];
      param = param[0][0];
    }

    if (param === 'canPlaceOrder') {
      setCanPlaceOrderFiltered(value);
    }

    handleFilterOptions(param, value, filtersChanged, setFiltersChanged);
  };

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterHandle = (urlPath, label, key, optionReference) => {
    const id = key === 'skusDescription' ? originIds : 0;
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      id,
      filtersChanged
    );
  };

  const allPurchase = (data) => {
    const totalQuantity = data.reduce(
      (accumulator, x) => accumulator + Number(x.salesQuantity),
      0
    );
    const totalVolume = data.reduce(
      (accumulator, x) => accumulator + Number(x.salesVolume),
      0
    );
    const purchaseOrders = data.reduce(
      (accumulator, x) => accumulator + Number(x.purchaseQuantity),
      0
    );
    const purchaseVolume = data.reduce(
      (accumulator, x) => accumulator + Number(x.purchaseVolume),
      0
    );
    const potentialOrders = purchaseOrders - totalQuantity;
    const potentialVolume = purchaseVolume - totalVolume;
    const percentageQuantity =
      totalQuantity && purchaseOrders
        ? Math.round((100 * totalQuantity) / purchaseOrders)
        : 0;
    const percentageVolume =
      totalVolume && purchaseVolume
        ? Math.round((100 * totalVolume) / purchaseVolume)
        : 0;

    return {
      totalQuantity,
      totalVolume,
      purchaseOrders,
      purchaseVolume,
      percentageQuantity,
      percentageVolume,
      potentialOrders,
      potentialVolume
    };
  };

  const fetchAutoCompleteValue = (content) => {
    const options = content?.length
      ? content.map((item) => ({
          value: item.skuBloomId,
          description: item.descriptionSku
        }))
      : [];

    return { options };
  };

  useEffect(() => {
    const content = data && data.message ? [] : data;

    if (content?.length) {
      SetValues(allPurchase(content));
    }

    if (!options || options.length === 0) {
      const { options: skusOptions } = fetchAutoCompleteValue(content);
      filterFields[0][1].options = skusOptions;
    }

    handleFetchData(content, tableData, setTableData);
  }, [data]);

  useEffect(() => {
    const delayFilterFetchData = setTimeout(() => {
      (async () => {
        setLoading(true);

        const params = filtersChanged
          .map((filter) => filter.urlParam)
          .join('&');
        const response = await fetch(
          `${environments.catalog}/providers/catalog/${originIds}?${params}`
        );
        const content = await response.json();

        setData(content);
        setLoading(false);
      })();
    }, 1000);

    return () => clearTimeout(delayFilterFetchData);
  }, [originId, filtersChanged]);

  return (
    <Layout>
      <TitleHead title="Fornecedores - Catálogo" />

      <div className="cards">
        <h1 className="title">Catálogo</h1>

        <Sales
          providerId={originIds}
          type="catalog"
          ordersPercentage={values.percentageQuantity}
          volumePercentage={values.percentageVolume}
          yourSalesOrders={values.totalQuantity}
          yourSalesVolume={values.totalVolume}
          purchaseOrders={values.purchaseOrders}
          purchaseVolume={values.purchaseVolume}
          yourPotentialRequests={values.potentialOrders}
          yourPotentialVolume={values.potentialVolume}
          handleFilter={handleFilter}
          canPlaceOrderFiltered={canPlaceOrderFiltered}
          filter={filters}
          filtersChanged={filtersChanged}
        />
      </div>

      <PageTitleAndFilter
        activeFilterFilds={filterFields}
        handleFilter={handleFilter}
        handleMultipleFilters={handleMultipleFiltersFunc}
        getFilterhandle={getFilterHandle}
      />

      <BloomTable
        loading={loading}
        erroMensagem="Nenhuma informação encontrada"
        tableData={tableData}
        setTableData={setTableData}
        tableFixed={false}
        handleOrdernationFilter={handleOrdenation}
        setModalStates={setModalStates}
        modalStates={modalStates}
        setDataDialog={setClientsDialog}
      />

      <Suspense fallback={<span />}>
        <ClientsDialog
          data={clientsDialog}
          openModal={modalStates}
          handleClose={handleClose}
          providerId={originIds}
        />
      </Suspense>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(FornecedoresCatalogo);
