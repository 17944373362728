import styled, { css } from 'styled-components';

export const ItemWrapper = styled.span`
  color: ${({ validColor }) => (validColor ? 'inherit' : '#E22121')};
  ${({ typeLink, typeLinkColor }) =>
    typeLink &&
    css`
      cursor: pointer;
      color: ${typeLinkColor ? 'inherit' : '#5062F0'};
    `};

  ${({ secondContentGray }) =>
    secondContentGray &&
    css`
      color: #9296ac !important;
      font-size: 12px;
      font-weight: normal !important;
      text-align: left;
      width: ${({ width }) => width || '110px'} !important;
    `}

  ${({ buttonLink }) =>
    buttonLink &&
    css`
      cursor: pointer;
      padding: 4px;
      color: ${buttonLink.color};
      background-color: ${buttonLink.backgroundColor};
      border-radius: ${buttonLink.borderRadius
        ? buttonLink.borderRadius
        : '4px'};
      /* width: 47px; */
      overflow: hidden;
    `};
  ${({ inputSize }) =>
    inputSize &&
    css`
      width: ${inputSize};
    `};
  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
      text-underline-offset: 2px;
    `};
  ${({ truncateOff }) =>
    !truncateOff &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `};

  ${({ fontStyle }) =>
    fontStyle &&
    css`
      color: ${fontStyle?.color};
      font-weight: ${fontStyle?.weight};
      font-size: ${fontStyle?.fontSize || '12px'};
    `}
`;
