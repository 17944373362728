import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import moment from 'moment';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

import './style.scss';
import SingleInvoiceTable from './SingleInvoiceTable';

export default function DialogSingleInvoices({
  openState,
  onCloseProductDialog,
  data
}) {
  const emissionDate = data.emissionDate ? moment(data.emissionDate).add(3, 'hours').format('DD/MM/YYYY - HH:mm') : ''

  return (
    <Dialog
      open={openState}
      onClose={() => onCloseProductDialog()}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal mH80"
      maxWidth="lg"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <div className="dFlex justifyBetween alignCenter w100">
          <Typography>
            NOTA FISCAL {data.code} -{' '}
            {emissionDate}
          </Typography>
        </div>

        <IconButton
          className="ml10"
          edge="start"
          color="inherit"
          onClick={() => onCloseProductDialog()}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <SingleInvoiceTable data={data} />
      </DialogContent>
    </Dialog>
  );
}
