import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import format from 'date-fns/format';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

function ExtractCountsModal({ open, onClose, paperComponent, count }) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      PaperComponent={paperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal"
    >
      <DialogTitle
        className="modalHeader navyBlue bold cursorMove textAlignCenter"
        id="draggable-dialog-title"
      >
        <Typography>
          <b>
            Contagens - {count && count.description ? count.description : ''}
          </b>
        </Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={(e) => {
            onClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <table>
          <thead>
            <tr>
              <th width="200" className="subtitle">
                DATA
              </th>
              <th width="100" className="subtitle">
                QTDE.
              </th>
              <th width="150" className="subtitle right">
                TOTAL R$
              </th>
            </tr>

            <tr>
              <td colSpan="5">
                <hr className="titleLineBorder" style={{ margin: '0' }} />
              </td>
            </tr>
          </thead>

          <tbody>
            {count && count.diffs.length ? (
              count.diffs.map((diff, index) =>
                index === 0 ? (
                  <>
                    <tr>
                      <td className="description">
                        {diff.counts.length && diff.counts[0].date
                          ? format(new Date(diff.counts[0].date), 'dd/MMM')
                          : 'Data não definida'}
                      </td>

                      <td className="description right">
                        {diff.counts.length && diff.counts[0].quantity
                          ? parseFloat(diff.counts[0].quantity).toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3
                              }
                            )
                          : '0,000'}
                      </td>

                      <td
                        className="description right"
                        style={{ borderLeft: 'solid 1px #BFC0CB' }}
                      >
                        R${' '}
                        {diff.counts.length && diff.counts[0].value
                          ? diff.counts[0].value.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          : '0,000'}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="3" className="p0">
                        <hr
                          className="titleLineBorder"
                          style={{ margin: '0' }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="description">
                        {diff.counts.length && diff.counts[1].date
                          ? format(new Date(diff.counts[1].date), 'dd/MMM')
                          : 'Data não definida'}
                      </td>

                      <td className="description right">
                        {diff.counts.length && diff.counts[1].quantity
                          ? parseFloat(diff.counts[1].quantity).toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3
                              }
                            )
                          : '0,000'}
                      </td>

                      <td
                        className="description right"
                        style={{ borderLeft: 'solid 1px #BFC0CB' }}
                      >
                        R${' '}
                        {diff.counts.length && diff.counts[1].value
                          ? diff.counts[1].value.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          : '0,000'}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="3" className="p0">
                        <hr
                          className="titleLineBorder"
                          style={{ margin: '0' }}
                        />
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td className="description">
                        {diff.counts.length &&
                        diff.counts[diff.counts.length - 1].date
                          ? format(
                              new Date(
                                diff.counts[diff.counts.length - 1].date
                              ),
                              'dd/MMM'
                            )
                          : 'Data não definida'}
                      </td>

                      <td className="description right">
                        {diff.counts.length &&
                        diff.counts[diff.counts.length - 1].quantity
                          ? parseFloat(
                              diff.counts[diff.counts.length - 1].quantity
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3
                            })
                          : '0,000'}
                      </td>

                      <td
                        className="description right"
                        style={{ borderLeft: 'solid 1px #BFC0CB' }}
                      >
                        R${' '}
                        {diff.counts.length &&
                        diff.counts[diff.counts.length - 1].value
                          ? diff.counts[
                              diff.counts.length - 1
                            ].value.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          : '0,000'}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="3" className="p0">
                        <hr
                          className="titleLineBorder"
                          style={{ margin: '0' }}
                        />
                      </td>
                    </tr>
                  </>
                )
              )
            ) : (
              <>
                <tr>
                  <td colSpan="5" className="description center">
                    <Typography style={{ margin: '5px', textAlign: 'center' }}>
                      Não há contagens.
                    </Typography>
                  </td>
                </tr>

                <tr>
                  <td colSpan="5">
                    <hr className="titleLineBorder" style={{ margin: '0' }} />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default ExtractCountsModal;
