/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect, useCallback } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import deLocale from 'date-fns/locale/pt-BR';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import CurrencyTextFieldSumeValues from 'components/Inputs/CurrencyTextFieldSumeValues';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';
import ValidationLength from 'utils/utils';

export function SingleCount({ userInfo, countInfo }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorOpenSku, setErrorOpenSku] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [statusCode, setStatusCode] = useState()
  const [products, setProducts] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [registerCount, setRegisterCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { id: paramsID } = useParams();
  const [getId, setGetId] = useState(paramsID);
  const [message, setErrorMessage] = useState('');
  const [countId, setCountId] = useState(
    countInfo && countInfo.id ? countInfo.id : null
  );
  const [onMouseInTH, setOnMouseInTH] = useState(false);
  const [onHoverActiveID, setOnHoverActiveID] = useState();
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  // Date
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClose = (handle) => {
    handle(false);
  };

  const handleSuccess = (handle) => {
    handle(false);
    history.push(`/inventario/contagens/${paramsID}`);
  };

  useEffect(() => {
    setGetId(paramsID);
    setProducts([]);
  }, [paramsID]);

  useEffect(() => {
    setCountId(countInfo.id);
  }, [countInfo]);

  useEffect(() => {
    axios
      .get(`${environments.catalog}/inventories/${countId}?originId=${getId}`)
      .then((response) => {
        setProducts(response.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [countId, getId, countInfo]);

  const getOptions = useCallback(
    (endpoint, key, hook, setHook, originId, filters) => {
      (async () => {
        let params = '';

        if (filters !== undefined && filters) {
          const keys = Object.keys(filters);
          const values = Object.values(filters);
          keys.map((key, index) => (params += `&${key}=${values[index]}`));
        }

        const response = await fetch(
          `${endpoint}?page=${Number(
            !(key === 'providers')
          )}&size=1000&originId=${originId}${params}`
        );
        const item = await response.json();
        setHook(item.content);
      })();
    },
    []
  );

  function InsertData(product) {
    setProducts([...products, product]);

    product.skus.map((sku) => {
      product.setups.map((setup) => {
        if (sku.skus.providers.length) {
          sku.skus.providers.map((provider) => {
            registerCount.push({
              restaurantId: getId,
              quantity: 0,
              additional: null,
              user: userInfo.user,
              productId: product.id,
              skuId: sku.skus.id,
              providerId: provider.id,
              setupId: setup.setupId
            });
          });
        }
      });
    });
  }

  function handleBlur(stringSomableValues, value, onBlurProps) {
    const [productId, sku, provider, setup, additional, unityAdditional] =
      onBlurProps;

    setRegisterCount(
      registerCount.map((item) =>
        item.productId === productId &&
          item.skuId === sku.skuId &&
          item.setupId === setup.setupId &&
          item.providerId === provider.id
          ? additional
            ? { ...item, additional: parseFloat(value) }
            : unityAdditional
              ? { ...item, unityAdditional: parseFloat(value) }
              : { ...item, quantity: parseFloat(value) }
          : item
      )
    );

    const data = {
      productId,
      quantity: value,
      skuId: sku?.id,
      setupId: setup?.id,
      restaurantId: getId,
      providerId: provider?.id,
      additional: additional && value,
      unityAdditional: unityAdditional && value,
      labelQuantity: additional ? null : stringSomableValues,
      labelAdditional: additional ? stringSomableValues : null
    };

    if (!data.labelQuantity) delete data.labelQuantity;
    if (!data.labelAdditional) delete data.labelAdditional;

    return axios({
      method: 'POST',
      url: `${environments.catalog}/inventories/counts/${countId}/products/${productId}?originId=${getId}`,
      data,
      headers: {
        User: `${userLocal?.user}`
      }
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function PostNewCount() {
    const body = {
      restaurantId: getId,
      user: userInfo.user,
      date: new Date(selectedDate.toLocaleDateString('pt-BR')),
      items: registerCount
    };

    setTimeout(() => {
      axios
        .post(`${environments.catalog}/inventories/single`, body, config)
        .then((response) => {
          setLoading(false);
          setSuccessOpen(true);
        })
        .catch((error) => {
          setLoading(false);
          setErrorOpen(true);
          setErrorMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
        });
    }, 1000);
  }

  function KeyCheck(e) {
    const KeyID = window.e ? e.keyCode : e.keyCode;
    if (KeyID === 8) {
      alert('no backspaces!!');
    }
  }

  function deleteProduct(index) {
    products.splice(index, 1);
  }

  function removeRepeated(array, param) {
    const arr = [];

    array.filter((item) => {
      const i = arr.findIndex((x) => x[`${param}`] == item[`${param}`]);

      if (i <= -1) {
        arr.push(item);
      }
    });

    return arr;
  }

  const handleMouseOver = (id) => {
    setOnMouseInTH(true);
    setOnHoverActiveID(id);
  };

  const handleMouseLeave = () => {
    setOnMouseInTH(false);
  };

  function handleDesktopRows() {
    if (products.length === 0) {
      return (
        <tr>
          <td colSpan="13" valign="center" align="center">
            <Typography
              style={{
                fontFamily: 'Lato',
                verticalAlign: 'center',
                margin: '5px 0'
              }}
            >
              Não há registros para exibir.
            </Typography>
          </td>
        </tr>
      );
    }

    return products.map((product, productIndex) => {
      const { id } = product;
      let hasSecondary = false;
      switch (product.consumptionUnitsPrimary.abbreviation) {
        case 'kg':
          hasSecondary = true;
          break;
        case 'lt':
          hasSecondary = true;
          break;
        case 'l':
          hasSecondary = true;
          break;
        case 'un':
          if (
            product.consumptionUnitsSecondary &&
            product.consumptionUnitsSecondary.abbreviation !== undefined
          )
            hasSecondary = true;
          break;
        default:
          break;
      }

      return (
        <>
          {product.skus.map((sku, skuIndex) => {
            const skuProviders = sku.providers
              ? sku.providers
              : sku.skus.providers;
            const SKUDescription = sku?.description || sku?.skus?.description;
            const SKUQuantity = sku?.skus?.quantity || 0;
            const SKUUnitsMeasurements =
              sku?.skus?.unitsMeasurements?.abbreviation || '';
            const {
              id: { skuID }
            } = sku;
            if (skuProviders.length > 0) {
              for (let i = 0; i < skuProviders.length; i++) {
                skuProviders[
                  i
                ].providerPackage = `E${skuProviders[i].quantity}x${SKUQuantity}${SKUUnitsMeasurements}`;
              }
            }

            const providers = removeRepeated(skuProviders, 'providerPackage');
            const setups = product.setups
              ? removeRepeated(product.setups, 'config.storageLocation.id')
              : null;
            return (
              <>
                {product.setups
                  ? setups.map((setup, setupIndex) => {
                    const storageLocationDescription =
                      setup?.config?.storageLocation?.description || '';
                    return providers.map((provider, providerIndex) => (
                      <tr
                        onMouseOver={() => handleMouseOver(id)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          backgroundColor:
                            onMouseInTH && onHoverActiveID === id
                              ? 'rgba(80, 98, 240, 0.1)'
                              : ''
                        }}
                      >
                        <td
                          width="180"
                          className="description pl12"
                          valign="top"
                          style={{
                            paddingTop: 9,
                            background:
                              !skuIndex && !setupIndex && !providerIndex
                                ? '#F6F6F8'
                                : 'transparent',
                            borderBottom:
                              !skuIndex && !setupIndex && !providerIndex
                                ? 'solid 1px #ECEDF1'
                                : 'none'
                          }}
                        >
                          {!skuIndex && !setupIndex && !providerIndex
                            ? ValidationLength(product.description, 20)
                            : ''}
                        </td>

                        <td
                          width="40"
                          className="description"
                          valign="top"
                          style={{
                            paddingTop: 9,
                            background:
                              !skuIndex && !setupIndex && !providerIndex
                                ? '#F6F6F8'
                                : 'transparent',
                            borderBottom:
                              !skuIndex && !setupIndex && !providerIndex
                                ? 'solid 1px #ECEDF1'
                                : 'none'
                          }}
                        >
                          {!skuIndex && !setupIndex && !providerIndex
                            ? product.consumptionUnitsPrimary.abbreviation
                            : ''}
                        </td>

                        <td
                          width="220"
                          className="description"
                          style={{ borderBottom: 'solid 1px #ECEDF1' }}
                        >
                          {ValidationLength(SKUDescription, 25)}
                        </td>

                        <td
                          width="100"
                          className="description right"
                          style={{ borderBottom: 'solid 1px #ECEDF1' }}
                        >
                          {ValidationLength(storageLocationDescription, 15)}
                        </td>

                        <td
                          width="80"
                          className="description"
                          style={{ borderBottom: 'solid 1px #ECEDF1' }}
                        >
                          {provider.providerPackage}
                        </td>

                        <td
                          width="120"
                          className="description p8"
                          style={{ borderBottom: 'solid 1px #ECEDF1' }}
                        >
                          <CurrencyTextFieldSumeValues
                            key={providerIndex}
                            name="contagem"
                            placeholder="Contagem"
                            onKeyDown={KeyCheck}
                            onBlur={handleBlur}
                            onBlurProps={[
                              product.id,
                              sku,
                              provider,
                              setup,
                              false,
                              false
                            ]}
                            defaultValue={
                              provider?.labelQuantity
                                ? provider?.labelQuantity
                                : provider?.stock
                            }
                          />
                        </td>

                        <td
                          width="70"
                          style={{
                            borderLeft: 'solid 1px #BFC0CB',
                            background:
                              !skuIndex && !setupIndex && !providerIndex
                                ? 'rgba(0, 19, 115, 0.03)'
                                : 'transparent',
                            color: '#313347',
                            fontWeight: 'bold'
                          }}
                          className="description right p8"
                        >
                          {!skuIndex && !setupIndex && !providerIndex
                            ? (product.realStock
                              ? product.realStock
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                            : ''}
                        </td>

                        <td
                          width="70"
                          style={{
                            background:
                              !skuIndex && !setupIndex && !providerIndex
                                ? 'rgba(0, 19, 115, 0.08)'
                                : 'transparent',
                            color: '#313347'
                          }}
                          className="description right p8"
                        >
                          {!skuIndex && !setupIndex && !providerIndex
                            ? (product.theoreticalStock
                              ? product.theoreticalStock
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                            : ''}
                        </td>

                        <td
                          width="70"
                          style={{
                            borderLeft: 'solid 1px #BFC0CB',
                            color: '#313347'
                          }}
                          className="description right p8"
                        >
                          {!skuIndex && !setupIndex && !providerIndex
                            ? (product.averagePrice
                              ? product.averagePrice
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                            : ''}
                        </td>

                        <td
                          width="70"
                          style={{ color: '#313347', fontWeight: 'bold' }}
                          className="description right p8"
                        >
                          {!skuIndex && !setupIndex && !providerIndex
                            ? (product.costTotal
                              ? product.costTotal
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                            : ''}
                        </td>

                        <td
                          width="70"
                          style={{
                            borderLeft: 'solid 1px #BFC0CB',
                            fontWeight: 'bold',
                            background:
                              !skuIndex && !setupIndex && !providerIndex
                                ? product.difference >= 0
                                  ? '#F7DFDF'
                                  : '#DFF7E8'
                                : 'transparent',
                            color:
                              product.difference >= 0 ? '#D94141' : '#3DCC6E',
                          }}
                          className="description right p8"
                        >
                          {!skuIndex && !setupIndex && !providerIndex
                            ? (product.difference
                              ? product.difference
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                            : ''}
                        </td>

                        <td
                          width="70"
                          style={{
                            background:
                              !skuIndex && !setupIndex && !providerIndex
                                ? product.percentageDiff >= 0
                                  ? '#F7DFDF'
                                  : '#DFF7E8'
                                : 'transparent',
                            color:
                              product.percentageDiff >= 0
                                ? '#D94141'
                                : '#3DCC6E',
                            fontWeight: 'bold'
                          }}
                          className="description right p8"
                        >
                          {!skuIndex && !setupIndex && !providerIndex
                            ? (product.percentageDiff
                              ? product.percentageDiff
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                            : ''}
                        </td>

                        <td
                          width="15"
                          style={{
                            maxWidth: '15px',
                            borderLeft: 'solid 1px #BFC0CB'
                          }}
                          className="description pr12"
                        >
                          {!skuIndex && !setupIndex && !providerIndex ? (
                            <IconButton
                              className="p0"
                              size="small"
                              onClick={(e) => deleteProduct(productIndex)}
                            >
                              <DeleteOutline />
                            </IconButton>
                          ) : null}
                        </td>
                      </tr>
                    ));
                  })
                  : null}

                {/* {Sku Additional ? */}
                {product.consumptionUnitsPrimary.abbreviation.toLowerCase() ===
                  'kg' ||
                  product.consumptionUnitsPrimary.abbreviation.toLowerCase() ===
                  'lt' ? (
                  <tr
                    onMouseOver={() => handleMouseOver(id)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      backgroundColor:
                        onMouseInTH && onHoverActiveID === id
                          ? 'rgba(80, 98, 240, 0.1)'
                          : ''
                    }}
                  >
                    <td
                      width="180"
                      className="description"
                      valign="top"
                      style={{ paddingTop: 9 }}
                    />

                    <td
                      width="40"
                      className="description"
                      valign="top"
                      style={{ paddingTop: 9 }}
                    />

                    <td
                      width="220"
                      className="description"
                      style={{ paddingLeft: 0 }}
                    >
                      {ValidationLength(
                        `${SKUQuantity}${sku.skus &&
                          sku.skus.unitsMeasurements &&
                          sku.skus.unitsMeasurements.abbreviation
                          ? sku.skus.unitsMeasurements.abbreviation
                          : 'un'
                        }`,
                        25
                      )}
                    </td>

                    <td width="100" className="description right" />

                    <td width="80" className="description right" />

                    <td width="120" className="description right p8">
                      <FormControl fullWidth>
                        <CurrencyTextFieldSumeValues
                          name="contagem"
                          placeholder="Adicional em SKU"
                          onKeyDown={KeyCheck}
                          onBlur={handleBlur}
                          onBlurProps={[
                            product.id,
                            product?.skus[0]?.skus,
                            product?.skus[0]?.skus?.providers[0],
                            product?.setups[0],
                            false,
                            true
                          ]}
                        />
                      </FormControl>
                    </td>

                    <td
                      style={{ borderLeft: 'solid 1px #BFC0CB' }}
                      width="70"
                      className="description right p8"
                    >
                      { }
                    </td>

                    <td width="70" className="description right p8">
                      { }
                    </td>

                    <td
                      style={{ borderLeft: 'solid 1px #BFC0CB' }}
                      width="70"
                      className="description right p8"
                    >
                      { }
                    </td>

                    <td width="70" className="description right p8">
                      { }
                    </td>

                    <td
                      style={{ borderLeft: 'solid 1px #BFC0CB' }}
                      width="70"
                      className="description right p8"
                    >
                      { }
                    </td>

                    <td width="70" className="description right p8">
                      { }
                    </td>

                    <td style={{ borderLeft: 'solid 1px #BFC0CB' }}>&nbsp;</td>
                  </tr>
                ) : null}
              </>
            );
          })}

          {/* {hasSecondary ? */}
          <tr
            onMouseOver={() => handleMouseOver(id)}
            onMouseLeave={handleMouseLeave}
            style={{
              backgroundColor:
                onMouseInTH && onHoverActiveID === id
                  ? 'rgba(80, 98, 240, 0.1)'
                  : ''
            }}
          >
            <td
              width="180"
              className="description"
              valign="top"
              style={{ paddingTop: 9 }}
            />

            <td
              width="40"
              className="description"
              valign="top"
              style={{ paddingTop: 9 }}
            />

            <td
              width="220"
              className="description"
            >
              {`Adicional em ${product.consumptionUnitsPrimary.abbreviation}`}
            </td>

            <td width="100" className="description right" />
            <td width="80" className="description right" />

            <td width="120" className="description right p8">
              <FormControl fullWidth>
                <CurrencyTextFieldSumeValues
                  name="contagem"
                  placeholder="Adicional"
                  onKeyDown={KeyCheck}
                  onBlur={handleBlur}
                  onBlurProps={[
                    product.id,
                    product?.skus[0]?.skus,
                    product?.skus[0]?.skus?.providers[0],
                    product?.setups[0],
                    true,
                    false
                  ]}
                />
              </FormControl>
            </td>

            <td
              style={{ borderLeft: 'solid 1px #BFC0CB' }}
              width="70"
              className="description right p8"
            >
              { }
            </td>

            <td width="70" className="description right p8">
              { }
            </td>

            <td
              style={{ borderLeft: 'solid 1px #BFC0CB' }}
              width="70"
              className="description right p8"
            >
              { }
            </td>

            <td width="70" className="description right p8">
              { }
            </td>

            <td
              style={{ borderLeft: 'solid 1px #BFC0CB' }}
              width="70"
              className="description right p8"
            >
              { }
            </td>

            <td width="70" className="description right p8">
              { }
            </td>
            
            <td style={{ borderLeft: 'solid 1px #BFC0CB' }}>&nbsp;</td>
          </tr>

          {productIndex !== products.length - 1 && (
            <tr>
              <td colSpan="13">
                <hr style={{ background: '#77798B' }} />
              </td>
            </tr>
          )}
        </>
      );
    });
  }

  return (
    <Layout>
      <GlobalStyle />

      <Container className="dailyCounts">
        <Grid
          container
          xs={12}
          justify="space-between"
          alignItems="flex-end"
          style={{ marginBottom: 15 }}
        >
          <Typography className="bold textAlignLeft fontSize16 mr10">
            Contagem Avulsa de itens
          </Typography>

          <Typography className="bold textAlignLeft fontSizeDefault">
            Usuário: {userInfo.user}
          </Typography>
        </Grid>

        <table className="desktop" width="100%" border="0">
          <thead>
            <tr>
              <td colSpan="13">
                <Grid container style={{ marginBottom: 10, marginTop: 10 }}>
                  <div
                    className="dFlex alignCenter"
                    style={{
                      marginLeft: '12px',
                      width: '15%'
                    }}
                  >
                    <label
                      className="bold dFlex alignEnd"
                      style={{
                        margin: '8px 10px 9px 0px',
                        fontSize: '14px',
                        lineHeight: 'normal'
                      }}
                    >
                      Data inicial
                    </label>

                    <MuiPickersUtilsProvider
                      locale={deLocale}
                      utils={DateFnsUtils}
                    >
                      <DatePicker
                        inputVariant="outlined"
                        size="small"
                        fullWidth
                        className="dateSmall"
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        onChange={handleDateChange}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: 12 }
                        }}
                        style={{ width: '45%', paddingBottom: '6px' }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '17%'
                    }}
                  >
                    <label
                      style={{
                        margin: '8px 10px 9px 0px',
                        fontSize: '14px',
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'flex-end',
                        lineHeight: 'normal'
                      }}
                    >
                      Produtos
                    </label>

                    <Autocomplete
                      size="small"
                      id="asynchronous-demo"
                      onFocus={(event) =>
                        getOptions(
                          `${environments.catalog}/products`,
                          products,
                          productOptions,
                          setProductOptions,
                          getId
                        )
                      }
                      onChange={(option, value) =>
                        value ? setSelectedProduct(value) : null
                      }
                      getOptionLabel={(option) => option.description}
                      options={productOptions}
                      className="autocompleteSmall"
                      style={{ width: '70%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          type="text"
                          variant="outlined"
                          placeholder="Selecione..."
                        />
                      )}
                      renderOption={(params) => (
                        <Typography
                          style={{ fontSize: '12px', fontFamily: 'Lato' }}
                        >
                          {params.description}
                        </Typography>
                      )}
                    />
                  </div>

                  <div className="dFlex">
                    <Tooltip title="Contar item" aria-label="filters">
                      <IconButton
                        edge="start"
                        onClick={(e) => {
                          if (selectedProduct) {
                            if (selectedProduct.setups.length) {
                              const hasExpirationDate = [];
                              for (const stp of selectedProduct.setups) {
                                if (stp.config.expirationDate)
                                  hasExpirationDate.push(stp);
                              }

                              for (const tst of hasExpirationDate) {
                                const index =
                                  selectedProduct.setups.indexOf(tst);
                                selectedProduct.setups.splice(index, 1);
                              }

                              const items = selectedProduct.skus.filter(
                                (sku) => sku.skus.providers.length
                              ).length;
                              if (items) {
                                InsertData(selectedProduct);
                                setErrorOpenSku(false);
                              } else {
                                setErrorOpenSku(true);
                                setErrorMessage(
                                  'Não há embalagens configuradas para este produto.'
                                );
                              }
                            } else {
                              setErrorOpenSku(true);
                              setErrorMessage(
                                'Não há Setup configurado para este produto.'
                              );
                            }
                          } else {
                            setErrorOpenSku(true);
                            setErrorMessage(
                              'Selecione um produto para continuar.'
                            );
                          }
                        }}
                        color="inherit"
                        size="small"
                        style={{ outline: 0, marginLeft: '5px' }}
                        aria-label="add"
                      >
                        <img
                          style={{ width: '25px' }}
                          src={AddIcon}
                          alt="AddIcon"
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              </td>
            </tr>

            <tr className="headerTitle">
              <th
                width="180"
                style={{ maxWidth: '270px' }}
                className="subtitle"
              >
                PRODUTO
              </th>
              <th width="40" style={{ maxWidth: '70px' }} className="subtitle">
                UN.
              </th>
              <th
                width="220"
                style={{ maxWidth: '270px' }}
                className="subtitle"
              >
                SKU
              </th>
              <th width="70" style={{ maxWidth: '70px' }} className="subtitle">
                LOCAL
              </th>
              <th width="80" style={{ maxWidth: '30px' }} className="subtitle">
                EMBALAGEM
              </th>
              <th
                width="90"
                style={{ maxWidth: '90px' }}
                className="subtitle right p8"
              >
                CONTAGEM
              </th>
              <th
                width="70"
                style={{ maxWidth: '70px', borderLeft: 'solid 1px #BFC0CB' }}
                className="subtitle right p8"
              >
                REAL
              </th>
              <th
                width="70"
                style={{ maxWidth: '70px' }}
                className="subtitle right p8"
              >
                TEÓRICO
              </th>
              <th
                width="70"
                style={{ maxWidth: '70px', borderLeft: 'solid 1px #BFC0CB' }}
                className="subtitle right p8"
              >
                PREÇO R$
              </th>
              <th
                width="70"
                style={{ maxWidth: '70px' }}
                className="subtitle right p8"
              >
                TOTAL R$
              </th>
              <th
                width="70"
                style={{ maxWidth: '70px', borderLeft: 'solid 1px #BFC0CB' }}
                className="subtitle right p8"
              >
                DIF
              </th>
              <th
                width="70"
                style={{ maxWidth: '70px' }}
                className="subtitle right p8"
              >
                %
              </th>
              <th
                width="15"
                style={{ maxWidth: '15px', borderLeft: 'solid 1px #BFC0CB' }}
                className="subtitle"
              >
                &nbsp;
              </th>
            </tr>
          </thead>

          <tbody>
            {handleDesktopRows()}

            {loading ? (
              <tr>
                <td colSpan="13" sytle={{ margin: '10px' }}>
                  <LinearProgress variant="query" />
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>

        <Grid container justify="flex-end" className="actionButtons pt20">
          <Link to={`/inventario/contagens/${paramsID}`}>
            <Button
              className="defaultButton backButton"
              design="outlined"
              label="Voltar"
            />
          </Link>

          <Button
            disabled={
              !registerCount.length &&
              registerCount.every((item) => item.quantity === 0)
            }
            design="contained"
            className="defaultButton submitButton"
            style={{ marginLeft: '20px' }}
            onClick={() => {
              PostNewCount();
              setLoading(true);
            }}
            label={loading ? 'Salvando...' : 'Salvar'}
          />
        </Grid>

        <CommonAlert
          open={successOpen}
          onClose={() => handleSuccess(setSuccessOpen)}
          severity="success"
          indexMessage="Cadastro realizado com sucesso!"
        />

        <CommonAlert
          open={errorOpen}
          onClose={() => handleClose(setErrorOpen)}
          indexMessage={message}
          statusCode={statusCode}
        />

        <CommonAlert
          open={errorOpenSku}
          onClose={() => handleClose(setErrorOpenSku)}
          indexMessage={message}
        />
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  countInfo: state.count
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SingleCount);
