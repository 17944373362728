/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Tooltip } from "@material-ui/core";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
`;

const BlueCircle = styled(Circle)`
  background-color: #5062F0;
`;

const RedCircle = styled(Circle)`
  background-color: #FF0000;
`;

const GrayCircle = styled(Circle)`
  background-color: #A8A8A8;;
`;

export default function handleQuotations(obj, setDialogQuotesSettings, dialogQuotesSettings, setAssociation) {
  let tooltip;
  let circle;

  if (obj.quoteStatus === "VALID") {
    tooltip = "Cotação dentro do prazo de validade";
    circle = <BlueCircle />;
  }
  else if (obj.quoteStatus === "EXPIRATED") {
    tooltip = "Cotação vencida";
    circle = <RedCircle />;
  }
  else {
    tooltip = obj.quotationRequested ? "Esse produto não possui cotações" : "Esse SKU não possui uma cotação";
    circle = <GrayCircle />;
  }

  return (
    <Tooltip title={tooltip} aria-label="add">
      <Link
        onClick={() => {
          if (!obj.quoteStatus) return;
          setDialogQuotesSettings({ ...dialogQuotesSettings, open: true, obj });
          setAssociation(obj)
        }}
        className="dFlex alignCenter justifyCenter"
      >
        {circle}
      </Link>
    </Tooltip>
  );
}
