import numberToReal from 'utils/numberToReal';

export const moneyNumber = (number, placesDecimal = 0) =>
  `R$${numberToReal(number, placesDecimal)}`;

const signNumber = (number) => {
  const signal = Math.sign(number);
  if (signal === 1) {
    return '+';
  }
  if (signal === -1) {
    return '-';
  }

  return '';
};

const percentNumber = (
  number,
  { placesDecimal, showArithmeticOperator } = {
    placesDecimal: 0,
    showArithmeticOperator: false
  }
) => {
  let arithmeticOperator = '';

  if (showArithmeticOperator) {
    arithmeticOperator = signNumber(number);
  }

  return `${arithmeticOperator} ${numberToReal(number, placesDecimal)}%`;
};

const setColorOfPercentAverage = (number) => {
  const sign = signNumber(number);

  if (sign === '+') {
    return {
      backgroundColor: '#F7D4D7',
      color: '#AA0816'
    };
  }
  return {
    backgroundColor: '#D2F9D6',
    color: '#08AA15'
  };
};

const setColorOfOrdersAndVolume = (number) => {
  if (number > 50) {
    return {
      backgroundColor: '#DADDF3',
      color: '#0B1ABE'
    };
  }
  return {
    backgroundColor: '#F7D4D7',
    color: '#AA0816'
  };
};

const mapDataToBloomTable = (response) =>
  response.map((data) => {
    let total = 0;
    let withSales = 0;

    if (data?.skus?.length) {
      total = data.skus.length;
      withSales = data.skus.filter(
        (sku) => sku.extracts && sku.extracts.length > 0
      ).length;
    }

    const contentSkus = `${withSales}/${total}`;
    const skusDialog = {
      restaurantDescription: data?.description ? data?.description : '',
      skus: data?.skus ? data.skus : []
    };

    return [
      {
        content: data.description,
        type: 'text',
        itemLength: 100
      },
      {
        content: data.status,
        type: 'text'
      },
      {
        content: contentSkus,
        dataToDialog: skusDialog,
        action: 'modalSkus',
        type: 'richNumber',
        borderTable: true,
        borderColor: '#1F2445',
        align: 'number'
      },
      {
        content: numberToReal(data.salesQuantity, 0),
        type: 'richNumber',
        align: 'number'
      },
      {
        content: percentNumber(data.percentageQuantity),
        type: 'text',
        align: 'number',
        action: null,
        inputSize: '50px',
        buttonLink: setColorOfOrdersAndVolume(data.percentageQuantity)
      },
      {
        content: moneyNumber(data.salesVolume, 2),
        type: 'richNumber',
        align: 'number'
      },
      {
        content: percentNumber(data.percentageVolume),
        type: 'text',
        align: 'number',
        action: null,
        buttonLink: setColorOfOrdersAndVolume(data.percentageVolume),
        borderTable: true,
        inputSize: '50px',
        borderColor: '#5062F0'
      },
      {
        content: numberToReal(data.purchaseQuantity, 0),
        type: 'richNumber',
        align: 'number'
      },
      {
        content: moneyNumber(data.purchaseVolume, 2),
        type: 'richNumber',
        align: 'number'
      }
    ];
  });

export const handleFetchData = (responseData, state, setState) => {
  setState({ ...state, collunms: mapDataToBloomTable(responseData) });
};
