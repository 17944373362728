import React, { useEffect, useState } from 'react';

import { LinearProgress, Snackbar } from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import { connect } from 'react-redux';

import Button from 'components/BloomForms/Button';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import makeGet from 'services/useFetch/makeGet';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions
} from 'utils/handleFilterActions';

import CreateNewTagDiaglog from './Dialogs/CreateNewTag/CreateNewTag';
import { PaginationStyled, Table, TableDiv, Th, Tr } from './style';
import TagData from './TagData';

function CreateTag({ userInfo }) {
  const originIds = userInfo.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat()
    .map((item) => item.id);

  const environments = Environment(process.env.REACT_APP_ENV);
  const [openCreateNewTagDialog, setOpenCreateNewTagDialog] = useState(false);
  const [tagData, setTagData] = useState([]);
  const [tagPage, setTagPage] = useState({});
  const [loading, setLoading] = useState(false);
  const [sucessMessage, setSucessMassage] = useState(false);
  const [toastEditSuccess, setToastEditSuccess] = useState(false);
  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState([
    { param: 'page', value: 1, urlParam: `page=${page}` },
    {
      param: 'originId',
      value: originIds,
      urlParam: `originId=${originIds}`
    },
    {
      param: 'size',
      value: 50,
      urlParam: `size=${50}`
    }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'TAG',
        filterName: 'tagDescription',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/tags`,
        key: 'skusDescription',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        multiple: false
      },
      {
        label: 'VALOR',
        filterName: 'valueDescription',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/values`,
        key: 'skusDescription',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        multiple: false
      },
      {
        label: 'SKU',
        filterName: 'skuDescription',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/skus/platform`,
        key: 'skusDescription',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        multiple: false
      },
      {
        label: 'PRODUTO',
        filterName: 'productDescription',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'skusDescription',
        optionReference: 'description',
        options: [],
        size: 'medium_plus'
      }
    ],
    []
  ]);

  const handleFilter = (param, value) => {
    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      true
    );
    if (param == 'page') setPage(value);
  };

  const clearFilterFields = () => {
    filterFields[0].map((el) => (el.options = []));
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      originIds,
      false,
      1
    );
  };

  const generateOrderList = () => (
    <Button
      label="+ Adicionar Tag"
      primary
      onClick={() => setOpenCreateNewTagDialog(true)}
      width="auto"
    />
  );

  function getTagListing() {
    setLoading(true);
    const handleResponseDataTag = (data) => {
      if (data) {
        setTagPage(data);
        setTagData(data?.content);
        setLoading(false);
      }
      setLoading(false);
    };

    makeGet(
      'tagCreation',
      filtersChanged,
      handleResponseDataTag,
      `?page=${page}&size=${50}`
    );
  }

  useEffect(() => {
    getTagListing();
  }, [page, filtersChanged, filtersChanged]);

  const handleOpenSucessMessage = () => {
    setSucessMassage(true);
  };

  const handleCloseSucessMessage = () => {
    setSucessMassage(false);
    setToastEditSuccess(false);
  };
  const handleOpenEditSuccessMessage = () => {
    setToastEditSuccess(true);
  };

  return (
    <Layout>
      <Snackbar
        open={sucessMessage}
        autoHideDuration={6000}
        onClose={handleCloseSucessMessage}
      >
        <Alert onClose={handleCloseSucessMessage} severity="success">
          Tag cadastrada com sucesso!
        </Alert>
      </Snackbar>

      <Snackbar
        open={toastEditSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSucessMessage}
      >
        <Alert onClose={handleCloseSucessMessage} severity="success">
          Tag editada com sucesso!
        </Alert>
      </Snackbar>

      {openCreateNewTagDialog && (
        <CreateNewTagDiaglog
          openCreateNewTagDialog={openCreateNewTagDialog}
          setOpenCreateNewTagDialog={setOpenCreateNewTagDialog}
          getTagListing={getTagListing}
          handleOpenSucessMessage={handleOpenSucessMessage}
          handleCloseSucessMessage={handleCloseSucessMessage}
          clearFilterFields={clearFilterFields}
        />
      )}

      <TitleHead title="Criar tag" />

      <PageTitleAndFilter
        title="Criação de tag"
        activeFilterFilds={filterFields}
        handleFilter={handleFilter}
        getFilterhandle={getFilterhandle}
        afterTitleButton={generateOrderList}
        // onChange={setByGroupOnChange}
        dynamicJustifyOff
      />

      <div>
        <TableDiv>
          <Table width="100%">
            <thead>
              <Tr>
                <Th
                  className="th-default-background"
                  style={{ width: '560px' }}
                >
                  TAG
                </Th>

                <Th
                  className="th-default-background"
                  style={{ width: '180px' }}
                >
                  VALORES
                </Th>

                <Th
                  className="th-default-background"
                  style={{ width: '180px' }}
                >
                  SKUS
                </Th>

                <Th
                  className="th-default-background"
                  style={{ width: '50px' }}
                />
              </Tr>
            </thead>

            {loading && (
              <Tr>
                <Th colSpan="4" style={{ width: '100%', padding: '8px 0' }}>
                  <LinearProgress id="loading" variant="query" />
                </Th>
              </Tr>
            )}
            
            {tagData.length ? (
              tagData
                .filter((item) => item.status !== 'DELETED')
                .map((tag) => (
                  <TagData
                    tag={tag}
                    tagData={tagData}
                    setTagData={setTagData}
                    getTagListing={getTagListing}
                    handleOpenEditSuccessMessage={handleOpenEditSuccessMessage}
                  />
                ))
            ) : (
              <tr>
                <td
                  className="description textAlignCenter w100 pt10 pb10"
                  style={{ fontSize: '14px' }}
                  colSpan="4"
                >
                  Nenhum registro encontrado.
                </td>
              </tr>
            )}
          </Table>
        </TableDiv>
      </div>

      <PaginationStyled>
        <Pagination
          count={tagPage?.totalPages ? tagPage?.totalPages : 0}
          onChange={(event, pageNumber) => {
            handleFilter('page', pageNumber);
            document
              .querySelector('main')
              .scrollTo({ top: 0, behavior: 'smooth' });
          }}
          variant="outlined"
          shape="rounded"
          size="small"
          color="primary"
        />
      </PaginationStyled>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(CreateTag);
