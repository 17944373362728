import styled from 'styled-components';

export const DateRangeContainer = styled.div`
  margin-right: 35px;

  @media only screen and (max-width: 550px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .MuiInputAdornment-root {
    button {
      padding: 12px 0;
    }
  }

  .MuiFormControl-root {
    width: ${({ width }) => width || '70px'} !important;
    margin-right: ${({ marginRight }) => marginRight || '15px'};
  }

  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }

  label {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #00020f;
  }

  input {
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: #77798b;

    &::placeholder {
      font-style: italic;
    }
  }

  a {
    margin-top: -150px !important;
  }

  .clearIcon {
    margin-top: 20px;
  }
`;

export const IconRotateLeft = styled.img`
  width: 18px;
  cursor: pointer;
  @media only screen and (min-width: 480px) {
    position: relative;
    top: 20px;
    left: 5px;
  }
`;
