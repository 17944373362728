import React, { useState } from 'react';

import {
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Snackbar,
  LinearProgress,
  Popover,
} from '@material-ui/core';
import axios from 'axios';
import { Alert } from 'reactstrap';

import Button from 'components/Button/button';
import PaperComponent from 'components/PaperComponent';
import add from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';

function EditEan({
  openModal,
  handleClose,
  skuBloomId,
  user,
  edit,
  setRefreshData,
  setFindEan
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { editEan } = openModal;
  const [ean, setEan] = useState(edit || null);
  const [loading, setLoading] = useState(false);
  const [openConfirmationPopover, setOpenConfirmationPopover] = useState(false);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const handleTitle = (ean) => {
    let title = `EAN `;

    if (ean) {
      title = `EAN ${ean} `;
    }

    return title;
  };

  const onSubmitEan = (ean, user, isEdit) => {
    const body = {
      ean,
      user
    };

    setLoading(true);
    axios
      .put(`${environments.catalog}/skus/${skuBloomId}/platform`, body)
      .then((res) => {
        if (res.status == 200) {
          setLoading(false);
          setMessage({
            description: `Ean já cadastrado na Bloom`,
            status: 'warning',
            className: 'snackBarWarning'
          });
        } else {
          setMessage({
            description: `${isEdit ? 'Editado' : 'Registrado'} com sucesso`,
            status: 'success'
          });
          setOpenConfirmationPopover(false);
          setLoading(false);
          setRefreshData(Math.random());
        }
      })
      .catch((error) => {
        setMessage({
          description: `Ocorreu algum erro`,
          status: 'error',
          className: 'snackBarError'
        });
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={editEan}
      className="defaultModal"
      PaperComponent={PaperComponent}
      onClose={handleClose}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        style={{
          borderRadius: '4px 4px 0 0'
        }}
        id="draggable-dialog-title"
      >
        <Typography>{handleTitle(edit)}</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            handleClose();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      {loading ? <LinearProgress /> : null}

      <Snackbar
        className={message.className}
        open={!!message.description}
        autoHideDuration={message.status === 'success' ? 800 : false}
        onClose={(e) => {
          if (message.status === 'success') {
            handleClose();
            setMessage({ description: '', status: '' });
          }
        }}
      >
        <Alert
          onClose={() => {
            if (message.status === 'success') {
              handleClose();
              setMessage({ description: '', status: '' });
            }
          }}
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>

      <div
        className="cardDefault pt16"
        style={{ width: 280, align: 'right', borderRadius: '0' }}
      >
        {!edit ? (
          <>
            <TextField
              label="EAN"
              name="EAN"
              placeholder="Digite aqui..."
              fullWidth
              variant="outlined"
              onChange={(e) => setEan(e.target.value)}
              value={ean}
              className="textFieldSmall mb20 mt20"
              required
              size="small"
            />

            <div className="dFlex justifyCenter">
              <Button
                className="defaultButton submitButton"
                style={{ align: 'right', borderRadius: '0' }}
                type="submit"
                label="Salvar"
                design="contained"
                disabled={!ean}
                onClick={async () => {
                  onSubmitEan(ean, user);
                }}
              >
                <img width="20px" src={add} alt={add} />
              </Button>
            </div>
          </>
        ) : (
          <>
            <TextField
              label="EAN"
              name="EAN"
              placeholder="Digite aqui..."
              fullWidth
              variant="outlined"
              onChange={(e) => setEan(e.target.value)}
              value={ean}
              className="textFieldSmall mb20 mt20"
              required
              size="small"
            />

            <div className="dFlex justifyCenter">
              <Button
                className="defaultButton submitButton mr20"
                type="submit"
                label="Excluir"
                onClick={() => {
                  setOpenConfirmationPopover(true);
                }}
              />

              <Button
                className="defaultButton submitButton"
                type="submit"
                label="Editar"
                design="contained"
                onClick={async () => {
                  onSubmitEan(ean, user, true);
                }}
              >
                <img width="20px" src={add} alt={add} />
              </Button>
            </div>
          </>
        )}

        <Popover
          className="editEanPopover"
          open={openConfirmationPopover}
          onClose={() => setOpenConfirmationPopover(false)}
        >
          <div className="dFlex flexColumn" style={{ padding: '20px 16px' }}>
            <p className="mb20">Deseja realmente excluir este EAN?</p>

            <div className="dFlex justifyCenter">
              <Button
                className="defaultButton backButton mr20"
                label="Voltar"
                onClick={() => {
                  setOpenConfirmationPopover(false);
                }}
              />

              <Button
                label="Sim"
                className="defaultButton submitButton"
                onClick={async () => {
                  onSubmitEan(null, user);
                  setFindEan(false);
                }}
              />
            </div>
          </div>
        </Popover>
      </div>
    </Dialog>
  );
}
export default EditEan;
