/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState, useEffect } from 'react';

import {
  Checkbox,
  IconButton,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import format from 'date-fns/format';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import DownloadIcon from 'images/icons/downloadUpload/download_down.svg'
import DivergenceAlert from 'images/icons/signals/alerts/failAlert.svg';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function TransferReports({ userInfo }) {
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const { id: paramsID } = useParams();
  const history = useHistory();
  const queryParams = useLocation()?.search;
  const isGroupManage = paramsID?.includes(',');
  const [loading, setLoading] = useState(false);
  const [transfersReport, setTransfersReport] = useState([]);
  const [productIdQueryParam, setProductIdQueryParam] = useState(
    queryParams?.includes('productId')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('productId'))
          ?.split('=')[1]
      : ''
  );
  const [productDescriptionQueryParam, setProductDescriptionQueryParam] =
    useState(
      queryParams?.includes('productDescription')
        ? decodeURI(
            queryParams
              ?.split('&')
              ?.find((el) => el.includes('productDescription'))
              ?.split('=')[1]
          )
        : ''
    );
  const [startDateFilterDefaultValue, setStartDateFilterDefaultValue] =
    useState(
      queryParams?.includes('startDate')
        ? queryParams
            ?.split('&')
            ?.find((el) => el.includes('startDate'))
            ?.split('=')[1]
        : ''
    );
  const [endDateFilterDefaultValue, setEndDateFilterDefaultValue] = useState(
    queryParams?.includes('endAt')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('endAt'))
          ?.split('=')[1]
      : ''
  );

  const DEFAULT_FILTERS = useMemo(
    () => [
      startDateFilterDefaultValue && {
        param: 'startDate',
        value: startDateFilterDefaultValue,
        urlParam: `startDate=${startDateFilterDefaultValue}`
      },
      {
        param: 'endDate',
        value: endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd'),
        urlParam: `endDate=${
          endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd')
        }`
      },
      productIdQueryParam && {
        param: 'productId',
        value: productIdQueryParam,
        urlParam: `productId=${productIdQueryParam}`
      },
    ],
    [
      endDateFilterDefaultValue,
      startDateFilterDefaultValue,
    ]
  );
  const [filtersChanged, setFiltersChanged] = useState(DEFAULT_FILTERS);
  const [callSettings, setCallSettings] = useState({
    page: 1,
    totalPages: 0,
    size: 100,
    filters: DEFAULT_FILTERS
  });
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [sentFilter, setSentFilter] = useState(false);
  const [receivedFilter, setReceivedFilter] = useState(false);
  const { page, filters } = callSettings;

  const [filterFields, setFilterFields] = useState([
    [
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'startDate',
        filterNameSecond: 'endDate',
        initialDate: startDateFilterDefaultValue
          ? moment(startDateFilterDefaultValue).add(3, 'hours')
          : moment()?.subtract(7, 'days'),
        endDate: endDateFilterDefaultValue
          ? moment(endDateFilterDefaultValue).add(3, 'hours')
          : moment()?.subtract(1, 'day')
      },
      {
        label: 'Produto',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        optionReference: 'description',
        size: 'medium_plus',
        options: [],
        storedValue: productDescriptionQueryParam || '',
        searchByTermMode: true
      },
      {
        label: 'Transferido Para',
        filterName: 'toRestaurantId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'stores',
        optionReference: 'name',
        options: stores[0]?.map(el => (
          {
            ...el,
            value: el?.id,
            title: el?.name
          }
        )),
        size: 'medium_plus',
        multiple: true,
      },
      {
        label: 'Transferido De',
        filterName: 'fromRestaurantId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'stores',
        optionReference: 'name',
        options: stores[0]?.map(el => (
          {
            ...el,
            value: el?.id,
            title: el?.name
          }
        )),
        size: 'medium_plus',
        multiple: true,
        // disabledParamArr: [],
        // disabled: true,
      },
    ]
  ]);

  // Filter
  const handleFilter = (param, value, _, paramSecond) => {
    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!value) {
      setStartDateFilterDefaultValue(
        param === 'startDate' ? '' : startDateFilterDefaultValue
      );
      setEndDateFilterDefaultValue(
        param === 'endDate' ? '' : endDateFilterDefaultValue
      );
      setProductIdQueryParam(param === 'productId' ? '' : productIdQueryParam);

      if (hasFilterWithFirstName || hasFilterWithSecondName) {
        formattedFilters = filtersChanged?.filter(
          (el) => el?.param !== paramSecond && el?.param !== param
        )?.filter(el => el);
      }
    }

    const urlParams = new URLSearchParams(queryParams);

    if (urlParams.has(param)) {
      urlParams.delete(param);
      history.replace({
        search: urlParams.toString()
      });
    }

    handleFilterOptions(param, value, formattedFilters, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      paramsID,
      filtersChanged,
      page
    );
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const buildTransferTotalQuantityInfos = () => {
    const totalCalc = numberToReal(
      transfersReport?.length ?
        transfersReport?.reduce(
          (total, value) =>
            {
              if (value?.type === "TRANSFER_OUT") {
                return parseFloat(total) - parseFloat(value?.quantity)  
              }

              return parseFloat(total) + parseFloat(value?.quantity)  
            },
          0
        )
      : 0,
      3
    )
    const signal = parseFloat(totalCalc) === 0 
      ? '' 
      : parseFloat(totalCalc) > 0 
        ? '+' 
        : '-'

    const totalObj = {
      value: parseFloat(totalCalc),
      color: parseFloat(totalCalc) === 0 ? 'rgb(14, 19, 55)' : parseFloat(totalCalc) > 0 ? '#0B1A8E' : '#AA0816',
      background: parseFloat(totalCalc) === 0 ? 'rgb(230, 231, 240)' : parseFloat(totalCalc) > 0 ? '#DADDF3' : '#F7D4D7',
      textFormatted: `${signal} ${totalCalc?.replace("-", "")} ${transfersReport?.find(el => el?.product?.description)?.product?.consumptionUnitsPrimary?.abbreviation}`
    }

    return totalObj
  }

  const buildTransferTotalInfos = () => {
    const totalCalc = numberToReal(
      transfersReport?.length ?
        transfersReport?.reduce(
          (total, value) =>
            {
              const transferCost = (value?.product?.lastCost || value?.product?.averageCost || 0)
              const valueCalc = parseFloat(transferCost) * parseFloat(value?.quantity)

              if (value?.type === "TRANSFER_OUT") {
                return parseFloat(total) - parseFloat(valueCalc)  
              }

              return parseFloat(total) + parseFloat(valueCalc)  
            },
          0
        )
      : 0,
      3
    )

    const signal = parseFloat(totalCalc) === 0 
      ? '' 
      : parseFloat(totalCalc) > 0 
        ? '+' 
        : '-'

    const totalObj = {
      value: parseFloat(totalCalc),
      color: parseFloat(totalCalc) === 0 ? 'rgb(14, 19, 55)' : parseFloat(totalCalc) > 0 ? '#0B1A8E' : '#AA0816',
      background: parseFloat(totalCalc) === 0 ? 'rgb(230, 231, 240)' : parseFloat(totalCalc) > 0 ? '#DADDF3' : '#F7D4D7',
      textFormatted: `${signal} R$ ${totalCalc?.replace("-", "")}`
    }
    
    return totalObj
  }

  const buildTransferTypeInfo = (transfer) => {
    const currentTransfer = transfer?.transferEntry || transfer?.transferOut
    let transferObj = {
      color: '',
      text: '',
      signal: '',
      background: ''
    }

    if (transfer?.type === "TRANSFER_ENTRY") {
      transferObj = {
        color: "#0B1A8E",
        text: `Transferido de ${currentTransfer?.fromRestaurantName}`,
        signal: '+',
      }
    }

    if (transfer?.type === "TRANSFER_OUT") {
      transferObj = {
        color: "#AA0816",
        text: `Transferido para ${currentTransfer?.toRestaurantName}`,
        signal: '-',
      }
    }

    return transferObj
  }

  const getMainContent = async () => {
    setLoading(true);

    const params = filtersChanged
      ?.filter((filter) => filter)
      ?.map((filter) => filter?.urlParam)
      .join('&');

    await axiosInstanceCatalog
      .get(`${environments.catalog}/products/transfer/report?restaurantId=${paramsID}&${params}`)
      .then((response) => {
        setTransfersReport(response?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onClearFilters = () => {
    const TODAY = new Date();

    const FILTERS = DEFAULT_FILTERS.map((filter) => {
      if (filter?.param === 'startDate') {
        return {
          param: 'startDate',
          value: '',
          urlParam: ''
        };
      }

      if (filter?.param === 'endDate') {
        return {
          param: 'endDate',
          value: format(TODAY, 'yyyy-MM-dd'),
          urlParam: `endDate=${format(TODAY, 'yyyy-MM-dd')}`
        };
      }

      return { ...filter, value: '', urlParam: '' };
    });

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((filter) => {
          if (filter.type === 'dateRangeField') {
            return {
              type: 'dateRangeField',
              size: 'small',
              filterNameFirst: 'startDate',
              filterNameSecond: 'endDate',
              initialDate: '',
              endDate: TODAY
            };
          }

          return filter;
        });
      }

      return filterLine;
    });

    setCallSettings((prevState) => ({
      ...prevState,
      filters: FILTERS
    }));
    setFiltersChanged(FILTERS);
    setFilterFields(FILTER_FIELDS);
  };

  const downloadSpreadsheet = () => {
    const params = filtersChanged
      ?.filter((filter) => filter)
      ?.map((filter) => filter?.urlParam)
      .join('&');


    const url = `${environments.catalog}/products/transfer/report?restaurantId=${paramsID}&spreadSheet=true&${params}`

    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const downloadSpreadsheetButton = () => (
    <Tooltip
      title="Baixar planilha com relatórios"
      aria-label="filters"
    >
      <IconButton
        className="p0"
        edge="start"
        color="inherit"
        aria-label="add"
        onClick={() => { downloadSpreadsheet() }}
      >
        <img style={{ width: "25px" }} src={DownloadIcon} alt="Download" />
      </IconButton>
    </Tooltip>
  )

  useEffect(() => {
    setTransfersReport([]);

    getMainContent();
  }, [paramsID, filtersChanged, filters, environments.catalog]);

  return (
    <Layout>
      <TitleHead title="Relatório de Transferências" />

      <GlobalStyle />

      <Container className="productionPage">
        <div className="desktopFilterInfo">
          <PageTitleAndFilter
            title="Transferências"
            activeFilterFilds={filterFields}
            additionalbutton={downloadSpreadsheetButton}
            handleFilter={handleFilter}
            handleMultipleFilters={handleOrdernationFilter}
            getFilterhandle={getFilterhandle}
            dynamicJustifyOff
          />

          <div className="dFlex transferReportHeader">
            {filtersChanged?.find(el => el?.param?.includes('product') && el?.value) && transfersReport?.length ?
              <div className="dFlex alignCenter mlAuto">
                <span className="mr8">
                  Total
                </span>
                
                <span
                  className="totalInfosValue bold"
                  style={{ background: buildTransferTotalQuantityInfos()?.background, color: buildTransferTotalQuantityInfos()?.color }}
                >
                  {buildTransferTotalQuantityInfos()?.textFormatted}
                </span>
              </div>
            : null}

            <div className="dFlex alignCenter justifyEnd" style={{ width: "11%", marginLeft: filtersChanged?.find(el => el?.param?.includes('product') && el?.value) ? 16 : 'auto' }}>
              <Checkbox
                style={{
                  marginTop: 4,
                  color: '#1F2445'
                }}
                name="View Mode"
                checked={sentFilter}
                className="checkBoxSmall"
                onChange={(_, checked) => {
                  setSentFilter(checked)
                  handleFilter("sent", checked)
                }}
                size="small"
              />

              <label
                onClick={() => {
                  setSentFilter(!sentFilter)
                  handleFilter("sent", !sentFilter)
                }}
                className="initialTransform fontWeightNormal fontSizeDefault dFlex alignEnd cursorPointer mb0 mr10"
                style={{
                  lineHeight: 'normal',
                  color: '#1F2445'
                }}
              >
                Enviadas
              </label>

              <Checkbox
                style={{
                  marginTop: 4,
                  color: '#1F2445'
                }}
                name="View Mode"
                checked={receivedFilter}
                className="checkBoxSmall"
                onChange={(_, checked) => {
                  setReceivedFilter(checked);
                  handleFilter("received", checked)
                }}
                size="small"
              />

              <label
                onClick={() => {
                  setReceivedFilter(!receivedFilter)
                  handleFilter("received", !receivedFilter)
                }}
                className="initialTransform fontWeightNormal fontSizeDefault dFlex alignEnd cursorPointer mb0"
                style={{
                  lineHeight: 'normal',
                  color: '#1F2445'
                }}
              >
                Recebidas
              </label>
            </div>

            <div className="dFlex alignCenter justifyEnd" style={{ width: "11%", marginLeft: 10 }}>
              <span className="mr8">
                Total
              </span>
              
              <span
                className="totalInfosValue bold"
                style={{ background: buildTransferTotalInfos()?.background, color: buildTransferTotalInfos()?.color }}
              >
                {buildTransferTotalInfos()?.textFormatted}
              </span>
            </div>
          </div>
        </div>

        <table
          className="desktop transferReportTable"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <tr>
            <th colSpan={isGroupManage ? "7" : "6"} style={{ backgroundColor: '#FFF' }}>
              <hr />
            </th>
          </tr>

          <thead>
            <tr className="headerTitle">
              <th width="8%" className="subtitle">
                DATA
              </th>

              <th width="28%" className="subtitle">
                PRODUTO
              </th>

              {isGroupManage ?
                <>
                  <th width="14%" className="subtitle">
                    ORIGEM 
                  </th>

                  <th width="14%" className="subtitle">
                    DESTINO
                  </th>
                </>
              :
                <th width="28%" className="subtitle">
                  ORIGEM / DESTINO
                </th>
              }
              
              <th width="12%" className="subtitle right" style={{ paddingRight: 29 }}>
                QTDE
              </th>

              <th width="12%" className="subtitle right pr8">
                CUSTO
              </th>

              <th width="12%" className="subtitle right">
                VALOR
              </th>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th className="mt10 textAlignCenter" colSpan={isGroupManage ? "7" : "6"}>
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {transfersReport?.length ? (
              transfersReport.map((transfer, transferIndex) => {
                const isLastElement =
                  transferIndex === transfersReport?.length - 1;
                const transferCost = transfer?.product?.lastCost || transfer?.product?.averageCost
                const isEntryTransfer = transfer?.type === "TRANSFER_ENTRY"
                const currentTransfer = transfer?.transferEntry || transfer?.transferOut
                const currentQuantity = isEntryTransfer ? transfer?.quantity : transfer?.quantity * -1
                const finalValueNotFormatted = transferCost * currentQuantity
                const finalValue = numberToReal(finalValueNotFormatted, 3)
                const nextTransferDate = transfersReport?.find((_, index) => index === transferIndex + 1)?.date
                const renderDivisor = moment(nextTransferDate)?.format('DD/MM/YYYY') !== moment(transfer?.date)?.format('DD/MM/YYYY')

                return (
                  <>
                    <tr className="lineBorder">
                      <td className="description pr8 pl12">
                        {moment(transfer?.date)?.format('DD/MM/YYYY')}
                      </td>

                      <td
                        className="description pr8"
                      > 
                        {ValidationLength(transfer?.product?.description, 40)}
                      </td>

                      {isGroupManage ?
                        <>
                          <td
                            className="description pr8"
                            style={{ color: '#AA0816' }}
                          >
                            {currentTransfer ? currentTransfer?.fromRestaurantName : '-'}
                          </td>

                          <td
                            className="description pr8"
                            style={{ color: '#0B1A8E' }}
                          >
                            {currentTransfer ? currentTransfer?.toRestaurantName : '-'}
                          </td>
                        </>
                      :
                        <td
                          className="description pr8"
                          style={{ color: buildTransferTypeInfo(transfer)?.color }}
                        >
                          {currentTransfer ? buildTransferTypeInfo(transfer)?.text : '-'}
                        </td>
                      }
                      
                      <td
                        className="description textAlignRight pr8"
                        style={{ 
                          color: isGroupManage 
                            ? '#0F1225' 
                            : isEntryTransfer 
                              ? '#0B1A8E' 
                              : '#AA0816' 
                        }}
                      >
                        <div className="dFlex alignCenter justifyEnd">
                          {!isGroupManage ? 
                            isEntryTransfer ? "+ " : "- "
                          : ''}

                          {transfer?.quantity?.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })} {" "}

                          {transfer?.product?.consumptionUnitsPrimary?.abbreviation}
                          
                          <div className="dFlex alignCenter justifyEnd" style={{ width: 20 }}>
                            {transfer?.hasDifference ?
                              <Tooltip
                                title={
                                  <div className="dFlex flexColumn" style={{ padding: '2px' }}>
                                    <p
                                      className="mb0 bold"
                                      style={{ padding: '2px 6px' }}
                                    >
                                      Qtde transferida: {transfer?.originalQuantity?.toLocaleString('pt-br', {
                                        minimumFractionDigits: 3
                                      })}

                                      {transfer?.product?.consumptionUnitsPrimary?.abbreviation}
                                    </p>

                                    <p
                                      className="mb0 bold"
                                      style={{ padding: '2px 6px' }}
                                    >
                                      Qtde recebida: {transfer?.quantity?.toLocaleString('pt-br', {
                                        minimumFractionDigits: 3
                                      })}

                                      {transfer?.product?.consumptionUnitsPrimary?.abbreviation}
                                    </p>
                                  </div>
                                }
                                aria-label="filters"
                                placement="right-start"
                              >
                                <img src={DivergenceAlert} alt="DivergenceAlert" style={{ width: 14 }} />
                              </Tooltip>
                            : null}
                          </div>
                        </div>
                      </td>

                      <td
                        className="description textAlignRight pr8"
                      >
                        R${' '}
                        {numberToReal(
                          transferCost,
                          3
                        )}
                      </td>

                      <td
                        className="description textAlignRight pr12"
                      >
                        {!isGroupManage ? 
                          finalValueNotFormatted === 0 
                            ? '' 
                            : finalValueNotFormatted > 0 ? "+ " : "- "
                        : ''}

                        R${' '}

                        {finalValue?.replace("-", "")}
                      </td>
                    </tr>

                    {renderDivisor && !isLastElement && (
                      <tr>
                        <td colSpan={isGroupManage ? "7" : "6"} className="pr0 pl0 pt0 pb0">
                          <hr
                            className="m0"
                            style={{
                              height: '0px',
                              backgroundColor: '#333541'
                            }}
                          />
                        </td>
                      </tr>
                    )}

                    {!isLastElement && (
                      <tr>
                        <td colSpan={isGroupManage ? "7" : "6"} className="pr0 pl0 pt0 pb0">
                          <hr
                            className="m0"
                            style={{
                              height: '0px',
                              backgroundColor: '#D2D3D7'
                            }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })
            ) : (
              <tr>
                <th className="mt10 textAlignCenter" colSpan={isGroupManage ? "7" : "6"}>
                  Nenhuma transferência encontrada{' '}
                </th>
              </tr>
            )}
          </tbody>
        </table>
      </Container>

      <CommonAlert
        open={!!message.description}
        onClose={() => setMessage({ description: '', status: '' })}
        severity={message.status}
        indexMessage={message.description}
        messagePersonal={message.description}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransferReports);
