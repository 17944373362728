import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

import configPack from './images/configPack.png';
import imageSku from './images/imageSKU.png';
import imageMultipleSku from './images/multipleSku.png';
import imageMultiplePack from './images/multiplesPack.png';
import numberOne from './images/numberOne.svg';
import numberTwo from './images/numberTwo.svg';
import register from './images/registerPack.png';

export function HelperCount({ openModal, onClose }) {
  return (
    <Dialog
      maxWidth="lg"
      open={openModal}
      onClose={onClose}
      className="defaultModal bigModal-help"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeaderNewLayout bold textAlignCenter cursorMove w100"
        style={{
          backgroundColor: '#5062F0',
          height: '35px'
        }}
      >
        <p style={{ margin: 0 }}>
          ENTENDA AS CONFIGURAÇÕES DAS CONTAGENS E COMO CONTAR!
        </p>

        <IconButton edge="start" color="inherit" onClick={onClose}>
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent
        className="modalContent minHeight150px"
        style={{ backgroundColor: '#333541' }}
      >
        <section>
          <Typography style={{ color: '#F7D56E' }}>
            PRODUTO x SKUs x EMBALAGENS
          </Typography>

          <Grid
            style={{ backgroundColor: '#fff', borderRadius: 4, padding: 8 }}
          >
            <div>
              <p>
                De acordo com a configuração de cada restaurante, 1 Produto
                poderá conter 1 SKU ou Múltiplos SKUs
              </p>
            </div>

            <div>
              <p style={{ fontWeight: 600 }}>Exemplo: 1 Produto com 1 SKU:</p>

              <img src={imageSku} alt={imageSku} style={{ width: '100%' }} />
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontWeight: 600 }}>
                Exemplo: 1 Produto com multiplos SKU:
              </p>

              <img
                src={imageMultipleSku}
                alt={imageMultipleSku}
                style={{ width: '100%' }}
              />
            </div>
          </Grid>
        </section>

        <section style={{ marginTop: 20 }}>
          <Typography style={{ color: '#F7D56E' }}>
						EMBALAGENS
					</Typography>

          <Grid
            style={{ backgroundColor: '#fff', borderRadius: 4, padding: 8 }}
          >
            <div>
              <p>
                E assim como 1 Produto poderá conter 1 ou múltiplos SKUs, 1 SKU
                poderá conter 1 ou múltiplas embalagens
              </p>
            </div>

            <div>
              <p style={{ fontWeight: 600 }}>
                Exemplo 1 SKU com múltiplas embalagens:
              </p>

              <img
                src={imageMultiplePack}
                alt={imageMultiplePack}
                style={{ width: '100%' }}
              />
            </div>
          </Grid>
        </section>

        <section style={{ marginTop: 20 }}>
          <Typography style={{ color: '#F7D56E' }}>
            CONFIGURAÇÃO DE EMBALAGENS
          </Typography>

          <Grid
            style={{ backgroundColor: '#fff', borderRadius: 4, padding: 8 }}
          >
            <div>
              <p>
                As embalagens dos SKUs são configuradas pela Plataforma Bloom de
                acordo com a disponibilidade do mercado e, com isso,
                inicialmente as contagens apresentarão todas as variações
                cadastradas
              </p>

              <p>
                Porém é possível configurar as embalagens de cada produto das
                suas contagens
              </p>
            </div>

            <div>
              <p>
                Clique sobre o ícone de configuração de embalagens do produto:
              </p>

              <img src={configPack} alt={configPack} style={{ width: '55%' }} />
            </div>

            <div style={{ marginTop: 20 }}>
              <p>
                Conforme sua necessidade, habilite ou desabilite as embalagens
                cadastradas:
              </p>

              <img src={register} alt={register} style={{ width: '100%' }} />
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontWeight: 600 }}>
                Obs.: Estas configurações ficarão salvas em cada produto e já
                estarão disponíveis nas suas próximas contagens
              </p>
            </div>
          </Grid>
        </section>

        <section style={{ marginTop: 20 }}>
          <Typography style={{ color: '#F7D56E' }}>
						COMO CONTAR...
					</Typography>

          <Grid
            style={{ backgroundColor: '#fff', borderRadius: 4, padding: 8 }}
          >
            <div style={{ position: 'relative' }}>
              <p>
                Produto COCA COLA KS possui 1 SKU e este SKU possui 1 Embalagem
                cadastrada de 24 unidades
              </p>

              <img src={imageSku} alt={imageSku} style={{ width: '100%' }} />

              <img
                src={numberOne}
                alt={numberOne}
                style={{ position: 'absolute', left: '65%', top: '47%' }}
              />

              <img
                src={numberTwo}
                alt={numberTwo}
                style={{ position: 'absolute', left: '65%', top: '74%' }}
              />
            </div>

            <div>
              <p>
                Além da embalagem cadastrada será criado automaticamente uma
                linha para registro de contagens adicionais (“Adicional em un”)
              </p>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontWeight: 600 }}>Exemplo 1:</p>

              <p>
                Ao conferir fisicamente o estoque deste produto, você
                identificou 2 “fardos” de 24 unidades + 2 unidades soltas
                <br />
                Preencha o campo da linha 1 com 2,000 e preencha o campo da
                linha 2 com 2,000
                <br />
                Total de 50 unidades registradas para este produto nesta
                contagem (2,000 x 24 + 2,000 = 50,000)
              </p>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontWeight: 600 }}>Exemplo 2:</p>

              <p>
                Ao conferir fisicamente o estoque deste produto, você
                identificou 10 unidades soltas
                <br />
                Não preencha nada no campo da linha 1 e preencha o campo da
                linha 2 com 10,000
                <br />
                Total de 10 unidades registradas para este produto
              </p>
            </div>

            <div style={{ marginTop: 20 }}>
              <p style={{ fontWeight: 600 }}>
                Neste mesmo exemplo, é possível registrar a contagem de 50
                unidades utilizando somente o campo 2 (adicionais)?
              </p>
							
              <p>
                Sim! Basta não preencher nada no campo 1 e preencher o campo 2
                com 50,000
                <br />
                Porém entendemos que o campo 1 deverá facilitar para quem
                estiver efetuando a conferência dos estoques e encontrar fardos,
                pack's, embalagens múltiplas, etc...
              </p>
            </div>
          </Grid>
        </section>
      </DialogContent>
    </Dialog>
  );
}
