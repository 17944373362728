import React from 'react';


import { EditModeIcons } from 'components/MainInfoCard/utils';

import {
  DropDownContainer,
  ConfirmEditContainer,
  ImgIconEdit,
  StyledAutoComplete,
  IconDropDownWrap,
  ImgIconContainer
} from './index.style';


function EditModeHeader({ handleClick }) {
  const test = [
    { title: 'Home' },
    { title: 'Visão 2' },
    { title: 'Visão 3' },
    { title: 'Visão 4' }
  ];

  const defaultProps = {
    options: test,
    getOptionLabel: (option) => option.title
  };

  return (
    <>
      <DropDownContainer>
        {/* <StyledAutoComplete
                    {...defaultProps}
                    id="debug"
                    debug
                    renderInput={(params) => <TextField {...params} margin="normal" />}
                /> */}
        {/* <IconDropDownWrap>
                    <ImgIconEdit src={EditModeIcons['addVision']} />
                    <ImgIconEdit src={EditModeIcons['trashIcon']} />
                </IconDropDownWrap> */}
      </DropDownContainer>

      <ConfirmEditContainer>
        <ImgIconContainer>
          <ImgIconEdit
            src={EditModeIcons.xicon}
            onClick={() => handleClick({ resetInfo: true })}
          />
        </ImgIconContainer>

        <ImgIconContainer>
          <ImgIconEdit src={EditModeIcons.correctIcon} onClick={handleClick} />
        </ImgIconContainer>
        
        <ImgIconContainer bgColor="#8B97F2">
          <ImgIconEdit src={EditModeIcons.editCard} />
        </ImgIconContainer>
      </ConfirmEditContainer>
    </>
  );
}

export default EditModeHeader;
