import React, { useCallback, useState, useEffect } from 'react';

import { TextField, InputAdornment } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import searchIcon from 'images/icons/searches/searchInputIcon.svg';

import { SearchIcon } from './index.style';

import { InputContainer } from '../AutoCompleteField/index.style';

function InputFieldSearch({
  label,
  placeHolder,
  filterName,
  handleFilter,
  size,
  clearable,
  inputSizes = {
    small: 110,
    medium: 155,
    medium_plus: 210,
    large: 250
  },
  withoutIcon,
  defaultValue,
  storedValue,
  shouldCleanFilter,
  marginRight
}) {
  const [initialValue, setInitialValue] = useState(storedValue || defaultValue);
  const [debouceTimers, setDebouceTimers] = useState([]);
  const [value, setValue] = useState('');

  const handleFilterValue = useCallback(
    (filterValue) => {
      if (debouceTimers.length) {
        debouceTimers.forEach((item) => {
          clearTimeout(item);
        });
      }

      const newDebouceTimer = setTimeout(() => {
        handleFilter(filterName, filterValue);
      }, 800);

      setDebouceTimers([...debouceTimers, newDebouceTimer]);
    },
    [debouceTimers, filterName, handleFilter]
  );

  const handleChange = useCallback(
    (event) => {
      setInitialValue('');
      setValue(event.target.value);
      handleFilterValue(event.target.value);
    },
    [handleFilterValue]
  );

  const onClickClear = () => {
    setInitialValue(defaultValue || '');
    setValue(defaultValue || '');
  };

  useEffect(() => {
    if (shouldCleanFilter) {
      onClickClear();
    }
  }, [shouldCleanFilter]);

  return (
    <InputContainer id={`${filterName}Box`} marginRight={marginRight} >
      <TextField
        className={clearable && 'searchByTermMode'}
        id={filterName}
        value={value || initialValue}
        onChange={handleChange}
        label={label && label}
        placeholder={placeHolder}
        fullWidth
        style={{ width: size && inputSizes[size] }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {!withoutIcon && <SearchIcon src={searchIcon} />}

              {clearable ? (
                <Close
                  className="pAbsolute customClearIcon cursorPointer"
                  onClick={() => {
                    onClickClear();
                    handleFilter(filterName, '');
                  }}
                  style={{ width: '19px', right: '0' }}
                />
              ) : null}
            </InputAdornment>
          )
        }}
      />
    </InputContainer>
  );
}

export default InputFieldSearch;
