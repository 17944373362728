import React, { useState } from 'react';

import {
  ClickAwayListener,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import {
  CheckRounded,
  ClearRounded,
  Delete,
  EditRounded
} from '@material-ui/icons';
import axios from 'axios';

import BloomIconLarge from 'images/icons/logo/bloomIconLarge.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import Environments from 'utils/environments';
import ValidationLength from 'utils/utils';

export default function ModalTable(provider, profileType) {
  const [state, setState] = useState();
  const [loading, setLoading] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [actions, setActions] = useState([]);
  const [fieldsSettings, setFieldSettings] = useState([
    { id: 1, name: 'fullName', value: null, error: false, errorMessage: '' },
    { id: 2, name: 'department', value: null, error: false, errorMessage: '' },
    {
      id: 3,
      name: 'emailAddress',
      value: null,
      error: false,
      errorMessage: ''
    },
    {
      id: 3,
      name: 'telephoneNumber',
      value: null,
      error: false,
      errorMessage: ''
    },
    { id: 4, name: 'isPublic', value: null, error: false, errorMessage: '' }
  ]);
  const [newData, setNewData] = useState({
    fullName: null,
    department: null,
    emailAddress: null,
    telephoneNumber: null,
    isPublic: null
  });
  const useStyles = makeStyles((theme) => ({
    headerCell: {
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: 'bold'
    },
    bodyCell:
      addMode || editMode || deleteMode
        ? {
            fontFamily: 'Lato',
            whiteSpace: 'nowrap',
            fontSize: '12px',
            color: '#e6e6e6'
          }
        : {
            fontFamily: 'Lato',
            fontSize: '12px',
            whiteSpace: 'nowrap'
          },
    bodyCellAdd: {
      fontFamily: 'Lato',
      fontSize: '12px',
      whiteSpace: 'nowrap'
    },
    headerRow: {
      borderBottom: '1px solid #000'
    },
    bodyRow:
      addMode || editMode || deleteMode
        ? {
            background: '#fafafa'
          }
        : {
            background: '#fff'
          },
    headerActions: {
      width: '70px',
      maxWidth: '70px',
      padding: '0',
      textAlign: 'center'
    },
    bodyActions: {
      // padding: '10px',
      whiteSpace: 'nowrap',
      padding: '0'
    },
    actionIcon:
      addMode || editMode || deleteMode
        ? {
            margin: '0',
            color: '#e6e6e6'
          }
        : {
            margin: '0',
            color: '#757575'
          }
  }));
  const classes = useStyles();
  const environments = Environments(process.env.REACT_APP_ENV);
  const isPlatform = profileType === 'admin';

  function resetData() {
    setNewData({
      fullName: null,
      department: null,
      emailAddress: null,
      telephoneNumber: null,
      isPublic: null
    });
  }

  function validateFields() {
    let count = 0;
    const emailPattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gim;

    if (!newData.fullName) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'fullName'
            ? { ...x, error: true, errorMessage: 'Campo obrigatório.' }
            : x
        )
      );
      setState({ fieldsSettings });
      count++;
    }

    if (!newData.emailAddress) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'emailAddress'
            ? { ...x, error: true, errorMessage: 'Campo obrigatório.' }
            : x
        )
      );
      setState({ fieldsSettings });
      count++;
    } else if (!emailPattern.test(newData.emailAddress)) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'emailAddress'
            ? { ...x, error: true, errorMessage: 'Email inválido' }
            : x
        )
      );
      setState({ fieldsSettings });
      count++;
    }

    if (!newData.telephoneNumber) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'telephoneNumber'
            ? { ...x, error: true, errorMessage: 'Campo obrigatório.' }
            : x
        )
      );
      setState({ fieldsSettings });
      count++;
    }

    if (!newData.department) {
      setFieldSettings(
        fieldsSettings.map((x) =>
          x.name === 'department'
            ? { ...x, error: true, errorMessage: 'Campo obrigatório.' }
            : x
        )
      );
      setState({ fieldsSettings });
      count++;
    }

    return count;
  }

  function addRow() {
    const errors = validateFields();

    if (!errors) {
      new Promise((resolve, reject) => {
        setTimeout(() => {
          {
            provider.contacts.push(newData);
            setState({ provider }, () => resolve());
          }

          axios
            .put(
              `${environments.providers}/providers/${provider.providerId}`,
              provider
            )
            .then((response) => {
              setAddMode(false);
              setLoading(false);
              resolve();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
              reject();
            });
        }, 1000);
      });
    } else {
      setLoading(false);
    }
  }

  function editRow(index, item) {
    const errors = validateFields();

    if (!errors) {
      new Promise((resolve, reject) => {
        setTimeout(() => {
          {
            provider.contacts[index] = newData;
            setActions(
              actions.map((action) =>
                action.data === item ? { ...action, action: 'list' } : action
              )
            );
            setState({ provider }, () => resolve());
          }
          axios
            .put(
              `${environments.providers}/providers/${provider.providerId}`,
              provider
            )
            .then((response) => {
              setEditMode(false);
              setLoading(false);
              resolve();
            })
            .catch((error) => {
              console.log(error);
              setLoading(false);
              reject();
            });
        }, 1000);
      });
    } else {
      setLoading(false);
    }
  }

  function deleteRow(index, data) {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        {
          const actionIndex = actions.findIndex((x) => x.data === data);
          provider.contacts.splice(index, 1);
          actions.splice(actionIndex, 1);
          setState({ provider }, () => resolve());
        }
        axios
          .put(
            `${environments.providers}/providers/${provider.providerId}`,
            provider
          )
          .then((response) => {
            setDeleteMode(false);
            setState({ actions });
            setLoading(false);
            resolve();
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            reject();
          });
      }, 1000);
    });
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setActions(actions.map((action) => ({ ...action, action: 'list' })));
        setAddMode(false);
        setEditMode(false);
        setDeleteMode(false);
        resetData();
        setFieldSettings(
          fieldsSettings.map((field) => ({
            ...field,
            error: false,
            errorMessage: ''
          }))
        );
      }}
    >
      <div className="w100 ph16">
        <div className="dFlex alignCenter mt10">
          <p
            style={{
              fontFamily: 'Lato',
              fontSize: 16,
              fontWeight: 'bold',
              marginRight: 10,
              marginBottom: 0
            }}
          >
            Contatos cadastrados
          </p>

          <Tooltip title="Cadastrar Contato" aria-label="add">
            <IconButton
              edge="start"
              color="inherit"
              style={{ outline: 0, padding: '0 10px' }}
              aria-label="add"
              onClick={() => {
                setAddMode(!addMode);
                resetData();
              }}
            >
              <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
            </IconButton>
          </Tooltip>
        </div>

        <TableContainer
          component={Paper}
          className="addressTable mt10"
          style={{ borderRadius: 4 }}
        >
          <Table size="small">
            <TableHead>
              <TableRow classes={{ head: classes.headerRow }}>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 240, minWidth: 240, maxWidth: 240 }}
                >
                  NOME
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 150, minWidth: 150, maxWidth: 150 }}
                >
                  DEPARTAMENTO
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 100, minWidth: 100, maxWidth: 100 }}
                >
                  TELEFONE
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 150, minWidth: 150, maxWidth: 150 }}
                >
                  EMAIL
                </TableCell>
                <TableCell
                  className={classes.headerCell}
                  style={{ width: 70, minWidth: 70, maxWidth: 70 }}
                />
                <TableCell className={classes.headerActions} />
              </TableRow>
            </TableHead>

            <TableBody>
              {provider.contacts
                ? provider.contacts.map((item, index) => {
                    const exists = actions.find((x) => x.data === item);
                    if (!exists) {
                      setActions([
                        ...actions,
                        { id: index, data: item, action: 'list' }
                      ]);
                    }

                    if (exists && exists.action === 'edit') {
                      return (
                        <TableRow hover={false}>
                          <TableCell style={{ width: '250px' }}>
                            <Tooltip
                              title={
                                fieldsSettings.find(
                                  (x) => x.name === 'fullName'
                                ).errorMessage
                              }
                              placement="bottom-start"
                              open={
                                fieldsSettings.find(
                                  (x) => x.name === 'fullName'
                                ).error
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                            >
                              <TextField
                                fullWidth
                                size="small"
                                placeholder="Nome"
                                name="fullName"
                                error={
                                  fieldsSettings.find(
                                    (x) => x.name === 'fullName'
                                  ).error
                                }
                                value={newData.fullName}
                                onChange={(event) => {
                                  setNewData({
                                    ...newData,
                                    fullName: event.target.value
                                  });
                                }}
                                InputLabelProps={{
                                  style: { fontFamily: 'Lato' }
                                }}
                                InputProps={{
                                  style: {
                                    fontFamily: 'Lato',
                                    fontSize: '12px'
                                  }
                                }}
                              />
                            </Tooltip>
                          </TableCell>

                          <TableCell style={{ width: '15%' }}>
                            <Tooltip
                              title={
                                fieldsSettings.find(
                                  (x) => x.name === 'department'
                                ).errorMessage
                              }
                              placement="bottom-start"
                              open={
                                fieldsSettings.find(
                                  (x) => x.name === 'department'
                                ).error
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                            >
                              <TextField
                                fullWidth
                                size="small"
                                placeholder="Departamento"
                                name="department"
                                error={
                                  fieldsSettings.find(
                                    (x) => x.name === 'department'
                                  ).error
                                }
                                value={newData.department}
                                onChange={(event) => {
                                  setNewData({
                                    ...newData,
                                    department: event.target.value
                                  });
                                }}
                                InputLabelProps={{
                                  style: { fontFamily: 'Lato' }
                                }}
                                InputProps={{
                                  style: {
                                    fontFamily: 'Lato',
                                    fontSize: '12px'
                                  }
                                }}
                              />
                            </Tooltip>
                          </TableCell>

                          <TableCell className={classes.bodyCellAdd}>
                            <Tooltip
                              title={
                                fieldsSettings.find(
                                  (x) => x.name === 'telephoneNumber'
                                ).errorMessage
                              }
                              placement="bottom-start"
                              open={
                                fieldsSettings.find(
                                  (x) => x.name === 'telephoneNumber'
                                ).error
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                            >
                              <TextField
                                fullWidth
                                size="small"
                                placeholder="Telefone"
                                name="telephoneNumber"
                                error={
                                  fieldsSettings.find(
                                    (x) => x.name === 'telephoneNumber'
                                  ).error
                                }
                                inputProps={{ maxLength: 14 }}
                                onInput={(e) => {
                                  e.target.value = e.target.value
                                    .replace(/[^0-9()-]/g, '')
                                    .replace(/^(\d{2})(\d)/, '($1)$2')
                                    .replace(/(\d{5})(\d)/, '$1-$2');
                                }}
                                value={newData.telephoneNumber}
                                onChange={(event) => {
                                  setNewData({
                                    ...newData,
                                    telephoneNumber: event.target.value
                                  });
                                }}
                                InputLabelProps={{
                                  style: { fontFamily: 'Lato' }
                                }}
                                InputProps={{
                                  style: {
                                    fontFamily: 'Lato',
                                    fontSize: '12px'
                                  }
                                }}
                              />
                            </Tooltip>
                          </TableCell>

                          <TableCell className={classes.bodyCellAdd}>
                            <Tooltip
                              title={
                                fieldsSettings.find(
                                  (x) => x.name === 'emailAddress'
                                ).errorMessage
                              }
                              placement="bottom"
                              open={
                                fieldsSettings.find(
                                  (x) => x.name === 'emailAddress'
                                ).error
                              }
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              arrow
                            >
                              <TextField
                                fullWidth
                                size="small"
                                placeholder="Email"
                                name="emailAddress"
                                error={
                                  fieldsSettings.find(
                                    (x) => x.name === 'emailAddress'
                                  ).error
                                }
                                value={newData.emailAddress}
                                onChange={(event) => {
                                  setNewData({
                                    ...newData,
                                    emailAddress: event.target.value
                                  });
                                }}
                                InputLabelProps={{
                                  style: { fontFamily: 'Lato' }
                                }}
                                InputProps={{
                                  style: {
                                    fontFamily: 'Lato',
                                    fontSize: '12px'
                                  }
                                }}
                              />
                            </Tooltip>
                          </TableCell>

                          {isPlatform && (
                            <TableCell className={classes.bodyCellAdd}>
                              <Checkbox
                                name="isPublic"
                                checked={!!newData.isPublic}
                                size="small"
                                onChange={(e, checked) => {
                                  if (e)
                                    setNewData({
                                      ...newData,
                                      isPublic: checked
                                    });
                                }}
                              />
                              Público
                            </TableCell>
                          )}

                          <TableCell className={classes.bodyActions}>
                            <Grid container xs={12} justify="center">
                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  className={classes.actionIcon}
                                  onClick={() => {
                                    setLoading(true);
                                    editRow(index, item);
                                  }}
                                >
                                  <CheckRounded />
                                </IconButton>
                              </Grid>

                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  className={classes.actionIcon}
                                  onClick={() => {
                                    setActions(
                                      actions.map((action) =>
                                        action.data === item
                                          ? { ...action, action: 'list' }
                                          : action
                                      )
                                    );
                                    setEditMode(false);
                                  }}
                                >
                                  <ClearRounded />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    }

                    if (exists && exists.action === 'delete') {
                      return (
                        <TableRow className={classes.bodyRow}>
                          <TableCell
                            className={classes.bodyCellAdd}
                            colSpan={4}
                          >
                            Deseja excluir?
                          </TableCell>

                          <TableCell className={classes.bodyActions}>
                            <Grid container xs={12} justify="center">
                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  onClick={() => {
                                    setLoading(true);
                                    deleteRow(index, item);
                                  }}
                                >
                                  <CheckRounded />
                                </IconButton>
                              </Grid>

                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  onClick={() => {
                                    setActions(
                                      actions.map((action) =>
                                        action.data === item
                                          ? { ...action, action: 'list' }
                                          : action
                                      )
                                    );
                                    setDeleteMode(false);
                                  }}
                                >
                                  <ClearRounded />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    }

                    return (
                      <TableRow
                        className={classes.bodyRow}
                        hover={!addMode && !editMode && !deleteMode}
                      >
                        <TableCell className={classes.bodyCell}>
                          {ValidationLength(item.fullName, 30)}
                        </TableCell>

                        <TableCell className={classes.bodyCell}>
                          {item.department}
                        </TableCell>

                        <TableCell className={classes.bodyCell}>
                          {item.telephoneNumber
                            ? item.telephoneNumber
                                .replace(/^(\d{2})(\d)/, '($1)$2')
                                .replace(/(\d{5})(\d)/, '$1-$2')
                            : null}
                        </TableCell>

                        <TableCell className={classes.bodyCell}>
                          {item.emailAddress}
                        </TableCell>

                        <TableCell className={classes.bodyCell}>
                          {item.isPublic && (
                            <Tooltip title="Contato Público" aria-label="info">
                              <img src={BloomIconLarge} alt="BloomIconLarge" />
                            </Tooltip>
                          )}
                        </TableCell>

                        {(isPlatform || !item.isPublic) && (
                          <TableCell className={classes.bodyActions}>
                            <Grid container xs={12} justify="center">
                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  disabled={addMode}
                                  className={classes.actionIcon}
                                  onClick={() => {
                                    setActions(
                                      actions.map((action) =>
                                        action.data === item
                                          ? { ...action, action: 'edit' }
                                          : action
                                      )
                                    );
                                    setNewData(item);
                                    setEditMode(true);
                                  }}
                                >
                                  <EditRounded className={classes.actionIcon} />
                                </IconButton>
                              </Grid>

                              <Grid container item justify="center" xs={6}>
                                <IconButton
                                  style={{ outline: 0 }}
                                  edge="start"
                                  size="small"
                                  color="inherit"
                                  disabled={addMode}
                                  className={classes.actionIcon}
                                  onClick={() => {
                                    setActions(
                                      actions.map((action) =>
                                        action.data === item
                                          ? { ...action, action: 'delete' }
                                          : action
                                      )
                                    );
                                    setDeleteMode(true);
                                  }}
                                >
                                  <Delete className={classes.actionIcon} />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                : null}

              {addMode ? (
                <TableRow hover={false}>
                  <TableCell style={{ width: '250px' }}>
                    <Tooltip
                      title={
                        fieldsSettings.find((x) => x.name === 'fullName')
                          .errorMessage
                      }
                      placement="bottom-start"
                      open={
                        fieldsSettings.find((x) => x.name === 'fullName').error
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                    >
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Nome"
                        name="fullName"
                        error={
                          fieldsSettings.find((x) => x.name === 'fullName')
                            .error
                        }
                        value={newData.fullName}
                        onChange={(event) => {
                          setNewData({
                            ...newData,
                            fullName: event.target.value
                          });
                        }}
                        InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                      />
                    </Tooltip>
                  </TableCell>

                  <TableCell style={{ width: '15%' }}>
                    <Tooltip
                      title={
                        fieldsSettings.find((x) => x.name === 'department')
                          .errorMessage
                      }
                      placement="bottom-start"
                      open={
                        fieldsSettings.find((x) => x.name === 'department')
                          .error
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                    >
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Departamento"
                        name="department"
                        error={
                          fieldsSettings.find((x) => x.name === 'department')
                            .error
                        }
                        value={newData.department}
                        onChange={(event) => {
                          setNewData({
                            ...newData,
                            department: event.target.value
                          });
                        }}
                        InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                      />
                    </Tooltip>
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                    <Tooltip
                      title={
                        fieldsSettings.find((x) => x.name === 'telephoneNumber')
                          .errorMessage
                      }
                      placement="bottom-start"
                      open={
                        fieldsSettings.find((x) => x.name === 'telephoneNumber')
                          .error
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                    >
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Telefone"
                        name="telephoneNumber"
                        error={
                          fieldsSettings.find(
                            (x) => x.name === 'telephoneNumber'
                          ).error
                        }
                        inputProps={{ maxLength: 14 }}
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/[^0-9()-]/g, '')
                            .replace(/^(\d{2})(\d)/, '($1)$2')
                            .replace(/(\d{5})(\d)/, '$1-$2');
                        }}
                        value={newData.telephoneNumber}
                        onChange={(event) => {
                          setNewData({
                            ...newData,
                            telephoneNumber: event.target.value
                          });
                        }}
                        InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                      />
                    </Tooltip>
                  </TableCell>

                  <TableCell className={classes.bodyCellAdd}>
                    <Tooltip
                      title={
                        fieldsSettings.find((x) => x.name === 'emailAddress')
                          .errorMessage
                      }
                      placement="bottom"
                      open={
                        fieldsSettings.find((x) => x.name === 'emailAddress')
                          .error
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      arrow
                    >
                      <TextField
                        fullWidth
                        size="small"
                        placeholder="Email"
                        name="emailAddress"
                        error={
                          fieldsSettings.find((x) => x.name === 'emailAddress')
                            .error
                        }
                        value={newData.emailAddress}
                        onChange={(event) => {
                          setNewData({
                            ...newData,
                            emailAddress: event.target.value
                          });
                        }}
                        InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                      />
                    </Tooltip>
                  </TableCell>

                  {isPlatform && (
                    <TableCell className={classes.bodyCellAdd}>
                      <Checkbox
                        name="isPublic"
                        checked={!!newData.isPublic}
                        size="small"
                        onChange={(e, checked) => {
                          if (e) setNewData({ ...newData, isPublic: checked });
                        }}
                      />
                      Público
                    </TableCell>
                  )}

                  <TableCell className={classes.bodyActions}>
                    <Grid container xs={12} justify="center">
                      <Grid container item justify="center" xs={6}>
                        <IconButton
                          style={{ outline: 0 }}
                          edge="start"
                          size="small"
                          color="inherit"
                          className={classes.actionIcon}
                          onClick={() => {
                            setLoading(true);
                            addRow();
                          }}
                        >
                          <CheckRounded />
                        </IconButton>
                      </Grid>

                      <Grid container item justify="center" xs={6}>
                        <IconButton
                          style={{ outline: 0 }}
                          edge="start"
                          size="small"
                          color="inherit"
                          className={classes.actionIcon}
                          onClick={() => {
                            resetData();
                            setAddMode(false);
                          }}
                        >
                          <ClearRounded />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : null}

              {loading ? (
                <tr>
                  <td colSpan="5" sytle={{ margin: '10px' }}>
                    <LinearProgress variant="query" />
                  </td>
                </tr>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </ClickAwayListener>
  );
}
