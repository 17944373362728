export function setProjection(data) {
    return {
        type: 'SET_PROJECTION_DATA',
        data,
    };
}

export function createProjection(data) {
    return {
        type: 'CREATE_PROJECTION_DATA',
        data,
    };
}