import React, { useState, useEffect } from 'react';

import { IconButton, Tooltip, LinearProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import * as ProjectionActions from 'store/actions/projection';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

function Estoque({ userInfo, setProjection, createProjection }) {
  const { id: paramsID } = useParams();
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 0,
    totalPages: 0,
    size: 10,
    filters: []
  });
  const { getId, page, size, totalPages, filters } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [values, setValues] = useState([]);

  const [totalForecast, setTotalForecast] = useState(
    values
      ? values.reduce(
        (total, value) =>
          value.forecastNoSafeMargin || value.forecast
            ? parseFloat(total) + parseFloat(Number(value.forecast))
            : parseFloat(total),
        0
      )
      : 0
  );
  const [totalProjection, setTotalProjection] = useState(
    values
      ? values.reduce((total, value) =>
        value.projection > 0
          ? parseFloat(total) + parseFloat(Number(value.projection))
          : parseFloat(total) + parseFloat(Number(value.forecastNoSafeMargin)),
        0
      )
      : 0
  );

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const stores = useState(
    userInfo?.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const [filterFields, setFilterFields] = useState([
    [
      {
        type: 'dateRangeField',
        size: 'small_to_medium',
        filterNameFirst: 'startAt',
        filterNameSecond: 'endAt',
        initialDate: null,
        endDate: null,
        minDate: true,
        maxDate: true,
        views: ['month', 'year'],
        dateFormat: 'MM/yyyy'
      },
      {
        label: 'Lojas',
        filterName: 'originId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'stores',
        optionReference: 'name',
        options: stores[0],
        size: 'medium'
      },
      {
        label: 'Status',
        filterName: 'status',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        optionReference: 'name',
        options: [
          { title: 'Todos', value: '' },
          { title: 'Ativo', value: 'ACTIVE' },
          { title: 'Inativo', value: 'INACTIVE' },
          { title: 'Vencido', value: 'EXPIRED' },
          { title: 'Vigente', value: 'CURRENT' },
          { title: 'Incompleto', value: 'INCOMPLETE' }
        ],
        size: 'medium'
      }
    ],
    []
  ]);
  const environments = Environment(process.env.REACT_APP_ENV);

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page
    );
  };

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: paramsID });
  }, [paramsID]);

  useEffect(() => {
    (async () => {
      const otherRestaurant =
        filtersChanged.find((item) => item.param === 'originId') &&
        filtersChanged.find((item) => item.param === 'originId').value;
      if (otherRestaurant) {
        const index = filtersChanged.indexOf(
          filtersChanged.find((item) => item.name === 'originId')
        );

        filtersChanged.splice(index, 1);
      }

      const params = filtersChanged.map((filter) => filter.value && filter.urlParam).join('&');
      setLoading(true);
      const response = await fetch(
        `${environments.restaurants
        }/salesProjection?page=${page}&size=${size}&projection=true${otherRestaurant
          ? `&originId=${otherRestaurant}`
          : `&originId=${getId}`
        }&${params}`
      );
      const items = await response.json();
      const contentValues = items?.content[0]?.values || [];

      setValues(contentValues);

      setTotalForecast(
        values?.reduce(
          (total, value) =>
            value.forecastNoSafeMargin || value.forecast
              ? parseFloat(total) + parseFloat(Number(value.forecast))
              : parseFloat(total),
          0
        )
      );

      if (items?.content?.length) {
        setTotalProjection(
          items.content[0].values
            ? items.content[0].values.reduce(
              (total, value) =>
                value.projection
                  ? parseFloat(total) + parseFloat(Number(value.projection))
                  : parseFloat(total),
              0
            )
            : 0
        );
      }

      setLoading(false);
      setOptions(
        items.content.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
      setCallSettings({ ...callSettings, totalPages: items.totalPages });
    })();
  }, [getId, page, filtersChanged]);

  useEffect(() => {
    if (values) {
      setTotalProjection(
        values.reduce(
          (total, value) =>
            value.projection
              ? parseFloat(total) + parseFloat(Number(value.projection))
              : parseFloat(total),
          0
        )
      );
      setTotalForecast(
        values.reduce(
          (total, value) =>
            value.forecastNoSafeMargin || value.forecast
              ? parseFloat(total) + parseFloat(Number(value.forecast))
              : parseFloat(total),
          0
        )
      );
    }
  }, [values]);

  function handleFilter(param, value) {
    handleFilterOptions(param, value, filtersChanged, setFiltersChanged);
  }

  const createSalesProjection = () => (
    <Tooltip title="Cadastrar Projeção de Vendas" aria-label="add">
      <Link
        to={`/admin/projecao-de-vendas/cadastro/${paramsID}`}
        onClick={() => createProjection()}
      >
        <IconButton
          className="p0"
          edge="start"
          color="inherit"
          style={{ outline: 0 }}
          aria-label="add"
        >
          <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
        </IconButton>
      </Link>
    </Tooltip>
  );

  return (
    <Layout>
      <TitleHead title="Projeção de Vendas" />
      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Projeção de Vendas"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          handleMultipleFilters={handleMultipleFiltersFunc}
          additionalbutton={createSalesProjection}
          dynamicJustifyOff
          getFilterhandle={getFilterhandle}
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="10%" className="subtitle">
                MÊS/ANO
              </th>

              <th width="30%" className="subtitle">
                LOJA
              </th>

              <th width="15%" className="subtitle right">
                VENDAS REALIZADAS
              </th>

              <th width="15%" className="subtitle right">
                META LOJA
              </th>

              <th width="15%" className="subtitle right">
                DELTA
              </th>

              <th width="15" className="subtitle center">
                STATUS
              </th>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th
                  colSpan="17"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  <LinearProgress id="loading" variant="query" />
                </th>
              </tr>
            )}
            {options?.length ? (
              options.map((option) => {
                let status = '';
                switch (option.status) {
                  case 'ACTIVE':
                    status = 'Ativo';
                    break;
                  case 'INACTIVE':
                    status = 'Inativo';
                    break;
                  case 'CURRENT':
                    status = 'Vigente';
                    break;
                  case 'EXPIRED':
                    status = 'Vencido';
                    break;
                  case 'INCOMPLETE':
                    status = 'Incompleto';
                    break;
                  default:
                    status = null;
                }

                const projection = option.values.map((items) => items?.projection > 0 ? items?.projection : items?.forecastNoSafeMargin)
                const resultsDelta = projection.reduce((acumulador, valor) => acumulador + valor, 0)

                let comp = option.totalSales - resultsDelta;

                let percentage =
                  (100 - (option.totalSales / resultsDelta) * 100) * -1;

                percentage =
                  percentage === Infinity || isNaN(percentage)
                    ? 0.0
                    : percentage;

                if (comp > 0) {
                  comp = (
                    <font color="#3DCC6E">
                      {(comp = `${formatter.format(comp)}`)}
                      <span style={{ marginLeft: '10px' }}>{percentage.toFixed(2)}%</span>
                    </font>
                  );
                } else if (status == 'Incompleto') {
                  comp = (
                    <font color="#F53D4C">
                      {(comp = `${formatter.format(comp)}`)}
                      <span style={{ marginLeft: '10px' }}>{percentage.toFixed(2)}%</span>
                    </font>
                  );
                } else {
                  comp = (
                    <font color="#F53D4C">
                      {(comp = `${formatter.format(comp)}`)}
                      <span style={{ marginLeft: '10px' }}>{percentage.toFixed(2)}%</span>
                    </font>
                  );
                }

                return (
                  <>
                    <tr>
                      <td className="description p12">
                        <Link
                          to={`/admin/projecao-de-vendas/cadastro/${paramsID}`}
                          onClick={() => setProjection(option)}
                        >
                          {option.formattedDate}
                        </Link>
                      </td>

                      <td className="description">
                        {ValidationLength(option.description, 35)}
                      </td>

                      <td className="description alnright fontSizeDefault">
                        {formatter.format(option.totalSales ? option.totalSales : 0)}
                      </td>

                      <td className="description alnright fontSizeDefault">
                        {formatter.format(
                          option.values
                            ? option.values.reduce(
                              (total, value) =>
                                value.projection > 0
                                  ? parseFloat(total) +
                                  parseFloat(Number(value.projection))
                                  : parseFloat(total) +
                                  parseFloat(Number(value.forecastNoSafeMargin)),
                              0
                            )
                            : 0
                        )}
                      </td>

                      <td className="description alnright fontSizeDefault">
                        {comp}{' '}
                      </td>

                      <td className="description center">{status}</td>
                    </tr>

                    <tr>
                      <td colSpan="6">
                        <hr />
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <th
                  className="textAlignCenter"
                  colSpan="13"
                  style={{ margin: '10px' }}
                >
                  Nada foi encontrado
                </th>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(event, page) =>
              setCallSettings({ ...callSettings, page })
            }
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
            showFirstButton
            showLastButton
          />
        </div>
      </Container>
    </Layout>
  );
}


const mapStateToProps = (state) => ({
  userInfo: state.user,
  projectionInfo: state.projection
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProjectionActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Estoque);
