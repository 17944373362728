const validateProviders = sku => {
    const { providers, quantity: skuQuantity, unitsMeasurements: { abbreviation: skuUnitMeasurement } } = sku;
    const rProviders = [];

    for (const provider of providers) {
      const { quantity: providerQuantity } = provider;

      if (provider.showOnCount) {
        rProviders.push({
          ...provider,
          isAdditional: false
        })
      }

      if ((providerQuantity !== 1 && skuQuantity !== 1 && skuUnitMeasurement !== 'g' && skuUnitMeasurement !== 'ml') && provider.showAdditionalOnCount) {
        rProviders.push({
          ...provider,
          isAdditional: true
        })
      }
    }


    return rProviders
}

export default validateProviders;
