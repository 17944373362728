import React from 'react';

import BloomIconSVG from 'images/icons/logo/bloomIconSmall.svg';
import numberToReal from 'utils/numberToReal';

import {
  ContainerSales,
  YourSalesContainer,
  BloomContainer,
  YourPotentialContainer,
  Title,
  Row,
  Subtitle,
  Values,
  Line,
  BorderPercent,
  PercentFill,
  PercentText,
  BloomIcon
} from './style';

const moneyNumber = (number, placesDecimal = 0) =>
  `R$${numberToReal(number, placesDecimal)}`;

const percentNumber = (number, { placesDecimal } = { placesDecimal: 0 }) =>
  `${numberToReal(number, placesDecimal)}%`;

function SalesProvider({
  salesQuantity,
  salesVolume,
  percentageQuantity,
  percentageVolume,
  bloomQuantity,
  bloomVolume,
  potentialQuantity,
  potentialVolume
}) {
  return (
    <ContainerSales>
      <YourSalesContainer>
        <Title>VENDAS REALIZADAS</Title>

        <Row>
          <Subtitle>PEDIDOS</Subtitle>

          <Values>{numberToReal(salesQuantity, 0)}</Values>

          <BorderPercent>
            <PercentFill width={percentNumber(percentageQuantity)}>
              <PercentText>{percentNumber(percentageQuantity)}</PercentText>
            </PercentFill>
          </BorderPercent>
        </Row>

        <Line />

        <Row>
          <Subtitle>VOLUME</Subtitle>

          <Values>{moneyNumber(salesVolume, 2)}</Values>

          <BorderPercent>
            <PercentFill width={percentNumber(percentageVolume)}>
              <PercentText>{percentNumber(percentageVolume)}</PercentText>
            </PercentFill>
          </BorderPercent>
        </Row>
      </YourSalesContainer>

      <BloomContainer>
        <Title color="white" size="9px" align="end" marginLeft="-5px">
          <BloomIcon src={BloomIconSVG} />
          POTENCIAL BLOOM
        </Title>

        <Row>
          <Values color="white">{numberToReal(bloomQuantity, 0)}</Values>
        </Row>

        <Line width="88px" />

        <Row>
          <Values color="white">{moneyNumber(bloomVolume, 2)}</Values>
        </Row>
      </BloomContainer>

      <YourPotentialContainer>
        <Title size="9px" align="end">
          SEU POTENCIAL
        </Title>

        <Row>
          <Values>{numberToReal(potentialQuantity, 0)}</Values>
        </Row>

        <Line width="88px" />

        <Row>
          <Values>{moneyNumber(potentialVolume, 2)}</Values>
        </Row>
      </YourPotentialContainer>
    </ContainerSales>
  );
}

export default SalesProvider;
