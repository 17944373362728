import styled from 'styled-components';

export const SlectWrapper = styled.div`
    padding: 12px 16px; 
`;

export const PackageTop = styled.div`
    width: 100%;
    color: #666770;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;
    padding: 12px 16px; 
    background-color: #F2F2F4;
    display: flex;
    justify-content: space-between;
`;

export const SelectAllItem = styled.img`
    margin-right: 5px;
    cursor: pointer;
`;

export const PackageListItem = styled.div`
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color:#333541;
    padding: 12px 16px;
    border-bottom: 1px solid #D2D3D7;
    display: flex;
    justify-content: space-between;
`;

export const SaveButtonWrapper = styled.div`
    margin: 12px 16px;
    display: flex;
    justify-content: flex-end;
`;

export const SaveButton = styled.button`
    width: 79px;
    height: 32px;
    text-align: center;
    border: none; 
    color: white; 
    background: #5062F0;
    border-radius: 8px;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
