import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { useParams } from 'react-router';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

import Button from '../../Button/button';

const environments = Environment(process.env.REACT_APP_ENV);

function ChangeProductSku({
  open,
  PaperComponent,
  dialogChangeSkuSettings,
  setDialogChangeSkuSettings,
  products,
  selectedItems,
  setSelectedItems,
  originId,
  isEditProduct = false,
  getSkusList,
  userInfo
}) {
  const { id: paramsID } = useParams();
  const description = dialogChangeSkuSettings.skuName;

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productsList, setProductsList] = useState(products || []);

  const handleClose = () => {
    setMessage({ ...message, description: '', status: '' });
    setSelectedProduct({});
    setDialogChangeSkuSettings({
      open: false,
      skuName: '',
      oldProductId: null,
      skuId: null,
      skuUnitAbbreviation: ''
    });
  };

  const handleSubmit = () => {
    setLoading(true);

    const body = {
      newProductId: selectedProduct.id,
      skuId: dialogChangeSkuSettings.skuId,
      originId: parseInt(originId, 10),
      skuUnitAbbreviation: dialogChangeSkuSettings.skuUnitAbbreviation,
      user: userInfo.user
    };

    axios
      .put(
        `${environments.catalog}/products/${dialogChangeSkuSettings.oldProductId}/skus`,
        body
      )
      .then(() => {
        setLoading(false);
        const updatedSkus = selectedItems?.product?.skus?.filter(
          (item) => item.skuId !== dialogChangeSkuSettings.skuId
        );

        if (isEditProduct) {
          setSelectedItems({
            ...selectedItems,
            skus: updatedSkus
          });
        } else {
          setSelectedItems({
            ...selectedItems,
            product: {
              ...selectedItems.product,
              skus: updatedSkus
            }
          });
        }

        setMessage({
          description: 'Sku atualizado com sucesso.',
          status: 'success'
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.error === 'Unit of measurement not allowed.') {
          setMessage({
            description: 'Unidade de medida não permitida.',
            status: 'error'
          });
        } else {
          setMessage({
            description: error.response.data.error,
            status: 'error'
          });
        }
      })
      .finally(getSkusList);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() =>
          setDialogChangeSkuSettings({
            ...dialogChangeSkuSettings,
            open: false
          })
        }
        className="defaultModal mediumModal minWidth"
        PaperComponent={PaperComponent}
        maxWidth="lg"
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
          fullWidth
        >
          <Typography>{description}</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setDialogChangeSkuSettings({
                ...dialogChangeSkuSettings,
                open: false
              });
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          <Autocomplete
            style={{ width: '100%' }}
            options={productsList}
            getOptionLabel={(option) => option?.description}
            className="autocompleteSmall"
            value={selectedProduct}
            size="small"
            onFocus={() => {
              if (!productsList || productsList?.length === 0) {
                getOptions(
                  `${environments.catalog}/products`,
                  'products',
                  productsList,
                  setProductsList,
                  paramsID,
                  { autoComplete: true },
                  null,
                  2000
                );
              }
            }}
            onChange={(_, value) => {
              setSelectedProduct(value || {});
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={params.id}
                type="text"
                name="Products"
                variant="outlined"
                required
                placeholder="Selecione..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            renderOption={(params) => (
              <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                {params.description}
              </Typography>
            )}
          />

          {loading && <LinearProgress className="m10" variant="query" />}
        </DialogContent>

        <DialogActions className="justifyEnd ph16 pb10 pt10">
          <Button
            className="defaultButton submitButton"
            design="contained"
            label={!loading ? 'Salvar' : 'Salvando...'}
            style={{ outline: 0, margin: '0 0 0 5px' }}
            onClick={() => handleSubmit()}
          />
        </DialogActions>
      </Dialog>

      {/* <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => message.status !== 'error' && handleClose()}
      >
        <Alert
          onClose={() => message.status !== 'error' && handleClose()}
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar> */}

      <CommonAlert
        open={!!message.description}
        severity={message.status}
        onClose={() => handleClose()}
        messagePersonal={message.description}
        width="50%"
      />
    </>
  );
}

export default ChangeProductSku;
