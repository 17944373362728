import styled from 'styled-components';

export const DateInputContainer = styled.div`
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
  .MuiButtonBase-root {
    display: none !important;
  }
  label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #666770;
  }
  input {
    font-family: Lato;
    font-weight: normal;
    font-size: 12px;
    color: #666770;
    padding: 6px 0 3px;

    &::placeholder {
      font-style: italic;
    }
  }
`;
