import styled from 'styled-components';

const sizes = {
    '3': '69%',
}

export const CardEditModeContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${({bgColor}) => bgColor};
    width: ${({size}) => size ? '74.5%' :'24.5%'};
    height: 270px;
    box-shadow: 0px 0px 8px rgba(32, 43, 133, 0.4);

    &:last-of-type {
        display: none;
    }
`;

export const CardEditTitle = styled.h4`
    align-self: center; 
    font-size: 16px;
    font-weight: bold;
    color: ${({color}) => color ? color : '#080B30'};
`;

export const AddIconContainer = styled.div`
    text-align: center;
    margin-bottom: 7px;
`;

export const ContainerEditMode = styled.div`
    display: flex;
    flex-flow: wrap;
    grid-gap: 7px;
`;
