import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import history from 'utils/history';

function PrivateRoutes(props) {
  const isLogged = !!localStorage.getItem('token');
  const reqQuoter = localStorage.getItem('companiesActive');
  const idQuoter = JSON.parse(reqQuoter)?.value?.id;
  const quoterUser = localStorage.getItem('companies');
  const routeQuoter = JSON.parse(quoterUser)?.profile[0]?.[0]?.name;
  // if (routeQuoter === 'quoter') {
  //   const path = window.location.pathname;
  //   if (
  //     path === `/` ||
  //     path === `/${idQuoter}` ||
  //     path === `/indicadores/${idQuoter}` ||
  //     path === `/home/infos/${idQuoter}`
  //   ) {
  //     return history.push(`/cotacao/gestor/compras/${idQuoter}`);
  //   }
  // }
  return isLogged ? <Route {...props} /> : <Redirect to="/login" />;
}

export default PrivateRoutes;
