import React, { useMemo } from 'react';

import { LinearProgress } from '@material-ui/core';
import format from 'date-fns/format';
import matchSorter from 'match-sorter';
import {
  useTable,
  useExpanded,
  useFilters,
  useGlobalFilter
} from 'react-table';

const fuzzyTextFilterFn = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
fuzzyTextFilterFn.autoRemove = (val) => !val;

function ExtractCountsTable({
  columns: userColumns,
  data,
  className,
  initialDate,
  endDate,
  loading
}) {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        })
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: userColumns,
        data,
        getSubRows: (row) =>
          row.children && row.children.length ? row.children : row.products,
        filterTypes
      },
      useFilters,
      useGlobalFilter,
      useExpanded
    );

  return (
    <div className={`${className && className}`}>
      <table {...getTableProps()} border="0">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className
                    }
                  ])}
                >
                  {column.className.split(' ').pop() === 'mainCount' && (
                    <div className="finishTab">
                      {endDate ? format(new Date(endDate), 'dd/MMM') : '-'}
                    </div>
                  )}

                  {column.className.split(' ').pop() === 'count' && (
                    <div className="initialTab">
                      {initialDate
                        ? format(new Date(initialDate), 'dd/MMM')
                        : '-'}
                    </div>
                  )}

                  {column.className.split(' ').pop() === 'shopping' && (
                    <div className="entryTab">ENTRADAS</div>
                  )}

                  {column.className.split(' ').pop() === 'sales' && (
                    <div className="outputTab">SAÍDAS</div>
                  )}

                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows?.length ? (
            rows.map(
              (row, i) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps([
                          { className: cell.column.className }
                        ])}
                        style={{ border: '1px solid #BFC0CB' }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
            )
          ) : (
            <tr>
              <th colSpan="15" style={{ margin: '10px', textAlign: 'center' }}>
                Nenhuma informação encontrada
              </th>
            </tr>
          )}
        </tbody>
      </table>

      {loading && <LinearProgress variant="query" />}
    </div>
  );
}

export default ExtractCountsTable;
