/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import './style.scss';
import moment from 'moment';

import numberToReal from 'utils/numberToReal';

export default function InvoiceTable({ data, openInvoice }) {
  return (
    <table className="invoiceTable">
      <thead>
        <tr>
          <th className="alignLeft">Data de Emissão</th>
          <th>Número da Nota</th>
          <th>Valor Total</th>
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <tr>
            <td colSpan={3} style={{ textAlign: 'center' }}>
              Nenhuma nota encontrada
            </td>
          </tr>
        )}
        {data && Array.isArray(data) &&
          data.map((item, index) => {
            const emissionDate = moment(item.emissionDate).add(3, 'hours').format('DD/MM/YYYY - HH:mm')

            return (
              <tr key={index}>
                <td className="alignLeft">{emissionDate}</td>
                <td>
                  <span
                    className="invoiceLink"
                    onClick={() =>
                      openInvoice(
                        item._id,
                        item.nfNumber,
                        item.emissionDate,
                        item.value
                      )
                    }
                  >
                    {item.nfNumber}
                  </span>
                </td>
                <td>R$ {numberToReal(item.value, 2)}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
