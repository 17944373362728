const cardsLocalize = {
  'need to send restaurantId to complete request':
    'Precisa enviar o restaurantId para concluir a solicitação',
  'invalid cards type': 'Tipo de ficha inválida',
  'there are mandatory keys missing from request':
    'Há chaves obrigatórias faltando na solicitação',
  'already exists a card with this description in one of the restaurants sent':
    'Já existe uma ficha com esta descrição em um dos restaurantes enviados',
  'already exists a card with this description':
    'Já existe uma ficha com esta descrição',
  'already exists a product with this description':
    'Já existe um produto com esta descrição',
  'Registration of at least 1 product is mandatory':
    'Obrigatório o cadastro de ao menos 1 produto',
  'need to send a origin ID': 'Precisa enviar um ID de origem',
  'not found results according to these parameters':
    'Não encontrei resultados de acordo com esses parâmetros',
  "groups in body can't be empty": 'Grupos no corpo não podem estar vazios',
  'need to send id to update': 'Precisa enviar id para atualizar',
  'not found cards': 'Nenhuma ficha encontrada',
  'there are repeated products and processes in this card': 'Existem produtos e processos repetidos nesta ficha'
};

export default cardsLocalize;
