import React from 'react';

import { Tooltip } from '@material-ui/core';

import { shortenString } from 'utils/shortenString';

import { ImgIcon, CardListContainer, ListItem } from './index.style';

import { iconTrendPaths } from '../utils';

function CardInfoList({ cardInfo }) {
  return (
    <CardListContainer
      gridCollumns={cardInfo.collunmCardNumber === 2 && '1fr 2fr 3fr 1fr 1fr'}
    >
      <ListItem />
      <ListItem />

      {cardInfo.collunmsName.map((column, index) => (
        <ListItem
          marginRight={cardInfo.collunmsName.length !== index + 1 && '10px'}
          marginTop="0px"
          textShadow
          bold="bold"
          color="white"
          textAlign="end"
          marginBottom="6px"
        >
          {column?.toUpperCase()}
        </ListItem>
      ))}

      {cardInfo.infos.map((info, index) => (
        <>
          <ListItem marginTop={index === 0 ? '0px' : '4px'} size>
            <ImgIcon src={iconTrendPaths[info.iconTrend]} />
          </ListItem>

          <ListItem color="white" marginRight="5px" bold="bold">
            {shortenString(info.name, 9)}
          </ListItem>

          <Tooltip
            title={<p className="mb0 indicatorValueTooltips">{info.raw}</p>}
            disableHoverListener={info.raw === undefined || info.raw === null}
            placement="right"
          >
            <ListItem
              marginRight="10px"
              color="white"
              textAlign={cardInfo.alignRight && 'right'}
            >
              {info.theory}
            </ListItem>
          </Tooltip>

          <ListItem
            marginRight="10px"
            color="white"
            textAlign={cardInfo.alignRight && 'right'}
          >
            {info.real}
          </ListItem>

          <ListItem
            width="55px"
            textShadow
            color={info.colorComp}
            textAlign="end"
            bold="bold"
          >
            {info.comp}
          </ListItem>
        </>
      ))}
    </CardListContainer>
  );
}

export default CardInfoList;
