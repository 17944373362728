import styled from 'styled-components'

export const QDHead = styled.div`
  div {
    background-color: #F2F3F8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    margin-bottom: 8px;

    p {
        font-weight: 700;
        color: #454A67;
        margin: 0;
        font-size: 9px;
      }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      p {
        font-weight: 700;
        color: #454A67;
        margin-right: 38px;
        font-size: 9px;
      }
    }
  }
`

export const QuantityAndDurationDialogCard = styled.div`
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 0px 12px;
    margin-bottom: 8px;

    div {
      padding: 0;
      margin: 0;
      color: #1F2445;
    }

    .quantity {
      margin-left: 22px;
      padding: 4px;
    }

    .duration {
      margin: 0px;
      width: 50px;
      height: 28px;
      
    }
  }
`

export const QDCollapse = styled.div`
  display: flex;
  justify-content: flex-end !important;
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    p {
      margin: 0;
    }
  }
`