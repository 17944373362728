import numberToReal from 'utils/numberToReal';

export const moneyNumber = (number, placesDecimal = 0) =>
  `R$${numberToReal(number, placesDecimal)}`;

const signNumber = (number) => {
  const signal = Math.sign(number);
  if (signal === 1) {
    return '+';
  }
  if (signal === -1) {
    return '-';
  }

  return '';
};

const percentNumber = (
  number,
  { placesDecimal, showArithmeticOperator } = {
    placesDecimal: 0,
    showArithmeticOperator: false
  }
) => {
  let arithmeticOperator = '';

  if (placesDecimal === 0 && !Math.round(number)) {
    return '0%';
  }

  if (showArithmeticOperator) {
    arithmeticOperator = signNumber(number);
  }

  return `${arithmeticOperator} ${numberToReal(number, placesDecimal)}%`;
};

const setColorOfOrdersAndVolume = (number) => {
  if (number > 50) {
    return {
      backgroundColor: '#DADDF3',
      color: '#0B1ABE'
    };
  }
  return {
    backgroundColor: '#F7D4D7',
    color: '#AA0816'
  };
};

const setColorOfPercentAverage = (number) => {
  const sign = signNumber(number);

  if (sign === '+') {
    return {
      backgroundColor: '#F7D4D7',
      color: '#AA0816'
    };
  }
  return {
    backgroundColor: '#D2F9D6',
    color: '#08AA15'
  };
};

const mapDataToBloomTable = (response) =>
  response.map((data) => {
    let total = 0;
    let canPlaceOrder = 0;

    if (data?.clients?.length) {
      total = data.clients.length;
      canPlaceOrder = data.clients.filter(
        (client) => client.restaurantId !== 0 && client.canPlaceOrder
      ).length;
    }

    const providerPackage = `${data.quantity} ${data.unitsMeasurements}`;
    const contentClients = `${canPlaceOrder}/${total}`;
    const clientsDialog = {
      descriptionSku: data.descriptionSku,
      clients: data?.clients ? data.clients : []
    };

    return [
      {
        content: data.codeProvider,
        type: 'text'
      },
      {
        content: data.descriptionSku,
        type: 'text',
        itemLength: 300,
        inputSize: '100%'
      },
      {
        content: providerPackage,
        type: 'text'
      },
      {
        content: data.category,
        type: 'text',
        itemLength: 100
      },
      {
        content: contentClients,
        dataToDialog: clientsDialog,
        action: 'modalClients',
        type: 'richNumber',
        borderTable: true,
        borderColor: '#1F2445',
        align: 'number'
      },
      {
        content: numberToReal(data.salesQuantity, 0),
        type: 'richNumber',
        align: 'number'
      },
      {
        content: percentNumber(data.percentageQuantity),
        type: 'text',
        align: 'number',
        action: null,
        inputSize: '42px',
        buttonLink: setColorOfOrdersAndVolume(data.percentageQuantity)
      },
      {
        content: moneyNumber(data.salesAveragePrice, 3),
        type: 'richNumber',
        align: 'number'
      },
      {
        content: percentNumber(data.percentageAveragePrice),
        type: 'text',
        align: 'number',
        action: null,
        inputSize: '42px',
        buttonLink: setColorOfPercentAverage(data.percentageAveragePrice)
      },
      {
        content: moneyNumber(data.salesVolume, 2),
        type: 'richNumber',
        align: 'number'
      },
      {
        content: percentNumber(data.percentageVolume),
        type: 'text',
        align: 'number',
        action: null,
        buttonLink: setColorOfOrdersAndVolume(data.percentageVolume),
        borderTable: true,
        inputSize: '42px',
        borderColor: '#5062F0'
      },
      {
        content: numberToReal(data.purchaseQuantity, 0),
        type: 'richNumber',
        align: 'number'
      },
      {
        content: moneyNumber(data.purchaseAveragePrice, 3),
        type: 'richNumber',
        align: 'number'
      },
      {
        content: moneyNumber(data.purchaseVolume, 2),
        type: 'richNumber',
        align: 'number'
      }
    ];
  });

export const handleFetchData = (responseData, state, setState) => {
  setState({ ...state, collunms: mapDataToBloomTable(responseData) });
};
