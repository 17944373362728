import React from 'react';

import { Skeleton } from '@material-ui/lab';

import WhiteArrow from 'images/icons/arrows/arrowRightWhiteOutline.svg';

import {
  Divider,
  QuotationManagerMobileWrapper,
  SendQuotesButton
} from './index.style';
import QuotationCard from './QuotationCard';

function QuotationManagerListMobile({
  loading,
  tableData,
  originIds,
  handleSendQuotations,
  selectedItens,
  setSectedItens,
  userInfo,
  restaurantsIds,
  productsIds,
  tableToModalProps,
  tableSettings
}) {
  const loadingCard = [];

  for (let i = 0; i < 7; i++) {
    loadingCard.push(
      <div className="mobile-card-loading-content">
        <div className="mobile-card-loading-title">
          <Skeleton variant="rect" width="100%" height="20px" />
        </div>

        <div className="mobile-card-loading-info">
          <div className="mobile-card-loading-data">
            <Skeleton
              className="mobile-card-loading-quotation-skeleton"
              variant="rect"
              width="100%"
              height="12px"
            />

            <Skeleton
              className="mobile-card-loading-quotation-skeleton"
              variant="rect"
              width="90%"
              height="20px"
            />
          </div>

          <div className="mobile-card-loading-data">
            <Skeleton
              className="mobile-card-loading-quotation-skeleton"
              variant="rect"
              width="100%"
              height="12px"
            />

            <Skeleton
              className="mobile-card-loading-quotation-skeleton"
              variant="rect"
              width="90%"
              height="20px"
            />
          </div>

          <div className="mobile-card-loading-data">
            <Skeleton
              className="mobile-card-loading-quotation-skeleton"
              variant="rect"
              width="100%"
              height="12px"
            />

            <Skeleton
              className="mobile-card-loading-quotation-skeleton"
              variant="rect"
              width="90%"
              height="20px"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <QuotationManagerMobileWrapper>
      {!loading ? (
        !!tableData.collunms.length &&
        tableData.collunms.map((item) => (
          <QuotationCard
            userInfo={userInfo}
            restaurantsIds={restaurantsIds}
            productsIds={productsIds}
            tableToModalProps={tableToModalProps}
            tableSettings={tableSettings}
            originIds={originIds}
            cardItem={item}
            setSectedItens={setSectedItens}
            selectedItens={selectedItens}
          />
        ))
      ) : (
        <div className="mobile-card-loading">
          {loadingCard.map((loading, index) => (
            <div key={index}>{loading}</div>
          ))}
        </div>
      )}

      {!!selectedItens.length && (
        <SendQuotesButton
          onClick={() => {
            handleSendQuotations();
          }}
        >
          <div>
            {selectedItens.length} produtos selecionados
          </div>

          <Divider />
          
          <div>
            Enviar solicitação <img src={WhiteArrow} alt="send quotes" />{' '}
          </div>
        </SendQuotesButton>
      )}
    </QuotationManagerMobileWrapper>
  );
}

export default QuotationManagerListMobile;
