/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Paper
} from '@material-ui/core';
import Draggable from 'react-draggable';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

const environments = Environment(process.env.REACT_APP_ENV);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-packagetype"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function RelatedCards({
  modalSettings,
  setModalSettings,
  setCardAssociation,
  cardAssociation,
  selectedCard,
  setSelectedCard,
  getId
}) {
  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'cardsAssociation')
          .fullScreen
      }
      maxWidth={
        modalSettings.find((modal) => modal.name === 'cardsAssociation')
          .maxWidth
      }
      open={
        modalSettings.find((modal) => modal.name === 'cardsAssociation').open
      }
      onClose={() => {
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'cardsAssociation'
              ? { ...modal, open: false }
              : modal
          )
        );
        setCardAssociation(null);
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>Fichas</Typography>

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'cardsAssociation'
                  ? { ...modal, open: false }
                  : modal
              )
            );
            setCardAssociation(null);
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <table>
          <thead>
            <tr>
              <th width="500" className="subtitle">
                DESCRIÇÃO
              </th>
            </tr>

            <tr>
              <td colSpan="5">
                <hr
                  className="titleLineBorder"
                  style={{ margin: '0', height: '2px' }}
                />
              </td>
            </tr>
          </thead>

          <tbody>
            {cardAssociation && cardAssociation.length ? (
              cardAssociation.map((card) => (
                <>
                  <tr>
                    <td className="description">
                      <div
                        className="cursorPointer lightBlue"
                        onClick={() => {
                          getOptions(
                            `${environments.catalog}/cards/${card?.cardsId}`,
                            'cards',
                            selectedCard,
                            setSelectedCard,
                            getId,
                            null,
                            null,
                            null,
                            true
                          );
                        }}
                      >
                        {card.cardsOrigin.description}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="5">
                      <hr
                        className="titleLineBorder"
                        style={{ margin: '0', height: '2px' }}
                      />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="description center">
                  <Typography className="fontSizeDefault bold" style={{ margin: '5px', textAlign: 'center' }}>
                    Essa ficha não esta associada em outra ficha.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}

export default RelatedCards;
