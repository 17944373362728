import numberToReal from 'utils/numberToReal';

export const calcDifferenceByCost =  ({
    difference = 0,
    cost = 0
}) => {
    const parsedDifference = parseFloat(Number(difference));
    const parsedCost = parseFloat(Number(cost));

    return parsedDifference * parsedCost;
};

export const calcDifference = ({ 
        otherStock = 0, 
        realStock = 0, 
        theoricalStock = 0, 
    }) => {
    const parsedOtherStock = parseFloat(Number(otherStock));
    const parsedRealStock = parseFloat(Number(realStock));
    const parsedTheoricalStock = parseFloat(Number(theoricalStock));

    const diff = parsedOtherStock + parsedRealStock - parsedTheoricalStock;
    return diff;
} 

export const setDifferentCollor = (param, lossPercentage, diff) => {
    const newParam = (!param || param === "-") ? 0 : parseFloat(param);
    const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];
    const color = newParam >= range[0] && newParam <= range[1] && (!diff || diff === '0,000') ? true : false;

    return color;
}

const calcPercentDifference = ({ 
    otherStock = 0, 
    realStock = 0, 
    theoricalStock = 0, 
    consumed = 0
}) => {
    const parsedOtherStock = parseFloat(Number(otherStock));
    const parsedRealStock = parseFloat(Number(realStock));
    const parsedTheoricalStock = parseFloat(Number(theoricalStock));
    const parsedConsumed = parseFloat(Number(consumed));

    const diff = parsedOtherStock + parsedRealStock - parsedTheoricalStock;
    
    if (diff === 0 || parsedConsumed === 0) {
        return  '-';
    }
    return `${numberToReal((diff / parsedConsumed) * 100)}%`;
}

export default calcPercentDifference;
