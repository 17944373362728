/* eslint-disable no-unused-expressions */
import React, { lazy, Suspense, useEffect, useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import { Pagination } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import BloomTable from 'components/BloomTable';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import getOptions from 'services/getOptions';
import useFetch from 'services/useFetch';
import makePost from 'services/useFetch/makePost';
import * as ProductsActions from 'store/actions/products';
import Environments from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';

import QuotationManagerListMobile from './mobile';
import {
  BottomBarWrapper,
  PaginationStyled,
  SenDQuotationButton
} from './style';
import { calcDistributionByRestaurant } from './suggestionQuantityDistribution';
import { handleFetchData } from './utils';

import RegisterProductModal from '../catalog/products/ProductForm';

function QuotationManager({ userInfo, setEditProducts, productInfo }) {
  const { id: paramsID } = useParams();
  const environments = Environments(process.env.REACT_APP_ENV);
  const history = useHistory();
  const originIds = userInfo.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat()
    .map((item) => item.id);
  const [skusId, setSkusId] = useState(() => {
    let skusId = '';
    const data = history?.location?.state?.length
      ? history?.location?.state[0].items.filter((value) => value.isSku)
      : null;
    if (data?.length) {
      skusId = data.map((value) => value.itemId);
    }
    return skusId;
  });
  const [filters, setFilters] = useState([
    {
      param: 'originId',
      value: originIds,
      urlParam: `originId=${originIds}`
    },
    { param: 'page', value: 1, urlParam: `page=1` },
    {
      param: 'skusId',
      value: skusId.toString(),
      urlParam: `skusId=${skusId.toString()}`
    }
  ]);

  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [grouped, setByGroup] = useState(true);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Fornecedor',
        filterName: 'providerId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/quotations/manager/autocomplete/providers`,
        key: 'products',
        optionReference: 'name',
        options: [],
        size: 'medium'
      },
      {
        label: 'Produto',
        filterName: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/quotations/manager/autocomplete/products`,
        key: 'products',
        optionReference: 'description',
        options: [],
        size: 'medium',
        multiple: false
      },
      {
        label: 'SKU',
        filterName: 'skusId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/quotations/manager/autocomplete/skus`,
        key: 'skus',
        optionReference: 'description',
        options: [],
        size: 'large',
        multiple: true
      },
      {
        label: 'Categoria',
        filterName: 'category',
        placeHolder: 'Selecione...',
        type: 'newTreeSelector',
        urlPath: `${environments.catalog}/categories`,
        key: 'products',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_to_plus'
      },
      {
        label: 'Curva',
        type: 'multipleCheckbox',
        checkBoxInputs: [{ name: 'A' }, { name: 'B' }, { name: 'C' }]
      },
      {
        label: 'Ruptura',
        filterName: 'hasRupture',
        type: 'switchField'
      }
    ],
    [
      {
        label: 'Cotações',
        type: 'multipleRadiobox',
        checkBoxInputs: [
          {
            name: 'Solicitada',
            referenceName: 'quotationRequested',
            value: 'true'
          },
          {
            name: 'Pendente',
            referenceName: 'quotationRequested',
            value: 'false'
          },
          {
            name: 'Ambos',
            referenceName: 'quotationRequested',
            value: null,
            checked: true
          }
        ]
      }
    ]
  ]);
  const [tableSelectedItens, setTableSelectedItens] = useState([]);
  const [selectedItens, setSectedItens] = useState([]);
  const [sendQuotationsButtonDisable, setSendQuotationsButtonDisable] =
    useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastProps, setToastProps] = useState();
  const [refreshFetch, setRefreshFetch] = useState();
  const [selectedProduct, setSelectedProduct] = useState('');
  const [currentProduct, setCurrentProduct] = useState();
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const InflationDialog = lazy(() => import('./dialogs/InflationDialog'));
  const QuotationByVolumeDialog = lazy(() =>
    import('./dialogs/QuotationByVolumeDialog')
  );
  const SkusQuotationDialog = lazy(() =>
    import('./dialogs/SkusQuotationDialog')
  );
  const StockPerStoreDialog = lazy(() =>
    import('./dialogs/StockPerStoreDialog')
  );
  const StoreConsumptionDialog = lazy(() =>
    import('./dialogs/StoreConsumptionDialog')
  );
  const StorePurchaseSuggestionsDialog = lazy(() =>
    import('./dialogs/StorePurchaseSuggestionsDialog')
  );
  const TradingHistoryDialog = lazy(() =>
    import('./dialogs/TradingHistoryDialog')
  );
  const ProviderQuotationModal = lazy(() =>
    import('./dialogs/ProviderQuotationDialog')
  );
  const { status, data, loading } = useFetch(
    'quotationManager',
    filtersChanged,
    false,
    false,
    refreshFetch
  );
  const [currentModalTitle, setCurrentModalTitle] = useState('');
  const [productsIds, setProductsIds] = useState([]);
  const [modalStates, setModalStates] = useState({
    modalInflation: false,
    modalQuotationByVolume: false,
    modalSkusQuotation: false,
    modalStockPerStore: false,
    modalStoreConsumption: false,
    modalPurchaseSuggestions: false,
    modalTradingHistory: false,
    modalProviderQuotation: false,
    modalSku: false,
    modalEmpty: false
  });
  const [tableToModalProps, setTableToModalProps] = useState({});
  const [tableData, setTableData] = useState({
    header: [
      {
        name: 'PRODUTO',
        size: '16%',
        order: {
          filterName: 'product'
        },
        groupHeaders: [
          {
            name: 'CATEGORIA',
            size: '8%',
            order: {
              filterName: 'category'
            }
          },
          { name: 'SKUS', size: '3%' },
          {
            name: 'SUG.',
            size: '6%',
            infoIcon: { type: 'question', toolTip: 'Sugestão de pedido' }
          }
        ],
        subHeaders: [
          {
            name: 'Selecionar Todos',
            action: 'selectAll',
            colSpan: 4
          }
        ]
      },
      {
        name: 'COTAÇÂO',
        size: '28%',
        subHeaders: [
          {
            name: 'UNID R$',
            size: '80px',
            alignRight: true
          },
          {
            name: 'EMB R$',
            size: '80px',
            alignRight: true
          },
          {
            name: 'VOL R$',
            size: '80px',
            alignRight: true
          },
          {
            size: '80px',
            iconName: 'provider',
            infoIcon: {
              type: 'question',
              toolTip: 'Fornecedores relacionados'
            },
            alignRight: true
          }
        ]
      },
      {
        name: 'INFLAÇÃO',
        size: '14%',
        subHeaders: [
          {
            name: 'ÚLT R$',
            size: '75px',
            alignRight: true
          },
          {
            name: '%',
            size: '80px',
            alignRight: true
          }
        ]
      },
      {
        name: 'CONSUMO 7 DIAS',
        size: '10%',
        subHeaders: [
          {
            name: 'CONS',
            size: '75px',
            alignRight: true
          },
          {
            name: 'VOL R$',
            size: '70px',
            alignRight: true
          }
        ]
      },
      {
        name: 'ESTOQUE',
        size: '21%',
        subHeaders: [
          {
            name: 'UNIT R$',
            size: '70px',
            alignRight: true
          },
          {
            name: 'QTDE',
            size: '70px',
            alignRight: true
          },
          {
            name: 'DUR',
            size: '60px',
            infoIcon: { type: 'question', toolTip: 'Duração do Estoque' },
            alignRight: true
          },
          {
            name: 'TOTAL R$',
            size: '80px',
            alignRight: true
          }
        ]
      }
    ],
    collunms: [],
    tableActions: ['selectAllItens']
  });

  useEffect(() => {
    const filter = filterFields[0].find(
      (value) => value.filterName === 'skusId'
    );
    getFilterhandle(
      filter.urlPath,
      filter.label,
      filter.key,
      filter.optionReference
    );
  }, [skusId]);

  useEffect(() => {
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        paramsID
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        paramsID
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        paramsID
      );
  }, [paramsID]);

  useEffect(() => {
    if (selectedProduct) {
      const arraySkus = [];
      selectedProduct.skus.forEach((skus) => {
        arraySkus.push({
          id: skus.skus ? skus.skus.id : null,
          description: skus.skus ? skus.skus.description : null,
          quantity: skus.skus
            ? parseFloat(skus.skus.quantity).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          unitsMeasurements: skus.skus
            ? skus.skus.unitsMeasurements.abbreviation
            : null,
          purchaseAverage:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseAverage
              : null,
          purchaseLast:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseLast
              : null,
          conversion: skus.skus
            ? parseFloat(skus.conversion).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          priceUc: skus ? skus.priceUc : null
        });
      });

      selectedProduct.skus = arraySkus;
    }

    setEditProducts(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    const restaurantProduct = currentProduct?.products?.find(
      (product) => product.restaurantId == paramsID
    );

    if (!currentProduct || !restaurantProduct) return;

    getOptions(
      `${environments.catalog}/products/${restaurantProduct?.productId}`,
      'products',
      selectedProduct,
      setSelectedProduct,
      paramsID,
      null,
      null,
      null,
      true
    );
  }, [currentProduct]);

  useEffect(() => {
    const filter = filterFields[0].find(
      (value) => value.filterName === 'skusId'
    );
    if (
      filter?.options?.length &&
      !filter.updateDefaultValue &&
      skusId?.length
    ) {
      const skus = filter.options.filter(
        (option) => !!skusId.find((sku) => sku === option.value)
      );
      const newHook = filterFields.map((firstLevel) =>
        firstLevel.map((secondLevel) => {
          if (secondLevel.label === 'SKU') {
            secondLevel.updateDefaultValue = skus;
            return secondLevel;
          }
          return secondLevel;
        })
      );
      setFilterFields(newHook);
    }
  }, [filterFields]);

  useEffect(() => {
    const { content } = data;
    if (!content) return;
    handleFetchData(content, tableData, setTableData);
  }, [data]);

  useEffect(() => {
    if (!skusId) return;
    setRefreshFetch(Math.random());
  }, [skusId, paramsID]);

  useEffect(() => {
    if (tableSelectedItens.length) {
      setSendQuotationsButtonDisable(false);
    }
    if (!tableSelectedItens.length) {
      setSendQuotationsButtonDisable(true);
    }
  }, [tableSelectedItens]);

  const handleFilter = (param, value) => {
    if (param === 'page') {
      setTableSelectedItens([]);
      setSectedItens([]);
    }

    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      true
    );
    setTableSelectedItens([]);
    setSectedItens([]);
  };

  useEffect(() => {
    tableSelectedItens.map((item) => {
      const conlunmData = tableData.collunms.find((collunm) => {
        if (collunm.length) {
          if (collunm[0].id === item.id) {
            return collunm;
          }
        }
      });

      const { modalToTableProps } = conlunmData[3];
      modalToTableProps.suggestionList = [];
      setTableData(tableData);
    });
  }, [tableSelectedItens]);

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      originIds,
      false,
      1
    );
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  };

  function setByGroupOnChange() {
    setByGroup((value) => !value);
  }

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSendQuotationsButtonDisable(true);
    setToastOpen(false);
  };

  const handleClose = () => {
    setModalStates({
      modalInflation: false,
      modalQuotationByVolume: false,
      modalSkusQuotation: false,
      modalStockPerStore: false,
      modalStoreConsumption: false,
      modalPurchaseSuggestions: false,
      modalTradingHistory: false,
      modalProviderQuotation: false,
      modalSku: false,
      modalEmpty: false
    });
  };

  const sendQuotationsCallBack = (responseError, response) => {
    setRefreshFetch(Math.random());

    if (responseError) {
      setToastProps({
        severity: 'success',
        message: 'Solicitação enviada com sucesso',
        timeOutOff: true
      });
      setToastOpen(true);
      return;
    }

    if (!responseError) {
      setToastProps({
        severity: 'error',
        message: 'Algo deu errado, por favor tente mais tarde',
        timeOutOff: false
      });
      setToastOpen(true);
    }
  };

  const handleSendQuotations = () => {
    if (!tableSelectedItens.length) {
      setToastProps({
        severity: 'error',
        message: 'Nenhum produto selecionado',
        timeOutOff: false
      });
      setToastOpen(true);
      return;
    }

    const data = tableSelectedItens.map((item) => {
      const conlunmData = tableData.collunms.find((collunm) => {
        if (collunm.length) {
          if (collunm[0].id === item.id) {
            return collunm;
          }
        }
      });

      const { modalToTableProps } = conlunmData[3];
      const suggestionQuantity = conlunmData[3].content[0];
      const { suggestionList = [] } = modalToTableProps;

      return {
        quantity:
          !suggestionQuantity || suggestionQuantity === null
            ? 0
            : parseFloat(suggestionQuantity),
        products: suggestionList.length ? suggestionList : item.products,
        productGroupId: item.productGroupId,
        suggestionListFlag: !!suggestionList.length
      };
    });

    const dataWithDistributionQuantity = data.map((item) => {
      if (item.suggestionListFlag) return item;
      const distriButionResult = calcDistributionByRestaurant({
        contentSuggestion: item.quantity,
        restaurants: item.products.length
      });

      const conlunmData = tableData.collunms.find((collunm) => {
        if (collunm.length) {
          if (collunm[0].id === item.id) {
            return collunm;
          }
        }
      });

      const newProducts = item.products.map((product, index) => ({
        ...product,
        quantity: distriButionResult[index]
      }));

      return {
        ...item,
        products: newProducts
      };
    });

    makePost(
      'quotationsSkuResponse',
      dataWithDistributionQuantity,
      sendQuotationsCallBack
    );
  };

  const handleSendQuotationsMobile = () => {
    if (!selectedItens.length) {
      setToastProps({
        severity: 'error',
        message: 'Nenhum produto selecionado',
        timeOutOff: false
      });
      setToastOpen(true);
      return;
    }

    const data = selectedItens.map((item) => {
      const conlunmData = tableData.collunms.find((collunm) => {
        if (collunm.length) {
          if (collunm[0].id === item.id) {
            return collunm;
          }
        }
      });

      const { modalToTableProps } = conlunmData[3];
      const suggestionQuantity = conlunmData[3].content[0];
      const { suggestionList = [] } = modalToTableProps;

      return {
        quantity:
          !suggestionQuantity || suggestionQuantity === null
            ? 0
            : parseFloat(suggestionQuantity),
        products: suggestionList.length ? suggestionList : item.products,
        productGroupId: item.productGroupId,
        suggestionListFlag: !!suggestionList.length
      };
    });

    const dataWithDistributionQuantity = data.map((item) => {
      if (item.suggestionListFlag) return item;
      const distriButionResult = calcDistributionByRestaurant({
        contentSuggestion: item.quantity,
        restaurants: item.products.length
      });

      const conlunmData = tableData.collunms.find((collunm) => {
        if (collunm.length) {
          if (collunm[0].id === item.id) {
            return collunm;
          }
        }
      });

      const newProducts = item.products.map((product, index) => ({
        ...product,
        quantity: distriButionResult[index]
      }));

      return {
        ...item,
        products: newProducts
      };
    });

    makePost(
      'quotationsSkuResponse',
      dataWithDistributionQuantity,
      sendQuotationsCallBack
    );
    setSectedItens([]);
  };

  return (
    <Layout>
      <TitleHead title="Cotações" />

      <PageTitleAndFilter
        title="Gestor de Cotações"
        activeFilterFilds={filterFields}
        handleFilter={handleFilter}
        getFilterhandle={getFilterhandle}
        onChange={setByGroupOnChange}
      />

      <QuotationManagerListMobile
        loading={loading}
        tableData={tableData}
        setTableData={setTableData}
        originIds={originIds}
        handleSendQuotations={handleSendQuotationsMobile}
        selectedItens={selectedItens}
        setSectedItens={setSectedItens}
        userInfo={userInfo}
        restaurantsIds={originIds}
        productsIds={productsIds}
        tableToModalProps={tableToModalProps}
        setTableToModalProps={setTableToModalProps}
        tableSettings={{ tableData, setTableData }}
      />

      <BloomTable
        loading={loading}
        setCurrentProduct={setCurrentProduct}
        erroMensagem="Nenhuma cotação foi encontrada"
        tableData={tableData}
        setTableData={setTableData}
        setModalStates={setModalStates}
        setProductsIds={setProductsIds}
        modalStates={modalStates}
        tableSelectedItens={tableSelectedItens}
        setTableSelectedItens={setTableSelectedItens}
        setCurrentModalTitle={setCurrentModalTitle}
        handleOrdernationFilter={handleOrdernationFilter}
        setTableToModalProps={setTableToModalProps}
      />

      <BottomBarWrapper>
        <PaginationStyled className="pagination">
          <Pagination
            count={data?.totalPages ? data?.totalPages : 0}
            onChange={(event, page) => {
              handleFilter('page', page);
            }}
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />
        </PaginationStyled>
      </BottomBarWrapper>

      <SenDQuotationButton
        id="sendQuotationButton"
        onClick={() => handleSendQuotations()}
        block={sendQuotationsButtonDisable}
      >
        +
      </SenDQuotationButton>

      <Suspense fallback={<span />}>
        <InflationDialog
          openModal={modalStates}
          handleClose={handleClose}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      </Suspense>

      <Suspense fallback={<span />}>
        <QuotationByVolumeDialog
          openModal={modalStates}
          handleClose={handleClose}
        />
      </Suspense>

      <Suspense fallback={<span />}>
        <SkusQuotationDialog
          openModal={modalStates}
          handleClose={handleClose}
          restaurantsIds={originIds}
          productsIds={productsIds}
          prefAndAcceptedOff={false}
        />
      </Suspense>

      <Suspense fallback={<span />}>
        <StockPerStoreDialog
          openModal={modalStates}
          handleClose={handleClose}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      </Suspense>

      <Suspense fallback={<span />}>
        <StoreConsumptionDialog
          openModal={modalStates}
          handleClose={handleClose}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      </Suspense>

      {modalStates.modalPurchaseSuggestions && (
        <Suspense fallback={<span />}>
          <StorePurchaseSuggestionsDialog
            openModal={modalStates}
            handleClose={handleClose}
            userInfo={userInfo}
            restaurantsIds={originIds}
            productsIds={productsIds}
            tableToModalProps={tableToModalProps}
            tableSettings={{ tableData, setTableData }}
          />
        </Suspense>
      )}

      <Suspense fallback={<span />}>
        <TradingHistoryDialog
          openModal={modalStates}
          handleClose={handleClose}
          restaurantsIds={originIds}
          productsIds={productsIds}
        />
      </Suspense>

      <Suspense fallback={<span />}>
        <ProviderQuotationModal
          openModal={modalStates}
          handleClose={handleClose}
          productsIds={productsIds}
          restaurantsIds={
            filtersChanged?.find((item) => item.param === 'originId')?.value
          }
          modalTitle={currentModalTitle}
        />
      </Suspense>

      {modalStates.modalSku && productInfo?.data && (
        <RegisterProductModal
          dialogOpen={modalStates.modalSku}
          isModal
          onlyView
          consumptionUnitOptions={consumptionUnits}
          periodicityOptions={periodicities}
          storageLocationOptions={storageLocations}
          onClose={(e) => {
            setEditProducts('');
            setModalStates({ ...modalStates, modalSku: true });
          }}
        />
      )}

      <Snackbar
        open={toastOpen}
        autoHideDuration={1000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity={toastProps?.severity}>
          {toastProps?.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuotationManager);
