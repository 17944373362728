import React from 'react';

import {
  SubCardContainer,
  PercenInfoConst,
  InfoDetailMessage,
  InfoDetailWrap
} from './index.style';

import {
  InfoCard,
  InforNameContainer,
  InfoNumber
} from '../CardInfoComparation/index.style';
import { iconTrendPaths } from '../utils';

function SubCardinfoComparation({ subInfos }) {
  return (
    <SubCardContainer>
      <div>
        <img
          alt={iconTrendPaths[subInfos.iconTrend]}
          src={iconTrendPaths[subInfos.iconTrend]}
        />
      </div>

      <InfoCard width="50%" marginLeft="-15px">
        <InforNameContainer>
          <span>{subInfos.infos.name?.toUpperCase()}</span>
        </InforNameContainer>

        <div>
          <InfoNumber textShadow bold color={subInfos.iconTrend}>
            {subInfos.infos.numberOfThen}

            <span style={{ color: '#FFFFFF' }}>
              {subInfos.infos.numberOfThenDescribe}
            </span>
          </InfoNumber>
        </div>
      </InfoCard>

      <InfoDetailWrap>
        <InfoDetailMessage color={subInfos.infos.detailMessageColor}>
          {subInfos.infos.detailMessage}
        </InfoDetailMessage>

        {subInfos.infos.percentInfo.map((subs) => (
          <PercenInfoConst color={subInfos.iconTrend}>
            {subs.const}
          </PercenInfoConst>
        ))}
      </InfoDetailWrap>
    </SubCardContainer>
  );
}

export default SubCardinfoComparation;
