const commonEN = {
  save: 'Save',
  cancel: 'Cancel',
  confirmed: 'Accept',
  ErrorToRecord: 'Erro ao cadastrar',
  TypeHere: 'Type here...',
  select: 'Select...',
  succussesRegister: 'Succusses Registered!',
  back: 'back',
  name: 'Name',
  200: 'Registration completed successfully',
  201: 'Registered successfully',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Internal Server Error',
  503: 'Service Unavailable'
};

export default commonEN;
