/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, {
  useCallback,
  useState,
  useEffect,
  forwardRef,
  createRef,
  useRef
} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddBox,
  ArrowDownward,
  Assignment,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  ImportContacts,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  Settings,
  StarBorder,
  ViewColumn
} from '@material-ui/icons';
import { Alert, Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import axios from 'axios';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import MaterialTable from 'material-table';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import FullScreenLoading from 'components/FullScreenLoading';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import FilterIcon from 'images/icons/filters/filter.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import unitsConversion from 'services/unitsConversion';
import * as OrderActions from 'store/actions/order';
import { Container, GlobalStyle } from 'styles/general';
import activeClickAction, {
  handleDeleteButton
} from 'utils/customMaterialTable';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  formControl: {
    width: 200,
    margin: theme.spacing(1),
    minWidth: 200
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  inputRoot: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },
  input: {
    fontFamily: 'Lato',
    fontSize: '12px'
  }
}));

const localization = {
  body: {
    emptyRowsWhenPaging: false,
    emptyDataSourceMessage: 'Não há dados a serem exibidos.',
    editTooltip: 'Editar',
    deleteTooltip: 'Deletar',
    addTooltip: 'Adicionar',
    editRow: {
      deleteText: 'Tem certeza que deseja excluir esta linha?',
      cancelTooltip: 'Cancelar',
      saveTooltip: 'Confirmar'
    }
  },
  toolbar: {
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquisar',
    showColumnsTitle: 'Exibir colunas',
    addRemoveColumns: 'Exibir ou esconder colunas',
    nRowsSelected: '{0} linha(s) selecionadas'
  },
  header: {
    actions: ''
  },
  pagination: {
    labelRowsSelect: 'linhas',
    labelDisplayedRows: ' {from}-{to} de {count}',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Próxima página',
    lastTooltip: 'Última página'
  }
};

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
});

export function RegisterChip({ userInfo, orderInfo, createOrder }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { loading, errors, handleSubmit } = useForm();
  const [saving, setSaving] = useState(false);
  const [editMode, setEditMode] = useState(
    orderInfo.action ? orderInfo.action.toLowerCase() === 'edit' : false
  );
  const [editData, setEditData] = useState(
    orderInfo.data ? [orderInfo.data] : []
  );
  const { id: paramsID } = useParams();
  const [getId, setGetId] = useState(paramsID);
  const [optionsProviders, setOptionsProviders] = useState([]);
  const [state, setState] = useState();
  const [optionsSkus, setOptionsSkus] = useState([]);
  const [providerSetting, setProviderSetting] = useState([]);
  const [quantityError, setQuantityError] = useState([]);
  const [optionsCompanies, setOptionsCompanies] = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const [companiesData, setCompaniesData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [bonusDialog, setBonusDialog] = useState(false);
  const [checked, setChecked] = useState({
    controlProduction: false,
    priority: false,
    draft: false,
    quote: false,
    resale: true
  });
  const [ordersList, setOrdersList] = useState(
    editMode ? (orderInfo.data ? [orderInfo.data] : []) : []
  );
  const [contactTel, setContactTel] = useState();
  const [value, setValue] = useState(
    editMode
      ? {
          description: orderInfo?.data?.providerName,
          providerId: orderInfo?.data.providerId
        }
      : null
  );
  const [type, setType] = useState();
  const [quotationExpirationDate, setQuotationExpirationDate] = useState();
  const [selectedStore, setSelectedStore] = useState(editMode ? {} : null);
  const [consumptionUnit, setConsumptionUnit] = useState();
  const [bonusData, setBonusData] = useState();
  const [hasQuotation, setHasQuotation] = useState(false);
  const classes = useStyles();
  const query = '';
  const [alertErr, setAlertErr] = useState(false);
  const [alertErrSkus, setAlertErrSkus] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [filterOpen, setFilterOpen] = useState(true);
  const [getAlert, setAlert] = useState('');
  const [resultConversion, setresultConversion] = useState(1);
  const history = useHistory();
  const [suggestionsIds, setSuggestionsIds] = useState([]);
  const [tableRefs, setTableRefs] = useState([]);
  const tableRef = useRef();
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const scrollToReference = (ref) => {
    if (ref /* + other conditions */) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    if (tableRefs.length) {
      handleActiveClick(tableRefs[ordersList.length - 1]);
    }
  }, [tableRefs]);

  useEffect(() => {
    setTableRefs((tableRefs) =>
      ordersList.map((_, i) => tableRefs[i] || createRef())
    );
  }, [ordersList.length]);

  const handleActiveClick = (tableRef) => {
    const intervalCallId = setInterval(findCurrent, 300);

    function findCurrent() {
      if (tableRef.current) {
        activeClickAction(tableRef);
        clearInterval(intervalCallId);
      }
    }
  };

  const tableIcons = (tableRef) => ({
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => (
      <Clear
        {...props}
        ref={ref}
        className="ClearButtonSvg"
        onClick={(event, value) => {
          handleActiveClick(tableRef, event);
        }}
      />
    )),
    Delete: forwardRef((props, ref) => (
      <DeleteOutline
        {...props}
        ref={ref}
        className="DeleteOutlineIcon"
        onClick={(event, value) => {
          handleDeleteButton(tableRef, event);
        }}
      />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
      <Edit {...props} ref={ref} className="editableSvgItem" />
    )),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    ImportContacts: forwardRef((props, ref) => (
      <ImportContacts {...props} ref={ref} />
    ))
  });

  const getSelectedStore = (index) =>
    companiesData.find(
      (company) => company?._id == ordersList[index].store.originId
    );

  const onDismiss = () => {
    setAlertErr(false);
    setAlertErrSkus(false);
  };

  function handleOpenAll(handle) {
    handle(true);
  }

  function handleCloseAll(handle) {
    handle(false);
  }

  // Origin / RestaurantId
  useEffect(() => {
    setGetId(paramsID);
  }, [paramsID]);

  useEffect(() => {
    (async () => {
      if (history?.location?.state.length) {
        const { providerId, ordersId, providerGroup } =
          history.location.state[0];
        const providerIds = providerGroup?.providerIds
          ? providerGroup.providerIds
          : providerId;
        await axios
          .get(
            `${environments.orders}/orders/suggestions/${providerIds}?ordersId=${ordersId}`
          )
          .then((response) => {
            const {
              providerId,
              ordersList: responseList,
              groupId,
              providerName
            } = response.data;
            responseList.forEach((order, orderIndex) => {
              const { originId } = order.store;

              order.orderItems.forEach((item, skuIndex) => {
                item.sku.type = `E${item?.sku?.provider?.quantity}x${
                  item?.sku?.quantity || 0
                }${item?.consumptionUnits?.abbreviation || ''}`;
                getProviderQuotation(
                  item.sku.provider,
                  providerId,
                  order,
                  null,
                  'type',
                  orderIndex,
                  true,
                  originId
                );
              });
            });

            setSuggestionsIds(ordersId);

            setValue(
              groupId
                ? optionsProviders.find((item) => item.groupId === groupId)
                : optionsProviders.find(
                    (item) =>
                      item?.description === providerName ||
                      item?.providersId.includes(providerId)
                  )
            );

            setOrdersList(
              responseList.map((order) => ({
                ...order,
                orderItems: order.orderItems.map((orderItem) => ({
                  ...orderItem,
                  deleted: false
                }))
              }))
            );
          })
          .catch((error) => {
            setOrdersList([]);
            console.log(error.message);
            setSaving(false);
          });
      }
    })();
  }, [history, optionsProviders]);

  // Fornecedores
  useEffect(() => {
    (async () => {
      if (!optionsProviders?.length) {
        const response = await fetch(
          `${environments.catalog}/suppliers/groups?page=1&size=10000&originId=${getId}`
        );
        const providers = await response.json();
        setOptionsProviders(providers.content);
      }
    })();
  }, [getId]);

  useEffect(() => {
    (async () => {
      const skus = await handleSkus(getId);
      const exists = optionsSkus.find((opt) => opt.originId === getId);

      if (exists) {
        setOptionsSkus(
          optionsSkus.map((opt) =>
            opt.originId === getId
              ? {
                  ...opt,
                  skuOptions: skus
                }
              : opt
          )
        );
      } else {
        setOptionsSkus([
          ...optionsSkus,
          {
            originId: getId,
            skuOptions: skus
          }
        ]);
      }
    })();
  }, [value, getId]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies?page=0&size=100&originId=0&providerName=${query}`
      );
      const items = await response.json();

      const stores = [];
      for (const subGroup of userInfo.companies.groups[0].subGroups) {
        for (const store of subGroup.stores) {
          stores.push(store);
        }
      }

      const companies = [];
      for (const store of stores) {
        companies.push(items.content.filter((x) => x._id == store.id)[0]);
      }
      setCompaniesData(companies);
    })();
  }, [query, getId]);

  useEffect(() => {
    (async () => {
      if (editMode) {
        const response = await fetch(
          `${environments.restaurants}/companies/${ordersList[0].store.originId}`
        );
        const item = await response.json();
        setSelectedStore(item);
      }
    })();
  }, [query, getId]);

  useEffect(() => {
    (async () => {
      axios
        .get(`${environments.catalog}/consumptionUnits?page=1&size=10`)
        .then((response) => {
          const unit = response.data.content;
          setConsumptionUnit(unit);
        })
        .catch((error) => {
          console.log(error);
          setSaving(false);
        });
    })();
  }, []);

  const getOptionsSkus = useCallback((originId, value) => {
    (async () => {
      const response = await fetch(
        `${
          environments.catalog
        }/skus?page=1&size=1000&originId=${originId}&providerId=${
          value ? value.providersId : ''
        }`
      );
      const skus = await response.json();
      setOptionsSkus([
        ...optionsSkus,
        {
          originId,
          skuOptions: skus.content
        }
      ]);
    })();
  }, []);

  async function handleSkus(originId) {
    const response = await fetch(
      `${
        environments.catalog
      }/skus?page=1&size=1000&originId=${originId}&providerId=${
        editMode
          ? value?.providerId
          : value?.providersId
          ? value?.providersId
          : ''
      }&withProducts=true`
    );
    const skus = await response.json();
    return skus.content;
  }

  // Start Date
  function handleStartDateChange(date) {
    if (date > selectedEndDate) {
      setSelectedStartDate(date);
      setSelectedEndDate(date);
    } else {
      setSelectedStartDate(date);
    }
  }

  // End Date
  function handleEndDateChange(date) {
    setSelectedEndDate(date);
  }

  // Close Snackbar
  function handleClose(event, reason) {
    history?.location?.state?.length &&
    history.location.state[0].source === 'SUGGESTION'
      ? history.push(`/pedidos/sugestao/fornecedor/${paramsID}`)
      : history.push(`/pedidos/${paramsID}`);
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }

  const onSubmit = (data) => {
    setSaving(true);

    if (ordersList.length) {
      if (editMode) {
        ordersList.map((order, index) => {
          if (order.orderItems.length === 0) {
            setAlertErr(true);
            setSaving(false);
          } else {
            const totalItemsValue = order.orderItems.reduce(
              (total, value) =>
                value.costs
                  ? parseFloat(total) + parseFloat(Number(value.costs.total))
                  : parseFloat(total),
              0
            );
            const bonusAmount = order.orderItems.reduce(
              (total, value) =>
                value.bonus
                  ? parseFloat(total) + parseFloat(Number(value.bonus.total))
                  : parseFloat(total),
              0
            );
            order.totalItemsValue = totalItemsValue;
            order.bonusAmount = bonusAmount;
            order.totalOrderAmount = totalItemsValue - bonusAmount;
            order.contact = order.providerContact;
            delete order.providerContact;
          }
        });

        if (
          ordersList.map((order) => order.orderItems).some((el) => !el.length)
        )
          return;

        axios
          .put(
            `${environments.orders}/orders/${ordersList[0]._id}`,
            ordersList[0],
            config
          )
          .then((res) => {
            setOpen(true);
            createOrder({});
            setSaving(false);
          })
          .catch((error) => {
            console.log(error);
            setSaving(false);
            setMessageError(error.response.data.message || 'errorCadastro');
          });
      } else {
        const emptyOrder = ordersList.reduce(
          (total, value) =>
            !value.orderItems.length ? parseFloat(total) + 1 : total,
          0
        );

        if (!emptyOrder) {
          ordersList.map((order) => {
            const totalItemsValue = order.orderItems.reduce(
              (total, value) =>
                value.costs
                  ? parseFloat(total) + parseFloat(Number(value.costs.total))
                  : parseFloat(total),
              0
            );
            const bonusAmount = order.orderItems.reduce(
              (total, value) =>
                value.bonus
                  ? parseFloat(total) + parseFloat(Number(value.bonus.total))
                  : parseFloat(total),
              0
            );
            order.totalItemsValue = totalItemsValue;
            order.bonusAmount = bonusAmount;
            order.totalOrderAmount = totalItemsValue - bonusAmount;

            order.orderItems.forEach((element) => {
              if (!element?.consumptionUnits?.abbreviation) {
                if (element?.unitsMeasurements?.abbreviation) {
                  element.consumptionUnits = element.unitsMeasurements;
                }
              }
            });
          });

          const constactTellReplace = (tell) => {
            if (!tell) return '';
            return `55${tell
              .replace(/\(/g, '')
              .replace(/\)/g, '')
              .replace(/\-/g, '')
              .replace(/\ /g, '')}`;
          };
          const { location: { state = [] } = {} } = history;
          const { providerGroup = null } = { ...state[0], providerGroup: null };

          const body = {
            user: userInfo.user,
            originId: getId,
            status: 'PENDING',
            orderDate: `${format(
              selectedStartDate,
              'yyyy-MM-dd'
            )}T05:00:00.000Z`,
            deliveryDate: `${format(
              selectedEndDate,
              'yyyy-MM-dd'
            )}T05:00:00.000Z`,
            quotation: checked.quote,
            providerId: value.providerId || value.providersId[0],
            providerName:
              providerGroup || value.groupId
                ? null
                : value?.providerName || value?.description,
            contact: constactTellReplace(contactTel),
            type: 'M',
            ordersList,
            providerGroup:
              providerGroup ||
              (value.groupId
                ? {
                    providerGroupId: value.groupId,
                    providerGroupName: value.description,
                    providerIds: value.providersId
                  }
                : null),
            suggestionsIds:
              suggestionsIds && suggestionsIds.length ? suggestionsIds : null
          };

          axios
            .post(`${environments.orders}/orders`, body, config)
            .then((res) => {
              setOpen(true);
            })
            .catch((error) => {
              console.log(error);
              setMessageError(error.response.data.message || 'errorCadastro');
              setSaving(false);
            });
        } else {
          setAlertErr(true);
          setSaving(false);
        }
      }
    } else {
      setAlertErr(true);
      setSaving(false);
    }
  };

  const handleFilterOPen = () => {
    setFilterOpen(!filterOpen);
  };

  const focusNextField = (e, index, itemIndex, nextField) => {
    if (e.key === 'Tab' && e.shiftKey) {
      setTimeout(() => {
        document
          .querySelector(`#${`${nextField + index}_${itemIndex}`}`)
          .focus();
      }, 200);
    } else if (e.key === 'Tab' && !e.shiftKey) {
      setTimeout(() => {
        nextField === 'quantity'
          ? document.querySelector(`#bonus${`${index}_${itemIndex}`}`).focus()
          : document.querySelector(`#unit${`${index}_${itemIndex}`}`).focus();
      }, 200);
    }
  };

  const getProviderQuotation = (
    item,
    providerId,
    data,
    updateData,
    field,
    index,
    isMultiple,
    originId = null
  ) => {
    axios
      .get(
        `${environments.catalog}/quotations/skus/${
          field === 'sku' ? item.id : item.skuId
        }/provider/${providerId}?originId=${getId}`
      )
      .then((response) => {
        const unitaryValue =
          response.data && response.data.providers[0].quotations.length
            ? response.data.providers[0].quotations[0].unitaryValue
            : '';
        const expirationDate =
          response.data && response.data.providers[0].quotations.length
            ? response.data.providers[0].quotations[0].expirationDate
            : '';
        const newData = data;

        setQuotationExpirationDate(
          expirationDate && format(new Date(expirationDate), 'dd/MM/yyyy')
        );

        newData.costs.unitaryValue = parseFloat(unitaryValue);
        newData.costs.expirationDate =
          expirationDate && format(new Date(expirationDate), 'dd/MM/yyyy');

        if (newData.costs.unitaryValue && newData.costs.quantityTotal) {
          newData.costs.total =
            parseFloat(newData.costs.unitaryValue) *
            parseFloat(newData.costs.quantityTotal);
        } else {
          newData.costs.total = 0;
        }

        const optSkus = optionsSkus.find(
          (option) => option.originId == originId
        )
          ? optionsSkus.find((option) => option.originId == originId).skuOptions
          : [];

        setOrdersList(
          ordersList.map((orderItem, i) => {
            if (index === i) {
              return {
                ...orderItem,
                selectedSku:
                  field === 'sku'
                    ? item
                    : optSkus.find((sku) => sku.id === data.sku.id),
                type: field === 'sku' ? item.providers[0] : item,
                unit: unitaryValue
              };
            }
            return orderItem;
          })
        );

        if (updateData) {
          field === 'sku'
            ? updateData.onRowDataChange(newData)
            : updateData.onChange(newData);
        }
      })
      .catch(() => {
        if (!isMultiple) {
          const unitaryValue = '';
          const newData = data;

          const optSkus = optionsSkus.find(
            (option) => option.originId == originId
          )
            ? optionsSkus.find((option) => option.originId == originId)
                .skuOptions
            : [];

          setOrdersList(
            ordersList.map((orderItem, i) => {
              if (index === i) {
                return {
                  ...orderItem,
                  selectedSku:
                    field === 'sku'
                      ? item
                      : optSkus.find((sku) => sku.id === data.sku.id),
                  type: field === 'sku' ? item.providers[0] : item,
                  unit: unitaryValue
                };
              }
              return orderItem;
            })
          );

          if (updateData) {
            field === 'sku'
              ? updateData.onRowDataChange(newData)
              : updateData.onChange(newData);
          }
        }
      });
  };

  const handleOrder = (order, index) => {
    const handleTotalValue = (data) => {
      if (data) {
        const totalValue = data.reduce(
          (total, value) =>
            value.costs
              ? parseFloat(total) + parseFloat(Number(value.costs.total))
              : parseFloat(total),
          0
        );
        return totalValue;
      }
      return 0;
    };

    const handleTotalSubsidized = (data) => {
      if (data) {
        const totalValue = data.reduce(
          (total, value) =>
            value.bonus
              ? parseFloat(total) + parseFloat(Number(value.bonus.total))
              : parseFloat(total),
          0
        );
        return totalValue;
      }
      return 0;
    };

    const isIntentionIcon = (order) => {
      if (order.isIntention) {
        return (
          <Tooltip title="Intenção">
            <Assignment style={{ width: '17px' }} className="lightBlue" />
          </Tooltip>
        );
      }
      if (order.isIntention == false) {
        return (
          <Tooltip title="Sugestão">
            <Settings style={{ width: '17px' }} className="lightBlue" />
          </Tooltip>
        );
      }
    };

    const totalValue = handleTotalValue(ordersList[index].orderItems);
    const totalSubsidized = handleTotalSubsidized(ordersList[index].orderItems);

    return (
      <Paper style={{ margin: '10px 0', padding: '0 10px 10px' }}>
        <Grid container item xs={12} alignItems="center">
          <label
            style={{
              margin: '0 10px -2px 0px',
              fontSize: '12px',
              fontFamily: 'Lato',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'flex-end',
              lineHeight: 'normal'
            }}
          >
            Loja
          </label>

          <Grid item xs={12} sm={2}>
            <Autocomplete
              size="small"
              required
              disabled={ordersList.length === optionsCompanies.length}
              id="asynchronous-demo"
              value={editMode ? selectedStore : getSelectedStore(index)}
              onChange={async (option, value) => {
                if (value) {
                  const address = value.address ? value.address[0] : null;
                  const fullAddress = address
                    ? `${address.street}, ${address.number} - ${address.complement} - ${address.zipCode} - ${address.city} - ${address.states}`
                    : null;
                  ordersList[index] = {
                    ...ordersList[index],
                    store: {
                      originId: value._id,
                      name: value.companyName,
                      cnpj: value.cnpjNumber,
                      address: fullAddress
                    }
                  };

                  const exists = optionsSkus.find(
                    (opt) => opt.originId === value._id
                  );
                  const skus = await handleSkus(value._id);

                  if (exists) {
                    setOptionsSkus(
                      optionsSkus.map((opt) =>
                        opt.originId === value._id
                          ? {
                              ...opt,
                              skuOptions: skus
                            }
                          : opt
                      )
                    );
                  } else {
                    setOptionsSkus([
                      ...optionsSkus,
                      {
                        originId: value._id,
                        skuOptions: skus
                      }
                    ]);
                  }

                  setState({ ordersList });
                }
              }}
              getOptionSelected={(option, value) => option._id === value._id}
              getOptionLabel={(option) => option.companyName}
              options={companiesData}
              classes={{ input: classes.input, option: classes.option }}
              className="autocompleteSmall"
              renderInput={(params) => (
                <TextField
                  {...params}
                  type="text"
                  variant="outlined"
                  placeholder="Selecione..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(params) => (
                <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                  {params.companyName}
                </Typography>
              )}
            />
          </Grid>

          <Grid item xs={3} style={editMode ? { display: 'none' } : null}>
            <Tooltip title="Excluir Pedido">
              <IconButton
                onClick={(e) => {
                  ordersList.splice(index, 1);
                  setState({ ordersList });
                }}
              >
                <DeleteOutline />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            className="materialTableDefault fixedTable"
          >
            <MaterialTable
              tableRef={tableRefs[index]}
              columns={[
                {
                  title: 'SKU',
                  field: 'sku.description',
                  defaultSort: 'asc',
                  editable: 'onAdd',
                  width: '30%',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                    color: '#FFF'
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px'
                  },
                  render: (rowData) => (
                    <td>{ValidationLength(rowData?.sku?.description, 20)}</td>
                  ),
                  editComponent: (props) => (
                    <Autocomplete
                      classes={{
                        option: classes.option,
                        input: classes.input
                      }}
                      onInputChange={(event, value) =>
                        event ? props.onChange(value) : null
                      }
                      autoSelect="true"
                      className="autocompleteSmall"
                      onChange={(e, newValue) => {
                        if (newValue) {
                          const data = { ...props.rowData };

                          if (!data.sku) {
                            data.sku = {
                              id: null,
                              description: null,
                              stock: null,
                              averageValue: null,
                              lastValue: null,
                              curve: null
                            };
                          }

                          if (!data.costs) {
                            data.costs = {
                              quantity: null,
                              unitaryValue: null,
                              total: null
                            };
                          }

                          if (!data.consumptionUnits) {
                            data.consumptionUnits = {
                              id: null,
                              abbreviation: null,
                              description: null
                            };
                          }

                          if (!data.unitsMeasurements) {
                            data.unitsMeasurements = {
                              id: null,
                              abbreviation: null,
                              description: null
                            };
                          }

                          if (!data.bonus) {
                            data.bonus = {
                              unitsMeasurements: {
                                id: null,
                                description: null,
                                abbreviation: null
                              },
                              quantity: null,
                              unitaryValue: null,
                              total: null
                            };
                          }
                          setProviderSetting(
                            newValue?.providers?.map((provider) => {
                              const isAnyValueProviderIdOnValue = editMode
                                ? value?.providerId === provider.providerId
                                : value?.providersId
                                ? value?.providersId?.some(
                                    (el) => el === provider.providerId
                                  )
                                : null;

                              if (
                                isAnyValueProviderIdOnValue &&
                                provider.skuId === newValue.id
                              ) {
                                return provider;
                              }
                            })
                          );

                          data.unitsMeasurements = newValue.unitsMeasurements
                            ? {
                                id: newValue.unitsMeasurements.id,
                                description:
                                  newValue.unitsMeasurements.description,
                                abbreviation:
                                  newValue.unitsMeasurements.abbreviation
                              }
                            : null;

                          data.consumptionUnits = newValue?.products?.length
                            ? consumptionUnit.find(
                                (el) =>
                                  el.id ===
                                  newValue.products[0]?.products
                                    ?.consumptionUnitsPrimaryId
                              ) || null
                            : null;

                          let unit;
                          if (!data.consumptionUnits) {
                            (async () => {
                              await axios
                                .get(
                                  `${environments.catalog}/products?page=1&size=50&originId=${getId}&skus=${newValue.description}`
                                )
                                .then((response) => {
                                  const { data: { content = [] } = {} } =
                                    response;

                                  if (!content.length) return;
                                  const [product] = content;
                                  unit = product.consumptionUnitsPrimary;
                                  data.consumptionUnits = unit
                                    ? {
                                        id: unit.id,
                                        description: unit.description,
                                        abbreviation: unit.abbreviation
                                      }
                                    : null;
                                })
                                .catch((error) => {
                                  console.log(error);
                                  setSaving(false);
                                  let unit;

                                  if (
                                    newValue.consumptionUnits &&
                                    (newValue.consumptionUnits.abbreviation.toLowerCase() ===
                                      'g' ||
                                      newValue.consumptionUnits.abbreviation.toLowerCase() ===
                                        'mg')
                                  ) {
                                    unit = consumptionUnit.find(
                                      (x) =>
                                        x.abbreviation.toLowerCase() === 'kg'
                                    );
                                    data.consumptionUnits = unit
                                      ? {
                                          id: unit.id,
                                          description: unit.description,
                                          abbreviation: unit.abbreviation
                                        }
                                      : null;
                                  } else if (
                                    newValue.consumptionUnits &&
                                    newValue.consumptionUnits.abbreviation.toLowerCase() ===
                                      'ml'
                                  ) {
                                    unit = consumptionUnit.find(
                                      (x) =>
                                        x.abbreviation.toLowerCase() === 'l'
                                    );
                                    data.consumptionUnits = unit
                                      ? {
                                          id: unit.id,
                                          description: unit.description,
                                          abbreviation: unit.abbreviation
                                        }
                                      : null;
                                  } else {
                                    unit = consumptionUnit.find(
                                      (x) =>
                                        x.abbreviation?.toLowerCase() ===
                                        newValue.units.abbreviation.toLowerCase()
                                    );
                                    data.consumptionUnits = unit
                                      ? {
                                          id: unit.id,
                                          description: unit.description,
                                          abbreviation: unit.abbreviation
                                        }
                                      : null;
                                  }
                                });
                            })();
                          }

                          data.sku.id = newValue.id;
                          data.sku.description = newValue.description;
                          data.costs.quantityTotal = null;
                          data.costs.unitaryValue = null;
                          data.sku.stock =
                            newValue.restaurants.length > 0
                              ? newValue.restaurants[0].stock
                              : 0;
                          data.sku.averageValue =
                            newValue.restaurants.length > 0
                              ? newValue.restaurants[0].purchaseAverage
                              : 0;
                          data.sku.lastValue =
                            newValue.restaurants.length > 0
                              ? newValue.restaurants[0].purchaseLast
                              : 0;
                          const newErrorsLIst = quantityError.filter((el) => {
                            if (el !== newValue.id) {
                              return el;
                            }
                          });

                          setQuantityError([...newErrorsLIst]);
                          if (newValue.providers.length === 1) {
                            getProviderQuotation(
                              newValue,
                              newValue.providers[0].id,
                              data,
                              props,
                              'sku',
                              index,
                              false
                            );
                            setType(newValue.providers[0]);
                          } else {
                            setOrdersList(
                              ordersList.map((orderItem, i) => {
                                if (index === i) {
                                  return {
                                    ...orderItem,
                                    selectedSku: newValue
                                  };
                                }
                                return orderItem;
                              })
                            );
                          }

                          props.onRowDataChange(data);
                        } else {
                          setOrdersList(
                            ordersList.map((orderItem, i) => {
                              if (index === i) {
                                return {
                                  ...orderItem,
                                  selectedSku: '',
                                  type: '',
                                  unit: ''
                                };
                              }
                              return orderItem;
                            })
                          );
                        }
                      }}
                      value={order?.selectedSku}
                      onFocus={() => {
                        if (
                          !optionsSkus.find(
                            (option) =>
                              option.originId ==
                              ordersList[index]?.store?.originId
                          )
                        ) {
                          getOptionsSkus(
                            ordersList[index]?.store?.originId,
                            value
                          );
                        }
                      }}
                      getOptionSelected={(option, value) =>
                        option.description === value.description
                      }
                      getOptionLabel={(option) => option.description}
                      options={
                        optionsSkus.find(
                          (option) =>
                            option.originId ==
                            ordersList[index]?.store?.originId
                        )
                          ? optionsSkus.find(
                              (option) =>
                                option.originId ==
                                ordersList[index]?.store?.originId
                            ).skuOptions
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...props}
                          {...params}
                          ref={scrollToReference}
                          // autoFocus
                          id={params.id}
                          placeholder="SKU"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                    />
                  )
                },
                {
                  title: 'TIPO',
                  field: 'sku.type',
                  width: '10%',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                    padding: 0
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    padding: 0
                  },
                  type: 'numeric',
                  render: (rowData) => {
                    if (rowData) {
                      return (
                        <TableCell
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '12px',
                            border: 'none',
                            margin: 0,
                            padding: 0,
                            textAlign: 'right',
                            display: 'block'
                          }}
                        >
                          {rowData?.sku?.type}
                        </TableCell>
                      );
                    }
                  },
                  editComponent: (props) => {
                    const itemIndex =
                      props.rowData.sku &&
                      ordersList[index].orderItems.indexOf(
                        ordersList[index].orderItems.find(
                          (item) => item.sku.id === props.rowData.sku.id
                        )
                      );
                    const providerPackageList = providerSetting.map((value) =>
                      order.selectedSku
                        ? `E${value.quantity}x${
                            order.selectedSku ? order.selectedSku.quantity : 0
                          }${
                            order.selectedSku &&
                            order.selectedSku.unitsMeasurements
                              ? order.selectedSku.unitsMeasurements.abbreviation
                              : ''
                          }`
                        : ''
                    );
                    const providerPackageListRemoveDuplicate = [
                      ...new Set(providerPackageList)
                    ];

                    return (
                      <TextField
                        key={`type${`${index}_${itemIndex}`}`}
                        id={`type${`${index}_${itemIndex}`}`}
                        size="small"
                        select
                        value={order?.type}
                        fullWidth
                        onChange={(event) => {
                          setType(event.target.value);
                          const originId = order?.store?.originId;
                          getProviderQuotation(
                            event.target.value,
                            event.target.value.id,
                            { ...props.rowData },
                            props,
                            'type',
                            index,
                            false,
                            originId
                          );
                        }}
                        InputProps={{
                          classes: {
                            input: classes.input,
                            option: classes.option
                          }
                        }}
                        className="textFieldSmall"
                        InputLabelProps={{
                          shrink: true
                        }}
                      >
                        {providerPackageListRemoveDuplicate.map(
                          (value, index) =>
                            value ? (
                              <MenuItem id={index} value={value}>
                                {value}
                              </MenuItem>
                            ) : null
                        )}
                      </TextField>
                    );
                  }
                },
                {
                  title: 'ESTOQUE',
                  field: 'sku.stock',
                  width: '12%',
                  align: 'right',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold'
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px'
                  },
                  type: 'numeric',
                  render: (rowData) => {
                    if (rowData) {
                      return (
                        <TableCell
                          align="right"
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '12px',
                            border: 'none',
                            margin: 0,
                            padding: 0,
                            textAlign: 'right',
                            display: 'block'
                          }}
                        >
                          {Number(rowData.sku.stock).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}
                        </TableCell>
                      );
                    }
                  },
                  editComponent: (props) => (
                    <TableCell
                      align="right"
                      style={{
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        border: 'none',
                        margin: 0,
                        padding: 0,
                        textAlign: 'right'
                      }}
                    >
                      {Number(props.value ? props.value : 0).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3 }
                      )}
                    </TableCell>
                  )
                },
                {
                  title: 'MÉD.',
                  field: 'sku.averageValue',
                  width: '10%',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold'
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px'
                  },
                  type: 'numeric',
                  render: (rowData) => {
                    if (rowData) {
                      return (
                        <TableCell
                          align="right"
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '12px',
                            border: 'none',
                            margin: 0,
                            padding: 0,
                            textAlign: 'right',
                            display: 'block'
                          }}
                        >
                          {Number(rowData.sku.averageValue).toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 3 }
                          )}
                        </TableCell>
                      );
                    }
                  },
                  editComponent: (props) => (
                    <TableCell
                      align="right"
                      style={{
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        border: 'none',
                        margin: 0,
                        padding: 0,
                        textAlign: 'right'
                      }}
                    >
                      {Number(props.value ? props.value : 0).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3 }
                      )}
                    </TableCell>
                  )
                },
                {
                  title: 'ÚLT.',
                  field: 'sku.lastValue',
                  width: '10%',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold'
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px'
                  },
                  type: 'numeric',
                  render: (rowData) => {
                    if (rowData) {
                      return (
                        <TableCell
                          align="right"
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '12px',
                            border: 'none',
                            margin: 0,
                            padding: 0,
                            textAlign: 'right',
                            display: 'block'
                          }}
                        >
                          {Number(rowData.sku.lastValue).toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 3 }
                          )}
                        </TableCell>
                      );
                    }
                  },
                  editComponent: (props) => (
                    <TableCell
                      align="right"
                      style={{
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        border: 'none',
                        margin: 0,
                        padding: 0,
                        textAlign: 'right'
                      }}
                    >
                      {Number(props.value ? props.value : 0).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3 }
                      )}
                    </TableCell>
                  )
                },
                {
                  title: 'QTDE.',
                  field: 'costs.quantityTotal',
                  width: '12%',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold'
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px'
                  },
                  type: 'numeric',
                  render: (rowData) => {
                    if (rowData) {
                      const itemIndex =
                        rowData.sku &&
                        ordersList[index].orderItems.indexOf(
                          ordersList[index].orderItems.find(
                            (item) => item.sku.id === rowData.sku.id
                          )
                        );
                      return (
                        <FormControl required>
                          <CurrencyTextField
                            key={`quantity${`${index + 1}_${itemIndex}`}`}
                            id={`quantity${`${index + 1}_${itemIndex}`}`}
                            variant="standard"
                            size="small"
                            currencySymbol=""
                            minimumValue="0"
                            outputFormat="string"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            decimalPlaces={3}
                            decimalPlacesShownOnFocus={3}
                            type="text"
                            name="quantity"
                            fullWidth
                            placeholder="Qtd"
                            required
                            onKeyDown={(e) =>
                              focusNextField(e, index + 1, itemIndex)
                            }
                            value={
                              rowData?.costs?.quantity
                                ? rowData.costs.quantity
                                : rowData?.costs?.quantityTotal || ''
                            }
                            InputProps={{
                              maxLength: 8,
                              classes: { input: classes.input }
                            }}
                            onBlur={(e, value) => {
                              setOrdersList(
                                ordersList.map((order, i) => {
                                  if (index === i) {
                                    return {
                                      ...order,
                                      orderItems: order.orderItems.map(
                                        (item) => {
                                          if (
                                            rowData.tableData.id ==
                                            item.tableData.id
                                          ) {
                                            return {
                                              ...item,
                                              costs: {
                                                ...item.costs,
                                                quantityTotal:
                                                  parseFloat(value),
                                                total:
                                                  parseFloat(value) *
                                                  rowData.costs.unitaryValue
                                              }
                                            };
                                          }
                                          return item;
                                        }
                                      )
                                    };
                                  }
                                  return order;
                                })
                              );
                            }}
                          />

                          <p
                            className="pAbsolute"
                            style={{ right: '-22px', top: '-2px' }}
                          >
                            {isIntentionIcon(rowData)}
                          </p>
                        </FormControl>
                      );
                    }
                  },
                  editComponent: (props) => {
                    const itemIndex =
                      props.rowData.sku &&
                      ordersList[index].orderItems.indexOf(
                        ordersList[index].orderItems.find(
                          (item) => item.sku.id === props.rowData.sku.id
                        )
                      );
                    const skuUnit =
                      order.selectedSku && order.selectedSku.unitsMeasurements
                        ? order.selectedSku.unitsMeasurements.abbreviation
                        : '';
                    let multiplier = 1;
                    const data = { ...props.rowData };

                    switch (skuUnit.toLowerCase()) {
                      case 'ml':
                        multiplier = 0.001;
                        break;
                      case 'g':
                        multiplier = 0.001;
                        break;
                      case 'mg':
                        multiplier = 0.000001;
                        break;
                      default:
                        multiplier = 1;
                        break;
                    }

                    function handleConversion(value, data) {
                      if (!data?.consumptionUnits?.abbreviation) {
                        return false;
                      }
                      if (
                        data?.consumptionUnits?.abbreviation?.toLowerCase() !==
                        'un'
                      ) {
                        const quantity =
                          parseFloat(order.type.quantity) *
                          parseFloat(multiplier * order.selectedSku.quantity) *
                          1000;
                        return (parseFloat(value * 1000) % quantity) / 1000;
                      }
                      return (
                        parseFloat(value) % parseFloat(order.type.quantity)
                      );
                    }

                    function handleTooltip() {
                      let result = 0;
                      if (!order.selectedSku?.description) return '';
                      if (
                        order.type &&
                        props.rowData &&
                        props.rowData.consumptionUnits &&
                        props.rowData.consumptionUnits.abbreviation &&
                        order.selectedSku
                      ) {
                        if (
                          props.rowData.consumptionUnits.abbreviation.toLowerCase() !==
                          'un'
                        ) {
                          unitsConversion(
                            order.selectedSku.quantity,
                            props.rowData.consumptionUnits.abbreviation,
                            skuUnit.toLowerCase(),
                            setresultConversion
                          );

                          return `Informe um valor múltiplo de ${(
                            order.type.quantity * resultConversion
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })} ${props.rowData.consumptionUnits.abbreviation}`;
                        }
                        result = parseFloat(order.type.quantity).toLocaleString(
                          'pt-br',
                          {
                            minimumFractionDigits: 3
                          }
                        );
                        return `Informe um valor múltiplo de ${result} ${props.rowData.consumptionUnits.abbreviation}`;
                      }
                      const {
                        rowData: { sku: { id: currentSkuId = null } = {} } = {}
                      } = props;

                      const isQuantityError = quantityError.find((el) => {
                        if (el === currentSkuId) return el;
                      });
                      if (order.type && Boolean(isQuantityError)) {
                        result = parseFloat(
                          order.type?.quantity || 0
                        ).toLocaleString('pt-br', { minimumFractionDigits: 3 });
                        return `Informe um valor múltiplo de ${result}`;
                      }
                      return '';
                    }
                    const {
                      rowData: { sku: { id: currentSkuId = null } = {} } = {}
                    } = props;

                    const isQuantityError = quantityError.find((el) => {
                      if (el === currentSkuId) return el;
                    });
                    return (
                      <Tooltip
                        title={handleTooltip()}
                        placement="bottom-start"
                        open={Boolean(isQuantityError)}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                      >
                        <div>
                          <CurrencyTextField
                            key={`quantity${`${index}_${itemIndex}`}`}
                            id={`quantity${`${index}_${itemIndex}`}`}
                            disabled={!props.rowData.sku || !order.type}
                            variant="standard"
                            size="small"
                            currencySymbol=""
                            minimumValue="0"
                            outputFormat="string"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            decimalPlaces={3}
                            decimalPlacesShownOnFocus={3}
                            onBlur={(e, value) => {
                              props.onChange(value);
                              const data = { ...props.rowData };
                              if (value) {
                                if (handleConversion(value, data)) {
                                  const {
                                    rowData: {
                                      sku: { id: currentSkuId = null } = {}
                                    } = {}
                                  } = props;
                                  if (currentSkuId) {
                                    setQuantityError([
                                      ...quantityError,
                                      currentSkuId
                                    ]);
                                  }
                                  setState({ quantityError });
                                  setSaving(false);
                                } else {
                                  const {
                                    rowData: {
                                      sku: { id: currentSkuId = null } = {}
                                    } = {}
                                  } = props;

                                  const newErrorsLIst = quantityError.filter(
                                    (el) => {
                                      if (el !== currentSkuId) {
                                        return el;
                                      }
                                    }
                                  );

                                  setQuantityError([...newErrorsLIst]);
                                  setState({ quantityError });
                                  data.costs.quantityTotal = parseFloat(value);
                                  if (
                                    data.costs.unitaryValue &&
                                    data.costs.quantityTotal
                                  ) {
                                    data.costs.total =
                                      parseFloat(data.costs.unitaryValue) *
                                      parseFloat(data.costs.quantityTotal);
                                  } else {
                                    data.costs.total = 0;
                                  }

                                  props.onRowDataChange(data);
                                }
                              }
                            }}
                            type="text"
                            name="unitaryValue"
                            value={props.value}
                            onKeyDown={(e) =>
                              focusNextField(e, index, itemIndex, 'type')
                            }
                            placeholder="Qtd."
                            inputProps={{ style: { textAlign: 'right' } }}
                            InputProps={{
                              classes: { input: classes.input },
                              error: Boolean(isQuantityError)
                            }}
                            className="textFieldSmall"
                          />
                        </div>
                      </Tooltip>
                    );
                  }
                },
                {
                  title: 'UN.',
                  field: 'unitsMeasurements.consumptionUnits',
                  editable: 'never',
                  width: '2%',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold'
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px'
                  },
                  type: 'numeric'
                },
                {
                  title: 'UNIT.',
                  field: 'costs.unitaryValue',
                  align: 'center',
                  width: '12%',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold'
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px'
                  },
                  type: 'numeric',
                  render: (rowData) => {
                    if (rowData) {
                      const itemIndex =
                        rowData.sku &&
                        ordersList[index].orderItems.indexOf(
                          ordersList[index].orderItems.find(
                            (item) => item.sku.id === rowData.sku.id
                          )
                        );

                      if (!hasQuotation) {
                        return (
                          <CurrencyTextField
                            key={`unit${`${index + 1}_${itemIndex}`}`}
                            id={`unit${`${index + 1}_${itemIndex}`}`}
                            variant="standard"
                            size="small"
                            fullWidth
                            currencySymbol=""
                            minimumValue="0"
                            outputFormat="string"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            decimalPlaces={3}
                            decimalPlacesShownOnFocus={3}
                            onBlur={(e, value) => {
                              if (value) {
                                const data = rowData;

                                data.costs.unitaryValue = value;

                                if (
                                  data.costs.unitaryValue &&
                                  data.costs.quantityTotal
                                ) {
                                  data.costs.total =
                                    parseFloat(data.costs.unitaryValue) *
                                    parseFloat(data.costs.quantityTotal);
                                } else {
                                  data.costs.total = 0;
                                }

                                setOrdersList(
                                  ordersList.map((orderItem, i) => {
                                    if (index === i) {
                                      return {
                                        ...orderItem,
                                        unit: parseFloat(value)
                                      };
                                    }
                                    return orderItem;
                                  })
                                );

                                // props.onRowDataChange(data);
                              } else {
                                return null;
                              }
                            }}
                            type="text"
                            onKeyDown={(e) =>
                              focusNextField(e, index + 1, itemIndex)
                            }
                            name="unitaryValue"
                            value={rowData?.costs?.unitaryValue}
                            placeholder="R$ Unit."
                            InputProps={{ classes: { input: classes.input } }}
                            inputProps={{ style: { textAlign: 'right' } }}
                            className="textFieldSmall"
                          />
                        );
                      }
                      return (
                        <TableCell
                          align="right"
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '12px',
                            border: 'none',
                            margin: 0,
                            padding: 0,
                            textAlign: 'right',
                            display: 'block'
                          }}
                        >
                          {parseFloat(rowData.costs.unitaryValue)
                            .toFixed(3)
                            .toString()
                            .replace('.', ',')}
                        </TableCell>
                      );
                    }
                  },
                  editComponent: (props) => {
                    const itemIndex =
                      props.rowData.sku &&
                      ordersList[index].orderItems.indexOf(
                        ordersList[index].orderItems.find(
                          (item) => item.sku.id === props.rowData.sku.id
                        )
                      );

                    return (
                      quotationExpirationDate ? (
                        <Tooltip
                          title={quotationExpirationDate}
                          placement="bottom-start"
                          arrow
                        >
                          <span>
                            <CurrencyTextField
                              key={`unit${`${index}_${itemIndex}`}`}
                              id={`unit${`${index}_${itemIndex}`}`}
                              disabled={!props.rowData.sku || !type}
                              variant="standard"
                              size="small"
                              fullWidth
                              currencySymbol=""
                              minimumValue="0"
                              outputFormat="string"
                              decimalCharacter=","
                              digitGroupSeparator="."
                              decimalPlaces={3}
                              decimalPlacesShownOnFocus={3}
                              onKeyDown={(e) =>
                                focusNextField(
                                  e,
                                  index,
                                  itemIndex,
                                  'quantity'
                                )
                              }
                              onBlur={(e) => {
                                if (e.target.value) {
                                  const data = { ...props.rowData };

                                  const unitaryValueFloat = parseFloat(
                                    e.target.value
                                      .replace(/\./g, '')
                                      .replace(',', '.')
                                  );
                                  data.costs.unitaryValue = unitaryValueFloat;

                                  if (
                                    data.costs.unitaryValue &&
                                    data.costs.quantityTotal
                                  ) {
                                    data.costs.total =
                                      parseFloat(data.costs.unitaryValue) *
                                      parseFloat(data.costs.quantityTotal);
                                  } else {
                                    data.costs.total = 0;
                                  }

                                  setOrdersList(
                                    ordersList.map((orderItem, i) => {
                                      if (index === i) {
                                        return {
                                          ...orderItem,
                                          unit: unitaryValueFloat
                                        };
                                      }
                                      return orderItem;
                                    })
                                  );
                                  props.onRowDataChange(data);
                                } else {
                                  return null;
                                }
                              }}
                              type="text"
                              name="unitaryValue"
                              value={order.unit}
                              placeholder="R$ Unit."
                              InputProps={{
                                classes: { input: classes.input }
                              }}
                              inputProps={{
                                style: {
                                  textAlign: 'right',
                                  color:
                                    moment(
                                      moment(
                                        quotationExpirationDate,
                                        'DD/MM/YYYY'
                                      ).format('DD/MM/YYYY')
                                    ).isBefore(
                                      moment().format('DD/MM/YYYY'),
                                      'day'
                                    ) && '#D94141'
                                }
                              }}
                              className="textFieldSmall"
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        <CurrencyTextField
                          key={`unit${`${index}_${itemIndex}`}`}
                          id={`unit${`${index}_${itemIndex}`}`}
                          disabled={!props.rowData.sku || !order.type}
                          variant="standard"
                          size="small"
                          fullWidth
                          currencySymbol=""
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          decimalPlaces={3}
                          decimalPlacesShownOnFocus={3}
                          onBlur={(e) => {
                            // props.onChange(e.target.value));
                            if (e.target.value) {
                              const data = { ...props.rowData };

                              data.costs.unitaryValue = parseFloat(
                                e.target.value
                                  .replace(/\./g, '')
                                  .replace(',', '.')
                              );

                              if (
                                data.costs.unitaryValue &&
                                data.costs.quantityTotal
                              ) {
                                data.costs.total =
                                  parseFloat(data.costs.unitaryValue) *
                                  parseFloat(data.costs.quantityTotal);
                              } else {
                                data.costs.total = 0;
                              }

                              setOrdersList(
                                ordersList.map((orderItem, i) => {
                                  if (index === i) {
                                    return {
                                      ...orderItem,
                                      unit: parseFloat(
                                        e.target.value
                                          .replace(/\./g, '')
                                          .replace(',', '.')
                                      )
                                    };
                                  }
                                  return orderItem;
                                })
                              );

                              props.onRowDataChange(data);
                            } else {
                              return null;
                            }
                          }}
                          type="text"
                          onKeyDown={(e) =>
                            focusNextField(e, index, itemIndex, 'quantity')
                          }
                          name="unitaryValue"
                          value={order.unit}
                          placeholder="R$ Unit."
                          InputProps={{ classes: { input: classes.input } }}
                          inputProps={{ style: { textAlign: 'right' } }}
                          className="textFieldSmall"
                        />
                      )
                    );
                  }
                },
                {
                  title: 'TOTAL',
                  width: '12%',
                  field: 'costs.total',
                  align: 'right',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold'
                  },
                  cellStyle: {
                    fontFamily: 'Lato',
                    fontSize: '12px'
                  },
                  type: 'numeric',
                  render: (rowData) => {
                    if (rowData) {
                      return (
                        <TableCell
                          align="right"
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '12px',
                            border: 'none',
                            margin: 0,
                            padding: 0,
                            textAlign: 'right',
                            display: 'block'
                          }}
                        >
                          {Number(rowData?.costs?.total).toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 3 }
                          )}
                        </TableCell>
                      );
                    }
                  },
                  editComponent: (props) => (
                    <TableCell
                      align="right"
                      style={{
                        fontFamily: 'Lato',
                        fontSize: '12px',
                        border: 'none',
                        margin: 0,
                        padding: 0,
                        textAlign: 'right'
                      }}
                    >
                      {Number(props.value ? props.value : 0).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3 }
                      )}
                    </TableCell>
                  )
                },
                {
                  title: 'BÔNUS',
                  field: 'bonus.total',
                  width: '10%',
                  headerStyle: {
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold'
                  },
                  type: 'numeric',
                  render: (rowData) => (
                    <>
                      <IconButton
                        size="small"
                        aria-label="registerBonus"
                        onClick={() => {
                          handleOpenAll(setBonusDialog)
                          setBonusData(rowData)
                        }}
                      >
                        <StarBorder />
                      </IconButton>

                      <Dialog
                        open={bonusDialog}
                        onClose={() => handleCloseAll(setBonusDialog)}
                        aria-labelledby="form-dialog-title"
                        maxWidth="md"
                        fullWidth
                        className="defaultModal"
                      >
                        <DialogTitle
                          className="modalHeader navyBlue"
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            cursor: 'move',
                            fontFamily: 'Lato'
                          }}
                          id="draggable-dialog-title"
                        >
                          <Typography>
                            <b>Bonificação</b>
                          </Typography>

                          <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => {
                              handleCloseAll(setBonusDialog);
                            }}
                            aria-label="close"
                            style={{
                              outline: '0',
                              background: 'none',
                              align: 'right'
                            }}
                          >
                            <img
                              style={{ width: '16px' }}
                              src={CloseIconNavy}
                              alt="CloseIconNavy"
                            />
                          </IconButton>
                        </DialogTitle>

                        <DialogContent className="modalContent onlyTable">
                          <Grid item xs={12} sm={12}>
                            <TableContainer component={Paper}>
                              <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      width="200"
                                      style={{
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      SKU
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      QTD.
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="60"
                                      style={{
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      UN.
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width="80"
                                      style={{
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      R$ UNIT.
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      width="100"
                                      style={{
                                        fontFamily: 'Lato',
                                        fontWeight: 'bold'
                                      }}
                                    >
                                      R$ TOTAL.
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  <TableRow>
                                    <TableCell width="200">
                                      <FormControl fullwidth>
                                        <TextField
                                          type="text"
                                          disabled
                                          InputProps={{
                                            style: {
                                              fontFamily: 'Lato',
                                              fontSize: '12px'
                                            }
                                          }}
                                          value={
                                            bonusData
                                              ? bonusData.sku.description
                                              : ''
                                          }
                                          className="textFieldSmall"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </FormControl>
                                    </TableCell>

                                    <TableCell width="60" align="center">
                                      <FormControl fullwidth>
                                        <TextField
                                          type="text"
                                          placeholder="Qtd"
                                          disabled
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                          InputProps={{
                                            style: {
                                              fontFamily: 'Lato',
                                              fontSize: '12px'
                                            }
                                          }}
                                          className="textFieldSmall"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={(bonusData && bonusData.bonus
                                            ? Number(bonusData.bonus.quantity)
                                            : 0
                                          ).toLocaleString('pt-br', {
                                            minimumFractionDigits: 3
                                          })}
                                        />
                                      </FormControl>
                                    </TableCell>

                                    <TableCell width="60" align="center">
                                      <FormControl fullWidth>
                                        <TextField
                                          type="text"
                                          disabled
                                          InputProps={{
                                            style: {
                                              fontFamily: 'Lato',
                                              fontSize: '12px'
                                            }
                                          }}
                                          value={
                                            bonusData && bonusData.bonus
                                              ? bonusData.bonus
                                                  .unitsMeasurements
                                                  .abbreviation
                                              : null
                                          }
                                          className="textFieldSmall"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </FormControl>
                                    </TableCell>

                                    <TableCell width="80" align="center">
                                      <FormControl fullwidth>
                                        <TextField
                                          type="text"
                                          disabled
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                          InputProps={{
                                            style: {
                                              fontFamily: 'Lato',
                                              fontSize: '12px'
                                            }
                                          }}
                                          value={(bonusData && bonusData.bonus
                                            ? Number(
                                                bonusData.bonus.unitaryValue
                                              )
                                            : 0
                                          ).toLocaleString('pt-br', {
                                            minimumFractionDigits: 3
                                          })}
                                          className="textFieldSmall"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </FormControl>
                                    </TableCell>

                                    <TableCell width="100" align="right">
                                      <FormControl fullwidth>
                                        <TextField
                                          type="text"
                                          disabled
                                          inputProps={{
                                            style: { textAlign: 'right' }
                                          }}
                                          InputProps={{
                                            style: {
                                              fontFamily: 'Lato',
                                              fontSize: '12px'
                                            }
                                          }}
                                          value={(bonusData && bonusData.bonus
                                            ? Number(bonusData.bonus.total)
                                            : 0
                                          ).toLocaleString('pt-br', {
                                            minimumFractionDigits: 3
                                          })}
                                          className="textFieldSmall"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </FormControl>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </DialogContent>

                        <DialogActions>
                          <Button
                            onClick={() => handleCloseAll(setBonusDialog)}
                            design="outlined"
                            label="Fechar"
                          />
                        </DialogActions>
                      </Dialog>
                    </>
                  ),
                  editComponent: (props) => {
                    const itemIndex =
                      props.rowData.sku &&
                      ordersList[index].orderItems.indexOf(
                        ordersList[index].orderItems.find(
                          (item) => item.sku.id === props.rowData.sku.id
                        )
                      );
                    return (
                      <>
                        <IconButton
                          size="small"
                          key={`bonus${`${index}_${itemIndex}`}`}
                          id={`bonus${`${index}_${itemIndex}`}`}
                          disabled={!props.rowData.sku}
                          aria-label="registerBonus"
                          onClick={() => {
                            handleOpenAll(setBonusDialog);
                            const data = { ...props.rowData };
                            if (!data.bonus) {
                              data.bonus = {
                                unitsMeasurements: {
                                  id: null,
                                  description: null,
                                  abbreviation: null
                                },
                                quantity: null,
                                unitaryValue: null,
                                total: null
                              };
                            }
                            data.bonus.unitsMeasurements =
                              data.unitsMeasurements;
                            props.onRowDataChange(data);
                          }}
                        >
                          <StarBorder />
                        </IconButton>

                        <Dialog
                          open={bonusDialog}
                          onClose={() => handleCloseAll(setBonusDialog)}
                          aria-labelledby="form-dialog-title"
                          maxWidth="md"
                          fullWidth="true"
                        >
                          <DialogTitle
                            className="modalHeader navyBlue"
                            style={{
                              fontWeight: 'bold',
                              textAlign: 'center',
                              cursor: 'move',
                              fontFamily: 'Lato'
                            }}
                            id="draggable-dialog-title"
                          >
                            <Typography>
                              <b>Bonificação</b>
                            </Typography>

                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={() => {
                                handleCloseAll(setBonusDialog);
                              }}
                              aria-label="close"
                              style={{
                                outline: '0',
                                background: 'none',
                                align: 'right'
                              }}
                            >
                              <img
                                style={{ width: '16px' }}
                                src={CloseIconNavy}
                                alt="CloseIconNavy"
                              />
                            </IconButton>
                          </DialogTitle>

                          <DialogContent className="modalContent onlyTable">
                            <Grid item xs={12} sm={12}>
                              <TableContainer component={Paper}>
                                <Table
                                  className={classes.table}
                                  size="small"
                                  aria-label="a dense table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          fontFamily: 'Lato',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        SKU
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          fontFamily: 'Lato',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        QTD.
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          fontFamily: 'Lato',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        UN.
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        style={{
                                          fontFamily: 'Lato',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        R$ UNIT.
                                      </TableCell>
                                      <TableCell
                                        align="right"
                                        style={{
                                          fontFamily: 'Lato',
                                          fontWeight: 'bold'
                                        }}
                                      >
                                        R$ TOTAL.
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>

                                  <TableBody>
                                    <TableRow>
                                      <TableCell width="200">
                                        <TextField
                                          type="text"
                                          value={
                                            props.rowData.sku
                                              ? props.rowData.sku.description
                                              : null
                                          }
                                          placeholder="SKU"
                                          disabled
                                          InputProps={{
                                            style: {
                                              fontFamily: 'Lato',
                                              fontSize: '12px'
                                            }
                                          }}
                                          error={Boolean(errors.quantityBonus)}
                                          className="textFieldSmall"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                        />
                                      </TableCell>

                                      <TableCell width="60" align="center">
                                        <FormControl>
                                          <CurrencyTextField
                                            disabled={!props.rowData.sku}
                                            variant="standard"
                                            size="small"
                                            currencySymbol=""
                                            minimumValue="0"
                                            outputFormat="string"
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                            decimalPlaces={3}
                                            decimalPlacesShownOnFocus={3}
                                            onBlur={(e) => {
                                              props.onChange(e.target.value);
                                              if (e.target.value) {
                                                const data = {
                                                  ...props.rowData
                                                };

                                                data.bonus.quantity =
                                                  parseFloat(
                                                    e.target.value
                                                      .replace(/\./g, '')
                                                      .replace(',', '.')
                                                  );

                                                if (
                                                  data.bonus.unitaryValue &&
                                                  data.bonus.quantity
                                                ) {
                                                  data.bonus.total =
                                                    parseFloat(
                                                      data.bonus.unitaryValue
                                                    ) *
                                                    parseFloat(
                                                      data.bonus.quantity
                                                    );
                                                } else {
                                                  data.bonus.total = 0;
                                                }
                                                props.onRowDataChange(data);
                                              } else {
                                                return null;
                                              }
                                            }}
                                            type="text"
                                            name="quantity"
                                            // variant="outlined"
                                            value={
                                              props.rowData.bonus
                                                ? props.rowData.bonus.quantity
                                                : 0
                                            }
                                            onKeyDown={(e) =>
                                              focusNextField(
                                                e,
                                                index,
                                                itemIndex
                                              )
                                            }
                                            placeholder="Qtd."
                                            // rules={{ required: "Campo obrigatório" }}
                                            InputProps={{
                                              classes: { input: classes.input }
                                            }}
                                            className="textFieldSmall"
                                          />
                                        </FormControl>
                                      </TableCell>

                                      <TableCell width="60" align="center">
                                        <FormControl>
                                          <TextField
                                            type="text"
                                            value={
                                              props.rowData.bonus &&
                                              props.rowData.bonus
                                                .unitsMeasurements
                                                ? props.rowData.bonus
                                                    .unitsMeasurements
                                                    .abbreviation
                                                : null
                                            }
                                            placeholder="SKU"
                                            disabled
                                            InputProps={{
                                              style: {
                                                fontFamily: 'Lato',
                                                fontSize: '12px'
                                              }
                                            }}
                                            error={Boolean(
                                              errors.quantityBonus
                                            )}
                                            className="textFieldSmall"
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                          />
                                        </FormControl>
                                      </TableCell>

                                      <TableCell width="80" align="center">
                                        <FormControl>
                                          <CurrencyTextField
                                            disabled={!props.rowData.sku}
                                            variant="standard"
                                            size="small"
                                            fullWidth
                                            currencySymbol=""
                                            minimumValue="0"
                                            outputFormat="string"
                                            decimalCharacter=","
                                            digitGroupSeparator="."
                                            decimalPlaces={3}
                                            decimalPlacesShownOnFocus={3}
                                            onBlur={(e) => {
                                              props.onChange(e.target.value);
                                              if (e.target.value) {
                                                const data = {
                                                  ...props.rowData
                                                };

                                                data.bonus.unitaryValue =
                                                  parseFloat(
                                                    e.target.value
                                                      .replace(/\./g, '')
                                                      .replace(',', '.')
                                                  );

                                                if (
                                                  data.bonus.unitaryValue &&
                                                  data.bonus.quantity
                                                ) {
                                                  data.bonus.total =
                                                    parseFloat(
                                                      data.bonus.unitaryValue
                                                    ) *
                                                    parseFloat(
                                                      data.bonus.quantity
                                                    );
                                                } else {
                                                  data.bonus.total = 0;
                                                }

                                                setType(null);
                                                props.onRowDataChange(data);
                                              } else {
                                                return null;
                                              }
                                            }}
                                            type="text"
                                            name="unitaryValue"
                                            value={
                                              props.rowData.bonus
                                                ? props.rowData.bonus
                                                    .unitaryValue
                                                : 0
                                            }
                                            placeholder="R$ Unit."
                                            InputProps={{
                                              classes: { input: classes.input }
                                            }}
                                            className="textFieldSmall"
                                          />
                                        </FormControl>
                                      </TableCell>
                                      <TableCell width="100" align="right">
                                        {props.rowData.bonus &&
                                        props.rowData.bonus.total
                                          ? props.rowData.bonus.total.toLocaleString(
                                              'pt-br',
                                              { minimumFractionDigits: 3 }
                                            )
                                          : (0).toLocaleString('pt-br', {
                                              minimumFractionDigits: 3
                                            })}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </DialogContent>

                          <DialogActions>
                            <Button
                              onClick={(e) => handleCloseAll(setBonusDialog)}
                              design="outlined"
                              label="Confirmar"
                            />
                          </DialogActions>
                        </Dialog>
                      </>
                    );
                  }
                }
              ]}
              data={
                history?.location?.state
                  ? ordersList[index].orderItems.filter((item) => !item.deleted)
                  : ordersList[index].orderItems
              }
              title=""
              icons={tableIcons(tableRefs[index])}
              localization={localization}
              options={{
                search: false,
                selection: false,
                sorting: false,
                emptyRowsWhenPaging: false,
                paging: false,
                actionsColumnIndex: -1,
                padding: 'dense',
                sorting: 'true'
              }}
              style={{ border: 'none', boxShadow: 'none', fontFamily: 'Lato' }}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const notUnique = newData.sku
                        ? Boolean(
                            ordersList[index].orderItems.find(
                              (x) =>
                                x.sku.id == newData.sku.id &&
                                x.costs.unitaryValue ==
                                  newData.costs.unitaryValue &&
                                x.costs.quantityTotal ==
                                  newData.costs.quantityTotal
                            )
                          )
                        : false;
                      const { sku: { id: currentSkuId = null } = {} } = newData;

                      const isQuantityError = quantityError.find((el) => {
                        if (el === currentSkuId) return el;
                      });
                      if (
                        !newData.sku ||
                        !newData.costs ||
                        !newData.costs.unitaryValue ||
                        !newData.costs.quantityTotal ||
                        !newData.costs.total ||
                        notUnique ||
                        isQuantityError
                      ) {
                        setAlertErr(true);
                        reject();
                        return;
                      }

                      const data = ordersList[index].orderItems;

                      if (newData.bonus.total) {
                        const pvdr = providerSetting.find(
                          (x) => x.skuId == newData.sku.id
                        );
                        data.push({
                          sku: {
                            id: newData.sku.id,
                            type: order.selectedSku
                              ? `E${pvdr.quantity}x${
                                  order.selectedSku
                                    ? order.selectedSku.quantity
                                    : 0
                                }${
                                  order.selectedSku &&
                                  order.selectedSku.consumptionUnits
                                    ? order.selectedSku.consumptionUnits
                                        .abbreviation
                                    : ''
                                }`
                              : '',
                            description: newData.sku.description,
                            quantity: order.selectedSku
                              ? order.selectedSku.quantity
                              : 0,
                            stock: newData.sku.stock,
                            averageValue: newData.sku.averageValue,
                            lastValue: newData.sku.lastValue,
                            curve: newData.sku.curve,
                            provider: pvdr
                          },
                          consumptionUnits: {
                            id: newData.consumptionUnits.id,
                            description: newData.consumptionUnits.description,
                            abbreviation: newData.consumptionUnits.abbreviation
                          },
                          unitsMeasurements: {
                            id: newData.unitsMeasurements.id,
                            description: newData.unitsMeasurements.description,
                            abbreviation: newData.unitsMeasurements.abbreviation
                          },
                          costs: {
                            quantity: newData.costs.quantityTotal,
                            unitaryValue: newData.costs.unitaryValue,
                            total: newData.costs.total
                          },
                          bonus: {
                            unitsMeasurements: {
                              id: newData.bonus.unitsMeasurements.id,
                              description:
                                newData.bonus.unitsMeasurements.description,
                              abbreviation:
                                newData.bonus.unitsMeasurements.abbreviation
                            },
                            quantityTotal: newData.bonus.quantity,
                            unitaryValue: newData.bonus.unitaryValue,
                            total: newData.bonus.total
                          }
                        });
                        setOrdersList(
                          ordersList.map((orderItem, i) => {
                            if (index === i) {
                              return {
                                ...orderItem,
                                selectedSku: '',
                                type: '',
                                unit: ''
                              };
                            }
                            return orderItem;
                          })
                        );
                      } else {
                        const pvdr = providerSetting.find(
                          (x) => x.skuId == newData.sku.id
                        );
                        data.push({
                          sku: {
                            id: newData.sku.id,
                            type: `E${pvdr.quantity}x${
                              order.selectedSku ? order.selectedSku.quantity : 0
                            }${
                              order.selectedSku &&
                              order.selectedSku.consumptionUnits
                                ? order.selectedSku.consumptionUnits
                                    .abbreviation
                                : ''
                            }`,
                            description: newData.sku.description,
                            quantity: order.selectedSku
                              ? order.selectedSku?.quantity
                              : 0,
                            stock: newData.sku?.stock,
                            averageValue: newData.sku?.averageValue,
                            lastValue: newData.sku?.lastValue,
                            curve: newData.sku?.curve,
                            provider: pvdr
                          },
                          consumptionUnits: {
                            id: newData.consumptionUnits.id,
                            description: newData.consumptionUnits.description,
                            abbreviation: newData.consumptionUnits.abbreviation
                          },
                          unitsMeasurements: {
                            id: newData.unitsMeasurements.id,
                            description: newData.unitsMeasurements.description,
                            abbreviation: newData.unitsMeasurements.abbreviation
                          },
                          costs: {
                            quantityTotal: newData.costs.quantityTotal,
                            unitaryValue: newData.costs.unitaryValue,
                            total: newData.costs.total
                          }
                        });
                      }
                      setQuotationExpirationDate();
                      setOrdersList(
                        ordersList.map((orderItem, i) => {
                          if (index === i) {
                            return {
                              ...orderItem,
                              selectedSku: '',
                              type: '',
                              unit: ''
                            };
                          }
                          return orderItem;
                        })
                      );
                      setState({ data }, () => resolve());
                      handleActiveClick(tableRefs[index]);
                      resolve();
                    }, 1000);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        const data = ordersList[index].orderItems;

                        const delIndex = data.indexOf(oldData);

                        if (history?.location?.state.length) {
                          data.find(
                            (item) => item._id === oldData._id
                          ).deleted = true;
                          const newArr = [...ordersList];
                          newArr[index].orderItems = data;
                          setOrdersList(newArr);
                        } else {
                          data.splice(delIndex, 1);
                          handleActiveClick(tableRefs[index]);
                        }

                        setType();
                        setState({ data }, () => resolve());
                      }
                      // setState(ordersList);
                      resolve();
                    }, 1000);
                  })
              }}
            />
          </Grid>

          <div
            className="dFlex w100 flexColumn alignEnd mt10"
            style={{ marginRight: '14.5%' }}
          >
            <div className="dFlex" style={{ marginBottom: '5px' }}>
              <p
                style={{
                  margin: '0 10px 0 0',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  textAlign: 'right',
                  fontStyle: 'italic'
                }}
              >
                Itens:
              </p>
              <p
                style={{
                  color: '#5062F0',
                  fontWeight: 'bold',
                  margin: '0',
                  fontFamily: 'Lato',
                  textAlign: 'right',
                  fontSize: '12px'
                }}
              >
                {formatter.format(totalValue)}
              </p>
            </div>

            <div className="dFlex" style={{ marginBottom: '5px' }}>
              <p
                style={{
                  margin: '0 10px 0 0',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  textAlign: 'right',
                  fontStyle: 'italic'
                }}
              >
                Bonificado:
              </p>
              <p
                style={{
                  color: '#5062F0',
                  fontWeight: 'bold',
                  margin: '0',
                  fontFamily: 'Lato',
                  textAlign: 'right',
                  fontSize: '12px'
                }}
              >
                {formatter.format(totalSubsidized)}
              </p>
            </div>

            <div className="dFlex" style={{ marginBottom: '5px' }}>
              <p
                style={{
                  margin: '0 10px 0 0',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  textAlign: 'right',
                  fontStyle: 'italic'
                }}
              >
                Total geral:
              </p>
              <p
                style={{
                  color: '#5062F0',
                  fontWeight: 'bold',
                  margin: '0',
                  fontFamily: 'Lato',
                  textAlign: 'right',
                  fontSize: '12px'
                }}
              >
                {formatter.format(totalValue - totalSubsidized)}
              </p>
            </div>
          </div>
        </Grid>
      </Paper>
    );
  };

  const form = (
    <Container className="p0">
      {alertErr ? (
        <CommonAlert
          open={alertErr}
          onClose={() => setAlertErr(false)}
          indexMessage={getAlert || messageError || 'Erro ao cadastrar'}
          width="50%"
        />
      ) : null}
      {alertErrSkus ? (
        <CommonAlert
          open={alertErrSkus}
          onClose={onDismiss}
          indexMessage={
            getAlert || messageError || 'Obrigatório o cadastro de 1 Sku'
          }
          width="50%"
        />
      ) : null}

      {loading ? <LinearProgress /> : null}

      <FullScreenLoading display={saving} description="Salvando" />
      <form onSubmit={handleSubmit(onSubmit)} className="newOrderTable">
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <div
              className={`${filterOpen ? 'filterOpened' : 'filterClosed'}`}
              style={{
                overflow: 'hidden',
                boxShadow: '0px 0px 8px rgba(4, 6, 26, 0.25)'
              }}
            >
              <div className="cardDefault">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      size="small"
                      id="asynchronous-demo"
                      getOptionLabel={(option) => option.description}
                      options={optionsProviders}
                      value={value}
                      disabled={editMode}
                      className="autocompleteSmall"
                      onChange={(event, newValue) => {
                        setValue(newValue);
                        setQuotationExpirationDate();

                        if (!value) {
                          handleActiveClick(
                            history?.location?.state.length
                              ? tableRefs
                              : tableRef
                          );
                        } else if (!newValue) {
                          handleActiveClick(
                            history?.location?.state.length
                              ? tableRefs
                              : tableRef
                          );
                        }

                        setOrdersList([
                          {
                            totalItemsValue: 0,
                            bonusAmount: 0,
                            totalOrderAmount: 0,
                            store: {
                              originId: userInfo.companiesActive.id,
                              name: userInfo.companiesActive.name,
                              cnpj: companiesData.find(
                                (company) =>
                                  company._id === userInfo.companiesActive.id
                              ).cnpjNumber
                            },
                            orderItems: []
                          }
                        ]);
                      }}
                      classes={{ input: classes.input, option: classes.option }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          type="text"
                          variant="outlined"
                          label="Fornecedor"
                          required
                          placeholder="Selecione..."
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      )}
                      renderOption={(params) => (
                        <Typography
                          style={{ fontSize: '12px', fontFamily: 'Lato' }}
                        >
                          {params.description}
                        </Typography>
                      )}
                    />
                  </Grid>

                  <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                    <Grid item xs={12} sm={1}>
                      <DatePicker
                        minDate={editMode ? null : new Date()}
                        size="small"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        label="Data Pedido"
                        autoOk
                        views={['year', 'month', 'date']}
                        value={
                          editMode && editData.length
                            ? editData[0].orderDate
                            : selectedStartDate
                        }
                        onChange={handleStartDateChange}
                        inputProps={{ style: { paddingBottom: '6px' } }}
                        classes={{
                          input: classes.input,
                          option: classes.option
                        }}
                        className="dateSmall"
                      />
                    </Grid>

                    <Grid item xs={12} sm={1}>
                      <DatePicker
                        minDate={editMode ? null : selectedStartDate}
                        format="dd/MM/yyyy"
                        size="small"
                        autoOk
                        inputVariant="outlined"
                        label="Data Entrega"
                        views={['year', 'month', 'date']}
                        value={
                          editMode && editData.length
                            ? editData[0].deliveryDate
                            : selectedEndDate
                        }
                        onChange={handleEndDateChange}
                        inputProps={{ style: { paddingBottom: '6px' } }}
                        classes={{
                          input: classes.input,
                          option: classes.option
                        }}
                        className="dateSmall"
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
              </div>
            </div>

            <Grid item sm={12}>
              {ordersList.map((order, index) => handleOrder(order, index))}

              <Paper
                className="cardDefault"
                style={{
                  margin: '0 0 10px 0',
                  paddingTop: '10px',
                  display:
                    ordersList.length === optionsCompanies.length || editMode
                      ? 'none'
                      : 'flex'
                }}
              >
                <label
                  style={{
                    margin: '0 10px 5px 0px',
                    fontSize: '12px',
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'flex-end',
                    lineHeight: 'normal'
                  }}
                >
                  Loja
                </label>

                <Grid item xs={12} sm={2}>
                  <Autocomplete
                    size="small"
                    disabled={
                      ordersList.length === optionsCompanies.length || !value
                    }
                    id="asynchronous-demo"
                    classes={{ input: classes.input, option: classes.option }}
                    className="autocompleteSmall"
                    onChange={async (option, value) => {
                      if (value) {
                        const company = companiesData.find((company) =>
                          (company._id === companiesData.length) === 1
                            ? userInfo.companiesActive.id
                            : value
                        );
                        const address = company.address
                          ? company.address[0]
                          : null;
                        const fullAddress = address
                          ? `${address.street}, ${address.number} - ${address.complement} - ${address.zipCode} - ${address.city} - ${address.states}`
                          : null;

                        const newArr = [
                          ...ordersList,
                          {
                            totalItemsValue: 0,
                            bonusAmount: 0,
                            totalOrderAmount: 0,
                            store: {
                              originId: value._id,
                              name: value.companyName,
                              cnpj: value.cnpjNumber,
                              address: fullAddress
                            },
                            orderItems: []
                          }
                        ];

                        const exists = optionsSkus.find(
                          (opt) => opt.originId === value._id
                        );
                        const skus = await handleSkus(value._id);

                        if (exists) {
                          setOptionsSkus(
                            optionsSkus.map((opt) =>
                              opt.originId === value._id
                                ? {
                                    ...opt,
                                    skuOptions: skus
                                  }
                                : opt
                            )
                          );
                        } else {
                          setOptionsSkus([
                            ...optionsSkus,
                            {
                              originId: value._id,
                              skuOptions: skus
                            }
                          ]);
                        }

                        setOrdersList(newArr);
                      }
                    }}
                    clearOnBlur
                    getOptionSelected={(option, value) =>
                      option._id === value._id
                    }
                    getOptionLabel={(option) => option.companyName}
                    getOptionDisabled={(option) =>
                      ordersList.map(
                        (order) => order.store.originId === option._id
                      )
                    }
                    options={companiesData}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.companyName}
                      </Typography>
                    )}
                  />
                </Grid>
              </Paper>
            </Grid>

            <Grid
              className="dFlex"
              item
              xs={12}
              sm={12}
              style={{ marginTop: 20 }}
              justify="flex-end"
            >
              <Link
                to={
                  history?.location?.state?.length &&
                  history.location.state[0].source === 'SUGGESTION'
                    ? `/pedidos/sugestao/fornecedor/${paramsID}`
                    : `/pedidos/${paramsID}`
                }
                onClick={(e) => createOrder(null)}
              >
                <Button
                  className="defaultButton backButton"
                  design="outlined"
                  label="Voltar"
                />
              </Link>

              <Button
                className="defaultButton submitButton"
                type="submit"
                onSubmit={(e) => onSubmit(ordersList)}
                design="contained"
                style={{ marginLeft: '20px' }}
                label={loading && saving ? 'Salvando...' : 'Salvar'}
              />
            </Grid>
          </Grid>

          <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Cadastro realizado com sucesso!
            </Alert>
          </Snackbar>
        </Grid>
      </form>
    </Container>
  );

  return (
    <Layout>
      <TitleHead title="Pedidos" />

      <GlobalStyle />

      <Container>
        <Grid container xs={12} style={{ marginBottom: '15px' }}>
          <Grid container item xs={8} justify="flex-start" align="center">
            <Typography
              className="dFlex alignCenter"
              style={{ fontFamily: 'Lato', fontSize: '16px' }}
            >
              <b>Cadastro de pedido de compra</b>
            </Typography>
          </Grid>

          <Grid container item xs={4} justify="flex-end" align="center">
            <img
              style={{ width: '25px', cursor: 'pointer' }}
              src={FilterIcon}
              alt="FilterIcon"
              onClick={handleFilterOPen}
            />
          </Grid>
        </Grid>

        {form}
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  orderInfo: state.order
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterChip);
