/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';

import { LinearProgress, TextField, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Autocomplete } from '@material-ui/lab';

import DisableView from 'images/icons/objects/eyes/disableView.svg';
import EnableView from 'images/icons/objects/eyes/enableView.svg';
import DeleteIcon from 'images/icons/signals/removesAndDeletes/deleteBlanc.svg';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

import { StyledTypography } from './styles';

import './styles.scss';

function TagsList(props) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [tagOptions, setTagOptions] = useState([]);
  const [tags, setTags] = useState(
    props.productTags?.filter((tag) => tag?.status !== 'DELETED' || !tag.status)
  );

  useEffect(() => {
    setTags(
      props.productTags?.filter(
        (tag) => tag?.status !== 'DELETED' || !tag.status
      )
    );
  }, [props.productTags]);

  const matchesBigScreen = useMediaQuery('(min-width:1281px)');
  const matchesMediumScreen = useMediaQuery('(max-width:1280px)');

  return (
    <section className="tagsListContainer">
      <table
        className="desktop tagsListTable"
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        border="0"
      >
        <thead>
          <tr className="headerTitle">
            <th width="44%" className="subtitle noBorderRadiusLeft">
              LISTA DE TAGS
            </th>
            <th width="56%" className="subtitle">
              &nbsp;
            </th>
          </tr>

          <tr className="headerSubtitle">
            <th
              style={{ paddingLeft: 26 }}
              width="44%"
              className="subtitle left"
            >
              TAGS
            </th>
            <th width="56%" className="subtitle p8">
              VALORES
            </th>
          </tr>
        </thead>

        <tbody>
          {tags?.length ? (
            tags
              .sort((a, b) => (a.description > b.description ? 1 : -1))
              .map((tag, tagIndex) => {
                const descriptionTag = tag.description;

                return (
                  <>
                    <tr className="withoutHover">
                      <td
                        width="45%"
                        id={`id${tagIndex}`}
                        className="description"
                        style={{ paddingLeft: 5, paddingRight: 8 }}
                      >
                        <div className="dFlex">
                          {tag?.values?.filter(
                            (item) => item.status !== 'DELETED'
                          ).length ? (
                            <img
                              style={{ width: 16, marginRight: 5 }}
                              src={
                                tag.showInDescription ? EnableView : DisableView
                              }
                              alt={
                                tag.showInDescription
                                  ? 'EnableView'
                                  : 'DisableView'
                              }
                              onClick={() => {
                                if (
                                  !tag.values.filter(
                                    (item) => item.status !== 'DELETED'
                                  ).length
                                )
                                  return;

                                props.handleChangeProductTagsList(
                                  tag,
                                  tag.showInDescription
                                    ? null
                                    : tag.values.filter(
                                        (item) => item.status !== 'DELETED'
                                      ),
                                  'DISABLED',
                                  !tag.showInDescription
                                );
                              }}
                            />
                          ) : (
                            <div style={{ width: 16, marginRight: 5 }} />
                          )}

                          {ValidationLength(
                            descriptionTag,
                            (matchesBigScreen && 15) ||
                              (matchesMediumScreen && 12)
                          )}
                        </div>
                      </td>

                      <td
                        width="55%"
                        id={`description${tagIndex}`}
                        className="description p8"
                      >
                        <div className="dFlex alignCenter justifyBetween">
                          <Autocomplete
                            disabled={props.onlyViewMode}
                            style={{ width: '90%' }}
                            options={
                              tagOptions.sort((a, b) =>
                                a.description > b.description ? 1 : -1
                              ) || []
                            }
                            getOptionLabel={(option) => option.description}
                            className="autocompleteSmall multipleNoExpand"
                            onFocus={() =>
                              getOptions(
                                `${environments.catalog}/tags/${tag.id}/values`,
                                'tagOptions',
                                tagOptions,
                                setTagOptions,
                                props.getId
                              )
                            }
                            onBlur={() => setTagOptions([])}
                            value={
                              tags
                                ?.find((item) => item.id === tag.id)
                                ?.values?.filter(
                                  (item) => item.status !== 'DELETED'
                                ) || []
                            }
                            multiple
                            limitTags={1}
                            disableClearable
                            size="small"
                            onChange={(event, value, reason, detail) => {
                              const selected = (
                                tags
                                  .find((item) => item.id === tag.id)
                                  ?.values.filter(
                                    (item) => item.status !== 'DELETED'
                                  ) || []
                              ).some((item) => item.id === detail.option.id);
                              setTags(
                                tags.map((item) =>
                                  item.id === tag.id
                                    ? { ...item, values: value }
                                    : item
                                )
                              );

                              if (
                                value &&
                                reason === 'select-option' &&
                                !selected
                              ) {
                                props.handleChangeProductTagsList(
                                  tag,
                                  detail.option,
                                  'CREATED',
                                  !tag?.values?.filter(
                                    (item) => item.status !== 'DELETED'
                                  ).length
                                    ? true
                                    : tag.showInDescription
                                );
                              } else if (
                                value &&
                                (reason === 'remove-option' || selected)
                              ) {
                                props.handleChangeProductTagsList(
                                  tag,
                                  detail.option,
                                  'DELETED'
                                );
                              } else {
                                props.handleChangeProductTagsList(
                                  tag,
                                  null,
                                  'DELETED'
                                );
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="tagValues"
                                variant="outlined"
                                required
                                placeholder="Selecione..."
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            renderOption={(params) => {
                              const selected = (
                                tags
                                  .find((item) => item.id === tag.id)
                                  ?.values?.filter(
                                    (item) => item.status !== 'DELETED'
                                  ) || []
                              ).some((item) => item.id === params.id);

                              return (
                                <StyledTypography
                                  noWrap
                                  selected={selected}
                                  style={{
                                    fontSize: '12px',
                                    fontFamily: 'Lato'
                                  }}
                                >
                                  {params.description}
                                </StyledTypography>
                              );
                            }}
                          />

                          <img
                            style={{ width: '12px', cursor: 'pointer' }}
                            src={DeleteIcon}
                            alt="DeleteIcon"
                            onClick={() => {
                              if (props.onlyViewMode) return;

                              props.handleChangeProductTagsList(
                                tag,
                                null,
                                'DELETED'
                              );
                            }}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="withoutHover">
                      <td colSpan="2">
                        <hr />
                      </td>
                    </tr>
                  </>
                );
              })
          ) : (
            <tr>
              <td colSpan="2">
                <Typography
                  style={{
                    fontSize: '12px',
                    margin: '5px 0',
                    fontFamily: 'Lato',
                    textAlign: 'center'
                  }}
                >
                  Não há registros para exibir.
                </Typography>
              </td>
            </tr>
          )}

          <tr className="withoutHover">
            <td colSpan="2">
              {false ? <LinearProgress variant="query" /> : <hr className="" />}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
}

export default TagsList;
