/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useState, useEffect } from 'react';

import {
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import NewPassword from 'components/Dialogs/NewPassword/index';
import ItemsPerPage from 'components/ItemsPerPage';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import NewPasswordIcon from 'images/icons/objects/keys/newPasswordIcon.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import axiosInstanceAuth from 'services/middlewares/apis/auth_validate';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import UserForm from './UserForm';

const environments = Environment(process.env.REACT_APP_ENV);

export function ListagemUsuarios({ userInfo }) {
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );

  const { id: paramsID } = useParams();
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 50,
    loading: false,
    filters: [],
    numberOfElements: 0,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    numberOfElements,
    filters,
    totalElements
  } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [users, setUsers] = useState([]);
  const [statusCode, setStatusCode] = useState();
  const [openToast, setOpenToast] = useState(false)
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'updatePassword',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    },
    {
      id: 2,
      name: 'userForm',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    },
    {
      id: 3,
      name: 'confirmDialog',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    }
  ]);
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Status',
        filterName: 'status',
        placeHolder: 'Selecione...',
        size: 'medium_plus',
        type: 'autoComplete',
        optionReference: 'name',
        options: [
          { title: 'Todas', value: null },
          { title: 'Enviado', value: 'SENT' },
          { title: 'Com erro', value: 'ERROR' }
        ]
      },
      {
        label: 'Restaurantes',
        filterName: 'restaurantId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'stores',
        optionReference: 'name',
        options: stores[0],
        size: 'medium_plus'
      }
    ]
  ]);

  const handleFilter = (param, value) => {
    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  };

  const getFilterHandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page
    );
  };

  const getUsers = useCallback(
    (currentPage = page) => {
      (async () => {
        setCallSettings({ ...callSettings, loading: true });

        const params = filtersChanged
          .map((filter) => filter.urlParam)
          .join('&');

        const restaurantId = getId.split(',');  

        const response = await axiosInstanceAuth.get(
          `${environments.auth}/users?page=${currentPage}&size=${size}&originId=${restaurantId[0]}${restaurantId[1] ? `&restaurantIds=${getId}`: ''}&${params}`
        );

        const jsonUsers = await response.data;

        setUsers(jsonUsers?.content);

        setCallSettings({
          ...callSettings,
          loading: false,
          numberOfElements: jsonUsers.numberOfElements,
          page: currentPage,
          totalElements: jsonUsers.totalElements,
          totalPages: jsonUsers.totalPages
        });
      })();
    },
    [filtersChanged, getId, callSettings]
  );

  const handleDeleteUser = (currentUser) => {
    axios
      .delete(`${environments.auth}/users/${currentUser?._id}`, config)
      .then(() => {
        setOpenToast(true);
        setMessage({
          description: 'Usuário removido com sucesso',
          status: 'success'
        });

        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'confirmDialog'
              ? {
                  ...modal,
                  open: false,
                  currentUser: null,
                  title: ''
                }
              : modal
          )
        );

        getUsers(page);
      })
      .catch((error) => {
        setOpenToast(true);
        setMessage({
          description: error.response.data.message,
          status: 'error'
        });
        setStatusCode(error?.response?.status);
      });
  };

  const handleSelectUser = (currentUser) => {
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'userForm'
          ? { ...modal, open: true, currentUser }
          : modal
      )
    );
  };

  const translateProfile = (profileType) => {
    let translatedProfile = '';

    switch (profileType) {
      case 'restaurants':
        translatedProfile = 'Admin Restaurante';
        break;
      case 'admin_master':
        translatedProfile = 'Admin Master';
        break;
      case 'admin':
        translatedProfile = 'Admin Plataforma';
        break;
      case 'managers':
        translatedProfile = 'Gestor';
        break;
      case 'providers':
        translatedProfile = 'Fornecedor';
        break;
      case 'stockists':
        translatedProfile = 'Estoquista';
        break;
      case 'producers':
        translatedProfile = 'Produção';
        break;
      case 'buyers':
        translatedProfile = 'Comprador';
        break;
      case 'quoter':
        translatedProfile = 'Cotador';
        break;
      case 'cataloguer':
        translatedProfile = 'Catalogador';
        break;
      case 'nfes':
        translatedProfile = 'NFE';
        break;
      case 'quoterList':
        translatedProfile = 'Cotador Listagem';
        break;
      case 'manufactures':
        translatedProfile = 'Produção';
        break;
      default:
        break;
    }

    return translatedProfile;
  };

  const addUser = () => (
    <Tooltip title="Cadastro de Usuário" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'userForm'
                ? { ...modal, open: true, currentUser: null }
                : modal
            )
          );
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  useEffect(() => {
    getUsers();
  }, [getId]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: paramsID });
  }, [paramsID]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  return (
    <Layout>
      <GlobalStyle />
      <TitleHead title="Usuários" />
      <Container>
        <PageTitleAndFilter title="Usuários" additionalbutton={addUser} />

        <table
          className="desktop salesNfesListTable"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th className="subtitle" width="17%">
                USUÁRIO
              </th>

              <th className="subtitle" width="17%">
                NOME
              </th>

              <th className="subtitle" width="15%">
                SOBRENOME
              </th>

              <th className="subtitle" width="25%">
                EMAIL
              </th>

              <th className="subtitle" width="10%">
                PERFIL
              </th>

              <th className="subtitle" width="14%">
                LOCAL DE TRABALHO
              </th>

              <th className="subtitle" width="1%">
                &nbsp;
              </th>

              <th className="subtitle pr8" width="1%">
                &nbsp;
              </th>
            </tr>

            <tr className="headerBorder">
              <th colSpan="8" className="titleLineBorder" />
            </tr>
          </thead>

          {callSettings.loading ? (
            <tr>
              <td colSpan="8" className="m10">
                <LinearProgress variant="query" />
              </td>
            </tr>
          ) : null}

          <tbody>
            {users?.length ? (
              users.map((user) => (
                <>
                  <tr>
                    <td className="description pl12">
                      <Link onClick={() => handleSelectUser(user)}>
                        {ValidationLength(user._id, 20)}
                      </Link>
                    </td>

                    <td className="description">
                      {ValidationLength(user?.firstName || '-', 20)}
                    </td>

                    <td className="description">
                      {ValidationLength(user?.lastName || '-', 20)}
                    </td>

                    <td className="description">
                      {ValidationLength(user?.email || '-', 30)}
                    </td>

                    <td className="description">
                      {ValidationLength(
                        translateProfile(user?.profile[0]?.name) || '-',
                        20
                      )}
                    </td>

                    <td className="description">
                      {user?.workplaces?.length
                        ? ValidationLength(user?.workplaces[0]?.name, 20)
                        : '-'}
                    </td>

                    <td
                      className="description pl12"
                      style={{ borderLeft: 'solid 1px #ECEDF1' }}
                    >
                      <Tooltip title="Alterar senha">
                        <IconButton
                          aria-label="update"
                          onClick={() => {
                            setModalSettings(
                              modalSettings.map((modal) =>
                                modal.name === 'updatePassword'
                                  ? { ...modal, open: true, currentUser: user }
                                  : modal
                              )
                            );
                          }}
                          style={{ padding: 0, outline: 0 }}
                        >
                          <img
                            style={{ width: '20px' }}
                            src={NewPasswordIcon}
                            alt="NewPasswordIcon"
                          />
                        </IconButton>
                      </Tooltip>
                    </td>

                    <td className="description pr8 pl12">
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setModalSettings(
                            modalSettings.map((modal) =>
                              modal.name === 'confirmDialog'
                                ? {
                                    ...modal,
                                    open: true,
                                    currentUser: user,
                                    title: user?._id
                                  }
                                : modal
                            )
                          );
                        }}
                        style={{ padding: 0, outline: 0 }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="8" className="p0">
                      <hr className="m0" />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="textAlignCenter">
                  <Typography className="fontSizeDefault mt10 mb10 textAlignCenter bold">
                    Não há registros para exibir.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(_, currentPage) => {
              getUsers(currentPage);
            }}
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
            showFirstButton
            showLastButton
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>
      </Container>

      {modalSettings.find((modal) => modal.name === 'updatePassword')?.open && (
        <NewPassword
          open={
            modalSettings.find((modal) => modal.name === 'updatePassword').open
          }
          close={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'updatePassword'
                  ? { ...modal, open: false, currentUser: null }
                  : modal
              )
            );
          }}
          userInfo={
            modalSettings.find((modal) => modal.name === 'updatePassword')
              ?.currentUser?._id
          }
          setMessage={setMessage}
          callSettings={callSettings}
          getUsers={getUsers}
        />
      )}

      {modalSettings.find((modal) => modal.name === 'confirmDialog')?.open && (
        <ConfirmModal
          open={
            modalSettings.find((modal) => modal.name === 'confirmDialog').open
          }
          title={
            modalSettings.find((modal) => modal.name === 'confirmDialog')?.title
          }
          content={
            <Typography className="bold">
              Deseja realmente excluir esse usuário?
            </Typography>
          }
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: ''
                    }
                  : modal
              )
            );
          }}
          cancelText="Não"
          onConfirm={() =>
            handleDeleteUser(
              modalSettings.find((modal) => modal.name === 'confirmDialog')
                ?.currentUser
            )
          }
          confirmText="Sim"
        />
      )}

      {modalSettings.find((modal) => modal.name === 'userForm')?.open && (
        <UserForm
          open={modalSettings.find((modal) => modal.name === 'userForm').open}
          translateProfile={translateProfile}
          setToast={setOpenToast}
          setMessage={setMessage}
          setStatusCode={setStatusCode}
          currentUser={
            modalSettings.find((modal) => modal.name === 'userForm')
              ?.currentUser
          }
          callSettings={callSettings}
          getUsers={getUsers}
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'userForm'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: ''
                    }
                  : modal
              )
            );
          }}
        />
      )}

      <CommonAlert
        open={openToast}
        onClose={() => setOpenToast(false)}
        indexMessage={message.description}
        messagePersonal={message.description}
        statusCode={statusCode}
        severity={message.status}
        width="50%"
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListagemUsuarios);
