import axios from 'axios';

const getOptions = (
  endpoint,
  key,
  hook,
  setHook,
  originId,
  filters,
  excepts,
  size = 1500,
  findOne = false,
  setAsArray = false,
  paramRest = 'originId'
) => {
  (async () => {
    let params = '';

    if (filters !== undefined && filters) {
      const keys = Object.keys(filters);
      const values = Object.values(filters);
      keys.map((key, index) => (params += `&${key}=${values[index]}`));
    }

    let route = null;

    if (findOne) {
      route = `${endpoint}?${paramRest}=${originId}${params}`;
    } else {
      route = `${endpoint}?page=${Number(
        !(key === 'restaurants')
      )}&size=${size}&${paramRest}=${originId}${params}`;
    }

    axios
      .get(route)
      .then((response) => {
        if (setAsArray) {
          setHook([response?.data?.content || response?.data]);
        } else {
          setHook(response?.data?.content || response?.data);
        }
      })
      .catch((error) => {
        setHook([]);
        console.log('error', error?.message);
      });
  })();
};

export default getOptions;
