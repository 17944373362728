/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import format from 'date-fns/format';
import Draggable from 'react-draggable';

import ArrowDownIcon from 'images/icons/arrows/arrowDrillDown.svg';
import CopyClipBoardIcon from 'images/icons/copyClipboards/copyClipboard.svg';
import CheckOutlineGreen from 'images/icons/signals/checks/checkOutlineGreen.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import copyClipBoard from 'utils/copyClipboard';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-ean-suggestion"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function EanSuggestion({
  eanSuggestions,
  openedByGeneralSuggestion,
  setHeaderDateTimeTitle,
  setEanSugg,
  modalSettings,
  setModalSettings,
  multipleSkuData,
  setMultipleSkuData,
  jsonBody,
  setJsonBody,
  onlyView,
  suggestionsSelected,
  setSuggestionsSelected,
  userInfo
}) {
  const currentDialogSettings = openedByGeneralSuggestion
    ? 'generalSuggestions'
    : 'eanSuggestions';
  const currentSkuIndex = modalSettings.find(
    (modal) => modal.name === currentDialogSettings
  )?.currentSkuIndex;
  const currentSku = modalSettings.find(
    (modal) => modal.name === currentDialogSettings
  )?.currentSku;
  const packageIds = modalSettings.find(
    (modal) => modal.name === currentDialogSettings
  )?.packageIds;
  const environments = Environment(process.env.REACT_APP_ENV);
  const isMultipleSku = currentSkuIndex || currentSkuIndex === 0;
  const [copied, setCopied] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const uniqueUrls = (arr) =>
    [...new Set(arr.map((item) => item.url))].map((url) => ({
      url,
      retiable: arr.find((el) => el.url === url).retiable
    }));

  const isRelevant = (sugg) =>
    currentSku && currentSku?.ean === sugg?.description;

  const orderedSuggestions = (suggestions) => {
    const relevantItems = suggestions
      .filter((element) => element.relevant)
      .sort((a, b) => (a.percentage < b.percentage ? 1 : -1));
    const otherItems = suggestions
      .filter((element) => !element.relevant)
      .sort((a, b) => (a.percentage < b.percentage ? 1 : -1));

    return [relevantItems, otherItems].flat();
  };

  const updateEan = (obj) => {
    const body = {
      urls: obj?.urls || [],
      description: obj?.description || '',
      type: 'EAN',
      user: userInfo.user
    };

    axios
      .put(`${environments.catalog}/providers/${packageIds}/searchs`, body)
      .then((response) => {
        if (isMultipleSku) {
          setMultipleSkuData(
            multipleSkuData.map((sku, i) => {
              if (currentSkuIndex === i) {
                return {
                  ...sku,
                  ean: obj?.description || sku.ean
                };
              }
              return sku;
            })
          );
        } else {
          setJsonBody({
            ...jsonBody,
            sku: {
              ...jsonBody.sku,
              ean: obj?.description || jsonBody.sku.ean
            }
          });
        }

        setSuggestions([]);
        setLoading(false);
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'eanSuggestions' ? { ...modal, open: false } : modal
          )
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setMessage({
          description: error.response.data.message,
          status: 'error'
        });
      });
  };

  const handleFinishProcess = (obj) => {
    setMessage({ ...message, description: '', status: '' });
    setLoading(false);

    if (onlyView || !obj) {
      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'eanSuggestions' ? { ...modal, open: false } : modal
        )
      );
    } else {
      updateEan(obj);
    }
  };

  const handleChangeRadio = (event, sugg) => {
    setRadioValue(event.target.value);

    if (openedByGeneralSuggestion) {
      setSuggestionsSelected({ ...suggestionsSelected, ean: '' });
      setEanSugg(sugg);
    } else {
      handleFinishProcess(sugg);
    }
  };

  const getSuggestions = () => {
    setLoading(true);

    axios
      .get(`${environments.catalog}/providers/${packageIds}/searchs?type=EAN`)
      .then((response) => {
        setSuggestions(response?.data);
        setRadioValue(
          response?.data?.find((el) => el.selected)?.description || ''
        );
        setHeaderDateTimeTitle &&
          setHeaderDateTimeTitle(
            new Date(response?.data[0]?.created_at || Date())
          );
        setLoading(false);
      })
      .catch((error) => {
        setSuggestions([]);
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (eanSuggestions?.length) {
      setSuggestions([]);
      setHeaderDateTimeTitle(new Date());
    } else {
      getSuggestions();
    }
  }, []);

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <>
      <ConditionalWrapper
        condition={!openedByGeneralSuggestion}
        wrapper={(children) => (
          <Dialog
            fullScreen={
              modalSettings.find((modal) => modal.name === 'eanSuggestions')
                .fullScreen
            }
            open={
              modalSettings.find((modal) => modal.name === 'eanSuggestions')
                .open
            }
            onClose={() => {
              handleFinishProcess();
            }}
            className="defaultModal eanSuggestionDialog customZIndex"
            PaperComponent={PaperComponent}
          >
            {children}
          </Dialog>
        )}
      >
        <section
          className="eanSuggestionDialog flexColumn"
          style={{ width: openedByGeneralSuggestion && '30%' }}
        >
          <DialogTitle
            className="modalHeader navyBlue bold textAlignCenter"
            style={{
              cursor: openedByGeneralSuggestion ? 'auto' : 'move'
            }}
            id="draggable-dialog-ean-suggestion"
            fullWidth
          >
            <Typography
              style={{ fontSize: openedByGeneralSuggestion ? 10 : 12 }}
            >
              {`RESULTADOS DA BUSCA DE EAN
                ${
                  suggestions?.length && !openedByGeneralSuggestion
                    ? `(${format(
                        new Date(suggestions[0].created_at),
                        'dd/MM/yy - HH'
                      )}hs)`
                    : ''
                }
              `}
            </Typography>

            {!openedByGeneralSuggestion && (
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  handleFinishProcess();
                }}
                aria-label="close"
                style={{ outline: '0', background: 'none', align: 'right' }}
              >
                <img
                  style={{ width: '16px' }}
                  src={CloseIconNavy}
                  alt="CloseIconNavy"
                />
              </IconButton>
            )}
          </DialogTitle>

          <DialogContent className="modalContent bgWhite dFlex flexColumn">
            {suggestions?.length ? (
              orderedSuggestions(suggestions).map((suggestion, index) => (
                <div className="suggestionItem dFlex flexColumn">
                  <div className="suggestionMainInfo dFlex pRelative">
                    {!onlyView ? (
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="type"
                          name="type"
                          value={
                            suggestionsSelected?.ean?.description || radioValue
                          }
                          onChange={(e) => handleChangeRadio(e, suggestion)}
                          style={{ flexDirection: 'row' }}
                        >
                          <FormControlLabel
                            value={suggestion?.description}
                            control={<Radio required size="small" />}
                            label={
                              isRelevant(suggestion) ? (
                                <Tooltip
                                  TransitionProps={{ timeout: 600 }}
                                  title={
                                    <p
                                      className="mb0"
                                      style={{ padding: '2px 6px' }}
                                    >
                                      Resultado mais relevante
                                    </p>
                                  }
                                  placement="right-end"
                                >
                                  <span
                                    id={`suggestion-ean-number-${index}`}
                                    className="suggestionName relevant bold fontSizeDefault"
                                  >
                                    {ValidationLength(
                                      suggestion?.description || '-',
                                      25
                                    )}
                                  </span>
                                </Tooltip>
                              ) : (
                                <span
                                  id={`suggestion-ean-number-${index}`}
                                  className="suggestionName bold fontSizeDefault"
                                >
                                  {ValidationLength(
                                    suggestion?.description || '-',
                                    25
                                  )}
                                </span>
                              )
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : isRelevant(suggestion) ? (
                      <Tooltip
                        TransitionProps={{ timeout: 600 }}
                        title={
                          <p className="mb0" style={{ padding: '2px 6px' }}>
                            Resultado mais relevante
                          </p>
                        }
                        placement="right-end"
                      >
                        <span
                          id={`suggestion-ean-number-${index}`}
                          className="ml0 suggestionName relevant  bold fontSizeDefault"
                        >
                          {ValidationLength(suggestion?.description || '-', 25)}
                        </span>
                      </Tooltip>
                    ) : (
                      <span
                        id={`suggestion-ean-number-${index}`}
                        className="ml0 suggestionName bold fontSizeDefault"
                      >
                        {ValidationLength(suggestion?.description || '-', 25)}
                      </span>
                    )}

                    <Tooltip
                      placement="right"
                      title={
                        <p style={{ padding: '4px 6px' }}>
                          {copied ? 'Copiado' : 'Copiar'}
                        </p>
                      }
                    >
                      <a
                        onMouseLeave={() => {
                          setTimeout(() => {
                            setCopied(false);
                          }, 1000);
                        }}
                        onClick={() => {
                          copyClipBoard('suggestion-ean-number', index, '');
                          setCopied(true);
                        }}
                        className="dFlex"
                        style={{ marginLeft: 5, cursor: 'pointer' }}
                      >
                        <img
                          style={{ marginRight: '4px' }}
                          src={CopyClipBoardIcon}
                          alt="CopyClipboard"
                        />
                      </a>
                    </Tooltip>

                    <span
                      className="fontSizeDefault ml8"
                      style={{ color: '#9296AC' }}
                    >
                      {suggestion?.percentage.toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      }) || '-'}
                      % / {uniqueUrls(suggestion?.urls || []).length || '-'}{' '}
                      {uniqueUrls(suggestion?.urls || []).length === 1
                        ? 'site'
                        : 'sites'}
                    </span>

                    {suggestion.whiteListCount > 0 ? (
                      <div className="dFlex alignCenter ml10">
                        <img
                          style={{ marginRight: 3 }}
                          src={CheckOutlineGreen}
                          alt="CheckOutlineGreen"
                        />

                        <p
                          className="mb0 fontSizeDefault"
                          style={{ color: '#08AA15' }}
                        >
                          {suggestion.whiteListCount}{' '}
                          {suggestion.whiteListCount === 1
                            ? 'confiável'
                            : 'confiáveis'}
                        </p>
                      </div>
                    ) : null}

                    <img
                      className={`pAbsolute cursorPointer ${
                        suggestion.open && 'rotate'
                      }`}
                      style={{ width: '16px', right: '0' }}
                      src={ArrowDownIcon}
                      alt="ArrowDownIcon"
                      onClick={() => {
                        setSuggestions(
                          suggestions.map((sugg) => {
                            if (sugg.description === suggestion.description) {
                              return { ...sugg, open: !sugg.open };
                            }
                            return { ...sugg };
                          })
                        );
                      }}
                    />
                  </div>

                  {suggestion?.urls?.length && suggestion.open ? (
                    <div className="suggestionLinks dFlex flexColumn">
                      {uniqueUrls(suggestion.urls).map((sugg) => (
                        <div
                          className="dFlex alignCenter"
                          style={{ marginLeft: 2 }}
                        >
                          <a
                            target={sugg?.url ? '_blank' : '_self'}
                            href={sugg?.url ? sugg?.url : '#'}
                            rel="noreferrer"
                          >
                            {ValidationLength(sugg?.url || '-', 50)}
                          </a>

                          {sugg.retiable ? (
                            <div
                              className="dFlex alignCenter ml10"
                              style={{ marginTop: 2 }}
                            >
                              <img
                                style={{ marginRight: 3 }}
                                src={CheckOutlineGreen}
                                alt="CheckOutlineGreen"
                              />

                              <p
                                className="mb0 fontSizeDefault"
                                style={{ color: '#08AA15' }}
                              >
                                site confiável
                              </p>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              ))
            ) : (
              <div
                className={`dFlex alignCenter ${
                  !currentSku?.ean && 'justifyCenter'
                }`}
              >
                <p className="mb0 bold">
                  {currentSku?.ean
                    ? `EAN já selecionado - ${currentSku.ean}`
                    : 'Nenhuma informação encontrada'}
                </p>
              </div>
            )}
          </DialogContent>

          {loading && <LinearProgress variant="query" />}
        </section>
      </ConditionalWrapper>

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => message.status !== 'error' && handleFinishProcess()}
      >
        <Alert
          onClose={() => message.status !== 'error' && handleFinishProcess()}
          severity={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </>
  );
}

export default EanSuggestion;
