const ProdutosLocalize = {
  'need to send originId on body to complete request':
    'É necessário enviar originId no corpo da requisição para completar a solicitação',
  'counter successfully updated': 'Contador atualizado com sucesso',
  'Need send originId': 'É necessário enviar originId',
  'status not accepted': 'Status não aceito',
  'need to send noteKey and originId to complete request':
    'É necessário enviar noteKey e originId para completar a solicitação',
  'need to send restaurantIds and productIds in query parameter':
    'É necessário enviar restaurantIds e productIds nos parâmetros de consulta',
  'need to send providerId and ordersId':
    'É necessário enviar providerId e ordersId',
  'originId is a required parameter': 'OriginId é um parâmetro obrigatório',
  'need send query parameters': 'É necessário enviar parâmetros de consulta',
  'some information is missing to be able update':
    'Algumas informações estão faltando para poder atualizar',
  'need send parameter': 'É necessário enviar o parâmetro',
  'need to send startDate and finishDate':
    'É necessário enviar startDate e finishDate',
  'need to send at least one item to receive':
    'É necessário enviar pelo menos um item para receber',
  'items is empty': 'Itens estão vazios',
  'need send intention': 'É necessário enviar a intenção',
  'need to send productGroupId in route params to complete request':
    'É necessário enviar productGroupId nos parâmetros da rota para completar a solicitação',
  'Objeto quotationProduct não encontrado para o identificador informado':
    'Objeto quotationProduct não encontrado para o identificador informado',
  'need to send originsId': 'É necessário enviar originsId',
  'necessário enviar o productGroupId ou associations':
    'Necessário enviar o productGroupId ou associations',
  'necessário enviar pelo menos os ids do restaurante ou os ids do produto':
    'Necessário enviar pelo menos os ids do restaurante ou os ids do produto',
  'need to send originIds to complete request':
    'É necessário enviar originIds para completar a solicitação',
  'Is missing information restaurantId in worksheet control':
    'Está faltando a informação restaurantId no controle da planilha.',
  'Products successfully updated': 'Produtos atualizados com sucesso',
  'Product platform updated': 'Produto Atualizado',
  'Product updated': 'Produto atualizado com sucesso',
  'Unit of measurement not allowed.': 'Unidade de medida não permitida.',
  'Units of measurement and consumption are not compatible': 'Unidades de medida e consumo não são compatíveis',
  'products for group successfully updated': 'Produto para grupo atualizado'
};

export default ProdutosLocalize;
