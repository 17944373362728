/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

export default function InvoicePerItem({
  modalSettings,
  onClose,
  getId,
  defaultStartDate = new Date(),
  defaultEndDate = new Date(),
  codePdv
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [mainList, setMainList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment(defaultStartDate).format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment(defaultEndDate).format('YYYY-MM-DD'));
  const [itemsTotalValues, setItemsTotalValues] = useState();

  const getMainContent = async () => {
    setLoading(true);

    await Axios
      .get(`${environments.sales}/sales/${getId}/menu/${codePdv}?startDate=${startDate}&endDate=${endDate}`)
      .then((response) => {
        const sumQuantity = response?.data?.reduce((acc, item) => acc + Number(item.quantity || 0), 0) || 0
        const sumUnitary  = response?.data?.reduce((acc, item) => acc + Number(item.unitaryValue || 0), 0) || 0
        const sumValue    = response?.data?.reduce((acc, item) => acc + Number(item.totalValue || 0), 0) || 0
        const sumDiscount = response?.data?.reduce((acc, item) => acc + Number(item.discount || 0), 0) || 0
        const sumAddition = response?.data?.reduce((acc, item) => acc + Number(item.addition || 0), 0) || 0

        setMainList(response?.data || []);
        setItemsTotalValues({
          quantity: sumQuantity,
          unitary: sumUnitary,
          discount: sumDiscount,
          addition: sumAddition,
          total: sumValue - sumDiscount + sumAddition,
          notes: response?.data?.length || 0
        })
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMainContent()
  }, [startDate, endDate])

  return (
    <Dialog
      fullScreen={
        modalSettings.find((modal) => modal.name === 'invoicePerItem')
          .fullScreen
      }
      open={
        modalSettings.find((modal) => modal.name === 'invoicePerItem').open
      }
      onClose={() => {
        onClose()
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal mH80 invoiceDialog invoiceDialogPerItem"
      maxWidth="lg"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <div className="dFlex justifyBetween alignCenter w100">
          <Typography>NOTAS FISCAIS DE VENDA</Typography>
        </div>

        <IconButton
          className="ml10"
          edge="start"
          color="inherit"
          onClick={() => {
            onClose()
          }}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent
        className="modalContent onlyTable pb0"
        style={{ padding: '0px 15px' }}
      >
        <div className="totalBar">
          <div className="leftInfos">
            <span>TOTAL:</span>
            <span className="notes">{itemsTotalValues?.notes} notas</span>
          </div>

          <div className="rightInfos">
            <span>
              {itemsTotalValues?.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
            <span>R$ {numberToReal(itemsTotalValues?.total, 2)}</span>
          </div>
        </div>
        
        <table className="invoiceTable">
          <thead>
            <tr>
              <th width="15%" className="alignLeft">
                Data de Emissão
              </th>

              <th width="10%">
                Número da Nota
              </th>

              <th width="10%" className="textAlignLeft">
                Código
              </th>

              <th width="28%" className="textAlignLeft">
                Produto
              </th>

              <th width="8%">
                Qtde
              </th>

              <th width="10%">
                Valor Unitário
              </th>

              <th width="9%">
                Desconto
              </th>

              <th width="10%">
                Valor Total
              </th>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th colSpan="8" className="textAlignCenter">
                  <LinearProgress
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {mainList?.length ?
              mainList.map((item, index) => {
                const emissionDate = moment(item?.emissionDate).add(3, 'hours').format('DD/MM/YYYY - HH:mm')
                const totalValueCalc = item?.unitaryValue * item?.quantity - item?.discount

                return (
                  <tr key={index}>
                    <td className="alignLeft">{emissionDate}</td>

                    <td>
                      {item.nfNumber}
                    </td>

                    <td>
                      {ValidationLength(item?.codePdv, 10)}
                    </td>

                    <td>
                      {ValidationLength(item?.description, 30)}
                    </td>

                    <td>
                      {item?.quantity?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </td>

                    <td>
                      R$ {numberToReal(item?.unitaryValue, 2)}
                    </td>

                    <td>
                      R$ {numberToReal(item?.discount, 2)}
                    </td>

                    <td>
                      R$ {numberToReal(totalValueCalc, 2)}
                    </td>
                  </tr>
                );
              })
            : (
              <tr>
                <td colSpan="8" className="description textAlignCenter">
                  <Typography className="fontSizeDefault textAlignCenter" style={{ margin: '5px' }}>
                    Não há histórico de notas para este período.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>        
      </DialogContent>
    </Dialog>
  );
}