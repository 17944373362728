/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';

function EconomyAndApproveCards({
  approveProductInfos,
  handleFilter,
  selectedItems
}) {
  const statusFilterObj = [
    {
      description: 'SEM QTDE',
      filterValue: 'NONE',
      value: approveProductInfos?.totalWithoutQuantity || 0,
      bgColor: 'defaultBg',
      selected: false
    },
    {
      description: 'À APROVAR',
      filterValue: 'TO APPROVE',
      value: approveProductInfos?.totalToApprove || 0,
      bgColor: 'defaultBg',
      selected: false,
      sumValue: approveProductInfos?.sumToApprove
    },
    {
      description: 'APROVADO',
      filterValue: 'APPROVED',
      value: approveProductInfos?.totalApproved || 0,
      bgColor: 'defaultBg',
      selected: false,
      sumValue: approveProductInfos?.sumApproved
    },
    {
      description: 'INCLUÍDO',
      filterValue: 'INCLUDED',
      value: approveProductInfos?.totalIncluded || 0,
      bgColor: 'defaultBg',
      selected: false
    },
    {
      description: 'ANDAMENTO',
      filterValue: 'IN PROGRESS',
      value: approveProductInfos?.totalInProgress || 0,
      bgColor: 'defaultBg',
      selected: false
    },
    {
      description: 'PARCIAL',
      filterValue: 'PARTIALLY_RECEIVED',
      value: approveProductInfos?.totalPartially || 0,
      bgColor: 'defaultBg',
      selected: false
    },
    {
      description: 'RECEBIDO',
      filterValue: 'RECEIVED',
      value: approveProductInfos?.totalReceived || 0,
      bgColor: 'defaultBg',
      selected: false
    },
    {
      description: 'DIVERGENTE',
      filterValue: 'RECEIVED_DIVERGENCE',
      value: approveProductInfos?.totalReceivedDivergence || 0,
      bgColor: 'defaultBg',
      selected: false
    }
  ];

  return (
    <section className="dFlex quotationManagerStatusFilterContainer">
      <div className="statusFilterBox mr20">
        <div className="dFlex">
          {statusFilterObj?.slice(0, 3).map((item, index) => {
            const isLast =
              index === (statusFilterObj?.slice(0, 3)?.length || 0) - 1;
            const isWithoutQuantityItem = item?.filterValue === 'NONE';
            const isWithoutApprovedItem = item?.filterValue === 'APPROVED';

            return (
              <div
                className={`dFlex flexColumn ${
                  !isWithoutQuantityItem && 'alignCenter'
                } ${!isLast && 'mr8'}`}
              >
                {!isWithoutQuantityItem ? (
                  <div className="boxTitleWithRealValue">
                    <p
                      className="boxTitle bold"
                      style={{
                        color: isWithoutApprovedItem ? '#08AA15' : '#AA8208'
                      }}
                    >
                      R${' '}
                      {item?.sumValue?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </p>
                  </div>
                ) : (
                  <p className="boxTitle lightBlue bold">APROVAÇÃO</p>
                )}

                <div
                  onClick={() => {
                    handleFilter(
                      'orderStatus',
                      selectedItems?.find(
                        (el) => el?.description === item?.description
                      )?.selected
                        ? ''
                        : item.filterValue,
                      item.description,
                      null,
                      item.filterValue
                    );
                  }}
                  className="item cursorPointer"
                >
                  <div
                    className={`itemTitle dFlex justifyBetween ${
                      selectedItems?.find(
                        (el) => el?.description === item?.description
                      )?.selected && 'selected'
                    }`}
                  >
                    <span>{item.description}</span>

                    <p className="mb0 white bold">{item.value}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="statusFilterBox">
        <p className="boxTitle lightBlue bold">PEDIDO</p>

        <div className="dFlex">
          {statusFilterObj?.slice(3, 8).map((item, index) => (
            <div
              onClick={() => {
                handleFilter(
                  'orderStatus',
                  selectedItems?.find(
                    (el) => el?.description === item?.description
                  )?.selected
                    ? ''
                    : item.filterValue,
                  item.description,
                  null,
                  item.filterValue
                );
              }}
              className="item cursorPointer"
            >
              <div
                className={`itemTitle dFlex justifyBetween ${
                  selectedItems?.find(
                    (el) => el?.description === item?.description
                  )?.selected && 'selected'
                }`}
              >
                <span>{item.description}</span>

                <p className="mb0 white bold">{item.value}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default EconomyAndApproveCards;
