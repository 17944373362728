import {
  unityToPackage,
  percentDiff,
  getInflationRangeString
} from 'utils/calcRelationPackageUnity';
import numberToReal from 'utils/numberToReal';

export const handleFetchData = (data, stateDaata, setNewData) => {
  const leftIconLighthouse = {
    LAST_ORDER: 'yellow',
    LAST_QUOTATION: 'yellow'
  };

  const leftIconLighthouseValid = {
    inValid: 'red',
    valid: 'green'
  };

  const getOriginQuotationOption = (quotes) => {
    if (quotes.quotationOrigin === 'LAST_ORDER') {
      return 'Sem cotação cadastrada, valor do último Pedido';
    }

    if (quotes.quotationOrigin === 'LAST_QUOTATION') {
      return 'Sem cotação cadastrada, valor da última Cotação';
    }

    if (quotes.quotationOrigin === 'MANUAL') {
      return 'Cotação cadastrada Manualmente pelo usuário';
    }

    return 'Sem informação';
  };

  const getQuotationOption = (quotes) => {
    if (quotes.quotationOrigin === 'LAST_ORDER') {
      return 'p';
    }

    if (quotes.quotationOrigin === 'LAST_QUOTATION') {
      return 'c';
    }

    if (quotes.quotationOrigin === 'MANUAL') {
      return 'm';
    }

    return 'none';
  };

  const numberTo3Decimal = (value) => {
    if (value) {
      return +value.toFixed(2);
    }
  };

  const getInflationContent = (quotation, lastCostExtractShopping) => {
    const { hasOrders, isValid, valueOfConsumptionUnit } = quotation;

    if (hasOrders && isValid) {
      const percent = percentDiff(
        numberTo3Decimal(valueOfConsumptionUnit),
        numberTo3Decimal(lastCostExtractShopping)
      );
      const getPercentString = getInflationRangeString(
        numberTo3Decimal(lastCostExtractShopping),
        numberTo3Decimal(valueOfConsumptionUnit),
        percent
      );

      if (
        getPercentString.content === '-0,00' ||
        getPercentString.content === '+ 0,00'
      ) {
        getPercentString.content = '0,00';
      }

      return getPercentString;
    }

    return { content: '-' };
  };

  const buttonLinkOptions = {
    valid: {
      backgroundColor: '#DFF7E8',
      color: '#3DCC6E'
    },
    inValid: {
      backgroundColor: '#F7DFDF',
      color: '#E22121'
    },
    isGray: {
      backgroundColor: '#D0D3E2',
      color: '#454A67'
    }
  };

  const setStatusOfButtonInflation = (
    unitaryValue,
    lastCostExtractShopping
  ) => {
    const decimalUnitaryValue = numberTo3Decimal(unitaryValue);
    const decimalLastExtractShopping = numberTo3Decimal(
      lastCostExtractShopping
    );
    const percent = percentDiff(
      decimalUnitaryValue,
      decimalLastExtractShopping
    );
    const { color } = getInflationRangeString(
      decimalLastExtractShopping,
      decimalUnitaryValue,
      percent
    );

    if (color) {
      return 'valid';
    }

    return 'inValid';
  };

  const getInflationButtonActions = (status, quatation) => {
    const { isValid = false, hasOrders = false } = quatation;

    if (isValid && hasOrders) {
      return buttonLinkOptions[status];
    }

    if (!isValid && hasOrders) {
      return buttonLinkOptions.isGray;
    }

    return buttonLinkOptions.isGray;
  };

  const geInflationActionStatus = (quatation) => {
    const { isValid = false, hasOrders = false } = quatation;

    if (isValid && hasOrders) {
      return true;
    }

    if (!isValid && hasOrders) {
      return true;
    }

    return false;
  };

  const translatedData = data.map((item) => {
    const consumptionValue = item?.quotation?.valueOfConsumptionUnit
      ? item?.quotation?.valueOfConsumptionUnit
      : '0,00';
    const comsumptionUnit = `${
      item?.quotation?.unitMeasurement
        ? `/${item?.quotation?.unitMeasurement}`
        : ''
    }`;

    const newTranslatedList = [
      {
        content: item.description,
        action: 'selectItem',
        secondAction: 'modalSku',
        selected: false,
        productsIds: item.products.map((product) => product.productId),
        products: item.products,
        id: item.productGroupId,
        item,
        setCurrentProduct: true,
        productGroupId: item.productGroupId,
        type: 'text',
        itemLength: 20,
        orderQuantity: item.suggestion.totalSuggestion,
        inputSize: '100%'
      },
      {
        content: item.category,
        type: 'text',
        itemLength: 11,
        inputSize: '100%'
      },
      {
        content: item.skus,
        action: 'modalSkusQuotation',
        align: 'number',
        productsIds: item.products.map((product) => product.productId),
        type: 'number',
        fractionDigits: 2
      },
      {
        content: [
          item.suggestion.totalSuggestion,
          `/${item.suggestion.quantityRestaurants}`
        ],
        action: 'editable',
        secondAction: 'modalPurchaseSuggestions',
        modalToTableProps: {
          totalSuggestion: item.suggestion.totalSuggestion
        },
        containerSize: '100%',
        key: 'orderQuantity',
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        productsIds: item.products.map((product) => product.productId),
        borderTable: true
      },
      {
        content: [
          item?.quotation?.valueOfConsumptionUnit
            ? item?.quotation?.valueOfConsumptionUnit
            : '0,00',
          `${
            item?.quotation?.consumptionUnitOfProduct
              ? `/${  item?.quotation?.consumptionUnitOfProduct}`
              : '   '
          }`
        ],
        leftIconLighthouse: item?.quotation?.quotationOrigin
          ? leftIconLighthouse[item?.quotation?.quotationOrigin]
          : item?.quotation?.isValid
          ? leftIconLighthouseValid.valid
          : leftIconLighthouseValid.inValid,
        productsIds: item.products.map((product) => product.productId),
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        valid: item?.quotation?.isValid
          ? true
          : !(!item?.quotation?.isValid ||
            item?.quotation?.quotationOrigin === 'LAST_QUOTATION' ||
            item?.quotation?.quotationOrigin === 'LAST_ORDER'),
        tooltip: item?.quotation?.message
      },
      {
        content: [
          item.quotation.packageValue ? item.quotation.packageValue : '0,000',
          `${
            item?.quotation?.quantityPackage
              ? `/${ 
                numberToReal(item?.quotation?.quantityPackage, 2) 
                }${item?.quotation?.unitMeasurementPackage}`
              : ''
          }`
        ],
        productsIds: item.products.map((product) => product.productId),
        padding: '0px',
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        valid: item?.quotation?.isValid
          ? true
          : !(!item?.quotation?.isValid ||
            item?.quotation?.quotationOrigin === 'LAST_QUOTATION' ||
            item?.quotation?.quotationOrigin === 'LAST_ORDER')
      },
      {
        content: unityToPackage(
          item?.quotation?.valueOfConsumptionUnit,
          item.suggestion.totalSuggestion
        ),
        rightIcon: {
          iconName: 'originQuotation',
          setOption: getQuotationOption(item?.quotation),
          show: true,
          tooltip: getOriginQuotationOption(item?.quotation)
        },
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        valid: item?.quotation?.isValid
          ? true
          : !(!item?.quotation?.isValid ||
            item?.quotation?.quotationOrigin === 'LAST_QUOTATION' ||
            item?.quotation?.quotationOrigin === 'LAST_ORDER')
      },
      {
        content: item.quotation.relatedProviders,
        action: 'modalProviderQuotation',
        productsIds: item.products.map((product) => product.productId),
        ucValue: [consumptionValue, comsumptionUnit],
        align: 'number',
        rightIcon: [
          {
            iconName: 'quotationNegotiation',
            setOption: item?.quotation?.hasHistorical ? 'valid' : 'inValid',
            action: item?.quotation?.hasHistorical ? 'modalTradingHistory' : '',
            productsIds: item.products.map((product) => product.productId),
            show: true,
            tooltip: item?.quotation?.hasHistorical
              ? 'Cotação possui mais de um valor'
              : 'Sem histórico de negociação'
          },
          {
            iconName: 'providerIcon',
            setOption: (
              item?.products?.length
                ? item?.products[0]?.quotationRequested
                : false
            )
              ? 'valid'
              : 'inValid',
            productsIds: item.products.map((product) => product.productId),
            show: true,
            tooltip: (
              item?.products?.length
                ? item?.products[0]?.quotationRequested
                : false
            )
              ? 'Cotação solicitada'
              : 'Cotação não solicitada'
          }
        ],
        type: 'richNumber',
        fractionDigits: 2,
        borderTable: true,
        valid: item?.quotation?.isValid
          ? true
          : !(!item?.quotation?.isValid ||
            item?.quotation?.quotationOrigin === 'LAST_QUOTATION' ||
            item?.quotation?.quotationOrigin === 'LAST_ORDER')
      },
      {
        content: `${numberToReal(
          item?.inflation?.lastCostExtractShopping
            ? item?.inflation?.lastCostExtractShopping
            : 0,
          2
        )}/${item?.inflation?.consumptionUnit}`,
        align: 'number',
        type: 'richNumber',
        fractionDigits: 2
      },
      {
        content: `${
          getInflationContent(
            item?.quotation,
            item?.inflation?.lastCostExtractShopping
          )?.content
        }`,
        action: geInflationActionStatus(item?.quotation)
          ? 'modalInflation'
          : null,
        align: 'number',
        productsIds: item.products.map((product) => product.productId),
        type: 'number',
        fractionDigits: 2,
        borderTable: true,
        buttonLink: getInflationButtonActions(
          setStatusOfButtonInflation(
            item?.quotation?.valueOfConsumptionUnit,
            item?.inflation?.lastCostExtractShopping
          ),
          item?.quotation
        ),
        inputSize: '100%'
      },
      {
        content: [
          item?.consumption?.totalConsumed,
          `/${item?.consumption?.quantityRestaurants}`
        ],
        action: 'modalStoreConsumption',
        secondAction: 'modalStoreConsumption',
        align: 'number',
        productsIds: item.products.map((product) => product.productId),
        type: 'richNumber',
        fractionDigits: 2
      },
      {
        content: item?.consumption?.totalValue,
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        borderTable: true
      },
      {
        content: item?.stock?.unitaryValue ? item?.stock?.unitaryValue : 0,
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number'
      },
      {
        content: `${numberToReal(item.stock.totalQuantity, 2)}/${
          item.stock.consumptionUnit
        }`,
        action: 'modalStockPerStore',
        align: 'number',
        productsIds: item.products.map((product) => product.productId),
        type: 'richNumber',
        fractionDigits: 2,
        buttonLink:
          buttonLinkOptions[item.stock.hasRupture ? 'inValid' : 'valid'],
        inputSize: '80px'
      },
      {
        content:
          item?.stock?.duration === 1 ? '1 dia' : `${item.stock.duration} dias`,
        type: 'text',
        inputSize: '45px',
        align: 'number',
        action: 'modalStockPerStore',
        productsIds: item.products.map((product) => product.productId)
      },
      {
        content: item.stock.totalValue,
        align: 'number',
        type: 'richNumber',
        fractionDigits: 2
      }
    ];
    return newTranslatedList;
  });

  setNewData({ ...stateDaata, collunms: translatedData });
};
