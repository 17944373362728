export function setProviders(data) {
    return {
        type: 'SET_PROVIDERS_DATA',
        data,
    };
}

export function createProviders(data) {
    return {
        type: 'CREATE_PROVIDERS_DATA',
        data,
    };
}