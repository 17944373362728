import React from 'react';

import Helmet from 'react-helmet';

function TitleHead({ title }) {
  const headTitle = `${title} | Bloom`;

  return (
    <Helmet>
      <title>{headTitle}</title>
    </Helmet>
  );
}

export default TitleHead;
