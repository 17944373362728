/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, Suspense } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';

import PaperComponent from 'components/PaperComponent';
import { ShortenStringTooltip } from 'utils/shortenString';

import AssertUploadDialog from './assertUploadDialog';
import multiFilesProcessing from './multipleFilesProcessing';
import {
  DialogContentWrapper,
  ButtonWrapper,
  FileListName,
  FileListStatus,
  StyledButton
} from './style';

function MultipleUploadDialog({
  openModal,
  handleClose,
  multipleUploadFiles,
  resetMultipleFilesRef,
  currentUploadProviderId,
  quoteDate,
  expirationDate,
  clearInput,
  setRefreshFetch
}) {
  const { multipleUpload } = openModal;
  const [filesList, setFilesList] = useState([]);
  const [filesListChanged, setFilesListChanged] = useState(filesList.values());

  const [assertButtonStatus, setAssertButtonStatus] = useState(true);
  const [assertUserDialog, setAssertUserDialog] = useState(false);

  const handleCloseAssertDialog = () => {
    setAssertUserDialog(false);

    setRefreshFetch && setRefreshFetch(Math.random());
  };

  const stopUploadProcess = () => {
    setFilesList([]);
    setFilesListChanged(filesList.values());
    setAssertUserDialog(false);
    setAssertButtonStatus(true);
    handleClose();
  };

  const handleInternalClose = () => {
    if (assertButtonStatus) {
      setAssertUserDialog(true);
      return;
    }
    resetMultipleFilesRef();
    stopUploadProcess();
  };

  const handleAssertButton = () => {
    if (assertButtonStatus) {
      setAssertUserDialog(true);
      return;
    }
    resetMultipleFilesRef && resetMultipleFilesRef();
    clearInput && clearInput();
    stopUploadProcess();
  };

  useEffect(() => {
    multiFilesProcessing(
      multipleUploadFiles,
      setFilesList,
      setFilesListChanged,
      setAssertButtonStatus,
      resetMultipleFilesRef,
      currentUploadProviderId,
      quoteDate,
      expirationDate
    );
  }, [multipleUploadFiles]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      style={{ width: '470px' }}
      open={multipleUpload}
      onClose={handleInternalClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal prioritySkusDialog"
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <Typography>ENVIO MÚLTIPLO DE ARQUIVOS</Typography>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <DialogContentWrapper>
          {filesList.map(({ name, status }) => (
            <div>
              <FileListName>
                <ShortenStringTooltip string={name} length={30} />
              </FileListName>

              <FileListStatus color={status?.color}>
                {status?.name}
              </FileListStatus>
            </div>
          ))}

          <ButtonWrapper>
            <StyledButton
              onClick={handleAssertButton}
              disableButton={assertButtonStatus}
            >
              ok
            </StyledButton>
          </ButtonWrapper>
        </DialogContentWrapper>

        <Suspense fallback={<span />}>
          <AssertUploadDialog
            openModal={assertUserDialog}
            handleClose={handleCloseAssertDialog}
            onClose={() => {}}
            stopUploadProcess={stopUploadProcess}
          />
        </Suspense>
      </DialogContent>
    </Dialog>
  );
}

export default MultipleUploadDialog;
