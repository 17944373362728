import styled from "styled-components";

export const DeleteTagContainer = styled.div`
  background-color: white;
  width: 304px;
  height: 156px;
  display: flex;  
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  div {
    button:first-child {
      border: 1px solid #5062F0;
      background-color: transparent;
      padding: 12px;
      border-radius: 12px;
      color: #5062F0;
      margin-right: 12px;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(0.95);
      }
    }

    button:last-child {
      border: 1px solid #5062F0;
      background-color: #5062F0;
      padding: 12px;
      border-radius: 12px;
      color: white;
      transition: filter 0.2s;

      &:hover {
        filter: brightness(1);
      }
    }
  }
`
export const DeleteTagTitle = styled.p`
  color: #333541;
  font-size: 16px;
  font-weight: 400;
`
export const DeleteTagSubTitle = styled.p`
  color: #A0A1A7;
  font-size: 12px;
  font-weight: 400;
`