const skuLocalize = {
  'there are mandatory keys missing from request':
    'there are mandatory keys missing from request',
  'need to send an array with at least 2 products':
    'need to send an array with at least 2 products',
  "it's necessary that some product be the reference":
    "it's necessary that some product be the reference",
  'need to send restaurantId when flag isGroup is false':
    'need to send restaurantId when flag isGroup is false',
  'need to send a group or originId': 'need to send a group or originId',
  'not found results according to these parameters':
    'not found results according to these parameters',
  'need to send a origin ID': 'need to send a origin ID',
  'not found product with id ${id}': 'not found product',
  'there are mandatory keys missing from request':
    'there are mandatory keys missing from request',
  'product with sku need periodicity and storage location':
    'product with sku need periodicity and storage location',
  'need to send id of product': 'need to send id of product',
  'there are mandatory keys missing from request':
    'there are mandatory keys missing from request',
  'The spreadsheet is not in the correct format to be read. Columns that are necessary for the import to be successful may be missing.':
    'The spreadsheet is not in the correct format to be read. Columns that are necessary for the import to be successful may be missing.',
  'Submitting a spreadsheet from originId ${controlInfo[0].originId} and in query parameter we received originId ${originId}.':
    'Submitting a spreadsheet from originId ${controlInfo[0].originId} and in query parameter we received originId',
  'Some required information in product is missing':
    'Some required information in product is missing',
  'spreadsheet successfully imported': 'spreadsheet successfully imported',
  'need to send id of product': 'need to send id of product',
  'not found results according to these parameters':
    'not found results according to these parameters',
  'need to send originId in query parameters':
    'need to send originId in query parameters',
  'not found results according to these parameters':
    'not found results according to these parameters',
  'need to send a origin ID': 'need to send a origin ID',
  'need to send skuId in route params': 'need to send skuId in route params',
  'need to send id in params': 'need to send id in params',
  'need to send providerId': 'need to send providerId',
  'providerId is required': 'providerId is required',
  'You cannot update this information': 'You cannot update this information',
  'need to send a provider to update': 'need to send a provider to update',
  'there are mandatory keys missing in product':
    'there are mandatory keys missing in product',
  'already exists a sku in platform with this description':
    'already exists a sku in platform with this description',
  'need to send skuBloomId in route params':
    'need to send skuBloomId in route params',
  'need to send an array with at least 2 skus':
    'need to send an array with at least 2 skus',
  "it's necessary that some sku be the reference":
    "it's necessary that some sku be the reference",
  'need to send skuBloomId': 'need to send skuBloomId ',
  'Need send query parameter url': 'Need send query parameter url',
  'need to send at least one company or restaurantId to complete request':
    'need to send at least one company or restaurantId to complete request',
  'need to send at least one restaurant to complete request':
    'need to send at least one restaurant to complete request',
  'You cannot update this information': 'You cannot update this information',
  'need to send id and status': 'need to send id and status',
  'invalid status, valids status are REFUSED, ASSOCIATED and APPROVED':
    'invalid status, valids status are REFUSED, ASSOCIATED and APPROVED',
  'There is already a product with this description':
    'There is already a product with this description',
  'already exists a sku in platform with this description':
    'Already exists a sku in platform with this description',
    'Unit of measurement not allowed.': 'Unit of measurement not allowed.'
};

export default skuLocalize;
