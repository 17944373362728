/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';

import { useMediaQuery } from '@material-ui/core';

import { completeNumberWithDecimal } from 'utils/completeNumberDecimal';

import {
  InputManufactureWrapper,
  InputManufactureInput,
  InputManufactureInputContainer
} from '../styles';

const parseValue = (
  value,
  { hasOperator, valueOperator, decimals } = {
    hasOperator: false,
    valueOperator: 0,
  }
) => {
  const operator = hasOperator && valueOperator ? value * valueOperator : value;
  const newValue = completeNumberWithDecimal(parseInt(operator), {
    decimalPlaces: decimals
  });

  return parseFloat(newValue.replace(',', '.')).toFixed(decimals);
};

function InputNumber({
  id,
  initialValue,
  unity,
  onKeyDown,
  disabled,
  setUpdateValue,
  updateValue,
  handleChangeDebounce,
  modalStates,
  setModalStates,
  updateQuantityInitialValue,
  type,
  handleValue = () => {},
  onBlur,
  content,
  fontSize,
  fontWeight,
  width,
  height,
  rootPage,
  referenceItem,
  validUnit
}) {
  initialValue = initialValue || 0;
  const initialValueNull = initialValue === 'null';
  const [inputValue, setInputValue] = useState(
    initialValueNull
      ? ''
      : initialValue < 0.001 
        ? initialValue 
        : parseValue(initialValue, {
            hasOperator: true,
            valueOperator: 1000,
            decimals: 3
          })
  );
  const [debounceTimeList, setDebounceTimeList] = useState([]);
  const isPhone = useMediaQuery('(max-width: 821px)');
  const currentValue = isPhone 
    ? inputValue 
    : validUnit ? parseInt(parseFloat(inputValue)) : parseFloat(inputValue).toLocaleString('pt-br', {
        minimumFractionDigits: inputValue < 0.001 && inputValue !== "0.000" ? 6 : validUnit ? 0 : 3
      })

  useEffect(() => {
    setInputValue(
      initialValueNull
        ? ''
        : initialValue < 0.001 
          ? initialValue 
          : parseValue(initialValue, {
              hasOperator: true,
              valueOperator: 1000,
              decimals: 3
            })
    );
  }, [initialValue, updateQuantityInitialValue]);

  const updateValues = (e) => {
    const currentInputValue = typeof e.target.value === 'string' ? e.target.value?.replace(/\./g, '')?.replace(',', '.') : e.target.value
    if (!updateValue && currentInputValue === inputValue) return;

    handleValue(inputValue || initialValue);
    setUpdateValue(false);
  };

  const handleInputValue = (value) => {
    if (isNaN(parseFloat(value))) return;

    const newValue = completeNumberWithDecimal(
      parseInt(value.replace('.', ''))
    );

    const parsedValue = validUnit ? parseInt(parseFloat(newValue.replace(',', ''))) : parseFloat(newValue.replace(',', '.')).toFixed(3);

    setInputValue(parsedValue);
    setUpdateValue && setUpdateValue(inputValue !== value);

    if (handleChangeDebounce) {
      debounceTimeList.forEach((el) => clearTimeout(el));

      if (value === inputValue) return;

      const newTimer = setTimeout(() => {
        handleValue(parsedValue);
      }, 200);

      setDebounceTimeList([...debounceTimeList, newTimer]);
    }
  };

  const handleOnBlur = (e) => {
    const { target: { value } = {} } = e;
    const isTransformationCard = referenceItem?.type === 'TRANSFORMATION';
    const currentValueValidation =
      typeof value === 'string'
        ? value?.replace(/\./g, '')?.replace(',', '.')
        : value;

    if (updateValue) {
      if (!parseFloat(currentValueValidation) && !isTransformationCard) {
        setModalStates({
          ...modalStates,
          modalInvalidQuantity: true
        });

        setTimeout(() => {
          setInputValue(parseFloat(initialValue).toFixed(3));
          setUpdateValue(false);
        }, 500);
        return;
      }
    }

    onBlur &&
      onBlur(
        content._id,
        content?.itemIndex,
        Number(parseFloat(value.replace(',', '.')).toFixed(3)),
        content?.discardReason,
        content?.discardReason &&
          Number(parseFloat(value.replace(',', '.')).toFixed(3)),
        content?.item
      );

    if (setUpdateValue) {
      updateValues(e);
    } else {
      handleValue(inputValue);
    }
  };

  return (
    <InputManufactureWrapper>
      <InputManufactureInputContainer>
        <InputManufactureInput
          id={id}
          className="quantity-modal"
          type={isPhone ? "number" : "text"}
          onChange={(e) => {
            handleInputValue(e.target.value.replace(/\D/g, ''));
          }}
          value={
            Number.isNaN(parseFloat(inputValue))
              ? ''
              : currentValue
          }
          pattern="[0-9]*"
          onFocus={(e) => {
            e.currentTarget.select();
          }}
          align="right"
          onBlur={(e) => {
            const formattedInitialValue =
              typeof initialValue === 'string'
                ? parseFloat(initialValue?.replace(',', '.')).toFixed(3)
                : parseFloat(initialValue).toFixed(3);
            const formattedValue = parseFloat(
              e?.target?.value?.replace(',', '.')
            ).toFixed(3);

            if (
              !handleChangeDebounce &&
              formattedInitialValue !== formattedValue
            ) {
              handleOnBlur(e);
            }
          }}
          onKeyDown={onKeyDown && onKeyDown}
          disabled={disabled}
          fontSize={fontSize}
          fontWeight={fontWeight}
          width={width}
          height={height}
        />
        <span
          className="bold"
          style={{
            position: 'relative',
            left: '9px',
            fontSize: 16
          }}
        >
          {unity}
        </span>
      </InputManufactureInputContainer>
    </InputManufactureWrapper>
  );
}

export default InputNumber;
