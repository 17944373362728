import React, { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  TextField,
  Typography,
  Paper
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import Draggable from 'react-draggable';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';

import Button from '../../Button/button';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-packagetype"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function RegisterPackageType({
  open,
  dialogRegisterPackageTypeSettings,
  setDialogRegisterPackageTypeSettings,
  multipleSkuData,
  setMultipleSkuData,
  selectedItems,
  isMultipleSku,
  setSelectedItems,
  jsonBody,
  setJsonBody,
  skuDefaultDescription,
  setSkuDefaultDescription,
  isModal,
  skuModal,
  updateOptions,
  setUpdateOptions,
  userInfo
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [loading, setLoading] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastSuccess, setToastSuccess] = useState(false);
  const [packageDescription, setPackageDescription] = useState('');

  const handleClose = () => {
    setPackageDescription('');
    setDialogRegisterPackageTypeSettings({
      ...dialogRegisterPackageTypeSettings,
      open: false
    });
  };

  const handleSubmit = () => {
    setLoading(true);

    const body = {
      description: packageDescription,
      user: userInfo.user
    };

    axios
      .post(`${environments.catalog}/typePackages/`, body)
      .then((response) => {
        setLoading(false);
        setToastSuccess(true);

        if (isMultipleSku && isModal) {
          setMultipleSkuData(
            multipleSkuData.map((sku, index) => {
              if (index === dialogRegisterPackageTypeSettings.currentSku) {
                return {
                  ...sku,
                  typePackageId: response?.data?.id || '',
                  packageType: {
                    id: response?.data?.id || '',
                    description: packageDescription.toLocaleUpperCase() || ''
                  },
                  skuDefaultDescription: {
                    ...sku?.skuDefaultDescription,
                    packageType: packageDescription.toLocaleUpperCase() || ''
                  }
                };
              }
              return sku;
            })
          );
        } else if (!isMultipleSku && isModal) {
          setSelectedItems({
            ...selectedItems,
            packageType: {
              id: response?.data?.id,
              description: packageDescription.toLocaleUpperCase() || ''
            }
          });

          setJsonBody({
            ...jsonBody,
            sku: {
              ...jsonBody?.sku,
              typePackageId: response?.data?.id,
              packageType: {
                id: response?.data?.id,
                description: packageDescription.toLocaleUpperCase() || ''
              }
            }
          });

          setSkuDefaultDescription({
            ...skuDefaultDescription,
            packageType: packageDescription.toLocaleUpperCase() || ''
          });
        }

        skuModal && setUpdateOptions({ ...updateOptions, packageTypes: true });

        handleClose();
      })
      .catch((error) => {
        setLoading(false);
        setToastError(true);
        handleClose();
      });
  };

  const closeToast = () => {
    setToastError(false);
    setToastSuccess(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() =>
          setDialogRegisterPackageTypeSettings({
            ...dialogRegisterPackageTypeSettings,
            open: false
          })
        }
        className="defaultModal mediumModal minWidth customZIndex"
        PaperComponent={PaperComponent}
        maxWidth="lg"
        aria-labelledby="draggable-dialog-title-packagetype"
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          id="draggable-dialog-title-packagetype"
          fullWidth
        >
          <Typography>CADASTRO DE TIPO DE EMBALAGEM</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setDialogRegisterPackageTypeSettings({
                ...dialogRegisterPackageTypeSettings,
                open: false
              });
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent pt20 pb10 bgWhite">
          <Grid className="m0" container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={12} className="pl0">
              <TextField
                size="small"
                label="Tipo de Embalagem"
                name="description"
                onChange={(e) => {
                  setPackageDescription(e.target.value || '');
                }}
                variant="outlined"
                value={packageDescription}
                fullWidth
                autoFocus
                required="required"
                inputProps={{ maxLength: 50 }}
                className="textFieldSmall"
                placeholder="Digite aqui..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>

          {loading && <LinearProgress variant="query" />}
        </DialogContent>

        <DialogActions className="justifyEnd ph16 pb10 pt10">
          <Button
            className="defaultButton submitButton"
            design="contained"
            label={!loading ? 'Salvar' : 'Salvando...'}
            style={{ outline: 0, margin: '0 0 0 5px' }}
            onClick={() => handleSubmit()}
          />
        </DialogActions>
      </Dialog>

      <Snackbar
        open={toastSuccess}
        autoHideDuration={2000}
        onClose={closeToast}
      >
        <Alert onClose={closeToast} severity="success">
          Tipo de embalagem criado com sucesso.
        </Alert>
      </Snackbar>

      <Snackbar open={toastError} autoHideDuration={2000} onClose={closeToast}>
        <Alert onClose={closeToast} severity="error">
          Não foi possível criar o tipo de embalagem.
        </Alert>
      </Snackbar>
    </>
  );
}

export default RegisterPackageType;
