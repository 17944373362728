import styled from "styled-components";

export const EditTagContainer = styled.div`
  width: 221px;
`

export const ValuesDiv = styled.div`
  background-color: #F2F2F4;
  padding: 4px 8px;
  margin: 8px 0;
  p {
    color: #666770;
    font-size: 9px;
    font-weight: 700;
  }
`

export const TagsValues = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
  border-bottom: 1px solid #D2D3D7;
  p {
    margin: 8px 0;
    font-size: 12px;
    font-weight: 400;
    color: #0F1225;
  }

`
