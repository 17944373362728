import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup
} from '@material-ui/core';

import RadioButton from './radioButton';

export default function RadioButtonsGroup(props) {
  const { properties, options, option, setOption } = props;
  const handleChange = (event) => {
    setOption(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      {properties.title ? (
        <FormLabel component="legend">{properties.title}</FormLabel>
      ) : null}
      <RadioGroup
        row={properties.row}
        aria-label={properties.name ? properties.name : null}
        name={properties.name ? properties.name : null}
        value={option}
        onChange={handleChange}
      >
        {options.length
          ? options.map((option) => (
              <FormControlLabel
                id={option.id}
                className={properties.radioLabelClassName}
                value={option.value}
                control={
                  <RadioButton
                    size={properties.radioSize}
                    style={{ padding: 0, margin: '0 5px' }}
                  />
                }
                label={option.label}
                labelPlacement={
                  properties.labelPlacement ? properties.labelPlacement : 'end'
                }
                style={{ color: '#FFF' }}
              />
            ))
          : null}
      </RadioGroup>
    </FormControl>
  );
}