import styled from 'styled-components';

export const InfoContainer = styled.div`
    position: relative;
    height: ${({height}) => height};
    background-color: #2A3942; 
    border-radius: 10px;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    overflow: hidden;
    transition: all 250ms ease-in-out;

    &:hover {
        transform: scale(1.050, 1.050);
    }
`;

export const CardTitle = styled.div`
    font-family: 'Lato';
    font-weight: bold; 
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #000000;
    padding: 9px 12px 7px;
    line-height: 1;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    border-radius: 10px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

    span {
        text-shadow: -1px 0 transparent, 0 1px transparent, 1px 0 transparent, 0 -1px transparent;
    }
`;

export const CardSubtitleInfo = styled.div`
    display: flex;
    align-items: flex-end;

    span {
        margin: 0 6px 2px 0;
        font-size: 12px;
        text-transform: uppercase;
        text-shadow: -1px 0 transparent, 0 1px transparent, 1px 0 transparent, 0 -1px transparent;
    } 

    p {
        font-family: 'Lato';
        font-weight: bold; 
        font-size: 20px;
        color: #FFFFFF;
        margin-bottom: 0;
        line-height: 1;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    }
`;

export const InfoWrap = styled.div`
    width: ${({size}) => size ? size : '24.5%'};
    position: relative;
    height: 270px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    transition: all 250ms ease-in-out;
    &:hover {
        height: ${({dinamicHeight}) => dinamicHeight ? 'auto' : '250px'};
        z-index: 20;
    }
`;
