import React, { useState } from 'react';

import MoreInfoArrow from 'images/icons/arrows/arrowDrillDown.svg';

import {
  BoardOptions,
  OptionsContainer,
  OptionsWrapp,
  OptionName,
  ImageIcon
} from './index.style';

function BlueSelectBox({ options, option, handleOption }) {
  const [toggleOptions, setToggleOptions] = useState(true);
  const { name } = option;

  const handleToggleOptions = () => {
    setToggleOptions(!toggleOptions);
  };

  const handleClickOption = (index) => {
    setToggleOptions(true);
    handleOption(index);
  };

  return (
    <OptionsWrapp>
      <BoardOptions onClick={handleToggleOptions}>
        <span>{name}</span>
        <ImageIcon open={toggleOptions} src={MoreInfoArrow} />
      </BoardOptions>

      <OptionsContainer hidden={toggleOptions}>
        {options.map(({ name }, index) => (
          <OptionName onClick={() => handleClickOption(index)}>
            {name}
          </OptionName>
        ))}
      </OptionsContainer>
    </OptionsWrapp>
  );
}

export default BlueSelectBox;
