const skuLocalize = {
  'there are mandatory keys missing from request':
    'Há chaves obrigatórias faltando na solicitação',
  'need to send an array with at least 2 products':
    'Precisa enviar pelo menos 2 produtos',
  "it's necessary that some product be the reference":
    'É necessário que algum produto seja referência',
  'need to send restaurantId when flag isGroup is false':
    'Precisa enviar um restaurante',
  'need to send a group or originId': 'Precisa enviar um grupo',
  'not found results according to these parameters':
    'Não encontrei resultados de acordo com esses parâmetros',
  'need to send a origin ID': 'Precisa enviar um ID de origem',
  'not found product with id ${id}': 'Produto não encontrado',
  'product with sku need periodicity and storage location':
    'Produto com sku precisa de contagem e local de estoque',
  'need to send id of product': 'Precisa enviar o id do produto',
  'The spreadsheet is not in the correct format to be read. Columns that are necessary for the import to be successful may be missing.':
    'A planilha não está no formato correto para ser lida. As colunas necessárias para que a importação seja bem-sucedida podem estar faltando.',
  'Submitting a spreadsheet from originId ${controlInfo[0].originId} and in query parameter we received originId ${originId}.':
    'Enviando uma planilha de originId e no parâmetro de consulta recebemos originId',
  'Some required information in product is missing':
    'Algumas informações obrigatórias no produto estão faltando',
  'spreadsheet successfully imported': 'Planilha importada com sucesso',
  'need to send originId in query parameters':
    'Precisa enviar originId nos parâmetros de consulta',

  'need to send skuId in route params':
    'Precisa enviar skuId em parâmetros de rota',
  'need to send id in params': 'Precisa enviar id em params',
  'need to send providerId': 'Precisa enviar o ProvideId',
  'providerId is required': 'O ID do provedor é obrigatório',
  'You cannot update this information':
    'Você não pode atualizar essas informações',
  'need to send a provider to update':
    'Preciso enviar um provedor para atualizar',
  'there are mandatory keys missing in product':
    'Há chaves obrigatórias faltando no produto',
  'already exists a sku in platform with this description':
    'Já existe um sku na plataforma com esta descrição',
  'need to send skuBloomId in route params':
    'Precisa enviar skuBloomId nos parâmetros de rota',
  'need to send an array with at least 2 skus':
    'Precisa enviar um array com pelo menos 2 skus',
  "it's necessary that some sku be the reference":
    'É necessário que algum sku seja a referência',
  'need to send skuBloomId': 'Precisa enviar skuBloomId',
  'Need send query parameter url':
    'É necessário enviar o URL do parâmetro de consulta',
  'need to send at least one company or restaurantId to complete request':
    'Precisa enviar pelo menos um ID de empresa ou restaurante para concluir a solicitação',
  'need to send at least one restaurant to complete request':
    'Precisa enviar pelo menos um restaurante para concluir a solicitação',
  'need to send id and status': 'Precisa enviar id e status',
  'invalid status, valids status are REFUSED, ASSOCIATED and APPROVED':
    'Status inválido, status válido são RECUSADOS, ASSOCIADOS e APROVADOS',
  'There is already a product with this description':
    'Já existe um produto com esta descrição',
  'already exists a product in platform with this description':
   'Já existe um produto na plataforma com esta descrição',
  'Unit of measurement not allowed.': 
   'Unidade de medida não compatível'
};

export default skuLocalize;
