import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  margin-right: ${({ marginRight }) => marginRight || '35px'};
  padding-top: ${({ multiple }) => (multiple ? '15px' : '0')};

  label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: ${({ multiple }) => (multiple ? '12px' : '15px')};
    color: #000;
  }

  input {
    font-family: 'Lato';
    font-weight: normal;
    font-size: 12px;

    &::placeholder {
      font-style: italic;
    }
  }

  ${({ multiple }) =>
    multiple &&
    css`
      .MuiFormControl-root {
        .MuiInputBase-root {
          margin-top: 0;
          padding-top: 0 !important;
          min-height: 28px;
        }
      }
    `}

  .MuiInputBase-input {
    height: ${({ inputHeight }) => inputHeight && inputHeight};
    margin-top: ${({ marginTop }) => marginTop && marginTop};
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;

  .MuiIconButton-root {
    padding: 3px 4px 0 0px;

    &:nth-of-type(2) {
      margin-left: 5px;
    }
  }

  svg {
    height: 19px;
    width: 19px;
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  position: absolute;
  top: -4px;
  right: -15px;

  span {
    font-family: 'Lato';
    font-style: normal;
    font-size: 12px;
  }

  span:first-of-type {
    margin: -3px !important;
  }

  img {
    margin-left: 2px;
  }

  label {
    font-family: 'Lato';
    font-style: normal;
    font-size: 15px;
  }
`;

export const TextFieldContainer = styled.div`
  position: relative;
`;

export const StyledCheckBox = styled(Checkbox)`
  /* position: absolute; */
`;

export const StyledTypography = styled(Typography)`
  font-size: 12px;
  font-family: 'Lato';
  width: calc(100% + 32px);
  margin: -6px -16px;
  padding: 11px;
  background-color: ${(props) => (!props.selected ? 'transparent' : '#d6d8d9')};
`;
