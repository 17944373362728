/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';

import { Grid, Radio, TextField, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

import CloseWhiteIcon from 'images/icons/signals/closesAndCancels/closeModal.svg';
import Environment from 'utils/environments';

import {
  PackageMobilePackageWrapper,
  Content,
  ContentHead,
  FormControlWrapper
} from './index.style';

function CountOrdenationDialog({
  reference,
  getId,
  setupId,
  countDate,
  onClose,
  currentData,
  assortment,
  countID,
  selectItem
}) {
  const [radioValue, setRadioValue] = useState('ALPHABETICAL');
  const [assortmentsOptions, setAssortmentsOptions] = useState([]);
  const [assortmentDate, setAssortmentDate] = useState({
    date: assortment,
    user: currentData?.user
  });
  const environments = Environment(process.env.REACT_APP_ENV);
  const [redirectURl, setRedirectURl] = useState();

  const query = new URLSearchParams(window.location.search);
  const assortmentType = query.get('assortmentType');

  const handleChange = (event) => {
    const { target: { value } = {} } = event;
    setRadioValue(value);

    if (!value === 'ALPHABETICAL') return;
    setRedirectURl(
      `/inventario/contagem-diaria/${getId}/${selectItem}/?countId=${countID}&countDate=${countDate}&categoryDescription=&assortmentDate=${value?.date}&selectItem=${selectItem}&assortmentType=ALPHABETICAL`
    );
  };
  
  useEffect(() => {
    if (!assortment || assortmentType === 'ALPHABETICAL') return;

    setRadioValue('PHYSICAL');
  }, [countDate, currentData, assortmentsOptions]);

  useEffect(() => {
    axios
      .get(
        `${environments.catalog}/inventories/assortment?originId=${getId}&setupId=${setupId}&countDate=${countDate}`
      )
      .then((response) => {
        setAssortmentsOptions(response.data);

        const findSelectedAssortment = response?.data?.find(
          (res) => res.date === assortment
        );
        
        setAssortmentDate(findSelectedAssortment);
      })
      .catch((error) => {
        console.log(error);
        setAssortmentsOptions([]);
      });
  }, [getId, setupId, countDate]);

  return (
    <PackageMobilePackageWrapper>
      <Content ref={reference}>
        <ContentHead>
          <div>
            Últimas Contagens
          </div>

          <div>
            <img
              src={CloseWhiteIcon}
              alt="close ordenation dialog"
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </ContentHead>

        <FormControlWrapper>
          <FormControl>
            <RadioGroup
              style={{
                fontSize: '12px',
                fontFamily: 'Lato',
                fontWeight: 'bold'
              }}
              aria-label="gender"
              name="gender1"
              value={radioValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="ALPHABETICAL"
                control={<Radio color="primary" />}
                label="Alfabética"
              />
              <FormControlLabel
                value="PHYSICAL"
                control={<Radio color="primary" />}
                label="Últimas contagens"
              />
            </RadioGroup>
          </FormControl>

          <Autocomplete
            fullWidth
            size="small"
            getOptionSelected={(option, value) => option?.date === value?.date}
            getOptionLabel={(option) =>
              `${moment(option.date).format('DD/MM/YYYY')}    ${option.user}`
            }
            options={assortmentsOptions}
            value={assortmentDate}
            onChange={(e, value) => {
              setAssortmentDate(value);
              setRadioValue('PHYSICAL');
              if (!value?.date) return;
              setRedirectURl(
                `/inventario/contagem-diaria/${getId}/${selectItem}/?countId=${countID}&countDate=${countDate}&categoryDescription=&assortmentDate=${value?.date}&selectItem=${selectItem}`
              );
            }}
            className="autocompleteSmall"
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                id={params.id}
                name="typeFilter"
                required
                placeholder="Digite aqui..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            renderOption={(params) => (
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Typography
                    style={{
                      fontSize: '10px',
                      fontFamily: 'Lato',
                      fontWeight: 'bold'
                    }}
                  >
                    {moment(params.date).format('DD/MM/YYYY')}
                  </Typography>
                </Grid>

                <Grid item xs={7}>
                  <Typography
                    style={{
                      fontSize: '10px',
                      fontFamily: 'Lato',
                      fontStyle: 'italic',
                      color: '#666770'
                    }}
                  >
                    {params.user}
                  </Typography>
                </Grid>
              </Grid>
            )}
          />
        </FormControlWrapper>
      </Content>

      {!!redirectURl && <Redirect to={redirectURl} />}
    </PackageMobilePackageWrapper>
  );
}

export default CountOrdenationDialog;
