import styled from 'styled-components';

export const MainContainer = styled.div``;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2px;
`;

export const ProductInfosContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-left: 10px;
`;

export const InfoBox = styled.div`
  width: 25px;
  display: flex;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  span {
    font-size: 12px;
    color: rgb(24, 144, 255);
  }
`;
