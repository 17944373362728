/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useEffect } from 'react';

import {
  LinearProgress,
  Tooltip
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import IconReceipt from 'images/icons/downloadUpload/receipt.svg'
import IconProduction from 'images/icons/objects/bells/production.svg'
import IconCount from 'images/icons/objects/boxesAndPackages/count.svg'
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import * as S from './styles';

const environments = Environment(process.env.REACT_APP_ENV);

function ClosureByRestaurants({ userInfo }) {
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);

  const companies = userInfo.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat();

  const idRestaurants = companies?.map((item) => item.id);
  const totalSales =
    restaurants.length &&
    restaurants?.reduce((item, sale) => item + sale.sales, 0);
  const totalSalesYstd =
    restaurants.length &&
    restaurants?.reduce((item, sale) => item + sale.yesterdaySales, 0);
  const totalMetaValue = restaurants.reduce((total, sum) => 
    total + sum?.metaLoja?.value, 0
  )
  const totalMetaValueYesterday = restaurants.reduce((total, sum) => 
    total + sum?.metaLojaYesterday?.value, 0
  )

  const totalPercentage = (totalSales / totalMetaValue) * 100
  const totalPercentageYesterday = (totalSalesYstd / totalMetaValueYesterday) * 100

  const dateFormatted = moment().isBefore(moment({ hour: 4})) 
    ? moment().subtract(1, 'day').format('YYYY-MM-DD') 
    : moment().format('YYYY-MM-DD');
  const dateFormattedWeek = moment().subtract(7, 'days').format('YYYY-MM-DD')

  const getClosureRestaurants = useCallback(() => {
    axios
      .get(
        `${environments.sales}/sales/${idRestaurants}/total?startDate=${dateFormatted}&endDate=${dateFormatted}`
      )
      .then((response) => {
        setRestaurants(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [userInfo.companiesActive.id]);

  useEffect(() => {
    getClosureRestaurants();
  }, []);

  const percentColor = (value, totalValue, sales, meta) => {
    const Number = parseFloat(value).toFixed(2)
    
    if (!totalValue) {
      if (Number < 0) {
        return (
          <p
            style={{
             color: '#F53D4C',
             fontWeight: '700',
             margin: '0'
            }}
          >
            {Number.toLocaleString('pt-br', {
              maximumFractionDigits: 2
            })}%
          </p>
        );
      }
      if (Number > 0) {
        return (
          <p
            style={{
              color: '#006608',
              fontWeight: '700',
              margin: '0'
            }}
          >
            +{Number.toLocaleString('pt-br', {
                maximumFractionDigits: 2
              })}%
          </p>
        );
      }
      return `0,00%`;
    } 
    const difference = Number - 100;
    const resNegative = difference.toLocaleString('pt-br', {
      maximumFractionDigits: 2
    });

    const numberPositive = String(difference).replace('-', '');
    const resPositive = numberPositive.toLocaleString('pt-br', {
      maximumFractionDigits: 2
    });

    const diffPositive = resPositive?.substring(0, 5);
    const diffFinal = resNegative?.substring(0, 5);

    if (sales < meta) {
      return (
        <p
          style={{
          color: '#F53D4C',
          fontWeight: '700',
          margin: '0'
          }}
        >
          {diffFinal}%
        </p>
      );
    }

    if (sales > meta) {
      return (
        <p
          style={{
            color: '#006608',
            fontWeight: '700',
            margin: '0'
          }}
        >
          +{diffPositive}%
        </p>
      );
    }

    return `0,00%`
  }

  return (
    <Layout>
      <GlobalStyle />
      <TitleHead title="Fechamento por Restaurante" />

      <Container className="closureAndSuggestionsPage">
        <S.TitleContent>
          <PageTitleAndFilter title="Fechamento por Restaurante"/>
        </S.TitleContent>

        <S.ResponsiveContent>
          <table
            width="100%"
            style={{ minWidth: '850px' }}
            className="desktop cardsMainTable widthTablePersonal w98 responsiveFull"
          >
            <tr className="headerTitle">
              <S.Th width="16%" className="subtitle bgBlue">
                RESTAURANTE
              </S.Th>

              <S.ThBorder
                width="4%"
                className="subtitle center borderLeft bgBlue "
              >
                <Tooltip
                  placement="bottom"
                  title="Contagem"
                >
                  <img src={IconCount} alt={IconCount} />
                </Tooltip>
              </S.ThBorder>

              <S.ThBorder
                width="4%"
                className="subtitle center borderLeft bgBlue "
              >
                <Tooltip
                  placement="bottom"
                  title="Produção"
                >
                  <img src={IconProduction} alt={IconProduction} />
                </Tooltip>
              </S.ThBorder>

              <S.ThBorder
                width="4%"
                className="subtitle center borderLeft bgBlue "
              >
                <Tooltip
                  placement="bottom"
                  title="Recebimento"
                >
                  <img src={IconReceipt} alt={IconReceipt} />
                </Tooltip>
              </S.ThBorder>

              <S.ThBorder
                width="17%"
                className="subtitle right pr8 borderLeft bgBlue"
              >
                VENDAS HOJE
              </S.ThBorder>

              <S.ThBorder
                width="13%"
                className="subtitle right pr8 bgBlue "
              >
                META
              </S.ThBorder>

              <S.ThBorder width="17%" className="subtitle right borderLeft pr8">
                VENDAS ONTEM
              </S.ThBorder>

              <S.ThBorder
                width="13%"
                className="subtitle right pr8 borderGray"
                style={{borderLeft: 'solid 1px #666770'}}
              >
                META
              </S.ThBorder>
            </tr>
            
            {loading && (
              <tr>
                <th colSpan="8" style={{ margin: '10px', textAlign: 'center' }}>
                  <LinearProgress id="loading" variant="query" />
                </th>
              </tr>
            )}

            <td colSpan="8" style={{ padding: '0px' }}>
              <table
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
                style={{ background: '#f2f3fb' }}
              >
                {restaurants?.length ? (
                  restaurants.map((company) => {
                    const date = company?.emissionDate?.split('T')[0];
                    const wholeHour = company?.emissionDate?.split('T')[1];
                    const hour = wholeHour?.split(':', 2)[0];
                    const minute = wholeHour?.split(':', 2)[1];
                    const hourRestaurant =
                      hour && minute ? `${hour}:${minute}` : '-';

                    const dateYstd =
                      company?.yesterdayEmissionDate?.split('T')[0];
                    const wholeHourYstd =
                      company?.yesterdayEmissionDate?.split('T')[1];
                    const hourYstd = wholeHourYstd?.split(':', 2)[0];
                    const minuteYstd = wholeHourYstd?.split(':', 2)[1];
                    const hourRestaurantYstd =
                      hourYstd && minuteYstd
                        ? `${hourYstd}:${minuteYstd}`
                        : '-';

                    return (
                      <tr>
                        <S.Td width="16%" className="description p10 bgWhite fontColor">
                          {company?.companyName}
                        </S.Td>

                        <S.TdBorder
                          width="4%"
                          className="description p4 right bgWhite fontColor"
                        >
                          {company?.totalCount ?
                            <Link
                              className="td0"
                              target="_blank"
                              to={`/inventario/contagens/${company._id}?startAt=${dateFormatted}`}
                            >
                              {company?.totalCount.toLocaleString('pt-br', {
                              maximumFractionDigits: 2, minimumFractionDigits: 2
                              })}%
                            </Link>
                          : '-'}
                        </S.TdBorder>

                        <S.TdBorder
                          width="4%"
                          className="description p4 right bgWhite fontColor"
                        >
                          {company?.statusProduction ?                           
                            <Link
                              className="td0"
                              target="_blank"
                              to={`/producao/${company._id}?startAt=${dateFormatted}`}
                            >
                              {company?.statusProduction}
                            </Link>
                           : '-'}
                        </S.TdBorder>
                        
                        <S.TdBorder
                          width="4%"
                          className="description p4 right bgWhite fontColor"
                        >
                          {company?.totalOrders ? 
                            <Link
                              className="td0"
                              target="_blank"
                              to={`/recebimento/${company._id}?startAt=${dateFormattedWeek}&endAt=${dateFormatted}`}
                            >
                              {company?.totalOrders}
                            </Link> 
                          : '-'}
                        </S.TdBorder>

                        <S.TdBorder
                          width="10%"
                          className="description center bgWhite fontColor"
                        >
                          <div className="dFlex justifyBetween p10">
                            <span style={{ width: '70px' }}>
                              {company?.emissionDate
                                ? moment(date).format('L')
                                : '-'}
                            </span>

                            <span className="center" style={{ width: '40px' }}>
                              {hourRestaurant}
                            </span>
                          </div>
                        </S.TdBorder>

                        <S.TdBorder
                          width="7%"
                          className="description p10 right bgWhite fontColor"
                          style={
                            company?.sales === 0 ? { color: '#666770' } : {}
                          }
                        >
                          R${' '}
                          {company?.sales?.toLocaleString('pt-br', {
                            maximumFractionDigits: 2, minimumFractionDigits: 2
                          })}
                        </S.TdBorder>

                        <S.TdBorder
                          width="7%"
                          className="description p10 right fontColorMeta"
                          style={{ background: '#DADDF3'}}
                        >
                          {company?.metaLoja?.value ? 
                            `R$${company?.metaLoja?.value?.toLocaleString('pt-br', {
                              maximumFractionDigits: 2, minimumFractionDigits: 2
                            })}`
                           : '-'}
                        </S.TdBorder>

                        <S.TdBorder
                          width="6%"
                          className="description p10 right"
                          style={{ background: '#DADDF3'}}
                        >
                          {percentColor(company?.metaLoja?.percentage)}
                        </S.TdBorder>

                        <S.TdBorder
                          width="10%"
                          className="description center borderLeftSeparator"
                          style={{ background: '#f4f4f7', color: '#666770' }}
                        >
                          <div className="dFlex justifyBetween p10">
                            <span style={{ width: '70px' }}>
                              {company?.yesterdayEmissionDate
                                ? moment(dateYstd).format('L')
                                : '-'}
                            </span>

                            <span style={{ width: '40px' }}>
                              {hourRestaurantYstd}
                            </span>
                          </div>
                        </S.TdBorder>

                        <S.TdBorder
                          width="7%"
                          className="description p10 right"
                          style={{ background: '#f4f4f7', color: '#666770' }}
                        >
             
                          R${' '}
                          {company?.yesterdaySales?.toLocaleString('pt-br', {
                            maximumFractionDigits: 2, minimumFractionDigits: 2
                          })}
                        </S.TdBorder>
                                                
                        <S.TdBorder
                          width="7%"
                          className="description p10 right fontColorMeta"
                          style={{ background: '#d9dae4', color: '#666770'}}
                        >
                          {company?.metaLojaYesterday?.value ? 
                            `R$${(company?.metaLojaYesterday?.value || 0)?.toLocaleString('pt-br', {
                              maximumFractionDigits: 2, minimumFractionDigits: 2
                            })}`
                           : '-'}
                        </S.TdBorder>

                        <S.TdBorder
                          width="6%"
                          className="description p10 right"
                          style={{ background: '#d9dae4'}}
                        >
                          {percentColor(company?.metaLojaYesterday?.percentage)}
                        </S.TdBorder>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <th colSpan="1" className="m10 textAlignCenter">
                      Não há nenhum restaurante
                    </th>
                  </tr>
                )}
              </table>

              {restaurants?.length ? (
                <table
                  width="100%"
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                  style={{ borderTop: '2px solid #333541' }}
                >
                  <tr>
                    <td width="28%" className="description left p10 bold"
                      style={{ color: '##0F1225' }}
                    >
                      TOTAL
                    </td>

                    <td width="17%" className="description p10 right bold"
                      style={{ color: '#000319' }}
                    >
                      R${' '}
                      {totalSales &&
                        totalSales?.toLocaleString('pt-br', {
                          maximumFractionDigits: 2, minimumFractionDigits: 2
                        })}
                    </td>

                    <S.TdBorder
                      width="7%"
                      className="description p10 right bold borderLeft noBorderBottom"
                      style={{background: '#DADDF3' }}

                    >
                      R${' '}
                      {restaurants.reduce((total, sum) => 
                        total + sum?.metaLoja?.value, 0
                      ).toLocaleString('pt-br', {
                          maximumFractionDigits: 2, minimumFractionDigits: 2
                        })}
                    </S.TdBorder>

                    <S.TdBorder
                      width="6%"
                      className="description p10 right bold borderLeft noBorderBottom"
                      style={{ color: '#666770', background: '#DADDF3' }}
                      
                    >
                      {percentColor(totalPercentage, true, totalSales, totalMetaValue)}
                    </S.TdBorder>

                    <S.TdBorder
                      width="17%"
                      className="description p10 right bold borderLeftSeparator noBorderBottom"
                      style={{ color: '#666770', background: '#f4f4f7' }}
                    >
                      R${' '}
                      {totalSalesYstd &&
                        totalSalesYstd?.toLocaleString('pt-br', {
                          maximumFractionDigits: 2, minimumFractionDigits: 2
                        })}
                    </S.TdBorder>

                    <S.TdBorder
                      width="7%"
                      className="description p10 right bold borderLeftSeparator noBorderBottom"
                      style={{ color: '#666770', background: '#d9dae4' }}
                    >
                      R${' '}
                      {(restaurants.reduce((total, sum) => 
                        total + sum?.metaLojaYesterday?.value, 0
                      ) || 0).toLocaleString('pt-br', {
                          maximumFractionDigits: 2, minimumFractionDigits: 2
                        })}
                    </S.TdBorder>

                    <S.TdBorder
                      width="6%"
                      className="description p10 right bold borderLeftSeparator noBorderBottom"
                      style={{ background: '#d9dae4'}}
                    >
                      {percentColor(totalPercentageYesterday, true, totalSalesYstd, totalMetaValueYesterday)}
                    </S.TdBorder>
                  </tr>
                </table>
              ) : (
                ''
              )}
            </td>
          </table>

          <S.DescriptionContent>
            <S.ParagraphDescription>
              CONTAGEM: % de contagens finalizadas na data atual
            </S.ParagraphDescription>

            <S.ParagraphDescription>
              PRODUÇÃO: qtde de fichas finalizadas / qtde total de fichas em aberto
            </S.ParagraphDescription>
            
            <S.ParagraphDescription>
              RECEBIMENTO: qtde de pedidos em aberto (últimos 7 dias)
            </S.ParagraphDescription>
          </S.DescriptionContent>
        </S.ResponsiveContent>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(ClosureByRestaurants);
