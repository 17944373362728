import styled from 'styled-components'

export const RPDialogBody = styled.div`
  div:first-child {
    p:first-child {
      font-size: 9px;
    }
    p {
      padding: 2px 12px;
      font-size: 12px;
      color: #454A67;
    }
  }

    .dataInfos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      
      .dataInfosButtons {
          display: flex;
          justify-content: space-around;
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 134px;
            height: 24px;

            p {
              margin: 0;
            }
          }
        }
    }
    .RPvaluesInfos {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0;

      div:first-child {
        p {
          padding-bottom: 0;
          font-size: 9px;
        }
        div {
          margin-left: 12px;
          width: 63px;
          height: 20px;
          padding: 4px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          p {
            padding: 0;
          }
        }
      }

      div {
        p:first-child {
          
          font-size: 9px;
          font-weight: 700;
          text-align: right;
          padding-right: 0;
        }
        p:last-child {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

  
`