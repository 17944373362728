import styled, { css } from 'styled-components';

export const ArrowIcon = styled.img`
    width: ${({tinyArrow}) => tinyArrow ? '8px' : ' 11px;'};
    cursor: pointer;
    ${({ orderbyBinIcon }) => !orderbyBinIcon && css`
        transition: all 250ms ease-in;
    `};
    transform: ${({orderSet}) => orderSet ? 'none' : 'rotate(180deg)'};  
    fill: ${({tinyArrow}) => tinyArrow && '#77798B;'};
`;
