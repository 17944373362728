import styled, { css } from "styled-components"; 

export const CollapsedHead = styled.div`
    display: flex;
    padding: 0 8px 8px 8px;
`;

export const StoreContainer = styled.div`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    color: #5062F0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    text-underline-offset: 4px;
    text-decoration-line: underline;
    img { 
        margin-left: 5px;
    }
`;

export const ProductCategoryWrapper = styled.div`
    background: #DADDF3;
    border-radius: 2px;
    width: 144px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5062F0;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-right: 4px;
`;

export const ColapseItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 8px 8px 8px;
`;

export const QuotesWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    margin: 4px 0 8px 0;
    ${({paddinBottom}) => paddinBottom && css`
        margin-bottom: ${paddinBottom};
    `}
    div{
        font-style: normal;
        font-weight: 600;
        font-size: 9px;
        line-height: 12px;
        text-transform: uppercase;
        font-feature-settings: 'tnum' on, 'lnum' on;
        padding: 4px 4px 4px 8px;

        &:first-child {
            background: #F2F3F8;
            color: #454A67;
            width: ${({ width100 }) => width100 ? "100%" : "50%"};
        }

        &:nth-child(2) {
            background: #9296AC;
            color: #FFFFFF;
            width: 50%;
        }

    }
`;

export const ProviderRelation = styled.div`
    display: flex;
    padding: 0 8px 8px 8px;

    div {
        &:first-child {
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            text-transform: uppercase;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: #454A67;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
        }
        &:nth-child(2) {
            font-size: 12px;
            line-height: 16px;
            color: #5062F0;
            background: #DADDF3;
            border-radius: 2px;
            padding: 4px;
            text-underline-offset: 2px;
            text-decoration-line: underline;
        }
    }
    
`;

export const QuotesButton = styled.div`
    width: ${({ width }) => width && width };
    height: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    background: #5062F0;
    border-radius: 8px;
    align-items: center;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({marginRight}) => marginRight && css`
        margin-right: 4px;
    `};
    img { 
        margin-right: 4px;
    }
    svg { 
        margin-right: 4px;
    }
    ${({isDesableButton}) => isDesableButton && css`
        color: #454A67;
        background: #D0D3E2;
    `};
    ${({outlineButton}) => outlineButton && css`
        color: #5062F0;
        background: #F2F3F8;
    `};
`;

export const LastSevenDaysCard = styled.div`
    display: flex; 
    width: 50%; 
    text-underline-offset: 2px;
    text-decoration-line: underline;
    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off, 'calt' off;
    color: #5062F0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    img {
        margin-left: 5px;
    }
`;

export const TotalWrapper = styled.div`
    display: flex;
    padding: 0 8px 12px 8px;
    div {
        &:first-child {
            text-transform: uppercase;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: #454A67;
            font-style: normal;
            font-weight: 600;
            font-size: 9px;
            line-height: 12px;
            margin-right: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:nth-child(2) {
            text-align: right;
            font-feature-settings: 'tnum' on, 'lnum' on;
            color: #010311;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
        }
    }
`;
