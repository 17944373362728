import axios from 'axios';

export const saveSend = (id, body, url) => {
  const newSend = {
    id,
    body
  }
  const queue = JSON.parse(localStorage.getItem('queue')) || [];
  let filterId = [];

  if(queue.queue){
    filterId = queue?.queue.filter((item) => item.id !== newSend.id);
  }
  
  const newQueue = {url,queue:[...filterId, newSend]};

  localStorage.setItem('queue', JSON.stringify(newQueue));
}

export const checkQueue = (succesHandle, errorHandle) => {
  const queue = localStorage.getItem('queue');
  const checkQueueOn = sessionStorage.getItem('checkQueueOn');

  if(checkQueueOn) return;
  if(!queue) return;

  sessionStorage.setItem('checkQueueOn', true);
  const checkInterval = setInterval(function(){
    if(!queue){
      clearInterval(checkInterval);
      return;
    }else if(navigator.onLine){
      clearInterval(checkInterval);
      reSendQueue(succesHandle, errorHandle);
    }
  },5000);
}

export const reSendQueue = async (succesHandle, errorHandle) => {
  if(!navigator.onLine) return checkQueue(succesHandle, errorHandle); 
  const queue = JSON.parse(localStorage.getItem('queue'));
  if(!queue) return;
  const body = queue.queue.map((item) => item.body);
    try {
      const response = await axios.post(queue.url, body);
      localStorage.removeItem('queue');
      sessionStorage.removeItem('checkQueueOn');
      succesHandle();
    } catch (error) {
      console.log(error);
      errorHandle();
    }
}