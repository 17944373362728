import React from 'react';

import { 
    Dialog, 
    DialogTitle, 
    Typography, 
    IconButton,
    DialogContent,
    DialogActions
} from '@material-ui/core';

import Button from 'components/Button/button';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';

function CountNetworkDialog({
    openModal, 
    handleClose,
    backFunction,
}) {
    const { countNetworkDialog } = openModal;
    
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={countNetworkDialog}
            onClose={() => handleClose()}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            className="defaultModal modalCardWidth"
            transitionDuration={0}
        >
        <DialogTitle 
            className="modalHeader navyBlue" 
            style={{ 
                fontWeight: 'bold', 
                textAlign: 'center', 
                cursor: 'move', 
                fontFamily: 'Lato', 
            }} 
            id="draggable-dialog-title"
        >
            <Typography>
              Atenção! você está sem conexão com a internet
            </Typography>
            <IconButton edge="start" color="inherit"
                onClick={e => handleClose('countDivergentCheckDialog')}
            >
                <img style={{ width: "16px", height: "16px" }} src={CloseIconNavy} alt="CloseIconNavy" />
            </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
            <Typography
                style={{
                    fontSize: '14px',
                    textAlign: 'center'
                }}
            >
                As alterações que você fez talvez não sejam salvas
            </Typography>
        </DialogContent>

        <DialogActions className="justifyCenter">
            <Button 
                className="defaultButton backButton"
                design="outlined"
                onClick={backFunction}
                label="Sair"
                color="primary"
                style={{ outline: 0, marginRight: '15px' }}
            />

            <Button
                className="defaultButton submitButton"
                autoFocus
                style={{ outline: 0 }}
                design="contained"
                label="Continuar"
                onClick={() => handleClose('countDivergentCheckDialog')}
                color="primary" autoFocus
            />
        </DialogActions>
        
        </Dialog>
    );
}

export default CountNetworkDialog; 
