import React, { useEffect, useState } from 'react';

import { KeyboardArrowUp } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import ReturnedIconRed from 'images/icons/arrows/returned-icon-red.svg';
import DoneIconNeutral from 'images/icons/signals/checks/correct-icon-neutral.svg';
import DoneIconWhite from 'images/icons/signals/checks/correct-icon-white.svg';
import CloseIcon from 'images/icons/signals/closesAndCancels/close-icon-white.svg';
import numberToReal from 'utils/numberToReal';
import { shortenString } from 'utils/shortenString';

import DialogItemNote from '../dialogs/DialogItemNote';

function ReceiverDetailsCards({
  receiverData,
  order,
  setOpenModalOrder,
  setIsCompleteNote,
  setCurrentOrder,
  setCurrentQuantity,
  currentQuantity,
  totalCards,
  setTotalCards,
  cardId,
  setOpenFloatButton,
  sendReceivedscards,
  setSendReceivedscards,
  loading
}) {

  const [openDrillDown, setOpenDrillDown] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [selectedReceiveDate, setSelectedReceiveDate] = useState(new Date())
  const [submitted, setSubmitted] = useState(false)
  const [errMsg, setErrMsg] = useState(false)
  const [contacts, setContacts] = useState(false)
  const [statusCard, setStatusCard] = useState(order.sku.status)
  const [qntde, setQntde] = useState(order?.costs?.quantity)
  const [emb, setEmb] = useState(handleTotalByItem(order, receiverData.status).packageValue)
  const [total, setTotal] = useState(handleTotalByItem(order, receiverData.status).total)
  const [handleOpenItemNote, setHandleOpenItemNote] = useState(false)
  const [disabled, setDisabled] = useState(false)
  
  useEffect(() => {
    setStatusCard(order.sku.status)
  }, [order])

  const handleCloseModal = () => {
    setContacts([])
    setSelectedReceiveDate(new Date())
    setOpenModal(false)
    setOpenModalOrder(false)
    setErrMsg(false)
    setSubmitted(false)
  }

  useEffect(() => {
    setTotalCards(total)
  }, [total])


  const handleClose = (event, reason) => {
    setErrMsg(false)
    handleCloseModal()
  }

  function handleReceiveDateChange(date) {
    setSelectedReceiveDate(date)
  }

  function calculateTotal(quantity, packageValue) {
    return Number(quantity) * Number(packageValue)
  }

  function calculatePackage(quantity, total) {
    return Number(total) / Number(quantity) 
  }

  const handleQuantity = (type, ev) => {
    const quantity = qntde;

    if (type == "qntde") {
      setTotal(calculateTotal(emb, ev))
      setQntde(ev)
      setCurrentQuantity({
        ...currentQuantity,
        quantity: ev
      })
    }

    if (type == "emb") {
      const newOrder = {...order,
        costs: {
          packageValue: ev,
          total
        },
      }
      setTotal(calculateTotal(quantity, ev))
      setEmb(ev)
      setCurrentQuantity({
        ...currentQuantity,
        package: ev
      })
    }
 
    if (type == "total") {
      if (!ev) {
        setCurrentQuantity({
          ...currentQuantity,
          totalOrder: parseFloat(currentQuantity?.package) * parseFloat(currentQuantity?.quantity)
        })
        return;
      }
      setEmb(calculatePackage(quantity, ev))
      setTotal(ev)
      setCurrentQuantity({
        ...currentQuantity,
        totalOrder: ev
      })
    }  
  }

  function arrayPushById(array = [], value, id) {
    const arrayElement = array.map(el => {
      if (el.id === id) {
        return { id, value }
      }
      return el;
    })
    const isAlreadyAdd = array.find(el => el.id === id) 
    if (!isAlreadyAdd) {
      arrayElement.push({id, value})
    }
    return arrayElement;
  }

  useEffect(() => {
    setTotalCards(arrayPushById(totalCards, total, cardId))
  }, [total])

  function handleTotalByItem(item, status) {
    let packageValue = 0;
    let total = 0;

    if (!item.itemsPaymentDocument) 
      return { packageValue, total };
    
    packageValue = item.itemsPaymentDocument.packageValue;
    total = item.itemsPaymentDocument.total;

    const { shipping, otherCosts, segCosts } = item.sku.provider;
    const discount = item.sku.provider.discount * item.costs.quantity;

    total = total + Number(otherCosts) + Number(segCosts) + Number(shipping) - Number(discount);
    packageValue = total / item.costs.quantity;
    
    return { packageValue, total };
  }

  const openItemNoteDialog = () => {
    setOpenModalOrder(true)
  }

  function deleteArrayItemById(array, id) {
    const isAlreadyAdd = array.find(el => el.itemId === id)
    if (!isAlreadyAdd) {
      return
    }
    array.splice(array.indexOf(isAlreadyAdd), 1);
  }

  function arrayPushByIdV2(array = [], newStatus, id) {
    const divergenceStatus = ""
    const arrayElement = array.map(el => {
      if (el.itemId === id) {
        return {
          adjustOrder: !!(divergenceStatus && divergenceStatus == "adjustOrder"),
          averageValue: order?.sku?.averageValue,
          description: order?.sku?.description,
          id: order?.sku?.id,
          itemId: order._id,
          lastValue: order?.sku?.lastValue,
          newOrder: !!(divergenceStatus && divergenceStatus == "newOrder"),
          receivedPrice: parseFloat(emb),
          receivedPriceTotal: parseFloat(total),
          receivedQuantity: parseFloat(qntde),
          status: newStatus,
          stock: order?.sku?.stock,
          user: receiverData.user,
        }
      }
      return el;
    })
    const isAlreadyAdd = array.find(el => el.itemId === id) 
    if (!isAlreadyAdd) {
      arrayElement.push({
        adjustOrder: !!(divergenceStatus && divergenceStatus == "adjustOrder"),
        averageValue: order?.sku?.averageValue,
        description: order?.sku?.description,
        id: order?.sku?.id,
        itemId: order._id,
        lastValue: order?.sku?.lastValue,
        newOrder: !!(divergenceStatus && divergenceStatus == "newOrder"),
        receivedPrice: parseFloat(emb),
        receivedPriceTotal: parseFloat(total),
        receivedQuantity: parseFloat(qntde),
        status: newStatus,
        stock: order?.sku?.stock,
        user: receiverData.user,
      })
    }
    return arrayElement;
  }

  function handleChangeStatus(type) {
    if (!emb || !total) {
      setDisabled(true)
      return
    }
    setDisabled(false)
    if (type == "NOT RECEIVED") {
      setStatusCard("NOT RECEIVED")
      setSendReceivedscards(arrayPushByIdV2(sendReceivedscards, "NOT RECEIVED", order._id))
      setOpenFloatButton(true)
      return
    }
    if (type == "RETURNED") {
      setStatusCard("RETURNED")
      setSendReceivedscards(arrayPushByIdV2(sendReceivedscards, "RETURNED", order._id))
      setOpenFloatButton(true)
      return
    }
    if (statusCard == "RECEIVED") {
      deleteArrayItemById(sendReceivedscards, order._id)
      if (sendReceivedscards.length == 0) {
        setOpenFloatButton(false)
      }
      setStatusCard(order.sku.status)
      return
    }
    setStatusCard('RECEIVED')
    setOpenFloatButton(true)
    setSendReceivedscards(arrayPushByIdV2(sendReceivedscards, "RECEIVED", order._id))
  }

  return (
    <>
    <DialogItemNote
    handleOpenItemNote={handleOpenItemNote}
    setHandleOpenItemNote={setHandleOpenItemNote}
    receiverData={receiverData}
    order={order}
    setStatusCard={setStatusCard}
    handleChangeStatus={handleChangeStatus}
    />
    <div className="cardProducts" >
      <div className="productsName" >
        <p>{shortenString(order.sku.description, 25)}</p>
        <p onClick={() => setOpenDrillDown(!openDrillDown)} >
          {openDrillDown ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
        </p>
      </div>
      {openDrillDown ? <div />
      : 
      <div className="productsInfos">
        <div className="qtde" >
          <div>
            QTDE.
          </div>
          <div>
            <h1>{numberToReal(order.costs.quantity, 3)}</h1>
          </div>
        </div>
        <div className='geral'>
          <div className="productsPrices" >
            <p>R$ / UN. NF</p>
            <p style={{fontSize: "12px", color: "#089191"}} >{numberToReal(order.costs.packageValue, 3)}</p>
          </div>
          <div className="productsPrices" >
            <p>R$TOTAL</p>
            <p style={{fontSize: "12px", color: "#089191"}} >{numberToReal(parseFloat(order.costs.total?.replace("," , ".")))}</p>
          </div>
        </div>
        
      <button
      onClick={() => handleChangeStatus()}
      disabled={loading}
      style={{
        height: "40px",
        width: "40px",
        outline: "none",
        backgroundColor: 
        statusCard == "RECEIVED" ? "#5062F0"
        : statusCard == "WAITING" ? "#FFFFFF"
        : statusCard == "NOT RECEIVED" ? "#F2B90D" 
        : "#AA0816",
        border: "1px solid",
        borderColor: 
        statusCard == "RECEIVED" ? "#5062F0"
        : statusCard == "WAITING" ? "#0E1337"
        : statusCard == "NOT RECEIVED" ? "#F2B90D" 
        : "#AA0816",
        borderRadius: "5px",
        padding: "10px"
        }} >
          {statusCard == "RECEIVED" ? <img src={DoneIconWhite} style={{height: "16px", width: "16px"}} />
          : statusCard == "WAITING" ? <img src={DoneIconNeutral} style={{height: "16px", width: "16px"}} />
          : statusCard == "NOT RECEIVED" ? <img src={CloseIcon} style={{height: "16px", width: "16px"}} />
          : <img src={ReturnedIconRed} style={{height: "16px", width: "16px"}} /> 
          }
        
      </button>
    </div>
      }
      {openDrillDown ? 
        <div className="productsInfosDetails">
          <div>
            <p style={{color: "#91084C"}} >Pedido</p>
            <p style={{color: "#089191"}} >Recebimento</p>
          </div>
          <div className="detailsQtde" >
            <div>
              <p>QTDE.</p>
              <h1>{numberToReal(order?.costs?.quantity, 3)}</h1>
            </div>
          <div className="receiverProduct" >
          <InputNumber 
            disabled={order.sku.status != statusCard}
            initialValue={qntde}
            handleValue={value => handleQuantity("qntde" , value)}
          />
          </div>
        </div>
        <div style={{margin: "2px 0"}} className="detailsQtde" >
          <div style={{
              display: "flex",
              flexDirection: "column", 
              alignItems: "space-between"
            }} >
            <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between"
            }}>
              <p>EMB.</p>
              <p style={{marginRight: "5px", fontSize: "16px", color: "#91084C", fontWeight: "400"}} >{order.sku.type}</p>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "flex-end"
              }} >
              <h1
               >R${numberToReal(order?.costs?.packageValue, 3)}</h1>
            </div>
          </div>
          <div className="receiverProduct" >
            <p 
              style={{
                color: "#91084C",
                fontSize: "16px",
                fontWeight: "400",
                marginBottom: "8px"
              }}
            >{order.sku.type}</p>
              <InputNumber 
                disabled={order.sku.status != statusCard}
                initialValue={emb}
                handleValue={value => handleQuantity("emb" , value)}
              />
          </div>
        </div>
        <div className="detailsQtde" >
       
          <div>
            <p>TOTAL</p>
            <h1>R${numberToReal(parseFloat(order?.costs?.total.replace("," , ".")))}</h1>
          </div>
          <div className="receiverProduct" >
            <InputNumber 
              disabled={order.sku.status != statusCard}
              initialValue={total}
              handleValue={value => handleQuantity("total" , value)}
            />
          </div>
        </div>
        <button
        onClick={() => setHandleOpenItemNote(!handleOpenItemNote)}
        style={{
        display: "flex",
        alignItems: "center",
        outline: "none",
        margin: "10px 5px",
        fontSize: "16px",
        fontWeight: "700",
        height: "44px",
        backgroundColor: "#5062F0",
        color: "white",
        border: "1px solid #5062F0"
        }}
        ><img src={DoneIconWhite} style={{height: "16px", width: "16px"}} /> Opções de Recebimento</button>
        {/* <button
        onClick={openItemNoteModal}
        style={{
        display: "flex",
        alignItems: "center",
        outline: "none",
        margin: "10px 5px",
        fontSize: "16px",
        fontWeight: "700",
        height: "44px",
        backgroundColor: 
        order?.sku?.status == "RECEIVED" ? "#5062F0"
        : order?.sku?.status == "WAITING" ? "#FFFFFF"
        : order?.sku?.status == "NOT RECEIVED" ? "#F2B90D" 
        : "#AA0816",
        border: "1px solid",
        borderColor: 
        order?.sku?.status == "RECEIVED" ? "#5062F0"
        : order?.sku?.status == "WAITING" ? "#5062F0"
        : order?.sku?.status == "NOT RECEIVED" ? "#F2B90D" 
        : "#AA0816",
        }}
        >
          {order.sku.status == "RECEIVED" ? <img src={DoneIconWhite} style={{height: "16px", width: "16px"}} />
          : order.sku.status == "WAITING" ? <img src={DoneIconBloomBlue} style={{height: "16px", width: "16px"}} />
          : order.sku.status == "NOT RECEIVED" ? <img src={CloseIcon} style={{height: "16px", width: "16px"}} />
          : <img src={ReturnedIconRed} style={{height: "16px", width: "16px"}} /> 
          }
          {order.sku.status == "RECEIVED" ? <p style={{marginLeft: "10px"}}> Item Recebido </p>
          : order.sku.status == "WAITING" ?<p style={{marginLeft: "10px", color: "#5062F0"}}> Receber Item </p>
          : order.sku.status == "NOT RECEIVED" ?<p style={{marginLeft: "10px"}}> Não Recebido </p>
          : <p style={{marginLeft: "10px"}}> Item Devolvido </p>
          }
        </button> */}
      </div> : <div />
      }
      {disabled ? 
      <p style={{
        color: "red",
        fontSize: "12px",
        fontWeight: "400",
        textAlign: "right",
        margin: "8px 12px 0 0"
        }} >
        *Preencha os dados do recebimento
      </p>
      : null}
      
    </div>
  </>
  )
}

export default ReceiverDetailsCards;