/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';

import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useParams } from 'react-router';

import PaperComponent from 'components/PaperComponent';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Trash from 'images/icons/signals/removesAndDeletes/newTrash.svg';
import TrashDisabled from 'images/icons/signals/removesAndDeletes/newTrashLightGray.svg';
import {
  AutocompleteContainer,
  CustomAutocomplete
} from 'pages/inventory/diary-count/count/TableHead/styles';
import getOptions from 'services/getOptions';
import Environment from 'utils/environments';
import { ShortenStringTooltip } from 'utils/shortenString';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

const productFilteredAndSortedList = (productList) =>
  productList?.sort((a, b) =>
    a.product.description.localeCompare(b.product.description)
  );

const itemStatus = (item) => {
  let color;
  let description = '';

  if (item?.status === 'CHANGED') {
    color = '#F2B90D';
    description = 'alterado';
  } else if (item?.status === 'ADDED') {
    color = '#5062F0';
    description = 'novo';
  } else if (item?.status === 'DELETED') {
    color = '#9296AC';
    description = 'excluído';
  } else {
    color = '#000000';
    description = '-';
  }

  return {
    color,
    description
  };
};

const statusMessage = (item) => {
  let message = '';

  if (item?.type === 'SKU') {
    message = 'Sku removido do produto';
  } else if (item?.type === 'PACKAGE') {
    message =
      item?.status === 'CHANGED'
        ? `Embalagem ${item?.oldValue} alterada para ${item?.newValue}`
        : `Embalagem ${item?.oldValue} ocultada`;
  } else if (item?.type === 'PRODUCT') {
    message = 'Produto removido do setup';
  }

  return message;
};

const generalStatus = (obj, isColor) => {
  const hasDeletedProductInfo = obj?.find(
    (item) => item.type === 'PRODUCT' && item.status === 'DELETED'
  );
  const productInfo = obj?.find((item) => item.type === 'PRODUCT');
  const hasDeletedSkuInfo = obj?.find(
    (item) => item.type === 'SKU' && item.status === 'DELETED'
  );
  const skuInfo = obj?.find((item) => item.type === 'SKU');
  const hasDeletedPackage = obj?.find(
    (item) => item.type === 'PACKAGE' && item.status === 'DELETED'
  );
  const packageInfo = obj?.find((item) => item.type === 'PACKAGE');

  if (hasDeletedProductInfo) {
    return isColor
      ? itemStatus(hasDeletedProductInfo)?.color
      : itemStatus(hasDeletedProductInfo)?.description;
  }

  if (productInfo) {
    return isColor
      ? itemStatus(productInfo)?.color
      : itemStatus(productInfo)?.description;
  }

  if (hasDeletedSkuInfo) {
    return isColor
      ? itemStatus(hasDeletedSkuInfo)?.color
      : itemStatus(hasDeletedSkuInfo)?.description;
  }

  if (skuInfo) {
    return isColor
      ? itemStatus(skuInfo)?.color
      : itemStatus(skuInfo)?.description;
  }

  if (hasDeletedPackage) {
    return isColor
      ? itemStatus(hasDeletedPackage)?.color
      : itemStatus(hasDeletedPackage)?.description;
  }

  if (packageInfo) {
    return isColor
      ? itemStatus(packageInfo)?.color
      : itemStatus(packageInfo)?.description;
  }

  return '';
};

function DialogCount({
  openState,
  productList,
  onCloseProductDialog,
  productDialogTitle,
  selectedProduct,
  setSelectedProduct,
  modalStates,
  setModalStates,
  setConfirmModalInfos
}) {
  const { id: paramsID } = useParams();
  const [productOptions, setProductOptions] = useState([]);
  const activeProducts = productList?.filter((el) => el?.status === 'ACTIVE');

  return (
    <Dialog
      open={openState}
      onClose={() => onCloseProductDialog()}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal mH80"
      maxWidth="lg"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <div className="dFlex justifyBetween alignCenter w100">
          <Typography>
            Contagem:{' '}
            {`${productDialogTitle.storageLocation || ''}  ${
              productDialogTitle.storageLocation ? '/' : ''
            }   ${productDialogTitle.periodicity || ''}`}
          </Typography>

          <p className="white bold mb0">
            {activeProducts?.length}{' '}
            {activeProducts?.length === 1 ? 'ITEM' : 'ITENS'}
          </p>
        </div>

        <IconButton
          className="ml10"
          edge="start"
          color="inherit"
          onClick={() => onCloseProductDialog()}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable pb0">
        <AutocompleteContainer>
          <span className="bold">Adicionar Produto</span>

          <CustomAutocomplete
            tabIndex={-1}
            options={productOptions || []}
            onFocus={() => {
              if (productOptions.length) return;

              getOptions(
                `${environments.catalog}/products`,
                'productOptions',
                productOptions,
                setProductOptions,
                paramsID,
                { autoComplete: true },
                null,
                2000
              );
            }}
            getOptionLabel={(option) => option.description}
            getOptionDisabled={(option) =>
              productList
                ?.filter((item) => item?.status === 'ACTIVE')
                .some((item) => item?.productId === option?.id)
            }
            className="autocompleteSmall"
            value={selectedProduct}
            size="small"
            onChange={(_, value) => {
              setSelectedProduct(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                tabIndex={-1}
                id={params.id}
                type="text"
                name="Products"
                variant="outlined"
                required
                placeholder="Selecione..."
                InputLabelProps={{
                  shrink: true
                }}
              />
            )}
            renderOption={(params) => (
              <Typography className="fontSizeDefault">
                {params.description}
              </Typography>
            )}
          />
          <IconButton
            className="p0 ml10"
            edge="start"
            color="inherit"
            style={{ outline: 0 }}
            aria-label="add"
            onClick={() => {
              if (selectedProduct) {
                setModalStates({
                  ...modalStates,
                  confirmModal: true
                });

                setConfirmModalInfos({
                  name: 'addProduct',
                  contentText:
                    'Deseja realmente adicionar este produto na contagem?'
                });
              }
            }}
          >
            <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
          </IconButton>
        </AutocompleteContainer>

        <Grid container>
          <table
            className="contagem"
            width="100%"
            height="70%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr>
                <th align="bottom" width="300" className="subtitle">
                  PRODUTO
                </th>

                <th width="70" className="subtitle">
                  UN.
                </th>

                <th width="70" className="subtitle">
                  SKU
                </th>

                <th width="100" className="subtitle">
                  STATUS
                </th>

                <th
                  width="30"
                  className="subtitle"
                  style={{ borderLeft: 'solid 1px #D2D3D7' }}
                >
                  &nbsp;
                </th>
              </tr>

              <tr>
                <td colSpan="5">
                  <hr className="titleLineBorder p0 m0" />
                </td>
              </tr>
            </thead>

            <tbody>
              {productFilteredAndSortedList(productList).map((product) => {
                const hasDeletedInfo = product?.infoChange?.filter(
                  (item) =>
                    item.type === 'PRODUCT' && item?.status === 'DELETED'
                ).length;

                return (
                  <>
                    <tr>
                      <td
                        width="200"
                        className={`description pr0 ${
                          hasDeletedInfo && 'disabledGray'
                        }`}
                      >
                        {product.product.description !== undefined
                          ? ValidationLength(product.product.description, 35)
                          : ''}
                      </td>

                      <td
                        width="70"
                        className={`description fontSizeDefault ${
                          hasDeletedInfo && 'disabledGray'
                        }`}
                      >
                        {product?.product?.consumptionUnitsPrimary
                          ?.abbreviation || '-'}
                      </td>

                      <td
                        width="350"
                        className={`description fontSizeDefault ${
                          hasDeletedInfo && 'disabledGray'
                        }`}
                      >
                        {product.product.skus !== undefined &&
                        product.product.skus.length > 0 ? (
                          <ShortenStringTooltip
                            string={product.product.skus[0].skus?.description}
                            length={30}
                          />
                        ) : (
                          ''
                        )}
                      </td>

                      <td width="100" className="description">
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          disableHoverListener={!hasDeletedInfo}
                          title={
                            <div
                              className="dFlex flexColumn"
                              style={{ width: 'auto', padding: '5px 0' }}
                            >
                              {product?.infoChange?.length ? (
                                <>
                                  <p className="dFlex mb0">
                                    <span className="bold mr5">Produto:</span>
                                    {
                                      product.infoChange[0]?.product
                                        ?.description
                                    }{' '}
                                    (
                                    {statusMessage(
                                      product?.infoChange?.find(
                                        (item) => item.type === 'PRODUCT'
                                      )
                                    ) || ''}
                                    )
                                  </p>

                                  {product.infoChange
                                    .filter((item) => item.type !== 'PRODUCT')
                                    ?.map((info, infoIndex) => (
                                      <p
                                        className={`dFlex ${
                                          product.infoChange.length - 1 ===
                                            infoIndex && 'mb5'
                                        } ${
                                          product.infoChange.length === 1 &&
                                          'mb0'
                                        }`}
                                      >
                                        <span className="bold mr5">SKU:</span>
                                        {info?.sku?.description || ''} (
                                        {statusMessage(info, true)})
                                      </p>
                                    ))}
                                </>
                              ) : (
                                <div className="countsCircularProgressBar dFlex justifyCenter">
                                  <CircularProgress />
                                </div>
                              )}
                            </div>
                          }
                        >
                          <p
                            className="mb0"
                            style={{
                              color:
                                generalStatus(product?.infoChange, true) ||
                                '#000'
                            }}
                          >
                            {generalStatus(product?.infoChange)}
                          </p>
                        </Tooltip>
                      </td>

                      <td
                        width="30"
                        className="description"
                        style={{ borderLeft: 'solid 1px #D2D3D7' }}
                      >
                        <IconButton
                          aria-label="playIcon"
                          disabled={hasDeletedInfo}
                          onClick={() => {
                            setModalStates({
                              ...modalStates,
                              confirmModal: true
                            });

                            setConfirmModalInfos({
                              name: 'deleteProduct',
                              contentText:
                                'Deseja realmente excluir este produto da contagem?',
                              productId: product?.productId
                            });
                          }}
                          style={{
                            color: 'eaeaea',
                            padding: 0,
                            outline: 0
                          }}
                        >
                          <img
                            src={hasDeletedInfo ? TrashDisabled : Trash}
                            alt="Trash"
                            width={12}
                          />
                        </IconButton>
                      </td>
                    </tr>

                    <tr>
                      <td className="titleLineBorder p0 m0" colSpan="5">
                        <hr className="titleLineBorder p0 m0" />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default DialogCount;
