import axios from 'axios';

const instance = axios.create();

instance.interceptors.response.use(
  (response) => {
    const version = localStorage.getItem('version');
    const listVersion = version?.length > 7 ? JSON?.parse(version) : version;

    const versionCatalog =
      version?.length > 7 ? listVersion?.catalog_version : version;
    const versionValidate =
      versionCatalog > 4
        ? JSON?.parse(versionCatalog)?.length
        : JSON?.parse(version)?.length;

    const currentVersion = response?.headers?.version;
    const objectVersions = {
      catalog_version: response?.headers?.version,
      auth_version: listVersion?.auth_version,
      orders_version: listVersion?.orders_version,
      manufactures_version: listVersion?.manufactures_version,
      providers_version: listVersion?.providers_version,
      restaurants_version: listVersion?.restaurants_version,
      cache_version: listVersion?.cache_version,
    };

    if (!versionCatalog || versionCatalog !== currentVersion) {
      localStorage.setItem('version', JSON.stringify(objectVersions));
    }

    if (
      versionValidate &&
      versionCatalog !== currentVersion &&
      versionCatalog !== null &&
      versionCatalog !== 'undefined' &&
      currentVersion !== undefined
    ) {
      location.reload(true);
    }

    return response;
  },
  (error) => Promise.reject(error)
);

export default instance;
