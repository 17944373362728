export const translateDays = (description) => {
  let dayTranslated = description;

  switch (description) {
    case 'Sunday':
      dayTranslated = 'Domingo';
      break;
    case 'Monday':
      dayTranslated = 'Segunda';
      break;
    case 'Tuesday':
      dayTranslated = 'Terça';
      break;
    case 'Wednesday':
      dayTranslated = 'Quarta';
      break;
    case 'Thursday':
      dayTranslated = 'Quinta';
      break;
    case 'Friday':
      dayTranslated = 'Sexta';
      break;
    case 'Saturday':
      dayTranslated = 'Sábado';
      break;
    default:
      break;
  }

  return dayTranslated;
};
