import React from "react";

function DialogIcon(props) {
  return <svg width={13} height={12} viewBox="0 0 13 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.22362 2.46905C2.41323 3.05034 2 3.78548 2 4.51903C2 5.29544 2.4649 6.077 3.37198 6.67066C3.86002 6.99008 4.24986 7.54928 4.24986 8.23143V10.2402C4.24986 10.329 4.27654 10.3755 4.30115 10.4039C4.33134 10.4389 4.38126 10.471 4.44623 10.4854C4.5112 10.4997 4.56996 10.4915 4.61193 10.4725C4.64617 10.457 4.68976 10.4261 4.72685 10.3454L5.79789 8.01497C5.89497 7.74685 6.13557 7.53171 6.49986 7.53171C7.81131 7.53171 8.96744 7.14927 9.77638 6.56901C10.5868 5.98772 11 5.25257 11 4.51903C11 3.78548 10.5868 3.05034 9.77638 2.46905C8.96744 1.88879 7.81145 1.50634 6.5 1.50634C5.18855 1.50634 4.03256 1.88879 3.22362 2.46905ZM2.35176 1.24328C3.44294 0.460573 4.91196 0 6.5 0C8.08804 0 9.55706 0.460573 10.6482 1.24328C11.738 2.02496 12.5 3.17275 12.5 4.51903C12.5 5.86531 11.738 7.01309 10.6482 7.79477C9.66939 8.4969 8.38651 8.93981 6.9864 9.02356L6.08879 10.9766C5.30244 12.6876 2.74986 12.1246 2.74986 10.2402V8.23143C2.74986 8.15183 2.70043 8.02916 2.55297 7.93265C1.35553 7.14894 0.5 5.94717 0.5 4.51903C0.5 3.17275 1.26202 2.02496 2.35176 1.24328Z"
      fill={props.color}
    />
  </svg>
}

export default DialogIcon;