import styled from 'styled-components';

export const DialogContentContainer = styled.div`
    margin: 16px;
    box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
    border-radius: 0px 4px 4px 4px;
`;

export const DanteRangeContainer = styled.div`
    padding-top: 10px;
    padding-left: 10px;
    display: flex; 
`;
