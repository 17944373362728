/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import graphW from 'images/icons/arrows/graphW.svg';
import wrenchW from 'images/icons/configsAndSetups/wrenchW.svg';

function FilterItem({
  title,
  type,
  setFilterMainOptions,
  setFilterOptions,
  selectedMainTypes,
  fakeData
}) {
  const [selectedMainType, setSelectedMainType] = useState(type);
  const [selectedType, setSelectedType] = useState('all');
  const [active, setActive] = useState(!!selectedMainTypes.includes(type));

  function setFilter(param) {
    if (param === selectedType) {
      setSelectedType('all');
      setFilterOptions(param === 'O' ? 'G' : 'O');
    } else {
      setSelectedType(param);
      setFilterOptions(param === 'O' ? 'G' : 'O');
    }
  }

  function setFilterMainType(param) {
    if (param === selectedMainType) {
      setSelectedMainType('GENERAL');
    } else {
      setSelectedMainType(param);
    }

    if (active === false) {
      setSelectedType('all');
      setFilterOptions('all');
    }

    setActive(!active);
    setFilterMainOptions(param);
  }

  const setIndCount = (cardType) => {
    if (type === 'GENERAL') {
      return fakeData.filter((item) => item.type === cardType).length;
    }
    return cardType === 'O'
      ? fakeData.operational.length
      : fakeData.managements.length;
  };

  return (
    <div
      className={`filterItemBox dFlex flexColumn ${
        (active || selectedMainTypes.includes(type)) && 'active'
      }`}
    >
      <p onClick={() => setFilterMainType(type)} className="filterTitle">
        {title}
      </p>

      <div className="filterActions dFlex">
        <button
          disabled={!active}
          onClick={() => setFilter('O')}
          className={
            selectedMainTypes.includes(type) &&
            (selectedType === 'G' || selectedType === 'all')
              ? 'operational'
              : 'inactive'
          }
        >
          {setIndCount('O')} missões
        </button>

        <button
          disabled={!active}
          onClick={() => setFilter('G')}
          className={
            selectedMainTypes.includes(type) &&
            (selectedType === 'O' || selectedType === 'all')
              ? 'manager'
              : 'inactive'
          }
        >
          {setIndCount('G')} indicadores
        </button>
      </div>

      <div
        onClick={() => setFilterMainType(type)}
        className="borderSeparator"
      />

      <div
        onClick={() => setFilterMainType(type)}
        className="defaultInfos indicatorsInfos"
      >
        <div className="defaultInfoBox">
          <div className="infoIconBox">
            <img src={graphW} alt="graph" />
          </div>

          <div className="infoValue">
            <p>{100 > 0 ? `+${100}%` : `-${100}%`}</p>
          </div>
        </div>

        <div className="defaultInfoBox">
          <div className="infoIconBox">
            <img src={wrenchW} alt="wrenchW" />
          </div>

          <div className="infoValue">
            <p>{100 > 0 ? `+${100}%` : `-${100}%`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterItem;
