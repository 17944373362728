import styled from 'styled-components';

export const Th = styled.th`
    width: ${(props) => props.width && props.width};
    @media (max-width: 1000px){
        width: ${(props) => props.widthMobile ? props.widthMobile : props.width};
    }
`

export const Td = styled.td`
    width: ${(props) => props.width && props.width};
    @media (max-width: 1000px){
        width: ${(props) => props.widthMobile ? props.widthMobile : props.width};
    }
`

export const TdBorder = styled.td`
    width: ${(props) => props.width && props.width};
    border-left: 1px solid #666770;
    @media (max-width: 1000px){
        width: ${(props) => props.widthMobile ? props.widthMobile : props.width};
    }
`

export const Paragraph = styled.p`
    margin: 0;
    @media (max-width: 1200px){
        display: none;
    }
`

export const ParagraphMobile = styled.p`
    display: none;
    margin: 0;

    @media (max-width: 1200px){
        display: contents;
    }
`