import React from 'react';

export default function engine({ fill }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1169 8.78333C14.1512 8.53333 14.1683 8.275 14.1683 8C14.1683 7.73333 14.1512 7.46667 14.1084 7.21667L15.8475 5.9C16.0017 5.78333 16.0445 5.55833 15.9503 5.39167L14.3054 2.625C14.2026 2.44167 13.9884 2.38333 13.8 2.44167L11.7524 3.24167C11.3241 2.925 10.87 2.65833 10.3645 2.45833L10.0561 0.341667C10.0218 0.141667 9.8505 0 9.64489 0H6.35511C6.1495 0 5.98672 0.141667 5.95245 0.341667L5.64404 2.45833C5.13857 2.65833 4.67595 2.93333 4.25616 3.24167L2.20861 2.44167C2.02013 2.375 1.80595 2.44167 1.70315 2.625L0.066824 5.39167C-0.0359817 5.56667 -0.00171323 5.78333 0.16963 5.9L1.90876 7.21667C1.86592 7.46667 1.83166 7.74167 1.83166 8C1.83166 8.25833 1.84879 8.53333 1.89163 8.78333L0.152495 10.1C-0.00171322 10.2167 -0.0445488 10.4417 0.0496898 10.6083L1.69458 13.375C1.79739 13.5583 2.01157 13.6167 2.20004 13.5583L4.24759 12.7583C4.67595 13.075 5.13001 13.3417 5.63547 13.5417L5.94389 15.6583C5.98672 15.8583 6.1495 16 6.35511 16H9.64489C9.8505 16 10.0218 15.8583 10.0475 15.6583L10.356 13.5417C10.8614 13.3417 11.3241 13.075 11.7438 12.7583L13.7914 13.5583C13.9799 13.625 14.194 13.5583 14.2969 13.375L15.9417 10.6083C16.0445 10.425 16.0017 10.2167 15.8389 10.1L14.1169 8.78333ZM8 11C6.30371 11 4.91583 9.65 4.91583 8C4.91583 6.35 6.30371 5 8 5C9.69629 5 11.0842 6.35 11.0842 8C11.0842 9.65 9.69629 11 8 11Z"
        fill={fill || '#5062F0'}
      />
    </svg>
  );
}
