/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DeleteOutline } from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import ItemsPerPage from 'components/ItemsPerPage';
import Localization, {
  LocalizationContext,
} from 'components/Localization';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import BrandForm from 'pages/catalog/brands/BrandForm';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as BrandsActions from 'store/actions/brands';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import { getFilterOptions, handleFilterOptions } from 'utils/handleFilterActions';

function Marcas({ userInfo, setBrands, createBrands }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [callSettings, setCallSettings] = useState({
    getId: userInfo.companiesActive.id,
    page: 1,
    totalPages: 0,
    size: 100,
    mainContent: [],
    numberOfElements: 0,
    loading: true,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    mainContent,
    numberOfElements,
    totalElements
  } = callSettings;
  const [brandModal, setBrandModal] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [deleteError, handleDeleteError] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [filtersChanged, setFiltersChanged] = useState([])

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: "Marcas",
        filterName: "brandDescription",
        filterNameSecond: "brandId",
        placeHolder: "Selecione...",
        type: "autoComplete",
        urlPath: `${environments.catalog}/brands`,
        key: "brands",
        optionReference: "description",
        options: [],
        size: "large",
        searchByTermMode: true
      },
      {
        label: "Fabricantes",
        filterName: "producerDescription",
        filterNameSecond: "producerId",
        placeHolder: "Selecione...",
        type: "autoComplete",
        urlPath: `${environments.catalog}/producers`,
        key: "producers",
        page: 1,
        optionReference: "description",
        options: [],
        size: "large",
        searchByTermMode: true
      }
    ]
  ])

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  function handleDelete() {
    const baseUrl = `${environments.catalog}/brands`;
    const data = mainContent;
    const index = data.indexOf(deleteData);
    mainContent.splice(index, 1);

    axios
      .delete(`${baseUrl}/${deleteData.id}`, config)
      .then(() => {
        handleDialogClose();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleFilter = (param, value, _, paramSecond) => {
    const hasFilterWithFirstName = filtersChanged?.filter(el => el?.param === param)?.length
    const hasFilterWithSecondName = filtersChanged?.filter(el => el?.param === paramSecond)?.length

    let formattedFilters = hasFilterWithSecondName ? filtersChanged?.filter(el => el?.param !== paramSecond) : filtersChanged

    if (!value && (hasFilterWithFirstName || hasFilterWithSecondName)) {
      formattedFilters = filtersChanged?.filter(el => el?.param !== paramSecond && el?.param !== param)
    }

    handleFilterOptions(param, value, formattedFilters, setFiltersChanged, null, false, '1')
  }

  const toggleBrandModal = () => {
    setBrandModal(!brandModal);

    if (brandModal === false) {
      setUpdateList(false);
    }
  };

  const getMainContent = async (page, size) => {
    const params = filtersChanged.map(fc => fc.urlParam).join("&")
    const response = await axiosInstanceCatalog.get(
      `${environments.catalog}/brands?page=${page}&size=${size}&originId=${getId}&${params}`
    );
    const brands = await response.data;
    setCallSettings({
      ...callSettings,
      totalPages: brands.totalPages,
      mainContent: brands.content || [],
      numberOfElements: brands.numberOfElements,
      loading: false,
      totalElements: brands.totalElements
    });
  }

  const addBrand = () => (
    <Tooltip title={<Localization text={ t('registerBrand')} />} aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          toggleBrandModal();
          createBrands();
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  const { t } = useTranslation('translations');

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useEffect(() => {
    filterFields?.forEach(fields => {
      fields?.forEach(({ urlPath, label, key, optionReference }) => {
        if (!urlPath) {
          return
        }

        getFilterOptions(urlPath, label, key, filterFields, setFilterFields, optionReference, getId, filtersChanged, 1)
      })
    })
  }, [getId, updateList])

  useEffect(() => {
    // Sempre que mudar o filtro ou o getId, retorna a paginação pro inicio
    setCallSettings({ ...callSettings, page: 1 })
    getMainContent(page, size)
  }, [getId, updateList, filtersChanged]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: userInfo.companiesActive.id });
  }, [userInfo]);

  return (
    <Layout>
      <LocalizationContext.Provider value={{ nameSpace: 'marcas' }}>
        <TitleHead title={t('brands')} />

        <GlobalStyle />

        <Container>
          <PageTitleAndFilter
            title={<Localization text={t('brands')} />}
            activeFilterFilds={filterFields}
            handleFilter={handleFilter}
            additionalbutton={addBrand}
          />
          <table
            className="desktop"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th width="49%" className="subtitle">
                  <Localization text={t('description')} toUppercase />
                </th>
                
                <th width="49%" className="subtitle">
                  <Localization text={t('producer')} toUppercase />
                </th>

                <th width="2%" className="subtitle">
                  &nbsp;
                </th>
              </tr>
            </thead>

            <tbody>
              {
                mainContent.length === 0 ? (
                  <tr>
                    <th
                      colSpan="18"
                      style={{ margin: '10px', textAlign: 'center' }}
                    >
                      Nenhuma marca encontrada
                    </th>
                  </tr>
                ) : null
              }

              {mainContent.map((brand) => (
                <>
                  <tr>
                    <td className="description p12">
                      <span
                        className="cursorPointer lightBlue"
                        onClick={() => {
                          setBrands(brand);
                          toggleBrandModal();
                        }}
                      >
                        {brand.description}
                      </span>
                    </td>

                    <td className="description">
                      {brand?.producers?.description}
                    </td>

                    <td className="description">
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          handleDialogClickOpen();
                          setDeleteData(brand);
                        }}
                        style={{ padding: 0, outline: 0, marginRight: '7px' }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="3">
                      <hr />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>

          <div className="pagination">
            <Pagination
              count={totalPages}
              onChange={(_, page) => {
                setCallSettings({ ...callSettings, page })
                getMainContent(page, size)
              }}
              variant="outlined"
              shape="rounded"
              size="small"
              color="primary"
            />

            <ItemsPerPage
              numberOfElements={numberOfElements}
              size={totalElements}
            />
          </div>

          {brandModal && (
            <BrandForm
              openModal={brandModal}
              isModal
              redirectPath={null}
              onClose={toggleBrandModal}
              setUpdateList={setUpdateList}
            />
          )}

          <Dialog
            fullScreen={fullScreen}
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              className="modalHeader navyBlue bold cursorMove"
              id="draggable-dialog-title"
            >
              <Typography>
                <Localization text={t('deleteTitle')} />
              </Typography>
            </DialogTitle>

            <DialogContent className="modalContent">
              <Typography>
                <Localization text={t('deleteMessageQuestion')} />
              </Typography>
            </DialogContent>

            <DialogActions className="justifyCenter">
              <Button
                className="defaultButton backButton"
                design="outlined"
                onClick={handleDialogClose}
                label={<Localization text={t('cancel')} />}
                color="primary"
              />
              <Button
                className="defaultButton submitButton"
                autoFocus
                design="contained"
                label={<Localization text={t('confirmed')} />}
                onClick={() => handleDelete()}
                color="primary"
              />
            </DialogActions>
          </Dialog>

          <Snackbar
            open={deleteSuccess}
            autoHideDuration={2000}
            onClose={() => handleCloseAll(handleDeleteSuccess)}
          >
            <Alert
              onClose={() => handleCloseAll(handleDeleteSuccess)}
              severity="success"
            >
              <Localization text="deletedSuccess" />
            </Alert>
          </Snackbar>

          <Snackbar
            open={deleteError}
            autoHideDuration={2000}
            onClose={() => handleCloseAll(handleDeleteError)}
          >
            <Alert
              onClose={() => handleCloseAll(handleDeleteError)}
              severity="error"
            >
              <Localization text="saveInfoError" />
            </Alert>
          </Snackbar>
        </Container>
      </LocalizationContext.Provider>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(BrandsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Marcas);
