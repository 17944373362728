/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
import { useCallback, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import { Tree } from 'shineout';

import SelectButton from 'components/BloomForms/SelectButton';
import PaperComponent from 'components/PaperComponent';
import categoriesExpand from 'images/icons/signals/addsAndCreates/categoriesExpand.svg';
import categoriesCollapsed from 'images/icons/signals/removesAndDeletes/categoriesCollapsed.svg';
import getOptions from 'services/getOptions';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import Environment from 'utils/environments';

import StepView from '../../components/StepView';

function CategoriesInfosDialog({
  open,
  onConfirm,
  onClose,
  onCancel,
  setSelectedCategoriesInfosObj
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { id: paramsID } = useParams();
  const [getId, setGetId] = useState(paramsID);
  const [loading, setLoading] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [categoryType, setCategoryType] = useState('Shopping');
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [segment, setSegment] = useState('');
  const [defaultExpanded] = useState(['Delivery']);

  const getCategories = useCallback(
    (currentSegment, currentType) => {
      (async () => {
        setLoading(true);
        const categoriesApiSales = await axiosInstanceCatalog.get(
          `${environments.catalog}/models/categories?page=1&size=10&segmentId=${currentSegment?.id}&type=${currentType}`
        );
        const data = await categoriesApiSales.data;
        setDataArray(data.content);
        setLoading(false);
      })();
    },
    [getId]
  );

  const handleCategoryType = (value) => {
    setCategoryType(value);
    getCategories(segment, value);
  };

  // renderiza os objetos com os botoes edit, del, add
  const renderItem = (node) => (
    <div>
      <TextField
        margin="dense"
        size="small"
        variant="outlined"
        defaultValue={node.description}
        inputProps={{ maxLength: 50 }}
        disabled
        style={{
          width: '320px'
        }}
        id={`item-${node.id}`}
      />
    </div>
  );

  const renderActionButtons = () => (
    <DialogActions className="dFlex justifyEnd p0">
      <Button
        className="defaultButton backButton mr8"
        autoFocus
        onClick={() => {
          onCancel ? onCancel() : onClose();
        }}
      >
        Cancelar
      </Button>

      <Button
        className={`defaultButton ${segment ? 'submitButton' : 'backButton'}`}
        onClick={() => {
          if (!segment) return null;

          onConfirm();
        }}
      >
        Utilizar
      </Button>
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      className="defaultModal dialog100 modal1085 categoryModelDialog fakePopover"
      PaperComponent={PaperComponent}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle
        id="draggable-dialog-confirm-title"
        className="modalHeader bold textAlignCenter cursorMove"
      >
        <Typography className="white fontSizeDefault">
          CATEGORIAS PRÉ-CADASTRADAS
        </Typography>

        <div style={{ marginLeft: 'auto' }}>
          <StepView currentStep="4" />
        </div>
      </DialogTitle>

      <DialogContent
        className="modalContent bgWhite w100"
        style={{ width: '1000px' }}
      >
        <Grid className="cardDefault dFlex alignCenter mb16">
          <div style={{ width: '268px', marginRight: 30 }}>
            <Autocomplete
              id="asynchronous-demo"
              getOptionLabel={(option) => option?.description}
              getOptionSelected={(option, value) =>
                option?.description === value?.description
              }
              onFocus={() => {
                if (!segmentOptions?.length) {
                  getOptions(
                    `${environments.catalog}/segments`,
                    'segmentOptions',
                    segmentOptions,
                    setSegmentOptions,
                    getId,
                    null,
                    null,
                    10
                  );
                }
              }}
              options={segmentOptions}
              value={segment}
              required
              onChange={(_, value) => {
                setSegment(value);
                setSelectedCategoriesInfosObj(value);
                getCategories(value, categoryType);
              }}
              className="autocompleteSmall"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Segmento"
                  variant="outlined"
                  name="segment"
                  size="small"
                  type="text"
                  placeholder="Selecione..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(params) => (
                <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                  {params?.description}
                </Typography>
              )}
            />
          </div>

          <div className="dFlex flexColumn alignStart">
            <span
              className="bold fontSizeDefault"
              style={{ marginTop: '-20px', marginBottom: 9 }}
            >
              Tipo de Categoria
            </span>

            <div className="dFlex alignCenter">
              <div className="dFlex alignCenter">
                <SelectButton
                  isSelected={categoryType === 'Shopping'}
                  setIsSelected={() => handleCategoryType('Shopping')}
                  radioButton
                  id="categoryTypeShopping"
                  className="comparationSelecteItemSimilar"
                />

                <span
                  className="cursorPointer"
                  onClick={() => {
                    handleCategoryType('Shopping');
                  }}
                  style={{ marginLeft: '7px', marginRight: '16px' }}
                >
                  Compra
                </span>
              </div>

              <div className="dFlex alignCenter">
                <SelectButton
                  isSelected={categoryType === 'Sales'}
                  setIsSelected={() => handleCategoryType('Sales')}
                  radioButton
                  id="categoryTypeSales"
                  className="comparationSelecteItemSimilar"
                />

                <span
                  className="cursorPointer"
                  onClick={() => {
                    handleCategoryType('Sales');
                  }}
                  style={{ marginLeft: '7px', marginRight: '16px' }}
                >
                  Venda
                </span>
              </div>
            </div>
          </div>
        </Grid>

        {dataArray?.length ? (
          <Grid className="cardDefault withoutInputs pRelative">
            {loading && (
              <div style={{ margin: '10px' }}>
                <LinearProgress variant="query" />
              </div>
            )}

            <Tree
              data={dataArray}
              keygen={(node) => node.description}
              defaultExpanded={defaultExpanded}
              renderItem={renderItem}
              className="categoriesTree"
              expandIcons={[
                <img src={categoriesExpand} alt="expandIcon" />,
                <img src={categoriesCollapsed} alt="collapse" />
              ]}
            />

            {renderActionButtons()}
          </Grid>
        ) : (
          <Grid className="cardDefault withoutInputs pRelative">
            <span className="bold">Nenhuma categoria foi encontrada</span>

            <div className="mt20">{renderActionButtons()}</div>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default CategoriesInfosDialog;
