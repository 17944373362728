/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { completeNumberWithDecimal } from 'utils/completeNumberDecimal';

import { CountInputWrapper, CountInputLabel } from './index.style';

const parseValue = (value, { hasOperator, valueOperator } = { hasOperator: false, valueOperator: 0 }) => {
    const operator = hasOperator && valueOperator && value ? parseFloat(value.toString().replace(',', '.')) * valueOperator : hasOperator && valueOperator ? value * valueOperator : value
    const newValue = completeNumberWithDecimal(parseInt(operator));
    return parseFloat(newValue.replace(',', '.')).toFixed(3)
}

function MobileInputNumber({ label, content, handleInputValue, sizes = {} }) {
    const [ inputValue, setInputValue ] = useState(parseValue(content, {
        hasOperator: true,
        valueOperator: 1000
    }));

    useEffect(() => {
        setInputValue(parseValue(content, {
            hasOperator: true,
            valueOperator: 1000
        }));
    }, [content]);

    const handleValue = (event) => {
        const {value} = event.target; 
        
        const parsedValue = parseValue(value.replace('.', ''));
        setInputValue(parsedValue);
    };

    return (
        <CountInputWrapper width={sizes?.totalWidth}>
            <CountInputLabel width={sizes?.label}>{label}</CountInputLabel>
            <div style={{width: sizes?.input}}>
                <input 
                    type='number' 
                    value={inputValue} 
                    onBlur={() => handleInputValue(inputValue)} 
                    onChange={handleValue}
                    inputMode="decimal"
                    pattern="[0-9]*"
                    style={{width: "100%"}}
                />
            </div>
        </CountInputWrapper>
    );
}

export default MobileInputNumber;
