import styled from 'styled-components';

export const OptionsWrapp = styled.div`
    position: relative;
`;

export const BoardOptions = styled.div`
    margin-left: 15px;
    transition: all 400ms ease;
    font-size: 12px;
    color: #000000;
    cursor: pointer;

    span {
        color: #000000;
        border-bottom: solid 1px #000;
        padding-bottom: 2px;
    }
`;

export const OptionsContainer = styled.div`
    transition: all 400ms ease;
    display: ${({hidden}) => hidden ? 'none' : 'block'};
    width: 120px;
    top: 25px;
    text-align: center;
    position: absolute; 
    z-index: 99;
    color: #8B97F2;
    font: Lato; 
    font-size: 12px; 
    background-color: white;
    right: -15px;
    border: 1px solid #77798B;
    border-radius: 0px 0px 4px 4px;
`; 

export const OptionName = styled.p`
    width: 100%;
    padding: 7px;
    transition: all 400ms ease;
    cursor: pointer;
    color: #080B30;
    &:hover{
        background-color: rgba(80, 98, 240, 0.15);
    }
`;

export const ImageIcon = styled.img`
    transition: all 400ms ease;
    margin-left: 4px;
    transform: ${({open}) => open ? 'none' : 'rotate(180deg)'};
`;
