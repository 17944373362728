import styled from 'styled-components'

export const MainGrid = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 8px rgba(4, 6, 26, 0.25);
  border-radius: 4px;
  width: ${props => props.isModal ? "100%" : "32%"};
  float: left;
  margin-left: 25px;
  margin-bottom: ${props => props.isModal ? "0" : "15px"};
  padding-bottom: ${props => props.isModal ? "10px" : "0"};

  &:first-of-type,
  &:nth-of-type(3n + 1) {
    margin-left: 0;
    clear: left;
  }

  &:last-of-type,
  &:nth-last-of-type(2),
  &:nth-last-of-type(3) {
    margin-bottom: 0;
  }
  
  .tipGrid {
    margin: 0 10px 10px;
    background: rgba(80, 98, 240, 0.25);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    padding: 10px;

    span {
      font-style: normal;
      font-weight: normal;
      line-height: 16px;
      text-align: center;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #5062F0;
      height: 100%;
      overflow: hidden;
    }
  }

  .improveGrid {
    width: 100%;
    padding: 10px 17px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    span {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      line-height: 12px;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #313347;
    }

    .items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 12px 60px;
    }

    .graph {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .imgBox {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      strong {       
        font-weight: bold;
        font-size: 12px;
        margin-top: 6px;
        color: #5062F0;
      }

      span {
        font-size: 12px;
        font-weight: normal;
        margin-top: 4px;
      }
    }     
  }

  .header {
    .red,
    .green {
      display: flex;
      flex-direction: row;
      padding: 13px 17px;
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 4px 4px 0px 0px;
      height: 66px;
    }

    .red {
      background: #773795;
    }

    .green {
      background: #2F8591;
    }

    .buttonBox {
      display: flex;
      align-items: flex-start;
      height: 100%;

      button {
        border: none;
        background-color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 12px;
        text-transform: uppercase;
        color: #5062F0;
      }
    }

    span {
      padding-right: 17px;
      font-style: normal;
      font-weight: bold;
      font-size: 15px !important;
      color: #FFFFFF;
    }
  }

  .completeList {
    border-bottom: 1px solid rgba(80, 98, 240, 0.25);
    margin: 10px 17px 0;

    button {
      border: 1px solid #313347;
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      background-color: #FFFFFF;
      margin-bottom: 10px;
      font-size: 12px;
      padding: 4px 8px;
    }
  }

  .options {
    border-bottom: 1px solid rgba(80, 98, 240, 0.25);
    width: 100%;
    padding: 10px 17px 0;
    display: flex;
    flex-direction: column;
    
    span {
      font-family: 'Lato';
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      text-transform: uppercase;
      font-feature-settings: 'tnum' on, 'lnum' on;
      color: #313347;
      margin-bottom: 10px;
    }

    .items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        font-feature-settings: 'tnum' on, 'lnum' on;
        color: #5062F0;
      }

      input {
        background: #FFFFFF;
        height: 32px;
        margin-left: 8px;
        border: 1px solid #5062F0;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px 15px 15px 8px;
        width: 100%;
        color: #313347;
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        cursor: pointer;
        margin-bottom: 12px;
      }
    }
  }
`
