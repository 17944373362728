import Environment from '../../utils/environments';

const env = Environment(process.env.REACT_APP_ENV);

export const queries = {
  'dash-board-api-home': `${env.dashBoardV2}/home`,
  quotations: `${env.catalog}/quotations`,
  quotationOrders: `${env.orders}/quotations`,
  quotationLastOrders: `${env.orders}/quotations/orders/`,
  optionsQuotation: `${env.catalogV2}/quotations/options`,
  suppliersContacts: `${env.catalog}/suppliers/contacts`,
  categoriesFilter: `${env.catalog}/categories`,
  quotationManager: `${env.catalog}/quotations/manager/list`,
  modalUpdateQuotationManagerSuppliers: `${env.orders}/quotations/suppliers`,
  modalQuotationManagerSuppliers: `${env.catalogV2}/quotations/providerSettings`,
  modalUpdateQuotationManagerPackages: `${env.orders}/quotations/packages`,
  modalQuotationManagerPackages: `${env.catalogV2}/quotations/providerSettings/packages`,
  updateQuotationBrands: `${env.orders}/quotations/brands`,
  quotationBrands: `${env.catalogV2}/quotations/brands`,
  modalQuotationManagerComparation: `${env.catalog}/extracts/shopping`,
  tagCreation: `${env.catalog}/tags`,
  quotationsHistory: `${env.catalog}/quotations/history/`,
  quotationAutoCompleteCategories: `${env.catalog}/quotations/manager/autocomplete/categories`,
  quotationPrioritySkus: `${env.catalog}/quotations/skus/request`,
  categories: `${env.catalog}/categories`,
  productSetups: `${env.catalog}/products/inventories/setups`,
  modalOutOfCountProducts: `${env.catalog}/products/inventories/setups`,
  modalInflation: `${env.catalog}/quotations/manager/inflation`,
  modalInflationV2: `${env.catalogV2}/quotations/inflation`,
  modalStoreConsumption: `${env.catalog}/quotations/manager/consumed`,
  quotationStoreConsumption: `${env.catalogV2}/quotations/purchases`,
  quotationBuyIntention: `${env.catalogV2}/quotations/intention`,
  quotationBuyIntentionDiff: `${env.catalogV2}/quotations/intentionDiff`,
  quotationIntention: `${env.orders}/quotations/intention`,
  modalPurchaseSuggestions: `${env.catalog}/quotations/manager/suggestion`,
  modalSkusQuotation: `${env.catalog}/quotations/manager/skus`,
  modalSkusQuotationV2: `${env.catalogV2}/quotations/skus`,
  modalStockPerStore: `${env.catalog}/quotations/manager/stocks`,
  modalTradingHistory: `${env.catalog}/quotations/manager/historical`,
  modalProvires: `${env.catalog}/quotations/manager/providers`,
  quotationsSkuResponse: `${env.catalog}/quotations/skus/request`,
  suggestionByProvider: `${env.orders}/orders/suggestionproviders`,
  quotationsPlatformSpreadsheet: `${env.catalog}/quotations/platform/spreadsheet`,
  quotationManagerSpreadsheets: `${env.orders}/quotations/products/upload`,
  providersQuotes: `${env.providers}/providers`,
  quotationProviders: `${env.orders}/quotations/options`,
  providerStatus: `${env.catalog}/providers/status`,
  skuCards: `${env.catalog}/skus/`,
  skuCardsByGroup: `${env.orders}/cache/products/uniquecards/`,
  skuCard: `${env.catalog}/skus`,
  skuPlatformCards: `${env.catalog}/skus/platform`,
  countsDivergentDiferences: `${env.catalog}/inventories/counts/`,
  countsDifferences: `${env.catalog}/inventories/counts/`,
  countsDifferencesPeriodicity: `${env.catalog}/inventories/counts/periodicity/`,
  saveInventoriesProducts: `${env.catalog}/inventories/counts/`,
  inventories: `${env.catalog}/inventories/`,
  productsCatalog: `${env.catalog}/products/`,
  saveDisplaysettings: `${env.catalog}/inventories/counts/displaysettings`,
  justificationTypes: `${env.catalog}/justifications/types`,
  changePassword: `${env.auth}/users/`,
  providersCatalog: `${env.catalog}/providers/catalog`,
  providersClients: `${env.catalog}/providers/clients`,
  providersGraph: `${env.catalog}/providers/graph`,
  ordersEndPoint: `${env.orders}/orders/`,
  restaurants: `${env.restaurants}/companies`,
  extracts: `${env.catalogV2}/extracts`,
  extractDiscards: `${env.catalog}/extracts/discards`
};
