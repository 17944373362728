import styled from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const DropDownContainer = styled.div`
    display: flex;
    width: 250px;
`;

export const ConfirmEditContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    width: 97px;
    height: 32px;
    background-color: #202B85; 
    border-radius: 4px;
`;

export const ImgIconEdit = styled.img`
    align-self: center;
`;

export const IconDropDownWrap = styled.div`
    display: flex;
    justify-content: space-between;
    width: 45px;
    margin-left: 14px;
    margin-top: 24px;
`;

export const IconWrap = styled.div`
    height: 100%;
    background-color: ${({bgColor}) => bgColor};
`;

export const StyledAutoComplete = styled(Autocomplete)`
    width: 200px;
    &::before{
        border-bottom: none;
    }
`;

export const ImgIconContainer = styled.div`
    text-align: center;
    cursor: pointer;
    width: 33%;
    height: 32px;
    display: grid;
    justify-content: center;
    background-color: ${({bgColor}) => bgColor};
    border-radius: 0px 4px 4px 0px;
`;
