import styled from 'styled-components';

export const BottomBarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 12px 0 12px 0px;
    font-size: 12px;
    font-weight: 600;
`;

export const SenDQuotationButton = styled.button`
    display: none;
    @media only screen and (min-width: 960px) {
        display: flex;

    }
    border: none;
    outline: none;
    padding: 8px 12px;
    color: white;
    background-color: ${({block}) => block ? '#D4D6E5' : '#5062F0'};
    border-radius: 50%;
    width: 48px;
    height: 48px;
    opacity: 0.8;
    font-size: 27px;
    z-index: 9000;
    bottom: 25px;
    right: 25px;
    position: fixed;
    justify-content: center;
    align-items: center;
}
`;

export const PaginationStyled = styled.div`
    nav {
        ul {
            li{
                font-family: "Lato";
                margin: 12px 0 10px 0;
                justify-content: flex-start;
                display: flex;

                button {
                    border-radius: 8px !important;
                }

                .MuiPaginationItem-outlinedPrimary {
                    border-color: #313347;
                
                    svg {
                        color: #313347!important;
                    }

                    &.Mui-selected {
                    background: #5062F0!important;
                    color: #FFF!important;
                    }
                }
            }
        }
    }
       
    
`;

export const InputFile = styled.input`    
    width: 100%;
    opacity: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
`;
