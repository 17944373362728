/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import ReactGA from 'react-ga4';

import orderArrowBlue from 'images/icons/arrows/arrowDownGrayOutline.svg';
import orderArrow from 'images/icons/arrows/orderArrow.svg';
import orderbyBinary from 'images/icons/arrows/orderbyBinary.svg';
import orderbyBinaryGray from 'images/icons/arrows/orderbyBinaryGray.svg';

import { ArrowIcon } from './style.index';

function OrderBy({
  filterName,
  handleFilter,
  tinyArrow,
  outSideArrowPosition = null,
  ascFirst = false,
  orderbyBinIcon = false,
  ordenationisTouched = false,
  customFilter,
  orderBy,
  customMargin
}) {
  const [ordenationArrow, setOrdenationArrow] = useState(!ascFirst);
  const [ordernationUntouched, setOrdernationUntouched] =
    useState(ordenationisTouched);
  const handleOrderBy = () => {
    !outSideArrowPosition && setOrdenationArrow(!ordenationArrow);
    const orderClassification = ordenationArrow ? 'desc' : 'asc';
    const outSideOrderClassification = outSideArrowPosition ? 'desc' : 'asc';

    const dictionaryEvent = {
      description: 'Produto',
      category: 'Categoria',
      skus: 'SKUS',
      unitaryValue: 'UnidRS',
      packageValue: 'EmbRS',
      lastCost: 'UltRS',
      totalQuantity: 'Compras',
      totalValue: 'VolRS',
      bloomEconomyValue: 'FornecEmbRSEstimado',
      potentialEconomyValue: 'MarcaRSEstimado'
    };

    ReactGA.event({
      category: 'Ordenacao',
      action: 'Clique',
      label: dictionaryEvent[filterName] || filterName
    });
    handleFilter([
      ['orderBy', filterName],
      [
        'orderClassification',
        outSideArrowPosition ? outSideOrderClassification : orderClassification
      ]
    ]);
    setOrdernationUntouched(true);
  };
  const srcArrow = tinyArrow ? orderArrowBlue : orderArrow;

  return (
    <span
      style={{ margin: customMargin || '5px' }}
      onClick={() => {
        if (customFilter) {
          customFilter(filterName, orderBy);
          setOrdernationUntouched(!ordernationUntouched);
        } else {
          handleOrderBy();
        }
      }}
    >
      <ArrowIcon
        src={ordernationUntouched ? orderbyBinary : orderbyBinaryGray}
        orderSet={
          outSideArrowPosition !== null
            ? !outSideArrowPosition
            : ordenationArrow
        }
        tinyArrow={tinyArrow}
        orderbyBinIcon
      />
    </span>
  );
}

export default OrderBy;
