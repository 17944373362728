/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { Tooltip } from '@material-ui/core';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

import { completeNumberWithDecimal } from 'utils/completeNumberDecimal';

import {
  InputManufactureWrapper,
  InputManufactureInput,
  InputManufactureInputContainer
} from '../styles';

function InputManufacture({
  objectToChange,
  updatedManufacture,
  label,
  keyChange,
  setUpdate,
  isMobile,
  onKeyDown,
  handleChangeDebounce,
  modalStates,
  setModalStates
}) {
  const initialValue = parseValue(
    objectToChange.initialYield
      ? objectToChange.yield
      : objectToChange.type.toLowerCase() === 'recipe'
      ? objectToChange.yield
      : (objectToChange.quantity || 1) -
        (objectToChange.quantity || 1) * (objectToChange.loss / 100),
    {
      hasOperator: true,
      valueOperator: 1000
    }
  );
  const [inputValue, setInputValue] = useState('');
  const { un: { abbreviation } = {} } = objectToChange;
  const [debounceTimeList, setDebounceTimeList] = useState([]);

  const updateValue = (value) => {
    if (value === '0.000') {
      objectToChange[keyChange] = '';
      setInputValue(initialValue);

      setModalStates({
        ...modalStates,
        modalInvalidQuantity: true
      });

      return;
    }

    setUpdate(parseFloat(value.replace(',', '.')));
  };

  const handleInputValue = (e) => {
    const { target: { value } = {} } = e;

    const newValue = completeNumberWithDecimal(
      parseInt(value.replace('.', ''))
    );

    const formattedValue = isMobile ? newValue : value;
    const parsedValue = parseFloat(formattedValue.replace(',', '.')).toFixed(3);

    if (!value) {
      objectToChange[keyChange] = '';
      setUpdate('');
      setInputValue('');
      return;
    }

    if (isMobile) {
      setInputValue(parsedValue);
      objectToChange[keyChange] = parsedValue;
    } else {
      setInputValue(value);
      objectToChange[keyChange] = parseFloat(value.replace(',', '.'));
      setUpdate(parseFloat(value.replace(',', '.')));
    }

    if (handleChangeDebounce) {
      debounceTimeList.forEach((el) => clearTimeout(el));

      const newTimer = setTimeout(() => {
        updateValue(parsedValue);
      }, 200);

      setDebounceTimeList([...debounceTimeList, newTimer]);
    }
  };

  useEffect(() => {
    setUpdate(inputValue);
  }, []);

  return (
    <Tooltip
      title="O valor inserido é maior que o insumo, está realmente correto?"
      placement="bottom"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
      className="productionTooltipAlert"
      open={updatedManufacture > objectToChange.quantity * objectToChange.yield}
    >
      <InputManufactureWrapper>
        <InputManufactureInputContainer>
          {isMobile ? (
            <InputManufactureInput
              onKeyDown={onKeyDown}
              onChange={handleInputValue}
              onBlur={(e) =>
                !handleChangeDebounce && updateValue(e.target.value)
              }
              value={inputValue}
              type="number"
              inputMode="decimal"
              pattern="[0-9]*"
              onFocus={(e) => {
                e.currentTarget.select();
              }}
              align="right"
            />
          ) : (
            <CurrencyTextField
              onKeyDown={onKeyDown}
              variant="outlined"
              size="small"
              currencySymbol=""
              minimumValue="0"
              outputFormat="string"
              decimalCharacter=","
              digitGroupSeparator="."
              decimalPlaces={3}
              decimalPlacesShownOnFocus={3}
              required
              name="quantity"
              fullWidth
              value={inputValue}
              type="text"
              inputMode="numeric"
              className="textFieldSmall right childInputFontSize16"
              placeholder="Digite aqui..."
              onBlur={handleInputValue}
              InputProps={{ style: { fontFamily: 'Lato', fontSize: '16px' } }}
            />
          )}
          <span className="ml5 mt5">{abbreviation}.</span>
        </InputManufactureInputContainer>
      </InputManufactureWrapper>
    </Tooltip>
  );
}

const parseValue = (
  value,
  { hasOperator, valueOperator } = { hasOperator: false, valueOperator: 0 }
) => {
  const operator = hasOperator && valueOperator ? value * valueOperator : value;
  const newValue = completeNumberWithDecimal(parseInt(operator, 10));
  return parseFloat(newValue.replace(',', '.')).toFixed(3);
};

export default InputManufacture;
