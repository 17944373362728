import numberToReal from './numberToReal';

export const unityToPackage = (unity, quantity) => {
  if (!unity || !quantity) return '0,00';
  return unity * quantity;
};

export const packageToUnity = (unity, packagePrice) => {
  if (unity === 0) return 0;
  return packagePrice / unity;
};

export const percentDiff = (cost, lastCostExtract) => {
  if (!cost || !lastCostExtract) return '0,00';
  const increase = cost - lastCostExtract;
  const percentage = (increase / lastCostExtract) * 100;
  return numberToReal(percentage, 2);
};

export const getInflationRangeString = (a, b, string) => {
  if (!a || !b) {
    return {
      content: `${string}`,
      color: true
    };
  }

  if (b < a) {
    return {
      content: `${string}`,
      color: true
    };
  }

  if (b > a) {
    return {
      content: `+ ${string}`,
      color: false
    };
  }

  if (a === b) {
    return {
      content: `${string}`,
      color: true
    };
  }
};
