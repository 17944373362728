const removeUnityMeasurementsFromString = (textToTransform = "") => {
    const regex = new RegExp(/([kg un g ml])\w+/);
    const newText = textToTransform.replace(regex, "");
    return newText.replace("g", ""); 
};

export const formatInflationValue = (inflation = "") => {
    if (inflation === "-") return "0%"
    return `${inflation}%`; 
}

export default removeUnityMeasurementsFromString;
