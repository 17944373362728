/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Snackbar,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Axios from 'axios';
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import PaperComponent from 'components/PaperComponent';
import CloseIconDialog from 'images/icons/signals/closesAndCancels/closeDialog.svg';
import * as UserActions from 'store/actions/user';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

function ConsumeQuantities({
  open,
  handleCloseModal,
  selectedCard,
  parameters
}) {
  const userInfo = useSelector((currState) => currState.user);
  const environments = Environment(process.env.REACT_APP_ENV);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentYield = selectedCard?.consumeDialogByItem
    ? selectedCard?.consumeDialogByItem?.quantity
    : selectedCard?.yield;
  const currentEngineYield = selectedCard?.consumeDialogByItem
    ? (selectedCard?.consumeDialogByItem?.engine?.yield || 0) + (selectedCard?.consumeDialogByItem?.stock || 0)
    : selectedCard?.type === "RECIPE" ? (selectedCard?.stock || 0) + (selectedCard?.engine?.yield || 0) : selectedCard?.engine?.yield;

  const dayOfWeek = (value) => {
    if (value === 1) {
      return 'Segunda';
    }

    if (value === 2) {
      return 'Terça';
    }

    if (value === 3) {
      return 'Quarta';
    }

    if (value === 4) {
      return 'Quinta';
    }

    if (value === 5) {
      return 'Sexta';
    }

    if (value === 6) {
      return 'Sábado';
    }

    if (value === 7) {
      return 'Domingo';
    }
  };

  useEffect(() => {
    const nextScheduleInfo = selectedCard?.nextScheduleDate
      ? `nextScheduleDate=${selectedCard?.nextScheduleDate}`
      : `nextScheduleDate=${moment()
          .add((selectedCard?.quantityValidDays || 0) - 1, 'days')
          .format('DD/MM')}`;

    const productId = selectedCard?.consumeDialogByItem
      ? `&productId=${selectedCard?.consumeDialogByItem?.id}`
      : '';

    setLoading(true);

    Axios.get(
      `${environments.engine}/forecast/manufacture/${selectedCard?._id}/items?${nextScheduleInfo}${productId}`
    )
      .then((response) => {
        setData(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [userInfo.companiesActive]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCloseModal}
      className="itemsDiffQuantitiesPopover"
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <article
        id="draggable-dialog-title"
        className="itemsDiffQuantitiesPopoverHeader cursorMove"
        style={{ background: 'rgba(80, 98, 240, .85)' }}
      >
        <span className="white fontSize16">
          Consumo das fichas relacionadas
        </span>

        <IconButton
          className="pAbsolute"
          edge="start"
          color="inherit"
          onClick={() => {
            handleCloseModal();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right', right: 0 }}
        >
          <img
            src={CloseIconDialog}
            alt="CloseIconNavy"
            style={{ width: 12 }}
          />
        </IconButton>
      </article>

      <DialogContent className="modalContent onlyTable bgWhite dFlex flexColumn p0">
        <div className="cardDescriptionBox">
          <p className="mb0">
            {ValidationLength(
              `${selectedCard?.description} ${
                selectedCard?.consumeDialogByItem
                  ? `- ${selectedCard?.consumeDialogByItem.item}`
                  : ''
              }`,
              50
            )}
          </p>
        </div>

        <section className="tableInfosBox">
          <div className="dFlex alignCenter justifyEnd">
            <p className="mb0">Qtde definida para esta produção:</p>

            <span className="bold" style={{ color: '#F53D4C' }}>
              {currentYield?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })}{' '}
              {selectedCard?.un?.abbreviation}
            </span>
          </div>

          <div className="dFlex alignCenter justifyEnd">
            <p className="mb0">Consumo previsto total:</p>

            <span className="bold">
              {currentEngineYield?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })}{' '}
              {selectedCard?.un?.abbreviation}
            </span>
          </div>

          <table className="desktop">
            <thead>
              <tr className="headerTitle">
                <th width="59%" className="subtitle">
                  FICHAS RELACIONADAS
                </th>

                <th width="18%" className="subtitle">
                  PRÓXIMA AGENDA
                </th>

                <th width="23%" className="subtitle right">
                  CONSUMO PREVISTO
                </th>
              </tr>
            </thead>

            <tbody>
              {loading && (
                <tr>
                  <th colSpan="3" className="m10 textAlignCenter">
                    <LinearProgress
                      style={{ height: '7px' }}
                      id="loading"
                      variant="query"
                    />
                  </th>
                </tr>
              )}

              {data?.items?.length ? (
                data.items.map((item, itemIndex) => {
                  const isLastElement = itemIndex === data.items.length - 1;
                  const unit = item?.un?.abbreviation || '';
                  const currentQuantity =
                    parameters?.salesProjection?.lastSuggestion ===
                    'ENGINE_PROJECTION'
                      ? item?.quantity
                      : item?.quantityManual || item?.quantity;

                  return (
                    <>
                      <tr className="lineBorder">
                        <td className="description pl12">
                          {ValidationLength(item?.description, 35)}
                        </td>

                        <td className="description pr0">
                          {dayOfWeek(item?.nextSchedule)}
                        </td>

                        <td className="description right pr12">
                          {currentQuantity?.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}{' '}
                          {unit}
                        </td>
                      </tr>

                      {!isLastElement && (
                        <tr>
                          <td colSpan="3" className="pr0 pl0 pt0 pb0">
                            <hr
                              className="titleLineBorder m0"
                              style={{
                                height: '0px',
                                backgroundColor: '#D0D3E2'
                              }}
                            />
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3" className="description textAlignCenter">
                    <Typography className="m5 bold">
                      Não há items a serem exibidos.
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>

        <Snackbar
          open={!!message.description}
          autoHideDuration={2000}
          onClose={() => {
            message.status !== 'error' &&
              setMessage({ ...message, description: '', status: '' });
          }}
        >
          <Alert
            onClose={() => {
              message.status !== 'error' &&
                setMessage({ ...message, description: '', status: '' });
            }}
            severity={message.status}
          >
            {message.description}
          </Alert>
        </Snackbar>
      </DialogContent>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConsumeQuantities);
