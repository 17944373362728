/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import ReactGA from 'react-ga4';

const handleFilterOptions = (
  param,
  value,
  filters,
  setFiltersChanged,
  extraValue,
  noProductSkuCleanFilter = false,
  defaultPage = 1,
  callSettingsOptions
) => {
  const productsParamIndex = filters.findIndex(
    (filter) =>
      filter?.param === 'products' || filter?.filterName === 'products'
  );
  const skusParamIndex = filters.findIndex(
    (filter) => filter?.param === 'skus' || filter?.filterName === 'skus'
  );
  const pageParamIndex = filters.findIndex(
    (filter) => filter?.param === 'page' || filter?.filterName === 'page'
  );
  const index = filters.findIndex(
    (filter) => filter?.param === param || filter?.filterName === param
  );

  ReactGA.event({
    category: 'Filtros',
    action: 'Filtrar',
    label: param
  });

  if (index < 0) {
    if (value !== null || value === 0) {
      if (extraValue) {
        filters.push({
          param,
          value: param === 'products' ? extraValue.productId : extraValue.skuId,
          urlParam: `${param}=${
            param === 'products' ? extraValue.productId : extraValue.skuId
          }`
        });
        filters.push({
          param: param === 'products' ? 'skus' : 'products',
          value,
          urlParam: `${param === 'products' ? 'skus' : 'products'}=${value}`
        });
      } else {
        filters = [
          ...filters,
          {
            param,
            value,
            urlParam: `${param}=${encodeURI(value)}`
          }
        ];
      }
    }
  } else if (extraValue) {
    filters[productsParamIndex] = {
      ...filters[productsParamIndex],
      value: param === 'products' ? extraValue.productId : extraValue.skuId,
      urlParam: `${param === 'products' ? 'skus' : 'products'}=${
        param === 'products' ? extraValue.productId : extraValue.skuId
      }`
    };
    filters[skusParamIndex] = {
      ...filters[skusParamIndex],
      value: param === 'products' ? extraValue.productId : extraValue.skuId,
      urlParam: `${param === 'products' ? 'skus' : 'products'}=${
        param === 'products' ? extraValue.productId : extraValue.skuId
      }`
    };
  } else if (value !== null || value === 0) {
    filters = filters.map((el, elIndex) => {
      if (elIndex === index) {
        return {
          ...el,
          [param]: value,
          value: value || '',
          textValue: value,
          urlParam: `${param}=${value}`
        };
      }

      return {
        ...el
      };
    });
  } else {
    if (noProductSkuCleanFilter) {
      filters.splice(index, 1);
    }
    if (!noProductSkuCleanFilter) {
      filters.splice(productsParamIndex, 1);
      filters.splice(skusParamIndex, 1);
      filters.splice(index, 1);
    }
  }

  const hasSetedFilters = filters.find((filter) => {
    const { param, value } = filter;
    if (param !== 'page' && param !== 'originId' && param !== 'size') {
      if (value) {
        return filter;
      }
    }
    return null;
  });

  if (pageParamIndex >= 0 && hasSetedFilters && param !== 'page') {
    filters[pageParamIndex] = {
      ...filters[pageParamIndex],
      value: defaultPage,
      urlParam: `page=${defaultPage}`
    };
  }
  if (pageParamIndex < 0) {
    if (callSettingsOptions) {
      const { callSettings, setCallSettings } = callSettingsOptions;
      setCallSettings({ ...callSettings, page: defaultPage });
    }
  }

  setFiltersChanged(filters);
};

export default handleFilterOptions;
