import React from 'react';

import { Tooltip } from '@material-ui/core';

import InfoQuestionIcon from 'images/icons/signals/questionMarks/infoQuestionIcon.svg';

import { InfoIcon } from './style';

function InfoIconTooltip({title}) {
    return(
        <Tooltip title={title}>
            <InfoIcon src={InfoQuestionIcon} />
        </Tooltip>
    );
}

export default InfoIconTooltip;
