/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

import { LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';

import Button from 'components/BloomForms/Button';
import AutoCompleteField from 'components/PageTitleAndFilter/AutoCompleteField/AutoCompleteField';
import CloseWhiteIcon from 'images/icons/signals/closesAndCancels/closeModal.svg';

import {
  OtherStocksWrapper,
  Content,
  ContentHead
} from '../OtherStocksDialog/index.style';

function CountDialog({
  reference,
  currentData,
  onClose,
  countID,
  countDate,
  assortmentDate,
  paramsID
}) {
  const [loading, setLoading] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [enableButton, setEnableButton] = useState(true);
  const [redirectLink, setRedirectLink] = useState(null);

  useEffect(() => {
    const options = currentData?.products?.map((product, productIndex) => {
      const { description } = product;

      return {
        ...product,
        value: description,
        description,
        productIndex
      };
    });
    setOptionsList(options);
  }, []);

  const handleFilterOptions = (filterName, value, filter) => {
    if (!value) {
      setEnableButton(true);
      return;
    }

    setSelectedOption(filter);
    setEnableButton(false);
  };

  const handleSearchButton = () => {
    const { productIndex } = selectedOption;
    if (!selectedOption) return;

    const url = {
      pathname: `/inventario/contagem-diaria-mobile/${paramsID}/${productIndex}/?countId=${countID}&countDate=${countDate}&categoryDescription=&assortmentDate=${assortmentDate}&selectItem=${productIndex}&backCountButton=true`,
      key: Math.random(),
      state: {
        applied: true
      }
    };

    setRedirectLink(url);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  return (
    <OtherStocksWrapper>
      <Content ref={reference}>
        <ContentHead>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              Encontrar produto
            </div>

            <div>
              <img src={CloseWhiteIcon} alt="Close icon" onClick={onClose} />{' '}
            </div>
          </div>
        </ContentHead>

        <div style={{ padding: '8px' }}>
          {loading && <LinearProgress />}

          <div style={{ marginTop: '12px' }}>
            <AutoCompleteField
              label="Produtos"
              placeHolder="digite..."
              options={optionsList}
              handleFilter={handleFilterOptions}
              size="large"
              getFilterhandle={() => {}}
              filterName="searchProduct"
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '8px'
            }}
          >
            <Button
              label="Pesquisar"
              primary
              disable={enableButton}
              onClick={handleSearchButton}
            />
          </div>
        </div>
      </Content>
      
      {redirectLink && <Redirect to={redirectLink.pathname} />}
    </OtherStocksWrapper>
  );
}

export default CountDialog;
