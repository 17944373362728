import React, { useState, useEffect } from 'react';

import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  Modal,
  Snackbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import ItemsPerPage from 'components/ItemsPerPage';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CancellationReasonsForm from 'pages/admin/cancellationReasons/create';
import * as CancellationReasonsActions from 'store/actions/cancellationReasons';
import { GlobalStyle, Container } from 'styles/general';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

function MotivosCancelamento({ userInfo, setCancellationReasons, createCancellationReasons }) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [callSettings, setCallSettings] = useState({
    getId: userInfo.companiesActive.id,
    page: 1,
    totalPages: 0,
    size: 100,
    mainContent: [],
    numberOfElements: 0,
    loading: true,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    mainContent,
    numberOfElements,
    loading,
    totalElements
  } = callSettings;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [state, setState] = useState('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [deleteData, setDeleteData] = useState();
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [deleteError, handleDeleteError] = useState(false);
  const [cancellationReasonsModal, setcancellationReasonsModal] = useState(false);
  const [updateList, setUpdateList] = useState(false);
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  // Get Motivos de Cancelamento
  useEffect(() => {
    (async () => {
      setCallSettings({ ...callSettings, loading: true });
      const response = await fetch(
        `${environments.catalog}/discards${getId?.[1] ? '/groups' : ''}?size=${size}&page=${page}&originId=${getId}&type=MANUFACTURE`
      );
      const CancellationReasons = await response.json();
      setCallSettings({
        ...callSettings,
        totalPages: CancellationReasons.totalPages,
        mainContent: CancellationReasons.content || [],
        numberOfElements: CancellationReasons.numberOfElements,
        loading: false,
        totalElements: CancellationReasons.totalElements
      });
    })();
  }, [getId, page, updateList, deleteSuccess]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: userInfo.companiesActive.id });
  }, [userInfo]);

  // Delete
  function handleDelete() {
    new Promise((resolve, reject) => {
      const baseUrl = `${environments.catalog}/discards`;
      const data = mainContent;
      const index = data.indexOf(deleteData);
      mainContent.splice(index, 1);
      setState(mainContent);

      axios
        .delete(`${baseUrl}/${deleteData.id}`, {
          method: 'DELETE',
          user: userInfo.user,
          headers: {
            User: `${userLocal?.user}`
          }
        })
        .then((response) => {
          resolve();
          handleDialogClose();
          handleDeleteSuccess(true)
        })
        .catch((error) => {
          console.log(error);
          reject();
        });
    });
  }

  const togglecancellationReasonsModal = () => {
    setcancellationReasonsModal(!cancellationReasonsModal);
    
    if (cancellationReasonsModal === false) {
      setUpdateList(false);
    }
  };

  const addCancellationReasons = () => (
    <Tooltip title="Cadastrar Motivos de Cancelamento" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          togglecancellationReasonsModal();
          createCancellationReasons();
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  return (
    <Layout>
      <TitleHead title="Motivos de Cancelamento" />
      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Motivos de Cancelamento"
          additionalbutton={addCancellationReasons}
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="30%" className="subtitle">
                DESCRIÇÃO
              </th>

              <th width="68%" className="subtitle">
                TIPO
              </th>

              <th width="2%" />
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th
                  colSpan="17"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  <LinearProgress id="loading" variant="query" />
                </th>
              </tr>
            )}

            {mainContent?.length ? (
              mainContent.map((cancellationReasons) => (
                <>
                  <tr key={cancellationReasons.id}>
                    <td className="p12">
                      <Link
                        onClick={() => {
                          setCancellationReasons(cancellationReasons);
                          togglecancellationReasonsModal();
                        }}
                      >
                        {ValidationLength(cancellationReasons.description || '-', 25)}
                      </Link>
                    </td>

                    <td>{cancellationReasons.type.description}</td>

                    <td width="2%">
                      <IconButton
                        aria-label="delete"
                        onClick={() => (
                          handleDialogClickOpen(), setDeleteData(cancellationReasons)
                        )}
                        style={{ padding: 0, outline: 0 }}
                      >
                        <DeleteOutline className="deleteIcon" />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="4">
                      <hr />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <th
                  colSpan="13"
                  style={{ margin: '10px', textAlign: 'center' }}
                >
                  Nada foi encontrado
                </th>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(event, page) =>
              setCallSettings({ ...callSettings, page })
            }
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>

        <Modal
          className="defaultModal modalRegisterContainer"
          open={cancellationReasonsModal}
          onClose={togglecancellationReasonsModal}
          aria-labelledby="Storage Location Modal"
        >
          <CancellationReasonsForm
            isModal
            redirectPath={null}
            onClose={togglecancellationReasonsModal}
            setUpdateList={setUpdateList}
          />
        </Modal>

        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            className="modalHeader navyBlue"
            style={{ fontWeight: 'bold', cursor: 'move', fontFamily: 'Lato' }}
            id="draggable-dialog-title"
          >
            <Typography>
              Deseja realmente excluir esse motivo de cancelamento?
            </Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography>
              Esta ação não pode ser desfeita. Deseja continuar?
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={handleDialogClose}
              label="Cancelar"
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              autoFocus
              design="contained"
              label="Confirmar"
              onClick={(e) => handleDelete()}
              color="primary"
            />
          </DialogActions>
        </Dialog>

        <Snackbar
          open={deleteSuccess}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteSuccess)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteSuccess)}
            severity="success"
          >
            Motivo de cancelamento excluído.
          </Alert>
        </Snackbar>

        <Snackbar
          open={deleteError}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteError)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteError)}
            severity="error"
          >
            Ocorreu um erro ao salvar as informações.
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CancellationReasonsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MotivosCancelamento);
