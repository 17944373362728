import styled from 'styled-components';

export const ChartContainer = styled.div`
    max-width: 100%;
    max-height: 100%;

    canvas {
        padding: 8px 8px 0px;
    }
`;
