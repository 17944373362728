/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState, useEffect, Suspense, lazy } from 'react';

import {
  Dialog,
  DialogActions,
  DialogTitle,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
  IconButton
} from '@material-ui/core';
import { default as MaterialButton } from '@material-ui/core/Button';
import { DeleteOutline } from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import axios from 'axios';
import Draggable from 'react-draggable';
import ReactGA from 'react-ga4';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Button from 'components/BloomForms/Button';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import FullScreenLoading from 'components/FullScreenLoading';
import ItemsPerPage from 'components/ItemsPerPage';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import DownloadIcon from 'images/icons/downloadUpload/download.svg';
import UploadIcon from 'images/icons/downloadUpload/upload.svg';
import BloomIcon from 'images/icons/logo/bloomIcon.svg';
import BloomIconBlue from 'images/icons/logo/bloomIconLarge.svg';
import RequestQuote from 'images/icons/objects/clipboards/providerIcon.svg';
import ApproveIcon from 'images/icons/signals/checks/blueChecked.svg';
import axiosInstanceOrders from 'services/middlewares/apis/orders_validate';
import {
  clearAll,
  handleProduct
} from 'store/reducers/quotationSelectProducts';
import { GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import numberToReal from 'utils/numberToReal';
import round from 'utils/roundNumber';
import ValidationLength from 'utils/utils';

import EconomyAndApproveCards from './cards';
import QuotationManagerTable from './QuotationManagerTable';
import handleFetchData from './utils';

import MultipleUploadDialog from '../admin/quoteSolicitations/dialogs/multipeUploadDialog';
import {
  BottomBarWrapper,
  InputFile,
  PaginationStyled
} from '../quotationManager/style';

const InflationDialog = lazy(() =>
  import('../quotationManager/dialogs/InflationDialog')
);
const SkusQuotationDialog = lazy(() =>
  import('../quotationManager/dialogs/SkusQuotationDialog')
);
const OptionsDialog = lazy(() =>
  import('./dialogs/OptionsDialog/OptionsDialog')
);
const ConsumeByStore = lazy(() =>
  import('./dialogs/ConsumeByStore/ConsumeByStore')
);
const BuyIntention = lazy(() => import('./dialogs/BuyIntention/BuyIntention'));
const DialogSupplier = lazy(() => import('./dialogs/DialogSupplier'));

const environments = Environment(process.env.REACT_APP_ENV);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function QuotationManagerProfile({ userInfo }) {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search
  });
  const role = userInfo.profile[0][0].name ?? '';
  const { id: paramsID } = useParams();
  const dispatch = useDispatch();
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 100,
    numberOfElements: 0,
    totalElements: 0
  });

  const { getId, size, page, totalPages, numberOfElements, totalElements } =
    callSettings;

  const originIds = userInfo.companies.groups
    .reduce((acc, group) => [...acc, group.subGroups], [])
    .flat()
    .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
    .flat()
    .map((item) => item.id);

  const [filters, setFilters] = useState([
    { param: 'page', value: 1, urlParam: `page=1` },
    {
      param: 'originsId',
      value: originIds,
      urlParam: `originsId=${originIds}`
    },
    {
      param: 'size',
      value: 50,
      urlParam: `size=${50}`
    }
  ]);
  const [data, setData] = useState();
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [productsIds, setProductsIds] = useState([]);
  const [inflationData, setInflationData] = useState({
    lastPurchase: null,
    percentage: null,
    unitaryValueConsumptionUnit: null,
    consumptionUnit: null
  });
  const [refreshFetch, setRefreshFetch] = useState();
  const [disabledApproveProduct, setDisabledApproveProduct] = useState();
  const [processing, setProcessing] = useState(false);
  const [currentProduct, setCurrentProduct] = useState();
  const [currentModalTitle, setCurrentModalTitle] = useState('');
  const [tableToModalProps, setTableToModalProps] = useState({});
  const [productGroupId, setProductGroupId] = useState();
  const [approveProductInfos, setApproveProductInfos] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [intentionTooltip, setIntentionTooltip] = useState();
  const [loadingIntention, setLoadingIntention] = useState(false);
  const [estimatedBloomEconomy, setEstimatedBloomEconomy] = useState();
  const [invertPositions, setInvertPositions] = useState({
    estimatedBloomEconomy: false,
    potentialEconomy: false,
    inflation: false,
    history: false,
    volume: false
  });
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [orderStatusFilter, setOrderStatusFilter] = useState([]);
  const [multipleUploadFiles, setMultipleUploadFiles] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [updateValues, setUpdateValues] = useState(false);
  const [modalStates, setModalStates] = useState({
    modalInflation: false,
    modalQuotationByVolume: false,
    optionsDialog: false,
    consumeByStore: false,
    dialogSupplier: false,
    dialogBrand: false,
    multipleUpload: false,
    buyIntention: false,
    confirmModal: false
  });

  const multipleFilesRef = React.useRef(null);

  const storedQuotationProductsSelected = useSelector(
    (currState) => currState.quotation.products
  );

  const storedQuotationPagesSelected = useSelector(
    (currState) => currState.quotation.selectedPages
  );

  const [modalStatesObj, setModalStatesObj] = useState({
    approveConfirmation: {
      open: false,
      productGroupId: ''
    },
    requestConfirmation: {
      open: false,
      productGroupId: ''
    }
  });

  const [selectedItems, setSelectedItems] = useState([
    {
      description: 'SEM QTDE',
      selected: false
    },
    {
      description: 'À APROVAR',
      selected: false
    },
    {
      description: 'APROVADO',
      selected: false
    },
    {
      description: 'INCLUÍDO',
      selected: false
    },
    {
      description: 'ANDAMENTO',
      selected: false
    },
    {
      description: 'PARCIAL',
      selected: false
    },
    {
      description: 'RECEBIDO',
      selected: false
    },
    {
      description: 'DIVERGENTE',
      selected: false
    }
  ]);

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'CATEGORIA',
        filterName: 'category',
        placeHolder: 'Selecione...',
        type: 'newTreeSelector',
        urlPath: `${environments.catalog}/categories`,
        key: 'products',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_custom',
        marginRight: '20px'
      },
      {
        label: 'PRODUTO',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.orders}/quotations/products`,
        key: 'ordersQuotation',
        optionReference: 'description',
        options: [],
        size: 'medium_custom',
        multiple: false,
        marginRight: '25px',
        searchByTermMode: true
      },
      {
        label: 'SKU',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/quotations/manager/autocomplete/skus`,
        key: 'skusDescription',
        optionReference: 'description',
        options: [],
        size: 'medium_custom',
        marginRight: '25px',
        searchByTermMode: true
      },
      {
        label: 'FORNECEDOR',
        filterName: 'providerDescription',
        filterNameSecond: 'providerId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/suppliers/groups`,
        key: 'providerDescriptions',
        optionReference: 'description',
        options: [],
        size: 'medium_custom',
        marginRight: '25px',
        searchByTermMode: true
      },
      {
        label: 'PROJEÇÃO DE COMPRA',
        filterName: 'typeQuantity',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        optionReference: 'typeQuantity',
        isSelect: true,
        marginRight: '25px',
        defaultValue: { title: 'Todas', value: '' },
        options: [
          { title: 'Todas', value: '' },
          { title: 'Definido pelo usuário', value: 'INTENTION' },
          { title: 'Sugestão Bloom', value: 'ENGINE' },
          { title: 'Histórico de compra', value: 'HISTORY' }
        ],
        size: 'medium_custom'
      },
      {
        label: 'COTAÇÃO',
        defaultValue: 'Todas',
        checkBoxInputs: [
          {
            name: 'Todas',
            referenceName: 'typeQuotation',
            value: ''
          },
          {
            name: 'Válidas',
            referenceName: 'typeQuotation',
            value: 'true'
          },
          {
            name: 'Invalidas',
            referenceName: 'typeQuotation',
            value: 'false'
          }
        ],
        type: 'multipleRadiobox'
      }
    ]
  ]);

  const [iconModalColor, setIconModalColor] = useState('gray');

  const [tableData, setTableData] = useState({
    header: [
      {
        name: 'CATÁLOGO',
        size: '160px',
        backgroundColor: '#D0D3E2',
        fontColor: '#1F2445',
        borderColor: '#1F2445',
        subHeaders: [
          {
            size: '20px'
          },
          {
            size: '20px'
          },
          {
            name: 'PRODUTO',
            size: '85px',
            order: {
              filterName: 'description',
              ordenationisTouched: true
            },
            fontColor: '#00094C',
            appendColumn: {
              name: 'CATEGORIA',
              size: '10%',
              order: {
                filterName: 'category',
                ordenationisTouched: true
              },
              fontColor: '#00094C'
            }
          },
          {
            name: 'SKUS',
            size: '10px',
            alignRight: true,
            order: {
              filterName: 'skus'
            },
            fontColor: '#00094C'
          }
        ]
      },
      {
        name: 'COTAÇÂO / PEDIDOS',
        size: '11%',
        backgroundColor: '#D0D3E2',
        borderColor: '#1F2445',
        fontColor: '#1F2445',
        subHeaders: [
          {
            name: 'UNID R$',
            size: '6%',
            alignRight: true,
            order: {
              filterName: 'unitaryValue'
            },
            fontColor: '#00094C'
          },
          {
            name: 'EMB R$',
            size: '5%',
            alignRight: true,
            order: {
              filterName: 'packageValue'
            },
            fontColor: '#00094C'
          },
          {
            name: 'OPÇÕES',
            size: '7%',
            textAlign: 'center',
            fontColor: '#00094C',
            padding: '4px 24px 4px 8px'
          }
        ]
      },
      {
        name: 'INFLAÇÃO',
        size: '8%',
        backgroundColor: '#D0D3E2',
        borderColor: '#1F2445',
        fontColor: '#1F2445',
        subHeaders: [
          {
            name: 'ÚLT R$',
            size: '5%',
            alignRight: true,
            order: {
              filterName: 'lastCost'
            },
            fontColor: '#00094C',
            switchTableInformation: true,
            label: 'inflation'
          }
        ]
      },
      {
        name: 'COMPRAS PROJEÇÃO / HISTÓRICO',
        size: '19%',
        backgroundColor: '#D0D3E2',
        borderColor: '#5062F0',
        fontColor: '#1F2445',
        subHeaders: [
          {
            name: 'QTDE',
            size: '5%',
            alignRight: true,
            order: {
              filterName: 'totalQuantity'
            },
            fontColor: '#00094C',
            switchTableInformation: true,
            label: 'history'
          },
          {
            name: 'VOL R$',
            size: '5%',
            alignRight: true,
            order: {
              filterName: 'totalValue'
            },
            fontColor: '#00094C',
            label: 'volume'
          }
        ]
      },
      {
        name: 'FORNECEDOR / EMB. / MARCA',
        size: '9%',
        backgroundColor: '#5062F0',
        borderColor: '#b0b5c9',
        subHeaders: [
          {
            name: 'R$ ESTIMADO',
            size: '9%',
            alignRight: true,
            order: {
              filterName: 'bloomEconomyValue'
            },
            fontColor: '#5062F0',
            switchTableInformation: true,
            label: 'estimatedBloomEconomy'
          }
        ]
      }
    ],
    collunms: [],
    tableActions: ['selectAllItens']
  });
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const openIntentionTooltip = (currentProductGroupId) => {
    setLoadingIntention(true);

    axios
      .get(
        `${environments.orders}/quotations/intentions/${currentProductGroupId}`
      )
      .then((res) => {
        setIntentionTooltip(res.data.content);
        setLoadingIntention(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setModalStates({
      modalSkusQuotation: false,
      optionsDialog: false,
      consumeByStore: false,
      dialogSupplier: false,
      dialogBrand: false,
      multipleUpload: false,
      buyIntention: false,
      confirmModal: false
    });
    setModalStatesObj({
      ...modalStatesObj,
      approveConfirmation: {
        open: false,
        productGroupId: ''
      },
      requestConfirmation: {
        open: false,
        productGroupId: ''
      }
    });
    setUpdateList(!updateList);
    setDisableButton(false);
  };

  const toggleCellPositions = (label) => {
    if (label === 'history') {
      setTableData({
        ...tableData,
        header: tableData.header.map((item) => {
          if (item.name === 'COMPRAS HISTÓRICO / PROJEÇÃO') {
            return {
              ...item,
              subHeaders: item?.subHeaders.map((sub) => {
                if (sub.name === 'HISTÓRICO' || sub.name === 'PROJEÇÃO') {
                  return {
                    ...sub,
                    name: sub.name === 'PROJEÇÃO' ? 'HISTÓRICO' : 'PROJEÇÃO'
                  };
                }
                return {
                  ...sub,
                  name: 'VOL R$',
                  order: {
                    ...sub.order,
                    filterName:
                      sub.order.filterName === 'totalValue'
                        ? 'totalVolumeProjection'
                        : 'totalValue'
                  }
                };
              })
            };
          }
          return { ...item };
        })
      });
    }

    const eventDictionary = {
      inflation: 'InflacaoIconeAlternar',
      estimatedBloomEconomy: 'FornecEmbIconeAlternar',
      potentialEconomy: 'MarcaIconeAlternar',
      history: 'HistoricoDeCompraAlternar'
    };
    ReactGA.event({
      category: !invertPositions[label]
        ? 'AlternarValor'
        : 'AlternarPorcentagem',
      action: 'Clique',
      label: eventDictionary[label] || 'Alternar'
    });
    const newPostions = {
      ...invertPositions,
      [label]: !invertPositions[label]
    };
    setInvertPositions({ ...newPostions });
  };

  const handleFilter = (
    param,
    value,
    statusDescription,
    paramSecond,
    statusValue
  ) => {
    const isParamDescription = param?.includes('Description');
    const hasAlreadyOptionFilter = filtersChanged?.filter(
      (el) => el?.param === 'option'
    ).length;
    const currentParam = param?.includes('Id')
      ? param?.replace('Id', 'Description')
      : param;

    let formattedFilters =
      isParamDescription && !hasAlreadyOptionFilter
        ? [
            ...filtersChanged,
            { param: 'option', value: 'part', urlParam: 'option=part' }
          ]
        : filtersChanged;

    const val = currentParam === 'restaurants' ? (!value ? '' : value) : value;

    if (typeof paramSecond === 'string') {
      if (
        (!val && isParamDescription) ||
        paramSecond?.includes('Description')
      ) {
        formattedFilters = filtersChanged?.filter(
          (el) => el?.param !== 'option'
        );
      }
    }

    if (currentParam === 'orderStatus') {
      setSelectedItems(
        selectedItems?.map((item) => {
          if (statusDescription === item.description) {
            return {
              ...item,
              selected: !item.selected
            };
          }
          return { ...item };
        })
      );

      setOrderStatusFilter(
        orderStatusFilter?.includes(statusValue)
          ? orderStatusFilter.filter((el) => el !== statusValue)
          : [...orderStatusFilter, statusValue]
      );
    }

    handleFilterOptions(
      currentParam,
      val,
      formattedFilters,
      setFiltersChanged,
      null,
      true
    );
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      originIds,
      false,
      1
    );
  };

  const handleMultipleFileChanges = (event) => {
    const { files } = event.target;
    if (files.length) {
      setMultipleUploadFiles(files);
      setModalStates({ ...modalStates, multipleUpload: true });
    }
  };

  const requestQuote = (productGroupId) => {
    setDisableButton(true);
    const body = {
      user: userInfo.user,
      productGroupIds: productGroupId
        ? [productGroupId]
        : storedQuotationProductsSelected
            ?.filter(
              (el) => el?.item?.statusRequestQuotation === 'NOT_REQUESTED'
            )
            ?.map((el) => el.item?.productGroupId) || null
    };

    axios
      .post(`${environments.orders}/quotations/request`, body, config)
      .then(() => {
        setTableData({
          ...tableData,
          collunms: handleFetchData(
            storedQuotationProductsSelected?.filter(
              (el) => el?.item?.statusRequestQuotation === 'NOT_REQUESTED'
            )?.length || productGroupId
              ? data.map((item) => {
                  if (
                    productGroupId
                      ? productGroupId === item.productGroupId
                      : body.productGroupIds.includes(item.productGroupId)
                  ) {
                    return {
                      ...item,
                      statusRequestQuotation: 'REQUESTED'
                    };
                  }
                  return {
                    ...item
                  };
                })
              : data || []
          )
        });

        dispatch(clearAll());
        handleClose();
        setMessage({
          description: 'Cotações solicitadas com sucesso.',
          status: 'success'
        });
      })
      .catch((error) => {
        console.log('error ===>', error);
        setDisableButton(false);
        setMessage({
          description: 'Erro ao solicitar cotação',
          status: 'error'
        });
      });
  };

  const getApprovalInfos = () => {
    const filterStatusObj = filtersChanged.find(
      (element) => element.param === 'orderStatus'
    );

    if (filterStatusObj) {
      filtersChanged.find(
        (element) => element.param === 'orderStatus'
      ).urlParam = `orderStatus=${orderStatusFilter}`;
    }

    const params = filtersChanged.map((filter) => filter.urlParam).join('&');

    axios
      .get(
        `${environments.orders}/quotations/dash/approval?restaurantId=${originIds}&${params}`
      )
      .then((response) => {
        const items = response.data;
        setApproveProductInfos(items);
      })
      .catch((error) => {
        console.log('error =>', error);
      });
  };

  const disapproveProducts = (productGroupId, productIds) => {
    const body = {
      restaurantIds: originIds,
      productIds,
      user: userInfo?.user
    };

    axios
      .put(
        `${environments.catalogV2}/quotations/products/disapproved`,
        body,
        config
      )
      .then(() => {
        setTableData({
          ...tableData,
          collunms: handleFetchData(
            productGroupId
              ? data.map((item) => {
                  if (productGroupId === item.productGroupId) {
                    return {
                      ...item,
                      intention: {
                        ...item.intention,
                        status: 'TO APPROVE'
                      }
                    };
                  }
                  return {
                    ...item
                  };
                })
              : data || [],
            originIds
          )
        });

        setData(
          data.map((item) => {
            if (productGroupId === item.productGroupId) {
              return {
                ...item,
                intention: {
                  ...item.intention,
                  status: 'TO APPROVE'
                }
              };
            }
            return {
              ...item
            };
          }) || []
        );

        setApproveProductInfos({
          ...approveProductInfos,
          totalToApprove:
            approveProductInfos.totalToApprove +
            (productGroupId
              ? 1
              : storedQuotationProductsSelected.filter(
                  (el) => el?.item?.intention?.status === 'TO APPROVE'
                )?.length),
          totalApproved:
            approveProductInfos.totalApproved -
            (productGroupId
              ? 1
              : storedQuotationProductsSelected.filter(
                  (el) => el?.item?.intention?.status === 'TO APPROVE'
                )?.length)
        });

        dispatch(clearAll());
        handleClose();
        setMessage({
          description: 'Produto desaprovado com sucesso.',
          status: 'success'
        });
      })
      .catch((error) => {
        console.log('error ===>', error);
        setMessage({
          description: 'Erro ao desaprovar produto',
          status: 'error'
        });
      });
  };

  const approveProducts = (productGroupId) => {
    setDisableButton(true);
    const body = {
      productGroupIds: productGroupId
        ? [productGroupId]
        : storedQuotationProductsSelected
            .filter((el) => el?.item?.intention?.status === 'TO APPROVE')
            ?.map((el) => el.item?.productGroupId) || null,
      user: userInfo.user
    };

    axios
      .put(`${environments.orders}/quotations/products/approval`, body, config)
      .then(() => {
        setTableData({
          ...tableData,
          collunms: handleFetchData(
            storedQuotationProductsSelected.filter(
              (el) => el?.item?.intention?.status === 'TO APPROVE'
            )?.length || productGroupId
              ? data.map((item) => {
                  if (
                    productGroupId
                      ? productGroupId === item.productGroupId
                      : body.productGroupIds.includes(item.productGroupId)
                  ) {
                    return {
                      ...item,
                      intention: {
                        ...item.intention,
                        status: 'APPROVED'
                      }
                    };
                  }
                  return {
                    ...item
                  };
                })
              : data || [],
            originIds
          )
        });

        setData(
          data.map((item) => {
            if (
              productGroupId
                ? productGroupId === item.productGroupId
                : body.productGroupIds.includes(item.productGroupId)
            ) {
              return {
                ...item,
                intention: {
                  ...item.intention,
                  status: 'APPROVED'
                }
              };
            }
            return {
              ...item
            };
          }) || []
        );

        getApprovalInfos();

        dispatch(clearAll());
        handleClose();
        setMessage({
          description: 'Produtos aprovados com sucesso.',
          status: 'success'
        });
      })
      .catch((error) => {
        console.log('error ===>', error);
        setDisableButton(false);
        setMessage({
          description: 'Erro ao aprovar produtos',
          status: 'error'
        });
      });
  };

  const getMainContent = useCallback(() => {
    (async () => {
      const filterStatusObj = filtersChanged.find(
        (element) => element.param === 'orderStatus'
      );

      if (filterStatusObj) {
        filtersChanged.find(
          (element) => element.param === 'orderStatus'
        ).urlParam = `orderStatus=${orderStatusFilter}`;
      }

      const params = filtersChanged
        ?.filter((el) => el?.value !== undefined)
        .map((filter) => filter.urlParam)
        .join('&');

      const response = await axiosInstanceOrders
        .get(`${environments.orders}/quotations?size=${size}&${params}`)
        .catch((err) => {
          console.log(err);
        });

      if (!response?.data) return;

      const items = await response?.data;
      setData(items?.content || []);

      setCallSettings({
        ...callSettings,
        totalPages: items.totalPages,
        page,
        numberOfElements: items.content.length,
        totalElements: items.totalElements
      });
    })();
  }, [getId, orderStatusFilter, filtersChanged, updateList, page]);

  const handleOrdernationFilter = (paramsAndValues, any, label) => {
    if (label) {
      const invertDictionary = {
        bloomEconomyValue: 'bloomEconomyPercentage',
        potentialEconomyValue: 'potentialEconomyPercentage',
        lastCost: 'percentage',
        totalQuantity: 'totalValueProjection',
        totalValue: 'totalVolumeProjection'
      };

      const isInverted = invertPositions[label];

      if (isInverted) {
        const [orderBy, orderOn] = paramsAndValues;
        const newOrderBy = invertDictionary[orderBy[1]];
        const newParamsAndValues = [['orderBy', newOrderBy], orderOn];

        handleMultipleFilters(
          newParamsAndValues,
          filtersChanged,
          setFiltersChanged
        );
        document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
        return;
      }
    }

    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  };

  const downloadSpreadsheet = (originIds) => {
    ReactGA.event({
      category: 'Dowload',
      action: 'CliqueBotao',
      label: 'GerarListaDeCompras'
    });
    const uri = `${environments.orders}/quotations/spreadsheet?originIds=${originIds}`;
    const link = document.createElement('a');

    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clearInput = () => {
    multipleFilesRef.current.value = null;
  };

  const downloadCatalogByRestaurant = () => {
    setProcessing(true);

    ReactGA.event({
      category: 'Download',
      action: 'CliqueBotao',
      label: 'BaixarCatalogoPorRestaurante'
    });

    const uri = `${environments.orders}/quotations/products/download/${originIds}`;
    const link = document.createElement('a');

    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      setProcessing(false);
    }, originIds.length * 950);
  };

  const getEstimatedEconomy = () => {
    axios
      .get(
        `${environments.orders}/quotations/dash/approvalEconomy?restaurantId=${originIds}`
      )
      .then((response) => {
        setEstimatedBloomEconomy(response.data);
      });
  };

  const catalogManager = () => (
    <>
      <MaterialButton
        className="defaultButton submitButton fontSizeDefault p8 pt5 pb5 ml5"
        onClick={() => downloadCatalogByRestaurant()}
        style={{ outline: 0 }}
        color="primary"
      >
        <img
          style={{ width: '22px', marginRight: 2, marginLeft: '-3px' }}
          src={DownloadIcon}
          alt="DownloadIcon"
        />
        Baixar catálogo
      </MaterialButton>

      <MaterialButton
        className="uploadMultipleFilesButton defaultButton submitButton fontSizeDefault p8 pt5 pb5 ml5 pRelative cursorPointer"
        style={{ outline: 0, marginRight: '-5px' }}
        color="primary"
      >
        <InputFile
          type="file"
          name="quotation_upload_files"
          ref={multipleFilesRef}
          accept=".xlsx"
          multiple
          onChange={handleMultipleFileChanges}
          className="cursorPointer"
        />
        <img
          className="cursorPointer"
          style={{ width: '22px', marginRight: 2, marginLeft: '-3px' }}
          src={UploadIcon}
          alt="UploadIcon"
        />
        Enviar arquivos
      </MaterialButton>
    </>
  );

  const additionalbuttons = () => (
    <div className="dFlex alignCenter">
      <Tooltip title="Total de Economia Bloom">
        <div className="dFlex alignCenter justifyCenter estimatedEconomy">
          <div
            className={`${
              estimatedBloomEconomy?.sumEconomyApproved
                ? 'iconBloom'
                : 'iconBloomDisabled'
            } dFlex alignCenter`}
          >
            <img src={BloomIcon} alt="bloomIcon" />
          </div>
          {estimatedBloomEconomy?.sumEconomyApproved ? (
            <p className="valueEstimated">
              R${' '}
              {estimatedBloomEconomy?.sumEconomyApproved.toLocaleString(
                'pt-br',
                {
                  minimumFractionDigits: 2
                }
              )}
            </p>
          ) : (
            ''
          )}
          <div
            className={`${
              estimatedBloomEconomy?.sumEconomyApproved
                ? 'percentEstimated'
                : 'percentEstimatedDisabled'
            } dFlex alignCenter justifyCenter`}
          >
            <p>
              {estimatedBloomEconomy?.sumPercentEconomyApproved?.toFixed(2)}%
            </p>
          </div>
        </div>
      </Tooltip>
      <Button
        label="Gerar lista de compra"
        primary
        width="auto"
        onClick={() => downloadSpreadsheet(originIds)}
      />
    </div>
  );

  const removeItem = (item) => {
    dispatch(
      handleProduct({
        item
      })
    );

    if (storedQuotationProductsSelected.length === 1) {
      setModalStatesObj({
        ...modalStatesObj,
        approveConfirmation: {
          open: false,
          productGroupId: '',
          singleApproval: false
        }
      });
    }
  };

  const calcApproveEconomyValue = (item, lastOrder) => {
    const totalValue =
      (item?.quotation?.unitaryValueConsumptionUnit || 0) *
      (item?.intention?.totalValue || 0);
    const calcValue =
      (lastOrder?.value || 0) * (item?.intention?.totalValue || 0) - totalValue;

    return calcValue > 0 ? `R$ ${numberToReal(calcValue, 2)}` : '-';
  };

  const approveProductsEconomyInfos = (item, lastOrder) => {
    if (item?.sku?.brand?.id !== lastOrder?.brandId) {
      return {
        text: 'MARCA',
        color: item?.sku?.isSimilarBloom ? '#D2F9D6' : '#7583F0',
        bg: item?.sku?.isSimilarBloom ? '#5062F0' : '#DADDF3'
      };
    }

    if (!item?.providerIds?.includes(lastOrder?.supplierId)) {
      return {
        text: 'FORNECEDOR',
        color: item?.sku?.isSimilarBloom ? '#D2F9D6' : '#7583F0',
        bg: item?.sku?.isSimilarBloom ? '#5062F0' : '#DADDF3'
      };
    }

    if (item?.quotation?.codeProvider !== lastOrder?.codeProvider) {
      return {
        text: 'EMBALAGEM',
        color: item?.sku?.isSimilarBloom ? '#D2F9D6' : '#7583F0',
        bg: item?.sku?.isSimilarBloom ? '#5062F0' : '#DADDF3'
      };
    }
  };

  const calcApproveProductsValue = (product) =>
    numberToReal(
      (product?.item?.quotation?.unitaryValueConsumptionUnit || 0) *
        (product?.item?.intention?.totalValue || 0),
      2
    );

  useEffect(() => {
    if (!data) return;

    setTableData({
      ...tableData,
      collunms: handleFetchData(
        storedQuotationProductsSelected.length
          ? data.map((item) => {
              if (
                storedQuotationProductsSelected
                  .map((el) => el.item?.productGroupId)
                  .includes(item.productGroupId)
              ) {
                return {
                  ...item,
                  selected: true
                };
              }
              return {
                ...item,
                selected: false
              };
            })
          : data || [],
        originIds
      )
    });
  }, [data]);

  useEffect(() => {
    storedQuotationProductsSelected.map((item) => {
      const conlunmData = tableData.collunms.find((collunm) => {
        if (collunm.length) {
          if (collunm[0].id === item.id) {
            return collunm;
          }
        }
      });

      setTableData(tableData);
    });
  }, [storedQuotationProductsSelected]);

  useEffect(() => {
    getMainContent();
    setTableData(tableData);
    getEstimatedEconomy();
  }, [orderStatusFilter, filtersChanged, updateList]);

  useEffect(() => {
    getApprovalInfos();
  }, [refreshFetch, filtersChanged]);

  return (
    <Layout>
      <GlobalStyle />
      <TitleHead title="Cotações" />

      <FullScreenLoading display={processing} description="Baixando" />

      <PageTitleAndFilter
        title="Gestor de Cotações"
        activeFilterFilds={filterFields}
        handleFilter={handleFilter}
        getFilterhandle={getFilterhandle}
        afterTitleButton={catalogManager}
        additionalbutton={additionalbuttons}
        dynamicJustifyOff
      />

      <EconomyAndApproveCards
        approveProductInfos={approveProductInfos}
        handleFilter={handleFilter}
        selectedItems={selectedItems}
      />

      {storedQuotationProductsSelected.filter(
        (el) => el?.item?.intention?.status === 'TO APPROVE'
      )?.length &&
      !modalStatesObj?.approveConfirmation?.singleApproval &&
      !modalStatesObj?.requestConfirmation?.singleApproval ? (
        <Tooltip
          title={`Aprovar ${
            storedQuotationProductsSelected.filter(
              (el) => el?.item?.intention?.status === 'TO APPROVE'
            )?.length
          } ${
            storedQuotationProductsSelected.filter(
              (el) => el?.item?.intention?.status === 'TO APPROVE'
            )?.length === 1
              ? 'produto'
              : 'produtos'
          }`}
        >
          <div
            className="dFlex flexColumn alignCenter justifyCenter p8 pt8 pb8 bgWhite"
            style={{
              width: 44,
              boxShadow: '0px 1px 4px 1px rgba(1, 3, 17, 0.45)',
              borderRadius: '0px 8px 8px 0px',
              position: 'fixed',
              left: 0,
              top: '30vh'
            }}
          >
            <p className="mb0 lightBlue mb10 dFlex flexColumn alignCenter justifyCenter">
              {
                storedQuotationProductsSelected.filter(
                  (el) => el?.item?.intention?.status === 'TO APPROVE'
                )?.length
              }
            </p>

            <img
              className="cursorPointer"
              onClick={() =>
                setModalStatesObj({
                  ...modalStatesObj,
                  approveConfirmation: { open: true }
                })
              }
              style={{ width: 20 }}
              src={ApproveIcon}
              alt="Approve Icon"
            />
          </div>
        </Tooltip>
      ) : null}

      {storedQuotationProductsSelected?.filter(
        (el) => el?.item?.statusRequestQuotation === 'NOT_REQUESTED'
      )?.length &&
      !modalStatesObj?.approveConfirmation?.singleApproval &&
      !modalStatesObj?.requestConfirmation?.singleApproval ? (
        <Tooltip title="Solicitar Cotação">
          <div
            className="dFlex flexColumn alignCenter justifyCenter p8 pt8 pb8 bgWhite"
            style={{
              width: 44,
              boxShadow: '0px 1px 4px 1px rgba(1, 3, 17, 0.45)',
              borderRadius: '0px 8px 8px 0px',
              position: 'fixed',
              left: 0,
              top: '41vh'
            }}
          >
            <p className="mb0 lightBlue mb10 dFlex flexColumn alignCenter justifyCenter">
              {
                storedQuotationProductsSelected?.filter(
                  (el) => el?.item?.statusRequestQuotation === 'NOT_REQUESTED'
                )?.length
              }
            </p>

            <img
              className="cursorPointer"
              onClick={() =>
                setModalStatesObj({
                  ...modalStatesObj,
                  requestConfirmation: { open: true }
                })
              }
              style={{ width: 20 }}
              src={RequestQuote}
              alt="RequestQuote Icon"
            />
          </div>
        </Tooltip>
      ) : null}

      <QuotationManagerTable
        approveProductInfos={approveProductInfos}
        erroMensagem="Nenhuma cotação foi encontrada"
        tableData={tableData}
        setIconColor={setIconModalColor}
        setCurrentProduct={setCurrentProduct}
        setTableData={setTableData}
        setModalStates={setModalStates}
        modalStatesObj={modalStatesObj}
        setModalStatesObj={setModalStatesObj}
        setProductsIds={setProductsIds}
        tableFixed
        setDisabledApproveProduct={setDisabledApproveProduct}
        storedQuotationProductsSelected={storedQuotationProductsSelected}
        storedQuotationPagesSelected={storedQuotationPagesSelected}
        handleOrdernationFilter={handleOrdernationFilter}
        setCurrentModalTitle={setCurrentModalTitle}
        setTableToModalProps={setTableToModalProps}
        setDataDialog={setInflationData}
        toggleCellPositions={toggleCellPositions}
        invertPositions={invertPositions}
        handleFilter={handleFilter}
        setProductGroupId={setProductGroupId}
        page={filtersChanged?.find((el) => el?.param === 'page')?.value}
        intentionTooltip={intentionTooltip}
        setIntentionTooltip={setIntentionTooltip}
        loadingIntention={loadingIntention}
        openIntentionTooltip={openIntentionTooltip}
      />

      <BottomBarWrapper>
        <PaginationStyled className="pagination">
          <Pagination
            count={totalPages || 0}
            page={filtersChanged?.find((el) => el?.param === 'page')?.value}
            onChange={(_, page) => {
              setCallSettings({ ...callSettings, page });
              setFiltersChanged(
                filtersChanged?.map((el) => {
                  if (el?.param === 'page') {
                    return {
                      ...el,
                      value: page,
                      urlParam: `page=${page}`
                    };
                  }

                  return {
                    ...el
                  };
                })
              );
              document
                .querySelector('main')
                .scrollTo({ top: 0, behavior: 'smooth' });
            }}
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />
          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </PaginationStyled>
      </BottomBarWrapper>

      {modalStates?.modalInflation && (
        <Suspense fallback={<span />}>
          <InflationDialog
            openModal={modalStates}
            handleClose={handleClose}
            restaurantsIds={tableToModalProps?.restaurantIds}
            productsIds={productsIds}
            role={role}
            inflationData={inflationData}
            styleProps={{
              lastLinebackGroundColor: '#D0D3E2'
            }}
          />
        </Suspense>
      )}

      {modalStates?.modalSkusQuotation && (
        <Suspense fallback={<span />}>
          <SkusQuotationDialog
            openModal={modalStates}
            handleClose={handleClose}
            restaurantsIds={tableToModalProps?.restaurantIds}
            productsIds={productsIds}
            role={role}
            inflationData={inflationData}
            prefAndAcceptedOff
          />
        </Suspense>
      )}

      {modalStates.optionsDialog && (
        <Suspense fallback={<span />}>
          <OptionsDialog
            currentProduct={currentProduct}
            openOptionDialog={modalStates}
            modalStatesObj={modalStatesObj}
            setModalStatesObj={setModalStatesObj}
            setOpenOptionDialog={handleClose}
            dialogTitle={currentModalTitle}
            disableApproveAction={disabledApproveProduct}
            refreshFetch={refreshFetch}
            setRefreshFetch={setRefreshFetch}
            productIds={productsIds}
            restaurantsIds={tableToModalProps?.restaurantIds}
            data={data}
            handleFetchData={handleFetchData}
            tableData={tableData}
            setTableData={setTableData}
            tableToModalProps={tableToModalProps}
            approveProducts={approveProducts}
            disapproveProducts={disapproveProducts}
            productGroupId={productGroupId}
          />
        </Suspense>
      )}

      {modalStates.buyIntention && (
        <Suspense fallback={<span />}>
          <BuyIntention
            storedQuotationProductsSelected={storedQuotationProductsSelected}
            dataContent={data}
            setDataContent={setData}
            iconColor={iconModalColor}
            openModal={modalStates.buyIntention}
            getApprovalInfos={getApprovalInfos}
            handleClose={handleClose}
            handleConfirm={() =>
              setModalStates({ ...modalStates, confirmModal: true })
            }
            restaurantsIds={tableToModalProps?.restaurantIds}
            productsIds={productsIds}
            tableToModalProps={tableToModalProps}
            dialogTitle={currentModalTitle}
            consumptionUnit={inflationData.consumptionUnit}
            setRefreshFetch={setRefreshFetch}
            styleProps={{
              lastLinebackGroundColor: '#D0D3E2'
            }}
            tableData={tableData}
            setTableData={setTableData}
            productGroupId={productGroupId}
            originIds={originIds}
            currentProduct={currentProduct}
            approveProducts={approveProducts}
            disapproveProducts={disapproveProducts}
            disableApproveAction={disabledApproveProduct}
            setMessage={setMessage}
            updateValue={updateValues}
            setUpdateValue={setUpdateValues}
          />
        </Suspense>
      )}

      {modalStates.confirmModal && (
        <ConfirmModal
          open={modalStates.confirmModal}
          content={
            <Typography className="bold">
              Deseja realmente abandonar essas alterações?
            </Typography>
          }
          onConfirm={handleClose}
          onClose={() =>
            setModalStates({ ...modalStates, confirmModal: false })
          }
          cancelText="Não"
          confirmText="Sim"
        />
      )}

      {modalStates.consumeByStore && (
        <Suspense fallback={<span />}>
          <ConsumeByStore
            openModal={modalStates.consumeByStore}
            handleClose={handleClose}
            restaurantsIds={tableToModalProps?.restaurantIds}
            productsIds={productsIds}
            dialogTitle={currentModalTitle}
            consumptionUnit={inflationData.consumptionUnit}
            productGroupId={productGroupId}
            styleProps={{
              lastLinebackGroundColor: '#D0D3E2'
            }}
          />
        </Suspense>
      )}

      {modalStates.dialogSupplier && (
        <Suspense fallback={<span />}>
          <DialogSupplier
            openModalProviders={modalStates.dialogSupplier}
            closeModal={handleClose}
            restaurantsIds={tableToModalProps?.restaurantIds}
            productsIds={productsIds}
            mainData={data}
            tableData={tableData}
            setTableData={setTableData}
            tableToModalProps={tableToModalProps}
            handleFetchData={handleFetchData}
            dialogTitle={currentModalTitle}
            productGroupId={productGroupId}
          />
        </Suspense>
      )}

      {modalStates.dialogBrand && (
        <Suspense fallback={<span />}>
          <DialogSupplier
            openModalBrands={modalStates.dialogBrand}
            closeModal={handleClose}
            restaurantsIds={tableToModalProps?.restaurantIds}
            productsIds={productsIds}
            tableData={tableData}
            setTableData={setTableData}
            tableToModalProps={tableToModalProps}
            dialogTitle={currentModalTitle}
            productGroupId={productGroupId}
          />
        </Suspense>
      )}

      {modalStates.multipleUpload && (
        <Suspense fallback={<span />}>
          <MultipleUploadDialog
            openModal={modalStates}
            handleClose={handleClose}
            clearInput={clearInput}
            multipleUploadFiles={multipleUploadFiles}
            setRefreshFetch={setRefreshFetch}
          />
        </Suspense>
      )}

      {modalStatesObj.approveConfirmation.open && (
        <Dialog
          open={modalStatesObj.approveConfirmation.open}
          maxWidth="xs"
          fullWidth
          className="defaultModal approveConfirmationDialog"
          onClose={() => {
            if (modalStatesObj?.approveConfirmation?.singleApproval) {
              dispatch(clearAll());
            }

            setModalStatesObj({
              ...modalStatesObj,
              approveConfirmation: {
                open: false,
                productGroupId: '',
                singleApproval: false
              }
            });
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title" style={{ padding: '16px' }}>
            <Typography
              className="bold textAlignCenter"
              style={{
                fontSize: '18px'
              }}
            >
              Deseja aprovar{' '}
              {
                storedQuotationProductsSelected.filter(
                  (el) => el?.item?.intention?.status === 'TO APPROVE'
                )?.length
              }{' '}
              {storedQuotationProductsSelected.filter(
                (el) => el?.item?.intention?.status === 'TO APPROVE'
              )?.length === 1
                ? 'produto'
                : 'produtos'}{' '}
              (R${' '}
              {numberToReal(
                storedQuotationProductsSelected.reduce(
                  (total, value) =>
                    value?.item?.intention?.status === 'TO APPROVE'
                      ? parseFloat(
                          Number(
                            value?.item?.quotation
                              ?.unitaryValueConsumptionUnit *
                              value?.item?.intention?.totalValue
                          )
                        ) + parseFloat(total)
                      : total,
                  0
                ),
                2
              )}
              )?
            </Typography>
          </DialogTitle>

          <DialogActions
            className="justifyCenter pb20 pt0"
            style={{ padding: '16px' }}
          >
            <MaterialButton
              className="defaultButton backButton"
              design="outlined"
              onClick={() => {
                if (modalStatesObj?.approveConfirmation?.singleApproval) {
                  dispatch(clearAll());
                }

                setModalStatesObj({
                  ...modalStatesObj,
                  approveConfirmation: {
                    open: false,
                    productGroupId: '',
                    singleApproval: false
                  }
                });
              }}
              style={{ outline: 0 }}
            >
              Cancelar
            </MaterialButton>

            <MaterialButton
              design="contained"
              className="defaultButton submitButton"
              onClick={() => {
                approveProducts();
              }}
              style={{ marginLeft: '20px' }}
              disabled={disableButton}
            >
              Confirmar
            </MaterialButton>
          </DialogActions>

          <div className="dFlex flexColumn" style={{ padding: '0 16px 8px' }}>
            <table
              className="desktop"
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{ tableLayout: 'fixed' }}
            >
              <colgroup>
                <col style={{ width: '30%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '11%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '15%' }} />
              </colgroup>

              <thead className="headerTitle">
                <tr>
                  <th className="textAlignLeft">PRODUTO</th>
                  <th className="textAlignRight">QTDE</th>
                  <th className="textAlignRight">UNIDADE</th>
                  <th className="textAlignRight">TOTAL</th>
                  <th className="textAlignRight"> </th>
                  <th
                    colSpan={2}
                    className="textAlignCenter white"
                    style={{ background: '#5062F0' }}
                  >
                    ECONOMIA
                  </th>
                </tr>
              </thead>

              <tbody>
                {storedQuotationProductsSelected
                  .filter((el) => el?.item?.intention?.status === 'TO APPROVE')
                  ?.map((el) => (
                    <tr
                      className="lineBorder"
                      style={{ borderBottom: '1px solid rgba(0,0,0,.1)' }}
                    >
                      <td className="pl12">
                        <div
                          className="cursorPointer lightBlue"
                          onClick={() => {
                            const quotation = el?.item?.quotation;
                            const modalTitle = [
                              quotation?.consumptionUnit == 'un'
                                ? round(
                                    quotation?.unitaryValueConsumptionUnit,
                                    2
                                  )
                                : numberToReal(
                                    quotation?.unitaryValueConsumptionUnit,
                                    2
                                  ),
                              `/${quotation?.consumptionUnit || '-'}`
                            ];
                            setModalStates({
                              ...modalStates,
                              buyIntention: true
                            });
                            setProductGroupId(el?.lineProductGroupId);
                            setInflationData(el?.dataToDialog);
                            setProductsIds(el?.productsIds);
                            setCurrentModalTitle(modalTitle);
                            setTableToModalProps(el);
                            setDisabledApproveProduct(el?.disabledMessage);
                          }}
                        >
                          {ValidationLength(el?.item?.description, 30)}
                        </div>
                      </td>

                      <td className="textAlignRight">
                        {`${numberToReal(el?.item?.intention?.totalValue, 2)}
                        ${el?.item?.quotation?.consumptionUnit}/${
                          el?.item?.intention?.totalRestaurants
                        }`}
                      </td>

                      <td className="textAlignRight">
                        R${' '}
                        {`${numberToReal(
                          el?.item?.quotation?.unitaryValueConsumptionUnit,
                          2
                        )}/${el?.item?.quotation?.consumptionUnit}`}
                      </td>

                      <td className="textAlignRight">
                        <span
                          className="bold"
                          style={{
                            color: el?.item?.sku?.isSimilarBloom
                              ? '#5062F0'
                              : '#7583F0'
                          }}
                        >
                          {calcApproveProductsValue(el)}
                        </span>
                      </td>

                      <td className="textAlignRight pr12">
                        <IconButton
                          aria-label="delete"
                          onClick={() => removeItem(el)}
                          style={{ padding: 0, outline: 0, width: '20px' }}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </td>

                      <td
                        className="textAlignRight pl12"
                        style={{ borderLeft: 'solid 2px #5062F0' }}
                      >
                        <div
                          className={`dFlex alignCenter ${
                            el?.item?.sku?.isSimilarBloom
                              ? 'justifyBetween'
                              : 'justifyEnd'
                          }`}
                        >
                          {el?.item?.sku?.isSimilarBloom ? (
                            <img src={BloomIconBlue} alt="BloomIconBlue" />
                          ) : null}

                          <span className="lightBlue bold">
                            {approveProductsEconomyInfos(
                              el?.item,
                              el?.item?.orders?.lastOrder
                            )?.bg
                              ? calcApproveEconomyValue(
                                  el?.item,
                                  el?.item?.orders?.lastOrder
                                )
                              : '-'}
                          </span>
                        </div>
                      </td>

                      <td className="textAlignRight pr12 pl12">
                        <div
                          className="dFlex alignCenter justifyCenter"
                          style={{
                            padding: '4px 8px',
                            borderRadius: 8,
                            boxShadow: approveProductsEconomyInfos(
                              el?.item,
                              el?.item?.orders?.lastOrder
                            )?.bg
                              ? '0px 4px 4px 0px #00000040'
                              : 'none',
                            background: approveProductsEconomyInfos(
                              el?.item,
                              el?.item?.orders?.lastOrder
                            )?.bg
                          }}
                        >
                          <span
                            className="bold uppercase"
                            style={{
                              fontSize: 10,
                              lineHeight: 0.8,
                              color: approveProductsEconomyInfos(
                                el?.item,
                                el?.item?.orders?.lastOrder
                              )?.color
                            }}
                          >
                            {
                              approveProductsEconomyInfos(
                                el?.item,
                                el?.item?.orders?.lastOrder
                              )?.text
                            }
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Dialog>
      )}

      {modalStatesObj.requestConfirmation.open && (
        <Dialog
          open={modalStatesObj.requestConfirmation.open}
          onClose={() => {
            if (modalStatesObj?.requestConfirmation?.singleApproval) {
              dispatch(clearAll());
            }
            setModalStatesObj({
              ...modalStatesObj,
              requestConfirmation: {
                open: false,
                productGroupId: '',
                singleApproval: false
              }
            });
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          style={{ zIndex: 1301 }}
        >
          <DialogTitle id="draggable-dialog-title" style={{ padding: '16px' }}>
            <Typography
              className="bold textAlignCenter"
              style={{
                fontSize: '18px'
              }}
            >
              Deseja solicitar a cotação de{' '}
              {
                storedQuotationProductsSelected?.filter(
                  (el) => el?.item?.statusRequestQuotation === 'NOT_REQUESTED'
                )?.length
              }{' '}
              {storedQuotationProductsSelected?.filter(
                (el) => el?.item?.statusRequestQuotation === 'NOT_REQUESTED'
              )?.length === 1
                ? 'produto'
                : 'produtos'}
              ?
            </Typography>
          </DialogTitle>

          <DialogActions
            className="justifyAround pb20 pt0"
            style={{ padding: '16px' }}
          >
            <MaterialButton
              className="defaultButton backButton"
              design="outlined"
              onClick={() => {
                if (modalStatesObj?.requestConfirmation?.singleApproval) {
                  dispatch(clearAll());
                }
                setModalStatesObj({
                  ...modalStatesObj,
                  requestConfirmation: {
                    open: false,
                    productGroupId: '',
                    singleApproval: false
                  }
                });
              }}
              style={{ outline: 0 }}
            >
              Cancelar
            </MaterialButton>

            <MaterialButton
              design="contained"
              className="defaultButton submitButton"
              onClick={() => {
                requestQuote();
              }}
              style={{ marginLeft: '20px' }}
              disabled={disableButton}
            >
              Confirmar
            </MaterialButton>
          </DialogActions>

          <div className="dFlex flexColumn" style={{ padding: '0 16px 8px' }}>
            {storedQuotationProductsSelected
              ?.filter(
                (el) => el?.item?.statusRequestQuotation === 'NOT_REQUESTED'
              )
              ?.map((el) => (
                <p className="mb5 fontSizeDefault bold">
                  {el?.item?.description}
                </p>
              ))}
          </div>
        </Dialog>
      )}

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => setMessage({ description: '', status: '' })}
      >
        <Alert
          onClose={() => setMessage({ description: '', status: '' })}
          severity={message?.status}
        >
          {message?.description}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(QuotationManagerProfile);
