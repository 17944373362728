/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useState,
  useEffect,
} from 'react';

import ArrowLeftIcon from 'images/icons/arrows/arrowLeft.svg';
import ArrowRightIcon from 'images/icons/arrows/arrowRight.svg';

function ButtonGroup({ next, previous, goToSlide, numberItems, counts, isMobileScreen, ...rest }) {
  const [firstSwipe, setFirstSwipe] = useState(false);
  const {
    carouselState: { currentSlide }
  } = rest;

  const disabledButton = rest.carouselState.totalItems - rest.carouselState.currentSlide
  const totalItemsToShowControl = isMobileScreen ? 2 : 5

  useEffect(() => {
    if (!firstSwipe) {
      const tabNumValidation = isMobileScreen ? 3 : 4
      const activeTabIndex = counts?.map((el, index) => el?.active ? index : null).find(item => item)
      const activeTabCalc = activeTabIndex > tabNumValidation ? activeTabIndex - tabNumValidation : 0
      goToSlide(activeTabCalc)
      setFirstSwipe(true)
    }
  }, []);

  return (
    <div
      className={`carousel-button-group ${
        rest.carouselState.totalItems > totalItemsToShowControl ? 'dFlex' : 'dNone'
      }`}
    >
      <button
        onClick={() => {
          !currentSlide
            ? next()
            : goToSlide(0);
        }}
        className="countTabItem arrowCustomButton"
        disabled={!rest.carouselState.currentSlide}
      >
        <img
          style={{ width: '6px'}}
          src={ArrowLeftIcon}
          alt="ArrowDownIcon"
        />
      </button>

      <button
        onClick={() => next()}
        className="countTabItem arrowCustomButton"
        disabled={disabledButton === rest.carouselState.slidesToShow}
      >
        <img
          style={{ width: '6px'}}
          src={ArrowRightIcon}
          alt="ArrowDownIcon"
        />
      </button>
    </div>
  );
}

export default ButtonGroup;
