export const calcCmvSimulation = (
  averagePriceSimulation,
  costTotal,
  setUpdateState
) => {
  if (averagePriceSimulation && costTotal)
    setUpdateState(
      (parseFloat(costTotal) / parseFloat(averagePriceSimulation)) * 100
    );
};

export const calcMarkupSimulation = (
  averagePriceSimulation,
  costTotal,
  setUpdateState
) => {
  if (averagePriceSimulation && costTotal)
    setUpdateState(parseFloat(averagePriceSimulation) / parseFloat(costTotal));
};
