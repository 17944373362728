/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import { LinearProgress, TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import InfoIcon from 'images/icons/signals/questionMarks/interrogationNavyBlueBg.svg';
import getOptions from 'services/getOptions';
import * as UserActions from 'store/actions/user';
import Environment from 'utils/environments'
import { isEmpty } from 'utils/isEmpty';

import GroupedVerticalBarGraphic from '../graphics/GroupedVerticalBarGraphic';
import SimpleLineGraphic from '../graphics/SimpleLineGraphic';

const environments = Environment(process.env.REACT_APP_ENV)

function ProductionInfosArea({ 
  userInfo, 
  productionCopilotInfos,
  productionDiff,
  productionOffForecastAndSchedule,
  productionExcessInventoryAndDailySchedule,
  setCourtId,
  setCourtDescription,
  setCourtIdCopilot,
  setCourtDescriptionCopilot
}) {
  const { id: paramsID } = useParams();

  const [getId, setGetId] = useState(paramsID)
  const [courtOptions, setCourtOptions] = useState([])
  const [courtFilterValue, setCourtFilterValue] = useState({id: "", description: "TODAS"})

  return (
    <section className="homeProductionInfosContainer homeDefaultContainer">
      <div className="homeDefaultInfoTitle">
        <p>
          PRODUÇÃO
        </p>
      </div>

      <div className="graphicContainer">
        <div className="graphicBox">
          <div className="graphicItem">
            <div
              style={{display: "flex", justifyContent: "space-between"}}
            >
              <span className="graphicTitle">
                encerramento do copiloto
              </span>
              <div
                style={{
                  position: "relative",
                  justifyContent: "center",
                  display: "flex",
                  marginRight: "20px"
                }}
              >
                <label 
                  className="graphicFilterTitle"
                  style={{marginBottom: "0px", marginTop: "2px"}}
                >
                  praça:
                </label>
                <Autocomplete
                  className='autocompleteSmall'
                  style={{width: '130px', height: '18px', marginTop: "-8px" }}
                  options={[{ description: 'TODAS', id: '' }, ...courtOptions] || []}
                  onKeyDown={(event) => {
                    if ((event.keyCode === 8 || event.keyCode === 13) && !isEmpty(event.target.value)) {
                      const courtFilterValueIn = {description: event.target.value, id: "999"}
                      setCourtFilterValue(courtFilterValueIn)
                      setCourtIdCopilot(null)
                      setCourtDescriptionCopilot(courtFilterValueIn.description)
                    }
                  }}
                  onFocus={() => {
                    if (courtOptions?.length) return;

                    getOptions(
                      `${environments.catalog}/courts`,
                      'courtOptions',
                      courtOptions,
                      setCourtOptions,
                      getId,
                      { restaurantId: getId },
                      null,
                      2000
                    );
                  }}
                  getOptionLabel={(option) => option?.description || option}
                  defaultValue={courtFilterValue?.description}
                  onChange={(_, value) => {
                    setCourtFilterValue(value)
                    setCourtDescriptionCopilot(null)

                    if (!isEmpty(value) && !isEmpty(value.id)) {
                      setCourtIdCopilot(value.id)
                    } else {
                      setCourtIdCopilot(null)
                      setCourtFilterValue({id: "", description: "TODAS"})
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        style: { paddingTop: 6, fontSize: "12px", marginBottom: "-5px" }
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                      {params?.description}
                    </Typography>
                  )}
                />
              </div>
            </div>

            {productionCopilotInfos?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <SimpleLineGraphic 
                mainContent={productionCopilotInfos}
              />
            }
          </div>

          <div className="graphicItem">
            <div
              style={{display: "flex", justifyContent: "space-between"}}
            >
              <div>
                <span className="graphicTitle" style={{marginBottom: "8px"}}>
                  diferenças
                </span>
              </div>
              <div
                style={{
                  position: "relative",
                  justifyContent: "center",
                  display: "flex",
                  marginRight: "20px"
                }}
              >
                <label 
                  className="graphicFilterTitle"
                  style={{marginBottom: "0px", marginTop: "2px"}}
                >
                  praça:
                </label>
                <Autocomplete
                  className='autocompleteSmall'
                  style={{ width: '178px', height: '18px', marginTop: "-8px" }}
                  options={[{ description: 'TODAS', id: '' }, ...courtOptions] || []}
                  onKeyDown={(event) => {
                    if ((event.keyCode === 8 || event.keyCode === 13) && !isEmpty(event.target.value)) {
                      const courtFilterValueIn = {description: event.target.value, id: "999"}
                      setCourtFilterValue(courtFilterValueIn)
                      setCourtId(null)
                      setCourtDescription(courtFilterValueIn.description)
                    }
                  }}
                  onFocus={() => {
                    if (courtOptions?.length) return;

                    getOptions(
                      `${environments.catalog}/courts`,
                      'courtOptions',
                      courtOptions,
                      setCourtOptions,
                      getId,
                      { restaurantId: getId },
                      null,
                      2000
                    );
                  }}
                  getOptionLabel={(option) => option?.description || option}
                  defaultValue={courtFilterValue?.description}
                  onChange={(_, value) => {
                    setCourtFilterValue(value)
                    setCourtDescription(null)

                    if (!isEmpty(value) && !isEmpty(value.id)) {
                      setCourtId(value.id)
                    } else {
                      setCourtId(null)
                      setCourtFilterValue({id: "", description: "TODAS"})
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        style: { paddingTop: 6, fontSize: "12px", marginBottom: "-5px" }
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                      {params?.description}
                    </Typography>
                  )}
                />
              </div>
            </div>

            {productionDiff?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <GroupedVerticalBarGraphic 
                mainContent={productionDiff}
              />
            }
          </div>
        </div>

        <div className="graphicBox">
          <div className="graphicItem">
            <span className="graphicTitle dFlex alignCenter">
              fora da previsão e fora da agenda

              <Tooltip
                placement="bottom"
                title={
                  <div className="dFlex flexColumn">
                    <span className="bold">
                      Fora da previsão:
                    </span>

                    <span className="bold mb10">
                      Produção incluída após encerramento do Copiloto
                    </span>

                    <span className="bold">
                      Fora da agenda:
                    </span>

                    <span className="bold">
                      Produção executada fora da programação das agendas cadastradas
                    </span>
                  </div>
                }
              >
                <img
                  className="ml5"
                  style={{ width: '16px' }}
                  src={InfoIcon}
                  alt="InfoIcon"
                />
              </Tooltip>
            </span>

            {productionOffForecastAndSchedule?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <SimpleLineGraphic 
                mainContent={productionOffForecastAndSchedule}
              />
            }
          </div>

          <div className="graphicItem">
            <span className="graphicTitle dFlex alignCenter">
              excesso de estoque x programação diária

              <Tooltip
                placement="bottom"
                title={
                  <div className="dFlex flexColumn">
                    <span className="bold">
                      Produção executada com produto suficiente 
                    </span>

                    <span className="bold">
                      para ultrapassar a próxima agenda de produção
                    </span>
                  </div>
                }
              >
                <img
                  className="ml5"
                  style={{ width: '16px' }}
                  src={InfoIcon}
                  alt="InfoIcon"
                />
              </Tooltip>
            </span>

            {productionExcessInventoryAndDailySchedule?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <SimpleLineGraphic 
                mainContent={productionExcessInventoryAndDailySchedule}
              />
            }
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionInfosArea);
