import styled from 'styled-components';

export const Status = styled.div`
    width: 85%;
    height: 20px;
    background: ${(props) => props.color ? props.color : '#fff'};
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 2px;
    gap: 3px;

    @media (max-width: 1280px){
        width: 20px;

        p {
            display: none;
        }
    }
`
