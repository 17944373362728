const INITIAL = {}

export default function unitsMeasurementsInfo(state = INITIAL, action) {
  switch (action.type) {
    case "SET_UNITSMEASUREMENTS_DATA":
      return (
        {
          action: "EDIT",
          data: action.data
        }
      )
    case "CREATE_UNITSMEASUREMENTS_DATA":
      return (
        {
          action: "CREATE",
          data: {}
        }
      )
    default:
      return state;
  }
}