const fabricantesLocalizeEN = {
  title: 'Producer registers',
  headTitle: 'Producers',
  description: 'Description',
  producer: 'Producer',
  producer_plural: 'Producers',
  registerProducer: 'Register producer',
  registerProducer_plural: 'Register producers',
  deleteTitle: 'You really like to delete this producers?',
  deleteMessageQuestion: "This action cannot be undo. You'd like to prossed?",
  deletedSuccess: 'Deleted producer Successfully!',
  saveInfoError: 'Something went wrong.',
  producers: 'Producers'
};

export default fabricantesLocalizeEN;
