import styled from 'styled-components'

export const TableDiv = styled.div`
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgb(4 6 26 / 25%);
`

export const Tr = styled.tr`
    border-bottom: 1px solid #D2D3D7;
    &:hover {
        background-color: #E6E8FF;
    }
`

export const Table = styled.table`
width: 100%;
table-layout: fixed;
empty-cells: hide;
background: #FFF;
border-spacing: 1px 0;
border-collapse: collapse;
border: none;
border-top-left-radius: 4px;
border-top-right-radius: 4px;
border-bottom-left-radius: 4px;
border-bottom-right-radius: 4px;
box-shadow: 0px 0px 8px rgba(4,6,26,0.25);
padding-bottom: 15px;
display: table;
font-family: "Lato";
`

export const Th = styled.th`
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    padding: 8px;
    color: #666770;

&.th-default-background {
    background-color: #333541;
    color: #fff;
}
`

export const Td = styled.td`
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    padding: 8px;
`

export const PaginationStyled = styled.div`
    nav {
        ul {
            li{
                font-family: "Lato";
                margin: 12px 0 10px 0;
                justify-content: flex-start;
                display: flex;

                button {
                    border-radius: 8px !important;
                }

                .MuiPaginationItem-outlinedPrimary {
                    border-color: #313347;
                
                    svg {
                        color: #313347!important;
                    }

                    &.Mui-selected {
                    background: #5062F0!important;
                    color: #FFF!important;
                    }
                }
            }
        }
    }
`;