import styled from 'styled-components';

export const MobileDialogContent = styled.div`
  background-color: white;
  padding: 0 10px;
  .MobileDialogDate {
    display: flex;
    align-items: center;
    margin-top: 20px;

    p {
      margin: 0;
    }
  }

  .MobileButtons {
    div {
      display: flex;
      justify-content: space-between;

    button {
      margin: 10px 0;
    }

    button:first-child {
      margin-right: 20px;
    }
    }
    
  }

  .receive {
    width: 100%;
    text-align: center;
  }

  .modalReceiverForm {
    input {
      width: 100%;
    }
    
  }
  
`;

export const MobileDialogDescontos = styled.div`
  background-color: #454A67;
  color: white;
  .totalHeader {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;

    .firstDetails {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      
      div {
        p, h1 {
          margin: 0;
          text-align: right;
        }

        p {
          color: #9296AC;
          font-size: 9px;
        }

        h1 {
          color: white;
          font-size: 12px;
          font-weight: 300;
        }
      }
      

    }

    .secondDetails {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      div {
        p, h1 {
          margin: 0;
          text-align: right;
        }

        p {
          color: #9296AC;
          font-size: 9px;
        }

        h1 {
          color: white;
          font-size: 12px;
        }
      }
    }
  }
`

export const MobileDialogDescontosDetails = styled.div`
 background-color: white;
.acrescimosDetail {
  padding-top: 5px 0;
  background-color: white;

  h1, p {
    color: black;
  }

  .totalHeaderDetail {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    h1, p {
      margin: 0;
      padding: 0;
    }

    div {
        p, h1 {
          color: #454A67;
          margin: 0;
          text-align: right;
          
        }

        p {
          font-weight: 700;
          color: #454A67;
          font-size: 9px;
        }

        h1 {
          font-size: 12px;
        }
      }
  }
    
  }
`