import React from 'react';
import { LightHouseWrapper } from './style.js';

const LeftIconLighthouse = ({color}) => {
    const colorTypes = {
        blue: '#5062F0',
        yellow: '#FFBF3E',
        red: '#E22121'
    }

    return(
        <LightHouseWrapper color={colorTypes[color]} />
    );
}

export default LeftIconLighthouse;
