/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import { Switch } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import getOptions from 'services/getOptions';
import Environment from 'utils/environments';

import {
  LabelTitle,
  FormControlLabelStyled,
  SwitchContainer
} from './index.style';

function SwitchField({
  title,
  handleFilter,
  topAdjustment,
  onChange,
  filterName,
  checkedAsDefault = false,
  titleColor,
  marginOf = false,
  onFormStyle = false,
  customClassName,
  useTitle = false
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { id: paramsID } = useParams();
  const [label, setLabel] = useState(title);
  const [restaurants, setRestaurants] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [checked, setChecked] = useState(
    !!(filterName == 'groupBy' || checkedAsDefault)
  );

  const arrayMove = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
  };

  const handleCheckBox = (event, checked, filterName) => {
    const { target: { name = '' } = {} } = event;
    const index = checkedList.indexOf(name);

    onChange && onChange(checked);
    setChecked(checked);
    if (checked) {
      if (filterName === 'restaurants') {
        getOptions(
          `${environments.restaurants}/companies`,
          'restaurants',
          restaurants,
          setRestaurants,
          paramsID
        );
        setLabel('Sku Restaurante');
        return;
      }
      if (filterName) {
        handleFilter(filterName, checked);
        return;
      }
      setCheckedList([...checkedList, name]);
      handleFilter('curve', [...checkedList, name].join());
    } else {
      if (index > -1) {
        checkedList.splice(index, 1);
        handleFilter('curve', checkedList.length ? checkedList.join() : null);
        return;
      }
      if (filterName === 'restaurants') {
        setRestaurants([]);
        setLabel('Sku Plataforma');
        handleFilter(filterName, null);
      } else if (filterName) {
        handleFilter(filterName, null);
      }
    }
  };

  useEffect(() => {
    if (restaurants.length) {
      handleFilter(
        filterName,
        arrayMove(
          restaurants.map((item) => item._id),
          0,
          1
        )
      );
    }
  }, [restaurants]);

  return (
    <SwitchContainer
      topAdjustment={topAdjustment}
      marginOf={marginOf}
      onFormStyle={onFormStyle}
      className={customClassName}
    >
      <FormControlLabelStyled
        className={
          onFormStyle ? 'toggleDefaultBlueContainer m0' : 'toggleDefault m0'
        }
        control={
          <Switch
            checked={checked}
            onChange={(event, checked) =>
              handleCheckBox(event, checked, filterName)
            }
            name="multipleSku"
          />
        }
      />

      <LabelTitle titleColor={titleColor}>{useTitle ? title : label}</LabelTitle>
    </SwitchContainer>
  );
}

export default SwitchField;
