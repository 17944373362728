/* eslint-disable no-prototype-builtins */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useCallback } from 'react';

import {
  Fade,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Snackbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { TreeSelect } from 'antd';
import axios from 'axios';
import moment from 'moment';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import AlreadyExistingProducts from 'components/Dialogs/AlreadyExistingProducts';
import DialogChangeSkuProduct from 'components/Dialogs/ChangeProductSku';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import FullScreenLoading from 'components/FullScreenLoading';
import ImageWrapper from 'components/ImageWrapper';
import ChangeSkuIcon from 'images/icons/arrows/changeIcon.svg';
import ClockNavyBlue from 'images/icons/objects/clocks/clockNavyBlue.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import InfoIcon from 'images/icons/signals/infos/info.svg';
import getOptions from 'services/getOptions';
import makeGet from 'services/useFetch/makeGet';
import makePost from 'services/useFetch/makePost';
import makePut from 'services/useFetch/makePut';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environments from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import history from 'utils/history';
import { setSubGroupsAndStores } from 'utils/setSubGroupsAndStores';
import ValidationLength from 'utils/utils';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title-product-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  input: {
    fontFamily: 'Lato',
    fontSize: 12
  },
  option: {
    fontFamily: 'Lato',
    fontSize: 12
  }
}));

const environment = Environments(process.env.REACT_APP_ENV);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ProductForm({
  userInfo,
  dialogOpen,
  productInfo: productData,
  productDataModal,
  onClose,
  setUpdateList,
  paramsProductId = null,
  isModalBehavior = false,
  consumptionUnitOptions,
  storageLocationOptions,
  periodicityOptions,
  onlyView = false,
  isModal = false,
  onCloseFunc,
  setMessage,
  message,
  skuRoute,
  productsSku
}) {
  const { register, handleSubmit, errors } = useForm();
  const { id: paramsID, productId } = useParams();
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const getId =
    userInfo?.companiesActive?.id === paramsID
      ? userInfo?.companiesActive
      : paramsID || null;

  const isGroupManage = getId?.includes(',');

  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'skuModal',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      product: null
    },
    {
      id: 2,
      name: 'alreadyExistingProductsModal',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      product: null
    }
  ]);
  const [productInfo, setProductInfo] = useState(productDataModal || { data: {} });

  const [productDescription, setProductDescription] = useState(
    productDataModal?.data?.description || ''
  );
  const [loading, setLoading] = useState(false);
  const [productWithSameDescription, setProductWithSameDescription] =
    useState(false);
  const [loadCategories, setLoadCategories] = useState(false);
  const [options, setOptions] = useState([]);

  const [originId, setOriginId] = useState(paramsID);
  const [editMode, setEditMode] = useState(
    productInfo?.action?.toLowerCase() === 'edit' || !!productDataModal?.data?.id || onlyView
  );
  const [noSkuProduct, setNoSkuProduct] = useState(
    editMode ? !productInfo.data?.skus?.length : !!isModal
  );
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [enableToLoadPeriodicities, setEnableToLoadPeriodicities] = useState(false)
  const [enableToLoadStorageLocations, setEnableToLoadStorageLocations] = useState(false)
  const [storageLocations, setStorageLocations] = useState(
    storageLocationOptions || []
  );
  const [periodicities, setPeriodicities] = useState(periodicityOptions || []);
  const [consumptionUnits, setConsumptionUnits] = useState(
    consumptionUnitOptions || []
  );
  const [parameters, setParameters] = useState('');
  const [unifiedSkus, setUnifiedSkus] = useState(
    editMode ? productInfo.data && productInfo.data?.unifiedSkus : false
  );
  const [alertErr, setAlertErr] = useState(false);
  const [messageAlert, setMessageAlert] = useState({
    description: '',
    status: ''
  })
  const [newSkuRow, setNewSkuRow] = useState('');
  const [alertErrSkus, setAlertErrSkus] = useState(false);
  const onDismiss = () => {
    setAlertErr(false);
    setAlertErrSkus(false);
  };
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState(
    editMode === true && productInfo?.data?.consumptionUnitsPrimary
      ? productInfo?.data?.consumptionUnitsPrimary
      : null
  );
  const [ucSecondarySelectedId, setUcSecondarySelectedId] = useState(
    editMode === true && productInfo?.data?.consumptionUnitsSecondary
      ? productInfo?.data?.consumptionUnitsSecondary?.id
      : null
  );
  const [getAlert, setAlert] = useState('');
  const [storeOptions, setStoreOptions] = useState([]);
  const [selectedStores, setSelectedStores] = useState([]);
  const [abbreviation, setAbbreviation] = useState(
    editMode === true &&
      productInfo?.data?.consumptionUnitsPrimary &&
      productInfo?.data?.consumptionUnitsPrimary?.abbreviation?.toLowerCase() ===
        'kg'
      ? 'kg,mg,g'
      : editMode === true &&
        productInfo?.data?.consumptionUnitsPrimary &&
        productInfo?.data?.consumptionUnitsPrimary?.abbreviation?.toLowerCase() ===
          'lt'
      ? 'l,ml'
      : editMode === true &&
        productInfo?.data?.consumptionUnitsPrimary &&
        productInfo?.data?.consumptionUnitsPrimary?.abbreviation.toLowerCase() ===
          'un'
      ? ''
      : null
  );
  const [products, setProducts] = useState({
    id: editMode ? productInfo?.data?.id : null,
    restaurantId: editMode ? productInfo.data?.restaurantId : originId,
    categoryId: editMode ? productInfo.data?.categoryId : null,
    lastCost: editMode ? productInfo.data?.lastCost : null,
    averageCost: editMode ? productInfo.data?.averageCost : null,
    consumptionUnitsPrimaryId: editMode
      ? productInfo?.data?.consumptionUnitsPrimaryId
      : null,
    consumptionUnitsSecondaryId: editMode
      ? productInfo?.data?.consumptionUnitsSecondaryId
      : null,
    user: editMode ? productInfo?.data?.user : userInfo.user,
    description: editMode ? productInfo.data?.description : null,
    salePrice: editMode ? productInfo?.data?.salePrice : null,
    discountPrice: editMode ? productInfo?.data?.discountPrice : null,
    useOnCards: editMode ? productInfo?.data?.useOnCards : true,
    takeInventory: editMode ? !!productInfo?.data?.setups?.length : true,
    quantityMinStock: editMode ? productInfo?.data?.quantityMinStock : null,
    quantityMaxStock: editMode ? productInfo?.data?.quantityMaxStock : null,
    approximateCost: editMode ? productInfo?.data?.approximateCost : null,
    storageLocations: editMode ? productInfo?.data?.storageLocations : [],
    frequencies: editMode ? productInfo?.data?.frequencies : [],
    skus: editMode ? productInfo?.data?.skus : [],
    averagePrice: editMode ? productInfo?.data?.averagePrice : null,
    averagePriceSimulation: editMode
      ? productInfo?.data?.averagePriceSimulation
      : null,
    markupSimulation: editMode ? productInfo?.data?.markupSimulation : null,
    cmvSimulation: editMode ? productInfo?.data?.cmvSimulation : null,
    created_at: productInfo?.data?.created_at || null,
    updated_at: productInfo?.data?.updated_at || null
  });
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [brands, setBrands] = useState([]);
  const [skuItems, setSkuItems] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [providers, setProviders] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [selectedFrequencies, setSelectedFrequencies] = useState([]);
  const [debounceTimeList, setDebounceTimeList] = useState([]);
  const [treeView, setTreeView] = useState(
    editMode ? productInfo?.data?.categories?.id : ''
  );
  const [categoryName, setCategoryName] = useState(
    productDataModal?.data?.categories?.description || ''
  );
  const [categoriesError, setCategoriesError] = useState(false);
  const [unitAlert, setUnitAlert] = useState(false);
  const [restaurantSubGroups, setRestaurantSubGroups] = useState([]);
  const [restaurantStores, setRestaurantStores] = useState([]);
  const [hideInventoryFields, setHideInventoryFields] = useState(
    editMode ? !productInfo.data?.setups?.length : false
  );
  const [lossPercentageCategory, setLossPercentageCategory] = useState(null);
  const [lossPercentage, setLossPercentage] = useState(
    editMode ? productInfo?.data?.lossPercentage : null
  );
  const [variationPercentage, setVariationPercentage] = useState(
    editMode ? productInfo?.data?.variationPercentage : null
  );
  const [lossPercentageChanged, setLossPercentageChanged] = useState(
    editMode ? productInfo?.data?.lossPercentageChanged : false
  );
  const [process, setProcess] = useState();
  const [quantityprocess, setQuantityProcess] = useState();
  const [processTable, setProcessTable] = useState(
    { data: [] }
  );
  const [newProcessRow, setProcessRow] = useState('');
  const [alreadyExistingProducts, setAlreadyExistingProducts] = useState([]);
  const [lossPercentageError, setLossPercentageError] = useState(false);
  const [selectedSku, setSelectedSku] = useState();
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const items = JSON.parse(localStorage.getItem('token'));
  const profileType = items.data.profile[0][0].name;
  const classes = useStyles();
  const [onlyClickOnce, setOnlyClickOnce] = useState(false);
  const [loadingProductFromParams, setLoadingProductFromParams] =
    useState(false);
  const [loadingSaving, setLoadingSaving] = useState(false)
  const [userGroups, setUserGroups] = useState({
    groups: [
      {
        subGroups: [
          {
            stores: [{}]
          }
        ]
      }
    ]
  });
  const [statusCode, setStatusCode] = useState();
  const [commonMessage, setCommonMessage] = useState({description: '', status: ''})
  const [restaurantGroups, setRestaurantGroups] = useState([
    userInfo.companies.groups[0]
  ]);
  const [restaurantsId, setRestaurantsId] = useState(
    userInfo.companies.groups[0].subGroups[0].stores
  );

  const [lastReceivedProvider, setLastReceivedProvider] = useState(
    productInfo?.data?.lastReceivedProvider || ''
  );
  const [dataTable, setDataTable] = useState(
    editMode && productInfo?.data?.skus?.length
      ? {
          data: productInfo.data?.skus
        }
      : { data: [] }
  );
  const [descriptionEdit, setDescriptionEdit] = useState(false)

  const [unityWarningModal, setUnityWarningModal] = useState({
    open: false,
    title: '',
    message: ''
  });

  const [dialogChangeSkuSettings, setDialogChangeSkuSettings] = useState({
    open: false,
    skuName: '',
    oldProductId: null,
    skuId: null,
    skuUnitAbbreviation: ''
  });

  const [jsonBody, setJsonBody] = useState({
    sku: {
      description: null,
      specification: null,
      providers: [],
      restaurants: {
        description: userInfo.companiesActive.name,
        restaurantId: getId,
        resales: false,
        itemPriority: false,
        brandFavorite: false,
        brandAccepted: false,
        categoryId: null,
        user: userInfo.user
      },
      categoryId: null,
      brandsId: null,
      quantity: null,
      unitsMeasurementsId: null,
      user: userInfo.user
    },
    product: {
      useOnCards: true,
      takeInventory: true,
      status: 'ACTIVE',
      description: null,
      restaurantId: getId || null,
      salePrice: null,
      discountPrice: null,
      theoreticalStock: null,
      realStock: null,
      duration: null,
      lastPrice: null,
      averagePrice: null,
      averageCost: null,
      lastCost: null,
      averageCostStock: null,
      lastCostStock: null,
      margin: null,
      markup: null,
      cmv: null,
      curve: null,
      user: userInfo.user,
      consumptionUnitsPrimaryId: null,
      consumptionUnitsSecondaryId: null,
      categoryId: null,
      category: undefined,
      quantityMinStock: 0,
      quantityMaxStock: 0,
      originIdCard: null,
      productsGroupId: null,
      approximateCost: null,
      skus: [],
      storageLocations: [],
      unifiedSkus
    },
    tags: []
  });

  const getSkus = useCallback(
    (query = '') => {
      (async () => {
        const currentId = isGroupManage ? getId?.split(',')[0] : getId
        const descriptionQuery = query ? `&descriptionSku=${query}` : '';
        const url =
          profileType === 'admin'
            ? `${environment.catalog}/skus/platform?page=1&size=10${descriptionQuery}`
            : `${environment.catalog}/skus?page=1&size=10&originId=${currentId}${descriptionQuery}`;

        const response = await fetch(url);
        const skus = await response.json();
        setOptions(skus.content);
      })();
    },
    [environment.catalog, originId, profileType]
  );

  const getMoreOptions = (getMoreCurrentOptions, value) => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      getMoreCurrentOptions(value);
    }, 1500);
    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  /* GET PRODUCT */
  const getProduct = () => {
    const currentProductId = productId || productDataModal?.data?.id || productData?.data?.id
    const validateStorageLocationAndPeriodicity = isAdminPage || (enableToLoadPeriodicities && enableToLoadStorageLocations)
    if (currentProductId && validateStorageLocationAndPeriodicity) {
      setLoading(true);

      axios
        .get(`${environment.catalog}/products/${currentProductId}?originId=${getId}`)
        .then((response) => {
          const currentProduct = response?.data;
          const formattedSkusObj =
            currentProduct?.skus?.map((sku) => ({
              id: sku?.skus?.id || null,
              description: sku?.skus?.description || '',
              quantity: sku?.skus
                ? parseFloat(sku?.skus.quantity).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : null,
              unitsMeasurements:
                sku?.skus?.unitsMeasurements?.abbreviation || null,
              purchaseAverage: sku?.skus?.restaurants?.length
                ? sku?.skus.restaurants[0].purchaseAverage
                : null,
              purchaseLast: sku?.skus?.restaurants?.length
                ? sku?.skus.restaurants[0].purchaseLast
                : null,
              conversion: sku?.skus
                ? parseFloat(sku?.conversion).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : null,
              priceUc: sku?.priceUc || null,
              restaurants: sku?.skus?.restaurants || [],
              providers: sku?.skus?.providers || [],
              skuBloom: sku.skus?.skuBloom
            })) || [];
          const flatLocations =
            currentProduct?.setups
              ?.map((item) => item?.config?.storageLocation)
              ?.flat() || [];
          const flatFrequencies =
            currentProduct?.setups
              ?.map((item) => item?.config?.periodicity)
              ?.flat() || [];
          const uniqueLocations = flatLocations.filter(
            (ele, ind) =>
              ind === flatLocations.findIndex((elem) => elem.id === ele.id)
          );
          const uniqueFrequencies = flatFrequencies.filter(
            (ele, ind) =>
              ind === flatFrequencies.findIndex((elem) => elem.id === ele.id)
          );
          const productLocations = storageLocations.filter((filter) =>
            uniqueLocations?.map((item) => item?.id)?.includes(filter?.id)
          );
          const productPeriodicities = periodicities.filter((filter) =>
            uniqueFrequencies?.map((item) => item?.id)?.includes(filter?.id)
          );

          setUnifiedSkus(currentProduct?.unifiedSkus);
          setLossPercentageChanged(currentProduct?.lossPercentageChanged);
          setTreeView(currentProduct?.categoryId);
          setProductDescription(currentProduct?.description || '');
          setUcPrimarySelectedId(currentProduct?.consumptionUnitsPrimary || null);
          setUcSecondarySelectedId(
            currentProduct?.consumptionUnitsSecondaryId || null
          );
          setCategoryName(currentProduct?.categories?.description || '');
          setLossPercentage(currentProduct?.lossPercentage || 0);
          setVariationPercentage(currentProduct?.variationPercentage || 0);
          setHideInventoryFields(
            !productLocations?.length && !productPeriodicities?.length
          );
          setSelectedLocation(productLocations);
          setSelectedFrequencies(productPeriodicities);
          setLastReceivedProvider(currentProduct?.lastReceivedProvider || '');
          setNoSkuProduct(!currentProduct?.skus?.length);
          setDataTable({
            data: formattedSkusObj
          });
          setProductInfo({
            data: {
              ...currentProduct,
              skus: formattedSkusObj
            }
          });

          setProducts({
            id: currentProduct?.id || null,
            restaurantId: currentProduct?.restaurantId || originId,
            categoryId: currentProduct?.categoryId || null,
            lastCost: currentProduct?.lastCost || null,
            averageCost: currentProduct?.averageCost || null,
            consumptionUnitsPrimaryId: editMode
              ? currentProduct?.consumptionUnitsPrimaryId
              : null,
            consumptionUnitsSecondaryId: editMode
              ? currentProduct?.consumptionUnitsSecondaryId
              : null,
            curve: currentProduct?.curve,
            user: currentProduct?.user || userInfo.user,
            description: currentProduct?.description || null,
            salePrice: currentProduct?.salePrice || null,
            discountPrice: currentProduct?.discountPrice || 0,
            useOnCards: currentProduct?.useOnCards,
            takeInventory: !!(
              productLocations?.length && productPeriodicities?.length
            ),
            quantityMinStock: currentProduct?.quantityMinStock || 0,
            quantityMaxStock: currentProduct?.quantityMaxStock || 0,
            approximateCost: currentProduct?.approximateCost?.toLocaleString('pt-br', { minimumFractionDigits: 3 }) || null,
            storageLocations: currentProduct?.storageLocations || [],
            frequencies: currentProduct?.frequencies || [],
            skus: formattedSkusObj,
            averagePrice: currentProduct?.averagePrice,
            averagePriceSimulation:
              currentProduct?.averagePriceSimulation || null,
            markupSimulation: currentProduct?.markupSimulation || null,
            cmvSimulation: currentProduct?.cmvSimulation || null,
            created_at: currentProduct?.created_at || null,
            updated_at: currentProduct?.updated_at || null
          });
          setEditMode(true);
          setLoading(false);
        })
        .catch((error) => {
          setMessage({
            description: `Produto não encontrado, por favor confirme se o id do restaurante e do produto estão corretos`,
            status: 'error',
            autoHideDuration: 5000
          });
          setProductInfo('');
          setEditMode(false);
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleOnSaveSkuPlatform = ({ body, isEditMode }) => {
    const { id } = body;
    const handleResponse = (status, error) => {
      const verb = isEditMode ? 'atualizado' : 'criado';
      if (status) {
        setMessage && setMessage({
          status: 'success',
          description: `SKU ${verb} com sucesso`,
        });
        setCommonMessage({
          status: 'success',
          description: `SKU ${verb} com sucesso`,
        })
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'skuModal'
              ? {
                  ...modal,
                  open: false
                }
              : modal
          )
        );
        setSelectedSku('')

        getProduct()
        return;
      }
      setMessage && setMessage({
        status: 'error',
        description: error?.response?.data?.message,
      });
      setCommonMessage({
        status: 'error',
        description: error?.response?.data?.message,
      })
      setStatusCode(error.status);
      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'skuModal'
            ? {
                ...modal,
                open: false
              }
            : modal
        )
      );
    };

    if (isEditMode) {
      makePut('skuCards', body, handleResponse, `/platform/${id}`);
    } else {
      makePost('skuCards', body, handleResponse, `/platform`);
    }
  };

  async function fetchRestaurantsOfProduct(productId) {
    const response = await fetch(
      `${environment.catalog}/products/${productId}/groups`
    );
    const groups = await response.json();

    return groups;
  }

  function calcMarkupSimulation() {
    if (!products.averagePriceSimulation) return 0;

    if (dataTable.data.length == 0 && !products.approximateCost) return 0;

    const priceSimulation = parseFloat(
      products.averagePriceSimulation.toString().replace(',', '.')
    );

    if (noSkuProduct) {
      const approximateCost = parseFloat(products.approximateCost);
      products.markupSimulation = parseFloat(priceSimulation / approximateCost);

      return products.markupSimulation;
    }
    const skus = dataTable.data;
    let sum = 0;
    skus.forEach((sku) => {
      sum += sku.purchaseAverage;
    });

    const averageCost = parseFloat(sum / skus.length);
    products.markupSimulation = parseFloat(priceSimulation / averageCost);

    return products.markupSimulation;
  }

  const calcMarkup = () => {
    if (!productInfo.data?.averagePrice) return 0;

    if (dataTable.data.length == 0 && !productInfo.data?.approximateCost)
      return 0;

    const price = parseFloat(productInfo.data?.averagePrice);

    if (noSkuProduct) {
      const approximateCost = parseFloat(productInfo.data?.approximateCost);
      productInfo.data.markup = parseFloat(price / approximateCost);

      return productInfo.data?.markup;
    }
    const skus = dataTable.data;
    let sum = 0;
    skus.forEach((sku) => {
      sum += sku.purchaseAverage;
    });

    const averageCost = parseFloat(sum / skus.length);
    productInfo.data.markup = parseFloat(price / averageCost);

    return isNaN(productInfo.data?.markup) ? 0 : productInfo.data?.markup;
  }

  const calcCmvSimulation = () => {
    if (!products.averagePriceSimulation) return 0;

    if (dataTable.data.length == 0 && !products.approximateCost) return 0;

    const priceSimulation = parseFloat(
      products.averagePriceSimulation.toString().replace(',', '.')
    );

    if (noSkuProduct) {
      const approximateCost = parseFloat(products.approximateCost);
      products.cmvSimulation = parseFloat(
        (approximateCost / priceSimulation) * 100
      );

      return products.cmvSimulation;
    }
    const allProviders = products.skus.map((sku) => sku.providers).flat();
    allProviders.sort(
      (a, b) => new Date(b.recivedDate) - new Date(a.recivedDate)
    );

    const lastProviderReceived = allProviders[0];
    const findSku = products.skus.find(
      (sku) => sku.id == lastProviderReceived.skuId
    );
    let averageCost = 0;

    if (findSku.conversion == 1 || findSku.conversion == '1,000') {
      averageCost = allProviders[0].purchaseLastUnitary;
    } else {
      averageCost = findSku.priceUc;
    }

    products.cmvSimulation = parseFloat((averageCost / priceSimulation) * 100);

    return products.cmvSimulation;
  }

  const calcCmv = () => {
    if (!products.averagePrice) return 0;

    if (dataTable.data.length === 0 && !products.approximateCost) return 0;

    const price = parseFloat(products.averagePrice);

    if (noSkuProduct) {
      const approximateCost = parseFloat(products.approximateCost);
      products.cmv = parseFloat((approximateCost / price) * 100);

      return products.cmv;
    }

    const allProviders = products.skus.map((sku) => sku?.providers).flat();
    allProviders.sort(
      (a, b) => new Date(b.recivedDate) - new Date(a.recivedDate)
    );

    const lastProviderReceived = allProviders[0];
    const findSku = products.skus.find(
      (sku) => sku?.id === lastProviderReceived?.skuId
    );

    let averageCost = 0;

    if (findSku?.conversion == 1 || findSku?.conversion == '1,000') {
      averageCost =
        lastProviderReceived === undefined
          ? 0
          : lastProviderReceived?.purchaseLastUnitary;
    } else {
      averageCost = findSku === undefined ? 0 : findSku?.priceUc;
    }

    products.cmv = parseFloat((averageCost / price) * 100);

    return products.cmv;
  }

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const handleSubmitOnPlatformMode = () => {
    const skus = dataTable.data.map((sku) => {
      sku.quantity = parseFloat(
        sku.quantity.toString().replace('.', '').replace(/,/g, '.')
      );
      sku.conversion = parseFloat(
        sku.conversion.toString().replace('.', '').replace(/,/g, '.')
      );
      return sku;
    });

    const body = {
      restaurantId: 0,
      categoryId: treeView,
      averageCost: null,
      consumptionUnitsPrimaryId: ucPrimarySelectedId.id,
      consumptionUnitsSecondaryId: ucSecondarySelectedId,
      user: userInfo.user,
      description: products?.description,
      useOnCards: products.useOnCards,
      takeInventory: products.takeInventory,
      skus,
      lossPercentage,
      lossPercentageChanged,
      productProcesses: processTable?.data
    };

    if (editMode) {
      axios
        .put(`${environment.catalogV2}/products/${products.id}`, body, config)
        .then(() => {
          setOpen(true);
          setLoading(false);
          setOnlyClickOnce(false);
        })
        .catch((error) => {
          setLoading(false);
          setOnlyClickOnce(false);
          setAlertErr(true);
        });
      return;
    }

    if (products.storageLocations && products.frequencies) {
      axios
        .post(`${environment.catalog}/products`, body, config)
        .then(() => {
          setOpen(true);
          setLoading(false);
          setOnlyClickOnce(false);
        })
        .catch((error) => {
          setLoading(false);
          setOnlyClickOnce(false);
          setAlertErr(true);
          console.log(error);
        });
    }
  };

  const handleUpdateSKUMaterialTable = (value) => {
    dataTable.data.forEach((data) => {
      const selectedOption = options.find((element) => element.id === data.id);
      data.conversion =
        data?.unitsMeasurements === null || data.unitsMeasurements === undefined
          ? null
          : data.unitsMeasurements.toLowerCase() === 'un'
          ? data.quantity.toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'un' &&
            data.unitsMeasurements.toLowerCase() === 'g'
          ? (1).toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'un' &&
            data.unitsMeasurements.toLowerCase() === 'mg'
          ? (1).toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'un' &&
            data.unitsMeasurements.toLowerCase() === 'kg'
          ? (1).toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'un' &&
            data.unitsMeasurements.toLowerCase() === 'ml'
          ? (1).toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'un' &&
            data.unitsMeasurements.toLowerCase() === 'lt'
          ? (1).toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'kg' &&
            data.unitsMeasurements.toLowerCase() === 'g'
          ? (
              parseFloat(data?.quantity?.replace(',', '.')) / 1000
            ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'kg' &&
            data.unitsMeasurements.toLowerCase() === 'mg'
          ? (
              parseFloat(data?.quantity?.replace(',', '.')) / 1000000
            ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'kg' &&
            data.unitsMeasurements.toLowerCase() === 'kg'
          ? data.quantity.toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : value?.abbreviation.toLowerCase() === 'lt' &&
            data.unitsMeasurements.toLowerCase() === 'ml'
          ? parseFloat(data?.quantity?.replace(',', '.') / 1000).toLocaleString(
              'pt-br',
              { minimumFractionDigits: 3 }
            )
          : value?.abbreviation.toLowerCase() === 'lt' &&
            data.unitsMeasurements.toLowerCase() === 'lt'
          ? data.quantity.toLocaleString('pt-br', { minimumFractionDigits: 3 })
          : null;
      const restaurantPurchaseAverage =
        data?.restaurants[0]?.purchaseAverage || 0;
      const restaurantPurchaseLast =
        data?.restaurants[0]?.purchaseLast || 0;
        data.priceUc = parseFloat(
          data?.restaurants === null ||
            data?.restaurants === undefined ||
            data?.restaurants.length === 0
            ? null
            : parameters === 'LAST_PURCHASE'
            ? restaurantPurchaseLast *
              (1 /
                parseFloat(
                  data.conversion.toString().replace('.', '').replace(/,/g, '.')
                ))
            : restaurantPurchaseAverage *
              (1 /
                parseFloat(
                  data.conversion.toString().replace('.', '').replace(/,/g, '.')
                ))
        );
      
    });
  };

  const pluckIds = (arr) => arr.map((obj) => obj.id);

  const buildStores = (subGroup) => {
    const stores = subGroup.stores.filter((store) =>
      pluckIds(restaurantStores).includes(store.id)
    );

    return stores.map((store) => ({
      id: store.id,
      name: store.name
    }));
  };

  const buildSubGroups = (group) => {
    const subGroups = group.subGroups.filter((subGroup) =>
      pluckIds(restaurantSubGroups).includes(subGroup.id)
    );

    return subGroups.map((subGroup) => ({
      id: subGroup.id,
      name: subGroup.name,
      stores: buildStores(subGroup)
    }));
  };

  const onSubmitProduct = () => {
    setOnlyClickOnce(true);
    setLoadingSaving(true)
    const getPlatformMode = userGroups.groups
      .map((group) =>
        group.subGroups.map((store) => store.stores.find((s) => s?.id === 0))
      )
      .flat(Infinity)[0];

    if (getPlatformMode?.name === 'Plataforma') {
      handleSubmitOnPlatformMode();
      return;
    }

    if (noSkuProduct === false && !dataTable.data.length) {
      setMessage({
        description: `Por favor adicione um sku ou selecione a opção "Produto sem SKU"`,
        status: 'error'
      });
      setLossPercentageError(true);
      setOnlyClickOnce(false);
    } else {
      if (
        (typeof products?.approximateCost === 'number'
          ? products?.approximateCost
          : parseFloat(
              products?.approximateCost?.replace('.', '')?.replace(/,/g, '.')
            )) < 0 &&
        !dataTable.data.length
      ) {
        setMessage({
          description: `Preço aproximado deve ser preenchido`,
          status: 'error'
        });
        setOnlyClickOnce(false);
        return;
      }

      treeView === ''
        ? setCategoriesError(true)
        : setTimeout(() => {
            if (editMode) {
              const skus = dataTable.data;
              let sum = 0;
              const lastCostSku =
                productInfo.data?.type !== 'INTERNAL' && skus.length
                  ? skus.reduce((a, b) =>
                      a.lastCostSku > b.lastCostSku ? a : b
                    )
                  : 0;
              skus.forEach((sku) => {
                sum += sku.priceUc;
                sku.quantity = parseFloat(
                  sku.quantity.toString().replace('.', '').replace(/,/g, '.')
                );
                sku.conversion = parseFloat(
                  sku.conversion.toString().replace('.', '').replace(/,/g, '.')
                );
              });

              if (skus.length) {
                products.frequencies = selectedFrequencies;
                products.storageLocations = selectedLocation;

                if (
                  !products.takeInventory ||
                  (products.storageLocations && products.frequencies)
                ) {
                  setLoading(true);

                  const body = {
                    groups: userGroups.groups.map((group) =>
                      group.hasOwnProperty('id')
                    )[0]
                      ? restaurantGroups.map((group) => ({
                          id: group.id,
                          name: group.name,
                          subGroups: buildSubGroups(group)
                        }))
                      : null,
                    restaurantId: productInfo.data?.restaurantId || originId,
                    categoryId: treeView,
                    consumptionUnitsPrimaryId: ucPrimarySelectedId.id,
                    consumptionUnitsSecondaryId: ucSecondarySelectedId,
                    user: userInfo.user,
                    originIdCard:
                      productInfo.data?.type === 'INTERNAL'
                        ? productInfo.data?.originIdCard
                        : null,
                    description: products?.description,
                    salePrice: parseFloat(products.salePrice),
                    discountPrice: parseFloat(products.discountPrice),
                    averageCost: parseFloat(sum / skus.length),
                    lastCost:
                      productInfo.data?.type !== 'INTERNAL'
                        ? parseFloat(skus.length ? lastCostSku.priceUc : 0)
                        : 0,
                    quantityMinStock: products.quantityMinStock
                      ? parseFloat(
                          products.quantityMinStock
                            .toString()
                            .replace('.', '')
                            .replace(/,/g, '.')
                        )
                      : null,
                    quantityMaxStock: products.quantityMaxStock
                      ? parseFloat(
                          products.quantityMaxStock
                            .toString()
                            .replace('.', '')
                            .replace(/,/g, '.')
                        )
                      : null,
                    approximateCost: products.approximateCost
                      ? parseFloat(
                          products.approximateCost
                            .toString()
                            .replace('.', '')
                            .replace(/,/g, '.')
                        )
                      : null,
                    useOnCards: products.useOnCards,
                    takeInventory: products.takeInventory,
                    frequencies: products.takeInventory
                      ? products.frequencies
                      : [],
                    storageLocations: products.takeInventory
                      ? products.storageLocations
                      : [],
                    skus,
                    markup: products.markup
                      ? parseFloat(parseFloat(products.markup).toFixed(3))
                      : null,
                    cmv: products.cmv
                      ? parseFloat(parseFloat(products.cmv).toFixed(3))
                      : null,
                    averagePriceSimulation: products.averagePriceSimulation
                      ? parseFloat(
                          parseFloat(
                            products.averagePriceSimulation
                              .toString()
                              .replace(',', '.')
                          ).toFixed(3)
                        )
                      : null,
                    markupSimulation: products.markupSimulation
                      ? parseFloat(
                          parseFloat(products.markupSimulation).toFixed(3)
                        )
                      : null,
                    cmvSimulation: products.cmvSimulation
                      ? parseFloat(
                          parseFloat(products.cmvSimulation).toFixed(3)
                        )
                      : null,
                    lossPercentage,
                    lossPercentageChanged,
                    variationPercentage,
                    unifiedSkus,
                    productProcesses: processTable?.data,
                  };

                  axios
                    .put(
                      `${environment.catalogV2}/products/${products.id}`,
                      body,
                      config
                    )
                    .then((res) => {
                      setMessage({
                        ...message,
                        description: res?.data?.message,
                        status: 'success' 
                      })
                      onCloseFunc && onCloseFunc()
                      setLoading(false);
                      setUpdateList && setUpdateList(true);
                      setLoadingSaving(false)
                    })
                    .catch((error) => {
                      setMessageAlert({
                        description: error.response.data.message,
                        status: 'error'
                      });
                      setLoading(false);
                      setLoadingSaving(false)
                      setUpdateList && setUpdateList(false);
                      
                    });
                } 
              } else if (noSkuProduct) {
                products.frequencies = selectedFrequencies;
                products.storageLocations = selectedLocation;
                
                const body = {
                  groups: userGroups.groups.map((group) =>
                    group.hasOwnProperty('id')
                  )[0]
                    ? restaurantGroups.map((group) => ({
                        id: group.id,
                        name: group.name,
                        subGroups: buildSubGroups(group)
                      }))
                    : null,
                  restaurantId: productInfo.data?.restaurantId || originId,
                  categoryId: treeView,
                  consumptionUnitsPrimaryId: ucPrimarySelectedId.id,
                  consumptionUnitsSecondaryId: ucSecondarySelectedId,
                  user: userInfo.user,
                  originIdCard:
                    productInfo.data?.type === 'INTERNAL'
                      ? productInfo.data?.originIdCard
                      : null,
                  description: products?.description,
                  salePrice: parseFloat(products.salePrice),
                  discountPrice: parseFloat(products.discountPrice),
                  averageCost: parseFloat(sum / skus.length),
                  lastCost:
                    productInfo.data?.type !== 'INTERNAL'
                      ? parseFloat(skus.length ? lastCostSku.priceUc : 0)
                      : 0,
                  quantityMinStock: products.quantityMinStock
                    ? parseFloat(
                        products.quantityMinStock
                          .toString()
                          .replace('.', '')
                          .replace(/,/g, '.')
                      )
                    : null,
                  quantityMaxStock: products.quantityMaxStock
                    ? parseFloat(
                        products.quantityMaxStock
                          .toString()
                          .replace('.', '')
                          .replace(/,/g, '.')
                      )
                    : null,
                  approximateCost: products.approximateCost
                    ? parseFloat(
                        products.approximateCost
                          .toString()
                          .replace('.', '')
                          .replace(/,/g, '.')
                      )
                    : null,
                  useOnCards: products.useOnCards,
                  takeInventory: products.takeInventory,
                  frequencies: products.frequencies,
                  storageLocations: products.storageLocations,
                  noSkuProduct,
                  skus,
                  markup: products.markup
                    ? parseFloat(parseFloat(products.markup).toFixed(3))
                    : null,
                  cmv: products.cmv
                    ? parseFloat(parseFloat(products.cmv).toFixed(3))
                    : null,
                  averagePriceSimulation: products.averagePriceSimulation
                    ? parseFloat(
                        parseFloat(
                          products.averagePriceSimulation
                            .toString()
                            .replace(',', '.')
                        ).toFixed(3)
                      )
                    : null,
                  markupSimulation: products.markupSimulation
                    ? parseFloat(
                        parseFloat(products.markupSimulation).toFixed(3)
                      )
                    : null,
                  cmvSimulation: products.cmvSimulation
                    ? parseFloat(parseFloat(products.cmvSimulation).toFixed(3))
                    : null,
                  lossPercentage,
                  lossPercentageChanged,
                  variationPercentage,
                  productProcesses: processTable?.data,
                  counts: {
                    unifiedSkus
                  }
                };

                setLoading(true);

                axios
                  .put(
                    `${environment.catalogV2}/products/${products.id}`,
                    body,
                    config
                  )
                  .then((res) => {
                    setOpen(true);
                    setLoading(false);
                    onCloseFunc &&
                      setTimeout(() => {
                        onCloseFunc(true);
                      }, 500);
                    setUpdateList && setUpdateList(true);
                    setLoadingSaving(false)

                  })
                  .catch((error) => {
                    setMessage({
                      description: error.response.data.message,
                      status: 'error'
                    });
                    setStatusCode(error?.response?.status)
                    setLoading(false);
                    setLoadingSaving(false)

                    setAlertErr(true);
                    setUpdateList && setUpdateList(false);
                  });
              } else {
                setAlertErrSkus(true);
              }
            } else {
              const skus = dataTable.data;
              let sum = 0;
              const lastCostSku =
                productInfo?.data &&
                productInfo?.data?.type !== 'INTERNAL' &&
                skus.length
                  ? skus.reduce((a, b) =>
                      a.lastCostSku > b.lastCostSku ? a : b
                    )
                  : 0;
              skus.forEach((sku) => {
                sum += sku.priceUc;
                sku.quantity = parseFloat(
                  sku.quantity.toString().replace('.', '').replace(/,/g, '.')
                );
                sku.conversion = parseFloat(
                  sku.conversion.toString().replace('.', '').replace(/,/g, '.')
                );
              });
              setAlertErrSkus(false);

              if (skus.length) {
                if (products.storageLocations && products.frequencies) {
                  setLoading(true);
                  axios
                    .post(
                      `${environment.catalog}/products`,
                      {
                        groups: userGroups.groups.map((group) =>
                          group.hasOwnProperty('id')
                        )[0]
                          ? restaurantGroups.map((group) => ({
                              id: group.id,
                              name: group.name,
                              subGroups: buildSubGroups(group)
                            }))
                          : null,
                        restaurantId:
                          productInfo.data?.restaurantId || originId,
                        categoryId: treeView,
                        averageCost: parseFloat(sum / skus.length),
                        consumptionUnitsPrimaryId: ucPrimarySelectedId.id,
                        consumptionUnitsSecondaryId: ucSecondarySelectedId,
                        user: userInfo.user,
                        description: products?.description,
                        salePrice: parseFloat(products.salePrice),
                        discountPrice: parseFloat(products.discountPrice),
                        lastCost:
                          productInfo?.data &&
                          productInfo?.data?.type !== 'INTERNAL'
                            ? parseFloat(skus.length ? lastCostSku.priceUc : 0)
                            : 0,
                        quantityMinStock: products.quantityMinStock
                          ? parseFloat(
                              products.quantityMinStock
                                .toString()
                                .replace('.', '')
                                .replace(/,/g, '.')
                            )
                          : null,
                        quantityMaxStock: products.quantityMaxStock
                          ? parseFloat(
                              products.quantityMaxStock
                                .toString()
                                .replace('.', '')
                                .replace(/,/g, '.')
                            )
                          : null,
                        approximateCost: products.approximateCost
                          ? parseFloat(
                              products.approximateCost
                                .toString()
                                .replace('.', '')
                                .replace(/,/g, '.')
                            )
                          : null,
                        useOnCards: products.useOnCards,
                        takeInventory: products.takeInventory,
                        frequencies: products.frequencies,
                        storageLocations: products.storageLocations,
                        skus,
                        averagePriceSimulation: products.averagePriceSimulation
                          ? parseFloat(
                              parseFloat(
                                products.averagePriceSimulation
                                  .toString()
                                  .replace(',', '.')
                              ).toFixed(3)
                            )
                          : null,
                        markupSimulation: products.markupSimulation
                          ? parseFloat(
                              parseFloat(products.markupSimulation).toFixed(3)
                            )
                          : null,
                        cmvSimulation: products.cmvSimulation
                          ? parseFloat(
                              parseFloat(products.cmvSimulation).toFixed(3)
                            )
                          : null,
                        lossPercentage,
                        lossPercentageChanged,
                        variationPercentage,
                        unifiedSkus,
                        productProcesses: processTable?.data
                      },
                      config
                    )
                    .then((res) => {
                      setOpen(true);
                      setUpdateList && setUpdateList(true);
                      onClose &&
                        setTimeout(() => {
                          onClose(true);
                        }, 1000);
                        setLoadingSaving(false)
                    })
                    .catch((error) => {
                      setCommonMessage({
                        description: error?.response?.data?.message,
                        status: 'error'
                      });
                      setStatusCode(error?.response?.status)
                      setLoading(false);
                      setLoadingSaving(false)
                      setUpdateList && setUpdateList(false);
                    });
                } 
              } else if (isModal ? true : noSkuProduct) {
                setLoading(true);
                axios
                  .post(
                    `${environment.catalog}/products`,
                    {
                      groups: userGroups.groups.map((group) =>
                        group.hasOwnProperty('id')
                      )[0]
                        ? restaurantGroups.map((group) => ({
                            id: group.id,
                            name: group.name,
                            subGroups: buildSubGroups(group)
                          }))
                        : null,
                      restaurantId: productInfo.data?.restaurantId || originId,
                      categoryId: treeView,
                      averageCost: parseFloat(sum / skus.length),
                      consumptionUnitsPrimaryId: ucPrimarySelectedId.id,
                      consumptionUnitsSecondaryId: ucSecondarySelectedId,
                      user: userInfo.user,
                      description: products?.description,
                      salePrice: parseFloat(products.salePrice),
                      discountPrice: parseFloat(products.discountPrice),
                      lastCost:
                        productInfo.data &&
                        productInfo?.data?.type !== 'INTERNAL'
                          ? parseFloat(skus.length ? lastCostSku.priceUc : 0)
                          : 0,
                      quantityMinStock: products.quantityMinStock
                        ? parseFloat(
                            products.quantityMinStock
                              .toString()
                              .replace('.', '')
                              .replace(/,/g, '.')
                          )
                        : null,
                      quantityMaxStock: products.quantityMaxStock
                        ? parseFloat(
                            products.quantityMaxStock
                              .toString()
                              .replace('.', '')
                              .replace(/,/g, '.')
                          )
                        : null,
                      approximateCost: products.approximateCost
                        ? parseFloat(
                            products.approximateCost
                              .toString()
                              .replace('.', '')
                              .replace(/,/g, '.')
                          )
                        : null,
                      useOnCards: products.useOnCards,
                      takeInventory: products.takeInventory,
                      frequencies: products.frequencies,
                      storageLocations: products.storageLocations,
                      skus,
                      averagePriceSimulation: products.averagePriceSimulation
                        ? parseFloat(
                            parseFloat(
                              products.averagePriceSimulation
                                .toString()
                                .replace(',', '.')
                            ).toFixed(3)
                          )
                        : null,
                      markupSimulation: products.markupSimulation
                        ? parseFloat(
                            parseFloat(products.markupSimulation).toFixed(3)
                          )
                        : null,
                      cmvSimulation: products.cmvSimulation
                        ? parseFloat(
                            parseFloat(products.cmvSimulation).toFixed(3)
                          )
                        : null,
                      lossPercentage,
                      lossPercentageChanged,
                      variationPercentage,
                      counts: {
                        unifiedSkus
                      }
                    },
                    config
                  )
                  .then((res) => {
                    setOpen(true);
                    setLoadingSaving(false)
                    setUpdateList && setUpdateList(true);
                    onClose &&
                      setTimeout(() => {
                        onClose(true);
                      }, 1000);
                  })
                  .catch((error) => {
                    setLoading(false);
                    setLoadingSaving(false)
                    setAlertErr(true);
                    setUpdateList && setUpdateList(false);
                  });
              } else {
                setAlertErrSkus(true);
              }
            }
          }, 400);
    }
  };

  const urlSearchParms = new URLSearchParams(window.location.search);

  const handleResponseProductData = (data) => {
    if (!data) return;
    setNoSkuProduct(!data.skus?.length);
    setUnifiedSkus(data.unifiedSkus);
    setUcPrimarySelectedId(data.consumptionUnitsPrimary);
    setUcSecondarySelectedId(data.consumptionUnitsSecondary?.id);
    setAbbreviation(data.consumptionUnitsPrimary);
    setProducts({
      id: data.id,
      restaurantId: data.restaurantId,
      categoryId: data.categoryId,
      lastCost: data.lastCost,
      averageCost: data.averageCost,
      consumptionUnitsPrimaryId: data.consumptionUnitsPrimaryId,
      consumptionUnitsSecondaryId: data.consumptionUnitsSecondaryId,
      user: data.user,
      description: data?.description,
      salePrice: data.salePrice,
      discountPrice: data.discountPrice,
      useOnCards: data.useOnCards,
      takeInventory: !!data.setups?.length,
      quantityMinStock: data.quantityMinStock,
      quantityMaxStock: data.quantityMaxStock,
      approximateCost: data.approximateCost,
      storageLocations: data.storageLocations,
      frequencies: data.frequencies,
      skus: data.skus,
      averagePriceSimulation: data.averagePriceSimulation,
      markupSimulation: data.markupSimulation,
      cmvSimulation: data.cmvSimulation,
      created_at: data?.created_at,
      updated_at: data?.updated_at
    });
    setHideInventoryFields(!data.setups?.length);
    setLossPercentage(data?.lossPercentage);
    setLossPercentageChanged(data?.lossPercentage);
    setVariationPercentage(data?.variationPercentage);
    const newSkusList =
      data.skus?.length &&
      data.skus.map((sku) => ({
        id: sku.skus?.id,
        description: sku.skus?.description || '',
        quantity: sku.skus
          ? parseFloat(sku.skus.quantity).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
          : null,
        unitsMeasurements: sku.skus
          ? sku.skus?.unitsMeasurements?.abbreviation
          : null,
        purchaseAverage:
          sku.skus && sku.skus?.restaurants?.length
            ? sku.skus?.restaurants[0]?.purchaseAverage
            : null,
        purchaseLast:
          sku.skus && sku.skus?.restaurants?.length
            ? sku.skus?.restaurants[0]?.purchaseLast
            : null,
        conversion: sku.skus
          ? parseFloat(sku.conversion).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
          : null,
        priceUc: sku ? sku.priceUc : null,
        skuBloom: sku.skus?.skuBloom
      }));

    setDataTable({
      data: newSkusList || []
    });

    setProductInfo({
      ...{
        data
      }
    });

    setProductDescription(data?.description || '');
    setCategoryName(data.categories?.description || undefined);
    setTreeView(data.categoryId);
    setEditMode(true);
    setLoadingProductFromParams(false);
  };

  const handlePropsFromUrlParams = () => {
    const productId = urlSearchParms.get('productId');
    if (!productDataModal?.data?.id && !paramsProductId) return;
    setLoadingProductFromParams(true);
    const params = [{ urlParam: `originId=${originId}` }];
    makeGet(
      'productsCatalog',
      params,
      handleResponseProductData,
      `${productDataModal?.data?.id || paramsProductId}`
    );
  };

  const onReturnParams = urlSearchParms.get('onReturn');

  const renderHeaderOptions = () => {
    if (userGroups.groups.map((group) => group.hasOwnProperty('id'))[0]) {
      return (
        <>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              className="autocompleteSmall"
              limitTags={1}
              options={userGroups.groups}
              disableCloseOnSelect
              disabled={onlyView}
              size="small"
              value={restaurantGroups}
              getOptionLabel={(option) => option.name}
              classes={{ input: classes.input, option: classes.option }}
              onChange={(e, value) => {
                if (value) {
                  setRestaurantGroups(value);
                  setRestaurantSubGroups(
                    value
                      .reduce((acc, group) => [...acc, group.subGroups], [])
                      .flat()
                  );
                  setRestaurantStores(
                    value
                      .reduce((acc, group) => [...acc, group.subGroups], [])
                      .flat()
                      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                      .flat()
                  );
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Grupos"
                  placeholder="Grupos"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              className="autocompleteSmall"
              limitTags={1}
              options={restaurantGroups
                .reduce((acc, group) => [...acc, group.subGroups], [])
                .flat()}
              value={restaurantSubGroups}
              disableCloseOnSelect
              disabled={onlyView}
              size="small"
              getOptionLabel={(option) => option.name}
              classes={{ input: classes.input, option: classes.option }}
              onChange={(e, value) => {
                if (value) {
                  setRestaurantSubGroups(value);
                  setRestaurantStores(
                    value
                      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                      .flat()
                  );
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Subgrupos"
                  placeholder="Subgrupos"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              className="autocompleteSmall"
              limitTags={1}
              options={restaurantSubGroups
                .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                .flat()}
              value={restaurantStores}
              disableCloseOnSelect
              disabled={onlyView}
              size="small"
              getOptionLabel={(option) => option.name}
              classes={{ input: classes.input, option: classes.option }}
              onChange={(e, value) => {
                if (value) {
                  setRestaurantStores(value);
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Restaurantes"
                  placeholder="Restaurantes"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>
        </>
      );
    }
    return (
      <Grid item xs={12}>
        <Autocomplete
          options={userInfo.companies.groups[0].subGroups[0].stores}
          getOptionLabel={(option) => option.name}
          size="small"
          limitTags={1}
          className="autocompleteSmall restaurantsCustomSelect"
          disabled={onlyView}
          value={restaurantsId}
          multiple
          onChange={(event, value) => {
            if (value) {
              setRestaurantsId(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={params.id}
              type="text"
              name="restaurantsId"
              label="Restaurantes"
              variant="outlined"
              InputLabelProps={{
                style: { fontFamily: 'Lato', fontSize: '12px' }
              }}
            />
          )}
        />
      </Grid>
    );
  };

  const handleChangeCategory = (categoryId, category) => {
    if (category) {
      setLossPercentage(category.lossPercentage);
      setLossPercentageCategory(category.lossPercentage);
    } else {
      setLossPercentage(null);
      setLossPercentageCategory(null);
    }

    setCategoryName(category?.description || undefined);
    setTreeView(categoryId);
    setCategoriesError(false);
    setLossPercentageError(false);
  };

  const handleClose = (event, reason, refreshList = false) => {
    if (onReturnParams === 'categories' || paramsProductId) {
      onClose(refreshList);
    } else if (paramsID == 0) {
      history.push({
        pathname: `/admin/catalogo/produtos/${paramsID}`,
        state: { prevPath: 'productForm' }
      });

    }else {
      history.push({
        pathname: `/catalogo/produtos/${paramsID}`,
        state: { prevPath: 'productForm' }
      });
    }

    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const normalizeString = (string) =>
    string?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '');

  const searchExistingProduct = (value) => {
    axios
      .get(
        `${environment.catalog}/products?page=1&size=1500&originId=${getId}&productDescription=${value}`
      )
      .then((res) => {
        const content = res?.data?.content;

        if (content?.length) {
          setAlreadyExistingProducts(content);
          setProductWithSameDescription(
            content?.length === 1 &&
              normalizeString(content[0]?.description)?.toUpperCase() ===
                normalizeString(value)?.toUpperCase()
          );
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'alreadyExistingProductsModal'
                ? {
                    ...modal,
                    open: true
                  }
                : modal
            )
          );
        } else {
          setProductWithSameDescription(false);
        }
      })
      .catch((err) => {
        console.log('err', err);
      }).finally(() => {
        setDescriptionEdit(false)
      })
  };

  const getProcess = async () => {
    axios
      .get(`${environment.catalog}/processes?restaurantId=${getId}&status=ACTIVE`)
      .then((res) => {
        setProcess(res?.data?.content)
      })
  }

  const handleAutocompleteChange = (e, value) => {
    const newProcessBody = {
      percentage: quantityprocess ? Number(quantityprocess) : 0,
      processes: {
        description: value?.description,
        id: value?.id,
        restaurantId: getId,
        status: "ACTIVE",
        user: "bloom"
      }
    };

    setProcessTable((prevState) => ({
      ...prevState,
      data: [prevState?.data, newProcessBody].flat()?.filter((item) => item)
    }));

    setQuantityProcess('')
    setProcessRow({ description: '' })
  };

  const handleExistingProductSearch = () => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      searchExistingProduct(productDescription);
    }, 1000);

    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  const handleChangePrimaryUnit = (e, value) => {
    if (value) {
      setUnitAlert(false);
      setUcPrimarySelectedId(value);
      if (value.abbreviation.toLowerCase() === 'kg') {
        setAbbreviation('kg,mg,g');
      } else if (value.abbreviation.toLowerCase() === 'lt') {
        setAbbreviation('l,lt,ml');
      } else {
        setAbbreviation('');
      }
      handleUpdateSKUMaterialTable(value);
    } else {
      setUnitAlert(true);
      setUcPrimarySelectedId({});
      setUcSecondarySelectedId({});
      setAbbreviation(null);
    }
  };

  const validUnits = {
    lt: ['lt', 'ml'],
    kg: ['g', 'mg', 'kg'],
    un: ['lt', 'ml', 'g', 'mg', 'kg', 'un']
  };

  const validateUnitMeasurement = (selectedSkuUnit) => {
    const selectedUnitAbbr = ucPrimarySelectedId.abbreviation;

    if (validUnits[selectedUnitAbbr].includes(selectedSkuUnit)) return true;
    return false;
  };

  const handleSelectProduct = (e, value) => {
    const selectedUnitAbbr = ucPrimarySelectedId.abbreviation;
    const selectedSkuUnitAbbr = value.unitsMeasurements.abbreviation;

    if (!validateUnitMeasurement(selectedSkuUnitAbbr)) {
      setUnityWarningModal({
        open: true,
        title: 'Unidade de SKU inválida',
        message: `Para a Un Primária (${selectedUnitAbbr}), você só pode selecionar skus com unidade de (${validUnits[
          selectedUnitAbbr
        ].join(', ')}).`
      });
      return;
    }

    if (value) {
      const hasRestaurants = value?.restaurants && value?.restaurants?.length;
      const restaurantPurchaseAverage =
        value?.restaurants[0]?.purchaseAverage || 0;
      const restaurantPurchaseLast = value?.restaurants[0]?.purchaseLast || 0;
      const parameterRestaurantPurchase =
        parameters === 'LAST_PURCHASE'
          ? restaurantPurchaseLast
          : restaurantPurchaseAverage;
      const unitAbbreviation =
        value?.unitsMeasurements?.abbreviation?.toLowerCase();
      const ucPrimaryAbbreviation =
        ucPrimarySelectedId?.abbreviation?.toLowerCase();
      const isSomeOfAbbreviation =
        unitAbbreviation === 'g' ||
        unitAbbreviation === 'mg' ||
        unitAbbreviation === 'kg' ||
        unitAbbreviation === 'ml' ||
        unitAbbreviation === 'lt';
      const isUnitKilogramOrLiter =
        unitAbbreviation === 'kg' || unitAbbreviation === 'lt';
      const isUnitGramOrMilliliter =
        unitAbbreviation === 'g' || unitAbbreviation === 'ml';
      const isUcPrimaryKilogramOrLiter =
        ucPrimaryAbbreviation === 'kg' || ucPrimaryAbbreviation === 'lt';
      const skuConversionValue = unitAbbreviation
        ? unitAbbreviation === 'un' ||
          (isUcPrimaryKilogramOrLiter && isUnitKilogramOrLiter)
          ? value?.quantity.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
          : ucPrimaryAbbreviation === 'un' && isSomeOfAbbreviation
          ? (1).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
          : isUcPrimaryKilogramOrLiter && isUnitGramOrMilliliter
          ? ((value?.quantity || 1) / 1000).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
          : ucPrimaryAbbreviation === 'kg' && unitAbbreviation === 'mg'
          ? ((value?.quantity || 1) / 1000000).toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })
          : null
        : null;

      const newSkuRowBody = {
        id: value?.id,
        description: e.target.innerText,
        quantity: (value?.quantity || 0).toLocaleString('pt-br', {
          minimumFractionDigits: 3
        }),
        unitsMeasurements: value?.unitsMeasurements?.abbreviation || null,
        purchaseAverage: hasRestaurants
          ? parseFloat(restaurantPurchaseAverage)
          : '-',
        purchaseLast: hasRestaurants ? parseFloat(restaurantPurchaseLast) : '-',
        conversion: skuConversionValue,
        priceUc: hasRestaurants
          ? parameterRestaurantPurchase *
            (1 /
              parseFloat(
                skuConversionValue
                  .toString()
                  .replace('.', '')
                  .replace(/,/g, '.')
              ))
          : null,
        restaurants: value?.restaurants || [],
        lastCostSku: value?.restaurants[0]?.updated_at || '',
        skuBloom: value.skuBloom
      };

      setNewSkuRow({ description: '' });

      setDataTable((prevState) => ({
        ...prevState,
        data: [prevState?.data, newSkuRowBody].flat()
      }));
    } else {
      setNewSkuRow({ description: '' });
    }
  };

  const formInputs = () => (
    <div
      className={`${isModal && 'cardDefault pt16'}`}
      style={{ borderRadius: isModal && '0' }}
    >
      <form
        onSubmit={handleSubmit(onSubmitProduct)}
        className={classes.container}
      >
        <Grid container spacing={1} style={{ marginBottom: '16px' }}>
          <Grid item xs={9}>
            <div className="cardDefault">
              <Grid container spacing={5} className="mb20">
                {renderHeaderOptions()}
              </Grid>

              <Grid container spacing={5} className="mb10">
                <Grid item xs={4}>
                  <TextField
                    onChange={(e) => {
                      const { value } = e.target;
                      setDescriptionEdit(true)
                      setProductDescription(value);
                      setProducts((prevState) => ({
                        ...prevState,
                        description: value.trim()
                      }));
                    }}
                    onBlur={products?.description && descriptionEdit && handleExistingProductSearch}
                    id="productFormInputDescription"
                    type="text"
                    name="description"
                    variant="outlined"
                    label="Descrição"
                    placeholder="Digite aqui..."
                    required
                    fullWidth
                    size="small"
                    disabled={onlyView}
                    className="textFieldSmall"
                    value={productDescription || ''}
                    error={Boolean(errors.description)}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={2} id="productFormInputUnityPrimary">
                  <Autocomplete
                    id="asynchronous-demo"
                    getOptionSelected={(option, value) =>
                      option.abbreviation === value.abbreviation
                    }
                    getOptionLabel={(option) => option.abbreviation}
                    options={consumptionUnits}
                    value={ucPrimarySelectedId}
                    size="small"
                    disabled={onlyView}
                    getOptionDisabled={(option) => {
                      if (!dataTable?.data?.length) return;

                      const [firstSkuDataTable] = dataTable.data;
                      const { abbreviation } = option;
                      const { unitsMeasurements = '' } = firstSkuDataTable;

                      if (
                        unitsMeasurements === 'g' ||
                        unitsMeasurements === 'mg' ||
                        unitsMeasurements === 'kg'
                      ) {
                        return (
                          abbreviation !== 'un' && abbreviation !== 'kg'
                        );
                      }

                      if (unitsMeasurements === 'un') {
                        return abbreviation !== 'un';
                      }

                      if (
                        unitsMeasurements === 'ml' ||
                        unitsMeasurements === 'lt'
                      ) {
                        return (
                          abbreviation !== 'un' && abbreviation !== 'lt'
                        );
                      }
                    }}
                    className="autocompleteSmall"
                    onChange={handleChangePrimaryUnit}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Un Primária"
                        error={errors.units}
                        variant="outlined"
                        placeholder="Selecione..."
                        required
                        value={ucPrimarySelectedId}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.abbreviation}
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      name="ucSecondarySelected"
                      variant="outlined"
                      select
                      label="Un Secundária"
                      placeholder="Selecione..."
                      id="demo-simple-select"
                      value={ucSecondarySelectedId}
                      onChange={(e) => {
                        setUcSecondarySelectedId(e.target.value);
                      }}
                      size="small"
                      fullWidth
                      className="textFieldSmall"
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={
                        !(
                          ucPrimarySelectedId &&
                          ucPrimarySelectedId.abbreviation &&
                          ucPrimarySelectedId.abbreviation.toLowerCase() ===
                            'un'
                        ) || onlyView
                      }
                    >
                      <MenuItem value={null}>
                        <em>Nenhuma</em>
                      </MenuItem>

                      {consumptionUnits.map((unit) => (
                        <MenuItem
                          key={unit.id}
                          disabled={unit.abbreviation == 'un'}
                          value={unit.id}
                        >
                          {unit.abbreviation}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                {(userGroups.groups.map((group) =>
                  group.hasOwnProperty('id')
                )[0] ||
                  profileType === 'admin') && (
                  <Grid
                    item
                    xs={!editMode || userInfo.user !== 'admin' ? 2 : 4}
                  >
                    <FormControl
                      className="selectSmallBox"
                      fullWidth
                      error={Boolean(errors.categories)}
                    >
                      <label className="fontSizeDefault pAbsolute">
                        Categorias*
                      </label>

                      <TreeSelect
                        id="productFormCategorieInput"
                        suffixIcon={
                          <svg
                            className="MuiSvgIcon-root pAbsolute"
                            style={{ right: 0, bottom: '-7px' }}
                            focusable="false"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                          >
                            <path d="M7 10l5 5 5-5z" />
                          </svg>
                        }
                        style={{ width: '100%' }}
                        allowClear
                        value={categoryName || undefined}
                        onDropdownVisibleChange={(event) => {
                          setLoadCategories(event);
                        }}
                        disabled={onlyView}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={categories}
                        placeholder="Selecione..."
                        showSearch
                        filterTreeNode={(search, item) =>
                          item?.title
                            ?.toLowerCase()
                            ?.normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            ?.indexOf(
                              search
                                ?.toLowerCase()
                                ?.normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                            ) >= 0
                        }
                        treeDefaultExpandAll
                        onChange={(element) => {
                          if (!element) {
                            handleChangeCategory(null, '');
                          }
                        }}
                        onSelect={(element, value) => {
                          if (element) {
                            handleChangeCategory(value?.id, value);
                          }
                        }}
                      />

                      <Tooltip
                        title="Preencha este campo."
                        placement="bottom-start"
                        open={Boolean(categoriesError)}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                      >
                        <span width="1px" />
                      </Tooltip>
                    </FormControl>
                  </Grid>
                )}

                {(!editMode || userInfo.user !== 'admin') && (
                  <Tooltip
                    title="% de Perda aceita na Contagem"
                    placement="bottom-start"
                  >
                    <Grid item xs={2}>
                      <CurrencyTextField
                        label="% Perda Contagem "
                        className="textFieldSmall right w100"
                        type="text"
                        placeholder="Digite aqui..."
                        disabled={onlyView}
                        size="small"
                        variant="outlined"
                        minimunValue="0"
                        decimalPlaces={2}
                        decimalPlacesShowOnFocus={2}
                        name="lossPercentage"
                        required={
                          noSkuProduct ? false : !dataTable.data.length
                        }
                        outputFormat="string"
                        currencySymbol=""
                        decimalCharacter=","
                        digitGroupSeparator="."
                        value={lossPercentage}
                        onBlur={(e) => {
                          const value =
                            e.target.value != ''
                              ? parseFloat(
                                  e.target.value
                                    .replace('.', '')
                                    .replace(',', '.')
                                )
                              : 0;

                          if (value !== lossPercentageCategory) {
                            setLossPercentageChanged(true);
                            setLossPercentage(value);
                          } else {
                            setLossPercentageChanged(false);
                            setLossPercentage(lossPercentageCategory);
                          }
                        }}
                      />
                      <Tooltip
                        title="Preencha este campo."
                        placement="bottom-start"
                        open={Boolean(lossPercentageError)}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                      >
                        <span width="1px" />
                      </Tooltip>
                    </Grid>
                  </Tooltip>
                )}
              </Grid>

              {userGroups.groups.map((group) =>
                group.hasOwnProperty('id')
              )[0] && (
                <Grid container spacing={5}>
                  <Grid className="inputBox mb10" container item xs={12}>
                    <Grid item xs={3}>
                      <Checkbox
                        name="productUseOnCards"
                        size="small"
                        disabled={onlyView}
                        className="checkBoxSmall"
                        color="primary"
                        checked={
                          products.useOnCards === false
                            ? products.useOnCards
                            : true
                        }
                        onChange={(event, checked) => {
                          setProducts({
                            ...products,
                            useOnCards: checked
                          });
                        }}
                      />

                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          marginRight: '15px'
                        }}
                      >
                        Utilizar em fichas?
                      </span>
                    </Grid>

                    <Grid item xs={3}>
                      <Tooltip
                        disableHoverListener={productInfo?.data?.type === "INTERNAL" || productInfo?.data?.skus?.length || dataTable.data?.length}
                        TransitionProps={{ timeout: 600 }}
                        title={
                          <p className="mb0" style={{ padding: 2 }}>
                            Produto sem SKU não é possivel efetuar inventario
                          </p>
                        }
                        placement="bottom-start"
                      >
                        <span>
                          <Checkbox
                            name="productTakeInventory"
                            checked={products.takeInventory}
                            className="checkBoxSmall"
                            color="primary"
                            disabled={onlyView || (productInfo?.data?.type !== 'INTERNAL' && !productInfo?.data?.skus?.length && !dataTable.data?.length)}
                            onChange={(_, checked) => {
                              setProducts({
                                ...products,
                                takeInventory: checked
                              });
                              setHideInventoryFields(!checked);
                            }}
                            size="small"
                          />
                        </span>
                      </Tooltip>

                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          marginRight: '15px'
                        }}
                      >
                        Efetuar inventário?
                      </span>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl fullWidth>
                        <CurrencyTextField
                          fullWidth
                          variant="outlined"
                          size="small"
                          currencySymbol=""
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          decimalPlaces={3}
                          decimalPlacesShownOnFocus={3}
                          disabled={onlyView}
                          type="text"
                          name="quantityMinStock"
                          className="textFieldSmall right"
                          label="Quantidade mínima"
                          placeholder="Digite aqui..."
                          InputProps={{ classes: { input: classes.input } }}
                          onBlur={(e) => {
                            const formatedValue = parseFloat(
                              e.target.value.replace(/,/g, '.')
                            );

                            setProducts({
                              ...products,
                              quantityMinStock: formatedValue
                            });
                          }}
                          value={products?.quantityMinStock?.toFixed(3)}
                        />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3}>
                    <FormControl fullWidth>
                      <CurrencyTextField
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        disabled={onlyView}
                        type="text"
                        name="quantityMaxStock"
                        fullWidth
                        className="textFieldSmall right"
                        label="Quantidade máxima"
                        placeholder="Digite aqui..."
                        InputProps={{ classes: { input: classes.input } }}
                        onBlur={(e) => {
                          const formatedValue = parseFloat(
                            e.target.value.replace(/,/g, '.')
                          );

                          setProducts({
                            ...products,
                            quantityMaxStock: formatedValue
                          });
                        }}
                        value={products?.quantityMaxStock?.toFixed(3)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} id="productFormStorageLocation">
                    {!hideInventoryFields ? (
                      <Tooltip
                        disableHoverListener={
                          !onlyView ||
                          ![...new Set(selectedLocation)].length
                        }
                        TransitionProps={{ timeout: 600 }}
                        title={[...new Set(selectedLocation)].map(
                          (location) => (
                            <p
                              className="mb0"
                              style={{ padding: '2px 4px' }}
                            >
                              {location.description || '-'}
                            </p>
                          )
                        )}
                        placement="right-end"
                      >
                        <FormControl fullWidth>
                          <Autocomplete
                            fullWidth
                            multiple
                            limitTags={1}
                            disabled={onlyView}
                            value={[...new Set(selectedLocation)]}
                            size="small"
                            className="autocompleteSmall"
                            classes={{
                              input: classes.input,
                              option: classes.option
                            }}
                            onChange={(e, value) => {
                              setSelectedLocation(value);
                              setProducts({
                                ...products,
                                storageLocations: value.map((item) => ({
                                  id: item.id,
                                  description: item?.description
                                }))
                              });
                            }}
                            getOptionLabel={(option) => option?.description}
                            options={storageLocations}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                variant="outlined"
                                // required={
                                //   !!(
                                //     !selectedLocation?.length &&
                                //     hideInventoryFields === false
                                //   )
                                // }
                                label="Local de Estoque"
                                placeholder="Selecione..."
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Tooltip>
                    ) : null}
                  </Grid>

                  <Grid item xs={3} id="productFormPeriodicityInput">
                    {!hideInventoryFields ? (
                      <Tooltip
                        disableHoverListener={
                          !onlyView ||
                          ![...new Set(selectedFrequencies)].length
                        }
                        TransitionProps={{ timeout: 600 }}
                        title={[...new Set(selectedFrequencies)].map(
                          (frequency) => (
                            <p
                              className="mb0"
                              style={{ padding: '2px 4px' }}
                            >
                              {frequency?.description || '-'}
                            </p>
                          )
                        )}
                        placement="right-end"
                      >
                        <FormControl fullWidth>
                          <Autocomplete
                            size="small"
                            multiple
                            limitTags={1}
                            disabled={onlyView}
                            value={[...new Set(selectedFrequencies)]}
                            className="autocompleteSmall"
                            onChange={(e, value) => {
                              setSelectedFrequencies(value);
                              if (value) {
                                setProducts({
                                  ...products,
                                  frequencies: value.map((item) => ({
                                    id: item.id,
                                    description: item?.description
                                  }))
                                });
                              } else {
                                setProducts({
                                  ...products,
                                  frequencies: []
                                });
                              }
                            }}
                            getOptionLabel={(option) => option?.description}
                            options={periodicities}
                            classes={{
                              input: classes.input,
                              option: classes.option
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                // required={
                                //   !!(
                                //     !selectedFrequencies.length &&
                                //     hideInventoryFields === false
                                //   )
                                // }
                                label="Contagem"
                                placeholder="Selecione..."
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Tooltip>
                    ) : null}
                  </Grid>

                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            if (event.target.checked) {
                              setDataTable({ data: [] });
                            }
                            setNoSkuProduct(event.target.checked);
                          }}
                          className="checkBoxSmall"
                          checked={
                            productInfo?.data?.type === 'INTERNAL'
                              ? true
                              : noSkuProduct
                          }
                          name="quote"
                          color="primary"
                          size="small"
                          disabled={
                            isModal ||
                            productInfo?.data?.type === 'INTERNAL' ||
                            onlyView
                          }
                          style={{ marginLeft: '8px' }}
                        />
                      }
                      label={
                        <Typography
                          style={{ fontFamily: 'Lato', fontSize: '12px' }}
                        >
                          {productInfo?.data?.type === 'INTERNAL'
                            ? 'Produto interno'
                            : 'Produto sem SKU'}
                        </Typography>
                      }
                    />
                  </Grid>

                  {noSkuProduct && (
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <CurrencyTextField
                          variant="outlined"
                          size="small"
                          currencySymbol="R$"
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          decimalPlaces={3}
                          decimalPlacesShownOnFocus={3}
                          disabled={
                            productInfo?.data?.type === 'INTERNAL'
                              ? true
                              : onlyView
                          }
                          type="text"
                          name="approximateCost"
                          fullWidth
                          required
                          className="textFieldSmall right"
                          label={
                            productInfo?.data?.type === 'INTERNAL'
                              ? 'Último Custo'
                              : 'Valor Aproximado'
                          }
                          placeholder="Digite aqui..."
                          classes={{
                            input: classes.input
                          }}
                          InputProps={{
                            style: {
                              fontFamily: 'Lato',
                              fontSize: '12px',
                              height: '35px'
                            }
                          }}
                          InputLabelProps={{
                            style: {
                              fontFamily: 'Lato',
                              fontSize: '12px'
                            }
                          }}
                          onChange={(e) => {
                            setProducts({
                              ...products,
                              approximateCost: e.target.value
                            });
                          }}
                          value={
                            products?.lastCostStock ||
                            products?.lastCost ||
                            products?.approximateCost ||
                            0
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}

                  {profileType !== 'admin' &&
                    editMode &&
                    productInfo?.data?.type === 'INTERNAL' && (
                      <Tooltip
                        title="% de Variação aceita na Produção"
                        placement="bottom-start"
                      >
                        <Grid item xs={3}>
                          <CurrencyTextField
                            label="% Variação Produção"
                            className="textFieldSmall right w100"
                            type="text"
                            placeholder="Digite aqui..."
                            disabled={onlyView}
                            size="small"
                            variant="outlined"
                            minimunValue="0"
                            decimalPlaces={2}
                            decimalPlacesShowOnFocus={2}
                            name="variationPercentage"
                            outputFormat="string"
                            currencySymbol=""
                            decimalCharacter=","
                            digitGroupSeparator="."
                            style={{ paddingTop: 4 }}
                            value={variationPercentage}
                            onBlur={(e) => {
                              const value =
                                e.target.value != ''
                                  ? parseFloat(
                                      e.target.value
                                        .replace('.', '')
                                        .replace(',', '.')
                                    )
                                  : 0;

                              if (value !== variationPercentage) {
                                setVariationPercentage(value);
                              } else {
                                setVariationPercentage(variationPercentage);
                              }
                            }}
                          />
                        </Grid>
                      </Tooltip>
                    )}

                  <Grid
                    item
                    xs={3}
                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                  >
                    <Checkbox
                      name="unifySkus"
                      checked={unifiedSkus}
                      disabled={onlyView}
                      className="checkBoxSmall"
                      onChange={() => {
                        setUnifiedSkus(!unifiedSkus);
                      }}
                      size="small"
                    />
                    Unificar SKUs
                    <Tooltip
                      TransitionProps={{ timeout: 600 }}
                      title={
                        <p className="mb0" style={{ padding: 2 }}>
                          Unificar SKUs de Fornecedores diferentes com a
                          mesma embalagem
                        </p>
                      }
                      placement="right-end"
                    >
                      <img
                        src={InfoIcon}
                        alt="Info Icon"
                        style={{ margin: '0 0 2px 5px', width: '13px' }}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </div>
            
            {!isAdminPage ?
              <div style={{ marginTop: '16px'}}>
                <table className="desktop">
                  <thead>
                    <tr className="headerTitle">
                      <th width="46%" className="subtitle fontSizeDefault pt0 pb0">
                        PROCESSO
                      </th>

                      <th
                        width="10%"
                        className="subtitle fontSizeDefault right pl12 pt0 pb0"
                      >
                        % DE PERDA
                      </th>

                      <th width="4%" className="subtitle fontSizeDefault pt0 pb0">
                        &nbsp;
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {processTable?.data?.length ? processTable?.data?.map((item, index) => (
                      <>
                        <tr className="lineBorder">
                          <td className="description left pl12">
                            <Typography
                              className="cursorPointer fontSizeDefault p0"
                            >
                              {item?.processes?.description}
                            </Typography>
                          </td>

                          <td className="description right dFlex pl12 justifyEnd alignCenter">
                            <div style={{ width: "45%", marginTop: '-5px', height: '35px' }}>
                              <CurrencyTextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                currencySymbol=""
                                minimumValue="0"
                                outputFormat="string"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                decimalPlaces={2}
                                decimalPlacesShownOnFocus={2}
                                type="text"
                                name="markupSimulation"
                                className="textFieldSmall right"
                                disabled={onlyView}
                                onChange={(e) => {
                                  setProcessTable((prevState) => ({
                                    ...prevState,
                                    data: prevState?.data?.map(
                                      (el) => el?.id === item?.id ? ({ ...el, percentage: parseInt(e.target.value) }) : el
                                    )
                                  }));
                                }}
                                value={item?.percentage?.toString()}
                              />
                            </div>
                          </td>

                          <td className="description center">
                            <IconButton
                              aria-label="delete"
                              disabled={onlyView}
                              onClick={() => {
                                setProcessTable((prevState) => ({
                                  ...prevState,
                                  data: prevState?.data?.filter(
                                    (el) => el?.id !== item?.id
                                  )
                                }));
                              }}
                              style={{ padding: 0, outline: 0 }}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </td>
                        </tr>

                        {index !== processTable?.data?.length - 1 ?
                          <tr>
                            <td colSpan="3" className="m0 p0 textAlignCenter">
                              <hr />
                            </td>
                          </tr>
                        : null}
                      </>
                    )) : (
                      null
                    )}
                    <tr>
                      <td colSpan="8" className="m10 textAlignCenter p0">
                        <hr />
                      </td>
                    </tr>

                    <tr className="lineBorder" style={{ height: "40px"}}>
                      <td className="description left pl12">
                        <FormControl style={{ width: '60%' }}>
                          <Autocomplete
                            size="small"
                            classes={{
                              input: classes.input,
                              option: classes.option
                            }}
                            onChange={handleAutocompleteChange}
                            value={newProcessRow}
                            inputValue={newProcessRow || newProcessRow?.description}
                            getOptionDisabled={(option) =>
                              processTable?.data?.some(
                                (item) => item?.processes?.id === option?.id
                              )
                            }
                            getOptionSelected={(option, value) =>
                                option?.description !== value?.description
                            }
                            getOptionLabel={(option) => option?.description}
                            options={process || []}
                            disableClearable
                            disabled={onlyView}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                name='process'
                                inputRef={register}
                                placeholder="Processo"
                                InputLabelProps={{
                                  style: {
                                    fontFamily: 'Lato',
                                    fontSize: '12px'
                                  }
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </td>

                      <td className="description right dFlex pl12 justifyEnd alignCenter">
                        <div style={{ width: "45%", height: "38px" }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder="Digite aqui..."
                            currencySymbol=""
                            minimumValue="0"
                            outputFormat="string"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            decimalPlaces={2}
                            decimalPlacesShownOnFocus={2}
                            disabled={onlyView}
                            onChange={(e) => setQuantityProcess(e.target.value)}
                            type="text"
                            name="markupSimulation"
                            className="textFieldSmall right"
                            value={quantityprocess}
                          />
                        </div>
                      </td>

                      <td className="description center" />
                    </tr>
                  </tbody>
                </table>
              </div>
            : null}
          </Grid>

          <Grid item xs={3}>
            <div className="cardDefault productRightCard pt16">
              <Grid
                container
                spacing={editMode ? 1 : 0}
                style={editMode ? { marginTop: '10px' } : null}
              >
                <Grid item xs={4} />

                <Grid item xs={4}>
                  <label className="blue">Vendas</label>
                </Grid>

                <Grid item xs={4}>
                  <label className="blue">Simulação</label>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={editMode ? 1 : 0}
                style={editMode ? { marginTop: '10px' } : null}
              >
                <Grid item xs={4}>
                  <label>Preço Médio</label>
                </Grid>

                <Grid item xs={4}>
                  {userGroups.groups.map((group) =>
                    group.hasOwnProperty('id')
                  )[0] && (
                    <CurrencyTextField
                      fullWidth
                      variant="outlined"
                      style={!editMode ? { display: 'none' } : null}
                      size="small"
                      currencySymbol="R$"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="averagePrice"
                      className="textFieldSmall right"
                      onChange={(e) => {
                        setProducts({
                          ...products,
                          averagePrice: e.target.value
                        });
                      }}
                      value={products?.averagePrice?.toFixed(3)}
                      disabled
                    />
                  )}
                </Grid>

                <Grid item xs={4}>
                  {userGroups.groups.map((group) =>
                    group.hasOwnProperty('id')
                  )[0] && (
                    <CurrencyTextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      currencySymbol="R$"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="averagePriceSimulation"
                      placeholder="Digite aqui..."
                      className="textFieldSmall right"
                      onChange={(e) => {
                        setProducts({
                          ...products,
                          averagePriceSimulation: e.target.value
                        });
                      }}
                      defaultValue={
                        editMode === true
                          ? productInfo?.data?.averagePriceSimulation
                          : null
                      }
                      disabled={onlyView}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={editMode ? 1 : 0}
                style={editMode ? { marginTop: '10px' } : null}
              >
                <Grid item xs={4}>
                  <label>Markup</label>
                </Grid>

                {userGroups.groups.map((group) =>
                  group.hasOwnProperty('id')
                )[0] && (
                  <Grid item xs={4}>
                    <CurrencyTextField
                      fullWidth
                      variant="outlined"
                      style={!editMode ? { display: 'none' } : null}
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="markup"
                      className="textFieldSmall right"
                      placeholder="Digite aqui..."
                      InputProps={{ classes: { input: classes.input } }}
                      onChange={(e) => {
                        setProducts({
                          ...products,
                          markup: e.target.value
                        });
                      }}
                      disabled
                      value={editMode === true ? calcMarkup() : null}
                    />
                  </Grid>
                )}

                {userGroups.groups.map((group) =>
                  group.hasOwnProperty('id')
                )[0] && (
                  <Grid item xs={4}>
                    <CurrencyTextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      currencySymbol=""
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="markupSimulation"
                      className="textFieldSmall right"
                      value={calcMarkupSimulation()}
                      disabled
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                spacing={editMode ? 1 : 0}
                style={editMode ? { marginTop: '10px' } : null}
              >
                <Grid item xs={4}>
                  <label>CMV</label>
                </Grid>

                {userGroups.groups.map((group) =>
                  group.hasOwnProperty('id')
                )[0] && (
                  <Grid item xs={4}>
                    <CurrencyTextField
                      fullWidth
                      variant="outlined"
                      style={!editMode ? { display: 'none' } : null}
                      size="small"
                      currencySymbol="%"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="cmv"
                      className="textFieldSmall right"
                      placeholder="Digite aqui..."
                      InputProps={{ classes: { input: classes.input } }}
                      onChange={(e) => {
                        setProducts({
                          ...products,
                          cmv: e.target.value
                        });
                      }}
                      disabled
                      value={editMode === true ? calcCmv() : null}
                    />
                  </Grid>
                )}

                {userGroups.groups.map((group) =>
                  group.hasOwnProperty('id')
                )[0] && (
                  <Grid item xs={4}>
                    <CurrencyTextField
                      fullWidth
                      variant="outlined"
                      size="small"
                      currencySymbol="%"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="cmvSimulation"
                      className="textFieldSmall right"
                      value={calcCmvSimulation()}
                      disabled
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                spacing={editMode ? 1 : 0}
                style={editMode ? { marginTop: '10px' } : null}
              >
                <Grid item xs={4}>
                  <label>Curva</label>
                </Grid>

                {userGroups.groups.map((group) =>
                  group.hasOwnProperty('id')
                )[0] && (
                  <Grid item xs={4}>
                    <TextField
                      onChange={(e) => {
                        setProducts({
                          ...products,
                          curve: e.target.value
                        });
                      }}
                      type="text"
                      style={!editMode ? { display: 'none' } : null}
                      name="curve"
                      variant="outlined"
                      className="textFieldSmall right"
                      placeholder="Digite aqui..."
                      required
                      fullWidth
                      size="small"
                      value={products?.curve || null}
                      defaultValue={
                        editMode === true ? productInfo?.data?.curve : null
                      }
                      disabled
                      error={Boolean(errors.curve)}
                      classes={{
                        input: classes.input,
                        option: classes.option
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={4} />
              </Grid>
            </div>
          </Grid>
        </Grid>

        {dataTable?.data?.length || !onlyView ? (
          <table className="desktop">
            <thead>
              <tr className="headerTitle">
                <th width="46%" className="subtitle fontSizeDefault pb0 pt0">
                  SKU
                </th>

                <th
                  width="10%"
                  className="subtitle fontSizeDefault right pl12 pb0 pt0"
                >
                  QTDE
                </th>

                <th
                  width="2%"
                  className="subtitle fontSizeDefault center pl12 pb0 pt0"
                >
                  UN.
                </th>

                <th
                  width="8%"
                  className="subtitle fontSizeDefault right pl12 pb0 pt0"
                >
                  MÉDIO
                </th>

                <th
                  width="10%"
                  className="subtitle fontSizeDefault right pl12 pr16 pb0 pt0"
                >
                  ÚLTIMO
                </th>

                <th
                  width="10%"
                  className="subtitle fontSizeDefault right pl12 pb0 pt0"
                >
                  CONVERSÃO
                </th>

                <th
                  width="10%"
                  className="subtitle fontSizeDefault right pl12 pb0 pt0"
                >
                  U.C.
                </th>

                <th width="4%" className="subtitle fontSizeDefault pb0 pt0">
                  &nbsp;
                </th>
              </tr>
            </thead>

            <tbody>
              {dataTable?.data?.length
                ? dataTable.data.map((sku) => (
                    <>
                      <tr className="lineBorder">
                        <td className="description left pl12">
                          <Typography
                            onClick={() => {
                              getOptions(
                                `${environment.catalog}/skus/${sku?.id}`,
                                'skus',
                                selectedSku,
                                setSelectedSku,
                                getId,
                                null,
                                null,
                                null,
                                true
                              );
                            }}
                            className="cursorPointer lightBlue fontSizeDefault p0"
                          >
                            {ValidationLength(sku?.description || '-', 100)}
                          </Typography>
                        </td>

                        <td className="description right dFlex pl12 justifyEnd alignCenter">
                          {sku.id !== jsonBody?.sku?.id && (
                            <div className="dFlex justifyEnd pr12">
                              <div className="  dFlex pr12 alignCenter">
                                <ImageWrapper
                                  width="30px"
                                  height="28px"
                                  url={sku.skuBloom?.images?.url}
                                />
                              </div>

                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                title="Trocar SKU de Produto"
                              >
                                <IconButton disabled={onlyView} style={{padding: '0', margin: '0'}}>
                                  <img
                                    style={{
                                      width: '12px',
                                      cursor: 'pointer'
                                    }}
                                    src={ChangeSkuIcon}
                                    alt="Change Sku"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDialogChangeSkuSettings(
                                        (prevState) => ({
                                          ...prevState,
                                          open: true,
                                          skuName: sku?.description
                                            ? sku?.description
                                            : '',
                                          oldProductId: productInfo?.data?.id,
                                          skuId: sku?.id,
                                          skuUnitAbbreviation:sku?.unitsMeasurements ||
                                            productInfo.data?.skus[0]?.unitsMeasurements
                                        })
                                      );
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          )}

                          {(sku?.quantity || 0).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}
                        </td>

                        <td className="description center pl12">
                          {sku?.unitsMeasurements || '-'}
                        </td>

                        <td className="description right pl12">
                          R${' '}
                          {(sku?.purchaseAverage || 0).toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 3 }
                          )}
                        </td>

                        <td className="description right pl12">
                          <Typography
                            className="fontSizeDefault pt0 pb0 pl0 pRelative"
                            style={{ paddingRight: 15 }}
                          >
                            R${' '}
                            {(sku?.purchaseLast || 0).toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3
                              }
                            )}
                            {sku?.providers?.filter(
                              (item) =>
                                item.id ===
                                lastReceivedProvider?.provider?.id
                            ).length ? (
                              <Tooltip
                                title={
                                  <div
                                    className="dFlex flexColumn"
                                    style={{ padding: '4px 2px' }}
                                  >
                                    <p className="mb0">
                                      <b>DATA:</b>{' '}
                                      {moment(
                                        lastReceivedProvider?.provider
                                          ?.recivedDate
                                      ).format('DD/MM/YYYY')}
                                    </p>

                                    <p className="mb0">
                                      <b>FORNECEDOR:</b>{' '}
                                      {lastReceivedProvider?.provider?.name}
                                    </p>

                                    <p className="mb0">
                                      <b>CNPJ:</b>{' '}
                                      {formatDocumentNumber(
                                        lastReceivedProvider?.provider
                                          ?.suppliers?.cnpjNumber
                                      )}
                                    </p>

                                    <p className="mb0">
                                      <b>EMBALAGEM:</b> E
                                      {
                                        lastReceivedProvider?.provider
                                          ?.quantity
                                      }
                                      x{sku?.quantity}
                                      {
                                        lastReceivedProvider
                                          ?.unitsMeasurements?.abbreviation
                                      }
                                    </p>
                                  </div>
                                }
                                disableHoverListener={!lastReceivedProvider}
                                aria-label="add"
                              >
                                <img
                                  style={{
                                    width: '10px',
                                    right: 0,
                                    top: 4.5
                                  }}
                                  className="pAbsolute"
                                  src={ClockNavyBlue}
                                  alt="ClockNavyBlue"
                                />
                              </Tooltip>
                            ) : null}
                          </Typography>
                        </td>

                        <td className="description right pl12">
                          {(sku?.conversion || 0).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}
                        </td>

                        <td className="description right pl12">
                          R${' '}
                          {(sku?.priceUc || 0).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}
                        </td>

                        <td className="description center">
                          <IconButton
                            aria-label="delete"
                            disabled={onlyView}
                            onClick={() => {
                              setDataTable((prevState) => ({
                                ...prevState,
                                data: prevState?.data?.filter(
                                  (el) => el?.id !== sku?.id
                                )
                              }));
                            }}
                            style={{ padding: 0, outline: 0 }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="8" className="m10 textAlignCenter p0">
                          <hr />
                        </td>
                      </tr>
                    </>
                  ))
                : null}

              {productInfo?.data?.type !== 'INTERNAL' && (
                <tr>
                  <td className="description left pl12">
                    <FormControl fullWidth error={Boolean(errors.units)}>
                      <Autocomplete
                        size="small"
                        disabled={
                          !(
                            ucPrimarySelectedId && ucPrimarySelectedId.id
                          ) || onlyView
                        }
                        classes={{
                          input: classes.input,
                          option: classes.option
                        }}
                        onInputChange={(_, value) => {
                          getMoreOptions(getSkus, value);
                        }}
                        onChange={handleSelectProduct}
                        value={newSkuRow}
                        inputValue={newSkuRow || newSkuRow?.description}
                        getOptionDisabled={(option) =>
                          dataTable?.data?.some(
                            (item) => item?.id === option?.id
                          )
                        }
                        getOptionSelected={(option, value) =>
                            option?.description === value?.description
                        }
                        getOptionLabel={(option) => option?.description}
                        options={options || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // disabled={productInfo?.data?.type === 'INTERNAL'}
                            id={params.id}
                            label={"SKU's"}
                            name="skus"
                            inputRef={register}
                            required={
                              noSkuProduct ? false : !dataTable.data.length
                            }
                            error={errors.units}
                            InputLabelProps={{
                              style: {
                                fontFamily: 'Lato',
                                fontSize: '12px'
                              }
                            }}
                          />
                        )}
                      />

                      <Tooltip
                        title="Necessário selecionar Un Principal"
                        placement="bottom-start"
                        open={Boolean(unitAlert)}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        arrow
                      >
                        <span width="1px" />
                      </Tooltip>
                    </FormControl>
                  </td>

                  <td className="description right pl12">
                    {(newSkuRow?.quantity || 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}
                  </td>

                  <td className="description center pl12">
                    {newSkuRow?.unitsMeasurements || '-'}
                  </td>

                  <td className="description right pl12">
                    R${' '}
                    {(newSkuRow?.purchaseAverage || 0).toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 3 }
                    )}
                  </td>

                  <td className="description right pl12 pr16">
                    R${' '}
                    {(newSkuRow?.purchaseLast || 0).toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 3
                      }
                    )}
                  </td>

                  <td className="description right pl12">
                    {(newSkuRow?.conversion || 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}
                  </td>

                  <td className="description right pl12">
                    R${' '}
                    {(newSkuRow?.priceUc || 0).toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}
                  </td>

                  <td className="description">&nbsp;</td>
                </tr>
              )}

              {!dataTable?.data?.length ? (
                <tr>
                  <th colSpan="8" className="m10 textAlignCenter p0">
                    Não há dados a serem exibidos.
                  </th>
                </tr>
              ) : null}
            </tbody>
          </table>
        ) : null}

        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={() => handleClose(null, null, true)}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            id="productFormSuccessMessage"
          >
            Cadastro realizado com sucesso!
          </Alert>
        </Snackbar>

        <CommonAlert
          open={commonMessage?.description}
          onClose={() => setCommonMessage({ description: '', status: '' })}
          severity={commonMessage?.status}
          indexMessage={commonMessage?.description}
          messagePersonal={commonMessage?.description}
          statusCode={statusCode}
        />

        {!onlyView && (
          <Grid container spacing={1} className="justifyEnd">
            <Grid
              className={`${
                !isModal && 'mt20'
              } dFlex justifyEnd alignCenter`}
              item
              xs={12}
              sm={4}
            >
              {editMode && products?.created_at && (
                <div className="dFlex flexColumn mr20">
                  <span className="bold fontSizeDefault">
                    Data da criação:{' '}
                    {moment(products?.created_at).format('DD/MM/YYYY')}
                  </span>

                  <span className="bold fontSizeDefault">
                    Última atualização:{' '}
                    {moment(products?.updated_at).format('DD/MM/YYYY')}
                  </span>

                  {products?.user ? (
                    <span className="bold fontSizeDefault">
                      Usuário: {products?.user}
                    </span>
                  ) : null}
                </div>
              )}

              {!isModal ? (
                <>
                  {!isModalBehavior && (
                    <Link
                      onClick={onCloseFunc}
                      className="defaultButton backButton mr20"
                      to={{
                        pathname:
                          onReturnParams === 'categories' ||
                          paramsProductId !== null
                            ? `/catalogo/categorias/${paramsID}`
                            : paramsID == 0 ? 
                            `/admin/catalogo/produtos/${paramsID}` : 
                            `/catalogo/produtos/${paramsID}`,
                        state: { prevPath: 'productForm' }
                      }}
                    >
                      Voltar
                    </Link>
                  )}
                  <Button
                    id="productFormSaveButton"
                    className="defaultButton submitButton"
                    type="submit"
                    disabled={onlyClickOnce || productWithSameDescription}
                    design="contained"
                    label={loadingSaving ? 'Salvando...' : 'Salvar'}
                  />
                </>
              ) : (
                <Button
                  id="productFormSaveButton"
                  className="defaultButton submitButton"
                  design="contained"
                  disabled={onlyClickOnce || productWithSameDescription}
                  label={loadingSaving ? 'Salvando...' : 'Salvar'}
                  onClick={() => onSubmitProduct()}
                  />
              )}
            </Grid>
          </Grid>
        )}
      </form>
    </div>
  )

  const form = (hide) => (
    <div className={`${isModal && hide && 'dNone'}`}>
      <FullScreenLoading
        description="Carregando"
        display={loadingProductFromParams}
      />

      <Container className={!isModal && 'p0 mt20'}>
        {isModal && (
          <DialogTitle
            className="modalHeader navyBlue bold textAlignCenter cursorMove"
            style={{
              borderRadius: '4px 4px 0 0'
            }}
            id="draggable-dialog-title-product-dialog"
          >
            <Typography>
              {onlyView
                ? productInfo.data?.description || ''
                : 'CADASTRO DE PRODUTOS'}
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                onClose();
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>
        )}

        {alertErr ? (
          <Alert
            style={{ width: '30%' }}
            color="danger"
            isOpen={alertErr}
            toggle={onDismiss}
          >
            {getAlert || 'Erro ao cadastrar'}
          </Alert>
        ) : null}

        <CommonAlert
          open={messageAlert?.description}
          onClose={() => setMessageAlert({ description: '', status: '' })}
          severity={messageAlert?.status}
          indexMessage={messageAlert?.description}
          messagePersonal={messageAlert?.description}
          statusCode={statusCode}
        />

        {alertErrSkus ? (
          <Alert
            style={{ width: '30%' }}
            color="danger"
            isOpen={alertErrSkus}
            toggle={onDismiss}
          >
            {getAlert || 'Obrigatório o cadastro de 1 Sku'}
          </Alert>
        ) : null}

        {loading ? <LinearProgress /> : null}

        {skuRoute ?
          productsSku.length ?
          formInputs() : 
          <div className='dFlex w100 justifyCenter mt10'>
            Não há produtos associados.
          </div>
        :
          formInputs()
        }
      </Container>
    </div>
  );

  useEffect(() => {
    getProcess()
  }, [])

  useEffect(() => {
    if (!storeOptions.length) {
      getOptions(
        `${environment.restaurants}/companies`,
        'restaurants',
        storeOptions,
        setStoreOptions,
        getId
      );
    }
  }, []);

  useEffect(() => {
    (async () => {
      const groupActive =
        userInfo.companies.groups.length > 0
          ? userInfo.companies.groups.find((group) =>
              group.subGroups.some((subGroup) =>
                subGroup.stores.some(
                  (store) => store.id === userInfo.companiesActive.id
                )
              )
            )
          : {};
      const subGroupActive =
        userInfo.companies.groups.length > 0
          ? groupActive.subGroups.find((subGroup) =>
              subGroup.stores.some(
                (store) => store.id === userInfo.companiesActive.id
              )
            )
          : {};

      if (editMode && (productDataModal?.data?.id )) {
        const origins = await fetchRestaurantsOfProduct(
          productDataModal?.data?.id
        );

        if (origins && origins.length > 0) {
          const { selectedSubGroups, selectedStores } = setSubGroupsAndStores({
            subGroups: groupActive.subGroups,
            originIds: origins
          });

          setRestaurantSubGroups(selectedSubGroups);
          setRestaurantStores(selectedStores);
        } else {
          setRestaurantSubGroups([subGroupActive]);
          setRestaurantStores(subGroupActive.stores);
        }
      } else {
        setRestaurantSubGroups([subGroupActive]);
        setRestaurantStores(subGroupActive.stores);
      }
    })();
  }, [userInfo.companiesActive]);

  useEffect(() => {
    const currentId = isGroupManage ? getId?.split(',')[0] : getId

    if (!periodicities.length && profileType !== 'admin') {
      (async () => {
        const response = await fetch(
          `${environment.catalog}/periodicities?page=1&size=1500&originId=${currentId}`
        );
        const periodicity = await response.json();
        setPeriodicities(periodicity.content);
        setEnableToLoadPeriodicities(true)
      })();
    }

    if (!storageLocations.length && profileType !== 'admin') {
      (async () => {
        const response = await fetch(
          `${environment.catalog}/storageLocations?page=1&size=1500&originId=${currentId}`
        );
        const storageLocationsJson = await response.json();
        setStorageLocations(storageLocationsJson.content);
        setEnableToLoadStorageLocations(true)
      })();
    }

    if (!consumptionUnits.length) {
      axios
        .get(`${environment.catalog}/consumptionUnits?page=1&size=1500`)
        .then((response) => {
          const units = response.data.content;
          setConsumptionUnits(units);
        })
        .catch(() => {});
    }

    if (!parameters.length) {
      (async () => {
        const response = await fetch(
          `${environment.restaurants}/companies/${currentId}/parameters`
        );
        const parametersJson = await response.json();

        setParameters(
          parametersJson.products ? parametersJson.products.type : 'LAST_PURCHASE'
        );
      })();
    }
  }, []);

  useEffect(() => {
    if (!modalSettings.find((modal) => modal.name === 'skuModal').open) return;

    !skuItems.length &&
      getOptions(
        `${environment.catalog}/skus/providers`,
        'skus',
        skuItems,
        setSkuItems,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        50
      );
    !packageTypes.length &&
      getOptions(
        `${environment.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environment.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null
      );
    !brands.length &&
      getOptions(
        `${environment.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null
      );
    !providers.length &&
      getOptions(
        `${environment.providers}/providers`,
        'providers',
        providers,
        setProviders,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        10
      );
  }, [getId, modalSettings.find((modal) => modal.name === 'skuModal').open]);

  useEffect(() => {
    if (!categories.length) {
      getOptions(
        `${environment.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    }
  }, [getId, loadCategories]);

  useEffect(() => {
    if (selectedSku) {
      if (isAdminPage) {
        const skuRestaurantIds = selectedSku?.restaurants?.map(el => el?.restaurantId)
        const associatedRestaurants = storeOptions?.filter((option) =>
          skuRestaurantIds?.includes(option.companyId)
        );

        setSelectedStores(associatedRestaurants);
      }

      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'skuModal'
            ? {
                ...modal,
                open: true
              }
            : modal
        )
      );
    }
  }, [selectedSku])

  // Seta Origin ID, Name e User Group
  useEffect(() => {
    setOriginId(userInfo.companiesActive.id);
    setUserGroups({ groups: userInfo.companies.groups });

    if (!onlyView) {
      getSkus();
    }
  }, [userInfo]);

  useEffect(() => {
    (async () => {
      const groupActive =
        userInfo.companies.groups.length > 0
          ? userInfo.companies.groups.find((group) =>
              group.subGroups.some((subGroup) =>
                subGroup.stores.some(
                  (store) => store.id === userInfo.companiesActive.id
                )
              )
            )
          : {};
      const subGroupActive =
        userInfo.companies.groups.length > 0
          ? groupActive.subGroups.find((subGroup) =>
              subGroup.stores.some(
                (store) => store.id === userInfo.companiesActive.id
              )
            )
          : {};

      if (editMode && (productData.data?.id || productId)) {
        const origins = await fetchRestaurantsOfProduct(
          productData?.data?.id || productId
        );

        if (origins && origins.length > 0) {
          const { selectedSubGroups, selectedStores } = setSubGroupsAndStores({
            subGroups: groupActive.subGroups,
            originIds: origins
          });

          setRestaurantSubGroups(selectedSubGroups);
          setRestaurantStores(selectedStores);
        } else {
          setRestaurantSubGroups([subGroupActive]);
          setRestaurantStores(subGroupActive.stores);
        }
      } else {
        setRestaurantSubGroups([subGroupActive]);
        setRestaurantStores(subGroupActive.stores);
      }
    })();
  }, [userInfo.companiesActive]);

  useEffect(() => {
    const currentId = isGroupManage ? getId?.split(',')[0] : getId

    if (!periodicities.length && profileType !== 'admin') {
      (async () => {
        const response = await fetch(
          `${environment.catalog}/periodicities?page=1&size=1500&originId=${currentId}`
        );
        const periodicity = await response.json();
        setPeriodicities(periodicity.content);
        setEnableToLoadPeriodicities(true)
      })();
    }

    if (!storageLocations.length && profileType !== 'admin') {
      (async () => {
        const response = await fetch(
          `${environment.catalog}/storageLocations?page=1&size=1500&originId=${currentId}`
        );
        const storageLocationsJson = await response.json();
        setStorageLocations(storageLocationsJson.content);
        setEnableToLoadStorageLocations(true)
      })();
    }

    if (!consumptionUnits.length) {
      axios
        .get(`${environment.catalog}/consumptionUnits?page=1&size=1500`)
        .then((response) => {
          const units = response.data.content;
          setConsumptionUnits(units);
        })
        .catch((error) => {});
    }

    if (!parameters.length) {
      (async () => {
        const response = await fetch(
          `${environment.restaurants}/companies/${currentId}/parameters`
        );
        const parameters = await response.json();
        // setUnifiedSkus(parameters.counts ? parameters.counts.unifiedSkus : false)
        setParameters(
          parameters.products ? parameters.products.type : 'LAST_PURCHASE'
        );
      })();
    }
  }, []);

  useEffect(() => {
    if (!modalSettings.find((modal) => modal.name === 'skuModal').open) return;

    !skuItems.length &&
      getOptions(
        `${environment.catalog}/skus/providers`,
        'skus',
        skuItems,
        setSkuItems,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        50
      );
    !packageTypes.length &&
      getOptions(
        `${environment.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environment.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null
      );
    !brands.length &&
      getOptions(
        `${environment.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null
      );
    !providers.length &&
      getOptions(
        `${environment.providers}/providers`,
        'providers',
        providers,
        setProviders,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        10
      );
  }, [getId, modalSettings.find((modal) => modal.name === 'skuModal').open]);

  useEffect(() => {
    if (!categories.length) {
      getOptions(
        `${environment.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    }
  }, [getId, loadCategories]);

  useEffect(() => {
    handlePropsFromUrlParams();
  }, []);

  useEffect(() => {
    if (productInfo) {
      setProcessTable({data: productInfo?.data?.productProcesses})
    } else {
      setProcessTable({data: []})
    }
  }, [productInfo])

  useEffect(() => {
    if (productId || productDataModal?.data?.id || productData?.data?.id) {
      getProduct();
    }
  }, [
    editMode,
    environment.catalog,
    getId,
    originId,
    productId,
    productDataModal?.data?.id,
    userInfo.user,
    enableToLoadPeriodicities,
    enableToLoadStorageLocations
  ]);

  return (
    <>
      <GlobalStyle />

      {!productDataModal && !isModal && (
        <Grid container xs={12}>
          <Grid container item xs={12} justify="flex-start" align="center">
            <Typography
              className="dFlex alignCenter"
              style={{ fontFamily: 'Lato', fontSize: '16px' }}
            >
              <b>Cadastro de Produtos</b>
            </Typography>
          </Grid>
        </Grid>
      )}

      {isModal && (
        <Dialog
          fullScreen={null}
          open={dialogOpen}
          onClose={() => {
            onClose();
          }}
          className="defaultModal modalRegisterContainer"
          PaperComponent={PaperComponent}
        >
          {form(false)}
        </Dialog>
      )}

      {form(true)}

      {modalSettings.find(
        (modal) => modal.name === 'alreadyExistingProductsModal'
      ).open && (
        <AlreadyExistingProducts
          open={
            modalSettings.find(
              (modal) => modal.name === 'alreadyExistingProductsModal'
            ).open
          }
          storageLocations={storageLocations}
          periodicities={periodicities}
          consumptionUnits={consumptionUnits}
          data={alreadyExistingProducts}
          productWithSameDescription={productWithSameDescription}
          handleClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'alreadyExistingProductsModal'
                  ? {
                      ...modal,
                      open: false
                    }
                  : modal
              )
            );
          }}
        />
      )}

      {modalSettings.find((modal) => modal.name === 'skuModal').open &&
      selectedSku &&
      unitsMeasurements.length &&
      brands.length &&
      packageTypes.length ? (
        <SkuModal
          fullScreen={
            modalSettings.find((modal) => modal.name === 'skuModal').fullScreen
          }
          maxWidth={
            modalSettings.find((modal) => modal.name === 'skuModal').maxWidth
          }
          open={modalSettings.find((modal) => modal.name === 'skuModal').open}
          data={selectedSku?.providers || []}
          selectedStores={selectedStores}
          getId={getId}
          editMode
          sku={selectedSku}
          userInfo={userInfo}
          items={skuItems}
          providers={providers}
          packageTypes={packageTypes}
          products={productOptions}
          categories={categories}
          brands={brands}
          storageLocations={storageLocations}
          periodicities={periodicities}
          unitsMeasurements={unitsMeasurements}
          consumptionUnits={consumptionUnits}
          skus={[]}
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'skuModal'
                  ? {
                      ...modal,
                      open: false
                    }
                  : modal
              )
            );

            setSelectedSku('');
          }}
          handleOnSave={isAdminPage ? handleOnSaveSkuPlatform : false}
          adminCatalogModal={isAdminPage}
          onlyViewMode={!isAdminPage}
        />
      ) : null}

      <ConfirmModal
        open={unityWarningModal.open}
        title={unityWarningModal.title}
        content={
          <Typography className="fontSizeDefault">
            {unityWarningModal.message}
          </Typography>
        }
        onConfirm={() =>
          setUnityWarningModal({
            open: false,
            title: '',
            message: ''
          })
        }
        confirmText="Confirmar"
      />

      {dialogChangeSkuSettings.open && (
        <DialogChangeSkuProduct
          open={dialogChangeSkuSettings.open}
          PaperComponent={PaperComponent}
          dialogChangeSkuSettings={dialogChangeSkuSettings}
          setDialogChangeSkuSettings={setDialogChangeSkuSettings}
          selectedItems={{ ...productInfo.data }}
          setSelectedItems={setProductInfo}
          originId={originId}
          isEditProduct
          getSkusList={getProduct}
          userInfo={userInfo}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
