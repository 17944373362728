/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Suspense, useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { Alert, Autocomplete, Pagination } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import axios from 'axios';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import ProviderRestaurantAssociationIcon from 'images/icons/combinations/providerRestaurantAssociationIcon.svg';
import DownloadIcon from 'images/icons/downloadUpload/download.svg';
import ProviderButtonIcon from 'images/icons/objects/trucks/providerButton.svg';
import SendLink from 'images/icons/shares/sendLink.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import AddressTable from 'pages/suppliers/addressesModal';
import ContactsTable from 'pages/suppliers/contactsModal';
import ProviderForm from 'pages/suppliers/ProviderForm';
import getOptions from 'services/getOptions';
import axiosInstanceProviders from 'services/middlewares/apis/providers_validate';
import * as ProvidersActions from 'store/actions/fornecedores';
import { Container, GlobalStyle } from 'styles/general';
import calcSKUPriceFromPackage from 'utils/calcSKUPriceFromPricePackage';
import Environments from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function Fornecedores({ userInfo, setProviders, createProviders }) {
  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const { id: paramsID } = useParams();
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 100,
    filters: [],
    loading: false,
    numberOfElements: 0,
    totalElements: 0
  });
  const {
    getId,
    page,
    size,
    totalPages,
    filters,
    numberOfElements,
    totalElements
  } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState([]);
  const isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin';

  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'filters',
      open: false,
      loading: false,
      fullScreen: null,
      maxWidth: null
    },
    {
      id: 2,
      name: 'skuAssociation',
      open: false,
      loading: false,
      fullScreen: false,
      maxWidth: 'md',
      provider: null
    },
    {
      id: 3,
      name: 'addresses',
      open: false,
      loading: false,
      fullScreen: false,
      maxWidth: 'md'
    },
    {
      id: 4,
      name: 'contacts',
      open: false,
      loading: false,
      fullScreen: false,
      maxWidth: 'md'
    },
    {
      id: 5,
      name: 'deleteProvider',
      open: false,
      loading: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 6,
      name: 'linkProvider',
      open: false,
      loading: false,
      fullScreen: false,
      maxWidth: 'md'
    }
  ]);
  const [providers, setProvider] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [skuAssociation, setSkuAssociation] = useState({});
  const [skusProvider, setSkusProvider] = useState([]);
  const [selectedSku, setSelectedSku] = useState([]);
  const [brands, setBrands] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [associationData, setAssociationData] = useState({
    provider: {
      restaurantId: getId,
      name: null,
      quantity: null,
      volume: skuAssociation ? skuAssociation.quantity : null,
      purchaseLast: null,
      codeProvider: null,
      type: null,
      user: userInfo.user
    }
  });
  const [options, setOptions] = useState({
    cards: [],
    unitsMeasurements: [],
    periods: [],
    menu: [],
    categories: [],
    skus: []
  });
  const [providerModal, setProviderModal] = useState(false);
  const [providerId, setProviderId] = useState()
  const [updateList, setUpdateList] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [hashId, setHashId] = useState();

  const [showAlert, setShowAlert] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [personalMessage, setPersonalMessage] = useState();
  const [severity, setSeverity] = useState();

  const [internalModalSettings, setInternalModalSettings] = useState([
    {
      id: 1,
      name: "confirmDialog",
      open: false,
      fullScreen: false,
      maxWidth: "sm",
      currentUser: null
    }
  ])

  const confirmDialogInfo = internalModalSettings.find((modal) => modal?.name === "confirmDialog")

  const environments = Environments(process.env.REACT_APP_ENV);
  const items = JSON.parse(localStorage.getItem('token'));
  const profileType = items.data.profile[0][0].name;
  const [modalStates, setModalStates] = useState({
    modalProviders: false,
    modalProviderRestaurantAssociation: false,
    modalRestaurantDialog: false,
    modalSku: false
  });
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const ProviderRestaurantAssociationDialog = React.lazy(() =>
    import(
      "./dialogs/ProviderRestaurantAssociationDialog"
    )
  );
  const ProviderGroupAssociation = React.lazy(() =>
    import("../cataloguing/components/ProviderGroupAssociation")
  );
  const RestaurantsDialog = React.lazy(() => import('components/Dialogs/RestaurantsDialog'));

  const query = '';

  const restaurantFilter = {
    label: 'Restaurantes',
    filterName: 'originId',
    placeHolder: 'Selecione...',
    type: 'autoComplete',
    urlPath: `${environments.restaurants}/companies?page=0&size=5000`,
    key: 'stores',
    optionReference: 'fantasyName',
    options: [],
    size: 'medium',
    marginTop: '15px'
  };

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'CNPJ',
        filterName: 'cnpjNumber',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'CPF',
        filterName: 'cpfNumber',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Razão Social',
        filterName: 'providerName',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Fantasia',
        filterName: 'fantasyName',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Contato',
        filterName: 'fullName',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium'
      },
      {
        label: 'Grupo',
        filterName: 'providerIds',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.providers}/providerGroups`,
        key: 'providerGroups',
        optionReference: 'description',
        options: [],
        size: 'medium'
      },
      ...(isAdmin ? [restaurantFilter] : [])
    ]
  ]);

  useEffect(() => {
    if (!modalStates.modalSku) return;

    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        getId
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        getId
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        getId
      );
    !providers.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        getId,
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        getId
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        getId,
        null,
        null,
        10
      );
  }, [getId, modalStates.modalSku]);

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const handleFilter = (param, value) => {
    if (value !== '') {
      if (param === 'cnpjNumber') {
        value = `j${value}`;
      } else if (param === 'cpfNumber') {
        value = `f${value}`;
        param = 'cnpjNumber';
      }
    } else if (param === 'cpfNumber') {
      param = 'cnpjNumber';
    }

    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      false,
      "1",
      { callSettings, setCallSettings }
    );
  };

  const handleDelete = () => {
    const baseUrl = `${environments.providers}/providers`;
    const data = providers;
    const index = data.indexOf(deleteData);
    providers.splice(index, 1);

    setLoading(true);
    axios
      .delete(`${baseUrl}/${deleteData.providerId}`, config)
      .then(() => {
        setModalSettings((modal) =>
          modal.map((item) =>
            item.name === 'deleteProvider'
              ? {
                  ...item,
                  open: false
                }
              : item
          )
        );
        setSeverity('success');
        setPersonalMessage('Fornecedor deletado com sucesso!');
        setShowAlert(true);
        setLoading(false);
      })
      .catch((error) => {
        setMessageError(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
        setShowAlert(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  function transformText(text) {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[çÇ]/g, 'c');
  }

  useEffect(() => {
    const delayFilterFetchData = setTimeout(() => {
      (async () => {
        setLoading(true);
        setCallSettings({ ...callSettings, loading: true });
        const params = filtersChanged
          .map((filter) => filter.urlParam)
          .join('&');

        const transform = transformText(params);
        const response = await axiosInstanceProviders.get(
          `${
            environments.providers
          }/providers?page=${page}&size=${size}&isListProviders=true&originId=${
            profileType !== 'admin' ? getId : ''
          }&${transform}`
        );
        const providers = await response.data;
        setLoading(false);
        setProvider(providers.content);
        setCallSettings({
          ...callSettings,
          totalElements: providers.totalElements,
          totalPages: providers.totalPages,
          loading: false,
          numberOfElements: providers.numberOfElements
        });
      })();
    }, 1000);

    return () => clearTimeout(delayFilterFetchData);
  }, [getId, page, filtersChanged, updateList]);

  useEffect(() => {
      (async () => {
        setLoading(true);
        const response = await fetch(
          `${environments.catalog}/skus?page=1&size=3000&originId=${getId}&descriptionSku=${query}`
        );
        const skus = await response.json();
        setLoading(false);
        setOptions({ ...options, skus: skus.content });
      })();
  }, [query, getId]);

  const getSkusprovider = (provider) => {
      axios.get(
        `${environments.catalogV2}/skus/provider/${provider?.id}?originIds=${getId}`
      ).then((response) => {
        setSkusProvider(response?.data?.content);
        setLoading(false);
      }).catch((error) => {
        console.log(error);
      })
  }


  async function getOrCreateHashId(
    user,
    providerId,
    providerName,
    parentCnpjNumber,
    cnpjNumber,
    fantasyName,
    fullName,
    telephone,
    emailAddress
  ) {
    await axios
      .post(
        `${environments.auth}/users/providers`,
        {
          user,
          providerId,
          providerName,
          parentCnpjNumber,
          cnpjNumber,
          fantasyName,
          fullName,
          telephone,
          emailAddress
        },
        config
      )
      .then((response) =>
        process.env.REACT_APP_ENV == 'development'
          ? setHashId(
              `http://191.234.181.234/login?hashId=${response.data.hashId}`
            )
          : setHashId(`http://bloom.group/login?hashId=${response.data.hashId}`)
      );
  }


  useEffect(() => {
    setCallSettings({ ...callSettings, getId: paramsID });
  }, [paramsID]);

  useEffect(() => {
    filterFields.forEach((fields) => {
      fields.forEach(({ urlPath, label, key, optionReference, page }) => {
        if (!urlPath) return;
        getFilterOptions(
          urlPath,
          label,
          key,
          filterFields,
          setFilterFields,
          optionReference,
          getId,
          filtersChanged,
          page
        );
      });
    });
  }, [getId]);

  const associateProvider = () => {
    setLoading(true);

    // eslint-disable-next-line no-new
    new Promise((resolve) => {
      const data = skusProvider;
      associationData.provider.type = `E${parseFloat(
        associationData.provider.quantity
      )
        .toString()
        .replace(',', '')
        .replace('.', ',')}x${associationData.provider.volume}${
        skuAssociation.unitsMeasurements.abbreviation
      }`;

      associationData.user = userInfo.user;

      axios
        .post(
          `${environments.catalog}/skus/${skuAssociation.id}/providers`,
          associationData.provider,
          config
        )
        .then((response) => {
          const index = data.findIndex((x) => x.id === skuAssociation.id);
          const providerIndex =
            index > -1
              ? data[index].providers.findIndex(
                  (x) => x.providerId === selectedData.providerId
                )
              : -1;

          if (index > -1) data[index].providers.push(response.data);
          else {
            if (!skuAssociation.providers) {
              skuAssociation.providers = [];
            }

            skuAssociation.providers.push(response.data);
            skusProvider.push(skuAssociation);
          }

          if (providerIndex < 0) {
            setProviders(
              providers.map((provider) =>
                provider.providerId === selectedData.providerId
                  ? { ...provider, skusCount: provider.skusCount++ }
                  : provider
              )
            );
          }

          resolve();
          setSkuAssociation({});
          setAssociationData({
            provider: {
              restaurantId: getId,
              name: '',
              quantity: '',
              volume: '',
              purchaseLast: '',
              codeProvider: '',
              type: '',
              providerId: selectedData.providerId,
              user: userInfo.user
            }
          });
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.data.error === "Data truncated for column 'quantity' at row 1") {
            setPersonalMessage(error.response.data.error);
          }
          else if (error.response.data.error === "Data truncated for column 'purchaseLast' at row 1") {
            setPersonalMessage(error.response.data.error);
          }
          setMessageError(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setShowAlert(true);
          setLoading(false);
          // reject();
        });
    });
  };

  const toggleProviderModal = () => {
    setProviderModal(!providerModal);

    if (providerModal === false) {
      setUpdateList(false);
    }
  };

  const disassociateProvider = (provider, sku) => {
    new Promise((resolve, reject) => {
      const data = skusProvider;
      setTimeout(() => {
        {
          const index = data.findIndex((x) => x.id === sku.id);
          const providerIndex =
            index > -1
              ? data[index].providers.findIndex((x) => x.providerId === provider.providerId)
              : -1;
          data[index].providers.splice(providerIndex, 1);

          const howManyRegisters =
            index > -1
              ? data[index].providers.reduce(
                  (total, value) =>
                    value.providerId === provider.providerId ? parseInt(total) + 1 : total,
                  0
                )
              : 0;

          if (!howManyRegisters) {
            setProviders(
              providers.map((provider) =>
                provider.providerId === selectedData.providerId
                  ? { ...provider, skusCount: provider.skusCount-- }
                  : provider
              )
            );
          }
        }
        setLoading(true);
        axios
          .delete(
            `${environments.catalog}/skus/${sku.id}/providers/${provider.id}`,
            config
          )
          .then((response) => {
            resolve();
            setLoading(false);
            setMessage({
              description: 'SKU desassociado com sucesso.',
              status: 'success'
            })
          })
          .catch((error) => {
            setMessageError(error?.response?.data?.message);
            setStatusCode(error?.response?.status);
            setShowAlert(true);
            setLoading(false);
            reject();
          });
      }, 1000);
    });
  };

  const addProvider = () => (
    <Tooltip title="Cadastro de Fornecedor" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          toggleProviderModal();
          createProviders();
        }}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </IconButton>
    </Tooltip>
  );

  const providerGroupAssociationButton = () => (
    <Tooltip title="Associação de Fornecedores em Grupo" aria-label="add">
      <IconButton
        className="p0 mr10"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          setModalStates({ ...modalStates, modalProviders: true });
        }}
      >
        <img
          style={{ width: '25px' }}
          src={ProviderButtonIcon}
          alt="ProviderButtonIcon"
        />
      </IconButton>
    </Tooltip>
  );

  const providerRestaurantAssociationButton = () => (
    <Tooltip title="Associação de Restaurantes e Fornecedores" aria-label="add">
      <IconButton
        className="p0 mr10"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          setModalStates({
            ...modalStates,
            modalProviderRestaurantAssociation: true
          });
        }}
      >
        <img
          style={{ width: '25px' }}
          src={ProviderRestaurantAssociationIcon}
          alt="ProviderRestaurantAssociationIcon"
        />
      </IconButton>
    </Tooltip>
  );

  const handleClose = () => {
    setModalStates({
      modalProviders: false,
      modalRestaurantDialog: false
    });
  };

  function exportSKUS() {
    const url = `${environments.catalog}/skus/xls?originId=${getId}`;

    window.open(url);
  }

  const exportExcelSku = () => (
    <Tooltip title="Exportar planilha com SKUS" aria-label="add">
      <IconButton
        className="p0 mr10"
        color="primary"
        aria-label="upload picture"
        component="span"
        onClick={() => {
          exportSKUS();
        }}
      >
        <img style={{ width: '25px' }} src={DownloadIcon} alt="DownloadIcon" />
      </IconButton>
    </Tooltip>
  );

  const handleKeyDown = (event) => {
    // Verifica se a tecla pressionada é "-" e impede a entrada se for
    if (event.key === '-' || event.key === 'e' || event.key === '.') {
      event.preventDefault();
    }
  };
  
  const handleKeyDownQtde = (event) => {
    // Verifica se a tecla pressionada é "-" e impede a entrada se for
    if (event.key === '-' || event.key === 'e' || event.key === '.' || event.key === ',') {
      event.preventDefault();
    }
  };

  return (
    <Layout>
      <TitleHead title="Fornecedores" />
      
      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Fornecedores Cadastrados"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          additionalbutton={addProvider}
          extrabutton={
            profileType === 'admin' && providerRestaurantAssociationButton
          }
          supplementarybutton={
            profileType === 'admin'
              ? providerGroupAssociationButton
              : exportExcelSku
          }
        />

        <table
          className="desktop"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <thead>
            <tr className="headerTitle">
              <th width="10%" className="subtitle">
                CNPJ{' '}
                <OrderBy
                  filterName="cnpjNumber"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="10%" className="subtitle">
                RAZÃO SOCIAL{' '}
                <OrderBy
                  filterName="providerName"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="12%" className="subtitle">
                NOME FANTASIA{' '}
                <OrderBy
                  filterName="fantasyName"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              {/* <th width="10%" className="subtitle">
                INS. ESTADUAL{' '}
                <OrderBy
                  filterName="stateRegistration"
                  handleFilter={handleOrdernationFilter}
                />
              </th>

              <th width="10%" className="subtitle">
                INS. MUNICIPAL{' '}
                <OrderBy
                  filterName="municipalRegistration"
                  handleFilter={handleOrdernationFilter}
                />
              </th> */}

              <th width="3%" className="subtitle center pl8">
                SKU'S
              </th>

              <th width="4%" className="subtitle center pl8">
                ENDEREÇOS
              </th>

              <th width="4%" className="subtitle center pl8">
                CONTATOS
              </th>

              {isAdmin && (
                <>
                  <th width="4%" className="subtitle center pl8">
                    RESTAURANTES
                  </th>

                  <th width="2%" className="subtitle" />
                </>
              )}

              <th width="2%" className="subtitle" />
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th className="m10 textAlignCenter" colSpan="18">
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {providers?.length ? (
              providers.map((provider) => (
                <>
                  <tr>
                    <td className="description p12">
                      {formatDocumentNumber(provider.cnpjNumber)}
                    </td>

                    <td
                      width="11%"
                      className="description"
                      style={{ maxWidth: '350px', textTransform: 'uppercase' }}
                    >
                      <Link
                        onClick={() => {
                          setProviders(provider);
                          toggleProviderModal();
                        }}
                      >
                        {ValidationLength(
                          provider.providerName,
                          45,
                          null,
                          false,
                          '350px'
                        )}
                      </Link>
                    </td>

                    <td className="description" style={{ maxWidth: '350px', textTransform: 'uppercase' }}>
                      {ValidationLength(
                        provider.fantasyName,
                        45,
                        null,
                        false,
                        '350px'
                      )}
                    </td>

                    {/* INS'S */}
                    {/* <td className="description" style={{ maxWidth: '160px' }}>
                      {provider.stateRegistration
                        ? provider.stateRegistration
                        : '-'}
                    </td>

                    <td className="description">
                      {provider.municipalRegistration
                        ? provider.municipalRegistration
                        : '-'}
                    </td> */}

                    <td className="description center">
                      <Link
                        onClick={() => {
                          setSelectedData(provider);
                          getSkusprovider(provider)
                          setModalSettings(
                            modalSettings.map((modal) =>
                              modal.name === 'skuAssociation'
                                ? {
                                    ...modal,
                                    open: true,
                                    provider: provider.providerName
                                  }
                                : modal
                            )
                          );
                          setAssociationData({
                            ...associationData,
                            provider: {
                              ...associationData.provider,
                              name: provider.providerName,
                              providerId: provider.providerId
                            }
                          });
                        }}
                      >
                        {provider.skusCount}
                      </Link>
                    </td>

                    <td className="description center">
                      <Link
                        onClick={() => (
                          setSelectedData(provider),
                          setModalSettings(
                            modalSettings.map((modal) =>
                              modal.name === 'addresses'
                                ? { ...modal, open: true }
                                : modal
                            )
                          )
                        )}
                      >
                        {provider.addresses ? provider.addresses.length : 0}
                      </Link>
                    </td>

                    <td className="description center p8">
                      <Link
                        onClick={() => (
                          setSelectedData(provider),
                          setModalSettings(
                            modalSettings.map((modal) =>
                              modal.name === 'contacts'
                                ? { ...modal, open: true }
                                : modal
                            )
                          )
                        )}
                      >
                        {provider.contacts ? provider.contacts.length : 0}
                      </Link>
                    </td>

                    {isAdmin && (
                      <td className="description center p8">
                        <Link
                          onClick={() => (
                            setSelectedData(provider),
                            setModalStates({
                              modalRestaurantDialog: true
                            })
                          )}
                        >
                          {provider?.companies?.length || 0}
                        </Link>
                      </td>
                    )}

                    {isAdmin ? (
                      <td>
                        <IconButton
                          aria-label="copy"
                          onClick={() => {
                            getOrCreateHashId(
                              userInfo.user,
                              provider.providerId,
                              provider.providerName,
                              provider.parentCnpjNumber,
                              provider.cnpjNumber,
                              provider.fantasyName,
                              provider.contacts.length > 0
                                ? provider.contacts[0].fullName
                                : '',
                              provider.contacts.length > 0
                                ? provider.contacts[0].telephoneNumber
                                : '',
                              provider.contacts.length > 0
                                ? provider.contacts[0].emailAddress
                                : ''
                            );
                            setModalSettings((modal) =>
                              modal.map((item) =>
                                item.name === 'linkProvider'
                                  ? {
                                      ...item,
                                      open: true
                                    }
                                  : item
                              )
                            );
                          }}
                          style={{ padding: 0, outline: 0 }}
                        >
                          <img
                            style={{ padding: 0, outline: 0 }}
                            src={SendLink}
                            alt="SendLink"
                          />
                        </IconButton>
                      </td>
                    ) : null}

                    <td>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setDeleteData(provider);
                          setModalSettings((modal) =>
                            modal.map((item) =>
                              item.name === 'deleteProvider'
                                ? {
                                    ...item,
                                    open: true
                                  }
                                : item
                            )
                          );
                        }}
                        style={{ padding: 0, outline: 0 }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="10">
                      <hr />
                    </td>
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <th colSpan="13" className="m10 textAlignCenter">
                  Não há nenhum fornecedor
                </th>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(_, page) => setCallSettings({ ...callSettings, page })}
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>

        <Modal
          className="defaultModal modalRegisterContainer"
          open={providerModal}
          onClose={toggleProviderModal}
          aria-labelledby="Provider Modal"
        >
          <ProviderForm
            isModal
            redirectPath={null}
            onClose={toggleProviderModal}
            setUpdateList={setUpdateList}
            providers={providers}
          />
        </Modal>

        <Suspense fallback={<span />}>
          <ProviderRestaurantAssociationDialog
            openModal={modalStates}
            handleClose={handleClose}
            getId={getId}
          />
        </Suspense>

        <Suspense fallback={<span />}>
          <ProviderGroupAssociation
            openModal={modalStates}
            handleClose={handleClose}
            getId={getId}
          />
        </Suspense>

        <Suspense fallback={<span />}>
          <RestaurantsDialog
            openModal={modalStates.modalRestaurantDialog}
            handleClose={handleClose}
            restaurantData={selectedData?.companies}
          />
        </Suspense>

        {modalStates.modalSku &&
        selectedSku &&
        unitsMeasurements.length &&
        brands.length ? (
          <SkuModal
            fullScreen={false}
            maxWidth="sm"
            open={modalStates.modalSku}
            data={selectedSku?.providers || []}
            getId={getId}
            editMode
            sku={selectedSku}
            userInfo={userInfo}
            brands={brands}
            storageLocations={storageLocations}
            periodicities={periodicities}
            unitsMeasurements={unitsMeasurements}
            consumptionUnits={consumptionUnits}
            packageTypes={packageTypes}
            categories={categories}
            skus={[]}
            onClose={() => {
              setModalStates({ ...modalStates, modalSku: false });

              setSelectedSku('');
            }}
            adminCatalogModal={isAdmin}
            onlyViewMode
          />
        ) : null}

        <Dialog
          fullWidth
          fullScreen={
            modalSettings.find((modal) => modal.name === 'skuAssociation')
              .fullScreen
          }
          maxWidth={
            modalSettings.find((modal) => modal.name === 'skuAssociation')
              .maxWidth
          }
          open={
            modalSettings.find((modal) => modal.name === 'skuAssociation').open
          }
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'skuAssociation'
                  ? { ...modal, open: false, provider: null }
                  : modal
              )
            );
            setSelectedData([]);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          className="defaultModal"
        >
          <DialogTitle
            className="modalHeader bold textAlignCenter cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>
              {
                modalSettings.find((modal) => modal.name === 'skuAssociation')
                  .provider
              }
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'skuAssociation'
                      ? { ...modal, open: false, provider: null }
                      : modal
                  )
                );
                setAssociationData({
                  ...associationData,
                  provider: {
                    ...associationData.provider,
                    quantity: '',
                    purchaseLast: ''
                  }
                })
                setSkuAssociation('');
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent">
            <form onSubmit={handleSubmit(associateProvider)}>
              <Grid container spacing={5} xs={12} className="m0">
                <Grid item xs={12} className="p0 pt20">
                  <Autocomplete
                    fullWidth
                    size="small"
                    onChange={(e, value) => {
                      setSkuAssociation(value);
                      setAssociationData({
                        ...associationData,
                        provider: {
                          ...associationData.provider,
                          volume: value ? value.quantity : null
                        }
                      });
                    }}
                    variant="outlined"
                    getOptionSelected={(option, value) =>
                      option.description === value.description
                    }
                    getOptionLabel={(option) => option.description}
                    options={options.skus || []}
                    value={skuAssociation}
                    className="autocompleteSmall"
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        id={params.id}
                        variant="outlined"
                        label="SKU"
                        name="sku"
                        required
                        placeholder="Digite aqui..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid container spacing={5} item xs={12} className="m0 p0 pt20">
                  <Grid item xs={3} className="pl0">
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      name="codeProvider"
                      required={false}
                      fullWidth
                      label="Código"
                      InputProps={{ style: { fontFamily: 'Lato' } }}
                      value={associationData.provider.codeProvider}
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                      className="textFieldSmall"
                      onChange={(e) => {
                        setAssociationData({
                          ...associationData,
                          provider: {
                            ...associationData.provider,
                            codeProvider: e.target.value ? e.target.value : null
                          }
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <CurrencyTextField
                      variant="outlined"
                      size="small"
                      currencySymbol=""
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="quantity"
                      required
                      fullWidth
                      label="Qtd.Emb"
                      InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                      placeholder="Digite aqui..."
                      className="textFieldSmall"
                      value={associationData.provider.quantity !== '' ? associationData.provider.quantity : ''}
                      onKeyDown={handleKeyDownQtde}
                      onChange={(e, inputValue) => {
                        // eslint-disable-next-line no-restricted-globals
                        if(!isNaN(inputValue)) {
                        setAssociationData({
                          ...associationData,
                          provider: {
                            ...associationData.provider,
                            quantity: parseFloat(inputValue) <= 0 ? '' : inputValue
                          }
                        })
                      }
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} className="pr0">
                    <CurrencyTextField
                      variant="outlined"
                      size="small"
                      currencySymbol=""
                      outputFormat="string"
                      decimalCharacter=","
                      digitGroupSeparator="."
                      decimalPlaces={3}
                      decimalPlacesShownOnFocus={3}
                      type="text"
                      name="purchaseLast"
                      required
                      fullWidth
                      label="R$ Emb."
                      InputLabelProps={{ style: { fontFamily: 'Lato' } }}
                      className="textFieldSmall"
                      placeholder="Digite aqui..."
                      value={associationData.provider.purchaseLast !== '' ? associationData.provider.purchaseLast : ''}
                      onKeyDown={handleKeyDown}
                      onChange={(e, inputValue) => {
                        // eslint-disable-next-line no-restricted-globals
                        if(!isNaN(inputValue)) {
                        setAssociationData({
                          ...associationData,
                          provider: {
                            ...associationData.provider,
                            purchaseLast: parseFloat(inputValue) <= 0 ? '' : inputValue
                          }
                        })
                      }
                      }}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Tooltip title="Associar fornecedor">
                      <IconButton
                        color="inherit"
                        type="submit"
                        size="small"
                        aria-label="add"
                        style={{ outline: '0', background: 'none' }}
                      >
                        <img
                          style={{ width: '25px' }}
                          src={AddIcon}
                          alt="AddIcon"
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </form>

            <table border="0">
              <thead style={{ zIndex: '1000'}}>
                {/* {loading ? <tr><td colSpan="9" sytle={{ margin: '10px' }}><LinearProgress variant="query" /></td></tr> : null} */}

                <tr>
                  <th width="100" className="subtitle">
                    COD.
                  </th>

                  <th width="260" className="subtitle">
                    DESCRIÇÃO
                  </th>

                  <th width="120" className="subtitle">
                    QTD.EMB.
                  </th>

                  <th width="120" className="subtitle">
                    R$ EMB.
                  </th>

                  <th width="120" className="subtitle">
                    R$ SKU
                  </th>

                  <th width="120" className="subtitle">
                    EMBALAGEM
                  </th>

                  <th width="5" className="subtitle" 
                    style={{zIndex: '1000' }}
                  />
                </tr>

                <tr>
                  <td colSpan="9" className="p0">
                    <hr
                      className="titleLineBorder m0"
                      style={{ height: '2px' }}
                    />
                  </td>
                </tr>
              </thead>

              <tbody>
                {skusProvider && skusProvider.length ? (
                  skusProvider.map((sku) => {
                    if (sku && selectedData) {
                      return sku.providers?.map((provider) => {
                        provider.type = `E${parseFloat(provider.quantity)
                          .toString()
                          .replace(',', '')
                          .replace('.', ',')}x${sku.quantity}${
                          sku.unitsMeasurements.abbreviation
                        }`;

                        if (provider.providerId === selectedData.providerId) {
                          return (
                            <>
                              <tr>
                                <td className="description center">
                                  {provider && provider.codeProvider
                                    ? ValidationLength(
                                        provider.codeProvider,
                                        15
                                      )
                                    : null}
                                </td>

                                <td className="description">
                                  <div
                                    className="cursorPointer lightBlue"
                                    onClick={() => {
                                      getOptions(
                                        `${environments.catalog}/skus/${sku?.id}`,
                                        'skus',
                                        selectedSku,
                                        setSelectedSku,
                                        getId,
                                        null,
                                        null,
                                        null,
                                        true
                                      );
                                      setModalStates({
                                        ...modalStates,
                                        modalSku: true
                                      });
                                    }}
                                  >
                                    {ValidationLength(sku.description, 30)}
                                  </div>
                                </td>

                                <td className="description center">
                                  {provider.quantity
                                    ? parseFloat(provider.quantity)
                                        .toFixed(3)
                                        .toString()
                                        .replace('.', ',')
                                    : '0,000'}
                                </td>

                                <td className="description center">
                                  R${' '}
                                  {provider.purchaseLast
                                    ? parseFloat(provider.purchaseLast)
                                        .toFixed(3)
                                        .toString()
                                        .replace('.', ',')
                                    : '0,000'}
                                </td>

                                <td className="description center">
                                  R${' '}
                                  {provider.purchaseLast
                                    ? calcSKUPriceFromPackage(
                                        provider.quantity,
                                        provider.purchaseLast
                                      )
                                        .toFixed(3)
                                        .toString()
                                        .replace('.', ',')
                                    : '0,000'}
                                </td>

                                <td className="description center">
                                  {provider.type}
                                </td>

                                <td>
                                  <Tooltip title="Desassociar fornecedor">
                                    <IconButton
                                      color="inherit"
                                      onClick={() => {
                                        setInternalModalSettings(
                                          internalModalSettings.map((modal) =>
                                            modal.name === 'confirmDialog'
                                              ? {
                                                  ...modal,
                                                  open: true,
                                                  currentProvider: provider,
                                                  skuAssociation: sku
                                                }
                                              : modal
                                          )
                                        );
                                      }}
                                      aria-label="add"
                                      size="small"
                                      style={{
                                        outline: '0',
                                        background: 'none',
                                        zIndex: '1'
                                      }}
                                    >
                                      <DeleteOutline />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>

                              <tr>
                                <td colSpan="9" className="p0">
                                  <hr
                                    className="titleLineBorder m0"
                                    style={{ height: '2px' }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        }
                      });
                    }
                  })
                ) : (
                  <>
                    <tr>
                      <td colSpan="6" className="description center">
                        <Typography
                          style={{ margin: '5px', textAlign: 'center' }}
                        >
                          Não há Fornecedores associados.
                        </Typography>
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="9">
                        <hr
                          className="titleLineBorder m0"
                          style={{ height: '2px' }}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </DialogContent>

          {
            confirmDialogInfo?.open && (
              <ConfirmModal
                open={
                  confirmDialogInfo.open
                }
                title={
                  confirmDialogInfo?.title
                }
                content={
                  <Typography className="bold">
                    Deseja realmente desassociar essa embalagem?
                  </Typography>
                }
                onClose={() => {
                  setInternalModalSettings(
                    internalModalSettings.map((modal) => modal.name === "confirmDialog" ? {
                      ...modal,
                      open: false,
                      currentProvider: null,
                      skuAssociation: null
                    } : modal)
                  )
                }}
                cancelText="Não"
                onConfirm={() => {
                  disassociateProvider(confirmDialogInfo?.currentProvider, confirmDialogInfo?.skuAssociation);
                  setInternalModalSettings(
                    internalModalSettings.map((modal) => modal.name === "confirmDialog" ? {
                      ...modal,
                      open: false
                    } : modal)
                  )
                }}
                confirmText="Sim"
              />
            )
          }

        </Dialog>

        <Dialog
          fullWidth
          fullScreen={
            modalSettings.find((modal) => modal.name === 'addresses').fullScreen
          }
          maxWidth={
            modalSettings.find((modal) => modal.name === 'addresses').maxWidth
          }
          minWidth={
            modalSettings.find((modal) => modal.name === 'addresses').minWidth
          }
          open={modalSettings.find((modal) => modal.name === 'addresses').open}
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'addresses' ? { ...modal, open: false } : modal
              )
            );
            setSelectedData(null);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          className="defaultModal"
        >
          <DialogTitle
            className="modalHeader bold textAlignCenter cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>GERENCIAR ENDEREÇOS</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'addresses'
                      ? { ...modal, open: false }
                      : modal
                  )
                );
                setSelectedData(null);
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent onlyTable">
            <Grid container xs={12}>
              {AddressTable(selectedData || {})}
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          fullScreen={
            modalSettings.find((modal) => modal.name === 'contacts').fullScreen
          }
          maxWidth={
            modalSettings.find((modal) => modal.name === 'contacts').maxWidth
          }
          minWidth={
            modalSettings.find((modal) => modal.name === 'contacts').minWidth
          }
          open={modalSettings.find((modal) => modal.name === 'contacts').open}
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'contacts' ? { ...modal, open: false } : modal
              )
            );
            setSelectedData(null);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          className="defaultModal"
        >
          <DialogTitle
            className="modalHeader bold textAlignCenter cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>GERENCIAR CONTATOS</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'contacts'
                      ? { ...modal, open: false }
                      : modal
                  )
                );
                setSelectedData(null);
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent onlyTable">
            <Grid container xs={12}>
              {ContactsTable(selectedData || {}, profileType)}
            </Grid>
          </DialogContent>
        </Dialog>

        <Dialog
          fullWidth
          fullScreen={
            modalSettings.find((modal) => modal.name === 'linkProvider')
              .fullScreen
          }
          maxWidth={
            modalSettings.find((modal) => modal.name === 'linkProvider')
              .maxWidth
          }
          minWidth={
            modalSettings.find((modal) => modal.name === 'linkProvider')
              .minWidth
          }
          open={
            modalSettings.find((modal) => modal.name === 'linkProvider').open
          }
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'linkProvider'
                  ? { ...modal, open: false }
                  : modal
              )
            );
            setSelectedData(null);
          }}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          className="defaultModal"
        >
          <DialogTitle
            className="modalHeader bold textAlignCenter cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>Link de acesso do fornecedor</Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'linkProvider'
                      ? { ...modal, open: false }
                      : modal
                  )
                );
                setHashId('');
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography>{hashId}</Typography>
          </DialogContent>
        </Dialog>

        <Dialog
          fullScreen={
            modalSettings.find((x) => x.name === 'deleteProvider').fullScreen
          }
          maxWidth={
            modalSettings.find((x) => x.name === 'deleteProvider').maxWidth
          }
          open={modalSettings.find((x) => x.name === 'deleteProvider').open}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{ style: { borderRadius: 20 } }}
          PaperComponent={PaperComponent}
          onClose={() => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'deleteProvider'
                  ? {
                      ...item,
                      open: false
                    }
                  : item
              )
            );
          }}
        >
          <DialogTitle
            className="modalHeader navyBlue bold cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>Deseja realmente excluir esse Fornecedor?</Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography>
              Esta ação não pode ser desfeita. Deseja continuar?
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={() => {
                setModalSettings((modal) =>
                  modal.map((item) =>
                    item.name === 'deleteProvider'
                      ? {
                          ...item,
                          open: false
                        }
                      : item
                  )
                );
              }}
              label="Cancelar"
              color="primary"
              style={{ outline: 0 }}
            />

            <Button
              className="defaultButton submitButton"
              autoFocus
              style={{ outline: 0 }}
              design="contained"
              label="Confirmar"
              onClick={() => {
                handleDelete();
              }}
              color="primary"
            />
          </DialogActions>
        </Dialog>

        <Snackbar
          open={!!message?.description}
          autoHideDuration={1000}
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'deleteProvider'
                  ? { ...modal, open: false }
                  : modal
              )
            );
            setMessage({ ...message, description: '', status: '' });
          }}
        >
          <Alert
            onClose={() => {
              setMessage({ ...message, description: '', status: '' });
            }}
            severity={message.status}
          >
            {message.description}
          </Alert>
        </Snackbar>
      </Container>

      <CommonAlert
        open={showAlert}
        severity={severity}
        onClose={() => {
          setPersonalMessage(null);
          setShowAlert(false);
        }}
        messagePersonal={personalMessage}
        indexMessage={messageError}
        statusCode={statusCode}
        width="50%"
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  providerInfo: state.fornecedores
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProvidersActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Fornecedores);
