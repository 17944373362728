export function setSquares(data) {
    return {
        type: 'SET_SQUARES_DATA',
        data,
    };
}

export function createSquares(data) {
    return {
        type: 'CREATE_SQUARES_DATA',
        data,
    };
}