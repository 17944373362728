import React from 'react';

import { Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';

import PaperComponent from 'components/PaperComponent';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';

import ProductForm from '../../ProductForm';

function ProductFormDialog({
  openDialog,
  onClose,
  currentProductId,
  consumptionUnits,
  storageLocations,
  periodicities
}) {
  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      PaperComponent={PaperComponent}
      maxWidth="xl"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
        fullWidth
      >
        <Typography />
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right' }}
        >
          <img src={CloseIcon} alt="CloseIcon" />
        </IconButton>
      </DialogTitle>
      <div
        style={{
          margin: '10px',
          width: '1120px'
        }}
      >
        <ProductForm
          isModal={false}
          onClose={onClose}
          isModalBehavior
          consumptionUnitOptions={consumptionUnits}
          storageLocationOptions={storageLocations}
          periodicityOptions={periodicities}
          paramsProductId={currentProductId}
        />
      </div>
    </Dialog>
  );
}

export default ProductFormDialog;
