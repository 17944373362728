import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography
} from '@material-ui/core';

import InputNumber from 'components/Inputs/InputNumber';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import useFetch from 'services/useFetch';
import ValidationLength from 'utils/utils';

import suggestionQuantityDistribution from '../suggestionQuantityDistribution';

function StorePurchaseSuggestionsDialog({
  openModal,
  handleClose,
  userInfo,
  restaurantsIds,
  productsIds,
  tableToModalProps,
  tableSettings,
  mobileBehavior = false,
  cardItem,
  handleInputChange
}) {
  const { modalPurchaseSuggestions } = openModal;
  const [filters] = useState([
    {
      param: 'originId',
      value: restaurantsIds,
      urlParam: `originId=${restaurantsIds}`
    },
    {
      param: 'productId',
      value: productsIds,
      urlParam: `productId=${productsIds}`
    }
  ]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const { status, data } = useFetch(
    'modalPurchaseSuggestions',
    filtersChanged,
    !modalPurchaseSuggestions,
    null
  );

  const handleUpdateSuggestionList = (newSuggestions) => {
    setSuggestionsList(newSuggestions);
  };

  const handleInputValue = (newInputValue, item) => {
    const { productId } = item;
    const { tableData, setTableData } = tableSettings;

    const newSuggestionsList = suggestionsList.map((suggestion) => {
      if (suggestion.productId === productId) {
        suggestion.quantity = parseFloat(newInputValue);
      }
      return suggestion;
    });

    const newTotalQuantity = Object.values(newSuggestionsList).reduce(
      (t, { quantity }) => t + quantity,
      0
    );
    if (mobileBehavior) {
      cardItem[3].content[0] = newTotalQuantity;
      cardItem[3].modalToTableProps = {
        ...cardItem[3].modalToTableProps,
        quantityeditByModal: true,
        suggestionList: newSuggestionsList
      };
      handleInputChange(newTotalQuantity);
      return;
    }
    tableToModalProps.content[0] = newTotalQuantity;
    tableToModalProps.modalToTableProps = {
      ...tableToModalProps.modalToTableProps,
      quantityeditByModal: true,
      suggestionList: newSuggestionsList
    };
    setTableData(tableData);
    setSuggestionsList(newSuggestionsList);
  };

  useEffect(() => {
    if (!data) return;
    if (mobileBehavior) {
      suggestionQuantityDistribution({
        tableToModalProps: cardItem[3],
        suggestionData: data,
        handleUpdateSuggestionList
      });
      return;
    }
    suggestionQuantityDistribution({
      tableToModalProps,
      suggestionData: data,
      handleUpdateSuggestionList
    });
  }, [data, cardItem]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={modalPurchaseSuggestions}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal smallModal"
      transitionDuration={0}
    >
      <DialogTitle className="modalHeader navyBlue bold textAlignCenter cursorMove">
        <Typography>SUGESTÃO DE COMPRA POR LOJA</Typography>

        <IconButton edge="start" color="inherit" onClick={() => handleClose()}>
          <img
            style={{
              width: mobileBehavior ? '8px' : '16px',
              height: mobileBehavior ? '8px' : '16px'
            }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent className="modalContent onlyTable">
        <table className="storePurchaseSuggestionsTable">
          <thead>
            <tr>
              <th className="subtitle">
                {data.productDescription && data.productDescription}{' '}
                {data.consumptionUnits &&
                  `(${data.consumptionUnits.toUpperCase()})`}
              </th>
              <th className="subtitle">&nbsp;</th>
            </tr>

            <tr className="headerBorder">
              <th colSpan="2" className="titleLineBorder" />
            </tr>
          </thead>

          <tbody>
            {suggestionsList && suggestionsList.length ? (
              <>
                {suggestionsList.map((suggestion, index) => (
                  <>
                    <tr style={{ height: mobileBehavior ? '48px' : 'auto' }}>
                      <td
                        id={`companyName${index}`}
                        className="description pl16"
                      >
                        {ValidationLength(
                          userInfo.companies.groups
                            .reduce(
                              (acc, group) => [...acc, group.subGroups],
                              []
                            )
                            .flat()
                            .reduce(
                              (acc, subGroup) => [...acc, subGroup.stores],
                              []
                            )
                            .flat()
                            .find(
                              (element) =>
                                element.id === suggestion.restaurantId
                            ).name,
                          mobileBehavior ? 22 : 30
                        )}
                      </td>

                      <td id={`quantity${index}`} className="description right">
                        <InputNumber
                          initialValue={suggestion.quantity}
                          size="70px"
                          handleValue={handleInputValue}
                          item={suggestion}
                          fractionDigits={2}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td colSpan="2" className="p0">
                        <hr className="m0" />
                      </td>
                    </tr>
                  </>
                ))}
              </>
            ) : (
              <>
                {status.loading ? (
                  <tr>
                    <td colSpan="2" className="m10">
                      <LinearProgress variant="query" />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="2" className="pt5 textAlignCenter">
                      Nenhum dado disponível
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>

        <div style={{ textAlign: 'end' }}>
          {mobileBehavior ? (
            <button
              style={{
                border: '1px solid #5062F0',
                color: '#fff',
                marginTop: '17px',
                marginRight: '12px',
                backgroundColor: '#5062F0',
                padding: '12px 60px',
                borderRadius: '12px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                fontSize: '16px'
              }}
              onClick={(e) => handleClose()}
            >
              Salvar
            </button>
          ) : null}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default StorePurchaseSuggestionsDialog;
