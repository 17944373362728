import Environment from '../../../utils/environments';
import axios from 'axios';

const environments = Environment(process.env.REACT_APP_ENV);

const getPercentageDifference = (setPercentageDifference, getId) => {
    (async () => {
        const request = await fetch(`${environments.restaurants}/parameters/${getId}`);
        const response = await request.json();
        const percentage = response && response.counts && response.counts.differencePercentage ? response.counts.differencePercentage : 0;
        setPercentageDifference(percentage);
    })();
}

export const putDiffInCatalog = ({ productId, countId, restaurantId, difference }) => {
    const URI = `${environments.catalog}/products/${productId}/extracts/difference/${countId}`
    const body = {
      restaurantId: restaurantId,
      difference: difference
    }

    return axios.put(URI, body)
}

export default getPercentageDifference;
