import React, { useState } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeGray.svg';

import { ClearIcon } from './index.style';

import { InputContainer } from '../AutoCompleteField/index.style';

const debounceCall = (callBack, filterName, value, timer) => {
  setTimeout(() => {
    callBack(filterName, value);
  }, timer);
};

function DefaultInputFieldSearch({
  label,
  placeHolder,
  filterName,
  handleFilter,
  size,
  inputSizes = {
    small: 110,
    medium: 155,
    medium_plus: 210,
    large: 250
  },
  withoutIcon,
  focus
}) {
  const [empty, setEmpty] = useState('');
  const handleFilterValue = (value) => {
    debounceCall(handleFilter, filterName, value, 800);
    setEmpty(value);
  };

  return (
    <InputContainer>
      <TextField
        id={filterName}
        autoFocus={focus}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            handleFilterValue(empty);
          }
        }}
        onChange={(e) => {
          setEmpty(e.target.value);
        }}
        autoComplete="off"
        value={empty}
        label={label && label}
        placeholder={placeHolder}
        fullWidth
        style={{ width: size && inputSizes[size] }}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              onClick={() => {
                setEmpty('');
              }}
              position="end"
            >
              {empty === '' ? null : (
                <ClearIcon src={CloseIconNavy} style={{ cursor: 'pointer' }} />
              )}
            </InputAdornment>
          )
        }}
      />
    </InputContainer>
  );
}

export default DefaultInputFieldSearch;
