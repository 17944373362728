/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import {
  Typography,
  Collapse,
  List,
  ListItem,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import * as SkusActions from 'store/actions/skus';
import Environment from 'utils/environments';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const Container = styled.div`
  margin: 0;
  display: block;
`;

function Notifications({ userInfo, setSkus, createSkus }) {
  const [getId, setGetId] = useState(userInfo.companiesActive.id);
  const [state, setState] = useState('');
  const { register } = useForm();
  const [loading, setLoading] = useState(false);
  const [callSettings, setCallSettings] = useState({
    getId: userInfo.companiesActive.id,
    page: 1,
    totalPages: 0,
    size: 1000,
    filters: []
  });
  const { page, size, totalPages, filters } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState();
  const [producers, setProducers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categoriesSales, setCategoriesSales] = useState([]);
  const [categoriesShopping, setCategoriesShopping] = useState([]);
  const [skus, setSku] = useState([]);
  const [products, setProducts] = useState([]);
  const [cards, setCard] = useState([]);
  const [cardsOptions, setCardsOptions] = useState([]);
  const [rests, setRests] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(false);
  const environments = Environment(process.env.REACT_APP_ENV);
  const [collapseOpen, setCollapseOpen] = useState([]);
  const classes = useStyles();
  const fakeObj = [
    {
      id: 1,
      name: 'Vendas',
      period: '',
      indicator: '-24,4%',
      total: 'R$ 18.833,22',
      open: false,
      alert: 1,
      stores: [
        {
          id: 1,
          name: 'LM Rio Sul',
          indicator: '-22,5%',
          total: 'R$ 4.529,62',
          storeIndicator: '-25,9%',
          storeTotal: 'R$ 2.164,43',
          deliveryIndicator: '-19,2%',
          deliveryTotal: 'R$ 2.365,19'
        },
        {
          id: 2,
          name: 'MJP Botafogo',
          indicator: '-1,9%',
          total: 'R$ 14.303,62',
          storeIndicator: '-26,4%',
          storeTotal: 'R$ 2.199,76',
          deliveryIndicator: '+4,4%',
          deliveryTotal: 'R$ 12.103,86'
        }
      ]
    },
    {
      id: 2,
      name: 'Cardápio',
      period: '',
      indicator: '',
      total: '358 itens',
      open: false,
      alert: null,
      stores: [
        {
          id: 1,
          name: 'LM Rio Sul',
          totalItems: 71,
          notCategorized: 0,
          notCardAssociated: 12,
          notSalesRegistered: 0
        },
        {
          id: 2,
          name: 'MJP Botafogo',
          totalItems: 287,
          notCategorized: 34,
          notCardAssociated: 287,
          notSalesRegistered: 0
        }
      ]
    },
    {
      id: 3,
      name: 'Pedidos',
      period: '',
      indicator: '27 pedidos',
      total: 'R$ 15.728,85 ',
      open: false,
      alert: null,
      deliveredOrders: 23,
      deliveredTotal: 'R$ 12.943,67',
      progressOrders: 4,
      progressTotal: 'R$ 2.785,18',
      delayOrders: 0,
      delayTotal: '-',
      returnedOrders: 0,
      returnedTotal: '-',
      suggestionsOrders: 37,
      suggestionsTotal: 'R$ 4.197,80'
    },
    {
      id: 4,
      name: 'Estoque',
      period: '',
      indicator: '150 itens',
      total: 'R$ 25.789,09',
      open: false,
      alert: null,
      stores: [
        {
          id: 1,
          name: 'LM Rio Sul',
          storeItems: 150,
          storeValue: 'R$ 25.789,09',
          totalItems: 138,
          totalValue: 'R$ 23.815,41',
          totalPreps: 12,
          prepsValue: 'R$ 1.973,68',
          breakItems: 17,
          breakValue: ''
        },
        {
          id: 2,
          name: 'MJP Botafogo',
          storeItems: 0,
          storeValue: '-',
          totalItems: 0,
          totalValue: '-',
          totalPreps: 0,
          prepsValue: '-',
          breakItems: 0,
          breakValue: ''
        }
      ]
    },
    {
      id: 5,
      name: 'Recebimento',
      period: '',
      indicator: '',
      total: '27/27 pedidos',
      open: false,
      alert: 1,
      stores: [
        {
          id: 1,
          name: 'LM Rio Sul',
          totalOrders: 27,
          totalOrdersValue: 'R$ 15.728,85',
          withDivergence: 2,
          withDivergenceValue: 'R$ 12.943,67',
          noInvoiceRegistered: 2,
          noInvoiceRegisteredValue: 'R$ 941,50',
          received: 23,
          receivedValue: 'R$ 1.843,68'
        },
        {
          id: 2,
          name: 'MJP Botafogo',
          totalOrders: 0,
          totalOrdersValue: '-',
          withDivergence: 0,
          withDivergenceValue: '-',
          noInvoiceRegistered: 0,
          noInvoiceRegisteredValue: '-',
          received: 0,
          receivedValue: '-'
        }
      ]
    },
    {
      id: 6,
      name: 'Produção',
      period: '',
      indicator: '2 em atraso',
      total: '7/12 preps',
      open: false,
      alert: null,
      stores: [
        {
          id: 1,
          name: 'LM Rio Sul',
          loss: 2,
          preps: 7
        },
        {
          id: 2,
          name: 'MJP Botafogo',
          loss: 0,
          preps: 0
        }
      ]
    },
    {
      id: 7,
      name: 'Inventário',
      period: '',
      indicator: '',
      total: '1/8 contagens',
      open: false,
      alert: null,
      stores: [
        {
          id: 1,
          name: 'LM Rio Sul',
          totalValue: 'R$ 25.788,00',
          stocks: [
            {
              id: 1,
              name: 'Estoque Seco',
              value: 7309,
              status: 'Iniciada'
            },
            {
              id: 2,
              name: 'Pizzaria',
              value: 14589,
              status: 'Concluída'
            },
            {
              id: 3,
              name: 'Cozinha',
              value: 3890,
              status: 'Não iniciada'
            }
          ]
        },
        {
          id: 1,
          name: 'MJP Botafogo',
          totalValue: '-',
          stocks: [
            {
              id: 1,
              name: 'Estoque Seco',
              value: null,
              status: ''
            },
            {
              id: 2,
              name: 'Pizzaria',
              value: null,
              status: ''
            },
            {
              id: 3,
              name: 'Cozinha',
              value: null,
              status: ''
            },
            {
              id: 4,
              name: 'Adega',
              value: null,
              status: ''
            },
            {
              id: 5,
              name: 'Bar',
              value: null,
              status: ''
            }
          ]
        }
      ]
    }
  ];

  const valueStyle = (value) => {
    if (value.indexOf('+') > -1) {
      return 'positive';
    }
    if (
      value.indexOf('-') > -1 ||
      value.indexOf('perda') > -1 ||
      value.indexOf('pedidos') > -1 ||
      value.indexOf('itens') > -1 ||
      value.indexOf('preps') > -1 ||
      value.indexOf('contagens') > -1 ||
      value.indexOf('atraso')
    ) {
      return 'negative';
    }
    return 'default';
  };

  const handleStockStatus = (status) => {
    if (status === 'Concluída') {
      return 'done';
    }
    if (status === 'Não iniciada') {
      return 'negative';
    }
    return 'default';
  };

    /* Inventory */
    const renderInventoryItem = (card) => (
      <ListItem
        button
        onClick={(e) =>
          setCollapseOpen(
            collapseOpen.map((x) =>
              x.id === card.id ? { ...x, open: !x.open } : x
            )
          )
        }
        style={{ padding: 0 }}
      >
        <table width="100%" cellPadding="0" cellSpacing="0" border="0">
          <tr>
            <td colSpan="4" height="4" />
          </tr>
  
          <tr>
            <td width="35%" className="topic-name" style={{ fontFamily: 'Lato' }}>
              <b>{card.name}</b> {card.period && `(${card.period})`}
              {card.alert && <div className="card-alert">{card.alert}</div>}
            </td>
  
            {card.indicator && (
              <td width="28%" className="description right" align="right">
                <Typography
                  className={valueStyle(card.indicator)}
                  style={{ fontFamily: 'Lato' }}
                >
                  {card.indicator}
                </Typography>
              </td>
            )}
  
            <td width="30%" className="description right" align="right">
              <Typography
                className={valueStyle(card.total)}
                style={{ fontFamily: 'Lato' }}
              >
                {card.total}
              </Typography>
            </td>
  
            <td width="7%">&nbsp;</td>
          </tr>
  
          <tr>
            <td colSpan="4" height="4" />
          </tr>
  
          <tr>
            <td colSpan="4" height="1" style={{ backgroundColor: '#DADEF7' }} />
          </tr>
        </table>
  
        {collapseOpen.find((x) => x.id === card.id) ? (
          collapseOpen.find((x) => x.id === card.id).open ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : (
          false
        )}
      </ListItem>
    );

  /* Sales */
  const renderSalesItem = (card) => (
    <ListItem
      button
      onClick={(e) =>
        setCollapseOpen(
          collapseOpen.map((x) =>
            x.id === card.id ? { ...x, open: !x.open } : x
          )
        )
      }
      style={{ padding: 0 }}
    >
      <table width="100%" cellPadding="0" cellSpacing="0" border="0">
        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td width="35%" className="topic-name" style={{ fontFamily: 'Lato' }}>
            <b>{card.name}</b> {card.period && `(${card.period})`}
            {card.alert && <div className="card-alert">{card.alert}</div>}
          </td>

          {card.indicator && (
            <td width="28%" className="description right" align="right">
              <Typography
                className={valueStyle(card.indicator)}
                style={{ fontFamily: 'Lato' }}
              >
                {card.indicator}
              </Typography>
            </td>
          )}

          <td width="30%" className="description right" align="right">
            <Typography style={{ fontFamily: 'Lato' }}>{card.total}</Typography>
          </td>

          <td width="7%">&nbsp;</td>
        </tr>

        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td colSpan="4" height="1" style={{ backgroundColor: '#DADEF7' }} />
        </tr>
      </table>

      {collapseOpen.find((x) => x.id === card.id) ? (
        collapseOpen.find((x) => x.id === card.id).open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : (
        false
      )}
    </ListItem>
  );

  const renderSalesTable = (card) =>
    card.stores &&
    card.stores.map((store) => (
      <ListItem
        className="p0"
        style={{
          alignContent: 'right',
          alignItems: 'right',
          align: 'right',
          alignmentBaseline: 'right'
        }}
      >
        <table
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          align="right"
        >
          <thead>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  <b>
                    {store.name} {store.index}
                  </b>
                </Typography>
              </td>

              <td width="28%" align="right">
                <Typography
                  className={`fontSizeDefault ${valueStyle(store.indicator)}`}
                >
                  {store.indicator}
                </Typography>
              </td>

              <td width="30%" align="right">
                <Typography className="fontSizeDefault">
                  {store.total}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">Loja:</Typography>
              </td>

              <td width="28%" align="right">
                <Typography
                  className={`fontSizeDefault ${valueStyle(store.storeIndicator)}`}
                >
                  {store.storeIndicator}
                </Typography>
              </td>

              <td width="30%" align="right">
                <Typography className="fontSizeDefault">
                  {store.storeTotal}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>

            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">Delivery:</Typography>
              </td>

              <td width="28%" align="right">
                <Typography
                  className={`fontSizeDefault ${valueStyle(store.deliveryIndicator)}`}
                >
                  {store.deliveryIndicator}
                </Typography>
              </td>

              <td width="30%" align="right">
                <Typography className="fontSizeDefault">
                  {store.deliveryTotal}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </ListItem>
    ));

  /* Menu */
  const renderMenuItem = (card) => (
    <ListItem
      button
      onClick={(e) =>
        setCollapseOpen(
          collapseOpen.map((x) =>
            x.id === card.id ? { ...x, open: !x.open } : x
          )
        )
      }
      style={{ padding: 0 }}
    >
      <table width="100%" cellPadding="0" cellSpacing="0" border="0">
        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td width="35%" className="topic-name" style={{ fontFamily: 'Lato' }}>
            <b>{card.name}</b> {card.period && `(${card.period})`}
            {card.alert && <div className="card-alert">{card.alert}</div>}
          </td>

          {card.indicator && (
            <td width="28%" className="description right" align="right">
              <Typography
                className={valueStyle(card.indicator)}
                style={{ fontFamily: 'Lato' }}
              >
                {card.indicator}
              </Typography>
            </td>
          )}

          <td width="30%" className="description right" align="right">
            <Typography
              className={valueStyle(card.total)}
              style={{ fontFamily: 'Lato' }}
            >
              {card.total}
            </Typography>
          </td>

          <td width="7%">&nbsp;</td>
        </tr>

        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td colSpan="4" height="1" style={{ backgroundColor: '#DADEF7' }} />
        </tr>
      </table>

      {collapseOpen.find((x) => x.id === card.id) ? (
        collapseOpen.find((x) => x.id === card.id).open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : (
        false
      )}
    </ListItem>
  );

  const renderMenuTable = (card) =>
    card.stores &&
    card.stores.map((store) => (
      <ListItem
        className="p0"
        style={{
          alignContent: 'right',
          alignItems: 'right',
          align: 'right',
          alignmentBaseline: 'right'
        }}
      >
        <table
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          align="right"
        >
          <thead>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  <b>
                    {store.name} {store.index}
                  </b>
                </Typography>
              </td>

              <td width="28%" align="right">
                &nbsp;
              </td>

              <td width="30%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.totalItems > 0 && 'negative'}`}
                >
                  {store.totalItems} {store.totalItems === 1 ? 'item' : 'itens'}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  Não Categorizado:
                </Typography>
              </td>

              <td width="28%" align="right">
                &nbsp;
              </td>

              <td width="30%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.notCategorized > 0 && 'negative'}`}
                >
                  {store.notCategorized}{' '}
                  {store.notCategorized === 1 ? 'item' : 'itens'}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>

            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  Sem Ficha associada:
                </Typography>
              </td>

              <td width="28%" align="right">
                &nbsp;
              </td>

              <td width="30%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.notCardAssociated > 0 && 'negative'}`}
                >
                  {store.notCardAssociated}{' '}
                  {store.notCardAssociated === 1 ? 'item' : 'itens'}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>

            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  Sem Venda registrada:
                </Typography>
              </td>

              <td width="28%" align="right">
                &nbsp;
              </td>

              <td width="30%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.notSalesRegistered > 0 && 'negative'}`}
                >
                  {store.notSalesRegistered}{' '}
                  {store.notSalesRegistered === 1 ? 'item' : 'itens'}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </ListItem>
    ));

  /* Orders */
  const renderOrdersItem = (card) => (
    <ListItem
      button
      onClick={(e) =>
        setCollapseOpen(
          collapseOpen.map((x) =>
            x.id === card.id ? { ...x, open: !x.open } : x
          )
        )
      }
      style={{ padding: 0 }}
    >
      <table width="100%" cellPadding="0" cellSpacing="0" border="0">
        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td width="35%" className="topic-name" style={{ fontFamily: 'Lato' }}>
            <b>{card.name}</b> {card.period && `(${card.period})`}
            {card.alert && <div className="card-alert">{card.alert}</div>}
          </td>

          {card.indicator && (
            <td width="28%" className="description right" align="right">
              <Typography
                className={card.delayOrders > 0 && 'negative'}
                style={{ fontFamily: 'Lato' }}
              >
                {card.indicator}
              </Typography>
            </td>
          )}

          <td width="30%" className="description right" align="right">
            <Typography
              className={card.delayOrders > 0 && 'negative'}
              style={{ fontFamily: 'Lato' }}
            >
              {card.total}
            </Typography>
          </td>

          <td width="7%">&nbsp;</td>
        </tr>

        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td colSpan="4" height="1" style={{ backgroundColor: '#DADEF7' }} />
        </tr>
      </table>

      {collapseOpen.find((x) => x.id === card.id) ? (
        collapseOpen.find((x) => x.id === card.id).open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : (
        false
      )}
    </ListItem>
  );

  const renderOrdersTable = (card) => (
    <ListItem
      className="p0"
      style={{
        alignContent: 'right',
        alignItems: 'right',
        align: 'right',
        alignmentBaseline: 'right'
      }}
    >
      <table
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        border="0"
        align="right"
      >
        <tbody>
          <tr>
            <td width="35%" align="left">
              <Typography className="fontSizeDefault">Entregue:</Typography>
            </td>

            <td width="28%" align="right">
              <Typography className="fontSizeDefault">
                {card.deliveredOrders}{' '}
                {card.deliveredOrders === 1 ? 'pedido' : 'pedidos'}
              </Typography>
            </td>

            <td width="30%" align="right">
              <Typography className="fontSizeDefault">
                {card.deliveredTotal}
              </Typography>
            </td>

            <td width="7%" align="right">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td width="35%" align="left">
              <Typography className="fontSizeDefault">Andamento:</Typography>
            </td>

            <td width="28%" align="right">
              <Typography className="fontSizeDefault">
                {card.progressOrders}{' '}
                {card.progressOrders === 1 ? 'pedido' : 'pedidos'}
              </Typography>
            </td>

            <td width="30%" align="right">
              <Typography className="fontSizeDefault">
                {card.progressTotal}
              </Typography>
            </td>

            <td width="7%" align="right">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td width="35%" align="left">
              <Typography className="fontSizeDefault">Atraso:</Typography>
            </td>

            <td width="28%" align="right">
              <Typography
                className={`fontSizeDefault ${card.delayOrders > 0 && 'negative'}`}
              >
                {card.delayOrders}{' '}
                {card.delayOrders === 1 ? 'pedido' : 'pedidos'}
              </Typography>
            </td>

            <td width="30%" align="right">
              <Typography className="fontSizeDefault">
                {card.delayTotal}
              </Typography>
            </td>

            <td width="7%" align="right">
              &nbsp;
            </td>
          </tr>

          <tr>
            <td width="35%" align="left">
              <Typography className="fontSizeDefault">Devolvido:</Typography>
            </td>

            <td width="28%" align="right">
              <Typography className="fontSizeDefault">
                {card.returnedOrders}{' '}
                {card.returnedOrders === 1 ? 'pedido' : 'pedidos'}
              </Typography>
            </td>

            <td width="30%" align="right">
              <Typography className="fontSizeDefault">
                {card.returnedTotal}
              </Typography>
            </td>

            <td width="7%" align="right">
              &nbsp;
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td width="35%" align="left">
              <Typography className="fontSizeDefault">Sugestões:</Typography>
            </td>

            <td width="28%" align="right">
              <Typography className="fontSizeDefault">
                {card.suggestionsOrders}{' '}
                {card.suggestionsOrders === 1 ? 'pedido' : 'pedidos'}
              </Typography>
            </td>

            <td width="30%" align="right">
              <Typography className="fontSizeDefault">
                {card.suggestionsTotal}
              </Typography>
            </td>

            <td width="7%" align="right">
              &nbsp;
            </td>
          </tr>
        </tfoot>
      </table>
    </ListItem>
  );

  /* Stocks */
  const renderStocksItem = (card) => (
    <ListItem
      button
      onClick={(e) =>
        setCollapseOpen(
          collapseOpen.map((x) =>
            x.id === card.id ? { ...x, open: !x.open } : x
          )
        )
      }
      style={{ padding: 0 }}
    >
      <table width="100%" cellPadding="0" cellSpacing="0" border="0">
        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td width="35%" className="topic-name" style={{ fontFamily: 'Lato' }}>
            <b>{card.name}</b> {card.period && `(${card.period})`}
            {card.alert && <div className="card-alert">{card.alert}</div>}
          </td>

          {card.indicator && (
            <td width="28%" className="description right" align="right">
              <Typography
                className={valueStyle(card.indicator)}
                style={{ fontFamily: 'Lato' }}
              >
                {card.indicator}
              </Typography>
            </td>
          )}

          <td width="30%" className="description right" align="right">
            <Typography style={{ fontFamily: 'Lato' }}>{card.total}</Typography>
          </td>

          <td width="7%">&nbsp;</td>
        </tr>

        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td colSpan="4" height="1" style={{ backgroundColor: '#DADEF7' }} />
        </tr>
      </table>

      {collapseOpen.find((x) => x.id === card.id) ? (
        collapseOpen.find((x) => x.id === card.id).open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : (
        false
      )}
    </ListItem>
  );

  const renderStocksTable = (card) =>
    card.stores &&
    card.stores.map((store) => (
      <ListItem
        className="p0"
        style={{
          alignContent: 'right',
          alignItems: 'right',
          align: 'right',
          alignmentBaseline: 'right'
        }}
      >
        <table
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          align="right"
        >
          <thead>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  <b>
                    {store.name} {store.index}
                  </b>
                </Typography>
              </td>

              <td width="28%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.breakItems > 0 && 'negative'}`}
                >
                  {store.storeItems} {store.storeItems === 1 ? 'item' : 'itens'}
                </Typography>
              </td>

              <td width="30%" align="right">
                <Typography className="fontSizeDefault">
                  {store.storeValue}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">Produtos:</Typography>
              </td>

              <td width="28%" align="right">
                <Typography className="fontSizeDefault">
                  {store.totalItems} {store.totalItems === 1 ? 'item' : 'itens'}
                </Typography>
              </td>

              <td width="30%" align="right">
                <Typography className="fontSizeDefault">
                  {store.totalValue}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>

            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">Preps:</Typography>
              </td>

              <td width="28%" align="right">
                <Typography className="fontSizeDefault">
                  {store.totalPreps} {store.totalPreps === 1 ? 'item' : 'itens'}
                </Typography>
              </td>

              <td width="30%" align="right">
                <Typography className="fontSizeDefault">
                  {store.prepsValue}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </tbody>

          <tfoot style={{ marginBottom: '10px' }}>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  Risco de Ruptura:
                </Typography>
              </td>

              <td width="28%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.breakItems > 0 && 'negative'}`}
                >
                  {store.breakItems} {store.breakItems === 1 ? 'item' : 'itens'}
                </Typography>
              </td>

              <td width="30%" align="right">
                <Typography className="fontSizeDefault">
                  {store.breakValue}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </tfoot>
        </table>
      </ListItem>
  ));

  /* Receivement */
  const renderReceivementItem = (card) => (
    <ListItem
      button
      onClick={() =>
        setCollapseOpen(
          collapseOpen.map((x) =>
            x.id === card.id ? { ...x, open: !x.open } : x
          )
        )
      }
      style={{ padding: 0 }}
    >
      <table width="100%" cellPadding="0" cellSpacing="0" border="0">
        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td width="35%" className="topic-name" style={{ fontFamily: 'Lato' }}>
            <b>{card.name}</b> {card.period && `(${card.period})`}
            {card.alert && <div className="card-alert">{card.alert}</div>}
          </td>

          {card.indicator && (
            <td width="28%" className="description right" align="right">
              <Typography
                className={valueStyle(card.indicator)}
                style={{ fontFamily: 'Lato' }}
              >
                {card.indicator}
              </Typography>
            </td>
          )}

          <td width="30%" className="description right" align="right">
            <Typography
              className={
                card.stores.filter(
                  (store) =>
                    (store.withDivergence || store.noInvoiceRegistered) > 0
                ).length && 'negative'
              }
              style={{ fontFamily: 'Lato' }}
            >
              {card.total}
            </Typography>
          </td>

          <td width="7%">&nbsp;</td>
        </tr>

        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td colSpan="4" height="1" style={{ backgroundColor: '#DADEF7' }} />
        </tr>
      </table>

      {collapseOpen.find((x) => x.id === card.id) ? (
        collapseOpen.find((x) => x.id === card.id).open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : (
        false
      )}
    </ListItem>
  );

  const renderReceivementTable = (card) =>
    card.stores &&
    card.stores.map((store) => (
      <ListItem
        className="p0"
        style={{
          alignContent: 'right',
          alignItems: 'right',
          align: 'right',
          alignmentBaseline: 'right'
        }}
      >
        <table
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          align="right"
        >
          <thead>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  <b>
                    {store.name} {store.index}
                  </b>
                </Typography>
              </td>

              <td width="28%" align="right">
                &nbsp;
              </td>

              <td width="30%" align="right">
                <Typography
                  className={
                    `fontSizeDefault ${(store.withDivergence || store.noInvoiceRegistered) > 0 &&
                    'negative'}`
                  }
                >
                  {store.totalOrders}{' '}
                  {store.totalOrders === 1 ? 'pedido' : 'pedidos'}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  Com divergência:
                </Typography>
              </td>

              <td width="28%" align="right">
                &nbsp;
              </td>

              <td width="30%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.withDivergence > 0 && 'negative'}`}
                >
                  {store.withDivergence}{' '}
                  {store.withDivergence === 1 ? 'pedido' : 'pedidos'}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>

            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  Sem NF recebida:
                </Typography>
              </td>

              <td width="28%" align="right">
                &nbsp;
              </td>

              <td width="30%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.noInvoiceRegistered > 0 && 'negative'}`}
                >
                  {store.noInvoiceRegistered}{' '}
                  {store.noInvoiceRegistered === 1 ? 'pedido' : 'pedidos'}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>

            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">Recebidos:</Typography>
              </td>

              <td width="28%" align="right">
                &nbsp;
              </td>

              <td width="30%" align="right">
                <Typography className="fontSizeDefault">
                  {store.received} {store.received === 1 ? 'pedido' : 'pedidos'}
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </ListItem>
  ));

  /* Production */
  const renderProductionItem = (card) => (
    <ListItem
      button
      onClick={(e) =>
        setCollapseOpen(
          collapseOpen.map((x) =>
            x.id === card.id ? { ...x, open: !x.open } : x
          )
        )
      }
      style={{ padding: 0 }}
    >
      <table width="100%" cellPadding="0" cellSpacing="0" border="0">
        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td width="35%" className="topic-name" style={{ fontFamily: 'Lato' }}>
            <b>{card.name}</b> {card.period && `(${card.period})`}
            {card.alert && <div className="card-alert">{card.alert}</div>}
          </td>

          {card.indicator && (
            <td width="28%" className="description right" align="right">
              <Typography
                className={valueStyle(card.indicator)}
                style={{ fontFamily: 'Lato' }}
              >
                {card.indicator}
              </Typography>
            </td>
          )}

          <td width="30%" className="description right" align="right">
            <Typography
              className={valueStyle(card.total)}
              style={{ fontFamily: 'Lato' }}
            >
              {card.total}
            </Typography>
          </td>

          <td width="7%">&nbsp;</td>
        </tr>

        <tr>
          <td colSpan="4" height="4" />
        </tr>

        <tr>
          <td colSpan="4" height="1" style={{ backgroundColor: '#DADEF7' }} />
        </tr>
      </table>

      {collapseOpen.find((x) => x.id === card.id) ? (
        collapseOpen.find((x) => x.id === card.id).open ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : (
        false
      )}
    </ListItem>
  );

  const renderProductionTable = (card) =>
    card.stores &&
    card.stores.map((store) => (
      <ListItem
        className="p0"
        style={{
          alignContent: 'right',
          alignItems: 'right',
          align: 'right',
          alignmentBaseline: 'right'
        }}
      >
        <table
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          align="right"
        >
          <thead>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  <b>
                    {store.name} {store.index}
                  </b>
                </Typography>
              </td>

              <td width="28%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.loss > 0 && 'negative'}`}
                >
                  {store.loss} em atraso
                </Typography>
              </td>

              <td width="30%" align="right">
                <Typography
                  className={`fontSizeDefault ${store.preps === 0 && 'negative'}`}
                >
                  {store.preps}/{card.total.split('/')[1]} preps
                </Typography>
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </thead>
        </table>
      </ListItem>
  ));

  const renderInventoryTable = (card) =>
    card.stores &&
    card.stores.map((store) => (
      <ListItem
        className="p0"
        style={{
          alignContent: 'right',
          alignItems: 'right',
          align: 'right',
          alignmentBaseline: 'right'
        }}
      >
        <table
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          align="right"
        >
          <thead>
            <tr>
              <td width="35%" align="left">
                <Typography className="fontSizeDefault">
                  <b>
                    {store.name} {store.index}
                  </b>
                </Typography>
              </td>

              <td width="28%" align="right">
                <Typography
                  className={
                    `fontSizeDefault ${store.stocks.filter((stock) => stock.value > 1000).length &&
                    'negative'}`
                  }
                >
                  {store.totalValue}
                </Typography>
              </td>

              <td width="30%" align="right">
                &nbsp;
              </td>

              <td width="7%">&nbsp;</td>
            </tr>
          </thead>

          <tbody>
            {store.stocks.map((stock) => (
              <tr>
                <td width="35%" align="left">
                  <Typography className="fontSizeDefault">
                    {stock.name}
                  </Typography>
                </td>

                <td width="28%" align="right">
                  <Typography
                    className={`fontSizeDefault ${stock.value > 1000 && 'negative'}`}
                  >
                    {stock.value ? `R$ ${stock.value},00` : '-'}
                  </Typography>
                </td>

                <td width="30%" align="right">
                  <Typography
                    className={`fontSizeDefault ${handleStockStatus(stock.status)}`}
                  >
                    {stock.status}
                  </Typography>
                </td>

                <td width="7%">&nbsp;</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ListItem>
  ));

  /* Notification Content Selector */
  const renderNotificationContent = (card, type) => {
    const isItem = type === 'item';

    if (card.name === 'Vendas') {
      return isItem ? renderSalesItem(card) : renderSalesTable(card);
    }
    if (card.name === 'Cardápio') {
      return isItem ? renderMenuItem(card) : renderMenuTable(card);
    }
    if (card.name === 'Pedidos') {
      return isItem ? renderOrdersItem(card) : renderOrdersTable(card);
    }
    if (card.name === 'Estoque') {
      return isItem ? renderStocksItem(card) : renderStocksTable(card);
    }
    if (card.name === 'Recebimento') {
      return isItem
        ? renderReceivementItem(card)
        : renderReceivementTable(card);
    }
    if (card.name === 'Produção') {
      return isItem ? renderProductionItem(card) : renderProductionTable(card);
    }
    return isItem ? renderInventoryItem(card) : renderInventoryTable(card);
  };

  useEffect(() => {
    setGetId(userInfo.companiesActive.id);
  }, [userInfo.companiesActive.id]);

  useEffect(() => {
    (async () => {
      const params = filtersChanged.map((filter) => filter.urlParam).join('&');
      const response = await fetch(
        `${environments.catalog}/cards?page=${page}&size=10&originId=${getId}&${params}`
      );
      const cards = await response.json();
      // setCardsOptions(cards.content)
      setCardsOptions(fakeObj);
      setCallSettings({ ...callSettings, totalPages: cards.totalPages });

      {
        /* setCollapseOpen(cards.content.map(card => ({
        id: card.id, open: false
      }))) */
      }

      setCollapseOpen(
        fakeObj.map((card) => ({
          id: card.id,
          open: false
        }))
      );
    })();
  }, [getId, page, filtersChanged]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies?page=0&size=10`
      );
      const rests = await response.json();
      setRests(rests.content ? rests.content : []);
    })();
  }, [getId]);

  return (
    <Container>
      <div className="main-notification">
        <span>
          Você tem <b>70</b> produtos para catalogar
        </span>
      </div>

      <table
        className=""
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        border="0"
        align="center"
      >
        <tr align="middle">
          <td width="30%" align="center">
            {cardsOptions.map((card, index) => {
              const exists = collapseOpen.find((x) => x.id === card.id);

              if (collapseOpen.length < cardsOptions.length && !exists) {
                collapseOpen.push({ id: card.id, open: false });
              }

              return (
                <>
                  {renderNotificationContent(card, 'item')}

                  <Collapse
                    in={
                      collapseOpen.find((x) => x.id === card.id)
                        ? collapseOpen.find((x) => x.id === card.id).open
                        : false
                    }
                    timeout="0"
                    unmountOnExit
                  >
                    <List component="div" style={{ align: 'right' }}>
                      {renderNotificationContent(card, 'content')}
                    </List>
                  </Collapse>
                </>
              );
            })}
          </td>
        </tr>
      </table>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(SkusActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
