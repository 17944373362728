const formatDocumentNumber = (value, typeDocumentNumber = null) => {
  if (value) {
    if (value.length === 14 || typeDocumentNumber?.toUpperCase() === 'CNPJ') {
      if (value.length === 14) {
        return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(
          5,
          3
        )}/${value.substr(8, 4)}-${value.substr(12, 2)}`;
      }
    } else if (
      value.length === 11 ||
      typeDocumentNumber?.toUpperCase() === 'CPF'
    ) {
      if (value.length === 11) {
        return `${value.substring(0, 3)}.${value.substring(
          3,
          6
        )}.${value.substring(6, 9)}-${value.substring(9, 11)}`;
      }
    }
  }

  return value;
};

export default formatDocumentNumber;
