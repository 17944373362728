import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography
} from '@material-ui/core';

import PaperComponent from 'components/PaperComponent';
import CloseIconDialog from 'images/icons/signals/closesAndCancels/closeDialog.svg';
import ValidationLength from 'utils/utils';

function DiffQuantities({ open, handleCloseModal, selectedCard }) {
  const [loading, setLoading] = useState(false);
  const hasNegativeDiff = selectedCard?.items
    ?.filter((el) => el?.type === 'INTERNAL')
    ?.map(
      (item) =>
        (item?.manufactureInProgress?.yield || 0 + item?.theoreticalStock) -
        item?.quantity
    )
    ?.some((el) => el < 0);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCloseModal}
      className="itemsDiffQuantitiesPopover"
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <article
        id="draggable-dialog-title"
        className="itemsDiffQuantitiesPopoverHeader"
        style={{ background: 'rgba(80, 98, 240, .85)' }}
      >
        <span className="white fontSize16">
          Consumo das fichas relacionadas
        </span>

        <IconButton
          className="pAbsolute"
          edge="start"
          color="inherit"
          onClick={() => {
            handleCloseModal();
          }}
          aria-label="close"
          style={{ outline: '0', background: 'none', align: 'right', right: 0 }}
        >
          <img
            src={CloseIconDialog}
            alt="CloseIconNavy"
            style={{ width: 12 }}
          />
        </IconButton>
      </article>

      <DialogContent className="modalContent onlyTable bgWhite dFlex flexColumn p0">
        <div className="cardDescriptionBox">
          <p className="mb0">
            {ValidationLength(selectedCard?.description, 50)}
          </p>
        </div>

        <section className="tableInfosBox">
          <div className="dFlex alignCenter justifyEnd">
            <p className="mb0">Qtde definida para esta produção:</p>

            <span
              className="bold pr12"
              style={{ color: hasNegativeDiff ? '#F53D4C' : '#010311' }}
            >
              {selectedCard?.yield?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })}{' '}
              {selectedCard?.un?.abbreviation}
            </span>
          </div>

          <table className="desktop">
            <thead>
              <tr className="headerTitle">
                <th width="44%" className="subtitle">
                  FICHAS RELACIONADAS
                </th>

                <th width="21%" className="subtitle right">
                  ESTOQUE / PRODUÇÃO
                </th>

                <th width="18%" className="subtitle right">
                  QTDE NECESSÁRIA
                </th>

                <th width="17%" className="subtitle right">
                  DIFERENÇA
                </th>
              </tr>
            </thead>

            <tbody>
              {loading && (
                <tr>
                  <th colSpan="4" className="m10 textAlignCenter">
                    <LinearProgress
                      style={{ height: '7px' }}
                      id="loading"
                      variant="query"
                    />
                  </th>
                </tr>
              )}

              {selectedCard?.items?.filter((card) => card?.type === 'INTERNAL')
                ?.length ? (
                selectedCard.items
                  .filter((card) => card?.type === 'INTERNAL')
                  ?.map((item, itemIndex) => {
                    const isLastElement =
                      itemIndex === selectedCard.items.length - 1;
                    const unit = item?.un?.abbreviation || '';
                    const stockProduction =
                      item?.theoreticalStock +
                      (item?.manufactureInProgress?.yield || 0);

                    return (
                      <>
                        <tr className="lineBorder">
                          <td className="description pl12">
                            {ValidationLength(item?.item, 30)}
                          </td>

                          <td className="description right pr0">
                            <Tooltip
                              title={
                                <div
                                  className="dFlex flexColumn"
                                  style={{ padding: 2 }}
                                >
                                  <span
                                    className="bold"
                                    style={{ marginBottom: 2 }}
                                  >
                                    Estoque:{' '}
                                    {item?.theoreticalStock?.toLocaleString(
                                      'pt-br',
                                      {
                                        minimumFractionDigits: 3
                                      }
                                    )}{' '}
                                    {unit}
                                  </span>

                                  <span className="bold">
                                    Em Produção:{' '}
                                    {(
                                      item?.manufactureInProgress?.yield || 0
                                    )?.toLocaleString('pt-br', {
                                      minimumFractionDigits: 3
                                    })}{' '}
                                    {unit}
                                  </span>
                                </div>
                              }
                            >
                              <span>
                                {stockProduction?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })}{' '}
                                {unit}
                              </span>
                            </Tooltip>
                          </td>

                          <td className="description right pr0 bold">
                            {item?.quantity?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}{' '}
                            {unit}
                          </td>

                          <td
                            className="description right pr12"
                            style={{
                              color:
                                stockProduction - (item?.quantity || 0) < 0
                                  ? '#F53D4C'
                                  : '#1F2445'
                            }}
                          >
                            {(
                              stockProduction - (item?.quantity || 0)
                            )?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}{' '}
                            {unit}
                          </td>
                        </tr>

                        {!isLastElement && (
                          <tr>
                            <td colSpan="4" className="pr0 pl0 pt0 pb0">
                              <hr
                                className="titleLineBorder m0"
                                style={{
                                  height: '0px',
                                  backgroundColor: '#D0D3E2'
                                }}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
              ) : (
                <tr>
                  <td colSpan="4" className="description textAlignCenter">
                    <Typography className="m5 bold fontSizeDefault">
                      Não há items a serem exibidos.
                    </Typography>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </DialogContent>
    </Dialog>
  );
}

export default DiffQuantities;
