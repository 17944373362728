/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import { Tooltip } from '@material-ui/core';
import { Assignment, Restore, Settings } from '@material-ui/icons';

import IntentionSuggestionBlue from 'images/icons/combinations/statusOtherTypeBlue.svg';
import RequestQuote from 'images/icons/objects/clipboards/providerIcon.svg';
import RequestQuoteEmpty from 'images/icons/objects/clipboards/providerIconEmpty.svg';
import numberToReal from 'utils/numberToReal';
import round from 'utils/roundNumber';

import QuotationOrderStatus from './QuotationManagerTable/QuotationOrderStatus';

const buttonLinkOptions = {
  valid: {
    background: '#D2F9D6',
    color: '#08AA15',
    weight: 'bold',
    fontSize: '12px'
  },
  inValid: {
    background: '#F7D4D7',
    color: '#AA0816',
    weight: 'bold',
    fontSize: '12px'
  },
  isGray: {
    background: '#D0D3E2',
    color: '#5062F0',
    fontSize: '12px'
  },
  validBloom: {
    background: '#DADDF3',
    color: '#5062F0',
    weight: 'bold',
    fontSize: '12px'
  },
  finished: {
    background: '#08AA15',
    color: '#D2F9D6',
    fontSize: '12px'
  },
  waiting: {
    background: '#FFF2CC',
    color: '#AA8208',
    fontSize: '12px'
  }
};

const estimatedStyle = {
  valid: {
    weight: 'bold',
    color: '#006608',
    fontSize: '16px'
  },
  inValid: {
    weight: 'bold',
    color: '#AA0816',
    fontSize: '16px'
  },
  isGray: {
    color: '#454A67',
    fontSize: '16px'
  },
  validBloom: {
    weight: 'bold',
    color: '#0B1A8E',
    fontSize: '16px'
  }
};

const getEstimatedColor = (value = 0) => {
  if (value === 0) {
    return estimatedStyle.isGray;
  }
  return estimatedStyle.validBloom;
};

const getInflationButtonActions = (value) => {
  if (value === 0) {
    return buttonLinkOptions.isGray;
  }

  if (value > 0) {
    return buttonLinkOptions.inValid;
  }
  return buttonLinkOptions.valid;
};

const getContentBlueCardColor = (option = '', quotation) => {
  const blueCardOptions = {
    validQuotation: {
      backGroundColor: '#5062F0',
      color: 'white',
      tooltip: 'COTAÇÃO VÁLIDA',
      subFontColor: '#5062F0'
    },
    validQuotationExpired: {
      backGroundColor: 'white',
      color: '#5062F0',
      border: '#5062F0',
      tooltip: 'COTAÇÃO VENCIDA',
      subFontColor: '#7583F0'
    },
    lastPurchased: {
      backGroundColor: '#454A67',
      color: '#fff',
      tooltip: 'ÚLTIMO PEDIDO',
      subFontColor: '#454A67'
    },
    noQuotation: {
      backGroundColor: 'white',
      color: '#9296AC',
      border: '#9296AC',
      tooltip: 'SEM COTAÇÃO',
      subFontColor: '#9296AC'
    }
  };

  const { isValid, isLastExtractValue } = quotation;

  const isValidQuotation = isValid && !isLastExtractValue;
  const isQuotationOut =
    !isValid &&
    !isLastExtractValue &&
    isValid !== null &&
    isLastExtractValue !== null;
  const noQuotation = isValid === null && isLastExtractValue === null;

  if (noQuotation) {
    return blueCardOptions.noQuotation;
  }

  if (isValidQuotation) {
    return blueCardOptions.validQuotation;
  }

  if (isQuotationOut) {
    return blueCardOptions.validQuotationExpired;
  }

  return blueCardOptions.lastPurchased;
};

const getProductsIds = (products = []) =>
  products.map((product) => product.productId);

const getRestaurantsIds = (products = []) =>
  products.map((product) => product.restaurantId);

const getStatusRequestQuotation = (item) => {
  if (item?.statusRequestQuotation === 'REQUESTED') {
    return RequestQuote;
  }
  if (item?.statusRequestQuotation === 'NOT_REQUESTED') {
    return RequestQuoteEmpty;
  }
};

export const statusIntention = (
  status,
  otherStatus = null,
  disableTooltip = false,
  color = 'lightBlue',
  width = '24px',
  height = 'auto'
) => {
  if (otherStatus && otherStatus !== status) {
    return (
      <Tooltip title="Intenção/Sugestão" disableHoverListener={disableTooltip}>
        <img
          style={{ width: '20px' }}
          src={IntentionSuggestionBlue}
          alt="IntentionSuggestionBlue"
        />
      </Tooltip>
    );
  }
  switch (status) {
    case 'INTENTION':
      return (
        <Tooltip title="Intenção" disableHoverListener={disableTooltip}>
          <Assignment style={{ width, height }} className={color} />
        </Tooltip>
      );
    case 'ENGINE':
      return (
        <Tooltip title="Sugestão" disableHoverListener={disableTooltip}>
          <Settings style={{ width, height }} className={color} />
        </Tooltip>
      );
    case 'HISTORY':
      return (
        <Tooltip
          title="Histórico de Compras 30 dias"
          disableHoverListener={disableTooltip}
        >
          <Restore style={{ width, height }} className={color} />
        </Tooltip>
      );
    default:
      break;
  }
};

export const getbloomtableLine = (item, originIds = []) => {
  const {
    description = '',
    category = '',
    categoryFamily = '',
    skus = 0,
    sku = {},
    products = [],
    quotation = {},
    inflation = {},
    orders = {},
    estimatedBloomEconomy = {},
    potentialEconomy = {},
    images = [],
    intention = {}
  } = item;

  const productIds = getProductsIds(products);
  const restaurantsIds = getRestaurantsIds(products);
  const inflationDialog = {
    lastPurchase: inflation?.lastCost,
    percentage: inflation?.percentage,
    unitaryValueConsumptionUnit: quotation?.unitaryValueConsumptionUnit,
    consumptionUnit: quotation?.consumptionUnit
  };

  const separateFammily = categoryFamily?.trim().split('/');
  const removerLastCategory = categoryFamily?.trim().split('/');

  if (removerLastCategory) {
    removerLastCategory.splice(separateFammily?.length - 1, 1);
  }

  return [
    {
      collumnName: 'checkLine',
      action: 'selectItem',
      width: '20px',
      selected: item?.selected || false,
      item,
      id: item._id,
      productsIds: productIds,
      lineProductGroupId: item.productGroupId,
      modalToTableProps: { productGroupId: item.productGroupId },
      dataToDialog: inflationDialog,
      totalQuantity: orders?.totalQuantity,
      totalValue: orders?.totalValue,
      consumptionUnit: quotation?.consumptionUnit,
      restaurantIds: restaurantsIds,
      type: 'text',
      itemLength: 20,
      productGroupId: item.productGroupId,
      inputSize: '100%',
      status: item?.orders?.status,
      disabledMessage:
        !item?.intention?.status || item?.intention?.status !== 'TO APPROVE'
          ? item?.quotation?.isValid ||
            (item?.statusRequestQuotation === 'REQUESTED' &&
              'Não é possível selecionar este produto')
          : false
    },
    {
      content: {
        skuId: sku?.id || null,
        image: sku?.image || null,
        images
      },
      type: 'image',
      id: item._id,
      inputSize: '56px',
      backGroundColor: 'white',
      padding: '8px 8px 8px 8px',
      action: 'optionsDialog',
      secondAction: 'optionsDialog',
      hasWaterMark: sku?.isSimilarBloom,
      lineProductGroupId: item.productGroupId,
      productsIds: productIds,
      restaurantIds: restaurantsIds,
      modalToTableProps: {
        estimatedBloomEconomy:
          estimatedBloomEconomy?.bloomEconomyValue === '-0,00' ||
          round(estimatedBloomEconomy?.bloomEconomyValue, 2) == 0
            ? '-'
            : Math.abs(estimatedBloomEconomy?.bloomEconomyValue),
        potentialEconomy:
          potentialEconomy?.potentialEconomyValue === '-0,00' ||
          round(potentialEconomy?.potentialEconomyValue, 2) == 0
            ? '-'
            : Math.abs(potentialEconomy?.potentialEconomyValue)
      }
    },
    {
      content: description,
      type: 'text',
      buttonTooltip: description.length > 47 ? description : null,
      topAlign: true,
      padding: '8px 8px 0px 4px',
      id: item._id,
      inputSize: '269px',
      truncateonTwoLine: true,
      fontStyle: {
        weight: 'bold',
        fontSize: '16px'
      },
      display: 'block',
      subLine: {
        content:
          separateFammily?.length > 1
            ? [
                removerLastCategory.join('/'),
                `/${separateFammily[separateFammily?.length - 1]}`
              ]
            : ['', `${categoryFamily || ''}`],
        fontStyle: {
          color: '#9296AC',
          marginTop: '8px',
          fontSize: '9px'
        },
        fontStyleSecond: {
          fontSize: '12px',
          color: '#0E1337'
        },
        type: 'text',
        itemLength: 11,
        id: item._id,
        buttonTooltip: categoryFamily,
        inputSize: '63px'
      }
    },
    {
      content: skus,
      dataToDialog: inflationDialog,
      type: 'number',
      fractionDigits: 2,
      borderTable: true,
      borderColor: '#1F2445',
      topAlign: true,
      action: 'modalSkusQuotation',
      align: 'number',
      productsIds: productIds,
      id: item._id,
      inputSize: '38px',
      restaurantIds: restaurantsIds,
      fontStyle: {
        fontSize: '16px'
      }
    },
    {
      content: [
        quotation?.consumptionUnit == 'un'
          ? round(quotation?.unitaryValueConsumptionUnit, 2)
          : numberToReal(quotation?.unitaryValueConsumptionUnit, 2),
        `/${quotation?.consumptionUnit || '-'}`
      ],
      fractionDigits: 2,
      padding: '8px 0px 0px 8px',
      totalQuantity: orders?.totalQuantity,
      totalValue: orders?.totalValue,
      consumptionUnit: quotation?.consumptionUnit,
      type: 'richNumber',
      secondContentGray: true,
      topAlign: true,
      productsIds: productIds,
      lineProductGroupId: item.productGroupId,
      restaurantIds: restaurantsIds,
      id: item._id,
      display: 'flex',
      iconAction: 'buyIntention',
      buyIntention: intention,
      products,
      horizontalAlignment: true,
      modalToTableProps: { productGroupId: item.productGroupId },
      fontStyle: {
        color: '#1F2445',
        weight: 'bold',
        fontSize: '16px'
      },
      fontStyleSecond: {
        fontSize: '12px'
      },
      dataToDialog: inflationDialog,
      item,
      tdPadding: '0px 9px 0px 0px',
      subLine: {
        action: 'approveConfirmation',
        validateAction: item?.intention?.status === 'TO APPROVE',
        singleApproval: true,
        content: (
          <QuotationOrderStatus
            value={item?.intention?.status}
            item={item}
            isIntention
            onClickApprove={item?.intention?.status === 'TO APPROVE'}
          />
        ),
        display: 'block',
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        productsIds: productIds,
        id: item._id,
        inputSize: '53px',
        restaurantIds: restaurantsIds,
        fontStyle: {
          container: {
            height: '20px',
            marginTop: '12px'
          }
        }
      }
    },
    {
      content: [
        quotation?.packageValue,
        `/${quotation?.skuQuantity || 0}${
          quotation?.unitMeasurementPackage || '-'
        }`
      ],
      fractionDigits: 2,
      type: 'richNumber',
      secondContentGray: true,
      topAlign: true,
      id: item._id,
      display: 'flex',
      horizontalAlignment: true,
      fontStyle: {
        color: '#1F2445',
        weight: 'bold',
        fontSize: '16px'
      },
      subLine: {
        content: (
          <QuotationOrderStatus value={item?.orders?.status} item={item} />
        ),
        display: 'block',
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        productsIds: productIds,
        id: item._id,
        inputSize: '53px',
        restaurantIds: restaurantsIds,
        fontStyle: {
          container: {
            height: '20px',
            marginTop: '12px'
          }
        }
      }
    },
    {
      collumnName: 'options',
      content: quotation?.relatedProviders,
      type: 'number',
      fractionDigits: 2,
      borderTable: true,
      borderColor: '#1F2445',
      topAlign: true,
      contentBlueCard: getContentBlueCardColor(
        quotation?.relatedProviders,
        quotation
      ),
      bloomOptionsToEvaluate: {
        tooltip: `+ ${item?.similarsQuantity} ${
          item?.similarsQuantity > 1 ? 'Opções' : 'Opção'
        } Bloom à avaliar`,
        amount: item?.similarsQuantity
      },
      action: 'optionsDialog',
      secondAction: 'optionsDialog',
      lineProductGroupId: item.productGroupId,
      disableWrapperClick: true,
      productsIds: productIds,
      align: 'center',
      id: item._id,
      inputSize: '75px',
      restaurantIds: restaurantsIds,
      fontStyle: {
        fontSize: '16px',
        container: {
          marginTop: '16px'
        }
      },
      modalToTableProps: {
        estimatedBloomEconomy:
          estimatedBloomEconomy?.bloomEconomyValue === '-0,00' ||
          round(estimatedBloomEconomy?.bloomEconomyValue, 2) == 0
            ? '-'
            : Math.abs(estimatedBloomEconomy?.bloomEconomyValue),
        potentialEconomy:
          potentialEconomy?.potentialEconomyValue === '-0,00' ||
          round(potentialEconomy?.potentialEconomyValue, 2) == 0
            ? '-'
            : Math.abs(potentialEconomy?.potentialEconomyValue)
      },
      display: 'flex',
      alignItems: 'center',
      item,
      subLine: {
        modalToTableProps: {
          estimatedBloomEconomy:
            estimatedBloomEconomy?.bloomEconomyValue === '-0,00' ||
            round(estimatedBloomEconomy?.bloomEconomyValue, 2) == 0
              ? '-'
              : Math.abs(estimatedBloomEconomy?.bloomEconomyValue),
          potentialEconomy:
            potentialEconomy?.potentialEconomyValue === '-0,00' ||
            round(potentialEconomy?.potentialEconomyValue, 2) == 0
              ? '-'
              : Math.abs(potentialEconomy?.potentialEconomyValue)
        },
        action: 'requestConfirmation',
        singleApproval: true,
        validateAction: item.statusRequestQuotation === 'NOT_REQUESTED',
        content: (
          <div className="dFlex pRelative">
            {
              getContentBlueCardColor(quotation?.relatedProviders, quotation)
                .tooltip
            }

            {item?.statusRequestQuotation &&
            item?.statusRequestQuotation !== 'NONE' ? (
              <Tooltip
                title={
                  item.statusRequestQuotation === 'REQUESTED'
                    ? 'Cotação solicitada'
                    : 'Cotação não solicitada'
                }
              >
                <img
                  className="pAbsolute"
                  style={{
                    width: '12px',
                    right: '-12px',
                    top: 3,
                    cursor:
                      item.statusRequestQuotation !== 'REQUESTED'
                        ? 'pointer'
                        : 'default'
                  }}
                  src={getStatusRequestQuotation(item)}
                  alt={getStatusRequestQuotation(item)}
                />
              </Tooltip>
            ) : null}
          </div>
        ),
        topAlign: true,
        fontStyle: {
          container: {
            marginLeft: '8px'
          },
          fontSize: '10px',
          color: getContentBlueCardColor(quotation?.relatedProviders, quotation)
            .subFontColor,
          textAlign: 'center',
          inputSize: '64px',
          fontWeight: 'bold',
          marginTop: '10px',
          marginBottom: '0px'
        }
      }
    },
    {
      label: 'inflation',
      content: [inflation?.lastCost, `/${quotation?.consumptionUnit || '-'}`],
      secondContentGray: true,
      action: 'modalInflation',
      secondAction: 'modalInflation',
      dataToDialog: inflationDialog,
      padding: '0px',
      paddingDiv: '8px 12px 0px 12px',
      type: 'richNumber',
      display: 'block',
      fractionDigits: 2,
      align: 'number',
      id: item._id,
      inputSize: '120px',
      borderTable: true,
      borderColor: '#1F2445',
      topAlign: true,
      specialDisplayCollumn: true,
      productsIds: productIds,
      restaurantIds: restaurantsIds,
      fontStyle: {
        weight: 'bold',
        fontSize: '16px',
        color: '#1F2445'
      },
      fontStyleSecond: {
        fontSize: '12px'
      },
      subLine: {
        content: `${inflation?.percentage > 0 ? '+' : ''}${numberToReal(
          inflation?.percentage,
          2
        )}${inflation?.percentage !== 0 ? '%' : ''}`,
        dataToDialog: inflationDialog,
        secondContentGray: true,
        display: 'block',
        action: 'modalInflation',
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        productsIds: productIds,
        buttonLink: buttonLinkOptions.gray,
        id: item._id,
        inputSize: '53px',
        restaurantIds: restaurantsIds,
        modalToTableProps: {
          restaurantIds: restaurantsIds
        },
        fontStyle: {
          container: {
            height: '20px',
            marginTop: '14px',
            marginLeft: '5px',
            marginRight: '5px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: '4px',
            rectStyles: getInflationButtonActions(inflation?.percentage),
            padding: '8px 8px 9px 8px'
          }
        }
      }
    },
    {
      label: 'history',
      content: [
        `${
          intention?.totalValue?.toLocaleString('pt-br', {
            minimumFractionDigits: 3
          }) || '-'
        }${intention?.totalValue ? quotation?.consumptionUnit : ''}`,
        `${intention?.totalRestaurants ? `/${intention.totalRestaurants}` : ''}`
      ],
      alignItems: 'flex-end',
      padding: '5px 8px 0px 4px',
      type: 'number',
      maxSize: '100%',
      secondContentGray: true,
      modalTitle: item?.description,
      fractionDigits: 2,
      secondAction:
        restaurantsIds.length == 1 && originIds.length === 1
          ? 'editable'
          : 'buyIntention',
      productsIds: productIds,
      topAlign: true,
      totalQuantity: orders?.totalQuantity,
      totalValue: orders?.totalValue,
      consumptionUnit: quotation?.consumptionUnit,
      lineProductGroupId: item.productGroupId,
      products,
      modalToTableProps: { productGroupId: item.productGroupId },
      item,
      restaurantIds: restaurantsIds,
      id: item._id,
      display: 'flex',
      iconAction: 'buyIntention',
      buyIntention: intention,
      hideIcon: true,
      dataToDialog: inflationDialog,
      specialDisplayCollumn: true,
      containerWidth: '100%',
      textAlign: 'end',
      fontStyle: {
        container: {
          width: 'auto'
        },
        weight: 'bold',
        fontSize: '16px',
        color: '#0E1337',
        height: '20px',
        textAlign: 'center'
      },
      fontStyleSecond: {
        fontSize: '12px',
        textAlign: 'end'
      },
      subLine: {
        content: orders?.totalQuantity
          ? [
              `${
                quotation?.consumptionUnit == 'un'
                  ? numberToReal(orders?.totalQuantity, 0)
                  : numberToReal(orders?.totalQuantity, 2)
              }${quotation?.consumptionUnit}`,
              `/${orders?.nRestaurants}`
            ]
          : '-',
        dataToDialog: inflationDialog,
        display: 'block',
        action: 'consumeByStore',
        modalToTableProps: {
          productGroupId: item.productGroupId,
          restaurantIds: restaurantsIds
        },
        modalTitle: item?.description,
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        productsIds: productIds,
        id: item._id,
        inputSize: '53px',
        restaurantIds: restaurantsIds,
        fontStyle: {
          marginBottom: '0px',
          marginTop: '7px',
          fontSize: '12px',
          color: '#0E1337',
          secundColor: 'rgb(146, 150, 172)',
          container: {
            marginTop: '11px',
            marginLeft: 'auto',
            padding: '0px 0px 0px 12px'
          }
        }
      }
    },
    {
      label: 'history',
      content: `${
        intention?.totalVolumeConsumptionUnit?.toLocaleString('pt-br', {
          minimumFractionDigits: 2
        }) || '-'
      }`,
      fractionDigits: 2,
      align: 'number',
      type: 'richNumber',
      borderTable: true,
      borderColor: '#5062F0',
      id: item._id,
      inputSize: '80px',
      topAlign: true,
      specialDisplayCollumn: true,
      display: 'block',
      fontStyle: {
        weight: 'bold',
        fontSize: '16px',
        color: '#0E1337'
      },
      subLine: {
        content:
          orders?.totalValue.toLocaleString('pt-br', {
            minimumFractionDigits: 2
          }) || '-',
        display: 'block',
        type: 'richNumber',
        fractionDigits: 2,
        align: 'number',
        productsIds: productIds,
        id: item._id,
        inputSize: '53px',
        restaurantIds: restaurantsIds,
        fontStyle: {
          marginBottom: '0px',
          marginTop: '7px',
          color: '',
          container: {
            marginTop: '11px',
            padding: '0px 0px 0px 12px'
          }
        }
      }
    },
    {
      label: 'potentialEconomy',
      content: !potentialEconomy?.hasSimilarBest
        ? '-'
        : potentialEconomy?.potentialEconomyValue === '-0,00' ||
          round(potentialEconomy?.potentialEconomyValue, 2) == 0
        ? '0,00'
        : Math.abs(potentialEconomy?.potentialEconomyValue),
      fractionDigits: 2,
      align: 'number',
      type: 'richNumber',
      id: item._id,
      padding: '0px',
      paddingDiv: '8px 12px 0px 0px',
      inputSize: '85px',
      action: 'dialogBrand',
      lineProductGroupId: item.productGroupId,
      restaurantIds: restaurantsIds,
      productsIds: productIds,
      fontStyle: getEstimatedColor(potentialEconomy?.potentialEconomyValue),
      borderTable: true,
      display: 'flex',
      topAlign: true,
      specialDisplayCollumn: true,
      fontStyle: {
        weight: 'bold',
        fontSize: '16px'
      },
      modalToTableProps: {
        restaurantIds: restaurantsIds,
        estimatedBloomEconomy:
          estimatedBloomEconomy?.bloomEconomyValue === '-0,00' ||
          round(estimatedBloomEconomy?.bloomEconomyValue, 2) == 0
            ? '-'
            : Math.abs(estimatedBloomEconomy?.bloomEconomyValue),
        potentialEconomy:
          potentialEconomy?.potentialEconomyValue === '-0,00' ||
          round(potentialEconomy?.potentialEconomyValue, 2) == 0
            ? '-'
            : Math.abs(potentialEconomy?.potentialEconomyValue),
        intention
      }
    }
  ];
};

export const updateTableLine = ({
  tableData,
  responseData,
  tableToModalProps,
  setTableData = () => {}
}) => {
  const { collunms = [] } = tableData;
  const { data } = responseData;

  if (!data?.length) return;

  const [item] = data;

  const tableNewLine = getbloomtableLine(item);

  collunms.forEach((collumn) => {
    if (collumn[0].id === tableToModalProps.id) {
      collumn.forEach((element, index) => {
        const keys = Object.keys(element);
        keys.forEach((item) => {
          if (item !== 'id') {
            element[item] = tableNewLine[index][item];
          }
        });
      });
    }
  });
  setTableData({ ...tableData });
};

const handleFetchData = (content = [], originIds = []) => {
  const translatedData = content.map((item) =>
    getbloomtableLine(item, originIds)
  );

  return translatedData;
};

export default handleFetchData;
